<div>
  <form nz-form>
    <div nz-row [nzGutter]="10">
      <div nz-col nzSpan="8">
        <div class="my-box">
          <div
            nz-col
            nzXs="24"
            nzSm="24"
            nzMd="24"
            nzLg="24"
            nzXl="24"
            nzSpan="12"
          >
            <nz-form-item
              style="
                display: inline-block;
                margin-bottom: 5px;
                width: -webkit-fill-available;
                padding: 0px 10px;
              "
            >
              <nz-form-label nzNoColon>Date </nz-form-label>

              <nz-form-control>
                <nz-date-picker
                  style="width: 100%"
                  nzFormat="dd/MM/yyyy"
                  name="BILL_SUBMISSION_DATE"
                  [(ngModel)]="tourtravellingllounce.TRAVELLING_ALLOWANCE_DATE"
                >
                </nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </div>
      <div nz-col nzSpan="16">
        <div class="my-box">
          <div style="margin-top: 70px; font-size: 14px">
            <div nz-row>
              <div nz-col nzSpan="15"></div>

              <div nz-col nzSpan="9">
                <p>From</p>

                <div style="font-weight: bold">
                  {{ tourtravellingllounce.EMPLOYEE_NAME }}
                </div>
                <div>{{ tourtravellingllounce.DESIGNATION }}</div>
                <div>Mob :- {{ tourtravellingllounce.MOBILE_NO }}</div>
                <p>
                  Dated :
                  {{
                    tourtravellingllounce.TRAVELLING_ALLOWANCE_DATE
                      | date : "dd MM yyyy"
                  }}
                </p>
              </div>
            </div>
          </div>

          <div style="margin-top: 70px; font-size: 14px">
            <div nz-row>
              <div nz-col nzSpan="9">
                <p>To,</p>

                <p>The Income Tax Officer(HQ),</p>
                <p>Gaz, Pay Bill Section,</p>
                <p>Ayakar, Mumbai</p>
              </div>
            </div>
          </div>

          <!-- <div>
              <p>To</p>
              <p>The Income Tax Officer(HQ),</p>
              <p>Gaz, Pay Bill Section,</p>
              <p>Ayakar, Mumbai</p>
            </div> -->
          <br />

          <p>Sir/Mam,</p>

          <p style="margin-left: 10%">
            Sub.: Travelling allowance Bill for
            <span *ngFor="let data of travellingdata; let i = index">
              <b>{{ data.PURPOSE_OF_JOURNEY }},</b></span
            >
          </p>
          <br />
          <br />

          <p style="margin-left: 10%">
            Kindly, find attached herewith the dully filled travelling allowance
            bill (in duplicate)
          </p>
          <p>for official Tour for kind perusal and needful.</p>
          <br />

          <div style="margin-top: 100px; font-size: 13px">
            <div nz-row>
              <div nz-col nzSpan="15"></div>

              <div nz-col nzSpan="9" style="text-align: center">
                <p>Yours Faithfully,</p>
                <br />
                <div style="font-weight: bold">
                  {{ tourtravellingllounce.EMPLOYEE_NAME }}
                </div>
                <div>{{ tourtravellingllounce.DESIGNATION }}</div>
              </div>
            </div>
          </div>

          <p>Encl.: 1. SR 61 approval</p>
          <p style="margin-left: 5%">
            2. Copy of Summons and attendace certificate from Court.
          </p>
          <p style="margin-left: 5%">
            3. Duly filled form in duplicate along with boarding pass in
            original, ticket and mandate form.
          </p>
        </div>
      </div>
    </div>
  </form>

  <div class="footer">
    <button
      type="button"
      nz-button
      (click)="close()"
      class="ant-btn"
      style="margin-right: 8px"
    >
      <span>Cancel</span>
    </button>
    <button
      type="button"
      class="ant-btn ant-btn-primary"
      nz-button
      (click)="showmodal()"
    >
      <span>Download or Print</span>
    </button>
  </div>
</div>

<nz-modal
  [nzWidth]="getwidth()"
  [(nzVisible)]="printOrderModalVisible"
  nzMaskClosable="false"
  nzClosable="false"
  [nzContent]="printOrderModalContent"
  [nzTitle]="'Travelling Allowance'"
  (nzOnCancel)="printOrderModalCancel()"
  [nzFooter]="printOrderModalFooter"
>
  <ng-template #printOrderModalContent>
    <nz-spin [nzSpinning]="loadingRecords">
      <div style="height: 450px; overflow: auto">
        <div id="excel-table2">
          <div
            style="
              width: 700px;
              padding-right: 3px;
              margin-left: 50px;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 14px;
            "
          >
            <div>
              <div style="margin-top: 70px; font-size: 14px">
                <div nz-row>
                  <div nz-col nzSpan="15"></div>

                  <div nz-col nzSpan="9">
                    <p>From</p>
                    <div style="font-weight: bold">
                      {{ tourtravellingllounce.EMPLOYEE_NAME }}
                    </div>
                    <div>{{ tourtravellingllounce.DESIGNATION }}</div>
                    <div>Mob :-{{ tourtravellingllounce.MOBILE_NO }}</div>
                    <p>
                      Dated :
                      {{
                        tourtravellingllounce.TRAVELLING_ALLOWANCE_DATE
                          | date : "dd MM yyyy"
                      }}
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <p>To</p>
                <p>The Income Tax Officer(HQ),</p>
                <p>Gaz, Pay Bill Section,</p>
                <p>Ayakar, Mumbai</p>
              </div>
              <br />

              <p>Sir/Mam,</p>

              <p style="margin-left: 10%">
                Sub.: Travelling allowance Bill for
                <span *ngFor="let data of travellingdata; let i = index">
                  <b>{{ data.PURPOSE_OF_JOURNEY }},</b></span
                >
              </p>
              <br />
              <br />

              <p style="margin-left: 10%">
                Kindly, find attached herewith the dully filled travelling
                allowance bill (in duplicate)
              </p>
              <p>for official Tour for kind perusal and needful.</p>
              <br />

              <div style="margin-top: 100px; font-size: 13px">
                <div nz-row>
                  <div nz-col nzSpan="15"></div>

                  <div nz-col nzSpan="9" style="text-align: center">
                    <p>Yours Faithfully,</p>
                    <br />
                    <div style="font-weight: bold">
                      {{ tourtravellingllounce.EMPLOYEE_NAME }}
                    </div>
                    <div>{{ tourtravellingllounce.DESIGNATION }}</div>
                  </div>
                </div>
              </div>

              <p>Encl.: 1. SR 61 approval</p>
              <p style="margin-left: 5%">
                2. Copy of Summons and attendace certificate from Court.
              </p>
              <p style="margin-left: 5%">
                3. Duly filled form in duplicate along with boarding pass in
                original, ticket and mandate form.
              </p>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>
  </ng-template>
  <ng-template #printOrderModalFooter>
    <button nz-button nzType="default" (click)="printOrderModalCancel()">
      Close
    </button>

    <button
      nz-button
      nzType="default"
      [useExistingCss]="true"
      printSectionId="excel-table2"
      ngxPrint
    >
      Print
    </button>

    <button
      nz-button
      nzType="primary"
      [nzLoading]="pdfDownload"
      (click)="openpdf()"
    >
      Download
    </button>
  </ng-template>
</nz-modal>
