export class TransferCheckList {
  ID: number = 0;
  CLIENT_ID = 0;
  TRANSFER_ID: number = 0;
  ORIGINAL_BILL_SUBMITTED_IN_PROPER_FORMAT: boolean = false;
  BILLS_SUMBMITTED_IN_PROPER_TIME: boolean = false;
  CONDOLATION_OF_DELAY_IS_SUBMITTED: any;
  T_A_ADVANCE_TAKEN: boolean = false;
  CTG_ALREADY_CLAIMED: boolean = false;
  REQUEST_CASE_TRANSFER: boolean = false;
  FIXED_TENURE_COMPLETED_AT_OLD_STATION: any;
  A_T_TICKET_SUBMITTED: any;
  ORIGINAL_BOARDING_PASS_SELF_DECLARATION: any;
  TRANSPORTATION_OF_PERSONAL_EFFECT_BILL_SUBMITTED: any;
  TRANSPORTATION_OF_VEHICLE_BY_ROAD_SELF_PROPULSION: any;
  IS_TRANSFER_ORDER_SERVICE_CERTIFICATE: boolean = false;
  ASSUMPTION_OF_CHARGE_SUBMITTED: any;
  LAST_PAY_CERTIFICATE_SUBMITTED: boolean = false;
  BANK_MANDATE_CHEQUE_SUBMITTED: boolean = false;
  SIGNATURE_ID: any = 1;
}
