export class EmployeeMaster {
  ID: number = 0;
  NAME: string = '';
  EMPLOYEE_CODE: number;
  GRADE_PAY: number;
  OFFICE_NAME: string = '';
  DESIGNATION: string = '';
  LOCATION: string = '';
  DDO_OF_THE_OFFICIAL: string = '';
  EMAIL_ID: any;
  MOBILE_NO: any;
  PASSWORD: any = 12345678;
  ADDRESS: any;
  CLAIM_ID: number = 0;
  STATUS: boolean = true;
}
