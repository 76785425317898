export class Joureyofparticulartours {
  ID: any;
  DATE: any;
  PERIOD_FROM: any;
  PERIOD_TO: any;
  TRAVEL_MODE_ID: any;
  ENTITLED_CLASS_ID: any;
  TRAVELLED_CLASS_ID: any;
  FARE_OF_ENTITLED_CLASS: any;
  EMP_ID: any;
  TOUR_ID: any;
  PLACE_FROM: any;
  PLACE_TO: any;
  FARE_PAID_BY_OFFICER: any;
}
