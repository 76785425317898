export class ApplicantMaster {
  ID: number = 0;
  CLIENT_ID = 0;
  NAME: any;
  STATUS: boolean = true;
  EMPLOYEE_CODE: any;
  GRADE_PAY: any;
  OFFICE_NAME: any;
  DESIGNATION: any;
  LOCATION: any;
  DDO_OF_THE_OFFICIAL: any;
  BENEFICIARY_TYPE: string = '';
  CGHS_CARD_NO: any;
  CGHS_CARD_VALIDITY: any;
  EMP_ID: any;
  MOBILE_NO: any;
  EMAIL_ID: any;
  ADDRESS: any;
  CURRENT_STAGE_ID: any;
  INSPECTOR_ID: any;
  EMPLOYEE_NAME: any;
  EMPLOYEE_OFFICE_NAME: any;
  EMPLOYEE_DESIGNATION: any;
  EMPLOYEE_DDO: any;
  EMPLOYEE_NAME_HN: any;
  EMPLOYEE_GRADE_PAY: any;
}
