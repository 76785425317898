export class Particularhotels {
  ID: number = 0;
  TOUR_ID: any;
  EMP_ID: any;
  PERIOD_FROM: any;
  PERIOD_TO: any;
  NAME_OF_HOTEL: any;
  DAILY_RATE_OF_LODGING_CHARGES: any;
  TOTAL_AMOUNT_PAID: any;
  PLACE: any;
  HOTEL_GST: any = 0;
  NET_AMOUNT: any = 0;
  PLACE_ORDER_ACCOMODATION_ADMISSIBLE_AMOUNT: any;
  IN_ADMISIBLE_AMOUNT: any;
  DAYS_AT_HOTEL: any;
  AMOUNT_WITHOUT_GST: any;
  HOTEL_BILL: any;
}
