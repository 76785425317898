<div style="text-align: center; padding: 50px;">
    <img src="assets/aykarlogo.png" alt="" srcset="" height="150px" width="180px">
    <br><br>
    <span style="font-size: 18px; font-weight: bold;">Welcome</span>
    <br>
    <span style="font-family: 'Courier New', Courier, monospace; font-size: 25px; font-weight: bold; color: black;">
        {{userId|uppercase}}
    </span>
    <br>
    <!-- <span>
        <span nz-icon nzType="mail" nzTheme="twotone"></span>
        {{emailId}}
    </span> -->
</div>