<!-- <div
    style="color:black(255, 5, 5); text-align: center; margin-bottom: 2%; font-weight: bolder; font-size: 35px;margin-top: 5%;">
    Checklist for TA on Transfer Bills
</div>
<br> -->
<div>
  <form nz-form>
    <nz-spin [nzSpinning]="isSpinning">
      <div nz-row [nzGutter]="10">
        <div nz-col nzSpan="8">
          <div class="my-box" style="height: 500px">
            <div nz-col nzSpan="24">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Signature</nz-form-label>
                <nz-form-control>
                  <nz-select
                    name="userid"
                    [(ngModel)]="transferData.SIGNATURE_ID"
                    nzAllowClear
                    nzPlaceHolder="Choose Signature"
                    (ngModelChange)="signature($event)"
                  >
                    <nz-option
                      *ngFor="let user of Signaturearray"
                      [nzValue]="user.ID"
                      [nzLabel]="user.NAME"
                    >
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </div>
        <div nz-col nzSpan="16">
          <div
            class="my-box"
            style="padding: 10px; height: 590px; overflow-y: scroll"
          >
            <p style="text-align: center; font-size: large; margin-bottom: 0">
              <b> <u> Checklist for TA on Transfer Bills </u></b>
            </p>
            <br />
            <p>
              Name of the Officer =<b> {{ transferData.NAME }}</b>
            </p>
            <p>
              Designation =<b> {{ transferData.DESIGNATION }} </b>
            </p>
            <p>
              Pay Level = <b> {{ transferData.GRADE_PAY_LEVEL }} </b>
            </p>
            <br />
            <table style="width: 100%">
              <thead>
                <tr>
                  <th nzAlign="center" style="width: 50px">Sr.No.</th>
                  <th nzAlign="center">Subject</th>
                  <th nzAlign="center" style="width: 75px">Yes</th>
                  <th nzAlign="center" style="width: 75px">No</th>
                  <th nzAlign="center" style="width: 75px">NA</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td nzAlign="center">1</td>
                  <td class="pading">
                    Whether Original bills submitted in proper format (Duly
                    filled Part A and signed Certificate)
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.ORIGINAL_BILL_SUBMITTED_IN_PROPER_FORMAT ==
                        1
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                    <span
                      *ngIf="
                        checklistData.ORIGINAL_BILL_SUBMITTED_IN_PROPER_FORMAT ==
                        0
                      "
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.ORIGINAL_BILL_SUBMITTED_IN_PROPER_FORMAT ==
                        1
                      "
                    ></span>
                    <span
                      *ngIf="
                        checklistData.ORIGINAL_BILL_SUBMITTED_IN_PROPER_FORMAT ==
                        0
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td nzAlign="center">2</td>
                  <td class="pading">
                    Whether bills has been submitted in proper time (If advance
                    is taken then bills should be submitted in 15 days after
                    completion of journey and bills should be submitted in 60
                    days after completion of journey if advance is not taken)
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="checklistData.BILLS_SUMBMITTED_IN_PROPER_TIME == 1"
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                    <span
                      *ngIf="checklistData.BILLS_SUMBMITTED_IN_PROPER_TIME == 0"
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="checklistData.BILLS_SUMBMITTED_IN_PROPER_TIME == 1"
                    ></span>
                    <span
                      *ngIf="checklistData.BILLS_SUMBMITTED_IN_PROPER_TIME == 0"
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td nzAlign="center">3</td>
                  <td class="pading">
                    In case of delayed submission of bill, whether condonation
                    of delay submitted
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.CONDOLATION_OF_DELAY_IS_SUBMITTED == 'Y'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                    <span
                      *ngIf="
                        checklistData.CONDOLATION_OF_DELAY_IS_SUBMITTED ==
                          'N' &&
                        checklistData.CONDOLATION_OF_DELAY_IS_SUBMITTED == 'NA'
                      "
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.CONDOLATION_OF_DELAY_IS_SUBMITTED ==
                          'Y' &&
                        checklistData.CONDOLATION_OF_DELAY_IS_SUBMITTED == 'NA'
                      "
                    ></span>
                    <span
                      *ngIf="
                        checklistData.CONDOLATION_OF_DELAY_IS_SUBMITTED == 'N'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.CONDOLATION_OF_DELAY_IS_SUBMITTED ==
                          'Y' &&
                        checklistData.CONDOLATION_OF_DELAY_IS_SUBMITTED == 'N'
                      "
                    ></span>
                    <span
                      *ngIf="
                        checklistData.CONDOLATION_OF_DELAY_IS_SUBMITTED == 'NA'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                </tr>
                <tr>
                  <td nzAlign="center">4</td>
                  <td class="pading">Whether T.A. advance taken</td>
                  <td style="border: 1px solid black; text-align: center">
                    <span *ngIf="checklistData.T_A_ADVANCE_TAKEN == 1"
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                    <span *ngIf="checklistData.T_A_ADVANCE_TAKEN == 0"></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span *ngIf="checklistData.T_A_ADVANCE_TAKEN == 1"></span>
                    <span *ngIf="checklistData.T_A_ADVANCE_TAKEN == 0"
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td nzAlign="center">5</td>
                  <td class="pading">Whether CTG already claimed?</td>
                  <td style="border: 1px solid black; text-align: center">
                    <span *ngIf="checklistData.CTG_ALREADY_CLAIMED == 1"
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                    <span *ngIf="checklistData.CTG_ALREADY_CLAIMED == 0"></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span *ngIf="checklistData.CTG_ALREADY_CLAIMED == 1"></span>
                    <span *ngIf="checklistData.CTG_ALREADY_CLAIMED == 0"
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td nzAlign="center">6</td>
                  <td class="pading">Whether Request case Transfer?</td>
                  <td style="border: 1px solid black; text-align: center">
                    <span *ngIf="checklistData.REQUEST_CASE_TRANSFER == 1"
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                    <span
                      *ngIf="checklistData.REQUEST_CASE_TRANSFER == 0"
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="checklistData.REQUEST_CASE_TRANSFER == 1"
                    ></span>
                    <span *ngIf="checklistData.REQUEST_CASE_TRANSFER == 0"
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td nzAlign="center">7</td>
                  <td class="pading">
                    If Request case Transfer, whether fixed tenure completed at
                    old station.
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.FIXED_TENURE_COMPLETED_AT_OLD_STATION ==
                        'Y'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                    <span
                      *ngIf="
                        checklistData.FIXED_TENURE_COMPLETED_AT_OLD_STATION ==
                          'N' &&
                        checklistData.FIXED_TENURE_COMPLETED_AT_OLD_STATION ==
                          'NA'
                      "
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.FIXED_TENURE_COMPLETED_AT_OLD_STATION ==
                          'Y' &&
                        checklistData.FIXED_TENURE_COMPLETED_AT_OLD_STATION ==
                          'NA'
                      "
                    ></span>
                    <span
                      *ngIf="
                        checklistData.FIXED_TENURE_COMPLETED_AT_OLD_STATION ==
                        'N'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.FIXED_TENURE_COMPLETED_AT_OLD_STATION ==
                          'Y' &&
                        checklistData.FIXED_TENURE_COMPLETED_AT_OLD_STATION ==
                          'N'
                      "
                    ></span>
                    <span
                      *ngIf="
                        checklistData.FIXED_TENURE_COMPLETED_AT_OLD_STATION ==
                        'NA'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                </tr>
                <tr>
                  <td nzAlign="center">8</td>
                  <td class="pading">
                    Whether air or train tickets submitted or not
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span *ngIf="checklistData.A_T_TICKET_SUBMITTED == 'Y'"
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                    <span
                      *ngIf="
                        checklistData.A_T_TICKET_SUBMITTED == 'N' &&
                        checklistData.A_T_TICKET_SUBMITTED == 'NA'
                      "
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.A_T_TICKET_SUBMITTED == 'Y' &&
                        checklistData.A_T_TICKET_SUBMITTED == 'NA'
                      "
                    ></span>
                    <span *ngIf="checklistData.A_T_TICKET_SUBMITTED == 'N'"
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.A_T_TICKET_SUBMITTED == 'N' &&
                        checklistData.A_T_TICKET_SUBMITTED == 'Y'
                      "
                    ></span>
                    <span *ngIf="checklistData.A_T_TICKET_SUBMITTED == 'NA'"
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                </tr>
                <tr>
                  <td nzAlign="center">9</td>
                  <td class="pading">
                    In case of Air journey, wheather original boarding pass/
                    self-declaration in case of non submission of boarding pass
                    duly submitted
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.ORIGINAL_BOARDING_PASS_SELF_DECLARATION ==
                        'Y'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                    <span
                      *ngIf="
                        checklistData.ORIGINAL_BOARDING_PASS_SELF_DECLARATION ==
                          'N' &&
                        checklistData.ORIGINAL_BOARDING_PASS_SELF_DECLARATION ==
                          'NA'
                      "
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.ORIGINAL_BOARDING_PASS_SELF_DECLARATION ==
                          'Y' &&
                        checklistData.ORIGINAL_BOARDING_PASS_SELF_DECLARATION ==
                          'NA'
                      "
                    ></span>
                    <span
                      *ngIf="
                        checklistData.ORIGINAL_BOARDING_PASS_SELF_DECLARATION ==
                        'N'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.ORIGINAL_BOARDING_PASS_SELF_DECLARATION ==
                          'Y' &&
                        checklistData.ORIGINAL_BOARDING_PASS_SELF_DECLARATION ==
                          'N'
                      "
                    ></span>
                    <span
                      *ngIf="
                        checklistData.ORIGINAL_BOARDING_PASS_SELF_DECLARATION ==
                        'NA'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                </tr>
                <tr>
                  <td nzAlign="center">10</td>
                  <td class="pading">
                    In case of transportation of personal; effects whether
                    original bills submitted
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.TRANSPORTATION_OF_PERSONAL_EFFECT_BILL_SUBMITTED ==
                        'Y'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                    <span
                      *ngIf="
                        checklistData.TRANSPORTATION_OF_PERSONAL_EFFECT_BILL_SUBMITTED ==
                          'N' &&
                        checklistData.TRANSPORTATION_OF_PERSONAL_EFFECT_BILL_SUBMITTED ==
                          'NA'
                      "
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.TRANSPORTATION_OF_PERSONAL_EFFECT_BILL_SUBMITTED ==
                          'Y' &&
                        checklistData.TRANSPORTATION_OF_PERSONAL_EFFECT_BILL_SUBMITTED ==
                          'NA'
                      "
                    ></span>
                    <span
                      *ngIf="
                        checklistData.TRANSPORTATION_OF_PERSONAL_EFFECT_BILL_SUBMITTED ==
                        'N'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.TRANSPORTATION_OF_PERSONAL_EFFECT_BILL_SUBMITTED ==
                          'Y' &&
                        checklistData.TRANSPORTATION_OF_PERSONAL_EFFECT_BILL_SUBMITTED ==
                          'N'
                      "
                    ></span>
                    <span
                      *ngIf="
                        checklistData.TRANSPORTATION_OF_PERSONAL_EFFECT_BILL_SUBMITTED ==
                        'NA'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                </tr>
                <tr>
                  <td nzAlign="center">11</td>
                  <td class="pading">
                    In case of transportation of vehicle by road/ self
                    propulsion, whether restricted to Railway freight charges.
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.TRANSPORTATION_OF_VEHICLE_BY_ROAD_SELF_PROPULSION ==
                        'Y'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                    <span
                      *ngIf="
                        checklistData.TRANSPORTATION_OF_VEHICLE_BY_ROAD_SELF_PROPULSION ==
                          'N' &&
                        checklistData.TRANSPORTATION_OF_VEHICLE_BY_ROAD_SELF_PROPULSION ==
                          'NA'
                      "
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.TRANSPORTATION_OF_VEHICLE_BY_ROAD_SELF_PROPULSION ==
                          'Y' &&
                        checklistData.TRANSPORTATION_OF_VEHICLE_BY_ROAD_SELF_PROPULSION ==
                          'NA'
                      "
                    ></span>
                    <span
                      *ngIf="
                        checklistData.TRANSPORTATION_OF_VEHICLE_BY_ROAD_SELF_PROPULSION ==
                        'N'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.TRANSPORTATION_OF_VEHICLE_BY_ROAD_SELF_PROPULSION ==
                          'Y' &&
                        checklistData.TRANSPORTATION_OF_VEHICLE_BY_ROAD_SELF_PROPULSION ==
                          'N'
                      "
                    ></span>
                    <span
                      *ngIf="
                        checklistData.TRANSPORTATION_OF_VEHICLE_BY_ROAD_SELF_PROPULSION ==
                        'NA'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                </tr>
                <tr>
                  <td nzAlign="center">12</td>
                  <td class="pading">
                    Whether Transfer order (full copy)/Service Certificate
                    submitted
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.IS_TRANSFER_ORDER_SERVICE_CERTIFICATE == 1
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                    <span
                      *ngIf="
                        checklistData.IS_TRANSFER_ORDER_SERVICE_CERTIFICATE == 0
                      "
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.IS_TRANSFER_ORDER_SERVICE_CERTIFICATE == 1
                      "
                    ></span>
                    <span
                      *ngIf="
                        checklistData.IS_TRANSFER_ORDER_SERVICE_CERTIFICATE == 0
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td nzAlign="center">13</td>
                  <td class="pading">Whether Assumption of charge submitted</td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.ASSUMPTION_OF_CHARGE_SUBMITTED == 'Y'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                    <span
                      *ngIf="
                        checklistData.ASSUMPTION_OF_CHARGE_SUBMITTED == 'N' &&
                        checklistData.ASSUMPTION_OF_CHARGE_SUBMITTED == 'NA'
                      "
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.ASSUMPTION_OF_CHARGE_SUBMITTED == 'Y' &&
                        checklistData.ASSUMPTION_OF_CHARGE_SUBMITTED == 'NA'
                      "
                    ></span>
                    <span
                      *ngIf="
                        checklistData.ASSUMPTION_OF_CHARGE_SUBMITTED == 'N'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.ASSUMPTION_OF_CHARGE_SUBMITTED == 'Y' &&
                        checklistData.ASSUMPTION_OF_CHARGE_SUBMITTED == 'N'
                      "
                    ></span>
                    <span
                      *ngIf="
                        checklistData.ASSUMPTION_OF_CHARGE_SUBMITTED == 'NA'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                </tr>
                <tr>
                  <td nzAlign="center">14</td>
                  <td class="pading">Whether Last pay certificate submitted</td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="checklistData.LAST_PAY_CERTIFICATE_SUBMITTED == 1"
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                    <span
                      *ngIf="checklistData.LAST_PAY_CERTIFICATE_SUBMITTED == 0"
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="checklistData.LAST_PAY_CERTIFICATE_SUBMITTED == 1"
                    ></span>
                    <span
                      *ngIf="checklistData.LAST_PAY_CERTIFICATE_SUBMITTED == 0"
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td nzAlign="center">15</td>
                  <td class="pading">
                    Whether Bank Mandate from/cancelled cheque submitted
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="checklistData.BANK_MANDATE_CHEQUE_SUBMITTED == 1"
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                    <span
                      *ngIf="checklistData.BANK_MANDATE_CHEQUE_SUBMITTED == 0"
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="checklistData.BANK_MANDATE_CHEQUE_SUBMITTED == 1"
                    ></span>
                    <span
                      *ngIf="checklistData.BANK_MANDATE_CHEQUE_SUBMITTED == 0"
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <br />

            <!-- <div style="margin-top: 100px; font-size: 13px">
                <div nz-row>
                    <div nz-col nzSpan="15"></div>
    
                    <div nz-col nzSpan="9" style="text-align: center">
                        <div style="font-weight: bold">({{ NAME_HN }})</div>
                        <div>{{ POST_HN }}</div>
                        <div>{{ OFFICE_NAME_HN }}</div>
                        <div style="font-weight: bold">
                            ({{ SIGNNAME }})
                        </div>
                        <div>{{ POST }}</div>
                        <div>{{ OFFICE_NAME }}</div>
    
                    </div>
                </div>
            </div> -->
            <!-- <div>
                <p style="text-align:left"><b>(Jitendra R Chandekar)</b></p>
                <br>
                <p style="text-align:left"><b>ITO, G.P.B.S-II, MUMBAI</b></p>
            </div> -->

            <div style="margin-top: 70px; font-size: 13px">
              <div nz-row>
                <div nz-col nzSpan="15"></div>

                <div nz-col nzSpan="9" style="text-align: center">
                  <div style="font-weight: bold">({{ SIGNNAME }})</div>
                  <div>
                    {{ POST }}<br />
                    {{ OFFICE_NAME }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>
  </form>
</div>

<div class="footer">
  <button
    type="button"
    nz-button
    (click)="close()"
    class="ant-btn"
    style="margin-right: 8px"
  >
    <span>Cancel</span>
  </button>
  <button
    type="button"
    class="ant-btn ant-btn-primary"
    nz-button
    (click)="checkSave(false)"
  >
    <span>Save & Preview</span>
  </button>
</div>

<nz-modal
  [nzWidth]="getwidth()"
  [(nzVisible)]="printOrderModalVisible"
  nzMaskClosable="false"
  nzClosable="false"
  [nzContent]="printOrderModalContent"
  [nzTitle]="'Order'"
  (nzOnCancel)="printOrderModalCancel()"
  [nzFooter]="printOrderModalFooter"
>
  <ng-template #printOrderModalContent>
    <nz-spin [nzSpinning]="loadingRecords">
      <div style="height: 450px; overflow: auto">
        <div id="excel-table">
          <div
            class="my-box"
            style="
              padding: 10px;
              margin-left: 50px;
              font-size: 14px;
              font-family: Arial, Helvetica, sans-serif;
            "
          >
            <p style="text-align: center; font-size: large; margin-bottom: 0">
              <b> <u> Checklist for TA on Transfer Bills </u></b>
            </p>
            <br />
            <p>
              Name of the Officer =<b> {{ transferData.NAME }}</b>
            </p>
            <p>
              Designation =<b> {{ transferData.DESIGNATION }} </b>
            </p>
            <p>
              Pay Level = <b> {{ transferData.GRADE_PAY_LEVEL }} </b>
            </p>
            <br />
            <table style="width: 100%">
              <thead>
                <tr>
                  <th nzAlign="center" style="width: 50px">Sr.No.</th>
                  <th nzAlign="center">Subject</th>
                  <th nzAlign="center" style="width: 75px">Yes</th>
                  <th nzAlign="center" style="width: 75px">No</th>
                  <th nzAlign="center" style="width: 75px">NA</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td nzAlign="center">1</td>
                  <td class="pading">
                    Whether Original bills submitted in proper format (Duly
                    filled Part A and signed Certificate)
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.ORIGINAL_BILL_SUBMITTED_IN_PROPER_FORMAT ==
                        1
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                    <span
                      *ngIf="
                        checklistData.ORIGINAL_BILL_SUBMITTED_IN_PROPER_FORMAT ==
                        0
                      "
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.ORIGINAL_BILL_SUBMITTED_IN_PROPER_FORMAT ==
                        1
                      "
                    ></span>
                    <span
                      *ngIf="
                        checklistData.ORIGINAL_BILL_SUBMITTED_IN_PROPER_FORMAT ==
                        0
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td nzAlign="center">2</td>
                  <td class="pading">
                    Whether bills has been submitted in proper time (If advance
                    is taken then bills should be submitted in 15 days after
                    completion of journey and bills should be submitted in 60
                    days after completion of journey if advance is not taken)
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="checklistData.BILLS_SUMBMITTED_IN_PROPER_TIME == 1"
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                    <span
                      *ngIf="checklistData.BILLS_SUMBMITTED_IN_PROPER_TIME == 0"
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="checklistData.BILLS_SUMBMITTED_IN_PROPER_TIME == 1"
                    ></span>
                    <span
                      *ngIf="checklistData.BILLS_SUMBMITTED_IN_PROPER_TIME == 0"
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td nzAlign="center">3</td>
                  <td class="pading">
                    In case of delayed submission of bill, whether condonation
                    of delay submitted
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.CONDOLATION_OF_DELAY_IS_SUBMITTED == 'Y'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                    <span
                      *ngIf="
                        checklistData.CONDOLATION_OF_DELAY_IS_SUBMITTED ==
                          'N' &&
                        checklistData.CONDOLATION_OF_DELAY_IS_SUBMITTED == 'NA'
                      "
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.CONDOLATION_OF_DELAY_IS_SUBMITTED ==
                          'Y' &&
                        checklistData.CONDOLATION_OF_DELAY_IS_SUBMITTED == 'NA'
                      "
                    ></span>
                    <span
                      *ngIf="
                        checklistData.CONDOLATION_OF_DELAY_IS_SUBMITTED == 'N'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.CONDOLATION_OF_DELAY_IS_SUBMITTED ==
                          'Y' &&
                        checklistData.CONDOLATION_OF_DELAY_IS_SUBMITTED == 'N'
                      "
                    ></span>
                    <span
                      *ngIf="
                        checklistData.CONDOLATION_OF_DELAY_IS_SUBMITTED == 'NA'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                </tr>
                <tr>
                  <td nzAlign="center">4</td>
                  <td class="pading">Whether T.A. advance taken</td>
                  <td style="border: 1px solid black; text-align: center">
                    <span *ngIf="checklistData.T_A_ADVANCE_TAKEN == 1"
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                    <span *ngIf="checklistData.T_A_ADVANCE_TAKEN == 0"></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span *ngIf="checklistData.T_A_ADVANCE_TAKEN == 1"></span>
                    <span *ngIf="checklistData.T_A_ADVANCE_TAKEN == 0"
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td nzAlign="center">5</td>
                  <td class="pading">Whether CTG already claimed?</td>
                  <td style="border: 1px solid black; text-align: center">
                    <span *ngIf="checklistData.CTG_ALREADY_CLAIMED == 1"
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                    <span *ngIf="checklistData.CTG_ALREADY_CLAIMED == 0"></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span *ngIf="checklistData.CTG_ALREADY_CLAIMED == 1"></span>
                    <span *ngIf="checklistData.CTG_ALREADY_CLAIMED == 0"
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td nzAlign="center">6</td>
                  <td class="pading">Whether Request case Transfer?</td>
                  <td style="border: 1px solid black; text-align: center">
                    <span *ngIf="checklistData.REQUEST_CASE_TRANSFER == 1"
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                    <span
                      *ngIf="checklistData.REQUEST_CASE_TRANSFER == 0"
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="checklistData.REQUEST_CASE_TRANSFER == 1"
                    ></span>
                    <span *ngIf="checklistData.REQUEST_CASE_TRANSFER == 0"
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td nzAlign="center">7</td>
                  <td class="pading">
                    If Request case Transfer, whether fixed tenure completed at
                    old station.
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.FIXED_TENURE_COMPLETED_AT_OLD_STATION ==
                        'Y'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                    <span
                      *ngIf="
                        checklistData.FIXED_TENURE_COMPLETED_AT_OLD_STATION ==
                          'N' &&
                        checklistData.FIXED_TENURE_COMPLETED_AT_OLD_STATION ==
                          'NA'
                      "
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.FIXED_TENURE_COMPLETED_AT_OLD_STATION ==
                          'Y' &&
                        checklistData.FIXED_TENURE_COMPLETED_AT_OLD_STATION ==
                          'NA'
                      "
                    ></span>
                    <span
                      *ngIf="
                        checklistData.FIXED_TENURE_COMPLETED_AT_OLD_STATION ==
                        'N'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.FIXED_TENURE_COMPLETED_AT_OLD_STATION ==
                          'Y' &&
                        checklistData.FIXED_TENURE_COMPLETED_AT_OLD_STATION ==
                          'N'
                      "
                    ></span>
                    <span
                      *ngIf="
                        checklistData.FIXED_TENURE_COMPLETED_AT_OLD_STATION ==
                        'NA'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                </tr>
                <tr>
                  <td nzAlign="center">8</td>
                  <td class="pading">
                    Whether air or train tickets submitted or not
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span *ngIf="checklistData.A_T_TICKET_SUBMITTED == 'Y'"
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                    <span
                      *ngIf="
                        checklistData.A_T_TICKET_SUBMITTED == 'N' &&
                        checklistData.A_T_TICKET_SUBMITTED == 'NA'
                      "
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.A_T_TICKET_SUBMITTED == 'Y' &&
                        checklistData.A_T_TICKET_SUBMITTED == 'NA'
                      "
                    ></span>
                    <span *ngIf="checklistData.A_T_TICKET_SUBMITTED == 'N'"
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.A_T_TICKET_SUBMITTED == 'N' &&
                        checklistData.A_T_TICKET_SUBMITTED == 'Y'
                      "
                    ></span>
                    <span *ngIf="checklistData.A_T_TICKET_SUBMITTED == 'NA'"
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                </tr>
                <tr>
                  <td nzAlign="center">9</td>
                  <td class="pading">
                    In case of Air journey, wheather original boarding pass/
                    self-declaration in case of non submission of boarding pass
                    duly submitted
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.ORIGINAL_BOARDING_PASS_SELF_DECLARATION ==
                        'Y'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                    <span
                      *ngIf="
                        checklistData.ORIGINAL_BOARDING_PASS_SELF_DECLARATION ==
                          'N' &&
                        checklistData.ORIGINAL_BOARDING_PASS_SELF_DECLARATION ==
                          'NA'
                      "
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.ORIGINAL_BOARDING_PASS_SELF_DECLARATION ==
                          'Y' &&
                        checklistData.ORIGINAL_BOARDING_PASS_SELF_DECLARATION ==
                          'NA'
                      "
                    ></span>
                    <span
                      *ngIf="
                        checklistData.ORIGINAL_BOARDING_PASS_SELF_DECLARATION ==
                        'N'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.ORIGINAL_BOARDING_PASS_SELF_DECLARATION ==
                          'Y' &&
                        checklistData.ORIGINAL_BOARDING_PASS_SELF_DECLARATION ==
                          'N'
                      "
                    ></span>
                    <span
                      *ngIf="
                        checklistData.ORIGINAL_BOARDING_PASS_SELF_DECLARATION ==
                        'NA'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                </tr>
                <tr>
                  <td nzAlign="center">10</td>
                  <td class="pading">
                    In case of transportation of personal; effects whether
                    original bills submitted
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.TRANSPORTATION_OF_PERSONAL_EFFECT_BILL_SUBMITTED ==
                        'Y'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                    <span
                      *ngIf="
                        checklistData.TRANSPORTATION_OF_PERSONAL_EFFECT_BILL_SUBMITTED ==
                          'N' &&
                        checklistData.TRANSPORTATION_OF_PERSONAL_EFFECT_BILL_SUBMITTED ==
                          'NA'
                      "
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.TRANSPORTATION_OF_PERSONAL_EFFECT_BILL_SUBMITTED ==
                          'Y' &&
                        checklistData.TRANSPORTATION_OF_PERSONAL_EFFECT_BILL_SUBMITTED ==
                          'NA'
                      "
                    ></span>
                    <span
                      *ngIf="
                        checklistData.TRANSPORTATION_OF_PERSONAL_EFFECT_BILL_SUBMITTED ==
                        'N'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.TRANSPORTATION_OF_PERSONAL_EFFECT_BILL_SUBMITTED ==
                          'Y' &&
                        checklistData.TRANSPORTATION_OF_PERSONAL_EFFECT_BILL_SUBMITTED ==
                          'N'
                      "
                    ></span>
                    <span
                      *ngIf="
                        checklistData.TRANSPORTATION_OF_PERSONAL_EFFECT_BILL_SUBMITTED ==
                        'NA'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                </tr>
                <tr>
                  <td nzAlign="center">11</td>
                  <td class="pading">
                    In case of transportation of vehicle by road/ self
                    propulsion, whether restricted to Railway freight charges.
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.TRANSPORTATION_OF_VEHICLE_BY_ROAD_SELF_PROPULSION ==
                        'Y'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                    <span
                      *ngIf="
                        checklistData.TRANSPORTATION_OF_VEHICLE_BY_ROAD_SELF_PROPULSION ==
                          'N' &&
                        checklistData.TRANSPORTATION_OF_VEHICLE_BY_ROAD_SELF_PROPULSION ==
                          'NA'
                      "
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.TRANSPORTATION_OF_VEHICLE_BY_ROAD_SELF_PROPULSION ==
                          'Y' &&
                        checklistData.TRANSPORTATION_OF_VEHICLE_BY_ROAD_SELF_PROPULSION ==
                          'NA'
                      "
                    ></span>
                    <span
                      *ngIf="
                        checklistData.TRANSPORTATION_OF_VEHICLE_BY_ROAD_SELF_PROPULSION ==
                        'N'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.TRANSPORTATION_OF_VEHICLE_BY_ROAD_SELF_PROPULSION ==
                          'Y' &&
                        checklistData.TRANSPORTATION_OF_VEHICLE_BY_ROAD_SELF_PROPULSION ==
                          'N'
                      "
                    ></span>
                    <span
                      *ngIf="
                        checklistData.TRANSPORTATION_OF_VEHICLE_BY_ROAD_SELF_PROPULSION ==
                        'NA'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                </tr>
                <tr>
                  <td nzAlign="center">12</td>
                  <td class="pading">
                    Whether Transfer order (full copy)/Service Certificate
                    submitted
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.IS_TRANSFER_ORDER_SERVICE_CERTIFICATE == 1
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                    <span
                      *ngIf="
                        checklistData.IS_TRANSFER_ORDER_SERVICE_CERTIFICATE == 0
                      "
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.IS_TRANSFER_ORDER_SERVICE_CERTIFICATE == 1
                      "
                    ></span>
                    <span
                      *ngIf="
                        checklistData.IS_TRANSFER_ORDER_SERVICE_CERTIFICATE == 0
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td nzAlign="center">13</td>
                  <td class="pading">Whether Assumption of charge submitted</td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.ASSUMPTION_OF_CHARGE_SUBMITTED == 'Y'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                    <span
                      *ngIf="
                        checklistData.ASSUMPTION_OF_CHARGE_SUBMITTED == 'N' &&
                        checklistData.ASSUMPTION_OF_CHARGE_SUBMITTED == 'NA'
                      "
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.ASSUMPTION_OF_CHARGE_SUBMITTED == 'Y' &&
                        checklistData.ASSUMPTION_OF_CHARGE_SUBMITTED == 'NA'
                      "
                    ></span>
                    <span
                      *ngIf="
                        checklistData.ASSUMPTION_OF_CHARGE_SUBMITTED == 'N'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="
                        checklistData.ASSUMPTION_OF_CHARGE_SUBMITTED == 'Y' &&
                        checklistData.ASSUMPTION_OF_CHARGE_SUBMITTED == 'N'
                      "
                    ></span>
                    <span
                      *ngIf="
                        checklistData.ASSUMPTION_OF_CHARGE_SUBMITTED == 'NA'
                      "
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                </tr>
                <tr>
                  <td nzAlign="center">14</td>
                  <td class="pading">Whether Last pay certificate submitted</td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="checklistData.LAST_PAY_CERTIFICATE_SUBMITTED == 1"
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                    <span
                      *ngIf="checklistData.LAST_PAY_CERTIFICATE_SUBMITTED == 0"
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="checklistData.LAST_PAY_CERTIFICATE_SUBMITTED == 1"
                    ></span>
                    <span
                      *ngIf="checklistData.LAST_PAY_CERTIFICATE_SUBMITTED == 0"
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td nzAlign="center">15</td>
                  <td class="pading">
                    Whether Bank Mandate from/cancelled cheque submitted
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="checklistData.BANK_MANDATE_CHEQUE_SUBMITTED == 1"
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                    <span
                      *ngIf="checklistData.BANK_MANDATE_CHEQUE_SUBMITTED == 0"
                    ></span>
                  </td>
                  <td style="border: 1px solid black; text-align: center">
                    <span
                      *ngIf="checklistData.BANK_MANDATE_CHEQUE_SUBMITTED == 1"
                    ></span>
                    <span
                      *ngIf="checklistData.BANK_MANDATE_CHEQUE_SUBMITTED == 0"
                      ><i nz-icon nzType="check" nzTheme="outline"></i
                    ></span>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <br />

            <!-- <div style="margin-top: 100px; font-size: 13px">
                <div nz-row>
                    <div nz-col nzSpan="15"></div>
    
                    <div nz-col nzSpan="9" style="text-align: center">
                        <div style="font-weight: bold">({{ NAME_HN }})</div>
                        <div>{{ POST_HN }}</div>
                        <div>{{ OFFICE_NAME_HN }}</div>
                        <div style="font-weight: bold">
                            ({{ SIGNNAME }})
                        </div>
                        <div>{{ POST }}</div>
                        <div>{{ OFFICE_NAME }}</div>
    
                    </div>
                </div>
            </div> -->
            <!-- <div>
                <p style="text-align:left"><b>(Jitendra R Chandekar)</b></p>
                <br>
                <p style="text-align:left"><b>ITO, G.P.B.S-II, MUMBAI</b></p>
            </div> -->

            <div style="margin-top: 70px; font-size: 13px">
              <div nz-row>
                <div nz-col nzSpan="15"></div>

                <div nz-col nzSpan="9" style="text-align: center">
                  <div style="font-weight: bold">({{ SIGNNAME }})</div>
                  <div>
                    {{ POST }}<br />
                    {{ OFFICE_NAME }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>
  </ng-template>
  <ng-template #printOrderModalFooter>
    <button nz-button nzType="default" (click)="printOrderModalCancel()">
      Close
    </button>

    <button
      nz-button
      nzType="default"
      [useExistingCss]="true"
      printSectionId="excel-table"
      ngxPrint
    >
      Print
    </button>

    <button
      nz-button
      nzType="primary"
      [nzLoading]="pdfDownload"
      (click)="openpdf()"
    >
      Download
    </button>
  </ng-template>
</nz-modal>
