<div>
  <div *ngIf="GARData != undefined || GARData != null">
    <form nz-form>
      <nz-spin [nzSpinning]="loadingRecords">
        <div nz-row [nzGutter]="10">
          <div nz-col nzXs="8" nzSm="8" nzMd="8" nzLg="8" nzXl="8" nzSpan="8">
            <div class="my-box">
              <div nz-row>
                <div nz-col nzSpan="24">
                  <nz-form-item>
                    <nz-form-label nzRequired nzNoColon
                      >Signature</nz-form-label
                    >
                    <nz-form-control>
                      <nz-select
                        name="userid"
                        [(ngModel)]="GARData.SIGNATURE_ID"
                        nzAllowClear
                        nzPlaceHolder="Choose Signature"
                        (ngModelChange)="signature($event)"
                      >
                        <nz-option
                          *ngFor="let user of Signaturearray"
                          [nzValue]="user.ID"
                          [nzLabel]="user.NAME"
                        >
                        </nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
              <!-- <div nz-row>
                <div
                  nz-col
                  nzXs="24"
                  nzSm="24"
                  nzMd="24"
                  nzLg="24"
                  nzXl="24"
                  nzSpan="24"
                >
                  <nz-form-item>
                    <nz-form-label nzRequired nzNoColon
                      >Bill Number</nz-form-label
                    >

                    <nz-form-control
                      nzHasFeedback
                      nzErrorTip="Bill no. is required"
                    >
                      <input
                        style="width: 100%"
                        name="BillNumber"
                        nz-input
                        placeholder="Enter bill number"
                        type="text"
                        [(ngModel)]="GARData.BILL_NO"
                      />
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div> -->
            </div>
          </div>

          <div nz-col nzXs="16" nzSm="16" nzMd="16" nzLg="16" nzXl="16">
            <div
              class="my-box"
              style="padding: 10px; height: 590px; overflow-y: scroll"
            >
              <div>
                <div
                  style="font-family: Arial, Helvetica, sans-serif !important"
                >
                  <div
                    style="
                      text-align: center;
                      margin-top: 20px;
                      margin-bottom: 10px;
                      font-size: 13px;
                    "
                  >
                    <b>G.A.R-23</b>
                    <p style="text-align: center">
                      (See Rule 91)<br />
                      <b>MEDICAL CHARGES REIMBURSEMENT BILL</b>
                    </p>
                  </div>
                  <div style="font-size: 14px !important">
                    <div nz-row [nzGutter]="10">
                      <div
                        nz-col
                        nzXs="18"
                        nzSm="18"
                        nzMd="18"
                        nzLg="18"
                        nzXl="18"
                        nzSpan="18"
                        style="text-align: left"
                      >
                        <p style="margin-bottom: 0px">
                          Bill NO :<b>{{ GARData.FILE_NUMBER }}</b>
                        </p>
                      </div>
                      <div
                        nz-col
                        nzXs="6"
                        nzSm="6"
                        nzMd="6"
                        nzLg="6"
                        nzXl="6"
                        nzSpan="6"
                        style="text-align: left"
                      >
                        <b>Dated:</b>
                      </div>
                    </div>
                    <p style="margin-bottom: 0px">
                      Ministry/Department/Office of DDO Admin Go. and S.G. for
                      the year <b>2023-2024 </b>
                    </p>
                    <p>Head of account-</p>
                    <table style="border: 1px solid black">
                      <tr style="border: 1px solid black">
                        <td style="border: 1px solid black; text-align: center">
                          Sr.No
                        </td>
                        <td style="border: 1px solid black; text-align: center">
                          Section of Establishment and name of the incumbent
                        </td>
                        <td style="border: 1px solid black; text-align: center">
                          Gross Claim
                        </td>
                        <td style="border: 1px solid black; text-align: center">
                          Recovery of Advance
                        </td>
                        <td style="border: 1px solid black; text-align: center">
                          Net Amount Payable
                        </td>
                        <td style="border: 1px solid black; text-align: center">
                          Remarks
                        </td>
                      </tr>
                      <tr style="border: 1px solid black">
                        <td style="border: 1px solid black; text-align: center">
                          1
                        </td>
                        <td style="border: 1px solid black; text-align: center">
                          2
                        </td>
                        <td style="border: 1px solid black; text-align: center">
                          3
                        </td>
                        <td style="border: 1px solid black; text-align: center">
                          4
                        </td>
                        <td style="border: 1px solid black; text-align: center">
                          5
                        </td>
                        <td style="border: 1px solid black; text-align: center">
                          6
                        </td>
                      </tr>
                      <tr style="border: 1px solid black">
                        <th
                          style="border: 1px solid black; text-align: center"
                        ></th>
                        <th style="border: 1px solid black; text-align: center">
                          {{ GARData.EMPLOYEE_NAME }} <br />{{
                            GARData.DESIGNATION
                          }}
                        </th>
                        <th
                          style="border: 1px solid black; text-align: center"
                        ></th>
                        <th
                          style="border: 1px solid black; text-align: center"
                        ></th>
                        <th style="border: 1px solid black; text-align: center">
                          {{ GARData.ADMISSIBLE_AMOUNT }}/-
                        </th>
                        <th
                          style="border: 1px solid black; text-align: center"
                        ></th>
                      </tr>
                    </table>
                    <p style="margin-top: 10px">
                      Net amount required for payment (in words)<br />
                      <b>(Rs.{{ amountinWords }}) </b>
                    </p>

                    <p style="margin-top: 10px">
                      1.&nbsp;Certified that I have satisfied myself that the
                      amount included in bilis drawn 1 month/2 months/ 3 months
                      previous to this date, with exception of those details
                      below (of which the total amount has been refunded by
                      deduction from this bill) have been disbursed to the Govt.
                      Servants therein named and their receipts taken in the
                      office of the bill or in a separate acquaintance roll.
                    </p>
                    <p>
                      2.&nbsp;Details of medical charges refunded<br />Section
                      of establishment and name of
                      incumbent&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Period&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      Amount
                    </p>
                    <p>
                      3.&nbsp;Certified that Essential Certificate, receipts
                      etc. are appended
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Received
                      Payment
                    </p>

                    <p style="text-align: right; margin-top: 80px">
                      <b
                        >Joint Commissioner of Income Tax<br />
                        (HQ) Personnel, Mumbai</b
                      >
                    </p>
                    <p>
                      Appropriation for <b>2022-2023</b><br />Station Mumbai
                    </p>
                    <p style="text-align: right; margin-top: 30px">
                      <b
                        >Income Tax Office<br />
                        {{ OFFICE_NAME }}</b
                      >
                    </p>
                    <p style="text-align: center">
                      <b>PAY AND ACCOUNTS OFFICER/CHEQUE DRAWING D.D.O.</b>
                    </p>
                    <p>Dated....................</p>
                    <p style="text-align: center">
                      For use in pay and accounts office<br />(Post Check)
                    </p>
                    <p>Admitted for RS.............................</p>
                    <p>Objected to RS.......................</p>
                    <div nz-row [nzGutter]="10">
                      <div
                        nz-col
                        nzXs="10"
                        nzSm="10"
                        nzMd="10"
                        nzLg="10"
                        nzXl="10"
                        nzSpan="10"
                      >
                        <p>Reason for objection:<b>JR./SR. Accountant</b></p>
                      </div>
                      <div
                        nz-col
                        nzXs="6"
                        nzSm="6"
                        nzMd="6"
                        nzLg="6"
                        nzXl="6"
                        nzSpan="6"
                        style="text-align: center"
                      >
                        <b style="text-align: center">Jr. AO</b>
                      </div>
                      <div
                        nz-col
                        nzXs="8"
                        nzSm="8"
                        nzMd="8"
                        nzLg="8"
                        nzXl="8"
                        nzSpan="8"
                        style="text-align: center"
                      >
                        <b style="text-align: center"
                          >Pay and Accounts Officer</b
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nz-spin>
    </form>
  </div>

  <div class="footer">
    <button
      type="button"
      nz-button
      class="ant-btn"
      style="margin-right: 8px"
      (click)="close()"
    >
      <span>Cancel</span>
    </button>

    <button
      type="button"
      class="ant-btn ant-btn-primary"
      nz-button
      (click)="Save()"
    >
      <span>Save & Preview</span>
    </button>
  </div>
</div>

<nz-modal
  [nzWidth]="getwidth()"
  [(nzVisible)]="printOrderModalVisible"
  nzMaskClosable="false"
  nzClosable="false"
  [nzContent]="printOrderModalContent"
  [nzTitle]="'Certificate'"
  (nzOnCancel)="printOrderModalCancel()"
  [nzFooter]="printOrderModalFooter"
>
  <ng-template #printOrderModalContent>
    <nz-spin [nzSpinning]="pdfDownload">
      <div style="height: 450px; overflow: auto">
        <div id="printOrderModal">
          <div>
            <div style="font-family: Arial, Helvetica, sans-serif !important">
              <div
                style="
                  text-align: center;
                  margin-top: 20px;
                  margin-bottom: 10px;
                  font-size: 13px;
                "
              >
                <b>G.A.R-23</b>
                <p style="text-align: center">
                  (See Rule 91)<br />
                  <b>MEDICAL CHARGES REIMBURSEMENT BILL</b>
                </p>
              </div>
              <div style="font-size: 14px !important">
                <div nz-row [nzGutter]="10">
                  <div
                    nz-col
                    nzXs="18"
                    nzSm="18"
                    nzMd="18"
                    nzLg="18"
                    nzXl="18"
                    nzSpan="18"
                    style="text-align: left"
                  >
                    <p style="margin-bottom: 0px">
                      Bill NO :<b>{{ GARData.FILE_NUMBER }}</b>
                    </p>
                  </div>
                  <div
                    nz-col
                    nzXs="6"
                    nzSm="6"
                    nzMd="6"
                    nzLg="6"
                    nzXl="6"
                    nzSpan="6"
                    style="text-align: left"
                  >
                    <b>Dated:</b>
                  </div>
                </div>
                <p style="margin-bottom: 0px">
                  Ministry/Department/Office of DDO Admin Go. and S.G. for the
                  year <b>2023-2024 </b>
                </p>
                <p>Head of account-</p>
                <table style="border: 1px solid black">
                  <tr style="border: 1px solid black">
                    <td style="border: 1px solid black; text-align: center">
                      Sr.No
                    </td>
                    <td style="border: 1px solid black; text-align: center">
                      Section of Establishment and name of the incumbent
                    </td>
                    <td style="border: 1px solid black; text-align: center">
                      Gross Claim
                    </td>
                    <td style="border: 1px solid black; text-align: center">
                      Recovery of Advance
                    </td>
                    <td style="border: 1px solid black; text-align: center">
                      Net Amount Payable
                    </td>
                    <td style="border: 1px solid black; text-align: center">
                      Remarks
                    </td>
                  </tr>
                  <tr style="border: 1px solid black">
                    <td style="border: 1px solid black; text-align: center">
                      1
                    </td>
                    <td style="border: 1px solid black; text-align: center">
                      2
                    </td>
                    <td style="border: 1px solid black; text-align: center">
                      3
                    </td>
                    <td style="border: 1px solid black; text-align: center">
                      4
                    </td>
                    <td style="border: 1px solid black; text-align: center">
                      5
                    </td>
                    <td style="border: 1px solid black; text-align: center">
                      6
                    </td>
                  </tr>
                  <tr style="border: 1px solid black">
                    <th
                      style="border: 1px solid black; text-align: center"
                    ></th>
                    <th style="border: 1px solid black; text-align: center">
                      {{ GARData.EMPLOYEE_NAME }} <br />{{
                        GARData.DESIGNATION
                      }}
                    </th>
                    <th
                      style="border: 1px solid black; text-align: center"
                    ></th>
                    <th
                      style="border: 1px solid black; text-align: center"
                    ></th>
                    <th style="border: 1px solid black; text-align: center">
                      {{ GARData.ADMISSIBLE_AMOUNT }} /-
                    </th>
                    <th
                      style="border: 1px solid black; text-align: center"
                    ></th>
                  </tr>
                </table>
                <p style="margin-top: 10px">
                  Net amount required for payment (in words)<br />
                  <b>(Rs.{{ amountinWords }}) </b>
                </p>

                <p style="margin-top: 10px">
                  1.&nbsp;Certified that I have satisfied myself that the amount
                  included in bilis drawn 1 month/2 months/ 3 months previous to
                  this date, with exception of those details below (of which the
                  total amount has been refunded by deduction from this bill)
                  have been disbursed to the Govt. Servants therein named and
                  their receipts taken in the office of the bill or in a
                  separate acquaintance roll.
                </p>
                <p>
                  2.&nbsp;Details of medical charges refunded<br />Section of
                  establishment and name of
                  incumbent&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Period&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  Amount
                </p>
                <p>
                  3.&nbsp;Certified that Essential Certificate, receipts etc.
                  are appended
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Received
                  Payment
                </p>

                <p style="text-align: right; margin-top: 80px">
                  <b
                    >Joint Commissioner of Income Tax<br />
                    (HQ) Personnel, Mumbai</b
                  >
                </p>
                <p>Appropriation for <b>2022-2023</b><br />Station Mumbai</p>
                <p style="text-align: right; margin-top: 30px">
                  <b
                    >Income Tax Office<br />
                    {{ OFFICE_NAME }}</b
                  >
                </p>
                <p style="text-align: center">
                  <b>PAY AND ACCOUNTS OFFICER/CHEQUE DRAWING D.D.O.</b>
                </p>
                <p>Dated....................</p>
                <p style="text-align: center">
                  For use in pay and accounts office<br />(Post Check)
                </p>
                <p>Admitted for RS.............................</p>
                <p>Objected to RS.......................</p>
                <div nz-row [nzGutter]="10">
                  <div
                    nz-col
                    nzXs="10"
                    nzSm="10"
                    nzMd="10"
                    nzLg="10"
                    nzXl="10"
                    nzSpan="10"
                  >
                    <p>Reason for objection:<b>JR./SR. Accountant</b></p>
                  </div>
                  <div
                    nz-col
                    nzXs="6"
                    nzSm="6"
                    nzMd="6"
                    nzLg="6"
                    nzXl="6"
                    nzSpan="6"
                    style="text-align: center"
                  >
                    <b style="text-align: center">Jr. AO</b>
                  </div>
                  <div
                    nz-col
                    nzXs="8"
                    nzSm="8"
                    nzMd="8"
                    nzLg="8"
                    nzXl="8"
                    nzSpan="8"
                    style="text-align: center"
                  >
                    <b style="text-align: center">Pay and Accounts Officer</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>
  </ng-template>

  <ng-template #printOrderModalFooter>
    <button nz-button nzType="default" (click)="printOrderModalCancel()">
      Close
    </button>

    <button
      nz-button
      nzType="default"
      [useExistingCss]="true"
      printSectionId="printOrderModal"
      ngxPrint
      [nzLoading]="pdfDownload"
    >
      Print
    </button>

    <button
      nz-button
      nzType="primary"
      [nzLoading]="pdfDownload"
      (click)="generatePDF()"
    >
      Download
    </button>
  </ng-template>
</nz-modal>
