export class DelayCondonationMaster {
  ID: number = 0;
  CLIENT_ID = 0;
  RELATION_WITH_PATIENT: any;
  NATURE_OF_TREATMENT: any;
  TREATMENT_START_DATE: any;
  TREATMENT_END_DATE: any;
  PATIENT_NAME: any;
  PATIENT_CGHS_BENEFICIERY_NO: any;
  IS_ADVANCE_TAKEN: any;
  ADVANCE_AMOUNT: any;
  ADVANCE_TAKEN_DATE: any;
  BILL_FILIING_DATE: any;
  BANK_NAME: any;
  BANK_ACCOUNT_NO: any;
  IFSC_CODE: any;
  MICR_CODE: any;
  TREATEMENT_TYPE: any;
  WARD_TYPE: any;
  EMPLOYEE_NAME: any;
  STATUS: boolean = true;
  EMPLOYEE_CODE: any;
  GRADE_PAY: any;
  OFFICE_NAME: any;
  DESIGNATION: any;
  LOCATION: any;
  DDO_OF_THE_OFFICIAL: any;
  BENEFICIARY_TYPE: string = '';
  CGHS_CARD_NO: any;
  CGHS_CARD_VALIDITY: any;
  EMP_ID: any;
  MOBILE_NO: any;
  EMAIL_ID: any;
  ADDRESS: any;

  CLAIM_REASON: any;
  RECEIVED_DATE: any;
  LETTER_DATE: any;
  EXPENCE_AMOUNT: any;
  CLAIM_FILLED_DATE: any;
  CLAIM_SUBMITTED_DATE: any;
  SIGNATURE_ID: any;
  PERMISSION_NO: any;
}
