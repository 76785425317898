export class LTCMaster {
  LTC_ID: any;
  ID: any;
  EMPLOYEE_NAME: any;
  EMPLOYEE_CODE: any;
  DESIGNATION: any;
  PAY_SCALE: any;
  HEADQUARTERS: any;
  NATURE_OF_LEAVE: any;
  IS_SANCTIONED: boolean = false;
  LEAVE_START_DATE: any;
  LEAVE_END_DATE: any;
  EMP_ID: any;
  NAME: any;
  STATUS: boolean = true;
  IS_ADVANCE_TAKEN: any;
  GRADE_PAY: any;
  OFFICE_NAME: any;

  LOCATION: any;
  DDO_OF_THE_OFFICIAL: any;
  BENEFICIARY_TYPE: string = '';

  GRADE_PAY_LEVEL: any;
  MOBILE_NO: any;
  EMAIL_ID: any;
  ADDRESS: any;

  INSPECTOR_ID: any;
  NAME_OF_FAMILY_MEMBER: any;
  AGE: any;
  RELATIONSHIP: any;
  NEW_ADDRESS: any;
  NEW_OFFICE_NAME: any;
  TRANSFER_ID: any;
  familyData: any = [];
  ADVANCED_AMOUNT: any;
  ADVANCE_TAKEN_DATE: any;

  TRAVEL_MODE_ID: any;
  STATION_NAME: any;
  TOTAL_AMOUNT: any;

  AMOUNT: any;
  SELF_DECLARATION: boolean = false;
  transportationData: any = [];
  transportationDataPersonal: any = [];
  journeyData: any = [];
  AMOUNT_OF_ADVANCE: any;
  BLOCK_YEAR: any;
  BILL_SUBMITTED_DATE: any;
  FILE_ID: any;
  LTC_STATUS: any = '';
  LTC_ASSUMPTION_CHARGE: any;
  LTC_LPC: any;
  LTC_MANDATE_FORM: any;
  LTC_SERVICE_CERTIFICATE: any;
  LTC_TICKET_BOARDING_PASS: any;
  LTC_TRANSPORTATION_BILLS: any;
  LTC_CONDONATION_OF_DELAY: any;
  BLOCK_YEAR_ID: any;
  PRIOR_LEAVE_APPLICATION_URL: any;
  LEAVE_SACTIONED_ORDER_URL: any;
  LATEST_PAYMENT_SLIP: any;
  DEPENDENCY_CERTIFICATE: any;
  WEBSITE_SCREENSHOT: any;
  SALUTATION:any;
  DESTIONATION_REMARK:any;
  IS_DESTINATION_EDIT:boolean=false;
  // ACTION_DATE:any=null;
  // ACTION_REMARK:any=null;
  // ACTION_STATUS:any='';
}
