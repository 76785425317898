
export class journeyP {
    ID:any
    PLACE_FROM: any
    PLACE_TO: any
    MODE_OF_CONVEYANCE_ID: any
    ENTITLED_CLASS_ID: any
    TRAVELLED_CLASS_ID: any
    NO_OF_FAIRS: any
    FAIR_PAID: any
    LTC_ID: any
    JOURNEY_PARTICULAR_CLAIMED_AMOUNT=0;
}