<div>
  <nz-steps [nzCurrent]="current" style="margin: 0px 50px">
    <nz-step nzTitle="Employee Details"></nz-step>
    <nz-step nzTitle="Travel Details"></nz-step>
    <nz-step nzTitle="Documents Details"></nz-step>
    <nz-step nzTitle="Checklist"></nz-step>
  </nz-steps>

  <div class="steps-content" style="overflow-y: scroll; height: 510px">
    <!-- Applicant Info -->
    <div *ngIf="current == 0">
      <nz-spin [nzSpinning]="isSpinning">
        <div>
          <form nz-form #ITCMasterPages="ngForm">
            <div nz-row>
              <div nz-col nzSpan="4">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>Salutation</nz-form-label>

                  <nz-form-control nzErrorTip="Select Salutation">
                    <nz-input-group>
                      <nz-select
                        name="SALUTATION"
                        style="width: 100%"
                        nzPlaceHolder="Salutation "
                        [(ngModel)]="data.SALUTATION"
                      >
                        <nz-option nzValue="Shri" nzLabel="Shri"></nz-option>
                        <nz-option nzValue="Smt" nzLabel="Smt"></nz-option>
                      </nz-select>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="8">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Name of Officer/Official</nz-form-label
                  >

                  <nz-form-control nzErrorTip="Please Select Employee Name">
                    <nz-select
                      [nzOptionHeightPx]="60"
                      nzShowSearch
                      nzAllowClear
                      nzPlaceHolder="Select Employee"
                      (ngModelChange)="filterEmpData($event)"
                      name="empID"
                      [(ngModel)]="data.EMP_ID"
                      [nzLoading]="empLoader"
                      required
                    >
                      <nz-option
                        *ngFor="let role of employee"
                        [nzValue]="role.ID"
                        nzLabel="{{ role.NAME }} - {{ role.EMPLOYEE_CODE }}"
                        nzCustomContent
                      >
                        <div style="background-color: #fffcfc; padding: 5px">
                          <div nz-row>
                            <div
                              nz-col
                              nzSpan="24"
                              style="width: 100%; color: black; font-size: 14px"
                            >
                              <b
                                >{{ role.NAME }}
                                <small style="position: absolute; right: 0px">{{
                                  role.EMPLOYEE_CODE
                                }}</small></b
                              >
                            </div>

                            <div nz-col nzSpan="24" style="font-size: 11px">
                              <div nz-row>
                                <div
                                  style="color: #b30062; font-weight: bold"
                                  nz-col
                                  nzSpan="12"
                                >
                                  {{ role.OFFICE_NAME }}
                                </div>

                                <div nz-col nzSpan="1"></div>

                                <div
                                  style="color: #2f08c0; font-weight: bold"
                                  nz-col
                                  nzSpan="11"
                                >
                                  {{ role.DESIGNATION }}
                                </div>
                              </div>
                            </div>

                            <div
                              nz-col
                              nzSpan="24"
                              style="font-size: 14px; font-weight: 500"
                            >
                              {{ role.DDO_OF_THE_OFFICIAL }}
                            </div>
                          </div>
                        </div>
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="1" *ngIf="!data.EMP_ID">
                <nz-form-item>
                  <nz-form-label nzNoColon></nz-form-label>
                  <nz-form-control>
                    <button nz-button nzType="primary" (click)="add()">
                      <i nz-icon nzType="plus"></i>
                    </button>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="1" *ngIf="data.EMP_ID">
                <nz-form-item>
                  <nz-form-label nzNoColon></nz-form-label>
                  <nz-form-control>
                    <button nz-button nzType="primary" (click)="edit(data)">
                      <i nz-icon nzType="edit"></i>
                    </button>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="6">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Designation</nz-form-label
                  >
                  <nz-form-control nzErrorTip="Enter Designation">
                    <input
                      nz-input
                      maxlength="256"
                      autocomplete="off"
                      name="Designation"
                      type="text"
                      [(ngModel)]="data.DESIGNATION"
                      placeHolder="Enter The Designation"
                      required
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="5">
                <nz-form-item>
                  <nz-form-label nzNoColon>Employee Code</nz-form-label>

                  <nz-form-control nzErrorTip="Enter Correct Employee Code">
                    <input
                      nz-input
                      maxlength="16"
                      autocomplete="off"
                      name="EmployeeCode"
                      [(ngModel)]="data.EMPLOYEE_CODE"
                      type="text"
                      placeHolder="Enter Employee Code"
                      required
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="10">
                <nz-form-item>
                  <nz-form-label nzNoColon>DDO Of The Official</nz-form-label>
                  <nz-form-control
                    nzErrorTip="Enter Correct DDO Of The Official"
                  >
                    <input
                      nz-input
                      maxlength="256"
                      autocomplete="off"
                      name="DDOOfficial"
                      type="text"
                      [(ngModel)]="data.DDO_OF_THE_OFFICIAL"
                      placeHolder="Enter The DDO Of The Official"
                      required
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="8">
                <nz-form-item>
                  <nz-form-label nzNoColon>Office Name</nz-form-label>
                  <nz-form-control nzErrorTip="Enter Correct Office Name">
                    <input
                      nz-input
                      autocomplete="off"
                      maxlength="256"
                      name="Office Name"
                      type="text"
                      [(ngModel)]="data.OFFICE_NAME"
                      placeHolder="Enter The Office Name"
                      required
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="6">
                <nz-form-item>
                  <nz-form-label nzNoColon>Head Quarter Name</nz-form-label>
                  <nz-form-control nzErrorTip="Please Enter Head Quater Name">
                    <input
                      nz-input
                      name="head"
                      maxlength="100"
                      [(ngModel)]="data.HEADQUARTERS"
                      placeHolder="Enter Head Quater Name"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="5">
                <nz-form-item>
                  <nz-form-label nzNoColon>Basic Pay</nz-form-label>

                  <nz-form-control nzErrorTip="Enter Correct Basic Pay">
                    <nz-input-group nzAddOnBefore="₹">
                      <input
                        nz-input
                        maxlength="8"
                        autocomplete="off"
                        (keypress)="omit($event)"
                        [(ngModel)]="data.GRADE_PAY"
                        name="gradePay"
                        type="text"
                        placeHolder="Enter Basic Pay"
                        required
                      />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="5">
                <nz-form-item>
                  <nz-form-label nzNoColon>Grade Pay Level </nz-form-label>
                  <nz-input-group>
                    <nz-select
                      name="gradepaylevel"
                      style="width: 100%"
                      nzShowSearch
                      nzPlaceHolder="Select Grade Pay Level"
                      [(ngModel)]="data.GRADE_PAY_LEVEL"
                    >
                      <nz-option
                        [nzValue]="'Level 1'"
                        [nzLabel]="'Level 1'"
                      ></nz-option>
                      <nz-option
                        [nzValue]="'Level 2'"
                        [nzLabel]="'Level 2'"
                      ></nz-option>
                      <nz-option
                        [nzValue]="'Level 3'"
                        [nzLabel]="'Level 3'"
                      ></nz-option>
                      <nz-option
                        [nzValue]="'Level 4'"
                        [nzLabel]="'Level 4'"
                      ></nz-option>
                      <nz-option
                        [nzValue]="'Level 5'"
                        [nzLabel]="'Level 5'"
                      ></nz-option>

                      <nz-option
                        [nzValue]="'Level 6'"
                        [nzLabel]="'Level 6'"
                      ></nz-option>
                      <nz-option
                        [nzValue]="'Level 7'"
                        [nzLabel]="'Level 7'"
                      ></nz-option>
                      <nz-option
                        [nzValue]="'Level 8'"
                        [nzLabel]="'Level 8'"
                      ></nz-option>
                      <nz-option
                        [nzValue]="'Level 9'"
                        [nzLabel]="'Level 9'"
                      ></nz-option>
                      <nz-option
                        [nzValue]="'Level 10'"
                        [nzLabel]="'Level 10'"
                      ></nz-option>
                      <nz-option
                        [nzValue]="'Level 11'"
                        [nzLabel]="'Level 11'"
                      ></nz-option>
                      <nz-option
                        [nzValue]="'Level 12'"
                        [nzLabel]="'Level 12'"
                      ></nz-option>
                      <nz-option
                        [nzValue]="'Level 13'"
                        [nzLabel]="'Level 13'"
                      ></nz-option>
                      <nz-option
                        [nzValue]="'Level 14'"
                        [nzLabel]="'Level 14'"
                      ></nz-option>
                      <nz-option
                        [nzValue]="'Level 15'"
                        [nzLabel]="'Level 15'"
                      ></nz-option>
                      <nz-option
                        [nzValue]="'Level 16'"
                        [nzLabel]="'Level 16'"
                      ></nz-option>
                      <nz-option
                        [nzValue]="'Level 17'"
                        [nzLabel]="'Level 17'"
                      ></nz-option>
                    </nz-select>
                  </nz-input-group>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="8">
                <nz-form-item>
                  <nz-form-label nzNoColon>Email ID </nz-form-label>
                  <nz-form-control nzErrorTip="Please Enter Valid Email ID">
                    <input
                      nz-input
                      name="commiteeemail"
                      maxlength="256"
                      [pattern]="emailpattern"
                      [(ngModel)]="data.EMAIL_ID"
                      placeHolder="Enter Email ID"
                      required
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="6">
                <nz-form-item>
                  <nz-form-label nzNoColon> Mobile Number </nz-form-label>
                  <nz-form-control
                    nzErrorTip="Please Enter Valid Mobile Number"
                  >
                    <nz-input-group nzAddOnBefore="(+91)">
                      <input
                        nz-input
                        name="commiteemobileno"
                        [pattern]="mobpattern"
                        [(ngModel)]="data.MOBILE_NO"
                        maxlength="10"
                        minlength="10"
                        (keypress)="omit($event)"
                        placeHolder="Enter Mobile Number"
                        required
                      />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="24">
                <div nz-row class="my-box">
                  <div nz-col nzSpan="8">
                    <nz-form-item>
                      <nz-form-label nzRequired nzNoColon
                        >Nature Of Leave</nz-form-label
                      >

                      <nz-form-control>
                        <nz-select
                          name="NATURE_OF_LEAVE"
                          nzShowSearch
                          nzAllowClear
                          nzPlaceHolder="Select Nature Of Leave"
                          [(ngModel)]="data.NATURE_OF_LEAVE"
                          style="width: 100%"
                        >
                          <nz-option
                            nzLabel="Casual Leave"
                            nzValue="Casual Leave"
                          ></nz-option>
                          <nz-option
                            nzLabel="Medical Leave"
                            nzValue="Medical Leave"
                          ></nz-option>
                          <nz-option
                            nzLabel="Half Day"
                            nzValue="Half Day"
                          ></nz-option>
                          <nz-option
                            nzLabel="Study Leave"
                            nzValue="Study Leave"
                          ></nz-option>
                          <nz-option
                            nzLabel="Child Care Leave"
                            nzValue="Child Care Leave"
                          ></nz-option>
                          <nz-option
                            nzLabel="Earn Leave"
                            nzValue="Earn Leave"
                          ></nz-option>
                          <nz-option
                            nzLabel="Restricted holiday"
                            nzValue="Restricted holiday"
                          ></nz-option>
                          <nz-option
                            nzLabel="Other"
                            nzValue="Other"
                          ></nz-option>
                        </nz-select>
                      </nz-form-control>
                    </nz-form-item>
                  </div>

                  <div nz-col nzSpan="6">
                    <nz-form-item>
                      <nz-form-label nzRequired nzNoColon
                        >Leave From Date</nz-form-label
                      >
                      <nz-form-control
                        nzErrorTip="Please Select Leave From Date"
                      >
                        <nz-form-control nzErrorTip="Please Select Date">
                          <nz-date-picker
                            name="leavestartdate"
                            [(ngModel)]="data.LEAVE_START_DATE"
                            style="width: 100%"
                            nzFormat="yyyy-MM-dd"
                            [nzDisabledDate]="disabledStartDate"
                            (ngModelChange)="leaveEndDate()"
                          ></nz-date-picker>
                        </nz-form-control>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                  <div nz-col nzSpan="6">
                    <nz-form-item>
                      <nz-form-label nzRequired nzNoColon
                        >Leave To Date</nz-form-label
                      >
                      <nz-form-control nzErrorTip="Please Select Leave To Date">
                        <nz-form-control nzErrorTip="Please Select Date">
                          <nz-date-picker
                            name="leaveenddate"
                            [(ngModel)]="data.LEAVE_END_DATE"
                            style="width: 100%"
                            nzFormat="yyyy-MM-dd"
                            [nzDisabledDate]="disabledEndDate"
                            (ngModelChange)="leaveStartDate()"
                          ></nz-date-picker>
                        </nz-form-control>
                      </nz-form-control>
                    </nz-form-item>
                  </div>

                  <div nz-col nzSpan="4">
                    <nz-form-item>
                      <nz-form-label nzNoColon>Is Sanctioned?</nz-form-label>
                      <nz-form-control>
                        <nz-switch
                          name="sanction"
                          nzCheckedChildren="Yes"
                          nzUnCheckedChildren="No"
                          [(ngModel)]="data.IS_SANCTIONED"
                          style="width: 100%"
                        ></nz-switch>
                      </nz-form-control>
                    </nz-form-item>
                  </div>

                  <div nz-col nzSpan="4">
                    <nz-form-item>
                      <nz-form-label nzRequired nzNoColon
                        >Block Year</nz-form-label
                      >

                      <nz-form-control nzErrorTip="Select Block Year">
                        <nz-input-group>
                          <nz-select
                            name="BLOCKYEAR"
                            style="width: 100%"
                            nzShowSearch
                            nzPlaceHolder="Block Year "
                            [(ngModel)]="data.BLOCK_YEAR_ID"
                          >
                            <nz-option
                              *ngFor="let year of blockYearList"
                              [nzValue]="year.ID"
                              [nzLabel]="year.YEAR"
                            ></nz-option>
                          </nz-select>
                        </nz-input-group>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <br />

          <nz-divider
            style="font-weight: bold"
            nzText="Particulars of members of family in respect of whom the L.T.C. has been claimed"
          >
          </nz-divider>

          <form nz-form #relation="ngForm">
            <div nz-row class="my-box">
              <div nz-col nzSpan="8">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Name of Family Member</nz-form-label
                  >

                  <nz-form-control
                    nzErrorTip="Please Enter Name of Family Member"
                  >
                    <input
                      nz-input
                      name="NAME_OF_FAMILY_MEMBER"
                      maxlength="256"
                      [(ngModel)]="editrelation.NAME_OF_FAMILY_MEMBER"
                      placeHolder="Enter Name of Family Member"
                      required
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="3">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon> Age </nz-form-label>
                  <nz-form-control nzErrorTip="Please Enter Age">
                    <input
                      nz-input
                      name="AGE"
                      (keypress)="omit($event)"
                      [(ngModel)]="editrelation.AGE"
                      placeHolder="Enter Age"
                      maxlength="3"
                      required
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="7">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Relationship with the Govt. Servant
                  </nz-form-label>
                  <nz-input-group>
                    <nz-select
                      name="RELATIONSHIP"
                      style="width: 100%"
                      nzShowSearch
                      nzPlaceHolder="Select Relationship "
                      [(ngModel)]="editrelation.RELATIONSHIP"
                    >
                      <nz-option nzLabel="Self" nzValue="Self"></nz-option>

                      <nz-option nzLabel="Mother" nzValue="Mother"></nz-option>
                      <nz-option nzLabel="Father" nzValue="Father"></nz-option>
                      <nz-option
                        nzLabel="Brother"
                        nzValue="Brother"
                      ></nz-option>
                      <nz-option nzLabel="Sister" nzValue="Sister"></nz-option>
                      <nz-option
                        nzLabel="Daughter"
                        nzValue="Daughter"
                      ></nz-option>
                      <nz-option nzLabel="Son" nzValue="Son"></nz-option>
                      <nz-option nzLabel="Wife" nzValue="Wife"></nz-option>
                      <nz-option
                        nzLabel="Husband"
                        nzValue="Husband"
                      ></nz-option>
                      <nz-option
                        nzLabel="Father-In-Law"
                        nzValue="Father-In-Law"
                      ></nz-option>
                      <nz-option
                        nzLabel="Mother-In-Law"
                        nzValue="Mother-In-Law"
                      ></nz-option>
                      <nz-option
                        nzLabel="Grand-Mother"
                        nzValue="Grand-Mother"
                      ></nz-option>
                      <nz-option
                        nzLabel="Grand-Father"
                        nzValue="Grand-Father"
                      ></nz-option>
                    </nz-select>
                  </nz-input-group>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="3">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>Gender </nz-form-label>
                  <nz-input-group>
                    <nz-select
                      name="GENDER"
                      style="width: 100%"
                      nzShowSearch
                      nzPlaceHolder="Gender "
                      [(ngModel)]="editrelation.GENDER"
                    >
                      <nz-option nzLabel="Male" nzValue="Male"></nz-option>
                      <nz-option nzLabel="Female" nzValue="Female"></nz-option>
                    </nz-select>
                  </nz-input-group>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="3">
                <nz-form-item style="margin-top: 30px">
                  <nz-form-label nzNoColon></nz-form-label>

                  <button
                    nz-button
                    nzType="primary"
                    (click)="addData(true, relation)"
                    nzJustify="end"
                  >
                    <i nz-icon nzType="plus"></i>Add
                  </button>
                </nz-form-item>
              </div>
            </div>
          </form>
          <!-- <nz-table class="my-scroll1 no-wrap1" nzBordered #datatable nzShowSizeChanger [nzData]="relationdaa"
          [nzFrontPagination]="false" [nzLoading]="loadingRecords" [nzSize]="'small'"
          [nzScroll]="{ x: '800px', y: '420px' }"> -->

          <div nz-row>
            <div nz-col nzSpan="24">
              <nz-table
                class="my-scroll no-wrap1"
                nzBordered
                #retlatioantable
                nzShowSizeChanger
                [nzFrontPagination]="false"
                [nzLoading]="loadingRecords"
                [nzSize]="'small'"
                [nzScroll]="{ x: '1100px', y: '420px' }"
                [nzData]="relationdata"
                [nzTotal]="totalRecords"
                [(nzPageIndex)]="pageIndex"
                [(nzPageSize)]="pageSize"
              >
                <thead>
                  <tr>
                    <th
                      nzWidth="10px"
                      style="font-weight: bold; text-align: center"
                    >
                      Action
                    </th>
                    <th
                      nzWidth="40px"
                      style="font-weight: bold; text-align: center"
                    >
                      Name of Family Member
                    </th>
                    <th
                      nzWidth="10px"
                      style="font-weight: bold; text-align: center"
                    >
                      Age
                    </th>
                    <th
                      nzWidth="40px"
                      style="font-weight: bold; text-align: center"
                    >
                      Relationship with the Govt Servant
                    </th>
                    <th
                      nzWidth="40px"
                      style="font-weight: bold; text-align: center"
                    >
                      Gender
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    style="white-space: pre-line"
                    *ngFor="let data of retlatioantable.data; let i = index"
                  >
                    <td style="text-align: center">
                      <span
                        style="cursor: pointer"
                        nz-popconfirm
                        nzPopconfirmTitle="Are you sure want delete?"
                        nzPopconfirmPlacement="top"
                        (nzOnConfirm)="confirmDeleterelation(data, i)"
                        (nzOnCancel)="cancel()"
                      >
                        <span
                          nz-icon
                          nzType="delete"
                          nzTheme="twotone"
                          nz-tooltip
                          nzTooltipTitle="Delete"
                        >
                        </span>
                      </span>
                      &nbsp;
                      <a
                        (click)="edit1(data, i)"
                        nz-tooltip
                        nzTooltipTitle="Edit"
                      >
                        <span
                          nz-icon
                          nzType="edit"
                          nzTheme="twotone"
                          style="margin-top: 10px"
                        ></span>
                      </a>
                    </td>

                    <td style="text-align: center">
                      {{ relationdata[i]["NAME_OF_FAMILY_MEMBER"] }}
                    </td>
                    <td style="text-align: center">
                      {{ relationdata[i]["AGE"] }}
                    </td>
                    <td style="text-align: center">
                      {{ relationdata[i]["RELATIONSHIP"] }}
                    </td>
                    <td style="text-align: center">
                      {{ relationdata[i]["GENDER"] }}
                    </td>

                    <!-- <td style="text-align: center;">
                            {{data['LAYOUT_NAME']}}
                          </td>
                          <td style="text-align: center;">{{data['DEFAULT_RATE']}}</td> -->
                  </tr>
                </tbody>
              </nz-table>
            </div>
          </div>
        </div>
      </nz-spin>
    </div>
    <!-- Applicant Info -->

    <!-- Travel Details -->
    <div *ngIf="current == 1">
      <nz-divider
        style="font-weight: bold"
        nzText="Details of Journeys(s) performed by Government Servant and the members of his/her Family"
      ></nz-divider>

      <div nz-row>
        <div nz-col nzSpan="24" style="text-align: right">
          <button
            nz-button
            nzType="primary"
            (click)="addform7()"
            nzJustify="end"
          >
            <i nz-icon nzType="plus"></i>Add
          </button>
        </div>
      </div>

      <div nz-row>
        <div nz-col nzSpan="24">
          <nz-table
            class="my-scroll no-wrap1"
            nzBordered
            #dataxfdstable
            nzShowSizeChanger
            [nzFrontPagination]="false"
            [nzLoading]="loadingRecords"
            [nzSize]="'small'"
            [nzScroll]="{ x: '2000px', y: '420px' }"
            [nzData]="formdata7"
            [nzTotal]="totalRecords"
            [(nzPageIndex)]="pageIndex"
            [(nzPageSize)]="pageSize"
          >
            <thead>
              <tr>
                <th
                  nzWidth="10px"
                  style="text-align: center; font-weight: bold"
                >
                  Action
                </th>
                <th
                  nzWidth="12px"
                  style="text-align: center; font-weight: bold"
                >
                  From Date
                </th>
                <th
                  nzWidth="15px"
                  style="text-align: center; font-weight: bold"
                >
                  Journey From
                </th>
                <th
                  nzWidth="12px"
                  style="text-align: center; font-weight: bold"
                >
                  To Date
                </th>
                <th
                  nzWidth="15px"
                  style="text-align: center; font-weight: bold"
                >
                  Journey To
                </th>
                <th
                  nzWidth="15px"
                  style="text-align: center; font-weight: bold"
                >
                  Travel Mode
                </th>
                <th
                  nzWidth="15px"
                  style="text-align: center; font-weight: bold"
                >
                  Class of Mode
                </th>
                <th
                  nzWidth="15px"
                  style="text-align: center; font-weight: bold"
                >
                  No of Fairs
                </th>
                <th
                  nzWidth="15px"
                  style="text-align: center; font-weight: bold"
                >
                  Distance of Road(Kms.)
                </th>
                <th
                  nzWidth="15px"
                  style="text-align: center; font-weight: bold"
                >
                  Fair Paid claimed(₹)
                </th>
                <th
                  nzWidth="15px"
                  style="text-align: center; font-weight: bold"
                >
                  Fair Paid Admisible(₹)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                style="white-space: pre-line"
                *ngFor="let data of dataxfdstable.data"
              >
                <td style="text-align: center">
                  <span
                    style="cursor: pointer"
                    nz-popconfirm
                    nzPopconfirmTitle="Are you sure want delete?"
                    nzPopconfirmPlacement="top"
                    (nzOnConfirm)="deleteConfirm(data)"
                    (nzOnCancel)="deleteCancel()"
                  >
                    <span
                      nz-icon
                      nzType="delete"
                      nzTheme="twotone"
                      nz-tooltip
                      nzTooltipTitle="Delete"
                    >
                    </span>
                  </span>
                  &nbsp;
                  <a (click)="editform7(data)" nz-tooltip nzTooltipTitle="Edit">
                    <span
                      nz-icon
                      nzType="edit"
                      nzTheme="twotone"
                      style="margin-top: 10px"
                    ></span>
                  </a>
                </td>
                <td style="text-align: center; white-space: pre-line">
                  {{ data.FROM_DATETIME }}
                </td>
                <td style="text-align: center; white-space: pre-line">
                  {{ data.DEPARTURE_FROM }}
                </td>
                <td style="text-align: center; white-space: pre-line">
                  {{ data.TO_DATETIME }}
                </td>
                <td style="text-align: center; white-space: pre-line">
                  {{ data.ARRIVAL_TO }}
                </td>
                <td style="text-align: center; white-space: pre-line">
                  {{ data.TRAVEL_CLASS_NAME }}
                </td>
                <td style="text-align: center; white-space: pre-line">
                  {{ data.TRAVEL_MODE_NAME }}
                </td>
                <td style="text-align: center; white-space: pre-line">
                  {{ data.NO_OF_FAIRS }}
                </td>
                <td style="text-align: center; white-space: pre-line">
                  {{ data.DISTANCE_BY_ROAD }}
                </td>
                <td style="text-align: center; white-space: pre-line">
                  {{ data.JOURNEY_CLAIMED_AMOUNT }}
                </td>
                <td style="text-align: center; white-space: pre-line">
                  {{ data.FAIR_PAID }}
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </div>
      <br />
      <div nz-row class="my-box">
        <div nz-col nzSpan="6">
          <nz-form-item>
            <nz-form-label nzNoColon>Is Advance Taken? </nz-form-label>
            <nz-form-control>
              <nz-switch
                name="advan"
                (ngModelChange)="switchChange1($event)"
                nzCheckedChildren="Yes"
                nzUnCheckedChildren="No"
                [(ngModel)]="data.IS_ADVANCE_TAKEN"
              ></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="6" *ngIf="data.IS_ADVANCE_TAKEN">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>Advance Amount </nz-form-label>
            <nz-form-control nzHasFeedback nzErrorTip="Please Enter Amount">
              <nz-input-group nzAddOnBefore="₹">
                <input
                  nz-input
                  name="Advanceamount"
                  placeHolder="Enter Advance Amount"
                  style="width: 100%"
                  maxlength="8"
                  (keypress)="omit($event)"
                  [(ngModel)]="data.AMOUNT_OF_ADVANCE"
                  required
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <br />
      <nz-divider
        style="font-weight: bold; white-space: pre"
        nzText="Particulars of Journey(s) for which higher class of accommodation than the one to which the
      Goverment Servant is entitled, was used ( Sanction No. and date to be given)"
      ></nz-divider>

      <div nz-row>
        <div nz-col nzSpan="24" style="text-align: right">
          <button
            nz-button
            nzType="primary"
            (click)="addform3()"
            nzJustify="end"
          >
            <i nz-icon nzType="plus"></i>Add
          </button>
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="24">
          <nz-table
            class="my-scroll no-wrap1"
            nzBordered
            #dataxfduuuustable
            nzShowSizeChanger
            [nzFrontPagination]="false"
            [nzLoading]="loadingRecords"
            [nzSize]="'small'"
            [nzScroll]="{ x: '1800px', y: '420px' }"
            [nzData]="formdata3"
            [nzTotal]="totalRecords"
            [(nzPageIndex)]="pageIndex"
            [(nzPageSize)]="pageSize"
          >
            <thead>
              <tr>
                <th nzWidth="5px" nzAlign="center">
                  <p style="font-weight: bold">Action</p>
                </th>

                <th
                  nzWidth="13px"
                  style="text-align: center; font-weight: bold"
                  nzColumnKey="{{ 'PLACE_FROM' }}"
                >
                  From Place
                </th>

                <th
                  nzWidth="13px"
                  style="text-align: center; font-weight: bold"
                  nzColumnKey="{{ 'PLACE_TO' }}"
                >
                  To Place
                </th>

                <th
                  nzColumnKey="{{ MODE_OF_CONVEYANCE_NAME }}"
                  nzWidth="13px"
                  style="text-align: center; font-weight: bold"
                >
                  Mode Of Conveyance
                </th>

                <th
                  nzWidth="9px"
                  style="text-align: center; font-weight: bold"
                  nzColumnKey="{{ 'ENTITLED_CLASS_NAME' }}"
                >
                  Class To which Entitled
                </th>
                <th
                  nzWidth="9px"
                  style="text-align: center; font-weight: bold"
                  nzColumnKey="{{ 'TRAVELLED_CLASS_CLASS_NAME' }}"
                >
                  Class Actually Travelled
                </th>
                <th
                  nzWidth="8px"
                  style="text-align: center; font-weight: bold"
                  nzColumnKey="{{ 'NO_OF_FAIRS' }}"
                >
                  No. Of Fairs(No.s)
                </th>
                <th
                  nzWidth="8px"
                  style="text-align: center; font-weight: bold"
                  nzColumnKey="{{ 'FAIR_PAID' }}"
                >
                  Fair Paid(₹)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                style="white-space: pre-line"
                *ngFor="let data of dataxfduuuustable.data"
              >
                <td style="text-align: center">
                  <span
                    style="cursor: pointer"
                    nz-popconfirm
                    nzPopconfirmTitle="Are you sure want delete?"
                    nzPopconfirmPlacement="top"
                    (nzOnConfirm)="deleteConfirmparticular(data)"
                    (nzOnCancel)="deleteCancel()"
                  >
                    <span
                      nz-icon
                      nzType="delete"
                      nzTheme="twotone"
                      nz-tooltip
                      nzTooltipTitle="Delete"
                    >
                    </span> </span
                  >&nbsp;
                  <a (click)="editform3(data)" nz-tooltip nzTooltipTitle="Edit">
                    <span
                      nz-icon
                      nzType="edit"
                      nzTheme="twotone"
                      style="margin-top: 10px"
                    ></span>
                  </a>
                </td>
                <!-- <td style="text-align: center;white-space: pre-line; "> {{ getTimeIn12Hour(data["DATE"]) | date: "hh:mm a"
                }}</td> -->
                <td style="text-align: center; white-space: pre-line">
                  {{ data["PLACE_FROM"] }}
                </td>
                <td style="text-align: center; white-space: pre-line">
                  {{ data["PLACE_TO"] }}
                </td>
                <td style="text-align: center; white-space: pre-line">
                  {{ data["MODE_OF_CONVEYANCE_NAME"] }}
                </td>
                <td style="text-align: center; white-space: pre-line">
                  {{ data["ENTITLED_CLASS_NAME"] }}
                </td>
                <td style="text-align: center; white-space: pre-line">
                  {{ data["TRAVELLED_CLASS_CLASS_NAME"] }}
                </td>
                <td style="text-align: center; white-space: pre-line">
                  {{ data["NO_OF_FAIRS"] }}
                </td>
                <td style="text-align: center; white-space: pre-line">
                  {{ data["FAIR_PAID"] }}
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </div>

      <br />
      <nz-divider
        style="font-weight: bold"
        nzText="Particulars of Journey(s) Performed by Road between places Connected by Rail"
      ></nz-divider>

      <div nz-row>
        <div nz-col nzSpan="24" style="text-align: right">
          <button
            nz-button
            nzType="primary"
            (click)="addform1()"
            nzJustify="end"
          >
            <i nz-icon nzType="plus"></i>Add
          </button>
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="24">
          <nz-table
            class="my-scroll no-wrap1"
            nzBordered
            #datatablefare
            nzShowSizeChanger
            [nzFrontPagination]="false"
            [nzLoading]="loadingRecords"
            [nzSize]="'small'"
            [nzScroll]="{ x: '910px', y: '420px' }"
            [nzData]="formdata1"
            [nzTotal]="totalRecords"
            [(nzPageIndex)]="pageIndex"
            [(nzPageSize)]="pageSize"
          >
            <thead>
              <tr>
                <th
                  nzWidth="15px"
                  style="font-weight: bold; text-align: center"
                >
                  Action
                </th>
                <th
                  nzWidth="50px"
                  style="font-weight: bold; text-align: center"
                >
                  From Place
                </th>
                <th
                  nzWidth="50px"
                  style="font-weight: bold; text-align: center"
                >
                  To Place
                </th>
                <th
                  nzWidth="25px"
                  style="font-weight: bold; text-align: center"
                >
                  Class To Entitled
                </th>
                <th
                  nzWidth="25px"
                  style="font-weight: bold; text-align: center"
                >
                  Fare Paid(₹)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                style="white-space: pre-line"
                *ngFor="let data of datatablefare.data"
              >
                <td style="text-align: center">
                  <span
                    style="cursor: pointer"
                    nz-popconfirm
                    nzPopconfirmTitle="Are you sure want delete?"
                    nzPopconfirmPlacement="top"
                    (nzOnConfirm)="deleteConfirmlitcroad(data)"
                    (nzOnCancel)="deleteCancel()"
                  >
                    <span
                      nz-icon
                      nzType="delete"
                      nzTheme="twotone"
                      nz-tooltip
                      nzTooltipTitle="Delete"
                    >
                    </span> </span
                  >&nbsp;
                  <a (click)="editform1(data)" nz-tooltip nzTooltipTitle="Edit">
                    <span
                      nz-icon
                      nzType="edit"
                      nzTheme="twotone"
                      style="margin-top: 10px"
                    ></span>
                  </a>
                </td>
                <!-- <td style="text-align: center;white-space: pre-line; "> {{ getTimeIn12Hour(data["DATE"]) | date: "hh:mm a"
                }}
              </td> -->
                <td style="text-align: center; white-space: pre-line">
                  {{ data.FROM_PLACE }}
                </td>
                <td style="text-align: center; white-space: pre-line">
                  {{ data.TO_PLACE }}
                </td>
                <td style="text-align: center; white-space: pre-line">
                  {{ data.ENTITLED_TRAVEL_CLASS_NAME }}
                </td>
                <td style="text-align: center; white-space: pre-line">
                  {{ data.FARE_PAID }}
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </div>

      <br />
      <br />

      <div nz-col nzSpan="24">
        <nz-form-item>
          <nz-form-control>
            <p>
              <b>
                <label
                  nz-checkbox
                  name="type"
                  [(ngModel)]="data.SELF_DECLARATION"
                  (ngModelChange)="chechboxdata($event)"
                >
                </label>
                Certifiied that the:-</b
              >
            </p>
            <p>
              Information as given above is true to the best of my knowledge and
              belief, and
            </p>
            <p>
              That my husband/ wife is not employed in the Government Service /
              that my husband / wife is employed in Government Service and the
              concession has not been availed of by him / her separately for
              himself / herself for any of the family members for the concerned
              Block of 2018-21.
            </p>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <!-- Travel Details -->

    <!-- Documents Upload -->
    <div *ngIf="current == 2">
      <div>
        <form nz-form #docMasterPage="ngForm">
          <nz-spin [nzSpinning]="isSpinning">
            <div nz-row>
              <div nz-col nzSpan="12">
                <nz-form-item>
                  <nz-form-label nzNoColon> Bank Mandate Form</nz-form-label>

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="PDF File is required"
                  >
                    <button
                      nz-button
                      style="height: 28px; padding: 0px 7px; font-size: 12px"
                      (click)="mandateForm.click()"
                    >
                      Select File
                      <input
                        #mandateForm
                        name="bankMandateForm"
                        [(ngModel)]="data.LTC_MANDATE_FORM"
                        type="file"
                        (change)="onFileSelectedMandateForm($event)"
                        style="display: none"
                        required
                      />
                    </button>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="6">
                <div
                  *ngIf="data.LTC_MANDATE_FORM != null"
                  style="overflow: hidden; padding-left: 11px; font-size: 13px"
                >
                  {{ data.LTC_MANDATE_FORM }}
                </div>

                <div *ngIf="data.LTC_MANDATE_FORM != null">
                  <button
                    nz-button
                    style="height: 28px; padding: 0px 7px; font-size: 12px"
                    (click)="viewMandateFormPDF(data.LTC_MANDATE_FORM)"
                  >
                    Open Bank Mandate Form PDF File
                  </button>
                </div>

                <!-- <div
                  *ngIf="LTCMandateFormFileURL == null && data.LTC_MANDATE_FORM != ''"
                >
                  <img
                    style="width: 100%; height: 100%; margin-top: 10px"
                    src="{{ imgurl }}uploads/mandateForm/{{
                      data.LTC_MANDATE_FORM
                    }}"
                  />
                </div> -->
              </div>
            </div>

            <div nz-row>
              <div nz-col nzSpan="12">
                <nz-form-item>
                  <nz-form-label nzNoColon
                    >Copy of Prior Intimation/Leave Application</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="PDF File is required"
                  >
                    <button
                      nz-button
                      style="height: 28px; padding: 0px 7px; font-size: 12px"
                      (click)="priorLeaveApplication.click()"
                    >
                      Select File
                      <input
                        #priorLeaveApplication
                        name="leaveApplication"
                        [(ngModel)]="data.PRIOR_LEAVE_APPLICATION_URL"
                        type="file"
                        (change)="onFileSelectedLeaveApp($event)"
                        style="display: none"
                        required
                      />
                    </button>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="6">
                <div
                  *ngIf="data.PRIOR_LEAVE_APPLICATION_URL != null"
                  style="overflow: hidden; padding-left: 11px; font-size: 13px"
                >
                  {{ data.PRIOR_LEAVE_APPLICATION_URL }}
                </div>

                <div *ngIf="data.PRIOR_LEAVE_APPLICATION_URL != null">
                  <button
                    nz-button
                    style="height: 28px; padding: 0px 7px; font-size: 12px"
                    (click)="
                      viewLeaveApplicationPDF(data.PRIOR_LEAVE_APPLICATION_URL)
                    "
                  >
                    Open Leave Application PDF File
                  </button>
                </div>
                <!-- <div
                  *ngIf="
                    LTCAssumptionFileURL == null && data.PRIOR_LEAVE_APPLICATION_URL     != ''
                  "
                >
                  <img
                    style="width: 100%; height: 100%; margin-top: 10px"
                    src="{{ imgurl }}uploads/assumptionCharge/{{
                      data.PRIOR_LEAVE_APPLICATION_URL    
                    }}"
                  />
                </div> -->
              </div>
            </div>

            <div nz-row>
              <div nz-col nzSpan="12">
                <nz-form-item>
                  <nz-form-label nzNoColon
                    >Copy of Leave Sanctioned Order</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="PDF File is required"
                  >
                    <button
                      nz-button
                      style="height: 28px; padding: 0px 7px; font-size: 12px"
                      (click)="leaveSanction.click()"
                    >
                      Select File
                      <input
                        #leaveSanction
                        name="leaveSanctionOrder"
                        [(ngModel)]="data.LEAVE_SACTIONED_ORDER_URL"
                        type="file"
                        (change)="onFileSelectedLeaveOrder($event)"
                        style="display: none"
                        required
                      />
                    </button>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="6">
                <div
                  *ngIf="data.LEAVE_SACTIONED_ORDER_URL != null"
                  style="overflow: hidden; padding-left: 11px; font-size: 13px"
                >
                  {{ data.LEAVE_SACTIONED_ORDER_URL }}
                </div>

                <div *ngIf="data.LEAVE_SACTIONED_ORDER_URL != null">
                  <button
                    nz-button
                    style="height: 28px; padding: 0px 7px; font-size: 12px"
                    (click)="
                      viewLeaveSanctionPDF(data.LEAVE_SACTIONED_ORDER_URL)
                    "
                  >
                    Open Leave Sanctioned Order PDF File
                  </button>
                </div>
                <!-- <div *ngIf="leaveSanctionFileURL == null && data.LEAVE_SACTIONED_ORDER_URL != ''">
                  <img
                    style="width: 100%; height: 100%; margin-top: 10px"
                    src="{{ imgurl }}uploads/lpc/{{ data.LEAVE_SACTIONED_ORDER_URL }}"
                  />
                </div> -->
              </div>
            </div>

            <div nz-row>
              <div nz-col nzSpan="12">
                <nz-form-item>
                  <nz-form-label nzNoColon>Latest Payment Slip </nz-form-label>

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="PDF File is required"
                  >
                    <button
                      nz-button
                      style="height: 28px; padding: 0px 7px; font-size: 12px"
                      (click)="paymentPaySlip.click()"
                    >
                      Select File
                      <input
                        #paymentPaySlip
                        name="paymentSlip"
                        [(ngModel)]="data.LATEST_PAYMENT_SLIP"
                        type="file"
                        (change)="onFileSelectedPaymentSlip($event)"
                        style="display: none"
                        required
                      />
                    </button>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="6">
                <div
                  *ngIf="data.LATEST_PAYMENT_SLIP != null"
                  style="overflow: hidden; padding-left: 11px; font-size: 13px"
                >
                  {{ data.LATEST_PAYMENT_SLIP }}
                </div>

                <div *ngIf="data.LATEST_PAYMENT_SLIP != null">
                  <button
                    nz-button
                    style="height: 28px; padding: 0px 7px; font-size: 12px"
                    (click)="viewPaymentPDF(data.LATEST_PAYMENT_SLIP)"
                  >
                    Open Latest Payment Slip PDF File
                  </button>
                </div>

                <!-- <div
                  *ngIf="
                    LTCTransferFileURL == null &&
                    data.LATEST_PAYMENT_SLIP != ''
                  "
                >
                  <img
                    style="width: 100%; height: 100%; margin-top: 10px"
                    src="{{ imgurl }}uploads/transferServiceCertificate/{{
                      data.LATEST_PAYMENT_SLIP
                    }}"
                  />
                </div> -->
              </div>
            </div>

            <div nz-row>
              <div nz-col nzSpan="12">
                <nz-form-item>
                  <nz-form-label nzNoColon>
                    Dependency Certificate</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="PDF File is required"
                  >
                    <button
                      nz-button
                      style="height: 28px; padding: 0px 7px; font-size: 12px"
                      (click)="dependancyCertificate.click()"
                    >
                      Select File
                      <input
                        #dependancyCertificate
                        name="dependancyCertificate1"
                        [(ngModel)]="data.DEPENDENCY_CERTIFICATE"
                        type="file"
                        (change)="onFileSelectedDependancyCertificate($event)"
                        style="display: none"
                        required
                      />
                    </button>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="6">
                <div
                  *ngIf="data.DEPENDENCY_CERTIFICATE != null"
                  style="overflow: hidden; padding-left: 11px; font-size: 13px"
                >
                  {{ data.DEPENDENCY_CERTIFICATE }}
                </div>

                <div *ngIf="data.DEPENDENCY_CERTIFICATE != null">
                  <button
                    nz-button
                    style="height: 28px; padding: 0px 7px; font-size: 12px"
                    (click)="viewDependancyPDF(data.DEPENDENCY_CERTIFICATE)"
                  >
                    Open Dependency Certificate PDF File
                  </button>
                </div>

                <!-- <div
                  *ngIf="
                    dependancyCertificateFileURL == null && data.DEPENDENCY_CERTIFICATE != ''
                  "
                >
                  <img
                    style="width: 100%; height: 100%; margin-top: 10px"
                    src="{{ imgurl }}uploads/ticketBoardingPass/{{
                      data.DEPENDENCY_CERTIFICATE
                    }}"
                  />
                </div> -->
              </div>
            </div>

            <div nz-row>
              <div nz-col nzSpan="12">
                <nz-form-item>
                  <nz-form-label nzNoColon> Website ScreenShot</nz-form-label>

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="PDF File is required"
                  >
                    <button
                      nz-button
                      style="height: 28px; padding: 0px 7px; font-size: 12px"
                      (click)="websiteScreenshot.click()"
                    >
                      Select File
                      <input
                        #websiteScreenshot
                        name="websiteSS"
                        [(ngModel)]="data.WEBSITE_SCREENSHOT"
                        type="file"
                        (change)="onFileSelectedwebsiteSS($event)"
                        style="display: none"
                        required
                      />
                    </button>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="6">
                <div
                  *ngIf="data.WEBSITE_SCREENSHOT != null"
                  style="overflow: hidden; padding-left: 11px; font-size: 13px"
                >
                  {{ data.WEBSITE_SCREENSHOT }}
                </div>

                <div *ngIf="data.WEBSITE_SCREENSHOT != null">
                  <button
                    nz-button
                    style="height: 28px; padding: 0px 7px; font-size: 12px"
                    (click)="viewwebsiteSSPDF(data.WEBSITE_SCREENSHOT)"
                  >
                    Open Website ScreenShot PDF File
                  </button>
                </div>

                <!-- <div
                  *ngIf="
                    websiteSSFileURL == null && data.WEBSITE_SCREENSHOT != ''
                  "
                >
                  <img
                    style="width: 100%; height: 100%; margin-top: 10px"
                    src="{{ imgurl }}uploads/ticketBoardingPass/{{
                      data.DEPENDENCY_CERTIFICATE
                    }}"
                  />
                </div> -->
              </div>
            </div>

            <!-- <div nz-row>
              <div nz-col nzSpan="12">
                <nz-form-item>
                  <nz-form-label nzNoColon>Transportation Bills </nz-form-label>

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="PDF File is required"
                  >
                    <button
                      nz-button
                      style="height: 28px; padding: 0px 7px; font-size: 12px"
                      (click)="transportationBill.click()"
                    >
                      Select File
                      <input
                        #transportationBill
                        name="LTCTransportationBill"
                        [(ngModel)]="data.LTC_TRANSPORTATION_BILLS"
                        type="file"
                        (change)="onFileSelectedTransportationBill($event)"
                        style="display: none"
                        required
                      />
                    </button>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="6">
                <div
                  *ngIf="data.LTC_TRANSPORTATION_BILLS != null"
                  style="overflow: hidden; padding-left: 11px; font-size: 13px"
                >
                  {{ data.LTC_TRANSPORTATION_BILLS }}
                </div>

                <div *ngIf="data.LTC_TRANSPORTATION_BILLS != null">
                  <button
                    nz-button
                    style="height: 28px; padding: 0px 7px; font-size: 12px"
                    (click)="
                      viewTransportationBillsPDF(data.LTC_TRANSPORTATION_BILLS)
                    "
                  >
                    Open Transportation Bills PDF File
                  </button>
                </div>
              </div>
            </div>

            <div nz-row>
              <div nz-col nzSpan="12">
                <nz-form-item>
                  <nz-form-label nzNoColon>
                    Condonation Of Delay
                  </nz-form-label>

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="PDF File is required"
                  >
                    <button
                      nz-button
                      style="height: 28px; padding: 0px 7px; font-size: 12px"
                      (click)="condonationOfDelay.click()"
                    >
                      Select File
                      <input
                        #condonationOfDelay
                        name="LTCCondonationDelay"
                        [(ngModel)]="data.LTC_CONDONATION_OF_DELAY"
                        type="file"
                        (change)="onFileSelectedCondonationOfDelay($event)"
                        style="display: none"
                        required
                      />
                    </button>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="6">
                <div
                  *ngIf="data.LTC_CONDONATION_OF_DELAY != null"
                  style="overflow: hidden; padding-left: 11px; font-size: 13px"
                >
                  {{ data.LTC_CONDONATION_OF_DELAY }}
                </div>

                <div *ngIf="data.LTC_CONDONATION_OF_DELAY != null">
                  <button
                    nz-button
                    style="height: 28px; padding: 0px 7px; font-size: 12px"
                    (click)="
                      viewCondonationOfDelayPDF(data.LTC_CONDONATION_OF_DELAY)
                    "
                  >
                    Open Condonation Of Delay PDF File
                  </button>
                </div>
              </div>
            </div> -->
          </nz-spin>
        </form>
      </div>
    </div>
    <!-- Documents Upload -->

    <!-- Checklist -->
    <div *ngIf="current == 3">
      <form nz-form #queMasterPage="ngForm">
        <nz-spin [nzSpinning]="isSpinning">
          <div class="my-box">
            <div nz-row>
              <div nz-col nzSpan="12">
                <nz-form-item>
                  <nz-form-label nzNoColon>File Number</nz-form-label>
                  <nz-form-control nzHasFeedback>
                    <nz-select
                      nzShowSearch
                      nzAllowClear
                      name="FILE_NUMBER"
                      [(ngModel)]="data.FILE_ID"
                    >
                      <nz-option
                        *ngFor="let option of fileList"
                        [nzLabel]="option.FILE_NUMBER"
                        [nzValue]="option.ID"
                      >
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>

          <div nz-row>
            <div nz-col nzSpan="18">
              <div>
                <b>1.</b> Whether Original bills submitted in proper format
              </div>
            </div>
            <div nz-col nzSpan="6">
              <nz-form-item>
                <!-- <nz-form-label nzRequired nzNoColon>Whether Emergency Treatment is Availed ?</nz-form-label> -->
                <nz-form-control>
                  <nz-switch
                    [(ngModel)]="QueData.IS_ORIGINAL_BILL_SUBMITTED"
                    name="originalbill"
                    nzCheckedChildren="Yes"
                    nzUnCheckedChildren="No"
                  >
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div nz-row>
            <div nz-col nzSpan="18">
              <div><b>2.</b> Whether Block Year mentioned</div>
            </div>
            <div nz-col nzSpan="6">
              <nz-form-item>
                <!-- <nz-form-label nzRequired nzNoColon>Whether CGHS/AMA's Reference Taken?</nz-form-label> -->
                <nz-form-control>
                  <nz-switch
                    [(ngModel)]="QueData.WHETHER_BLOCK_YEAR_MENTIONED"
                    name="blockyear"
                    nzCheckedChildren="Yes"
                    nzUnCheckedChildren="No"
                  >
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div nz-row>
            <div nz-col nzSpan="18">
              <div>
                <b>3.</b> Whether bills has been submitted in proper time (If
                advance is taken then bills should be submitted in 30 days after
                completion of journey and bills should be submitted in 90 days
                after completion of journey if advance is not taken).
              </div>
            </div>
            <div nz-col nzSpan="6">
              <nz-form-item>
                <!-- <nz-form-label nzRequired nzNoColon>Whether Hospital Is Emplanelled?</nz-form-label> -->
                <nz-form-control>
                  <nz-switch
                    [(ngModel)]="QueData.IS_BILL_SUBMITTED_PROPER_TIME"
                    name="billinproper"
                    nzCheckedChildren="Yes"
                    nzUnCheckedChildren="No"
                  >
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div nz-row>
            <div nz-col nzSpan="18">
              <div>
                <b>4.</b> In case of Air Journey, whether original boarding pass
                and Tickets of Air Fare duly submitted and in case of Train
                Journey Train Tickets submitted.
              </div>
            </div>
            <div nz-col nzSpan="6">
              <nz-form-item>
                <!-- <nz-form-label nzRequired nzNoColon>Whether Hospital Is Emplanelled?</nz-form-label> -->
                <nz-form-control>
                  <nz-switch
                    [(ngModel)]="QueData.IS_PASS_OR_TICKET_SUBMITTED"
                    name="originaldocuments"
                    nzCheckedChildren="Yes"
                    nzUnCheckedChildren="No"
                  >
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div nz-row>
            <div nz-col nzSpan="18">
              <div>
                <b>4(a).</b> Print-out of concerned webpage of the ATAS having
                flight & fare details for concerned time slot for tickets taken
                after 28/08/2022.
              </div>
            </div>
            <div nz-col nzSpan="6">
              <nz-form-item>
                <!-- <nz-form-label nzRequired nzNoColon>Whether Hospital Is Emplanelled?</nz-form-label> -->
                <!-- <nz-form-control>
                  <nz-switch
                    [(ngModel)]="
                      QueData.IS_PRINTOUT_SUBMITTED_FOR_CONCERNED_TIME_SLOT
                    "
                    name="printout"
                    nzCheckedChildren="Yes"
                    nzUnCheckedChildren="No"
                  >
                  </nz-switch>
                </nz-form-control> -->
                <nz-form-control>
                  <nz-radio-group
                    name="IS_PRINTOUT_SUBMITTED_FOR_CONCERNED_TIME_SLOT"
                    [(ngModel)]="
                      QueData.IS_PRINTOUT_SUBMITTED_FOR_CONCERNED_TIME_SLOT
                    "
                  >
                    <label nz-radio nzValue="Y">Yes </label>
                    <label nz-radio nzValue="N">No</label>
                    <label nz-radio nzValue="NA">NA</label>
                  </nz-radio-group>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div nz-row>
            <div nz-col nzSpan="18">
              <div><b>5.</b> Whether Bank Mandate Form Submitted</div>
            </div>
            <div nz-col nzSpan="6">
              <nz-form-item>
                <!-- <nz-form-label nzRequired nzNoColon>Whether Hospital Is Emplanelled?</nz-form-label> -->
                <nz-form-control>
                  <nz-switch
                    [(ngModel)]="QueData.IS_BANK_MANDATE_FORM_SUBMITTED"
                    name="bankmandateform"
                    nzCheckedChildren="Yes"
                    nzUnCheckedChildren="No"
                  >
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div nz-row>
            <div nz-col nzSpan="18">
              <div>
                <b>6.</b> Whether Copy of Prior Intimation/Leave Application
                Given
              </div>
            </div>
            <div nz-col nzSpan="6">
              <nz-form-item>
                <!-- <nz-form-label nzRequired nzNoColon>Whether Hospital Is Emplanelled?</nz-form-label> -->
                <nz-form-control>
                  <nz-switch
                    [(ngModel)]="
                      QueData.IS_COPY_OF_INTIMATION_OR_LEAVE_APPLICATION_GIVEN
                    "
                    name="leaveapplication"
                    nzCheckedChildren="Yes"
                    nzUnCheckedChildren="No"
                  >
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div nz-row>
            <div nz-col nzSpan="18">
              <div><b>7.</b> Whether copy of leave sanctioned order Given</div>
            </div>
            <div nz-col nzSpan="6">
              <nz-form-item>
                <!-- <nz-form-label nzRequired nzNoColon>Whether Hospital Is Emplanelled?</nz-form-label> -->
                <!-- <nz-form-control>
                  <nz-switch
                    [(ngModel)]="
                      QueData.IS_COPY_OF_LEAVE_LEAVE_SANCTIONED_ORDER_GIVEN
                    "
                    name="leavesanction"
                    nzCheckedChildren="Yes"
                    nzUnCheckedChildren="No"
                  >
                  </nz-switch>
                </nz-form-control> -->
                <nz-form-control>
                  <nz-radio-group
                    name="IS_COPY_OF_LEAVE_LEAVE_SANCTIONED_ORDER_GIVEN"
                    [(ngModel)]="
                      QueData.IS_COPY_OF_LEAVE_LEAVE_SANCTIONED_ORDER_GIVEN
                    "
                  >
                    <label nz-radio nzValue="Y">Yes </label>
                    <label nz-radio nzValue="N">No</label>
                    <label nz-radio nzValue="NA">NA</label>
                  </nz-radio-group>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div nz-row>
            <div nz-col nzSpan="18">
              <div><b>8.</b> Latest Payment Slip for pay level purpose</div>
            </div>
            <div nz-col nzSpan="6">
              <nz-form-item>
                <!-- <nz-form-label nzRequired nzNoColon>Whether Hospital Is Emplanelled?</nz-form-label> -->
                <nz-form-control>
                  <nz-switch
                    [(ngModel)]="QueData.IS_LATEST_PAYMENT_SLIP_FOR_PAY_LEVEL"
                    name="paymentslip"
                    nzCheckedChildren="Yes"
                    nzUnCheckedChildren="No"
                  >
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div nz-row>
            <div nz-col nzSpan="18">
              <div>
                <b>9.</b> Dependency Certificate given in case if sons age is
                more than 18 years and daughters age is more than 24 years
              </div>
            </div>
            <div nz-col nzSpan="6">
              <nz-form-item>
                <!-- <nz-form-label nzRequired nzNoColon>Whether Hospital Is Emplanelled?</nz-form-label> -->
                <!-- <nz-form-control>
                  <nz-switch
                    [(ngModel)]="QueData.IS_DEPENDENCY_CERTIFICATE_GIVEN"
                    name="dependancycertificate"
                    nzCheckedChildren="Yes"
                    nzUnCheckedChildren="No"
                  >
                  </nz-switch>
                </nz-form-control> -->
                <nz-form-control>
                  <nz-radio-group
                    name="IS_DEPENDENCY_CERTIFICATE_GIVEN"
                    [(ngModel)]="QueData.IS_DEPENDENCY_CERTIFICATE_GIVEN"
                  >
                    <label nz-radio nzValue="Y">Yes </label>
                    <label nz-radio nzValue="N">No</label>
                    <label nz-radio nzValue="NA">NA</label>
                  </nz-radio-group>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div nz-row>
            <div nz-col nzSpan="18">
              <div><b>10.</b> Whether Advance for LTC Granted</div>
            </div>
            <div nz-col nzSpan="6">
              <nz-form-item>
                <!-- <nz-form-label nzRequired nzNoColon>Whether Hospital Is Emplanelled?</nz-form-label> -->
                <nz-form-control>
                  <nz-switch
                    [(ngModel)]="QueData.IS_ADVANCE_FOR_LTC_GRANTED"
                    name="advanceforLTC"
                    nzCheckedChildren="Yes"
                    nzUnCheckedChildren="No"
                  >
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div nz-row>
            <div nz-col nzSpan="18">
              <div><b>11.</b> Whether Home Town</div>
            </div>
            <div nz-col nzSpan="6">
              <nz-form-item>
                <!-- <nz-form-label nzRequired nzNoColon>Whether Hospital Is Emplanelled?</nz-form-label> -->
                <nz-form-control>
                  <nz-switch
                    [(ngModel)]="QueData.WHETHER_HOME_TOWN"
                    name="hometown"
                    nzCheckedChildren="Yes"
                    nzUnCheckedChildren="No"
                  >
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div nz-row>
            <div nz-col nzSpan="18">
              <div><b>12.</b> Whether All Over India</div>
            </div>
            <div nz-col nzSpan="6">
              <nz-form-item>
                <!-- <nz-form-label nzRequired nzNoColon>Whether Hospital Is Emplanelled?</nz-form-label> -->
                <nz-form-control>
                  <nz-switch
                    [(ngModel)]="QueData.WHETHER_ALL_OVER_INDIA"
                    name="alloverindia"
                    nzCheckedChildren="Yes"
                    nzUnCheckedChildren="No"
                  >
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div nz-row>
            <div nz-col nzSpan="18">
              <div>
                <b>13.</b> Whether converted Home Town to NER/Andaman &
                Nicobar/Jammu & Kashmir/Ladakh
              </div>
            </div>
            <div nz-col nzSpan="4">
              <nz-form-item>
                <!-- <nz-form-label nzRequired nzNoColon>Whether Hospital Is Emplanelled?</nz-form-label> -->
                <nz-form-control>
                  <nz-switch
                    [(ngModel)]="QueData.WHETHER_CONVERTED_HOME_TO_NER_AN_JK_L"
                    name="convertedhometown"
                    nzCheckedChildren="Yes"
                    nzUnCheckedChildren="No"
                  >
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <!-- <div class="my-box">
            <div nz-row>
              <div nz-col nzSpan="12" >
                <nz-form-item>
                  <nz-form-label nzNoColon>
                    <h3>Claim Confirmation</h3>
                  </nz-form-label>
                  <nz-form-control>
                 
                    <nz-radio-group name="checkstatus" style="margin-left: 10px" [(ngModel)]="data.ACTION_STATUS"
                      (ngModelChange)="clearRemark($event)" required>
                      <label nz-radio nzValue="A" style="color: green">Accept Claim</label>
    
                      <label nz-radio nzValue="R" style="color: red">Reject Claim</label>
                      <label nz-radio nzValue="H" style="color: black">On Hold</label>
                    </nz-radio-group>
                  </nz-form-control>
                </nz-form-item>
              </div>
             
    
              <div nz-col nzSpan="24" *ngIf="data.ACTION_STATUS == 'R'">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>Reject Remark</nz-form-label>
                  <nz-form-control nzHasFeedback nzErrorTip="Please Enter Valid Reject Remark ">
                    <textarea name="remark" style="width: 100%" [(ngModel)]="data.ACTION_REMARK" nz-input
                      [nzAutosize]="{ minRows: 2, maxRows: 4 }" maxlength="256" placeholder="Please Enter Reject Remark"
                      draggable="false" required></textarea>
                    <h6 style="color: red" *ngIf="data.ACTION_REMARK != undefined">
                      {{ data.ACTION_REMARK.length }}/256
                    </h6>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="12"></div>
              <div nz-col nzSpan="6" *ngIf="data.ACTION_STATUS == 'H'">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>
                    On Hold Date
                  </nz-form-label>
                  <nz-form-control nzErrorTip="Select Correct On Hold Date">
                    <nz-date-picker style="width: 100%" nzFormat="dd/MM/yyyy" name="onholddate"
                      [(ngModel)]="data.ACTION_DATE" required></nz-date-picker>
                  </nz-form-control>
                </nz-form-item>
              </div>
    
              <div nz-col nzSpan="24" *ngIf="data.ACTION_STATUS == 'H'">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>On Hold Remark</nz-form-label>
                  <nz-form-control nzHasFeedback nzErrorTip="Please Enter Valid On Hold Remark ">
                    <textarea name="onholdremark" style="width: 100%" [(ngModel)]="data.ACTION_REMARK" nz-input
                      [nzAutosize]="{ minRows: 2, maxRows: 4 }" maxlength="256" placeholder="Please Enter On Hold Remark"
                      draggable="false" required></textarea>
                    <h6 style="color: red" *ngIf="data.ACTION_REMARK != undefined">
                      {{ data.ACTION_REMARK.length }}/256
                    </h6>
                  </nz-form-control>
                </nz-form-item>
              </div>
              
            </div>
          </div> -->
        </nz-spin>
      </form>
    </div>
    <!-- Checklist -->
  </div>
  <div class="footer">
    <button
      [nzLoading]="isSpinning"
      nz-button
      nzType="default"
      (click)="pre()"
      *ngIf="current > 0"
      style="margin-right: 8px"
    >
      <span>Previous</span>
    </button>

    <button
      [nzLoading]="isSpinning"
      *ngIf="current < 3 && data.ID && userId == 1"
      type="button"
      class="ant-btn ant-btn-primary"
      nz-button
      (click)="next()"
    >
      <span> Next </span>
    </button>
    <span>
      <button
        [nzLoading]="isSpinning"
        *ngIf="current == 0 && userId != 1"
        nzType="primary"
        class="ant-btn ant-btn-primary"
        nz-button
        (click)="empSave()"
      >
        <span>Save Applicant Info. & Next </span>
      </button>
    </span>

    <span>
      <button
        [nzLoading]="isSpinning"
        *ngIf="current == 1 && userId != 1"
        nzType="primary"
        class="ant-btn ant-btn-primary"
        (click)="AlldataSave(false)"
        nz-button
      >
        <span>Save Travel Details & Next </span>
      </button>
    </span>

    <span>
      <button
        [nzLoading]="isSpinning"
        *ngIf="current == 2 && userId != 1"
        nzType="primary"
        class="ant-btn ant-btn-primary"
        nz-button
        (click)="uploadDoc(false)"
      >
        <span>Upload Document & Next</span>
      </button>
    </span>
    <span>
      <button
        [nzLoading]="isSpinning"
        *ngIf="current == 3 && userId != 1"
        nzType="primary"
        class="ant-btn ant-btn-primary"
        nz-button
        (click)="queSave(false)"
      >
        <span>Save Questionary & Finish </span>
      </button>
    </span>

    <!-- <span>
        <button [nzLoading]="isSpinning" *ngIf="current == 3" nzType="primary" class="ant-btn ant-btn-primary" nz-button
          (click)="checkSave(false)">
          <span>Save & Finish</span>
        </button>
      </span> -->
    <button
      type="button"
      nz-button
      (click)="close()"
      class="ant-btn"
      style="margin-right: 8px"
    >
      <span>Cancel</span>
    </button>
  </div>

  <div class="header">
    <button
      type="button"
      nz-button
      (click)="close()"
      class="ant-btn"
      style="margin-right: 8px"
    >
      <i nz-icon nzType="close" nzTheme="outline"></i>
    </button>
  </div>
</div>

<div nz-row>
  <div *ngIf="drawerVisible2form1">
    <nz-drawer
      [nzBodyStyle]="{
        height: 'calc(100% - 55px)',
        overflow: 'auto',
        'padding-bottom': '53px'
      }"
      [nzClosable]="false"
      [nzMask]="true"
      [nzMaskClosable]="false"
      [nzWidth]="500"
      [nzVisible]="drawerVisible2form1"
      [nzTitle]="drawerTitleform1"
      (nzOnClose)="drawerClose2form1()"
      (nzKeyboard)="(true)"
    >
      <ng-container>
        <app-placesconnectedbyrail
          [drawerClose]="closeCallbackform1form1"
          [ltcID]="ltcID"
          [empID]="empID"
          [data]="drawerData2form1"
        ></app-placesconnectedbyrail>
      </ng-container>
    </nz-drawer>
  </div>
</div>

<!-- 
  <div nz-row>
    <nz-drawer [nzBodyStyle]="{
        height: 'calc(100% - 55px)',
        overflow: 'auto',
        'padding-bottom': '53px'
      }" [nzClosable]="false" [nzMask]="true" [nzMaskClosable]="false" [nzWidth]="550" [nzVisible]="drawerVisibleform2"
      [nzTitle]="drawerTitleform2" (nzOnClose)="drawerCloseform2()" (nzKeyboard)="(true)">
      <ng-container>
        <app-form2 [drawerClose]="closeCallbackform2" [empID]="empID" [claimID]="claimID"
          [drawerVisible]="drawerVisibleform2" [data]="drawerDataform2"></app-form2>
      </ng-container>
    </nz-drawer>
  </div> -->

<div nz-row>
  <div *ngIf="drawerVisibleform3">
    <nz-drawer
      [nzBodyStyle]="{
        height: 'calc(100% - 55px)',
        overflow: 'auto',
        'padding-bottom': '53px'
      }"
      [nzClosable]="false"
      [nzMask]="true"
      [nzMaskClosable]="false"
      [nzWidth]="600"
      [nzVisible]="drawerVisibleform3"
      [nzTitle]="drawerTitleform3"
      (nzOnClose)="drawerCloseform3()"
      (nzKeyboard)="(true)"
    >
      <ng-container>
        <app-particularsofjourney
          [drawerClose]="closeCallbackform3"
          [empID]="empID"
          [ltcID]="ltcID"
          [data]="drawerDataform3"
          [class]="class"
        ></app-particularsofjourney>
      </ng-container>
    </nz-drawer>
  </div>
</div>

<div nz-row>
  <div *ngIf="drawerVisibleform7">
    <nz-drawer
      [nzBodyStyle]="{
        height: 'calc(100% - 55px)',
        overflow: 'auto',
        'padding-bottom': '53px'
      }"
      [nzClosable]="false"
      [nzMask]="true"
      [nzMaskClosable]="false"
      [nzWidth]="1000"
      [nzVisible]="drawerVisibleform7"
      [nzTitle]="drawerTitleform7"
      (nzOnClose)="drawerCloseform7()"
      (nzKeyboard)="(true)"
    >
      <ng-container>
        <app-detailsofjourney
          [drawerClose]="closeCallbackform7"
          [empID]="empID"
          [ltcID]="ltcID"
          [data]="drawerDataform7"
          [classdata]="classdata"
          [gradePayLevel]="gradePayLevel"
        ></app-detailsofjourney>
      </ng-container>
    </nz-drawer>
  </div>
</div>

<div *ngIf="empDrawerVisible">
  <nz-drawer
    [nzBodyStyle]="{
      height: 'calc(100% - 55px)',
      overflow: 'auto',
      'padding-bottom': '53px'
    }"
    [nzClosable]="false"
    [nzMask]="true"
    [nzMaskClosable]="false"
    [nzWidth]="700"
    [nzVisible]="empDrawerVisible"
    [nzTitle]="drawerTitle"
    (nzOnClose)="empDrawerClose()"
    (nzKeyboard)="(true)"
  >
    <app-addclaimemployee
      [drawerClose]="closeCallback"
      [data]="drawerData"
    ></app-addclaimemployee>
  </nz-drawer>
</div>

<nz-modal
  [nzWidth]="1200"
  [(nzVisible)]="printOrderModalVisible"
  nzMaskClosable="false"
  nzClosable="false"
  [nzContent]="printOrderModalContent"
  [nzTitle]="'PDFview'"
  (nzOnCancel)="printOrderModalCancel()"
  [nzFooter]="printOrderModalFooter"
>
  <ng-template #printOrderModalContent>
    <nz-spin [nzSpinning]="loadingRecords">
      <div *ngIf="view == 1" style="height: 600px; overflow: auto">
        <iframe
          [src]="getS(data.PRIOR_LEAVE_APPLICATION_URL)"
          style="width: 1150px; height: 600px"
          frameborder="0"
        ></iframe>
      </div>
      <div *ngIf="view == 2" style="height: 600px; overflow: auto">
        <iframe
          [src]="getS(data.LEAVE_SACTIONED_ORDER_URL)"
          style="width: 1150px; height: 600px"
          frameborder="0"
        ></iframe>
      </div>
      <div *ngIf="view == 3" style="height: 600px; overflow: auto">
        <iframe
          [src]="getS(data.LTC_MANDATE_FORM)"
          style="width: 1150px; height: 600px"
          frameborder="0"
        ></iframe>
      </div>
      <div *ngIf="view == 4" style="height: 600px; overflow: auto">
        <iframe
          [src]="getS(data.LATEST_PAYMENT_SLIP)"
          style="width: 1150px; height: 600px"
          frameborder="0"
        ></iframe>
      </div>
      <div *ngIf="view == 5" style="height: 600px; overflow: auto">
        <iframe
          [src]="getS(data.DEPENDENCY_CERTIFICATE)"
          style="width: 1150px; height: 600px"
          frameborder="0"
        ></iframe>
      </div>
      <div *ngIf="view == 6" style="height: 600px; overflow: auto">
        <iframe
          [src]="getS(data.LTC_TRANSPORTATION_BILLS)"
          style="width: 1150px; height: 600px"
          frameborder="0"
        ></iframe>
      </div>
      <div *ngIf="view == 7" style="height: 600px; overflow: auto">
        <iframe
          [src]="getS(data.LTC_CONDONATION_OF_DELAY)"
          style="width: 1150px; height: 600px"
          frameborder="0"
        ></iframe>
      </div>
      <div *ngIf="view == 8" style="height: 600px; overflow: auto">
        <iframe
          [src]="getS(data.WEBSITE_SCREENSHOT)"
          style="width: 1150px; height: 600px"
          frameborder="0"
        ></iframe>
      </div>
    </nz-spin>
  </ng-template>
  <ng-template #printOrderModalFooter>
    <button nz-button nzType="default" (click)="printOrderModalCancel()">
      Close
    </button>

    <!-- <button nz-button nzType="primary" (click)="Accept()">Accept</button>
    <button nz-button nzType="primary" nzDanger (click)="reject()">
      Reject
    </button> -->
  </ng-template>
</nz-modal>
