<div *ngIf="orderdata1 != undefined">
  <form nz-form #delayCondolenceOrderPage="ngForm">
    <nz-spin [nzSpinning]="isSpinning">
      <div nz-row [nzGutter]="10">
        <div nz-col nzXs="8" nzSm="8" nzMd="8" nzLg="8" nzXl="8" nzSpan="8">
          <div class="my-box">
            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <!-- <div>
                  <nz-form-item>

                    <nz-radio-group [(ngModel)]="orderdata1.PAY_BILL" name="radiogaz">
                      <label nz-radio nzValue="NonGazPay">Welfare & S.G </label>
                      <label nz-radio nzValue="GazPay">Gaz Pay Bill</label>
                    </nz-radio-group>
                  </nz-form-item>
                </div> -->
                <div nz-col nzSpan="24">
                  <nz-form-item>
                    <nz-form-label nzRequired nzNoColon
                      >Signature</nz-form-label
                    >
                    <nz-form-control>
                      <nz-select
                        name="userid"
                        [(ngModel)]="orderdata1.SIGNATURE_ID"
                        nzAllowClear
                        nzPlaceHolder="Choose Signature"
                        (ngModelChange)="signature($event)"
                      >
                        <nz-option
                          *ngFor="let user of Signaturearray"
                          [nzValue]="user.ID"
                          [nzLabel]="user.NAME"
                        >
                        </nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
            </div>

            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="12"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Claim Submission Date</nz-form-label
                  >

                  <nz-form-control>
                    <nz-date-picker
                      style="width: 100%"
                      nzFormat="dd/MM/yyyy"
                      name="CLAIM_SUBMITTED_DATE"
                      (ngModelChange)="dateDiff($event)"
                      [(ngModel)]="queData.CLAIM_SUBMITTED_DATE"
                    >
                    </nz-date-picker>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>

            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="12"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Claim Received Date</nz-form-label
                  >

                  <nz-form-control>
                    <nz-date-picker
                      style="width: 100%"
                      nzFormat="dd/MM/yyyy"
                      name="CLAIM_FILLED_DATE"
                      [(ngModel)]="queData.CLAIM_FILLED_DATE"
                    >
                    </nz-date-picker>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>

            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>
                    Amount Expense
                  </nz-form-label>

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Expense amount is required"
                  >
                    <input
                      style="width: 100%"
                      name="EXPENCE_AMOUNT"
                      nz-input
                      placeholder="Enter Expence Amount"
                      type="text"
                      [(ngModel)]="queData.EXPENCE_AMOUNT"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>

            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="12"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Delay Condonation Letter Date
                  </nz-form-label>

                  <nz-form-control>
                    <nz-date-picker
                      style="width: 100%"
                      nzFormat="dd/MM/yyyy"
                      name="LETTER_DATE"
                      [(ngModel)]="queData.LETTER_DATE"
                    >
                    </nz-date-picker>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>

            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="12"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Delay Condonation Letter Received Date</nz-form-label
                  >

                  <nz-form-control>
                    <nz-date-picker
                      style="width: 100%"
                      nzFormat="dd/MM/yyyy"
                      name="RECEIVED_DATE"
                      [(ngModel)]="queData.RECEIVED_DATE"
                    >
                    </nz-date-picker>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>

            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzNoColon>Claim Reason</nz-form-label>

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Claim Reason is required"
                  >
                    <textarea
                      style="width: 100%"
                      name="claimreasons"
                      nz-input
                      placeholder="Enter Claim Reason"
                      autocomplete="off"
                      type="text"
                      [(ngModel)]="queData.CLAIM_REASON"
                      maxlength="2500"
                    ></textarea>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>

            <div *ngFor="let data of HospitalMapping; let i = index">
              <div class="my-box">
                <div nz-row>
                  <div nz-col nzSpan="24">
                    <nz-form-item>
                      <nz-form-label nzNoColon>
                        Name of Hospital
                      </nz-form-label>

                      <nz-form-control
                        nzHasFeedback
                        nzErrorTip="Name of Hospital is required"
                      >
                        <input
                          style="width: 100%"
                          name="NAMENAME{{ i }}"
                          nz-input
                          placeholder="Enter name in english"
                          autocomplete="off"
                          type="text"
                          disabled="true"
                          [(ngModel)]="data.NAME"
                        />
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
                <!-- <div nz-row>
                  <div
                    nz-col
                    nzXs="24"
                    nzSm="24"
                    nzMd="24"
                    nzLg="24"
                    nzXl="24"
                    nzSpan="24"
                  >
                    <nz-form-item>
                      <nz-form-label nzRequired nzNoColon
                        >Name of Hospital(in hindi)</nz-form-label
                      >
  
                      <nz-form-control
                        nzHasFeedback
                        nzErrorTip="Name of Hospital(in hindi) is required"
                      >
                        <input
                          style="width: 100%"
                          nz-input
                          placeholder="Enter name of Hospital in hindi"
                          autocomplete="off"
                          name="NAME_HN{{ i }}"
                          type="text"
                          [(ngModel)]="data.NAME_HN"
                        />
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div> -->

                <div nz-row>
                  <div
                    nz-col
                    nzXs="24"
                    nzSm="24"
                    nzMd="24"
                    nzLg="24"
                    nzXl="24"
                    nzSpan="24"
                  >
                    <nz-form-item>
                      <nz-form-label nzNoColon
                        >Address of Hospital
                      </nz-form-label>

                      <nz-form-control
                        nzHasFeedback
                        nzErrorTip="Name of Address is required"
                      >
                        <input
                          style="width: 100%"
                          name="ADDRESS{{ i }}"
                          nz-input
                          placeholder="Enter Address in english"
                          autocomplete="off"
                          type="text"
                          [(ngModel)]="data.ADDRESS"
                        />
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
                <!-- <div nz-row>
                  <div
                    nz-col
                    nzXs="24"
                    nzSm="24"
                    nzMd="24"
                    nzLg="24"
                    nzXl="24"
                    nzSpan="24"
                  >
                    <nz-form-item>
                      <nz-form-label nzRequired nzNoColon
                        >Address of Hospital(in hindi)</nz-form-label
                      >
  
                      <nz-form-control
                        nzHasFeedback
                        nzErrorTip="Address of Hospital(in hindi) is required"
                      >
                        <input
                          style="width: 100%"
                          nz-input
                          placeholder="Enter Address of Hospital in hindi"
                          autocomplete="off"
                          name="ADDRESS_HN{{ i }}"
                          type="text"
                          [(ngModel)]="data.ADDRESS_HN"
                        />
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div> -->

                <div>
                  <nz-form-item>
                    <nz-form-label nzRequired nzNoColon
                      >Show In Order</nz-form-label
                    >
                    <nz-form-control>
                      <nz-switch
                        name="IS_VISIBLE_IN_ORDER{{ i }}"
                        nzCheckedChildren="Yes"
                        nzUnCheckedChildren="No"
                        [(ngModel)]="HospitalMapping[i].IS_VISIBLE_IN_ORDER"
                      ></nz-switch>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
            </div>

            <!-- <div nz-row *ngIf="orderdata1.HOSPITAL_TYPE == 'E'">
                <div
                  nz-col
                  nzXs="24"
                  nzSm="24"
                  nzMd="24"
                  nzLg="24"
                  nzXl="24"
                  nzSpan="24"
                >
                  <nz-form-item>
                    <nz-form-label nzNoColon>Name of Hospital </nz-form-label>
  
                    <nz-form-control
                      nzHasFeedback
                      nzErrorTip="Name of patient is required"
                    >
                      <input
                        style="width: 100%"
                        name="EMPANELLED_HOSPITAL_NAME"
                        nz-input
                        placeholder="Enter name in english"
                        autocomplete="off"
                        type="text"
                        disabled="true"
                        [(ngModel)]="orderdata1.EMPANELLED_HOSPITAL_NAME"
                      />
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div> -->

            <!-- <div nz-row>
                              <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" nzSpan="24">
                                  <nz-form-item>
                                      <nz-form-label  nzNoColon>Amount (in words)</nz-form-label>
  
                                      <nz-form-control nzHasFeedback nzErrorTip="City Name is required">
                                          <input style="width: 100%;" nz-input placeholder="Enter Amount"
                                              autocomplete="off"  name="amountinwords" type="text"
                                              [(ngModel)]="amountinwords" />
                                      </nz-form-control>
                                  </nz-form-item>
                              </div>
                             
                          </div>
                          <div nz-row>
                              <div  nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" nzSpan="24">
                                  <nz-form-item>
                                      <nz-form-label nzRequired nzNoColon>Amount (in hindi)</nz-form-label>
  
                                      <nz-form-control nzHasFeedback nzErrorTip="City Name is required">
                                          <input style="width: 100%;" nz-input placeholder="Enter amount in hindi"
                                              autocomplete="off" name="amountinwordsh"  type="text"
                                              [(ngModel)]="amountinwordsh" />
                                      </nz-form-control>
                                  </nz-form-item>
                              </div>
                          </div> -->
          </div>
        </div>

        <div nz-col nzXs="16" nzSm="16" nzMd="16" nzLg="16" nzXl="16">
          <div class="my-box" style="padding: 10px">
            <div>
              <div style="font-family: Arial, Helvetica, sans-serif !important">
                <div
                  style="
                    text-align: center;
                    margin-top: 20px;
                    margin-bottom: 10px;
                    font-size: 13px;
                  "
                >
                  <b style="text-decoration: underline">ORDER SHEET</b>
                </div>
                <div style="font-size: 14px !important">
                  <p style="margin-bottom: 2px; text-align: justify">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b
                      >{{ orderdata1.EMPLOYEE_NAME }},
                      {{ orderdata1.DESIGNATION }}</b
                    >
                    vide letter dt
                    <b
                      >{{
                        queData.CLAIM_SUBMITTED_DATE | date : "dd/MM/yyyy"
                      }} </b
                    >(S/A) and received in office on
                    <b>{{ queData.CLAIM_FILLED_DATE | date : "dd/MM/yyyy" }}</b>
                    submitted the claim for reimbursement of medical expenses
                    amounting to Rs. <b>{{ queData.EXPENCE_AMOUNT }}</b
                    >/- incurred for Treatment/investigations at
                    <!-- {{ HospitalMapping.NAME }}
                    {{ HospitalMapping.ADDRESS }} -->
                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <span *ngIf="data.IS_VISIBLE_IN_ORDER == true">
                        {{ data.NAME }} ,{{ data.ADDRESS }} ,{{
                          data.TYPE == "E"
                            ? "Empanelled"
                            : data.TYPE == "NE"
                            ? "Non Empanelled"
                            : data.TYPE == "G"
                            ? "Government"
                            : ""
                        }}
                        <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                        <!-- <span *ngIf="HospitalMapping.length - 2 == k">and</span> -->
                      </span>
                    </span>
                    from {{ orderdata1.TREATMENT_START_DATE }} to
                    {{ orderdata1.TREATMENT_END_DATE }} for
                    <span *ngIf="orderdata1.RELATION_WITH_PATIENT != 'Self'">
                      his/her {{ orderdata1.RELATION_WITH_PATIENT }}
                      {{ orderdata1.PATIENT_NAME }}.
                    </span>

                    <!-- &nbsp; for&nbsp;{{ orderdata1.NATURE_OF_TREATMENT }} -->
                    <!-- <span *ngIf="orderdata1.RELATION_WITH_PATIENT == 'Self'"> -->
                    <!-- of Self -->
                    <!-- </span > -->
                    The medical bill was filed out of time which was received in
                    the office on
                    <b>{{ queData.CLAIM_FILLED_DATE | date : "dd/MM/yyyy" }}</b>
                    <!-- &nbsp; {{ orderdata1.EMPLOYEE_NAME }},
                    {{ orderdata1.DESIGNATION }} , seek condonation of delay for
                    filing the medical bill out of time -->
                    &nbsp; vide letter dt
                    <b>{{ queData.LETTER_DATE | date : "dd/MM/yyyy" }}</b> and
                    received in this office dt
                    <b>{{ queData.RECEIVED_DATE | date : "dd/MM/yyyy" }}</b
                    >, {{ orderdata1.EMPLOYEE_NAME }},
                    {{ orderdata1.DESIGNATION }} has required to seek
                    condonation of delay for filing the medical bill out of
                    time.
                  </p>
                  <p style="margin-bottom: 2px; text-align: justify">
                    2.It is seen from the papers on record that
                    {{ orderdata1.PATIENT_NAME }},
                    <!-- <span *ngIf="orderdata1.RELATION_WITH_PATIENT != 'Self'"> -->
                    {{ orderdata1.RELATION_WITH_PATIENT }} of
                    <!-- {{ orderdata1.PATIENT_NAME }} -->
                    <!-- </span
                    > -->

                    <!-- &nbsp; for&nbsp;{{ orderdata1.NATURE_OF_TREATMENT }} -->
                    <!-- <span *ngIf="orderdata1.RELATION_WITH_PATIENT == 'Self'">
                      of Self</span> -->
                    {{ orderdata1.EMPLOYEE_NAME }},
                    {{ orderdata1.DESIGNATION }} had taken treatment from
                    <!-- {{ HospitalMapping.NAME }} {{ HospitalMapping.ADDRESS }} -->
                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <span *ngIf="data.IS_VISIBLE_IN_ORDER == true">
                        {{ data.NAME }} ,{{ data.ADDRESS }} ,{{
                          data.TYPE == "E"
                            ? "Empanelled"
                            : data.TYPE == "NE"
                            ? "Non Empanelled"
                            : data.TYPE == "G"
                            ? "Government"
                            : ""
                        }}
                        <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                        <!-- <span *ngIf="HospitalMapping.length - 2 == k">and</span> -->
                      </span>
                    </span>
                    from {{ orderdata1.TREATMENT_START_DATE }} to
                    {{ orderdata1.TREATMENT_END_DATE }} As per the revised time
                    limit prescribed by the Ministry of Health & Family Welfare
                    vide Office Memorandum No 1-60/2017-CGHS/C&P/EHS dt
                    01.06.2020 <b>(F/B)</b>, the official/ officer is required
                    to submit claim within 6 months from the date of completion
                    of medical treatment/discharge of the patient from the
                    hospital. In the instant case, the claim is submitted on
                    <b>
                      {{
                        queData.CLAIM_SUBMITTED_DATE | date : "dd/MM/yyyy"
                      }}</b
                    >
                    and received in office on
                    <b>{{ queData.CLAIM_FILLED_DATE | date : "dd/MM/yyyy" }}</b>
                    where as it should have been filed on or before
                    <b>{{ sixMonthDate | date : "dd/MM/yyyy" }}</b>
                    As such there is a delay of
                    <b>{{ dateBetweenDiff }}</b> days in filling the claim for
                    reimbursement of expenses.
                  </p>
                  <p style="margin-bottom: 2px; text-align: justify">
                    3.&nbsp;&nbsp;&nbsp;
                    <b>{{ queData.CLAIM_REASON }}</b>
                  </p>
                  <p style="margin-bottom: 2px; text-align: justify">
                    4.&nbsp;&nbsp;&nbsp; Considering the explanation given for
                    delay in submission of reimbursement claim and as per Office
                    Memorandum No S 14025/8/99/MS dated 25.05.1999 <b>(F/C)</b>,
                    the power is vested with the HOD to condone the delay in
                    submission of medical bills, after giving a reasonable
                    opportunity of being heard to the applicant Considering the
                    facts and circumstances of the case, the reason attributed
                    for delay in submission of the claim appears to be
                    convincing and hence delay may kindly be condoned, if
                    approved. The order for condonation of delay is put up for
                    approval and signature, please
                  </p>

                  <div style="margin-top: 100px; font-size: 13px">
                    <div nz-row>
                      <div nz-col nzSpan="15"></div>

                      <div nz-col nzSpan="9" style="text-align: center">
                        <div style="font-weight: bold; font-size: 13px">
                          <div *ngIf="SECTION_TYPE == 'welfare'">
                            <b>(Ritesh Kumar)</b>
                          </div>
                          <div *ngIf="SECTION_TYPE == 'gazpay'">
                            <b>(Jitendra R Chandekar)</b>
                          </div>
                          <span *ngIf="SECTION_TYPE == 'welfare'">
                            ITO (HQ) Welfare & SG, Mumbai.</span
                          >
                          <span *ngIf="SECTION_TYPE == 'gazpay'"
                            >ITO Gaz Pay Bill Section II, Mumbai</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      style="
                        page-break-inside: avoid !important;
                        font-size: 13px;
                        page-break-before: avoid !important;
                      "
                    >
                      <div style="margin-top: 50px">
                        <b>Jt.CIT (HQ) Persennel, Mumbai </b>
                      </div>
                    </div>
                  </div>
                  <div
                    style="
                      page-break-inside: avoid !important;
                      font-size: 13px;
                      page-break-before: avoid !important;
                    "
                  >
                    <div style="margin-top: 65px">
                      <b
                        >Commisioner of Income Tax (Administration & TPS),
                        Mumbai
                      </b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>
  </form>

  <div class="footer">
    <button
      type="button"
      nz-button
      class="ant-btn"
      style="margin-right: 8px"
      (click)="close(delayCondolenceOrderPage)"
    >
      <span>Cancel</span>
    </button>

    <button
      type="button"
      class="ant-btn ant-btn-primary"
      nz-button
      (click)="Save()"
    >
      <span>Save & Preview</span>
    </button>
    <!-- <button
      type="button"
      class="ant-btn ant-btn-primary"
      nz-button
      (click)="pakagedata()"
    >
      <span>Save & Preview</span>
    </button> -->
    <!-- <button nz-button nzType="primary"  (click)="pakagedata()">
              Save and Next
          </button> -->

    <!-- <button
        type="button"
        class="ant-btn ant-btn-primary"
        nz-button
        (click)="openPrintOrderModal()"
      >
        <span>Preview</span>
      </button> -->
  </div>
  <div class="header">
    <button
      type="button"
      nz-button
      (click)="close(delayCondolenceOrderPage)"
      class="ant-btn"
      style="margin-right: 8px"
    >
      <i nz-icon nzType="close" nzTheme="outline"></i>
    </button>
  </div>
</div>

<nz-modal
  [nzWidth]="getwidth()"
  [(nzVisible)]="printOrderModalVisible"
  nzMaskClosable="false"
  nzClosable="false"
  [nzContent]="printOrderModalContent"
  [nzTitle]="'Delay Order Sheet'"
  (nzOnCancel)="printOrderModalCancel()"
  [nzFooter]="printOrderModalFooter"
>
  <ng-template #printOrderModalContent>
    <nz-spin [nzSpinning]="loadingRecords">
      <div style="height: 450px; overflow: auto">
        <div id="printOrderModal">
          <div style="width: 700px; padding: 3px; margin-left: 15px">
            <div>
              <div style="font-family: Arial, Helvetica, sans-serif !important">
                <div
                  style="
                    text-align: center;
                    margin-top: 20px;
                    margin-bottom: 10px;
                    font-size: 13px;
                  "
                >
                  <b style="text-decoration: underline">ORDER SHEET</b>
                </div>
                <div style="font-size: 14px !important">
                  <p style="margin-bottom: 2px; text-align: justify">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b
                      >{{ orderdata1.EMPLOYEE_NAME }},
                      {{ orderdata1.DESIGNATION }}</b
                    >
                    vide letter dt
                    <b>{{
                      queData.CLAIM_SUBMITTED_DATE | date : "dd/MM/yyyy"
                    }}</b>
                    (S/A) and received in office on
                    <b>{{ queData.CLAIM_FILLED_DATE | date : "dd/MM/yyyy" }}</b>
                    submitted the claim for reimbursement of medical expenses
                    amounting to Rs. <b>{{ queData.EXPENCE_AMOUNT }}</b
                    >/- incurred for Treatment/investigations at
                    <!-- {{ HospitalMapping.NAME }}
                    {{ HospitalMapping.ADDRESS }} -->
                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <span *ngIf="data.IS_VISIBLE_IN_ORDER == true">
                        {{ data.NAME }} ,{{ data.ADDRESS }} ,{{
                          data.TYPE == "E"
                            ? "Empanelled"
                            : data.TYPE == "NE"
                            ? "Non Empanelled"
                            : data.TYPE == "G"
                            ? "Government"
                            : ""
                        }}
                        <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                        <!-- <span *ngIf="HospitalMapping.length - 2 == k">and</span> -->
                      </span>
                    </span>
                    from {{ orderdata1.TREATMENT_START_DATE }} to
                    {{ orderdata1.TREATMENT_END_DATE }} for
                    <span *ngIf="orderdata1.RELATION_WITH_PATIENT != 'Self'">
                      his/her {{ orderdata1.RELATION_WITH_PATIENT }}
                      {{ orderdata1.PATIENT_NAME }}.
                    </span>

                    <!-- &nbsp; for&nbsp;{{ orderdata1.NATURE_OF_TREATMENT }} -->
                    <!-- <span *ngIf="orderdata1.RELATION_WITH_PATIENT == 'Self'"> -->
                    <!-- of Self -->
                    <!-- </span > -->
                    The medical bill was filed out of time which was received in
                    the office on
                    <b>{{ queData.CLAIM_FILLED_DATE | date : "dd/MM/yyyy" }}</b>
                    <!-- &nbsp; {{ orderdata1.EMPLOYEE_NAME }},
                    {{ orderdata1.DESIGNATION }} , seek condonation of delay for
                    filing the medical bill out of time -->
                    &nbsp; vide letter dt
                    <b>{{ queData.LETTER_DATE | date : "dd/MM/yyyy" }}</b> and
                    received in this office dt
                    <b>{{ queData.RECEIVED_DATE | date : "dd/MM/yyyy" }}</b
                    >, {{ orderdata1.EMPLOYEE_NAME }},
                    {{ orderdata1.DESIGNATION }} has required to seek
                    condonation of delay for filing the medical bill out of
                    time.
                  </p>
                  <p style="margin-bottom: 2px; text-align: justify">
                    2.It is seen from the papers on record that
                    {{ orderdata1.PATIENT_NAME }},
                    <!-- <span *ngIf="orderdata1.RELATION_WITH_PATIENT != 'Self'"> -->
                    {{ orderdata1.RELATION_WITH_PATIENT }} of
                    <!-- </span
                    > -->

                    <!-- &nbsp; for&nbsp;{{ orderdata1.NATURE_OF_TREATMENT }} -->
                    <!-- <span *ngIf="orderdata1.RELATION_WITH_PATIENT == 'Self'">
                      of Self</span> -->
                    {{ orderdata1.EMPLOYEE_NAME }},
                    {{ orderdata1.DESIGNATION }} had taken treatment from
                    {{ HospitalMapping.NAME }} {{ HospitalMapping.ADDRESS }}
                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <span *ngIf="data.IS_VISIBLE_IN_ORDER == true">
                        {{ data.NAME }} ,{{ data.ADDRESS }} ,{{
                          data.TYPE == "E"
                            ? "Empanelled"
                            : data.TYPE == "NE"
                            ? "Non Empanelled"
                            : data.TYPE == "G"
                            ? "Government"
                            : ""
                        }}
                        <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                        <!-- <span *ngIf="HospitalMapping.length - 2 == k">and</span> -->
                      </span>
                    </span>
                    from {{ orderdata1.TREATMENT_START_DATE }} to
                    {{ orderdata1.TREATMENT_END_DATE }} As per the revised time
                    limit prescribed by the Ministry of Health & Family Welfare
                    vide Office Memorandum No 1-60/2017-CGHS/C&P/EHS dt
                    01.06.2020 <b>(F/B)</b>, the official/ officer is required
                    to submit claim within 6 months from the date of completion
                    of medical treatment/discharge of the patient from the
                    hospital. In the instant case, the claim is submitted on
                    <b>{{
                      queData.CLAIM_SUBMITTED_DATE | date : "dd/MM/yyyy"
                    }}</b>
                    and received in office on
                    <b>{{ queData.CLAIM_FILLED_DATE | date : "dd/MM/yyyy" }}</b>
                    where as it should have been filed on or before
                    <b>{{ sixMonthDate | date : "dd/MM/yyyy" }}</b
                    >. As such there is a delay of
                    <b>{{ dateBetweenDiff }}</b> days in filling the claim for
                    reimbursement of expenses.
                  </p>
                  <p style="margin-bottom: 2px; text-align: justify">
                    3.&nbsp;&nbsp;&nbsp;
                    <b>{{ queData.CLAIM_REASON }}</b>
                  </p>
                  <p style="margin-bottom: 2px; text-align: justify">
                    4. Considering the explanation given for delay in submission
                    of reimbursement claim and as per Office Memorandum No S
                    14025/8/99/MS dated 25.05.1999 <b>(F/C)</b>, the power is
                    vested with the HOD to condone the delay in submission of
                    medical bills, after giving a reasonable opportunity of
                    being heard to the applicant Considering the facts and
                    circumstances of the case, the reason attributed for delay
                    in submission of the claim appears to be convincing and
                    hence delay may kindly be condoned, if approved. The order
                    for condonation of delay is put up for approval and
                    signature, please
                  </p>

                  <div style="margin-top: 100px; font-size: 13px">
                    <div nz-row>
                      <div nz-col nzSpan="15"></div>

                      <div nz-col nzSpan="9" style="text-align: center">
                        <div *ngIf="SECTION_TYPE == 'welfare'">
                          <b>(Ritesh Kumar)</b>
                        </div>
                        <div *ngIf="SECTION_TYPE == 'gazpay'">
                          <b>(Jitendra R Chandekar)</b>
                        </div>
                        <div style="font-weight: bold; font-size: 13px">
                          <span *ngIf="SECTION_TYPE == 'welfare'">
                            ITO (HQ) Welfare & SG, Mumbai.</span
                          >
                          <span *ngIf="SECTION_TYPE == 'gazpay'"
                            >ITO Gaz Pay Bill Section II, Mumbai</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      style="
                        page-break-inside: avoid !important;
                        font-size: 13px;
                        page-break-before: avoid !important;
                      "
                    >
                      <div style="margin-top: 50px">
                        <b>Jt.CIT (HQ) Persennel, Mumbai </b>
                      </div>
                    </div>
                  </div>
                  <div
                    style="
                      page-break-inside: avoid !important;
                      font-size: 13px;
                      page-break-before: avoid !important;
                    "
                  >
                    <div style="margin-top: 65px">
                      <b
                        >Commisioner of Income Tax (Administration & TPS),
                        Mumbai
                      </b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>
  </ng-template>

  <ng-template #printOrderModalFooter>
    <button nz-button nzType="default" (click)="printOrderModalCancel()">
      Close
    </button>

    <button
      nz-button
      nzType="default"
      [useExistingCss]="true"
      printSectionId="printOrderModal"
      ngxPrint
    >
      Print
    </button>

    <button
      nz-button
      nzType="primary"
      [nzLoading]="pdfDownload"
      (click)="generatePDF()"
    >
      Download
    </button>
  </ng-template>
</nz-modal>
