export class form2 {
  ID: number;
  // DATE: any;
  // FROM_PLACE_NAME: string;
  // TO_PLACE_NAME: string;
  // TO_ENTITLED: any;
  // BY_TRAYLLED: any;
  // FARE_PAID: any;

  EMP_ID: any;
  DATE: any;

  STATION_FROM: any;
  STATION_TO: any;
  WEIGHT: any;
  RATE: any;
  AMOUNT: any;
  REMARK: any;

  TRANSFER_ID: any;

  TRAVEL_MODE_ID: any;
  STATION_NAME: any;
  ADVANCED_AMOUNT: any;
  IS_ADVANCE_TAKEN: any;
  ADVANCE_TAKEN_DATE: any;

  IS_HIGHER_CLASS_ACCOMODATION: any;

  FROM_PLACE: any;
  TO_PLACE: any;

  ENTITLED_TRAVEL_CLASS_ID: any;
  TRAVELLED_TRAVEL_CLASS_ID: any;
  FARE_OF_ENTITLED_CLASS: any;
  TOTAL_AMOUNT: any;
  IS_DELETED: any;
  TRANSPORTATION_CLAIMED_AMOUNT:any=0;
}
