import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { EmployeeMaster } from 'src/app/Models/Employee';
import { QuestionaryMaster } from 'src/app/Models/questionarymaster';
import { ApiService } from 'src/app/Service/api.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { ApplicantMaster } from 'src/app/Models/applicantmaster';
import { CheckList } from 'src/app/Models/checkList';
import { ClaimMaster } from 'src/app/Models/claimmaster';
import { CookieService } from 'ngx-cookie-service';
import { HospitalMappingMaster } from 'src/app/Models/hospitalmappingmaster';
import { form1 } from 'src/app/Models/form1';
import { form3 } from 'src/app/Models/form3';
import { form2 } from 'src/app/Models/form2';
import { Travelmode } from 'src/app/Models/travelmode';
import { Journeydetails } from 'src/app/Models/journeydetails';
import { TranferapplicationMaster } from 'src/app/Models/transferapplication';
import { Realtionshipdata } from 'src/app/Models/relationship';
import { TransferCheckList } from 'src/app/Models/TransferChecklist';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

export class FromTOAccount {
  // ID: number;
  CLIENT_ID: number = 1;

  AGE: number = 0;

  NAME_OF_FAMILY_MEMBER: String = '';

  RELATIONSHIP: String = '';
}
@Component({
  selector: 'app-transferadd',
  templateUrl: './transferadd.component.html',
  styleUrls: ['./transferadd.component.css'],
})
export class TransferaddComponent implements OnInit {
  constructor(
    private message: NzNotificationService,
    private api: ApiService,
    private datepipe: DatePipe,
    public cookie: CookieService,
    private sanitizer: DomSanitizer
  ) {}
  namepatt = /[a-zA-Z][a-zA-Z ]+/;
  emailpattern =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  @Input() isSpinning = false;
  @Input() relationdata: any = [];
  // @Input() Hospitalclaim: any = [];
  @Input() journey: form2;
  isOk = true;
  imgurl = this.api.retriveimgUrl;
  userId: any;
  ngOnInit(): void {
    this.userId = Number(sessionStorage.getItem('userId'));
    this.current = 0;
    this.isSpinning = false;
    if (this.current == 0) {
      this.allEmployeeList();
    } else {
      this.fileNumberList();
      this.allHospitalList();
      this.api.gettravelmode(0, 0, '', 'asc', '').subscribe((data) => {
        if (data['code'] == 200) {
          this.travelmode = data['data'];
        }
      });
    }
  }
  @Input()
  drawerVisible: boolean = false;
  empDrawerVisible: boolean = false;
  @Input() drawerClose: Function;
  @Input() data: TranferapplicationMaster;

  @Input() empID: any;
  @Input() claimID: any;
  @Input() currentStageID: any;
  // @Input() data2: ClaimMaster;
  //  data2: ClaimMaster;
  // @Input() data3: QuestionaryMaster;
  data2: ClaimMaster = new ClaimMaster();
  editrelation: Realtionshipdata = new Realtionshipdata();
  data3: QuestionaryMaster = new QuestionaryMaster();
  checkData: TransferCheckList = new TransferCheckList();
  hospitalData: HospitalMappingMaster = new HospitalMappingMaster();
  // @Input() data4: CheckList;
  //  data4: CheckList;
  data4: CheckList = new CheckList();

  @Input() current = 0;
  employee: EmployeeMaster[] = [];

  Cities: EmployeeMaster[];
  filteredOptions = [];
  Names = EmployeeMaster;
  hospitalList: any = [];
  diffDays: any;
  date1: any;
  date2: any;
  expoDiffDays: any;
  TREATEMENT_TYPE1 = false;
  TREATEMENT_TYPE2 = false;
  CGHS_AMA_REFERENCE_DATE: any = new Date();
  CGHS_AMA_REFERENCE_END_DATE: any = new Date();
  empLoader: boolean = false;
  orderdata1 = [];
  travelmode = new Travelmode();
  drawerTitleform1!: string;
  drawerVisible2form1: boolean = false;
  drawerData2form1: form1 = new form1();
  addform1(): void {
    this.drawerTitleform1 = 'Add Journey Details';
    this.drawerData2form1 = new form1();
    this.drawerVisible2form1 = true;
  }

  drawerClose2form1(): void {
    this.getDataform1();
    this.drawerVisible2form1 = false;
  }

  get closeCallbackform1form1() {
    return this.drawerClose2form1.bind(this);
  }

  drawerTitleform2!: string;
  drawerVisibleform2: boolean = false;
  drawerDataform2: form2 = new form2();
  addform2(): void {
    this.drawerTitleform2 = 'Add New Higher Class of Accommodation';
    this.drawerDataform2 = new form2();
    this.drawerVisibleform2 = true;
  }

  drawerCloseform2(): void {
    this.getDataform2();
    this.drawerVisibleform2 = false;
  }

  get closeCallbackform2() {
    return this.drawerCloseform2.bind(this);
  }

  editform2(data: form2): void {
    this.drawerTitleform2 = 'Edit Higher Class of Accommodation Details';
    this.drawerDataform2 = Object.assign({}, data);
    this.api
      .gettravelclass(
        0,
        0,
        '',
        '',
        ' AND STATUS=1 AND MODE_ID = ' + data.TRAVEL_MODE_ID
      )
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            this.classdata = data['data'];
          }
        },
        (err) => {
          console.log(err);
        }
      );
    this.drawerVisibleform2 = true;
  }

  drawerTitleform3!: string;
  drawerVisibleform3: boolean = false;
  drawerDataform3: form3 = new form3();

  addform3(): void {
    this.drawerTitleform3 = 'Add Transportion Charges';
    this.drawerDataform3 = new form3();
    this.drawerVisibleform3 = true;
  }

  drawerCloseform3(): void {
    this.getDataform3();
    this.drawerVisibleform3 = false;
  }

  get closeCallbackform3() {
    return this.drawerCloseform3.bind(this);
  }

  drawerTitledetailsjorney: string = '';
  detailsjorneyDrawerVisible: boolean = false;
  detailsJorneyDrawerData: Journeydetails = new Journeydetails();
  gradePayLevel: any = '';
  adddetailsjorney(): void {
    this.empID = this.empID;
    this.claimID = this.claimID;
    this.gradePayLevel = this.data.GRADE_PAY_LEVEL;
    this.drawerTitledetailsjorney = 'Add Details of Journeys(s)';
    this.detailsJorneyDrawerData = new Journeydetails();
    this.detailsjorneyDrawerVisible = true;
  }

  detailsjorneyDrawerClose(): void {
    this.detailsJorneyGetData();
    this.detailsjorneyDrawerVisible = false;
  }

  get closeCallbackdetailsjorney() {
    return this.detailsjorneyDrawerClose.bind(this);
  }

  formdata3: any = [];
  formdata2: any = [];
  formdata1: any = [];
  formdata7: any = [];
  getDataform3() {
    this.loadingRecords = true;
    this.api
      .gettransfarchnagedetailspersonal(
        0,
        0,
        '',
        'asc',
        ' AND TRANSFER_ID =' + this.claimID
      )
      .subscribe((data) => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.formdata3 = data['data'];
        }
      });
  }

  getDataform2() {
    this.loadingRecords = true;
    this.api
      .gettransfortation(0, 0, '', 'asc', ' AND TRANSFER_ID =' + this.claimID)
      .subscribe((data) => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.formdata2 = data['data'];
        }
      });
  }

  // SHOW_TABLE: boolean = false;
  // showtable(event: { SHOW_TABLE: boolean; }) {
  //   event.IS_HIGHER_CLASS_ACCOMODATION = !event.SHOW_TABLE;
  // }

  getDataform1() {
    this.loadingRecords = true;
    this.api
      .gettransfarchnagedetails1(
        0,
        0,
        '',
        'asc',
        ' AND TRANSFER_ID =' + this.claimID
      )
      .subscribe((data) => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.formdata1 = data['data'];
        }
      });
  }

  detailsJorneyGetData() {
    this.loadingRecords = true;
    this.api
      .getjourneydetails(0, 0, '', 'asc', ' AND TRANSFER_ID =' + this.claimID)
      .subscribe((data) => {
        if (data['code'] == 200) {
          this.loadingRecords = false;

          this.formdata7 = data['data'];
        }
      });
  }

  getrelationtable() {
    this.loadingRecords = true;
    this.api
      .getrelationtable(0, 0, '', 'asc', ' AND TRANSFER_ID =' + this.claimID)
      .subscribe((data) => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.relationdata = data['data'];
        }
      });
  }
  getTimeIn12Hour(time: any) {
    return this.datepipe.transform(new Date(), 'yyyy-MM-dd' + ' ' + time);
  }
  checkboxdatais: boolean = false;
  chechboxdata(event: any) {
    this.data.CERTIFIED_INFORMATION = event;
  }

  showtable1(event: any) {
    this.data.IS_HIGHER_CLASS_ACCOMODATION = event;
  }

  editform3(data: form3): void {
    this.drawerTitleform3 = 'Edit Transportion Charges';
    this.drawerDataform3 = Object.assign({}, data);
    this.drawerVisibleform3 = true;
  }

  classdata: any;
  editform7(data: Journeydetails): void {
    this.gradePayLevel = this.data.GRADE_PAY_LEVEL;
    this.drawerTitledetailsjorney = 'Edit Details of Journeys(s)';
    this.detailsJorneyDrawerData = Object.assign({}, data);
    this.api
      .gettravelclass(
        0,
        0,
        '',
        '',
        ' AND STATUS=1 AND MODE_ID=' + data.TRAVEL_MODE_ID
      )
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            this.classdata = data['data'];
          }
        },
        (err) => {
          console.log(err);
        }
      );
    this.detailsjorneyDrawerVisible = true;
  }

  editform1(data: form1): void {
    this.drawerTitleform1 = 'Edit Journey Details';
    this.drawerData2form1 = Object.assign({}, data);
    this.drawerVisible2form1 = true;
  }

  allEmployeeList() {
    this.empLoader = true;

    this.api.getEmployeeMaster(0, 0, '', '', ' AND STATUS = 1').subscribe(
      (data) => {
        if (data['code'] == 200) {
          this.employee = data['data'];
          this.filteredOptions = this.employee;
          this.empLoader = false;
        } else {
          this.message.error("Can't Load Employee Data", '');
          this.empLoader = false;
        }
      },
      (err) => {
        console.log(err);
      }
    );
    if (this.data.EMP_ID != null || this.data.EMP_ID != undefined) {
      this.api
        .getEmployeeMaster(0, 0, '', '', ' AND ID = ' + this.data.EMP_ID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              this.data.OFFICE_NAME = data['data'][0]['OFFICE_NAME'];
              this.data.DESIGNATION = data['data'][0]['DESIGNATION'];
              this.data.EMPLOYEE_CODE = data['data'][0]['EMPLOYEE_CODE'];
              this.data.LOCATION = data['data'][0]['LOCATION'];
              this.data.DDO_OF_THE_OFFICIAL =
                data['data'][0]['DDO_OF_THE_OFFICIAL'];
              this.data.GRADE_PAY = data['data'][0]['GRADE_PAY'];
              this.data.EMAIL_ID = data['data'][0]['EMAIL_ID'];
              this.data.MOBILE_NO = data['data'][0]['MOBILE_NO'];
              this.data.ADDRESS = data['data'][0]['ADDRESS'];
              this.data.EMPLOYEE_NAME = data['data'][0]['NAME'];
            } else {
              this.message.error("Can't Load Employee Data", '');
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
    }
  }

  allHospitalList() {
    this.api.getAllHospital(0, 0, '', '', ' AND STATUS = 1').subscribe(
      (data) => {
        if (data['code'] == 200) {
          this.hospitalList = data['data'];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  close(): void {
    this.current = 0;
    this.drawerClose();
  }

  applicantResetDrawer(applicantMasterPages: NgForm) {
    this.data = new TranferapplicationMaster();
    // this.data.PARENT_GROUP_ID=this.parentgroup;
    // applicantMasterPages.form.markAsPristine();
    // applicantMasterPages.form.markAsUntouched();
    applicantMasterPages.form.reset();
  }
  claimResetDrawer(claimMasterPage: NgForm) {
    this.data2 = new ClaimMaster();
    // this.data.PARENT_GROUP_ID=this.parentgroup;
    // claimMasterPage.form.markAsPristine();
    // claimMasterPage.form.markAsUntouched();
    claimMasterPage.form.reset();
  }
  queResetDrawer(queMasterPage: NgForm) {
    this.data3 = new QuestionaryMaster();
    // this.data.PARENT_GROUP_ID=this.parentgroup;
    // queMasterPage.form.markAsPristine();
    // queMasterPage.form.markAsUntouched();
    queMasterPage.form.reset();
  }
  checkResetDrawer(checkListMasterPage: NgForm) {
    this.data4 = new CheckList();
    // this.data.PARENT_GROUP_ID=this.parentgroup;
    // checkListMasterPage.form.markAsPristine();
    // checkListMasterPage.form.markAsUntouched();
    checkListMasterPage.form.reset();
  }
  // close(applicantMasterPages: NgForm,claimMasterPage: NgForm,queMasterPage: NgForm,checkListMasterPage: NgForm) {

  //  this.current = 0;
  //   this.applicantResetDrawer(applicantMasterPages);
  //   this.claimResetDrawer(claimMasterPage);
  //   this.queResetDrawer(queMasterPage);
  //   this.checkResetDrawer(checkListMasterPage);
  //   // claimMasterPage.form.reset();
  //   this.drawerClose();
  // }

  omit(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  //// Only number and dot
  onlynumdot(event: any) {
    event = event ? event : window.event;
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 46 || charCode > 57)) {
      return false;
    }
    return true;
  }
  dateOmit(event: any) {
    return false;
  }

  save(addNew: boolean, claimMasterPage: NgForm): void {
    if (this.current == 0) {
      this.current = 1;
    } else if (this.current == 1) {
      this.current = 2;
    } else if (this.current == 2) {
      this.current = 3;
    }
    this.isSpinning = false;
    this.isOk = true;

    if (
      this.data.NAME.trim() == '' &&
      this.data.LOCATION.trim() == '' &&
      this.data.DDO_OF_THE_OFFICIAL.trim() == '' &&
      this.data.DESIGNATION.trim() == '' &&
      this.data.OFFICE_NAME.trim() == '' &&
      this.data.EMPLOYEE_CODE == 0 &&
      this.data.GRADE_PAY == 0
    ) {
      this.isOk = false;
      this.message.error('Please Fill All The Required Fields ', '');
    } else if (this.data.NAME == null || this.data.NAME.trim() == '') {
      this.isOk = false;
      this.message.error(' Please Enter Employee Name.', '');
    } else if (
      this.data.EMPLOYEE_CODE == undefined ||
      this.data.EMPLOYEE_CODE <= 0
    ) {
      this.isOk = false;
      this.message.error('Please Enter Employee Code  ', '');
    } else if (this.data.GRADE_PAY == undefined || this.data.GRADE_PAY <= 0) {
      this.isOk = false;
      this.message.error('Please Enter Grade Pay  ', '');
    } else if (
      this.data.OFFICE_NAME == null ||
      this.data.OFFICE_NAME.trim() == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Office Name.', '');
    } else if (
      this.data.DESIGNATION == null ||
      this.data.DESIGNATION.trim() == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Designation', '');
    } else if (this.data.LOCATION == null || this.data.LOCATION.trim() == '') {
      this.isOk = false;
      this.message.error(' Please Enter Location', '');
    } else if (
      this.data.DDO_OF_THE_OFFICIAL == null ||
      this.data.DDO_OF_THE_OFFICIAL.trim() == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Designation', '');
    }

    if (this.isOk) {
      this.isSpinning = true;
      {
        if (this.data.ID) {
          this.api.updateEmployeeMaster(this.data).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information Changed Successfully...', '');
              if (!addNew) this.drawerClose();
              this.isSpinning = false;
            } else {
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
        } else {
          this.api.createEmployeeMaster(this.data).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information Save Successfully...', '');
              if (!addNew) this.drawerClose();
              else {
                this.data = new TranferapplicationMaster();
                // this.resetDrawer(claimMasterPage);
                // this.data.IMG_URL= '';

                this.api.getEmployeeMaster(0, 0, '', 'desc', '').subscribe(
                  (data) => {
                    // if (data['count']==0){
                    //   this.data.SEQUENCE_NUMBER=1;
                    // }else
                    // {
                    //   this.data.SEQUENCE_NUMBER=data['data'][0]['SEQUENCE_NUMBER']+1;
                    // }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              }
              this.isSpinning = false;
            } else {
              this.message.error('Failed To Fill Information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
  }
  STATION_NAME: string = '';
  TOTAL_AMOUNT: any;
  ADVANCE_TAKEN_DATE: any;
  ADVANCED_AMOUNT: any;
  AMOUNT: any;
  AlldataSave(addNew: boolean, modedataform: NgForm, stage: string) {
    this.isOk = true;
    this.isSpinning = false;
    // if (
    //   this.formdata7 == 0 &&
    //   this.formdata3 == 0 &&
    //   this.formdata1 == 0 &&
    //   this.data.TRAVEL_MODE_ID == 0 &&
    //   this.data.STATION_NAME.trim() == '' &&
    //   this.data.AMOUNT == 0
    // ) {
    //   this.isOk = false;
    //   this.message.error('Please Fill All The Required Fields ', '');
    // } else if (this.formdata7 == null || this.formdata7 <= 0) {
    //   this.isOk = false;
    //   this.message.error(' Details of Journeys(s)', '');
    // } else if (this.formdata3 == null || this.formdata3 <= 0) {
    //   this.isOk = false;
    //   this.message.error(' Transportion Charges of personal effects', '');
    // } else if (this.formdata1 == null || this.formdata1 <= 0) {
    //   this.isOk = false;
    //   this.message.error(
    //     'Details Journey(s) performed by road between place connected by Rail',
    //     ''
    //   );
    // } else if (
    //   this.data.TRAVEL_MODE_ID == null ||
    //   this.data.TRAVEL_MODE_ID <= 0
    // ) {
    //   this.isOk = false;
    //   this.message.error(' Please Select Travel Mode', '');
    // } else if (
    //   this.data.STATION_NAME == null ||
    //   this.data.STATION_NAME.trim() == ''
    // ) {
    //   this.isOk = false;
    //   this.message.error(' Please Enter Station Name', '');
    // } else if (this.data.AMOUNT == null || this.data.AMOUNT <= 0) {
    //   this.isOk = false;
    //   this.message.error(' Please Enter Amout', '');
    // } else
    if (
      this.data.IS_ADVANCE_TAKEN == true &&
      (this.data.ADVANCED_AMOUNT == undefined ||
        this.data.ADVANCED_AMOUNT == null ||
        this.data.ADVANCED_AMOUNT == '')
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Advance Amount.', '');
    } else if (
      this.data.IS_ADVANCE_TAKEN == true &&
      (this.data.ADVANCE_TAKEN_DATE == undefined ||
        this.data.ADVANCE_TAKEN_DATE == null)
    ) {
      this.isOk = false;
      this.message.error('Please Select Date Of Advance Taken.  ', '');
    }

    if (this.data.ADVANCE_TAKEN_DATE == undefined) {
      this.data.ADVANCE_TAKEN_DATE = null;
    } else {
      this.data.ADVANCE_TAKEN_DATE = this.datepipe.transform(
        this.data.ADVANCE_TAKEN_DATE,
        'yyyy-MM-dd'
      );
    }
    // this.data.STATION_NAME = this.STATION_NAME;
    // this.data.CERTIFIED_INFORMATION = this.checkboxdatais;
    // this.data.TOTAL_AMOUNT = this.TOTAL_AMOUNT;
    // this.data.AMOUNT = this.AMOUNT;
    // this.data.ADVANCE_TAKEN_DATE = this.ADVANCE_TAKEN_DATE;
    // this.data.ADVANCED_AMOUNT = this.ADVANCED_AMOUNT;
    this.data.ID = this.claimID;
    if (this.isOk && this.data.ID != undefined) {
      if (this.data.IS_ADVANCE_TAKEN == false) {
        this.data.ADVANCE_TAKEN_DATE = null;
        this.data.ADVANCED_AMOUNT = 0;
      }
      if (stage == 'V') {
        this.data.CURRENT_STAGE_ID = 3;
      }
      this.data.TRANSFER_ID = undefined;
      this.api.updatetransfer(this.data).subscribe((successCode) => {
        if (successCode.code == '200') {
          // this.drawerClose();
          this.next();
          this.resetDrawer(modedataform);
          this.message.success('Information Saved Successfully...', '');
        } else {
          this.message.error('Information Has Not Saved...', '');

          this.isSpinning = false;
        }
      });
    }
  }

  FILE_NO: any;
  AlldataSave1(addNew: boolean, modedataform: NgForm, stage: string) {
    this.isOk = true;
    this.isSpinning = false;

    // this.data.FILE_NO = this.FILE_NO;

    this.data.ID = this.claimID;
    this.data.CURRENT_STAGE_ID = 4;
    if (this.isOk && this.data.ID != undefined) {
      this.data.TRANSFER_ID = undefined;
      this.api.updatetransfer(this.data).subscribe((successCode) => {
        if (successCode.code == '200') {
          this.current = 0;
          this.drawerClose();
          // this.next();
          this.resetDrawer(modedataform);
          this.message.success('Information Saved Successfully...', '');
        } else {
          this.message.error('Information Has Not Saved...', '');

          this.isSpinning = false;
        }
      });
    }
  }

  savepdf1(addNew: boolean, modedataform: NgForm, stage: string) {
    // this.data.FILE_NO = this.FILE_NO;

    // if (this.data.FILE_URL.trim() == '') {
    //   this.isOk = false;
    //   this.message.error('Please Fill All Required Fields.', '');
    // } else if (this.data.FILE_URL == null || this.data.FILE_URL.trim() == '') {
    //   this.isOk = false;
    //   this.message.error('Please Select Pdf File', '');
    // }
    if (this.isOk && this.data.ID != undefined) {
      this.isSpinning = true;

      if (this.fileURL1 != null) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.fileURL1.name.split('.').pop();
        var d = this.datepipe.transform(new Date(), 'yyyyMMdd');
        var url = '';
        url = d == null ? '' : d + number + '.' + fileExt;
        if (
          this.data.FILE_URL != undefined &&
          this.data.FILE_URL.trim() != ''
        ) {
          var arr = this.data.FILE_URL.split('/');
          if (arr.length > 1) {
            url = arr[5];
          }
        }
        this.api
          .onUpload('transferFile', this.fileURL1, url)
          .subscribe((successCode) => {
            if (successCode.code == '200') {
              this.data.FILE_URL = url;
              // appkeys.retriveimgUrl + 'DownloadsFile/' + url;

              if (this.data.ID) {
                this.data.TRANSFER_ID = undefined;
                this.api.updatetransfer(this.data).subscribe((successCode) => {
                  this.isSpinning = false;
                  if (successCode.code == '200') {
                    this.current = 0;
                    this.drawerClose();
                    // this.next();
                    this.resetDrawer(modedataform);
                    this.message.success(
                      'Information Saved Successfully...',
                      ''
                    );
                  } else {
                    this.message.error('Information Has Not Saved...', '');
                  }
                });
              } else {
              }
            } else {
              this.message.error('Failed To Save PDF File...', '');
              this.isSpinning = false;
            }
          });
      } else if (this.data.FILE_URL == null || this.data.FILE_URL == '') {
        this.message.error('Please Select File', '');
        this.isSpinning = false;
      } else {
        if (this.data.ID) {
          this.api.updatetransfer(this.data).subscribe((successCode) => {
            this.isSpinning = false;
            if (successCode.code == '200') {
              this.current = 0;
              this.drawerClose();
              // this.next();
              this.resetDrawer(modedataform);
              this.message.success('Information Saved Successfully...', '');
            } else {
              this.message.error('Information Has Not Saved...', '');
            }
          });
        } else {
        }
      }
    }
  }

  grantamount(event: any) {
    if (event != undefined || event != null || event != '') {
      if (event <= 100) {
        // this.data.TRANSFER_GRANT = null;
        this.data.TRANSFER_GRANT_AMOUNT = (this.data.GRADE_PAY * event) / 100;
        // this.data.TRANSFER_GRANT_AMOUNT = gamount;
        // this.data.TRANSFER_GRANT = '80% of ' + event;
      } else {
        this.message.error("Can't Type More Than 100%", '');
        this.data.TRANSFER_GRANT = 0;
        this.data.TRANSFER_GRANT_AMOUNT = 0;
        // event = 100;
      }
    } else {
      this.data.TRANSFER_GRANT_AMOUNT = 0;
    }
  }
  grantamount1(event: any) {
    this.data.TRANSFER_GRANT = null;
    var gamount = (event * 80) / 100;
    this.data.TRANSFER_GRANT_AMOUNT = gamount;
    this.data.TRANSFER_GRANT = '80% of ' + event;
  }
  saveFileDetails(addNew: boolean, modedataform: NgForm, stage: string) {
    // this.data.FILE_NO = this.FILE_NO;

    // if (this.data.FILE_URL.trim() == '') {
    this.isOk = true;
    //   this.message.error('Please Fill All Required Fields.', '');
    // } else if (this.data.FILE_URL == null || this.data.FILE_URL.trim() == '') {
    //   this.isOk = false;
    //   this.message.error('Please Select Pdf File', '');
    // }
    if (this.isOk && this.data.ID != undefined) {
      this.isSpinning = true;
      if (this.data.ID) {
        this.data.TRANSFER_ID = undefined;
        this.api.updatetransfer(this.data).subscribe((successCode) => {
          if (successCode.code == '200') {
            this.message.success('Information Saved Successfully...', '');
            this.isSpinning = false;
            // this.drawerClose();
            this.next();
            this.resetDrawer(modedataform);
          } else {
            this.message.error('Information Has Not Saved...', '');
            this.isSpinning = false;
          }
        });
      } else {
      }
      ///////////////////////////
      // if (this.fileURL1 != null) {
      //   var number = Math.floor(100000 + Math.random() * 900000);
      //   var fileExt = this.fileURL1.name.split('.').pop();
      //   var d = this.datepipe.transform(new Date(), 'yyyyMMdd');
      //   var url = '';
      //   url = d == null ? '' : d + number + '.' + fileExt;
      //   if (
      //     this.data.FILE_URL != undefined &&
      //     this.data.FILE_URL.trim() != ''
      //   ) {
      //     var arr = this.data.FILE_URL.split('/');
      //     if (arr.length > 1) {
      //       url = arr[5];
      //     }
      //   }
      //   this.api
      //     .onUpload('transferFile', this.fileURL1, url)
      //     .subscribe((successCode) => {
      //       if (successCode.code == '200') {
      //         this.data.FILE_URL = url;
      //         // appkeys.retriveimgUrl + 'DownloadsFile/' + url;

      //         if (this.data.ID) {
      //           this.data.TRANSFER_ID = undefined;
      //           this.api.updatetransfer(this.data).subscribe((successCode) => {
      //             this.isSpinning = false;
      //             if (successCode.code == '200') {
      //               this.current = 0;
      //               this.drawerClose();
      //               // this.next();
      //               this.resetDrawer(modedataform);
      //               this.message.success(
      //                 'Information Saved Successfully...',
      //                 ''
      //               );
      //             } else {
      //               this.message.error('Information Has Not Saved...', '');
      //             }
      //           });
      //         } else {
      //         }
      //       } else {
      //         this.message.error('Failed To Save PDF File...', '');
      //         this.isSpinning = false;
      //       }
      //     });
      // } else if (this.data.FILE_URL == null || this.data.FILE_URL == '') {
      //   this.message.error('Please Select File', '');
      //   this.isSpinning = false;
      // } else {
      //   if (this.data.ID) {
      //     this.api.updatetransfer(this.data).subscribe((successCode) => {
      //       this.isSpinning = false;
      //       if (successCode.code == '200') {
      //         this.current = 0;
      //         this.drawerClose();
      //         // this.next();
      //         this.resetDrawer(modedataform);
      //         this.message.success('Information Saved Successfully...', '');

      //       } else {
      //         this.message.error('Information Has Not Saved...', '');

      //       }
      //     });
      //   } else {
      //   }
      // }
    }
  }

  empAllDataForUpdate: any;
  empSave(addNew: boolean, claimMasterPage: NgForm): void {
    this.data.ORDER_FORM_REMARK = this.data.ORDER_FORM_REMARK;
    this.data.ORDER_FORM_REMARK_2 = this.data.ORDER_FORM_REMARK_2;
    this.isSpinning = false;
    this.data.INSPECTOR_ID = Number(sessionStorage.getItem('userId'));
    this.isOk = true;
    this.data.memberTransferData = this.relationdata;
    if (
      this.data.EMP_ID == undefined &&
      this.data.DESIGNATION.trim() == '' &&
      this.data.GRADE_PAY == 0 &&
      this.data.GRADE_PAY_LEVEL == undefined
    ) {
      this.isOk = false;
      this.message.error('Please Fill All The Required Fields ', '');
    } else if (
      this.data.EMP_ID == undefined ||
      this.data.EMP_ID == null ||
      this.data.EMP_ID == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Select Employee Name', '');
    } else if (
      this.data.DESIGNATION == undefined ||
      this.data.DESIGNATION == null ||
      this.data.DESIGNATION == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Designation', '');
    } else if (
      this.data.GRADE_PAY == undefined ||
      this.data.GRADE_PAY == null ||
      this.data.GRADE_PAY == 0
    ) {
      this.isOk = false;
      this.message.error(' Please Select Grade Pay', '');
    } else if (
      this.data.GRADE_PAY_LEVEL == undefined ||
      this.data.GRADE_PAY_LEVEL == null ||
      this.data.GRADE_PAY_LEVEL == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Select Grade Pay Level', '');
    }
    // else if (
    //   this.data.memberTransferData == undefined ||
    //   this.data.memberTransferData.length == 0
    // ) {
    //   this.isOk = false;
    //   this.message.error('Please Add Family Information Information ', '');
    // }

    if (this.isOk) {
      if (
        this.data.memberTransferData == undefined ||
        this.data.memberTransferData.length == 0
      ) {
      } else {
        for (var i = 0; this.relationdata.length > i; i++) {
          this.relationdata[i]['ID'] = undefined;
        }
        this.data.memberTransferData = this.relationdata;
      }

      this.isSpinning = true;
      if (
        this.data.OFFICE_NAME == undefined ||
        this.data.OFFICE_NAME == null ||
        this.data.OFFICE_NAME == '' ||
        this.data.OFFICE_NAME.trim() == ''
      ) {
        this.data.OFFICE_NAME = null;
      }

      if (
        this.data.EMAIL_ID == undefined ||
        this.data.EMAIL_ID == null ||
        this.data.EMAIL_ID == '' ||
        this.data.EMAIL_ID.trim() == ''
      ) {
        this.data.EMAIL_ID = null;
      }
      if (
        this.data.EMPLOYEE_CODE == undefined ||
        this.data.EMPLOYEE_CODE == null ||
        this.data.EMPLOYEE_CODE == '' ||
        this.data.EMPLOYEE_CODE.trim() == ''
      ) {
        this.data.EMPLOYEE_CODE = ' ';
      }

      if (
        this.data.MOBILE_NO == undefined ||
        this.data.MOBILE_NO == null ||
        this.data.MOBILE_NO == '' ||
        this.data.MOBILE_NO.trim() == ''
      ) {
        this.data.MOBILE_NO = ' ';
      }

      if (
        this.data.ADDRESS == undefined ||
        this.data.ADDRESS == null ||
        this.data.ADDRESS == '' ||
        this.data.ADDRESS.trim() == ''
      ) {
        this.data.ADDRESS = ' ';
      }

      if (
        this.data.BENEFICIARY_TYPE == 'CS' &&
        (this.data.CGHS_CARD_NO != undefined ||
          this.data.CGHS_CARD_NO != null ||
          this.data.CGHS_CARD_NO != '')
      ) {
        this.data.CGHS_CARD_NO = null;
      }
      if (
        this.data.BENEFICIARY_TYPE == 'CS' &&
        (this.data.CGHS_CARD_VALIDITY != undefined ||
          this.data.CGHS_CARD_VALIDITY != null ||
          this.data.CGHS_CARD_VALIDITY != '')
      ) {
        this.data.CGHS_CARD_VALIDITY = null;
      }

      if (
        this.data.BENEFICIARY_TYPE == 'CG' &&
        (this.data.CGHS_CARD_VALIDITY == undefined ||
          this.data.CGHS_CARD_VALIDITY == null ||
          this.data.CGHS_CARD_VALIDITY == '')
      ) {
        this.data.CGHS_CARD_VALIDITY = null;
      }

      this.data.CGHS_CARD_VALIDITY = this.datepipe.transform(
        this.data.CGHS_CARD_VALIDITY,
        'yyyy-MM-dd'
      );
      {
        if (this.data.ID) {
          this.data['TRANSFER_ID'] = this.claimID;
          this.api
            .updatetransferempdetails(this.data)
            .subscribe((successCode) => {
              if (successCode.code == '200') {
                this.message.success('Information Saved Successfully...', '');

                this.empID = successCode.EMP_ID;
                this.claimID = successCode.TRANSFER_ID;
                this.next();
                this.isSpinning = false;
              } else if (successCode.code == '300') {
                this.message.error(
                  'Email ID or Mobile Number Already Registered...',
                  ''
                );
                this.isSpinning = false;
              } else {
                this.message.error('Information Has Not Saved...', '');
                this.isSpinning = false;
              }
            });
        } else {
          this.api.createEmployeeMaster(this.data).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information Save Successfully...', '');
              this.empID = successCode.EMPLOYEE;
              this.claimID = successCode.TRANSFER_ID;
              this.next();
              // if (!addNew)
              // this.drawerClose();
              // else {
              //   this.data = new ApplicantMaster();
              //   this.resetDrawer(claimMasterPage);
              //   // this.data.IMG_URL= '';

              //   this.api.getEmployeeMaster(0,0,'','desc','').subscribe (data =>{
              //     // if (data['count']==0){
              //     //   this.data.SEQUENCE_NUMBER=1;
              //     // }else
              //     // {
              //     //   this.data.SEQUENCE_NUMBER=data['data'][0]['SEQUENCE_NUMBER']+1;
              //     // }
              //   },err=>{
              //     console.log(err);
              //   })
              // }
              this.isSpinning = false;
            } else if (successCode.code == '300') {
              this.message.error(
                'Email ID or Mobile Number Already Registered...',
                ''
              );
              this.isSpinning = false;
            } else {
              this.message.error('Failed To Fill Information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
  }

  // empSave(addNew: boolean, claimMasterPage: NgForm): void {
  //   this.data.ORDER_FORM_REMARK = this.data.ORDER_FORM_REMARK;
  //   this.data.ORDER_FORM_REMARK_2 = this.data.ORDER_FORM_REMARK_2;
  //   this.isSpinning = false;
  //   this.data.INSPECTOR_ID = Number(sessionStorage.getItem('userId'));
  //   this.isOk = true;
  //   this.data.memberTransferData = this.relationdata;
  //   if (

  //     this.data.EMP_ID == undefined &&
  //     this.data.DESIGNATION == undefined &&
  //     this.data.EMPLOYEE_CODE == undefined &&
  //     this.data.DDO_OF_THE_OFFICIAL == undefined &&
  //     this.data.GRADE_PAY == 0 &&
  //     this.data.MOBILE_NO == undefined &&
  //     this.data.GRADE_PAY_LEVEL == undefined &&
  //     this.data.HEADQUARTERS_NAME == undefined &&
  //     this.data.ADDRESS == undefined &&
  //     this.data.NEW_HEADQUARTERS_NAME == undefined &&
  //     this.data.NEW_ADDRESS == undefined
  //   ) {
  //     this.isOk = false;
  //     this.message.error('Please Fill All The Required Fields ', '');
  //   } else if (
  //     this.data.EMP_ID == undefined ||
  //     this.data.EMP_ID == null ||
  //     this.data.EMP_ID == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Select Employee Name', '');
  //   } else if (
  //     this.data.DESIGNATION == undefined ||
  //     this.data.DESIGNATION == null ||
  //     this.data.DESIGNATION == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Enter Designation', '');
  //   } else if (
  //     this.data.EMPLOYEE_CODE == undefined ||
  //     this.data.EMPLOYEE_CODE == null ||
  //     this.data.EMPLOYEE_CODE == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Enter Employee Code', '');
  //   } else if (
  //     this.data.DDO_OF_THE_OFFICIAL == undefined ||
  //     this.data.DDO_OF_THE_OFFICIAL == null ||
  //     this.data.DDO_OF_THE_OFFICIAL == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Enter DDO Of The Official', '');
  //   } else if (
  //     this.data.GRADE_PAY == undefined ||
  //     this.data.GRADE_PAY == null ||
  //     this.data.GRADE_PAY == 0
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Select Grade Pay', '');
  //   } else if (
  //     this.data.MOBILE_NO == undefined ||
  //     this.data.MOBILE_NO == null ||
  //     this.data.MOBILE_NO == 0
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Select Grade Pay', '');
  //   } else if (
  //     this.data.GRADE_PAY_LEVEL == undefined ||
  //     this.data.GRADE_PAY_LEVEL == null ||
  //     this.data.GRADE_PAY_LEVEL == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Select Grade Pay Level', '');
  //   } else if (
  //     this.data.HEADQUARTERS_NAME == undefined ||
  //     this.data.HEADQUARTERS_NAME == null ||
  //     this.data.HEADQUARTERS_NAME == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Enter Old Head Quarter Name', '');
  //   } else if (
  //     this.data.ADDRESS == undefined ||
  //     this.data.ADDRESS == null ||
  //     this.data.ADDRESS == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Enter Old Residential Address', '');
  //   } else if (
  //     this.data.NEW_HEADQUARTERS_NAME == undefined ||
  //     this.data.NEW_HEADQUARTERS_NAME == null ||
  //     this.data.NEW_HEADQUARTERS_NAME == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Enter New Head Quarter Name', '');
  //   } else if (
  //     this.data.NEW_ADDRESS == undefined ||
  //     this.data.NEW_ADDRESS == null ||
  //     this.data.NEW_ADDRESS == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Enter New Residential Address', '');
  //   }
  //   // else if (
  //   //   this.data.memberTransferData == undefined ||
  //   //   this.data.memberTransferData.length == 0
  //   // ) {
  //   //   this.isOk = false;
  //   //   this.message.error('Please Add Family Information Information ', '');
  //   // }

  //   if (this.isOk) {
  //     if (
  //       this.data.memberTransferData == undefined ||
  //       this.data.memberTransferData.length == 0
  //     ) {
  //     } else {
  //       for (var i = 0; this.relationdata.length > i; i++) {
  //         this.relationdata[i]['ID'] = undefined;
  //       }
  //       this.data.memberTransferData = this.relationdata;
  //     }

  //     this.isSpinning = true;
  //     if (
  //       this.data.OFFICE_NAME == undefined ||
  //       this.data.OFFICE_NAME == null ||
  //       this.data.OFFICE_NAME == '' ||
  //       this.data.OFFICE_NAME.trim() == ''
  //     ) {
  //       this.data.OFFICE_NAME = null;
  //     }

  //     if (
  //       this.data.EMAIL_ID == undefined ||
  //       this.data.EMAIL_ID == null ||
  //       this.data.EMAIL_ID == '' ||
  //       this.data.EMAIL_ID.trim() == ''
  //     ) {
  //       this.data.EMAIL_ID = null;
  //     }
  //     if (
  //       this.data.EMPLOYEE_CODE == undefined ||
  //       this.data.EMPLOYEE_CODE == null ||
  //       this.data.EMPLOYEE_CODE == '' ||
  //       this.data.EMPLOYEE_CODE.trim() == ''
  //     ) {
  //       this.data.EMPLOYEE_CODE = ' ';
  //     }

  //     if (
  //       this.data.MOBILE_NO == undefined ||
  //       this.data.MOBILE_NO == null ||
  //       this.data.MOBILE_NO == '' ||
  //       this.data.MOBILE_NO.trim() == ''
  //     ) {
  //       this.data.MOBILE_NO = ' ';
  //     }

  //     if (
  //       this.data.ADDRESS == undefined ||
  //       this.data.ADDRESS == null ||
  //       this.data.ADDRESS == '' ||
  //       this.data.ADDRESS.trim() == ''
  //     ) {
  //       this.data.ADDRESS = ' ';
  //     }

  //     if (
  //       this.data.BENEFICIARY_TYPE == 'CS' &&
  //       (this.data.CGHS_CARD_NO != undefined ||
  //         this.data.CGHS_CARD_NO != null ||
  //         this.data.CGHS_CARD_NO != '')
  //     ) {
  //       this.data.CGHS_CARD_NO = null;
  //     }
  //     if (
  //       this.data.BENEFICIARY_TYPE == 'CS' &&
  //       (this.data.CGHS_CARD_VALIDITY != undefined ||
  //         this.data.CGHS_CARD_VALIDITY != null ||
  //         this.data.CGHS_CARD_VALIDITY != '')
  //     ) {
  //       this.data.CGHS_CARD_VALIDITY = null;
  //     }

  //     if (
  //       this.data.BENEFICIARY_TYPE == 'CG' &&
  //       (this.data.CGHS_CARD_VALIDITY == undefined ||
  //         this.data.CGHS_CARD_VALIDITY == null ||
  //         this.data.CGHS_CARD_VALIDITY == '')
  //     ) {
  //       this.data.CGHS_CARD_VALIDITY = null;
  //     }

  //     this.data.CGHS_CARD_VALIDITY = this.datepipe.transform(
  //       this.data.CGHS_CARD_VALIDITY,
  //       'yyyy-MM-dd'
  //     );
  //     {
  //       if (this.data.ID) {
  //         this.data['TRANSFER_ID'] = this.claimID;
  //         this.api
  //           .updatetransferempdetails(this.data)
  //           .subscribe((successCode) => {
  //             if (successCode.code == '200') {
  //               this.message.success('Information Saved Successfully...', '');

  //               this.empID = successCode.EMP_ID;
  //               this.claimID = successCode.TRANSFER_ID;
  //               this.next();
  //               this.isSpinning = false;
  //             } else if (successCode.code == '300') {
  //               this.message.error(
  //                 'Email ID or Mobile Number Already Registered...',
  //                 ''
  //               );
  //               this.isSpinning = false;
  //             } else {
  //               this.message.error('Information Has Not Saved...', '');
  //               this.isSpinning = false;
  //             }
  //           });
  //       } else {
  //         this.api.createEmployeeMaster(this.data).subscribe((successCode) => {
  //           if (successCode.code == '200') {
  //             this.message.success('Information Save Successfully...', '');
  //             this.empID = successCode.EMPLOYEE;
  //             this.claimID = successCode.TRANSFER_ID;

  //             this.next();
  //             // if (!addNew)
  //             // this.drawerClose();
  //             // else {
  //             //   this.data = new ApplicantMaster();
  //             //   this.resetDrawer(claimMasterPage);
  //             //   // this.data.IMG_URL= '';

  //             //   this.api.getEmployeeMaster(0,0,'','desc','').subscribe (data =>{
  //             //     // if (data['count']==0){
  //             //     //   this.data.SEQUENCE_NUMBER=1;
  //             //     // }else
  //             //     // {
  //             //     //   this.data.SEQUENCE_NUMBER=data['data'][0]['SEQUENCE_NUMBER']+1;
  //             //     // }
  //             //   },err=>{
  //             //     console.log(err);
  //             //   })
  //             // }
  //             this.isSpinning = false;
  //           } else if (successCode.code == '300') {
  //             this.message.error(
  //               'Email ID or Mobile Number Already Registered...',
  //               ''
  //             );
  //             this.isSpinning = false;
  //           } else {
  //             this.message.error('Failed To Fill Information...', '');
  //             this.isSpinning = false;
  //           }
  //         });
  //       }
  //     }
  //   }
  // }
  claimSave(addNew: boolean, claimMasterPage: NgForm): void {
    this.data2.EMP_ID = this.empID;
    this.data2.ID = this.claimID;
    this.data2.hospitalData = this.hospitalMapList;
    this.data2.INSPECTOR_ID = Number(sessionStorage.getItem('userId'));
    for (var i = 0; this.hospitalMapList.length > i; i++) {
      this.hospitalMapList[i]['ID'] = undefined;
      this.hospitalMapList[i]['CLAIM_ID'] = undefined;
    }
    this.data2.hospitalData = this.hospitalMapList;

    this.isSpinning = false;
    this.isOk = true;

    if (
      this.data2.RELATION_WITH_PATIENT == undefined &&
      this.data2.PATIENT_NAME == undefined &&
      this.data2.PATIENT_CGHS_BENEFICIERY_NO == undefined &&
      this.data2.NATURE_OF_TREATMENT == undefined &&
      this.data2.TREATMENT_START_DATE == undefined &&
      this.data2.TREATMENT_END_DATE == undefined &&
      this.data2.BILL_FILIING_DATE == undefined &&
      this.data2.hospitalData.length == 0
    ) {
      this.isOk = false;
      this.message.error('Please Fill All The Required Fields ', '');
    } else if (
      this.data2.RELATION_WITH_PATIENT == undefined ||
      this.data2.RELATION_WITH_PATIENT == null ||
      this.data2.RELATION_WITH_PATIENT == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Select Relationship with Applicant', '');
    } else if (
      this.data2.PATIENT_NAME == undefined ||
      this.data2.PATIENT_NAME == null ||
      this.data2.PATIENT_NAME == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Patient Name', '');
    } else if (
      this.data2.PATIENT_CGHS_BENEFICIERY_NO == undefined ||
      this.data2.PATIENT_CGHS_BENEFICIERY_NO == null ||
      this.data2.PATIENT_CGHS_BENEFICIERY_NO == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Patient CGHS Beneficiary Number', '');
    } else if (
      this.data2.NATURE_OF_TREATMENT == undefined ||
      this.data2.NATURE_OF_TREATMENT == null ||
      this.data2.NATURE_OF_TREATMENT == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Nature Of Treatment/Test', '');
    } else if (
      this.data2.TREATMENT_START_DATE == undefined ||
      this.data2.TREATMENT_START_DATE == null
    ) {
      this.isOk = false;
      this.message.error(' Please Select Treatment Start Date', '');
    } else if (
      this.data2.TREATMENT_END_DATE == undefined ||
      this.data2.TREATMENT_END_DATE == null
    ) {
      this.isOk = false;
      this.message.error(' Please Select Treatment End Date', '');
    } else if (
      this.data2.BILL_FILIING_DATE == undefined ||
      this.data2.BILL_FILIING_DATE == null
    ) {
      this.isOk = false;
      this.message.error(' Please Select Bill Filling Date', '');
    } else if (
      this.data2.IS_ADVANCE_TAKEN == true &&
      (this.data2.ADVANCE_AMOUNT == undefined ||
        this.data2.ADVANCE_AMOUNT == null ||
        this.data2.ADVANCE_AMOUNT == '')
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Advance Amount.', '');
    } else if (
      this.data2.IS_ADVANCE_TAKEN == true &&
      (this.data2.ADVANCE_TAKEN_DATE == undefined ||
        this.data2.ADVANCE_TAKEN_DATE == null)
    ) {
      this.isOk = false;
      this.message.error('Please Select Date Of Advance Taken.  ', '');
    } else if (this.data2.hospitalData.length == 0) {
      this.isOk = false;
      this.message.error('Please Add Hospital ', '');
      // }
      //  else if (
      //   this.data2.HOSPITAL_TYPE == undefined ||
      //   this.data2.HOSPITAL_TYPE == null ||
      //   this.data2.HOSPITAL_TYPE == ''
      // ) {
      //   this.isOk = false;
      //   this.message.error(' Please Select Hospital Type', '');
      // } else if (
      //   this.data2.HOSPITAL_TYPE == 'E' &&
      //   (this.data2.HOSPITAL_ID == undefined ||
      //     this.data2.HOSPITAL_ID == null ||
      //     this.data2.HOSPITAL_ID == '')
      // ) {
      //   this.isOk = false;
      //   this.message.error(' Please Select Hospital Name.', '');
      // } else if (
      //   this.data2.HOSPITAL_TYPE == 'E' &&
      //   (this.data2.HOSPITAL_ADDRESS == undefined ||
      //     this.data2.HOSPITAL_ADDRESS == null ||
      //     this.data2.HOSPITAL_ADDRESS == '')
      // ) {
      //   this.isOk = false;
      //   this.message.error(' Please Enter Hospital Address.', '');
      // } else if (
      //   (this.data2.HOSPITAL_TYPE == 'NE' || this.data2.HOSPITAL_TYPE == 'G') &&
      //   (this.data2.HOSPITAL_NAME == undefined ||
      //     this.data2.HOSPITAL_NAME == null ||
      //     this.data2.HOSPITAL_NAME == '')
      // ) {
      //   this.isOk = false;
      //   this.message.error('Please Enter Hospital Name.  ', '');
      // } else if (
      //   (this.data2.HOSPITAL_TYPE == 'NE' || this.data2.HOSPITAL_TYPE == 'G') &&
      //   (this.data2.HOSPITAL_ADDRESS == undefined ||
      //     this.data2.HOSPITAL_ADDRESS == null ||
      //     this.data2.HOSPITAL_ADDRESS == '')
      // ) {
      //   this.isOk = false;
      //   this.message.error('Please Enter Hospital Address. ', '');
      // } else if (
      //   (this.data2.HOSPITAL_TYPE == 'NE' || this.data2.HOSPITAL_TYPE == 'G') &&
      //   (this.data2.CLAIM_ACCREDITATION == undefined ||
      //     this.data2.CLAIM_ACCREDITATION == null ||
      //     this.data2.CLAIM_ACCREDITATION == '')
      // ) {
      //   this.isOk = false;
      //   this.message.error('Please Select Accredition.  ', '');
    }
    if (this.isOk) {
      this.data2['TRANSFER_ID'] = this.claimID;
      this.isSpinning = true;
      this.data2.TREATMENT_START_DATE = this.datepipe.transform(
        this.data2.TREATMENT_START_DATE,
        'yyyy-MM-dd'
      );
      this.data2.TREATMENT_END_DATE = this.datepipe.transform(
        this.data2.TREATMENT_END_DATE,
        'yyyy-MM-dd'
      );
      this.data2.BILL_FILIING_DATE = this.datepipe.transform(
        this.data2.BILL_FILIING_DATE,
        'yyyy-MM-dd'
      );
      this.data2.ADVANCE_TAKEN_DATE = this.datepipe.transform(
        this.data2.ADVANCE_TAKEN_DATE,
        'yyyy-MM-dd'
      );

      var date1: any = new Date(this.data2.TREATMENT_END_DATE);
      var date2: any = new Date(this.data2.BILL_FILIING_DATE);
      this.diffDays = Math.floor((date2 - date1) / (1000 * 60 * 60 * 24)) + 1;

      this.data2.DELAY_CONDELENCE_DIFFERENCE = this.diffDays;
      var advance;
      if (this.data2.IS_ADVANCE_TAKEN == true) {
        advance = 1;
      } else {
        advance = 0;
      }
      if (advance == 1 && this.diffDays <= 30) {
        this.data3.BILL_FILLED_INTIME = true;
      } else if (advance == 1 && this.diffDays > 30) {
        this.data3.BILL_FILLED_INTIME = false;
      } else if (advance == 0 && this.diffDays > 182) {
        this.data3.BILL_FILLED_INTIME = false;
      } else {
        this.data3.BILL_FILLED_INTIME = true;
      }

      if (this.data2.HOSPITAL_TYPE == 'E') {
        this.data2.HOSPITAL_NAME = '';
      } else {
        this.data2.HOSPITAL_NAME = this.data2.HOSPITAL_NAME;
      }

      // if(this.data2.HOSPITAL_TYPE == 'E' && (this.data2.HOSPITAL_ADDRESS != undefined || this.data2.HOSPITAL_ADDRESS != null
      //   || this.data2.HOSPITAL_ADDRESS != '' || this.data2.HOSPITAL_ADDRESS.trim() != '' )){
      //     this.data2.HOSPITAL_ADDRESS = ''
      //   } else{
      //     this.data2.HOSPITAL_ADDRESS = this.data2.HOSPITAL_ADDRESS
      //   }

      if (
        this.data2.HOSPITAL_TYPE == 'E' &&
        (this.data2.CLAIM_ACCREDITATION != undefined ||
          this.data2.CLAIM_ACCREDITATION != null ||
          this.data2.CLAIM_ACCREDITATION != '' ||
          this.data2.CLAIM_ACCREDITATION.trim() != '')
      ) {
        this.data2.CLAIM_ACCREDITATION = '';
      } else {
        this.data2.CLAIM_ACCREDITATION = this.data2.CLAIM_ACCREDITATION;
      }

      if (
        (this.data2.HOSPITAL_TYPE == 'NE' || this.data2.HOSPITAL_TYPE == 'G') &&
        (this.data2.HOSPITAL_ID != undefined ||
          this.data2.HOSPITAL_ID != null ||
          this.data2.HOSPITAL_ID != '' ||
          this.data2.HOSPITAL_ID.trim() != '')
      ) {
        this.data2.HOSPITAL_ID = '';
      } else {
        this.data2.HOSPITAL_ID = this.data2.HOSPITAL_ID;
      }

      {
        if (this.data2.ID) {
          if (
            (this.currentStageID != undefined ||
              this.currentStageID != null ||
              this.currentStageID != '') &&
            this.currentStageID > 3
          ) {
            this.data2.CURRENT_STAGE_ID = this.data2.CURRENT_STAGE_ID;
          } else {
            this.data2.CURRENT_STAGE_ID = 3;
          }
          this.api.updateclaimed(this.data2).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information Changed Successfully...', '');
              this.billInTime();
              this.empanelledHospital();
              this.next();
              // if (!addNew) this.drawerClose();
              this.isSpinning = false;
            } else {
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
        } else {
          this.api.createclaimed(this.data2).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information Save Successfully...', '');
              // this.claimID = successCode.EMPLOYEE

              this.billInTime();
              this.empanelledHospital();
              this.next();

              // if (!addNew) this.drawerClose();
              // else {
              //   // this.data = new ApplicantMaster();
              //   this.resetDrawer(claimMasterPage);
              //   // this.data.IMG_URL= '';

              //   // this.api.getEmployeeMaster(0,0,'','desc','').subscribe (data =>{
              //   //   // if (data['count']==0){
              //   //   //   this.data.SEQUENCE_NUMBER=1;
              //   //   // }else
              //   //   // {
              //   //   //   this.data.SEQUENCE_NUMBER=data['data'][0]['SEQUENCE_NUMBER']+1;
              //   //   // }
              //   // },err=>{
              //   //   console.log(err);
              //   // })
              // }
              this.isSpinning = false;
            } else {
              this.message.error('Failed To Fill Information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
  }

  queSave(addNew: boolean, claimMasterPage: NgForm): void {
    this.data3.EMP_ID = this.empID;
    this.data3.CLAIM_ID = this.claimID;

    // this.data.NAME = this.name
    // if(this.current == 0){
    //   this.current = 1;
    // }else if(this.current == 1){
    // this.current = 2;
    // } else if(this.current == 2){
    // this.current = 3;
    // }
    this.isSpinning = false;
    this.isOk = true;

    //   if(
    //   this.data.NAME.trim() == '' &&
    //   this.data.LOCATION.trim() == '' &&
    //   this.data.DDO_OF_THE_OFFICIAL.trim() == '' &&
    //   this.data.DESIGNATION.trim() == '' &&
    //   this.data.OFFICE_NAME.trim() == '' &&
    //   this.data.EMPLOYEE_CODE == 0 &&
    //   this.data.GRADE_PAY == 0

    // )

    //   {
    //     this.isOk=false;
    //     this.message.error("Please Fill All The Required Fields " ,"")
    //   }

    if (
      this.data3.REFERENCE_FORM_CGHS_AMA_TAKEN == true &&
      (this.data3.CGHS_AMA_REFERENCE_DATE == undefined ||
        this.data3.CGHS_AMA_REFERENCE_DATE == null ||
        this.data3.CGHS_AMA_REFERENCE_DATE.length == 0)
    ) {
      this.isOk = false;
      this.message.error(' Please Add CGHS/AMAs Reference Start Date', '');
    } else if (
      this.data3.REFERENCE_FORM_CGHS_AMA_TAKEN == true &&
      (this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined ||
        this.data3.CGHS_AMA_REFERENCE_END_DATE == null ||
        this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0)
    ) {
      this.isOk = false;
      this.message.error(' Please Add CGHS/AMAs Reference End Date', '');
    } else if (
      this.data3.REFERENCE_FORM_CGHS_AMA_TAKEN == true &&
      this.data3.CGHS_AMA_REFERENCE_DATE.length !=
        this.data3.CGHS_AMA_REFERENCE_END_DATE.length
    ) {
      this.isOk = false;
      this.message.error(
        ' Please Add CGHS/AMAs Reference Start Date & End Date Equal',
        ''
      );
    }
    // if (this.data3.REFERENCE_FORM_CGHS_AMA_TAKEN == true && (this.data3.CGHS_AMA_REFERENCE_NO == undefined || this.data3.CGHS_AMA_REFERENCE_NO == null)) {
    //   this.isOk = false;
    //   this.message.error('Please Enter CGHS/AMAs Reference Number ', '');
    // }
    else if (
      this.data3.IS_PERMISSION_TAKEN_FROM_HOD == true &&
      (this.data3.HOD_PERMISSION_DATE == undefined ||
        this.data3.HOD_PERMISSION_DATE == null)
    ) {
      this.isOk = false;
      this.message.error(' Please Select HOD Permission Date', '');
    } else if (
      this.data3.EXPO_FACTO_PERMISSION == true &&
      (this.data3.EXPO_FACTO_DATE == undefined ||
        this.data3.EXPO_FACTO_DATE == null)
    ) {
      this.isOk = false;
      this.message.error('Please Select Ex-Post Facto Referance Date ', '');
    }
    if (
      this.data3.BILL_FILLED_INTIME == false &&
      (this.data3.DELAY_CONDOLENCE_DATE == undefined ||
        this.data3.DELAY_CONDOLENCE_DATE == null)
    ) {
      this.isOk = false;
      this.message.error(' Please Select Delay Condolence Date', '');
    }
    // else if (this.data3.BILL_FILLED_INTIME == false && (this.data3.DELAY_CONDOLENCE_NUMBER == undefined || this.data3.DELAY_CONDOLENCE_NUMBER == null)) {
    //   this.isOk = false;
    //   this.message.error('Please Enter Delay Condolence Number', '');
    // }
    else if (
      this.data3.REFERENCE_FORM_CGHS_AMA_TAKEN == true &&
      (this.data3.CGHS_AMA_REFERENCE_DATE != undefined ||
        this.data3.CGHS_AMA_REFERENCE_DATE != null ||
        this.data3.CGHS_AMA_REFERENCE_DATE.length != 0)
    ) {
      // var date1: any = new Date(
      //   this.data3.CGHS_AMA_REFERENCE_DATE[
      //     this.data3.CGHS_AMA_REFERENCE_DATE.length - 1
      //   ]
      // );

      ///////////////////////////////////Date Difference ///////////////////
      // var date1: any = new Date(
      //   Math.max.apply(
      //     null,
      //     this.data3.CGHS_AMA_REFERENCE_DATE.map(function (e) {

      //       return new Date(e);
      //     })
      //   )
      // );

      var date2: any = new Date(this.data2.BILL_FILIING_DATE);

      var expoDiffDays = Math.floor(
        (date2 - this.date1) / (1000 * 60 * 60 * 24)
      );

      ///////////////////////////////////Date Difference ///////////////////

      // if (expoDiffDays > 30 && this.data3.EXPO_FACTO_PERMISSION == false) {
      //   this.isOk = false;
      //   this.message.error(' Please Take Ex-Post Facto Permission ', '');
      // } else {
      // }
    }

    if (this.isOk) {
      this.isSpinning = true;

      // this.data3.CGHS_AMA_REFERENCE_DATE = this.datepipe.transform(
      //   this.data3.CGHS_AMA_REFERENCE_DATE,
      //   'yyyy-MM-dd'
      // );
      this.data3.DELAY_CONDOLENCE_DATE = this.datepipe.transform(
        this.data3.DELAY_CONDOLENCE_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPOST_FACTO_PERMISSION_DATE = this.datepipe.transform(
        this.data3.EXPOST_FACTO_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPO_FACTO_DATE = this.datepipe.transform(
        this.data3.EXPO_FACTO_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      // this.data2.TREATMENT_END_DATE = this.datepipe.transform(this.data2.TREATMENT_END_DATE, 'yyyy-MM-dd');
      // this.data2.BILL_FILIING_DATE = this.datepipe.transform(this.data2.BILL_FILIING_DATE, 'yyyy-MM-dd');
      // if (
      //   this.data3.REFERENCE_FORM_CGHS_AMA_TAKEN == false &&
      //   (this.data3.CGHS_AMA_REFERENCE_DATE != undefined ||
      //     this.data3.CGHS_AMA_REFERENCE_DATE != null ||
      //     this.data3.CGHS_AMA_REFERENCE_DATE != '' ||
      //     this.data3.CGHS_AMA_REFERENCE_DATE.trim() != '')
      // ) {
      //   this.data3.CGHS_AMA_REFERENCE_DATE = null;
      // } else {
      //   this.data3.CGHS_AMA_REFERENCE_DATE = this.data3.CGHS_AMA_REFERENCE_DATE;
      // }
      // if (
      //   this.data3.REFERENCE_FORM_CGHS_AMA_TAKEN == false &&
      //   (this.data3.CGHS_AMA_REFERENCE_NO != undefined ||
      //     this.data3.CGHS_AMA_REFERENCE_NO != null ||
      //     this.data3.CGHS_AMA_REFERENCE_NO != '' ||
      //     this.data3.CGHS_AMA_REFERENCE_NO.trim() != '')
      // ) {
      //   this.data3.CGHS_AMA_REFERENCE_NO = ' ';
      // } else {
      //   this.data3.CGHS_AMA_REFERENCE_NO = this.data3.CGHS_AMA_REFERENCE_NO;
      // }

      // if (
      //   this.data3.IS_PERMISSION_TAKEN_FROM_HOD == false &&
      //   (this.data3.HOD_PERMISSION_DATE != undefined ||
      //     this.data3.HOD_PERMISSION_DATE != null)
      // ) {
      //   this.data3.HOD_PERMISSION_DATE = null;
      // } else {
      //   this.data3.HOD_PERMISSION_DATE = this.data3.HOD_PERMISSION_DATE;
      // }
      // if (
      //   this.data3.IS_PERMISSION_TAKEN_FROM_HOD == false &&
      //   (this.data3.HOD_PERMISSION_NO != undefined ||
      //     this.data3.HOD_PERMISSION_NO != null)
      // ) {
      //   this.data3.HOD_PERMISSION_NO = '';
      // } else {
      //   this.data3.HOD_PERMISSION_NO = this.data3.HOD_PERMISSION_NO;
      // }

      // if (
      //   this.data3.EXPO_FACTO_PERMISSION == false &&
      //   (this.data3.EXPO_FACTO_DATE != undefined ||
      //     this.data3.EXPO_FACTO_DATE != null)
      // ) {
      //   this.data3.EXPO_FACTO_DATE = null;
      // } else {
      //   this.data3.EXPO_FACTO_DATE = this.data3.EXPO_FACTO_DATE;
      // }
      // if (
      //   this.data3.EXPO_FACTO_PERMISSION == false &&
      //   (this.data3.EXPO_FACTO_REFERENCE_NO != undefined ||
      //     this.data3.EXPO_FACTO_REFERENCE_NO != null)
      // ) {
      //   this.data3.EXPO_FACTO_REFERENCE_NO = '';
      // } else {
      //   this.data3.EXPO_FACTO_REFERENCE_NO = this.data3.EXPO_FACTO_REFERENCE_NO;
      // }
      if (
        this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE == null &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
      ) {
        this.data3.CGHS_AMA_REFERENCE_END_DATE = '';
      } else {
        this.data3.CGHS_AMA_REFERENCE_END_DATE =
          this.data3.CGHS_AMA_REFERENCE_END_DATE;
      }

      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_DATE != undefined ||
          this.data3.DELAY_CONDOLENCE_DATE != null)
      ) {
        this.data3.DELAY_CONDOLENCE_DATE = this.data3.DELAY_CONDOLENCE_DATE;
      } else {
        this.data3.DELAY_CONDOLENCE_DATE = null;
      }
      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_NUMBER != undefined ||
          this.data3.DELAY_CONDOLENCE_NUMBER != null)
      ) {
        this.data3.DELAY_CONDOLENCE_NUMBER = this.data3.DELAY_CONDOLENCE_NUMBER;
      } else {
        this.data3.DELAY_CONDOLENCE_NUMBER = '';
      }
      {
        if (this.data3.ID) {
          this.api.updateQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information Changed Successfully...', '');
              this.api
                .getAllQuestions(0, 0, '', '', ' AND CLAIM_ID =' + this.claimID)
                .subscribe(
                  (data) => {
                    if (data['code'] == 200) {
                      if (data['data'].length == 0) {
                        this.isSpinning = false;
                      } else {
                        this.data3 = data['data'][0];
                        this.emergencyTaken =
                          data['data'][0]['EMERGENCY_TREATEMENT'];
                        this.drCertificate =
                          data['data'][0]['DOCTOR_EMERGENCY_CERTIFICATE_TAKEN'];
                        this.empanelHospital =
                          data['data'][0]['IS_HOSPITAL_EMPLANELLED'];
                        this.expoFacto =
                          data['data'][0]['EXPO_FACTO_PERMISSION'];
                        this.referanceTaken =
                          data['data'][0]['REFERENCE_FORM_CGHS_AMA_TAKEN'];
                        this.hodPermission =
                          data['data'][0]['IS_PERMISSION_TAKEN_FROM_HOD'];
                        if (
                          this.data3.CGHS_AMA_REFERENCE_DATE == undefined &&
                          this.data3.CGHS_AMA_REFERENCE_DATE == null &&
                          this.data3.CGHS_AMA_REFERENCE_DATE == '' &&
                          this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
                        ) {
                          this.data3.CGHS_AMA_REFERENCE_DATE = [];
                        } else {
                          this.data3.CGHS_AMA_REFERENCE_DATE =
                            this.data3.CGHS_AMA_REFERENCE_DATE.split(',');
                        }
                        if (
                          this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined &&
                          this.data3.CGHS_AMA_REFERENCE_END_DATE == null &&
                          this.data3.CGHS_AMA_REFERENCE_END_DATE == '' &&
                          this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
                        ) {
                          this.data3.CGHS_AMA_REFERENCE_END_DATE = [];
                        } else {
                          this.data3.CGHS_AMA_REFERENCE_END_DATE =
                            this.data3.CGHS_AMA_REFERENCE_END_DATE.split(',');
                        }
                        this.isSpinning = false;
                      }
                    } else {
                      this.message.error('Something Went Wrong', '');
                      this.isSpinning = false;
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              this.next();
              // this.claimConfirmation();
              // if (!addNew) this.drawerClose();
              // this.current = 3
              this.isSpinning = false;
            } else {
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
        } else {
          this.api.createQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information Save Successfully...', '');
              // this.claimID = successCode.EMPLOYEE

              // this.claimConfirmation();
              this.current = 3;

              // if (!addNew) this.drawerClose();
              // else {
              this.data4 = new CheckList();
              //   this.resetDrawer(claimMasterPage);
              //   // this.data.IMG_URL= '';

              //   // this.api.getEmployeeMaster(0,0,'','desc','').subscribe (data =>{
              //   //   // if (data['count']==0){
              //   //   //   this.data.SEQUENCE_NUMBER=1;
              //   //   // }else
              //   //   // {
              //   //   //   this.data.SEQUENCE_NUMBER=data['data'][0]['SEQUENCE_NUMBER']+1;
              //   //   // }
              //   // },err=>{
              //   //   console.log(err);
              //   // })
              // }
              this.isSpinning = false;
            } else {
              this.message.error('Failed To Fill Information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
  }
  checkSave(addNew: boolean): void {
    // this.checkData.EMP_ID = this.empID;
    this.checkData.TRANSFER_ID = this.claimID;
    this.isSpinning = false;
    this.isOk = true;
    if (
      this.checkData.CONDOLATION_OF_DELAY_IS_SUBMITTED == undefined &&
      this.checkData.FIXED_TENURE_COMPLETED_AT_OLD_STATION == undefined &&
      this.checkData.A_T_TICKET_SUBMITTED == undefined &&
      this.checkData.ORIGINAL_BOARDING_PASS_SELF_DECLARATION == undefined &&
      this.checkData.TRANSPORTATION_OF_PERSONAL_EFFECT_BILL_SUBMITTED ==
        undefined &&
      this.checkData.TRANSPORTATION_OF_VEHICLE_BY_ROAD_SELF_PROPULSION ==
        undefined &&
      this.checkData.ASSUMPTION_OF_CHARGE_SUBMITTED == undefined
    ) {
      this.isOk = false;
      this.message.error('Please Check All Checklist Proper ', '');
    } else if (
      this.checkData.CONDOLATION_OF_DELAY_IS_SUBMITTED == undefined ||
      this.checkData.CONDOLATION_OF_DELAY_IS_SUBMITTED == null ||
      this.checkData.CONDOLATION_OF_DELAY_IS_SUBMITTED == ''
    ) {
      this.isOk = false;
      this.message.error('Please Check 3 No. Point ', '');
    } else if (
      this.checkData.FIXED_TENURE_COMPLETED_AT_OLD_STATION == undefined ||
      this.checkData.FIXED_TENURE_COMPLETED_AT_OLD_STATION == null ||
      this.checkData.FIXED_TENURE_COMPLETED_AT_OLD_STATION == ''
    ) {
      this.isOk = false;
      this.message.error('Please Check 7 No. Point ', '');
    } else if (
      this.checkData.A_T_TICKET_SUBMITTED == undefined ||
      this.checkData.A_T_TICKET_SUBMITTED == null ||
      this.checkData.A_T_TICKET_SUBMITTED == ''
    ) {
      this.isOk = false;
      this.message.error('Please Check 8 No. Point  ', '');
    } else if (
      this.checkData.ORIGINAL_BOARDING_PASS_SELF_DECLARATION == undefined ||
      this.checkData.ORIGINAL_BOARDING_PASS_SELF_DECLARATION == null ||
      this.checkData.ORIGINAL_BOARDING_PASS_SELF_DECLARATION == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Check 9 No. Point ', '');
    } else if (
      this.checkData.TRANSPORTATION_OF_PERSONAL_EFFECT_BILL_SUBMITTED ==
        undefined ||
      this.checkData.TRANSPORTATION_OF_PERSONAL_EFFECT_BILL_SUBMITTED == null ||
      this.checkData.TRANSPORTATION_OF_PERSONAL_EFFECT_BILL_SUBMITTED == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Check 10 No. Point ', '');
    } else if (
      this.checkData.TRANSPORTATION_OF_VEHICLE_BY_ROAD_SELF_PROPULSION ==
        undefined ||
      this.checkData.TRANSPORTATION_OF_VEHICLE_BY_ROAD_SELF_PROPULSION ==
        null ||
      this.checkData.TRANSPORTATION_OF_VEHICLE_BY_ROAD_SELF_PROPULSION == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Check 11 No. Point ', '');
    } else if (
      this.checkData.ASSUMPTION_OF_CHARGE_SUBMITTED == undefined ||
      this.checkData.ASSUMPTION_OF_CHARGE_SUBMITTED == null ||
      this.checkData.ASSUMPTION_OF_CHARGE_SUBMITTED == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Check 13 No. Point ', '');
    }
    if (this.isOk) {
      this.isSpinning = true;
      this.api.updatetransfer(this.data).subscribe((successCode) => {
        if (successCode.code == '200') {
          // this.message.success('Information Saved Successfully...', '');
          if (this.checkData.ID) {
            this.api
              .updateTransferChecklist(this.checkData)
              .subscribe((successCode) => {
                if (successCode.code == '200') {
                  this.message.success(
                    'Information Changed Successfully...',
                    ''
                  );
                  if (!addNew) {
                    // this.empID = '';
                    // this.claimID = '';
                    this.drawerClose();
                    this.current = 0;
                    this.isSpinning = false;
                  }
                } else {
                  this.message.error('Information Has Not Changed...', '');
                  this.isSpinning = false;
                }
              });
          } else {
            this.api
              .createTransferChecklist(this.checkData)
              .subscribe((successCode) => {
                if (successCode.code == '200') {
                  this.message.success('Information Save Successfully...', '');
                  if (!addNew) {
                    // this.empID = '';
                    // this.claimID = '';
                    this.drawerClose();
                    this.current = 0;
                  } else {
                  }
                  this.isSpinning = false;
                } else {
                  this.message.error('Failed To Fill Information...', '');
                  this.isSpinning = false;
                }
              });
          }
        } else {
          this.message.error('Information Has Not Saved...', '');
          this.isSpinning = false;
        }
      });
    }
  }

  // checkSave(addNew: boolean): void {
  //   // this.checkData.EMP_ID = this.empID;
  //   this.checkData.TRANSFER_ID = this.claimID;
  //   this.isSpinning = false;
  //   this.isOk = true;
  //   if (
  //     this.checkData.CONDOLATION_OF_DELAY_IS_SUBMITTED == undefined &&
  //     this.checkData.FIXED_TENURE_COMPLETED_AT_OLD_STATION == undefined &&
  //     this.checkData.A_T_TICKET_SUBMITTED == undefined &&
  //     this.checkData.ORIGINAL_BOARDING_PASS_SELF_DECLARATION == undefined &&
  //     this.checkData.TRANSPORTATION_OF_PERSONAL_EFFECT_BILL_SUBMITTED ==
  //     undefined &&
  //     this.checkData.TRANSPORTATION_OF_VEHICLE_BY_ROAD_SELF_PROPULSION ==
  //     undefined &&
  //     this.checkData.ASSUMPTION_OF_CHARGE_SUBMITTED == undefined
  //   ) {
  //     this.isOk = false;
  //     this.message.error('Please Check All Checklist Proper ', '');
  //   } else if (
  //     this.checkData.CONDOLATION_OF_DELAY_IS_SUBMITTED == undefined ||
  //     this.checkData.CONDOLATION_OF_DELAY_IS_SUBMITTED == null ||
  //     this.checkData.CONDOLATION_OF_DELAY_IS_SUBMITTED == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error('Please Check 3 No. Point ', '');
  //   } else if (
  //     this.checkData.FIXED_TENURE_COMPLETED_AT_OLD_STATION == undefined ||
  //     this.checkData.FIXED_TENURE_COMPLETED_AT_OLD_STATION == null ||
  //     this.checkData.FIXED_TENURE_COMPLETED_AT_OLD_STATION == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error('Please Check 7 No. Point ', '');
  //   } else if (
  //     this.checkData.A_T_TICKET_SUBMITTED == undefined ||
  //     this.checkData.A_T_TICKET_SUBMITTED == null ||
  //     this.checkData.A_T_TICKET_SUBMITTED == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error('Please Check 8 No. Point  ', '');
  //   } else if (
  //     this.checkData.ORIGINAL_BOARDING_PASS_SELF_DECLARATION == undefined ||
  //     this.checkData.ORIGINAL_BOARDING_PASS_SELF_DECLARATION == null ||
  //     this.checkData.ORIGINAL_BOARDING_PASS_SELF_DECLARATION == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Check 9 No. Point ', '');
  //   } else if (
  //     this.checkData.TRANSPORTATION_OF_PERSONAL_EFFECT_BILL_SUBMITTED ==
  //     undefined ||
  //     this.checkData.TRANSPORTATION_OF_PERSONAL_EFFECT_BILL_SUBMITTED == null ||
  //     this.checkData.TRANSPORTATION_OF_PERSONAL_EFFECT_BILL_SUBMITTED == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Check 10 No. Point ', '');
  //   } else if (
  //     this.checkData.TRANSPORTATION_OF_VEHICLE_BY_ROAD_SELF_PROPULSION ==
  //     undefined ||
  //     this.checkData.TRANSPORTATION_OF_VEHICLE_BY_ROAD_SELF_PROPULSION ==
  //     null ||
  //     this.checkData.TRANSPORTATION_OF_VEHICLE_BY_ROAD_SELF_PROPULSION == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Check 11 No. Point ', '');
  //   } else if (
  //     this.checkData.ASSUMPTION_OF_CHARGE_SUBMITTED == undefined ||
  //     this.checkData.ASSUMPTION_OF_CHARGE_SUBMITTED == null ||
  //     this.checkData.ASSUMPTION_OF_CHARGE_SUBMITTED == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Check 13 No. Point ', '');
  //   } else if (
  //     this.data.ACTION_STATUS == 'R' &&
  //     (this.data.ACTION_REMARK == undefined ||
  //       this.data.ACTION_REMARK == null ||
  //       this.data.ACTION_REMARK == '')
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Enter Reject Remark.', '');
  //   } else if (
  //     this.data.ACTION_STATUS == 'H' &&
  //     (this.data.ACTION_DATE == undefined || this.data.ACTION_DATE == null)
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Select On Hold Date.', '');
  //   } else if (
  //     this.data.ACTION_STATUS == 'H' &&
  //     (this.data.ACTION_REMARK == undefined ||
  //       this.data.ACTION_REMARK == null ||
  //       this.data.ACTION_REMARK == '')
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Enter On Hold Remark.', '');
  //   }

  //   if (this.data.ACTION_DATE != null && this.data.ACTION_DATE != '' && this.data.ACTION_DATE != undefined) {
  //     this.data.ACTION_DATE = this.datepipe.transform(
  //       this.data.ACTION_DATE,
  //       'yyyy-MM-dd'
  //     );
  //   }

  //   if (this.isOk) {
  //     this.isSpinning = true;
  //     {
  //       if (this.checkData.ID) {
  //         this.api
  //           .updateTransferChecklist(this.checkData)
  //           .subscribe((successCode) => {
  //             if (successCode.code == '200') {

  //               this.api.updatetransfer(this.data).subscribe((successCode) => {
  //                 if (successCode.code == '200') {
  //                   this.message.success('Information Changed Successfully...', '');
  //                   if (!addNew) {
  //                     // this.empID = '';
  //                     // this.claimID = '';
  //                     this.drawerClose();
  //                     this.current = 0;
  //                     this.isSpinning = false;
  //                   }

  //                 } else {
  //                   this.message.error('Information Has Not Saved...', '');
  //                   this.loadingRecords = false;
  //                 }
  //               });

  //             } else {
  //               this.message.error('Information Has Not Changed...', '');
  //               this.isSpinning = false;
  //             }
  //           });
  //       } else {
  //         this.api
  //           .createTransferChecklist(this.checkData)
  //           .subscribe((successCode) => {
  //             if (successCode.code == '200') {

  //               this.api.updatetransfer(this.data).subscribe((successCode) => {
  //                 if (successCode.code == '200') {
  //                   this.message.success('Information Save Successfully...', '');
  //                   if (!addNew) {
  //                     // this.empID = '';
  //                     // this.claimID = '';
  //                     this.drawerClose();
  //                     this.current = 0;
  //                     this.isSpinning = false;
  //                   }
  //                 } else {
  //                   this.message.error('Information Has Not Saved...', '');
  //                   this.isSpinning = false;
  //                 }
  //               });
  //               this.isSpinning = false;
  //             } else {
  //               this.message.error('Failed To Fill Information...', '');
  //               this.isSpinning = false;
  //             }
  //           });
  //       }
  //     }
  //   }
  // }
  uploadDoc(addNew: boolean) {
    this.isOk = true;
    if (this.isOk && this.data.ID != undefined) {
      this.isSpinning = true;
      if (this.data.ID) {
        this.data.TRANSFER_ID = undefined;
        this.api.updatetransfer(this.data).subscribe((successCode) => {
          if (successCode.code == '200') {
            this.isSpinning = false;
            // this.drawerClose();
            this.next();
            this.message.success('Information Saved Successfully...', '');
          } else {
            this.message.error('Information Has Not Saved...', '');
            this.isSpinning = false;
          }
        });
      } else {
      }
    }
  }
  pre2(): void {
    if (this.current == 2 && this.data.ID > 0) {
      this.current -= 2;
    } else {
      this.current -= 1;
    }
  }

  pre(): void {
    if (this.current == 1) {
      this.isSpinning = true;
      this.allEmployeeList();
      this.api
        .gettransferdata(0, 0, '', '', ' AND ID =' + this.claimID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              if (data['data'].length == 0) {
                this.current -= 1;
                // this.isSpinning = false;
              } else {
                this.data = data['data'][0];

                this.current -= 1;
                // this.isSpinning = false;
              }
            } else {
              this.message.error('Something Went Wrong', '');
              // this.isSpinning = false;
            }
            this.api
              .getrelationtable(
                0,
                0,
                '',
                '',
                ' AND TRANSFER_ID = ' + this.claimID
              )
              .subscribe(
                (data) => {
                  if (data['code'] == 200) {
                    this.relationdata = data['data'];
                    this.isSpinning = false;
                  } else {
                    this.message.error('Something Went Wrong', '');
                    this.isSpinning = false;
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
          },
          (err) => {
            console.log(err);
          }
        );
    } else if (this.current == 3) {
      this.isSpinning = true;
      this.fileNumberList();
      this.api
        .gettransferdata(0, 0, '', '', ' AND ID =' + this.claimID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              if (data['data'].length == 0) {
                this.current = 2;
                this.isSpinning = false;
              } else {
                this.current = 2;
                this.isSpinning = false;
              }
            } else {
              this.message.error('Something Went Wrong', '');
              this.isSpinning = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else if (this.current == 2) {
      this.isSpinning = true;
      this.allHospitalList();
      this.api.getclaimed(0, 0, '', '', ' AND ID =' + this.claimID).subscribe(
        (data) => {
          if (data['code'] == 200) {
            if (data['data'].length == 0) {
              // this.billInTime()
              // this.empanelledHospital()
              this.data2 = new ClaimMaster();
              this.current -= 1;
              this.isSpinning = false;
            } else {
              this.data2 = data['data'][0];

              if (
                this.data2.TREATEMENT_TYPE == null ||
                this.data2.TREATEMENT_TYPE == '' ||
                this.data2.TREATEMENT_TYPE == undefined
              ) {
                this.TREATEMENT_TYPE1 = false;
                this.TREATEMENT_TYPE2 = false;
              }
              if (this.data2.TREATEMENT_TYPE == 'IT') {
                this.TREATEMENT_TYPE1 = false;
                this.TREATEMENT_TYPE2 = true;
              }
              if (this.data2.TREATEMENT_TYPE == 'OT') {
                this.TREATEMENT_TYPE1 = true;
                this.TREATEMENT_TYPE2 = false;
              }
              if (this.data2.TREATEMENT_TYPE == 'OT,IT') {
                this.TREATEMENT_TYPE1 = true;
                this.TREATEMENT_TYPE2 = true;
              }
              // this.billInTime()
              // this.empanelledHospital();
              this.current -= 1;
              this.isSpinning = false;
            }
          } else {
            this.message.error('Something Went Wrong', '');
            this.isSpinning = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );

      this.isSpinning = false;
    } else if (this.current == 4) {
      this.isSpinning = true;
      this.api
        .gettransferdata(0, 0, '', '', ' AND ID =' + this.claimID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              this.data = data['data'][0];
              this.current = 3;
              this.isSpinning = false;
            } else {
              // this.message.error('Something Went Wrong', '');
              this.isSpinning = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
      this.isSpinning = true;
      this.current -= 1;
      this.isSpinning = false;
    }
  }

  filterEmpData(event: any) {
    this.empLoader = true;
    if (event != null) {
      this.api.getEmployeeMaster(0, 0, '', '', ' AND ID =' + event).subscribe(
        (data) => {
          if (data['code'] == 200) {
            this.empLoader = false;
            if (
              this.data2.ID == 0 ||
              this.data2.ID == null ||
              this.data2.ID == undefined
            ) {
              this.data.ID = data['data'][0]['ID'];
            } else {
              this.data.ID = this.data2.ID;
            }
            this.data.OFFICE_NAME = data['data'][0]['OFFICE_NAME'];
            this.data.DESIGNATION = data['data'][0]['DESIGNATION'];
            this.data.EMPLOYEE_CODE = data['data'][0]['EMPLOYEE_CODE'];
            this.data.LOCATION = data['data'][0]['LOCATION'];
            this.data.DDO_OF_THE_OFFICIAL =
              data['data'][0]['DDO_OF_THE_OFFICIAL'];
            this.data.GRADE_PAY = data['data'][0]['GRADE_PAY'];
            this.data.EMAIL_ID = data['data'][0]['EMAIL_ID'];
            this.data.MOBILE_NO = data['data'][0]['MOBILE_NO'];
            this.data.ADDRESS = data['data'][0]['ADDRESS'];
            this.data.NAME = data['data'][0]['NAME'];
            // if (
            //   this.data.GRADE_PAY != '' ||
            //   this.data.GRADE_PAY != null ||
            //   this.data.GRADE_PAY != undefined
            // ) {
            //   this.grantamount(this.data.GRADE_PAY);
            // }
          }
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      this.empLoader = false;
      this.data.ID = null;
      this.data.OFFICE_NAME = '';
      this.data.DESIGNATION = '';
      this.data.EMPLOYEE_CODE = '';
      this.data.LOCATION = '';
      this.data.DDO_OF_THE_OFFICIAL = '';
      this.data.GRADE_PAY = '';
      this.data.EMAIL_ID = '';
      this.data.MOBILE_NO = '';
      this.data.ADDRESS = '';
    }
  }

  // name
  // onChange(value: string): void {

  //   this.name == value

  //   this.filteredOptions = this.employee.filter(option => option.NAME.toLowerCase().indexOf(value.toLowerCase()) !== -1);

  //   // var a = this.employee.filter(obj => {
  //   //   return obj.NAME == value;
  //   // })

  // }

  name = '';
  // onChange(value: string): void {
  //   this.name = value;
  //   this.filteredOptions = [];
  //   this.filteredOptions = this.employee.filter(
  //     (option) => option.NAME.toLowerCase().indexOf(value.toLowerCase()) !== -1
  //   );

  //   if (value != '') {
  //     if (this.filteredOptions.length > 0) {
  //       // this.name = this.filteredOptions[0]['NAME'];
  //       this.data.ID = this.filteredOptions[0]['ID'];

  //       this.data.OFFICE_NAME = this.filteredOptions[0]['OFFICE_NAME'];
  //       this.data.DESIGNATION = this.filteredOptions[0]['DESIGNATION'];
  //       this.data.EMPLOYEE_CODE = this.filteredOptions[0]['EMPLOYEE_CODE'];
  //       this.data.LOCATION = this.filteredOptions[0]['LOCATION'];
  //       this.data.DDO_OF_THE_OFFICIAL =
  //         this.filteredOptions[0]['DDO_OF_THE_OFFICIAL'];
  //       this.data.GRADE_PAY = this.filteredOptions[0]['GRADE_PAY'];
  //       this.data.EMAIL_ID = this.filteredOptions[0]['EMAIL_ID'];
  //       this.data.MOBILE_NO = this.filteredOptions[0]['MOBILE_NO'];
  //       this.data.ADDRESS = this.filteredOptions[0]['ADDRESS'];
  //     } else {
  //       this.data.ID = undefined;
  //       this.name = value;
  //       this.data.OFFICE_NAME = '';
  //       this.data.DESIGNATION = '';
  //       this.data.EMPLOYEE_CODE = '';
  //       this.data.LOCATION = '';
  //       this.data.DDO_OF_THE_OFFICIAL = '';
  //       this.data.GRADE_PAY = '';
  //       this.data.EMAIL_ID = '';
  //       this.data.MOBILE_NO = '';
  //       this.data.ADDRESS = '';
  //     }
  //   } else {
  //     this.name = value;
  //     this.data.OFFICE_NAME = '';
  //     this.data.DESIGNATION = '';
  //     this.data.EMPLOYEE_CODE = '';
  //     this.data.LOCATION = '';
  //     this.data.DDO_OF_THE_OFFICIAL = '';
  //     this.data.GRADE_PAY = '';
  //     this.data.EMAIL_ID = '';
  //     this.data.MOBILE_NO = '';
  //     this.data.ADDRESS = '';
  //   }
  //   this.data.NAME = this.name;
  // }
  emergencyTaken;
  drCertificate;
  empanelHospital;
  expoFacto;
  referanceTaken;
  hodPermission;

  dateDifference() {
    // var date1: any = new Date(
    //   Math.max.apply(
    //     null,
    //     this.data3.CGHS_AMA_REFERENCE_DATE.map(function (e) {

    //       return new Date(e);
    //     })
    //   )
    // );

    var date2: any = new Date(this.data2.BILL_FILIING_DATE);

    this.expoDiffDays = Math.floor(
      (date2 - this.date1) / (1000 * 60 * 60 * 24)
    );
  }

  fileList: any = [];
  fileNumberList() {
    this.api
      .getFileMaster(
        0,
        0,
        'ID',
        'ASC',
        ' AND STATUS = 1 AND HIRARCHY_ID in (6,7)',
        sessionStorage.getItem('userId')
      )
      .subscribe(
        (data: any) => {
          if (data['code'] == 200 && data['count'] > 0) {
            this.fileList = data['data'];
          } else {
            this.fileList = [];
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }
  railTicket: boolean = false;
  airTicket: boolean = false;
  freightCharge: any = 0;
  next() {
    if (this.current == 0) {
      this.isSpinning = true;
      this.getDataform1();
      this.getDataform2();
      this.getDataform3();
      this.detailsJorneyGetData();
      this.api
        .gettransferdata(0, 0, '', '', ' AND ID =' + this.claimID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              if (data['data'].length == 0) {
                this.data2 = new ClaimMaster();

                this.current = 1;

                this.isSpinning = false;
              } else {
                this.data = data['data'][0];
                this.current = 1;
                this.isSpinning = false;
              }
            } else {
              // this.message.error('Something Went Wrong', '');
              this.isSpinning = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
      // this.getDataform1();
      // this.getDataform2();
      // this.getDataform3();
      // this.detailsJorneyGetData();
    } else if (this.current == 1) {
      this.isSpinning = true;

      // this.fileNumberList();

      this.api
        .gettransferdata(0, 0, '', '', ' AND ID =' + this.claimID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              if (data['data'].length == 0) {
                // this.fileNumberList();
                this.current = 2;
                this.isSpinning = false;
              } else {
                this.current = 2;
                this.isSpinning = false;
              }
            } else {
              this.message.error('Something Went Wrong', '');
              this.isSpinning = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else if (this.current == 2) {
      this.freightCharge = 0;
      this.isSpinning = true;
      this.api
        .gettransferdata(0, 0, '', '', ' AND ID =' + this.claimID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              this.data = data['data'][0];
              if (data['data']['VEHICLE_BROUGHT_SELF_PROPULATION'] == 1) {
                this.freightCharge = 1;
              } else {
                this.freightCharge = 0;
              }
              // if (data['data'].length == 0) {
              this.current = 3;
              this.isSpinning = false;
              // } else {
              //   this.current = 3;
              //   this.isSpinning = false;
              // }
            } else {
              // this.message.error('Something Went Wrong', '');
              this.isSpinning = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else if (this.current == 3) {
      this.fileNumberList();
      this.isSpinning = true;
      this.railTicket = false;
      this.airTicket = false;
      this.api
        .gettransferdata(0, 0, '', '', ' AND ID =' + this.claimID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              this.data = data['data'][0];
              // this.isSpinning = false;
              this.api
                .getAllTransferChecklist(
                  0,
                  0,
                  '',
                  '',
                  ' AND TRANSFER_ID =' + this.claimID
                )
                .subscribe(
                  (data) => {
                    if (data['code'] == 200) {
                      if (data['data'].length > 0) {
                        this.checkData = data['data'][0];
                        this.isSpinning = false;
                        this.current = 4;
                      } else {
                        this.current = 4;
                        this.checkData = new TransferCheckList();
                        if (this.data.IS_ADVANCE_TAKEN == 1) {
                          this.checkData.T_A_ADVANCE_TAKEN = true;
                        } else {
                          this.checkData.T_A_ADVANCE_TAKEN =
                            this.checkData.T_A_ADVANCE_TAKEN;
                        }
                        if (this.formdata7.length > 0) {
                          for (var i = 0; this.formdata7.length > i; i++) {
                            if (
                              this.formdata7[i]['TRAVEL_MODE_ID'] == 4 &&
                              (this.formdata7[i]['IRCTC_TICKET'] != null ||
                                this.formdata7[i]['IRCTC_TICKET'] !=
                                  undefined ||
                                this.formdata7[i]['IRCTC_TICKET'] != '')
                            ) {
                              this.railTicket = true;
                            } else {
                              this.railTicket = false;
                            }
                            if (
                              this.formdata7[i]['TRAVEL_MODE_ID'] == 5 &&
                              (this.formdata7[i]['AIR_TICKET'] != null ||
                                this.formdata7[i]['AIR_TICKET'] != undefined ||
                                this.formdata7[i]['AIR_TICKET'] != '')
                            ) {
                              this.airTicket = true;
                            } else {
                              this.airTicket = false;
                            }
                          }
                          if (
                            this.airTicket == true ||
                            this.railTicket == true
                          ) {
                            this.checkData.A_T_TICKET_SUBMITTED = 'Y';
                          } else {
                            this.checkData.A_T_TICKET_SUBMITTED = 'N';
                          }
                        } else {
                          this.checkData.A_T_TICKET_SUBMITTED = 'NA';
                        }

                        if (
                          this.data.ASSUMPTION_CHARGE != null &&
                          this.data.ASSUMPTION_CHARGE != undefined &&
                          this.data.ASSUMPTION_CHARGE != ''
                        ) {
                          this.checkData.ASSUMPTION_OF_CHARGE_SUBMITTED = 'Y';
                        } else {
                          this.checkData.ASSUMPTION_OF_CHARGE_SUBMITTED = 'N';
                        }

                        if (
                          this.data.MANDATE_FORM != null &&
                          this.data.MANDATE_FORM != undefined &&
                          this.data.MANDATE_FORM != ''
                        ) {
                          this.checkData.BANK_MANDATE_CHEQUE_SUBMITTED = true;
                        } else {
                          this.checkData.BANK_MANDATE_CHEQUE_SUBMITTED =
                            this.checkData.BANK_MANDATE_CHEQUE_SUBMITTED;
                        }

                        if (this.freightCharge == 1) {
                          if (
                            this.data.TRAIN_BROUGHT_CHARGE != null ||
                            this.data.TRAIN_BROUGHT_CHARGE != undefined ||
                            this.data.TRAIN_BROUGHT_CHARGE != ''
                          ) {
                            this.checkData.TRANSPORTATION_OF_VEHICLE_BY_ROAD_SELF_PROPULSION =
                              'Y';
                          } else {
                            this.checkData.TRANSPORTATION_OF_VEHICLE_BY_ROAD_SELF_PROPULSION =
                              'N';
                          }
                        } else {
                          this.checkData.TRANSPORTATION_OF_VEHICLE_BY_ROAD_SELF_PROPULSION =
                            'NA';
                        }
                        this.isSpinning = false;
                      }
                      // }
                    } else {
                      // this.message.error('Something Went Wrong', '');
                      this.isSpinning = false;
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
            } else {
              // this.message.error('Something Went Wrong', '');
              this.isSpinning = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
    }
  }

  calculateDiff() {
    var date1: any = new Date(this.data2.TREATMENT_END_DATE);
    var date2: any = new Date(this.data2.BILL_FILIING_DATE);
    this.diffDays = Math.floor((date2 - date1) / (1000 * 60 * 60 * 24)) + 1;
  }

  hospitalAddress(event: any) {
    this.api.getAllHospital(0, 0, '', '', ' AND ID = ' + event).subscribe(
      (data) => {
        if (data['code'] == 200) {
          this.hospitalData.ADDRESS = data['data'][0]['ADDRESS'];
          this.hospitalData.ACCREDATION = data['data'][0]['ACCREDITATION'];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  hospitalType(event: any) {
    if (event == 'E') {
      this.hospitalData.ADDRESS = null;
      this.hospitalData.ACCREDATION = null;
      if (
        this.hospitalData.NAME != undefined ||
        this.hospitalData.NAME != null ||
        this.hospitalData.NAME != ''
      ) {
        this.hospitalData.NAME = '';
      } else {
        this.hospitalData.NAME = '';
      }
    } else if (event == 'NE') {
      this.hospitalData.ADDRESS = null;
      this.hospitalData.ACCREDATION = null;
      if (
        this.hospitalData.HOSPITAL_ID != undefined ||
        this.hospitalData.HOSPITAL_ID != null
      ) {
        this.hospitalData.HOSPITAL_ID = null;
      } else {
        this.hospitalData.HOSPITAL_ID = null;
      }
    } else {
      this.hospitalData.ADDRESS = null;
      this.hospitalData.ACCREDATION = null;
      if (
        this.hospitalData.HOSPITAL_ID != undefined ||
        this.hospitalData.HOSPITAL_ID != null
      ) {
        this.hospitalData.HOSPITAL_ID = null;
      } else {
        this.hospitalData.HOSPITAL_ID = null;
      }
    }
  }

  billInTime() {
    var date1: any = new Date(this.data2.TREATMENT_END_DATE);
    var date2: any = new Date(this.data2.BILL_FILIING_DATE);
    this.diffDays = Math.floor((date2 - date1) / (1000 * 60 * 60 * 24)) + 1;

    var advance;
    if (this.data2.IS_ADVANCE_TAKEN == true) {
      advance = 1;
    } else {
      advance = 0;
    }
    if (advance == 1 && this.diffDays <= 30) {
      this.data3.BILL_FILLED_INTIME = true;
    } else if (advance == 1 && this.diffDays > 30) {
      this.data3.BILL_FILLED_INTIME = false;
    } else if (advance == 0 && this.diffDays > 182) {
      this.data3.BILL_FILLED_INTIME = false;
    } else {
      this.data3.BILL_FILLED_INTIME = true;
    }
  }
  empanneledHospitalMapList = [];
  nonEmpanneled: boolean = false;
  empanelledHospital() {
    this.nonEmpanneled = false;
    this.api
      .getHospitalMapping(0, 0, '', '', ' AND CLAIM_ID = ' + this.claimID)
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            this.empanneledHospitalMapList = data['data'];
            for (var i = 0; this.empanneledHospitalMapList.length > i; i++) {
              if (this.empanneledHospitalMapList[i]['TYPE'] == 'NE') {
                this.nonEmpanneled = true;
              } else {
              }
            }
            if (this.nonEmpanneled == true) {
              this.data3.IS_HOSPITAL_EMPLANELLED = false;
            } else {
              this.data3.IS_HOSPITAL_EMPLANELLED = true;
            }
            this.isSpinning = false;
          } else {
            this.message.error("Can't Load Hospital Mapped Data.", '');
            this.isSpinning = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  isAdvanceTaken(event: any) {
    if (event == false) {
      if (
        this.ADVANCED_AMOUNT != null ||
        this.data.ADVANCED_AMOUNT != undefined
      ) {
        this.data.ADVANCED_AMOUNT = null;
      } else {
        this.data.ADVANCED_AMOUNT = null;
      }
      if (
        this.ADVANCE_TAKEN_DATE != null ||
        this.ADVANCE_TAKEN_DATE != undefined
      ) {
        this.ADVANCE_TAKEN_DATE = null;
      } else {
        this.ADVANCE_TAKEN_DATE = null;
      }
    } else {
    }
  }
  disabledDate = (current: Date): boolean =>
    differenceInCalendarDays(
      current,
      new Date(this.data2.TREATMENT_START_DATE)
    ) < 0;

  disabledDate2 = (current: Date): boolean =>
    differenceInCalendarDays(
      current,
      new Date(this.data2.TREATMENT_START_DATE)
    ) < 0;

  disableBillDate() {
    this.disabledDate = (current: Date): boolean =>
      differenceInCalendarDays(
        current,
        new Date(this.data2.TREATMENT_START_DATE)
      ) < 0;
  }

  treatEndDate() {
    this.disabledDate2 = (current: Date): boolean =>
      differenceInCalendarDays(
        current,
        new Date(this.data2.TREATMENT_START_DATE)
      ) < 0;
  }

  referanceDateDisabledDate = (current: Date): boolean =>
    differenceInCalendarDays(
      current,
      new Date(this.data2.TREATMENT_START_DATE)
    ) < 0;

  disableReferanceDate() {
    this.referanceDateDisabledDate = (current: Date): boolean =>
      differenceInCalendarDays(
        current,
        new Date(this.data2.BILL_FILIING_DATE)
      ) > 0;
  }
  // TREATEMENT_TYPE1 =false;
  // TREATEMENT_TYPE2 =false;

  onItemChecked(checked: boolean) {
    this.TREATEMENT_TYPE1 = checked;
    this.data2.TREATEMENT_TYPE = '';
    if (this.TREATEMENT_TYPE1 == true && this.TREATEMENT_TYPE2 == true) {
      this.data2.TREATEMENT_TYPE = 'OT,IT';
    }
    if (this.TREATEMENT_TYPE1 == false && this.TREATEMENT_TYPE2 == true) {
      this.data2.TREATEMENT_TYPE = 'IT';
    }
    if (this.TREATEMENT_TYPE1 == true && this.TREATEMENT_TYPE2 == false) {
      this.data2.TREATEMENT_TYPE = 'OT';
    }
  }

  onItemChecked2(checked: boolean) {
    this.TREATEMENT_TYPE2 = checked;
    this.data2.TREATEMENT_TYPE = '';
    if (this.TREATEMENT_TYPE1 == true && this.TREATEMENT_TYPE2 == true) {
      this.data2.TREATEMENT_TYPE = 'OT,IT';
    }
    if (this.TREATEMENT_TYPE1 == false && this.TREATEMENT_TYPE2 == true) {
      this.data2.TREATEMENT_TYPE = 'IT';
    }
    if (this.TREATEMENT_TYPE1 == true && this.TREATEMENT_TYPE2 == false) {
      this.data2.TREATEMENT_TYPE = 'OT';
    }
  }

  claimConfirmation() {
    if (
      ((this.data3.EMERGENCY_TREATEMENT == true || this.emergencyTaken == 1) &&
        (this.data3.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == false ||
          this.drCertificate == 0) &&
        (this.data3.IS_HOSPITAL_EMPLANELLED == true ||
          this.empanelHospital == 1) &&
        (this.data3.EXPO_FACTO_PERMISSION == false || this.expoFacto == 0)) ||
      ((this.data3.EMERGENCY_TREATEMENT == false || this.emergencyTaken == 0) &&
        (this.data3.REFERENCE_FORM_CGHS_AMA_TAKEN == true ||
          this.referanceTaken == 1) &&
        (this.data3.IS_HOSPITAL_EMPLANELLED == false ||
          this.empanelHospital == 0) &&
        (this.data3.IS_PERMISSION_TAKEN_FROM_HOD == false ||
          this.hodPermission == 0) &&
        (this.data3.EXPO_FACTO_PERMISSION == false || this.expoFacto == 0)) ||
      ((this.data3.EMERGENCY_TREATEMENT == false || this.emergencyTaken == 0) &&
        (this.data3.REFERENCE_FORM_CGHS_AMA_TAKEN == false ||
          this.referanceTaken == 0) &&
        (this.data3.IS_HOSPITAL_EMPLANELLED == false ||
          this.empanelHospital == 0) &&
        (this.data3.EXPO_FACTO_PERMISSION == false || this.expoFacto == 0)) ||
      ((this.data3.EMERGENCY_TREATEMENT == false || this.emergencyTaken == 0) &&
        (this.data3.IS_HOSPITAL_EMPLANELLED == true ||
          this.empanelHospital == 1) &&
        (this.data3.EXPO_FACTO_PERMISSION == false || this.expoFacto == 0) &&
        (this.data3.REFERENCE_FORM_CGHS_AMA_TAKEN == false ||
          this.referanceTaken == 0))
    ) {
      this.data4.CHECKLIST_STATUS = 'R';
    } else {
      this.data4.CHECKLIST_STATUS = 'A';
    }
  }

  relationshipFilter(event) {
    this.isSpinning = true;
    if (event == 'Self') {
      this.api
        .getEmployeeMaster(1, 1, '', '', ' AND ID = ' + this.empID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              this.data2.PATIENT_NAME = data['data'][0]['NAME'];
              this.isSpinning = false;
            } else {
              this.message.error("Can't Load Employee Data", '');
              this.isSpinning = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
      this.data2.PATIENT_CGHS_BENEFICIERY_NO = this.data.CGHS_CARD_NO;
    } else {
      this.data2.PATIENT_CGHS_BENEFICIERY_NO = '';
      this.data2.PATIENT_NAME = '';
      this.isSpinning = false;
    }
  }
  list: any = [];
  ADD(CGHS_AMA_REFERENCE_DATE: any) {
    this.list.push(CGHS_AMA_REFERENCE_DATE);
  }

  onChangeReferance(event: any) {
    if (event == false) {
      this.data3.CGHS_AMA_REFERENCE_DATE = [];
      this.data3.CGHS_AMA_REFERENCE_END_DATE = [];
      this.data3.CGHS_AMA_REFERENCE_NO = '';
      this.data3.IS_PERMISSION_TAKEN_FROM_HOD = false;
      this.data3.HOD_PERMISSION_DATE = null;
      this.data3.HOD_PERMISSION_NO = '';
    } else {
    }
  }

  onChangeExpoFacto(event: any) {
    if (event == false) {
      this.data3.EXPO_FACTO_DATE = null;
      this.data3.EXPO_FACTO_REFERENCE_NO = '';
    } else {
    }
  }

  onChangeHOD(event: any) {
    if (event == false) {
      this.data3.HOD_PERMISSION_DATE = null;
      this.data3.HOD_PERMISSION_NO = '';
    } else {
      this.data3.EXPO_FACTO_PERMISSION = false;
      this.data3.EXPO_FACTO_DATE = null;
      this.data3.EXPO_FACTO_REFERENCE_NO = '';
    }
  }
  onChangeBillIntime(event: any) {
    if (event == true) {
      this.data3.DELAY_CONDOLENCE_DATE = null;
      this.data3.DELAY_CONDOLENCE_NUMBER = '';
    } else {
      this.data3.DELAY_CONDOLENCE_DATE = this.data3.DELAY_CONDOLENCE_DATE;
      this.data3.DELAY_CONDOLENCE_NUMBER = this.data3.DELAY_CONDOLENCE_NUMBER;
    }
  }

  addDates() {
    if (
      (this.CGHS_AMA_REFERENCE_DATE == undefined ||
        this.CGHS_AMA_REFERENCE_DATE == null ||
        this.CGHS_AMA_REFERENCE_DATE == '') &&
      (this.CGHS_AMA_REFERENCE_END_DATE == undefined ||
        this.CGHS_AMA_REFERENCE_END_DATE == null ||
        this.CGHS_AMA_REFERENCE_END_DATE == '')
    ) {
      this.message.error('Please Select Date', '');
    } else if (
      (this.CGHS_AMA_REFERENCE_DATE == undefined ||
        this.CGHS_AMA_REFERENCE_DATE == null ||
        this.CGHS_AMA_REFERENCE_DATE == '') &&
      (this.CGHS_AMA_REFERENCE_END_DATE != undefined ||
        this.CGHS_AMA_REFERENCE_END_DATE != null ||
        this.CGHS_AMA_REFERENCE_END_DATE != '')
    ) {
      this.message.error("Please Select CGHS/AMA's Reference Start Date", '');
    } else if (
      (this.CGHS_AMA_REFERENCE_DATE != undefined ||
        this.CGHS_AMA_REFERENCE_DATE != null ||
        this.CGHS_AMA_REFERENCE_DATE != '') &&
      (this.CGHS_AMA_REFERENCE_END_DATE == undefined ||
        this.CGHS_AMA_REFERENCE_END_DATE == null ||
        this.CGHS_AMA_REFERENCE_END_DATE == '')
    ) {
      this.message.error("Please Select CGHS/AMA's Reference End Date", '');
    } else {
      this.date1 = '';
      this.expoDiffDays = '';
      var date = this.datepipe.transform(
        this.CGHS_AMA_REFERENCE_DATE,
        'yyyy-MM-dd'
      );
      this.date2 = '';
      this.expoDiffDays = '';
      var enddate = this.datepipe.transform(
        this.CGHS_AMA_REFERENCE_END_DATE,
        'yyyy-MM-dd'
      );

      this.data3.CGHS_AMA_REFERENCE_DATE.push(date);
      this.CGHS_AMA_REFERENCE_DATE = null;

      this.data3.CGHS_AMA_REFERENCE_END_DATE.push(enddate);
      this.CGHS_AMA_REFERENCE_END_DATE = null;

      this.date1 = new Date(
        Math.max.apply(
          null,
          this.data3.CGHS_AMA_REFERENCE_END_DATE.map(function (e) {
            return new Date(e);
          })
        )
      );
      var date2: any = new Date(this.data2.BILL_FILIING_DATE);

      this.expoDiffDays = Math.floor(
        (date2 - this.date1) / (1000 * 60 * 60 * 24)
      );
    }
  }
  drawerData: EmployeeMaster = new EmployeeMaster();
  drawerTitle: string = '';

  add(): void {
    this.drawerTitle = 'Create New Employee';
    this.drawerData = new EmployeeMaster();
    this.empDrawerVisible = true;
  }
  editEmp;

  edit(data: any): void {
    this.drawerTitle = 'Edit Employee Details';
    this.api
      .getEmployeeMaster(0, 0, '', '', ' AND ID = ' + data.EMP_ID)
      .subscribe((data) => {
        if (data['code'] == 200) {
          this.drawerData = data['data'][0];
        } else {
          this.message.error("Can't Load Employee Data", '');
        }
      }),
      (this.empDrawerVisible = true);
  }

  empDrawerClose(): void {
    this.empDrawerVisible = false;
    this.allEmployeeList();
    // window.location.reload();
  }

  get closeCallback() {
    return this.empDrawerClose.bind(this);
  }

  emergencySwitch(event) {
    if (event == true) {
      this.data3.REFERENCE_FORM_CGHS_AMA_TAKEN = false;
      this.data3.CGHS_AMA_REFERENCE_DATE = [];
      this.data3.IS_PERMISSION_TAKEN_FROM_HOD = false;
      this.data3.HOD_PERMISSION_DATE = null;
      this.data3.HOD_PERMISSION_NO = '';
    } else {
      this.data3.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN = false;
    }
  }

  doctorSwitch(event) {
    if (event == true) {
      this.data3.EXPO_FACTO_PERMISSION = false;
      this.data3.EXPO_FACTO_DATE = null;
      this.data3.EXPO_FACTO_REFERENCE_NO = '';
    } else {
    }
  }

  checkDateDiff() {
    if (
      this.data3.CGHS_AMA_REFERENCE_DATE != undefined ||
      this.data3.CGHS_AMA_REFERENCE_DATE != null ||
      this.data3.CGHS_AMA_REFERENCE_DATE != '' ||
      this.data3.CGHS_AMA_REFERENCE_DATE.length != 0
    ) {
      // this.data3.CGHS_AMA_REFERENCE_DATE =
      //   this.data3.CGHS_AMA_REFERENCE_DATE.split(',');
      // this.dateDifference()
      this.date1 = new Date(
        Math.max.apply(
          null,
          this.data3.CGHS_AMA_REFERENCE_DATE.map(function (e) {
            return new Date(e);
          })
        )
      );
      var date2: any = new Date(this.data2.BILL_FILIING_DATE);

      this.expoDiffDays = Math.floor(
        (date2 - this.date1) / (1000 * 60 * 60 * 24)
      );
    } else {
    }
    if (
      this.data3.EMERGENCY_TREATEMENT == false &&
      this.data3.REFERENCE_FORM_CGHS_AMA_TAKEN == true &&
      this.expoDiffDays < 30
    ) {
      // this.onChangeExpoFacto(false);
      this.data3.EXPO_FACTO_PERMISSION = false;
      this.data3.EXPO_FACTO_DATE = null;
      this.data3.EXPO_FACTO_REFERENCE_NO = '';
    } else {
    }
  }

  HOSPITAL_ADDRESS;
  HOSPITAL_TYPE;
  HOSPITAL_NAME;
  HOSPITAL_ID;
  hospitalMapList: any = [];
  addHospital(addNew: boolean, hospitalFormReset: NgForm) {
    this.hospitalData.CLAIM_ID = this.claimID;
    this.isSpinning = false;
    this.isOk = true;
    // if(this.hospitalData.HOSPITAL_ID !=undefined || this.hospitalData.HOSPITAL_ID !=null){

    // }
    if (
      this.hospitalData.TYPE == '' &&
      this.hospitalData.ADDRESS == '' &&
      this.hospitalData.ACCREDATION == ''
    ) {
      this.isOk = false;
      this.message.error('Please Fill All The Required Fields ', '');
    } else if (
      this.hospitalData.TYPE == null ||
      this.hospitalData.TYPE.trim() == '' ||
      this.hospitalData.TYPE == undefined
    ) {
      this.isOk = false;
      this.message.error(' Please Select Hospital Type.', '');
    } else if (
      this.hospitalData.TYPE == 'E' &&
      (this.hospitalData.HOSPITAL_ID == undefined ||
        this.hospitalData.HOSPITAL_ID == null)
    ) {
      this.isOk = false;
      this.message.error('Please Select Hospital Name ', '');
    } else if (
      (this.hospitalData.TYPE == 'NE' || this.hospitalData.TYPE == 'G') &&
      (this.hospitalData.NAME == undefined ||
        this.hospitalData.NAME == null ||
        this.hospitalData.NAME == '')
    ) {
      this.isOk = false;
      this.message.error('Please Enter Hospital Name ', '');
    } else if (
      this.hospitalData.ADDRESS == undefined ||
      this.hospitalData.ADDRESS == null ||
      this.hospitalData.ADDRESS == ''
    ) {
      this.isOk = false;
      this.message.error('Please Enter Hospital Address ', '');
    } else if (
      this.hospitalData.ACCREDATION == undefined ||
      this.hospitalData.ACCREDATION == null ||
      this.hospitalData.ACCREDATION == ''
    ) {
      this.isOk = false;
      this.message.error('Please Select Accreditation ', '');
    }
    if (this.isOk) {
      this.isSpinning = true;
      {
        if (this.hospitalData.ID) {
          this.api
            .updateHospitalMapping(this.hospitalData)
            .subscribe((successCode) => {
              if (successCode.code == '200') {
                this.message.success('Information Changed Successfully...', '');
                hospitalFormReset.form.reset();
                this.resetDrawer(hospitalFormReset);
                // this.hospitalData.TYPE = null;
                // this.hospitalData.HOSPITAL_ID = null;
                // this.hospitalData.NAME = '';
                // this.hospitalData.ADDRESS = '';
                // this.hospitalData.ACCREDATION = '';
                this.api
                  .getHospitalMapping(
                    0,
                    0,
                    '',
                    '',
                    ' AND CLAIM_ID = ' + this.claimID
                  )
                  .subscribe(
                    (data) => {
                      if (data['code'] == 200) {
                        this.hospitalMapList = data['data'];
                        this.isSpinning = false;
                      } else {
                        this.message.error(
                          "Can't Load Hospital Mapped Data.",
                          ''
                        );
                        this.isSpinning = false;
                      }
                    },
                    (err) => {
                      console.log(err);
                    }
                  );
                // if (!addNew)
                // this.drawerClose();
                this.isSpinning = false;
              } else {
                this.message.error('Information Has Not Changed...', '');
                this.isSpinning = false;
              }
            });
        } else {
          this.api
            .createHospitalMapping(this.hospitalData)
            .subscribe((successCode) => {
              if (successCode.code == '200') {
                this.message.success('Information Save Successfully...', '');
                // if (!addNew)
                // this.drawerClose();
                // else {
                //   this.hospitalData = new HospitalMappingMaster();
                //   // this.resetDrawer(claimMasterPage);
                //   // this.data.IMG_URL= '';

                //   // this.api.getEmployeeMaster(0, 0, '', 'desc', '').subscribe(
                //   //   (data) => {
                //       // if (data['count']==0){
                //       //   this.data.SEQUENCE_NUMBER=1;
                //       // }else
                //       // {
                //       //   this.data.SEQUENCE_NUMBER=data['data'][0]['SEQUENCE_NUMBER']+1;
                //       // }
                //     // },
                //     // (err) => {
                //     //   console.log(err);
                //     // }
                //   // );
                // }
                // this.hospitalData.TYPE = null;
                // this.hospitalData.HOSPITAL_ID = null;
                // this.hospitalData.NAME = '';
                // this.hospitalData.ADDRESS = '';
                // this.hospitalData.ACCREDATION = '';
                hospitalFormReset.form.reset();
                this.resetDrawer(hospitalFormReset);
                this.api
                  .getHospitalMapping(
                    0,
                    0,
                    '',
                    '',
                    ' AND CLAIM_ID = ' + this.claimID
                  )
                  .subscribe(
                    (data) => {
                      if (data['code'] == 200) {
                        this.hospitalMapList = data['data'];
                        this.isSpinning = false;
                      } else {
                        this.message.error(
                          "Can't Load Hospital Mapped Data.",
                          ''
                        );
                        this.isSpinning = false;
                      }
                    },
                    (err) => {
                      console.log(err);
                    }
                  );
                this.isSpinning = false;
              } else {
                this.message.error('Failed To Fill Information...', '');
                this.isSpinning = false;
              }
            });
        }
      }
    }
  }

  editHospital(data: any): void {
    // this.drawerTitle = 'Edit Claim Details';
    this.hospitalData = Object.assign({}, data);
  }

  cancel(): void {}

  resetDrawer(hospitalFormReset: NgForm) {
    this.hospitalData = new HospitalMappingMaster();
    // this.data.PARENT_GROUP_ID=this.parentgroup;
    // hospitalFormReset.form.markAsPristine();
    // hospitalFormReset.form.markAsUntouched();
  }

  employeeSearch(event: any) {
    // this.procedureList2 = [];
    var f = '';
    if (event.length >= 3) {
      this.api
        .getEmployeeMaster(
          0,
          0,
          '',
          'asc',
          " AND NAME like '%" +
            event +
            "%'" +
            " OR EMPLOYEE_CODE like '%" +
            event +
            "%'" +
            " OR OFFICE_NAME like '%" +
            event +
            "%'" +
            " OR DESIGNATION like '%" +
            event +
            "%'" +
            " OR DDO_OF_THE_OFFICIAL like '%" +
            event +
            "%'" +
            f
        )
        .subscribe(
          (empData) => {
            if (empData['code'] == 200) {
              var filteredOptions = empData['data'];

              // this.employee = [...[], ...empData['data']];

              // this.empLoader = false;
            } else {
              this.message.error("Can't Load Employee Data", '');
              // this.empLoader = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
    }
    // this.api
    //   .getinvestigationprocedure(
    //     0,
    //     0,
    //     'NAME',
    //     'asc',
    //     " and NAME like '%" + event + "%'" + f
    //   )
    //   .subscribe(
    //     (data) => {
    //       if (data['code'] == 200 && data['data'].length > 0)
    //         this.procedureList2 = data['data'];
    //       else this.procedureList2 = [];
    //     },
    //     (err) => {
    //       console.log(err);
    //       this.isSpinning = false;
    //     }
    //   );
    // }
  }
  pressCS(event) {
    if (event == 'CS') {
      this.data.CGHS_CARD_VALIDITY = '';
      this.data.CGHS_CARD_NO = '';
    } else {
    }
  }
  // clearRemark(event) {
  //   if (event == 'A') {
  //     this.data4.REJECT_REMARK = null;
  //     this.data4.ON_HOLD_REMARK = null;
  //     this.data4.ON_HOLD_DATE = null;
  //   } else if (event == 'R') {
  //     this.data4.ON_HOLD_REMARK = null;
  //     this.data4.ON_HOLD_DATE = null;
  //   } else {
  //     this.data4.REJECT_REMARK = null;
  //   }
  // }

  orderSheetVisible: boolean = false;
  orderSheetTitle: string = '';
  orderValue: any = '';
  orderSheet() {
    this.orderValue = '';
    this.orderSheetTitle = 'Order Sheet';
    this.orderSheetVisible = true;
    // this.orderValue = 1;
  }
  permissionLetter() {
    this.orderValue = '';
    this.orderSheetTitle = 'Permission Letter';
    this.orderSheetVisible = true;
    // this.orderValue = 2;
  }

  orderSheetClose(): void {
    this.orderSheetVisible = false;
  }

  get orderSheetCloseCallback() {
    return this.orderSheetClose.bind(this);
  }
  HospitalMapping = [];
  queData: any = [];
  empID1;
  claimID1;
  // delayOrderSheetVisible: boolean = false;
  // delayOrderSheetTitle: string = '';
  // delayOrderSheet() {
  //   this.isSpinning = true;
  //   this.claimID1 = '';
  //   this.empID1 = '';
  //   this.delayOrderSheetTitle = 'Order Letter';
  //   this.isOk = true;
  //   if (this.isOk) {
  //     this.isSpinning = true;
  //     this.data3.DELAY_CONDOLENCE_DATE = this.datepipe.transform(
  //       this.data3.DELAY_CONDOLENCE_DATE,
  //       'yyyy-MM-dd'
  //     );
  //     this.data3.EXPOST_FACTO_PERMISSION_DATE = this.datepipe.transform(
  //       this.data3.EXPOST_FACTO_PERMISSION_DATE,
  //       'yyyy-MM-dd'
  //     );
  //     this.data3.EXPO_FACTO_DATE = this.datepipe.transform(
  //       this.data3.EXPO_FACTO_DATE,
  //       'yyyy-MM-dd'
  //     );
  //     this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
  //       this.data3.HOD_PERMISSION_DATE,
  //       'yyyy-MM-dd'
  //     );
  //     this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
  //       this.data3.HOD_PERMISSION_DATE,
  //       'yyyy-MM-dd'
  //     );
  //     if (
  //       this.data3.CGHS_AMA_REFERENCE_DATE == undefined &&
  //       this.data3.CGHS_AMA_REFERENCE_DATE == null &&
  //       this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
  //     ) {
  //       this.data3.CGHS_AMA_REFERENCE_DATE = '';
  //     } else {
  //       this.data3.CGHS_AMA_REFERENCE_DATE = this.data3.CGHS_AMA_REFERENCE_DATE;
  //     }
  //     if (
  //       this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined &&
  //       this.data3.CGHS_AMA_REFERENCE_END_DATE == null &&
  //       this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
  //     ) {
  //       this.data3.CGHS_AMA_REFERENCE_END_DATE = '';
  //     } else {
  //       this.data3.CGHS_AMA_REFERENCE_END_DATE =
  //         this.data3.CGHS_AMA_REFERENCE_END_DATE;
  //     }

  //     if (
  //       this.data3.BILL_FILLED_INTIME == false &&
  //       (this.data3.DELAY_CONDOLENCE_DATE != undefined ||
  //         this.data3.DELAY_CONDOLENCE_DATE != null)
  //     ) {
  //       this.data3.DELAY_CONDOLENCE_DATE = this.data3.DELAY_CONDOLENCE_DATE;
  //     } else {
  //       this.data3.DELAY_CONDOLENCE_DATE = null;
  //     }
  //     if (
  //       this.data3.BILL_FILLED_INTIME == false &&
  //       (this.data3.DELAY_CONDOLENCE_NUMBER != undefined ||
  //         this.data3.DELAY_CONDOLENCE_NUMBER != null)
  //     ) {
  //       this.data3.DELAY_CONDOLENCE_NUMBER = this.data3.DELAY_CONDOLENCE_NUMBER;
  //     } else {
  //       this.data3.DELAY_CONDOLENCE_NUMBER = '';
  //     }
  //     {
  //       if (this.data3.ID) {
  //         this.api.updateQuestions(this.data3).subscribe((successCode) => {
  //           if (successCode.code == '200') {
  //             // this.message.success('Information Changed Successfully...', '');
  //             this.api
  //               .getclaimMaster(
  //                 0,
  //                 0,
  //                 '',
  //                 '',
  //                 ' AND ID=' + this.claimID,
  //                 '',
  //                 '',
  //                 '',
  //                 ''
  //               )
  //               .subscribe(
  //                 (data) => {
  //                   if (data['code'] == 200) {

  //                     this.orderdata1 = data['data'][0];
  //                     this.claimID1 = data['data'][0]['ID'];
  //                     this.empID1 = data['data'][0]['EMP_ID'];

  //                     this.api
  //                       .getHospitalMapping(
  //                         0,
  //                         0,
  //                         '',
  //                         '',
  //                         ' AND CLAIM_ID = ' + this.claimID
  //                       )
  //                       .subscribe(
  //                         (data) => {
  //                           if (data['code'] == 200) {
  //                             this.HospitalMapping = data['data'];

  //                             // this.isSpinning = false;
  //                             this.api
  //                               .getAllQuestions(
  //                                 0,
  //                                 0,
  //                                 '',
  //                                 '',
  //                                 ' AND CLAIM_ID =' + this.claimID
  //                               )
  //                               .subscribe(
  //                                 (data) => {
  //                                   if (data['code'] == 200) {
  //                                     if (data['data'].length == 0) {
  //                                       this.queData = new QuestionaryMaster();
  //                                     } else {
  //                                       this.queData = data['data'][0];
  //                                       if (
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_DATE ==
  //                                           undefined &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_DATE == null &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_DATE == '' &&
  //                                         this.queData.CGHS_AMA_REFERENCE_DATE
  //                                           .length == 0
  //                                       ) {
  //                                         this.queData.CGHS_AMA_REFERENCE_DATE =
  //                                           [];
  //                                       } else {
  //                                         this.queData.CGHS_AMA_REFERENCE_DATE =
  //                                           this.queData.CGHS_AMA_REFERENCE_DATE.split(
  //                                             ','
  //                                           );
  //                                       }
  //                                       if (
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_END_DATE ==
  //                                           undefined &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_END_DATE ==
  //                                           null &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_END_DATE ==
  //                                           '' &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_END_DATE
  //                                           .length == 0
  //                                       ) {
  //                                         this.queData.CGHS_AMA_REFERENCE_END_DATE =
  //                                           [];
  //                                       } else {
  //                                         this.queData.CGHS_AMA_REFERENCE_END_DATE =
  //                                           this.queData.CGHS_AMA_REFERENCE_END_DATE.split(
  //                                             ','
  //                                           );
  //                                       }
  //                                     }
  //                                   } else {
  //                                     this.message.error(
  //                                       'Something Went Wrong',
  //                                       ''
  //                                     );
  //                                     // this.isSpinning = false;
  //                                   }
  //                                 },
  //                                 (err) => {
  //                                   console.log(err);
  //                                 }
  //                               );
  //                           } else {
  //                             this.message.error('Something Went Wrong', '');
  //                             // this.isSpinning = false;
  //                           }
  //                         },
  //                         (err) => {
  //                           console.log(err);
  //                         }
  //                       );
  //                   } else {
  //                     this.message.error("Can't Load Data", '');
  //                   }
  //                 },
  //                 (err) => {
  //                   console.log(err);
  //                 }
  //               );
  //             this.isSpinning = false;
  //             this.delayOrderSheetVisible = true;
  //           } else {
  //             this.message.error('Information Has Not Changed...', '');
  //             this.isSpinning = false;
  //           }
  //         });
  //       } else {
  //         this.api.createQuestions(this.data3).subscribe((successCode) => {
  //           if (successCode.code == '200') {
  //             // this.message.success('Information Save Successfully...', '');
  //             this.api
  //               .getclaimMaster(
  //                 0,
  //                 0,
  //                 '',
  //                 '',
  //                 ' AND ID=' + this.claimID,
  //                 '',
  //                 '',
  //                 '',
  //                 ''
  //               )
  //               .subscribe(
  //                 (data) => {
  //                   if (data['code'] == 200) {

  //                     this.orderdata1 = data['data'][0];
  //                     this.claimID1 = data['data'][0]['ID'];
  //                     this.empID1 = data['data'][0]['EMP_ID'];

  //                     this.api
  //                       .getHospitalMapping(
  //                         0,
  //                         0,
  //                         '',
  //                         '',
  //                         ' AND CLAIM_ID = ' + this.claimID
  //                       )
  //                       .subscribe(
  //                         (data) => {
  //                           if (data['code'] == 200) {
  //                             this.HospitalMapping = data['data'];

  //                             // this.isSpinning = false;
  //                             this.api
  //                               .getAllQuestions(
  //                                 0,
  //                                 0,
  //                                 '',
  //                                 '',
  //                                 ' AND CLAIM_ID =' + this.claimID
  //                               )
  //                               .subscribe(
  //                                 (data) => {
  //                                   if (data['code'] == 200) {
  //                                     if (data['data'].length == 0) {
  //                                       this.queData = new QuestionaryMaster();
  //                                     } else {
  //                                       this.queData = data['data'][0];
  //                                       if (
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_DATE ==
  //                                           undefined &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_DATE == null &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_DATE == '' &&
  //                                         this.queData.CGHS_AMA_REFERENCE_DATE
  //                                           .length == 0
  //                                       ) {
  //                                         this.queData.CGHS_AMA_REFERENCE_DATE =
  //                                           [];
  //                                       } else {
  //                                         this.queData.CGHS_AMA_REFERENCE_DATE =
  //                                           this.queData.CGHS_AMA_REFERENCE_DATE.split(
  //                                             ','
  //                                           );
  //                                       }
  //                                       if (
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_END_DATE ==
  //                                           undefined &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_END_DATE ==
  //                                           null &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_END_DATE ==
  //                                           '' &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_END_DATE
  //                                           .length == 0
  //                                       ) {
  //                                         this.queData.CGHS_AMA_REFERENCE_END_DATE =
  //                                           [];
  //                                       } else {
  //                                         this.queData.CGHS_AMA_REFERENCE_END_DATE =
  //                                           this.queData.CGHS_AMA_REFERENCE_END_DATE.split(
  //                                             ','
  //                                           );
  //                                       }
  //                                     }
  //                                   } else {
  //                                     this.message.error(
  //                                       'Something Went Wrong',
  //                                       ''
  //                                     );
  //                                     this.isSpinning = false;
  //                                   }
  //                                 },
  //                                 (err) => {
  //                                   console.log(err);
  //                                 }
  //                               );
  //                           } else {
  //                             this.message.error('Something Went Wrong', '');
  //                             // this.isSpinning = false;
  //                           }
  //                         },
  //                         (err) => {
  //                           console.log(err);
  //                         }
  //                       );
  //                   } else {
  //                     this.message.error("Can't Load Data", '');
  //                   }
  //                 },
  //                 (err) => {
  //                   console.log(err);
  //                 }
  //               );
  //             this.delayOrderSheetVisible = true;
  //             this.isSpinning = false;
  //           } else {
  //             this.message.error('Failed To Fill Information...', '');
  //             this.isSpinning = false;
  //           }
  //         });
  //       }
  //     }
  //   }
  //   this.orderdata1 = [];
  // }

  // delayOrderSheetClose(): void {
  //   // this.delayOrderSheetVisible = false;
  //   this.disableReferanceDate();
  //   this.api
  //     .getAllQuestions(0, 0, '', '', ' AND CLAIM_ID =' + this.claimID)
  //     .subscribe(
  //       (data) => {
  //         if (data['code'] == 200) {
  //           if (data['data'].length == 0) {
  //             this.data3 = new QuestionaryMaster();
  //             this.billInTime();
  //             this.empanelledHospital();
  //             // this.current = 2;
  //             this.delayOrderSheetVisible = false;
  //             this.isSpinning = false;
  //           } else {
  //             this.data3 = data['data'][0];
  //             this.emergencyTaken = data['data'][0]['EMERGENCY_TREATEMENT'];
  //             this.drCertificate =
  //               data['data'][0]['DOCTOR_EMERGENCY_CERTIFICATE_TAKEN'];
  //             this.empanelHospital = data['data'][0]['IS_HOSPITAL_EMPLANELLED'];
  //             this.expoFacto = data['data'][0]['EXPO_FACTO_PERMISSION'];
  //             this.referanceTaken =
  //               data['data'][0]['REFERENCE_FORM_CGHS_AMA_TAKEN'];
  //             this.hodPermission =
  //               data['data'][0]['IS_PERMISSION_TAKEN_FROM_HOD'];
  //             if (
  //               this.data3.CGHS_AMA_REFERENCE_DATE == undefined &&
  //               this.data3.CGHS_AMA_REFERENCE_DATE == null &&
  //               this.data3.CGHS_AMA_REFERENCE_DATE == '' &&
  //               this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
  //             ) {
  //               this.data3.CGHS_AMA_REFERENCE_DATE = [];
  //             } else {
  //               this.data3.CGHS_AMA_REFERENCE_DATE =
  //                 this.data3.CGHS_AMA_REFERENCE_DATE.split(',');
  //               this.dateDifference();
  //             }
  //             if (
  //               this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined &&
  //               this.data3.CGHS_AMA_REFERENCE_END_DATE == null &&
  //               this.data3.CGHS_AMA_REFERENCE_END_DATE == '' &&
  //               this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
  //             ) {
  //               this.data3.CGHS_AMA_REFERENCE_END_DATE = [];
  //             } else {
  //               this.data3.CGHS_AMA_REFERENCE_END_DATE =
  //                 this.data3.CGHS_AMA_REFERENCE_END_DATE.split(',');
  //               this.dateDifference();
  //             }
  //             this.calculateDiff();
  //             this.billInTime();
  //             this.empanelledHospital();
  //             // this.current = 2;
  //             this.isSpinning = false;
  //             this.delayOrderSheetVisible = false;
  //           }
  //         } else {
  //           this.message.error('Something Went Wrong', '');
  //           this.isSpinning = false;
  //         }
  //       },
  //       (err) => {
  //         console.log(err);
  //       }
  //     );
  // }

  delayOrderSheetCloseNew(): void {
    this.api
      .getAllQuestions(0, 0, '', '', ' AND CLAIM_ID =' + this.claimID)
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            if (data['data'].length == 0) {
              this.data3 = new QuestionaryMaster();
              this.billInTime();
              this.empanelledHospital();
              this.delayOrderSheetnewVisible = false;
              this.isSpinning = false;
            } else {
              this.data3 = data['data'][0];
              this.emergencyTaken = data['data'][0]['EMERGENCY_TREATEMENT'];
              this.drCertificate =
                data['data'][0]['DOCTOR_EMERGENCY_CERTIFICATE_TAKEN'];
              this.empanelHospital = data['data'][0]['IS_HOSPITAL_EMPLANELLED'];
              this.expoFacto = data['data'][0]['EXPO_FACTO_PERMISSION'];
              this.referanceTaken =
                data['data'][0]['REFERENCE_FORM_CGHS_AMA_TAKEN'];
              this.hodPermission =
                data['data'][0]['IS_PERMISSION_TAKEN_FROM_HOD'];
              if (
                this.data3.CGHS_AMA_REFERENCE_DATE == undefined ||
                this.data3.CGHS_AMA_REFERENCE_DATE == null ||
                this.data3.CGHS_AMA_REFERENCE_DATE == '' ||
                this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
              ) {
                this.data3.CGHS_AMA_REFERENCE_DATE = [];
              } else {
                this.data3.CGHS_AMA_REFERENCE_DATE =
                  this.data3.CGHS_AMA_REFERENCE_DATE.split(',');
                // this.dateDifference();
              }
              if (
                this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined ||
                this.data3.CGHS_AMA_REFERENCE_END_DATE == null ||
                this.data3.CGHS_AMA_REFERENCE_END_DATE == '' ||
                this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
              ) {
                this.data3.CGHS_AMA_REFERENCE_END_DATE = [];
              } else {
                this.data3.CGHS_AMA_REFERENCE_END_DATE =
                  this.data3.CGHS_AMA_REFERENCE_END_DATE.split(',');
                this.dateDifference();
              }
              // this.calculateDiff();
              this.billInTime();
              this.empanelledHospital();
              this.delayOrderSheetnewVisible = false;
              this.isSpinning = false;
            }
          } else {
            this.message.error('Something Went Wrong', '');
            this.isSpinning = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }
  get delayOrderSheetCloseCallback() {
    return this.delayOrderSheetCloseNew.bind(this);
  }

  // delayPermissionVisible: boolean = false;
  // delayPermissionTitle: string = '';
  // delayPermissionData: any = [];
  // delayPermissionClaimData = [];
  // delayPermission() {
  //   this.delayPermissionTitle = 'Permission Letter';
  //   this.delayPermissionVisible = true;
  //   if (this.isOk) {
  //     this.isSpinning = true;
  //     this.data3.DELAY_CONDOLENCE_DATE = this.datepipe.transform(
  //       this.data3.DELAY_CONDOLENCE_DATE,
  //       'yyyy-MM-dd'
  //     );
  //     this.data3.EXPOST_FACTO_PERMISSION_DATE = this.datepipe.transform(
  //       this.data3.EXPOST_FACTO_PERMISSION_DATE,
  //       'yyyy-MM-dd'
  //     );
  //     this.data3.EXPO_FACTO_DATE = this.datepipe.transform(
  //       this.data3.EXPO_FACTO_DATE,
  //       'yyyy-MM-dd'
  //     );
  //     this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
  //       this.data3.HOD_PERMISSION_DATE,
  //       'yyyy-MM-dd'
  //     );
  //     this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
  //       this.data3.HOD_PERMISSION_DATE,
  //       'yyyy-MM-dd'
  //     );
  //     if (
  //       this.data3.CGHS_AMA_REFERENCE_DATE == undefined &&
  //       this.data3.CGHS_AMA_REFERENCE_DATE == null &&
  //       this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
  //     ) {
  //       this.data3.CGHS_AMA_REFERENCE_DATE = '';
  //     } else {
  //       this.data3.CGHS_AMA_REFERENCE_DATE = this.data3.CGHS_AMA_REFERENCE_DATE;
  //     }
  //     if (
  //       this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined &&
  //       this.data3.CGHS_AMA_REFERENCE_END_DATE == null &&
  //       this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
  //     ) {
  //       this.data3.CGHS_AMA_REFERENCE_END_DATE = '';
  //     } else {
  //       this.data3.CGHS_AMA_REFERENCE_END_DATE =
  //         this.data3.CGHS_AMA_REFERENCE_END_DATE;
  //     }

  //     if (
  //       this.data3.BILL_FILLED_INTIME == false &&
  //       (this.data3.DELAY_CONDOLENCE_DATE != undefined ||
  //         this.data3.DELAY_CONDOLENCE_DATE != null)
  //     ) {
  //       this.data3.DELAY_CONDOLENCE_DATE = this.data3.DELAY_CONDOLENCE_DATE;
  //     } else {
  //       this.data3.DELAY_CONDOLENCE_DATE = null;
  //     }
  //     if (
  //       this.data3.BILL_FILLED_INTIME == false &&
  //       (this.data3.DELAY_CONDOLENCE_NUMBER != undefined ||
  //         this.data3.DELAY_CONDOLENCE_NUMBER != null)
  //     ) {
  //       this.data3.DELAY_CONDOLENCE_NUMBER = this.data3.DELAY_CONDOLENCE_NUMBER;
  //     } else {
  //       this.data3.DELAY_CONDOLENCE_NUMBER = '';
  //     }
  //     {
  //       if (this.data3.ID) {
  //         this.api.updateQuestions(this.data3).subscribe((successCode) => {
  //           if (successCode.code == '200') {
  //             // this.message.success('Information Changed Successfully...', '');
  //             this.api
  //               .getclaimMaster(
  //                 0,
  //                 0,
  //                 '',
  //                 '',
  //                 ' AND ID=' + this.claimID,
  //                 '',
  //                 '',
  //                 '',
  //                 ''
  //               )
  //               .subscribe(
  //                 (data) => {
  //                   if (data['code'] == 200) {

  //                     this.orderdata1 = data['data'][0];
  //                     this.claimID1 = data['data'][0]['ID'];
  //                     this.empID1 = data['data'][0]['EMP_ID'];

  //                     this.api
  //                       .getHospitalMapping(
  //                         0,
  //                         0,
  //                         '',
  //                         '',
  //                         ' AND CLAIM_ID = ' + this.claimID
  //                       )
  //                       .subscribe(
  //                         (data) => {
  //                           if (data['code'] == 200) {
  //                             this.HospitalMapping = data['data'];

  //                             // this.isSpinning = false;
  //                             this.api
  //                               .getAllQuestions(
  //                                 0,
  //                                 0,
  //                                 '',
  //                                 '',
  //                                 ' AND CLAIM_ID =' + this.claimID
  //                               )
  //                               .subscribe(
  //                                 (data) => {
  //                                   if (data['code'] == 200) {
  //                                     if (data['data'].length == 0) {
  //                                       this.queData = new QuestionaryMaster();
  //                                     } else {
  //                                       this.queData = data['data'][0];
  //                                       if (
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_DATE ==
  //                                           undefined &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_DATE == null &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_DATE == '' &&
  //                                         this.queData.CGHS_AMA_REFERENCE_DATE
  //                                           .length == 0
  //                                       ) {
  //                                         this.queData.CGHS_AMA_REFERENCE_DATE =
  //                                           [];
  //                                       } else {
  //                                         this.queData.CGHS_AMA_REFERENCE_DATE =
  //                                           this.queData.CGHS_AMA_REFERENCE_DATE.split(
  //                                             ','
  //                                           );
  //                                       }
  //                                       if (
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_END_DATE ==
  //                                           undefined &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_END_DATE ==
  //                                           null &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_END_DATE ==
  //                                           '' &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_END_DATE
  //                                           .length == 0
  //                                       ) {
  //                                         this.queData.CGHS_AMA_REFERENCE_END_DATE =
  //                                           [];
  //                                       } else {
  //                                         this.queData.CGHS_AMA_REFERENCE_END_DATE =
  //                                           this.queData.CGHS_AMA_REFERENCE_END_DATE.split(
  //                                             ','
  //                                           );
  //                                       }
  //                                     }
  //                                   } else {
  //                                     this.message.error(
  //                                       'Something Went Wrong',
  //                                       ''
  //                                     );
  //                                     // this.isSpinning = false;
  //                                   }
  //                                 },
  //                                 (err) => {
  //                                   console.log(err);
  //                                 }
  //                               );
  //                           } else {
  //                             this.message.error('Something Went Wrong', '');
  //                             // this.isSpinning = false;
  //                           }
  //                         },
  //                         (err) => {
  //                           console.log(err);
  //                         }
  //                       );
  //                   } else {
  //                     this.message.error("Can't Load Data", '');
  //                   }
  //                 },
  //                 (err) => {
  //                   console.log(err);
  //                 }
  //               );
  //             this.isSpinning = false;
  //             this.delayPermissionVisible = true;
  //           } else {
  //             this.message.error('Information Has Not Changed...', '');
  //             this.isSpinning = false;
  //           }
  //         });
  //       } else {
  //         this.api.createQuestions(this.data3).subscribe((successCode) => {
  //           if (successCode.code == '200') {
  //             // this.message.success('Information Save Successfully...', '');
  //             this.api
  //               .getclaimMaster(
  //                 0,
  //                 0,
  //                 '',
  //                 '',
  //                 ' AND ID=' + this.claimID,
  //                 '',
  //                 '',
  //                 '',
  //                 ''
  //               )
  //               .subscribe(
  //                 (data) => {
  //                   if (data['code'] == 200) {

  //                     this.orderdata1 = data['data'][0];
  //                     this.claimID1 = data['data'][0]['ID'];
  //                     this.empID1 = data['data'][0]['EMP_ID'];

  //                     this.api
  //                       .getHospitalMapping(
  //                         0,
  //                         0,
  //                         '',
  //                         '',
  //                         ' AND CLAIM_ID = ' + this.claimID
  //                       )
  //                       .subscribe(
  //                         (data) => {
  //                           if (data['code'] == 200) {
  //                             this.HospitalMapping = data['data'];

  //                             // this.isSpinning = false;
  //                             this.api
  //                               .getAllQuestions(
  //                                 0,
  //                                 0,
  //                                 '',
  //                                 '',
  //                                 ' AND CLAIM_ID =' + this.claimID
  //                               )
  //                               .subscribe(
  //                                 (data) => {
  //                                   if (data['code'] == 200) {
  //                                     if (data['data'].length == 0) {
  //                                       this.queData = new QuestionaryMaster();
  //                                     } else {
  //                                       this.queData = data['data'][0];
  //                                       if (
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_DATE ==
  //                                           undefined &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_DATE == null &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_DATE == '' &&
  //                                         this.queData.CGHS_AMA_REFERENCE_DATE
  //                                           .length == 0
  //                                       ) {
  //                                         this.queData.CGHS_AMA_REFERENCE_DATE =
  //                                           [];
  //                                       } else {
  //                                         this.queData.CGHS_AMA_REFERENCE_DATE =
  //                                           this.queData.CGHS_AMA_REFERENCE_DATE.split(
  //                                             ','
  //                                           );
  //                                       }
  //                                       if (
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_END_DATE ==
  //                                           undefined &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_END_DATE ==
  //                                           null &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_END_DATE ==
  //                                           '' &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_END_DATE
  //                                           .length == 0
  //                                       ) {
  //                                         this.queData.CGHS_AMA_REFERENCE_END_DATE =
  //                                           [];
  //                                       } else {
  //                                         this.queData.CGHS_AMA_REFERENCE_END_DATE =
  //                                           this.queData.CGHS_AMA_REFERENCE_END_DATE.split(
  //                                             ','
  //                                           );
  //                                       }
  //                                     }
  //                                   } else {
  //                                     this.message.error(
  //                                       'Something Went Wrong',
  //                                       ''
  //                                     );
  //                                     this.isSpinning = false;
  //                                   }
  //                                 },
  //                                 (err) => {
  //                                   console.log(err);
  //                                 }
  //                               );
  //                           } else {
  //                             this.message.error('Something Went Wrong', '');
  //                             // this.isSpinning = false;
  //                           }
  //                         },
  //                         (err) => {
  //                           console.log(err);
  //                         }
  //                       );
  //                   } else {
  //                     this.message.error("Can't Load Data", '');
  //                   }
  //                 },
  //                 (err) => {
  //                   console.log(err);
  //                 }
  //               );
  //             this.delayPermissionVisible = true;
  //             this.isSpinning = false;
  //           } else {
  //             this.message.error('Failed To Fill Information...', '');
  //             this.isSpinning = false;
  //           }
  //         });
  //       }
  //     }
  //   }
  //   this.orderdata1 = [];
  // }

  // delayPermissionClose(): void {
  //   this.api
  //     .getAllQuestions(0, 0, '', '', ' AND CLAIM_ID =' + this.claimID)
  //     .subscribe(
  //       (data) => {
  //         if (data['code'] == 200) {
  //           if (data['data'].length == 0) {
  //             this.data3 = new QuestionaryMaster();
  //             this.billInTime();
  //             this.empanelledHospital();
  //             this.delayPermissionVisible = false;
  //             this.isSpinning = false;
  //           } else {
  //             this.data3 = data['data'][0];
  //             this.emergencyTaken = data['data'][0]['EMERGENCY_TREATEMENT'];
  //             this.drCertificate =
  //               data['data'][0]['DOCTOR_EMERGENCY_CERTIFICATE_TAKEN'];
  //             this.empanelHospital = data['data'][0]['IS_HOSPITAL_EMPLANELLED'];
  //             this.expoFacto = data['data'][0]['EXPO_FACTO_PERMISSION'];
  //             this.referanceTaken =
  //               data['data'][0]['REFERENCE_FORM_CGHS_AMA_TAKEN'];
  //             this.hodPermission =
  //               data['data'][0]['IS_PERMISSION_TAKEN_FROM_HOD'];
  //             if (
  //               this.data3.CGHS_AMA_REFERENCE_DATE == undefined ||
  //               this.data3.CGHS_AMA_REFERENCE_DATE == null ||
  //               this.data3.CGHS_AMA_REFERENCE_DATE == '' ||
  //               this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
  //             ) {
  //               this.data3.CGHS_AMA_REFERENCE_DATE = [];
  //             } else {
  //               this.data3.CGHS_AMA_REFERENCE_DATE =
  //                 this.data3.CGHS_AMA_REFERENCE_DATE.split(',');
  //               // this.dateDifference();
  //             }
  //             if (
  //               this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined ||
  //               this.data3.CGHS_AMA_REFERENCE_END_DATE == null ||
  //               this.data3.CGHS_AMA_REFERENCE_END_DATE == '' ||
  //               this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
  //             ) {
  //               this.data3.CGHS_AMA_REFERENCE_END_DATE = [];
  //             } else {
  //               this.data3.CGHS_AMA_REFERENCE_END_DATE =
  //                 this.data3.CGHS_AMA_REFERENCE_END_DATE.split(',');
  //               this.dateDifference();
  //             }
  //             // this.calculateDiff();
  //             this.billInTime();
  //             this.empanelledHospital();
  //             this.delayPermissionVisible = false;
  //             this.isSpinning = false;
  //           }
  //         } else {
  //           this.message.error('Something Went Wrong', '');
  //           this.isSpinning = false;
  //         }
  //       },
  //       (err) => {
  //         console.log(err);
  //       }
  //     );
  // }

  get delayPermissionCloseCallback() {
    return this.delayPermissionClosenew.bind(this);
  }

  delayPermissionVisiblenew: any;
  delayPermissionTitlenew: any;
  delayOrderSheetnewVisible: any;
  delayOrderSheetnewTitle: any;
  orderdata = [];

  delayOrderSheetnew() {
    this.delayOrderSheetnewTitle = 'Delay Order Sheet';
    // this.delayOrderSheetnewVisible = true;
    this.claimID1 = '';
    this.empID1 = '';
    this.data3.EMP_ID = this.empID;
    this.data3.CLAIM_ID = this.claimID;
    if (this.isOk) {
      this.isSpinning = true;
      this.data3.DELAY_CONDOLENCE_DATE = this.datepipe.transform(
        this.data3.DELAY_CONDOLENCE_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPOST_FACTO_PERMISSION_DATE = this.datepipe.transform(
        this.data3.EXPOST_FACTO_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPO_FACTO_DATE = this.datepipe.transform(
        this.data3.EXPO_FACTO_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      if (
        this.data3.CGHS_AMA_REFERENCE_DATE == undefined &&
        this.data3.CGHS_AMA_REFERENCE_DATE == null &&
        this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
      ) {
        this.data3.CGHS_AMA_REFERENCE_DATE = '';
      } else {
        this.data3.CGHS_AMA_REFERENCE_DATE = this.data3.CGHS_AMA_REFERENCE_DATE;
      }
      if (
        this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE == null &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
      ) {
        this.data3.CGHS_AMA_REFERENCE_END_DATE = '';
      } else {
        this.data3.CGHS_AMA_REFERENCE_END_DATE =
          this.data3.CGHS_AMA_REFERENCE_END_DATE;
      }

      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_DATE != undefined ||
          this.data3.DELAY_CONDOLENCE_DATE != null)
      ) {
        this.data3.DELAY_CONDOLENCE_DATE = this.data3.DELAY_CONDOLENCE_DATE;
      } else {
        this.data3.DELAY_CONDOLENCE_DATE = null;
      }
      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_NUMBER != undefined ||
          this.data3.DELAY_CONDOLENCE_NUMBER != null)
      ) {
        this.data3.DELAY_CONDOLENCE_NUMBER = this.data3.DELAY_CONDOLENCE_NUMBER;
      } else {
        this.data3.DELAY_CONDOLENCE_NUMBER = '';
      }
      {
        if (this.data3.ID) {
          this.api.updateQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              // this.message.success('Information Changed Successfully...', '');
              this.api
                .getclaimMaster(
                  0,
                  0,
                  '',
                  '',
                  ' AND ID=' + this.claimID,
                  '',
                  '',
                  '',
                  ''
                )
                .subscribe(
                  (data) => {
                    if (data['code'] == 200) {
                      this.orderdata1 = data['data'][0];
                      this.claimID1 = data['data'][0]['ID'];
                      this.empID1 = data['data'][0]['EMP_ID'];

                      this.api
                        .getSignature(
                          0,
                          0,
                          'ID',
                          'desc',
                          ' AND ID = ' + data['data'][0]['SIGNATURE_ID']
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.SECTION_TYPE =
                                data['data'][0]['SECTION_TYPE'];
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                      this.api
                        .getHospitalMapping(
                          0,
                          0,
                          '',
                          '',
                          ' AND CLAIM_ID = ' + this.claimID
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.HospitalMapping = data['data'];

                              // this.isSpinning = false;
                              this.api
                                .getAllQuestions(
                                  0,
                                  0,
                                  '',
                                  '',
                                  ' AND CLAIM_ID =' + this.claimID
                                )
                                .subscribe(
                                  (data) => {
                                    if (data['code'] == 200) {
                                      if (data['data'].length == 0) {
                                        this.queData = new QuestionaryMaster();
                                      } else {
                                        this.queData = data['data'][0];
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == '' &&
                                          this.queData.CGHS_AMA_REFERENCE_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_DATE.split(
                                              ','
                                            );
                                        }
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            '' &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_END_DATE.split(
                                              ','
                                            );
                                        }
                                      }
                                      this.delayOrderSheetnewVisible = true;
                                    } else {
                                      this.message.error(
                                        'Something Went Wrong',
                                        ''
                                      );
                                      // this.isSpinning = false;
                                    }
                                  },
                                  (err) => {
                                    console.log(err);
                                  }
                                );
                            } else {
                              this.message.error('Something Went Wrong', '');
                              // this.isSpinning = false;
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                    } else {
                      this.message.error("Can't Load Data", '');
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              this.isSpinning = false;
            } else {
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
        } else {
          this.api.createQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              // this.message.success('Information Save Successfully...', '');
              this.api
                .getclaimMaster(
                  0,
                  0,
                  '',
                  '',
                  ' AND ID=' + this.claimID,
                  '',
                  '',
                  '',
                  ''
                )
                .subscribe(
                  (data) => {
                    if (data['code'] == 200) {
                      this.orderdata1 = data['data'][0];
                      this.claimID1 = data['data'][0]['ID'];
                      this.empID1 = data['data'][0]['EMP_ID'];

                      this.api
                        .getSignature(
                          0,
                          0,
                          'ID',
                          'desc',
                          ' AND ID = ' + data['data'][0]['SIGNATURE_ID']
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.SECTION_TYPE =
                                data['data'][0]['SECTION_TYPE'];
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                      this.api
                        .getHospitalMapping(
                          0,
                          0,
                          '',
                          '',
                          ' AND CLAIM_ID = ' + this.claimID
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.HospitalMapping = data['data'];

                              // this.isSpinning = false;
                              this.api
                                .getAllQuestions(
                                  0,
                                  0,
                                  '',
                                  '',
                                  ' AND CLAIM_ID =' + this.claimID
                                )
                                .subscribe(
                                  (data) => {
                                    if (data['code'] == 200) {
                                      if (data['data'].length == 0) {
                                        this.queData = new QuestionaryMaster();
                                      } else {
                                        this.queData = data['data'][0];
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == '' &&
                                          this.queData.CGHS_AMA_REFERENCE_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_DATE.split(
                                              ','
                                            );
                                        }
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            '' &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_END_DATE.split(
                                              ','
                                            );
                                        }
                                      }
                                      this.delayOrderSheetnewVisible = true;
                                    } else {
                                      this.message.error(
                                        'Something Went Wrong',
                                        ''
                                      );
                                      this.isSpinning = false;
                                    }
                                  },
                                  (err) => {
                                    console.log(err);
                                  }
                                );
                            } else {
                              this.message.error('Something Went Wrong', '');
                              // this.isSpinning = false;
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                    } else {
                      this.message.error("Can't Load Data", '');
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              this.isSpinning = false;
            } else {
              this.message.error('Failed To Fill Information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
    this.orderdata1 = [];
  }

  delayOrderSheetnewClose(): void {
    this.delayOrderSheetnewVisible = false;
  }
  delayPermissionClosenew(): void {
    this.delayPermissionVisiblenew = false;
  }
  SECTION_TYPE: any;
  permissionnewLetter() {
    this.delayPermissionTitlenew = 'Delay Permission Letter';
    // this.delayPermissionVisiblenew = true;
    this.claimID1 = '';
    this.empID1 = '';
    this.data3.EMP_ID = this.empID;
    this.data3.CLAIM_ID = this.claimID;
    if (this.isOk) {
      this.isSpinning = true;
      this.data3.DELAY_CONDOLENCE_DATE = this.datepipe.transform(
        this.data3.DELAY_CONDOLENCE_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPOST_FACTO_PERMISSION_DATE = this.datepipe.transform(
        this.data3.EXPOST_FACTO_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPO_FACTO_DATE = this.datepipe.transform(
        this.data3.EXPO_FACTO_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      if (
        this.data3.CGHS_AMA_REFERENCE_DATE == undefined &&
        this.data3.CGHS_AMA_REFERENCE_DATE == null &&
        this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
      ) {
        this.data3.CGHS_AMA_REFERENCE_DATE = '';
      } else {
        this.data3.CGHS_AMA_REFERENCE_DATE = this.data3.CGHS_AMA_REFERENCE_DATE;
      }
      if (
        this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE == null &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
      ) {
        this.data3.CGHS_AMA_REFERENCE_END_DATE = '';
      } else {
        this.data3.CGHS_AMA_REFERENCE_END_DATE =
          this.data3.CGHS_AMA_REFERENCE_END_DATE;
      }

      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_DATE != undefined ||
          this.data3.DELAY_CONDOLENCE_DATE != null)
      ) {
        this.data3.DELAY_CONDOLENCE_DATE = this.data3.DELAY_CONDOLENCE_DATE;
      } else {
        this.data3.DELAY_CONDOLENCE_DATE = null;
      }
      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_NUMBER != undefined ||
          this.data3.DELAY_CONDOLENCE_NUMBER != null)
      ) {
        this.data3.DELAY_CONDOLENCE_NUMBER = this.data3.DELAY_CONDOLENCE_NUMBER;
      } else {
        this.data3.DELAY_CONDOLENCE_NUMBER = '';
      }
      {
        if (this.data3.ID) {
          this.api.updateQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              // this.message.success('Information Changed Successfully...', '');
              this.api
                .getclaimMaster(
                  0,
                  0,
                  '',
                  '',
                  ' AND ID=' + this.claimID,
                  '',
                  '',
                  '',
                  ''
                )
                .subscribe(
                  (data) => {
                    if (data['code'] == 200) {
                      this.orderdata1 = data['data'][0];
                      this.claimID1 = data['data'][0]['ID'];
                      this.empID1 = data['data'][0]['EMP_ID'];

                      this.api
                        .getSignature(
                          0,
                          0,
                          'ID',
                          'desc',
                          ' AND ID = ' + data['data'][0]['SIGNATURE_ID']
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.SECTION_TYPE =
                                data['data'][0]['SECTION_TYPE'];
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                      this.api
                        .getHospitalMapping(
                          0,
                          0,
                          '',
                          '',
                          ' AND CLAIM_ID = ' + this.claimID
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.HospitalMapping = data['data'];

                              // this.isSpinning = false;
                              this.api
                                .getAllQuestions(
                                  0,
                                  0,
                                  '',
                                  '',
                                  ' AND CLAIM_ID =' + this.claimID
                                )
                                .subscribe(
                                  (data) => {
                                    if (data['code'] == 200) {
                                      if (data['data'].length == 0) {
                                        this.queData = new QuestionaryMaster();
                                      } else {
                                        this.queData = data['data'][0];
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == '' &&
                                          this.queData.CGHS_AMA_REFERENCE_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_DATE.split(
                                              ','
                                            );
                                        }
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            '' &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_END_DATE.split(
                                              ','
                                            );
                                        }
                                      }
                                    } else {
                                      this.message.error(
                                        'Something Went Wrong',
                                        ''
                                      );
                                      // this.isSpinning = false;
                                    }
                                  },
                                  (err) => {
                                    console.log(err);
                                  }
                                );
                            } else {
                              this.message.error('Something Went Wrong', '');
                              // this.isSpinning = false;
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                    } else {
                      this.message.error("Can't Load Data", '');
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              this.isSpinning = false;
              this.delayPermissionVisiblenew = true;
            } else {
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
        } else {
          this.api.createQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              // this.message.success('Information Save Successfully...', '');
              this.api
                .getclaimMaster(
                  0,
                  0,
                  '',
                  '',
                  ' AND ID=' + this.claimID,
                  '',
                  '',
                  '',
                  ''
                )
                .subscribe(
                  (data) => {
                    if (data['code'] == 200) {
                      this.orderdata1 = data['data'][0];
                      this.claimID1 = data['data'][0]['ID'];
                      this.empID1 = data['data'][0]['EMP_ID'];

                      this.api
                        .getSignature(
                          0,
                          0,
                          'ID',
                          'desc',
                          ' AND ID = ' + data['data'][0]['SIGNATURE_ID']
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.SECTION_TYPE =
                                data['data'][0]['SECTION_TYPE'];
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                      this.api
                        .getHospitalMapping(
                          0,
                          0,
                          '',
                          '',
                          ' AND CLAIM_ID = ' + this.claimID
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.HospitalMapping = data['data'];

                              // this.isSpinning = false;
                              this.api
                                .getAllQuestions(
                                  0,
                                  0,
                                  '',
                                  '',
                                  ' AND CLAIM_ID =' + this.claimID
                                )
                                .subscribe(
                                  (data) => {
                                    if (data['code'] == 200) {
                                      if (data['data'].length == 0) {
                                        this.queData = new QuestionaryMaster();
                                      } else {
                                        this.queData = data['data'][0];
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == '' &&
                                          this.queData.CGHS_AMA_REFERENCE_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_DATE.split(
                                              ','
                                            );
                                        }
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            '' &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_END_DATE.split(
                                              ','
                                            );
                                        }
                                      }
                                    } else {
                                      this.message.error(
                                        'Something Went Wrong',
                                        ''
                                      );
                                      this.isSpinning = false;
                                    }
                                  },
                                  (err) => {
                                    console.log(err);
                                  }
                                );
                            } else {
                              this.message.error('Something Went Wrong', '');
                              // this.isSpinning = false;
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                    } else {
                      this.message.error("Can't Load Data", '');
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              this.delayPermissionVisiblenew = true;
              this.isSpinning = false;
            } else {
              this.message.error('Failed To Fill Information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
    this.orderdata1 = [];
  }

  refExpoOrderVisible: boolean = false;
  refExpoOrderTitle: string = '';
  refExpoOrderSheet() {
    this.refExpoOrderTitle = 'Referance Expo Facto Order Sheet';
    this.claimID1 = '';
    this.empID1 = '';
    this.data3.EMP_ID = this.empID;
    this.data3.CLAIM_ID = this.claimID;
    this.isOk = true;
    if (this.isOk) {
      this.isSpinning = true;
      this.data3.DELAY_CONDOLENCE_DATE = this.datepipe.transform(
        this.data3.DELAY_CONDOLENCE_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPOST_FACTO_PERMISSION_DATE = this.datepipe.transform(
        this.data3.EXPOST_FACTO_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPO_FACTO_DATE = this.datepipe.transform(
        this.data3.EXPO_FACTO_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      if (
        this.data3.CGHS_AMA_REFERENCE_DATE == undefined &&
        this.data3.CGHS_AMA_REFERENCE_DATE == null &&
        this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
      ) {
        this.data3.CGHS_AMA_REFERENCE_DATE = '';
      } else {
        this.data3.CGHS_AMA_REFERENCE_DATE = this.data3.CGHS_AMA_REFERENCE_DATE;
      }
      if (
        this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE == null &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
      ) {
        this.data3.CGHS_AMA_REFERENCE_END_DATE = '';
      } else {
        this.data3.CGHS_AMA_REFERENCE_END_DATE =
          this.data3.CGHS_AMA_REFERENCE_END_DATE;
      }

      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_DATE != undefined ||
          this.data3.DELAY_CONDOLENCE_DATE != null)
      ) {
        this.data3.DELAY_CONDOLENCE_DATE = this.data3.DELAY_CONDOLENCE_DATE;
      } else {
        this.data3.DELAY_CONDOLENCE_DATE = null;
      }
      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_NUMBER != undefined ||
          this.data3.DELAY_CONDOLENCE_NUMBER != null)
      ) {
        this.data3.DELAY_CONDOLENCE_NUMBER = this.data3.DELAY_CONDOLENCE_NUMBER;
      } else {
        this.data3.DELAY_CONDOLENCE_NUMBER = '';
      }
      {
        if (this.data3.ID) {
          this.api.updateQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              // this.message.success('Information Changed Successfully...', '');
              this.api
                .getclaimMaster(
                  0,
                  0,
                  '',
                  '',
                  ' AND ID=' + this.claimID,
                  '',
                  '',
                  '',
                  ''
                )
                .subscribe(
                  (data) => {
                    if (data['code'] == 200) {
                      this.orderdata1 = data['data'][0];
                      this.claimID1 = data['data'][0]['ID'];
                      this.empID1 = data['data'][0]['EMP_ID'];

                      this.api
                        .getSignature(
                          0,
                          0,
                          'ID',
                          'desc',
                          ' AND ID = ' + data['data'][0]['SIGNATURE_ID']
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.SECTION_TYPE =
                                data['data'][0]['SECTION_TYPE'];
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                      this.api
                        .getHospitalMapping(
                          0,
                          0,
                          '',
                          '',
                          ' AND CLAIM_ID = ' + this.claimID
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.HospitalMapping = data['data'];

                              // this.isSpinning = false;
                              this.api
                                .getAllQuestions(
                                  0,
                                  0,
                                  '',
                                  '',
                                  ' AND CLAIM_ID =' + this.claimID
                                )
                                .subscribe(
                                  (data) => {
                                    if (data['code'] == 200) {
                                      if (data['data'].length == 0) {
                                        this.queData = new QuestionaryMaster();
                                      } else {
                                        this.queData = data['data'][0];
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == '' &&
                                          this.queData.CGHS_AMA_REFERENCE_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_DATE.split(
                                              ','
                                            );
                                        }
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            '' &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_END_DATE.split(
                                              ','
                                            );
                                        }
                                      }
                                    } else {
                                      this.message.error(
                                        'Something Went Wrong',
                                        ''
                                      );
                                      // this.isSpinning = false;
                                    }
                                  },
                                  (err) => {
                                    console.log(err);
                                  }
                                );
                            } else {
                              this.message.error('Something Went Wrong', '');
                              // this.isSpinning = false;
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                    } else {
                      this.message.error("Can't Load Data", '');
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              this.isSpinning = false;
              this.refExpoOrderVisible = true;
            } else {
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
        } else {
          this.api.createQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              // this.message.success('Information Save Successfully...', '');
              this.api
                .getclaimMaster(
                  0,
                  0,
                  '',
                  '',
                  ' AND ID=' + this.claimID,
                  '',
                  '',
                  '',
                  ''
                )
                .subscribe(
                  (data) => {
                    if (data['code'] == 200) {
                      this.orderdata1 = data['data'][0];
                      this.claimID1 = data['data'][0]['ID'];
                      this.empID1 = data['data'][0]['EMP_ID'];

                      this.api
                        .getSignature(
                          0,
                          0,
                          'ID',
                          'desc',
                          ' AND ID = ' + data['data'][0]['SIGNATURE_ID']
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.SECTION_TYPE =
                                data['data'][0]['SECTION_TYPE'];
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                      this.api
                        .getHospitalMapping(
                          0,
                          0,
                          '',
                          '',
                          ' AND CLAIM_ID = ' + this.claimID
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.HospitalMapping = data['data'];

                              // this.isSpinning = false;
                              this.api
                                .getAllQuestions(
                                  0,
                                  0,
                                  '',
                                  '',
                                  ' AND CLAIM_ID =' + this.claimID
                                )
                                .subscribe(
                                  (data) => {
                                    if (data['code'] == 200) {
                                      if (data['data'].length == 0) {
                                        this.queData = new QuestionaryMaster();
                                      } else {
                                        this.queData = data['data'][0];
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == '' &&
                                          this.queData.CGHS_AMA_REFERENCE_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_DATE.split(
                                              ','
                                            );
                                        }
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            '' &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_END_DATE.split(
                                              ','
                                            );
                                        }
                                      }
                                    } else {
                                      this.message.error(
                                        'Something Went Wrong',
                                        ''
                                      );
                                      this.isSpinning = false;
                                    }
                                  },
                                  (err) => {
                                    console.log(err);
                                  }
                                );
                            } else {
                              this.message.error('Something Went Wrong', '');
                              // this.isSpinning = false;
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                    } else {
                      this.message.error("Can't Load Data", '');
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              this.refExpoOrderVisible = true;
              this.isSpinning = false;
            } else {
              this.message.error('Failed To Fill Information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
  }

  refExpoOrderClose(): void {
    // this.refExpoOrderVisible = false;
    this.api
      .getAllQuestions(0, 0, '', '', ' AND CLAIM_ID =' + this.claimID)
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            if (data['data'].length == 0) {
              this.data3 = new QuestionaryMaster();
              this.billInTime();
              this.empanelledHospital();
              this.refExpoOrderVisible = false;
              this.isSpinning = false;
            } else {
              this.data3 = data['data'][0];
              this.emergencyTaken = data['data'][0]['EMERGENCY_TREATEMENT'];
              this.drCertificate =
                data['data'][0]['DOCTOR_EMERGENCY_CERTIFICATE_TAKEN'];
              this.empanelHospital = data['data'][0]['IS_HOSPITAL_EMPLANELLED'];
              this.expoFacto = data['data'][0]['EXPO_FACTO_PERMISSION'];
              this.referanceTaken =
                data['data'][0]['REFERENCE_FORM_CGHS_AMA_TAKEN'];
              this.hodPermission =
                data['data'][0]['IS_PERMISSION_TAKEN_FROM_HOD'];
              if (
                this.data3.CGHS_AMA_REFERENCE_DATE == undefined ||
                this.data3.CGHS_AMA_REFERENCE_DATE == null ||
                this.data3.CGHS_AMA_REFERENCE_DATE == '' ||
                this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
              ) {
                this.data3.CGHS_AMA_REFERENCE_DATE = [];
              } else {
                this.data3.CGHS_AMA_REFERENCE_DATE =
                  this.data3.CGHS_AMA_REFERENCE_DATE.split(',');
                // this.dateDifference();
              }
              if (
                this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined ||
                this.data3.CGHS_AMA_REFERENCE_END_DATE == null ||
                this.data3.CGHS_AMA_REFERENCE_END_DATE == '' ||
                this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
              ) {
                this.data3.CGHS_AMA_REFERENCE_END_DATE = [];
              } else {
                this.data3.CGHS_AMA_REFERENCE_END_DATE =
                  this.data3.CGHS_AMA_REFERENCE_END_DATE.split(',');
                this.dateDifference();
              }
              // this.calculateDiff();
              this.billInTime();
              this.empanelledHospital();
              this.refExpoOrderVisible = false;
              this.isSpinning = false;
            }
          } else {
            this.message.error('Something Went Wrong', '');
            this.isSpinning = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  get refExpoOrderCloseCallback() {
    return this.refExpoOrderClose.bind(this);
  }

  refExpoPermissionLetterVisible: boolean = false;
  refExpoPermissionLetterTitle: string = '';
  refExpoPermissionLetter() {
    this.refExpoOrderTitle = 'Referance Expo Facto Order Sheet';
    this.claimID1 = '';
    this.empID1 = '';
    this.data3.EMP_ID = this.empID;
    this.data3.CLAIM_ID = this.claimID;
    this.isOk = true;
    if (this.isOk) {
      this.isSpinning = true;
      this.data3.DELAY_CONDOLENCE_DATE = this.datepipe.transform(
        this.data3.DELAY_CONDOLENCE_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPOST_FACTO_PERMISSION_DATE = this.datepipe.transform(
        this.data3.EXPOST_FACTO_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPO_FACTO_DATE = this.datepipe.transform(
        this.data3.EXPO_FACTO_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      if (
        this.data3.CGHS_AMA_REFERENCE_DATE == undefined &&
        this.data3.CGHS_AMA_REFERENCE_DATE == null &&
        this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
      ) {
        this.data3.CGHS_AMA_REFERENCE_DATE = '';
      } else {
        this.data3.CGHS_AMA_REFERENCE_DATE = this.data3.CGHS_AMA_REFERENCE_DATE;
      }
      if (
        this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE == null &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
      ) {
        this.data3.CGHS_AMA_REFERENCE_END_DATE = '';
      } else {
        this.data3.CGHS_AMA_REFERENCE_END_DATE =
          this.data3.CGHS_AMA_REFERENCE_END_DATE;
      }

      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_DATE != undefined ||
          this.data3.DELAY_CONDOLENCE_DATE != null)
      ) {
        this.data3.DELAY_CONDOLENCE_DATE = this.data3.DELAY_CONDOLENCE_DATE;
      } else {
        this.data3.DELAY_CONDOLENCE_DATE = null;
      }
      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_NUMBER != undefined ||
          this.data3.DELAY_CONDOLENCE_NUMBER != null)
      ) {
        this.data3.DELAY_CONDOLENCE_NUMBER = this.data3.DELAY_CONDOLENCE_NUMBER;
      } else {
        this.data3.DELAY_CONDOLENCE_NUMBER = '';
      }
      {
        if (this.data3.ID) {
          this.api.updateQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              // this.message.success('Information Changed Successfully...', '');
              this.api
                .getclaimMaster(
                  0,
                  0,
                  '',
                  '',
                  ' AND ID=' + this.claimID,
                  '',
                  '',
                  '',
                  ''
                )
                .subscribe(
                  (data) => {
                    if (data['code'] == 200) {
                      this.orderdata1 = data['data'][0];
                      this.claimID1 = data['data'][0]['ID'];
                      this.empID1 = data['data'][0]['EMP_ID'];

                      this.api
                        .getSignature(
                          0,
                          0,
                          'ID',
                          'desc',
                          ' AND ID = ' + data['data'][0]['SIGNATURE_ID']
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.SECTION_TYPE =
                                data['data'][0]['SECTION_TYPE'];
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                      this.api
                        .getHospitalMapping(
                          0,
                          0,
                          '',
                          '',
                          ' AND CLAIM_ID = ' + this.claimID
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.HospitalMapping = data['data'];

                              // this.isSpinning = false;
                              this.api
                                .getAllQuestions(
                                  0,
                                  0,
                                  '',
                                  '',
                                  ' AND CLAIM_ID =' + this.claimID
                                )
                                .subscribe(
                                  (data) => {
                                    if (data['code'] == 200) {
                                      if (data['data'].length == 0) {
                                        this.queData = new QuestionaryMaster();
                                      } else {
                                        this.queData = data['data'][0];
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == '' &&
                                          this.queData.CGHS_AMA_REFERENCE_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_DATE.split(
                                              ','
                                            );
                                        }
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            '' &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_END_DATE.split(
                                              ','
                                            );
                                        }
                                      }
                                    } else {
                                      this.message.error(
                                        'Something Went Wrong',
                                        ''
                                      );
                                      // this.isSpinning = false;
                                    }
                                  },
                                  (err) => {
                                    console.log(err);
                                  }
                                );
                            } else {
                              this.message.error('Something Went Wrong', '');
                              // this.isSpinning = false;
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                    } else {
                      this.message.error("Can't Load Data", '');
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              this.isSpinning = false;
              this.refExpoPermissionLetterVisible = true;
            } else {
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
        } else {
          this.api.createQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              // this.message.success('Information Save Successfully...', '');
              this.api
                .getclaimMaster(
                  0,
                  0,
                  '',
                  '',
                  ' AND ID=' + this.claimID,
                  '',
                  '',
                  '',
                  ''
                )
                .subscribe(
                  (data) => {
                    if (data['code'] == 200) {
                      this.orderdata1 = data['data'][0];
                      this.claimID1 = data['data'][0]['ID'];
                      this.empID1 = data['data'][0]['EMP_ID'];

                      this.api
                        .getSignature(
                          0,
                          0,
                          'ID',
                          'desc',
                          ' AND ID = ' + data['data'][0]['SIGNATURE_ID']
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.SECTION_TYPE =
                                data['data'][0]['SECTION_TYPE'];
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                      this.api
                        .getHospitalMapping(
                          0,
                          0,
                          '',
                          '',
                          ' AND CLAIM_ID = ' + this.claimID
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.HospitalMapping = data['data'];

                              // this.isSpinning = false;
                              this.api
                                .getAllQuestions(
                                  0,
                                  0,
                                  '',
                                  '',
                                  ' AND CLAIM_ID =' + this.claimID
                                )
                                .subscribe(
                                  (data) => {
                                    if (data['code'] == 200) {
                                      if (data['data'].length == 0) {
                                        this.queData = new QuestionaryMaster();
                                      } else {
                                        this.queData = data['data'][0];
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == '' &&
                                          this.queData.CGHS_AMA_REFERENCE_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_DATE.split(
                                              ','
                                            );
                                        }
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            '' &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_END_DATE.split(
                                              ','
                                            );
                                        }
                                      }
                                    } else {
                                      this.message.error(
                                        'Something Went Wrong',
                                        ''
                                      );
                                      this.isSpinning = false;
                                    }
                                  },
                                  (err) => {
                                    console.log(err);
                                  }
                                );
                            } else {
                              this.message.error('Something Went Wrong', '');
                              // this.isSpinning = false;
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                    } else {
                      this.message.error("Can't Load Data", '');
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              this.refExpoPermissionLetterVisible = true;
              this.isSpinning = false;
            } else {
              this.message.error('Failed To Fill Information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
  }

  refExpoPermissionLetterClose(): void {
    // this.refExpoPermissionLetterVisible = false;
    this.api
      .getAllQuestions(0, 0, '', '', ' AND CLAIM_ID =' + this.claimID)
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            if (data['data'].length == 0) {
              this.data3 = new QuestionaryMaster();
              this.billInTime();
              this.empanelledHospital();
              this.refExpoPermissionLetterVisible = false;
              this.isSpinning = false;
            } else {
              this.data3 = data['data'][0];
              this.emergencyTaken = data['data'][0]['EMERGENCY_TREATEMENT'];
              this.drCertificate =
                data['data'][0]['DOCTOR_EMERGENCY_CERTIFICATE_TAKEN'];
              this.empanelHospital = data['data'][0]['IS_HOSPITAL_EMPLANELLED'];
              this.expoFacto = data['data'][0]['EXPO_FACTO_PERMISSION'];
              this.referanceTaken =
                data['data'][0]['REFERENCE_FORM_CGHS_AMA_TAKEN'];
              this.hodPermission =
                data['data'][0]['IS_PERMISSION_TAKEN_FROM_HOD'];
              if (
                this.data3.CGHS_AMA_REFERENCE_DATE == undefined ||
                this.data3.CGHS_AMA_REFERENCE_DATE == null ||
                this.data3.CGHS_AMA_REFERENCE_DATE == '' ||
                this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
              ) {
                this.data3.CGHS_AMA_REFERENCE_DATE = [];
              } else {
                this.data3.CGHS_AMA_REFERENCE_DATE =
                  this.data3.CGHS_AMA_REFERENCE_DATE.split(',');
                // this.dateDifference();
              }
              if (
                this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined ||
                this.data3.CGHS_AMA_REFERENCE_END_DATE == null ||
                this.data3.CGHS_AMA_REFERENCE_END_DATE == '' ||
                this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
              ) {
                this.data3.CGHS_AMA_REFERENCE_END_DATE = [];
              } else {
                this.data3.CGHS_AMA_REFERENCE_END_DATE =
                  this.data3.CGHS_AMA_REFERENCE_END_DATE.split(',');
                this.dateDifference();
              }
              // this.calculateDiff();
              this.billInTime();
              this.empanelledHospital();
              this.refExpoPermissionLetterVisible = false;
              this.isSpinning = false;
            }
          } else {
            this.message.error('Something Went Wrong', '');
            this.isSpinning = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  get refExpoPermissionLetterCloseCallback() {
    return this.refExpoPermissionLetterClose.bind(this);
  }

  refhodorderVisible: boolean = false;
  refhodorderSheetTitle: string = '';
  refHODOrderSheet() {
    this.isSpinning = true;
    this.claimID1 = '';
    this.empID1 = '';
    this.data3.EMP_ID = this.empID;
    this.data3.CLAIM_ID = this.claimID;
    this.refhodorderSheetTitle = 'Referance HOD Order Letter';
    this.isOk = true;
    if (this.isOk) {
      this.isSpinning = true;
      this.data3.DELAY_CONDOLENCE_DATE = this.datepipe.transform(
        this.data3.DELAY_CONDOLENCE_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPOST_FACTO_PERMISSION_DATE = this.datepipe.transform(
        this.data3.EXPOST_FACTO_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPO_FACTO_DATE = this.datepipe.transform(
        this.data3.EXPO_FACTO_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      if (
        this.data3.CGHS_AMA_REFERENCE_DATE == undefined &&
        this.data3.CGHS_AMA_REFERENCE_DATE == null &&
        this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
      ) {
        this.data3.CGHS_AMA_REFERENCE_DATE = '';
      } else {
        this.data3.CGHS_AMA_REFERENCE_DATE = this.data3.CGHS_AMA_REFERENCE_DATE;
      }
      if (
        this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE == null &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
      ) {
        this.data3.CGHS_AMA_REFERENCE_END_DATE = '';
      } else {
        this.data3.CGHS_AMA_REFERENCE_END_DATE =
          this.data3.CGHS_AMA_REFERENCE_END_DATE;
      }

      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_DATE != undefined ||
          this.data3.DELAY_CONDOLENCE_DATE != null)
      ) {
        this.data3.DELAY_CONDOLENCE_DATE = this.data3.DELAY_CONDOLENCE_DATE;
      } else {
        this.data3.DELAY_CONDOLENCE_DATE = null;
      }
      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_NUMBER != undefined ||
          this.data3.DELAY_CONDOLENCE_NUMBER != null)
      ) {
        this.data3.DELAY_CONDOLENCE_NUMBER = this.data3.DELAY_CONDOLENCE_NUMBER;
      } else {
        this.data3.DELAY_CONDOLENCE_NUMBER = '';
      }
      {
        if (this.data3.ID) {
          this.api.updateQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              // this.message.success('Information Changed Successfully...', '');
              this.api
                .getclaimMaster(
                  0,
                  0,
                  '',
                  '',
                  ' AND ID=' + this.claimID,
                  '',
                  '',
                  '',
                  ''
                )
                .subscribe(
                  (data) => {
                    if (data['code'] == 200) {
                      this.orderdata1 = data['data'][0];
                      this.claimID1 = data['data'][0]['ID'];
                      this.empID1 = data['data'][0]['EMP_ID'];

                      this.api
                        .getSignature(
                          0,
                          0,
                          'ID',
                          'desc',
                          ' AND ID = ' + data['data'][0]['SIGNATURE_ID']
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.SECTION_TYPE =
                                data['data'][0]['SECTION_TYPE'];
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                      this.api
                        .getHospitalMapping(
                          0,
                          0,
                          '',
                          '',
                          ' AND CLAIM_ID = ' + this.claimID
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.HospitalMapping = data['data'];

                              // this.isSpinning = false;
                              this.api
                                .getAllQuestions(
                                  0,
                                  0,
                                  '',
                                  '',
                                  ' AND CLAIM_ID =' + this.claimID
                                )
                                .subscribe(
                                  (data) => {
                                    if (data['code'] == 200) {
                                      if (data['data'].length == 0) {
                                        this.queData = new QuestionaryMaster();
                                      } else {
                                        this.queData = data['data'][0];
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == '' &&
                                          this.queData.CGHS_AMA_REFERENCE_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_DATE.split(
                                              ','
                                            );
                                        }
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            '' &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_END_DATE.split(
                                              ','
                                            );
                                        }
                                      }
                                    } else {
                                      this.message.error(
                                        'Something Went Wrong',
                                        ''
                                      );
                                      // this.isSpinning = false;
                                    }
                                  },
                                  (err) => {
                                    console.log(err);
                                  }
                                );
                            } else {
                              this.message.error('Something Went Wrong', '');
                              // this.isSpinning = false;
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                    } else {
                      this.message.error("Can't Load Data", '');
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              this.isSpinning = false;
              this.refhodorderVisible = true;
            } else {
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
        } else {
          this.api.createQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              // this.message.success('Information Save Successfully...', '');
              this.api
                .getclaimMaster(
                  0,
                  0,
                  '',
                  '',
                  ' AND ID=' + this.claimID,
                  '',
                  '',
                  '',
                  ''
                )
                .subscribe(
                  (data) => {
                    if (data['code'] == 200) {
                      this.orderdata1 = data['data'][0];
                      this.claimID1 = data['data'][0]['ID'];
                      this.empID1 = data['data'][0]['EMP_ID'];

                      this.api
                        .getSignature(
                          0,
                          0,
                          'ID',
                          'desc',
                          ' AND ID = ' + data['data'][0]['SIGNATURE_ID']
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.SECTION_TYPE =
                                data['data'][0]['SECTION_TYPE'];
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                      this.api
                        .getHospitalMapping(
                          0,
                          0,
                          '',
                          '',
                          ' AND CLAIM_ID = ' + this.claimID
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.HospitalMapping = data['data'];

                              // this.isSpinning = false;
                              this.api
                                .getAllQuestions(
                                  0,
                                  0,
                                  '',
                                  '',
                                  ' AND CLAIM_ID =' + this.claimID
                                )
                                .subscribe(
                                  (data) => {
                                    if (data['code'] == 200) {
                                      if (data['data'].length == 0) {
                                        this.queData = new QuestionaryMaster();
                                      } else {
                                        this.queData = data['data'][0];
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == '' &&
                                          this.queData.CGHS_AMA_REFERENCE_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_DATE.split(
                                              ','
                                            );
                                        }
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            '' &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_END_DATE.split(
                                              ','
                                            );
                                        }
                                      }
                                    } else {
                                      this.message.error(
                                        'Something Went Wrong',
                                        ''
                                      );
                                      this.isSpinning = false;
                                    }
                                  },
                                  (err) => {
                                    console.log(err);
                                  }
                                );
                            } else {
                              this.message.error('Something Went Wrong', '');
                              // this.isSpinning = false;
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                    } else {
                      this.message.error("Can't Load Data", '');
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              this.refhodorderVisible = true;
              this.isSpinning = false;
            } else {
              this.message.error('Failed To Fill Information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
    this.orderdata1 = [];
  }

  refhodorderSheetClose(): void {
    // this.delayOrderSheetVisible = false;
    this.disableReferanceDate();
    this.api
      .getAllQuestions(0, 0, '', '', ' AND CLAIM_ID =' + this.claimID)
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            if (data['data'].length == 0) {
              this.data3 = new QuestionaryMaster();
              this.billInTime();
              this.empanelledHospital();
              // this.current = 2;
              this.refhodorderVisible = false;
              this.isSpinning = false;
            } else {
              this.data3 = data['data'][0];
              this.emergencyTaken = data['data'][0]['EMERGENCY_TREATEMENT'];
              this.drCertificate =
                data['data'][0]['DOCTOR_EMERGENCY_CERTIFICATE_TAKEN'];
              this.empanelHospital = data['data'][0]['IS_HOSPITAL_EMPLANELLED'];
              this.expoFacto = data['data'][0]['EXPO_FACTO_PERMISSION'];
              this.referanceTaken =
                data['data'][0]['REFERENCE_FORM_CGHS_AMA_TAKEN'];
              this.hodPermission =
                data['data'][0]['IS_PERMISSION_TAKEN_FROM_HOD'];
              if (
                this.data3.CGHS_AMA_REFERENCE_DATE == undefined &&
                this.data3.CGHS_AMA_REFERENCE_DATE == null &&
                this.data3.CGHS_AMA_REFERENCE_DATE == '' &&
                this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
              ) {
                this.data3.CGHS_AMA_REFERENCE_DATE = [];
              } else {
                this.data3.CGHS_AMA_REFERENCE_DATE =
                  this.data3.CGHS_AMA_REFERENCE_DATE.split(',');
                this.dateDifference();
              }
              if (
                this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined &&
                this.data3.CGHS_AMA_REFERENCE_END_DATE == null &&
                this.data3.CGHS_AMA_REFERENCE_END_DATE == '' &&
                this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
              ) {
                this.data3.CGHS_AMA_REFERENCE_END_DATE = [];
              } else {
                this.data3.CGHS_AMA_REFERENCE_END_DATE =
                  this.data3.CGHS_AMA_REFERENCE_END_DATE.split(',');
                this.dateDifference();
              }
              this.calculateDiff();
              this.billInTime();
              this.empanelledHospital();
              // this.current = 2;
              this.isSpinning = false;
              this.refhodorderVisible = false;
            }
          } else {
            this.message.error('Something Went Wrong', '');
            this.isSpinning = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }
  get refhodorderSheetCloseCallback() {
    return this.refhodorderSheetClose.bind(this);
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////
  refhodPermissionVisible: boolean = false;
  refhodPermissionSheetTitle: string = '';
  refHODPermission() {
    this.isSpinning = true;
    this.claimID1 = '';
    this.empID1 = '';
    this.data3.EMP_ID = this.empID;
    this.data3.CLAIM_ID = this.claimID;
    this.refhodPermissionSheetTitle = 'Referance HOD Permission Letter';
    this.isOk = true;
    if (this.isOk) {
      this.isSpinning = true;
      this.data3.DELAY_CONDOLENCE_DATE = this.datepipe.transform(
        this.data3.DELAY_CONDOLENCE_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPOST_FACTO_PERMISSION_DATE = this.datepipe.transform(
        this.data3.EXPOST_FACTO_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPO_FACTO_DATE = this.datepipe.transform(
        this.data3.EXPO_FACTO_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      if (
        this.data3.CGHS_AMA_REFERENCE_DATE == undefined &&
        this.data3.CGHS_AMA_REFERENCE_DATE == null &&
        this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
      ) {
        this.data3.CGHS_AMA_REFERENCE_DATE = '';
      } else {
        this.data3.CGHS_AMA_REFERENCE_DATE = this.data3.CGHS_AMA_REFERENCE_DATE;
      }
      if (
        this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE == null &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
      ) {
        this.data3.CGHS_AMA_REFERENCE_END_DATE = '';
      } else {
        this.data3.CGHS_AMA_REFERENCE_END_DATE =
          this.data3.CGHS_AMA_REFERENCE_END_DATE;
      }

      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_DATE != undefined ||
          this.data3.DELAY_CONDOLENCE_DATE != null)
      ) {
        this.data3.DELAY_CONDOLENCE_DATE = this.data3.DELAY_CONDOLENCE_DATE;
      } else {
        this.data3.DELAY_CONDOLENCE_DATE = null;
      }
      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_NUMBER != undefined ||
          this.data3.DELAY_CONDOLENCE_NUMBER != null)
      ) {
        this.data3.DELAY_CONDOLENCE_NUMBER = this.data3.DELAY_CONDOLENCE_NUMBER;
      } else {
        this.data3.DELAY_CONDOLENCE_NUMBER = '';
      }
      {
        if (this.data3.ID) {
          this.api.updateQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              // this.message.success('Information Changed Successfully...', '');
              this.api
                .getclaimMaster(
                  0,
                  0,
                  '',
                  '',
                  ' AND ID=' + this.claimID,
                  '',
                  '',
                  '',
                  ''
                )
                .subscribe(
                  (data) => {
                    if (data['code'] == 200) {
                      this.orderdata1 = data['data'][0];
                      this.claimID1 = data['data'][0]['ID'];
                      this.empID1 = data['data'][0]['EMP_ID'];

                      this.api
                        .getSignature(
                          0,
                          0,
                          'ID',
                          'desc',
                          ' AND ID = ' + data['data'][0]['SIGNATURE_ID']
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.SECTION_TYPE =
                                data['data'][0]['SECTION_TYPE'];
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                      this.api
                        .getHospitalMapping(
                          0,
                          0,
                          '',
                          '',
                          ' AND CLAIM_ID = ' + this.claimID
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.HospitalMapping = data['data'];

                              // this.isSpinning = false;
                              this.api
                                .getAllQuestions(
                                  0,
                                  0,
                                  '',
                                  '',
                                  ' AND CLAIM_ID =' + this.claimID
                                )
                                .subscribe(
                                  (data) => {
                                    if (data['code'] == 200) {
                                      if (data['data'].length == 0) {
                                        this.queData = new QuestionaryMaster();
                                      } else {
                                        this.queData = data['data'][0];
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == '' &&
                                          this.queData.CGHS_AMA_REFERENCE_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_DATE.split(
                                              ','
                                            );
                                        }
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            '' &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_END_DATE.split(
                                              ','
                                            );
                                        }
                                      }
                                    } else {
                                      this.message.error(
                                        'Something Went Wrong',
                                        ''
                                      );
                                      // this.isSpinning = false;
                                    }
                                  },
                                  (err) => {
                                    console.log(err);
                                  }
                                );
                            } else {
                              this.message.error('Something Went Wrong', '');
                              // this.isSpinning = false;
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                    } else {
                      this.message.error("Can't Load Data", '');
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              this.isSpinning = false;
              this.refhodPermissionVisible = true;
            } else {
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
        } else {
          this.api.createQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              // this.message.success('Information Save Successfully...', '');
              this.api
                .getclaimMaster(
                  0,
                  0,
                  '',
                  '',
                  ' AND ID=' + this.claimID,
                  '',
                  '',
                  '',
                  ''
                )
                .subscribe(
                  (data) => {
                    if (data['code'] == 200) {
                      this.orderdata1 = data['data'][0];
                      this.claimID1 = data['data'][0]['ID'];
                      this.empID1 = data['data'][0]['EMP_ID'];

                      this.api
                        .getSignature(
                          0,
                          0,
                          'ID',
                          'desc',
                          ' AND ID = ' + data['data'][0]['SIGNATURE_ID']
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.SECTION_TYPE =
                                data['data'][0]['SECTION_TYPE'];
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                      this.api
                        .getHospitalMapping(
                          0,
                          0,
                          '',
                          '',
                          ' AND CLAIM_ID = ' + this.claimID
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.HospitalMapping = data['data'];

                              // this.isSpinning = false;
                              this.api
                                .getAllQuestions(
                                  0,
                                  0,
                                  '',
                                  '',
                                  ' AND CLAIM_ID =' + this.claimID
                                )
                                .subscribe(
                                  (data) => {
                                    if (data['code'] == 200) {
                                      if (data['data'].length == 0) {
                                        this.queData = new QuestionaryMaster();
                                      } else {
                                        this.queData = data['data'][0];
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == '' &&
                                          this.queData.CGHS_AMA_REFERENCE_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_DATE.split(
                                              ','
                                            );
                                        }
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            '' &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_END_DATE.split(
                                              ','
                                            );
                                        }
                                      }
                                    } else {
                                      this.message.error(
                                        'Something Went Wrong',
                                        ''
                                      );
                                      this.isSpinning = false;
                                    }
                                  },
                                  (err) => {
                                    console.log(err);
                                  }
                                );
                            } else {
                              this.message.error('Something Went Wrong', '');
                              // this.isSpinning = false;
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                    } else {
                      this.message.error("Can't Load Data", '');
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              this.refhodPermissionVisible = true;
              this.isSpinning = false;
            } else {
              this.message.error('Failed To Fill Information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
    this.orderdata1 = [];
  }
  fileURL1: any;
  onFileSelected(event: any) {
    // if (this.data.FILE_TYPE == 'PDF') {

    if (event.target.files[0].type == 'application/pdf') {
      this.fileURL1 = <File>event.target.files[0];
    } else {
      this.message.error('Please Select Only PDF File', '');
      this.fileURL1 = null;
    }
  }
  refhodPermissionSheetClose(): void {
    // this.delayOrderSheetVisible = false;
    this.disableReferanceDate();
    this.api
      .getAllQuestions(0, 0, '', '', ' AND CLAIM_ID =' + this.claimID)
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            if (data['data'].length == 0) {
              this.data3 = new QuestionaryMaster();
              this.billInTime();
              this.empanelledHospital();
              // this.current = 2;
              this.refhodPermissionVisible = false;
              this.isSpinning = false;
            } else {
              this.data3 = data['data'][0];
              this.emergencyTaken = data['data'][0]['EMERGENCY_TREATEMENT'];
              this.drCertificate =
                data['data'][0]['DOCTOR_EMERGENCY_CERTIFICATE_TAKEN'];
              this.empanelHospital = data['data'][0]['IS_HOSPITAL_EMPLANELLED'];
              this.expoFacto = data['data'][0]['EXPO_FACTO_PERMISSION'];
              this.referanceTaken =
                data['data'][0]['REFERENCE_FORM_CGHS_AMA_TAKEN'];
              this.hodPermission =
                data['data'][0]['IS_PERMISSION_TAKEN_FROM_HOD'];
              if (
                this.data3.CGHS_AMA_REFERENCE_DATE == undefined &&
                this.data3.CGHS_AMA_REFERENCE_DATE == null &&
                this.data3.CGHS_AMA_REFERENCE_DATE == '' &&
                this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
              ) {
                this.data3.CGHS_AMA_REFERENCE_DATE = [];
              } else {
                this.data3.CGHS_AMA_REFERENCE_DATE =
                  this.data3.CGHS_AMA_REFERENCE_DATE.split(',');
                this.dateDifference();
              }
              if (
                this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined &&
                this.data3.CGHS_AMA_REFERENCE_END_DATE == null &&
                this.data3.CGHS_AMA_REFERENCE_END_DATE == '' &&
                this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
              ) {
                this.data3.CGHS_AMA_REFERENCE_END_DATE = [];
              } else {
                this.data3.CGHS_AMA_REFERENCE_END_DATE =
                  this.data3.CGHS_AMA_REFERENCE_END_DATE.split(',');
                this.dateDifference();
              }
              this.calculateDiff();
              this.billInTime();
              this.empanelledHospital();
              // this.current = 2;
              this.isSpinning = false;
              this.refhodPermissionVisible = false;
            }
          } else {
            this.message.error('Something Went Wrong', '');
            this.isSpinning = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  confirmDeleteHospital(data: any, i: number) {
    this.relationdata = this.relationdata.filter((item, index) => index != i);

    this.relationdata = [...[], ...this.relationdata];
  }

  index = -1;
  edit1(data: Realtionshipdata, i: any): void {
    this.index = i;
    this.editrelation = Object.assign({}, data);
  }

  FROMACCOUNT: FromTOAccount = new FromTOAccount();
  addData(addNew: boolean, relation: NgForm) {
    this.isSpinning = false;
    this.isOk = true;

    if (
      this.editrelation.NAME_OF_FAMILY_MEMBER == undefined &&
      this.editrelation.AGE == undefined &&
      this.editrelation.RELATIONSHIP == undefined
    ) {
      this.isOk = false;
      this.message.error('Please Fill All The Required Fields ', '');
    } else if (
      this.editrelation.NAME_OF_FAMILY_MEMBER == null ||
      this.editrelation.NAME_OF_FAMILY_MEMBER.trim() == '' ||
      this.editrelation.NAME_OF_FAMILY_MEMBER == undefined
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Name of Family Member.', '');
    } else if (
      this.editrelation.AGE == undefined ||
      this.editrelation.AGE <= 0 ||
      this.editrelation.AGE == ''
    ) {
      this.isOk = false;
      this.message.error('Please Enter Age  ', '');
    } else if (
      this.editrelation.RELATIONSHIP == null ||
      this.editrelation.RELATIONSHIP == undefined
    ) {
      this.isOk = false;
      this.message.error(
        ' Please Select Relationship with the Govt. Servant.',
        ''
      );
    }

    if (this.isOk) {
      if (this.index > -1) {
        this.relationdata[this.index] = Object.assign({}, this.editrelation);
      } else {
        this.relationdata.push(Object.assign({}, this.editrelation));
      }
      this.relationdata = [...[], ...this.relationdata];

      relation.form.reset();
      this.editrelation = new Realtionshipdata();
      this.index = -1;
    }
  }

  get refhodPermissionSheetCloseCallback() {
    return this.refhodPermissionSheetClose.bind(this);
  }

  vehicleBroughtClear(event) {
    if (event == true) {
      this.data.VEHICLE_BROUGHT_ROAD_MILEAGE = null;
      this.data.VEHICLE_BROUGHT_ROAD_MILEAGE_KMS = null;
      this.data.VEHICLE_BROUGHT_TOTAL = null;
      this.data.TRAIN_BROUGHT_KMS = null;
      this.data.TRAIN_BROUGHT_CHARGE = null;
    } else {
      this.data.TRUCK_SHIP_CHARGE = null;
      this.data.TRAIN_BROUGHT_FOR_KMS_NO = null;
      this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = null;
    }
  }
  roadMilageTotal(event) {
    if (event != null || event != undefined || event != '') {
      if (
        this.data.VEHICLE_BROUGHT_ROAD_MILEAGE_KMS != undefined ||
        this.data.VEHICLE_BROUGHT_ROAD_MILEAGE_KMS != null ||
        this.data.VEHICLE_BROUGHT_ROAD_MILEAGE_KMS != 0
      ) {
        this.data.VEHICLE_BROUGHT_TOTAL =
          this.data.VEHICLE_BROUGHT_ROAD_MILEAGE_KMS * event;
      } else {
      }
    } else {
    }
  }
  roadKMTotal(event) {
    if (event != null || event != undefined || event != '') {
      if (
        this.data.VEHICLE_BROUGHT_ROAD_MILEAGE != undefined ||
        this.data.VEHICLE_BROUGHT_ROAD_MILEAGE != null ||
        this.data.VEHICLE_BROUGHT_ROAD_MILEAGE != 0
      ) {
        this.data.VEHICLE_BROUGHT_TOTAL =
          this.data.VEHICLE_BROUGHT_ROAD_MILEAGE * event;
      } else {
      }
    } else {
    }
  }

  assumptionFileURL: any;
  onFileSelectedAssumption(event: any) {
    // if (this.data.FILE_TYPE == 'PDF') {

    if (event.target.files[0].type == 'application/pdf') {
      this.isSpinning = true;
      this.assumptionFileURL = <File>event.target.files[0];

      if (this.assumptionFileURL != null) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.assumptionFileURL.name.split('.').pop();
        var d = this.datepipe.transform(new Date(), 'yyyyMMdd');
        var url = '';
        url = d == null ? '' : d + number + '.' + fileExt;
        if (
          this.data.ASSUMPTION_CHARGE != undefined &&
          this.data.ASSUMPTION_CHARGE.trim() != ''
        ) {
          var arr = this.data.ASSUMPTION_CHARGE.split('/');
          if (arr.length > 1) {
            url = arr[5];
          }
        }
      }
      this.api
        .onUpload('assumptionCharge', this.assumptionFileURL, url)
        .subscribe((successCode) => {
          if (successCode.code == '200') {
            this.data.ASSUMPTION_CHARGE = url;
            this.data.ASSUMPTION_CHARGE_STATUS = '';
            this.updatecall();
            this.message.success('File Uploaded Successfully...', '');
            this.isSpinning = false;

            // this.data.FILE_URL = url;
          } else {
            this.message.error('Failed To Save File...', '');
            this.isSpinning = false;
          }
        });
    } else {
      this.message.error('Please Select Only PDF File', '');
      this.assumptionFileURL = null;
      this.data.ASSUMPTION_CHARGE = null;
    }
  }

  LPCFileURL: any;
  onFileSelectedLPC(event: any) {
    // if (this.data.FILE_TYPE == 'PDF') {

    if (event.target.files[0].type == 'application/pdf') {
      this.isSpinning = true;
      this.LPCFileURL = <File>event.target.files[0];

      if (this.LPCFileURL != null) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.LPCFileURL.name.split('.').pop();
        var d = this.datepipe.transform(new Date(), 'yyyyMMdd');
        var url = '';
        url = d == null ? '' : d + number + '.' + fileExt;
        if (this.data.LPC != undefined && this.data.LPC.trim() != '') {
          var arr = this.data.LPC.split('/');
          if (arr.length > 1) {
            url = arr[5];
          }
        }
      }
      this.api
        .onUpload('lpc', this.LPCFileURL, url)
        .subscribe((successCode) => {
          if (successCode.code == '200') {
            this.data.LPC = url;
            this.data.LPC_STATUS = '';
            this.updatecall();
            this.message.success('File Uploaded Successfully...', '');
            this.isSpinning = false;

            // this.data.FILE_URL = url;
          } else {
            this.message.error('Failed To Save File...', '');
            this.isSpinning = false;
          }
        });
    } else {
      this.message.error('Please Select Only PDF File', '');
      this.LPCFileURL = null;
      this.data.LPC = null;
    }
  }

  mandateFormFileURL: any;
  onFileSelectedMandateForm(event: any) {
    // if (this.data.FILE_TYPE == 'PDF') {

    if (event.target.files[0].type == 'application/pdf') {
      this.isSpinning = true;
      this.mandateFormFileURL = <File>event.target.files[0];

      if (this.mandateFormFileURL != null) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.mandateFormFileURL.name.split('.').pop();
        var d = this.datepipe.transform(new Date(), 'yyyyMMdd');
        var url = '';
        url = d == null ? '' : d + number + '.' + fileExt;
        if (
          this.data.MANDATE_FORM != undefined &&
          this.data.MANDATE_FORM.trim() != ''
        ) {
          var arr = this.data.MANDATE_FORM.split('/');
          if (arr.length > 1) {
            url = arr[5];
          }
        }
      }
      this.api
        .onUpload('mandateForm', this.mandateFormFileURL, url)
        .subscribe((successCode) => {
          if (successCode.code == '200') {
            this.data.MANDATE_FORM = url;
            this.data.MANDATE_FORM_STATUS = '';
            this.updatecall();
            this.message.success('File Uploaded Successfully...', '');
            this.isSpinning = false;
            // this.data.FILE_URL = url;
          } else {
            this.message.error('Failed To Save File...', '');
            this.isSpinning = false;
          }
        });
    } else {
      this.message.error('Please Select Only PDF File', '');
      this.mandateFormFileURL = null;
      this.data.MANDATE_FORM = null;
    }
  }

  transferFileURL: any;
  onFileSelectedTransfer(event: any) {
    // if (this.data.FILE_TYPE == 'PDF') {

    if (event.target.files[0].type == 'application/pdf') {
      this.isSpinning = true;
      this.transferFileURL = <File>event.target.files[0];

      if (this.transferFileURL != null) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.transferFileURL.name.split('.').pop();
        var d = this.datepipe.transform(new Date(), 'yyyyMMdd');
        var url = '';
        url = d == null ? '' : d + number + '.' + fileExt;
        if (
          this.data.TRANSFER_ORDER_SERVICE_CERTIFICATE != undefined &&
          this.data.TRANSFER_ORDER_SERVICE_CERTIFICATE.trim() != ''
        ) {
          var arr = this.data.TRANSFER_ORDER_SERVICE_CERTIFICATE.split('/');
          if (arr.length > 1) {
            url = arr[5];
          }
        }
      }
      this.api
        .onUpload('transferServiceCertificate', this.transferFileURL, url)
        .subscribe((successCode) => {
          if (successCode.code == '200') {
            this.data.TRANSFER_ORDER_SERVICE_CERTIFICATE = url;
            this.data.TRANSFER_ORDER_SERVICE_CERTIFICATE_STATUS = '';
            this.updatecall();
            this.message.success('File Uploaded Successfully...', '');
            this.isSpinning = false;
            // this.data.FILE_URL = url;
          } else {
            this.message.error('Failed To Save File...', '');
            this.isSpinning = false;
          }
        });
    } else {
      this.message.error('Please Select Only PDF File', '');
      this.transferFileURL = null;
      this.data.TRANSFER_ORDER_SERVICE_CERTIFICATE = null;
    }
  }

  ticketPassFileURL: any;
  onFileSelectedTicketPass(event: any) {
    // if (this.data.FILE_TYPE == 'PDF') {

    if (event.target.files[0].type == 'application/pdf') {
      this.isSpinning = true;
      this.ticketPassFileURL = <File>event.target.files[0];

      if (this.ticketPassFileURL != null) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.ticketPassFileURL.name.split('.').pop();
        var d = this.datepipe.transform(new Date(), 'yyyyMMdd');
        var url = '';
        url = d == null ? '' : d + number + '.' + fileExt;
        if (
          this.data.TICKET_BOARDING_PASS != undefined &&
          this.data.TICKET_BOARDING_PASS.trim() != ''
        ) {
          var arr = this.data.TICKET_BOARDING_PASS.split('/');
          if (arr.length > 1) {
            url = arr[5];
          }
        }
      }
      this.api
        .onUpload('ticketBoardingPass', this.ticketPassFileURL, url)
        .subscribe((successCode) => {
          if (successCode.code == '200') {
            this.data.TICKET_BOARDING_PASS = url;
            this.data.TICKET_BOARDING_PASS_STATUS = '';
            this.updatecall();
            this.message.success('File Uploaded Successfully...', '');
            this.isSpinning = false;
            // this.data.FILE_URL = url;
          } else {
            this.message.error('Failed To Save File...', '');
            this.isSpinning = false;
          }
        });
    } else {
      this.message.error('Please Select Only PDF File', '');
      this.ticketPassFileURL = null;
      this.data.TICKET_BOARDING_PASS = null;
    }
  }

  transportationBillFileURL: any;
  onFileSelectedTransportationBill(event: any) {
    // if (this.data.FILE_TYPE == 'PDF') {

    if (event.target.files[0].type == 'application/pdf') {
      this.isSpinning = true;
      this.transportationBillFileURL = <File>event.target.files[0];

      if (this.transportationBillFileURL != null) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.transportationBillFileURL.name.split('.').pop();
        var d = this.datepipe.transform(new Date(), 'yyyyMMdd');
        var url = '';
        url = d == null ? '' : d + number + '.' + fileExt;
        if (
          this.data.TRANSPORTATION_BILLS != undefined &&
          this.data.TRANSPORTATION_BILLS.trim() != ''
        ) {
          var arr = this.data.TRANSPORTATION_BILLS.split('/');
          if (arr.length > 1) {
            url = arr[5];
          }
        }
      }
      this.api
        .onUpload('transportationBills', this.transportationBillFileURL, url)
        .subscribe((successCode) => {
          if (successCode.code == '200') {
            this.data.TRANSPORTATION_BILLS = url;
            this.data.TRANSPORTATION_BILLS_STATUS = '';
            this.updatecall();
            this.message.success('File Uploaded Successfully...', '');
            this.isSpinning = false;
            // this.data.FILE_URL = url;
          } else {
            this.message.error('Failed To Save File...', '');
            this.isSpinning = false;
          }
        });
    } else {
      this.message.error('Please Select Only PDF File', '');
      this.transportationBillFileURL = null;
      this.data.TRANSPORTATION_BILLS = null;
    }
  }

  condonationOfDelayFileURL: any;
  onFileSelectedCondonationOfDelay(event: any) {
    // if (this.data.FILE_TYPE == 'PDF') {

    if (event.target.files[0].type == 'application/pdf') {
      this.isSpinning = true;
      this.condonationOfDelayFileURL = <File>event.target.files[0];

      if (this.condonationOfDelayFileURL != null) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.condonationOfDelayFileURL.name.split('.').pop();
        var d = this.datepipe.transform(new Date(), 'yyyyMMdd');
        var url = '';
        url = d == null ? '' : d + number + '.' + fileExt;
        if (
          this.data.CONDOLATION_OF_DELAY != undefined &&
          this.data.CONDOLATION_OF_DELAY.trim() != ''
        ) {
          var arr = this.data.CONDOLATION_OF_DELAY.split('/');
          if (arr.length > 1) {
            url = arr[5];
          }
        }
      }
      this.api
        .onUpload('condolationOfDelay', this.condonationOfDelayFileURL, url)
        .subscribe((successCode) => {
          if (successCode.code == '200') {
            this.data.CONDOLATION_OF_DELAY = url;
            this.data.CONDOLATION_OF_DELAY_STATUS = '';
            this.updatecall();
            this.message.success('File Uploaded Successfully...', '');
            this.isSpinning = false;
            // this.data.FILE_URL = url;
          } else {
            this.message.error('Failed To Save File...', '');
            this.isSpinning = false;
          }
        });
    } else {
      this.message.error('Please Select Only PDF File', '');
      this.condonationOfDelayFileURL = null;
      this.data.CONDOLATION_OF_DELAY = null;
    }
  }
  view = 0;
  sanitizedLink: any = '';
  getS(link: string) {
    if (this.view == 1) {
      var a = this.api.retriveimgUrl + 'assumptionCharge/' + link;
    }
    if (this.view == 2) {
      var a = this.api.retriveimgUrl + 'lpc/' + link;
    }
    if (this.view == 3) {
      var a = this.api.retriveimgUrl + 'mandateForm/' + link;
    }
    if (this.view == 4) {
      var a = this.api.retriveimgUrl + 'transferServiceCertificate/' + link;
    }
    if (this.view == 5) {
      var a = this.api.retriveimgUrl + 'ticketBoardingPass/' + link;
    }
    if (this.view == 6) {
      var a = this.api.retriveimgUrl + 'transportationBills/' + link;
    }
    if (this.view == 7) {
      var a = this.api.retriveimgUrl + 'condolationOfDelay/' + link;
    }

    this.sanitizedLink = this.sanitizer.bypassSecurityTrustResourceUrl(a);

    return this.sanitizedLink;
  }
  printOrderModalCancel() {
    this.printOrderModalVisible = false;
  }
  printOrderModalVisible = false;
  viewAssumptionPDF(pdfURL: string): void {
    this.view = 1;
    this.printOrderModalVisible = true;

    // window.open(this.api.retriveimgUrl + 'assumptionCharge/' + pdfURL);
  }
  viewLPCPDF(pdfURL: string): void {
    this.view = 2;
    this.printOrderModalVisible = true;
    // window.open(this.api.retriveimgUrl + 'lpc/' + pdfURL);
  }
  viewMandateFormPDF(pdfURL: string): void {
    this.view = 3;
    this.printOrderModalVisible = true;
    // window.open(this.api.retriveimgUrl + 'mandateForm/' + pdfURL);
  }
  viewTransferPDF(pdfURL: string): void {
    this.view = 4;
    this.printOrderModalVisible = true;
    // window.open(
    // this.api.retriveimgUrl + 'transferServiceCertificate/' + pdfURL
    // );
  }
  viewBoardingPassPDF(pdfURL: string): void {
    this.view = 5;
    this.printOrderModalVisible = true;
    // window.open(this.api.retriveimgUrl + 'ticketBoardingPass/' + pdfURL);
  }
  viewTransportationBillsPDF(pdfURL: string): void {
    this.view = 6;
    this.printOrderModalVisible = true;
    // window.open(this.api.retriveimgUrl + 'transportationBills/' + pdfURL);
  }
  viewCondonationOfDelayPDF(pdfURL: string): void {
    this.view = 7;
    this.printOrderModalVisible = true;
    // window.open(this.api.retriveimgUrl + 'condolationOfDelay/' + pdfURL);
  }

  Accept() {
    this.isSpinning = true;
    if (this.view == 1) {
      this.data.ASSUMPTION_CHARGE_STATUS = 'A';
    }
    if (this.view == 2) {
      this.data.LPC_STATUS = 'A';
    }
    if (this.view == 3) {
      this.data.MANDATE_FORM_STATUS = 'A';
    }
    if (this.view == 4) {
      this.data.TRANSFER_ORDER_SERVICE_CERTIFICATE_STATUS = 'A';
    }
    if (this.view == 5) {
      this.data.TICKET_BOARDING_PASS_STATUS = 'A';
    }
    if (this.view == 6) {
      this.data.TRANSPORTATION_BILLS_STATUS = 'A';
    }
    if (this.view == 7) {
      this.data.CONDOLATION_OF_DELAY_STATUS = 'A';
    }
    this.api.updatetransfer(this.data).subscribe((successCode) => {
      if (successCode.code == '200') {
        this.isSpinning = false;
        this.message.success('Verified Successfully...', '');
        this.printOrderModalVisible = false;
      } else {
        this.message.error('Information Has Not Saved...', '');

        this.isSpinning = false;
      }
    });
  }
  reject() {
    this.isSpinning = true;
    if (this.view == 1) {
      this.data.ASSUMPTION_CHARGE_STATUS = 'R';
    }
    if (this.view == 2) {
      this.data.LPC_STATUS = 'R';
    }
    if (this.view == 3) {
      this.data.MANDATE_FORM_STATUS = 'R';
    }
    if (this.view == 4) {
      this.data.TRANSFER_ORDER_SERVICE_CERTIFICATE_STATUS = 'R';
    }
    if (this.view == 5) {
      this.data.TICKET_BOARDING_PASS_STATUS = 'R';
    }
    if (this.view == 6) {
      this.data.TRANSPORTATION_BILLS_STATUS = 'R';
    }
    if (this.view == 7) {
      this.data.CONDOLATION_OF_DELAY_STATUS = 'R';
    }

    this.api.updatetransfer(this.data).subscribe((successCode) => {
      if (successCode.code == '200') {
        this.isSpinning = false;

        this.message.success('Rejected Successfully...', '');
        this.printOrderModalVisible = false;
      } else {
        this.message.error('Information Has Not Saved...', '');

        this.isSpinning = false;
      }
    });
  }

  updatecall() {
    this.api.updatetransfer(this.data).subscribe((successCode) => {
      if (successCode.code == '200') {
        // this.isSpinning = false;
        // this.message.success('Verified Successfully...', '');
        // this.printOrderModalVisible = false;
      } else {
        this.message.error('Information Has Not Saved...', '');

        this.isSpinning = false;
      }
    });
  }

  loadingRecords = false;
  deleteConfirm(data: any) {
    this.loadingRecords = true;

    var data1 = {
      ID: data.ID,
      IS_DELETED: 1,
    };
    this.api.deletedetailsofjourney(data1).subscribe((successCode) => {
      if (successCode.code == '200') {
        this.message.success('Record Deleted Successfully...', '');
        this.detailsJorneyGetData();
        this.loadingRecords = false;
      } else {
        this.message.error('Information Has Not Deleted...', '');
        this.loadingRecords = false;
      }
    });
  }

  deletetransportationcharges(data: any) {
    this.loadingRecords = true;

    var data1 = {
      ID: data.ID,
      IS_DELETED: 1,
    };
    this.api.deletetransportationcharges(data1).subscribe((successCode) => {
      if (successCode.code == '200') {
        this.message.success('Record Deleted Successfully...', '');
        this.getDataform3();

        this.loadingRecords = false;
      } else {
        this.message.error('Information Has Not Deleted...', '');
        this.loadingRecords = false;
      }
    });
  }

  accommodation(data: any) {
    this.loadingRecords = true;

    var data1 = {
      ID: data.ID,
      IS_DELETED: 1,
    };
    this.api.accommodation(data1).subscribe((successCode) => {
      if (successCode.code == '200') {
        this.message.success('Record Deleted Successfully...', '');
        this.getDataform2();

        this.loadingRecords = false;
      } else {
        this.message.error('Information Has Not Deleted...', '');
        this.loadingRecords = false;
      }
    });
  }

  deleterail(data: any) {
    this.loadingRecords = true;

    var data1 = {
      ID: data.ID,
      IS_DELETED: 1,
    };
    this.api.deleterail(data1).subscribe((successCode) => {
      if (successCode.code == '200') {
        this.message.success('Record Deleted Successfully...', '');
        this.getDataform1();

        this.loadingRecords = false;
      } else {
        this.message.error('Information Has Not Deleted...', '');
        this.loadingRecords = false;
      }
    });
  }

  // clearRemark(event) {
  //   if (event == 'A') {
  //     this.data.ACTION_REMARK = null;

  //     this.data.ACTION_DATE = null;
  //   } else if (event == 'R') {
  //     this.data.ACTION_REMARK = null;
  //     this.data.ACTION_DATE = null;
  //   } else {
  //     this.data.ACTION_REMARK = null;
  //   }
  // }

  rateOnKM(event) {
    if (event != null && event != undefined && event != '') {
      if (event >= 1 && event <= 100) {
        this.data.TRAIN_BROUGHT_CHARGE = 6485;
      } else if (event >= 101 && event <= 150) {
        this.data.TRAIN_BROUGHT_CHARGE = 7828;
      } else if (event >= 151 && event <= 200) {
        this.data.TRAIN_BROUGHT_CHARGE = 9059;
      } else if (event >= 201 && event <= 250) {
        this.data.TRAIN_BROUGHT_CHARGE = 10283;
      } else if (event >= 251 && event <= 300) {
        this.data.TRAIN_BROUGHT_CHARGE = 11530;
      } else if (event >= 301 && event <= 350) {
        this.data.TRAIN_BROUGHT_CHARGE = 12747;
      } else if (event >= 351 && event <= 400) {
        this.data.TRAIN_BROUGHT_CHARGE = 13994;
      } else if (event >= 401 && event <= 450) {
        this.data.TRAIN_BROUGHT_CHARGE = 15247;
      } else if (event >= 451 && event <= 500) {
        this.data.TRAIN_BROUGHT_CHARGE = 16494;
      } else if (event >= 501 && event <= 550) {
        this.data.TRAIN_BROUGHT_CHARGE = 17785;
      } else if (event >= 551 && event <= 600) {
        this.data.TRAIN_BROUGHT_CHARGE = 19069;
      } else if (event >= 601 && event <= 650) {
        this.data.TRAIN_BROUGHT_CHARGE = 19142;
      } else if (event >= 651 && event <= 700) {
        this.data.TRAIN_BROUGHT_CHARGE = 20336;
      } else if (event >= 701 && event <= 750) {
        this.data.TRAIN_BROUGHT_CHARGE = 21530;
      } else if (event >= 751 && event <= 800) {
        this.data.TRAIN_BROUGHT_CHARGE = 22717;
      } else if (event >= 801 && event <= 850) {
        this.data.TRAIN_BROUGHT_CHARGE = 23898;
      } else if (event >= 851 && event <= 900) {
        this.data.TRAIN_BROUGHT_CHARGE = 25070;
      } else if (event >= 901 && event <= 950) {
        this.data.TRAIN_BROUGHT_CHARGE = 26244;
      } else if (event >= 951 && event <= 1000) {
        this.data.TRAIN_BROUGHT_CHARGE = 27410;
      } else if (event >= 1001 && event <= 1050) {
        this.data.TRAIN_BROUGHT_CHARGE = 28604;
      } else if (event >= 1051 && event <= 1100) {
        this.data.TRAIN_BROUGHT_CHARGE = 29791;
      } else if (event >= 1101 && event <= 1150) {
        this.data.TRAIN_BROUGHT_CHARGE = 30979;
      } else if (event >= 1151 && event <= 1200) {
        this.data.TRAIN_BROUGHT_CHARGE = 32159;
      } else if (event >= 1201 && event <= 1250) {
        this.data.TRAIN_BROUGHT_CHARGE = 41674;
      } else if (event >= 1251 && event <= 1300) {
        this.data.TRAIN_BROUGHT_CHARGE = 43158;
      } else if (event >= 1301 && event <= 1350) {
        this.data.TRAIN_BROUGHT_CHARGE = 44633;
      } else if (event >= 1351 && event <= 1400) {
        this.data.TRAIN_BROUGHT_CHARGE = 46100;
      } else if (event >= 1401 && event <= 1450) {
        this.data.TRAIN_BROUGHT_CHARGE = 47567;
      } else if (event >= 1451 && event <= 1500) {
        this.data.TRAIN_BROUGHT_CHARGE = 49033;
      } else if (event >= 1501 && event <= 1550) {
        this.data.TRAIN_BROUGHT_CHARGE = 50369;
      } else if (event >= 1551 && event <= 1600) {
        this.data.TRAIN_BROUGHT_CHARGE = 51704;
      } else if (event >= 1601 && event <= 1650) {
        this.data.TRAIN_BROUGHT_CHARGE = 53040;
      } else if (event >= 1651 && event <= 1700) {
        this.data.TRAIN_BROUGHT_CHARGE = 54367;
      } else if (event >= 1701 && event <= 1750) {
        this.data.TRAIN_BROUGHT_CHARGE = 55624;
      } else if (event >= 1751 && event <= 1800) {
        this.data.TRAIN_BROUGHT_CHARGE = 56881;
      } else if (event >= 1801 && event <= 1850) {
        this.data.TRAIN_BROUGHT_CHARGE = 57850;
      } else if (event >= 1851 && event <= 1900) {
        this.data.TRAIN_BROUGHT_CHARGE = 58802;
      } else if (event >= 1901 && event <= 1950) {
        this.data.TRAIN_BROUGHT_CHARGE = 59753;
      } else if (event >= 1951 && event <= 2000) {
        this.data.TRAIN_BROUGHT_CHARGE = 60687;
      }
      // if (event >= 1901 && event <= 1950)
      else {
        this.data.TRAIN_BROUGHT_CHARGE = null;
      }
    } else {
      this.data.TRAIN_BROUGHT_CHARGE = 0;
    }
  }
  rateOnKM2(event) {
    if (event != null && event != undefined && event != '') {
      if (event >= 1 && event <= 100) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 6485;
      } else if (event >= 101 && event <= 150) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 7828;
      } else if (event >= 151 && event <= 200) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 9059;
      } else if (event >= 201 && event <= 250) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 10283;
      } else if (event >= 251 && event <= 300) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 11530;
      } else if (event >= 301 && event <= 350) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 12747;
      } else if (event >= 351 && event <= 400) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 13994;
      } else if (event >= 401 && event <= 450) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 15247;
      } else if (event >= 451 && event <= 500) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 16494;
      } else if (event >= 501 && event <= 550) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 17785;
      } else if (event >= 551 && event <= 600) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 19069;
      } else if (event >= 601 && event <= 650) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 19142;
      } else if (event >= 651 && event <= 700) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 20336;
      } else if (event >= 701 && event <= 750) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 21530;
      } else if (event >= 751 && event <= 800) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 22717;
      } else if (event >= 801 && event <= 850) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 23898;
      } else if (event >= 851 && event <= 900) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 25070;
      } else if (event >= 901 && event <= 950) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 26244;
      } else if (event >= 951 && event <= 1000) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 27410;
      } else if (event >= 1001 && event <= 1050) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 28604;
      } else if (event >= 1051 && event <= 1100) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 29791;
      } else if (event >= 1101 && event <= 1150) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 30979;
      } else if (event >= 1151 && event <= 1200) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 32159;
      } else if (event >= 1201 && event <= 1250) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 41674;
      } else if (event >= 1251 && event <= 1300) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 43158;
      } else if (event >= 1301 && event <= 1350) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 44633;
      } else if (event >= 1351 && event <= 1400) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 46100;
      } else if (event >= 1401 && event <= 1450) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 47567;
      } else if (event >= 1451 && event <= 1500) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 49033;
      } else if (event >= 1501 && event <= 1550) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 50369;
      } else if (event >= 1551 && event <= 1600) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 51704;
      } else if (event >= 1601 && event <= 1650) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 53040;
      } else if (event >= 1651 && event <= 1700) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 54367;
      } else if (event >= 1701 && event <= 1750) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 55624;
      } else if (event >= 1751 && event <= 1800) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 56881;
      } else if (event >= 1801 && event <= 1850) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 57850;
      } else if (event >= 1851 && event <= 1900) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 58802;
      } else if (event >= 1901 && event <= 1950) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 59753;
      } else if (event >= 1951 && event <= 2000) {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 60687;
      }
      // if (event >= 1901 && event <= 1950)
      else {
        this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = null;
      }
    } else {
      this.data.TRAIN_BROUGHT_FOR_CHARGE_NO = 0;
    }
  }
}
