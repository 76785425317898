export class form1 {
  ID: number;
  EMP_ID: any;
  TRANSFER_ID: any;
  DATE: any;
  FROM_PLACE: any;
  TO_PLACE: any;
  FARE_PAID: any;
  IS_DELETED: any;
  TRANSFER_ROAD_CLAIMED_AMOUNT:any=0
}
