import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Journeydetails } from 'src/app/Models/journeydetails';
import { ApiService } from 'src/app/Service/api.service';
import { differenceInCalendarDays, setHours } from 'date-fns';

@Component({
  selector: 'app-detailsofjourney',
  templateUrl: './detailsofjourney.component.html',
  styleUrls: ['./detailsofjourney.component.css'],
})
export class DetailsofjourneyComponent implements OnInit {
  @Input()
  drawerVisible: boolean = false;
  @Input() drawerClose: Function;
  @Input() data: Journeydetails;
  @Input() empID: any;
  @Input() ltcID: any;
  @Input() gradePayLevel: any;
  ID: any;
  DATE: any;
  FARE_PAID: any;
  FROM_PLACE_NAME: any;
  TO_PLACE_NAME: any;
  modedata: any[] = [];
  @Input() classdata: any[] = [];
  constructor(
    private message: NzNotificationService,
    private api: ApiService,
    private datePipe: DatePipe
  ) {}
  isSpinning = false;
  isOk = true;
  ngOnInit(): void {
    this.ltcID = this.ltcID;
    this.api.gettravelmode(0, 0, '', '', ' AND STATUS=1').subscribe(
      (data) => {
        if (data['code'] == 200) {
          this.modedata = data['data'];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  close(AddNewRecord: NgForm) {
    this.drawerClose();
    this.resetDrawer(AddNewRecord);
    AddNewRecord.form.reset();
  }

  getClasses(event: any) {
    this.data.TRAVEL_MODE_ID = event;
    this.data.TRAVEL_CLASS_ID = 0;
    if (
      this.data.TRAVEL_MODE_ID != undefined &&
      this.data.TRAVEL_MODE_ID != null
    )
      this.api
        .gettravelclass(0, 0, '', '', ' AND STATUS=1 AND MODE_ID=' + event)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              this.classdata = data['data'];
            }
          },
          (err) => {
            console.log(err);
          }
        );
  }
  resetDrawer(AddNewRecord: NgForm) {
    this.data = new Journeydetails();
    // this.data.PARENT_GROUP_ID=this.parentgroup;
    AddNewRecord.form.markAsPristine();
    AddNewRecord.form.markAsUntouched();
  }

  omit(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  /////////////Disable Date /////////////
  disabledEndDate = (current: Date): boolean =>
    differenceInCalendarDays(current, new Date(this.data.FROM_DATETIME)) < 0;

  leaveEndDate() {
    this.disabledEndDate = (current: Date): boolean =>
      differenceInCalendarDays(current, new Date(this.data.FROM_DATETIME)) < 0;
  }
  disabledStartDate = (current: Date): boolean =>
    differenceInCalendarDays(current, new Date(this.data.TO_DATETIME)) > 0;

  leaveStartDate() {
    this.disabledStartDate = (current: Date): boolean =>
      differenceInCalendarDays(current, new Date(this.data.TO_DATETIME)) > 0;
  }
  /////////////Disable Date /////////////
  save(addNew: boolean, AddNewRecord: NgForm): void {
    if (
      this.data.FAIR_PAID != null ||
      this.data.FAIR_PAID != undefined ||
      this.data.FAIR_PAID != ''
    ) {
      this.data.FAIR_PAID = Number(this.data.FAIR_PAID);
    } else {
    }
    if (
      this.data.JOURNEY_CLAIMED_AMOUNT != null ||
      this.data.JOURNEY_CLAIMED_AMOUNT != undefined
    ) {
      this.data.JOURNEY_CLAIMED_AMOUNT = Number(
        this.data.JOURNEY_CLAIMED_AMOUNT
      );
    } else {
    }
    this.isSpinning = false;
    this.isOk = true;
    this.data.LTC_ID = this.ltcID;
    this.data.EMP_ID = this.empID;

    if (
      // this.data.DISTANCE_BY_ROAD == undefined &&
      this.data.FAIR_PAID == undefined &&
      this.data.NO_OF_FAIRS == undefined &&
      this.data.TRAVEL_MODE_ID == 0 &&
      this.data.TRAVEL_CLASS_ID == 0 &&
      this.data.FROM_DATETIME == undefined &&
      this.data.DEPARTURE_FROM == undefined &&
      this.data.FROM_DATETIME == undefined &&
      this.data.ARRIVAL_TO == undefined
    ) {
      this.isOk = false;
      this.message.error('Please Fill All The Required Fields ', '');
    } else if (
      this.data.FROM_DATETIME == null ||
      this.data.FROM_DATETIME == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Select From Date.', '');
    } else if (
      this.data.DEPARTURE_FROM == null ||
      this.data.DEPARTURE_FROM == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Departure Form.', '');
    } else if (this.data.TO_DATETIME == null || this.data.TO_DATETIME == '') {
      this.isOk = false;
      this.message.error(' Please Select To Date', '');
    } else if (this.data.ARRIVAL_TO == null || this.data.ARRIVAL_TO == '') {
      this.isOk = false;
      this.message.error('Please Enter Arrival to.', '');
    } else if (
      this.data.TRAVEL_MODE_ID == null ||
      this.data.TRAVEL_MODE_ID <= 0
    ) {
      this.isOk = false;
      this.message.error('Please Select Travel Mode', '');
    } else if (
      this.data.TRAVEL_CLASS_ID == null ||
      this.data.TRAVEL_CLASS_ID <= 0
    ) {
      this.isOk = false;
      this.message.error('Please Select Class of Mode.', '');
    } else if (this.data.NO_OF_FAIRS == null || this.data.NO_OF_FAIRS <= 0) {
      this.isOk = false;
      this.message.error('Please Enter No of Fairs.', '');
    } else if (
      this.data.JOURNEY_CLAIMED_AMOUNT == null ||
      this.data.JOURNEY_CLAIMED_AMOUNT == undefined
    ) {
      this.isOk = false;
      this.message.error('Please Enter Fair Paid claimed.', '');
    } else if (
      this.data.FAIR_PAID == null ||
      this.data.FAIR_PAID == undefined
    ) {
      this.isOk = false;
      this.message.error('Please Enter Base Fare.', '');
      // } else if (
      //   this.data.DISTANCE_BY_ROAD == null ||
      //   this.data.DISTANCE_BY_ROAD <= 0
      // ) {
      //   this.isOk = false;
      //   this.message.error('Please Enter Distance of Road.', '');
    } else if (this.data.FAIR_PAID > this.data.JOURNEY_CLAIMED_AMOUNT) {
      this.isOk = false;
      this.message.error(
        'Please Enter Fair Paid Admisible Amount Which is Less than Fair Paid claimed',
        ''
      );
    }

    if (this.isOk) {
      // this.data.LTC_ID = this.ltcID;
      // this.data.EMP_ID = this.empID;
      if (this.data.TO_DATETIME == undefined) {
        this.data.TO_DATETIME = null;
      } else {
        this.data.TO_DATETIME = this.datePipe.transform(
          this.data.TO_DATETIME,
          'yyyy-MM-dd HH:mm'
        );
      }
      if (this.data.FROM_DATETIME == undefined) {
        this.data.FROM_DATETIME = null;
      } else {
        this.data.FROM_DATETIME = this.datePipe.transform(
          this.data.FROM_DATETIME,
          'yyyy-MM-dd HH:mm'
        );
      }
      this.isSpinning = true;
      {
        if (this.data.ID) {
          this.api
            .ltcJourneyDetailUpdate(this.data)
            .subscribe((successCode) => {
              if (successCode.code == '200') {
                this.message.success('Information Changed Successfully...', '');
                // this.ltcID = successCode.LTC_ID;
                // this.empID = successCode.EMP_ID;

                if (!addNew) {
                  this.drawerClose();
                }
                this.isSpinning = false;
                AddNewRecord.form.reset();
                // this.search()
              } else {
                this.message.error('Information Has Not Changed...', '');
                this.isSpinning = false;
              }
            });
        } else {
          this.api
            .ltcJourneyDetailCreate(this.data)
            .subscribe((successCode) => {
              if (successCode.code == '200') {
                this.message.success('Information Save Successfully...', '');
                // this.ltcID = successCode.LTC_ID;
                // this.empID = successCode.EMP_ID;

                if (!addNew) {
                  this.close(AddNewRecord);
                } else {
                  this.data = new Journeydetails();
                  this.resetDrawer(AddNewRecord);
                }
                this.isSpinning = false;
              } else {
                this.message.error('Failed To Fill Information...', '');
                this.isSpinning = false;
              }
            });
        }
      }
    }
  }

  assignToDate(event) {
    if (event != null || event != undefined) {
      if (
        this.data.TO_DATETIME == undefined ||
        this.data.TO_DATETIME == null ||
        this.data.TO_DATETIME == ''
      ) {
        this.data.TO_DATETIME = event;
      } else {
      }
    } else {
    }
  }

  fileURL1: any;
  onFileSelected(event: any) {
    // if (this.data.FILE_TYPE == 'PDF') {

    if (event.target.files[0].type == 'application/pdf') {
      this.fileURL1 = <File>event.target.files[0];
      if (this.fileURL1 != null) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.fileURL1.name.split('.').pop();
        var d = this.datePipe.transform(new Date(), 'yyyyMMdd');
        var url = '';
        url = d == null ? '' : d + number + '.' + fileExt;
        if (
          this.data.TICKET_URL != undefined &&
          this.data.TICKET_URL.trim() != ''
        ) {
          var arr = this.data.TICKET_URL.split('/');
          if (arr.length > 1) {
            url = arr[5];
          }
        }
      }

      this.isSpinning = true;
      this.api
        .onUpload('ltcTicket', this.fileURL1, url)
        .subscribe((successCode) => {
          if (successCode.code == '200') {
            this.data.TICKET_URL = url;
            this.message.success('File saved Successfully...', '');
            this.isSpinning = false;
            // this.data.FILE_URL = url;
          } else {
            this.message.error('Failed To Save File...', '');
            this.isSpinning = false;
          }
        });
    } else {
      this.message.error('Please Select Only PDF File', '');
      this.fileURL1 = null;
      this.data.TICKET_URL = null;
    }
  }
  ticketsView(pdfURL: string): void {
    // this.view = 2
    // this.printOrderModalVisible = true;
    window.open(this.api.retriveimgUrl + 'ltcTicket/' + pdfURL);
  }
}
