<div nz-row nzType="flex">
  <div nz-col nzXs="24" nzSm="24" nzMd="20" nzLg="20" nzXl="12">
    <div class="formname">Inspecter Wise Ltc Summary Report</div>
  </div>

  <div
    nz-col
    nzXs="24"
    nzSm="24"
    nzMd="4"
    nzLg="6"
    nzXl="12"
    style="display: flex; justify-content: flex-end"
  >
    <form nz-form [nzLayout]="'inline'">
      <nz-form-item>
        <button
          nz-button
          nzTooltipTitle="Excel"
          nzTooltipPlacement="top"
          nz-tooltip
          nzType="primary"
          (click)="importInExcel()"
          [nzLoading]="exportLoading"
        >
          <i nz-icon nzType="file-excel" nzTheme="outline"></i>
        </button>
      </nz-form-item>

      <nz-form-item>
        <button
          nz-button
          nzTooltipTitle="Filter"
          nzTooltipPlacement="top"
          nz-tooltip
          [nzType]="isFilterApplied"
          (click)="showFilter()"
        >
          <i nz-icon nzType="filter"></i>
        </button>
      </nz-form-item>

      <!-- <nz-form-item>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                    <input type="text" name="search" [(ngModel)]="searchText" (keydown.enter)="onKeypressEvent($event)"
                        nz-input placeholder="Search" />
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" id="button" (click)="search(true)" nzSearch><i nz-icon
                            nzType="search"></i></button>
                </ng-template>
            </nz-form-item> -->
    </form>
  </div>
</div>

<div nz-row>
  <div nz-col nzSpan="24">
    <div class="filter {{ filterClass }}">
      <div class="filter-box">
        <form nz-form>
          <div nz-row>
            <div nz-col nzSpan="8">
              <nz-form-item style="margin-top: 1px; width: 100%">
                <nz-form-label nzNoColon>Select Date</nz-form-label>
                <nz-range-picker
                  name="date"
                  nzFormat="dd MMM yyyy"
                  [(ngModel)]="DATE"
                >
                </nz-range-picker>
              </nz-form-item>
            </div>
            &nbsp;&nbsp;&nbsp;
            <div nz-col nzSpan="8">
              <nz-form-label nzNoColon></nz-form-label>
              <nz-form-item>
                <button
                  style="margin-right: 10px"
                  nz-button
                  [nzLoading]="isSpinning"
                  nzType="primary"
                  (click)="applyFilter()"
                  nzJustify="end"
                >
                  <i nz-icon nzType="filter"></i>Apply Filter
                </button>
                <button nz-button nzType="primary" (click)="clearFilter()">
                  <i nz-icon nzType="filter"></i>Clear filter
                </button>
              </nz-form-item>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Table -->
<div nz-row>
  <div nz-col nzSpan="24">
    <nz-table
      id="excel-table"
      class="my-scroll no-wrap1"
      nzBordered
      #table
      nzShowSizeChanger
      [nzFrontPagination]="false"
      [nzData]="dataList"
      [nzLoading]="loadingRecords"
      [nzTotal]="totalRecords"
      [(nzPageIndex)]="pageIndex"
      [(nzPageSize)]="pageSize"
      (nzPageIndexChange)="search()"
      [nzScroll]="{ x: '800px', y: '420px' }"
      (nzPageSizeChange)="search()"
      (nzQueryParams)="sort($event)"
    >
      <thead nzSingleSort>
        <tr>
          <th
            nzWidth="100px"
            nzColumnKey="{{ 'INSPECTOR_NAME' }}"
            [nzSortFn]="true"
            style="font-weight: bold; text-align: center"
          >
            Inspector Name
          </th>
          <th
            nzWidth="100px"
            nzColumnKey="{{ 'TOTAL_CREATED' }}"
            [nzSortFn]="true"
            style="font-weight: bold; text-align: center"
          >
            Total Created
          </th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let data of table.data">
          <td style="white-space: pre-line" align="center">
            {{ data["INSPECTOR_NAME"] }}
          </td>
          <td style="white-space: pre-line" align="center">
            {{ data["TOTAL_CREATED"] }}
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>
