<div>
  <form nz-form>
    <!-- <div style="font-size: 14px">
      <p style="text-align: center; font-size: 16px">
        <u>(To be filled in the Bill Section)</u>
      </p> 
      <p>
        The net entitlement on account of Travelling Allowance works out to
        <b>
          <u
            >Rs.<b>{{ totalamountofpartb }}</b
            >/-</u
          ></b
        >
        as detailed below :-
      </p>

      <p>(a) Railway / Air / Bus / Steamer fare : Rs _________</p>
      <p>
        (b) Road mileage for <b>{{ tourpartbdata.KILOMETER }}</b> Kms @ .Rs
        <b>{{ tourpartbdata.MILAGE_BY_ROAD }}</b> per/Km
      </p>

      <table style="border: 1px solid black">
        <tr>
          <th rowspan="5" style="width: 40px; text-align: center">(c)</th>
          <td colspan="6">Daily Allowance</td>
        </tr>
        <tr>
          <td style="width: 40px; text-align: center">(i)</td>
          <td style="width: 220px">__________ days</td>
          <td style="width: 40px; text-align: center">@</td>
          <td style="width: 220px">Rs.__________</td>
          <td style="width: 100px; text-align: center">Per days</td>
          <td style="width: 200px">Rs.</td>
        </tr>
        <tr>
          <td style="width: 40px; text-align: center">(ii)</td>
          <td style="width: 220px">__________ days</td>
          <td style="width: 40px; text-align: center">@</td>
          <td style="width: 220px">Rs.__________</td>
          <td style="width: 100px; text-align: center">Per days</td>
          <td style="width: 200px">Rs.</td>
        </tr>
        <tr>
          <td style="width: 40px; text-align: center">(iii)</td>
          <td style="width: 220px">__________ days</td>
          <td style="width: 40px; text-align: center">@</td>
          <td style="width: 220px">Rs.__________</td>
          <td style="width: 100px; text-align: center">Per days</td>
          <td style="width: 200px">Rs.</td>
        </tr>
        <tr>
          <td style="width: 40px"></td>
          <td style="width: 220px"></td>
          <td style="width: 40px; text-align: center"></td>
          <td style="width: 220px"></td>
          <td style="width: 100px; text-align: center"></td>
          <td style="width: 200px">Rs.</td>
        </tr>
        <tr>
          <th  style="width: 40px; text-align: center">(d)</th>
          <td colspan="5" style="text-align: left">Hotel</td>
          <td style="width: 200px">
            Rs.<b>{{ hotelAdmissibleAmount }}/-</b>
          </td>
        </tr>
        <tr>
          <th  style="width: 40px; text-align: center">(e)</th>
          <td colspan="5"  style="text-align: left">Food</td>
          <td style="width: 200px">
            Rs.<b>{{ foodAdmissibleAmount }}/-</b>
          </td>
        </tr>
        <tr>
          <th  style="width: 40px; text-align: center">(f)</th>
          <td colspan="5" style="text-align: left">Intercity</td>
          <td style="width: 200px">
            Rs.<b>{{ intercityAdmissibleAmount }}/-</b>
          </td>
        </tr>
        <tr>
          <th rowspan="2" style="width: 40px; text-align: center">(g)</th>
          <td style="text-align: right">Actual expences :</td>
          <td style="width: 200px">
            Rs.<b>{{ actualExpences }}/-</b>
          </td>
        </tr>
        <tr>
          <td colspan="5" style="text-align: right">Gross Amount :</td>
          <td style="width: 200px">
            Rs.<b>{{ grossAmount }}/-</b>
          </td>
        </tr>
        <tr>
          <th style="text-align: center">(h)</th>
          <td colspan="5">
            Less: Amount of T.A. advance, if any, drawn vide voucher No.
            <b>{{ tourpartbdata.ADVANCE_TAKEN_VOUCHER_NO }}</b> date :-<b>{{
              tourpartbdata.ADVANCE_TAKEN_DATE | date : "dd MM yyyy"
            }}</b>
            :
          </td>
          <td style="width: 200px">
            Rs.<b>{{ tourpartbdata.AMOUNT_OF_T_A }}/-</b>
          </td>
        </tr>
        <tr>
          <th style="text-align: center"></th>
          <td colspan="5" style="text-align: right">Net Amount :</td>
          <td style="width: 200px">
            Rs.<b>{{ totalamountofpartb }}/-</b>
          </td>
        </tr>
        <tr>
          <td colspan="6" style="height: 33px"></td>
          <td style="width: 200px; height: 33px"></td>
        </tr>
      </table>

      <br /><br />
      <br />

      <p>
        The Expenditure is debitable to
        <span style="text-decoration: underline">DTE</span>
      </p>
      <br />
      <br /><br />

      <div style="display: flex; gap: 255px; margin-left: 35px">
        <div style="text-transform: uppercase">Initials of Bill Clerk</div>
        <div>Signature of Drawing & Disbursing Officer</div>
      </div>
      <br /><br />
      <div style="margin-top: 100px; font-size: 13px">
        <div nz-row>
          <div nz-col nzSpan="12"></div>

          <div nz-col nzSpan="12" style="text-align: center">
            <p>COUNTERSIGNED</p>
          </div>
        </div>
      </div>

      <br /><br />

      <div style="margin-top: 100px; font-size: 13px">
        <div nz-row>
          <div nz-col nzSpan="12"></div>

          <div nz-col nzSpan="12" style="text-align: center">
            <p>(Signature of Controlling Officer)</p>
          </div>
        </div>
      </div>
    </div> -->

    <div style="font-size: 14px; height: 590px; overflow-y: scroll">
      <h2 style="text-align: center; font-weight: bold">Part - B</h2>
      <p style="text-align: center; font-size: 16px">
        <u>(To be filled in the Bill Section)</u>
      </p>
      <p>
        The net entitlement on account of Travelling Allowance works out to
        <b>
          <u
            >Rs.<b>{{ totalamountofpartb }}</b
            >/-</u
          ></b
        >
        as detailed below :-
      </p>

      <p>
        (a) Railway / Air / Bus / Steamer fare : Rs
        <b>{{ journeytotalamounttourpartb }}/-</b>
      </p>
      <p>
        (b) Road mileage for <b>{{ tourpartbdata.KILOMETER }}</b> Kms @ .Rs
        <b>{{ tourpartbdata.MILAGE_BY_ROAD }}</b> per/Km
      </p>

      <table style="border: 1px solid black">
        <tr>
          <th rowspan="5" style="width: 40px; text-align: center">(c)</th>
          <td colspan="6">Daily Allowance</td>
        </tr>
        <tr>
          <td style="width: 40px; text-align: center">(i)</td>
          <td style="width: 220px">__________ days</td>
          <td style="width: 40px; text-align: center">@</td>
          <td style="width: 220px">Rs.__________</td>
          <td style="width: 100px; text-align: center">Per days</td>
          <td style="width: 200px">Rs.</td>
        </tr>
        <tr>
          <td style="width: 40px; text-align: center">(ii)</td>
          <td style="width: 220px">__________ days</td>
          <td style="width: 40px; text-align: center">@</td>
          <td style="width: 220px">Rs.__________</td>
          <td style="width: 100px; text-align: center">Per days</td>
          <td style="width: 200px">Rs.</td>
        </tr>
        <tr>
          <td style="width: 40px; text-align: center">(iii)</td>
          <td style="width: 220px">__________ days</td>
          <td style="width: 40px; text-align: center">@</td>
          <td style="width: 220px">Rs.__________</td>
          <td style="width: 100px; text-align: center">Per days</td>
          <td style="width: 200px">Rs.</td>
        </tr>
        <tr>
          <td style="width: 40px"></td>
          <td style="width: 220px"></td>
          <td style="width: 40px; text-align: center"></td>
          <td style="width: 220px"></td>
          <td style="width: 100px; text-align: center"></td>
          <td style="width: 200px">Rs.</td>
        </tr>
        <tr>
          <th style="width: 40px; text-align: center">(d)</th>
          <td colspan="5" style="text-align: left">Accommodation</td>
          <td style="width: 200px">
            Rs.<b>{{ accommodationAdmissibleAmount }}/-</b>
          </td>
        </tr>
        <tr>
          <th style="width: 40px; text-align: center">(e)</th>
          <td colspan="5" style="text-align: left">Hotel</td>
          <td style="width: 200px">
            Rs.<b>{{ hotelAdmissibleAmount }}/-</b>
          </td>
        </tr>
        <tr>
          <th style="width: 40px; text-align: center">(f)</th>
          <td colspan="5" style="text-align: left">Food</td>
          <td style="width: 200px">
            Rs.<b>{{ foodAdmissibleAmount }}/-</b>
          </td>
        </tr>
        <tr>
          <th style="width: 40px; text-align: center">(g)</th>
          <td colspan="5" style="text-align: left">Intercity</td>
          <td style="width: 200px">
            Rs.<b>{{ intercityAdmissibleAmount }}/-</b>
          </td>
        </tr>
        <tr>
          <th rowspan="2" style="width: 40px; text-align: center">(h)</th>
          <td colspan="5" style="text-align: right">Actual expences :</td>
          <td style="width: 200px">
            Rs.<b>{{ actualExpences }}/-</b>
          </td>
        </tr>
        <tr>
          <td colspan="5" style="text-align: right">Gross Amount :</td>
          <td style="width: 200px">
            Rs.<b>{{ grossAmount }}/-</b>
          </td>
        </tr>
        <tr>
          <th style="text-align: center">(i)</th>
          <td colspan="5">
            Less: Amount of T.A. advance, if any, drawn vide voucher No.
            <b>{{ tourpartbdata.ADVANCE_TAKEN_VOUCHER_NO }}</b> date :-<b>{{
              tourpartbdata.ADVANCE_TAKEN_DATE | date : "dd MM yyyy"
            }}</b>
            :
          </td>
          <td style="width: 200px">
            Rs.<b>{{ tourpartbdata.AMOUNT_OF_T_A }}/-</b>
          </td>
        </tr>
        <tr>
          <th style="text-align: center"></th>
          <td colspan="5" style="text-align: right">Net Amount :</td>
          <td style="width: 200px">
            Rs.<b>{{ totalamountofpartb }}/-</b>
          </td>
        </tr>
        <tr>
          <td colspan="6" style="height: 33px"></td>
          <td style="width: 200px; height: 33px"></td>
        </tr>
      </table>
      <p style="margin-top: 10px">
        The Expenditure is debitable to
        <span style="text-decoration: underline">DTE</span>
      </p>
      <br />
      <br />

      <!-- <div style="display: flex; gap: 255px; margin-left: 35px">
        <div style="text-transform: uppercase">Initials of Bill Clerk</div>
        <div>Signature of Drawing & Disbursing Officer</div>
      </div> -->
      <div nz-row>
        <div nz-col nzSpan="12" style="font-size: 12px !important">
          Initials of Bill Clerk
        </div>

        <div
          nz-col
          nzSpan="12"
          style="text-align: center; font-size: 12px !important"
        >
          <div>(Signature of Drawing & Disbursing Officer)</div>
          <div style="font-weight: bold">जितेंद्र रा चांदेकर</div>
          <div style="font-weight: bold">Jitendra R Chandekar</div>
          <div>आयकर अधिकारी / राजपत्रित वेतन बिल कक्ष-II,(कार्मिक), मुंबई</div>
          <div>
            <b>
              Income Tax Officer / Gazetted Pay Bill Section II Personnel,
              Mumbai
            </b>
          </div>
          <div>COUNTERSIGNED</div>
        </div>
      </div>
      <div
        style="margin-top: 80px; text-align: center; font-size: 12px !important"
      >
        <div nz-row>
          <div nz-col nzSpan="12">
            <div></div>
            <div></div>
            <br />
            <div style="font-weight: bold">(मुदित श्रीवास्तव, भा.रा.से.)</div>
            <div><b>(MUDIT SRIVASTAVA, I.R.S.)</b></div>
            <div>अपर आयकर आयुक्त (मुख्या.) कार्मिक, मुंबई</div>
            <div>
              <b>Addl. Commissioner of Income Tax (HQ),Personnel, Mumbai </b>
            </div>
          </div>

          <div
            nz-col
            nzSpan="12"
            style="text-align: center; font-size: 12px !important"
          >
            <div>(Signature of Controlling Officer)</div>

            <div style="font-weight: bold">(विनय सिन्हा)</div>
            <div style="font-weight: bold">(Vinay Sinha)</div>
            <div>आयकर आयुक्त (प्रशासन एवं करदाता सेवा),मुंबई</div>
            <div><b>Commissioner of Income Tax (Admin. & TPS),Mumbai </b></div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="footer">
    <button
      type="button"
      nz-button
      (click)="close()"
      class="ant-btn"
      style="margin-right: 8px"
    >
      <span>Cancel</span>
    </button>
    <button
      type="button"
      class="ant-btn ant-btn-primary"
      nz-button
      (click)="showmodal()"
    >
      <span>Download or Print</span>
    </button>
  </div>
</div>

<nz-modal
  [nzWidth]="getwidth()"
  [(nzVisible)]="printOrderModalVisible"
  nzMaskClosable="false"
  nzClosable="false"
  [nzContent]="printOrderModalContent"
  [nzTitle]="'Tour Part B'"
  (nzOnCancel)="printOrderModalCancel()"
  [nzFooter]="printOrderModalFooter"
>
  <ng-template #printOrderModalContent>
    <nz-spin [nzSpinning]="loadingRecords">
      <div style="height: 450px; overflow: auto">
        <div id="excel-table">
          <div
            style="
              width: 700px;
              padding-right: 3px;
              margin-left: 50px;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 14px;
            "
          >
            <div>
              <h2 style="text-align: center; font-weight: bold">Part - B</h2>
              <p style="text-align: center; font-size: 16px">
                <u>(To be filled in the Bill Section)</u>
              </p>
              <p>
                The net entitlement on account of Travelling Allowance works out
                to
                <b>
                  <u
                    >Rs.<b>{{ totalamountofpartb }}</b
                    >/-</u
                  ></b
                >
                as detailed below :-
              </p>

              <p>
                (a) Railway / Air / Bus / Steamer fare : Rs
                <b>{{ journeytotalamounttourpartb }}/-</b>
              </p>
              <p>
                (b) Road mileage for <b>{{ tourpartbdata.KILOMETER }}</b> Kms @
                .Rs <b>{{ tourpartbdata.MILAGE_BY_ROAD }}</b> per/Km
              </p>

              <table style="border: 1px solid black">
                <tr>
                  <th rowspan="5" style="width: 40px; text-align: center">
                    (c)
                  </th>
                  <td colspan="6">Daily Allowance</td>
                </tr>
                <tr>
                  <td style="width: 40px; text-align: center">(i)</td>
                  <td style="width: 220px">__________ days</td>
                  <td style="width: 40px; text-align: center">@</td>
                  <td style="width: 220px">Rs.__________</td>
                  <td style="width: 100px; text-align: center">Per days</td>
                  <td style="width: 200px">Rs.</td>
                </tr>
                <tr>
                  <td style="width: 40px; text-align: center">(ii)</td>
                  <td style="width: 220px">__________ days</td>
                  <td style="width: 40px; text-align: center">@</td>
                  <td style="width: 220px">Rs.__________</td>
                  <td style="width: 100px; text-align: center">Per days</td>
                  <td style="width: 200px">Rs.</td>
                </tr>
                <tr>
                  <td style="width: 40px; text-align: center">(iii)</td>
                  <td style="width: 220px">__________ days</td>
                  <td style="width: 40px; text-align: center">@</td>
                  <td style="width: 220px">Rs.__________</td>
                  <td style="width: 100px; text-align: center">Per days</td>
                  <td style="width: 200px">Rs.</td>
                </tr>
                <tr>
                  <td style="width: 40px"></td>
                  <td style="width: 220px"></td>
                  <td style="width: 40px; text-align: center"></td>
                  <td style="width: 220px"></td>
                  <td style="width: 100px; text-align: center"></td>
                  <td style="width: 200px">Rs.</td>
                </tr>
                <tr>
                  <th style="width: 40px; text-align: center">(d)</th>
                  <td colspan="5" style="text-align: left">Accommodation</td>
                  <td style="width: 200px">
                    Rs.<b>{{ accommodationAdmissibleAmount }}/-</b>
                  </td>
                </tr>
                <tr>
                  <th style="width: 40px; text-align: center">(e)</th>
                  <td colspan="5" style="text-align: left">Hotel</td>
                  <td style="width: 200px">
                    Rs.<b>{{ hotelAdmissibleAmount }}/-</b>
                  </td>
                </tr>
                <tr>
                  <th style="width: 40px; text-align: center">(f)</th>
                  <td colspan="5" style="text-align: left">Food</td>
                  <td style="width: 200px">
                    Rs.<b>{{ foodAdmissibleAmount }}/-</b>
                  </td>
                </tr>
                <tr>
                  <th style="width: 40px; text-align: center">(g)</th>
                  <td colspan="5" style="text-align: left">Intercity</td>
                  <td style="width: 200px">
                    Rs.<b>{{ intercityAdmissibleAmount }}/-</b>
                  </td>
                </tr>
                <tr>
                  <th rowspan="2" style="width: 40px; text-align: center">
                    (h)
                  </th>
                  <td colspan="5" style="text-align: right">
                    Actual expences :
                  </td>
                  <td style="width: 200px">
                    Rs.<b>{{ actualExpences }}/-</b>
                  </td>
                </tr>
                <tr>
                  <td colspan="5" style="text-align: right">Gross Amount :</td>
                  <td style="width: 200px">
                    Rs.<b>{{ grossAmount }}/-</b>
                  </td>
                </tr>
                <tr>
                  <th style="text-align: center">(i)</th>
                  <td colspan="5">
                    Less: Amount of T.A. advance, if any, drawn vide voucher No.
                    <b>{{ tourpartbdata.ADVANCE_TAKEN_VOUCHER_NO }}</b> date
                    :-<b>{{
                      tourpartbdata.ADVANCE_TAKEN_DATE | date : "dd MM yyyy"
                    }}</b>
                    :
                  </td>
                  <td style="width: 200px">
                    Rs.<b>{{ tourpartbdata.AMOUNT_OF_T_A }}/-</b>
                  </td>
                </tr>
                <tr>
                  <th style="text-align: center"></th>
                  <td colspan="5" style="text-align: right">Net Amount :</td>
                  <td style="width: 200px">
                    Rs.<b>{{ totalamountofpartb }}/-</b>
                  </td>
                </tr>
                <tr>
                  <td colspan="6" style="height: 33px"></td>
                  <td style="width: 200px; height: 33px"></td>
                </tr>
              </table>

              <br /><br />
              <br />

              <p>
                The Expenditure is debitable to
                <span style="text-decoration: underline">DTE</span>
              </p>
              <br />
              <br />

              <div nz-row>
                <div nz-col nzSpan="12" style="font-size: 12px !important">
                  Initials of Bill Clerk
                </div>

                <div
                  nz-col
                  nzSpan="12"
                  style="text-align: center; font-size: 12px !important"
                >
                  <div>(Signature of Drawing & Disbursing Officer)</div>
                  <div style="font-weight: bold">जितेंद्र रा चांदेकर</div>
                  <div style="font-weight: bold">Jitendra R Chandekar</div>
                  <div>
                    आयकर अधिकारी / राजपत्रित वेतन बिल कक्ष-II,(कार्मिक), मुंबई
                  </div>
                  <div>
                    <b>
                      Income Tax Officer / Gazetted Pay Bill Section II
                      Personnel, Mumbai
                    </b>
                  </div>
                  <div>COUNTERSIGNED</div>
                </div>
              </div>
              <div
                style="
                  margin-top: 80px;
                  text-align: center;
                  font-size: 12px !important;
                "
              >
                <div nz-row>
                  <div nz-col nzSpan="12">
                    <div></div>
                    <div></div>
                    <br />
                    <div style="font-weight: bold">
                      (मुदित श्रीवास्तव, भा.रा.से.)
                    </div>
                    <div><b>(MUDIT SRIVASTAVA, I.R.S.)</b></div>
                    <div>अपर आयकर आयुक्त (मुख्या.) कार्मिक, मुंबई</div>
                    <div>
                      <b>
                        Addl. Commissioner of Income Tax (HQ),Personnel, Mumbai
                      </b>
                    </div>
                  </div>

                  <div
                    nz-col
                    nzSpan="12"
                    style="text-align: center; font-size: 12px !important"
                  >
                    <div>(Signature of Controlling Officer)</div>

                    <div style="font-weight: bold">(विनय सिन्हा)</div>
                    <div style="font-weight: bold">(Vinay Sinha)</div>
                    <div>आयकर आयुक्त (प्रशासन एवं करदाता सेवा),मुंबई</div>
                    <div>
                      <b>Commissioner of Income Tax (Admin. & TPS),Mumbai</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>
  </ng-template>
  <ng-template #printOrderModalFooter>
    <button nz-button nzType="default" (click)="printOrderModalCancel()">
      Close
    </button>

    <button
      nz-button
      nzType="default"
      [useExistingCss]="true"
      printSectionId="excel-table"
      ngxPrint
    >
      Print
    </button>

    <button
      nz-button
      nzType="primary"
      [nzLoading]="pdfDownload"
      (click)="openpdf()"
    >
      Download
    </button>
  </ng-template>
</nz-modal>
