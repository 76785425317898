<div nz-row>
  <div nz-col nzXs="0" nzSm="0" nzMd="8" nzLg="8" nzXl="8"></div>

  <div
    nz-col
    nzXs="24"
    nzSm="24"
    nzMd="8"
    nzLg="8"
    nzXl="8"
    style="text-align: center; margin-top: 70px; padding-bottom: 21px"
    class="my-box"
  >
    <div nz-row *ngIf="fogotdata == false">
      <div nz-col nzSpan="24" *ngIf="!isLogedIn">
        <div>
          <img src=".\assets\aykarlogo.png" class="image" />
        </div>
        <div>
          <h4><b> Mumbai </b></h4>
          <h4
            style="color: #208aeb; font-weight: bold; text-transform: uppercase"
          >
            Claim Management System
          </h4>
          <h4 style="text-align: center; color: rgb(134, 132, 132)">
            <b> (Medical/LTC/TA on Tour/Transfer) </b>
          </h4>
        </div>

        <div>
          <form nz-form>
            <nz-form-item>
              <nz-form-control
                nzRequired
                nzErrorTip="Please Enter Valid Email/Mobile Number"
              >
                <nz-input-group
                  nzPrefixIcon="user"
                  style="border-radius: 5px; width: 310px"
                >
                  <input
                    type="email"
                    name="email_id"
                    [(ngModel)]="EMAIL_ID"
                    nz-input
                    placeholder="Email ID/Mobile Number"
                    required
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-control nzRequired nzErrorTip="Input is required">
                <nz-input-group
                  nzPrefixIcon="lock"
                  [nzSuffix]="suffixTemplate"
                  style="border-radius: 5px; width: 310px"
                >
                  <input
                    [type]="passwordVisible ? 'text' : 'password'"
                    name="password"
                    [(ngModel)]="PASSWORD"
                    nz-input
                    placeholder="Password"
                    required
                  />
                </nz-input-group>
                <ng-template #suffixTemplate>
                  <i
                    nz-icon
                    [nzType]="passwordVisible ? 'eye' : 'eye-invisible'"
                    (click)="passwordVisible = !passwordVisible"
                  ></i>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
            <div style="text-align: center">
              <a (click)="showModal()">Forgot Password?</a><br />
            </div>
            <nz-form-item>
              <nz-form-control>
                <div nz-row>
                  <div
                    nz-col
                    nzXs="6"
                    nzSm="6"
                    nzMd="8"
                    nzLg="8"
                    nzXl="8"
                  ></div>
                  <div nz-col nzXs="12" nzSm="12" nzMd="8" nzLg="8" nzXl="8">
                    <button
                      style="margin-top: 10px"
                      nz-button
                      class="wrap-login100-form-btn"
                      (click)="login()"
                      [nzLoading]="isloginSpinning"
                      [nzType]="'primary'"
                    >
                      Log in
                    </button>
                  </div>
                  <div
                    nz-col
                    nzXs="6"
                    nzSm="6"
                    nzMd="8"
                    nzLg="8"
                    nzXl="8"
                  ></div>
                </div>
              </nz-form-control>
            </nz-form-item>
          </form>
          <br />
          <div style="text-align: center; color: rgb(173, 172, 172)">
            <span style="color: grey">Concept by :-</span>Jitendra Chandekar,
            ITO(GPBS)-2, Mumbai
          </div>
        </div>
      </div>
    </div>

    <div nz-row *ngIf="fogotdata == true">
      <div nz-col nzSpan="24">
        <div>
          <img src=".\assets\aykarlogo.png" class="image" />
        </div>
        <div>
          <h4><b> Mumbai </b></h4>
          <h4 style="color: #208aeb; font-weight: bold">
            Gazetted/Non-Gazetted Claim Management System
          </h4>
          <h4 style="text-align: center; color: rgb(134, 132, 132)">
            <b> (Medical/LTC/Tour/Transfer) </b>
          </h4>
        </div>

        <div>
          <form nz-form class="login-form">
            <div *ngIf="!sendOtp && !mobileverified">
              <h4 style="text-align: center">
                Enter Your Registered Mobile Number
              </h4>

              <div nz-row>
                <div nz-col nzXs="2" nzSm="2" nzMd="8" nzLg="6" nzXl="6"></div>
                <div
                  nz-col
                  nzXs="20"
                  nzSm="20"
                  nzMd="8"
                  nzLg="12"
                  nzXl="12"
                  style="text-align: center"
                >
                  <nz-form-item>
                    <nz-form-control nzRequired>
                      <nz-input-group
                        style="
                          text-align: center;
                          border-radius: 5px;
                          width: 100%;
                        "
                      >
                        <input
                          style="border-radius: 5px"
                          nz-input
                          autocomplete="off"
                          (keypress)="omit($event)"
                          placeholder="Mobile Number"
                          maxlength="10"
                          required
                          name="mobile"
                          [(ngModel)]="MOBILE_NO"
                          required
                        />
                      </nz-input-group>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
              <div nz-col nzXs="2" nzSm="2" nzMd="8" nzLg="6" nzXl="6"></div>
            </div>

            <div nz-row>
              <div nz-col nzXs="4" nzSm="4" nzMd="8" nzLg="8" nzXl="8"></div>
              <div nz-col nzXs="16" nzSm="16" nzMd="8" nzLg="8" nzXl="8">
                <div *ngIf="!sendOtp && !mobileverified">
                  <button
                    nz-button
                    style="margin-top: 0px"
                    class="wrap-login100-form-btn"
                    (click)="check()"
                    [nzLoading]="isloginSpinning"
                    [nzType]="'primary'"
                  >
                    <span>Send Otp</span>
                  </button>
                </div>
              </div>
              <div nz-col nzXs="4" nzSm="4" nzMd="8" nzLg="8" nzXl="8"></div>
            </div>

            <div style="text-align: center; margin-top: 10px">
              <a *ngIf="!sendOtp && !mobileverified" href="/login">Cancel</a>
            </div>

            <div *ngIf="sendOtp && !mobileverified && !password">
              <h4 style="text-align: center">Enter Otp</h4>

              <div nz-row>
                <div nz-col nzXs="6" nzSm="6" nzMd="8" nzLg="6" nzXl="6"></div>
                <div
                  nz-col
                  nzXs="12"
                  nzSm="12"
                  nzMd="8"
                  nzLg="12"
                  nzXl="12"
                  style="text-align: center"
                >
                  <nz-form-item>
                    <nz-form-control>
                      <input
                        nz-input
                        name="demo1name"
                        style="text-align: center"
                        placeholder="Enter OTP "
                        autocomplete="off"
                        required
                        (keypress)="omit($event)"
                        (onInputChange)="onOtpChange($event)"
                        [(ngModel)]="OTP"
                        maxlength="6"
                        required
                      />
                      <h4
                        style="
                          font-size: 12px;
                          margin-top: 20px;
                          margin-bottom: 10px;
                          text-align: center;
                        "
                      >
                        Didn't Receive OTP?
                        <a
                          *ngIf="resendTrue && !showTimer"
                          (click)="resendOTP()"
                          style="
                            color: #131952;
                            font-weight: bold;
                            cursor: pointer;
                          "
                        >
                          Resend OTP
                        </a>
                        <a
                          *ngIf="resendTrue && showTimer"
                          style="color: #2b2c33; font-weight: bold"
                        >
                          Resend OTP({{ 180 - timerMobile }})s</a
                        >
                      </h4>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col nzXs="6" nzSm="6" nzMd="8" nzLg="6" nzXl="6"></div>
              </div>
            </div>

            <div nz-row>
              <div nz-col nzXs="4" nzSm="4" nzMd="8" nzLg="8" nzXl="8"></div>
              <div
                nz-col
                nzXs="16"
                nzSm="16"
                nzMd="8"
                nzLg="8"
                nzXl="8"
                style="text-align: center"
              >
                <div *ngIf="sendOtp && !mobileverified && !password">
                  <div>
                    <button
                      nz-button
                      class="wrap-login100-form-btn"
                      style="width: 150px"
                      (click)="varify()"
                      [nzLoading]="isloginSpinning"
                      [nzType]="'primary'"
                    >
                      Verify Otp
                    </button>
                  </div>
                </div>
              </div>
              <div nz-col nzXs="4" nzSm="4" nzMd="8" nzLg="8" nzXl="8"></div>
            </div>

            <div *ngIf="password">
              <h4 style="text-align: center">Enter New Password</h4>

              <div nz-row>
                <div nz-col nzXs="6" nzSm="6" nzMd="8" nzLg="4" nzXl="4"></div>
                <div
                  nz-col
                  nzXs="12"
                  nzSm="12"
                  nzMd="8"
                  nzLg="16"
                  nzXl="16"
                  style="text-align: center"
                >
                  <nz-form-item>
                    <nz-form-control nzRequired>
                      <nz-input-group
                        [nzSuffix]="suffixTemplate1"
                        style="text-align: center; border-radius: 5px"
                      >
                        <input
                          [type]="passwordVisible1 ? 'text' : 'password'"
                          name="password"
                          [(ngModel)]="NEW_PASSWORD"
                          nz-input
                          placeholder="Enter your New password"
                          required
                        />
                      </nz-input-group>
                      <ng-template #suffixTemplate1>
                        <i
                          nz-icon
                          [nzType]="passwordVisible1 ? 'eye' : 'eye-invisible'"
                          (click)="passwordVisible1 = !passwordVisible1"
                        ></i>
                      </ng-template>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col nzXs="6" nzSm="6" nzMd="8" nzLg="4" nzXl="4"></div>
              </div>

              <h4 style="text-align: center">Confirm Password</h4>

              <div nz-row>
                <div nz-col nzXs="6" nzSm="6" nzMd="8" nzLg="4" nzXl="4"></div>

                <div
                  nz-col
                  nzXs="12"
                  nzSm="12"
                  nzMd="8"
                  nzLg="16"
                  nzXl="16"
                  style="text-align: center"
                >
                  <nz-form-item>
                    <nz-form-control nzRequired>
                      <nz-input-group
                        [nzSuffix]="suffixTemplate"
                        style="text-align: center; border-radius: 5px"
                      >
                        <input
                          [type]="passwordVisible ? 'text' : 'password'"
                          name="password"
                          [(ngModel)]="CONFPASSWORD"
                          nz-input
                          placeholder="Enter Confirm Password"
                          required
                        />
                      </nz-input-group>
                      <ng-template #suffixTemplate>
                        <i
                          nz-icon
                          [nzType]="passwordVisible ? 'eye' : 'eye-invisible'"
                          (click)="passwordVisible = !passwordVisible"
                        ></i>
                      </ng-template>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col nzXs="6" nzSm="6" nzMd="8" nzLg="4" nzXl="4"></div>
              </div>
            </div>

            <div nz-row>
              <div nz-col nzXs="4" nzSm="4" nzMd="8" nzLg="8" nzXl="8"></div>

              <div
                nz-col
                nzXs="16"
                nzSm="16"
                nzMd="8"
                nzLg="8"
                nzXl="8"
                style="text-align: center"
              >
                <div *ngIf="password">
                  <button
                    nz-button
                    class="wrap-login100-form-btn"
                    (click)="confpass()"
                    [nzLoading]="isloginSpinning"
                    [nzType]="'primary'"
                  >
                    Confirm Password
                  </button>
                </div>
              </div>
              <div nz-col nzXs="4" nzSm="4" nzMd="8" nzLg="8" nzXl="8"></div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div nz-col nzXs="0" nzSm="0" nzMd="8" nzLg="8" nzXl="8"></div>
</div>

<!-- <div *ngIf="fogotdata == false">
  <div nz-row *ngIf="!isLogedIn">
    <div class="limiter">
      <div class="container-login100" style="background-color: white">
        <div class="wrap-login100">
          <img src=".\assets\aykarlogo.png" class="image" />

          <h4 style="text-align: center"><b> Mumbai </b></h4>

          <h4 style="color: #208aeb; font-weight: bold">
            Gazetted/Non-Gazetted Claim Management System
          </h4>

          <h4 style="text-align: center; color: rgb(134, 132, 132)">
            <b> (Medical/LTC/Tour/Transfer) </b>
          </h4>

          <br />

          <form nz-form class="login-form">
            <nz-form-item style="margin-left: 45px; margin-right: -25px">
              <nz-form-control
                nzRequired
                nzErrorTip="Please Enter Valid Email/Mobile Number"
              >
                <nz-input-group
                  nzPrefixIcon="user"
                  style="border-radius: 5px"
                >
                  <input
                    type="email"
                    name="email_id"
                    [(ngModel)]="EMAIL_ID"
                    nz-input
                    placeholder="Email ID/Mobile Number"
                    required
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
           

            <nz-form-item style="margin-left: 45px; margin-right: -25px">
              <nz-form-control nzRequired nzErrorTip="Input is required">
                <nz-input-group
                  nzPrefixIcon="lock"
                  [nzSuffix]="suffixTemplate"
                  style="border-radius: 5px"
                >
                  <input
                    [type]="passwordVisible ? 'text' : 'password'"
                    name="password"
                    [(ngModel)]="PASSWORD"
                    nz-input
                    placeholder="Password"
                    required
                  />
                </nz-input-group>
                <ng-template #suffixTemplate>
                  <i
                    nz-icon
                    [nzType]="passwordVisible ? 'eye' : 'eye-invisible'"
                    (click)="passwordVisible = !passwordVisible"
                  ></i>
                </ng-template>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-control>
                <a class="login-form-forgot" (click)="showModal()"
                  >Forgot Password?</a
                ><br />

                <div class="container-login100-form-btn">
                  <div class="wrap-login100-form-btn">
                    <div class="login100-form-bgbtn"></div>
                    <button
                      nz-button
                      class="login100-form-btn"
                      (click)="login()"
                      [nzLoading]="isloginSpinning"
                      [nzType]="'primary'"
                    >
                      Log in
                    </button>
                  </div>
                </div>
              </nz-form-control>
            </nz-form-item>
          </form>
          <br />
          <div style="text-align: center; color: rgb(173, 172, 172)">
            <span style="color: grey">Concept by :-</span>Jitendra
            Chandekar, ITO(GPBS)-2, Mumbai
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div nz-row style="padding-left: 480px" *ngIf="fogotdata == true">
  <div class="limiter">
    <div class="container-login100" style="background-color: white">
      <div class="wrap-login100">
        <h4 style="text-align: center; margin-bottom: 2px">Welcome</h4>
        <h4 style="text-align: center; margin-bottom: 2px">To</h4>
        <h3 style="text-align: center; color: #208aeb; font-weight: bold">
          Medical Claim Management System
        </h3>

        <form nz-form class="login-form">
          <div *ngIf="!sendOtp && !mobileverified">
            <nz-form-item>
             
            </nz-form-item>
            <h4 style="text-align: center">
              Enter Your Registered Mobile Number
            </h4>

            <nz-form-item>
              <nz-form-control nzRequired>
                <nz-input-group>
                  <input
                    nz-input
                    autocomplete="off"
                    (keypress)="omit($event)"
                    style="text-align: center"
                    placeholder="Mobile Number"
                    maxlength="10"
                    required
                    name="mobile"
                    [(ngModel)]="MOBILE_NO"
                    class="form-control"
                    x-webkit-speech="x-webkit-speech"
                    required
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
          
          <div
            class="container-login100-form-btn"
            *ngIf="!sendOtp && !mobileverified"
          >
            <div class="wrap-login100-form-btn">
              <div class="login100-form-bgbtn"></div>
              <button
                nz-button
                class="login100-form-btn"
                style="font-family: Poppins-Medium"
                (click)="check()"
                [nzLoading]="isloginSpinning"
                [nzType]="'primary'"
              >
                <span style="font-family: Poppins-Medium">Send Otp</span>
              </button>
            </div>
          </div>

          <a
            style="text-align: center; margin-left: 116px"
            *ngIf="!sendOtp && !mobileverified"
            href="/login"
            >Cancel</a
          >

          

          <div *ngIf="sendOtp && !mobileverified && !password">
            <h4 style="text-align: center">Enter Otp</h4>
            <nz-form-item>
              <nz-form-control>
                <input
                  nz-input
                  name="demo1name"
                  style="text-align: center"
                  autocomplete="off"
                  required
                  (keypress)="omit($event)"
                  (onInputChange)="onOtpChange($event)"
                  [(ngModel)]="OTP"
                  maxlength="6"
                  required
                />
                <h4
                  style="
                    font-size: 12px;
                    margin-top: 20px;
                    margin-bottom: 10px;
                    text-align: center;
                  "
                >
                  Didn't recive OTP?
                  <a
                    *ngIf="resendTrue && !showTimer"
                    (click)="resendOTP()"
                    style="
                      color: #131952;
                      font-weight: bold;
                      cursor: pointer;
                    "
                  >
                    Resend OTP
                  </a>
                  <a
                    *ngIf="resendTrue && showTimer"
                    style="color: #2b2c33; font-weight: bold"
                  >
                    Resend OTP({{ 180 - timerMobile }})s</a
                  >
                </h4>

               
              </nz-form-control>
            </nz-form-item>
          </div>

          <div
            class="container-login100-form-btn"
            *ngIf="sendOtp && !mobileverified && !password"
          >
            <div class="wrap-login100-form-btn">
              <div class="login100-form-bgbtn"></div>
              <button
                nz-button
                class="login100-form-btn"
                style="font-family: Poppins-Medium"
                (click)="varify()"
                [nzLoading]="isloginSpinning"
                [nzType]="'primary'"
              >
                Verify Otp
              </button>
            </div>
          </div>
        
          <div *ngIf="password">
            <h4 style="text-align: center">Enter New Password</h4>

            <nz-form-item>
              <nz-form-control nzRequired>
                <nz-input-group [nzSuffix]="suffixTemplate1">
                  <input
                    [type]="passwordVisible1 ? 'text' : 'password'"
                    style="text-align: center"
                    name="password"
                    [(ngModel)]="NEW_PASSWORD"
                    nz-input
                    placeholder="Enter your New password"
                    required
                  />
                </nz-input-group>
                <ng-template #suffixTemplate1>
                  <i
                    nz-icon
                    [nzType]="passwordVisible1 ? 'eye' : 'eye-invisible'"
                    (click)="passwordVisible1 = !passwordVisible1"
                  ></i>
                </ng-template>
              </nz-form-control>
            </nz-form-item>

            <h4 style="text-align: center">Confirm Password</h4>

            <nz-form-item>
              <nz-form-control nzRequired>
                <nz-input-group [nzSuffix]="suffixTemplate">
                  <input
                    [type]="passwordVisible ? 'text' : 'password'"
                    name="password"
                    style="text-align: center"
                    [(ngModel)]="CONFPASSWORD"
                    nz-input
                    placeholder="Enter Confirm Password"
                    required
                  />
                </nz-input-group>
                <ng-template #suffixTemplate>
                  <i
                    nz-icon
                    [nzType]="passwordVisible ? 'eye' : 'eye-invisible'"
                    (click)="passwordVisible = !passwordVisible"
                  ></i>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="container-login100-form-btn" *ngIf="password">
            <div class="wrap-login100-form-btn">
              <div class="login100-form-bgbtn"></div>
              <button
                nz-button
                class="login100-form-btn"
                style="font-family: Poppins-Medium"
                (click)="confpass()"
                [nzLoading]="isloginSpinning"
                [nzType]="'primary'"
              >
                Confirm Password
              </button>
            </div>
          </div>

          
        </form>
      </div>
    </div>
  </div>
</div> -->
