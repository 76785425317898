<div>
  <nz-steps [nzCurrent]="current" style="margin: 0px 50px">
    <nz-step nzTitle="Applicant Information"></nz-step>
    <nz-step nzTitle="Claim Information"></nz-step>
  </nz-steps>

  <div class="steps-content">
    <div *ngIf="current == 0">
      <div>
        <form nz-form #applicantMasterPages="ngForm">
          <nz-spin [nzSpinning]="isSpinning">
            <div nz-row>
              <!-- <div nz-col nzSpan="8">
                                <nz-form-item>
                                    <nz-form-label nzRequired nzNoColon>Employee Name</nz-form-label>
                                    <nz-form-control nzHasFeedback nzErrorTip="Please Select/Enter Employee Name ">
                                        <input nz-input maxlength="512" name="empname" style="width: 100%"
                                            [(ngModel)]="data.NAME" nz-input placeholder="Please Select/Enter Employee"
                                            draggable="false" required (ngModelChange)="onChange($event)"
                                            [nzAutocomplete]="auto" />
                                        <nz-autocomplete #auto>
                                            <nz-auto-option *ngFor="let role of filteredOptions" [nzValue]="role.NAME"
                                                [nzLabel]="role.NAME">{{ role.NAME }}</nz-auto-option>
                                        </nz-autocomplete>
                                    </nz-form-control>
                                </nz-form-item>
                            </div> -->

              <div nz-col nzSpan="8">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Employee Name</nz-form-label
                  >
                  <nz-form-control nzErrorTip="Please Enter Employee Name ">
                    <input
                      nz-input
                      maxlength="256"
                      autocomplete="off"
                      name="Dessdsdignation"
                      type="text"
                      [(ngModel)]="data.NAME"
                      placeHolder="Enter The Employee Name"
                      required
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="8">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Designation</nz-form-label
                  >
                  <nz-form-control nzErrorTip=" Please Enter Designation">
                    <input
                      nz-input
                      maxlength="256"
                      autocomplete="off"
                      name="Designation"
                      type="text"
                      [(ngModel)]="data.DESIGNATION"
                      placeHolder="Enter The Designation"
                      required
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="8">
                <nz-form-item>
                  <nz-form-label nzNoColon>Office Name</nz-form-label>
                  <nz-form-control nzErrorTip="Enter Correct Office Name">
                    <input
                      nz-input
                      autocomplete="off"
                      maxlength="256"
                      name="Office Name"
                      type="text"
                      [(ngModel)]="data.OFFICE_NAME"
                      placeHolder="Enter The Office Name"
                      required
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="8">
                <nz-form-item>
                  <nz-form-label nzNoColon>DDO Of The Official</nz-form-label>
                  <nz-form-control
                    nzErrorTip=" Please Enter Correct DDO Of The Official"
                  >
                    <input
                      nz-input
                      maxlength="256"
                      autocomplete="off"
                      name="DDOOfficial"
                      type="text"
                      [(ngModel)]="data.DDO_OF_THE_OFFICIAL"
                      placeHolder="Enter The DDO Of The Official"
                      required
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="8">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Employee Code</nz-form-label
                  >

                  <nz-form-control
                    nzErrorTip=" Please Enter Correct Employee Code"
                  >
                    <input
                      nz-input
                      maxlength="16"
                      autocomplete="off"
                      name="EmployeeCode"
                      [(ngModel)]="data.EMPLOYEE_CODE"
                      type="text"
                      placeHolder="Enter Employee Code"
                      required
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="8">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>Basic Pay</nz-form-label>

                  <nz-form-control nzErrorTip=" Please Enter Correct Basic Pay">
                    <nz-input-group style="width: 100%" nzAddOnBefore="₹">
                      <input
                        nz-input
                        maxlength="8"
                        autocomplete="off"
                        (keypress)="omit($event)"
                        [(ngModel)]="data.GRADE_PAY"
                        name="gradePay"
                        type="text"
                        placeHolder="Enter Employee Basic Pay"
                        required
                      />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="8">
                <nz-form-item>
                  <nz-form-label nzNoColon>Email ID </nz-form-label>
                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Enter Valid Email ID"
                  >
                    <input
                      nz-input
                      name="commiteeemail"
                      maxlength="256"
                      [pattern]="emailpattern"
                      [(ngModel)]="data.EMAIL_ID"
                      placeHolder="Enter Email ID"
                      required
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="8">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>
                    Mobile Number
                  </nz-form-label>
                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Enter Valid Mobile Number"
                  >
                    <input
                      nz-input
                      name="commiteemobileno"
                      [pattern]="mobpattern"
                      [(ngModel)]="data.MOBILE_NO"
                      maxlength="10"
                      minlength="10"
                      (keypress)="omit($event)"
                      placeHolder="Enter Mobile Number"
                      required
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="8">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Beneficiary Type
                  </nz-form-label>
                  <nz-form-control>
                    <nz-radio-group
                      name="yesno"
                      [(ngModel)]="data.BENEFICIARY_TYPE"
                      required
                    >
                      <label nz-radio nzValue="CG">CGHS </label>
                      <label nz-radio nzValue="CS">CS(MA)</label>
                    </nz-radio-group>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="16">
                <div nz-row>
                  <div nz-col nzSpan="24">
                    <nz-form-item>
                      <nz-form-label nzNoColon>
                        Detailed Address Of Employee
                      </nz-form-label>
                      <nz-form-control
                        nzHasFeedback
                        nzErrorTip="Please Enter Valid Address"
                      >
                        <textarea
                          nz-input
                          style="width: 100%"
                          maxlength="256"
                          rows="4"
                          name="contactaddress"
                          [(ngModel)]="data.ADDRESS"
                          placeholder="Enter Detailed Address"
                          required
                        ></textarea>
                        <h6
                          style="color: red"
                          *ngIf="data.ADDRESS != undefined"
                        >
                          {{ data.ADDRESS.length }}/256
                        </h6>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
              </div>

              <div nz-col nzSpan="8">
                <div nz-row>
                  <div nz-col nzSpan="24" *ngIf="data.BENEFICIARY_TYPE == 'CG'">
                    <nz-form-item>
                      <nz-form-label nzRequired nzNoColon
                        >CGHS Card Number</nz-form-label
                      >
                      <nz-form-control
                        nzHasFeedback
                        nzErrorTip="Please Enter Correct CGHS Card Number"
                      >
                        <input
                          nz-input
                          name="CGHScardnumber"
                          maxlength="16"
                          [(ngModel)]="data.CGHS_CARD_NO"
                          placeHolder="Enter CGHS Card Number"
                          required
                        />
                      </nz-form-control>
                    </nz-form-item>
                  </div>

                  <div nz-col nzSpan="24" *ngIf="data.BENEFICIARY_TYPE == 'CG'">
                    <nz-form-item>
                      <nz-form-label nzNoColon
                        >CGHS Card Validity</nz-form-label
                      >
                      <nz-form-control
                        nzErrorTip=" Please Enter Correct CGHS Card Validity"
                      >
                        <nz-date-picker
                          style="width: 100%"
                          nzFormat="dd/MM/yyyy"
                          name="CGHScardvalidity"
                          [(ngModel)]="data.CGHS_CARD_VALIDITY"
                          required
                        ></nz-date-picker>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
              </div>
            </div>
          </nz-spin>
        </form>
      </div>
    </div>
    <!-- Applicant Info -->

    <!-- Claim Info -->
    <div *ngIf="current == 1">
      <form nz-form #claimMasterPage="ngForm">
        <nz-spin [nzSpinning]="isSpinning">
          <div nz-row class="my-box">
            <div nz-col nzSpan="6">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon
                  >Relationship with Applicant</nz-form-label
                >
                <nz-form-control
                  nzErrorTip="Please Select Correct Relationship with Applicant"
                >
                  <!-- <input nz-input autocomplete="off" name="Relation" type="text"
                      [(ngModel)]="data2.RELATION_WITH_Applicant" placeHolder="Enter Relation" required /> -->
                  <nz-select
                    nzShowSearch
                    nzAllowClear
                    nzPlaceHolder="Select Relation with Applicant"
                    [(ngModel)]="data2.RELATION_WITH_PATIENT"
                    (ngModelChange)="relationshipFilter($event)"
                    name="relation"
                    required
                  >
                    <!-- <nz-option *ngFor="let relatives of relativeArray;" [nzValue]="relatives"
                      [nzLabel]="relatives"></nz-option> -->
                    <nz-option nzLabel="Self" nzValue="Self"></nz-option>
                    <nz-option nzLabel="Mother" nzValue="Mother"></nz-option>
                    <nz-option nzLabel="Father" nzValue="Father"></nz-option>
                    <nz-option nzLabel="Brother" nzValue="Brother"></nz-option>
                    <nz-option nzLabel="Sister" nzValue="Sister"></nz-option>
                    <nz-option
                      nzLabel="Daughter"
                      nzValue="Daughter"
                    ></nz-option>
                    <nz-option nzLabel="Son" nzValue="Son"></nz-option>
                    <nz-option nzLabel="Wife" nzValue="Wife"></nz-option>
                    <!-- <nz-option
                      nzLabel="Grand-Mother"
                      nzValue="Grand-Mother"
                    ></nz-option>
                    <nz-option
                      nzLabel="Grand-Father"
                      nzValue="Grand-Father"
                    ></nz-option> -->
                    <nz-option
                      nzLabel="Father-In-Law"
                      nzValue="Father-In-Law"
                    ></nz-option>
                    <nz-option
                      nzLabel="Mother-In-Law"
                      nzValue="Mother-In-Law"
                    ></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="10">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Patient Name</nz-form-label>
                <nz-form-control nzErrorTip="Please Enter Correct Patient Name">
                  <input
                    nz-input
                    autocomplete="off"
                    maxlength="128"
                    name="PatientName"
                    type="text"
                    [(ngModel)]="data2.PATIENT_NAME"
                    placeHolder="Enter Patient Name"
                    required
                  />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>
                  Patient CGHS Beneficiary Number</nz-form-label
                >

                <nz-form-control
                  nzErrorTip="Please Enter Correct Patient CGHS Beneficiary Number"
                >
                  <!-- <nz-input-group style="width: 100%" nzAddOnBefore="₹"> -->
                  <input
                    nz-input
                    autocomplete="off"
                    maxlength="16"
                    [(ngModel)]="data2.PATIENT_CGHS_BENEFICIERY_NO"
                    name="cghsnumber"
                    type="text"
                    placeHolder="Enter Patient CGHS Beneficiary Number"
                    required
                  />
                  <!-- </nz-input-group> -->
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="16">
              <div nz-row>
                <div nz-col nzSpan="24">
                  <nz-form-item>
                    <nz-form-label nzRequired nzNoColon
                      >Nature Of Treatment/Test</nz-form-label
                    >
                    <nz-form-control
                      nzErrorTip="Please Enter Correct Nature Of Treatment/Test"
                    >
                      <textarea
                        nz-input
                        [nzAutosize]="{ minRows: 4, maxRows: 6 }"
                        name="NatureOfTreatment"
                        placeHolder="Enter a Nature Of Treatment/Test"
                        [(ngModel)]="data2.NATURE_OF_TREATMENT"
                        maxlength="512"
                        required
                      ></textarea>
                      <h6
                        style="color: red"
                        *ngIf="data2.NATURE_OF_TREATMENT != undefined"
                      >
                        {{ data2.NATURE_OF_TREATMENT.length }}/512
                      </h6>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
            </div>

            <div nz-col nzSpan="8">
              <div nz-row>
                <div nz-col nzSpan="24">
                  <nz-form-item>
                    <nz-form-label nzRequired nzNoColon
                      >Treatment Start Date</nz-form-label
                    >
                    <nz-form-control
                      nzErrorTip="Please Select Treatment Start Date"
                    >
                      <nz-date-picker
                        style="width: 100%"
                        nzFormat="dd/MM/yyyy"
                        (ngModelChange)="disableBillDate()"
                        [(ngModel)]="data2.TREATMENT_START_DATE"
                        name="TreatmentStartDate"
                        (ngModelChange)="treatEndDate()"
                        required
                      ></nz-date-picker>
                    </nz-form-control>
                  </nz-form-item>
                </div>

                <div nz-col nzSpan="24">
                  <nz-form-item>
                    <nz-form-label nzRequired nzNoColon
                      >Treatment End Date</nz-form-label
                    >
                    <nz-form-control
                      nzErrorTip="Please Select Treatment End Date"
                    >
                      <nz-date-picker
                        style="width: 100%"
                        [nzDisabledDate]="disabledDate2"
                        [(ngModel)]="data2.TREATMENT_END_DATE"
                        nzFormat="dd/MM/yyyy"
                        name="TreatmentEndDate"
                        required
                      ></nz-date-picker>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
            </div>
          </div>

          <div nz-row class="my-box">
            <div nz-col nzSpan="6">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>
                  Bill Filling Date
                </nz-form-label>
                <nz-form-control nzErrorTip="Please Select Bill Filling Date">
                  <nz-date-picker
                    style="width: 100%"
                    nzFormat="dd/MM/yyyy"
                    name="billfilldate"
                    [(ngModel)]="data2.BILL_FILIING_DATE"
                    [nzDisabledDate]="disabledDate2"
                    required
                  ></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="6">
              <nz-form-item>
                <nz-form-label nzNoColon>Is Advance Taken?</nz-form-label>
                <nz-form-control>
                  <nz-switch
                    [(ngModel)]="data2.IS_ADVANCE_TAKEN"
                    name="AdvanceTaken"
                    nzCheckedChildren="Yes"
                    nzUnCheckedChildren="No"
                    (ngModelChange)="isAdvanceTaken($event)"
                  >
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="6" *ngIf="data2.IS_ADVANCE_TAKEN == true">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon
                  >Advance Amount</nz-form-label
                >
                <nz-form-control
                  nzHasFeedback
                  nzErrorTip="Please Enter Advance Amount"
                >
                  <nz-input-group nzAddOnBefore="₹">
                    <input
                      nz-input
                      name="AdvanceAmount"
                      maxlength="8"
                      type="number"
                      [(ngModel)]="data2.ADVANCE_AMOUNT"
                      placeHolder="Enter Advance Amount"
                      required
                    />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="6" *ngIf="data2.IS_ADVANCE_TAKEN == true">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon
                  >Date Of Advance Taken</nz-form-label
                >
                <nz-form-control
                  nzErrorTip="Please Select Date Of Advance Taken"
                >
                  <nz-date-picker
                    style="width: 100%"
                    nzFormat="dd/MM/yyyy"
                    name="dateCGHSAMASreferences"
                    [(ngModel)]="data2.ADVANCE_TAKEN_DATE"
                    required
                  ></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </nz-spin>
      </form>

      <!-- <div nz-row class="my-box">
           

            <div nz-col nzSpan="6">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Hospital Type</nz-form-label>


                <nz-form-control nzHasFeedback nzErrorTip="Please Select Hospital Type">
                  <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Please Select Hospital Type " name="hospitaltype"
                    [(ngModel)]="data2.HOSPITAL_TYPE" (ngModelChange)="hospitalType($event)" required>
                    <nz-option nzValue="E" nzLabel="Empanelled"></nz-option>
                    <nz-option nzValue="NE" nzLabel="Non Empanelled"></nz-option>
                    <nz-option nzValue="G" nzLabel="Government"></nz-option>

                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="16" *ngIf="
                      data2.HOSPITAL_TYPE == undefined ||
                      data2.HOSPITAL_TYPE == null ||
                      data2.HOSPITAL_TYPE == ''
                    "></div>

            <div nz-col nzSpan="18" *ngIf="data2.HOSPITAL_TYPE == 'E'">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Hospital Name</nz-form-label>
                <nz-form-control nzHasFeedback nzErrorTip="Please Select Correct Hospital Name">
                  <nz-select name="role_name" style="width: 100%" [(ngModel)]="data2.HOSPITAL_ID" nzShowSearch
                    nzPlaceHolder="Select Hospital Name" (ngModelChange)="hospitalAddress($event)">
                    <nz-option *ngFor="let role of hospitalList" [nzValue]="role.ID" nzLabel="{{ role.NAME }}" required>
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="18" *ngIf="data2.HOSPITAL_TYPE == 'NE' || data2.HOSPITAL_TYPE == 'G'">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Hospital Name</nz-form-label>
                <nz-form-control nzHasFeedback nzErrorTip="Please Enter Correct Hospital Name">
                  <input nz-input name="hospitalname" maxlength="256" [(ngModel)]="data2.HOSPITAL_NAME"
                    placeHolder="Enter Hospital Name" required />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="16">
              <div nz-row>
                <div nz-col nzSpan="24" *ngIf="
                          data2.HOSPITAL_TYPE == undefined ||
                          data2.HOSPITAL_TYPE == ''
                        "></div>
                <div nz-col nzSpan="24" *ngIf="data2.HOSPITAL_TYPE == 'E'">
                  <nz-form-item>
                    <nz-form-label nzNoColon> Hospital Address </nz-form-label>
                    <nz-form-control nzHasFeedback nzErrorTip="Please Enter Valid Hospital Address">
                      <textarea [disabled]="true" maxlength="256" nz-input style="width: 100%" rows="2"
                        name="contactaddress" [(ngModel)]="data2.HOSPITAL_ADDRESS" required></textarea>
                      <h6 style="color: red" *ngIf="data2.HOSPITAL_ADDRESS != undefined">
                        {{ data2.HOSPITAL_ADDRESS.length }}/256
                      </h6>
                    </nz-form-control>
                  </nz-form-item>
                </div>

                <div nz-col nzSpan="24" *ngIf="
                          data2.HOSPITAL_TYPE == 'NE' || data2.HOSPITAL_TYPE == 'G'
                        ">
                  <nz-form-item>
                    <nz-form-label nzNoColon> Hospital Address </nz-form-label>
                    <nz-form-control nzHasFeedback nzErrorTip="Please Enter Valid Hospital Address">
                      <textarea nz-input style="width: 100%" maxlength="256" rows="3" name="contactaddress2"
                        [(ngModel)]="data2.HOSPITAL_ADDRESS" required></textarea>
                      <h6 style="color: red" *ngIf="data2.HOSPITAL_ADDRESS != undefined">
                        {{ data2.HOSPITAL_ADDRESS.length }}/256
                      </h6>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
            </div>

            <div nz-col nzSpan="8">
              <div nz-row>
                <div nz-col nzSpan="24" *ngIf="
                          data2.HOSPITAL_TYPE == 'NE' || data2.HOSPITAL_TYPE == 'G'
                        ">
                  <nz-form-item>
                    <nz-form-label nzRequired nzNoColon>Accreditation</nz-form-label>

                    <nz-form-control nzHasFeedback nzErrorTip="Please Select Accreditation">
                    <input nz-input autocomplete="off" name="accreditation" type="text"
                                  placeHolder="Enter The Accreditation" [(ngModel)]="data.ACCREDITATION" required />
                             
                      <nz-select name="accreditation" [(ngModel)]="data2.CLAIM_ACCREDITATION" nzShowSearch nzAllowClear
                        nzPlaceHolder="Select Accreditation" required>
                        <nz-option nzValue="N" nzLabel="NABH"></nz-option>
                        <nz-option nzValue="NN" nzLabel="Non NABH"></nz-option>
                        <nz-option nzValue="SP" nzLabel="Super Speciality"></nz-option>
                       
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>

                <div nz-col nzSpan="24">
                  <nz-form-item>
                    <nz-form-label nzRequired nzNoColon>
                      Bill Filling Date
                    </nz-form-label>
                    <nz-form-control nzErrorTip="Please Select Bill Filling Date">
                      <nz-date-picker style="width: 100%" nzFormat="dd/MM/yyyy" name="billfilldate"
                        [(ngModel)]="data2.BILL_FILIING_DATE" [nzDisabledDate]="disabledDate2"
                        required></nz-date-picker>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
            </div>
          </div> -->

      <form nz-form #form11="ngForm">
        <nz-spin [nzSpinning]="isSpinning">
          <div nz-row class="my-box">
            <div nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon
                  >Hospital Type</nz-form-label
                >

                <nz-form-control
                  nzHasFeedback
                  nzErrorTip="Please Select Hospital Type"
                >
                  <nz-select
                    nzShowSearch
                    nzAllowClear
                    nzPlaceHolder="Please Select Hospital Type "
                    name="hospitaltype"
                    [(ngModel)]="data5.TYPE"
                    (ngModelChange)="hospitalType($event)"
                    required
                  >
                    <nz-option nzValue="E" nzLabel="Empanelled"></nz-option>
                    <nz-option
                      nzValue="NE"
                      nzLabel="Non Empanelled"
                    ></nz-option>
                    <nz-option nzValue="G" nzLabel="Government"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div
              nz-col
              nzSpan="16"
              *ngIf="
                data5.TYPE == undefined ||
                data5.TYPE == null ||
                data5.TYPE == ''
              "
            ></div>

            <div nz-col nzSpan="16" *ngIf="data5.TYPE == 'E'">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon
                  >Hospital Name</nz-form-label
                >
                <nz-form-control
                  nzHasFeedback
                  nzErrorTip="Please Select Correct Hospital Name"
                >
                  <nz-select
                    name="role_name"
                    style="width: 100%"
                    [(ngModel)]="data5.HOSPITAL_ID"
                    nzShowSearch
                    nzPlaceHolder="Select Hospital Name"
                    (ngModelChange)="hospitalAddress($event)"
                  >
                    <nz-option
                      *ngFor="let role of hospitalList"
                      [nzValue]="role.ID"
                      nzLabel="{{ role.NAME }}"
                      required
                    >
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div
              nz-col
              nzSpan="16"
              *ngIf="data5.TYPE == 'NE' || data5.TYPE == 'G'"
            >
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon
                  >Hospital Name</nz-form-label
                >
                <nz-form-control
                  nzHasFeedback
                  nzErrorTip="Please Enter Correct Hospital Name"
                >
                  <input
                    nz-input
                    name="hospitalname"
                    maxlength="256"
                    [(ngModel)]="data5.NAME"
                    placeHolder="Enter Hospital Name"
                    required
                  />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="15">
              <div nz-row>
                <div
                  nz-col
                  nzSpan="24"
                  *ngIf="data5.TYPE == undefined || data5.TYPE == ''"
                ></div>
                <div nz-col nzSpan="24" *ngIf="data5.TYPE == 'E'">
                  <nz-form-item>
                    <nz-form-label nzNoColon> Hospital Address </nz-form-label>
                    <nz-form-control
                      nzHasFeedback
                      nzErrorTip="Please Enter Valid Hospital Address"
                    >
                      <textarea
                        [disabled]="true"
                        maxlength="256"
                        nz-input
                        style="width: 100%"
                        rows="3"
                        name="contactaddress"
                        [(ngModel)]="data5.ADDRESS"
                        required
                      ></textarea>
                      <h6 style="color: red" *ngIf="data5.ADDRESS != undefined">
                        {{ data5.ADDRESS.length }}/256
                      </h6>
                    </nz-form-control>
                  </nz-form-item>
                </div>

                <div
                  nz-col
                  nzSpan="24"
                  *ngIf="data5.TYPE == 'NE' || data5.TYPE == 'G'"
                >
                  <nz-form-item>
                    <nz-form-label nzNoColon> Hospital Address </nz-form-label>
                    <nz-form-control
                      nzHasFeedback
                      nzErrorTip="Please Enter Valid Hospital Address"
                    >
                      <textarea
                        nz-input
                        style="width: 100%"
                        maxlength="256"
                        rows="3"
                        name="contactaddress2"
                        [(ngModel)]="data5.ADDRESS"
                        required
                      ></textarea>
                      <h6 style="color: red" *ngIf="data5.ADDRESS != undefined">
                        {{ data5.ADDRESS.length }}/256
                      </h6>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
            </div>

            <div nz-col nzSpan="8">
              <div nz-row>
                <div
                  nz-col
                  nzSpan="24"
                  *ngIf="data5.TYPE == 'NE' || data5.TYPE == 'G'"
                >
                  <nz-form-item>
                    <nz-form-label nzRequired nzNoColon
                      >Accreditation</nz-form-label
                    >

                    <nz-form-control
                      nzHasFeedback
                      nzErrorTip="Please Select Accreditation"
                    >
                      <!-- <input nz-input autocomplete="off" name="accreditation" type="text"
                                          placeHolder="Enter The Accreditation" [(ngModel)]="data.ACCREDITATION" required />
                                       -->
                      <nz-select
                        name="accreditation"
                        [(ngModel)]="data5.ACCREDATION"
                        nzShowSearch
                        nzAllowClear
                        nzPlaceHolder="Select Accreditation"
                        required
                      >
                        <nz-option nzValue="N" nzLabel="NABH"></nz-option>
                        <nz-option nzValue="NN" nzLabel="Non NABH"></nz-option>
                        <nz-option
                          nzValue="SP"
                          nzLabel="Super Speciality"
                        ></nz-option>
                        <!-- <nz-option *ngFor="let option of accreditation" [nzLabel]="option" [nzValue]="option"></nz-option> -->
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col nzSpan="24" *ngIf="data5.TYPE == 'E'">
                  <nz-form-item>
                    <nz-form-label nzRequired nzNoColon
                      >Accreditation</nz-form-label
                    >

                    <nz-form-control
                      nzHasFeedback
                      nzErrorTip="Please Select Accreditation"
                    >
                      <!-- <input nz-input autocomplete="off" name="accreditation" type="text"
                                          placeHolder="Enter The Accreditation" [(ngModel)]="data.ACCREDITATION" required />
                                       -->
                      <nz-select
                        name="accreditation"
                        [(ngModel)]="data5.ACCREDATION"
                        nzShowSearch
                        nzAllowClear
                        nzPlaceHolder="Select Accreditation"
                        required
                        disabled="true"
                      >
                        <nz-option nzValue="N" nzLabel="NABH"></nz-option>
                        <nz-option nzValue="NN" nzLabel="Non NABH"></nz-option>
                        <nz-option
                          nzValue="SP"
                          nzLabel="Super Speciality"
                        ></nz-option>
                        <!-- <nz-option *ngFor="let option of accreditation" [nzLabel]="option" [nzValue]="option"></nz-option> -->
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
            </div>

            <div
              nz-col
              nzSpan="1"
              *ngIf="
                data5.TYPE == 'NE' || data5.TYPE == 'G' || data5.TYPE == 'E'
              "
            >
              <div nz-row>
                <nz-form-item>
                  <nz-form-label nzNoColon></nz-form-label>
                  <nz-form-control>
                    <button
                      nz-button
                      nzType="primary"
                      [nzLoading]="loadingRecords"
                      (click)="addData(true, form11)"
                    >
                      <i nz-icon nzType="plus"></i>
                    </button>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>

            <!-- <div nz-col nzSpan="4">

              <nz-form-item>

                <nz-form-label nzNoColon></nz-form-label>
                <button nz-button nzType="primary" [nzLoading]="loadingRecords" (click)="addData(true,form11)"
                  nzJustify="end">
                  <i nz-icon nzType="plus"></i>
                </button>

              </nz-form-item>
            </div> -->
          </div>

          <div nz-row>
            <div nz-col nzSpan="24">
              <nz-table
                class="my-scroll no-wrap1"
                #newtabledata
                [nzData]="Hospitalclaim"
                nzBordered
                nzFrontPagination="false"
                [nzScroll]="{ x: '1400px', y: '250px' }"
              >
                <thead>
                  <tr>
                    <th nzAlign="center" nzWidth="20px">
                      <p style="font-weight: bold">Action</p>
                    </th>

                    <th nzAlign="center" nzWidth="60px">
                      <p style="font-weight: bold">Hospital Type</p>
                    </th>
                    <th nzAlign="center" nzWidth="150px">
                      <b> Hostpital Name </b>
                    </th>

                    <th nzAlign="center" nzWidth="150px">
                      <p style="font-weight: bold">Hostpital Address</p>
                    </th>

                    <th nzAlign="center" nzWidth="45px">
                      <b> Accreditation</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of newtabledata.data; let i = index">
                    <td style="text-align: center">
                      <span
                        style="cursor: pointer"
                        nz-popconfirm
                        nzPopconfirmTitle="Are you sure want delete?"
                        nzPopconfirmPlacement="top"
                        (nzOnConfirm)="confirmDeleteHospital(data)"
                        (nzOnCancel)="cancel()"
                      >
                        <span
                          nz-icon
                          nzType="delete"
                          nzTheme="twotone"
                          nz-tooltip
                          nzTooltipTitle="Delete"
                        >
                        </span>
                      </span>
                      &nbsp;
                      <a
                        (click)="edit(data, i)"
                        nz-tooltip
                        nzTooltipTitle="Edit"
                      >
                        <span
                          nz-icon
                          nzType="edit"
                          nzTheme="twotone"
                          style="margin-top: 10px"
                        ></span>
                      </a>
                    </td>

                    <td style="text-align: center">
                      <span nz-icon *ngIf="Hospitalclaim[i]['TYPE'] == 'E'"
                        >Empanelled</span
                      >
                      <span nz-icon *ngIf="Hospitalclaim[i]['TYPE'] == 'NE'"
                        >Non Empanelled</span
                      >
                      <span nz-icon *ngIf="Hospitalclaim[i]['TYPE'] == 'G'"
                        >Government</span
                      >
                    </td>
                    <td style="text-align: center; white-space: pre-line">
                      {{ Hospitalclaim[i]["NAME"] }}
                    </td>
                    <td style="text-align: center; white-space: pre-line">
                      {{ Hospitalclaim[i]["ADDRESS"] }}
                    </td>
                    <td style="text-align: center">
                      <span
                        nz-icon
                        *ngIf="Hospitalclaim[i]['ACCREDATION'] == 'N'"
                        >NABH</span
                      >
                      <span
                        nz-icon
                        *ngIf="Hospitalclaim[i]['ACCREDATION'] == 'NN'"
                        >Non NABH</span
                      >
                      <span
                        nz-icon
                        *ngIf="Hospitalclaim[i]['ACCREDATION'] == 'SP'"
                        >Super Speciality</span
                      >
                    </td>
                  </tr>
                </tbody>
              </nz-table>
            </div>
          </div>
        </nz-spin>
      </form>

      <div nz-row class="my-box">
        <div nz-col nzSpan="8">
          <nz-form-item>
            <nz-form-label nzNoColon> Name Of The Bank</nz-form-label>
            <nz-form-control
              nzHasFeedback
              nzErrorTip="Please Enter Valid Name Of The Bank"
            >
              <input
                nz-input
                name="bankname"
                maxlength="256"
                [(ngModel)]="data2.BANK_NAME"
                placeHolder="Enter Name Of The Bank"
                required
              />
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="8">
          <nz-form-item>
            <nz-form-label nzNoColon> SB Account Number</nz-form-label>
            <nz-form-control
              nzHasFeedback
              nzErrorTip="Please Enter Valid SB Account Number"
            >
              <input
                nz-input
                name="accnumber"
                maxlength="128"
                [(ngModel)]="data2.BANK_ACCOUNT_NO"
                placeHolder="Enter SB Account Number"
                required
              />
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="8">
          <nz-form-item>
            <nz-form-label nzNoColon> Branch IFSC Code </nz-form-label>
            <nz-form-control
              nzHasFeedback
              nzErrorTip="Please Enter Valid Branch IFSC Code"
            >
              <input
                nz-input
                name="branchcode"
                maxlength="32"
                [(ngModel)]="data2.IFSC_CODE"
                placeHolder="Enter SB Account Number"
                required
              />
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="8">
          <nz-form-item>
            <nz-form-label nzNoColon> Branch MICR Code </nz-form-label>
            <nz-form-control
              nzHasFeedback
              nzErrorTip="Please Enter Valid Branch MICR Code"
            >
              <input
                nz-input
                name="micrcode"
                maxlength="32"
                [(ngModel)]="data2.MICR_CODE"
                placeHolder="Enter Branch MICR Code"
                required
              />
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="8">
          <nz-form-item>
            <nz-form-label nzNoColon>Ward Entitlement </nz-form-label>
            <nz-form-control>
              <nz-radio-group
                name="wardentitlement"
                [(ngModel)]="data2.WARD_TYPE"
              >
                <label nz-radio nzValue="P">Pvt. </label>
                <label nz-radio nzValue="S">Semi Pvt.</label>
                <label nz-radio nzValue="G">General</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="8">
          <nz-form-item>
            <nz-form-label nzNoColon> Treatment Type </nz-form-label>
            <nz-form-control>
              <nz-checkbox-wrapper style="width: 100%">
                <div nz-row>
                  <div nz-col nzSpan="24">
                    <label
                      nz-checkbox
                      (nzCheckedChange)="onItemChecked($event)"
                      name="treattype2"
                      [(ngModel)]="TREATEMENT_TYPE1"
                      >OPD Treatment / Test Entitled</label
                    >
                  </div>
                  <div nz-col nzSpan="24">
                    <label
                      nz-checkbox
                      (nzCheckedChange)="onItemChecked2($event)"
                      name="treattype3"
                      [(ngModel)]="TREATEMENT_TYPE2"
                      >Indoor Treatment</label
                    >
                  </div>
                </div>
              </nz-checkbox-wrapper>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>

    <div class="footer">
      <span>
        <button
          [nzLoading]="isSpinning"
          *ngIf="current == 0"
          nzType="primary"
          class="ant-btn ant-btn-primary"
          nz-button
          (click)="empSave(false, applicantMasterPages)"
        >
          <span>Save Information. & Next </span>
        </button>
      </span>

      <span>
        <button
          [nzLoading]="isSpinning"
          *ngIf="current == 1"
          nzType="primary"
          class="ant-btn ant-btn-primary"
          nz-button
          (click)="claimSave(false, claimMasterPage, 'V')"
        >
          <span>Submit For Verification</span>
        </button>
      </span>

      <span>
        <button
          [nzLoading]="isSpinning"
          *ngIf="current == 1"
          nzType="primary"
          class="ant-btn ant-btn-primary"
          nz-button
          (click)="claimSave(false, claimMasterPage, 'S')"
        >
          <span>Save & Close</span>
        </button>
      </span>

      <button
        type="button"
        nz-button
        (click)="close()"
        class="ant-btn"
        style="margin-right: 8px"
      >
        <span>Cancel</span>
      </button>
    </div>
  </div>

  <div class="header">
    <button
      type="button"
      nz-button
      (click)="close()"
      class="ant-btn"
      style="margin-right: 8px"
    >
      <i nz-icon nzType="close" nzTheme="outline"></i>
    </button>
  </div>
</div>
