import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { EmployeeMaster } from 'src/app/Models/Employee';
import { Tourmaster } from 'src/app/Models/Tourmaster';
import { Checklisttour } from 'src/app/Models/checklisttour';
import { ClaimMaster } from 'src/app/Models/claimmaster';
import { Deatailsandpurposeoftours } from 'src/app/Models/deatailsandpurposetour';
import { foodtable } from 'src/app/Models/foodaddd';
import { HospitalMappingMaster } from 'src/app/Models/hospitalmappingmaster';
import { Joureyofparticularcity } from 'src/app/Models/journeydetailsbycity';
import { Joureyofparticulartours } from 'src/app/Models/journeyofparticulartours';
import { Particularhotels } from 'src/app/Models/particulardetailsofhotel';
import { QuestionaryMaster } from 'src/app/Models/questionarymaster';
import { TranferapplicationMaster } from 'src/app/Models/transferapplication';
import { ApiService } from 'src/app/Service/api.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-addtourmaster',
  templateUrl: './addtourmaster.component.html',
  styleUrls: ['./addtourmaster.component.css'],
})
export class AddtourmasterComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() isSpinning = false;
  @Input() data: Tourmaster;
  @Input() data2: Deatailsandpurposeoftours;
  @Input() current = 0;
  @Input() empID: any;
  @Input() tourID: any;
  @Input() editdata: any;

  isOk = true;
  namepatt = /[a-zA-Z][a-zA-Z ]+/;
  filteredOptions = [];
  employee: EmployeeMaster[] = [];
  empLoader: boolean = false;
  @Input() detailsandpurpose: any = [];
  @Input() particularofhotelsdata: any = [];
  @Input() particularoftours: any = [];
  particularofcity: any = [];
  modedata: any[] = [];
  pageIndex = 1;
  pageSize = 10;
  loadingRecords: boolean = false;
  totalRecords = 1;
  classdata: any[] = [];
  AMOUNT: number;
  gradpaylevel: any;
  userId: any;
  show = true;
  // data2: Deatailsandpurposeoftours = new Deatailsandpurposeoftours();
  data3: Checklisttour = new Checklisttour();
  constructor(
    private message: NzNotificationService,
    private api: ApiService,
    private datepipe: DatePipe,
    public cookie: CookieService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.userId = Number(sessionStorage.getItem('userId'));
    this.current = 0;
    if (this.current == 0) {
      this.allEmployeeList();
    } else {
      this.api.gettravelmode(0, 0, '', '', ' AND STATUS=1').subscribe(
        (data) => {
          if (data['code'] == 200) {
            this.modedata = data['data'];
          }
        },
        (err) => {
          console.log(err);
        }
      );

      this.api.gettravelclass(0, 0, '', '', ' AND STATUS=1').subscribe(
        (data) => {
          if (data['code'] == 200) {
            this.classdata = data['data'];
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  drawerTitledetailsofjourney!: string;
  drawerVisibledetailsofjourney: boolean = false;
  drawerDatadetailsofjourney: Deatailsandpurposeoftours =
    new Deatailsandpurposeoftours();

  detailsofjourney(): void {
    this.drawerTitledetailsofjourney = 'Add Details Charges';
    this.drawerDatadetailsofjourney = new Deatailsandpurposeoftours();
    this.gradpaylevel = this.data.GRADE_PAY_LEVEL;
    this.drawerVisibledetailsofjourney = true;
  }

  drawerClosedetailsofjourney(): void {
    this.getdeatilssofpurpose();
    this.drawerVisibledetailsofjourney = false;
  }

  get closeCallbackdetailsofjourney() {
    return this.drawerClosedetailsofjourney.bind(this);
  }

  editdetailsndpurpose(data: Deatailsandpurposeoftours): void {
    this.drawerTitledetailsofjourney = 'Edit Journey Details';
    this.gradpaylevel = this.data.GRADE_PAY_LEVEL;
    this.drawerDatadetailsofjourney = Object.assign({}, data);
    this.drawerVisibledetailsofjourney = true;
  }

  getdeatilssofpurpose() {
    this.loadingRecords = true;

    this.api
      .getdeatilssofpurpose(0, 0, '', 'asc', ' AND TOUR_ID =' + this.tourID)
      .subscribe((data) => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.detailsandpurpose = data['data'];
        }
      });
  }

  drawerTitleparticularofhotels!: string;
  drawerVisibleparticularofhotels: boolean = false;
  drawerDataparticularofhotels: Particularhotels = new Particularhotels();

  particularofhotels(): void {
    this.drawerTitleparticularofhotels = 'Add Particular of Hotels Details';
    this.drawerDataparticularofhotels = new Particularhotels();
    this.gradpaylevel = this.data.GRADE_PAY_LEVEL;
    this.drawerVisibleparticularofhotels = true;
  }
  drawerTitlefood: any;
  drawerDatafood: any;
  maxAdmissibleAmount: any = 0;

  foodopen(): void {
    this.drawerTitlefood = 'Add Food Bill';
    this.drawerDatafood = new foodtable();
    this.gradpaylevel = this.data.GRADE_PAY_LEVEL;
    this.maxAdmissibleAmount = 0;
    this.drawerVisiblefood = true;
  }
  drawerVisiblefood = false;
  drawerClosefood(): void {
    this.gettoursfood();
    this.drawerVisiblefood = false;
  }

  get closeCallbackfood() {
    return this.drawerClosefood.bind(this);
  }
  editfood(data: foodtable): void {
    this.drawerTitlefood = 'Edit Food Bill Details';
    this.gradpaylevel = this.data.GRADE_PAY_LEVEL;
    this.drawerDatafood = Object.assign({}, data);
    this.drawerDatafood.START_TIME = new Date('01-01-2000 ' + data.START_TIME);
    this.drawerDatafood.END_TIME = new Date('01-01-2000 ' + data.END_TIME);
    if (this.drawerDatafood.IS_LONG_JOURNEY == true) {
      var date1: any = new Date(this.drawerDatafood.JOURNEY_END_DATE);
      var date2: any = new Date(this.drawerDatafood.EXPENSE_DATE);
      var days1 = Math.ceil(Math.abs(date1 - date2) / (1000 * 60 * 60 * 24));
      var days = days1 + 1;

      if (
        this.gradpaylevel == 'Level 6' ||
        this.gradpaylevel == 'Level 7' ||
        this.gradpaylevel == 'Level 8'
      ) {
        this.maxAdmissibleAmount = 500 * Number(days);
      } else if (
        this.gradpaylevel == 'Level 9' ||
        this.gradpaylevel == 'Level 10' ||
        this.gradpaylevel == 'Level 11'
      ) {
        this.maxAdmissibleAmount = 800 * Number(days);
      } else if (
        this.gradpaylevel == 'Level 12' ||
        this.gradpaylevel == 'Level 13'
      ) {
        this.maxAdmissibleAmount = 1000 * Number(days);
      } else if (this.gradpaylevel >= 'Level 14') {
        this.maxAdmissibleAmount = 1200 * Number(days);
      }
    } else {
      this.maxAdmissibleAmount = 0;
    }
    this.drawerVisiblefood = true;
  }

  drawerCloseparticularofhotels(): void {
    this.gettoursparticularhotel();
    this.drawerVisibleparticularofhotels = false;
  }

  get closeCallbackparticularofhotels() {
    return this.drawerCloseparticularofhotels.bind(this);
  }

  // editparticularsofhotels(data: Particularhotels): void {
  //   this.drawerTitleparticularofhotels = 'Edit Particular of Hotels Details';
  //   this.drawerDataparticularofhotels = Object.assign({}, data);
  //   this.drawerVisibleparticularofhotels = true;
  // }
  editparticularsofhotels(data: Particularhotels): void {
    this.drawerTitleparticularofhotels = 'Edit Particular of Hotels Details';
    this.gradpay();
    this.drawerDataparticularofhotels = Object.assign({}, data);
    this.drawerVisibleparticularofhotels = true;
  }

  gradpay() {
    this.gradpaylevel = this.data.GRADE_PAY_LEVEL;
  }
  gettoursparticularhotel() {
    this.loadingRecords = true;
    this.api
      .gettoursparticularhotel(0, 0, '', 'asc', ' AND TOUR_ID =' + this.tourID)
      .subscribe((data) => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.particularofhotelsdata = data['data'];
        }
      });
  }
  fooddata: any;
  gettoursfood() {
    // this.loadingRecords = true;
    this.api
      .gettoursFood(0, 0, '', 'asc', ' AND TOUR_ID =' + this.tourID)
      .subscribe((data) => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.fooddata = data['data'];
        }
      });
  }

  gettourchecklist() {
    this.loadingRecords = true;
    this.api
      .getTourChecklist(0, 0, '', 'asc', ' AND TOUR_ID =' + this.tourID)
      .subscribe((data) => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.data3 = data['data'];
        }
      });
  }

  drawerTitlejourneyparticulartours!: string;
  drawerVisiblejourneyparticulartours: boolean = false;
  drawerDatajourneyparticulartours: Joureyofparticulartours =
    new Joureyofparticulartours();

  journeyofparticular(): void {
    this.drawerTitlejourneyparticulartours = 'Add Journey of Particular';
    this.gradpaylevel = this.data.GRADE_PAY_LEVEL;

    this.drawerDatajourneyparticulartours = new Joureyofparticulartours();
    this.drawerVisiblejourneyparticulartours = true;
  }

  drawerClosejourneyparticulartours(): void {
    this.drawerVisiblejourneyparticulartours = false;
    this.prticulartours();
  }

  get closeCallbackjourneyparticulartours() {
    return this.drawerClosejourneyparticulartours.bind(this);
  }

  editjourneyofparticular(data: any): void {
    this.gradpaylevel = this.data.GRADE_PAY_LEVEL;

    this.drawerTitlejourneyparticulartours = 'Edit Journey of Particular';
    this.drawerVisiblejourneyparticulartours = true;
    this.drawerDatajourneyparticulartours = Object.assign({}, data);
    // this.api
    //   .getjourneyofparticulartours(0, 0, '', '', ' AND TOUR_ID =' +
    //     this.tourID +
    //     ' AND ID=' +
    //     data.ID)
    //   .subscribe(
    //     (data) => {
    //       if (data['code'] == 200) {

    //         this.drawerDatajourneyparticulartours = Object.assign({}, data['data'][0]);

    //       }

    //     },
    //     (err) => {
    //       console.log(err);
    //     }
    //   );
  }

  prticulartours() {
    this.loadingRecords = true;
    if (this.tourID != undefined && this.tourID != null && this.tourID != '') {
      this.api
        .getjourneyofparticulartours(
          0,
          0,
          '',
          'asc',
          ' AND TOUR_ID =' + this.tourID
        )
        .subscribe((data) => {
          if (data['code'] == 200) {
            this.loadingRecords = false;
            this.particularoftours = data['data'];
          }
        });
    } else {
    }
  }

  drawerTitlejourneycity!: string;
  drawerVisiblejourneycity: boolean = false;
  drawerDatajourneycity: Joureyofparticularcity = new Joureyofparticularcity();

  journeyofparticularcity(): void {
    this.drawerTitlejourneycity = 'Add Transportion Charges';
    this.drawerDatajourneycity = new Joureyofparticularcity();
    this.drawerVisiblejourneycity = true;
  }

  drawerClosejourneyofparticularcity(): void {
    this.getjourneyofparticularcity();
    this.drawerVisiblejourneycity = false;
  }

  get closeCallbackcityback() {
    return this.drawerClosejourneyofparticularcity.bind(this);
  }

  editdrawerClosejourneyofparticularcity(data: Joureyofparticularcity): void {
    this.drawerTitlejourneycity = 'Edit Particular of City';
    this.drawerDatajourneycity = Object.assign({}, data);
    this.drawerVisiblejourneycity = true;
  }

  getjourneyofparticularcity() {
    this.api
      .getjourneyofparticularcity(
        0,
        0,
        '',
        'asc',
        ' AND TOUR_ID =' + this.tourID
      )
      .subscribe((data) => {
        if (data['code'] == 200) {
          this.particularofcity = data['data'];
        }
      });
  }

  filterEmpData(event: any) {
    this.empLoader = true;
    if (event != null) {
      this.api.getEmployeeMaster(0, 0, '', '', ' AND ID =' + event).subscribe(
        (data) => {
          if (data['code'] == 200) {
            this.empLoader = false;
            this.data.ID = data['data'][0]['ID'];

            this.data.DESIGNATION = data['data'][0]['DESIGNATION'];
            // this.data.HEADQUARTERS_NAME = data['data'][0]['OFFICE_NAME'];

            this.data.BASIC_PAY = data['data'][0]['GRADE_PAY'];
            this.data.MOBILE_NO = data['data'][0]['MOBILE_NO'];

            this.data.EMPLOYEE_NAME = data['data'][0]['NAME'];
          }
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      this.empLoader = false;
      this.data.ID = null;
      this.data.DESIGNATION = '';
      this.data.HEADQUARTERS_NAME = '';
      this.data.MOBILE_NO = '';

      this.data.BASIC_PAY = '';
      this.data.EMPLOYEE_NAME = '';
    }
  }
  omit(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  empAllDataForUpdate: any;
  isPrevious: boolean = false;
  empSave(addNew: boolean, claimMasterPage: NgForm): void {
    this.isSpinning = false;
    this.isOk = true;
    // this.data.journeyData = this.detailsandpurpose;
    var data2 = [];
    // for (i = 0; i < this.data.journeyData.length; i++) {
    //   this.data.journeyData[i]['TRAVEL_CLASS_NAME'] = undefined;
    //   this.data.journeyData[i]['TRAVEL_MODE_NAME'] = undefined;
    //   this.data.journeyData[i]['ADVANCE_TAKEN_AMOUNT'] = undefined;
    //   this.data.journeyData[i]['CREATED_DATE_TIME'] = undefined;
    //   this.data.journeyData[i]['EMPLOYEE_NAME'] = undefined;
    //   this.data.journeyData[i]['EMP_ID'] = undefined;
    //   this.data.journeyData[i]['IS_ADVANCE_TAKEN'] = undefined;
    //   // this.data.journeyData[i][''] = undefined
    //   // this.data.journeyData[i][''] = undefined
    // }

    if (
      this.data.EMP_ID == undefined &&
      this.data.DESIGNATION == undefined &&
      this.data.BASIC_PAY == undefined &&
      this.data.GRADE_PAY_LEVEL == undefined &&
      this.data.MOBILE_NO == undefined &&
      this.data.HEADQUARTERS_NAME == undefined
    ) {
      this.isOk = false;
      this.message.error('Please Fill All The Required Fields ', '');
    } else if (
      this.data.EMP_ID == undefined ||
      this.data.EMP_ID == null ||
      this.data.EMP_ID == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Select Employee Name', '');
    } else if (
      this.data.DESIGNATION == undefined ||
      this.data.DESIGNATION == null ||
      this.data.DESIGNATION == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Designation', '');
    } else if (
      this.data.BASIC_PAY == undefined ||
      this.data.BASIC_PAY == null ||
      this.data.BASIC_PAY == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Basic Pay', '');
    } else if (
      this.data.HEADQUARTERS_NAME == undefined ||
      this.data.HEADQUARTERS_NAME == null ||
      this.data.HEADQUARTERS_NAME == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Headqurter Name', '');
    } else if (
      this.data.MOBILE_NO == undefined ||
      this.data.MOBILE_NO == null ||
      this.data.MOBILE_NO == 0
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Mobile No', '');
    } else if (
      this.data.GRADE_PAY_LEVEL == undefined ||
      this.data.GRADE_PAY_LEVEL == null ||
      this.data.GRADE_PAY_LEVEL == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Select Grade Pay Level', '');
    }

    if (this.isOk) {
      // if (
      //   this.data.journeyData == undefined ||
      //   this.data.journeyData.length == 0
      // ) {
      // } else {
      //   for (var i = 0; this.detailsandpurpose.length > i; i++) {
      //     this.detailsandpurpose[i]['ID'] = undefined;
      //   }
      //   this.data.journeyData = this.detailsandpurpose;
      // }

      this.isSpinning = true;
      if (
        this.data.HEADQUARTERS_NAME == undefined ||
        this.data.HEADQUARTERS_NAME == null ||
        this.data.HEADQUARTERS_NAME == '' ||
        this.data.HEADQUARTERS_NAME.trim() == ''
      ) {
        this.data.HEADQUARTERS_NAME = null;
      }
      {
        if (this.data.ID) {
          if (
            (this.empID != undefined ||
              this.empID != null ||
              this.empID != '') &&
            this.empID == this.data.EMP_ID
          ) {
            this.empAllDataForUpdate = {
              ID: this.empID,
              CLIENT_ID: 1,
              EMP_ID: this.empID,
              TOUR_ID: this.data.ID,
              EMPLOYEE_NAME: this.data.EMPLOYEE_NAME,
              DESIGNATION: this.data.DESIGNATION,
              BASIC_PAY: this.data.BASIC_PAY,
              MOBILE_NO: this.data.MOBILE_NO,
              AMOUNT_OF_T_A: this.data.AMOUNT_OF_T_A,
              GRADE_PAY_LEVEL: this.data.GRADE_PAY_LEVEL,
              HEADQUARTERS_NAME: this.data.HEADQUARTERS_NAME,
              DEPARTURE_FROM: this.data2.DEPARTURE_FROM,
              ARRIVAL_DATETIME: this.data2.ARRIVAL_DATETIME,
              ARRIVAL_TO: this.data2.ARRIVAL_TO,
              TRAVEL_MODE_ID: this.data2.TRAVEL_MODE_ID,
              TRAVEL_CLASS_ID: this.data2.TRAVEL_CLASS_ID,
              HOURS_OF_HALT: this.data2.HOURS_OF_HALT,
              DAYS_OF_HALT: this.data2.DAYS_OF_HALT,
              FARE_PAID: this.data2.FARE_PAID,
              DISTANCE_IN_KM_FOR_ROAD: this.data2.DISTANCE_IN_KM_FOR_ROAD,
              IS_RETURNED: this.data2.IS_RETURNED,
              // journeyData: this.data.journeyData,
              STATUS: true,
              INSPECTOR_ID: Number(sessionStorage.getItem('userId')),
              ADESH_REASON: this.data.ADESH_REASON,
              SIGNATURE_ID: this.data.SIGNATURE_ID,
              TOUR_STATUS: this.data.TOUR_STATUS,
            };
          } else if (this.isPrevious == true) {
            this.empAllDataForUpdate = {
              ID: this.data.ID,
              CLIENT_ID: 1,
              EMP_ID: this.data.EMP_ID,
              TOUR_ID: this.data.ID,
              EMPLOYEE_NAME: this.data.EMPLOYEE_NAME,
              DESIGNATION: this.data.DESIGNATION,
              AMOUNT_OF_T_A: this.data.AMOUNT_OF_T_A,
              BASIC_PAY: this.data.BASIC_PAY,
              MOBILE_NO: this.data.MOBILE_NO,
              GRADE_PAY_LEVEL: this.data.GRADE_PAY_LEVEL,
              HEADQUARTERS_NAME: this.data.HEADQUARTERS_NAME,
              DEPARTURE_FROM: this.data2.DEPARTURE_FROM,
              ARRIVAL_DATETIME: this.data2.ARRIVAL_DATETIME,
              ARRIVAL_TO: this.data2.ARRIVAL_TO,
              TRAVEL_MODE_ID: this.data2.TRAVEL_MODE_ID,
              IS_RETURNED: this.data2.IS_RETURNED,
              TRAVEL_CLASS_ID: this.data2.TRAVEL_CLASS_ID,
              HOURS_OF_HALT: this.data2.HOURS_OF_HALT,
              DAYS_OF_HALT: this.data2.DAYS_OF_HALT,
              FARE_PAID: this.data2.FARE_PAID,
              DISTANCE_IN_KM_FOR_ROAD: this.data2.DISTANCE_IN_KM_FOR_ROAD,
              // journeyData: this.data.journeyData,
              INSPECTOR_ID: Number(sessionStorage.getItem('userId')),
              ADESH_REASON: this.data.ADESH_REASON,
              SIGNATURE_ID: this.data.SIGNATURE_ID,
              TOUR_STATUS: this.data.TOUR_STATUS,
            };
          } else {
            this.empAllDataForUpdate = {
              ID: this.data.ID,
              CLIENT_ID: 1,
              EMP_ID: this.data.EMP_ID,
              TOUR_ID: null,
              EMPLOYEE_NAME: this.data.EMPLOYEE_NAME,
              DESIGNATION: this.data.DESIGNATION,
              AMOUNT_OF_T_A: this.data.AMOUNT_OF_T_A,
              BASIC_PAY: this.data.BASIC_PAY,
              MOBILE_NO: this.data.MOBILE_NO,
              GRADE_PAY_LEVEL: this.data.GRADE_PAY_LEVEL,
              HEADQUARTERS_NAME: this.data.HEADQUARTERS_NAME,
              DEPARTURE_FROM: this.data2.DEPARTURE_FROM,
              ARRIVAL_DATETIME: this.data2.ARRIVAL_DATETIME,
              ARRIVAL_TO: this.data2.ARRIVAL_TO,
              TRAVEL_MODE_ID: this.data2.TRAVEL_MODE_ID,
              IS_RETURNED: this.data2.IS_RETURNED,
              TRAVEL_CLASS_ID: this.data2.TRAVEL_CLASS_ID,
              HOURS_OF_HALT: this.data2.HOURS_OF_HALT,
              DAYS_OF_HALT: this.data2.DAYS_OF_HALT,
              FARE_PAID: this.data2.FARE_PAID,
              DISTANCE_IN_KM_FOR_ROAD: this.data2.DISTANCE_IN_KM_FOR_ROAD,
              // journeyData: this.data.journeyData,
              INSPECTOR_ID: Number(sessionStorage.getItem('userId')),
              ADESH_REASON: this.data.ADESH_REASON,
              SIGNATURE_ID: this.data.SIGNATURE_ID,
              TOUR_STATUS: this.data.TOUR_STATUS,
            };
          }
        }

        {
          if (this.data.ID) {
            // this.data['TOUR_ID'] = this.tourID;
            this.isSpinning = true;
            this.api
              .updatetourmasterform(this.empAllDataForUpdate)
              .subscribe((successCode) => {
                if (successCode.code == '200') {
                  this.message.success('Information Saved Successfully...', '');

                  this.empID = successCode.EMP_ID;
                  this.tourID = successCode.TOUR_ID;
                  this.next();
                  this.isSpinning = false;
                } else {
                  this.message.error('Information Has Not Saved...', '');
                  this.isSpinning = false;
                }
              });
          } else {
            this.isSpinning = true;
            this.api
              .createEmployeeMaster(this.data)
              .subscribe((successCode) => {
                if (successCode.code == '200') {
                  this.message.success('Information Save Successfully...', '');
                  this.empID = successCode.EMP_ID;
                  this.tourID = successCode.TOUR_ID;
                  this.next();
                  this.isSpinning = false;
                } else {
                  this.message.error('Failed To Fill Information...', '');
                  this.isSpinning = false;
                }
              });
          }
        }
      }
    }
  }
  fileURL1: any;

  showfoodtable(event: any) {
    // if (event == false) {
    //   this.data.TRAVELLED_M_E_OT = '';
    // }
  }

  resetDrawer(hospitalFormReset: NgForm) {
    // this.hospitalData = new HospitalMappingMaster();
    // this.data.PARENT_GROUP_ID=this.parentgroup;
    // hospitalFormReset.form.markAsPristine();
    // hospitalFormReset.form.markAsUntouched();
  }
  onlynumdot(event: any) {
    event = event ? event : window.event;
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 46 || charCode > 57)) {
      return false;
    }
    return true;
  }
  pre(): void {
    this.isPrevious = true;
    if (this.current == 1) {
      this.isSpinning = true;
      // this.getdeatilssofpurpose();
      this.allEmployeeList();
      this.api
        .gettouralldata(0, 0, '', '', ' AND ID =' + this.tourID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              if (data['data'].length == 0) {
                // this.current -= 1;
                // this.isSpinning = false;
              } else {
                this.data = data['data'][0];
                // this.current -= 1;
                // this.isSpinning = false;
              }

              this.api
                .getdeatilssofpurpose(
                  0,
                  0,
                  '',
                  '',
                  ' AND TOUR_ID = ' + this.tourID
                )
                .subscribe(
                  (data) => {
                    if (data['code'] == 200) {
                      this.detailsandpurpose = data['data'];
                      this.isSpinning = false;
                      this.current = 0;
                    } else {
                      this.message.error('Something Went Wrong', '');
                      this.isSpinning = false;
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
            } else {
              this.message.error('Something Went Wrong', '');
              // this.isSpinning = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else if (this.current == 2) {
      this.isSpinning = true;
      this.getdeatilssofpurpose();
      this.api
        .gettouralldata(0, 0, '', '', ' AND ID =' + this.tourID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              if (data['data'].length == 0) {
                this.data = data['data'][0];
                this.current = 1;
                this.isSpinning = false;
              } else {
                this.data = data['data'][0];
                this.current = 1;
                this.isSpinning = false;
              }
            } else {
              this.message.error('Something Went Wrong', '');
              this.isSpinning = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );

      this.isSpinning = false;
    } else if (this.current == 3) {
      this.isSpinning = true;
      this.getjourneyofparticularcity();
      this.gettoursparticularhotel();
      this.gettoursfood();
      this.prticulartours();
      this.api
        .gettouralldata(0, 0, '', '', ' AND ID =' + this.tourID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              this.data = data['data'][0];
              this.current = 2;
              this.isSpinning = false;
              // if (data['data'].length == 0) {
              //   this.data = data['data'][0];
              //   this.current -= 1;
              //   this.isSpinning = false;
              // } else {
              //   this.data = data['data'][0];
              //   this.current -= 1;
              //   this.isSpinning = false;
              // }
            } else {
              this.message.error('Something Went Wrong', '');
              this.isSpinning = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );

      this.isSpinning = false;
    }
    if (this.current == 4) {
      this.isSpinning = true;
      this.api
        .gettouralldata(0, 0, '', '', ' AND ID =' + this.tourID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              if (data['data'].length == 0) {
                this.data = data['data'][0];
                this.current -= 1;
                this.isSpinning = false;
              } else {
                this.data = data['data'][0];
                this.current -= 1;
                this.isSpinning = false;
              }
            } else {
              this.message.error('Something Went Wrong', '');
              this.isSpinning = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );

      this.isSpinning = false;
    } else {
      this.isSpinning = true;
      this.current -= 1;
      this.isSpinning = false;
    }
  }

  // signature(event: any) {
  //   var f = this.Signaturearray.filter((item) => item.ID == event);
  //   this.SECTION_TYPE = f[0]['SECTION_TYPE'];
  //   this.SIGNNAME = f[0]['NAME'];
  //   this.NAME_HN = f[0]['NAME_HN'];
  //   this.OFFICE_NAME = f[0]['OFFICE_NAME'];
  //   this.OFFICE_NAME_HN = f[0]['OFFICE_NAME_HN'];
  //   this.POST = f[0]['POST'];
  //   this.POST_HN = f[0]['POST_HN'];
  // }
  travelclass: any;
  travelid: any;
  getclassmode(event: any) {
    this.classdata = [];

    this.api
      .gettravelclass(0, 0, '', '', ' AND STATUS=1 AND MODE_ID = ' + event)
      .subscribe(
        (data: any) => {
          if (data['code'] == 200) {
            this.classdata = data['data'];
            var f = this.classdata.filter((item) => item.ID == event);
            this.travelclass = f[0]['TRAVEL_CLASS_ID'];
            this.travelid = f[0]['TRAVEL_MODE_ID'];
            let name;
            name = this.modedata.filter((val) => {
              if (val.ID == event) {
                return val.NAME;
              }
            });
            this.data2.TRAVEL_MODE_NAME = name[0].NAME;
          }
        },
        (err: any) => {
          console.log(err);
        }
      );
  }

  selecteddata(data: any) {}

  selecteddatamode(data1: any) {}

  advanceTaken: any = 0;
  foodAmountClaimed: any = 0;
  railTicket: boolean = false;
  airTicket: boolean = false;
  roadTicket: boolean = false;
  bordingPass: boolean = false;
  hotelBill: boolean = false;
  next() {
    if (this.current == 0) {
      this.isSpinning = true;
      this.getdeatilssofpurpose();
      this.api
        .gettouralldata(0, 0, '', '', ' AND ID =' + this.tourID)
        .subscribe(
          (data) => {
            this.data = new Tourmaster();
            if (data['code'] == 200) {
              if (data['data'].length == 0) {
                this.current = 1;
                this.isSpinning = false;
              } else {
                this.data = data['data'][0];
                this.current = 1;
                this.isSpinning = false;
              }
            } else {
              // this.message.error('Something Went Wrong', '');
              this.isSpinning = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else if (this.current == 1) {
      this.isSpinning = true;
      this.advanceTaken = 0;
      this.foodAmountClaimed = 0;
      this.getjourneyofparticularcity();
      this.gettoursparticularhotel();
      this.gettoursfood();
      this.prticulartours();
      this.api
        .gettouralldata(0, 0, '', '', ' AND ID =' + this.tourID)
        .subscribe(
          (data) => {
            this.data = new Tourmaster();
            if (data['code'] == 200) {
              if (data['data']['IS_ADVANCE_TAKEN'] == 1) {
                this.advanceTaken = 1;
              } else {
                this.advanceTaken = 0;
              }
              if (data['data']['FOOD_BILL_CLAIM'] == 1) {
                this.foodAmountClaimed = 1;
              } else {
                this.foodAmountClaimed = 0;
              }
              if (data['data'].length == 0) {
                this.current = 2;
                this.isSpinning = false;
              } else {
                this.data = data['data'][0];
                this.current = 2;
                this.isSpinning = false;
              }
            } else {
              // this.message.error('Something Went Wrong', '');
              this.isSpinning = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else if (this.current == 2) {
      this.api
        .gettouralldata(0, 0, '', '', ' AND ID =' + this.tourID)
        .subscribe(
          (data) => {
            this.data = new Tourmaster();
            if (data['code'] == 200) {
              if (data['data']['IS_ADVANCE_TAKEN'] == 1) {
                this.advanceTaken = 1;
              } else {
                this.advanceTaken = 0;
              }
              if (data['data']['FOOD_BILL_CLAIM'] == 1) {
                this.foodAmountClaimed = 1;
              } else {
                this.foodAmountClaimed = 0;
              }
              if (data['data'].length == 0) {
                this.current = 3;
                this.isSpinning = false;
              } else {
                this.data = data['data'][0];
                this.current = 3;
                this.isSpinning = false;
              }
            } else {
              // this.message.error('Something Went Wrong', '');
              this.isSpinning = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else if (this.current == 3) {
      this.fileNumberList();
      this.railTicket = false;
      this.airTicket = false;
      this.bordingPass = false;
      this.roadTicket = false;
      this.hotelBill = false;

      this.api
        .gettouralldata(0, 0, '', '', ' AND ID =' + this.tourID)
        .subscribe(
          (data1) => {
            if (data1['code'] == 200) {
              this.data = data1['data'][0];
              this.api
                .getTourChecklist(
                  0,
                  0,
                  '',
                  'asc',
                  ' AND TOUR_ID =' + this.tourID
                )
                .subscribe((data) => {
                  if (data['code'] == 200) {
                    this.isSpinning = false;
                    if (data['count'] == 0) {
                      this.data3 = new Checklisttour();
                      if ((this.advanceTaken = 1)) {
                        this.data3.T_A_ADVANCE_TAKEN = true;
                      } else {
                        this.data3.T_A_ADVANCE_TAKEN =
                          this.data3.T_A_ADVANCE_TAKEN;
                      }

                      if (this.detailsandpurpose.length > 0) {
                        for (
                          var i = 0;
                          this.detailsandpurpose.length > i;
                          i++
                        ) {
                          // if (
                          //   this.detailsandpurpose[i]['TRAVEL_MODE_ID'] == 4 &&
                          //   this.detailsandpurpose[i]['TICKET_FROM'] == 1
                          // ) {
                          //   this.railTicket = true;
                          // } else {
                          //   this.railTicket = false;
                          // }
                          if (
                            this.detailsandpurpose[i]['TRAVEL_MODE_ID'] == 5 &&
                            this.detailsandpurpose[i]['TICKET_FROM'] == 1
                          ) {
                            this.airTicket = true;
                          } else {
                            this.airTicket = false;
                          }
                        }
                        if (this.airTicket == true) {
                          this.data3.TICKET_BOOKED_THROUGH_TRAVEL_AGENT = true;
                        } else {
                          this.data3.TICKET_BOOKED_THROUGH_TRAVEL_AGENT = false;
                        }
                      } else {
                        this.data3.TICKET_BOOKED_THROUGH_TRAVEL_AGENT = false;
                      }

                      if ((this.foodAmountClaimed = 1)) {
                        this.data3.FOOD_EXPENSES_CLAIMED = true;
                      } else {
                        this.data3.FOOD_EXPENSES_CLAIMED =
                          this.data3.FOOD_EXPENSES_CLAIMED;
                      }
                      if (
                        this.data.TICKET_EXCHANGE_VOUCHER_ARRANGED_BY ==
                        'DC (Admin)'
                      ) {
                        this.data3.TICKET_BOOKED_THROUGH_TRAVEL_DESK = 'Y';
                      } else if (
                        this.data.TICKET_EXCHANGE_VOUCHER_ARRANGED_BY ==
                          undefined &&
                        this.data.TICKET_EXCHANGE_VOUCHER_ARRANGED_BY == null &&
                        this.data.TICKET_EXCHANGE_VOUCHER_ARRANGED_BY == ''
                      ) {
                        this.data3.TICKET_BOOKED_THROUGH_TRAVEL_DESK = 'NA';
                      } else {
                        this.data3.TICKET_BOOKED_THROUGH_TRAVEL_DESK = 'N';
                      }
                      if (
                        this.data.S_R_61 != null &&
                        this.data.S_R_61 != undefined &&
                        this.data.S_R_61 != ''
                      ) {
                        this.data3.S_R_61_SUBMITTED = true;
                      } else {
                        this.data3.S_R_61_SUBMITTED =
                          this.data3.S_R_61_SUBMITTED;
                      }
                      if (
                        this.data.BANK_MANDATE_FORM != null &&
                        this.data.BANK_MANDATE_FORM != undefined &&
                        this.data.BANK_MANDATE_FORM != ''
                      ) {
                        this.data3.BANK_MANDATE_FORM_SUBMITTED = true;
                      } else {
                        this.data3.BANK_MANDATE_FORM_SUBMITTED =
                          this.data3.BANK_MANDATE_FORM_SUBMITTED;
                      }

                      if (this.particularofhotelsdata.length > 0) {
                        for (
                          var i = 0;
                          this.particularofhotelsdata.length > i;
                          i++
                        ) {
                          if (
                            this.particularofhotelsdata[i]['HOTEL_BILL'] !=
                              null &&
                            this.particularofhotelsdata[i]['HOTEL_BILL'] !=
                              undefined &&
                            this.particularofhotelsdata[i]['HOTEL_BILL'] != ''
                          ) {
                            this.hotelBill = true;
                          } else {
                            // this.hotelBill = false;
                          }
                        }
                        if (this.hotelBill == true) {
                          this.data3.ACCOMODATION_BILL_CLAIMED_SUBMITTED = true;
                        } else {
                          this.data3.ACCOMODATION_BILL_CLAIMED_SUBMITTED =
                            false;
                        }
                      } else {
                        this.data3.ACCOMODATION_BILL_CLAIMED_SUBMITTED = false;
                      }
                      if (this.detailsandpurpose.length > 0) {
                        for (
                          var i = 0;
                          this.detailsandpurpose.length > i;
                          i++
                        ) {
                          if (
                            this.detailsandpurpose[i]['TRAVEL_MODE_ID'] == 5 &&
                            this.detailsandpurpose[i]['TOUR_BOARDING_PASS'] !=
                              null &&
                            this.detailsandpurpose[i]['TOUR_BOARDING_PASS'] !=
                              undefined &&
                            this.detailsandpurpose[i]['TOUR_BOARDING_PASS'] !=
                              ''
                          ) {
                            this.bordingPass = true;
                          } else {
                            // this.bordingPass = false;
                          }
                        }
                        if (
                          this.bordingPass == true &&
                          this.data.TOUR_SELF_DECLARATION != null &&
                          this.data.TOUR_SELF_DECLARATION != undefined &&
                          this.data.TOUR_SELF_DECLARATION != ''
                        ) {
                          this.data3.TRAVELLING_VOUCHER_SUBMITTED = true;
                        } else {
                          this.data3.TRAVELLING_VOUCHER_SUBMITTED = false;
                        }
                      } else {
                      }

                      if (this.detailsandpurpose.length > 0) {
                        for (
                          var i = 0;
                          this.detailsandpurpose.length > i;
                          i++
                        ) {
                          if (
                            this.detailsandpurpose[i]['TRAVEL_MODE_ID'] == 3 &&
                            this.detailsandpurpose[i]['TOUR_ROAD_TICKETS'] !=
                              null &&
                            this.detailsandpurpose[i]['TOUR_ROAD_TICKETS'] !=
                              undefined &&
                            this.detailsandpurpose[i]['TOUR_ROAD_TICKETS'] != ''
                          ) {
                            this.roadTicket = true;
                          } else {
                            // this.roadTicket = false;
                          }
                        }
                        if (
                          this.roadTicket == true &&
                          this.data.TOUR_SELF_DECLARATION != null &&
                          this.data.TOUR_SELF_DECLARATION != undefined &&
                          this.data.TOUR_SELF_DECLARATION != ''
                        ) {
                          this.data3.ORIGINAL_BOARDING_PASS_SUBMITTED = true;
                        } else {
                          this.data3.ORIGINAL_BOARDING_PASS_SUBMITTED = false;
                        }
                      }

                      this.current = 4;
                    } else {
                      this.data3 = data['data'][0];
                      this.current = 4;
                    }
                    // this.current = 4;
                  } else {
                    this.isSpinning = false;
                  }
                });
            } else {
              // this.message.error('Something Went Wrong', '');
              this.isSpinning = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
      // this.isSpinning = false;
      // this.current += 1
    }
  }
  close(): void {
    this.current = 0;
    this.isSpinning = false;
    this.drawerClose();
  }
  fileList: any = [];
  fileNumberList() {
    this.isSpinning = true;
    this.api
      .getFileMaster(
        0,
        0,
        'ID',
        'ASC',
        ' AND STATUS = 1 AND HIRARCHY_ID in (9,10)',
        sessionStorage.getItem('userId')
      )
      .subscribe(
        (data: any) => {
          if (data['code'] == 200) {
            if (data['count'] > 0) {
              this.fileList = data['data'];
            } else {
              this.fileList = [];
            }
            this.isSpinning = false;
          } else {
            this.isSpinning = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  allEmployeeList() {
    this.empLoader = true;

    this.api.getEmployeeMaster(0, 0, '', '', ' AND STATUS = 1').subscribe(
      (data) => {
        if (data['code'] == 200) {
          this.employee = data['data'];
          this.filteredOptions = this.employee;
          this.empLoader = false;
        } else {
          this.message.error("Can't Load Employee Data", '');
          this.empLoader = false;
        }
      },
      (err) => {
        console.log(err);
      }
    );
    if (this.data.EMP_ID != null || this.data.EMP_ID != undefined) {
      this.api
        .getEmployeeMaster(0, 0, '', '', ' AND ID = ' + this.data.EMP_ID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              // this.data.OFFICE_NAME = data['data'][0]['OFFICE_NAME'];
              this.data.DESIGNATION = data['data'][0]['DESIGNATION'];
              // this.data.EMPLOYEE_CODE = data['data'][0]['EMPLOYEE_CODE'];
              // this.data.LOCATION = data['data'][0]['LOCATION'];
              // this.data.DDO_OF_THE_OFFICIAL =
              //   data['data'][0]['DDO_OF_THE_OFFICIAL'];
              this.data.BASIC_PAY = data['data'][0]['GRADE_PAY'];
              // this.data.EMAIL_ID = data['data'][0]['EMAIL_ID'];
              this.data.MOBILE_NO = data['data'][0]['MOBILE_NO'];
              // this.data.ADDRESS = data['data'][0]['ADDRESS'];
              this.data.EMPLOYEE_NAME = data['data'][0]['NAME'];
            } else {
              this.message.error("Can't Load Employee Data", '');
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
    }
  }
  employeeSearch(event: any) {
    var f = '';
    if (event.length >= 3) {
      this.api
        .getEmployeeMaster(
          0,
          0,
          '',
          'asc',
          " AND NAME like '%" +
            event +
            "%'" +
            " OR EMPLOYEE_CODE like '%" +
            event +
            "%'" +
            " OR OFFICE_NAME like '%" +
            event +
            "%'" +
            " OR DESIGNATION like '%" +
            event +
            "%'" +
            " OR DDO_OF_THE_OFFICIAL like '%" +
            event +
            "%'" +
            f
        )
        .subscribe(
          (empData) => {
            if (empData['code'] == 200) {
              var filteredOptions = empData['data'];

              // this.employee = [...[], ...empData['data']];

              // this.empLoader = false;
            } else {
              this.message.error("Can't Load Employee Data", '');
              // this.empLoader = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
    }
    // this.api
    //   .getinvestigationprocedure(
    //     0,
    //     0,
    //     'NAME',
    //     'asc',
    //     " and NAME like '%" + event + "%'" + f
    //   )
    //   .subscribe(
    //     (data) => {
    //       if (data['code'] == 200 && data['data'].length > 0)
    //         this.procedureList2 = data['data'];
    //       else this.procedureList2 = [];
    //     },
    //     (err) => {
    //       console.log(err);
    //       this.isSpinning = false;
    //     }
    //   );
    // }
  }
  name = '';
  onChange(value: string): void {
    this.name = value;
    this.filteredOptions = [];
    this.filteredOptions = this.employee.filter(
      (option) => option.NAME.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );

    if (value != '') {
      if (this.filteredOptions.length > 0) {
        // this.name = this.filteredOptions[0]['NAME'];
        this.data.ID = this.filteredOptions[0]['ID'];

        // this.data.OFFICE_NAME = this.filteredOptions[0]['OFFICE_NAME'];
        // this.data.DESIGNATION = this.filteredOptions[0]['DESIGNATION'];
        // this.data.EMPLOYEE_CODE = this.filteredOptions[0]['EMPLOYEE_CODE'];
        // this.data.LOCATION = this.filteredOptions[0]['LOCATION'];
        // this.data.DDO_OF_THE_OFFICIAL =
        //   this.filteredOptions[0]['DDO_OF_THE_OFFICIAL'];
        // this.data.GRADE_PAY = this.filteredOptions[0]['GRADE_PAY'];
        // this.data.EMAIL_ID = this.filteredOptions[0]['EMAIL_ID'];
        // this.data.MOBILE_NO = this.filteredOptions[0]['MOBILE_NO'];
        // this.data.ADDRESS = this.filteredOptions[0]['ADDRESS'];
      } else {
        this.data.ID = undefined;
        this.name = value;
        // this.data.OFFICE_NAME = '';
        // this.data.DESIGNATION = '';
        // this.data.EMPLOYEE_CODE = '';
        // this.data.LOCATION = '';
        // this.data.DDO_OF_THE_OFFICIAL = '';
        // this.data.GRADE_PAY = '';
        // this.data.EMAIL_ID = '';
        // this.data.MOBILE_NO = '';
        // this.data.ADDRESS = '';
      }
    } else {
      this.name = value;
      // this.data.OFFICE_NAME = '';
      // this.data.DESIGNATION = '';
      // this.data.EMPLOYEE_CODE = '';
      // this.data.LOCATION = '';
      // this.data.DDO_OF_THE_OFFICIAL = '';
      // this.data.GRADE_PAY = '';
      // this.data.EMAIL_ID = '';
      // this.data.MOBILE_NO = '';
      // this.data.ADDRESS = '';
    }
    // this.data.NAME = this.name;
  }
  drawerData: EmployeeMaster = new EmployeeMaster();
  drawerTitle: string = '';

  add(): void {
    this.drawerTitle = 'Create New Employee';
    this.drawerData = new EmployeeMaster();
    this.empDrawerVisible = true;
  }
  editEmp;
  empDrawerVisible: boolean = false;
  edit(data: any): void {
    this.drawerTitle = 'Edit Employee Details';
    this.api
      .getEmployeeMaster(0, 0, '', '', ' AND ID = ' + data.EMP_ID)
      .subscribe((data) => {
        if (data['code'] == 200) {
          this.drawerData = data['data'][0];
        } else {
          this.message.error("Can't Load Employee Data", '');
        }
      }),
      (this.empDrawerVisible = true);
  }

  empDrawerClose(): void {
    this.empDrawerVisible = false;
    this.allEmployeeList();
  }

  get closeCallback1() {
    return this.empDrawerClose.bind(this);
  }
  index = -1;
  addData(addNew: boolean, journoey: NgForm) {
    this.isSpinning = false;
    this.isOk = true;

    if (
      this.data2.DISTANCE_IN_KM_FOR_ROAD == undefined &&
      this.data2.FARE_PAID == undefined &&
      this.data2.TRAVEL_MODE_ID == 0 &&
      this.data2.TRAVEL_CLASS_ID == 0 &&
      this.data2.DEPARTURE_FROM == undefined &&
      this.data2.ARRIVAL_TO == undefined &&
      this.data2.PURPOSE_OF_JOURNEY == undefined
    ) {
      this.isOk = false;
      this.message.error('Please Fill All The Required Fields ', '');
    } else if (
      this.data2.DEPARTURE_DATETIME == null ||
      this.data2.DEPARTURE_DATETIME == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Select Departure Date', '');
    } else if (
      this.data2.DEPARTURE_FROM == null ||
      this.data2.DEPARTURE_FROM == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Departure From.', '');
    } else if (
      this.data2.ARRIVAL_DATETIME == null ||
      this.data2.ARRIVAL_DATETIME == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Select Arrival Date', '');
    } else if (this.data2.ARRIVAL_TO == null || this.data2.ARRIVAL_TO == '') {
      this.isOk = false;
      this.message.error('Please Enter Arrival to.', '');
    } else if (
      this.data2.TRAVEL_MODE_ID == null ||
      this.data2.TRAVEL_MODE_ID <= 0
    ) {
      this.isOk = false;
      this.message.error('Please Select Travel Mode', '');
    } else if (
      this.data2.TRAVEL_CLASS_ID == null ||
      this.data2.TRAVEL_CLASS_ID <= 0
    ) {
      this.isOk = false;
      this.message.error('Please Select Class of Mode.', '');
    } else if (
      this.data2.DAYS_OF_HALT == null ||
      this.data2.DAYS_OF_HALT <= 0
    ) {
      this.isOk = false;
      this.message.error('Please days of halts.', '');
    } else if (
      this.data2.HOURS_OF_HALT == null ||
      this.data2.HOURS_OF_HALT == ''
    ) {
      this.isOk = false;
      this.message.error('Please Enter Hours of Halts.', '');
    } else if (this.data2.FARE_PAID == null || this.data2.FARE_PAID <= 0) {
      this.isOk = false;
      this.message.error('Please Enter Fair Paid.', '');
    } else if (
      this.data2.DISTANCE_IN_KM_FOR_ROAD == null ||
      this.data2.DISTANCE_IN_KM_FOR_ROAD <= 0
    ) {
      this.isOk = false;
      this.message.error('Please Enter Distance in km for road.', '');
    } else if (
      this.data2.PURPOSE_OF_JOURNEY == null ||
      this.data2.PURPOSE_OF_JOURNEY <= 0
    ) {
      this.isOk = false;
      this.message.error('Please purpose of Journey.', '');
    }

    if (this.isOk) {
      if (this.data2.ARRIVAL_DATETIME == undefined) {
        this.data2.ARRIVAL_DATETIME = null;
      } else {
        this.data2.ARRIVAL_DATETIME = this.datepipe.transform(
          this.data2.ARRIVAL_DATETIME,
          'yyyy-MM-dd'
        );
      }
      if (this.data2.DEPARTURE_DATETIME == undefined) {
        this.data2.DEPARTURE_DATETIME = null;
      } else {
        this.data2.DEPARTURE_DATETIME = this.datepipe.transform(
          this.data2.DEPARTURE_DATETIME,
          'yyyy-MM-dd'
        );
      }

      if (this.index > -1) {
        this.detailsandpurpose[this.index] = Object.assign({}, this.data2);
      } else {
        this.detailsandpurpose.push(Object.assign({}, this.data2));
      }
      this.detailsandpurpose = [...[], ...this.detailsandpurpose];

      // this.api
      //   .getdeatilssofpurpose(0, 0, '', ' ', ' AND TOUR_ID =' + this.tourID)
      //   .subscribe(
      //     (data) => {

      //       if (data['code'] == 200 && data['data'].length > 0)
      //         this.detailsandpurpose = data['data'];
      //       this.data2.TRAVEL_MODE_ID = data['data']['TRAVEL_MODE_ID'];

      //     },
      //     (err) => {
      //       console.log(err);
      //     }
      //   );

      journoey.form.reset();
      this.data2 = new Deatailsandpurposeoftours();
      this.index = -1;
    }
  }
  TRAVEL_MODE_NAME: any;
  isdata(event: any) {
    if (event == false) {
      this.data.TRAVELLED_M_E_OT = '';
    }
  }
  freeBoardLodging(event: any) {
    if (event == false) {
      this.data.BOARD = null;
      this.data.LODGING = null;
      this.data.BOARD_AND_LODGING = null;
    } else {
    }
  }
  datadessable(event: any) {
    if (event == true) {
      this.data.LODGING = false;
      this.data.BOARD_AND_LODGING = false;
    }
  }

  datadessable1(event: any) {
    if (event == true) {
      this.data.BOARD = false;
      this.data.BOARD_AND_LODGING = false;
    }
  }
  datadessable2(event: any) {
    if (event == true) {
      this.data.LODGING = false;
      this.data.BOARD = false;
    }
  }

  selectchangevent(key: any) {
    if (key != null || key != undefined) {
      let name;
      name = this.modedata.filter((val) => {
        if (val.ID == key) {
          return val.NAME;
        }
      });
      this.data2.TRAVEL_MODE_NAME = name[0].NAME;

      this.api
        .gettravelclass(0, 0, '', '', ' AND STATUS=1 AND MODE_ID = ' + key)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              this.classdata = data['data'];
            }
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }

  selectchangevent1(key: any) {
    if (key != null || key != undefined) {
      let name;
      name = this.classdata.filter((val) => {
        if (val.ID == key) {
          return val.NAME;
        }
      });
      this.data2.TRAVEL_CLASS_NAME = name[0].NAME;
    }
  }

  isdasadta(event: any) {
    if (event == false) {
      this.data.RETURN_TICKET_PURCHASED_REMARK = '';
    }
  }

  AlldataSave(addNew: boolean, modedataform: NgForm, stage: string) {
    this.isOk = true;
    this.isSpinning = false;

    // if (

    //   this.data.TRAVEL_MODE_ID == 0

    // ) {
    //   this.isOk = false;
    //   this.message.error('Please Fill All The Required Fields ', '');
    // }
    // else if (
    //   this.data.TRAVEL_MODE_ID == null ||
    //   this.data.TRAVEL_MODE_ID <= 0
    // ) {
    //   this.isOk = false;
    //   this.message.error(' Please Select Travel Mode', '');
    // }

    // else if (this.particularofhotelsdata == null || this.particularofhotelsdata <= 0) {
    //   this.isOk = false;
    //   this.message.error(
    //     'Particulars of particularofhotelsdata ',
    //     ''
    //   );

    // }
    // else if (this.particularoftours == null || this.particularoftours <= 0) {
    //   this.isOk = false;
    //   this.message.error(
    //     'Particulars of journey(s) for which higher class ',
    //     ''
    //   );

    // }
    // else if (this.particularofcity == null || this.particularofcity <= 0) {
    //   this.isOk = false;
    //   this.message.error(
    //     'Details Journey(s) performed by road within city:',
    //     ''
    //   );

    // }
    this.isSpinning = true;
    this.data.ID = this.tourID;
    if (this.isOk && this.data.ID != undefined) {
      if (this.data.ADVANCE_TAKEN_DATE == undefined) {
        this.data.ADVANCE_TAKEN_DATE = null;
      } else {
        this.data.ADVANCE_TAKEN_DATE = this.datepipe.transform(
          this.data.ADVANCE_TAKEN_DATE,
          'yyyy-MM-dd'
        );
      }
      this.data.TOUR_ID = undefined;
      this.api.updatetour(this.data).subscribe((successCode) => {
        if (successCode.code == '200') {
          this.next();
          this.message.success('Information Saved Successfully...', '');
          this.isSpinning = false;
        } else {
          this.message.error('Information Has Not Saved...', '');

          this.isSpinning = false;
        }
      });
    }
  }

  Secondstep(addNew: boolean, modedataform: NgForm, stage: string) {
    this.isOk = true;
    this.isSpinning = false;
    this.isSpinning = true;
    this.data.ID = this.tourID;
    if (this.isOk && this.data.ID != undefined) {
      this.data.TOUR_ID = undefined;
      this.api.updatetour(this.data).subscribe((successCode) => {
        if (successCode.code == '200') {
          this.next();
          this.message.success('Information Saved Successfully...', '');
          this.isSpinning = false;
        } else {
          this.message.error('Information Has Not Saved...', '');

          this.isSpinning = false;
        }
      });
    }
  }
  docSave(addNew: boolean, modedataform: NgForm, stage: string) {
    this.isOk = true;
    this.isSpinning = false;
    this.isSpinning = true;
    this.data.ID = this.tourID;
    if (this.isOk && this.data.ID != undefined) {
      this.data.TOUR_ID = undefined;
      this.api.updatetour(this.data).subscribe((successCode) => {
        if (successCode.code == '200') {
          this.next();
          this.message.success('Information Saved Successfully...', '');
          this.isSpinning = false;
        } else {
          this.message.error('Information Has Not Saved...', '');

          this.isSpinning = false;
        }
      });
    }
  }

  FILE_NO: any;
  saveCheckList(addNew: boolean, websitebannerPage: NgForm): void {
    this.isSpinning = false;
    this.isOk = true;
    this.data3.TOUR_ID = this.tourID;
    if (
      this.data3.DELAYED_SUBMISSION_BILL == undefined &&
      this.data3.ELECTION_DUTY == undefined &&
      this.data3.TICKET_BOOKED_THROUGH_TRAVEL_DESK == undefined
    ) {
      this.isOk = false;
      this.message.error('Please Check 3 No. Point ', '');
    } else if (
      this.data3.DELAYED_SUBMISSION_BILL == undefined ||
      this.data3.DELAYED_SUBMISSION_BILL == null ||
      this.data3.DELAYED_SUBMISSION_BILL == ''
    ) {
      this.isOk = false;
      this.message.error('Please Check 3 No. Point ', '');
    } else if (
      this.data3.ELECTION_DUTY == undefined ||
      this.data3.ELECTION_DUTY == null ||
      this.data3.ELECTION_DUTY == ''
    ) {
      this.isOk = false;
      this.message.error('Please Check 6 No. Point ', '');
    } else if (
      this.data3.TICKET_BOOKED_THROUGH_TRAVEL_DESK == undefined ||
      this.data3.TICKET_BOOKED_THROUGH_TRAVEL_DESK == null ||
      this.data3.TICKET_BOOKED_THROUGH_TRAVEL_DESK == ''
    ) {
      this.isOk = false;
      this.message.error('Please Check 7 No. Point ', '');
    }

    if (this.isOk) {
      this.isSpinning = true;
      this.api.updatetour(this.data).subscribe((successCode) => {
        if (successCode.code == '200') {
          if (this.data3.ID) {
            this.api
              .updatetourmasterchecklist(this.data3)
              .subscribe((successCode) => {
                if (successCode.code == '200') {
                  this.message.success(
                    'Information Changed Successfully...',
                    ''
                  );
                  this.isSpinning = false;
                  this.current = 0;
                  if (!addNew) this.drawerClose();
                } else {
                  this.message.error('Information Has Not Changed...', '');
                  this.isSpinning = false;
                }
              });
          } else {
            this.api
              .createtourmasterchecklist(this.data3)
              .subscribe((successCode) => {
                if (successCode.code == '200') {
                  this.message.success('Information Save Successfully...', '');
                  this.isSpinning = false;
                  this.current = 0;
                  if (!addNew) this.drawerClose();
                  else {
                    this.data3 = new Checklisttour();
                    this.resetDrawer(websitebannerPage);
                  }
                } else {
                  this.message.error('Failed To Fill Information...', '');
                  this.isSpinning = false;
                }
              });
          }
          this.isSpinning = false;
        } else {
          this.message.error('Information Has Not Saved...', '');
          this.isSpinning = false;
        }
      });
    }
  }

  // saveCheckList(addNew: boolean, websitebannerPage: NgForm): void {
  //   this.isSpinning = false;
  //   this.isOk = true;
  //   this.data3.TOUR_ID = this.tourID;
  //   if (
  //     this.data3.DELAYED_SUBMISSION_BILL == undefined &&
  //     this.data3.ELECTION_DUTY == undefined &&
  //     this.data3.TICKET_BOOKED_THROUGH_TRAVEL_DESK == undefined
  //   ) {
  //     this.isOk = false;
  //     this.message.error('Please Check 3 No. Point ', '');
  //   } else if (
  //     this.data3.DELAYED_SUBMISSION_BILL == undefined ||
  //     this.data3.DELAYED_SUBMISSION_BILL == null ||
  //     this.data3.DELAYED_SUBMISSION_BILL == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error('Please Check 3 No. Point ', '');
  //   } else if (
  //     this.data3.ELECTION_DUTY == undefined ||
  //     this.data3.ELECTION_DUTY == null ||
  //     this.data3.ELECTION_DUTY == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error('Please Check 6 No. Point ', '');
  //   } else if (
  //     this.data3.TICKET_BOOKED_THROUGH_TRAVEL_DESK == undefined ||
  //     this.data3.TICKET_BOOKED_THROUGH_TRAVEL_DESK == null ||
  //     this.data3.TICKET_BOOKED_THROUGH_TRAVEL_DESK == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error('Please Check 7 No. Point ', '');
  //   } else if (
  //     this.data.ACTION_STATUS == 'R' &&
  //     (this.data.ACTION_REMARK == undefined ||
  //       this.data.ACTION_REMARK == null ||
  //       this.data.ACTION_REMARK == '')
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Enter Reject Remark.', '');
  //   } else if (
  //     this.data.ACTION_STATUS == 'H' &&
  //     (this.data.ACTION_DATE == undefined || this.data.ACTION_DATE == null)
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Select On Hold Date.', '');
  //   } else if (
  //     this.data.ACTION_STATUS == 'H' &&
  //     (this.data.ACTION_REMARK == undefined ||
  //       this.data.ACTION_REMARK == null ||
  //       this.data.ACTION_REMARK == '')
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Enter On Hold Remark.', '');
  //   }

  //   if(this.data.ACTION_DATE !=null && this.data.ACTION_DATE !='' && this.data.ACTION_DATE !=undefined  ){
  //     this.data.ACTION_DATE = this.datepipe.transform(
  //       this.data.ACTION_DATE,
  //       'yyyy-MM-dd'
  //     );
  //   }

  //   if (this.isOk) {
  //     {
  //       if (this.data3.ID) {
  //         this.api
  //           .updatetourmasterchecklist(this.data3)
  //           .subscribe((successCode) => {
  //             if (successCode.code == '200') {

  //               this.api.updatetour(this.data).subscribe((successCode) => {
  //                 if (successCode.code == '200') {
  //                   this.message.success('Information Changed Successfully...', '');
  //                   this.isSpinning = false;
  //                   this.current = 0;
  //                   if (!addNew) this.drawerClose();

  //                 } else {
  //                   this.message.error('Information Has Not Saved...', '');
  //                   this.isSpinning = false;
  //                 }
  //               });

  //             } else {
  //               this.message.error('Information Has Not Changed...', '');
  //               this.isSpinning = false;
  //             }
  //           });
  //       } else {
  //         this.api
  //           .createtourmasterchecklist(this.data3)
  //           .subscribe((successCode) => {
  //             if (successCode.code == '200') {

  //               this.api.updatetour(this.data).subscribe((successCode) => {
  //                 if (successCode.code == '200') {
  //                   this.message.success('Information Save Successfully...', '');
  //                   this.isSpinning = false;
  //                   this.current = 0;
  //                   if (!addNew) this.drawerClose();
  //                   else {
  //                     this.data3 = new Checklisttour();
  //                     this.resetDrawer(websitebannerPage);
  //                   }
  //                 } else {
  //                   this.isSpinning = false;
  //                   this.message.error('Information Has Not Saved...', '');

  //                 }
  //               });
  //             } else {
  //               this.message.error('Failed To Fill Information...', '');
  //               this.isSpinning = false;
  //             }
  //           });
  //       }
  //     }
  //   }
  // }

  // isreturend(event: any) {
  //   if (event == false) {
  //     this.data2.IS_RETURNED
  //   }
  // }
  isAdvanceTaken(event: any) {
    if (event == false) {
      if (
        this.data.AMOUNT_OF_T_A != null ||
        this.data.AMOUNT_OF_T_A != undefined
      ) {
        this.data.AMOUNT_OF_T_A = null;
      } else {
        this.data.AMOUNT_OF_T_A = null;
      }
      if (
        this.data.ADVANCE_TAKEN_DATE != null ||
        this.data.ADVANCE_TAKEN_DATE != undefined
      ) {
        this.data.ADVANCE_TAKEN_DATE = null;
      } else {
        this.data.ADVANCE_TAKEN_DATE = null;
      }
      if (
        this.data.ADVANCE_TAKEN_VOUCHER_NO != null ||
        this.data.ADVANCE_TAKEN_VOUCHER_NO != undefined
      ) {
        this.data.ADVANCE_TAKEN_VOUCHER_NO = null;
      } else {
        this.data.ADVANCE_TAKEN_VOUCHER_NO = null;
      }
    } else {
    }
  }

  // clearRemark(event) {
  //   if (event == 'A') {
  //     this.data.ACTION_REMARK = null;

  //     this.data.ACTION_DATE = null;
  //   } else if (event == 'R') {
  //     this.data.ACTION_REMARK = null;
  //     this.data.ACTION_DATE = null;
  //   } else {
  //     this.data.ACTION_REMARK = null;
  //   }
  // }

  updatecall() {
    this.api.updatetour(this.data).subscribe((successCode) => {
      if (successCode.code == '200') {
        // this.isSpinning = false;
        // this.message.success('Verified Successfully...', '');
        // this.printOrderModalVisible = false;
      } else {
        this.message.error('Information Has Not Saved...', '');

        this.isSpinning = false;
      }
    });
  }
  SR61FileURL: any;
  onFileSelectedSR61(event: any) {
    // if (this.data.FILE_TYPE == 'PDF') {

    if (event.target.files[0].type == 'application/pdf') {
      this.isSpinning = true;
      this.SR61FileURL = <File>event.target.files[0];

      if (this.SR61FileURL != null) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.SR61FileURL.name.split('.').pop();
        var d = this.datepipe.transform(new Date(), 'yyyyMMdd');
        var url = '';
        url = d == null ? '' : d + number + '.' + fileExt;
        if (this.data.S_R_61 != undefined && this.data.S_R_61.trim() != '') {
          var arr = this.data.S_R_61.split('/');
          if (arr.length > 1) {
            url = arr[5];
          }
        }
      }
      this.api
        .onUpload('S_R_61', this.SR61FileURL, url)
        .subscribe((successCode) => {
          if (successCode.code == '200') {
            this.data.S_R_61 = url;
            // this.data.ASSUMPTION_CHARGE_STATUS = '';
            this.updatecall();
            this.message.success('File Uploaded Successfully...', '');
            this.isSpinning = false;

            // this.data.FILE_URL = url;
          } else {
            this.message.error('Failed To Save File...', '');
            this.SR61FileURL = null;
            this.data.S_R_61 = null;
            this.isSpinning = false;
          }
        });
    } else {
      this.message.error('Please Select Only PDF File', '');
      this.SR61FileURL = null;
      this.data.S_R_61 = null;
    }
  }

  bankMandateFormFileURL: any;
  onFileSelectedBankMandateForm(event: any) {
    // if (this.data.FILE_TYPE == 'PDF') {

    if (event.target.files[0].type == 'application/pdf') {
      this.isSpinning = true;
      this.bankMandateFormFileURL = <File>event.target.files[0];

      if (this.bankMandateFormFileURL != null) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.bankMandateFormFileURL.name.split('.').pop();
        var d = this.datepipe.transform(new Date(), 'yyyyMMdd');
        var url = '';
        url = d == null ? '' : d + number + '.' + fileExt;
        if (
          this.data.BANK_MANDATE_FORM != undefined &&
          this.data.BANK_MANDATE_FORM.trim() != ''
        ) {
          var arr = this.data.BANK_MANDATE_FORM.split('/');
          if (arr.length > 1) {
            url = arr[5];
          }
        }
      }
      this.api
        .onUpload('bankMandateForm', this.bankMandateFormFileURL, url)
        .subscribe((successCode) => {
          if (successCode.code == '200') {
            this.data.BANK_MANDATE_FORM = url;
            // this.data.MANDATE_FORM_STATUS = '';
            this.updatecall();
            this.message.success('File Uploaded Successfully...', '');
            this.isSpinning = false;
            // this.data.FILE_URL = url;
          } else {
            this.message.error('Failed To Save File...', '');
            this.bankMandateFormFileURL = null;
            this.data.BANK_MANDATE_FORM = null;
            this.isSpinning = false;
          }
        });
    } else {
      this.message.error('Please Select Only PDF File', '');
      this.bankMandateFormFileURL = null;
      this.data.BANK_MANDATE_FORM = null;
    }
  }
  selfDeclarationFormFileURL: any;
  onFileSelectedSelfDeclaration(event: any) {
    // if (this.data.FILE_TYPE == 'PDF') {

    if (event.target.files[0].type == 'application/pdf') {
      this.isSpinning = true;
      this.selfDeclarationFormFileURL = <File>event.target.files[0];

      if (this.selfDeclarationFormFileURL != null) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.selfDeclarationFormFileURL.name.split('.').pop();
        var d = this.datepipe.transform(new Date(), 'yyyyMMdd');
        var url = '';
        url = d == null ? '' : d + number + '.' + fileExt;
        if (
          this.data.TOUR_SELF_DECLARATION != undefined &&
          this.data.TOUR_SELF_DECLARATION.trim() != ''
        ) {
          var arr = this.data.TOUR_SELF_DECLARATION.split('/');
          if (arr.length > 1) {
            url = arr[5];
          }
        }
      }
      this.api
        .onUpload('tourSelfDeclaration', this.selfDeclarationFormFileURL, url)
        .subscribe((successCode) => {
          if (successCode.code == '200') {
            this.data.TOUR_SELF_DECLARATION = url;
            // this.data.MANDATE_FORM_STATUS = '';
            this.updatecall();
            this.message.success('File Uploaded Successfully...', '');
            this.isSpinning = false;
            // this.data.FILE_URL = url;
          } else {
            this.message.error('Failed To Save File...', '');
            this.selfDeclarationFormFileURL = null;
            this.data.TOUR_SELF_DECLARATION = null;
            this.isSpinning = false;
          }
        });
    } else {
      this.message.error('Please Select Only PDF File', '');
      this.selfDeclarationFormFileURL = null;
      this.data.TOUR_SELF_DECLARATION = null;
    }
  }

  tourDelayCondolation: any;
  onFileSelectedtourDelayCondolation(event: any) {
    // if (this.data.FILE_TYPE == 'PDF') {

    if (event.target.files[0].type == 'application/pdf') {
      this.isSpinning = true;
      this.tourDelayCondolation = <File>event.target.files[0];

      if (this.tourDelayCondolation != null) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.tourDelayCondolation.name.split('.').pop();
        var d = this.datepipe.transform(new Date(), 'yyyyMMdd');
        var url = '';
        url = d == null ? '' : d + number + '.' + fileExt;
        if (
          this.data.TOUR_DELAY_CONDOLATION_URL != undefined &&
          this.data.TOUR_DELAY_CONDOLATION_URL.trim() != ''
        ) {
          var arr = this.data.TOUR_DELAY_CONDOLATION_URL.split('/');
          if (arr.length > 1) {
            url = arr[5];
          }
        }
      }
      this.api
        .onUpload('tourDelayCondolation', this.tourDelayCondolation, url)
        .subscribe((successCode) => {
          if (successCode.code == '200') {
            this.data.TOUR_DELAY_CONDOLATION_URL = url;
            // this.data.MANDATE_FORM_STATUS = '';
            this.updatecall();
            this.message.success('File Uploaded Successfully...', '');
            this.isSpinning = false;
            // this.data.FILE_URL = url;
          } else {
            this.message.error('Failed To Save File...', '');
            this.tourDelayCondolation = null;
            this.data.TOUR_DELAY_CONDOLATION_URL = null;
            this.isSpinning = false;
          }
        });
    } else {
      this.message.error('Please Select Only PDF File', '');
      this.tourDelayCondolation = null;
      this.data.TOUR_DELAY_CONDOLATION_URL = null;
    }
  }
  view = 0;
  sanitizedLink: any = '';
  getS(link: string) {
    if (this.view == 1) {
      var a = this.api.retriveimgUrl + 'S_R_61/' + link;
    }
    if (this.view == 2) {
      var a = this.api.retriveimgUrl + 'bankMandateForm/' + link;
    }
    if (this.view == 3) {
      var a = this.api.retriveimgUrl + 'tourSelfDeclaration/' + link;
    }
    if (this.view == 4) {
      var a = this.api.retriveimgUrl + 'tourDelayCondolation/' + link;
    }
    if (this.view == 5) {
      var a = this.api.retriveimgUrl + 'ticketBoardingPass/' + link;
    }
    if (this.view == 6) {
      var a = this.api.retriveimgUrl + 'transportationBills/' + link;
    }
    if (this.view == 7) {
      var a = this.api.retriveimgUrl + 'condolationOfDelay/' + link;
    }

    this.sanitizedLink = this.sanitizer.bypassSecurityTrustResourceUrl(a);

    return this.sanitizedLink;
  }
  printOrderModalCancel() {
    this.printOrderModalVisible = false;
  }
  printOrderModalVisible = false;
  SR61ViewAssumptionPDF(pdfURL: string): void {
    this.view = 1;
    this.printOrderModalVisible = true;

    // window.open(this.api.retriveimgUrl + 'S_R_61/' + pdfURL);
  }
  bankViewMandateFormPDF(pdfURL: string): void {
    this.view = 2;
    this.printOrderModalVisible = true;
    // window.open(this.api.retriveimgUrl + 'mandateForm/' + pdfURL);
  }
  viewSelfDeclarationFormPDF(pdfURL: string): void {
    this.view = 3;
    this.printOrderModalVisible = true;
    // window.open(this.api.retriveimgUrl + 'mandateForm/' + pdfURL);
  }
  viewDelayCondonationPDF(pdfURL: string): void {
    this.view = 4;
    this.printOrderModalVisible = true;
    // window.open(this.api.retriveimgUrl + 'mandateForm/' + pdfURL);
  }
}
