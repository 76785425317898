<div>
  <form nz-form #websitebannerPage="ngForm">
    <nz-spin [nzSpinning]="isSpinning">
      <div nz-row>
        <div nz-col nzSpan="15">
          <div nz-row class="my-box">
            <div nz-col nzSpan="24">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Employee Name</nz-form-label>

                <nz-form-control nzErrorTip="Input is required">
                  <nz-select nzShowSearch nzAllowClear nzErrorTip="Please Select Employee"
                    (ngModelChange)="filterdrama($event)" name="cityid" [(ngModel)]="data.EMP_ID" required>
                    <nz-option *ngFor="let role of employee " [nzValue]="role.ID" [nzLabel]="role.NAME">
                    </nz-option>

                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="12">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Employee Code</nz-form-label>

                <nz-form-control nzErrorTip="Input is required">
                  <input nz-input autocomplete="off" name="EmployeeCode" type="text" [(ngModel)]="data.EMPLOYEE_CODE"
                    placeHolder="Enter Employee Code" required />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="12">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Grade Pay</nz-form-label>

                <nz-form-control nzErrorTip="Input is required">
                  <nz-input-group style="width: 100%" nzAddOnBefore="₹">
                    <input nz-input autocomplete="off" (keypress)="omit($event)" [(ngModel)]="data.GRADE_PAY"
                      name="gradePay" type="text" placeHolder="Enter Employee Grade Pay" required />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="16">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Office Name</nz-form-label>
                <nz-form-control nzErrorTip="Input is required">
                  <input nz-input autocomplete="off" name="Office Name" type="text" [(ngModel)]="data.OFFICE_NAME"
                    placeHolder="Enter The Office Name" required />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Designation</nz-form-label>
                <nz-form-control nzErrorTip="Input is required">
                  <input nz-input autocomplete="off" name="Designation" type="text" [(ngModel)]="data.DESIGNATION"
                    placeHolder="Enter The Designation" required />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Location</nz-form-label>
                <nz-form-control nzErrorTip="Input is required">
                  <input nz-input autocomplete="off" name="Location" type="text" [(ngModel)]="data.LOCATION"
                    placeHolder="Enter The Location" required />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="16">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>DDO Of The Official</nz-form-label>
                <nz-form-control nzErrorTip="Input is required">
                  <input nz-input autocomplete="off" name="DDOOfficial" type="text"
                    [(ngModel)]="data.DDO_OF_THE_OFFICIAL" placeHolder="Enter The DDO" required />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="16">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Patient Name</nz-form-label>
                <nz-form-control nzErrorTip="Input is required">
                  <input nz-input autocomplete="off" name="PatientName" type="text" [(ngModel)]="data.PATIENT_NAME"
                    placeHolder="Enter Patient Name" required />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Relation with Patient</nz-form-label>
                <nz-form-control nzErrorTip="Input is required">
                  <!-- <input nz-input autocomplete="off" name="Relation" type="text"
                    [(ngModel)]="data.RELATION_WITH_PATIENT" placeHolder="Enter Relation" required /> -->
                </nz-form-control>


                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select a person" [(ngModel)]="selectedValue"
                  (ngModelChange)="SelectRelative($event)">
                  <nz-option *ngFor="let relatives of relativeArray;" [nzValue]="relatives"
                    [nzLabel]="relatives"></nz-option>
                  <nz-option nzLabel="Mother" nzValue="Mother"></nz-option>
                  <nz-option nzLabel="Brother" nzValue="Brother"></nz-option>
                  </nz-select>

              </nz-form-item>
            </div>

            <div nz-col nzSpan="10">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Beneficiary Type</nz-form-label>

                <nz-form-control>
                  <nz-radio-group name="Benefeciarytype" [(ngModel)]="Benefeciarytype">
                    <label nz-radio nzValue="CG">CGHS</label>
                    <label nz-radio nzValue="CS">CS(MA)</label>
                  </nz-radio-group>
                </nz-form-control>

                <nz-form-control *ngIf="Benefeciarytype=='CG'">
                  <input nz-input [(ngModel)]="data.BENEFICIARY_TYPE" placeholder="Input CGHS Card Number">
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="14">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Whether Emergrncy Treatment Availed?</nz-form-label>
                <nz-form-control>
                  <nz-switch name="etreatmenta" [(ngModel)]="data.IS_EMERGENCY_TREATMENT_APPLIED"
                    nzCheckedChildren="Yes" nzUnCheckedChildren="No">
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="12" *ngIf="treatment_availed == true">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Date Of CGHS/AMA'S References</nz-form-label>
                <nz-form-control nzErrorTip="Input is required">
                  <nz-date-picker style="width: 100%" nzFormat="dd/MM/yyyy"
                    name="dateCGHSAMASreferences"></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div nz-row class="my-box">
            <div nz-col nzSpan="16">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Name of Hospital/Dignostic Center
                </nz-form-label>
                <nz-input-group>
                  <nz-select name="Hospitalname" style="width: 100%" nzShowSearch nzPlaceHolder="Select Hospital">
                    <nz-option *ngFor="let Hospital of Hospital" [nzValue]="Hospital.ID"
                      [nzLabel]="Hospital.NAME"></nz-option>
                  </nz-select>
                </nz-input-group>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Hospital Type
                </nz-form-label>
                <nz-input-group>
                  <nz-select name="HospitalType" style="width: 100%" nzShowSearch nzPlaceHolder="Select Hospital Type">
                    <nz-option [nzValue]="1" [nzLabel]="'Empanelled'"></nz-option>
                    <nz-option [nzValue]="2" [nzLabel]="'Non-Empanelled'"></nz-option>
                    <nz-option [nzValue]="3" [nzLabel]="'Goverment'"></nz-option>
                  </nz-select>
                </nz-input-group>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="24">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Is Hospital/Dignostic Center Is Private?</nz-form-label>
                <nz-form-control>
                  <nz-switch [(ngModel)]="data.IS_PRIVATE_HOSPITAL" name="PrivateH" nzCheckedChildren="Yes" nzUnCheckedChildren="No">
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="24" *ngIf="PrivateH == true">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Whether Permission/Ex-Post Facto Permission Of HOD
                  Taken?</nz-form-label>
                <nz-form-control>
                  <nz-switch name="Permission" [(ngModel)]="data.IS_PERMISSION_TAKEN" nzCheckedChildren="Yes" nzUnCheckedChildren="No">
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </div>
        <div nz-col nzSpan="1"></div>
        <div nz-col nzSpan="8">
          <div nz-row class="my-box">
            <div nz-col nzSpan="24">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Date Of Bill</nz-form-label>
                <nz-form-control nzErrorTip="Input is required">
                  <nz-date-picker style="width: 100%" [(ngModel)]="data.DATE_OF_BILL" nzFormat="dd/MM/yyyy" name="DateofBill"></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="24">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Whether Bill Is Filled In Time?</nz-form-label>
                <nz-form-control>
                  <nz-switch name="filledintime" [(ngModel)]="data.DATE_OF_BILL" nzCheckedChildren="Yes" nzUnCheckedChildren="No">
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div nz-row class="my-box">
            <div nz-col nzSpan="24">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Amount of Reimbusment Claimed</nz-form-label>
                <nz-form-control nzHasFeedback nzErrorTip="Input is Required">
                  <nz-input-group nzAddOnBefore="₹">
                    <input nz-input name="AmountClaimed"  [(ngModel)]="data.AMOUNT_OF_REIMBUSMENT_CLAIMED" type="number" placeHolder="Enter Amount of Reimbusment Claimed"
                      required />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="24">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Amount of Reimbusment Admissible</nz-form-label>
                <nz-form-control nzHasFeedback nzErrorTip="Input is Required">
                  <nz-input-group nzAddOnBefore="₹">
                    <input nz-input name="AmountAdmissible"  [(ngModel)]="data.AMOUNT_OF_REIMBUSMENT_ADMISSIBLE" type="number"
                      placeHolder="Enter Amount of Reimbusment Admissible" required />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="24">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Is Advance Taken?</nz-form-label>
                <nz-form-control>
                  <nz-switch [(ngModel)]="AdvanceTaken"  [(ngModel)]="data.IS_ADVANCE_TAKEN" name="AdvanceTaken" nzCheckedChildren="Yes"
                    nzUnCheckedChildren="No">
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="24" *ngIf="AdvanceTaken == true">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Advance Amount</nz-form-label>
                <nz-form-control nzHasFeedback nzErrorTip="Input is Required">
                  <nz-input-group nzAddOnBefore="₹">
                    <input nz-input name="AdvanceAmount" type="number" [(ngModel)]="data.ADVANCE_AMOUNT" placeHolder="Enter Advance Amount" required />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="24">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Net Amount Payable/Recoverable</nz-form-label>
                <nz-form-control nzHasFeedback nzErrorTip="Input is Required">
                  <nz-input-group nzAddOnBefore="₹">
                    <input nz-input name="NetAmount" type="number" [(ngModel)]="data.NET_AMOUNT_PAYABLE"
                      placeHolder="Enter Net Payable Amount" required />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </div>

        <div nz-col nzSpan="24">
          <div nz-row class="my-box">
            <div nz-col nzSpan="12">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Nature Of Treatment/Test</nz-form-label>
                <nz-form-control nzErrorTip="Input is required">
                  <textarea nz-input [nzAutosize]="{ minRows: 3, maxRows: 6 }" name="NatureOfTreatment"
                    placeHolder="Enter a Nature Of Treatment/Test" [(ngModel)]="data.NATURE_OF_TREATMENT"
                    maxlength="500"></textarea>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="6">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Treatment Start Date</nz-form-label>
                <nz-form-control nzErrorTip="Input is required">
                  <nz-date-picker style="width: 100%" nzFormat="dd/MM/yyyy" [(ngModel)]="data.TREATMENT_START_DATE"
                    name="TreatmentStartDate"></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="6">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Treatment End Date</nz-form-label>
                <nz-form-control nzErrorTip="Input is required">
                  <nz-date-picker style="width: 100%" [(ngModel)]="data.TREATMENT_END_DATE" nzFormat="dd/MM/yyyy"
                    name="TreatmentEndDate"></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </div>

        <div nz-col nzSpan="24">
          <!-- <div nz-row class="my-box">
        
          </div> -->
          <nz-divider nzText="The checklist of the above mentioned bill is as under:"></nz-divider>
          <div nz-row class="my-box">
            <div nz-col nzSpan="24">
              <nz-form-item style="display: flex !important">
                <nz-form-label nzNoColon>1. Forwarding letter (through proper channel)</nz-form-label>
                <nz-form-control>
                  <nz-switch name="Checklist1" [(ngModel)]="data.IS_FORWARDING_LETTER" nzCheckedChildren="Yes" nzUnCheckedChildren="No">
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="24">
              <nz-form-item style="display: flex !important">
                <nz-form-label nzNoColon>2. Medical Reimbursement Claim (MRC) form for (CGHS/CSMA)
                </nz-form-label>
                <nz-form-control>
                  <nz-switch name="Checklist2" [(ngModel)]="data.IS_MEDICAL_REIMBURSEMENT_CLAIMED"
                    nzCheckedChildren="Yes" nzUnCheckedChildren="No">
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="24">
              <nz-form-item style="display: flex !important">
                <nz-form-label nzNoColon>3. Copy of CGHS Card for both beneficiary and
                  patient</nz-form-label>
                <nz-form-control>
                  <nz-switch name="Checklist3" [(ngModel)]="data.IS_COPY_OF_CGHS_CARD_FOR_BOTH_BENEFICIARY_AND_PATIENT"
                    nzCheckedChildren="Yes" nzUnCheckedChildren="No">
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="24">
              <nz-form-item style="display: flex !important">
                <nz-form-label nzNoColon>4. Discharge Card</nz-form-label>
                <nz-form-control>
                  <nz-switch name="Checklist4" [(ngModel)]="data.IS_DISCHARGE_CARD" nzCheckedChildren="Yes"
                    nzUnCheckedChildren="No">
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="24">
              <nz-form-item style="display: flex !important">
                <nz-form-label nzNoColon>5. Form No.3 in case the medical claim is of the official's
                  family member for AMA Beneficiary</nz-form-label>
                <nz-form-control>
                  <nz-switch name="Checklist5" [(ngModel)]="data.FORM_NO_3_IN_CASE_THE_MEDICAL_CLAIM"
                    nzCheckedChildren="Yes" nzUnCheckedChildren="No">
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="24">
              <nz-form-item style="display: flex !important">
                <nz-form-label nzNoColon>6. Final Bill in Original along with one Xerox
                  Copy</nz-form-label>
                <nz-form-control>
                  <nz-switch name="Checklist6"
                    [(ngModel)]="data.IS_FINAL_BILL_IN_ORIGINAL_ALONG_WITH_ONE_XEROX_ONE_COPY" nzCheckedChildren="Yes"
                    nzUnCheckedChildren="No">
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="24">
              <nz-form-item style="display: flex !important">
                <nz-form-label nzNoColon>7. Pay Slip of the period of treatment</nz-form-label>
                <nz-form-control>
                  <nz-switch name="Checklist7" [(ngModel)]="data.IS_PAY_SLIP_OF_THE_PERIOD_OF_TRATMENT"
                    nzCheckedChildren="Yes" nzUnCheckedChildren="No">
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="24">
              <nz-form-item style="display: flex !important">
                <nz-form-label nzNoColon>8. Bank mandate form alongwith cancelled
                  Cheque</nz-form-label>
                <nz-form-control>
                  <nz-switch name="Checklist8" [(ngModel)]="data.IS_BANK_MANDATE_FROM_ALONG_WITH_CANCELLED_CHEQUE"
                    nzCheckedChildren="Yes" nzUnCheckedChildren="No">
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="24">
              <nz-form-item style="display: flex !important">
                <nz-form-label nzNoColon>9. Prescription of medicines purchased from
                  outside</nz-form-label>
                <nz-form-control>
                  <nz-switch name="Checklist9" [(ngModel)]="data.IS_PRESCRIPTION_OF_MEDICINES_PURCHASED_FROM_OUTSIDE"
                    nzCheckedChildren="Yes" nzUnCheckedChildren="No">
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>
  </form>

  <div class="footer">
    <button type="button" nz-button (click)="close()" class="ant-btn" style="margin-right: 8px">
      <span>Cancel</span>
    </button>
    <button type="button" class="ant-btn ant-btn-primary" *ngIf="!data.ID" nz-button nz-dropdown [nzDropdownMenu]="menu"
      [nzLoading]="isSpinning" (click)="save(false,websitebannerPage)">
      <span>Save & Close</span>
    </button>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li nz-menu-item class="menuc" style="border-radius: 4px; background-color: #40a9ff">
          <a style="color: white" (click)="save(true,websitebannerPage)">Save & New</a>
        </li>
      </ul>
    </nz-dropdown-menu>
    <button type="button" class="ant-btn ant-btn-primary" nz-button [nzLoading]="isSpinning" *ngIf="data.ID"
      (click)="save(false,websitebannerPage)">
      <span>Update & Close</span>
    </button>
  </div>
</div>