import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-transferchecklist',
  templateUrl: './transferchecklist.component.html',
  styleUrls: ['./transferchecklist.component.css'],
})
export class TransferchecklistComponent implements OnInit {
  @Input() transferData;
  @Input() checklistData;
  @Input() drawerClose;
  // @Input() SECTION_TYPE: any;
  @Input() SIGNNAME: any;
  // @Input() NAME_HN: any;
  @Input() OFFICE_NAME: any;
  // @Input() OFFICE_NAME_HN: any;
  @Input() POST: any;
  // @Input() POST_HN: any;
  isSpinning: boolean = false;
  isOk: boolean = false;
  constructor(
    private message: NzNotificationService,
    private api: ApiService,
    private datepipe: DatePipe,
    public cookie: CookieService
  ) {}

  ngOnInit(): void {
    this.getAllUsers();
  }
  close(): void {
    this.isSpinning = false;
    this.drawerClose();
  }
  // print() {
  //   this.api
  //     .gettransferdata(0, 0, '', '', ' AND ID = ' + this.drawerClaimData.ID)
  //     .subscribe(
  //       (data) => {
  //         if (data['code'] == 200) {

  //           this.loadingRecords = false;
  //           this.drawerClaimData = data['data'][0];
  //           this.printOrderModalVisible = true;
  //         } else {
  //           // this.message.error("Can't Load Data", '');
  //         }
  //       },
  //       (err) => {
  //         console.log(err);
  //       }
  //     );
  // }

  checkSave(addNew: boolean): void {
    this.isSpinning = true;
    this.isOk = true;

    if (this.isOk) {
      {
        if (this.transferData.ID) {
          this.api
            .updatetransfer(this.transferData)
            .subscribe((successCode) => {
              if (successCode.code == '200') {
                this.message.success('Information Changed Successfully...', '');
                this.isSpinning = false;
                this.showmodal();
                if (!addNew) {
                  // this.drawerClose();
                  this.isSpinning = false;
                }
              } else {
                this.message.error('Information Has Not Changed...', '');
                this.isSpinning = false;
              }
            });
        } else {
          this.api
            .createtransfer(this.transferData)
            .subscribe((successCode) => {
              if (successCode.code == '200') {
                this.message.success('Information Save Successfully...', '');
                this.isSpinning = false;
                this.showmodal();
                if (!addNew) {
                  // this.empID = '';
                  // this.claimID = '';
                  // this.drawerClose();
                } else {
                }
                this.isSpinning = false;
              } else {
                this.message.error('Failed To Fill Information...', '');
                this.isSpinning = false;
              }
            });
        }
      }
    }
  }
  printOrderModalVisible = false;
  showmodal() {
    // this.advanceamount = this.drawerClaimData.ORDER_FORM_TOTAL;
    this.printOrderModalVisible = true;
  }
  getwidth() {
    if (window.innerWidth <= 400) {
      return 400;
    } else {
      return 850;
    }
  }
  printOrderModalCancel() {
    this.printOrderModalVisible = false;
  }
  openpdf() {
    const element = document.getElementById('excel-table');
    const opt = {
      margin: 0.2,
      filename: 'Dated.pdf',
      image: { type: 'jpeg', quality: 7 },
      html2canvas: { scale: 7 },
      jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
    };
    html2pdf().from(element).set(opt).save();
  }

  Signaturearray = [];
  getAllUsers() {
    this.api.getSignature(0, 0, 'ID', 'desc', '').subscribe(
      (data) => {
        if (data['code'] == 200) {
          this.Signaturearray = data['data'];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  signature(event: any) {
    var f = this.Signaturearray.filter((item) => item.ID == event);
    // this.SECTION_TYPE = f[0]['SECTION_TYPE'];
    this.SIGNNAME = f[0]['NAME'];
    // this.NAME_HN = f[0]['NAME_HN'];
    this.OFFICE_NAME = f[0]['OFFICE_NAME'];
    // this.OFFICE_NAME_HN = f[0]['OFFICE_NAME_HN'];
    this.POST = f[0]['POST'];
    // this.POST_HN = f[0]['POST_HN'];
  }
}
