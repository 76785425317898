<div>
  <div *ngIf="certificateData != undefined || certificateData != null">
    <form nz-form>
      <nz-spin [nzSpinning]="loadingRecords">
        <!-- <div nz-row [nzGutter]="10"> -->
        <div nz-row>
          <!-- <div
            nz-col
            nzXs="10"
            nzSm="10"
            nzMd="10"
            nzLg="10"
            nzXl="10"
            nzSpan="10"
          >
            <div class="my-box">
              <div nz-row>
                <div
                  nz-col
                  nzXs="24"
                  nzSm="24"
                  nzMd="24"
                  nzLg="24"
                  nzXl="24"
                  nzSpan="24"
                >
                  <nz-form-item>
                    <nz-form-label nzRequired nzNoColon
                      >Bill Number</nz-form-label
                    >

                    <nz-form-control
                      nzHasFeedback
                      nzErrorTip="Bill no. is required"
                    >
                      <input
                        style="width: 100%"
                        name="BillNumber"
                        nz-input
                        placeholder="Enter bill number"
                        type="text"
                        [(ngModel)]="certificateData.BILL_NO"
                      />
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
            </div>
          </div> -->

          <div nz-col nzSpan="24">
            <div class="my-box" style="padding: 10px">
              <div>
                <div
                  style="font-family: Arial, Helvetica, sans-serif !important"
                >
                  <div
                    style="
                      text-align: center;
                      margin-top: 20px;
                      margin-bottom: 10px;
                      font-size: 13px;
                    "
                  >
                    <b>CERTIFICATE</b>
                  </div>
                  <br />
                  <div style="font-size: 14px !important">
                    <p>
                      Name of the Officer:<b>{{
                        certificateData.EMPLOYEE_NAME
                      }}</b>
                    </p>
                    <p>
                      Designation: <b>{{ certificateData.DESIGNATION }}</b>
                    </p>
                    <p>
                      Bill No.: <b>{{ certificateData.FILE_NUMBER }}</b>
                    </p>
                    <p style="margin-bottom: 10px">
                      Certified that area in which the officer is residing is
                      not covered by the C.G.H. Scheme.
                    </p>
                    <p>
                      Certified that the medicines which have been included in
                      this claim are not included in the list of in-admissible
                      medicines published by the Government from time to time.
                    </p>

                    <p style="margin-bottom: 10px">
                      Certified that the bill has been submitted by the officer
                      is within the time limit prescribed under the Rules.
                    </p>

                    <p style="margin-bottom: 10px">
                      Certified that the claim made by the officer is genuine to
                      the best of my knowledge and the amount claimed is
                      reimbursed to him.
                    </p>

                    <p style="text-align: right; margin-top: 80px">
                      <b
                        >Joint Commissioner of Income Tax<br />(HQ) Personnel,
                        Mumbai</b
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nz-spin>
    </form>
  </div>
  <div class="footer">
    <button
      type="button"
      nz-button
      class="ant-btn"
      style="margin-right: 8px"
      (click)="close()"
    >
      <span>Cancel</span>
    </button>

    <button
      type="button"
      class="ant-btn ant-btn-primary"
      nz-button
      (click)="openPrintOrderModal()"
    >
      <span>Print or Download</span>
    </button>
  </div>
</div>

<nz-modal
  [nzWidth]="getwidth()"
  [(nzVisible)]="printOrderModalVisible"
  nzMaskClosable="false"
  nzClosable="false"
  [nzContent]="printOrderModalContent"
  [nzTitle]="'Certificate'"
  (nzOnCancel)="printOrderModalCancel()"
  [nzFooter]="printOrderModalFooter"
>
  <ng-template #printOrderModalContent>
    <nz-spin [nzSpinning]="pdfDownload">
      <div style="height: 450px; overflow: auto">
        <div id="printOrderModal">
          <div>
            <div style="font-family: Arial, Helvetica, sans-serif !important">
              <div
                style="
                  text-align: center;
                  margin-top: 20px;
                  margin-bottom: 10px;
                  font-size: 13px;
                "
              >
                <b>CERTIFICATE</b>
              </div>
              <br />
              <div style="font-size: 14px !important">
                <p>
                  Name of the Officer:<b>{{ certificateData.EMPLOYEE_NAME }}</b>
                </p>
                <p>
                  Designation: <b>{{ certificateData.DESIGNATION }}</b>
                </p>
                <p>
                  Bill No.: <b>{{ certificateData.FILE_NUMBER }}</b>
                </p>
                <p style="margin-bottom: 10px">
                  Certified that area in which the officer is residing is not
                  covered by the C.G.H. Scheme.
                </p>
                <p>
                  Certified that the medicines which have been included in this
                  claim are not included in the list of in-admissible medicines
                  published by the Government from time to time.
                </p>

                <p style="margin-bottom: 10px">
                  Certified that the bill has been submitted by the officer is
                  within the time limit prescribed under the Rules.
                </p>

                <p style="margin-bottom: 10px">
                  Certified that the claim made by the officer is genuine to the
                  best of my knowledge and the amount claimed is reimbursed to
                  him.
                </p>

                <p style="text-align: right; margin-top: 80px">
                  <b
                    >Joint Commissioner of Income Tax<br />(HQ) Personnel,
                    Mumbai</b
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>
  </ng-template>

  <ng-template #printOrderModalFooter>
    <button nz-button nzType="default" (click)="printOrderModalCancel()">
      Close
    </button>

    <button
      nz-button
      nzType="default"
      [useExistingCss]="true"
      printSectionId="printOrderModal"
      [nzLoading]="pdfDownload"
      ngxPrint
    >
      Print
    </button>

    <button
      nz-button
      nzType="primary"
      [nzLoading]="pdfDownload"
      (click)="generatePDF()"
    >
      Download
    </button>
  </ng-template>
</nz-modal>
