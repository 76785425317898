import { DatePipe } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { featureMaster } from './Models/BasicForms/featureMaster';
import { moduleMaster } from './Models/BasicForms/moduleMaster';
import { taskSubtaskMaster } from './Models/BasicForms/taskSubtaskMaster';
import { User } from './Models/Commonmodule/user';
import { EmployeeMaster } from './Models/Employee';
import { ApiService } from './Service/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [DatePipe],
})
export class AppComponent {
  user = new User();
  isCollapsed = false;
  isLogedIn = false;
  userId = sessionStorage.getItem('userId');
  roleId = Number(sessionStorage.getItem('roleId'));
  level = Number(sessionStorage.getItem('level'));
  menus = [];
  USERNAME = sessionStorage.getItem('userName');
  pageName: string = '';

  constructor(
    private router: Router,
    private api: ApiService,
    private cookie: CookieService,
    private notificationService: NzNotificationService,
    private datePipe: DatePipe
  ) {
    this.loggerInit();
  }

  loggerInit() {
    if (
      this.cookie.get('supportKey') === '' ||
      this.cookie.get('supportKey') === null
    ) {
      this.api.loggerInit().subscribe(
        (data) => {
          if (data['code'] == 200) {
            this.cookie.set(
              'supportKey',
              data['data'][0]['supportkey'],
              365,
              '',
              '',
              false,
              'Strict'
            );
          }
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
    }
  }
  ngOnInit() {
    this.employeeedit = sessionStorage.getItem('userId');
    this.roleId = Number(sessionStorage.getItem('roleId'));
    let url = window.location.href;
    var arr = url.split('/');

    this.pageName = arr[3];
    if (this.cookie.get('token') === '' || this.cookie.get('token') === null) {
      this.isLogedIn = false;
      // this.isLogedIn=true;
    } else {
      if (this.userId || this.roleId != 0) {
        this.isLogedIn = true;
        // this.router.navigateByUrl('/admin-dashboard')
        if (this.roleId != 2) this.accessPageForRedirect();
        this.loadForms();
      } else {
        // this.api.logoutForSessionValues();
      }
    }
    // this.api.receiveMessage()
    // this.message1 = this.api.currentMessage
  }

  accessPageForRedirect() {
    if (this.roleId != 0) {
      let url = window.location.href;
      var arr = url.split('/');

      let validPage = '/' + arr[3];
      this.api
        .getCheckAccessOfForm(this.roleId, validPage)
        .subscribe((data) => {
          if (data['data'] == true) {
            this.router.navigateByUrl(validPage);
            this.pageName = arr[3];
          } else {
            if (validPage != '/login') {
              this.api.logoutForSessionValues();
            }
          }
        });
    }
  }

  // loadForms() {
  //   this.api.getForms(this.roleId).subscribe((data) => {
  //     if (data['code'] == 200) {
  //       data['data'].forEach((element) => {
  //         element['children'].sort(this.sortFunction);

  //         if (element['children'].length == 0) delete element['children'];
  //       });

  //       this.menus = data['data'].sort(this.sortFunction);
  //     }
  //   });
  // }

  reports = [];

  loadForms() {
    this.api.getForms(this.roleId).subscribe((data) => {
      if (data['code'] == 200) {
        data['data'].forEach((element) => {
          element['children'].sort(this.sortFunction);

          if (element['children'].length == 0) delete element['children'];
        });

        this.reports = data['data'];

        for (let k = 0; k < this.reports.length; k++) {
          if (
            this.reports[k].title != 'Reports' &&
            this.reports[k].title != 'File Reports' &&
            this.reports[k].title != 'LTC Reports' &&
            this.reports[k].title != 'Tour Reports' &&
            this.reports[k].title != 'Transfer Reports'
          ) {
            this.menus.push(this.reports[k]);
          }
        }

        this.menus.sort(this.sortFunction);
      }
    });
  }
  sortFunction(a, b) {
    var dateA = a.SEQ_NO;
    var dateB = b.SEQ_NO;
    return dateA > dateB ? 1 : -1;
  }

  isSpinning = false;

  logout() {
    this.isSpinning = true;

    setTimeout(() => {
      this.api.logoutForSessionValues();
      this.isSpinning = false;
    }, 1000);
  }

  modalName = 'Create Issue';
  PROJECT_ID = 0;
  ISSUE_TYPE = '';

  MODULE_NAME = '';
  MODULE_DESCRIPTION = '';
  MODULE_ASSIGNEE_ID = [];
  MODULE_START_DATE: Date = null;
  MODULE_END_DATE: Date = null;
  MODULE_ESTIMATED_MANDAYS = 0;

  MODULE_ID = 0;
  FEATURE_NAME = '';
  FEATURE_DESCRIPTION = '';
  FEATURE_ASSIGNEE_ID = [];
  FEATURE_START_DATE: Date = null;
  FEATURE_END_DATE: Date = null;
  FEATURE_ESTIMATED_MANDAYS = 0;

  TASK_MODULE_ID = 0;
  TASK_FEATURE_ID = 0;
  TASK_NAME = '';
  TASK_DESCRIPTION = '';
  TASK_ASSIGNEE_ID = [];
  TASK_START_DATE: Date = null;
  TASK_END_DATE: Date = null;
  TASK_CATEGORY_ID = 0;
  TASK_PRIORITY = 'L';
  TASK_ESTIMATED_HOURS = 0;
  TASK_ESTIMATED_MINUTES = 0;
  TASK_ESTIMATED_SECONDS = 0;

  SUBTASK_MODULE_ID = 0;
  SUBTASK_FEATURE_ID = 0;
  PARENT_TASK_ID = 0;
  SUBTASK_NAME = '';
  SUBTASK_DESCRIPTION = '';
  SUBTASK_ASSIGNEE_ID = [];
  SUBTASK_START_DATE: Date = null;
  SUBTASK_END_DATE: Date = null;
  SUBTASK_CATEGORY_ID = 0;
  SUBTASK_PRIORITY = 'L';
  SUBTASK_ESTIMATED_HOURS = 0;
  SUBTASK_ESTIMATED_MINUTES = 0;
  SUBTASK_ESTIMATED_SECONDS = 0;

  isVisible = false;

  moduleDrawerVisible: boolean = false;
  moduleDrawerData = new moduleMaster();

  featureDrawerVisible: boolean = false;
  featureDrawerData = new featureMaster();

  taskDrawerVisible: boolean = false;
  taskDrawerData = new taskSubtaskMaster();

  subtaskDrawerVisible: boolean = false;
  subtaskDrawerData = new taskSubtaskMaster();

  isListLoading = false;

  moduleButtonHidden = true;
  featureButtonHidden = true;
  taskButtonHidden = true;
  subtaskButtonHidden = true;

  showModal(): void {
    this.loadAllProjects();

    this.PROJECT_ID = 0;
    // var defaultProject = sessionStorage.getItem("projectID");
    // this.PROJECT_ID = parseInt(defaultProject);

    this.hideShowRadioButtons();
  }

  levelVar = '0';

  hideShowRadioButtons() {
    this.moduleButtonHidden = true;
    this.featureButtonHidden = true;
    this.taskButtonHidden = true;
    this.subtaskButtonHidden = true;

    this.isVisible = true;

    this.moduleDrawerVisible = false;
    this.featureDrawerVisible = false;
    this.taskDrawerVisible = false;
    this.subtaskDrawerVisible = false;

    this.ISSUE_TYPE = '';

    if (this.levelVar == '1' || this.levelVar == '2' || this.levelVar == '3') {
      this.moduleButtonHidden = false;
      this.featureButtonHidden = false;
      this.taskButtonHidden = false;
      this.subtaskButtonHidden = false;

      // } else if(this.cookie.get("level")== "3") {
      // if(this.cookie.get("roleName")== "Project Manager") {
      //   this.moduleButtonHidden= false;
      //   this.featureButtonHidden= false;
      //   this.taskButtonHidden= true;
      //   this.subtaskButtonHidden= true;

      // } else if(this.cookie.get("roleName")== "Bussiness Analyst" || this.cookie.get("roleName")== "Owner") {
      //   this.moduleButtonHidden= false;
      //   this.featureButtonHidden= true;
      //   this.taskButtonHidden= true;
      //   this.subtaskButtonHidden= true;
      // }
    } else if (this.levelVar == '4') {
      this.moduleButtonHidden = true;
      this.featureButtonHidden = true;
      this.taskButtonHidden = false;
      this.subtaskButtonHidden = false;
    } else if (this.levelVar == '5') {
      this.moduleButtonHidden = true;
      this.featureButtonHidden = true;
      this.taskButtonHidden = true;
      this.subtaskButtonHidden = false;
    }
  }

  isFeatureModuleLoading = false;

  isTaskModuleLoading = false;
  isTaskFeatureLoading = false;
  isTaskCategoryLoading = false;

  isSubtaskModuleLoading = false;
  isSubtaskFeatureLoading = false;
  isSubtaskParentTaskLoading = false;
  isSubtaskCategoryLoading = false;

  radioButtonChange() {
    var isOK = true;
    this.moduleDrawerVisible = false;
    this.featureDrawerVisible = false;
    this.taskDrawerVisible = false;
    this.subtaskDrawerVisible = false;

    this.loadAllRoles();
    this.loadAllAssignee();

    if (this.PROJECT_ID != undefined) {
      if (
        this.PROJECT_ID.toString() == '' ||
        this.PROJECT_ID.toString() == 'NaN' ||
        this.PROJECT_ID == 0
      ) {
        isOK = false;
        this.notificationService.error('Please Select Valid Project', '');
      }
    } else {
      isOK = false;
      this.notificationService.error('Please Select Valid Project', '');
    }

    this.loadAllModules();
    this.loadAllCategories();

    if (isOK) {
      // sessionStorage.setItem("projectID", this.PROJECT_ID.toString());

      if (this.ISSUE_TYPE == 'M') {
        this.moduleDrawerVisible = true;
      } else if (this.ISSUE_TYPE == 'F') {
        this.featureDrawerVisible = true;
        this.isFeatureModuleLoading = true;
      } else if (this.ISSUE_TYPE == 'T') {
        this.taskDrawerVisible = true;
        this.isTaskModuleLoading = true;
        this.isTaskCategoryLoading = true;
      } else if (this.ISSUE_TYPE == 'S') {
        this.subtaskDrawerVisible = true;
        this.isSubtaskModuleLoading = true;
        this.isSubtaskCategoryLoading = true;
      }
    }
  }

  mappingData: any = [];

  handleOk(clear: true): void {
    if (this.ISSUE_TYPE == 'M') {
      var isOK = true;

      if (this.PROJECT_ID != undefined) {
        if (
          this.PROJECT_ID.toString() == '' ||
          this.PROJECT_ID.toString() == 'NaN' ||
          this.PROJECT_ID == 0
        ) {
          isOK = false;
          this.notificationService.error('Please Select Valid Project', '');
        }
      } else {
        isOK = false;
        this.notificationService.error('Please Select Valid Project', '');
      }

      if (this.MODULE_NAME != undefined) {
        if (this.MODULE_NAME.trim() == '') {
          isOK = false;
          this.notificationService.error('Please Enter Valid Module Name', '');
        }
      }

      // if(this.MODULE_DESCRIPTION!= undefined) {
      //   if(this.MODULE_DESCRIPTION.trim()== "") {
      //     isOK= false;
      //     this.notificationService.error("Please Enter Valid Module Description","");
      //   }
      // }

      if (this.MODULE_ASSIGNEE_ID != undefined) {
        if (this.MODULE_ASSIGNEE_ID.length == 0) {
          isOK = false;
          this.notificationService.error('Please Select Valid Assignee', '');
        }
      } else {
        isOK = false;
        this.notificationService.error('Please Select Valid Assignee', '');
      }

      // if (this.MODULE_START_DATE != undefined) {
      //   if (this.MODULE_START_DATE == null) {
      //     isOK = false;
      //     this.notificationService.error("Please Select Valid Start Date", "");
      //   }
      // } else {
      //   isOK = false;
      //   this.notificationService.error("Please Select Valid Start Date", "");
      // }

      // if (this.MODULE_END_DATE != undefined) {
      //   if (this.MODULE_END_DATE == null) {
      //     isOK = false;
      //     this.notificationService.error("Please Select Valid End Date", "");
      //   }
      // } else {
      //   isOK = false;
      //   this.notificationService.error("Please Select Valid End Date", "");
      // }

      if (this.MODULE_ESTIMATED_MANDAYS != undefined) {
        if (this.MODULE_ESTIMATED_MANDAYS == null) {
          isOK = false;
          this.notificationService.error('Please Enter Valid Mandays', '');
        }
      } else {
        isOK = false;
        this.notificationService.error('Please Enter Valid Mandays', '');
      }

      if (isOK) {
        this.moduleDrawerData.PROJECT_ID = this.PROJECT_ID;
        this.moduleDrawerData.NAME = this.MODULE_NAME;
        this.moduleDrawerData.DESCRIPTION = this.MODULE_DESCRIPTION;
        this.moduleDrawerData.ESTIMATED_MANDAYS = this.MODULE_ESTIMATED_MANDAYS;
        this.moduleDrawerData.ESTIMATED_HOURS = 0;
        this.moduleDrawerData.ESTIMATED_MINUTES = 0;
        this.moduleDrawerData.ESTIMATED_SECONDS = 0;
        this.moduleDrawerData.START_DATE = this.datePipe.transform(
          this.MODULE_START_DATE,
          'yyyy-MM-dd'
        );
        this.moduleDrawerData.END_DATE = this.datePipe.transform(
          this.MODULE_END_DATE,
          'yyyy-MM-dd'
        );
        this.moduleDrawerData.STATUS = 'P';
        this.moduleDrawerData.IS_ACTIVE = true;

        this.moduleDrawerData.ASSIGNEES = this.MODULE_ASSIGNEE_ID;
        this.moduleDrawerData.REPORTER_ID = this.api.userId;
        this.moduleDrawerData.TYPE = 2;

        console.log(this.moduleDrawerData);

        this.api.createModule(this.moduleDrawerData).subscribe(
          (successCode) => {
            if (successCode['code'] == '200') {
              this.notificationService.success(
                'Module Successfully Created',
                ''
              );
              // this.handleCancel();
              // this.modalClear();

              // Get Module Mapping Details
              console.log(this.MODULE_ASSIGNEE_ID);
              var createdModuleID = successCode['insertId'];

              this.api.getModuleDetails(createdModuleID, 0).subscribe(
                (data) => {
                  if (data['code'] == '200') {
                    this.mappingData = data['data'];
                    console.log(this.mappingData);

                    for (var i = 0; i < this.mappingData.length; i++) {
                      for (var j = 0; j < this.MODULE_ASSIGNEE_ID.length; j++) {
                        if (
                          this.mappingData[i]['USER_ID'] ==
                          this.MODULE_ASSIGNEE_ID[j]
                        ) {
                          this.mappingData[i]['IS_ACTIVE'] = 1;
                          this.mappingData[i]['ROLE_ID'] = Number(
                            this.mappingData[i]['ROLE_IDS']
                          );
                        }
                      }
                    }

                    console.log(this.mappingData);

                    this.api
                      .addModuleMappingDetails(
                        createdModuleID,
                        this.mappingData
                      )
                      .subscribe((successCode) => {
                        if (successCode['code'] == '200') {
                          this.notificationService.success(
                            'Module Successfully Mapped',
                            ''
                          );

                          if (clear) {
                            this.modalClear();
                            this.handleCancel();
                          } else {
                            this.clearOnlyTextFields();
                          }
                        } else {
                          this.notificationService.error(
                            'Module Mapping Failed',
                            ''
                          );
                        }
                      });
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
            } else {
              this.notificationService.error('Failed to Create Module', '');
            }
          },
          (err) => {
            if (err['ok'] == false) {
              this.notificationService.error('Server Not Found', '');
            }
          }
        );
      }
    } else if (this.ISSUE_TYPE == 'F') {
      var isOK = true;

      if (this.PROJECT_ID != undefined) {
        if (
          this.PROJECT_ID.toString() == '' ||
          this.PROJECT_ID.toString() == 'NaN' ||
          this.PROJECT_ID == 0
        ) {
          isOK = false;
          this.notificationService.error('Please Select Valid Project', '');
        }
      } else {
        isOK = false;
        this.notificationService.error('Please Select Valid Project', '');
      }

      if (this.MODULE_ID != undefined) {
        if (this.MODULE_ID == null || this.MODULE_ID == 0) {
          isOK = false;
          this.notificationService.error('Please Select Valid Module', '');
        }
      }

      if (this.FEATURE_NAME != undefined) {
        if (this.FEATURE_NAME.trim() == '') {
          isOK = false;
          this.notificationService.error('Please Enter Valid Feature Name', '');
        }
      }

      // if(this.FEATURE_DESCRIPTION!= undefined) {
      //   if(this.FEATURE_DESCRIPTION.trim()== "") {
      //     isOK= false;
      //     this.notificationService.error("Please Enter Valid Feature Description","");
      //   }
      // }

      if (this.FEATURE_ASSIGNEE_ID != undefined) {
        if (this.FEATURE_ASSIGNEE_ID.length == 0) {
          isOK = false;
          this.notificationService.error('Please Select Valid Assignee', '');
        }
      } else {
        isOK = false;
        this.notificationService.error('Please Select Valid Assignee', '');
      }

      // if (this.FEATURE_START_DATE != undefined) {
      //   if (this.FEATURE_START_DATE == null) {
      //     isOK = false;
      //     this.notificationService.error("Please Select Valid Start Date", "");
      //   }
      // } else {
      //   isOK = false;
      //   this.notificationService.error("Please Select Valid Start Date", "");
      // }

      // if (this.FEATURE_END_DATE != undefined) {
      //   if (this.FEATURE_END_DATE == null) {
      //     isOK = false;
      //     this.notificationService.error("Please Select Valid End Date", "");
      //   }
      // } else {
      //   isOK = false;
      //   this.notificationService.error("Please Select Valid End Date", "");
      // }

      if (this.FEATURE_ESTIMATED_MANDAYS != undefined) {
        if (this.FEATURE_ESTIMATED_MANDAYS == null) {
          isOK = false;
          this.notificationService.error('Please Enter Valid Mandays', '');
        }
      } else {
        isOK = false;
        this.notificationService.error('Please Enter Valid Mandays', '');
      }

      if (isOK) {
        this.featureDrawerData.MODULE_ID = this.MODULE_ID;
        this.featureDrawerData.NAME = this.FEATURE_NAME;
        this.featureDrawerData.DESCRIPTION = this.FEATURE_DESCRIPTION;
        this.featureDrawerData.ESTIMATED_MANDAYS =
          this.FEATURE_ESTIMATED_MANDAYS;
        this.featureDrawerData.ESTIMATED_HOURS = 0;
        this.featureDrawerData.ESTIMATED_MINUTES = 0;
        this.featureDrawerData.ESTIMATED_SECONDS = 0;
        this.featureDrawerData.START_DATE = this.datePipe.transform(
          this.FEATURE_START_DATE,
          'yyyy-MM-dd'
        );
        this.featureDrawerData.END_DATE = this.datePipe.transform(
          this.FEATURE_END_DATE,
          'yyyy-MM-dd'
        );
        this.featureDrawerData.STATUS = 'P';
        this.featureDrawerData.IS_ACTIVE = true;

        this.featureDrawerData.ASSIGNEES = this.FEATURE_ASSIGNEE_ID;
        this.featureDrawerData.REPORTER_ID = this.api.userId;
        this.featureDrawerData.TYPE = 3;

        console.log(this.featureDrawerData);

        this.api.createFeature(this.featureDrawerData).subscribe(
          (successCode) => {
            if (successCode['code'] == '200') {
              this.notificationService.success(
                'Feature Successfully Created',
                ''
              );
              // this.handleCancel();
              // this.modalClear();

              // Get Feature Mapping Details
              console.log(this.FEATURE_ASSIGNEE_ID);
              var createdFeatureID = successCode['insertId'];

              this.api.getFeatureDetails(createdFeatureID, 0).subscribe(
                (data) => {
                  if (data['code'] == '200') {
                    this.mappingData = data['data'];
                    console.log(this.mappingData);

                    for (var i = 0; i < this.mappingData.length; i++) {
                      for (
                        var j = 0;
                        j < this.FEATURE_ASSIGNEE_ID.length;
                        j++
                      ) {
                        if (
                          this.mappingData[i]['USER_ID'] ==
                          this.FEATURE_ASSIGNEE_ID[j]
                        ) {
                          this.mappingData[i]['IS_ACTIVE'] = 1;
                          this.mappingData[i]['ROLE_ID'] = Number(
                            this.mappingData[i]['ROLE_IDS']
                          );
                        }
                      }
                    }

                    console.log(this.mappingData);

                    this.api
                      .addFeatureMappingDetails(
                        createdFeatureID,
                        this.mappingData
                      )
                      .subscribe((successCode) => {
                        if (successCode['code'] == '200') {
                          this.notificationService.success(
                            'Feature Successfully Mapped',
                            ''
                          );

                          if (clear) {
                            this.modalClear();
                            this.handleCancel();
                          } else {
                            this.clearOnlyTextFields();
                          }
                        } else {
                          this.notificationService.error(
                            'Feature Mapping Failed',
                            ''
                          );
                        }
                      });
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
            } else {
              this.notificationService.error('Failed to Create Feature', '');
            }
          },
          (err) => {
            if (err['ok'] == false) {
              this.notificationService.error('Server Not Found', '');
            }
          }
        );
      }
    } else if (this.ISSUE_TYPE == 'T') {
      var isOK = true;

      if (this.PROJECT_ID != undefined) {
        if (
          this.PROJECT_ID.toString() == '' ||
          this.PROJECT_ID.toString() == 'NaN' ||
          this.PROJECT_ID == 0
        ) {
          isOK = false;
          this.notificationService.error('Please Select Valid Project', '');
        }
      } else {
        isOK = false;
        this.notificationService.error('Please Select Valid Project', '');
      }

      if (this.TASK_MODULE_ID != undefined) {
        if (this.TASK_MODULE_ID == null || this.TASK_MODULE_ID == 0) {
          isOK = false;
          this.notificationService.error('Please Select Valid Module', '');
        }
      }

      if (this.TASK_FEATURE_ID != undefined) {
        if (this.TASK_FEATURE_ID == null || this.TASK_FEATURE_ID == 0) {
          isOK = false;
          this.notificationService.error('Please Select Valid Feature', '');
        }
      }

      if (this.TASK_NAME != undefined) {
        if (this.TASK_NAME.trim() == '') {
          isOK = false;
          this.notificationService.error('Please Enter Valid Task Name', '');
        }
      }

      // if(this.TASK_DESCRIPTION!= undefined) {
      //   if(this.TASK_DESCRIPTION.trim()== "") {
      //     isOK= false;
      //     this.notificationService.error("Please Enter Valid Task Description","");
      //   }
      // }

      if (this.TASK_ASSIGNEE_ID != undefined) {
        if (this.TASK_ASSIGNEE_ID.length == 0) {
          isOK = false;
          this.notificationService.error('Please Select Valid Assignee', '');
        }
      } else {
        isOK = false;
        this.notificationService.error('Please Select Valid Assignee', '');
      }

      if (this.TASK_CATEGORY_ID != undefined) {
        if (this.TASK_CATEGORY_ID == null || this.TASK_CATEGORY_ID == 0) {
          isOK = false;
          this.notificationService.error(
            'Please Select Valid Task Category',
            ''
          );
        }
      }

      if (this.TASK_PRIORITY != undefined) {
        if (this.TASK_PRIORITY == '') {
          isOK = false;
          this.notificationService.error(
            'Please Select Valid Task Priority',
            ''
          );
        }
      }

      // if (this.TASK_START_DATE != undefined) {
      //   if (this.TASK_START_DATE == null) {
      //     isOK = false;
      //     this.notificationService.error("Please Select Valid Start Date", "");
      //   }
      // } else {
      //   isOK = false;
      //   this.notificationService.error("Please Select Valid Start Date", "");
      // }

      // if (this.TASK_END_DATE != undefined) {
      //   if (this.TASK_END_DATE == null) {
      //     isOK = false;
      //     this.notificationService.error("Please Select Valid End Date", "");
      //   }
      // } else {
      //   isOK = false;
      //   this.notificationService.error("Please Select Valid End Date", "");
      // }

      if (this.TASK_ESTIMATED_HOURS != undefined) {
        if (this.TASK_ESTIMATED_HOURS == null) {
          isOK = false;
          this.notificationService.error(
            'Please Enter Valid Estimate Hours',
            ''
          );
        }
      } else {
        isOK = false;
        this.notificationService.error('Please Enter Valid Estimate Hours', '');
      }

      if (this.TASK_ESTIMATED_MINUTES != undefined) {
        if (this.TASK_ESTIMATED_MINUTES == null) {
          isOK = false;
          this.notificationService.error(
            'Please Enter Valid Estimate Minutes',
            ''
          );
        }
      } else {
        isOK = false;
        this.notificationService.error(
          'Please Enter Valid Estimate Minutes',
          ''
        );
      }

      if (this.TASK_ESTIMATED_SECONDS != undefined) {
        if (this.TASK_ESTIMATED_SECONDS == null) {
          isOK = false;
          this.notificationService.error(
            'Please Enter Valid Estimate Seconds',
            ''
          );
        }
      } else {
        isOK = false;
        this.notificationService.error(
          'Please Enter Valid Estimate Seconds',
          ''
        );
      }

      if (isOK) {
        this.taskDrawerData.TASK_CATEGORY_ID = this.TASK_CATEGORY_ID;
        this.taskDrawerData.FEATURE_ID = this.TASK_FEATURE_ID;
        this.taskDrawerData.PARENT_ID = 0;
        this.taskDrawerData.NAME = this.TASK_NAME;
        this.taskDrawerData.DESCRIPTION = this.TASK_DESCRIPTION;
        this.taskDrawerData.ESTIMATED_HOURS = this.TASK_ESTIMATED_HOURS;
        this.taskDrawerData.ESTIMATED_MINUTES = this.TASK_ESTIMATED_MINUTES;
        this.taskDrawerData.ESTIMATED_SECONDS = this.TASK_ESTIMATED_SECONDS;

        this.taskDrawerData.PRIORITY = this.TASK_PRIORITY;

        this.taskDrawerData.TIME_TRACKED = new Date();
        this.taskDrawerData.TIME_TRACKED =
          this.datePipe.transform(this.taskDrawerData.TIME_TRACKED, 'HH:mm') +
          ':00';

        this.taskDrawerData.STATUS = 'P';

        this.taskDrawerData.START_DATE = this.datePipe.transform(
          this.TASK_START_DATE,
          'yyyy-MM-dd'
        );
        this.taskDrawerData.END_DATE = this.datePipe.transform(
          this.TASK_END_DATE,
          'yyyy-MM-dd'
        );

        this.taskDrawerData.IS_CHILD = false;
        this.taskDrawerData.DEPENDENT_TASK_ID = 0;

        this.taskDrawerData.UPDATED_DATETIME = new Date();
        this.taskDrawerData.UPDATED_DATETIME =
          this.datePipe.transform(
            this.taskDrawerData.UPDATED_DATETIME,
            'yyyy-MM-dd HH:mm'
          ) + ':00';

        this.taskDrawerData.TAGS = '';
        this.taskDrawerData.PROGRESS = 0;
        this.taskDrawerData.IS_ACTIVE = true;

        this.taskDrawerData.ASSIGNEES = this.TASK_ASSIGNEE_ID;
        this.taskDrawerData.REPORTER_ID = this.api.userId;
        this.taskDrawerData.TYPE = 4;

        console.log(this.taskDrawerData);

        this.api.createTaskSubtask(this.taskDrawerData).subscribe(
          (successCode) => {
            if (successCode['code'] == '200') {
              this.notificationService.success('Task Successfully Created', '');
              // this.handleCancel();
              // this.modalClear();

              // Get Task Mapping Details
              console.log(this.TASK_ASSIGNEE_ID);
              var createdTaskID = successCode['insertId'];

              this.api.getTaskSubtaskDetails(createdTaskID, 0).subscribe(
                (data) => {
                  if (data['code'] == '200') {
                    this.mappingData = data['data'];
                    console.log(this.mappingData);

                    for (var i = 0; i < this.mappingData.length; i++) {
                      for (var j = 0; j < this.TASK_ASSIGNEE_ID.length; j++) {
                        if (
                          this.mappingData[i]['USER_ID'] ==
                          this.TASK_ASSIGNEE_ID[j]
                        ) {
                          this.mappingData[i]['IS_ACTIVE'] = 1;
                          this.mappingData[i]['ROLE_ID'] = Number(
                            this.mappingData[i]['ROLE_IDS']
                          );
                        }
                      }
                    }

                    console.log(this.mappingData);

                    this.api
                      .addTaskSubtaskMappingDetails(
                        createdTaskID,
                        this.mappingData
                      )
                      .subscribe((successCode) => {
                        if (successCode['code'] == '200') {
                          this.notificationService.success(
                            'Task Successfully Mapped',
                            ''
                          );

                          if (clear) {
                            this.modalClear();
                            this.handleCancel();
                          } else {
                            this.clearOnlyTextFields();
                          }
                        } else {
                          this.notificationService.error(
                            'Task Mapping Failed',
                            ''
                          );
                        }
                      });
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
            } else {
              this.notificationService.error('Failed to Create Task', '');
            }
          },
          (err) => {
            if (err['ok'] == false) {
              this.notificationService.error('Server Not Found', '');
            }
          }
        );
      }
    } else if (this.ISSUE_TYPE == 'S') {
      var isOK = true;

      if (this.PROJECT_ID != undefined) {
        if (
          this.PROJECT_ID.toString() == '' ||
          this.PROJECT_ID.toString() == 'NaN' ||
          this.PROJECT_ID == 0
        ) {
          isOK = false;
          this.notificationService.error('Please Select Valid Project', '');
        }
      } else {
        isOK = false;
        this.notificationService.error('Please Select Valid Project', '');
      }

      if (this.SUBTASK_MODULE_ID != undefined) {
        if (this.SUBTASK_MODULE_ID == null || this.SUBTASK_MODULE_ID == 0) {
          isOK = false;
          this.notificationService.error('Please Select Valid Module', '');
        }
      }

      if (this.SUBTASK_FEATURE_ID != undefined) {
        if (this.SUBTASK_FEATURE_ID == null || this.SUBTASK_FEATURE_ID == 0) {
          isOK = false;
          this.notificationService.error('Please Select Valid Feature', '');
        }
      }

      if (this.PARENT_TASK_ID != undefined) {
        if (this.PARENT_TASK_ID == null || this.PARENT_TASK_ID == 0) {
          isOK = false;
          this.notificationService.error('Please Select Valid Parent Task', '');
        }
      }

      if (this.SUBTASK_NAME != undefined) {
        if (this.SUBTASK_NAME.trim() == '') {
          isOK = false;
          this.notificationService.error('Please Enter Valid Subtask Name', '');
        }
      }

      // if(this.SUBTASK_DESCRIPTION!= undefined) {
      //   if(this.SUBTASK_DESCRIPTION.trim()== "") {
      //     isOK= false;
      //     this.notificationService.error("Please Enter Valid Subtask Description","");
      //   }
      // }

      if (this.SUBTASK_ASSIGNEE_ID != undefined) {
        if (this.SUBTASK_ASSIGNEE_ID.length == 0) {
          isOK = false;
          this.notificationService.error('Please Select Valid Assignee', '');
        }
      } else {
        isOK = false;
        this.notificationService.error('Please Select Valid Assignee', '');
      }

      if (this.SUBTASK_CATEGORY_ID != undefined) {
        if (this.SUBTASK_CATEGORY_ID == null || this.SUBTASK_CATEGORY_ID == 0) {
          isOK = false;
          this.notificationService.error(
            'Please Select Valid Subtask Category',
            ''
          );
        }
      }

      if (this.SUBTASK_PRIORITY != undefined) {
        if (this.SUBTASK_PRIORITY == '') {
          isOK = false;
          this.notificationService.error(
            'Please Select Valid Subtask Priority',
            ''
          );
        }
      }

      // if (this.SUBTASK_START_DATE != undefined) {
      //   if (this.SUBTASK_START_DATE == null) {
      //     isOK = false;
      //     this.notificationService.error("Please Select Valid Start Date", "");
      //   }
      // } else {
      //   isOK = false;
      //   this.notificationService.error("Please Select Valid Start Date", "");
      // }

      // if (this.SUBTASK_END_DATE != undefined) {
      //   if (this.SUBTASK_END_DATE == null) {
      //     isOK = false;
      //     this.notificationService.error("Please Select Valid End Date", "");
      //   }
      // } else {
      //   isOK = false;
      //   this.notificationService.error("Please Select Valid End Date", "");
      // }

      if (this.SUBTASK_ESTIMATED_HOURS != undefined) {
        if (this.SUBTASK_ESTIMATED_HOURS == null) {
          isOK = false;
          this.notificationService.error(
            'Please Enter Valid Estimate Hours',
            ''
          );
        }
      } else {
        isOK = false;
        this.notificationService.error('Please Enter Valid Estimate Hours', '');
      }

      if (this.SUBTASK_ESTIMATED_MINUTES != undefined) {
        if (this.SUBTASK_ESTIMATED_MINUTES == null) {
          isOK = false;
          this.notificationService.error(
            'Please Enter Valid Estimate Minutes',
            ''
          );
        }
      } else {
        isOK = false;
        this.notificationService.error(
          'Please Enter Valid Estimate Minutes',
          ''
        );
      }

      if (this.SUBTASK_ESTIMATED_SECONDS != undefined) {
        if (this.SUBTASK_ESTIMATED_SECONDS == null) {
          isOK = false;
          this.notificationService.error(
            'Please Enter Valid Estimate Seconds',
            ''
          );
        }
      } else {
        isOK = false;
        this.notificationService.error(
          'Please Enter Valid Estimate Seconds',
          ''
        );
      }

      if (isOK) {
        this.taskDrawerData.TASK_CATEGORY_ID = this.SUBTASK_CATEGORY_ID;
        this.taskDrawerData.FEATURE_ID = this.SUBTASK_FEATURE_ID;
        this.taskDrawerData.PARENT_ID = this.PARENT_TASK_ID;
        this.taskDrawerData.NAME = this.SUBTASK_NAME;
        this.taskDrawerData.DESCRIPTION = this.SUBTASK_DESCRIPTION;
        this.taskDrawerData.ESTIMATED_HOURS = this.SUBTASK_ESTIMATED_HOURS;
        this.taskDrawerData.ESTIMATED_MINUTES = this.SUBTASK_ESTIMATED_MINUTES;
        this.taskDrawerData.ESTIMATED_SECONDS = this.SUBTASK_ESTIMATED_SECONDS;

        this.taskDrawerData.PRIORITY = this.SUBTASK_PRIORITY;

        this.taskDrawerData.TIME_TRACKED = new Date();
        this.taskDrawerData.TIME_TRACKED =
          this.datePipe.transform(this.taskDrawerData.TIME_TRACKED, 'HH:mm') +
          ':00';

        this.taskDrawerData.STATUS = 'P';

        this.taskDrawerData.START_DATE = this.datePipe.transform(
          this.SUBTASK_START_DATE,
          'yyyy-MM-dd'
        );
        this.taskDrawerData.END_DATE = this.datePipe.transform(
          this.SUBTASK_END_DATE,
          'yyyy-MM-dd'
        );

        this.taskDrawerData.IS_CHILD = false;
        this.taskDrawerData.DEPENDENT_TASK_ID = 0;

        this.taskDrawerData.UPDATED_DATETIME = new Date();
        this.taskDrawerData.UPDATED_DATETIME =
          this.datePipe.transform(
            this.taskDrawerData.UPDATED_DATETIME,
            'yyyy-MM-dd HH:mm'
          ) + ':00';

        this.taskDrawerData.TAGS = '';
        this.taskDrawerData.PROGRESS = 0;
        this.taskDrawerData.IS_ACTIVE = true;

        this.taskDrawerData.ASSIGNEES = this.SUBTASK_ASSIGNEE_ID;
        this.taskDrawerData.REPORTER_ID = this.api.userId;
        this.taskDrawerData.TYPE = 5;

        console.log(this.taskDrawerData);

        this.api.createTaskSubtask(this.taskDrawerData).subscribe(
          (successCode) => {
            if (successCode['code'] == '200') {
              this.notificationService.success(
                'Subtask Successfully Created',
                ''
              );
              // this.handleCancel();
              // this.modalClear();

              // Get Subtask Mapping Details
              console.log(this.SUBTASK_ASSIGNEE_ID);
              var createdTaskID = successCode['insertId'];

              this.api.getTaskSubtaskDetails(createdTaskID, 0).subscribe(
                (data) => {
                  if (data['code'] == '200') {
                    this.mappingData = data['data'];
                    console.log(this.mappingData);

                    for (var i = 0; i < this.mappingData.length; i++) {
                      for (
                        var j = 0;
                        j < this.SUBTASK_ASSIGNEE_ID.length;
                        j++
                      ) {
                        if (
                          this.mappingData[i]['USER_ID'] ==
                          this.SUBTASK_ASSIGNEE_ID[j]
                        ) {
                          this.mappingData[i]['IS_ACTIVE'] = 1;
                          this.mappingData[i]['ROLE_ID'] = Number(
                            this.mappingData[i]['ROLE_IDS']
                          );
                        }
                      }
                    }

                    console.log(this.mappingData);

                    this.api
                      .addTaskSubtaskMappingDetails(
                        createdTaskID,
                        this.mappingData
                      )
                      .subscribe((successCode) => {
                        if (successCode['code'] == '200') {
                          this.notificationService.success(
                            'Subtask Successfully Mapped',
                            ''
                          );

                          if (clear) {
                            this.modalClear();
                            this.handleCancel();
                          } else {
                            this.clearOnlyTextFields();
                          }
                        } else {
                          this.notificationService.error(
                            'Subtask Mapping Failed',
                            ''
                          );
                        }
                      });
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
            } else {
              this.notificationService.error('Failed to Create Subtask', '');
            }
          },
          (err) => {
            if (err['ok'] == false) {
              this.notificationService.error('Server Not Found', '');
            }
          }
        );
      }
    }
  }

  moduleDrawerClose() {
    this.moduleDrawerVisible = false;
  }

  callBackModuleDrawerClose() {
    this.moduleDrawerClose.bind(this);
  }

  featureDrawerClose() {
    this.featureDrawerVisible = false;
  }

  callBackFeatureDrawerClose() {
    this.featureDrawerClose.bind(this);
  }

  handleCancel(): void {
    this.isVisible = false;
    this.modalClear();

    window.location.reload();
  }

  modalClear() {
    this.ISSUE_TYPE = '';
    this.moduleDrawerVisible = false;
    this.featureDrawerVisible = false;
    this.taskDrawerVisible = false;
    this.subtaskDrawerVisible = false;

    this.MODULE_NAME = '';
    this.MODULE_DESCRIPTION = '';
    this.MODULE_ASSIGNEE_ID = [];
    this.MODULE_START_DATE = null;
    this.MODULE_END_DATE = null;
    this.MODULE_ESTIMATED_MANDAYS = 0;

    this.MODULE_ID = 0;
    this.FEATURE_NAME = '';
    this.FEATURE_DESCRIPTION = '';
    this.FEATURE_ASSIGNEE_ID = [];
    this.FEATURE_START_DATE = null;
    this.FEATURE_END_DATE = null;
    this.FEATURE_ESTIMATED_MANDAYS = 0;

    this.TASK_MODULE_ID = 0;
    this.TASK_FEATURE_ID = 0;
    this.TASK_NAME = '';
    this.TASK_DESCRIPTION = '';
    this.TASK_ASSIGNEE_ID = [];
    this.TASK_START_DATE = null;
    this.TASK_END_DATE = null;
    this.TASK_CATEGORY_ID = 0;
    this.TASK_PRIORITY = 'L';
    this.TASK_ESTIMATED_HOURS = 0;
    this.TASK_ESTIMATED_MINUTES = 0;
    this.TASK_ESTIMATED_SECONDS = 0;

    this.SUBTASK_MODULE_ID = 0;
    this.SUBTASK_FEATURE_ID = 0;
    this.PARENT_TASK_ID = 0;
    this.SUBTASK_NAME = '';
    this.SUBTASK_DESCRIPTION = '';
    this.SUBTASK_ASSIGNEE_ID = [];
    this.SUBTASK_START_DATE = null;
    this.SUBTASK_END_DATE = null;
    this.SUBTASK_CATEGORY_ID = 0;
    this.SUBTASK_PRIORITY = 'L';
    this.SUBTASK_ESTIMATED_HOURS = 0;
    this.SUBTASK_ESTIMATED_MINUTES = 0;
    this.SUBTASK_ESTIMATED_SECONDS = 0;
  }

  clearOnlyTextFields() {
    console.log('Clear only Text Field....');

    // this.ISSUE_TYPE = "";
    // this.moduleDrawerVisible = false;
    // this.featureDrawerVisible = false;
    // this.taskDrawerVisible = false;
    // this.subtaskDrawerVisible = false;

    this.MODULE_NAME = '';
    this.MODULE_DESCRIPTION = '';
    // this.MODULE_ASSIGNEE_ID = [];
    this.MODULE_START_DATE = null;
    this.MODULE_END_DATE = null;
    this.MODULE_ESTIMATED_MANDAYS = 0;

    // this.MODULE_ID = 0;
    this.FEATURE_NAME = '';
    this.FEATURE_DESCRIPTION = '';
    // this.FEATURE_ASSIGNEE_ID = [];
    this.FEATURE_START_DATE = null;
    this.FEATURE_END_DATE = null;
    this.FEATURE_ESTIMATED_MANDAYS = 0;

    // this.TASK_MODULE_ID = 0;
    // this.TASK_FEATURE_ID = 0;
    this.TASK_NAME = '';
    this.TASK_DESCRIPTION = '';
    // this.TASK_ASSIGNEE_ID = [];
    this.TASK_START_DATE = null;
    this.TASK_END_DATE = null;
    // this.TASK_CATEGORY_ID = 0;
    this.TASK_PRIORITY = 'L';
    this.TASK_ESTIMATED_HOURS = 0;
    this.TASK_ESTIMATED_MINUTES = 0;
    this.TASK_ESTIMATED_SECONDS = 0;

    // this.SUBTASK_MODULE_ID = 0;
    // this.SUBTASK_FEATURE_ID = 0;
    // this.PARENT_TASK_ID = 0;
    this.SUBTASK_NAME = '';
    this.SUBTASK_DESCRIPTION = '';
    // this.SUBTASK_ASSIGNEE_ID = [];
    this.SUBTASK_START_DATE = null;
    this.SUBTASK_END_DATE = null;
    // this.SUBTASK_CATEGORY_ID = 0;
    this.SUBTASK_PRIORITY = 'L';
    this.SUBTASK_ESTIMATED_HOURS = 0;
    this.SUBTASK_ESTIMATED_MINUTES = 0;
    this.SUBTASK_ESTIMATED_SECONDS = 0;
  }

  projectList = [];

  loadAllProjects() {
    this.projectList = [];
    this.isListLoading = true;

    console.log(this.cookie.get('level'));
    if (
      sessionStorage.getItem('level') == '1' ||
      sessionStorage.getItem('level') == '2' ||
      sessionStorage.getItem('level') == '3'
    ) {
      this.api
        .getAllProjects(0, 0, 'START_DATE', 'desc', ' and IS_ACTIVE=1')
        .subscribe(
          (projectData) => {
            if (projectData['code'] == 200) {
              this.isListLoading = false;
              console.log(projectData);
              this.projectList = projectData['data'];
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
      this.api
        .getAllMappedProjects(
          0,
          0,
          'START_DATE',
          'desc',
          ' and IS_ACTIVE=1 and USER_ID=' + this.api.userId
        )
        .subscribe(
          (projectData) => {
            if (projectData['code'] == 200) {
              this.isListLoading = false;
              console.log(projectData);
              this.projectList = projectData['data'];
            }
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }

  assigneeList = [];

  loadAllAssignee() {
    this.assigneeList = [];
    var USER_ID = this.api.userId;

    // this.api.getAllUsers(0, 0, 'ID', 'desc', ' and ID!=' + USER_ID).subscribe(userData => {
    //   console.log("Assignee");
    //   console.log(userData);
    //   this.assigneeList = userData['data'];

    // }, err => {
    //   console.log(err);
    // });

    this.api
      .getAllMappedUsers(
        0,
        0,
        'LEVEL',
        'asc',
        ' and IS_ACTIVE=1 and PROJECT_ID=' + this.PROJECT_ID
      )
      .subscribe(
        (userData) => {
          if (userData['code'] == 200) {
            console.log('Assignee');
            console.log(userData);
            this.assigneeList = userData['data'];

            this.findNewAssigneeList();
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  roleData = [];

  loadAllRoles() {
    this.roleData = [];

    var level = sessionStorage.getItem('level');
    var nextLevel = Number(sessionStorage.getItem('level')) + 1;

    level = level + ',' + nextLevel;

    if (
      sessionStorage.getItem('level') == '1' ||
      sessionStorage.getItem('level') == '2'
    ) {
      this.api.getAllRoles(0, 0, 'ID', 'desc', '').subscribe(
        (data) => {
          if (data['code'] == 200) {
            console.log('Roles');
            console.log(data);
            this.roleData = data['data'];

            this.findNewAssigneeList();
          }
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      // this.api.getAllRoles(0, 0, 'ID', 'desc', ' and LEVEL in (' + level + ')').subscribe(data => {
      this.api.getAllRoles(0, 0, 'ID', 'asc', '').subscribe(
        (data) => {
          if (data['code'] == 200) {
            console.log('Roles');
            console.log(data);
            this.roleData = data['data'];

            this.findNewAssigneeList();
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  newAssigneeList = [];

  findNewAssigneeList() {
    this.newAssigneeList = [];

    for (var j = 0; j < this.roleData.length; j++) {
      for (var k = 0; k < this.assigneeList.length; k++) {
        if (this.assigneeList[k]['ROLE_ID'] == this.roleData[j]['ID']) {
          this.newAssigneeList.push(this.assigneeList[k]);
        }
      }
    }

    console.log('New Assignee');
    console.log(this.newAssigneeList);

    // Pre select of Level 3 Users
    this.MODULE_ASSIGNEE_ID = [];
    this.FEATURE_ASSIGNEE_ID = [];
    this.TASK_ASSIGNEE_ID = [];
    this.SUBTASK_ASSIGNEE_ID = [];

    var currentCat = 0;

    for (var i = 0; i < this.newAssigneeList.length; i++) {
      if (this.newAssigneeList[i]['LEVEL'] == 3) {
        this.MODULE_ASSIGNEE_ID.push(this.newAssigneeList[i]['USER_ID']);
        this.TASK_ASSIGNEE_ID.push(this.newAssigneeList[i]['USER_ID']);
        this.SUBTASK_ASSIGNEE_ID.push(this.newAssigneeList[i]['USER_ID']);
      }

      if (
        this.newAssigneeList[i]['LEVEL'] == 3 ||
        this.newAssigneeList[i]['LEVEL'] == 4
      ) {
        this.FEATURE_ASSIGNEE_ID.push(this.newAssigneeList[i]['USER_ID']);
      }

      if (this.newAssigneeList[i]['LEVEL'] == 4) {
        if (
          this.newAssigneeList[i]['USER_ID'] ==
          Number(sessionStorage.getItem('userId'))
        ) {
          this.TASK_ASSIGNEE_ID.push(this.newAssigneeList[i]['USER_ID']);
          this.SUBTASK_ASSIGNEE_ID.push(this.newAssigneeList[i]['USER_ID']);

          currentCat = this.newAssigneeList[i]['TASK_CATEGORY_ID'];
          this.TASK_CATEGORY_ID = currentCat;
        }
      }

      if (this.newAssigneeList[i]['LEVEL'] == 5) {
        if (this.newAssigneeList[i]['TASK_CATEGORY_ID'] == currentCat) {
          this.TASK_ASSIGNEE_ID.push(this.newAssigneeList[i]['USER_ID']);
          this.SUBTASK_ASSIGNEE_ID.push(this.newAssigneeList[i]['USER_ID']);
        }
      }
    }
  }

  loadModal() {
    this.getRoleProjectWise(this.PROJECT_ID);
  }

  moduleList = [];

  loadAllModules() {
    sessionStorage.setItem('projectID', this.PROJECT_ID.toString());

    if (this.ISSUE_TYPE == 'M') {
      this.moduleDrawerVisible = true;
    } else if (this.ISSUE_TYPE == 'F') {
      this.featureDrawerVisible = true;
    } else if (this.ISSUE_TYPE == 'T') {
      this.taskDrawerVisible = true;
    } else if (this.ISSUE_TYPE == 'S') {
      this.subtaskDrawerVisible = true;
    }

    this.moduleList = [];
    this.loadAllAssignee();

    // this.MODULE_ID = 0;
    // this.TASK_MODULE_ID = 0;
    // this.SUBTASK_MODULE_ID = 0;

    this.isFeatureModuleLoading = true;
    this.isTaskModuleLoading = true;
    this.isSubtaskModuleLoading = true;

    this.api
      .getAllModules(
        0,
        0,
        'ID',
        'asc',
        ' and IS_ACTIVE=1 and PROJECT_ID=' + this.PROJECT_ID
      )
      .subscribe(
        (moduleData) => {
          if (moduleData['code'] == 200) {
            console.log(moduleData);
            this.isFeatureModuleLoading = false;
            this.isTaskModuleLoading = false;
            this.isSubtaskModuleLoading = false;
            this.moduleList = moduleData['data'];
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  getRoleProjectWise(projectID) {
    this.api.getProjectDetails(projectID, 0).subscribe(
      (data) => {
        if (data['code'] == 200) {
          var roleData = data['data'];

          var roleData1 = roleData.filter((obj) => {
            return obj.USER_ID == this.api.userId;
          });

          // Get Level Role ID Wise
          this.api
            .getAllRoles(0, 0, '', '', ' and ID=' + roleData1[0]['ROLE_ID'])
            .subscribe(
              (data) => {
                if (data['code'] == 200) {
                  this.levelVar = data['data'][0]['LEVEL'];
                  this.hideShowRadioButtons();
                }
              },
              (err) => {
                if (err['ok'] == false)
                  this.notificationService.error('Server Not Found', '');
              }
            );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  fillTaskFeatureModuleWise(moduleID) {
    this.TASK_FEATURE_ID = 0;
    this.isTaskFeatureLoading = true;

    this.loadAllTaskFeatures(moduleID);
  }

  fillSubtaskFeatureModuleWise(moduleID) {
    this.SUBTASK_FEATURE_ID = 0;
    this.PARENT_TASK_ID = 0;
    this.isSubtaskFeatureLoading = true;

    this.loadAllSubtaskFeatures(moduleID);
  }

  taskFeatureList = [];
  loadAllTaskFeatures(moduleID) {
    this.taskFeatureList = [];

    this.api
      .getAllFeatures(
        0,
        0,
        'ID',
        'asc',
        ' and IS_ACTIVE=1 and MODULE_ID=' + moduleID
      )
      .subscribe(
        (featureData) => {
          if (featureData['code'] == 200) {
            this.isTaskFeatureLoading = false;
            this.taskFeatureList = featureData['data'];
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  subtaskFeatureList = [];
  loadAllSubtaskFeatures(moduleID) {
    this.subtaskFeatureList = [];

    this.api
      .getAllFeatures(
        0,
        0,
        'ID',
        'asc',
        ' and IS_ACTIVE=1 and MODULE_ID=' + moduleID
      )
      .subscribe(
        (featureData) => {
          if (featureData['code'] == 200) {
            this.isSubtaskFeatureLoading = false;
            this.subtaskFeatureList = featureData['data'];
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  categoryList = [];

  loadAllCategories() {
    this.categoryList = [];

    this.api.getAllCategories(0, 0, 'ID', 'asc', ' and IS_ACTIVE=1').subscribe(
      (categoryData) => {
        if (categoryData['code'] == 200) {
          this.isTaskCategoryLoading = false;
          this.isSubtaskCategoryLoading = false;
          this.categoryList = categoryData['data'];

          this.TASK_CATEGORY_ID = 0;
          this.SUBTASK_CATEGORY_ID = 0;
          var USER_ID = this.api.userId;

          this.api
            .getAllUsers(0, 0, 'ID', 'desc', ' and ID=' + USER_ID)
            .subscribe(
              (userData) => {
                this.TASK_CATEGORY_ID = userData['data'][0]['TASK_CATEGORY_ID'];
                this.SUBTASK_CATEGORY_ID =
                  userData['data'][0]['TASK_CATEGORY_ID'];
              },
              (err) => {
                console.log(err);
              }
            );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  fillTaskSubtaskFeatureWise(featureID) {
    this.PARENT_TASK_ID = 0;
    this.isSubtaskParentTaskLoading = true;
    this.loadAllParentTasks(featureID);
  }

  parentTaskList = [];
  loadAllParentTasks(featureID) {
    this.parentTaskList = [];

    this.api
      .getAllTaskSubtask(
        0,
        0,
        'ID',
        'asc',
        ' and IS_ACTIVE=1 and PARENT_ID=0 and FEATURE_ID=' + featureID
      )
      .subscribe(
        (taskData) => {
          if (taskData['code'] == 200) {
            this.isSubtaskParentTaskLoading = false;
            this.parentTaskList = taskData['data'];
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  @ViewChild('moduleEndDatePicker') moduleEndDatePicker: NzDatePickerComponent;

  moduleStartDateHandle(open: boolean) {
    this.MODULE_END_DATE = null;

    if (!open) {
      this.moduleEndDatePicker.open();
      this.MODULE_END_DATE = this.MODULE_START_DATE;
    }
  }

  @ViewChild('featureEndDatePicker')
  featureEndDatePicker: NzDatePickerComponent;

  featureStartDateHandle(open: boolean) {
    this.FEATURE_END_DATE = null;

    if (!open) {
      this.featureEndDatePicker.open();
      this.FEATURE_END_DATE = this.FEATURE_START_DATE;
    }
  }

  @ViewChild('taskEndDatePicker') taskEndDatePicker: NzDatePickerComponent;

  taskStartDateHandle(open: boolean) {
    this.TASK_END_DATE = null;

    if (!open) {
      this.taskEndDatePicker.open();
      this.TASK_END_DATE = this.TASK_START_DATE;
    }
  }

  @ViewChild('subtaskEndDatePicker')
  subtaskEndDatePicker: NzDatePickerComponent;

  subtaskStartDateHandle(open: boolean) {
    this.SUBTASK_END_DATE = null;

    if (!open) {
      this.subtaskEndDatePicker.open();
      this.SUBTASK_END_DATE = this.SUBTASK_START_DATE;
    }
  }

  disabledModuleEndDate = (endValue: Date): boolean => {
    if (this.MODULE_START_DATE != null) {
      if (!endValue) {
        return false;
      }

      var modulePreviousDate = new Date(this.MODULE_START_DATE);
      modulePreviousDate.setDate(modulePreviousDate.getDate() + -1);

      return endValue <= new Date(modulePreviousDate);
    }
  };

  disabledFeatureEndDate = (endValue: Date): boolean => {
    if (this.FEATURE_START_DATE != null) {
      if (!endValue) {
        return false;
      }

      var featurePreviousDate = new Date(this.FEATURE_START_DATE);
      featurePreviousDate.setDate(featurePreviousDate.getDate() + -1);

      return endValue <= new Date(featurePreviousDate);
    }
  };

  disabledTaskEndDate = (endValue: Date): boolean => {
    if (this.TASK_START_DATE != null) {
      if (!endValue) {
        return false;
      }

      var taskPreviousDate = new Date(this.TASK_START_DATE);
      taskPreviousDate.setDate(taskPreviousDate.getDate() + -1);

      return endValue <= new Date(taskPreviousDate);
    }
  };

  disabledSubtaskEndDate = (endValue: Date): boolean => {
    if (this.SUBTASK_START_DATE != null) {
      if (!endValue) {
        return false;
      }

      var subtaskPreviousDate = new Date(this.SUBTASK_START_DATE);
      subtaskPreviousDate.setDate(subtaskPreviousDate.getDate() + -1);

      return endValue <= new Date(subtaskPreviousDate);
    }
  };

  generateBadge(nameForBadge: string) {
    return this.api.createBadge(nameForBadge);
  }

  empPassVisible = false;
  PASSWORD: any = '';
  NEW_PASSWORD: any = '';
  CONFPASSWORD: any = '';

  changepass(): void {
    this.NEW_PASSWORD = '';
    this.CONFPASSWORD = '';
    this.PASSWORD = '';
    this.empPassVisible = true;
  }
  changeEmpPass(): void {
    this.NEW_PASSWORD = '';
    this.CONFPASSWORD = '';
    this.PASSWORD = '';
    this.empPassVisible = true;
  }
  handleCancel1(): void {
    window.location.reload();
    this.empPassVisible = false;
    this.NEW_PASSWORD = '';
    this.CONFPASSWORD = '';
    this.PASSWORD = '';
  }

  USER_ID: any;
  ID = sessionStorage.getItem('userId');
  showconfirm = false;
  checkpass() {
    console.log(this.PASSWORD, 'sss');

    if (
      this.PASSWORD != null ||
      this.PASSWORD != undefined ||
      this.PASSWORD != ''
    ) {
      if (this.roleId == 2) {
        this.api.getEmployeeCheckPassword(this.ID, this.PASSWORD).subscribe(
          (data) => {
            // this.user = data['data'][0];
            if (data['code'] == 200) {
              this.showconfirm = true;
            } else if (data['code'] == 300) {
              this.showconfirm = false;
              this.notificationService.error('Wrong Current Password', '');
            } else {
              this.showconfirm = false;
              this.notificationService.error('Failed To Check Password', '');
            }
          },
          (err) => {
            console.log(err);
          }
        );
      } else {
        this.api.getUserCheckPassword(this.ID, this.PASSWORD).subscribe(
          (data) => {
            // this.user = data['data'][0];
            if (data['code'] == 200) {
              this.showconfirm = true;
            } else if (data['code'] == 300) {
              this.showconfirm = false;
              this.notificationService.error('Wrong Current Password', '');
            } else {
              this.showconfirm = false;
              this.notificationService.error('Failed To Check Password', '');
            }
          },
          (err) => {
            console.log(err);
          }
        );
      }
    } else {
      this.notificationService.error('Please Enter Current Password', '');
    }
  }

  showEmpConfirm = false;
  checkEmpOldPass() {
    console.log(this.PASSWORD, 'sss');

    if (
      this.PASSWORD != null &&
      this.PASSWORD != undefined &&
      this.PASSWORD != '' &&
      this.PASSWORD.trim() != ''
    ) {
      this.api.getEmployeeCheckPassword(this.ID, this.PASSWORD).subscribe(
        (data) => {
          // this.user = data['data'][0];
          if (data['code'] == 200) {
            this.showEmpConfirm = true;
          } else if (data['code'] == 300) {
            this.showEmpConfirm = false;
            this.notificationService.error('Wrong Current Password', '');
          } else {
            this.showEmpConfirm = false;
            this.notificationService.error('Failed To Check Password', '');
          }
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      this.notificationService.error('Please Enter Current Password', '');
    }
  }
  isOk = true;
  userid: any;
  confpass() {
    this.isSpinning = false;
    this.isOk = true;
    this.user.USER_ID = this.userid;
    if (this.NEW_PASSWORD.trim() == '' && this.CONFPASSWORD.trim() == '') {
      this.isOk = false;
      this.notificationService.error('Please Enter All Fields', '');
    } else if (
      this.NEW_PASSWORD == undefined ||
      this.NEW_PASSWORD.trim() == ''
    ) {
      this.isOk = false;
      this.notificationService.error('Please Enter New Password', '');
    } else if (
      this.CONFPASSWORD == undefined ||
      this.CONFPASSWORD.trim() == ''
    ) {
      this.isOk = false;
      this.notificationService.error(
        'Please Enter Correct Confirm Password',
        ''
      );
    } else if (this.NEW_PASSWORD == this.CONFPASSWORD) {
      this.user.PASSWORD = this.NEW_PASSWORD;
      this.api
        .userchangepassord(this.ID, this.NEW_PASSWORD)
        .subscribe((successCode) => {
          if (successCode['code'] == '200') {
            this.notificationService.success(
              'Password Changed Successfully...',
              ''
            );
            window.location.reload();
            this.isVisible = false;
            this.NEW_PASSWORD = '';
            this.CONFPASSWORD = '';
            this.PASSWORD = '';
            this.isSpinning = false;
          } else {
            this.notificationService.error('Password Change Failed...', '');
            this.isSpinning = false;
          }
        });
    } else {
      this.notificationService.error(
        'New Password & Confirm Password Must Be Same',
        ''
      );
    }
  }
  confEmpPass() {
    this.isSpinning = false;
    this.isOk = true;
    this.user.USER_ID = this.userid;
    if (this.NEW_PASSWORD.trim() == '' && this.CONFPASSWORD.trim() == '') {
      this.isOk = false;
      this.notificationService.error('Please Enter All Fields', '');
    } else if (
      this.NEW_PASSWORD == undefined ||
      this.NEW_PASSWORD.trim() == ''
    ) {
      this.isOk = false;
      this.notificationService.error('Please Enter New Password', '');
    } else if (
      this.CONFPASSWORD == undefined ||
      this.CONFPASSWORD.trim() == ''
    ) {
      this.isOk = false;
      this.notificationService.error(
        'Please Enter Correct Confirm Password',
        ''
      );
    } else if (this.NEW_PASSWORD == this.CONFPASSWORD) {
      this.user.PASSWORD = this.NEW_PASSWORD;
      this.api
        .updateEmployeePassword(this.ID, this.NEW_PASSWORD)
        .subscribe((successCode) => {
          if (successCode['code'] == '200') {
            this.notificationService.success(
              'Password Changed Successfully...',
              ''
            );
            window.location.reload();
            this.empPassVisible = false;
            this.NEW_PASSWORD = '';
            this.CONFPASSWORD = '';
            this.PASSWORD = '';
            this.isSpinning = false;
          } else {
            this.notificationService.error('Password Change Failed...', '');
            this.isSpinning = false;
          }
        });
    } else {
      this.notificationService.error(
        'New Password & Confirm Password Must Be Same',
        ''
      );
    }
  }

  ///////////////////////////////////////////
  dataList = [];
  drawerVisible: boolean = false;
  drawerTitle!: string;
  employeeedit: any;
  drawerData: EmployeeMaster = new EmployeeMaster();
  drawerClose(): void {
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  edit(data: EmployeeMaster): void {
    this.drawerTitle = 'Update Employee ';
    this.drawerData = Object.assign({}, data);
    this.api
      .getEmployeeMaster(0, 0, '', '', ' AND ID =' + this.employeeedit)
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            this.dataList = data['data'];
            this.drawerData = Object.assign({}, data['data'][0]);
          }
          this.drawerVisible = true;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  userPassVisible: boolean = false;
  changepass1(): void {
    this.NEW_PASSWORD = '';
    this.CONFPASSWORD = '';
    this.PASSWORD = '';
    this.userPassVisible = true;
  }
  changeUserPass(): void {
    this.NEW_PASSWORD = '';
    this.CONFPASSWORD = '';
    this.PASSWORD = '';
    this.userPassVisible = true;
  }
  handleCancel11(): void {
    window.location.reload();
    this.userPassVisible = false;
    this.NEW_PASSWORD = '';
    this.CONFPASSWORD = '';
    this.PASSWORD = '';
  }

  checkpassemp() {
    console.log('userid', this.ID);

    this.api.getemployeecheckpassword(this.ID, this.PASSWORD).subscribe(
      (data) => {
        if (data['code'] == 200) {
          this.showconfirm = true;
        } else {
          this.showconfirm = false;
          this.notificationService.error('Please Enter Correct Password', '');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  showUserConfirm: boolean = false;
  checkPassUser() {
    if (
      this.PASSWORD != null &&
      this.PASSWORD != undefined &&
      this.PASSWORD != '' &&
      this.PASSWORD.trim() != ''
    ) {
      this.api.getUserCheckPassword(this.ID, this.PASSWORD).subscribe(
        (data) => {
          // this.user = data['data'][0];
          if (data['code'] == 200) {
            this.showUserConfirm = true;
          } else if (data['code'] == 300) {
            this.showUserConfirm = false;
            this.notificationService.error('Wrong Current Password', '');
          } else {
            this.showUserConfirm = false;
            this.notificationService.error('Failed To Check Password', '');
          }
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      this.notificationService.error('Please Enter Current Password', '');
    }
  }

  confpassemp() {
    this.isSpinning = false;
    this.isOk = true;

    this.user.USER_ID = this.userid;

    if (this.NEW_PASSWORD.trim() == '' && this.CONFPASSWORD.trim() == '') {
      this.isOk = false;
      this.notificationService.error('Please Enter All Fields', '');
    } else if (
      this.NEW_PASSWORD == undefined ||
      this.NEW_PASSWORD.trim() == ''
    ) {
      this.isOk = false;
      this.notificationService.error('Please Enter New Password', '');
    } else if (
      this.CONFPASSWORD == undefined ||
      this.CONFPASSWORD.trim() == ''
    ) {
      this.isOk = false;
      this.notificationService.error(
        'Please Enter Correct Confirm Password',
        ''
      );
    } else if (this.NEW_PASSWORD == this.CONFPASSWORD) {
      this.user.PASSWORD = this.NEW_PASSWORD;
      this.api
        .employeechangechangepassord(this.ID, this.NEW_PASSWORD)
        .subscribe((successCode) => {
          if (successCode['code'] == '200') {
            this.notificationService.success(
              'Password Changed Successfully...',
              ''
            );
            window.location.reload();
            this.isVisible = false;
            this.NEW_PASSWORD = '';
            this.CONFPASSWORD = '';
            this.PASSWORD = '';

            this.isSpinning = false;
          } else {
            this.notificationService.error('Password Change Failed...', '');
            this.isSpinning = false;
          }
        });
    } else {
      this.notificationService.error(
        'New Password & Confirm Password Must Be Same',
        ''
      );
    }
  }
  confPassUser() {
    this.isSpinning = false;
    this.isOk = true;
    this.user.USER_ID = this.userid;

    if (this.NEW_PASSWORD.trim() == '' && this.CONFPASSWORD.trim() == '') {
      this.isOk = false;
      this.notificationService.error('Please Enter All Fields', '');
    } else if (
      this.NEW_PASSWORD == undefined ||
      this.NEW_PASSWORD.trim() == ''
    ) {
      this.isOk = false;
      this.notificationService.error('Please Enter New Password', '');
    } else if (
      this.CONFPASSWORD == undefined ||
      this.CONFPASSWORD.trim() == ''
    ) {
      this.isOk = false;
      this.notificationService.error(
        'Please Enter Correct Confirm Password',
        ''
      );
    } else if (this.NEW_PASSWORD == this.CONFPASSWORD) {
      this.user.PASSWORD = this.NEW_PASSWORD;
      this.api
        .updateUserPassword(this.ID, this.NEW_PASSWORD)
        .subscribe((successCode) => {
          if (successCode['code'] == '200') {
            this.notificationService.success(
              'Password Changed Successfully...',
              ''
            );
            window.location.reload();
            this.userPassVisible = false;
            this.NEW_PASSWORD = '';
            this.CONFPASSWORD = '';
            this.PASSWORD = '';
            this.isSpinning = false;
          } else {
            this.notificationService.error('Password Change Failed...', '');
            this.isSpinning = false;
          }
        });
    } else {
      this.notificationService.error(
        'New Password & Confirm Password Must Be Same',
        ''
      );
    }
  }
  infoVisible: boolean = false;
  infoDrawerTitle: string = '';
  info(event) {
    this.router.navigateByUrl('/aboutInfo');
    console.log('event', event);

    // this.infoVisible = true
    // this.infoDrawerTitle = 'Info'
  }

  InfoDrawerClose(): void {
    this.drawerVisible = false;
  }

  get InfoCloseCallback() {
    return this.InfoDrawerClose.bind(this);
  }
}
