import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import * as html2pdf from 'html2pdf.js';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from 'src/app/Service/api.service';
@Component({
  selector: 'app-part-a',
  templateUrl: './part-a.component.html',
  styleUrls: ['./part-a.component.css'],
})
export class PartAComponent implements OnInit {
  @Input() tform: any;
  @Input() drawerClose: any;
  @Input() partadata: any;
  @Input() detailsandpurpose: any;
  @Input() particularofhotelsdata: any;
  @Input() particularoftours: any;
  @Input() particularofcity: any;
  @Input() fooddata: any;
  constructor(
    private message: NzNotificationService,
    private api: ApiService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {}

  visible = false;

  open(): void {
    this.visible = true;
  }

  close(): void {
    this.loadingRecords = false;
    this.drawerClose();
  }
  openpdf() {
    const element = document.getElementById('excel-table');
    const opt = {
      margin: 0.2,
      filename: 'Part-A.pdf',
      image: { type: 'jpeg', quality: 7 },
      html2canvas: { scale: 7 },
      jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
    };
    html2pdf().from(element).set(opt).save();
  }
  // printModel() {
  //   this.loadingRecords = true;
  //   this.api
  //     .gettransferdata(0, 0, '', '', ' AND ID = ' + this.partadata.ID)
  //     .subscribe(
  //       (data) => {
  //         if (data['code'] == 200) {
  //           this.loadingRecords = false;
  //           this.partadata = data['data'][0];
  //           this.printOrderModalVisible = true;
  //         } else {
  //           // this.message.error("Can't Load Data", '');
  //           this.loadingRecords = false;
  //         }
  //       },
  //       (err) => {
  //         console.log(err);
  //       }
  //     );
  // }

  printModel() {
    this.printOrderModalVisible = true;
  }
  loadingRecords = false;
  printOrderModalVisible: boolean = false;
  showmodal() {
    this.loadingRecords = true;
    this.api.updatetour(this.partadata).subscribe((successCode) => {
      if (successCode.code == '200') {
        this.loadingRecords = false;
        // this.printModel();

        this.message.success('Information Saved Successfully...', '');
        this.printOrderModalVisible = true;
      } else {
        this.message.error('Information Has Not Saved...', '');
        this.loadingRecords = false;
      }
    });
  }
  getwidth() {
    if (window.innerWidth <= 400) {
      return 400;
    } else {
      return 850;
    }
  }

  printOrderModalCancel() {
    this.printOrderModalVisible = false;
  }

  getTimeIn12Hour(time: any) {
    return this.datePipe.transform(new Date(), 'yyyy-MM-dd' + ' ' + time);
  }
}
