export class FileHierarchy {
  ID: number = 0;
  NAME: string = '';
  PARENT_ID: any;
  CODE: any;
  IS_LAST:boolean = false;
  CLIENT_ID: any;
  IS_ADVANCE:string
  FINAL_HIRARCHY_ID
}
