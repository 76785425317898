<!-- <p> -->
<div class="body">
  <h2 style="text-align: center; padding-top: 40px"><b> About EMRCPS </b></h2>
  <h3 style="text-align: center">
    Employee Medical Reimbursment Claim Processing System
  </h3>
  <h5 style="text-align: center; font-weight: bold; color: rgb(0, 79, 170)">
    Version 1.0.9
  </h5>

  <h2
    style="
      text-align: center;
      font-weight: bold;
      padding-top: 20px;
      font-size: medium;
    "
  >
    Concept By
    <!-- <nz-divider
      style="font-weight: bold; padding-top: 10px"
      nzText="Concept By"
    ></nz-divider> -->
  </h2>
  <h3
    style="
      text-align: center;
      font-family: initial;
      font-size: 24px;
      font-weight: 700;
      color: rgb(118, 121, 216);
    "
  >
    Mr. Jitendra Chandekar (ITO)
  </h3>
  <h3
    style="
      text-align: center;
      font-size: 15px;
      color: rgb(255 46 46);
      margin-top: -15px;
      font-weight: 600;
    "
  >
    Income Tax Office - Mumbai
  </h3>
  <h2
    style="
      text-align: center;
      font-weight: bold;
      padding-top: 20px;
      font-size: medium;
    "
  >
    Powered By
    <!-- <nz-divider
      style="font-weight: bold; padding-top: 10px"
      nzText="Powered By"
    ></nz-divider> -->
  </h2>
  <h3 style="text-align: center; color: rgb(0, 79, 170); font-weight: 700">
    Income Tax Office - Mumbai
  </h3>
  <h2
    style="
      text-align: center;
      font-weight: bold;
      padding-top: 20px;
      font-size: medium;
    "
  >
    Designed & Developed
    <!-- <nz-divider
      style="font-weight: bold; padding-top: 10px"
      nzText="Designed & Developed"
    ></nz-divider> -->
  </h2>
  <h3 style="text-align: center; color: gray">
    UjjVilas Technologies & Software Private Limited.
  </h3>
</div>
<!-- </p> -->
<!-- <nz-footer>
  Proudly Designed & Developed By,
  <a href="https://www.uvtechsoft.com/" target="_blank"
    >UjjVilas Technologies & Software Pvt. Ltd.</a
  >
</nz-footer> -->
