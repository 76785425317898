<div *ngIf="LTC1data != undefined">
  <form nz-form>
    <nz-spin [nzSpinning]="loadingRecords">
      <div nz-row [nzGutter]="10">
        <div nz-col nzXs="8" nzSm="8" nzMd="8" nzLg="8" nzXl="8" nzSpan="8">
          <div class="my-box">
            <!-- <div nz-col nzSpan="24">
              <nz-form-item>
                <nz-form-label nzNoColon>Signature</nz-form-label>
                <nz-form-control>
                  <nz-select
                    name="userid"
                    [(ngModel)]="LTC1data.SIGNATURE_ID"
                    nzAllowClear
                    nzPlaceHolder="Choose Signature"
                    (ngModelChange)="signature($event)"
                  >
                    <nz-option
                      *ngFor="let user of Signaturearray"
                      [nzValue]="user.ID"
                      [nzLabel]="user.NAME"
                    >
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div> -->

            <div nz-col nzSpan="24">
              <nz-form-item>
                <nz-form-label nzNoColon>Bill Submitted Date</nz-form-label>

                <nz-form-control>
                  <nz-date-picker
                    style="width: 100%"
                    [(ngModel)]="LTC1data.BILL_SUBMITTED_DATE"
                    nzFormat="dd/MM/yyyy"
                    name="Bill"
                  >
                  </nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="24" *ngIf="formdata7.length > 0">
              <nz-form-item>
                <nz-form-label nzNoColon>Journey Remark</nz-form-label>
                <nz-input-group>
                  <textarea
                    type="text"
                    autocomplete="off"
                    name="Journey_Remark"
                    nz-input
                    rows="5"
                    placeholder="Enter Journey Remark"
                    [(ngModel)]="LTC1data.JOURNEY_REMARK"
                  ></textarea>
                </nz-input-group>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="24">
              <nz-form-item>
                <nz-form-label nzNoColon>Remark</nz-form-label>
                <angular-editor
                  style="resize: none; color: black"
                  name="Return_Journey_Remark"
                  [(ngModel)]="LTC1data.RETURN_JOURNEY_REMARK"
                  [config]="editorConfigPara"
                ></angular-editor>
                <!-- <nz-input-group>
                  <textarea
                    type="text"
                    autocomplete="off"
                    name="Remark"
                    nz-input
                    rows="5"
                    placeholder="Enter Remark"
                    [(ngModel)]="LTC1data.RETURN_JOURNEY_REMARK"
                  ></textarea>
                </nz-input-group> -->
              </nz-form-item>
            </div>
            <div nz-col nzSpan="24">
              <nz-form-item>
                <nz-form-label nzNoColon>Enter Year Type</nz-form-label>

                <nz-input-group>
                  <input
                    type="text"
                    autocomplete="off"
                    name="ORDER_YEAR"
                    nz-input
                    placeholder="Enter Year Type"
                    [(ngModel)]="LTC1data.ORDER_YEAR"
                  />
                </nz-input-group>
              </nz-form-item>
            </div>
          </div>
        </div>

        <div nz-col nzXs="16" nzSm="16" nzMd="16" nzLg="16" nzXl="16">
          <div
            class="my-box"
            style="padding: 10px; height: 590px; overflow-y: scroll"
          >
            <div>
              <div style="font-family: Arial, Helvetica, sans-serif !important">
                <div
                  style="
                    text-align: center;
                    margin-top: 20px;
                    margin-bottom: 10px;
                    font-size: 13px;
                  "
                ></div>
                <div style="font-size: 13px !important">
                  <p>
                    <b>Dated:- </b>
                    <!-- <b>Dated:- {{ getdateeeee() }}</b> -->
                  </p>
                  <p>
                    1. {{ LTC1data.SALUTATION }}
                    <b
                      >{{ LTC1data.EMPLOYEE_NAME }}, {{ LTC1data.DESIGNATION }},
                      {{ LTC1data.OFFICE_NAME }}</b
                    >
                    had submitted LTC Bill for the {{ LTC1data.ORDER_YEAR }}
                    <b>{{ LTC1data.BLOCK_YEAR }}</b> in this office on
                    <b>{{
                      LTC1data.BILL_SUBMITTED_DATE | date : "dd-MM-yyyy"
                    }}</b
                    >. Wherein he/she has claimed LTC for
                    <!-- <span *ngFor="let data of relationdata1; let k = index"
                      ><b>
                        <span *ngIf="relationdata1.length == 1">{{
                          data.RELATIONSHIP
                        }}</span> -->

                    <span *ngIf="relationdata1.length > 0">
                      <ng-container
                        *ngFor="
                          let item of relationdata1;
                          let last = last;
                          let i = index
                        "
                      >
                        <b> {{ item.RELATIONSHIP }} </b>
                        <ng-container *ngIf="relationdata1.length > 1 && !last">
                          <ng-container
                            *ngIf="relationdata1.length === 2 && i === 0"
                          >
                            and
                          </ng-container>
                          <ng-container
                            *ngIf="
                              relationdata1.length > 2 &&
                              i < relationdata1.length - 2
                            "
                            >,
                          </ng-container>
                          <ng-container
                            *ngIf="
                              relationdata1.length > 2 &&
                              i === relationdata1.length - 2
                            "
                          >
                            and
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </span>
                    only .

                    <!-- <span *ngIf="relationdata1.length > 1">
                      {{ data.RELATIONSHIP }}
                      <span *ngIf="relationdata1.length - 2 != k">,</span>
                      <span *ngIf="relationdata1.length - 2 == k">and</span>
                    </span> -->
                    <!-- </b> -->

                    <!-- </span>. -->
                  </p>
                  <p>
                    2. The pay level of the officer is
                    <b> {{ getlevel(LTC1data.GRADE_PAY_LEVEL) }} </b>hence
                    he/she is entitled for economy/business class Air Fare,
                    While going to the bills/vouchers and documents submitted by
                    the officer it is observed that the officer has claimed
                    actual expenses.
                  </p>
                  <p style="text-align: justify">
                    3. As per Service book record, the details of
                    <b>previous LTC claimed </b>by the officer is as under:-
                  </p>
                  <table style="border: 1px solid black">
                    <tr style="border: 1px solid black">
                      <td style="border: 1px solid black; text-align: left">
                        Home Town Declared
                      </td>
                      <td
                        style="
                          border: 1px solid black;
                          text-align: center;
                          width: 50%;
                        "
                      ></td>
                    </tr>
                    <tr style="border: 1px solid black">
                      <td style="border: 1px solid black; text-align: left">
                        Previous LTC claimed (Home Town/ Any where in India)
                      </td>
                      <td
                        style="
                          border: 1px solid black;
                          text-align: left;
                          width: 50%;
                        "
                      ></td>
                    </tr>
                    <tr style="border: 1px solid black">
                      <td style="border: 1px solid black; text-align: left">
                        Block Year / Calendar Year
                      </td>
                      <td
                        style="
                          border: 1px solid black;
                          text-align: left;
                          width: 50%;
                        "
                      ></td>
                    </tr>
                    <tr style="border: 1px solid black">
                      <td style="border: 1px solid black; text-align: left">
                        Date of journey
                      </td>
                      <td
                        style="
                          border: 1px solid black;
                          text-align: left;
                          width: 50%;
                        "
                      ></td>
                    </tr>
                    <tr style="border: 1px solid black">
                      <td style="border: 1px solid black; text-align: left">
                        Destination
                      </td>
                      <td
                        style="
                          border: 1px solid black;
                          text-align: left;
                          width: 50%;
                        "
                      ></td>
                    </tr>
                  </table>

                  <p style="margin-top: 10px">
                    And as per service book, the details of the
                    <b>current LTC claimed</b> by the officer is as under:-
                  </p>
                  <table style="border: 1px solid black">
                    <tr style="border: 1px solid black">
                      <td style="border: 1px solid black; text-align: left">
                        Home Town Declared
                      </td>
                      <td
                        style="
                          border: 1px solid black;
                          text-align: left;
                          width: 50%;
                        "
                      ></td>
                    </tr>
                    <tr style="border: 1px solid black">
                      <td style="border: 1px solid black; text-align: left">
                        Current LTC claimed (Home Town/ Any where in India)
                      </td>
                      <td
                        style="
                          border: 1px solid black;
                          text-align: left;
                          width: 50%;
                        "
                      ></td>
                    </tr>
                    <tr style="border: 1px solid black">
                      <td style="border: 1px solid black; text-align: left">
                        Block Year / Calendar Year
                      </td>
                      <td
                        style="
                          border: 1px solid black;
                          text-align: left;
                          width: 50%;
                        "
                      ></td>
                    </tr>
                    <tr style="border: 1px solid black">
                      <td style="border: 1px solid black; text-align: left">
                        Date of journey
                      </td>
                      <td
                        style="
                          border: 1px solid black;
                          text-align: left;
                          width: 50%;
                        "
                      ></td>
                    </tr>
                    <tr style="border: 1px solid black">
                      <td style="border: 1px solid black; text-align: left">
                        Destination
                      </td>
                      <td
                        style="
                          border: 1px solid black;
                          text-align: left;
                          width: 50%;
                        "
                      ></td>
                    </tr>
                  </table>

                  <p style="margin-top: 10px">
                    Necessary Entries of current LTC claim made by the officer
                    has been made in service book of the officer:-
                  </p>
                  <div
                    nz-row
                    [nzGutter]="10"
                    style="margin-top: 50px; margin-bottom: 20px"
                  >
                    <div
                      nz-col
                      nzXs="12"
                      nzSm="12"
                      nzMd="12"
                      nzLg="12"
                      nzXl="12"
                      nzSpan="12"
                      style="text-align: left"
                    >
                      <b>Entry made by:</b>
                    </div>
                    <div
                      nz-col
                      nzXs="12"
                      nzSm="12"
                      nzMd="12"
                      nzLg="12"
                      nzXl="12"
                      nzSpan="12"
                      style="text-align: right"
                    >
                      <b style="text-align: right; padding-right: 15px"
                        >Checked by:</b
                      >
                    </div>
                  </div>

                  <p
                    style="
                      page-break-inside: avoid !important;

                      font-size: 13px;
                      margin-top: 24px;
                      page-break-before: always !important;
                    "
                  >
                    4. The checklist for the LTC bill submitted by
                    <b
                      >{{ LTC1data.SALUTATION }} {{ LTC1data.EMPLOYEE_NAME }},
                      {{ LTC1data.DESIGNATION }}, </b
                    >, Pay level=<b
                      >{{ getlevel(LTC1data.GRADE_PAY_LEVEL) }}
                    </b>
                    for the {{ LTC1data.ORDER_YEAR }}
                    <b>{{ LTC1data.BLOCK_YEAR }}</b> is as under:-
                  </p>

                  <div nz-row>
                    <div nz-col nzSpan="2"></div>
                    <div nz-col nzSpan="22">
                      <table
                        style="
                          border: 1px solid black;
                          font-size: 12px !important;
                          font-size: 12px !important;
                        "
                      >
                        <tr
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                          "
                        >
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                              width: 10%;
                            "
                          >
                            Sr.No
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                              width: 70%;
                            "
                          >
                            Subject
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                              width: 10%;
                            "
                          >
                            Yes
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                              width: 10%;
                            "
                          >
                            No
                          </td>
                        </tr>

                        <tr
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                          "
                        >
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            1
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: left;
                              width: 50%;
                            "
                          >
                            Whether Original bills submitted in proper format
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            <span
                              *ngIf="LTC1data.IS_ORIGINAL_BILL_SUBMITTED == 1"
                              ><i nz-icon nzType="check" nzTheme="outline"></i
                            ></span>
                            <span
                              *ngIf="LTC1data.IS_ORIGINAL_BILL_SUBMITTED == 0"
                            ></span>
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            <span
                              *ngIf="LTC1data.IS_ORIGINAL_BILL_SUBMITTED == 1"
                            ></span>
                            <span
                              *ngIf="LTC1data.IS_ORIGINAL_BILL_SUBMITTED == 0"
                              ><i nz-icon nzType="check" nzTheme="outline"></i
                            ></span>
                          </td>
                        </tr>

                        <tr
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                          "
                        >
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            2
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: left;
                              width: 50%;
                            "
                          >
                            Whether Block Year mentioned
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            <span
                              *ngIf="LTC1data.WHETHER_BLOCK_YEAR_MENTIONED == 1"
                              ><i nz-icon nzType="check" nzTheme="outline"></i
                            ></span>
                            <span
                              *ngIf="LTC1data.WHETHER_BLOCK_YEAR_MENTIONED == 0"
                            ></span>
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            <span
                              *ngIf="LTC1data.WHETHER_BLOCK_YEAR_MENTIONED == 1"
                            ></span>
                            <span
                              *ngIf="LTC1data.WHETHER_BLOCK_YEAR_MENTIONED == 0"
                              ><i nz-icon nzType="check" nzTheme="outline"></i
                            ></span>
                          </td>
                        </tr>
                        <tr
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                          "
                        >
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            3
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: left;
                              width: 50%;
                            "
                          >
                            Whether bills has been submitted in proper time (If
                            advance is taken then bills should be submitted in
                            30 days after completion of journey and bills should
                            be submitted in 90 days after completion of journey
                            if advance is not taken).
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            <span
                              *ngIf="
                                LTC1data.IS_BILL_SUBMITTED_PROPER_TIME == 1
                              "
                              ><i nz-icon nzType="check" nzTheme="outline"></i
                            ></span>
                            <span
                              *ngIf="
                                LTC1data.IS_BILL_SUBMITTED_PROPER_TIME == 0
                              "
                            ></span>
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            <span
                              *ngIf="
                                LTC1data.IS_BILL_SUBMITTED_PROPER_TIME == 1
                              "
                            ></span>
                            <span
                              *ngIf="
                                LTC1data.IS_BILL_SUBMITTED_PROPER_TIME == 0
                              "
                              ><i nz-icon nzType="check" nzTheme="outline"></i
                            ></span>
                          </td>
                        </tr>
                        <tr
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                          "
                        >
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            4
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: left;
                              width: 50%;
                            "
                          >
                            In case of Air Journey, whether original boarding
                            pass and Tickets of Air Fare duly submitted and in
                            case of Train Journey Train Tickets submitted.
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            <span
                              *ngIf="LTC1data.IS_PASS_OR_TICKET_SUBMITTED == 1"
                              ><i nz-icon nzType="check" nzTheme="outline"></i
                            ></span>
                            <span
                              *ngIf="LTC1data.IS_PASS_OR_TICKET_SUBMITTED == 0"
                            ></span>
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            <span
                              *ngIf="LTC1data.IS_PASS_OR_TICKET_SUBMITTED == 1"
                            ></span>
                            <span
                              *ngIf="LTC1data.IS_PASS_OR_TICKET_SUBMITTED == 0"
                              ><i nz-icon nzType="check" nzTheme="outline"></i
                            ></span>
                          </td>
                        </tr>
                        <tr
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                          "
                        >
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            4(a)
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: left;
                              width: 50%;
                            "
                          >
                            Print-out of concerned webpage of the ATAs having
                            flight & fare details for concerned time slot for
                            tickets taken after 28/08/2022.
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            <span
                              *ngIf="
                                LTC1data.IS_PRINTOUT_SUBMITTED_FOR_CONCERNED_TIME_SLOT ==
                                'Y'
                              "
                              ><i nz-icon nzType="check" nzTheme="outline"></i
                            ></span>
                            <!-- <span
                              *ngIf="
                                LTC1data.IS_PRINTOUT_SUBMITTED_FOR_CONCERNED_TIME_SLOT ==
                                
                              "
                            ></span> -->
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            <span
                              *ngIf="
                                LTC1data.IS_PRINTOUT_SUBMITTED_FOR_CONCERNED_TIME_SLOT ==
                                1
                              "
                            ></span>
                            <span
                              *ngIf="
                                LTC1data.IS_PRINTOUT_SUBMITTED_FOR_CONCERNED_TIME_SLOT ==
                                0
                              "
                              ><i nz-icon nzType="check" nzTheme="outline"></i
                            ></span>
                          </td>
                        </tr>
                        <tr
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                          "
                        >
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            5
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: left;
                              width: 50%;
                            "
                          >
                            Whether Bank Mandate Form Submitted
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            <span
                              *ngIf="
                                LTC1data.IS_BANK_MANDATE_FORM_SUBMITTED == 1
                              "
                              ><i nz-icon nzType="check" nzTheme="outline"></i
                            ></span>
                            <span
                              *ngIf="
                                LTC1data.IS_BANK_MANDATE_FORM_SUBMITTED == 0
                              "
                            ></span>
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            <span
                              *ngIf="
                                LTC1data.IS_BANK_MANDATE_FORM_SUBMITTED == 1
                              "
                            ></span>
                            <span
                              *ngIf="
                                LTC1data.IS_BANK_MANDATE_FORM_SUBMITTED == 0
                              "
                              ><i nz-icon nzType="check" nzTheme="outline"></i
                            ></span>
                          </td>
                        </tr>
                        <tr
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                          "
                        >
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            6
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: left;
                              width: 50%;
                            "
                          >
                            Whether Copy of Prior Intimation/Leave Application
                            Given
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            <span
                              *ngIf="
                                LTC1data.IS_COPY_OF_INTIMATION_OR_LEAVE_APPLICATION_GIVEN ==
                                1
                              "
                              ><i nz-icon nzType="check" nzTheme="outline"></i
                            ></span>
                            <span
                              *ngIf="
                                LTC1data.IS_COPY_OF_INTIMATION_OR_LEAVE_APPLICATION_GIVEN ==
                                0
                              "
                            ></span>
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            <span
                              *ngIf="
                                LTC1data.IS_COPY_OF_INTIMATION_OR_LEAVE_APPLICATION_GIVEN ==
                                1
                              "
                            ></span>
                            <span
                              *ngIf="
                                LTC1data.IS_COPY_OF_INTIMATION_OR_LEAVE_APPLICATION_GIVEN ==
                                0
                              "
                              ><i nz-icon nzType="check" nzTheme="outline"></i
                            ></span>
                          </td>
                        </tr>
                        <tr
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                          "
                        >
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            7
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: left;
                              width: 50%;
                            "
                          >
                            Whether copy of leave sanctioned order Given
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            <span
                              *ngIf="
                                LTC1data.IS_COPY_OF_LEAVE_LEAVE_SANCTIONED_ORDER_GIVEN ==
                                1
                              "
                              ><i nz-icon nzType="check" nzTheme="outline"></i
                            ></span>
                            <span
                              *ngIf="
                                LTC1data.IS_COPY_OF_LEAVE_LEAVE_SANCTIONED_ORDER_GIVEN ==
                                0
                              "
                            ></span>
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            <span
                              *ngIf="
                                LTC1data.IS_COPY_OF_LEAVE_LEAVE_SANCTIONED_ORDER_GIVEN ==
                                1
                              "
                            ></span>
                            <span
                              *ngIf="
                                LTC1data.IS_COPY_OF_LEAVE_LEAVE_SANCTIONED_ORDER_GIVEN ==
                                0
                              "
                              ><i nz-icon nzType="check" nzTheme="outline"></i
                            ></span>
                          </td>
                        </tr>
                        <tr
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                          "
                        >
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            8
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: left;
                              width: 50%;
                            "
                          >
                            Latest Payment Slip for pay level purpose
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            <span
                              *ngIf="
                                LTC1data.IS_LATEST_PAYMENT_SLIP_FOR_PAY_LEVEL ==
                                1
                              "
                              ><i nz-icon nzType="check" nzTheme="outline"></i
                            ></span>
                            <span
                              *ngIf="
                                LTC1data.IS_LATEST_PAYMENT_SLIP_FOR_PAY_LEVEL ==
                                0
                              "
                            ></span>
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            <span
                              *ngIf="
                                LTC1data.IS_LATEST_PAYMENT_SLIP_FOR_PAY_LEVEL ==
                                1
                              "
                            ></span>
                            <span
                              *ngIf="
                                LTC1data.IS_LATEST_PAYMENT_SLIP_FOR_PAY_LEVEL ==
                                0
                              "
                              ><i nz-icon nzType="check" nzTheme="outline"></i
                            ></span>
                          </td>
                        </tr>
                        <tr
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                          "
                        >
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            9
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: left;
                              width: 50%;
                            "
                          >
                            Dependency Certificate given in case if sons age is
                            more than 18 years and daughters age is more than 24
                            years
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            <span
                              *ngIf="
                                LTC1data.IS_DEPENDENCY_CERTIFICATE_GIVEN == 'Y'
                              "
                              ><i nz-icon nzType="check" nzTheme="outline"></i
                            ></span>
                            <!-- <span
                              *ngIf="LTC1data.IS_DEPENDENCY_CERTIFICATE_GIVEN == 0"
                            ></span> -->
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            <span
                              *ngIf="
                                LTC1data.IS_DEPENDENCY_CERTIFICATE_GIVEN == 1
                              "
                            ></span>
                            <span
                              *ngIf="
                                LTC1data.IS_DEPENDENCY_CERTIFICATE_GIVEN == 0
                              "
                              ><i nz-icon nzType="check" nzTheme="outline"></i
                            ></span>
                          </td>
                        </tr>
                        <tr
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                          "
                        >
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            10
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: left;
                              width: 50%;
                            "
                          >
                            Whether Advance for LTC Granted
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            <span
                              *ngIf="LTC1data.IS_ADVANCE_FOR_LTC_GRANTED == 1"
                              ><i nz-icon nzType="check" nzTheme="outline"></i
                            ></span>
                            <span
                              *ngIf="LTC1data.IS_ADVANCE_FOR_LTC_GRANTED == 0"
                            ></span>
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            <span
                              *ngIf="LTC1data.IS_ADVANCE_FOR_LTC_GRANTED == 1"
                            ></span>
                            <span
                              *ngIf="LTC1data.IS_ADVANCE_FOR_LTC_GRANTED == 0"
                              ><i nz-icon nzType="check" nzTheme="outline"></i
                            ></span>
                          </td>
                        </tr>
                        <tr
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                          "
                        >
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            11
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: left;
                              width: 50%;
                            "
                          >
                            Whether Home Town
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            <span *ngIf="LTC1data.WHETHER_HOME_TOWN == 1"
                              ><i nz-icon nzType="check" nzTheme="outline"></i
                            ></span>
                            <span
                              *ngIf="LTC1data.WHETHER_HOME_TOWN == 0"
                            ></span>
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            <span
                              *ngIf="LTC1data.WHETHER_HOME_TOWN == 1"
                            ></span>
                            <span *ngIf="LTC1data.WHETHER_HOME_TOWN == 0"
                              ><i nz-icon nzType="check" nzTheme="outline"></i
                            ></span>
                          </td>
                        </tr>
                        <tr
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                          "
                        >
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            12
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: left;
                              width: 50%;
                            "
                          >
                            Whether All Over India
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            <span *ngIf="LTC1data.WHETHER_ALL_OVER_INDIA == 1"
                              ><i nz-icon nzType="check" nzTheme="outline"></i
                            ></span>
                            <span
                              *ngIf="LTC1data.WHETHER_ALL_OVER_INDIA == 0"
                            ></span>
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            <span
                              *ngIf="LTC1data.WHETHER_ALL_OVER_INDIA == 1"
                            ></span>
                            <span *ngIf="LTC1data.WHETHER_ALL_OVER_INDIA == 0"
                              ><i nz-icon nzType="check" nzTheme="outline"></i
                            ></span>
                          </td>
                        </tr>
                        <tr
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                          "
                        >
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            13
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: left;
                              width: 50%;
                            "
                          >
                            Whether converted Home Town to NER/Andaman &
                            Nicobar/Jammu & Kashmir/Ladakh
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            <span
                              *ngIf="
                                LTC1data.WHETHER_CONVERTED_HOME_TO_NER_AN_JK_L ==
                                1
                              "
                              ><i nz-icon nzType="check" nzTheme="outline"></i
                            ></span>
                            <span
                              *ngIf="
                                LTC1data.WHETHER_CONVERTED_HOME_TO_NER_AN_JK_L ==
                                0
                              "
                            ></span>
                          </td>
                          <td
                            style="
                              border: 1px solid black;
                              font-size: 12px !important;
                              text-align: center;
                            "
                          >
                            <span
                              *ngIf="
                                LTC1data.WHETHER_CONVERTED_HOME_TO_NER_AN_JK_L ==
                                1
                              "
                            ></span>
                            <span
                              *ngIf="
                                LTC1data.WHETHER_CONVERTED_HOME_TO_NER_AN_JK_L ==
                                0
                              "
                              ><i nz-icon nzType="check" nzTheme="outline"></i
                            ></span>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <p style="margin-top: 10px">5.</p>

                  <table style="border: 1px solid black">
                    <tr style="border: 1px solid black">
                      <td style="border: 1px solid black; text-align: center">
                        From
                      </td>
                      <td style="border: 1px solid black; text-align: center">
                        To
                      </td>
                      <td style="border: 1px solid black; text-align: center">
                        Mode of travel
                      </td>
                      <td style="border: 1px solid black; text-align: center">
                        No. of Passengers
                      </td>
                      <td style="border: 1px solid black; text-align: center">
                        Amount claimed with taxes/fees (in Rs.)
                      </td>
                      <td style="border: 1px solid black; text-align: center">
                        Base fare allowed for all passengers (in Rs.)
                      </td>
                      <td style="border: 1px solid black; text-align: center">
                        Taxes allowed (in Rs.)
                      </td>
                      <td style="border: 1px solid black; text-align: center">
                        Amount granted with taxes (In Rs.)
                      </td>
                      <td style="border: 1px solid black; text-align: center">
                        Remarks
                      </td>
                    </tr>
                    <tr
                      style="border: 1px solid black"
                      *ngFor="let data of formdata7; let k = index"
                    >
                      <td style="border: 1px solid black; text-align: center">
                        {{ data.DEPARTURE_FROM }}
                      </td>
                      <td style="border: 1px solid black; text-align: center">
                        {{ data.ARRIVAL_TO }}
                      </td>
                      <td style="border: 1px solid black; text-align: center">
                        {{ data.TRAVEL_MODE_NAME }}
                      </td>
                      <td style="border: 1px solid black; text-align: center">
                        {{ data.NO_OF_FAIRS }}
                      </td>
                      <td style="border: 1px solid black; text-align: center">
                        {{ data.JOURNEY_CLAIMED_AMOUNT }}
                        <!-- old -->
                      </td>
                      <td style="border: 1px solid black; text-align: center">
                        {{ data.FAIR_PAID }}
                      </td>
                      <td style="border: 1px solid black; text-align: center">
                        {{ data.ALLOWED_TOTAL }}
                      </td>
                      <td style="border: 1px solid black; text-align: center">
                        {{ data.AMOUNT_WITH_TAX }}
                      </td>
                      <td
                        *ngIf="k == 0"
                        [attr.rowspan]="remarkCount"
                        style="border: 1px solid black; text-align: center"
                      >
                        <!-- {{ allRemarks }} -->
                        {{ LTC1data.JOURNEY_REMARK }}
                      </td>
                      <!-- <td rowspan="4" style="border: 1px solid black; text-align: center">


                                        </td> -->
                    </tr>
                    <tr style="border: 1px solid black">
                      <td
                        colspan="7"
                        style="border: 1px solid black; text-align: right"
                      >
                        Total
                      </td>
                      <td style="border: 1px solid black; text-align: center">
                        <b> {{ amount }} /- </b>
                      </td>
                    </tr>

                    <tr style="border: 1px solid black">
                      <td
                        colspan="7"
                        style="border: 1px solid black; text-align: right"
                      >
                        Advance
                      </td>
                      <td style="border: 1px solid black; text-align: center">
                        <b> {{ LTC1data.AMOUNT_OF_ADVANCE }} /- </b>
                      </td>
                    </tr>
                    <tr style="border: 1px solid black">
                      <td
                        colspan="7"
                        style="border: 1px solid black; text-align: right"
                      >
                        Total
                      </td>
                      <td style="border: 1px solid black; text-align: center">
                        <b> {{ namount }}/- </b>
                      </td>
                      <td
                        style="border: 1px solid black; text-align: center"
                      ></td>
                    </tr>
                  </table>
                  <p
                    style="
                      margin-top: 10px;
                      padding-left: 10px;
                      font-weight: bold;
                    "
                    [innerHtml]="LTC1data.RETURN_JOURNEY_REMARK"
                  >
                    <!-- <b> {{ LTC1data.RETURN_JOURNEY_REMARK }} </b> -->
                  </p>

                  <p style="margin-top: 10px; padding-left: 20px">
                    The necessary procedural requirements as per LTC rules have
                    been fulfilled by the officer, hence the net bill amount
                    which is to be issued comes to <b>Rs. {{ namount }}/-</b>
                  </p>
                  <p>
                    6. File is put up for kind perusal and signature please.
                  </p>

                  <div
                    nz-row
                    [nzGutter]="10"
                    style="margin-top: 20px; margin-bottom: 20px"
                  >
                    <div
                      nz-col
                      nzXs="12"
                      nzSm="12"
                      nzMd="12"
                      nzLg="12"
                      nzXl="12"
                      nzSpan="12"
                      style="text-align: left"
                    >
                      <span style="text-align: left">
                        Prepared by:
                        <!-- Prepared by: {{ LTC1data.INSPECTOR_NAME }} -->
                      </span>
                    </div>
                  </div>
                  <div nz-row [nzGutter]="10" style="margin-top: 50px">
                    <div
                      nz-col
                      nzXs="12"
                      nzSm="12"
                      nzMd="12"
                      nzLg="12"
                      nzXl="12"
                      nzSpan="12"
                    ></div>
                    <div
                      nz-col
                      nzXs="12"
                      nzSm="12"
                      nzMd="12"
                      nzLg="12"
                      nzXl="12"
                      nzSpan="12"
                      style="text-align: center"
                    >
                      <span style="text-align: center">
                        <div>जितेंद्र रा. चांदेकर</div>
                        <div>
                          <b style="text-transform: uppercase">
                            (Jitendra R Chandekar)
                          </b>
                        </div>
                        <div>आयकर अधिकारी</div>
                        <div>
                          <b> Income Tax Officer </b>
                        </div>
                        <div>राजपत्रित वेतन बिल कक्ष - २, मुंबई</div>
                        <div>
                          <b> Gazetted Pay Bill - II, Mumbai </b>
                        </div>
                      </span>
                      <!-- <span style="text-align: center"
                        >{{ NAME_HN }}<br />
                        <b> ({{ NAME }})</b> <br />
                        {{ POST_HN }}<br />
                        <b>{{ POST }} </b><br />
                        <span *ngIf="LTC1data.SIGNATURE_ID == 2">
                          राजपत्रित वेतन बिल कक्ष - २, मुंबई
                        </span>
                        <span *ngIf="LTC1data.SIGNATURE_ID != 2">
                          {{ OFFICE_NAME_HN }}
                        </span>
                        <br />
                        <b>{{ OFFICE_NAME }}</b>
                      </span> -->
                    </div>
                  </div>
                  <div nz-row>
                    <div nz-col nzSpan="10" style="text-align: center">
                      <p style="text-align: center; margin-top: 50px">
                        <b> (मुदित श्रीवास्तव, भा.रा.से.) </b><br />
                        <b> (MUDIT SRIVASTAVA, I.R.S.)</b><br />
                        अपर आयकर आयुक्त (मुख्या.) कार्मिक, मुंबई <br />
                        <b> Addl. Commissioner of Income Tax (HQ) </b> <br />
                        <b> Personnel, Mumbai </b>
                      </p>
                    </div>
                    <div nz-col nzSpan="14"></div>
                  </div>

                  <div
                    nz-row
                    [nzGutter]="10"
                    style="margin-top: 20px; margin-bottom: 20px"
                  >
                    <div
                      nz-col
                      nzXs="16"
                      nzSm="16"
                      nzMd="16"
                      nzLg="16"
                      nzXl="16"
                      nzSpan="16"
                      style="text-align: left"
                    ></div>
                    <div
                      nz-col
                      nzXs="8"
                      nzSm="8"
                      nzMd="8"
                      nzLg="8"
                      nzXl="8"
                      nzSpan="8"
                      style="text-align: left"
                    >
                      <!-- <b style="text-align: left; border: 1px solid"
                        >Checked by..............</b
                      > -->
                    </div>
                  </div>
                  <div nz-row>
                    <div nz-col nzSpan="10" style="text-align: center">
                      <p style="text-align: center; margin-top: 50px">
                        <span style="text-align: center">
                          विनय सिन्हा<br />
                          <b> (VINAY SINHA) </b><br />
                          आयकर आयुक्त प्रशासन एव करदाता सेवाएँ, मुंबई <br />
                          <b> Commissioner of Income Tax </b><br />
                          <b> Admn And TPS Mumbai </b><br />
                        </span>
                      </p>
                    </div>
                    <div nz-col nzSpan="14"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>
  </form>

  <div class="footer">
    <button
      type="button"
      nz-button
      class="ant-btn"
      style="margin-right: 8px"
      (click)="close()"
    >
      <span>Cancel</span>
    </button>

    <button
      type="button"
      class="ant-btn ant-btn-primary"
      nz-button
      (click)="Save()"
      [nzLoading]="loadingRecords"
    >
      <span>Save & Preview</span>
    </button>
  </div>
</div>

<nz-modal
  [nzWidth]="getwidth()"
  [(nzVisible)]="printOrderModalVisible"
  nzMaskClosable="false"
  nzClosable="false"
  [nzContent]="printOrderModalContent"
  [nzTitle]="'Order'"
  (nzOnCancel)="printOrderModalCancel()"
  [nzFooter]="printOrderModalFooter"
>
  <ng-template #printOrderModalContent>
    <nz-spin [nzSpinning]="loadingRecords">
      <div style="height: 450px; overflow: auto">
        <div id="printOrderModal">
          <div>
            <div
              style="font-size: 14px !important; font-family: Bookman Old Style"
            >
              <div
                style="
                  text-align: center;
                  margin-top: 20px;
                  margin-bottom: 10px;
                  font-size: 14px;
                "
              ></div>
              <div style="font-size: 14px !important">
                <p>
                  <b>Dated:-</b>
                  <!-- <b>Dated:-{{ getdateeeee() }}</b> -->
                </p>
                <p style="text-align: justify">
                  1. {{ LTC1data.SALUTATION }}
                  <b
                    >{{ LTC1data.EMPLOYEE_NAME }}, {{ LTC1data.DESIGNATION }},
                    {{ LTC1data.OFFICE_NAME }}</b
                  >
                  had submitted LTC Bill for the {{ LTC1data.ORDER_YEAR }}
                  <b>{{ LTC1data.BLOCK_YEAR }}</b> in this office on
                  <b>{{ LTC1data.BILL_SUBMITTED_DATE | date : "dd-MM-yyyy" }}</b
                  >. Wherein he/she has claimed LTC for

                  <span *ngIf="relationdata1.length > 0">
                    <ng-container
                      *ngFor="
                        let item of relationdata1;
                        let last = last;
                        let i = index
                      "
                    >
                      <b> {{ item.RELATIONSHIP }} </b>
                      <ng-container *ngIf="relationdata1.length > 1 && !last">
                        <ng-container
                          *ngIf="relationdata1.length === 2 && i === 0"
                        >
                          and
                        </ng-container>
                        <ng-container
                          *ngIf="
                            relationdata1.length > 2 &&
                            i < relationdata1.length - 2
                          "
                          >,
                        </ng-container>
                        <ng-container
                          *ngIf="
                            relationdata1.length > 2 &&
                            i === relationdata1.length - 2
                          "
                        >
                          and
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </span>
                  only .
                  <!-- <span *ngFor="let data of relationdata1; let k = index"
                    ><b>
                      <span *ngIf="relationdata1.length == 1">{{
                        data.RELATIONSHIP
                      }}</span>

                      <span *ngIf="relationdata1.length > 1"
                        >{{ data.RELATIONSHIP }},</span
                      >
                    </b> </span
                  >. -->
                </p>
                <br />
                <p style="text-align: justify">
                  2. The pay level of the officer is
                  <b> {{ getlevel(LTC1data.GRADE_PAY_LEVEL) }} </b>hence he/she
                  is entitled for economy/business class Air Fare, While going
                  to the bills/vouchers and documents submitted by the officer
                  it is observed that the officer has claimed actual expenses.
                </p>
                <br />
                <p style="text-align: justify">
                  3. As per Service book record, the details of
                  <b>previous LTC claimed </b>by the officer is as under:-
                </p>
                <br />
                <table class="tbbb" style="border: 1px solid black; width: 99%">
                  <tr class="tbbb" style="border: 1px solid black">
                    <td
                      class="tbbb"
                      style="border: 1px solid black; text-align: left"
                    >
                      Home Town Declared
                    </td>
                    <td
                      class="tbbb"
                      style="
                        border: 1px solid black;
                        text-align: center;
                        width: 50%;
                      "
                    ></td>
                  </tr>
                  <tr class="tbbb" style="border: 1px solid black">
                    <td
                      class="tbbb"
                      style="border: 1px solid black; text-align: left"
                    >
                      Previous LTC claimed (Home Town/ Any where in India)
                    </td>
                    <td
                      class="tbbb"
                      style="
                        border: 1px solid black;
                        text-align: left;
                        width: 50%;
                      "
                    ></td>
                  </tr>
                  <tr class="tbbb" style="border: 1px solid black">
                    <td
                      class="tbbb"
                      style="border: 1px solid black; text-align: left"
                    >
                      Block Year / Calendar Year
                    </td>
                    <td
                      class="tbbb"
                      style="
                        border: 1px solid black;
                        text-align: left;
                        width: 50%;
                      "
                    ></td>
                  </tr>
                  <tr class="tbbb" style="border: 1px solid black">
                    <td
                      class="tbbb"
                      style="border: 1px solid black; text-align: left"
                    >
                      Date of journey
                    </td>
                    <td
                      class="tbbb"
                      style="
                        border: 1px solid black;
                        text-align: left;
                        width: 50%;
                      "
                    ></td>
                  </tr>
                  <tr class="tbbb" style="border: 1px solid black">
                    <td
                      class="tbbb"
                      style="border: 1px solid black; text-align: left"
                    >
                      Destination
                    </td>
                    <td
                      class="tbbb"
                      style="
                        border: 1px solid black;
                        text-align: left;
                        width: 50%;
                      "
                    ></td>
                  </tr>
                </table>

                <p style="margin-top: 10px">
                  And as per service book, the details of the
                  <b>current LTC claimed</b> by the officer is as under:-
                </p>
                <table style="border: 1px solid black; width: 99%">
                  <tr class="tbbb" style="border: 1px solid black">
                    <td
                      class="tbbb"
                      style="border: 1px solid black; text-align: left"
                    >
                      Home Town Declared
                    </td>
                    <td
                      class="tbbb"
                      style="
                        border: 1px solid black;
                        text-align: left;
                        width: 50%;
                      "
                    ></td>
                  </tr>
                  <tr class="tbbb" style="border: 1px solid black">
                    <td
                      class="tbbb"
                      style="border: 1px solid black; text-align: left"
                    >
                      Current LTC claimed (Home Town/ Any where in India)
                    </td>
                    <td
                      class="tbbb"
                      style="
                        border: 1px solid black;
                        text-align: left;
                        width: 50%;
                      "
                    ></td>
                  </tr>
                  <tr class="tbbb" style="border: 1px solid black">
                    <td
                      class="tbbb"
                      style="border: 1px solid black; text-align: left"
                    >
                      Block Year / Calendar Year
                    </td>
                    <td
                      class="tbbb"
                      style="
                        border: 1px solid black;
                        text-align: left;
                        width: 50%;
                      "
                    ></td>
                  </tr>
                  <tr class="tbbb" style="border: 1px solid black">
                    <td
                      class="tbbb"
                      style="border: 1px solid black; text-align: left"
                    >
                      Date of journey
                    </td>
                    <td
                      class="tbbb"
                      style="
                        border: 1px solid black;
                        text-align: left;
                        width: 50%;
                      "
                    ></td>
                  </tr>
                  <tr class="tbbb" style="border: 1px solid black">
                    <td
                      class="tbbb"
                      style="border: 1px solid black; text-align: left"
                    >
                      Destination
                    </td>
                    <td
                      class="tbbb"
                      style="
                        border: 1px solid black;
                        text-align: left;
                        width: 50%;
                      "
                    ></td>
                  </tr>
                </table>

                <p style="margin-top: 10px">
                  Necessary Entries of current LTC claim made by the officer has
                  been made in service book of the officer:-
                </p>
                <div
                  nz-row
                  [nzGutter]="10"
                  style="margin-top: 50px; margin-bottom: 20px"
                >
                  <div
                    nz-col
                    nzXs="12"
                    nzSm="12"
                    nzMd="12"
                    nzLg="12"
                    nzXl="12"
                    nzSpan="12"
                    style="text-align: left"
                  >
                    <b>Entry made by:</b>
                  </div>
                  <div
                    nz-col
                    nzXs="12"
                    nzSm="12"
                    nzMd="12"
                    nzLg="12"
                    nzXl="12"
                    nzSpan="12"
                    style="text-align: right"
                  >
                    <b style="text-align: right; padding-right: 15px"
                      >Checked by:</b
                    >
                  </div>
                </div>

                <p
                  style="
                    page-break-inside: avoid !important;

                    font-size: 14px;
                    margin-top: 20px;
                    page-break-before: always !important;
                  "
                >
                  4. The checklist for the LTC bill submitted by
                  <b
                    >{{ LTC1data.SALUTATION }} {{ LTC1data.EMPLOYEE_NAME }},
                    {{ LTC1data.DESIGNATION }}, </b
                  >, Pay level=<b>{{ getlevel(LTC1data.GRADE_PAY_LEVEL) }} </b>
                  for the {{ LTC1data.ORDER_YEAR }}
                  <b>{{ LTC1data.BLOCK_YEAR }}</b> is as under:-
                </p>
                <div nz-row>
                  <div nz-col nzSpan="2"></div>
                  <div nz-col nzSpan="22">
                    <table
                      style="
                        border: 1px solid black;
                        font-size: 12px !important;
                        font-size: 12px !important;
                      "
                    >
                      <tr
                        style="
                          border: 1px solid black;
                          font-size: 12px !important;
                        "
                      >
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                            width: 10%;
                          "
                        >
                          Sr.No
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                            width: 70%;
                          "
                        >
                          Subject
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                            width: 10%;
                          "
                        >
                          Yes
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                            width: 10%;
                          "
                        >
                          No
                        </td>
                      </tr>

                      <tr
                        style="
                          border: 1px solid black;
                          font-size: 12px !important;
                        "
                      >
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          1
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: left;
                            width: 50%;
                          "
                        >
                          Whether Original bills submitted in proper format
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          <span *ngIf="LTC1data.IS_ORIGINAL_BILL_SUBMITTED == 1"
                            ><i nz-icon nzType="check" nzTheme="outline"></i
                          ></span>
                          <span
                            *ngIf="LTC1data.IS_ORIGINAL_BILL_SUBMITTED == 0"
                          ></span>
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          <span
                            *ngIf="LTC1data.IS_ORIGINAL_BILL_SUBMITTED == 1"
                          ></span>
                          <span *ngIf="LTC1data.IS_ORIGINAL_BILL_SUBMITTED == 0"
                            ><i nz-icon nzType="check" nzTheme="outline"></i
                          ></span>
                        </td>
                      </tr>

                      <tr
                        style="
                          border: 1px solid black;
                          font-size: 12px !important;
                        "
                      >
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          2
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: left;
                            width: 50%;
                          "
                        >
                          Whether Block Year mentioned
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          <span
                            *ngIf="LTC1data.WHETHER_BLOCK_YEAR_MENTIONED == 1"
                            ><i nz-icon nzType="check" nzTheme="outline"></i
                          ></span>
                          <span
                            *ngIf="LTC1data.WHETHER_BLOCK_YEAR_MENTIONED == 0"
                          ></span>
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          <span
                            *ngIf="LTC1data.WHETHER_BLOCK_YEAR_MENTIONED == 1"
                          ></span>
                          <span
                            *ngIf="LTC1data.WHETHER_BLOCK_YEAR_MENTIONED == 0"
                            ><i nz-icon nzType="check" nzTheme="outline"></i
                          ></span>
                        </td>
                      </tr>
                      <tr
                        style="
                          border: 1px solid black;
                          font-size: 12px !important;
                        "
                      >
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          3
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: left;
                            width: 50%;
                          "
                        >
                          Whether bills has been submitted in proper time (If
                          advance is taken then bills should be submitted in 30
                          days after completion of journey and bills should be
                          submitted in 90 days after completion of journey if
                          advance is not taken).
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          <span
                            *ngIf="LTC1data.IS_BILL_SUBMITTED_PROPER_TIME == 1"
                            ><i nz-icon nzType="check" nzTheme="outline"></i
                          ></span>
                          <span
                            *ngIf="LTC1data.IS_BILL_SUBMITTED_PROPER_TIME == 0"
                          ></span>
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          <span
                            *ngIf="LTC1data.IS_BILL_SUBMITTED_PROPER_TIME == 1"
                          ></span>
                          <span
                            *ngIf="LTC1data.IS_BILL_SUBMITTED_PROPER_TIME == 0"
                            ><i nz-icon nzType="check" nzTheme="outline"></i
                          ></span>
                        </td>
                      </tr>
                      <tr
                        style="
                          border: 1px solid black;
                          font-size: 12px !important;
                        "
                      >
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          4
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: left;
                            width: 50%;
                          "
                        >
                          In case of Air Journey, whether original boarding pass
                          and Tickets of Air Fare duly submitted and in case of
                          Train Journey Train Tickets submitted.
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          <span
                            *ngIf="LTC1data.IS_PASS_OR_TICKET_SUBMITTED == 1"
                            ><i nz-icon nzType="check" nzTheme="outline"></i
                          ></span>
                          <span
                            *ngIf="LTC1data.IS_PASS_OR_TICKET_SUBMITTED == 0"
                          ></span>
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          <span
                            *ngIf="LTC1data.IS_PASS_OR_TICKET_SUBMITTED == 1"
                          ></span>
                          <span
                            *ngIf="LTC1data.IS_PASS_OR_TICKET_SUBMITTED == 0"
                            ><i nz-icon nzType="check" nzTheme="outline"></i
                          ></span>
                        </td>
                      </tr>
                      <tr
                        style="
                          border: 1px solid black;
                          font-size: 12px !important;
                        "
                      >
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          4(a)
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: left;
                            width: 50%;
                          "
                        >
                          Print-out of concerned webpage of the ATAs having
                          flight & fare details for concerned time slot for
                          tickets taken after 28/08/2022.
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          <span
                            *ngIf="
                              LTC1data.IS_PRINTOUT_SUBMITTED_FOR_CONCERNED_TIME_SLOT ==
                              'Y'
                            "
                            ><i nz-icon nzType="check" nzTheme="outline"></i
                          ></span>
                          <!-- <span
                            *ngIf="
                              LTC1data.IS_PRINTOUT_SUBMITTED_FOR_CONCERNED_TIME_SLOT ==
                              
                            "
                          ></span> -->
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          <span
                            *ngIf="
                              LTC1data.IS_PRINTOUT_SUBMITTED_FOR_CONCERNED_TIME_SLOT ==
                              1
                            "
                          ></span>
                          <span
                            *ngIf="
                              LTC1data.IS_PRINTOUT_SUBMITTED_FOR_CONCERNED_TIME_SLOT ==
                              0
                            "
                            ><i nz-icon nzType="check" nzTheme="outline"></i
                          ></span>
                        </td>
                      </tr>
                      <tr
                        style="
                          border: 1px solid black;
                          font-size: 12px !important;
                        "
                      >
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          5
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: left;
                            width: 50%;
                          "
                        >
                          Whether Bank Mandate Form Submitted
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          <span
                            *ngIf="LTC1data.IS_BANK_MANDATE_FORM_SUBMITTED == 1"
                            ><i nz-icon nzType="check" nzTheme="outline"></i
                          ></span>
                          <span
                            *ngIf="LTC1data.IS_BANK_MANDATE_FORM_SUBMITTED == 0"
                          ></span>
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          <span
                            *ngIf="LTC1data.IS_BANK_MANDATE_FORM_SUBMITTED == 1"
                          ></span>
                          <span
                            *ngIf="LTC1data.IS_BANK_MANDATE_FORM_SUBMITTED == 0"
                            ><i nz-icon nzType="check" nzTheme="outline"></i
                          ></span>
                        </td>
                      </tr>
                      <tr
                        style="
                          border: 1px solid black;
                          font-size: 12px !important;
                        "
                      >
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          6
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: left;
                            width: 50%;
                          "
                        >
                          Whether Copy of Prior Intimation/Leave Application
                          Given
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          <span
                            *ngIf="
                              LTC1data.IS_COPY_OF_INTIMATION_OR_LEAVE_APPLICATION_GIVEN ==
                              1
                            "
                            ><i nz-icon nzType="check" nzTheme="outline"></i
                          ></span>
                          <span
                            *ngIf="
                              LTC1data.IS_COPY_OF_INTIMATION_OR_LEAVE_APPLICATION_GIVEN ==
                              0
                            "
                          ></span>
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          <span
                            *ngIf="
                              LTC1data.IS_COPY_OF_INTIMATION_OR_LEAVE_APPLICATION_GIVEN ==
                              1
                            "
                          ></span>
                          <span
                            *ngIf="
                              LTC1data.IS_COPY_OF_INTIMATION_OR_LEAVE_APPLICATION_GIVEN ==
                              0
                            "
                            ><i nz-icon nzType="check" nzTheme="outline"></i
                          ></span>
                        </td>
                      </tr>
                      <tr
                        style="
                          border: 1px solid black;
                          font-size: 12px !important;
                        "
                      >
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          7
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: left;
                            width: 50%;
                          "
                        >
                          Whether copy of leave sanctioned order Given
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          <span
                            *ngIf="
                              LTC1data.IS_COPY_OF_LEAVE_LEAVE_SANCTIONED_ORDER_GIVEN ==
                              1
                            "
                            ><i nz-icon nzType="check" nzTheme="outline"></i
                          ></span>
                          <span
                            *ngIf="
                              LTC1data.IS_COPY_OF_LEAVE_LEAVE_SANCTIONED_ORDER_GIVEN ==
                              0
                            "
                          ></span>
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          <span
                            *ngIf="
                              LTC1data.IS_COPY_OF_LEAVE_LEAVE_SANCTIONED_ORDER_GIVEN ==
                              1
                            "
                          ></span>
                          <span
                            *ngIf="
                              LTC1data.IS_COPY_OF_LEAVE_LEAVE_SANCTIONED_ORDER_GIVEN ==
                              0
                            "
                            ><i nz-icon nzType="check" nzTheme="outline"></i
                          ></span>
                        </td>
                      </tr>
                      <tr
                        style="
                          border: 1px solid black;
                          font-size: 12px !important;
                        "
                      >
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          8
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: left;
                            width: 50%;
                          "
                        >
                          Latest Payment Slip for pay level purpose
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          <span
                            *ngIf="
                              LTC1data.IS_LATEST_PAYMENT_SLIP_FOR_PAY_LEVEL == 1
                            "
                            ><i nz-icon nzType="check" nzTheme="outline"></i
                          ></span>
                          <span
                            *ngIf="
                              LTC1data.IS_LATEST_PAYMENT_SLIP_FOR_PAY_LEVEL == 0
                            "
                          ></span>
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          <span
                            *ngIf="
                              LTC1data.IS_LATEST_PAYMENT_SLIP_FOR_PAY_LEVEL == 1
                            "
                          ></span>
                          <span
                            *ngIf="
                              LTC1data.IS_LATEST_PAYMENT_SLIP_FOR_PAY_LEVEL == 0
                            "
                            ><i nz-icon nzType="check" nzTheme="outline"></i
                          ></span>
                        </td>
                      </tr>
                      <tr
                        style="
                          border: 1px solid black;
                          font-size: 12px !important;
                        "
                      >
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          9
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: left;
                            width: 50%;
                          "
                        >
                          Dependency Certificate given in case if sons age is
                          more than 18 years and daughters age is more than 24
                          years
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          <span
                            *ngIf="
                              LTC1data.IS_DEPENDENCY_CERTIFICATE_GIVEN == 'Y'
                            "
                            ><i nz-icon nzType="check" nzTheme="outline"></i
                          ></span>
                          <!-- <span
                            *ngIf="LTC1data.IS_DEPENDENCY_CERTIFICATE_GIVEN == 0"
                          ></span> -->
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          <span
                            *ngIf="
                              LTC1data.IS_DEPENDENCY_CERTIFICATE_GIVEN == 1
                            "
                          ></span>
                          <span
                            *ngIf="
                              LTC1data.IS_DEPENDENCY_CERTIFICATE_GIVEN == 0
                            "
                            ><i nz-icon nzType="check" nzTheme="outline"></i
                          ></span>
                        </td>
                      </tr>
                      <tr
                        style="
                          border: 1px solid black;
                          font-size: 12px !important;
                        "
                      >
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          10
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: left;
                            width: 50%;
                          "
                        >
                          Whether Advance for LTC Granted
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          <span *ngIf="LTC1data.IS_ADVANCE_FOR_LTC_GRANTED == 1"
                            ><i nz-icon nzType="check" nzTheme="outline"></i
                          ></span>
                          <span
                            *ngIf="LTC1data.IS_ADVANCE_FOR_LTC_GRANTED == 0"
                          ></span>
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          <span
                            *ngIf="LTC1data.IS_ADVANCE_FOR_LTC_GRANTED == 1"
                          ></span>
                          <span *ngIf="LTC1data.IS_ADVANCE_FOR_LTC_GRANTED == 0"
                            ><i nz-icon nzType="check" nzTheme="outline"></i
                          ></span>
                        </td>
                      </tr>
                      <tr
                        style="
                          border: 1px solid black;
                          font-size: 12px !important;
                        "
                      >
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          11
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: left;
                            width: 50%;
                          "
                        >
                          Whether Home Town
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          <span *ngIf="LTC1data.WHETHER_HOME_TOWN == 1"
                            ><i nz-icon nzType="check" nzTheme="outline"></i
                          ></span>
                          <span *ngIf="LTC1data.WHETHER_HOME_TOWN == 0"></span>
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          <span *ngIf="LTC1data.WHETHER_HOME_TOWN == 1"></span>
                          <span *ngIf="LTC1data.WHETHER_HOME_TOWN == 0"
                            ><i nz-icon nzType="check" nzTheme="outline"></i
                          ></span>
                        </td>
                      </tr>
                      <tr
                        style="
                          border: 1px solid black;
                          font-size: 12px !important;
                        "
                      >
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          12
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: left;
                            width: 50%;
                          "
                        >
                          Whether All Over India
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          <span *ngIf="LTC1data.WHETHER_ALL_OVER_INDIA == 1"
                            ><i nz-icon nzType="check" nzTheme="outline"></i
                          ></span>
                          <span
                            *ngIf="LTC1data.WHETHER_ALL_OVER_INDIA == 0"
                          ></span>
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          <span
                            *ngIf="LTC1data.WHETHER_ALL_OVER_INDIA == 1"
                          ></span>
                          <span *ngIf="LTC1data.WHETHER_ALL_OVER_INDIA == 0"
                            ><i nz-icon nzType="check" nzTheme="outline"></i
                          ></span>
                        </td>
                      </tr>
                      <tr
                        style="
                          border: 1px solid black;
                          font-size: 12px !important;
                        "
                      >
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          13
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: left;
                            width: 50%;
                          "
                        >
                          Whether converted Home Town to NER/Andaman &
                          Nicobar/Jammu & Kashmir/Ladakh
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          <span
                            *ngIf="
                              LTC1data.WHETHER_CONVERTED_HOME_TO_NER_AN_JK_L ==
                              1
                            "
                            ><i nz-icon nzType="check" nzTheme="outline"></i
                          ></span>
                          <span
                            *ngIf="
                              LTC1data.WHETHER_CONVERTED_HOME_TO_NER_AN_JK_L ==
                              0
                            "
                          ></span>
                        </td>
                        <td
                          style="
                            border: 1px solid black;
                            font-size: 12px !important;
                            text-align: center;
                          "
                        >
                          <span
                            *ngIf="
                              LTC1data.WHETHER_CONVERTED_HOME_TO_NER_AN_JK_L ==
                              1
                            "
                          ></span>
                          <span
                            *ngIf="
                              LTC1data.WHETHER_CONVERTED_HOME_TO_NER_AN_JK_L ==
                              0
                            "
                            ><i nz-icon nzType="check" nzTheme="outline"></i
                          ></span>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <p style="margin-top: 10px">5.</p>

                <table style="border: 1px solid black; width: 99%">
                  <tr style="border: 1px solid black">
                    <td style="border: 1px solid black; text-align: center">
                      From
                    </td>
                    <td style="border: 1px solid black; text-align: center">
                      To
                    </td>
                    <td style="border: 1px solid black; text-align: center">
                      Mode of travel
                    </td>
                    <td style="border: 1px solid black; text-align: center">
                      No. of Passengers
                    </td>
                    <td style="border: 1px solid black; text-align: center">
                      Amount claimed with taxes/fees (in Rs.)
                    </td>
                    <td style="border: 1px solid black; text-align: center">
                      Base fare allowed for all passengers (in Rs.)
                    </td>
                    <td style="border: 1px solid black; text-align: center">
                      Taxes allowed (in Rs.)
                    </td>
                    <td style="border: 1px solid black; text-align: center">
                      Amount granted with taxes (In Rs.)
                    </td>
                    <td style="border: 1px solid black; text-align: center">
                      Remarks
                    </td>
                  </tr>
                  <tr
                    style="border: 1px solid black"
                    *ngFor="let data of formdata7; let k = index"
                  >
                    <td style="border: 1px solid black; text-align: center">
                      {{ data.DEPARTURE_FROM }}
                    </td>
                    <td style="border: 1px solid black; text-align: center">
                      {{ data.ARRIVAL_TO }}
                    </td>
                    <td style="border: 1px solid black; text-align: center">
                      {{ data.TRAVEL_MODE_NAME }}
                    </td>
                    <td style="border: 1px solid black; text-align: center">
                      {{ data.NO_OF_FAIRS }}
                    </td>
                    <td style="border: 1px solid black; text-align: center">
                      {{ data.JOURNEY_CLAIMED_AMOUNT }}
                      <!-- old -->
                    </td>
                    <td style="border: 1px solid black; text-align: center">
                      {{ data.FAIR_PAID }}
                    </td>
                    <td style="border: 1px solid black; text-align: center">
                      {{ data.ALLOWED_TOTAL }}
                    </td>
                    <td style="border: 1px solid black; text-align: center">
                      {{ data.AMOUNT_WITH_TAX }}
                    </td>
                    <td
                      *ngIf="k == 0"
                      [attr.rowspan]="remarkCount"
                      style="border: 1px solid black; text-align: center"
                    >
                      <!-- {{ allRemarks }} -->
                      {{ LTC1data.JOURNEY_REMARK }}
                    </td>
                    <!-- <td rowspan="4" style="border: 1px solid black; text-align: center">


                                      </td> -->
                  </tr>
                  <tr style="border: 1px solid black">
                    <td
                      colspan="7"
                      style="border: 1px solid black; text-align: right"
                    >
                      Total
                    </td>
                    <td style="border: 1px solid black; text-align: center">
                      <b> {{ amount }} /- </b>
                    </td>
                  </tr>

                  <tr style="border: 1px solid black">
                    <td
                      colspan="7"
                      style="border: 1px solid black; text-align: right"
                    >
                      Advance
                    </td>
                    <td style="border: 1px solid black; text-align: center">
                      <b> {{ LTC1data.AMOUNT_OF_ADVANCE }} /- </b>
                    </td>
                  </tr>
                  <tr style="border: 1px solid black">
                    <td
                      colspan="7"
                      style="border: 1px solid black; text-align: right"
                    >
                      Total
                    </td>
                    <td style="border: 1px solid black; text-align: center">
                      <b> {{ namount }}/- </b>
                    </td>
                    <td
                      style="border: 1px solid black; text-align: center"
                    ></td>
                  </tr>
                </table>
                <p
                  style="
                    margin-top: 10px;
                    padding-left: 10px;
                    font-weight: bold;
                  "
                  [innerHtml]="LTC1data.RETURN_JOURNEY_REMARK"
                >
                  <!-- <b> {{ LTC1data.RETURN_JOURNEY_REMARK }} </b> -->
                </p>

                <p style="margin-top: 10px; padding-left: 20px">
                  The necessary procedural requirements as per LTC rules have
                  been fulfilled by the officer, hence the net bill amount which
                  is to be issued comes to <b>Rs. {{ namount }}/-</b>
                </p>
                <p>6. File is put up for kind perusal and signature please.</p>

                <div
                  nz-row
                  [nzGutter]="10"
                  style="margin-top: 20px; margin-bottom: 20px"
                >
                  <div
                    nz-col
                    nzXs="12"
                    nzSm="12"
                    nzMd="12"
                    nzLg="12"
                    nzXl="12"
                    nzSpan="12"
                    style="text-align: left"
                  >
                    <span style="text-align: left">
                      Prepared by:
                      <!-- Prepared by: {{ LTC1data.INSPECTOR_NAME }} -->
                    </span>
                  </div>
                </div>
                <div nz-row [nzGutter]="10" style="margin-top: 50px">
                  <div
                    nz-col
                    nzXs="12"
                    nzSm="12"
                    nzMd="12"
                    nzLg="12"
                    nzXl="12"
                    nzSpan="12"
                  ></div>
                  <div
                    nz-col
                    nzXs="12"
                    nzSm="12"
                    nzMd="12"
                    nzLg="12"
                    nzXl="12"
                    nzSpan="12"
                    style="text-align: center"
                  >
                    <span style="text-align: center">
                      <div><B>(जितेंद्र रा. चांदेकर)</B></div>
                      <div>
                        <b style="text-transform: uppercase">
                          (Jitendra R Chandekar)
                        </b>
                      </div>
                      <div>आयकर अधिकारी</div>
                      <div>
                        <b> Income Tax Officer </b>
                      </div>
                      <div>राजपत्रित वेतन बिल कक्ष - २, मुंबई</div>
                      <div>
                        <b> Gazetted Pay Bill - II, Mumbai </b>
                      </div>
                    </span>
                    <!-- <span style="text-align: center"
                      >{{ NAME_HN }}<br />
                      <b> ({{ NAME }})</b> <br />
                      {{ POST_HN }}<br />
                      <b>{{ POST }} </b><br />
                      <span *ngIf="LTC1data.SIGNATURE_ID == 2">
                        राजपत्रित वेतन बिल कक्ष - २, मुंबई
                      </span>
                      <span *ngIf="LTC1data.SIGNATURE_ID != 2">
                        {{ OFFICE_NAME_HN }}
                      </span>
                      <br />
                      <b>{{ OFFICE_NAME }}</b>
                    </span> -->
                  </div>
                </div>
                <div nz-row>
                  <div nz-col nzSpan="10" style="text-align: center">
                    <p style="text-align: center">
                      <b> (मुदित श्रीवास्तव, भा.रा.से.) </b><br />
                      <b> (MUDIT SRIVASTAVA, I.R.S.)</b><br />
                      अपर आयकर आयुक्त (मुख्या.) कार्मिक, मुंबई <br />
                      <b> Addl. Commissioner of Income Tax (HQ) </b><br />
                      <b> Personnel, Mumbai</b>
                    </p>
                  </div>
                  <div nz-col nzSpan="14"></div>
                </div>

                <div
                  nz-row
                  [nzGutter]="10"
                  style="margin-top: 20px; margin-bottom: 20px"
                >
                  <div
                    nz-col
                    nzXs="16"
                    nzSm="16"
                    nzMd="16"
                    nzLg="16"
                    nzXl="16"
                    nzSpan="16"
                    style="text-align: left"
                  ></div>
                  <div
                    nz-col
                    nzXs="8"
                    nzSm="8"
                    nzMd="8"
                    nzLg="8"
                    nzXl="8"
                    nzSpan="8"
                    style="text-align: left"
                  >
                    <!-- <b style="text-align: left; border: 1px solid"
                      >Checked by..............</b
                    > -->
                  </div>
                </div>
                <div nz-row>
                  <div nz-col nzSpan="10" style="text-align: center">
                    <p style="text-align: center; margin-top: 50px">
                      <span style="text-align: center">
                        <B> (विनय सिन्हा भा.रा.से.)</B><br />
                        <b> (VINAY SINHA I.R.S) </b><br />
                        आयकर आयुक्त (प्रशासन एव करदाता सेवाएँ),मुंबई <br />
                        <b> Commissioner of Income Tax </b><br />
                        <b> Admn And TPS Mumbai </b><br />
                      </span>
                    </p>
                  </div>
                  <div nz-col nzSpan="14"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>
  </ng-template>

  <ng-template #printOrderModalFooter>
    <button nz-button nzType="default" (click)="printOrderModalCancel()">
      Close
    </button>

    <button
      nz-button
      nzType="default"
      [useExistingCss]="true"
      printSectionId="printOrderModal"
      ngxPrint
    >
      Print
    </button>

    <button
      nz-button
      nzType="primary"
      [nzLoading]="pdfDownload"
      (click)="generatePDF()"
    >
      Download
    </button>
  </ng-template>
</nz-modal>
