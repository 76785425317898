export class CheckList {
  ID: number = 0;
  CLIENT_ID = 0;
  IS_FORWARDING_LETTER: any;
  MEDICAL_REIMBURSMENT_CLAIM_FORM_FOR_CGHS: any;
  COPY_OF_CGHS_CARD: any;
  DISCHARGE_CARD: any;
  FORM_NO_3_MEDICAL_CLAIM: any;
  FINAL_BILL_ORIGINAL_ALONG_WITH_XEROX: any;
  PAYSLIP_OF_PERIOD_OF_TREATMENT: any;
  BANK_MANDATE_FORM_ALONG_WITH_CANCELLED_CHEQUE: any;
  PRESCIPTION_OF_MEDICINES_PURSCHASED_OUTSIDE: any;
  REJECT_REMARK: any;
  CHECKLIST_STATUS: string = 'A';
  EMP_ID: any;
  CLAIM_ID: any;
  CURRENT_STAGE_ID: any;
  ON_HOLD_REMARK: any;
  ON_HOLD_DATE: any;
  FILE_NO_ID: any;
}
