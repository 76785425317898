export class TranferapplicationMaster {
  ID: number = 0;
  CLIENT_ID = 0;
  NAME: any;
  EMPLOYEE_NAME: any;
  STATUS: boolean = true;
  EMPLOYEE_CODE: any;
  GRADE_PAY: any;
  OFFICE_NAME: any;
  DESIGNATION: any;
  LOCATION: any;
  DDO_OF_THE_OFFICIAL: any;
  BENEFICIARY_TYPE: string = '';
  CGHS_CARD_NO: any;
  CGHS_CARD_VALIDITY: any;
  EMP_ID: any;
  GRADE_PAY_LEVEL: any;
  MOBILE_NO: any;
  EMAIL_ID: any;
  ADDRESS: any;
  CURRENT_STAGE_ID: any;
  INSPECTOR_ID: any;
  NAME_OF_FAMILY_MEMBER: any;
  AGE: any;
  RELATIONSHIP: any;
  NEW_ADDRESS: any;
  NEW_OFFICE_NAME: any;
  TRANSFER_ID: any;
  memberTransferData: any = [];
  ADVANCED_AMOUNT: any;
  ADVANCE_TAKEN_DATE: any;
  FILE_NO: any;
  TRAVEL_MODE_ID: any;
  STATION_NAME: any;
  TOTAL_AMOUNT: any;
  IS_ADVANCE_TAKEN: any;
  AMOUNT: any;
  IS_HIGHER_CLASS_ACCOMODATION: boolean = false;
  IS_EDITABLE_DESTINATION: boolean = false;
  CERTIFIED_INFORMATION: any;
  transportationData: any = [];
  transportationDataPersonal: any = [];
  journeyData: any = [];
  FILE_URL: any;
  TRANSFER_GRANT_AMOUNT: any;
  ORDER_BILL_NO: any;
  ORDER_REASON: any;
  HEADQUARTERS_NAME: any;
  NEW_HEADQUARTERS_NAME: any;
  JOURNEY_REMARK: any;
  PERSONAL_REMARK: any;
  TRAIN_REMARK: any;
  VEHICLE_BROUGHT_SELF_PROPULATION: boolean = true;
  VEHICLE_BROUGHT_ROAD_MILEAGE: any;
  VEHICLE_BROUGHT_ROAD_MILEAGE_KMS: any;
  VEHICLE_BROUGHT_TOTAL: any;
  TRAIN_BROUGHT_KMS: any;
  TRAIN_BROUGHT_CHARGE: any;
  TRUCK_SHIP_CHARGE: any;
  TRAIN_BROUGHT_FOR_KMS_NO: any;
  TRAIN_BROUGHT_FOR_CHARGE_NO: any;
  ASSUMPTION_CHARGE: any;
  LPC: any;
  MANDATE_FORM: any;
  TRANSFER_ORDER_SERVICE_CERTIFICATE: any;
  TICKET_BOARDING_PASS: any;
  TRANSPORTATION_BILLS: any;
  CONDOLATION_OF_DELAY: any;
  TRANSFER_GRANT: any;

  ORDER_FORM_REMARK: any =
    'Vide F No. A. 22011/5/2022-AD-Vl, Office Order No. 212 Of 2022 dated Pg. No. of. 17/25 SI No. 20.';
  ORDER_FORM_REMARK_2: any =
    "The Officer has already Claimed an amount of against CTG and Airfare for Brother only vide CP00002835 dated (Annexure 'A').";
  ORDER_FORM_TOTAL: any;
  ORDER_CTG: any;
  ORDER_PERSONAL_EFFECT: any;
  ORDER_AIR_FARE: any;
  ORDER_FORM_NET_TOTAL: any;
  ORDER_FORM_DATE: any;
  SIGNATURE_ID: any = 2;
  ASSUMPTION_CHARGE_STATUS: any;
  LPC_STATUS: any;
  MANDATE_FORM_STATUS: any;
  TRANSFER_ORDER_SERVICE_CERTIFICATE_STATUS: any;
  TICKET_BOARDING_PASS_STATUS: any;
  TRANSPORTATION_BILLS_STATUS: any;
  CONDOLATION_OF_DELAY_STATUS: any;
  IS_PERSONAL_CAR: boolean = false;
  CTG_ADMISIBLE_AMOUNT: any = 0;
  PERSONAL_EFFECT_ADMISIBLE_AMOUNT: any = 0;
  ROAD_MILEAGE_ADMISIBLE_AMOUNT: any = 0;
  JOURNEY_ADMISIBLE_AMOUNT: any = 0;
  PRIVATE_CONVIENCE_ADMISIBLE_AMOUNT: any = 0;
  CTG_REMARK: any;
  PRIVATE_CONVEYANCE_REMARK: any;
  CTG_TOTAL: number = 0;
  ROAD_MILEAGE_TOTAL: number = 0;
  JOURNEY_TOTAL: number = 0;
  PERSONAL_EFFECT_TOTAL: number = 0;
  PRIVATE_CONVEYANCE_TOTAL: number = 0;
  TRANSFER_STATUS: any = '';
  VEHICLE_CLAIMED_AMOUNT: any = 0;
  DESTINATION_REMARK: any;

  // ACTION_DATE:any=null;
  // ACTION_REMARK:any=null;
  // ACTION_STATUS:any='';
}
