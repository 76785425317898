<div>
  <nz-steps [nzCurrent]="current" style="margin: 0px 50px">
    <nz-step nzTitle="Tour Part A"></nz-step>
    <nz-step nzTitle="Journey Details"></nz-step>
    <nz-step nzTitle="Tour Details"></nz-step>
    <nz-step nzTitle=" Documents Details"></nz-step>
    <nz-step nzTitle="Checklist for TA on Tour Bills"></nz-step>
  </nz-steps>

  <div class="steps-content">
    <div *ngIf="current == 0">
      <div>
        <form nz-form #applicantMasterPages="ngForm">
          <nz-spin [nzSpinning]="isSpinning">
            <div nz-row>
              <div nz-col nzSpan="10">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>Name</nz-form-label>
                  <nz-form-control nzErrorTip="Please Enter Employee Name">
                    <input
                      nz-input
                      maxlength="30"
                      autocomplete="off"
                      name="empID"
                      type="text"
                      [(ngModel)]="data.EMPLOYEE_NAME"
                      [pattern]="namepatt"
                      placeHolder="Enter The Employee Name"
                      required
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>

              <!-- <div nz-col nzSpan="1" *ngIf="!data.EMP_ID">
                  <nz-form-item style="padding: 0px !important">
                    <nz-form-label nzNoColon></nz-form-label>
                    <nz-form-control>
                      <button nz-button nzType="primary" (click)="add()">
                        <i nz-icon nzType="plus"></i>
                      </button>
                    </nz-form-control>
                  </nz-form-item>
                </div> -->

              <!-- <div nz-col nzSpan="1" *ngIf="data.EMP_ID">
                  <nz-form-item style="padding: 0px !important">
                    <nz-form-label nzNoColon></nz-form-label>
                    <nz-form-control>
                      <button nz-button nzType="primary" (click)="edit(data)">
                        <i nz-icon nzType="edit"></i>
                      </button>
                    </nz-form-control>
                  </nz-form-item>
                </div> -->

              <div nz-col nzSpan="7">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Designation</nz-form-label
                  >
                  <nz-form-control nzErrorTip="Enter Designation">
                    <input
                      nz-input
                      maxlength="256"
                      autocomplete="off"
                      name="Designation"
                      type="text"
                      [(ngModel)]="data.DESIGNATION"
                      [pattern]="namepatt"
                      placeHolder="Enter The Designation"
                      required
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="6">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>Basic Pay</nz-form-label>

                  <nz-form-control nzErrorTip="Enter Correct Basic Pay">
                    <nz-input-group style="width: 100%" nzAddOnBefore="₹">
                      <input
                        nz-input
                        maxlength="8"
                        autocomplete="off"
                        (keypress)="omit($event)"
                        [(ngModel)]="data.BASIC_PAY"
                        name="gradePay"
                        type="text"
                        placeHolder="Enter Employee Basic Pay"
                        required
                      />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="11">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Head quater Address
                  </nz-form-label>
                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Enter Head quater Address"
                  >
                    <textarea
                      maxlength="256"
                      required
                      rows="2"
                      placeHolder="Enter Head quater Address"
                      [(ngModel)]="data.HEADQUARTERS_NAME"
                      [pattern]="namepatt"
                      name="addressfggggline1"
                      nz-input
                    ></textarea>
                  </nz-form-control>
                  <h6
                    style="color: red"
                    *ngIf="data.HEADQUARTERS_NAME != undefined"
                  >
                    {{ data.HEADQUARTERS_NAME.length }}/256
                  </h6>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="7">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>
                    Mobile Number
                  </nz-form-label>
                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Enter Valid Mobile Number"
                  >
                    <nz-input-group style="width: 100%" nzAddOnBefore="(+91)">
                      <input
                        nz-input
                        name="commiteemobileno"
                        [pattern]="mobpattern"
                        [(ngModel)]="data.MOBILE_NO"
                        maxlength="10"
                        minlength="10"
                        (keypress)="omit($event)"
                        placeHolder="Enter Mobile Number"
                        required
                      />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="6">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Grade Pay Level
                  </nz-form-label>
                  <nz-input-group>
                    <nz-select
                      name="HospitalType"
                      style="width: 100%"
                      nzShowSearch
                      nzPlaceHolder="Select Grade Pay Level"
                      [(ngModel)]="data.GRADE_PAY_LEVEL"
                    >
                      <nz-option
                        [nzValue]="'Level 1'"
                        [nzLabel]="'Level 1'"
                      ></nz-option>
                      <nz-option
                        [nzValue]="'Level 2'"
                        [nzLabel]="'Level 2'"
                      ></nz-option>
                      <nz-option
                        [nzValue]="'Level 3'"
                        [nzLabel]="'Level 3'"
                      ></nz-option>
                      <nz-option
                        [nzValue]="'Level 4'"
                        [nzLabel]="'Level 4'"
                      ></nz-option>
                      <nz-option
                        [nzValue]="'Level 5'"
                        [nzLabel]="'Level 5'"
                      ></nz-option>
                      <nz-option
                        [nzValue]="'Level 6'"
                        [nzLabel]="'Level 6'"
                      ></nz-option>
                      <nz-option
                        [nzValue]="'Level 7'"
                        [nzLabel]="'Level 7'"
                      ></nz-option>
                      <nz-option
                        [nzValue]="'Level 8'"
                        [nzLabel]="'Level 8'"
                      ></nz-option>
                      <nz-option
                        [nzValue]="'Level 9'"
                        [nzLabel]="'Level 9'"
                      ></nz-option>
                      <nz-option
                        [nzValue]="'Level 10'"
                        [nzLabel]="'Level 10'"
                      ></nz-option>
                      <nz-option
                        [nzValue]="'Level 11'"
                        [nzLabel]="'Level 11'"
                      ></nz-option>
                      <nz-option
                        [nzValue]="'Level 12'"
                        [nzLabel]="'Level 12'"
                      ></nz-option>
                      <nz-option
                        [nzValue]="'Level 13'"
                        [nzLabel]="'Level 13'"
                      ></nz-option>
                      <nz-option
                        [nzValue]="'Level 14'"
                        [nzLabel]="'Level 14'"
                      ></nz-option>
                      <nz-option
                        [nzValue]="'Level 15'"
                        [nzLabel]="'Level 15'"
                      ></nz-option>
                      <nz-option
                        [nzValue]="'Level 16'"
                        [nzLabel]="'Level 16'"
                      ></nz-option>
                      <nz-option
                        [nzValue]="'Level 17'"
                        [nzLabel]="'Level 17'"
                      ></nz-option>
                    </nz-select>
                  </nz-input-group>
                </nz-form-item>
              </div>

              <!-- <div nz-col nzSpan="10">
                                  <nz-form-item>
                                      <nz-form-label nzNoColon>Office Name</nz-form-label>
                                      <nz-form-control nzErrorTip="Enter Correct Office Name">
                                          <input nz-input autocomplete="off" maxlength="256" name="Office Name"
                                              type="text" [(ngModel)]="data.OFFICE_NAME"
                                              placeHolder="Enter The Office Name" required />
                                      </nz-form-control>
                                  </nz-form-item>
                              </div>
                              <div nz-col nzSpan="1"></div>
                              <div nz-col nzSpan="7">
                                  <nz-form-item>
                                      <nz-form-label nzRequired nzNoColon>DDO Of The Official</nz-form-label>
                                      <nz-form-control nzErrorTip="Enter Correct DDO Of The Official">
                                          <input nz-input maxlength="256" autocomplete="off" name="DDOOfficial"
                                              type="text" [(ngModel)]="data.DDO_OF_THE_OFFICIAL"
                                              placeHolder="Enter The DDO Of The Official" required />
                                      </nz-form-control>
                                  </nz-form-item>
                              </div> -->

              <!-- <div nz-col nzSpan="10">
                                  <div nz-row class="my-box1"> -->
              <!-- <nz-form-item>
                                          <nz-form-label nzRequired nzNoColon>Old Head Quater Name</nz-form-label>
                                          <nz-form-control nzHasFeedback nzErrorTip="Please Enter Old Head Quater Name">
                                              <input nz-input name="commfffiteeemail" maxlength="256"
                                                  [(ngModel)]="data.OFFICE_NAME" placeHolder="Enter Old Head Quater Name"
                                                  required />
                                          </nz-form-control>
                                      </nz-form-item> -->

              <!-- </div>
                              </div> -->
              <!-- <div nz-col nzSpan="1"></div> -->
              <!-- <div nz-col nzSpan="12">
                                  <div nz-row class="my-box1">
                                      <nz-form-item>
                                          <nz-form-label nzRequired nzNoColon>New Head Quater Name
                                          </nz-form-label>
                                          <nz-form-control nzHasFeedback nzErrorTip="Please Enter New Head Quater Name">
                                              <input nz-input name="newhead" maxlength="256"
                                                  [(ngModel)]="data.NEW_OFFICE_NAME" placeHolder="Enter Head Quater Name"
                                                  required />
                                          </nz-form-control>
                                      </nz-form-item>
  
                                      <nz-form-item>
                                          <nz-form-label nzRequired nzNoColon>
                                              New Residential Address
                                          </nz-form-label>
                                          <nz-form-control nzHasFeedback
                                              nzErrorTip="Please Enter New Residential Address">
                                              <textarea maxlength="256" required rows="2" maxlength="512"
                                                  placeHolder="Enter New Residential Name" [(ngModel)]="data.NEW_ADDRESS"
                                                  name="addrdfdfessline1" nz-input></textarea>
                                          </nz-form-control>
                                          <h6 style="color: red" *ngIf="data.NEW_ADDRESS != undefined">
                                              {{ data.NEW_ADDRESS.length }}/256
                                          </h6>
                                      </nz-form-item>
                                  </div>
                              </div> -->
            </div>
          </nz-spin>
        </form>
        <br />

        <!-- <nz-divider
            style="font-weight: bold; padding-top: 5px"
            nzText="Details and purpose of journey(s) performed :"></nz-divider>
          <br /> -->

        <!-- <form nz-form #journoey="ngForm">
            <div style="display: flex; gap: 452px; margin-left: 144px">
              <h3 style="text-align: center; margin-top: 10px">
                <b>Departure</b>
              </h3>
              <h3 style="text-align: center; margin-top: 10px">
                <b>Arrival</b>
              </h3>
            </div>
            <div nz-row>
              <div nz-col nzSpan="11">
                <div nz-row class="my-box1">
                  <div nz-col nzSpan="12">
                    <nz-form-item>
                      <nz-form-label nzRequired nzNoColon>From Date</nz-form-label>
                      <nz-form-control nzErrorTip="Please Select Date">
                        <nz-form-control
                          nzHasFeedback
                          nzErrorTip="Please Select Date">
                          <nz-date-picker
                            name="date"
                            [(ngModel)]="data2.DEPARTURE_DATETIME"
                            style="width: 100%"></nz-date-picker>
                        </nz-form-control>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                  <div nz-col nzSpan="12">
                    <nz-form-item>
                      <nz-form-label nzRequired nzNoColon>Journey From
                      </nz-form-label>
                      <nz-form-control nzErrorTip="Please Enter Journey From">
                        <nz-input-group style="width: 100%">
                          <input
                            nz-input
                            autocomplete="off"
                            [(ngModel)]="data2.DEPARTURE_FROM"
                            name="fairPaid"
                            type="text"
                            maxlength="255"
                            [pattern]="namepatt"
                            placeHolder="Enter Journey From" />
                        </nz-input-group>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
              </div>
              <div nz-col nzSpan="1"></div>
              <div nz-col nzSpan="12">
                <div nz-row class="my-box1">
                 
                  <div nz-col nzSpan="12">
                    <nz-form-item>
                      <nz-form-label nzRequired nzNoColon>To Date</nz-form-label>
                      <nz-form-control nzErrorTip="Please Select to Date">
                        <nz-form-control
                          nzHasFeedback
                          nzErrorTip="Please Select to Date">
                          <nz-date-picker
                            name="ddddddddate"
                            [(ngModel)]="data2.ARRIVAL_DATETIME"
                            style="width: 100%"></nz-date-picker>
                        </nz-form-control>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                  <div nz-col nzSpan="12">
                    <nz-form-item>
                      <nz-form-label nzRequired nzNoColon>Arrival To
                      </nz-form-label>
                      <nz-form-control nzErrorTip="Please Enter Journey To">
                        <nz-input-group style="width: 100%">
                          <input
                            nz-input
                            autocomplete="off"
                            [(ngModel)]="data2.ARRIVAL_TO"
                            name="faidfdfgrPaid"
                            type="text"
                            maxlength="255"
                            [pattern]="namepatt"
                            placeHolder="Enter Journey To" />
                        </nz-input-group>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
              </div>
            </div>
            <div nz-row>
              <div nz-col nzSpan="6">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>Travel Mode</nz-form-label>
                  <nz-form-control nzHasFeedback nzErrorTip="Select Mode">
                    <nz-select
                      nzAllowClear
                      name="dfgdfdfgdfgdfg"
                      (ngModelChange)="selectchangevent($event)"
                      [(ngModel)]="data2.TRAVEL_MODE_ID"
                      style="width: 100%"
                      nzPlaceHolder="Select Mode">
                      <nz-option
                        *ngFor="let role of modedata"
                        [nzValue]="role.ID"
                        [nzLabel]="role.NAME">
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="6">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>Class of Mode</nz-form-label>
                  <nz-form-control nzHasFeedback nzErrorTip="Select Class Mode">
                    <nz-select
                      nzAllowClear
                      name="classmodenamasnkadfhjajah"
                      [(ngModel)]="data2.TRAVEL_CLASS_ID"
                      style="width: 100%"
                      (ngModelChange)="selectchangevent1($event)"
                      nzPlaceHolder="Select Class Mode">
                      <nz-option
                        *ngFor="let role of classdata"
                        [nzValue]="role.ID"
                        [nzLabel]="role.NAME">
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="6">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>Days of halt</nz-form-label>
                  <nz-form-control nzErrorTip="Please Enter Days of halt">
                    <nz-input-group style="width: 100%">
                      <input
                        nz-input
                        autocomplete="off"
                        (keypress)="omit($event)"
                        [(ngModel)]="data2.DAYS_OF_HALT"
                        name="daysofhalt"
                        type="text"
                        maxlength="8"
                        placeHolder="Enter Days of halt" />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="6">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>Hours of halt</nz-form-label>
                  <nz-form-control nzErrorTip="Please Enter Hours of halt">
                    <nz-input-group style="width: 100%">
                      <input
                        nz-input
                        autocomplete="off"
                        (keypress)="onlynumdot($event)"
                        [(ngModel)]="data2.HOURS_OF_HALT"
                        name="amsdfsdsddount"
                        type="text"
                        maxlength="8"
                        placeHolder="Enter Hours of halt" />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </div>
  
              <div nz-col nzSpan="4">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>Fair Paid</nz-form-label>
                  <nz-form-control nzErrorTip="Please Enter Fair Paid">
                    <nz-input-group style="width: 100%" nzAddOnBefore="₹">
                      <input
                        nz-input
                        autocomplete="off"
                        (keypress)="omit($event)"
                        [(ngModel)]="data2.FARE_PAID"
                        name="rate"
                        type="text"
                        maxlength="8"
                        placeHolder="Enter Fair Paid" />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </div>
  
              <div nz-col nzSpan="4">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>Distance of Road</nz-form-label>
                  <nz-form-control nzErrorTip="Please Enter Distance of Road">
                    <nz-input-group style="width: 100%" nzAddOnAfter="Kms">
                      <input
                        nz-input
                        autocomplete="off"
                        (keypress)="omit($event)"
                        [(ngModel)]="data2.DISTANCE_IN_KM_FOR_ROAD"
                        name="rafdsgte"
                        type="text"
                        maxlength="8"
                        placeHolder="Enter Distance of Road" />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </div>
  
              <div nz-col nzSpan="9">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>Purpose of Journey</nz-form-label>
                  <nz-form-control nzErrorTip="Please Purpose of Journey">
                    <nz-input-group style="width: 100%">
                      <input
                        nz-input
                        autocomplete="off"
                        [(ngModel)]="data2.PURPOSE_OF_JOURNEY"
                        name="faidfdsdfsffgrPaid"
                        type="text"
                        maxlength="511"
                        [pattern]="namepatt"
                        placeHolder="Enter Purpose of Journey" />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="4">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>Is returned</nz-form-label>
                  <nz-form-control>
                    <nz-switch
                      [(ngModel)]="data2.IS_RETURNED"
                      name="isretained"
                      nzCheckedChildren="Yes"
                      nzUnCheckedChildren="No">
                    </nz-switch>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="3">
                <nz-form-item>
                  <nz-form-label nzNoColon></nz-form-label>
  
                  <button
                    nz-button
                    nzType="primary"
                    (click)="addData(true, journoey)"
                    nzJustify="end">
                    <i nz-icon nzType="plus"></i>Add
                  </button>
                </nz-form-item>
              </div>
            </div>
          </form> -->

        <br />
        <!-- <div nz-row>
            <div nz-col nzSpan="24">
              <nz-table
                class="my-scroll no-wrap1"
                nzBordered
                #dataxfdstable
                nzShowSizeChanger
                [nzLoading]="loadingRecords"
                [nzShowPagination]="false"
                [nzSize]="'small'"
                [nzScroll]="{ x: '2400px', y: '420px' }"
                [nzData]="detailsandpurpose"
                [nzTotal]="totalRecords">
                <thead>
                  <tr>
                    <th
                      nzWidth="7px"
                      style="text-align: center; font-weight: bold">
                      Action
                    </th>
                    <th
                      nzWidth="10px"
                      style="text-align: center; font-weight: bold">
                      From Date
                    </th>
                    <th
                      nzWidth="15px"
                      style="text-align: center; font-weight: bold">
                      Journey From
                    </th>
                    <th
                      nzWidth="10px"
                      style="text-align: center; font-weight: bold">
                      To Date
                    </th>
                    <th
                      nzWidth="15px"
                      style="text-align: center; font-weight: bold">
                      Journey To
                    </th>
                    <th
                      nzWidth="15px"
                      style="text-align: center; font-weight: bold">
                      Travel Mode
                    </th>
                    <th
                      nzWidth="15px"
                      style="text-align: center; font-weight: bold">
                      Class of Mode
                    </th>
  
                    <th
                      nzWidth="13px"
                      style="text-align: center; font-weight: bold">
                      Purpose of Journey
                    </th>
                    <th
                      nzWidth="7px"
                      style="text-align: center; font-weight: bold">
                      Hours of halt
                    </th>
                    <th
                      nzWidth="7px"
                      style="text-align: center; font-weight: bold">
                      Days of halt
                    </th>
                    <th
                      nzWidth="10px"
                      style="text-align: center; font-weight: bold">
                      Fair Paid(₹)
                    </th>
                    <th
                      nzWidth="15px"
                      style="text-align: center; font-weight: bold">
                      Distance of Road(Kms)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    style="white-space: pre-line"
                    *ngFor="let data of dataxfdstable.data; let i = index">
                    <td style="text-align: center">
                      <a
                        (click)="editdetailsndpurpose(data, i)"
                        nz-tooltip
                        nzTooltipTitle="Edit">
                        <span
                          nz-icon
                          nzType="edit"
                          nzTheme="twotone"
                          style="margin-top: 10px"></span>
                      </a>
                    </td>
                    <td style="text-align: center; white-space: pre-line">
                      {{
                      detailsandpurpose[i]["DEPARTURE_DATETIME"]
                      | date : "dd/MM/yyyy"
                      }}
                    </td>
                    <td style="text-align: center; white-space: pre-line">
                      {{ detailsandpurpose[i]["DEPARTURE_FROM"] }}
                    </td>
                    <td style="text-align: center; white-space: pre-line">
                      {{
                      detailsandpurpose[i]["ARRIVAL_DATETIME"]
                      | date : "dd/MM/yyyy"
                      }}
                    </td>
                    <td style="text-align: center; white-space: pre-line">
                      {{ detailsandpurpose[i]["ARRIVAL_TO"] }}
                    </td>
                    <td style="text-align: center; white-space: pre-line">
                      {{ detailsandpurpose[i]["TRAVEL_MODE_NAME"] }}
                    </td>
                    <td style="text-align: center; white-space: pre-line">
                      {{ detailsandpurpose[i]["TRAVEL_CLASS_NAME"] }}
                    </td>
  
                    <td style="text-align: center; white-space: pre-line">
                      {{ detailsandpurpose[i]["PURPOSE_OF_JOURNEY"] }}
                    </td>
                    <td style="text-align: center; white-space: pre-line">
                      {{ detailsandpurpose[i]["HOURS_OF_HALT"] }}
                    </td>
                    <td style="text-align: center; white-space: pre-line">
                      {{ detailsandpurpose[i]["DAYS_OF_HALT"] }}
                    </td>
                    <td style="text-align: center; white-space: pre-line">
                      {{ detailsandpurpose[i]["FARE_PAID"] }}
                    </td>
                    <td style="text-align: center; white-space: pre-line">
                      {{ detailsandpurpose[i]["DISTANCE_IN_KM_FOR_ROAD"] }}
                    </td>
                  </tr>
                </tbody>
              </nz-table>
            </div>
          </div> -->
      </div>
    </div>
  </div>
</div>
<div *ngIf="current == 1">
  <nz-spin [nzSpinning]="isSpinning">
    <nz-divider
      style="font-weight: bold; padding-top: 5px"
      nzText="Details and purpose of journey(s) performed :"
    ></nz-divider>
    <br />
    <div nz-row>
      <div nz-col nzSpan="24">
        <div nz-row>
          <div nz-col nzSpan="24" style="text-align: right">
            <button
              nz-button
              nzType="primary"
              (click)="detailsofjourney()"
              nzJustify="end"
            >
              <i nz-icon nzType="plus"></i>Add
            </button>
          </div>
        </div>
        <nz-table
          class="my-scroll no-wrap1"
          nzBordered
          #dataxfdstable
          nzShowSizeChanger
          [nzLoading]="loadingRecords"
          [nzShowPagination]="false"
          [nzSize]="'small'"
          [nzScroll]="{ x: '2400px', y: '420px' }"
          [nzData]="detailsandpurpose"
          [nzTotal]="totalRecords"
        >
          <thead>
            <tr>
              <th nzWidth="7px" style="text-align: center; font-weight: bold">
                Action
              </th>
              <th nzWidth="10px" style="text-align: center; font-weight: bold">
                From Date
              </th>
              <th nzWidth="15px" style="text-align: center; font-weight: bold">
                Journey From
              </th>
              <th nzWidth="10px" style="text-align: center; font-weight: bold">
                To Date
              </th>
              <th nzWidth="15px" style="text-align: center; font-weight: bold">
                Journey To
              </th>
              <th nzWidth="15px" style="text-align: center; font-weight: bold">
                Travel Mode
              </th>
              <th nzWidth="15px" style="text-align: center; font-weight: bold">
                Class of Mode
              </th>

              <th nzWidth="13px" style="text-align: center; font-weight: bold">
                Purpose of Journey
              </th>
              <th nzWidth="7px" style="text-align: center; font-weight: bold">
                Hours of halt
              </th>
              <th nzWidth="7px" style="text-align: center; font-weight: bold">
                Days of halt
              </th>
              <th nzWidth="10px" style="text-align: center; font-weight: bold">
                Fair Paid(₹)
              </th>
              <th nzWidth="15px" style="text-align: center; font-weight: bold">
                Distance of Road(Kms)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              style="white-space: pre-line"
              *ngFor="let data of dataxfdstable.data; let i = index"
            >
              <td style="text-align: center">
                <a
                  (click)="editdetailsndpurpose(data)"
                  nz-tooltip
                  nzTooltipTitle="Edit"
                >
                  <span
                    nz-icon
                    nzType="edit"
                    nzTheme="twotone"
                    style="margin-top: 10px"
                  ></span>
                </a>
              </td>
              <td style="text-align: center; white-space: pre-line">
                {{ data["DEPARTURE_DATETIME"] | date : "dd/MM/yyyy" }}
              </td>
              <td style="text-align: center; white-space: pre-line">
                {{ data["DEPARTURE_FROM"] }}
              </td>
              <td style="text-align: center; white-space: pre-line">
                {{ data["ARRIVAL_DATETIME"] | date : "dd/MM/yyyy" }}
              </td>
              <td style="text-align: center; white-space: pre-line">
                {{ data["ARRIVAL_TO"] }}
              </td>
              <td style="text-align: center; white-space: pre-line">
                {{ data["TRAVEL_MODE_NAME"] }}
              </td>
              <td style="text-align: center; white-space: pre-line">
                {{ data["TRAVEL_CLASS_NAME"] }}
              </td>

              <td style="text-align: center; white-space: pre-line">
                {{ data["PURPOSE_OF_JOURNEY"] }}
              </td>
              <td style="text-align: center; white-space: pre-line">
                {{ data["HOURS_OF_HALT"] }}
              </td>
              <td style="text-align: center; white-space: pre-line">
                {{ data["DAYS_OF_HALT"] }}
              </td>
              <td style="text-align: center; white-space: pre-line">
                {{ data["FARE_PAID"] }}
              </td>
              <td style="text-align: center; white-space: pre-line">
                {{ data["DISTANCE_IN_KM_FOR_ROAD"] }}
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </nz-spin>
</div>
<div *ngIf="current == 2">
  <nz-spin [nzSpinning]="isSpinning">
    <nz-divider
      style="font-weight: bold; padding-top: 10px"
      nzText="Mode of Journey"
    ></nz-divider>

    <div nz-row>
      <div nz-col nzSpan="24">
        <div style="text-align: center"><b>Travel Modes</b></div>
      </div>
    </div>

    <div class="my-box">
      <div nz-row>
        <div nz-col nzSpan="16">
          <div><b>Air :-</b></div>
        </div>
      </div>

      <div nz-row>
        <!-- <h4 *ngIf="data.TRAVEL_MODE_ID == 2"><b>Air</b></h4><br> -->
        <div nz-col nzSpan="16">
          <div>a) Exchange voucher arranged by office</div>
        </div>

        <div nz-col nzSpan="8">
          <nz-form-item>
            <!-- <nz-form-label nzRequired nzNoColon>Whether Emergency Treatment is Availed ?</nz-form-label> -->
            <nz-form-control>
              <nz-radio-group
                name="copyofcard"
                [(ngModel)]="data.IS_EXCHANGE_VOUCHER_ARRANGED_BY_OFFICE"
              >
                <label nz-radio nzValue="Y">Yes </label>
                <label nz-radio nzValue="N">No</label>
                <label nz-radio nzValue="NA">NA</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div nz-row>
        <div nz-col nzSpan="16">
          <div>b) Ticket / Exchange voucher arranged by</div>
        </div>
        <div nz-col nzSpan="8">
          <!-- <nz-form-item>
           
            <nz-form-control>
              <nz-radio-group
                name="copyosdffcard"
                [(ngModel)]="data.TICKET_EXCHANGE_VOUCHER_ARRANGED_BY"
              >
                <label nz-radio nzValue="Y">Yes </label>
                <label nz-radio nzValue="N">No</label>
                <label nz-radio nzValue="NA">NA</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item> -->
          <!-- (ngModelChange)="getclassmode($event)" -->
          <nz-form-item>
            <!-- <nz-form-label nzRequired nzNoColon>Ticket / Exchange voucher arranged by</nz-form-label> -->
            <nz-form-control
              nzHasFeedback
              nzErrorTip="Select voucher arranged by"
            >
              <nz-select
                nzAllowClear
                name="voucherarrangedby"
                [(ngModel)]="data.TICKET_EXCHANGE_VOUCHER_ARRANGED_BY"
                style="width: 100%"
                nzPlaceHolder="Select Voucher Arranged by"
              >
                <nz-option [nzValue]="'Self'" [nzLabel]="'Self'"> </nz-option>
                <nz-option [nzValue]="'DC (Admin)'" [nzLabel]="'DC (Admin)'">
                </nz-option>
                <nz-option
                  [nzValue]="'Ashoka Tours & Travels'"
                  [nzLabel]="'Ashoka Tours & Travels'"
                >
                </nz-option>
                <nz-option
                  [nzValue]="'Other (Office)'"
                  [nzLabel]="'Other (Office)'"
                >
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>

    <div class="my-box">
      <div nz-row>
        <div nz-col nzSpan="16">
          <div><b>Road :-</b></div>
        </div>
      </div>

      <div nz-row>
        <div nz-col nzSpan="8">
          <nz-form-item>
            <nz-form-label nzNoColon>Kilometer</nz-form-label>
            <nz-form-control nzErrorTip="Enter Kilometer">
              <nz-input-group style="width: 100%" nzAddOnAfter="kms">
                <input
                  nz-input
                  autocomplete="off"
                  [(ngModel)]="data.KILOMETER"
                  name="kilotmeterroad"
                  type="text"
                  (keypress)="omit($event)"
                  maxlength="8"
                  placeHolder="Kilometer"
                  required
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="8">
          <nz-form-item>
            <nz-form-label nzNoColon>Mileage by road</nz-form-label>
            <nz-form-control nzErrorTip="Enter Mileage by road">
              <nz-input-group style="width: 100%">
                <input
                  nz-input
                  autocomplete="off"
                  [(ngModel)]="data.MILAGE_BY_ROAD"
                  name="milagebyroad"
                  type="text"
                  (keypress)="omit($event)"
                  maxlength="8"
                  placeHolder="Mileage by road"
                  required
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div nz-row>
        <!-- <h4 *ngIf="data.TRAVEL_MODE_ID == 0"><b>Road :</b></h4> -->

        <div nz-row style="margin-top: 40px">
          <div nz-col nzSpan="22">
            <p style="font-weight: bold">
              Mode of conveyance used i.e. by Govt. transport/by taking a taxi,
              single seat in a bus or other public conveyance/by sharing with
              another Govt. servant in car belonging to him or to a third
              person, to be mentioned.
            </p>
          </div>
          <div
            style="text-align: right"
            nz-col
            nzXs="2"
            nzSm="2"
            nzMd="2"
            nzLg="2"
            nzXl="2"
          >
            <nz-switch
              [(ngModel)]="data.MODE_OF_CONVEYANCE_USED"
              name="fgfghfasdjgfg"
              nzCheckedChildren="Yes"
              nzJustify="end"
              nzUnCheckedChildren="No"
            >
            </nz-switch>
          </div>
        </div>
      </div>
    </div>

    <div class="my-box">
      <div nz-row>
        <div nz-col nzSpan="16">
          <div><b>Rail :-</b></div>
        </div>
      </div>

      <div nz-row>
        <div nz-col nzSpan="16">
          <div>a) Whether travelled by mail/Express/Ordinary train ?</div>
        </div>
        <div nz-col nzSpan="8">
          <nz-form-item>
            <nz-form-control>
              <nz-switch
                [(ngModel)]="data.WHETHER_TRAVELLED_BY_M_E_OT"
                (ngModelChange)="isdata($event)"
                name="aaaaaa"
                nzCheckedChildren="Yes"
                nzUnCheckedChildren="No"
              >
              </nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div nz-row>
        <div nz-col nzSpan="8" *ngIf="data.WHETHER_TRAVELLED_BY_M_E_OT == true">
          <nz-form-item>
            <nz-form-control nzErrorTip="Please Travel by ">
              <nz-input-group style="width: 100%">
                <input
                  nz-input
                  autocomplete="off"
                  [(ngModel)]="data.TRAVELLED_M_E_OT"
                  name="faidfdfgassfdfdarPaid"
                  type="text"
                  maxlength="512"
                  [pattern]="namepatt"
                  placeHolder="Enter Please Travel by"
                  required
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div nz-row>
        <div nz-col nzSpan="16">
          <div>b) Whether return ticket was available ?</div>
        </div>
        <div nz-col nzSpan="8">
          <nz-form-item>
            <nz-form-control>
              <nz-switch
                [(ngModel)]="data.WHETHER_RETURN_TICKET_WAS_AVAILABLE"
                name="fgfgfg"
                nzCheckedChildren="Yes"
                nzUnCheckedChildren="No"
              >
              </nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="16">
          <div>
            c) If available whether return ticket purchased ? if not, state
            reasons
          </div>
        </div>
        <div nz-col nzSpan="8">
          <nz-form-item>
            <nz-form-control>
              <nz-switch
                [(ngModel)]="data.IS_WHETHER_RETURN_TICKET_PURCHASED"
                (ngModelChange)="isdasadta($event)"
                name="fgfghfjgfg"
                nzCheckedChildren="Yes"
                nzUnCheckedChildren="No"
              >
              </nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div
        nz-col
        nzSpan="8"
        *ngIf="data.IS_WHETHER_RETURN_TICKET_PURCHASED == false"
      >
        <nz-form-item>
          <nz-form-control nzErrorTip="Enter return ticket purchased remark ">
            <nz-input-group style="width: 100%">
              <input
                nz-input
                autocomplete="off"
                [(ngModel)]="data.RETURN_TICKET_PURCHASED_REMARK"
                name="faidfdfgaSfsfgdhfssfdfdarPaid"
                type="text"
                maxlength="127"
                [pattern]="namepatt"
                placeHolder="Enter return ticket purchased remark"
                required
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <!-- <h4 *ngIf="data.TRAVEL_MODE_ID == 1"><b>Rail :</b></h4><br> -->

    <br />
    <div nz-row>
      <div nz-col nzSpan="16">
        <div><b>Date of absent from place halt on account of :-</b></div>
      </div>
      <div nz-col nzSpan="8">
        <nz-form-item>
          <nz-form-control nzErrorTip="Please Dates of absent from place">
            <nz-input-group style="width: 100%">
              <input
                nz-input
                autocomplete="off"
                [(ngModel)]="data.DATES_OF_ABSENT_FROM_PLACE"
                name="faidfdfgasdarPaid"
                type="text"
                maxlength="126"
                [pattern]="namepatt"
                placeHolder="Enter Dates of absent from place"
                required
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <br />
    <div nz-row>
      <div nz-col nzSpan="16">
        <div>i) R H</div>
      </div>
      <div nz-col nzSpan="8">
        <nz-form-item>
          <nz-form-control nzErrorTip="Enter RH">
            <input
              nz-input
              maxlength="126"
              autocomplete="off"
              [pattern]="namepatt"
              name="RH"
              type="text"
              [(ngModel)]="data.R_H"
              placeHolder="Enter The RH"
              required
            />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div nz-row>
      <div nz-col nzSpan="16">
        <div>ii) C L</div>
      </div>
      <div nz-col nzSpan="8">
        <nz-form-item>
          <nz-form-control nzErrorTip="Enter CL">
            <input
              nz-input
              maxlength="126"
              autocomplete="off"
              name="Desisdssddfgfsdgnation"
              type="text"
              [(ngModel)]="data.C_L"
              [pattern]="namepatt"
              placeHolder="Enter The CL"
              required
            />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div nz-row>
      <div nz-col nzSpan="16">
        <div>iii) Note being actually in camp on sunday and holidays.</div>
      </div>
      <div nz-col nzSpan="8">
        <nz-form-item>
          <nz-form-control nzErrorTip="Enter Designation">
            <nz-switch
              [(ngModel)]="data.NOT_BEING_ACTUALLY_IN_CAMP_ON_SUNDAY_HOLIDAY"
              name="fgfghfasdsdfsdgjgfg"
              nzCheckedChildren="Yes"
              nzUnCheckedChildren="No"
            >
            </nz-switch>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <br />

    <div nz-row style="margin-top: 10px">
      <div nz-col nzXs="22" nzSm="22" nzMd="22" nzLg="22" nzXl="22">
        <p style="font-weight: bold">
          Dates on which free board and/or lodging provided by the State or any
          organisation financed by State funds:
        </p>
      </div>
      <div
        style="text-align: right"
        nz-col
        nzXs="2"
        nzSm="2"
        nzMd="2"
        nzLg="2"
        nzXl="2"
      >
        <nz-switch
          [(ngModel)]="data.DATES_OF_FREE_BOARD_AND_LODGING"
          name="show_table"
          (ngModelChange)="freeBoardLodging($event)"
          nzCheckedChildren="Yes"
          nzJustify="end"
          nzUnCheckedChildren="No"
        >
        </nz-switch>
      </div>
    </div>

    <div nz-row *ngIf="data.DATES_OF_FREE_BOARD_AND_LODGING == true">
      <div nz-col nzSpan="16">
        <div>i) Board Only</div>
      </div>
      <div nz-col nzSpan="8">
        <nz-form-item>
          <nz-switch
            [(ngModel)]="data.BOARD"
            (ngModelChange)="datadessable($event)"
            name="emegtkkkrat"
            nzCheckedChildren="Yes"
            nzUnCheckedChildren="No"
          >
          </nz-switch>
        </nz-form-item>
      </div>
    </div>
    <div nz-row *ngIf="data.DATES_OF_FREE_BOARD_AND_LODGING == true">
      <div nz-col nzSpan="16">
        <div>ii) Lodging Only</div>
      </div>
      <div nz-col nzSpan="8">
        <nz-form-item>
          <nz-switch
            [(ngModel)]="data.LODGING"
            (ngModelChange)="datadessable1($event)"
            name="emegtsssrat"
            nzCheckedChildren="Yes"
            nzUnCheckedChildren="No"
          >
          </nz-switch>
        </nz-form-item>
      </div>
    </div>
    <div nz-row *ngIf="data.DATES_OF_FREE_BOARD_AND_LODGING == true">
      <div nz-col nzSpan="16">
        <div>iii) Boards and Lodging</div>
      </div>
      <div nz-col nzSpan="8">
        <nz-form-item>
          <nz-switch
            [(ngModel)]="data.BOARD_AND_LODGING"
            (ngModelChange)="datadessable2($event)"
            name="dsfsf"
            nzCheckedChildren="Yes"
            nzUnCheckedChildren="No"
          >
          </nz-switch>
        </nz-form-item>
      </div>
    </div>

    <br />
    <p style="font-weight: bold; padding-top: 20px">
      Particulars to be furnished along with hotel receipts etc. in case where
      higher rate of D.A. is claimed for stay in hotel other establishments
      providing board and/or lodging at scheduled tariffs :
    </p>

    <div nz-row>
      <div nz-col nzSpan="24" style="text-align: right">
        <button
          nz-button
          nzType="primary"
          (click)="particularofhotels()"
          nzJustify="end"
        >
          <i nz-icon nzType="plus"></i>Add
        </button>
      </div>
    </div>

    <div nz-row>
      <div nz-col nzSpan="24">
        <nz-table
          class="my-scroll no-wrap1"
          nzBordered
          #dataxfduuuustable
          nzShowSizeChanger
          [nzShowPagination]="false"
          [nzLoading]="loadingRecords"
          [nzSize]="'small'"
          [nzScroll]="{ x: '1500px', y: '420px' }"
          [nzData]="particularofhotelsdata"
          [nzTotal]="totalRecords"
          [(nzPageIndex)]="pageIndex"
          [(nzPageSize)]="pageSize"
        >
          <thead>
            <tr>
              <th nzWidth="5px" nzAlign="center">
                <span style="font-weight: bold">Action</span>
              </th>

              <th nzColumnKey nzWidth="12px" nzAlign="center">
                <span style="font-weight: bold">Name of Hotel</span>
              </th>

              <th nzColumnKey nzWidth="13px" nzAlign="center">
                <span style="font-weight: bold">Place</span>
              </th>

              <th nzColumnKey nzWidth="11px" nzAlign="center">
                <span style="font-weight: bold">Period From</span>
              </th>

              <th
                nzWidth="13px"
                style="text-align: center; font-weight: bold"
                nzColumnKey="{{ 'DEFAULT_RATE' }}"
              >
                Period To
              </th>

              <th
                nzWidth="13px"
                style="text-align: center; font-weight: bold"
                nzColumnKey="{{ 'PLACE_ORDER_ACCOMODATION_ADMISSIBLE_AMOUNT' }}"
              >
                Fair Paid By the Officer(₹)
              </th>

              <th
                nzWidth="13px"
                style="text-align: center; font-weight: bold"
                nzColumnKey="{{ 'DEFAULT_RATE' }}"
              >
                In Admissible Amount(₹)
              </th>

              <th
                nzWidth="8px"
                style="text-align: center; font-weight: bold"
                nzColumnKey="{{ 'DEFAULT_RATE' }}"
              >
                Admissible Amount(₹)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              style="white-space: pre-line"
              *ngFor="let data of dataxfduuuustable.data"
            >
              <td style="text-align: center">
                <a
                  (click)="editparticularsofhotels(data)"
                  nz-tooltip
                  nzTooltipTitle="Edit"
                >
                  <span
                    nz-icon
                    nzType="edit"
                    nzTheme="twotone"
                    style="margin-top: 10px"
                  ></span>
                </a>
              </td>

              <td style="text-align: center; white-space: pre-line">
                {{ data["NAME_OF_HOTEL"] }}
              </td>
              <td style="text-align: center; white-space: pre-line">
                {{ data["PLACE"] }}
              </td>
              <td style="text-align: center; white-space: pre-line">
                <!-- {{ data["PERIOD_FROM"] | date : "dd/MM/yyyy" }} -->
                {{ data["PERIOD_FROM"] | date : "dd.MM.yyyy" }}
                {{ data["PERIOD_FROM"] | date : "(HH:mm aa)" }}
              </td>
              <td style="text-align: center; white-space: pre-line">
                <!-- {{ data["PERIOD_TO"] | date : "dd/MM/yyyy" }} -->
                {{ data["PERIOD_TO"] | date : "dd.MM.yyyy" }}
                {{ data["PERIOD_TO"] | date : "(HH:mm aa)" }}
              </td>
              <td style="text-align: center; white-space: pre-line">
                {{ data["PLACE_ORDER_ACCOMODATION_ADMISSIBLE_AMOUNT"] }}
              </td>
              <td style="text-align: center; white-space: pre-line">
                {{ data["IN_ADMISIBLE_AMOUNT"] }}
              </td>
              <td style="text-align: center; white-space: pre-line">
                {{ data["TOTAL_AMOUNT_PAID"] }}
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>

    <div nz-row style="margin-top: 30px">
      <div nz-col nzSpan="16">
        <div><b>Whether Food Bill Is Claimed?</b></div>
      </div>
      <div nz-col nzSpan="8">
        <nz-form-item>
          <nz-form-control>
            <nz-switch
              [(ngModel)]="data.FOOD_BILL_CLAIM"
              (ngModelChange)="showfoodtable($event)"
              name="FoodBill"
              nzCheckedChildren="Yes"
              nzUnCheckedChildren="No"
            >
            </nz-switch>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div nz-row *ngIf="data.FOOD_BILL_CLAIM == true">
      <div nz-col nzSpan="24" style="text-align: right">
        <button nz-button nzType="primary" (click)="foodopen()" nzJustify="end">
          <i nz-icon nzType="plus"></i>Add
        </button>
      </div>
    </div>

    <div nz-row *ngIf="data.FOOD_BILL_CLAIM == true">
      <div nz-col nzSpan="24">
        <nz-table
          class="my-scroll no-wrap1"
          nzBordered
          #datafooddata
          nzShowSizeChanger
          [nzShowPagination]="false"
          [nzLoading]="loadingRecords"
          [nzSize]="'small'"
          [nzScroll]="{ x: '1400px', y: '420px' }"
          [nzData]="fooddata"
          [nzTotal]="totalRecords"
          [(nzPageIndex)]="pageIndex"
          [(nzPageSize)]="pageSize"
        >
          <thead>
            <tr>
              <th nzWidth="5px" nzAlign="center">
                <span style="font-weight: bold">Action</span>
              </th>

              <th nzColumnKey nzWidth="12px" nzAlign="center">
                <span style="font-weight: bold">Journey Start Date </span>
              </th>
              <th nzColumnKey nzWidth="12px" nzAlign="center">
                <span style="font-weight: bold">Journey End Date </span>
              </th>

              <th nzColumnKey nzWidth="13px" nzAlign="center">
                <span style="font-weight: bold">Start Time</span>
              </th>
              <th nzColumnKey nzWidth="13px" nzAlign="center">
                <span style="font-weight: bold">End Time</span>
              </th>
              <!-- <th nzColumnKey nzWidth="13px" nzAlign="center">
                <span style="font-weight: bold">End Time</span>
              </th> -->

              <th nzColumnKey nzWidth="11px" nzAlign="center">
                <span style="font-weight: bold">Claimed Amount(₹)</span>
              </th>
              <th nzColumnKey nzWidth="11px" nzAlign="center">
                <span style="font-weight: bold">Allowed Percentage(%)</span>
              </th>
              <th nzColumnKey nzWidth="11px" nzAlign="center">
                <span style="font-weight: bold">Admisible Amount (₹)</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              style="white-space: pre-line"
              *ngFor="let data of datafooddata.data"
            >
              <td style="text-align: center">
                <a (click)="editfood(data)" nz-tooltip nzTooltipTitle="Edit">
                  <span
                    nz-icon
                    nzType="edit"
                    nzTheme="twotone"
                    style="margin-top: 10px"
                  ></span>
                </a>
              </td>

              <td style="text-align: center; white-space: pre-line">
                {{ data["EXPENSE_DATE"] | date : "dd.MM.yyyy" }}
              </td>
              <td style="text-align: center; white-space: pre-line">
                <span *ngIf="data.IS_LONG_JOURNEY == false">{{
                  data["EXPENSE_DATE"] | date : "dd.MM.yyyy"
                }}</span>
                <span *ngIf="data.IS_LONG_JOURNEY == true">{{
                  data["JOURNEY_END_DATE"] | date : "dd.MM.yyyy"
                }}</span>
              </td>
              <td style="text-align: center; white-space: pre-line">
                <span *ngIf="data.START_TIME != null">{{
                  data["START_TIME"]
                }}</span>
                <span *ngIf="data.START_TIME == null">-</span>
              </td>
              <td style="text-align: center; white-space: pre-line">
                <!-- {{ data["END_TIME"] }} -->
                <span *ngIf="data.END_TIME != null">{{
                  data["END_TIME"]
                }}</span>
                <span *ngIf="data.END_TIME == null">-</span>
              </td>
              <td style="text-align: center; white-space: pre-line">
                {{ data["EXPENSE_AMOUNT"] }}
              </td>
              <td style="text-align: center; white-space: pre-line">
                {{ data["ALLOWED_PERCENTAGE"] }}
              </td>
              <td style="text-align: center; white-space: pre-line">
                {{ data["FOOD_ADMISIBLE_AMOUNT"] }}
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>

    <p style="font-weight: bold; padding-top: 20px">
      Particulars of journey(s) for which higher class of accommodation than the
      one to which the Government servant is entitled was used:
    </p>

    <div nz-row>
      <div nz-col nzSpan="24" style="text-align: right">
        <button
          nz-button
          nzType="primary"
          (click)="journeyofparticular()"
          nzJustify="end"
        >
          <i nz-icon nzType="plus"></i>Add
        </button>
      </div>
    </div>

    <div nz-row>
      <div nz-col nzSpan="24">
        <nz-table
          class="my-scroll no-wrap1"
          nzBordered
          #datatableconveyance
          nzShowSizeChanger
          [nzShowPagination]="false"
          [nzLoading]="loadingRecords"
          [nzSize]="'small'"
          [nzScroll]="{ x: '1500px', y: '420px' }"
          [nzData]="particularoftours"
          [nzTotal]="totalRecords"
          [(nzPageIndex)]="pageIndex"
          [(nzPageSize)]="pageSize"
        >
          <thead>
            <tr>
              <th nzWidth="15px" style="font-weight: bold; text-align: center">
                Action
              </th>
              <th nzWidth="30px" style="font-weight: bold; text-align: center">
                Date
              </th>
              <th nzWidth="65px" style="font-weight: bold; text-align: center">
                Mode Of Conveyance Used
              </th>
              <th nzWidth="35px" style="font-weight: bold; text-align: center">
                Period Form
              </th>
              <th nzWidth="35px" style="font-weight: bold; text-align: center">
                Period To
              </th>
              <th nzWidth="35px" style="font-weight: bold; text-align: center">
                Form Place
              </th>
              <th nzWidth="35px" style="font-weight: bold; text-align: center">
                To Place
              </th>
              <th nzWidth="55px" style="font-weight: bold; text-align: center">
                Class To Which Entitled
              </th>
              <th nzWidth="55px" style="font-weight: bold; text-align: center">
                Class By Which Traylled
              </th>
              <th nzWidth="50px" style="font-weight: bold; text-align: center">
                Admissible Amount(₹)
              </th>
              <th nzWidth="52px" style="font-weight: bold; text-align: center">
                Fair Paid By The Officer(₹)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              style="white-space: pre-line"
              *ngFor="let data of datatableconveyance.data"
            >
              <td style="text-align: center">
                <a
                  (click)="editjourneyofparticular(data)"
                  nz-tooltip
                  nzTooltipTitle="Edit"
                >
                  <span
                    nz-icon
                    nzType="edit"
                    nzTheme="twotone"
                    style="margin-top: 10px"
                  ></span>
                </a>
              </td>

              <td style="text-align: center; white-space: pre-line">
                {{ data["DATE"] | date : "dd/MM/yyyy" }}
              </td>
              <td style="text-align: center; white-space: pre-line">
                {{ data["TRAVEL_MODE_NAME"] }}
              </td>
              <td style="text-align: center; white-space: pre-line">
                <!-- {{ data["PERIOD_FROM"] | date : "dd/MM/yyyy" }} -->
                {{ data["PERIOD_FROM"] | date : "dd.MM.yyyy" }}
                {{ data["PERIOD_FROM"] | date : "(HH:mm aa)" }}
              </td>
              <td style="text-align: center; white-space: pre-line">
                <!-- {{ data["PERIOD_TO"] | date : "dd/MM/yyyy" }} -->
                {{ data["PERIOD_TO"] | date : "dd.MM.yyyy" }}
                {{ data["PERIOD_TO"] | date : "(HH:mm aa)" }}
              </td>
              <td style="text-align: center; white-space: pre-line">
                {{ data["PLACE_FROM"] }}
              </td>
              <td style="text-align: center; white-space: pre-line">
                {{ data["PLACE_TO"] }}
              </td>
              <td style="text-align: center; white-space: pre-line">
                {{ data["ENTITLED_CLASS_NAME"] }}
              </td>
              <td style="text-align: center; white-space: pre-line">
                {{ data["TRAVELLED_CLASS_NAME"] }}
              </td>
              <td style="text-align: center; white-space: pre-line">
                {{ data["FARE_OF_ENTITLED_CLASS"] }}
              </td>
              <td style="text-align: center; white-space: pre-line">
                {{ data["FARE_PAID_BY_OFFICER"] }}
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>

    <div nz-row style="margin-top: 40px">
      <div nz-col nzXs="16" nzSm="16" nzMd="16" nzLg="16" nzXl="16">
        <p style="font-weight: bold">
          If the Journey(s) by higher class accommodation has been performed
          with the approval competent authority.No. and date of sanction may be
          quoted.
        </p>
      </div>
      <div
        style="text-align: right"
        nz-col
        nzXs="8"
        nzSm="8"
        nzMd="8"
        nzLg="8"
        nzXl="8"
      >
        <nz-form-item>
          <nz-form-control nzErrorTip="Enter Higher class accommodation">
            <input
              nz-input
              maxlength="512"
              autocomplete="off"
              name="RomkaH"
              type="text"
              [(ngModel)]="data.HIGH_CLASS_ACCOMODATION_PERFORMED"
              placeHolder="Enter Higher class accommodation"
              required
            />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <nz-divider
      style="font-weight: bold; padding-top: 20px"
      nzText="Details Journey(s) performed by
         road  between places connceted by rail: "
    ></nz-divider>

    <div nz-row>
      <div nz-col nzSpan="24" style="text-align: right">
        <button
          nz-button
          nzType="primary"
          (click)="journeyofparticularcity()"
          nzJustify="end"
        >
          <i nz-icon nzType="plus"></i>Add
        </button>
      </div>
    </div>
    <div nz-row>
      <div nz-col nzSpan="24">
        <nz-table
          class="my-scroll no-wrap1"
          nzBordered
          #datatablefare
          nzShowSizeChanger
          [nzShowPagination]="false"
          [nzLoading]="loadingRecords"
          [nzSize]="'small'"
          [nzScroll]="{ x: '1000px', y: '420px' }"
          [nzData]="particularofcity"
          [nzTotal]="totalRecords"
          [(nzPageIndex)]="pageIndex"
          [(nzPageSize)]="pageSize"
        >
          <thead>
            <tr>
              <th nzWidth="13px" style="font-weight: bold; text-align: center">
                Action
              </th>
              <th nzWidth="30px" style="font-weight: bold; text-align: center">
                Date
              </th>
              <th nzWidth="35px" style="font-weight: bold; text-align: center">
                Fair Paid(₹)
              </th>
              <th nzWidth="45px" style="font-weight: bold; text-align: center">
                Fair Paid By The Officer(₹)
              </th>
              <th nzWidth="35px" style="font-weight: bold; text-align: center">
                From Place Name
              </th>
              <th nzWidth="35px" style="font-weight: bold; text-align: center">
                To Place Name
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              style="white-space: pre-line"
              *ngFor="let data of datatablefare.data"
            >
              <td style="text-align: center">
                <a
                  (click)="editdrawerClosejourneyofparticularcity(data)"
                  nz-tooltip
                  nzTooltipTitle="Edit"
                >
                  <span
                    nz-icon
                    nzType="edit"
                    nzTheme="twotone"
                    style="margin-top: 10px"
                  ></span>
                </a>
              </td>

              <td style="text-align: center; white-space: pre-line">
                <!-- {{ data["DATE"] | date : "dd/MM/yyyy" }} -->
                {{ data["DATE"] | date : "dd.MM.yyyy" }}
                {{ data["DATE"] | date : "(HH:mm aa)" }}
              </td>
              <td style="text-align: center; white-space: pre-line">
                {{ data["FARE_PAID"] }}
              </td>
              <td style="text-align: center; white-space: pre-line">
                {{ data["FARE_PAID_BY_OFFICER"] }}
              </td>
              <td style="text-align: center; white-space: pre-line">
                {{ data["FROM_PLACE"] }}
              </td>
              <td style="text-align: center; white-space: pre-line">
                {{ data["TO_PLACE"] }}
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>

    <br />

    <div class="my-box">
      <div nz-row>
        <!-- //////////////// -->
        <div nz-col nzSpan="6">
          <nz-form-item>
            <nz-form-label nzNoColon> T.A. advance , if any</nz-form-label>
            <nz-form-control>
              <nz-switch
                [(ngModel)]="data.IS_ADVANCE_TAKEN"
                name="AdvanceTaken"
                nzCheckedChildren="Yes"
                nzUnCheckedChildren="No"
                (ngModelChange)="isAdvanceTaken($event)"
              >
              </nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="6" *ngIf="data.IS_ADVANCE_TAKEN == true">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon
              >T. A. Advance Amount</nz-form-label
            >
            <nz-form-control
              nzHasFeedback
              nzErrorTip="Please Enter T. A. Advance Amount"
            >
              <nz-input-group nzAddOnBefore="₹">
                <input
                  mode
                  of
                  conveyanc
                  nz-input
                  name="AdvanceAmount"
                  maxlength="8"
                  type="text"
                  (keypress)="omit($event)"
                  [(ngModel)]="data.AMOUNT_OF_T_A"
                  placeHolder="Enter T. A. Advance Amount"
                  required
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="6" *ngIf="data.IS_ADVANCE_TAKEN == true">
          <nz-form-item>
            <nz-form-label nzNoColon>Voucher No.</nz-form-label>
            <nz-form-control
              nzHasFeedback
              nzErrorTip="Please Enter Voucher No."
            >
              <input
                nz-input
                name="VoucherNo"
                maxlength="64"
                type="text"
                [(ngModel)]="data.ADVANCE_TAKEN_VOUCHER_NO"
                placeHolder="Enter Voucher No."
              />
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="6" *ngIf="data.IS_ADVANCE_TAKEN == true">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon
              >Date Of Advance Taken</nz-form-label
            >
            <nz-form-control nzErrorTip="Please Select Date Of Advance Taken">
              <nz-date-picker
                style="width: 100%"
                nzFormat="dd/MM/yyyy"
                name="dateOfAdvance"
                [(ngModel)]="data.ADVANCE_TAKEN_DATE"
                required
              ></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
        <!-- /////////// -->
        <!-- <div nz-col nzSpan="16">
        <div>Amount of T.A. advance , if any, drawn</div>
      </div>

      <div nz-col nzSpan="8">
        <nz-form-item>
          <nz-form-control nzErrorTip="Enter Correct Basic Pay">
            <nz-input-group style="width: 100%" nzAddOnBefore="₹">
              <input
                nz-input
                maxlength="8"
                autocomplete="off"
                (keypress)="omit($event)"
                [(ngModel)]="data.AMOUNT_OF_T_A"
                name="amountofta"
                type="text"
                placeHolder="Enter Employee Basic Pay"
                required
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div> -->
      </div>
    </div>
  </nz-spin>
</div>

<div *ngIf="current == 3">
  <div>
    <form nz-form #docMasterPage="ngForm">
      <nz-spin [nzSpinning]="isSpinning">
        <div nz-row>
          <div nz-col nzSpan="12">
            <nz-form-item>
              <nz-form-label nzNoColon>S.R.61</nz-form-label>

              <nz-form-control nzHasFeedback nzErrorTip="PDF File is required">
                <button
                  nz-button
                  style="height: 28px; padding: 0px 7px; font-size: 12px"
                  (click)="SR61.click()"
                >
                  Select File
                  <input
                    #SR61
                    name="S_R_61"
                    [(ngModel)]="data.S_R_61"
                    type="file"
                    (change)="onFileSelectedSR61($event)"
                    style="display: none"
                    required
                  />
                </button>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="6">
            <div
              *ngIf="data.S_R_61 != null"
              style="overflow: hidden; padding-left: 11px; font-size: 13px"
            >
              {{ data.S_R_61 }}
            </div>

            <div *ngIf="data.S_R_61 != null">
              <button
                nz-button
                style="height: 28px; padding: 0px 7px; font-size: 12px"
                (click)="SR61ViewAssumptionPDF(data.S_R_61)"
              >
                Open S.R.61 PDF File
              </button>
            </div>
            <!-- <div
            *ngIf="
              SR61FileURL == null && data.S_R_61 != ''
            "
          >
            <img
              style="width: 100%; height: 100%; margin-top: 10px"
              src="{{ imgurl }}uploads/S_R_61/{{
                data.S_R_61
              }}"
            />
          </div> -->
          </div>
          <div nz-col nzSpan="6">
            <div
              *ngIf="data.ASSUMPTION_CHARGE_STATUS != null"
              style="margin-top: 22px"
            >
              <b
                *ngIf="data.ASSUMPTION_CHARGE_STATUS == 'A'"
                style="color: green; font-weight: 1000"
                >Verified</b
              >
              <b
                *ngIf="data.ASSUMPTION_CHARGE_STATUS == 'R'"
                style="color: red; font-weight: 1000"
                >Rejected</b
              >
            </div>
          </div>
        </div>

        <div nz-row>
          <div nz-col nzSpan="12">
            <nz-form-item>
              <nz-form-label nzNoColon>Bank Mandate Form</nz-form-label>

              <nz-form-control nzHasFeedback nzErrorTip="PDF File is required">
                <button
                  nz-button
                  style="height: 28px; padding: 0px 7px; font-size: 12px"
                  (click)="mandateForm.click()"
                >
                  Select File
                  <input
                    #mandateForm
                    name="mandateForm"
                    [(ngModel)]="data.BANK_MANDATE_FORM"
                    type="file"
                    (change)="onFileSelectedBankMandateForm($event)"
                    style="display: none"
                    required
                  />
                </button>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="6">
            <div
              *ngIf="data.BANK_MANDATE_FORM != null"
              style="overflow: hidden; padding-left: 11px; font-size: 13px"
            >
              {{ data.BANK_MANDATE_FORM }}
            </div>

            <div *ngIf="data.BANK_MANDATE_FORM != null">
              <button
                nz-button
                style="height: 28px; padding: 0px 7px; font-size: 12px"
                (click)="bankViewMandateFormPDF(data.BANK_MANDATE_FORM)"
              >
                Open Bank Mandate Form PDF File
              </button>
            </div>

            <!-- <div
            *ngIf="bankMandateFormFileURL == null && data.BANK_MANDATE_FORM != ''"
          >
            <img
              style="width: 100%; height: 100%; margin-top: 10px"
              src="{{ imgurl }}uploads/mandateForm/{{
                data.BANK_MANDATE_FORM
              }}"
            />
          </div> -->
          </div>
          <div nz-col nzSpan="6">
            <div
              style="margin-top: 22px"
              *ngIf="data.MANDATE_FORM_STATUS != null"
            >
              <b
                *ngIf="data.MANDATE_FORM_STATUS == 'A'"
                style="color: green; font-weight: 1000"
                >Verified</b
              >
              <b
                *ngIf="data.MANDATE_FORM_STATUS == 'R'"
                style="color: red; font-weight: 1000"
                >Rejected</b
              >
            </div>
          </div>
        </div>

        <div nz-row>
          <div nz-col nzSpan="12">
            <nz-form-item>
              <nz-form-label nzNoColon>Self Declaration Form</nz-form-label>
              <nz-form-control nzHasFeedback nzErrorTip="PDF File is required">
                <button
                  nz-button
                  style="height: 28px; padding: 0px 7px; font-size: 12px"
                  (click)="SelfDeclaration.click()"
                >
                  Select File
                  <input
                    #SelfDeclaration
                    name="SelfDeclaration"
                    [(ngModel)]="data.TOUR_SELF_DECLARATION"
                    type="file"
                    (change)="onFileSelectedSelfDeclaration($event)"
                    style="display: none"
                    required
                  />
                </button>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="6">
            <div
              *ngIf="data.TOUR_SELF_DECLARATION != null"
              style="overflow: hidden; padding-left: 11px; font-size: 13px"
            >
              {{ data.TOUR_SELF_DECLARATION }}
            </div>

            <div *ngIf="data.TOUR_SELF_DECLARATION != null">
              <button
                nz-button
                style="height: 28px; padding: 0px 7px; font-size: 12px"
                (click)="viewSelfDeclarationFormPDF(data.TOUR_SELF_DECLARATION)"
              >
                Open Self Declaration Form PDF File
              </button>
            </div>

            <!-- <div
            *ngIf="selfDeclarationFormFileURL == null && data.TOUR_SELF_DECLARATION != ''"
          >
            <img
              style="width: 100%; height: 100%; margin-top: 10px"
              src="{{ imgurl }}uploads/mandateForm/{{
                data.TOUR_SELF_DECLARATION
              }}"
            />
          </div> -->
          </div>
          <div nz-col nzSpan="6">
            <div
              style="margin-top: 22px"
              *ngIf="data.MANDATE_FORM_STATUS != null"
            >
              <b
                *ngIf="data.MANDATE_FORM_STATUS == 'A'"
                style="color: green; font-weight: 1000"
                >Verified</b
              >
              <b
                *ngIf="data.MANDATE_FORM_STATUS == 'R'"
                style="color: red; font-weight: 1000"
                >Rejected</b
              >
            </div>
          </div>
        </div>
      </nz-spin>
    </form>
  </div>
</div>

<div *ngIf="current == 4">
  <nz-spin [nzSpinning]="isSpinning">
    <form nz-form #websitebannerPage="ngForm">
      <div nz-row>
        <div nz-col nzSpan="20">
          <div>
            <b>1)</b> Whether original bills submitted in proper format (Dully
            filled GAR14A-Part-A and signed certificate)
          </div>
        </div>
        <div nz-col nzSpan="4">
          <nz-form-item>
            <nz-form-control>
              <nz-switch
                [(ngModel)]="data3.ORIGINAL_BILL_SUBMITTED_IN_PROPER_FORMAT"
                name="originalbill"
                nzCheckedChildren="Yes"
                nzUnCheckedChildren="No"
              >
              </nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="20">
          <div>
            <b>2)</b> Whether bills have been submitted in proper time if
            (advance is taken then bills should be submitted in 15 days after
            submission of journey and bills should be submitted in 60 days after
            completion of journey after in advance is not taken)
          </div>
        </div>
        <div nz-col nzSpan="4">
          <nz-form-item>
            <nz-form-control>
              <nz-switch
                [(ngModel)]="data3.BILL_SUBMITTED_IN_PROPER_TIME"
                name="submitbill"
                nzCheckedChildren="Yes"
                nzUnCheckedChildren="No"
              >
              </nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="18">
          <div>
            <b>3)</b> In case of delayed submission of bill, whether condition
            of delay submitted
          </div>
        </div>
        <div nz-col nzSpan="6">
          <!-- <nz-form-item>
              <nz-form-control>
                <nz-switch
                  [(ngModel)]="data3.DELAYED_SUBMISSION_BILL"
                  name="delaybll"
                  nzCheckedChildren="Yes"
                  nzUnCheckedChildren="No">
                </nz-switch>
              </nz-form-control>
            </nz-form-item> -->

          <nz-form-item>
            <nz-form-control>
              <nz-radio-group
                [(ngModel)]="data3.DELAYED_SUBMISSION_BILL"
                name="delaybll"
              >
                <label nz-radio nzValue="Y">Yes </label>
                <label nz-radio nzValue="N">No</label>
                <label nz-radio nzValue="NA">NA</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="20">
          <div><b>4)</b> Whether T.A. advance taken</div>
        </div>
        <div nz-col nzSpan="4">
          <nz-form-item>
            <nz-form-control>
              <nz-switch
                [(ngModel)]="data3.T_A_ADVANCE_TAKEN"
                name="advanisisi"
                nzCheckedChildren="Yes"
                nzUnCheckedChildren="No"
              >
              </nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="20">
          <div><b>5)</b> Whether S.R.61 (original) submitted</div>
        </div>
        <div nz-col nzSpan="4">
          <nz-form-item>
            <nz-form-control>
              <nz-switch
                [(ngModel)]="data3.S_R_61_SUBMITTED"
                name="adfdvanisisi"
                nzCheckedChildren="Yes"
                nzUnCheckedChildren="No"
              >
              </nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="18">
          <div>
            <b>6)</b> In case of election duty, whether election order duly
            enclosed
          </div>
        </div>
        <div nz-col nzSpan="6">
          <!-- <nz-form-item>
              <nz-form-control>
                <nz-switch
                  [(ngModel)]="data3.ELECTION_DUTY"
                  name="electiodyty"
                  nzCheckedChildren="Yes"
                  nzUnCheckedChildren="No">
                </nz-switch>
              </nz-form-control>
            </nz-form-item> -->

          <nz-form-item>
            <nz-form-control>
              <nz-radio-group
                [(ngModel)]="data3.ELECTION_DUTY"
                name="electiodyty"
              >
                <label nz-radio nzValue="Y">Yes </label>
                <label nz-radio nzValue="N">No</label>
                <label nz-radio nzValue="NA">NA</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="18">
          <div>
            <b>7)</b> Whether Air/Train tickets booked through official Travel
            Desk(DCIT(Admin))
          </div>
        </div>
        <div nz-col nzSpan="6">
          <!-- <nz-form-item>
              <nz-form-control>
                <nz-switch
                  [(ngModel)]="data3.TICKET_BOOKED_THROUGH_TRAVEL_DESK"
                  name="electiodyty"
                  nzCheckedChildren="Yes"
                  nzUnCheckedChildren="No">
                </nz-switch>
              </nz-form-control>
            </nz-form-item> -->

          <nz-form-item>
            <nz-form-control>
              <nz-radio-group
                [(ngModel)]="data3.TICKET_BOOKED_THROUGH_TRAVEL_DESK"
                name="electiodyty"
              >
                <label nz-radio nzValue="Y">Yes </label>
                <label nz-radio nzValue="N">No</label>
                <label nz-radio nzValue="NA">NA</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="20">
          <div><b>8)</b> Whether air or train tickets submitted</div>
        </div>
        <div nz-col nzSpan="4">
          <nz-form-item>
            <nz-form-control>
              <nz-switch
                [(ngModel)]="data3.TICKET_SUBMITTED"
                name="ticketsubmiteed"
                nzCheckedChildren="Yes"
                nzUnCheckedChildren="No"
              >
              </nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="20">
          <div>
            <b>9)</b> In case of Air journey, wheather original boarding pass/
            self-declaration in case of non submission of boarding pass duly
            submitted
          </div>
        </div>
        <div nz-col nzSpan="4">
          <nz-form-item>
            <nz-form-control>
              <nz-switch
                [(ngModel)]="data3.ORIGINAL_BOARDING_PASS_SUBMITTED"
                name="ticketujsusdfbmiteed"
                nzCheckedChildren="Yes"
                nzUnCheckedChildren="No"
              >
              </nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="20">
          <div>
            <b>10)</b> In case of Air journey, wheather tickets booked through
            official travel agents (IRCTC, Ashoka Tour & travels, balmer &
            lawrie)
          </div>
        </div>
        <div nz-col nzSpan="4">
          <nz-form-item>
            <nz-form-control>
              <nz-switch
                [(ngModel)]="data3.TICKET_BOOKED_THROUGH_TRAVEL_AGENT"
                name="ticketujsusdfbmiteed"
                nzCheckedChildren="Yes"
                nzUnCheckedChildren="No"
              >
              </nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="20">
          <div><b>11)</b> Whether food expenses claimed</div>
        </div>
        <div nz-col nzSpan="4">
          <nz-form-item>
            <nz-form-control>
              <nz-switch
                [(ngModel)]="data3.FOOD_EXPENSES_CLAIMED"
                name="ticketujsusdfsdfbmiteed"
                nzCheckedChildren="Yes"
                nzUnCheckedChildren="No"
              >
              </nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="20">
          <div>
            <b>12)</b> In case of accommodation claimed, original Hotel/Guest
            House bills submitted
          </div>
        </div>
        <div nz-col nzSpan="4">
          <nz-form-item>
            <nz-form-control>
              <nz-switch
                [(ngModel)]="data3.ACCOMODATION_BILL_CLAIMED_SUBMITTED"
                name="ticketukkjsusdfsdfbmiteed"
                nzCheckedChildren="Yes"
                nzUnCheckedChildren="No"
              >
              </nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="20">
          <div>
            <b>13)</b> In case of taxi, whether travelling voucher or
            self-declaration submitted
          </div>
        </div>
        <div nz-col nzSpan="4">
          <nz-form-item>
            <nz-form-control>
              <nz-switch
                [(ngModel)]="data3.TRAVELLING_VOUCHER_SUBMITTED"
                name="ticketukkjsussdfsdfbmiteed"
                nzCheckedChildren="Yes"
                nzUnCheckedChildren="No"
              >
              </nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="20">
          <div>
            <b>14)</b> Whether Bank Mandate from/cancelled cheque submitted
          </div>
        </div>
        <div nz-col nzSpan="4">
          <nz-form-item>
            <nz-form-control>
              <nz-switch
                [(ngModel)]="data3.BANK_MANDATE_FORM_SUBMITTED"
                name="ticketukkghjsussdfsdfbmiteed"
                nzCheckedChildren="Yes"
                nzUnCheckedChildren="No"
              >
              </nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </form>
  </nz-spin>
</div>
<div class="footer">
  <button
    [nzLoading]="isSpinning"
    nz-button
    nzType="default"
    (click)="pre()"
    *ngIf="current > 0"
    style="margin-right: 8px"
  >
    <span>Previous</span>
  </button>
  <span *ngIf="current == 4">
    <button
      [nzLoading]="isSpinning"
      nzType="primary"
      class="ant-btn ant-btn-primary"
      nz-button
      (click)="saveCheckListClose(false)"
    >
      <span>Send To Verification & close </span>
    </button>
  </span>

  <span>
    <button
      [nzLoading]="isSpinning"
      *ngIf="current == 0"
      nzType="primary"
      class="ant-btn ant-btn-primary"
      nz-button
      (click)="empSave(false)"
    >
      <span>Save Applicant Info. & Next </span>
    </button>
  </span>

  <span>
    <button
      [nzLoading]="isSpinning"
      (click)="Secondstep(false)"
      *ngIf="current == 1"
      nzType="primary"
      class="ant-btn ant-btn-primary"
      nz-button
    >
      <span>Save & Next</span>
    </button>
  </span>

  <span>
    <button
      [nzLoading]="isSpinning"
      (click)="AlldataSave(false)"
      *ngIf="current == 2"
      nzType="primary"
      class="ant-btn ant-btn-primary"
      nz-button
    >
      <span>Save & Next </span>
    </button>
  </span>

  <span>
    <button
      [nzLoading]="isSpinning"
      *ngIf="current == 3"
      nzType="primary"
      class="ant-btn ant-btn-primary"
      nz-button
      (click)="docSave(false)"
    >
      <span>Submit Document & Next</span>
    </button>
  </span>

  <span>
    <button
      [nzLoading]="isSpinning"
      *ngIf="current == 4"
      nzType="primary"
      class="ant-btn ant-btn-primary"
      nz-button
      (click)="saveCheckList(false)"
    >
      <span>Submit & Close</span>
    </button>
  </span>
  <button
    type="button"
    nz-button
    (click)="close()"
    class="ant-btn"
    style="margin-right: 8px"
  >
    <span>Cancel</span>
  </button>
</div>
<div class="header">
  <button
    type="button"
    nz-button
    (click)="close()"
    class="ant-btn"
    style="margin-right: 8px"
  >
    <i nz-icon nzType="close" nzTheme="outline"></i>
  </button>
</div>
<!-- 
  <div nz-row>
    <nz-drawer
      [nzBodyStyle]="{
        height: 'calc(100% - 55px)',
        overflow: 'auto',
        'padding-bottom': '53px'
      }"
      [nzClosable]="false"
      [nzMask]="true"
      [nzMaskClosable]="false"
      [nzWidth]="550"
      [nzVisible]="drawerVisibledetailsofjourney"
      [nzTitle]="drawerTitledetailsofjourney"
      (nzOnClose)="drawerClosedetailsofjourney()"
      (nzKeyboard)="(true)">
      <ng-container>
        <app-detailsandpurposetour
          [drawerClose]="closeCallbackdetailsofjourney"
          [drawerVisible]="drawerVisibledetailsofjourney"
          [data]="drawerDatadetailsofjourney"></app-detailsandpurposetour>
      </ng-container>
    </nz-drawer>
  </div> -->

<div nz-row>
  <nz-drawer
    [nzBodyStyle]="{
      height: 'calc(100% - 55px)',
      overflow: 'auto',
      'padding-bottom': '53px'
    }"
    [nzClosable]="false"
    [nzMask]="true"
    [nzMaskClosable]="false"
    [nzWidth]="550"
    [nzVisible]="drawerVisibleparticularofhotels"
    [nzTitle]="drawerTitleparticularofhotels"
    (nzOnClose)="drawerCloseparticularofhotels()"
    (nzKeyboard)="(true)"
  >
    <ng-container>
      <app-tourparticularhotels
        [empID]="empID"
        [tourID]="tourID"
        [drawerClose]="closeCallbackparticularofhotels"
        [drawerVisible]="drawerVisibleparticularofhotels"
        [data]="drawerDataparticularofhotels"
      ></app-tourparticularhotels>
    </ng-container>
  </nz-drawer>
</div>

<div nz-row>
  <nz-drawer
    [nzBodyStyle]="{
      height: 'calc(100% - 55px)',
      overflow: 'auto',
      'padding-bottom': '53px'
    }"
    [nzClosable]="false"
    [nzMask]="true"
    [nzMaskClosable]="false"
    [nzWidth]="550"
    [nzVisible]="drawerVisiblejourneyparticulartours"
    [nzTitle]="drawerTitlejourneyparticulartours"
    (nzOnClose)="drawerClosejourneyparticulartours()"
    (nzKeyboard)="(true)"
  >
    <ng-container>
      <app-journeyparticulartours
        [empID]="empID"
        [tourID]="tourID"
        [drawerClose]="closeCallbackjourneyparticulartours"
        [drawerVisible]="drawerVisiblejourneyparticulartours"
        [data]="drawerDatajourneyparticulartours"
      ></app-journeyparticulartours>
    </ng-container>
  </nz-drawer>
</div>

<div nz-row>
  <nz-drawer
    [nzBodyStyle]="{
      height: 'calc(100% - 55px)',
      overflow: 'auto',
      'padding-bottom': '53px'
    }"
    [nzClosable]="false"
    [nzMask]="true"
    [nzMaskClosable]="false"
    [nzWidth]="550"
    [nzVisible]="drawerVisiblejourneycity"
    [nzTitle]="drawerTitlejourneycity"
    (nzOnClose)="drawerClosejourneyofparticularcity()"
    (nzKeyboard)="(true)"
  >
    <ng-container>
      <app-citytour
        [empID]="empID"
        [tourID]="tourID"
        [drawerClose]="closeCallbackcityback"
        [drawerVisible]="drawerVisiblejourneycity"
        [data]="drawerDatajourneycity"
      ></app-citytour>
    </ng-container>
  </nz-drawer>
</div>

<nz-drawer
  [nzBodyStyle]="{
    height: 'calc(100% - 55px)',
    overflow: 'auto',
    'padding-bottom': '53px'
  }"
  [nzClosable]="false"
  [nzMask]="true"
  [nzMaskClosable]="false"
  [nzWidth]="700"
  [nzVisible]="empDrawerVisible"
  [nzTitle]="drawerTitle"
  (nzOnClose)="empDrawerClose()"
  (nzKeyboard)="(true)"
>
  <app-addclaimemployee
    [drawerClose]="closeCallback1"
    [data]="drawerData"
  ></app-addclaimemployee>
</nz-drawer>

<div nz-row>
  <nz-drawer
    [nzBodyStyle]="{
      height: 'calc(100% - 55px)',
      overflow: 'auto',
      'padding-bottom': '53px'
    }"
    [nzClosable]="false"
    [nzMask]="true"
    [nzMaskClosable]="false"
    [nzWidth]="650"
    [nzVisible]="drawerVisibledetailsofjourney"
    [nzTitle]="drawerTitledetailsofjourney"
    (nzOnClose)="drawerClosedetailsofjourney()"
    (nzKeyboard)="(true)"
  >
    <ng-container>
      <app-detailsandpurposetour
        [empID]="empID"
        [tourID]="tourID"
        [drawerClose]="closeCallbackdetailsofjourney"
        [drawerVisible]="drawerVisibledetailsofjourney"
        [data]="drawerDatadetailsofjourney"
      ></app-detailsandpurposetour>
    </ng-container>
  </nz-drawer>
</div>

<div nz-row>
  <nz-drawer
    [nzBodyStyle]="{
      height: 'calc(100% - 55px)',
      overflow: 'auto',
      'padding-bottom': '53px'
    }"
    [nzClosable]="false"
    [nzMask]="true"
    [nzMaskClosable]="false"
    [nzWidth]="550"
    [nzVisible]="drawerVisiblefood"
    [nzTitle]="drawerTitlefood"
    (nzOnClose)="drawerClosefood()"
    (nzKeyboard)="(true)"
  >
    <ng-container>
      <app-tourfoodbilladd
        [tourID]="tourID"
        [drawerClose]="closeCallbackfood"
        [drawerVisible]="drawerVisiblefood"
        [show]="show"
        [data]="drawerDatafood"
      ></app-tourfoodbilladd>
    </ng-container>
  </nz-drawer>
</div>
