import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { EmployeeMaster } from '../Models/Employee';
import { ApiService } from '../Service/api.service';

@Component({
  selector: 'app-employeeupdate',
  templateUrl: './employeeupdate.component.html',
  styleUrls: ['./employeeupdate.component.css'],
})
export class EmployeeupdateComponent implements OnInit {
  @Input() data: EmployeeMaster;
  @Input()
  drawerVisible: boolean = false;
  @Input() drawerClose: Function;
  isSpinning = false;
  isOk = true;
  namepatt = /[a-zA-Z][a-zA-Z ]+/;
  passwordVisible = false;
  emailpattern =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  constructor(
    private router: Router,
    private api: ApiService,
    private message: NzNotificationService,
    private cookie: CookieService
  ) {}

  ngOnInit(): void {}
  close(websitebannerPage: NgForm) {
    this.drawerClose();
    this.resetDrawer(websitebannerPage);
    websitebannerPage.form.reset();
  }
  resetDrawer(websitebannerPage: NgForm) {
    this.data = new EmployeeMaster();
    // this.data.PARENT_GROUP_ID=this.parentgroup;
    websitebannerPage.form.markAsPristine();
    websitebannerPage.form.markAsUntouched();
  }
  omit(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  save(addNew: boolean, websitebannerPage: NgForm): void {
    this.isSpinning = false;
    this.isOk = true;

    if (this.isOk) {
      this.isSpinning = true;
      {
        if (this.data.ID) {
          this.api.updateEmployeeMaster(this.data).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information Changed Successfully...', '');
              if (!addNew) this.drawerClose();
              this.isSpinning = false;
              // this.search()
            } else {
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
  }

  mobilepattern = /^[6-9]\d{9}$/;
  save1(addNew: boolean, websitebannerPage: NgForm): void {
    this.isSpinning = false;
    this.isOk = true;
    console.log(websitebannerPage);
    if (
      this.data.NAME.trim() == '' &&
      this.data.EMPLOYEE_CODE == undefined &&
      this.data.GRADE_PAY == undefined &&
      // this.data.OFFICE_NAME.trim() == '' &&
      this.data.DESIGNATION.trim() == '' &&
      this.data.LOCATION.trim() == '' &&
      this.data.DDO_OF_THE_OFFICIAL.trim() == '' &&
      this.data.MOBILE_NO == undefined &&
      // this.data.EMAIL_ID == undefined &&
      this.data.PASSWORD == undefined
      // && this.data.ADDRESS == undefined
    ) {
      this.isOk = false;
      this.message.error('Please Fill All The Required Fields ', '');
    } else if (this.data.NAME == null || this.data.NAME.trim() == '') {
      this.isOk = false;
      this.message.error(' Please Enter Employee Name.', '');
    } else if (this.data.MOBILE_NO == undefined || this.data.MOBILE_NO <= 0) {
      this.isOk = false;
      this.message.error(' Please Enter Mobile Number', '');
    } else if (!this.mobilepattern.test(this.data.MOBILE_NO.toString())) {
      this.isOk = false;
      this.message.error('Please Enter Mobile No.', '');
      // } else if (this.data.EMAIL_ID == null || this.data.EMAIL_ID.trim() == '') {
      //   this.isOk = false;
      //   this.message.error(' Please Enter Email ID', '');
      // } else if (!this.emailpattern.test(this.data.EMAIL_ID)) {
      //   this.isOk = false;
      //   this.message.error('Please Enter Email id', '');
    } else if (
      this.data.PASSWORD == null ||
      this.data.PASSWORD == undefined ||
      this.data.PASSWORD.trim() == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Password', '');
    } else if (
      (this.data.PASSWORD != null ||
        this.data.PASSWORD != undefined ||
        this.data.PASSWORD.trim() != '') &&
      this.data.PASSWORD.length < 8
    ) {
      this.isOk = false;
      this.message.error(' Password Must Be 8 Character/Digit', '');
      // } else if (this.data.ADDRESS == null || this.data.ADDRESS.trim() == '') {
      //   this.isOk = false;
      //   this.message.error(' Please Enter Address', '');
      // } else if (
      //   this.data.OFFICE_NAME == null ||
      //   this.data.OFFICE_NAME.trim() == ''
      // ) {
      //   this.isOk = false;
      //   this.message.error(' Please Enter Office Name.', '');
    } else if (this.data.LOCATION == null || this.data.LOCATION.trim() == '') {
      this.isOk = false;
      this.message.error(' Please Enter Location', '');
    } else if (
      this.data.DESIGNATION == null ||
      this.data.DESIGNATION.trim() == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Designation', '');
    } else if (
      this.data.EMPLOYEE_CODE == undefined ||
      this.data.EMPLOYEE_CODE <= 0
    ) {
      this.isOk = false;
      this.message.error('Please Enter Employee Code  ', '');
    } else if (
      this.data.DDO_OF_THE_OFFICIAL == null ||
      this.data.DDO_OF_THE_OFFICIAL.trim() == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter DDO of the Official', '');
    } else if (this.data.GRADE_PAY == undefined || this.data.GRADE_PAY <= 0) {
      this.isOk = false;
      this.message.error('Please Enter Basic Pay  ', '');
    }

    if (this.isOk) {
      this.isSpinning = true;
      if (
        this.data.ADDRESS == undefined ||
        this.data.ADDRESS == null ||
        this.data.ADDRESS == '' ||
        this.data.ADDRESS.trim() == ''
      ) {
        this.data.ADDRESS = ' ';
      } else {
        this.data.ADDRESS = this.data.ADDRESS;
      }
      if (
        this.data.EMAIL_ID == undefined ||
        this.data.EMAIL_ID == null ||
        this.data.EMAIL_ID == '' ||
        this.data.EMAIL_ID.trim() == ''
      ) {
        this.data.EMAIL_ID = null;
      } else {
        this.data.EMAIL_ID = this.data.EMAIL_ID;
      }
      if (
        this.data.OFFICE_NAME == undefined ||
        this.data.OFFICE_NAME == null ||
        this.data.OFFICE_NAME == '' ||
        this.data.OFFICE_NAME.trim() == ''
      ) {
        this.data.OFFICE_NAME = null;
      } else {
        this.data.OFFICE_NAME = this.data.OFFICE_NAME;
      }
      {
        if (this.data.ID) {
          // this.data.CLAIM_ID = this.claimID
          this.api.updateEmployeeMaster(this.data).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information Changed Successfully...', '');
              if (!addNew) {
                this.drawerClose();
                websitebannerPage.form.reset();

                this.isSpinning = false;
              }
            } else if (successCode.code == '300') {
              this.message.error(
                'Mobile Number or Email ID Already Exist...',
                ''
              );
              this.isSpinning = false;
            } else {
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
        } else {
          this.api
            .createsimpleEmployeeMaster(this.data)
            .subscribe((successCode) => {
              if (successCode.code == '200') {
                this.message.success('Information Save Successfully...', '');
                if (!addNew) {
                  this.drawerClose();
                  websitebannerPage.form.reset();
                } else {
                  this.data = new EmployeeMaster();
                  this.resetDrawer(websitebannerPage);
                  // this.data.IMG_URL= '';

                  this.api.getEmployeeMaster(0, 0, '', 'desc', '').subscribe(
                    (data) => {
                      // if (data['count']==0){
                      //   this.data.SEQUENCE_NUMBER=1;
                      // }else
                      // {
                      //   this.data.SEQUENCE_NUMBER=data['data'][0]['SEQUENCE_NUMBER']+1;
                      // }
                    },
                    (err) => {
                      console.log(err);
                    }
                  );
                }
                this.isSpinning = false;
              } else if (successCode.code == '300') {
                this.message.error(
                  'Mobile Number or Email ID Already Exist...',
                  ''
                );
                this.isSpinning = false;
              } else {
                this.message.error('Failed To Fill Information...', '');
                this.isSpinning = false;
              }
            });
        }
      }
    }
  }
}
