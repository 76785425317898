<div nz-row nzType="flex">
    <div nz-col nzXs="24" nzSm="24" nzMd="20" nzLg="20" nzXl="12">
        <div class="formname">{{formTitle}}</div>
    </div>

    <div nz-col nzXs="24" nzSm="24" nzMd="4" nzLg="6" nzXl="12" style="display: flex; justify-content: flex-end">
        <form nz-form [nzLayout]="'inline'">

            <nz-form-item>
                <button nz-button nzTooltipTitle="Excel" nzTooltipPlacement="top" nz-tooltip nzType="primary"
                    (click)="importInExcel()" [nzLoading]="exportLoading">
                    <i nz-icon nzType="file-excel" nzTheme="outline"></i>
                </button>
            </nz-form-item>

            <nz-form-item>
                <button nz-button nzTooltipTitle="Filter" nzTooltipPlacement="top" nz-tooltip [nzType]="isFilterApplied"
                    (click)="showFilter()">
                    <i nz-icon nzType="filter"></i>
                </button>
            </nz-form-item>
            <!-- <nz-form-item>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                    <input type="text" name="search" [(ngModel)]="searchText" (keydown.enter)="onKeypressEvent($event)"
                        nz-input placeholder="Search" />
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" id="button" (click)="search(true)" nzSearch><i nz-icon
                            nzType="search"></i></button>
                </ng-template>
            </nz-form-item> -->
        </form>
    </div>
</div>

<div nz-row>
    <div nz-col nzSpan="24">
        <div class="filter {{ filterClass }}">
            <div class="filter-box">

                <form nz-form>
                    <div nz-row>
                        <div nz-col nzSpan="8">
                            <nz-form-item style="margin-top: 1px;width: 100%;">
                                <nz-form-label nzNoColon>Select Date</nz-form-label>
                                <nz-range-picker name="date" nzFormat="dd MMM yyyy" [(ngModel)]="DATE">
                                </nz-range-picker>
                            </nz-form-item>
                        </div>

                        <div nz-col nzSpan="6">
                            <nz-form-item>
                                <nz-form-label nzNoColon>User</nz-form-label>
                                <nz-select name="stageName" nzShowSearch nzAllowClear style="width: 100%"
                                    nzPlaceHolder="Select User Name" [(ngModel)]="USERS">
                                    <nz-option *ngFor="let stage of usernames;" [nzValue]="stage.ID"
                                        [nzLabel]="stage.NAME">
                                    </nz-option>
                                </nz-select>
                            </nz-form-item>
                        </div>


                        <div nz-col nzSpan="8">
                            <nz-form-label nzNoColon></nz-form-label>
                            <nz-form-item>
                                <button style="margin-right: 10px" nz-button [nzLoading]="isSpinning" nzType="primary"
                                    (click)="applyFilter()" nzJustify="end">
                                    <i nz-icon nzType="filter"></i>Apply Filter
                                </button>
                                <button nz-button nzType="primary" (click)="clearFilter()">
                                    <i nz-icon nzType="filter"></i>Clear filter
                                </button>
                            </nz-form-item>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>





<!-- Table -->
<div nz-row>
    <div nz-col nzSpan="24">
        <nz-table id="excel-table" class="my-scroll no-wrap1" nzBordered #table nzShowSizeChanger
            [nzFrontPagination]="false" [nzData]="dataList" [nzLoading]="loadingRecords" [nzTotal]="totalRecords"
            [(nzPageIndex)]="pageIndex" [(nzPageSize)]="pageSize" (nzPageIndexChange)="search()"
            (nzPageSizeChange)="search()" (nzQueryParams)="sort($event)" [nzScroll]="{x:'1500px',y:'420px'}">

            <thead nzSingleSort>
                <tr>
                    <th nzWidth="80px" style="font-weight: bold; text-align: center;"
                        nzColumnKey="{{'TOTAL_CREATED'}}" [nzSortFn]="true">
                        Created
                    </th>
                    <th nzWidth="100px" style="font-weight: bold; text-align: center;"
                        nzColumnKey="{{'PENDING_AT_OTHERDESK'}}" [nzSortFn]="true">
                        Transfered by me
                    </th>
                    <th nzWidth="100px" style="font-weight: bold; text-align: center;" nzColumnKey="{{'PENDING_AT_MYDESK'}}"
                        [nzSortFn]="true">
                        Transfered towards me
                    </th>
                    <th nzWidth="80px" style="font-weight: bold; text-align: center;"
                        nzColumnKey="{{'TOTAL_CLOSE'}}" [nzSortFn]="true">
                        Closed by me
                    </th>
                    <th nzWidth="80px" nzColumnKey="{{'TOTAL_ACCEPT'}}" [nzSortFn]="true"
                        style="font-weight: bold; text-align: center;">
                        Accepted by me
                    </th>
                    <th nzWidth="80px" nzColumnKey="{{'TOTAL_REJECT'}}" [nzSortFn]="true"
                        style="font-weight: bold; text-align: center;">
                        Rejected by me
                    </th>
                    <th nzWidth="80px" nzColumnKey="{{'TOTAL_CONVERTED_TO_FINAL'}}" [nzSortFn]="true"
                        style="font-weight: bold; text-align: center;">
                        Converted To Final
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let data of table.data;">
                    <td style="white-space: pre-line;" align="center">{{data['TOTAL_CREATED']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['PENDING_AT_OTHERDESK']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['PENDING_AT_MYDESK']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['TOTAL_CLOSE']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['TOTAL_ACCEPT']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['TOTAL_REJECT']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['TOTAL_CONVERTED_TO_FINAL']}}</td>
                </tr>
            </tbody>
        </nz-table>
    </div>
</div>