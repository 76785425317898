<div>
    <form nz-form>
        <nz-spin [nzSpinning]="isSpinning">
            <div nz-row class="my-box">
                <div nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label nzRequired nzNoColon>Parent Role </nz-form-label>
                        <nz-input-group>
                            <nz-select name="parent_name" [nzLoading]="roleLoading" nzShowSearch style="width: 100%"
                                [(ngModel)]="data.PARENT_ID">
                                <nz-option nzValue="0" nzLabel="None"></nz-option>
                                <nz-option *ngFor="let role of roles" [nzValue]="role.ID" [nzLabel]="role.NAME">
                                </nz-option>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-item>
                </div>

                <div nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label nzRequired nzNoColon>Name </nz-form-label>
                        <nz-form-control nzHasFeedback nzErrorTip="Input is required">
                            <input nz-input autocomplete="off" name="name" [(ngModel)]="data.NAME" required />
                        </nz-form-control>
                    </nz-form-item>
                </div>

                <div nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label nzRequired nzNoColon>Type </nz-form-label>
                        <nz-input-group>
                            <nz-select name="type" nzShowSearch style="width: 100%" [(ngModel)]="data.TYPE">
                                <nz-option nzValue="SUPER_ADMIN" nzLabel="Super Admin"></nz-option>
                                <nz-option nzValue="USER" nzLabel="User"></nz-option>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-item>
                </div>

                <div nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label nzNoColon>Description</nz-form-label>
                        <nz-form-control>
                            <textarea nz-input autocomplete="off" rows="2" name="description"
                                [(ngModel)]="data.DESCRIPTION"></textarea>
                        </nz-form-control>
                    </nz-form-item>
                </div>

                <div nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label nzRequired nzNoColon>Start Page Name (/pagename) </nz-form-label>
                        <nz-form-control nzHasFeedback nzErrorTip="Input is required">
                            <input nz-input autocomplete="off" name="start_page" [(ngModel)]="data.START_PAGE"
                                required />
                        </nz-form-control>
                    </nz-form-item>
                </div>

                <div nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label nzRequired nzNoColon>Level </nz-form-label>
                        <nz-form-control nzHasFeedback nzErrorTip="Input is required">
                            <input nz-input autocomplete="off" name="level" [(ngModel)]="data.LEVEL" required />
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
        </nz-spin>
    </form>

    <div class="footer">
        <button type="button" nz-button (click)="close()" class="ant-btn"
            style="margin-right: 8px;"><span>Cancel</span></button>
        <button type="button" class="ant-btn ant-btn-primary" nz-button [nzLoading]="isSpinning" *ngIf="data.ID"
            (click)="save(false)"><span>Update & close</span></button>
        <button type="button" class="ant-btn ant-btn-primary" *ngIf="!data.ID" nz-button nz-dropdown
            [nzDropdownMenu]="menu" (click)="save(false)"><span>Save & Close</span></button>
        <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
                <li nz-menu-item class="menuc" style="border-radius: 4px;background-color: #40a9ff;">
                    <a (click)="save(true)">Save & New</a>
                </li>
            </ul>
        </nz-dropdown-menu>
    </div>
</div>