export class HospitalMappingMaster {
  ID: number;
  CLAIM_ID: number;
  HOD_ID: number;
  EXPOST_FACTO_ID: number;
  DELAY_CONDOLATION_ID: number;
  TYPE: string = '';
  HOSPITAL_ID: any;
  NAME: string = '';
  NAME_HN: string = '';
  ADDRESS: string = '';
  ADDRESS_HN: string = '';
  ACCREDATION: string = '';
  //   CREATED_MODIFIED_DATE;
  //   READ_ONLY;
  //   ARCHIVE_FLAG;
  //   CLIENT_ID;
}
