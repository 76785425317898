<div nz-row nzType="flex">
    <div nz-col nzXs="24" nzSm="24" nzMd="20" nzLg="20" nzXl="12">
        <div class="formname">{{formTitle}}</div>
    </div>

    <div nz-col nzXs="24" nzSm="24" nzMd="4" nzLg="6" nzXl="12" style="display: flex; justify-content: flex-end">
        <form nz-form [nzLayout]="'inline'">
            <nz-form-item>
                <button nz-button nzTooltipTitle="Excel" nzTooltipPlacement="top" nz-tooltip nzType="primary"
                    (click)="importInExcel()" [nzLoading]="exportLoading">
                    <i nz-icon nzType="file-excel" nzTheme="outline"></i>
                </button>
            </nz-form-item>

            <nz-form-item>
                <button nz-button nzTooltipTitle="Filter" nzTooltipPlacement="top" nz-tooltip [nzType]="isFilterApplied"
                    (click)="showFilter()">
                    <i nz-icon nzType="filter"></i>
                </button>
            </nz-form-item>

            <nz-form-item>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                    <input type="text" name="search" [(ngModel)]="searchText" (keydown.enter)="onKeypressEvent($event)"
                        nz-input placeholder="Search" />
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" id="button" (click)="search(true)" nzSearch><i nz-icon
                            nzType="search"></i></button>
                </ng-template>
            </nz-form-item>
        </form>
    </div>
</div>

<div nz-row>
    <div nz-col nzSpan="24">
        <div class="filter {{ filterClass }}">
            <div class="filter-box">

                <form nz-form>
                    <div nz-row>
                        <div nz-col nzSpan="6">
                            <nz-form-item style="margin-top: 1px;width: 100%;">
                                <nz-form-label nzNoColon>Select Date</nz-form-label>
                                <nz-range-picker name="date" nzFormat="dd MMM yyyy" [(ngModel)]="DATE">
                                </nz-range-picker>
                            </nz-form-item>
                        </div>
                      
                    
                        <div nz-col nzSpan="6">
                            <nz-form-item>
                                <nz-form-label nzNoColon>User</nz-form-label>
                                <nz-select name="user" nzShowSearch nzAllowClear style="width: 100%"
                                    nzPlaceHolder="Select User Name" nzMode="multiple" [(ngModel)]="USERS">
                                    <nz-option *ngFor="let stage of usernames;" [nzValue]="stage.ID"
                                        [nzLabel]="stage.NAME">
                                    </nz-option>
                                </nz-select>
                            </nz-form-item>
                        </div>

                        <div nz-col nzSpan="6">
                            <nz-form-item>
                                <nz-form-label nzNoColon>Hierarchy Name</nz-form-label>
                                <nz-select name="InspectorName" nzShowSearch nzAllowClear style="width: 100%"
                                    nzPlaceHolder="Select Hierarchy Name" nzMode="multiple"
                                    [(ngModel)]="HIERARCHY">
                                    <nz-option *ngFor="let iname of Hierarchyname;" [nzValue]="iname.ID"
                                        [nzLabel]="iname.NAME">
                                    </nz-option>
                                </nz-select>
                            </nz-form-item>
                        </div>
                        <div nz-col nzSpan="6">
                            <nz-form-item>
                                <nz-form-label nzNoColon>File Year</nz-form-label>
                                <nz-select name="fileyear" nzShowSearch nzAllowClear style="width: 100%"
                                    nzPlaceHolder="Select File Year" nzMode="multiple" [(ngModel)]="FILE_NAME">
                                    <!-- <nz-option *ngFor="let file of filenames;" [nzValue]="stage.ID"
                                        [nzLabel]="stage.NAME">
                                    </nz-option> -->
                                    <nz-option nzLabel="2022-23" nzValue="2022-23"></nz-option>
                                    <nz-option nzLabel="2023-24" nzValue="2023-24"></nz-option>
                                    <!-- <nz-option nzLabel="Governemnt" nzValue="G"></nz-option> -->
                                </nz-select>
                            </nz-form-item>
                        </div>

                        <div nz-col nzSpan="6">
                            <nz-form-item>
                                <nz-form-label nzNoColon>Status</nz-form-label>
                                <nz-select name="status" nzShowSearch nzAllowClear style="width: 100%"
                                    nzPlaceHolder="Select Status" nzMode="multiple"
                                    [(ngModel)]="STATUS">
                                    <nz-option nzLabel="Created" nzValue="A"></nz-option>
                                    <nz-option nzLabel="Transfered" nzValue="T"></nz-option>
                                    <!-- <nz-option nzLabel="Governemnt" nzValue="G"></nz-option> -->
                                    <!-- <nz-option *ngFor="let stat of Status;" [nzValue]="stat.ID"
                                        [nzLabel]="iname.NAME">
                                    </nz-option> -->
                                </nz-select>
                            </nz-form-item>
                        </div>

                    </div>



                    <div nz-row>
                        <!-- <div nz-col nzSpan="6">
                            <nz-form-item>
                                <nz-form-label nzNoColon>Status</nz-form-label>
                                <nz-select nzShowSearch nzAllowClear name="Benificiarytypesds"
                                    nzPlaceHolder="Select Status Type" [(ngModel)]="BENIFICIARY_TYPE">
                                    <nz-option nzLabel="CGHS" nzValue="CG"></nz-option>
                                    <nz-option nzLabel="CS(MA)" nzValue="CS"></nz-option>

                                </nz-select>
                            </nz-form-item>
                        </div> -->

                        <div nz-col nzSpan="8">
                            <nz-form-label nzNoColon></nz-form-label>
                            <nz-form-item>
                                <button nz-button [nzLoading]="isSpinning" nzType="primary" (click)="applyFilter()"
                                    nzJustify="end">
                                    <i nz-icon nzType="filter"></i>Apply Filter
                                </button>
                                <button nz-button nzType="primary" (click)="clearFilter()">
                                    <i nz-icon nzType="filter"></i>Clear filter
                                </button>
                            </nz-form-item>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>





<!-- Table -->
<div nz-row>
    <div nz-col nzSpan="24">
        <!-- <nz-table id="excel-table" class="my-scroll no-wrap1" nzBordered #table nzShowSizeChanger [nzFrontPagination]="true"
            [nzData]="dataList" [nzLoading]="loadingRecords" [nzTotal]="totalRecords" [(nzPageIndex)]="pageIndex"
            [(nzPageSize)]="pageSize" (nzPageIndexChange)="search()" (nzPageSizeChange)="search(true)"> -->

        <nz-table id="excel-table" class="my-scroll no-wrap1" nzBordered #table nzShowSizeChanger
            [nzFrontPagination]="false" [nzData]="dataList" [nzLoading]="loadingRecords" [nzTotal]="totalRecords"
            [(nzPageIndex)]="pageIndex" [(nzPageSize)]="pageSize" (nzPageIndexChange)="search()"
            [nzScroll]="{x:'2000px',y:'420px'}" (nzPageSizeChange)="search()" (nzQueryParams)="sort($event)">

            <thead nzSingleSort>
                <tr>

                    <th nzWidth="100px" style="font-weight: bold; text-align: center;" nzColumnKey="{{'CREATOR_NAME'}}"
                        [nzSortFn]="true">
                        User Name
                    </th>
                    <th nzWidth="90px" style="font-weight: bold; text-align: center;" nzColumnKey="{{'CREATOR_ROLE'}}"
                        [nzSortFn]="true">
                        Role Name
                    </th>
                    <th nzWidth="90px" style="font-weight: bold; text-align: center;" nzColumnKey="{{'HIRARCHY_NAME'}}"
                        [nzSortFn]="true">
                        Hierarchy Name
                    </th>
                    <th nzWidth="70px" style="font-weight: bold; text-align: center;" nzColumnKey="{{'FILE_NAME'}}"
                        [nzSortFn]="true">
                        File Name
                    </th>
                    <th nzWidth="60px" nzColumnKey="{{'FILE_NUMBER'}}" [nzSortFn]="true"
                        style="font-weight: bold; text-align: center;">
                        File Number
                    </th>
                    <th nzWidth="80px" nzColumnKey="{{'FILE_CREATED_DATE_TIME'}}" [nzSortFn]="true"
                        style="font-weight: bold; text-align: center;">
                        Created Date & Time
                    </th>

                    <th nzWidth="60px" nzColumnKey="{{'CURRENT_POSITION_ID'}}" [nzSortFn]="true"
                        style="font-weight: bold; text-align: center;">Currently At
                    </th>
                    <th nzWidth="90px" nzColumnKey="{{'TRANSFER_DATETIME'}}" [nzSortFn]="true"
                        style="font-weight: bold; text-align: center;">
                        Transfered Date & Time
                    </th>
                    <th nzWidth="85px" nzColumnKey="{{'IS_ADVANCE'}}" [nzSortFn]="true"
                        style="font-weight: bold; text-align: center;">
                        Is Advance/Final File
                    </th>
                    <th nzWidth="80px" nzColumnKey="{{'FILE_STATUS'}}" [nzSortFn]="true"
                        style="font-weight: bold; text-align: center;">
                        Status
                    </th>
                    <th nzWidth="80px" nzColumnKey="{{'FILE_CLOSED_DATETIME'}}" [nzSortFn]="true"
                        style="font-weight: bold; text-align: center;">
                        Closed Date & Time
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let data of table.data;">

                    <td style="white-space: pre-line;" align="center">{{data['CREATOR_NAME']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['CREATOR_ROLE']}}</td>

                    <!-- <td style="text-align: center;">
                        <span nz-icon *ngIf="data['CREATOR_ROLE']=='E'">Empanelled</span>
                        <span nz-icon *ngIf="data['CREATOR_ROLE']=='NE'">Non Empanelled</span>
                        <span nz-icon *ngIf="data['CREATOR_ROLE']=='G'">Government</span>
                    </td> -->
                    <td style="white-space: pre-line;" align="center">{{data['HIRARCHY_NAME']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['FILE_NAME']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['FILE_NUMBER']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['FILE_CREATED_DATE_TIME']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['CURRENT_POSITION_ID']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['TRANSFER_DATETIME']}}</td>
                    <!-- <td style="white-space: pre-line;" align="center">{{data['IS_ADVANCE']}}</td> -->
                    <td style="text-align: center;">
                        <span nz-icon *ngIf="data['IS_ADVANCE']=='Y'">Yes</span>
                        <span nz-icon *ngIf="data['IS_ADVANCE']=='F'">Final</span>
                        <span nz-icon *ngIf="data['IS_ADVANCE']=='N'">No</span>
                        <!-- <span nz-icon *ngIf="data['CREATOR_ROLE']=='G'">Government</span> -->
                    </td>
                    <!-- <td style="white-space: pre-line;" align="center">{{data['FILE_STATUS']}}</td> -->
                    <td style="text-align: center;">
                        <span nz-icon *ngIf="data['FILE_STATUS']=='A'">Created</span>
                        <span nz-icon *ngIf="data['FILE_STATUS']=='T'">Transfered</span>
                        <!-- <span nz-icon *ngIf="data['CREATOR_ROLE']=='G'">Government</span> -->
                    </td>
                    <td style="white-space: pre-line;" align="center">{{data['FILE_CLOSED_DATETIME']}}</td>

                </tr>
            </tbody>
        </nz-table>
    </div>
</div>