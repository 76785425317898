import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { EmployeeMaster } from 'src/app/Models/Employee';
import { QuestionaryMaster } from 'src/app/Models/questionarymaster';
import { ApiService } from 'src/app/Service/api.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { ApplicantMaster } from 'src/app/Models/applicantmaster';
import { CheckList } from 'src/app/Models/checkList';
import { ClaimMaster } from 'src/app/Models/claimmaster';
import { CookieService } from 'ngx-cookie-service';
import { HospitalMappingMaster } from 'src/app/Models/hospitalmappingmaster';
import * as moment from 'moment';
import { HODPermissionMaster } from 'src/app/Models/hodpermission';

@Component({
  selector: 'app-addclaimmaster',
  templateUrl: './addclaimmaster.component.html',
  styleUrls: ['./addclaimmaster.component.css'],
})
export class AddclaimmasterComponent implements OnInit {
  constructor(
    private message: NzNotificationService,
    private api: ApiService,
    private datepipe: DatePipe,
    public cookie: CookieService
  ) {}
  namepatt = /[a-zA-Z][a-zA-Z ]+/;
  emailpattern =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  @Input() isSpinning = false;
  isOk = true;
  userId: any;
  ngOnInit(): void {
    this.current = 0;
    this.isSpinning = false;
    this.allEmployeeList();
    // this.allHODPermissionList();
    // this.allDelayCondonationList();
    // this.allExpostFactoList();
    this.allHospitalList();
    this.userId = Number(sessionStorage.getItem('userId'));
  }
  @Input()
  drawerVisible: boolean = false;
  empDrawerVisible: boolean = false;
  @Input() drawerClose: Function;
  @Input() data: any;
  // @Input() data: ApplicantMaster;
  @Input() empID: any;
  @Input() claimID: any;
  @Input() currentStageID: any;
  // @Input() data2: ClaimMaster;
  //  data2: ClaimMaster;
  // @Input() data3: QuestionaryMaster;
  data2: ClaimMaster = new ClaimMaster();
  data3: QuestionaryMaster = new QuestionaryMaster();
  hospitalData: HospitalMappingMaster = new HospitalMappingMaster();
  // @Input() data4: CheckList;
  //  data4: CheckList;
  data4: CheckList = new CheckList();

  @Input() current = 0;
  employee: EmployeeMaster[] = [];
  HODPermissionList: HODPermissionMaster[] = [];
  delayCondolationList: HODPermissionMaster[] = [];
  expostFactoList: HODPermissionMaster[] = [];

  Cities: EmployeeMaster[];
  filteredOptions = [];
  Names = EmployeeMaster;
  hospitalList: any = [];
  diffDays: any;
  date1: any;
  date2: any;
  expoDiffDays: any;
  TREATEMENT_TYPE1 = false;
  TREATEMENT_TYPE2 = false;
  CGHS_AMA_REFERENCE_DATE: any = new Date();
  CGHS_AMA_REFERENCE_END_DATE: any = new Date();
  empLoader: boolean = false;
  orderdata1 = [];
  hodList = [];
  allEmployeeList() {
    this.empLoader = true;
    console.log('aaa', this.data.EMP_ID);

    this.api.getEmployeeMaster(0, 0, '', '', ' AND STATUS = 1').subscribe(
      (data) => {
        if (data['code'] == 200) {
          this.employee = data['data'];
          this.filteredOptions = this.employee;
          this.empLoader = false;
        } else {
          this.message.error("Can't Load Employee Data", '');
          this.empLoader = false;
        }
      },
      (err) => {
        console.log(err);
      }
    );
    if (this.data.EMP_ID != null || this.data.EMP_ID != undefined) {
      this.api
        .getEmployeeMaster(0, 0, '', '', ' AND ID = ' + this.data.EMP_ID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              this.data.OFFICE_NAME = data['data'][0]['OFFICE_NAME'];
              this.data.DESIGNATION = data['data'][0]['DESIGNATION'];
              this.data.EMPLOYEE_CODE = data['data'][0]['EMPLOYEE_CODE'];
              this.data.LOCATION = data['data'][0]['LOCATION'];
              this.data.DDO_OF_THE_OFFICIAL =
                data['data'][0]['DDO_OF_THE_OFFICIAL'];
              this.data.GRADE_PAY = data['data'][0]['GRADE_PAY'];
              this.data.EMAIL_ID = data['data'][0]['EMAIL_ID'];
              this.data.MOBILE_NO = data['data'][0]['MOBILE_NO'];
              this.data.ADDRESS = data['data'][0]['ADDRESS'];
              this.data.EMPLOYEE_NAME = data['data'][0]['NAME'];
            } else {
              this.message.error("Can't Load Employee Data", '');
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
    }
  }
  allHODPermissionList() {
    // this.empLoader = true;
    console.log('aaa', this.data.EMP_ID);

    this.api
      .getHODPermissionMaster(
        0,
        0,
        '',
        '',
        ' AND EMP_ID = ' + this.data2.EMP_ID
      )
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            this.HODPermissionList = data['data'];
            // this.filteredOptions = this.employee;
            // this.empLoader = false;
          } else {
            this.message.error("Can't Load Data", '');
            // this.empLoader = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }
  allDelayCondonationList() {
    // this.empLoader = true;
    console.log('aaa', this.data.EMP_ID);

    this.api
      .getDelayCondolationMaster(
        0,
        0,
        '',
        '',
        ' AND EMP_ID = ' + this.data2.EMP_ID
      )
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            this.delayCondolationList = data['data'];
            // this.filteredOptions = this.employee;
            // this.empLoader = false;
          } else {
            this.message.error("Can't Load Data", '');
            // this.empLoader = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }
  allExpostFactoList() {
    // this.empLoader = true;
    console.log('aaa', this.data.EMP_ID);

    this.api
      .getExpostFactoMaster(0, 0, '', '', ' AND EMP_ID = ' + this.data2.EMP_ID)
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            this.expostFactoList = data['data'];
            // this.filteredOptions = this.employee;
            // this.empLoader = false;
          } else {
            this.message.error("Can't Load Data", '');
            // this.empLoader = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  allHospitalList() {
    this.api.getAllHospital(0, 0, '', '', ' AND STATUS = 1').subscribe(
      (data) => {
        if (data['code'] == 200) {
          this.hospitalList = data['data'];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  close(): void {
    this.current = 0;
    this.hospitalData.TYPE = '';
    this.hospitalData.HOSPITAL_ID = '';
    this.hospitalData.NAME = '';
    this.hospitalData.ADDRESS = '';
    this.hospitalData.ACCREDATION = '';
    this.drawerClose();
  }

  applicantResetDrawer(applicantMasterPages: NgForm) {
    console.log('current', this.current);

    this.data = new ApplicantMaster();
    // this.data.PARENT_GROUP_ID=this.parentgroup;
    // applicantMasterPages.form.markAsPristine();
    // applicantMasterPages.form.markAsUntouched();
    applicantMasterPages.form.reset();
  }
  claimResetDrawer(claimMasterPage: NgForm) {
    this.data2 = new ClaimMaster();
    // this.data.PARENT_GROUP_ID=this.parentgroup;
    // claimMasterPage.form.markAsPristine();
    // claimMasterPage.form.markAsUntouched();
    claimMasterPage.form.reset();
  }
  queResetDrawer(queMasterPage: NgForm) {
    this.data3 = new QuestionaryMaster();
    // this.data.PARENT_GROUP_ID=this.parentgroup;
    // queMasterPage.form.markAsPristine();
    // queMasterPage.form.markAsUntouched();
    queMasterPage.form.reset();
  }
  checkResetDrawer(checkListMasterPage: NgForm) {
    this.data4 = new CheckList();
    // this.data.PARENT_GROUP_ID=this.parentgroup;
    // checkListMasterPage.form.markAsPristine();
    // checkListMasterPage.form.markAsUntouched();
    checkListMasterPage.form.reset();
  }
  // close(applicantMasterPages: NgForm,claimMasterPage: NgForm,queMasterPage: NgForm,checkListMasterPage: NgForm) {
  //   console.log('curren2t',this.current);
  //  this.current = 0;
  //   this.applicantResetDrawer(applicantMasterPages);
  //   this.claimResetDrawer(claimMasterPage);
  //   this.queResetDrawer(queMasterPage);
  //   this.checkResetDrawer(checkListMasterPage);
  //   // claimMasterPage.form.reset();
  //   this.drawerClose();
  // }

  omit(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  dateOmit(event: any) {
    return false;
  }

  save(addNew: boolean, claimMasterPage: NgForm): void {
    if (this.current == 0) {
      this.current = 1;
    } else if (this.current == 1) {
      this.current = 2;
    } else if (this.current == 2) {
      this.current = 3;
    }
    this.isSpinning = false;
    this.isOk = true;

    if (
      this.data.NAME.trim() == '' &&
      this.data.LOCATION.trim() == '' &&
      this.data.DDO_OF_THE_OFFICIAL.trim() == '' &&
      this.data.DESIGNATION.trim() == '' &&
      this.data.OFFICE_NAME.trim() == '' &&
      this.data.EMPLOYEE_CODE == 0 &&
      this.data.GRADE_PAY == 0
    ) {
      this.isOk = false;
      this.message.error('Please Fill All The Required Fields ', '');
    } else if (this.data.NAME == null || this.data.NAME.trim() == '') {
      this.isOk = false;
      this.message.error(' Please Enter Employee Name.', '');
    } else if (
      this.data.EMPLOYEE_CODE == undefined ||
      this.data.EMPLOYEE_CODE <= 0
    ) {
      this.isOk = false;
      this.message.error('Please Enter Employee Code  ', '');
    } else if (this.data.GRADE_PAY == undefined || this.data.GRADE_PAY <= 0) {
      this.isOk = false;
      this.message.error('Please Enter Grade Pay  ', '');
    } else if (
      this.data.OFFICE_NAME == null ||
      this.data.OFFICE_NAME.trim() == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Office Name.', '');
    } else if (
      this.data.DESIGNATION == null ||
      this.data.DESIGNATION.trim() == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Designation', '');
    } else if (this.data.LOCATION == null || this.data.LOCATION.trim() == '') {
      this.isOk = false;
      this.message.error(' Please Enter Location', '');
    } else if (
      this.data.DDO_OF_THE_OFFICIAL == null ||
      this.data.DDO_OF_THE_OFFICIAL.trim() == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Designation', '');
    }

    if (this.isOk) {
      this.isSpinning = true;
      {
        if (this.data.ID) {
          this.api.updateEmployeeMaster(this.data).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information Changed Successfully...', '');
              if (!addNew) this.drawerClose();
              this.isSpinning = false;
            } else {
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
        } else {
          this.api.createEmployeeMaster(this.data).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information Save Successfully...', '');
              if (!addNew) this.drawerClose();
              else {
                this.data = new ApplicantMaster();
                // this.resetDrawer(claimMasterPage);
                // this.data.IMG_URL= '';

                this.api.getEmployeeMaster(0, 0, '', 'desc', '').subscribe(
                  (data) => {
                    // if (data['count']==0){
                    //   this.data.SEQUENCE_NUMBER=1;
                    // }else
                    // {
                    //   this.data.SEQUENCE_NUMBER=data['data'][0]['SEQUENCE_NUMBER']+1;
                    // }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              }
              this.isSpinning = false;
            } else {
              this.message.error('Failed To Fill Information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
  }
  empAllDataForUpdate: any;
  empSave(addNew: boolean, claimMasterPage: NgForm): void {
    this.isSpinning = false;
    console.log('ID', this.data.ID);
    console.log('this.empID', this.empID);
    console.log('this.claimID', this.claimID);
    console.log('this.userid', Number(sessionStorage.getItem('userId')));
    this.isOk = true;
    this.data.hospitalData = this.hospitalMapList;
    for (var i = 0; this.hospitalMapList.length > i; i++) {
      this.hospitalMapList[i]['ID'] = undefined;
      this.hospitalMapList[i]['CLAIM_ID'] = undefined;
    }
    this.data.hospitalData = this.hospitalMapList;

    if (
      this.data.EMP_ID == undefined &&
      this.data.DESIGNATION == undefined &&
      // this.data.OFFICE_NAME == undefined &&
      // this.data.DDO_OF_THE_OFFICIAL == undefined &&
      this.data.EMPLOYEE_CODE == undefined &&
      this.data.GRADE_PAY == 0 &&
      this.data.BENEFICIARY_TYPE == undefined
    ) {
      this.isOk = false;
      this.message.error('Please Fill All The Required Fields ', '');
    } else if (
      this.data.EMP_ID == undefined ||
      this.data.EMP_ID == null ||
      this.data.EMP_ID == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Select Employee Name', '');
    } else if (
      this.data.DESIGNATION == undefined ||
      this.data.DESIGNATION == null ||
      this.data.DESIGNATION == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Designation', '');
      // } else if (
      //   this.data.OFFICE_NAME == undefined ||
      //   this.data.OFFICE_NAME == null ||
      //   this.data.OFFICE_NAME == ''
      // ) {
      //   this.isOk = false;
      //   this.message.error(' Please Enter Office Name', '');
      // } else if (
      //   this.data.DDO_OF_THE_OFFICIAL == undefined ||
      //   this.data.DDO_OF_THE_OFFICIAL == null ||
      //   this.data.DDO_OF_THE_OFFICIAL == ''
      // ) {
      //   this.isOk = false;
      //   this.message.error(' Please Enter DDO Of The Official', '');
    } else if (
      this.data.EMPLOYEE_CODE == undefined ||
      this.data.EMPLOYEE_CODE == null ||
      this.data.EMPLOYEE_CODE == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Employee Code', '');
    } else if (
      this.data.GRADE_PAY == undefined ||
      this.data.GRADE_PAY == null ||
      this.data.GRADE_PAY == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Basic Pay ', '');
    } else if (
      this.data.MOBILE_NO == undefined ||
      this.data.MOBILE_NO == null ||
      this.data.MOBILE_NO == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Mobile Number', '');
    } else if (
      this.data.BENEFICIARY_TYPE == undefined ||
      this.data.BENEFICIARY_TYPE == null ||
      this.data.BENEFICIARY_TYPE == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Select Beneficiary Type', '');
    } else if (
      this.data.BENEFICIARY_TYPE == 'CG' &&
      (this.data.CGHS_CARD_NO == undefined ||
        this.data.CGHS_CARD_NO == null ||
        this.data.CGHS_CARD_NO == '')
    ) {
      this.isOk = false;
      this.message.error(' Please Enter CGHS Card Number.', '');
      // } else if (
      //   this.data.BENEFICIARY_TYPE == 'CG' &&
      //   (this.data.CGHS_CARD_VALIDITY == undefined ||
      //     this.data.CGHS_CARD_VALIDITY == null)
      // ) {
      //   this.isOk = false;
      //   this.message.error('Please Enter CGHS Card Validity  ', '');
    }
    //  else if (
    //   this.data.MOBILE_NO != undefined ||
    //   this.data.MOBILE_NO != null ||
    //   this.data.MOBILE_NO != '' &&
    //   ( this.data.MOBILE_NO.length < 10))
    //  {
    //   this.isOk = false;
    //   this.message.error(' Please Enter Valid 10 Digit Mobile Number', '');
    // }
    // else
    // if (
    //   this.data.EMAIL_ID != null ||
    //   this.data.EMAIL_ID != undefined ||
    //   this.data.EMAIL_ID != '' ||
    //   this.data.EMAIL_ID.trim() == ''
    // )
    // {
    //   if ((this.data.EMAIL_ID != null ||
    //     this.data.EMAIL_ID != undefined ||
    //     this.data.EMAIL_ID != '' ||
    //     this.data.EMAIL_ID.trim() == '') && (this.emailpattern.test(this.data.EMAIL_ID))) {
    //     this.message.error('Email ID Must be Proper', '');
    //   this.isOk = false;
    //   }
    // }
    if (this.isOk) {
      // this.data.INSPECTOR_ID = Number(sessionStorage.getItem('userId'));
      this.isSpinning = true;
      if (
        this.data.OFFICE_NAME == undefined ||
        this.data.OFFICE_NAME == null ||
        this.data.OFFICE_NAME == '' ||
        this.data.OFFICE_NAME.trim() == ''
      ) {
        this.data.OFFICE_NAME = null;
      } else {
        this.data.OFFICE_NAME = this.data.OFFICE_NAME;
      }
      if (
        this.data.EMAIL_ID == undefined ||
        this.data.EMAIL_ID == null ||
        this.data.EMAIL_ID == '' ||
        this.data.EMAIL_ID.trim() == ''
      ) {
        this.data.EMAIL_ID = null;
      } else {
        this.data.EMAIL_ID = this.data.EMAIL_ID;
      }
      if (
        this.data.EMPLOYEE_CODE == undefined ||
        this.data.EMPLOYEE_CODE == null ||
        this.data.EMPLOYEE_CODE == '' ||
        this.data.EMPLOYEE_CODE.trim() == ''
      ) {
        this.data.EMPLOYEE_CODE = ' ';
      } else {
        this.data.EMPLOYEE_CODE = this.data.EMPLOYEE_CODE;
      }

      if (
        this.data.MOBILE_NO == undefined ||
        this.data.MOBILE_NO == null ||
        this.data.MOBILE_NO == '' ||
        this.data.MOBILE_NO.trim() == ''
      ) {
        this.data.MOBILE_NO = ' ';
      } else {
        this.data.MOBILE_NO = this.data.MOBILE_NO;
      }

      if (
        this.data.ADDRESS == undefined ||
        this.data.ADDRESS == null ||
        this.data.ADDRESS == '' ||
        this.data.ADDRESS.trim() == ''
      ) {
        this.data.ADDRESS = ' ';
      } else {
        this.data.ADDRESS = this.data.ADDRESS;
      }

      if (
        this.data.BENEFICIARY_TYPE == 'CS' &&
        (this.data.CGHS_CARD_NO != undefined ||
          this.data.CGHS_CARD_NO != null ||
          this.data.CGHS_CARD_NO != '')
      ) {
        this.data.CGHS_CARD_NO = null;
      } else {
        this.data.CGHS_CARD_NO = this.data.CGHS_CARD_NO;
      }
      if (
        this.data.BENEFICIARY_TYPE == 'CS' &&
        (this.data.CGHS_CARD_VALIDITY != undefined ||
          this.data.CGHS_CARD_VALIDITY != null ||
          this.data.CGHS_CARD_VALIDITY != '')
      ) {
        this.data.CGHS_CARD_VALIDITY = null;
      } else {
        this.data.CGHS_CARD_VALIDITY = this.data.CGHS_CARD_VALIDITY;
      }
      if (
        this.data.BENEFICIARY_TYPE == 'CG' &&
        (this.data.CGHS_CARD_VALIDITY == undefined ||
          this.data.CGHS_CARD_VALIDITY == null ||
          this.data.CGHS_CARD_VALIDITY == '')
      ) {
        this.data.CGHS_CARD_VALIDITY = null;
      } else {
        this.data.CGHS_CARD_VALIDITY = this.data.CGHS_CARD_VALIDITY;
      }
      this.data.CGHS_CARD_VALIDITY = this.datepipe.transform(
        this.data.CGHS_CARD_VALIDITY,
        'yyyy-MM-dd'
      );
      {
        if (this.data.ID) {
          //  ID: this.data.ID,

          // var data = {
          //   ID: this.empID,
          //   CLIENT_ID: 1,
          //   NAME: this.data.NAME,
          //   EMPLOYEE_CODE: this.data.EMPLOYEE_CODE,
          //   GRADE_PAY: this.data.GRADE_PAY,
          //   OFFICE_NAME: this.data.OFFICE_NAME,
          //   DESIGNATION: this.data.DESIGNATION,
          //   LOCATION: this.data.LOCATION,
          //   DDO_OF_THE_OFFICIAL: this.data.DDO_OF_THE_OFFICIAL,
          //   BENEFICIARY_TYPE: this.data.BENEFICIARY_TYPE,
          //   CGHS_CARD_NO: this.data.CGHS_CARD_NO,
          //   CGHS_CARD_VALIDITY: this.data.CGHS_CARD_VALIDITY,
          //   EMP_ID: this.empID,
          //   CLAIM_ID: this.claimID,
          //   MOBILE_NO: this.data.MOBILE_NO,
          //   EMAIL_ID: this.data.EMAIL_ID,
          //   STATUS: true,
          //   ADDRESS: this.data.ADDRESS,
          // };
          // if (
          //   (this.empID != undefined ||
          //     this.empID != null ||
          //     this.empID != '') &&
          //   this.empID == this.data.EMP_ID
          // ) {
          //   console.log('AAAAAAAAA');
          //   this.empAllDataForUpdate = {
          //     ID: this.empID,
          //     CLIENT_ID: 1,
          //     NAME: this.data.NAME,
          //     EMPLOYEE_CODE: this.data.EMPLOYEE_CODE,
          //     GRADE_PAY: this.data.GRADE_PAY,
          //     OFFICE_NAME: this.data.OFFICE_NAME,
          //     DESIGNATION: this.data.DESIGNATION,
          //     LOCATION: this.data.LOCATION,
          //     DDO_OF_THE_OFFICIAL: this.data.DDO_OF_THE_OFFICIAL,
          //     BENEFICIARY_TYPE: this.data.BENEFICIARY_TYPE,
          //     CGHS_CARD_NO: this.data.CGHS_CARD_NO,
          //     CGHS_CARD_VALIDITY: this.data.CGHS_CARD_VALIDITY,
          //     EMP_ID: this.empID,
          //     CLAIM_ID: this.claimID,
          //     MOBILE_NO: this.data.MOBILE_NO,
          //     EMAIL_ID: this.data.EMAIL_ID,
          //     STATUS: true,
          //     ADDRESS: this.data.ADDRESS,
          //     INSPECTOR_ID: Number(sessionStorage.getItem('userId')),
          //   };
          // } else {
          //   // this.claimID = null;
          //   console.log('BBBBBBB');
          //   console.log('BBBBBBB', this.claimID);
          //   this.empAllDataForUpdate = {
          //     ID: this.data.ID,
          //     CLIENT_ID: 1,
          //     NAME: this.data.NAME,
          //     EMPLOYEE_CODE: this.data.EMPLOYEE_CODE,
          //     GRADE_PAY: this.data.GRADE_PAY,
          //     OFFICE_NAME: this.data.OFFICE_NAME,
          //     DESIGNATION: this.data.DESIGNATION,
          //     LOCATION: this.data.LOCATION,
          //     DDO_OF_THE_OFFICIAL: this.data.DDO_OF_THE_OFFICIAL,
          //     BENEFICIARY_TYPE: this.data.BENEFICIARY_TYPE,
          //     CGHS_CARD_NO: this.data.CGHS_CARD_NO,
          //     CGHS_CARD_VALIDITY: this.data.CGHS_CARD_VALIDITY,
          //     EMP_ID: this.data.ID,
          //     CLAIM_ID: null,
          //     MOBILE_NO: this.data.MOBILE_NO,
          //     EMAIL_ID: this.data.EMAIL_ID,
          //     STATUS: true,
          //     ADDRESS: this.data.ADDRESS,
          //     INSPECTOR_ID: Number(sessionStorage.getItem('userId')),
          //   };
          // }
          this.data['CLAIM_ID'] = this.claimID;
          this.api
            .updateClaimWithoutHospitalData(this.data)
            .subscribe((successCode) => {
              if (successCode.code == '200') {
                this.message.success('Information Saved Successfully...', '');
                // this.api.updateclaimed(this.data).subscribe((successCode) => {
                // if (successCode.code == '200') {
                //   this.message.success(
                //     'Information Saved Successfully...',
                //     ''
                //   );
                this.empID = successCode.EMPLOYEE;
                this.claimID = successCode.CLAIM;
                // claimMasterPage.form.reset();
                // claimMasterPage.form.markAsPristine();
                // claimMasterPage.form.markAsUntouched();
                this.next();
                //     // if (!addNew) this.drawerClose();
                //     this.isSpinning = false;
                // } else {
                //   this.message.error('Information Has Not Saved...', '');
                //   this.isSpinning = false;
                // }
                // });
                this.isSpinning = false;
              } else if (successCode.code == '300') {
                this.message.error(
                  'Email ID or Mobile Number Already Registered...',
                  ''
                );
                this.isSpinning = false;
              } else {
                this.message.error('Information Has Not Saved...', '');
                this.isSpinning = false;
              }
            });
        } else {
          (this.data['CLIENT_ID'] = 1),
            (this.data['NAME'] = this.data.NAME),
            (this.data['EMPLOYEE_CODE'] = this.data.EMPLOYEE_CODE),
            (this.data['GRADE_PAY'] = this.data.GRADE_PAY),
            (this.data['OFFICE_NAME'] = this.data.OFFICE_NAME),
            (this.data['DESIGNATION'] = this.data.DESIGNATION),
            (this.data['LOCATION'] = this.data.LOCATION),
            (this.data['DDO_OF_THE_OFFICIAL'] = this.data.DDO_OF_THE_OFFICIAL),
            (this.data['BENEFICIARY_TYPE'] = this.data.BENEFICIARY_TYPE),
            (this.data['CGHS_CARD_NO'] = this.data.CGHS_CARD_NO),
            (this.data['CGHS_CARD_VALIDITY'] = this.data.CGHS_CARD_VALIDITY),
            (this.data['CLAIM_ID'] = null),
            (this.data['MOBILE_NO'] = this.data.MOBILE_NO),
            (this.data['EMAIL_ID'] = this.data.EMAIL_ID),
            (this.data['STATUS'] = true),
            (this.data['ADDRESS'] = this.data.ADDRESS),
            (this.data['INSPECTOR_ID'] = Number(
              sessionStorage.getItem('userId')
            )),
            this.api.createClaimNew(this.data).subscribe((successCode) => {
              if (successCode.code == '200') {
                this.message.success('Information Save Successfully...', '');
                this.empID = successCode.EMPLOYEE;
                this.claimID = successCode.CLAIM;
                // claimMasterPage.form.reset();
                // claimMasterPage.form.markAsPristine();
                // claimMasterPage.form.markAsUntouched();
                this.next();
                // if (!addNew)
                // this.drawerClose();
                // else {
                //   this.data = new ApplicantMaster();
                //   this.resetDrawer(claimMasterPage);
                //   // this.data.IMG_URL= '';

                //   this.api.getEmployeeMaster(0,0,'','desc','').subscribe (data =>{
                //     // if (data['count']==0){
                //     //   this.data.SEQUENCE_NUMBER=1;
                //     // }else
                //     // {
                //     //   this.data.SEQUENCE_NUMBER=data['data'][0]['SEQUENCE_NUMBER']+1;
                //     // }
                //   },err=>{
                //     console.log(err);
                //   })
                // }
                this.isSpinning = false;
              } else if (successCode.code == '300') {
                this.message.error(
                  'Email ID or Mobile Number Already Registered...',
                  ''
                );
                this.isSpinning = false;
              } else {
                this.message.error('Failed To Fill Information...', '');
                this.isSpinning = false;
              }
            });
        }
      }
    }
  }
  // empSave(addNew: boolean, claimMasterPage: NgForm): void {
  //   this.isSpinning = false;
  //   console.log('ID', this.data.ID);
  //   console.log('this.empID', this.empID);
  //   console.log('this.claimID', this.claimID);
  //   this.isOk = true;

  //   if (
  //     this.data.EMP_ID == undefined &&
  //     this.data.DESIGNATION == undefined &&
  //     // this.data.OFFICE_NAME == undefined &&
  //     // this.data.DDO_OF_THE_OFFICIAL == undefined &&
  //     this.data.EMPLOYEE_CODE == undefined &&
  //     this.data.GRADE_PAY == 0 &&
  //     this.data.BENEFICIARY_TYPE == undefined
  //   ) {
  //     this.isOk = false;
  //     this.message.error('Please Fill All The Required Fields ', '');
  //   } else if (
  //     this.data.EMP_ID == undefined ||
  //     this.data.EMP_ID == null ||
  //     this.data.EMP_ID == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Select Employee Name', '');
  //   } else if (
  //     this.data.DESIGNATION == undefined ||
  //     this.data.DESIGNATION == null ||
  //     this.data.DESIGNATION == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Enter Designation', '');
  //     // } else if (
  //     //   this.data.OFFICE_NAME == undefined ||
  //     //   this.data.OFFICE_NAME == null ||
  //     //   this.data.OFFICE_NAME == ''
  //     // ) {
  //     //   this.isOk = false;
  //     //   this.message.error(' Please Enter Office Name', '');
  //     // } else if (
  //     //   this.data.DDO_OF_THE_OFFICIAL == undefined ||
  //     //   this.data.DDO_OF_THE_OFFICIAL == null ||
  //     //   this.data.DDO_OF_THE_OFFICIAL == ''
  //     // ) {
  //     //   this.isOk = false;
  //     //   this.message.error(' Please Enter DDO Of The Official', '');
  //   } else if (
  //     this.data.EMPLOYEE_CODE == undefined ||
  //     this.data.EMPLOYEE_CODE == null ||
  //     this.data.EMPLOYEE_CODE == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Enter Employee Code', '');
  //   } else if (
  //     this.data.GRADE_PAY == undefined ||
  //     this.data.GRADE_PAY == null ||
  //     this.data.GRADE_PAY == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Enter Basic Pay ', '');
  //   } else if (
  //     this.data.MOBILE_NO == undefined ||
  //     this.data.MOBILE_NO == null ||
  //     this.data.MOBILE_NO == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Enter Mobile Number', '');
  //   } else if (
  //     this.data.BENEFICIARY_TYPE == undefined ||
  //     this.data.BENEFICIARY_TYPE == null ||
  //     this.data.BENEFICIARY_TYPE == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Select Beneficiary Type', '');
  //   } else if (
  //     this.data.BENEFICIARY_TYPE == 'CG' &&
  //     (this.data.CGHS_CARD_NO == undefined ||
  //       this.data.CGHS_CARD_NO == null ||
  //       this.data.CGHS_CARD_NO == '')
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Enter CGHS Card Number.', '');
  //     // } else if (
  //     //   this.data.BENEFICIARY_TYPE == 'CG' &&
  //     //   (this.data.CGHS_CARD_VALIDITY == undefined ||
  //     //     this.data.CGHS_CARD_VALIDITY == null)
  //     // ) {
  //     //   this.isOk = false;
  //     //   this.message.error('Please Enter CGHS Card Validity  ', '');
  //   }
  //   //  else if (
  //   //   this.data.MOBILE_NO != undefined ||
  //   //   this.data.MOBILE_NO != null ||
  //   //   this.data.MOBILE_NO != '' &&
  //   //   ( this.data.MOBILE_NO.length < 10))
  //   //  {
  //   //   this.isOk = false;
  //   //   this.message.error(' Please Enter Valid 10 Digit Mobile Number', '');
  //   // }
  //   // else
  //   // if (
  //   //   this.data.EMAIL_ID != null ||
  //   //   this.data.EMAIL_ID != undefined ||
  //   //   this.data.EMAIL_ID != '' ||
  //   //   this.data.EMAIL_ID.trim() == ''
  //   // )
  //   // {
  //   //   if ((this.data.EMAIL_ID != null ||
  //   //     this.data.EMAIL_ID != undefined ||
  //   //     this.data.EMAIL_ID != '' ||
  //   //     this.data.EMAIL_ID.trim() == '') && (this.emailpattern.test(this.data.EMAIL_ID))) {
  //   //     this.message.error('Email ID Must be Proper', '');
  //   //   this.isOk = false;
  //   //   }
  //   // }
  //   if (this.isOk) {
  //     // this.data.INSPECTOR_ID = Number(sessionStorage.getItem('userId'));
  //     this.isSpinning = true;
  //     if (
  //       this.data.OFFICE_NAME == undefined ||
  //       this.data.OFFICE_NAME == null ||
  //       this.data.OFFICE_NAME == '' ||
  //       this.data.OFFICE_NAME.trim() == ''
  //     ) {
  //       this.data.OFFICE_NAME = null;
  //     } else {
  //       this.data.OFFICE_NAME = this.data.OFFICE_NAME;
  //     }
  //     if (
  //       this.data.EMAIL_ID == undefined ||
  //       this.data.EMAIL_ID == null ||
  //       this.data.EMAIL_ID == '' ||
  //       this.data.EMAIL_ID.trim() == ''
  //     ) {
  //       this.data.EMAIL_ID = null;
  //     } else {
  //       this.data.EMAIL_ID = this.data.EMAIL_ID;
  //     }
  //     if (
  //       this.data.EMPLOYEE_CODE == undefined ||
  //       this.data.EMPLOYEE_CODE == null ||
  //       this.data.EMPLOYEE_CODE == '' ||
  //       this.data.EMPLOYEE_CODE.trim() == ''
  //     ) {
  //       this.data.EMPLOYEE_CODE = ' ';
  //     } else {
  //       this.data.EMPLOYEE_CODE = this.data.EMPLOYEE_CODE;
  //     }

  //     if (
  //       this.data.MOBILE_NO == undefined ||
  //       this.data.MOBILE_NO == null ||
  //       this.data.MOBILE_NO == '' ||
  //       this.data.MOBILE_NO.trim() == ''
  //     ) {
  //       this.data.MOBILE_NO = ' ';
  //     } else {
  //       this.data.MOBILE_NO = this.data.MOBILE_NO;
  //     }

  //     if (
  //       this.data.ADDRESS == undefined ||
  //       this.data.ADDRESS == null ||
  //       this.data.ADDRESS == '' ||
  //       this.data.ADDRESS.trim() == ''
  //     ) {
  //       this.data.ADDRESS = ' ';
  //     } else {
  //       this.data.ADDRESS = this.data.ADDRESS;
  //     }

  //     if (
  //       this.data.BENEFICIARY_TYPE == 'CS' &&
  //       (this.data.CGHS_CARD_NO != undefined ||
  //         this.data.CGHS_CARD_NO != null ||
  //         this.data.CGHS_CARD_NO != '')
  //     ) {
  //       this.data.CGHS_CARD_NO = null;
  //     } else {
  //       this.data.CGHS_CARD_NO = this.data.CGHS_CARD_NO;
  //     }
  //     if (
  //       this.data.BENEFICIARY_TYPE == 'CS' &&
  //       (this.data.CGHS_CARD_VALIDITY != undefined ||
  //         this.data.CGHS_CARD_VALIDITY != null ||
  //         this.data.CGHS_CARD_VALIDITY != '')
  //     ) {
  //       this.data.CGHS_CARD_VALIDITY = null;
  //     } else {
  //       this.data.CGHS_CARD_VALIDITY = this.data.CGHS_CARD_VALIDITY;
  //     }
  //     if (
  //       this.data.BENEFICIARY_TYPE == 'CG' &&
  //       (this.data.CGHS_CARD_VALIDITY == undefined ||
  //         this.data.CGHS_CARD_VALIDITY == null ||
  //         this.data.CGHS_CARD_VALIDITY == '')
  //     ) {
  //       this.data.CGHS_CARD_VALIDITY = null;
  //     } else {
  //       this.data.CGHS_CARD_VALIDITY = this.data.CGHS_CARD_VALIDITY;
  //     }
  //     this.data.CGHS_CARD_VALIDITY = this.datepipe.transform(
  //       this.data.CGHS_CARD_VALIDITY,
  //       'yyyy-MM-dd'
  //     );
  //     {
  //       if (this.data.ID) {
  //         console.log('ID2', this.data.ID);
  //         console.log('ID2', this.data.ID);

  //         // var data = {
  //         //   ID: this.empID,
  //         //   CLIENT_ID: 1,
  //         //   NAME: this.data.NAME,
  //         //   EMPLOYEE_CODE: this.data.EMPLOYEE_CODE,
  //         //   GRADE_PAY: this.data.GRADE_PAY,
  //         //   OFFICE_NAME: this.data.OFFICE_NAME,
  //         //   DESIGNATION: this.data.DESIGNATION,
  //         //   LOCATION: this.data.LOCATION,
  //         //   DDO_OF_THE_OFFICIAL: this.data.DDO_OF_THE_OFFICIAL,
  //         //   BENEFICIARY_TYPE: this.data.BENEFICIARY_TYPE,
  //         //   CGHS_CARD_NO: this.data.CGHS_CARD_NO,
  //         //   CGHS_CARD_VALIDITY: this.data.CGHS_CARD_VALIDITY,
  //         //   EMP_ID: this.empID,
  //         //   CLAIM_ID: this.claimID,
  //         //   MOBILE_NO: this.data.MOBILE_NO,
  //         //   EMAIL_ID: this.data.EMAIL_ID,
  //         //   STATUS: true,
  //         //   ADDRESS: this.data.ADDRESS,
  //         // };
  //         if (
  //           (this.empID != undefined ||
  //             this.empID != null ||
  //             this.empID != '') &&
  //           this.empID == this.data.EMP_ID
  //         ) {
  //           console.log('AAAAAAAAA');
  //           this.empAllDataForUpdate = {
  //             ID: this.empID,
  //             CLIENT_ID: 1,
  //             NAME: this.data.NAME,
  //             EMPLOYEE_CODE: this.data.EMPLOYEE_CODE,
  //             GRADE_PAY: this.data.GRADE_PAY,
  //             OFFICE_NAME: this.data.OFFICE_NAME,
  //             DESIGNATION: this.data.DESIGNATION,
  //             LOCATION: this.data.LOCATION,
  //             DDO_OF_THE_OFFICIAL: this.data.DDO_OF_THE_OFFICIAL,
  //             BENEFICIARY_TYPE: this.data.BENEFICIARY_TYPE,
  //             CGHS_CARD_NO: this.data.CGHS_CARD_NO,
  //             CGHS_CARD_VALIDITY: this.data.CGHS_CARD_VALIDITY,
  //             EMP_ID: this.empID,
  //             CLAIM_ID: this.claimID,
  //             MOBILE_NO: this.data.MOBILE_NO,
  //             EMAIL_ID: this.data.EMAIL_ID,
  //             STATUS: true,
  //             ADDRESS: this.data.ADDRESS,
  //             INSPECTOR_ID: Number(sessionStorage.getItem('userId')),
  //           };
  //         } else {
  //           // this.claimID = null;
  //           console.log('BBBBBBB');
  //           console.log('BBBBBBB', this.claimID);
  //           this.empAllDataForUpdate = {
  //             ID: this.data.ID,
  //             CLIENT_ID: 1,
  //             NAME: this.data.NAME,
  //             EMPLOYEE_CODE: this.data.EMPLOYEE_CODE,
  //             GRADE_PAY: this.data.GRADE_PAY,
  //             OFFICE_NAME: this.data.OFFICE_NAME,
  //             DESIGNATION: this.data.DESIGNATION,
  //             LOCATION: this.data.LOCATION,
  //             DDO_OF_THE_OFFICIAL: this.data.DDO_OF_THE_OFFICIAL,
  //             BENEFICIARY_TYPE: this.data.BENEFICIARY_TYPE,
  //             CGHS_CARD_NO: this.data.CGHS_CARD_NO,
  //             CGHS_CARD_VALIDITY: this.data.CGHS_CARD_VALIDITY,
  //             EMP_ID: this.data.ID,
  //             CLAIM_ID: null,
  //             MOBILE_NO: this.data.MOBILE_NO,
  //             EMAIL_ID: this.data.EMAIL_ID,
  //             STATUS: true,
  //             ADDRESS: this.data.ADDRESS,
  //             INSPECTOR_ID: Number(sessionStorage.getItem('userId')),
  //           };
  //         }
  //         this.data['CLAIM_ID'] = this.claimID;
  //         this.api
  //           .updateEmployeeMasterFromClaim(this.empAllDataForUpdate)
  //           .subscribe((successCode) => {
  //             if (successCode.code == '200') {
  //               this.message.success('Information Saved Successfully...', '');
  //               // this.api.updateclaimed(this.data).subscribe((successCode) => {
  //               // if (successCode.code == '200') {
  //               //   this.message.success(
  //               //     'Information Saved Successfully...',
  //               //     ''
  //               //   );
  //               this.empID = successCode.EMPLOYEE;
  //               this.claimID = successCode.CLAIM;
  //               // claimMasterPage.form.reset();
  //               // claimMasterPage.form.markAsPristine();
  //               // claimMasterPage.form.markAsUntouched();
  //               this.next();
  //               //     // if (!addNew) this.drawerClose();
  //               //     this.isSpinning = false;
  //               // } else {
  //               //   this.message.error('Information Has Not Saved...', '');
  //               //   this.isSpinning = false;
  //               // }
  //               // });
  //               this.isSpinning = false;
  //             } else if (successCode.code == '300') {
  //               this.message.error(
  //                 'Email ID or Mobile Number Already Registered...',
  //                 ''
  //               );
  //               this.isSpinning = false;
  //             } else {
  //               this.message.error('Information Has Not Saved...', '');
  //               this.isSpinning = false;
  //             }
  //           });
  //       } else {
  //         this.api.createEmployeeMaster(this.data).subscribe((successCode) => {
  //           if (successCode.code == '200') {
  //             this.message.success('Information Save Successfully...', '');
  //             this.empID = successCode.EMPLOYEE;
  //             this.claimID = successCode.CLAIM;
  //             console.log('this.claimID', this.empID);
  //             console.log('this.claimID', this.claimID);
  //             // claimMasterPage.form.reset();
  //             // claimMasterPage.form.markAsPristine();
  //             // claimMasterPage.form.markAsUntouched();
  //             this.next();
  //             // if (!addNew)
  //             // this.drawerClose();
  //             // else {
  //             //   this.data = new ApplicantMaster();
  //             //   this.resetDrawer(claimMasterPage);
  //             //   // this.data.IMG_URL= '';

  //             //   this.api.getEmployeeMaster(0,0,'','desc','').subscribe (data =>{
  //             //     // if (data['count']==0){
  //             //     //   this.data.SEQUENCE_NUMBER=1;
  //             //     // }else
  //             //     // {
  //             //     //   this.data.SEQUENCE_NUMBER=data['data'][0]['SEQUENCE_NUMBER']+1;
  //             //     // }
  //             //   },err=>{
  //             //     console.log(err);
  //             //   })
  //             // }
  //             this.isSpinning = false;
  //           } else if (successCode.code == '300') {
  //             this.message.error(
  //               'Email ID or Mobile Number Already Registered...',
  //               ''
  //             );
  //             this.isSpinning = false;
  //           } else {
  //             this.message.error('Failed To Fill Information...', '');
  //             this.isSpinning = false;
  //           }
  //         });
  //       }
  //     }
  //   }
  // }

  claimSave(addNew: boolean, claimMasterPage: NgForm): void {
    // console.log('this.hospitalMapList', this.hospitalMapList);
    // console.log('this.hospitalMapListlength', this.hospitalMapList.length);

    this.data2.EMP_ID = this.empID;
    this.data2.ID = this.claimID;
    this.data2.hospitalData = this.hospitalMapList;
    this.data2.INSPECTOR_ID = Number(sessionStorage.getItem('userId'));
    for (var i = 0; this.hospitalMapList.length > i; i++) {
      this.hospitalMapList[i]['ID'] = undefined;
      this.hospitalMapList[i]['CLAIM_ID'] = undefined;
    }
    this.data2.hospitalData = this.hospitalMapList;

    // console.log('this.claimID2', this.data2.INSPECTOR_ID);
    // console.log('this.claimID2', sessionStorage.getItem('userId'));

    this.isSpinning = false;
    this.isOk = true;

    if (
      this.data2.RELATION_WITH_PATIENT == undefined &&
      this.data2.PATIENT_NAME == undefined &&
      this.data2.PATIENT_CGHS_BENEFICIERY_NO == undefined &&
      this.data2.NATURE_OF_TREATMENT == undefined &&
      this.data2.hospitalData.length == 0
    ) {
      this.isOk = false;
      this.message.error('Please Fill All The Required Fields ', '');
    } else if (
      this.data2.RELATION_WITH_PATIENT == undefined ||
      this.data2.RELATION_WITH_PATIENT == null ||
      this.data2.RELATION_WITH_PATIENT == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Select Relationship with Applicant', '');
    } else if (
      this.data2.PATIENT_NAME == undefined ||
      this.data2.PATIENT_NAME == null ||
      this.data2.PATIENT_NAME == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Patient Name', '');
    } else if (
      this.data2.BENEFICIARY_TYPE == 'CG' &&
      (this.data2.PATIENT_CGHS_BENEFICIERY_NO == undefined ||
        this.data2.PATIENT_CGHS_BENEFICIERY_NO == null ||
        this.data2.PATIENT_CGHS_BENEFICIERY_NO == '')
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Patient CGHS Beneficiary Number', '');
    } else if (
      this.data2.NATURE_OF_TREATMENT == undefined ||
      this.data2.NATURE_OF_TREATMENT == null ||
      this.data2.NATURE_OF_TREATMENT == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Nature Of Treatment/Test', '');
    } else if (
      this.data2.IS_ADVANCE_TAKEN == false &&
      (this.data2.TREATMENT_START_DATE == undefined ||
        this.data2.TREATMENT_START_DATE == null)
    ) {
      this.isOk = false;
      this.message.error(' Please Select Treatment Start Date', '');
    } else if (
      this.data2.IS_ADVANCE_TAKEN == false &&
      (this.data2.TREATMENT_END_DATE == undefined ||
        this.data2.TREATMENT_END_DATE == null)
    ) {
      this.isOk = false;
      this.message.error(' Please Select Treatment End Date', '');
    } else if (
      this.data2.IS_ADVANCE_TAKEN == false &&
      (this.data2.BILL_FILIING_DATE == undefined ||
        this.data2.BILL_FILIING_DATE == null)
    ) {
      this.isOk = false;
      this.message.error(' Please Select Bill Filling Date', '');
      // } else if (
      //   this.data2.IS_ADVANCE_TAKEN == true &&
      //   (this.data2.ADVANCE_AMOUNT == undefined ||
      //     this.data2.ADVANCE_AMOUNT == null ||
      //     this.data2.ADVANCE_AMOUNT == '')
      // ) {
      //   this.isOk = false;
      //   this.message.error(' Please Enter Advance Amount.', '');
    } else if (
      this.data2.IS_ADVANCE_TAKEN == true &&
      (this.data2.ADVANCE_TAKEN_DATE == undefined ||
        this.data2.ADVANCE_TAKEN_DATE == null)
    ) {
      this.isOk = false;
      this.message.error('Please Select Date Of Advance Taken.  ', '');
    } else if (this.data2.hospitalData.length == 0) {
      this.isOk = false;
      this.message.error('Please Add Hospital ', '');
      // }
      //  else if (
      //   this.data2.HOSPITAL_TYPE == undefined ||
      //   this.data2.HOSPITAL_TYPE == null ||
      //   this.data2.HOSPITAL_TYPE == ''
      // ) {
      //   this.isOk = false;
      //   this.message.error(' Please Select Hospital Type', '');
      // } else if (
      //   this.data2.HOSPITAL_TYPE == 'E' &&
      //   (this.data2.HOSPITAL_ID == undefined ||
      //     this.data2.HOSPITAL_ID == null ||
      //     this.data2.HOSPITAL_ID == '')
      // ) {
      //   this.isOk = false;
      //   this.message.error(' Please Select Hospital Name.', '');
      // } else if (
      //   this.data2.HOSPITAL_TYPE == 'E' &&
      //   (this.data2.HOSPITAL_ADDRESS == undefined ||
      //     this.data2.HOSPITAL_ADDRESS == null ||
      //     this.data2.HOSPITAL_ADDRESS == '')
      // ) {
      //   this.isOk = false;
      //   this.message.error(' Please Enter Hospital Address.', '');
      // } else if (
      //   (this.data2.HOSPITAL_TYPE == 'NE' || this.data2.HOSPITAL_TYPE == 'G') &&
      //   (this.data2.HOSPITAL_NAME == undefined ||
      //     this.data2.HOSPITAL_NAME == null ||
      //     this.data2.HOSPITAL_NAME == '')
      // ) {
      //   this.isOk = false;
      //   this.message.error('Please Enter Hospital Name.  ', '');
      // } else if (
      //   (this.data2.HOSPITAL_TYPE == 'NE' || this.data2.HOSPITAL_TYPE == 'G') &&
      //   (this.data2.HOSPITAL_ADDRESS == undefined ||
      //     this.data2.HOSPITAL_ADDRESS == null ||
      //     this.data2.HOSPITAL_ADDRESS == '')
      // ) {
      //   this.isOk = false;
      //   this.message.error('Please Enter Hospital Address. ', '');
      // } else if (
      //   (this.data2.HOSPITAL_TYPE == 'NE' || this.data2.HOSPITAL_TYPE == 'G') &&
      //   (this.data2.CLAIM_ACCREDITATION == undefined ||
      //     this.data2.CLAIM_ACCREDITATION == null ||
      //     this.data2.CLAIM_ACCREDITATION == '')
      // ) {
      //   this.isOk = false;
      //   this.message.error('Please Select Accredition.  ', '');
    }
    if (this.isOk) {
      if (this.data2.BENEFICIARY_TYPE == 'CG') {
        this.data2.PATIENT_CGHS_BENEFICIERY_NO =
          this.data2.PATIENT_CGHS_BENEFICIERY_NO;
      } else {
        this.data2.PATIENT_CGHS_BENEFICIERY_NO = null;
      }
      this.data2['CLAIM_ID'] = this.claimID;
      this.isSpinning = true;
      if (
        this.data2.TREATMENT_START_DATE != undefined &&
        this.data2.TREATMENT_START_DATE != null &&
        this.data2.TREATMENT_START_DATE != ''
      ) {
        this.data2.TREATMENT_START_DATE = this.datepipe.transform(
          this.data2.TREATMENT_START_DATE,
          'yyyy-MM-dd'
        );
      } else {
        this.data2.TREATMENT_START_DATE = null;
      }
      if (
        this.data2.BILL_FILIING_DATE != undefined &&
        this.data2.BILL_FILIING_DATE != null &&
        this.data2.BILL_FILIING_DATE != ''
      ) {
        this.data2.BILL_FILIING_DATE = this.datepipe.transform(
          this.data2.BILL_FILIING_DATE,
          'yyyy-MM-dd'
        );
      } else {
        this.data2.BILL_FILIING_DATE = null;
      }
      if (
        this.data2.TREATMENT_END_DATE != undefined &&
        this.data2.TREATMENT_END_DATE != null &&
        this.data2.TREATMENT_END_DATE != ''
      ) {
        this.data2.TREATMENT_END_DATE = this.datepipe.transform(
          this.data2.TREATMENT_END_DATE,
          'yyyy-MM-dd'
        );
      } else {
        this.data2.TREATMENT_END_DATE = null;
      }
      this.data2.ADVANCE_TAKEN_DATE = this.datepipe.transform(
        this.data2.ADVANCE_TAKEN_DATE,
        'yyyy-MM-dd'
      );

      if (
        this.data2.TREATMENT_END_DATE != undefined &&
        this.data2.TREATMENT_END_DATE != null &&
        this.data2.BILL_FILIING_DATE != undefined &&
        this.data2.BILL_FILIING_DATE != null
      ) {
        var date1: any = new Date(this.data2.TREATMENT_END_DATE);
        var date2: any = new Date(this.data2.BILL_FILIING_DATE);
        this.diffDays = Math.floor((date2 - date1) / (1000 * 60 * 60 * 24)) + 1;
        console.log('this.diffDayssdsd', this.diffDays);
      }
      // this.data2.DELAY_CONDELENCE_DIFFERENCE = this.diffDays;
      var advance;
      if (this.data2.IS_ADVANCE_TAKEN == true) {
        advance = 1;
        console.log('this.advance', advance);
      } else {
        advance = 0;
        console.log('advance', advance);
      }
      if (advance == 1 && this.diffDays <= 30) {
        this.data3.BILL_FILLED_INTIME = true;
      } else if (advance == 1 && this.diffDays > 30) {
        this.data3.BILL_FILLED_INTIME = false;
      } else if (advance == 0 && this.diffDays > 182) {
        this.data3.BILL_FILLED_INTIME = false;
      } else {
        this.data3.BILL_FILLED_INTIME = true;
      }

      if (this.data2.HOSPITAL_TYPE == 'E') {
        this.data2.HOSPITAL_NAME = '';
      } else {
        this.data2.HOSPITAL_NAME = this.data2.HOSPITAL_NAME;
      }

      // if(this.data2.HOSPITAL_TYPE == 'E' && (this.data2.HOSPITAL_ADDRESS != undefined || this.data2.HOSPITAL_ADDRESS != null
      //   || this.data2.HOSPITAL_ADDRESS != '' || this.data2.HOSPITAL_ADDRESS.trim() != '' )){
      //     this.data2.HOSPITAL_ADDRESS = ''
      //   } else{
      //     this.data2.HOSPITAL_ADDRESS = this.data2.HOSPITAL_ADDRESS
      //   }

      if (
        this.data2.HOSPITAL_TYPE == 'E' &&
        (this.data2.CLAIM_ACCREDITATION != undefined ||
          this.data2.CLAIM_ACCREDITATION != null ||
          this.data2.CLAIM_ACCREDITATION != '' ||
          this.data2.CLAIM_ACCREDITATION.trim() != '')
      ) {
        this.data2.CLAIM_ACCREDITATION = '';
      } else {
        this.data2.CLAIM_ACCREDITATION = this.data2.CLAIM_ACCREDITATION;
      }

      if (
        (this.data2.HOSPITAL_TYPE == 'NE' || this.data2.HOSPITAL_TYPE == 'G') &&
        (this.data2.HOSPITAL_ID != undefined ||
          this.data2.HOSPITAL_ID != null ||
          this.data2.HOSPITAL_ID != '' ||
          this.data2.HOSPITAL_ID.trim() != '')
      ) {
        this.data2.HOSPITAL_ID = '';
      } else {
        this.data2.HOSPITAL_ID = this.data2.HOSPITAL_ID;
      }

      {
        if (this.data2.ID) {
          if (
            (this.currentStageID != undefined ||
              this.currentStageID != null ||
              this.currentStageID != '') &&
            this.currentStageID > 3
          ) {
            this.data2.CURRENT_STAGE_ID = this.data2.CURRENT_STAGE_ID;
          } else {
            this.data2.CURRENT_STAGE_ID = 3;
          }
          this.api.updateclaimed(this.data2).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information Changed Successfully...', '');
              this.billInTime();
              this.empanelledHospital();
              this.next();
              // if (!addNew) this.drawerClose();
              this.isSpinning = false;
            } else {
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
        } else {
          this.api.createclaimed(this.data2).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information Save Successfully...', '');
              // this.claimID = successCode.EMPLOYEE
              // console.log('this.claimID', this.claimID);
              this.billInTime();
              this.empanelledHospital();
              this.next();

              // if (!addNew) this.drawerClose();
              // else {
              //   // this.data = new ApplicantMaster();
              //   this.resetDrawer(claimMasterPage);
              //   // this.data.IMG_URL= '';

              //   // this.api.getEmployeeMaster(0,0,'','desc','').subscribe (data =>{
              //   //   // if (data['count']==0){
              //   //   //   this.data.SEQUENCE_NUMBER=1;
              //   //   // }else
              //   //   // {
              //   //   //   this.data.SEQUENCE_NUMBER=data['data'][0]['SEQUENCE_NUMBER']+1;
              //   //   // }
              //   // },err=>{
              //   //   console.log(err);
              //   // })
              // }
              this.isSpinning = false;
            } else {
              this.message.error('Failed To Fill Information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
  }

  queSave(addNew: boolean, claimMasterPage: NgForm): void {
    this.data3.EMP_ID = this.empID;
    this.data3.CLAIM_ID = this.claimID;

    // console.log('this.claimID3', this.empID);
    // console.log('this.claimID3', this.claimID);
    // this.data.NAME = this.name
    // if(this.current == 0){
    //   this.current = 1;
    // }else if(this.current == 1){
    // this.current = 2;
    // } else if(this.current == 2){
    // this.current = 3;
    // }
    this.isSpinning = false;
    this.isOk = true;

    //   if(
    //   this.data.NAME.trim() == '' &&
    //   this.data.LOCATION.trim() == '' &&
    //   this.data.DDO_OF_THE_OFFICIAL.trim() == '' &&
    //   this.data.DESIGNATION.trim() == '' &&
    //   this.data.OFFICE_NAME.trim() == '' &&
    //   this.data.EMPLOYEE_CODE == 0 &&
    //   this.data.GRADE_PAY == 0

    // )

    //   {
    //     this.isOk=false;
    //     this.message.error("Please Fill All The Required Fields " ,"")
    //   }

    if (
      this.data3.REFERENCE_FORM_CGHS_AMA_TAKEN == true &&
      (this.data3.CGHS_AMA_REFERENCE_DATE == undefined ||
        this.data3.CGHS_AMA_REFERENCE_DATE == null ||
        this.data3.CGHS_AMA_REFERENCE_DATE.length == 0)
    ) {
      this.isOk = false;
      this.message.error(' Please Add CGHS/AMAs Reference Start Date', '');
      // } else if (
      //   this.data3.REFERENCE_FORM_CGHS_AMA_TAKEN == true &&
      //   (this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined ||
      //     this.data3.CGHS_AMA_REFERENCE_END_DATE == null ||
      //     this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0)
      // ) {
      //   this.isOk = false;
      //   this.message.error(' Please Add CGHS/AMAs Reference End Date', '');
      // } else if (
      //   this.data3.REFERENCE_FORM_CGHS_AMA_TAKEN == true &&
      //   this.data3.CGHS_AMA_REFERENCE_DATE.length !=
      //     this.data3.CGHS_AMA_REFERENCE_END_DATE.length
      // ) {
      //   this.isOk = false;
      //   this.message.error(
      //     ' Please Add CGHS/AMAs Reference Start Date & End Date Equal',
      //     ''
      //   );
    } else if (
      this.data3.REFERENCE_FORM_CGHS_AMA_TAKEN == true &&
      this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
    ) {
      this.isOk = false;
      this.message.error(' Please Add CGHS/AMAs Reference Start Date', '');
    }
    // if (this.data3.REFERENCE_FORM_CGHS_AMA_TAKEN == true && (this.data3.CGHS_AMA_REFERENCE_NO == undefined || this.data3.CGHS_AMA_REFERENCE_NO == null)) {
    //   this.isOk = false;
    //   this.message.error('Please Enter CGHS/AMAs Reference Number ', '');
    // }
    else if (
      this.data3.IS_PERMISSION_TAKEN_FROM_HOD == true &&
      (this.data3.HOD_PERMISSION_DATE == undefined ||
        this.data3.HOD_PERMISSION_DATE == null)
    ) {
      this.isOk = false;
      this.message.error(' Please Select HOD Permission Date', '');
    } else if (
      this.data3.EXPO_FACTO_PERMISSION == true &&
      (this.data3.EXPO_FACTO_DATE == undefined ||
        this.data3.EXPO_FACTO_DATE == null)
    ) {
      this.isOk = false;
      this.message.error('Please Select Ex-Post Facto Referance Date ', '');
    }
    if (
      this.data3.BILL_FILLED_INTIME == false &&
      (this.data3.DELAY_CONDOLENCE_DATE == undefined ||
        this.data3.DELAY_CONDOLENCE_DATE == null)
    ) {
      this.isOk = false;
      this.message.error(' Please Select Delay Condolence Date', '');
    }
    // else if (this.data3.BILL_FILLED_INTIME == false && (this.data3.DELAY_CONDOLENCE_NUMBER == undefined || this.data3.DELAY_CONDOLENCE_NUMBER == null)) {
    //   this.isOk = false;
    //   this.message.error('Please Enter Delay Condolence Number', '');
    // }
    else if (
      this.data3.REFERENCE_FORM_CGHS_AMA_TAKEN == true &&
      (this.data3.CGHS_AMA_REFERENCE_DATE != undefined ||
        this.data3.CGHS_AMA_REFERENCE_DATE != null ||
        this.data3.CGHS_AMA_REFERENCE_DATE.length != 0)
    ) {
      // var date1: any = new Date(
      //   this.data3.CGHS_AMA_REFERENCE_DATE[
      //     this.data3.CGHS_AMA_REFERENCE_DATE.length - 1
      //   ]
      // );

      ///////////////////////////////////Date Difference ///////////////////
      // var date1: any = new Date(
      //   Math.max.apply(
      //     null,
      //     this.data3.CGHS_AMA_REFERENCE_DATE.map(function (e) {
      //       console.log('e', e);
      //       return new Date(e);
      //     })
      //   )
      // );
      // console.log('date1',this.data3.CGHS_AMA_REFERENCE_DATE[this.data3.CGHS_AMA_REFERENCE_DATE.length - 1])
      // console.log('date11',date1)
      var date2: any = new Date(this.data2.BILL_FILIING_DATE);
      // console.log('date2',date2)

      var expoDiffDays = Math.floor(
        (date2 - this.date1) / (1000 * 60 * 60 * 24)
      );
      console.log('expoDiffDays', expoDiffDays);
      ///////////////////////////////////Date Difference ///////////////////

      // if (expoDiffDays > 30 && this.data3.EXPO_FACTO_PERMISSION == false) {
      //   this.isOk = false;
      //   this.message.error(' Please Take Ex-Post Facto Permission ', '');
      // } else {
      // }
    }

    if (this.isOk) {
      this.isSpinning = true;

      // this.data3.CGHS_AMA_REFERENCE_DATE = this.datepipe.transform(
      //   this.data3.CGHS_AMA_REFERENCE_DATE,
      //   'yyyy-MM-dd'
      // );
      this.data3.DELAY_CONDOLENCE_DATE = this.datepipe.transform(
        this.data3.DELAY_CONDOLENCE_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPOST_FACTO_PERMISSION_DATE = this.datepipe.transform(
        this.data3.EXPOST_FACTO_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPO_FACTO_DATE = this.datepipe.transform(
        this.data3.EXPO_FACTO_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      // this.data2.TREATMENT_END_DATE = this.datepipe.transform(this.data2.TREATMENT_END_DATE, 'yyyy-MM-dd');
      // this.data2.BILL_FILIING_DATE = this.datepipe.transform(this.data2.BILL_FILIING_DATE, 'yyyy-MM-dd');
      // if (
      //   this.data3.REFERENCE_FORM_CGHS_AMA_TAKEN == false &&
      //   (this.data3.CGHS_AMA_REFERENCE_DATE != undefined ||
      //     this.data3.CGHS_AMA_REFERENCE_DATE != null ||
      //     this.data3.CGHS_AMA_REFERENCE_DATE != '' ||
      //     this.data3.CGHS_AMA_REFERENCE_DATE.trim() != '')
      // ) {
      //   this.data3.CGHS_AMA_REFERENCE_DATE = null;
      // } else {
      //   this.data3.CGHS_AMA_REFERENCE_DATE = this.data3.CGHS_AMA_REFERENCE_DATE;
      // }
      // if (
      //   this.data3.REFERENCE_FORM_CGHS_AMA_TAKEN == false &&
      //   (this.data3.CGHS_AMA_REFERENCE_NO != undefined ||
      //     this.data3.CGHS_AMA_REFERENCE_NO != null ||
      //     this.data3.CGHS_AMA_REFERENCE_NO != '' ||
      //     this.data3.CGHS_AMA_REFERENCE_NO.trim() != '')
      // ) {
      //   this.data3.CGHS_AMA_REFERENCE_NO = ' ';
      // } else {
      //   this.data3.CGHS_AMA_REFERENCE_NO = this.data3.CGHS_AMA_REFERENCE_NO;
      // }

      // if (
      //   this.data3.IS_PERMISSION_TAKEN_FROM_HOD == false &&
      //   (this.data3.HOD_PERMISSION_DATE != undefined ||
      //     this.data3.HOD_PERMISSION_DATE != null)
      // ) {
      //   this.data3.HOD_PERMISSION_DATE = null;
      // } else {
      //   this.data3.HOD_PERMISSION_DATE = this.data3.HOD_PERMISSION_DATE;
      // }
      // if (
      //   this.data3.IS_PERMISSION_TAKEN_FROM_HOD == false &&
      //   (this.data3.HOD_PERMISSION_NO != undefined ||
      //     this.data3.HOD_PERMISSION_NO != null)
      // ) {
      //   this.data3.HOD_PERMISSION_NO = '';
      // } else {
      //   this.data3.HOD_PERMISSION_NO = this.data3.HOD_PERMISSION_NO;
      // }

      // if (
      //   this.data3.EXPO_FACTO_PERMISSION == false &&
      //   (this.data3.EXPO_FACTO_DATE != undefined ||
      //     this.data3.EXPO_FACTO_DATE != null)
      // ) {
      //   this.data3.EXPO_FACTO_DATE = null;
      // } else {
      //   this.data3.EXPO_FACTO_DATE = this.data3.EXPO_FACTO_DATE;
      // }
      // if (
      //   this.data3.EXPO_FACTO_PERMISSION == false &&
      //   (this.data3.EXPO_FACTO_REFERENCE_NO != undefined ||
      //     this.data3.EXPO_FACTO_REFERENCE_NO != null)
      // ) {
      //   this.data3.EXPO_FACTO_REFERENCE_NO = '';
      // } else {
      //   this.data3.EXPO_FACTO_REFERENCE_NO = this.data3.EXPO_FACTO_REFERENCE_NO;
      // }
      if (
        this.data3.HOD_ID != undefined &&
        this.data3.HOD_ID != null &&
        this.data3.HOD_ID.length != 0
      ) {
        this.data3.HOD_ID = this.data3.HOD_ID.toString();
      } else {
        this.data3.HOD_ID = null;
      }
      if (
        this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE == null &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
      ) {
        this.data3.CGHS_AMA_REFERENCE_END_DATE = '';
      } else {
        this.data3.CGHS_AMA_REFERENCE_END_DATE =
          this.data3.CGHS_AMA_REFERENCE_END_DATE;
      }

      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_DATE != undefined ||
          this.data3.DELAY_CONDOLENCE_DATE != null)
      ) {
        this.data3.DELAY_CONDOLENCE_DATE = this.data3.DELAY_CONDOLENCE_DATE;
      } else {
        this.data3.DELAY_CONDOLENCE_DATE = null;
      }
      if (
        this.data3.TREATEMENT_PERMISSION_START_DATE == undefined ||
        this.data3.TREATEMENT_PERMISSION_START_DATE == null ||
        this.data3.TREATEMENT_PERMISSION_START_DATE == ''
      ) {
        this.data3.TREATEMENT_PERMISSION_START_DATE = null;
      } else {
        this.data3.TREATEMENT_PERMISSION_START_DATE = this.datepipe.transform(
          this.data3.TREATEMENT_PERMISSION_START_DATE,
          'yyyy-MM-dd'
        );
      }
      if (
        this.data3.TREATEMENT_PERMISSION_END_DATE == undefined ||
        this.data3.TREATEMENT_PERMISSION_END_DATE == null ||
        this.data3.TREATEMENT_PERMISSION_END_DATE == ''
      ) {
        this.data3.TREATEMENT_PERMISSION_END_DATE = null;
      } else {
        this.data3.TREATEMENT_PERMISSION_END_DATE = this.datepipe.transform(
          this.data3.TREATEMENT_PERMISSION_END_DATE,
          'yyyy-MM-dd'
        );
      }
      if (
        this.data3.CLAIM_SUBMITTED_DATE == undefined ||
        this.data3.CLAIM_SUBMITTED_DATE == null ||
        this.data3.CLAIM_SUBMITTED_DATE == ''
      ) {
        this.data3.CLAIM_SUBMITTED_DATE = null;
      } else {
        this.data3.CLAIM_SUBMITTED_DATE = this.datepipe.transform(
          this.data3.CLAIM_SUBMITTED_DATE,
          'yyyy-MM-dd'
        );
      }
      if (
        this.data3.BILL_SUBMISSION_DATE == undefined ||
        this.data3.BILL_SUBMISSION_DATE == null ||
        this.data3.BILL_SUBMISSION_DATE == ''
      ) {
        this.data3.BILL_SUBMISSION_DATE = null;
      } else {
        this.data3.BILL_SUBMISSION_DATE = this.datepipe.transform(
          this.data3.BILL_SUBMISSION_DATE,
          'yyyy-MM-dd'
        );
      }
      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_NUMBER != undefined ||
          this.data3.DELAY_CONDOLENCE_NUMBER != null)
      ) {
        this.data3.DELAY_CONDOLENCE_NUMBER = this.data3.DELAY_CONDOLENCE_NUMBER;
      } else {
        this.data3.DELAY_CONDOLENCE_NUMBER = '';
      }
      {
        if (this.data3.ID) {
          this.api.updateQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information Changed Successfully...', '');
              this.api
                .getAllQuestions(0, 0, '', '', ' AND CLAIM_ID =' + this.claimID)
                .subscribe(
                  (data) => {
                    if (data['code'] == 200) {
                      if (data['data'].length == 0) {
                        this.isSpinning = false;
                      } else {
                        this.data3 = data['data'][0];
                        this.emergencyTaken =
                          data['data'][0]['EMERGENCY_TREATEMENT'];
                        this.drCertificate =
                          data['data'][0]['DOCTOR_EMERGENCY_CERTIFICATE_TAKEN'];
                        this.empanelHospital =
                          data['data'][0]['IS_HOSPITAL_EMPLANELLED'];
                        this.expoFacto =
                          data['data'][0]['EXPO_FACTO_PERMISSION'];
                        this.referanceTaken =
                          data['data'][0]['REFERENCE_FORM_CGHS_AMA_TAKEN'];
                        this.hodPermission =
                          data['data'][0]['IS_PERMISSION_TAKEN_FROM_HOD'];
                        if (
                          this.data3.CGHS_AMA_REFERENCE_DATE == undefined &&
                          this.data3.CGHS_AMA_REFERENCE_DATE == null &&
                          this.data3.CGHS_AMA_REFERENCE_DATE == '' &&
                          this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
                        ) {
                          this.data3.CGHS_AMA_REFERENCE_DATE = [];
                        } else {
                          this.data3.CGHS_AMA_REFERENCE_DATE =
                            this.data3.CGHS_AMA_REFERENCE_DATE.split(',');
                        }
                        if (
                          this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined &&
                          this.data3.CGHS_AMA_REFERENCE_END_DATE == null &&
                          this.data3.CGHS_AMA_REFERENCE_END_DATE == '' &&
                          this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
                        ) {
                          this.data3.CGHS_AMA_REFERENCE_END_DATE = [];
                        } else {
                          this.data3.CGHS_AMA_REFERENCE_END_DATE =
                            this.data3.CGHS_AMA_REFERENCE_END_DATE.split(',');
                        }
                        this.isSpinning = false;
                      }
                    } else {
                      this.message.error('Something Went Wrong', '');
                      this.isSpinning = false;
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              this.next();
              // this.claimConfirmation();
              // if (!addNew) this.drawerClose();
              // this.current = 3
              this.isSpinning = false;
            } else {
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
        } else {
          this.api.createQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information Save Successfully...', '');
              // this.claimID = successCode.EMPLOYEE
              // console.log('this.claimID', this.claimID);
              // this.claimConfirmation();
              this.current = 3;

              // if (!addNew) this.drawerClose();
              // else {
              this.data4 = new CheckList();
              //   this.resetDrawer(claimMasterPage);
              //   // this.data.IMG_URL= '';

              //   // this.api.getEmployeeMaster(0,0,'','desc','').subscribe (data =>{
              //   //   // if (data['count']==0){
              //   //   //   this.data.SEQUENCE_NUMBER=1;
              //   //   // }else
              //   //   // {
              //   //   //   this.data.SEQUENCE_NUMBER=data['data'][0]['SEQUENCE_NUMBER']+1;
              //   //   // }
              //   // },err=>{
              //   //   console.log(err);
              //   // })
              // }
              this.isSpinning = false;
            } else {
              this.message.error('Failed To Fill Information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
  }
  checkSave(addNew: boolean, claimMasterPage: NgForm): void {
    this.data4.EMP_ID = this.empID;
    this.data4.CLAIM_ID = this.claimID;
    this.isSpinning = false;
    this.isOk = true;

    //   if(
    //   this.data.NAME.trim() == '' &&
    //   this.data.LOCATION.trim() == '' &&
    //   this.data.DDO_OF_THE_OFFICIAL.trim() == '' &&
    //   this.data.DESIGNATION.trim() == '' &&
    //   this.data.OFFICE_NAME.trim() == '' &&
    //   this.data.EMPLOYEE_CODE == 0 &&
    //   this.data.GRADE_PAY == 0

    // )
    //   {
    //     this.isOk=false;
    //     this.message.error("Please Fill All The Required Fields " ,"")
    //   }
    //   else
    if (
      this.data4.COPY_OF_CGHS_CARD == undefined ||
      this.data4.COPY_OF_CGHS_CARD == null ||
      this.data4.COPY_OF_CGHS_CARD == ''
    ) {
      this.isOk = false;
      this.message.error(
        'Please Check Copy of CGHS card for both benificiary and patient ',
        ''
      );
    } else if (
      this.data4.DISCHARGE_CARD == undefined ||
      this.data4.DISCHARGE_CARD == null ||
      this.data4.DISCHARGE_CARD == ''
    ) {
      this.isOk = false;
      this.message.error('Please Check Discharge Card ', '');
    } else if (
      this.data4.FORM_NO_3_MEDICAL_CLAIM == undefined ||
      this.data4.FORM_NO_3_MEDICAL_CLAIM == null ||
      this.data4.FORM_NO_3_MEDICAL_CLAIM == ''
    ) {
      this.isOk = false;
      this.message.error(
        'Please Check Form No.3 in case the medical claim is of the officials family member for AMA Benificiery ',
        ''
      );
    } else if (
      this.data4.PRESCIPTION_OF_MEDICINES_PURSCHASED_OUTSIDE == undefined ||
      this.data4.PRESCIPTION_OF_MEDICINES_PURSCHASED_OUTSIDE == null ||
      this.data4.PRESCIPTION_OF_MEDICINES_PURSCHASED_OUTSIDE == ''
    ) {
      this.isOk = false;
      this.message.error(
        'Please Check Prescription of Medicines Purchased From Outside ',
        ''
      );
    } else if (
      this.data4.CHECKLIST_STATUS == 'R' &&
      (this.data4.REJECT_REMARK == undefined ||
        this.data4.REJECT_REMARK == null ||
        this.data4.REJECT_REMARK == '')
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Reject Remark.', '');
    } else if (
      this.data4.CHECKLIST_STATUS == 'H' &&
      (this.data4.ON_HOLD_DATE == undefined || this.data4.ON_HOLD_DATE == null)
    ) {
      this.isOk = false;
      this.message.error(' Please Select On Hold Date.', '');
    } else if (
      this.data4.CHECKLIST_STATUS == 'H' &&
      (this.data4.ON_HOLD_REMARK == undefined ||
        this.data4.ON_HOLD_REMARK == null ||
        this.data4.ON_HOLD_REMARK == '')
    ) {
      this.isOk = false;
      this.message.error(' Please Enter On Hold Remark.', '');
    } else if (this.data4.CHECKLIST_STATUS == 'A') {
      if (this.data3.EMERGENCY_TREATEMENT == true || this.emergencyTaken == 1) {
        if (
          this.data3.IS_HOSPITAL_EMPLANELLED == true ||
          this.empanelHospital == 1
        ) {
          if (
            (this.data3.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == false ||
              this.drCertificate == 0) &&
            (this.data3.EXPO_FACTO_PERMISSION == false || this.expoFacto == 0)
          ) {
            this.isOk = false;
            this.message.error(
              'Please Provide Doctor Certificate Or Expo Facto Permission Letter.',
              ''
            );
          }
          // else if (
          //   this.data3.EXPO_FACTO_PERMISSION == false ||
          //   this.expoFacto == 0
          // ) {
          //   this.isOk = false;
          //   this.message.error(
          //     'Please Provide  Expo Facto Permission Letter.',
          //     ''
          //   );
          // } else if (
          //   this.data3.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == false ||
          //   this.drCertificate == 0
          // ) {
          //   this.isOk = false;
          //   this.message.error('Please Provide Doctor Certificate.', '');
          // }
        }
      } else {
        if (
          this.data3.REFERENCE_FORM_CGHS_AMA_TAKEN == true ||
          this.referanceTaken == 1
        ) {
          if (
            this.data3.IS_HOSPITAL_EMPLANELLED == false ||
            this.empanelHospital == 0
          ) {
            if (
              (this.data3.IS_PERMISSION_TAKEN_FROM_HOD == false ||
                this.hodPermission == 0) &&
              (this.data3.EXPO_FACTO_PERMISSION == false || this.expoFacto == 0)
            ) {
              this.isOk = false;
              this.message.error(
                'Please Provide HOD Permission Letter Or Expo Facto Permission Letter.',
                ''
              );
            }
            // else if(this.data3.IS_PERMISSION_TAKEN_FROM_HOD == false || this.hodPermission == 0) {
            //   this.isOk = false;
            //   this.message.error('Please Provide HOD Permission Letter.', '');
            // }
          }
        } else {
          if (
            this.data3.IS_HOSPITAL_EMPLANELLED == false ||
            this.empanelHospital == 0
          ) {
            if (
              this.data3.EXPO_FACTO_PERMISSION == false ||
              this.expoFacto == 0
            ) {
              this.isOk = false;
              this.message.error(
                'Please Provide Expo Facto Permission Letter.',
                ''
              );
            }
          } else {
            if (
              this.data3.EXPO_FACTO_PERMISSION == false ||
              this.expoFacto == 0
            ) {
              this.isOk = false;
              this.message.error(
                'Please Provide Expo Facto Permission Letter.',
                ''
              );
            }
          }
        }
      }
    }
    // if (
    //   (((this.data3.EMERGENCY_TREATEMENT == true || this.emergencyTaken == 1) &&
    //     (this.data3.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == false ||
    //       this.drCertificate == 0) &&
    //     (this.data3.IS_HOSPITAL_EMPLANELLED == true ||
    //       this.empanelHospital == 1) &&
    //     (this.data3.EXPO_FACTO_PERMISSION == false || this.expoFacto == 0)) ||
    //     ((this.data3.EMERGENCY_TREATEMENT == false ||
    //       this.emergencyTaken == 0) &&
    //       (this.data3.REFERENCE_FORM_CGHS_AMA_TAKEN == true ||
    //         this.referanceTaken == 1) &&
    //       (this.data3.IS_HOSPITAL_EMPLANELLED == false ||
    //         this.empanelHospital == 0) &&
    //       (this.data3.IS_PERMISSION_TAKEN_FROM_HOD == false ||
    //         this.hodPermission == 0) &&
    //       (this.data3.EXPO_FACTO_PERMISSION == false || this.expoFacto == 0)) ||
    //     ((this.data3.EMERGENCY_TREATEMENT == false ||
    //       this.emergencyTaken == 0) &&
    //       (this.data3.REFERENCE_FORM_CGHS_AMA_TAKEN == false ||
    //         this.referanceTaken == 0) &&
    //       (this.data3.IS_HOSPITAL_EMPLANELLED == false ||
    //         this.empanelHospital == 0) &&
    //       (this.data3.EXPO_FACTO_PERMISSION == false || this.expoFacto == 0)) ||
    //     ((this.data3.EMERGENCY_TREATEMENT == false ||
    //       this.emergencyTaken == 0) &&
    //       (this.data3.IS_HOSPITAL_EMPLANELLED == true ||
    //         this.empanelHospital == 1) &&
    //       (this.data3.EXPO_FACTO_PERMISSION == false || this.expoFacto == 0) &&
    //       (this.data3.REFERENCE_FORM_CGHS_AMA_TAKEN == false ||
    //         this.referanceTaken == 0))) &&
    //   this.data4.CHECKLIST_STATUS == 'A'
    // ) {
    //   this.isOk = false;
    //   this.message.error(' Please Check Questionaries Again', '');
    // }

    //   else if (this.data.EMPLOYEE_CODE == undefined || this.data.EMPLOYEE_CODE <= 0) {
    //     this.isOk = false;
    //     this.message.error('Please Enter Employee Code  ', '');
    //   }
    //   else if (this.data.GRADE_PAY == undefined || this.data.GRADE_PAY <= 0) {
    //     this.isOk = false;
    //     this.message.error('Please Enter Grade Pay  ', '');
    //   }
    //   else if (this.data.OFFICE_NAME == null || this.data.OFFICE_NAME.trim() == '') {
    //     this.isOk = false;
    //     this.message.error(' Please Enter Office Name.', '');
    //   }
    //   else if (this.data.DESIGNATION == null || this.data.DESIGNATION.trim() == '') {
    //     this.isOk = false;
    //     this.message.error(' Please Enter Designation', '');
    //   }

    //   else if (this.data.LOCATION == null || this.data.LOCATION.trim() == '') {
    //     this.isOk = false;
    //     this.message.error(' Please Enter Location', '');
    //   }
    //   else if (this.data.DDO_OF_THE_OFFICIAL == null || this.data.DDO_OF_THE_OFFICIAL.trim() == '') {
    //     this.isOk = false;
    //     this.message.error(' Please Enter Designation', '');
    //   }

    if (this.isOk) {
      if (
        this.data4.FILE_NO_ID == undefined ||
        this.data4.FILE_NO_ID == null ||
        this.data4.FILE_NO_ID == ''
      ) {
        this.data4.FILE_NO_ID = 0;
      } else {
        this.data4.FILE_NO_ID = this.data4.FILE_NO_ID;
      }
      if (this.data4.CHECKLIST_STATUS == 'A') {
        this.data4.CURRENT_STAGE_ID = 4;
        this.data4.REJECT_REMARK = '';
      } else if (this.data4.CHECKLIST_STATUS == 'R') {
        this.data4.CURRENT_STAGE_ID = 5;
        this.data4.REJECT_REMARK = this.data4.REJECT_REMARK;
      } else {
        this.data4.CURRENT_STAGE_ID = 18;
        this.data4.ON_HOLD_REMARK = this.data4.ON_HOLD_REMARK;
      }
      this.data4.ON_HOLD_DATE = this.datepipe.transform(
        this.data4.ON_HOLD_DATE,
        'yyyy-MM-dd'
      );
      this.isSpinning = true;
      // this.data3.CGHS_AMA_REFERENCE_DATE = this.datepipe.transform(this.data3.CGHS_AMA_REFERENCE_DATE, 'yyyy-MM-dd');
      // this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(this.data3.HOD_PERMISSION_DATE, 'yyyy-MM-dd');
      // this.data3.EXPOST_FACTO_PERMISSION_DATE = this.datepipe.transform(this.data3.EXPOST_FACTO_PERMISSION_DATE, 'yyyy-MM-dd');
      // this.data3.DELAY_CONDOLENCE_DATE = this.datepipe.transform(this.data3.DELAY_CONDOLENCE_DATE, 'yyyy-MM-dd');
      // this.data2.TREATMENT_END_DATE = this.datepipe.transform(this.data2.TREATMENT_END_DATE, 'yyyy-MM-dd');
      // this.data2.BILL_FILIING_DATE = this.datepipe.transform(this.data2.BILL_FILIING_DATE, 'yyyy-MM-dd');

      {
        if (this.data4.ID) {
          this.api.updateChecklist(this.data4).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information Changed Successfully...', '');
              if (!addNew) {
                // this.empID = '';
                // this.claimID = '';
                this.drawerClose();
                this.current = 0;
                this.isSpinning = false;
              }
            } else {
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
        } else {
          this.api.createChecklist(this.data4).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information Save Successfully...', '');
              if (!addNew) {
                // this.empID = '';
                // this.claimID = '';
                this.drawerClose();
                this.current = 0;
              } else {
                // this.data = new ApplicantMaster();
                // this.resetDrawer(claimMasterPage);
                // this.data.IMG_URL= '';
                // this.api.getEmployeeMaster(0,0,'','desc','').subscribe (data =>{
                //   // if (data['count']==0){
                //   //   this.data.SEQUENCE_NUMBER=1;
                //   // }else
                //   // {
                //   //   this.data.SEQUENCE_NUMBER=data['data'][0]['SEQUENCE_NUMBER']+1;
                //   // }
                // },err=>{
                //   console.log(err);
                // })
              }
              this.isSpinning = false;
            } else {
              this.message.error('Failed To Fill Information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
  }
  pre2(): void {
    if (this.current == 2 && this.data.ID > 0) {
      this.current -= 2;
    } else {
      this.current -= 1;
    }
  }

  pre(): void {
    if (this.current == 1) {
      this.isSpinning = true;
      this.allEmployeeList();
      this.api
        .getclaimMaster(
          0,
          0,
          '',
          '',
          ' AND ID =' + this.claimID,
          null,
          null,
          '',
          ''
        )
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              if (data['data'].length == 0) {
                this.current -= 1;
                // this.isSpinning = false;
              } else {
                this.data = data['data'][0];
                console.log('data:', this.data);
                this.current -= 1;
                // this.isSpinning = false;
              }
            } else {
              this.message.error('Something Went Wrong', '');
              // this.isSpinning = false;
            }
            this.api
              .getHospitalMapping(
                0,
                0,
                '',
                'desc',
                ' AND CLAIM_ID = ' + this.claimID
              )
              .subscribe(
                (data) => {
                  if (data['code'] == 200) {
                    this.hospitalMapList = data['data'];
                    this.isSpinning = false;
                  } else {
                    this.message.error('Something Went Wrong', '');
                    this.isSpinning = false;
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
          },
          (err) => {
            console.log(err);
          }
        );
    } else if (this.current == 3) {
      this.isSpinning = true;
      this.disableReferanceDate();
      this.allHODPermissionList();
      this.allDelayCondonationList();
      this.allExpostFactoList();
      this.api
        .getAllQuestions(0, 0, '', '', ' AND CLAIM_ID =' + this.claimID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              if (data['data'].length == 0) {
                this.current -= 1;
                this.isSpinning = false;
              } else {
                this.data3 = data['data'][0];
                this.emergencyTaken = data['data'][0]['EMERGENCY_TREATEMENT'];
                this.drCertificate =
                  data['data'][0]['DOCTOR_EMERGENCY_CERTIFICATE_TAKEN'];
                this.empanelHospital =
                  data['data'][0]['IS_HOSPITAL_EMPLANELLED'];
                this.expoFacto = data['data'][0]['EXPO_FACTO_PERMISSION'];
                this.referanceTaken =
                  data['data'][0]['REFERENCE_FORM_CGHS_AMA_TAKEN'];
                this.hodPermission =
                  data['data'][0]['IS_PERMISSION_TAKEN_FROM_HOD'];
                if (
                  data['data'][0]['HOD_ID'] != undefined &&
                  data['data'][0]['HOD_ID'] != null &&
                  data['data'][0]['HOD_ID'] != '' &&
                  data['data'][0]['HOD_ID'].length != 0
                ) {
                  this.data3.HOD_ID = data['data'][0]['HOD_ID'].split(',');
                  this.data3.HOD_ID.forEach((element, index) => {
                    this.data3.HOD_ID[index] = Number(element);
                  });
                } else {
                  // this.dateDifference();
                }
                // this.data3.HOD_ID = this.hodList;
                if (
                  this.data3.CGHS_AMA_REFERENCE_DATE == undefined &&
                  this.data3.CGHS_AMA_REFERENCE_DATE == null &&
                  this.data3.CGHS_AMA_REFERENCE_DATE == '' &&
                  this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
                ) {
                  this.data3.CGHS_AMA_REFERENCE_DATE = [];
                } else {
                  this.data3.CGHS_AMA_REFERENCE_DATE =
                    this.data3.CGHS_AMA_REFERENCE_DATE.split(',');
                  // this.dateDifference();
                }
                if (
                  this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined &&
                  this.data3.CGHS_AMA_REFERENCE_END_DATE == null &&
                  this.data3.CGHS_AMA_REFERENCE_END_DATE == '' &&
                  this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
                ) {
                  this.data3.CGHS_AMA_REFERENCE_END_DATE = [];
                } else {
                  this.data3.CGHS_AMA_REFERENCE_END_DATE =
                    this.data3.CGHS_AMA_REFERENCE_END_DATE.split(',');
                  this.date1 = new Date(
                    Math.max.apply(
                      null,
                      this.data3.CGHS_AMA_REFERENCE_END_DATE.map(function (e) {
                        // console.log('e', e);
                        return new Date(e);
                      })
                    )
                  );
                  var date2: any = new Date(this.data2.BILL_FILIING_DATE);
                  // console.log('date2',date2)

                  this.expoDiffDays = Math.floor(
                    (date2 - this.date1) / (1000 * 60 * 60 * 24)
                  );
                  console.log('this.expoDiffDaysssssss', this.expoDiffDays);
                  this.dateDifference();
                }
                this.current -= 1;
                this.isSpinning = false;
              }
            } else {
              this.message.error('Something Went Wrong', '');
              this.isSpinning = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else if (this.current == 2) {
      this.isSpinning = true;
      this.allHospitalList();
      this.api.getclaimed(0, 0, '', '', ' AND ID =' + this.claimID).subscribe(
        (data) => {
          if (data['code'] == 200) {
            if (data['data'].length == 0) {
              // this.billInTime()
              // this.empanelledHospital()
              this.data2 = new ClaimMaster();
              this.current -= 1;
              this.isSpinning = false;
            } else {
              this.data2 = data['data'][0];

              if (
                this.data2.TREATEMENT_TYPE == null ||
                this.data2.TREATEMENT_TYPE == '' ||
                this.data2.TREATEMENT_TYPE == undefined
              ) {
                this.TREATEMENT_TYPE1 = false;
                this.TREATEMENT_TYPE2 = false;
              }
              if (this.data2.TREATEMENT_TYPE == 'IT') {
                this.TREATEMENT_TYPE1 = false;
                this.TREATEMENT_TYPE2 = true;
              }
              if (this.data2.TREATEMENT_TYPE == 'OT') {
                this.TREATEMENT_TYPE1 = true;
                this.TREATEMENT_TYPE2 = false;
              }
              if (this.data2.TREATEMENT_TYPE == 'OT,IT') {
                this.TREATEMENT_TYPE1 = true;
                this.TREATEMENT_TYPE2 = true;
              }
              // this.billInTime()
              // this.empanelledHospital();
              this.current -= 1;
              this.isSpinning = false;
            }
          } else {
            this.message.error('Something Went Wrong', '');
            this.isSpinning = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );

      this.isSpinning = false;
    } else {
      this.isSpinning = true;
      this.current -= 1;
      this.isSpinning = false;
    }
  }

  filterEmpData(event: any) {
    this.empLoader = true;
    this.isSpinning = true;
    if (event != null) {
      this.api.getEmployeeMaster(0, 0, '', '', ' AND ID = ' + event).subscribe(
        (data) => {
          if (data['code'] == 200) {
            this.empLoader = false;
            // this.data.ID = data['data'][0]['ID'];
            this.data.EMP_ID = data['data'][0]['ID'];
            this.data.OFFICE_NAME = data['data'][0]['OFFICE_NAME'];
            this.data.DESIGNATION = data['data'][0]['DESIGNATION'];
            this.data.EMPLOYEE_CODE = data['data'][0]['EMPLOYEE_CODE'];
            this.data.LOCATION = data['data'][0]['LOCATION'];
            this.data.DDO_OF_THE_OFFICIAL =
              data['data'][0]['DDO_OF_THE_OFFICIAL'];
            this.data.GRADE_PAY = data['data'][0]['GRADE_PAY'];
            this.data.EMAIL_ID = data['data'][0]['EMAIL_ID'];
            this.data.MOBILE_NO = data['data'][0]['MOBILE_NO'];
            this.data.ADDRESS = data['data'][0]['ADDRESS'];
            // this.data.NAME = data['data'][0]['NAME'];
            this.data.EMPLOYEE_NAME = data['data'][0]['NAME'];
            this.data.EMPLOYEE_NAME_HN = data['data'][0]['EMPLOYEE_NAME_HN'];

            // this.data.EMPLOYEE_OFFICE_NAME;
            // this.data.EMPLOYEE_DESIGNATION;
            // this.data.EMPLOYEE_DDO;
            // this.data.EMPLOYEE_NAME_HN;
            // this.data.EMPLOYEE_GRADE_PAY;

            this.data.CGHS_CARD_NO = '';
            this.data.CGHS_CARD_VALIDITY = '';
            this.data.BENEFICIARY_TYPE = '';
            this.isSpinning = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      this.empLoader = false;
      this.data.ID = null;
      this.data.OFFICE_NAME = '';
      this.data.DESIGNATION = '';
      this.data.EMPLOYEE_CODE = '';
      this.data.LOCATION = '';
      this.data.DDO_OF_THE_OFFICIAL = '';
      this.data.GRADE_PAY = '';
      this.data.EMAIL_ID = '';
      this.data.MOBILE_NO = '';
      this.data.ADDRESS = '';
      this.data.CGHS_CARD_NO = '';
      this.data.CGHS_CARD_VALIDITY = '';
      this.data.BENEFICIARY_TYPE = '';
      this.isSpinning = false;
    }
  }

  // name
  // onChange(value: string): void {
  //   console.log('value', value)
  //   this.name == value

  //   this.filteredOptions = this.employee.filter(option => option.NAME.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  //   console.log('filteredOptions', this.filteredOptions)

  //   // var a = this.employee.filter(obj => {
  //   //   return obj.NAME == value;
  //   // })
  //   console.log('name', this.name)

  // }

  name = '';
  onChange(value: string): void {
    this.name = value;
    this.filteredOptions = [];
    this.filteredOptions = this.employee.filter(
      (option) => option.NAME.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
    console.log('filteredOptions', this.filteredOptions);
    if (value != '') {
      if (this.filteredOptions.length > 0) {
        // this.name = this.filteredOptions[0]['NAME'];
        this.data.ID = this.filteredOptions[0]['ID'];

        this.data.OFFICE_NAME = this.filteredOptions[0]['OFFICE_NAME'];
        this.data.DESIGNATION = this.filteredOptions[0]['DESIGNATION'];
        this.data.EMPLOYEE_CODE = this.filteredOptions[0]['EMPLOYEE_CODE'];
        this.data.LOCATION = this.filteredOptions[0]['LOCATION'];
        this.data.DDO_OF_THE_OFFICIAL =
          this.filteredOptions[0]['DDO_OF_THE_OFFICIAL'];
        this.data.GRADE_PAY = this.filteredOptions[0]['GRADE_PAY'];
        this.data.EMAIL_ID = this.filteredOptions[0]['EMAIL_ID'];
        this.data.MOBILE_NO = this.filteredOptions[0]['MOBILE_NO'];
        this.data.ADDRESS = this.filteredOptions[0]['ADDRESS'];
      } else {
        this.data.ID = undefined;
        this.name = value;
        this.data.OFFICE_NAME = '';
        this.data.DESIGNATION = '';
        this.data.EMPLOYEE_CODE = '';
        this.data.LOCATION = '';
        this.data.DDO_OF_THE_OFFICIAL = '';
        this.data.GRADE_PAY = '';
        this.data.EMAIL_ID = '';
        this.data.MOBILE_NO = '';
        this.data.ADDRESS = '';
      }
    } else {
      this.name = value;
      this.data.OFFICE_NAME = '';
      this.data.DESIGNATION = '';
      this.data.EMPLOYEE_CODE = '';
      this.data.LOCATION = '';
      this.data.DDO_OF_THE_OFFICIAL = '';
      this.data.GRADE_PAY = '';
      this.data.EMAIL_ID = '';
      this.data.MOBILE_NO = '';
      this.data.ADDRESS = '';
    }
    this.data.NAME = this.name;
  }
  emergencyTaken;
  drCertificate;
  empanelHospital;
  expoFacto;
  referanceTaken;
  hodPermission;

  dateDifference() {
    // var date1: any = new Date(
    //   Math.max.apply(
    //     null,
    //     this.data3.CGHS_AMA_REFERENCE_DATE.map(function (e) {
    //       console.log('e', e);
    //       return new Date(e);
    //     })
    //   )
    // );
    // console.log('date1',this.data3.CGHS_AMA_REFERENCE_DATE[this.data3.CGHS_AMA_REFERENCE_DATE.length - 1])
    // console.log('date11',date1)
    var date2: any = new Date(this.data2.BILL_FILIING_DATE);
    // console.log('date2',date2)

    this.expoDiffDays = Math.floor(
      (date2 - this.date1) / (1000 * 60 * 60 * 24)
    );
  }

  fileList: any = [];
  fileNumberList() {
    this.api
      .getFileMaster(
        0,
        0,
        'ID',
        'ASC',
        ' AND STATUS = 1 AND HIRARCHY_ID in (12,13)',
        sessionStorage.getItem('userId')
      )
      .subscribe(
        (data: any) => {
          if (data['code'] == 200 && data['count'] > 0) {
            this.fileList = data['data'];
          } else {
            this.fileList = [];
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }
  next() {
    if (this.current == 0) {
      this.allHospitalList();
      this.isSpinning = true;
      this.api.getclaimed(0, 0, '', '', ' AND ID =' + this.claimID).subscribe(
        (data) => {
          if (data['code'] == 200) {
            if (data['data'].length == 0) {
              // this.billInTime()
              // this.empanelledHospital()
              this.data2 = new ClaimMaster();
              // this.isSpinning = false;
            } else {
              this.data2 = data['data'][0];

              if (
                this.data2.TREATEMENT_TYPE == null ||
                this.data2.TREATEMENT_TYPE == '' ||
                this.data2.TREATEMENT_TYPE == undefined
              ) {
                this.TREATEMENT_TYPE1 = false;
                this.TREATEMENT_TYPE2 = false;
              }
              if (this.data2.TREATEMENT_TYPE == 'IT') {
                this.TREATEMENT_TYPE1 = false;
                this.TREATEMENT_TYPE2 = true;
              }
              if (this.data2.TREATEMENT_TYPE == 'OT') {
                this.TREATEMENT_TYPE1 = true;
                this.TREATEMENT_TYPE2 = false;
              }
              if (this.data2.TREATEMENT_TYPE == 'OT,IT') {
                this.TREATEMENT_TYPE1 = true;
                this.TREATEMENT_TYPE2 = true;
              }
              // this.billInTime()
              this.empanelledHospital();
              // this.isSpinning = false;
            }
            this.api
              .getHospitalMapping(
                0,
                0,
                '',
                'desc',
                ' AND CLAIM_ID = ' + this.claimID
              )
              .subscribe(
                (data) => {
                  if (data['code'] == 200) {
                    this.hospitalMapList = data['data'];
                    this.isSpinning = false;
                    this.current = 1;
                  } else {
                    this.message.error('Something Went Wrong', '');
                    this.isSpinning = false;
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
          } else {
            this.message.error('Something Went Wrong', '');
            this.isSpinning = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );
    } else if (this.current == 1) {
      this.isSpinning = true;
      this.disableReferanceDate();
      this.api
        .getAllQuestions(0, 0, '', '', ' AND CLAIM_ID =' + this.claimID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              if (data['data'].length == 0) {
                this.data3 = new QuestionaryMaster();
                this.billInTime();
                this.empanelledHospital();
                this.allHODPermissionList();
                this.allDelayCondonationList();
                this.allExpostFactoList();
                this.current = 2;
                this.isSpinning = false;
              } else {
                this.data3 = data['data'][0];
                this.emergencyTaken = data['data'][0]['EMERGENCY_TREATEMENT'];
                this.drCertificate =
                  data['data'][0]['DOCTOR_EMERGENCY_CERTIFICATE_TAKEN'];
                this.empanelHospital =
                  data['data'][0]['IS_HOSPITAL_EMPLANELLED'];
                this.expoFacto = data['data'][0]['EXPO_FACTO_PERMISSION'];
                this.referanceTaken =
                  data['data'][0]['REFERENCE_FORM_CGHS_AMA_TAKEN'];
                this.hodPermission =
                  data['data'][0]['IS_PERMISSION_TAKEN_FROM_HOD'];
                if (
                  data['data'][0]['HOD_ID'] != undefined &&
                  data['data'][0]['HOD_ID'] != null &&
                  data['data'][0]['HOD_ID'] != '' &&
                  data['data'][0]['HOD_ID'].length != 0
                ) {
                  this.data3.HOD_ID = data['data'][0]['HOD_ID'].split(',');
                  this.data3.HOD_ID.forEach((element, index) => {
                    this.data3.HOD_ID[index] = Number(element);
                  });
                } else {
                  // this.dateDifference();
                }
                // this.data3.HOD_ID = this.hodList;
                console.log('this.data3.HOD_ID', this.data3.HOD_ID);
                if (
                  this.data3.CGHS_AMA_REFERENCE_DATE == undefined ||
                  this.data3.CGHS_AMA_REFERENCE_DATE == null ||
                  this.data3.CGHS_AMA_REFERENCE_DATE == '' ||
                  this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
                ) {
                  this.data3.CGHS_AMA_REFERENCE_DATE = [];
                } else {
                  this.data3.CGHS_AMA_REFERENCE_DATE =
                    this.data3.CGHS_AMA_REFERENCE_DATE.split(',');
                  // this.dateDifference();
                }
                if (
                  this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined ||
                  this.data3.CGHS_AMA_REFERENCE_END_DATE == null ||
                  this.data3.CGHS_AMA_REFERENCE_END_DATE == '' ||
                  this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
                ) {
                  this.data3.CGHS_AMA_REFERENCE_END_DATE = [];
                } else {
                  this.data3.CGHS_AMA_REFERENCE_END_DATE =
                    this.data3.CGHS_AMA_REFERENCE_END_DATE.split(',');
                  this.date1 = new Date(
                    Math.max.apply(
                      null,
                      this.data3.CGHS_AMA_REFERENCE_END_DATE.map(function (e) {
                        // console.log('e', e);
                        return new Date(e);
                      })
                    )
                  );
                  var date2: any = new Date(this.data2.BILL_FILIING_DATE);
                  // console.log('date2',date2)

                  this.expoDiffDays = Math.floor(
                    (date2 - this.date1) / (1000 * 60 * 60 * 24)
                  );
                  console.log('this.expoDiffDaysaaaaaaaaa', this.expoDiffDays);
                  this.dateDifference();
                }
                // this.calculateDiff();
                this.billInTime();
                this.empanelledHospital();
                this.allHODPermissionList();
                this.allDelayCondonationList();
                this.allExpostFactoList();
                this.current = 2;
                this.isSpinning = false;
              }
            } else {
              this.message.error('Something Went Wrong', '');
              this.isSpinning = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else if ((this.current = 2)) {
      this.fileNumberList();
      this.isSpinning = true;
      this.api
        .getAllChecklist(0, 0, '', '', ' AND CLAIM_ID =' + this.claimID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              if (data['data'].length == 0) {
                this.data4 = new CheckList();
                // this.claimConfirmation();
                this.current = 3;
                this.isSpinning = false;
              } else {
                this.data4 = data['data'][0];
                // this.claimConfirmation();
                this.current = 3;
                this.isSpinning = false;
              }
            } else {
              this.message.error('Something Went Wrong', '');
              this.isSpinning = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
    }
  }

  calculateDiff() {
    var date1: any = new Date(this.data2.TREATMENT_END_DATE);
    var date2: any = new Date(this.data2.BILL_FILIING_DATE);
    this.diffDays = Math.floor((date2 - date1) / (1000 * 60 * 60 * 24)) + 1;
  }

  hospitalAddress(event: any) {
    this.api.getAllHospital(0, 0, '', '', ' AND ID = ' + event).subscribe(
      (data) => {
        if (data['code'] == 200) {
          this.hospitalData.ADDRESS = data['data'][0]['ADDRESS'];
          this.hospitalData.ACCREDATION = data['data'][0]['ACCREDITATION'];
          // console.log('ACCREDITATION', ACCREDITATION);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  hospitalType(event: any) {
    console.log('event', event);
    console.log('this.data2.HOSPITAL_ID', this.data2.HOSPITAL_ID);
    console.log('this.data2.name', this.data2.HOSPITAL_NAME);

    if (event == 'E') {
      this.hospitalData.ADDRESS = null;
      this.hospitalData.ACCREDATION = null;
      if (
        this.hospitalData.NAME != undefined ||
        this.hospitalData.NAME != null ||
        this.hospitalData.NAME != ''
      ) {
        this.hospitalData.NAME = '';
      } else {
        this.hospitalData.NAME = '';
      }
    } else if (event == 'NE') {
      this.hospitalData.ADDRESS = null;
      this.hospitalData.ACCREDATION = null;
      if (
        this.hospitalData.HOSPITAL_ID != undefined ||
        this.hospitalData.HOSPITAL_ID != null
      ) {
        this.hospitalData.HOSPITAL_ID = null;
      } else {
        this.hospitalData.HOSPITAL_ID = null;
      }
    } else {
      this.hospitalData.ADDRESS = null;
      this.hospitalData.ACCREDATION = null;
      if (
        this.hospitalData.HOSPITAL_ID != undefined ||
        this.hospitalData.HOSPITAL_ID != null
      ) {
        this.hospitalData.HOSPITAL_ID = null;
      } else {
        this.hospitalData.HOSPITAL_ID = null;
      }
    }
  }

  billInTime() {
    var date1: any = new Date(this.data2.TREATMENT_END_DATE);
    var date2: any = new Date(this.data2.BILL_FILIING_DATE);
    this.diffDays = Math.floor((date2 - date1) / (1000 * 60 * 60 * 24)) + 1;
    // console.log('this.data2.IS_ADVANCE_TAKEN', this.data2.IS_ADVANCE_TAKEN);
    // console.log('this.diffDays', this.diffDays);
    // console.log('this.diffDays', this.diffDays);
    var advance;
    if (this.data2.IS_ADVANCE_TAKEN == true) {
      advance = 1;
      console.log('this.advance', advance);
    } else {
      advance = 0;
      console.log('advance', advance);
    }
    if (advance == 1 && this.diffDays <= 30) {
      this.data3.BILL_FILLED_INTIME = true;
    } else if (advance == 1 && this.diffDays > 30) {
      this.data3.BILL_FILLED_INTIME = false;
    } else if (advance == 0 && this.diffDays > 182) {
      this.data3.BILL_FILLED_INTIME = false;
    } else {
      this.data3.BILL_FILLED_INTIME = true;
    }
  }
  empanneledHospitalMapList = [];
  nonEmpanneled: boolean = false;
  empanelledHospital() {
    this.nonEmpanneled = false;
    this.api
      .getHospitalMapping(0, 0, '', 'desc', ' AND CLAIM_ID = ' + this.claimID)
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            this.empanneledHospitalMapList = data['data'];
            for (var i = 0; this.empanneledHospitalMapList.length > i; i++) {
              if (this.empanneledHospitalMapList[i]['TYPE'] == 'NE') {
                this.nonEmpanneled = true;
              } else {
              }
            }
            if (this.nonEmpanneled == true) {
              this.data3.IS_HOSPITAL_EMPLANELLED = false;
            } else {
              this.data3.IS_HOSPITAL_EMPLANELLED = true;
            }
            this.isSpinning = false;
          } else {
            this.message.error("Can't Load Hospital Mapped Data.", '');
            this.isSpinning = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  isAdvanceTaken(event: any) {
    if (event == false) {
      if (
        this.data2.ADVANCE_AMOUNT != null ||
        this.data2.ADVANCE_AMOUNT != undefined
      ) {
        this.data2.ADVANCE_AMOUNT = null;
      } else {
        this.data2.ADVANCE_AMOUNT = null;
      }
      if (
        this.data2.ADVANCE_TAKEN_DATE != null ||
        this.data2.ADVANCE_TAKEN_DATE != undefined
      ) {
        this.data2.ADVANCE_TAKEN_DATE = null;
      } else {
        this.data2.ADVANCE_TAKEN_DATE = null;
      }
    } else {
    }
  }
  disabledDate = (current: Date): boolean =>
    differenceInCalendarDays(
      current,
      new Date(this.data2.TREATMENT_START_DATE)
    ) < 0;

  disabledDate2 = (current: Date): boolean =>
    differenceInCalendarDays(
      current,
      new Date(this.data2.TREATMENT_START_DATE)
    ) < 0;

  disableBillDate() {
    this.disabledDate = (current: Date): boolean =>
      differenceInCalendarDays(
        current,
        new Date(this.data2.TREATMENT_START_DATE)
      ) < 0;
  }

  treatEndDate() {
    this.disabledDate2 = (current: Date): boolean =>
      differenceInCalendarDays(
        current,
        new Date(this.data2.TREATMENT_START_DATE)
      ) < 0;
  }

  referanceDateDisabledDate = (current: Date): boolean =>
    differenceInCalendarDays(
      current,
      new Date(this.data2.TREATMENT_START_DATE)
    ) < 0;

  disableReferanceDate() {
    this.referanceDateDisabledDate = (current: Date): boolean =>
      differenceInCalendarDays(
        current,
        new Date(this.data2.BILL_FILIING_DATE)
      ) > 0;
  }
  // TREATEMENT_TYPE1 =false;
  // TREATEMENT_TYPE2 =false;

  onItemChecked(checked: boolean) {
    this.TREATEMENT_TYPE1 = checked;
    this.data2.TREATEMENT_TYPE = '';
    if (this.TREATEMENT_TYPE1 == true && this.TREATEMENT_TYPE2 == true) {
      this.data2.TREATEMENT_TYPE = 'OT,IT';
    }
    if (this.TREATEMENT_TYPE1 == false && this.TREATEMENT_TYPE2 == true) {
      this.data2.TREATEMENT_TYPE = 'IT';
    }
    if (this.TREATEMENT_TYPE1 == true && this.TREATEMENT_TYPE2 == false) {
      this.data2.TREATEMENT_TYPE = 'OT';
    }
  }

  onItemChecked2(checked: boolean) {
    this.TREATEMENT_TYPE2 = checked;
    this.data2.TREATEMENT_TYPE = '';
    if (this.TREATEMENT_TYPE1 == true && this.TREATEMENT_TYPE2 == true) {
      this.data2.TREATEMENT_TYPE = 'OT,IT';
    }
    if (this.TREATEMENT_TYPE1 == false && this.TREATEMENT_TYPE2 == true) {
      this.data2.TREATEMENT_TYPE = 'IT';
    }
    if (this.TREATEMENT_TYPE1 == true && this.TREATEMENT_TYPE2 == false) {
      this.data2.TREATEMENT_TYPE = 'OT';
    }
  }

  claimConfirmation() {
    // console.log('emergencyTaken', this.emergencyTaken);
    // console.log('drCertificate', this.drCertificate);
    // console.log('empanelHospital', this.empanelHospital);
    // console.log('expoFacto', this.expoFacto);
    // console.log('referanceTaken', this.referanceTaken);

    // console.log('ss', this.data3.EMERGENCY_TREATEMENT);
    // console.log('aa', this.data3.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN);
    // console.log('aas', this.data3.IS_HOSPITAL_EMPLANELLED);
    // console.log('hh', this.data3.EXPO_FACTO_PERMISSION);
    // console.log('jj', this.data3.REFERENCE_FORM_CGHS_AMA_TAKEN);

    if (
      ((this.data3.EMERGENCY_TREATEMENT == true || this.emergencyTaken == 1) &&
        (this.data3.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == false ||
          this.drCertificate == 0) &&
        (this.data3.IS_HOSPITAL_EMPLANELLED == true ||
          this.empanelHospital == 1) &&
        (this.data3.EXPO_FACTO_PERMISSION == false || this.expoFacto == 0)) ||
      ((this.data3.EMERGENCY_TREATEMENT == false || this.emergencyTaken == 0) &&
        (this.data3.REFERENCE_FORM_CGHS_AMA_TAKEN == true ||
          this.referanceTaken == 1) &&
        (this.data3.IS_HOSPITAL_EMPLANELLED == false ||
          this.empanelHospital == 0) &&
        (this.data3.IS_PERMISSION_TAKEN_FROM_HOD == false ||
          this.hodPermission == 0) &&
        (this.data3.EXPO_FACTO_PERMISSION == false || this.expoFacto == 0)) ||
      ((this.data3.EMERGENCY_TREATEMENT == false || this.emergencyTaken == 0) &&
        (this.data3.REFERENCE_FORM_CGHS_AMA_TAKEN == false ||
          this.referanceTaken == 0) &&
        (this.data3.IS_HOSPITAL_EMPLANELLED == false ||
          this.empanelHospital == 0) &&
        (this.data3.EXPO_FACTO_PERMISSION == false || this.expoFacto == 0)) ||
      ((this.data3.EMERGENCY_TREATEMENT == false || this.emergencyTaken == 0) &&
        (this.data3.IS_HOSPITAL_EMPLANELLED == true ||
          this.empanelHospital == 1) &&
        (this.data3.EXPO_FACTO_PERMISSION == false || this.expoFacto == 0) &&
        (this.data3.REFERENCE_FORM_CGHS_AMA_TAKEN == false ||
          this.referanceTaken == 0))
    ) {
      this.data4.CHECKLIST_STATUS = 'R';
      console.log('R', this.data4.CHECKLIST_STATUS);
    } else {
      this.data4.CHECKLIST_STATUS = 'A';
      console.log('A', this.data4.CHECKLIST_STATUS);
    }
  }

  relationshipFilter(event) {
    // console.log(event);
    this.isSpinning = true;
    if (event == 'Self') {
      this.api
        .getEmployeeMaster(1, 1, '', '', ' AND ID = ' + this.empID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              this.data2.PATIENT_NAME = data['data'][0]['NAME'];
              this.isSpinning = false;
            } else {
              this.message.error("Can't Load Employee Data", '');
              this.isSpinning = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
      this.data2.PATIENT_CGHS_BENEFICIERY_NO = this.data.CGHS_CARD_NO;
    } else {
      this.data2.PATIENT_CGHS_BENEFICIERY_NO = '';
      this.data2.PATIENT_NAME = '';
      this.isSpinning = false;
    }
  }
  list: any = [];
  ADD(CGHS_AMA_REFERENCE_DATE: any) {
    console.log('CGHS_AMA_REFERENCE_DATE', CGHS_AMA_REFERENCE_DATE);
    this.list.push(CGHS_AMA_REFERENCE_DATE);
    console.log('hh', this.list);
  }

  onChangeReferance(event: any) {
    if (event == false) {
      this.data3.CGHS_AMA_REFERENCE_DATE = [];
      this.data3.CGHS_AMA_REFERENCE_END_DATE = [];
      this.data3.CGHS_AMA_REFERENCE_NO = '';
      this.data3.IS_PERMISSION_TAKEN_FROM_HOD = false;
      this.data3.HOD_PERMISSION_DATE = null;
      this.data3.HOD_PERMISSION_NO = '';
      this.data3.HOD_ID = [];
    } else {
    }
  }

  onChangeExpoFacto(event: any) {
    if (event == false) {
      this.data3.EXPO_FACTO_DATE = null;
      this.data3.EXPO_FACTO_REFERENCE_NO = '';
    } else {
    }
  }

  onChangeHOD(event: any) {
    if (event == false) {
      this.data3.HOD_PERMISSION_DATE = null;
      this.data3.HOD_PERMISSION_NO = '';
      this.data3.HOD_ID = [];
    } else {
      this.data3.EXPO_FACTO_PERMISSION = false;
      this.data3.EXPO_FACTO_DATE = null;
      this.data3.EXPO_FACTO_REFERENCE_NO = '';
    }
  }
  onChangeBillIntime(event: any) {
    if (event == true) {
      this.data3.DELAY_CONDOLENCE_DATE = null;
      this.data3.DELAY_CONDOLENCE_NUMBER = '';
    } else {
      this.data3.DELAY_CONDOLENCE_DATE = this.data3.DELAY_CONDOLENCE_DATE;
      this.data3.DELAY_CONDOLENCE_NUMBER = this.data3.DELAY_CONDOLENCE_NUMBER;
    }
  }

  addDates() {
    console.log('this.CGHS_AMA_REFERENCE_DATE', this.CGHS_AMA_REFERENCE_DATE);
    console.log(
      'this.CGHS_AMA_REFERENCE_END_DATE',
      this.CGHS_AMA_REFERENCE_END_DATE
    );
    // if (
    //   (this.CGHS_AMA_REFERENCE_DATE == undefined ||
    //     this.CGHS_AMA_REFERENCE_DATE == null ||
    //     this.CGHS_AMA_REFERENCE_DATE == '') &&
    //   (this.CGHS_AMA_REFERENCE_END_DATE == undefined ||
    //     this.CGHS_AMA_REFERENCE_END_DATE == null ||
    //     this.CGHS_AMA_REFERENCE_END_DATE == '')
    // ) {
    //   this.message.error('Please Select Date', '');
    // } else
    if (
      this.CGHS_AMA_REFERENCE_DATE == undefined ||
      this.CGHS_AMA_REFERENCE_DATE == null ||
      this.CGHS_AMA_REFERENCE_DATE == ''
    ) {
      this.message.error("Please Select CGHS/AMA's Reference Start Date", '');
      // } else
      //  if (
      //   (this.CGHS_AMA_REFERENCE_DATE != undefined ||
      //     this.CGHS_AMA_REFERENCE_DATE != null ||
      //     this.CGHS_AMA_REFERENCE_DATE != '') &&
      //   (this.CGHS_AMA_REFERENCE_END_DATE == undefined ||
      //     this.CGHS_AMA_REFERENCE_END_DATE == null ||
      //     this.CGHS_AMA_REFERENCE_END_DATE == '')
      // ) {
      //   this.message.error("Please Select CGHS/AMA's Reference End Date", '');
    } else {
      this.date1 = '';
      this.expoDiffDays = '';
      var date = this.datepipe.transform(
        this.CGHS_AMA_REFERENCE_DATE,
        'yyyy-MM-dd'
      );

      // console.log('date', date);
      // console.log('enddate', enddate);

      this.data3.CGHS_AMA_REFERENCE_DATE.push(date);
      this.CGHS_AMA_REFERENCE_DATE = null;
      if (
        this.CGHS_AMA_REFERENCE_END_DATE != undefined &&
        this.CGHS_AMA_REFERENCE_END_DATE != null &&
        this.CGHS_AMA_REFERENCE_END_DATE != ''
      ) {
        this.date2 = '';
        this.expoDiffDays = '';
        var enddate = this.datepipe.transform(
          this.CGHS_AMA_REFERENCE_END_DATE,
          'yyyy-MM-dd'
        );

        this.data3.CGHS_AMA_REFERENCE_END_DATE.push(enddate);
        this.CGHS_AMA_REFERENCE_END_DATE = null;

        this.date1 = new Date(
          Math.max.apply(
            null,
            this.data3.CGHS_AMA_REFERENCE_END_DATE.map(function (e) {
              // console.log('e', e);
              return new Date(e);
            })
          )
        );
        var date2: any = new Date(this.data2.BILL_FILIING_DATE);
        // console.log('date2',date2)

        this.expoDiffDays = Math.floor(
          (date2 - this.date1) / (1000 * 60 * 60 * 24)
        );
        console.log('this.expoDiffDays', this.expoDiffDays);
        // console.log('this.data3.CGHS_AMA_REFERENCE_DATE',this.data3.CGHS_AMA_REFERENCE_DATE)
      } else {
        this.CGHS_AMA_REFERENCE_END_DATE = null;
      }
    }
  }
  drawerData: EmployeeMaster = new EmployeeMaster();
  drawerTitle: string = '';

  add(): void {
    this.drawerTitle = 'Create New Employee';
    this.drawerData = new EmployeeMaster();
    this.empDrawerVisible = true;
  }
  editEmp;
  edit(data: any): void {
    this.drawerTitle = 'Edit Employee Details';
    this.api
      .getEmployeeMaster(0, 0, '', '', ' AND ID = ' + data.EMP_ID)
      .subscribe((data) => {
        if (data['code'] == 200) {
          this.drawerData = data['data'][0];
        } else {
          this.message.error("Can't Load Employee Data", '');
        }
      }),
      // this.drawerData = Object.assign({}, this.editEmp);
      (this.empDrawerVisible = true);
  }
  empDrawerClose(): void {
    this.empDrawerVisible = false;
    this.allEmployeeList();
    // window.location.reload();
  }

  get closeCallback() {
    return this.empDrawerClose.bind(this);
  }

  emergencySwitch(event) {
    if (event == true) {
      this.data3.REFERENCE_FORM_CGHS_AMA_TAKEN = false;
      this.data3.CGHS_AMA_REFERENCE_DATE = [];
      this.data3.IS_PERMISSION_TAKEN_FROM_HOD = false;
      this.data3.HOD_PERMISSION_DATE = null;
      this.data3.HOD_PERMISSION_NO = '';
    } else {
      this.data3.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN = false;
    }
  }

  doctorSwitch(event) {
    if (event == true) {
      this.data3.EXPO_FACTO_PERMISSION = false;
      this.data3.EXPO_FACTO_DATE = null;
      this.data3.EXPO_FACTO_REFERENCE_NO = '';
    } else {
    }
  }

  checkDateDiff() {
    // console.log('this.data3.CGHS_AMA_REFERENCE_DATE',this.data3.CGHS_AMA_REFERENCE_DATE);

    if (
      this.data3.CGHS_AMA_REFERENCE_DATE != undefined ||
      this.data3.CGHS_AMA_REFERENCE_DATE != null ||
      this.data3.CGHS_AMA_REFERENCE_DATE != '' ||
      this.data3.CGHS_AMA_REFERENCE_DATE.length != 0
    ) {
      // console.log('this.data3.CGHS_AMA_REFERENCE_DATE2',this.data3.CGHS_AMA_REFERENCE_DATE);

      // this.data3.CGHS_AMA_REFERENCE_DATE =
      //   this.data3.CGHS_AMA_REFERENCE_DATE.split(',');
      // this.dateDifference()
      this.date1 = new Date(
        Math.max.apply(
          null,
          this.data3.CGHS_AMA_REFERENCE_DATE.map(function (e) {
            console.log('e', e);
            return new Date(e);
          })
        )
      );
      var date2: any = new Date(this.data2.BILL_FILIING_DATE);
      // console.log('date2',date2)
      // console.log('this.date2',this.date1)

      this.expoDiffDays = Math.floor(
        (date2 - this.date1) / (1000 * 60 * 60 * 24)
      );
      console.log('this.expoDiffDayssssss', this.expoDiffDays);
    } else {
      // console.log('this.data3.CGHS_AMA_REFERENCE_DATE3',this.data3.CGHS_AMA_REFERENCE_DATE);
    }
    if (
      this.data3.EMERGENCY_TREATEMENT == false &&
      this.data3.REFERENCE_FORM_CGHS_AMA_TAKEN == true &&
      this.expoDiffDays < 30
    ) {
      // this.onChangeExpoFacto(false);
      this.data3.EXPO_FACTO_PERMISSION = false;
      this.data3.EXPO_FACTO_DATE = null;
      this.data3.EXPO_FACTO_REFERENCE_NO = '';
      console.log('this.onChangeExpoFacto(false)');
    } else {
    }
  }

  HOSPITAL_ADDRESS;
  HOSPITAL_TYPE;
  HOSPITAL_NAME;
  HOSPITAL_ID;
  hospitalMapList: any = [];
  addHospital(addNew: boolean, hospitalFormReset: NgForm) {
    console.log('HOSPITAL_ADDRESS', this.hospitalData.ACCREDATION);
    console.log('HOSPITAL_ADDRESS', this.hospitalData.ADDRESS);
    console.log('HOSPITAL_ADDRESS', this.hospitalData.HOSPITAL_ID);
    console.log('HOSPITAL_ADDRESS', this.hospitalData.NAME);
    console.log('HOSPITAL_ADDRESS', this.hospitalData.TYPE);
    this.hospitalData.CLAIM_ID = this.claimID;
    this.isSpinning = false;
    this.isOk = true;
    // if(this.hospitalData.HOSPITAL_ID !=undefined || this.hospitalData.HOSPITAL_ID !=null){

    // }
    if (
      this.hospitalData.TYPE == '' &&
      this.hospitalData.ADDRESS == '' &&
      this.hospitalData.ACCREDATION == ''
    ) {
      this.isOk = false;
      this.message.error('Please Fill All The Required Fields ', '');
    } else if (
      this.hospitalData.TYPE == null ||
      this.hospitalData.TYPE.trim() == '' ||
      this.hospitalData.TYPE == undefined
    ) {
      this.isOk = false;
      this.message.error(' Please Select Hospital Type.', '');
    } else if (
      this.hospitalData.TYPE == 'E' &&
      (this.hospitalData.HOSPITAL_ID == undefined ||
        this.hospitalData.HOSPITAL_ID == null)
    ) {
      this.isOk = false;
      this.message.error('Please Select Hospital Name ', '');
    } else if (
      (this.hospitalData.TYPE == 'NE' || this.hospitalData.TYPE == 'G') &&
      (this.hospitalData.NAME == undefined ||
        this.hospitalData.NAME == null ||
        this.hospitalData.NAME == '')
    ) {
      this.isOk = false;
      this.message.error('Please Enter Hospital Name ', '');
    } else if (
      this.hospitalData.ADDRESS == undefined ||
      this.hospitalData.ADDRESS == null ||
      this.hospitalData.ADDRESS == ''
    ) {
      this.isOk = false;
      this.message.error('Please Enter Hospital Address ', '');
    } else if (
      this.hospitalData.ACCREDATION == undefined ||
      this.hospitalData.ACCREDATION == null ||
      this.hospitalData.ACCREDATION == ''
    ) {
      this.isOk = false;
      this.message.error('Please Select Accreditation ', '');
    }
    if (this.isOk) {
      this.isSpinning = true;
      {
        if (this.hospitalData.ID) {
          this.api
            .updateHospitalMapping(this.hospitalData)
            .subscribe((successCode) => {
              if (successCode.code == '200') {
                this.message.success('Information Changed Successfully...', '');
                hospitalFormReset.form.reset();
                this.resetDrawer(hospitalFormReset);
                // this.hospitalData.TYPE = null;
                // this.hospitalData.HOSPITAL_ID = null;
                // this.hospitalData.NAME = '';
                // this.hospitalData.ADDRESS = '';
                // this.hospitalData.ACCREDATION = '';
                this.api
                  .getHospitalMapping(
                    0,
                    0,
                    '',
                    'desc',
                    ' AND CLAIM_ID = ' + this.claimID
                  )
                  .subscribe(
                    (data) => {
                      if (data['code'] == 200) {
                        this.hospitalMapList = data['data'];
                        this.isSpinning = false;
                      } else {
                        this.message.error(
                          "Can't Load Hospital Mapped Data.",
                          ''
                        );
                        this.isSpinning = false;
                      }
                    },
                    (err) => {
                      console.log(err);
                    }
                  );
                // if (!addNew)
                // this.drawerClose();
                this.isSpinning = false;
              } else {
                this.message.error('Information Has Not Changed...', '');
                this.isSpinning = false;
              }
            });
        } else {
          this.api
            .createHospitalMapping(this.hospitalData)
            .subscribe((successCode) => {
              if (successCode.code == '200') {
                this.message.success('Information Save Successfully...', '');
                // this.hospitalData.TYPE = null;
                // this.hospitalData.HOSPITAL_ID = null;
                // this.hospitalData.NAME = '';
                // this.hospitalData.ADDRESS = '';
                // this.hospitalData.ACCREDATION = '';
                hospitalFormReset.form.reset();
                this.resetDrawer(hospitalFormReset);
                this.api
                  .getHospitalMapping(
                    0,
                    0,
                    '',
                    'desc',
                    ' AND CLAIM_ID = ' + this.claimID
                  )
                  .subscribe(
                    (data) => {
                      if (data['code'] == 200) {
                        this.hospitalMapList = data['data'];
                        this.isSpinning = false;
                      } else {
                        this.message.error(
                          "Can't Load Hospital Mapped Data.",
                          ''
                        );
                        this.isSpinning = false;
                      }
                    },
                    (err) => {
                      console.log(err);
                    }
                  );
                this.isSpinning = false;
              } else {
                this.message.error('Failed To Fill Information...', '');
                this.isSpinning = false;
              }
            });
        }
      }
    }
  }

  editHospital(data: any): void {
    console.log('data', data);

    // this.drawerTitle = 'Edit Claim Details';
    this.hospitalData = Object.assign({}, data);
  }

  confirmDeleteHospital(data: any) {
    console.log('data', data);
    console.log('i', data.ID);
    var hospitalData = {
      ID: data.ID,
    };
    this.api.deleteHospitalMapping(hospitalData).subscribe((successCode) => {
      if (successCode.code == '200') {
        this.message.success('Hospital Deleted Successfully...', '');
        this.api
          .getHospitalMapping(
            0,
            0,
            '',
            'desc',
            ' AND CLAIM_ID = ' + this.claimID
          )
          .subscribe(
            (data) => {
              if (data['code'] == 200) {
                this.hospitalMapList = data['data'];
                this.isSpinning = false;
              } else {
                this.message.error("Can't Load Hospital Mapped Data.", '');
                this.isSpinning = false;
              }
            },
            (err) => {
              console.log(err);
            }
          );
      } else {
        this.message.error('Hospital Has Not Deleted...', '');
        this.isSpinning = false;
      }
    });
    // this.api.deleteHospitalMapping(data1)
  }
  cancel(): void {}

  resetDrawer(hospitalFormReset: NgForm) {
    this.hospitalData = new HospitalMappingMaster();
    // this.data.PARENT_GROUP_ID=this.parentgroup;
    // hospitalFormReset.form.markAsPristine();
    // hospitalFormReset.form.markAsUntouched();
  }

  employeeSearch(event: any) {
    console.log('event', event);
    // console.log('event.key', event.key);
    // console.log('event.target.value', event.target.value);
    // this.procedureList2 = [];
    var f = '';
    if (event.length >= 3) {
      this.api
        .getEmployeeMaster(
          0,
          0,
          '',
          'asc',
          " AND NAME like '%" +
            event +
            "%'" +
            " OR EMPLOYEE_CODE like '%" +
            event +
            "%'" +
            " OR OFFICE_NAME like '%" +
            event +
            "%'" +
            " OR DESIGNATION like '%" +
            event +
            "%'" +
            " OR DDO_OF_THE_OFFICIAL like '%" +
            event +
            "%'" +
            f
        )
        .subscribe(
          (empData) => {
            if (empData['code'] == 200) {
              console.log('at', empData['data']);

              var filteredOptions = empData['data'];
              console.log('tfilteredOptions', filteredOptions);
              // this.employee = [...[], ...empData['data']];
              console.log('this.employee', this.employee);

              // this.empLoader = false;
            } else {
              this.message.error("Can't Load Employee Data", '');
              // this.empLoader = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
    }
    // this.api
    //   .getinvestigationprocedure(
    //     0,
    //     0,
    //     'NAME',
    //     'asc',
    //     " and NAME like '%" + event + "%'" + f
    //   )
    //   .subscribe(
    //     (data) => {
    //       if (data['code'] == 200 && data['data'].length > 0)
    //         this.procedureList2 = data['data'];
    //       else this.procedureList2 = [];
    //     },
    //     (err) => {
    //       console.log(err);
    //       this.isSpinning = false;
    //     }
    //   );
    // }
  }
  pressCS(event) {
    if (event == 'CS') {
      this.data.CGHS_CARD_VALIDITY = '';
      this.data.CGHS_CARD_NO = '';
    } else {
    }
  }
  clearRemark(event) {
    if (event == 'A') {
      this.data4.REJECT_REMARK = null;
      this.data4.ON_HOLD_REMARK = null;
      this.data4.ON_HOLD_DATE = null;
    } else if (event == 'R') {
      this.data4.ON_HOLD_REMARK = null;
      this.data4.ON_HOLD_DATE = null;
    } else {
      this.data4.REJECT_REMARK = null;
    }
  }

  orderSheetVisible: boolean = false;
  orderSheetTitle: string = '';
  orderValue: any = '';
  orderSheet() {
    this.orderValue = '';
    this.orderSheetTitle = 'Order Sheet';
    this.orderSheetVisible = true;
    // this.orderValue = 1;
  }
  permissionLetter() {
    this.orderValue = '';
    this.orderSheetTitle = 'Permission Letter';
    this.orderSheetVisible = true;
    // this.orderValue = 2;
  }

  orderSheetClose(): void {
    this.orderSheetVisible = false;
  }

  get orderSheetCloseCallback() {
    return this.orderSheetClose.bind(this);
  }
  HospitalMapping = [];
  queData: any = [];
  empID1;
  claimID1;
  // delayOrderSheetVisible: boolean = false;
  // delayOrderSheetTitle: string = '';
  // delayOrderSheet() {
  //   this.isSpinning = true;
  //   this.claimID1 = '';
  //   this.empID1 = '';
  //   this.delayOrderSheetTitle = 'Order Letter';
  //   this.isOk = true;
  //   if (this.isOk) {
  //     this.isSpinning = true;
  //     this.data3.DELAY_CONDOLENCE_DATE = this.datepipe.transform(
  //       this.data3.DELAY_CONDOLENCE_DATE,
  //       'yyyy-MM-dd'
  //     );
  //     this.data3.EXPOST_FACTO_PERMISSION_DATE = this.datepipe.transform(
  //       this.data3.EXPOST_FACTO_PERMISSION_DATE,
  //       'yyyy-MM-dd'
  //     );
  //     this.data3.EXPO_FACTO_DATE = this.datepipe.transform(
  //       this.data3.EXPO_FACTO_DATE,
  //       'yyyy-MM-dd'
  //     );
  //     this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
  //       this.data3.HOD_PERMISSION_DATE,
  //       'yyyy-MM-dd'
  //     );
  //     this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
  //       this.data3.HOD_PERMISSION_DATE,
  //       'yyyy-MM-dd'
  //     );
  //     if (
  //       this.data3.CGHS_AMA_REFERENCE_DATE == undefined &&
  //       this.data3.CGHS_AMA_REFERENCE_DATE == null &&
  //       this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
  //     ) {
  //       this.data3.CGHS_AMA_REFERENCE_DATE = '';
  //     } else {
  //       this.data3.CGHS_AMA_REFERENCE_DATE = this.data3.CGHS_AMA_REFERENCE_DATE;
  //     }
  //     if (
  //       this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined &&
  //       this.data3.CGHS_AMA_REFERENCE_END_DATE == null &&
  //       this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
  //     ) {
  //       this.data3.CGHS_AMA_REFERENCE_END_DATE = '';
  //     } else {
  //       this.data3.CGHS_AMA_REFERENCE_END_DATE =
  //         this.data3.CGHS_AMA_REFERENCE_END_DATE;
  //     }

  //     if (
  //       this.data3.BILL_FILLED_INTIME == false &&
  //       (this.data3.DELAY_CONDOLENCE_DATE != undefined ||
  //         this.data3.DELAY_CONDOLENCE_DATE != null)
  //     ) {
  //       this.data3.DELAY_CONDOLENCE_DATE = this.data3.DELAY_CONDOLENCE_DATE;
  //     } else {
  //       this.data3.DELAY_CONDOLENCE_DATE = null;
  //     }
  //     if (
  //       this.data3.BILL_FILLED_INTIME == false &&
  //       (this.data3.DELAY_CONDOLENCE_NUMBER != undefined ||
  //         this.data3.DELAY_CONDOLENCE_NUMBER != null)
  //     ) {
  //       this.data3.DELAY_CONDOLENCE_NUMBER = this.data3.DELAY_CONDOLENCE_NUMBER;
  //     } else {
  //       this.data3.DELAY_CONDOLENCE_NUMBER = '';
  //     }
  //     {
  //       if (this.data3.ID) {
  //         this.api.updateQuestions(this.data3).subscribe((successCode) => {
  //           if (successCode.code == '200') {
  //             // this.message.success('Information Changed Successfully...', '');
  //             this.api
  //               .getclaimMaster(
  //                 0,
  //                 0,
  //                 '',
  //                 '',
  //                 ' AND ID=' + this.claimID,
  //                 '',
  //                 '',
  //                 '',
  //                 ''
  //               )
  //               .subscribe(
  //                 (data) => {
  //                   if (data['code'] == 200) {
  //                     console.log(data);
  //                     this.orderdata1 = data['data'][0];
  //                     this.claimID1 = data['data'][0]['ID'];
  //                     this.empID1 = data['data'][0]['EMP_ID'];
  //                     console.log('this.orderdata1', this.orderdata1);

  //                     this.api
  //                       .getHospitalMapping(
  //                         0,
  //                         0,
  //                         '',
  //                         '',
  //                         ' AND CLAIM_ID = ' + this.claimID
  //                       )
  //                       .subscribe(
  //                         (data) => {
  //                           if (data['code'] == 200) {
  //                             this.HospitalMapping = data['data'];
  //                             console.log(
  //                               'this.HospitalMapping',
  //                               this.HospitalMapping
  //                             );
  //                             // this.isSpinning = false;
  //                             this.api
  //                               .getAllQuestions(
  //                                 0,
  //                                 0,
  //                                 '',
  //                                 '',
  //                                 ' AND CLAIM_ID =' + this.claimID
  //                               )
  //                               .subscribe(
  //                                 (data) => {
  //                                   if (data['code'] == 200) {
  //                                     if (data['data'].length == 0) {
  //                                       this.queData = new QuestionaryMaster();
  //                                     } else {
  //                                       this.queData = data['data'][0];
  //                                       if (
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_DATE ==
  //                                           undefined &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_DATE == null &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_DATE == '' &&
  //                                         this.queData.CGHS_AMA_REFERENCE_DATE
  //                                           .length == 0
  //                                       ) {
  //                                         this.queData.CGHS_AMA_REFERENCE_DATE =
  //                                           [];
  //                                       } else {
  //                                         this.queData.CGHS_AMA_REFERENCE_DATE =
  //                                           this.queData.CGHS_AMA_REFERENCE_DATE.split(
  //                                             ','
  //                                           );
  //                                       }
  //                                       if (
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_END_DATE ==
  //                                           undefined &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_END_DATE ==
  //                                           null &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_END_DATE ==
  //                                           '' &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_END_DATE
  //                                           .length == 0
  //                                       ) {
  //                                         this.queData.CGHS_AMA_REFERENCE_END_DATE =
  //                                           [];
  //                                       } else {
  //                                         this.queData.CGHS_AMA_REFERENCE_END_DATE =
  //                                           this.queData.CGHS_AMA_REFERENCE_END_DATE.split(
  //                                             ','
  //                                           );
  //                                       }
  //                                     }
  //                                   } else {
  //                                     this.message.error(
  //                                       'Something Went Wrong',
  //                                       ''
  //                                     );
  //                                     // this.isSpinning = false;
  //                                   }
  //                                 },
  //                                 (err) => {
  //                                   console.log(err);
  //                                 }
  //                               );
  //                           } else {
  //                             this.message.error('Something Went Wrong', '');
  //                             // this.isSpinning = false;
  //                           }
  //                         },
  //                         (err) => {
  //                           console.log(err);
  //                         }
  //                       );
  //                   } else {
  //                     this.message.error("Can't Load Data", '');
  //                   }
  //                 },
  //                 (err) => {
  //                   console.log(err);
  //                 }
  //               );
  //             this.isSpinning = false;
  //             this.delayOrderSheetVisible = true;
  //           } else {
  //             this.message.error('Information Has Not Changed...', '');
  //             this.isSpinning = false;
  //           }
  //         });
  //       } else {
  //         this.api.createQuestions(this.data3).subscribe((successCode) => {
  //           if (successCode.code == '200') {
  //             // this.message.success('Information Save Successfully...', '');
  //             this.api
  //               .getclaimMaster(
  //                 0,
  //                 0,
  //                 '',
  //                 '',
  //                 ' AND ID=' + this.claimID,
  //                 '',
  //                 '',
  //                 '',
  //                 ''
  //               )
  //               .subscribe(
  //                 (data) => {
  //                   if (data['code'] == 200) {
  //                     console.log(data);
  //                     this.orderdata1 = data['data'][0];
  //                     this.claimID1 = data['data'][0]['ID'];
  //                     this.empID1 = data['data'][0]['EMP_ID'];
  //                     console.log('this.orderdata1', this.orderdata1);
  //                     this.api
  //                       .getHospitalMapping(
  //                         0,
  //                         0,
  //                         '',
  //                         '',
  //                         ' AND CLAIM_ID = ' + this.claimID
  //                       )
  //                       .subscribe(
  //                         (data) => {
  //                           if (data['code'] == 200) {
  //                             this.HospitalMapping = data['data'];
  //                             console.log(
  //                               'this.HospitalMapping',
  //                               this.HospitalMapping
  //                             );
  //                             // this.isSpinning = false;
  //                             this.api
  //                               .getAllQuestions(
  //                                 0,
  //                                 0,
  //                                 '',
  //                                 '',
  //                                 ' AND CLAIM_ID =' + this.claimID
  //                               )
  //                               .subscribe(
  //                                 (data) => {
  //                                   if (data['code'] == 200) {
  //                                     if (data['data'].length == 0) {
  //                                       this.queData = new QuestionaryMaster();
  //                                     } else {
  //                                       this.queData = data['data'][0];
  //                                       if (
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_DATE ==
  //                                           undefined &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_DATE == null &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_DATE == '' &&
  //                                         this.queData.CGHS_AMA_REFERENCE_DATE
  //                                           .length == 0
  //                                       ) {
  //                                         this.queData.CGHS_AMA_REFERENCE_DATE =
  //                                           [];
  //                                       } else {
  //                                         this.queData.CGHS_AMA_REFERENCE_DATE =
  //                                           this.queData.CGHS_AMA_REFERENCE_DATE.split(
  //                                             ','
  //                                           );
  //                                       }
  //                                       if (
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_END_DATE ==
  //                                           undefined &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_END_DATE ==
  //                                           null &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_END_DATE ==
  //                                           '' &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_END_DATE
  //                                           .length == 0
  //                                       ) {
  //                                         this.queData.CGHS_AMA_REFERENCE_END_DATE =
  //                                           [];
  //                                       } else {
  //                                         this.queData.CGHS_AMA_REFERENCE_END_DATE =
  //                                           this.queData.CGHS_AMA_REFERENCE_END_DATE.split(
  //                                             ','
  //                                           );
  //                                       }
  //                                     }
  //                                   } else {
  //                                     this.message.error(
  //                                       'Something Went Wrong',
  //                                       ''
  //                                     );
  //                                     this.isSpinning = false;
  //                                   }
  //                                 },
  //                                 (err) => {
  //                                   console.log(err);
  //                                 }
  //                               );
  //                           } else {
  //                             this.message.error('Something Went Wrong', '');
  //                             // this.isSpinning = false;
  //                           }
  //                         },
  //                         (err) => {
  //                           console.log(err);
  //                         }
  //                       );
  //                   } else {
  //                     this.message.error("Can't Load Data", '');
  //                   }
  //                 },
  //                 (err) => {
  //                   console.log(err);
  //                 }
  //               );
  //             this.delayOrderSheetVisible = true;
  //             this.isSpinning = false;
  //           } else {
  //             this.message.error('Failed To Fill Information...', '');
  //             this.isSpinning = false;
  //           }
  //         });
  //       }
  //     }
  //   }
  //   this.orderdata1 = [];
  // }

  // delayOrderSheetClose(): void {
  //   // this.delayOrderSheetVisible = false;
  //   this.disableReferanceDate();
  //   this.api
  //     .getAllQuestions(0, 0, '', '', ' AND CLAIM_ID =' + this.claimID)
  //     .subscribe(
  //       (data) => {
  //         if (data['code'] == 200) {
  //           if (data['data'].length == 0) {
  //             this.data3 = new QuestionaryMaster();
  //             this.billInTime();
  //             this.empanelledHospital();
  //             // this.current = 2;
  //             this.delayOrderSheetVisible = false;
  //             this.isSpinning = false;
  //           } else {
  //             this.data3 = data['data'][0];
  //             this.emergencyTaken = data['data'][0]['EMERGENCY_TREATEMENT'];
  //             this.drCertificate =
  //               data['data'][0]['DOCTOR_EMERGENCY_CERTIFICATE_TAKEN'];
  //             this.empanelHospital = data['data'][0]['IS_HOSPITAL_EMPLANELLED'];
  //             this.expoFacto = data['data'][0]['EXPO_FACTO_PERMISSION'];
  //             this.referanceTaken =
  //               data['data'][0]['REFERENCE_FORM_CGHS_AMA_TAKEN'];
  //             this.hodPermission =
  //               data['data'][0]['IS_PERMISSION_TAKEN_FROM_HOD'];
  //             if (
  //               this.data3.CGHS_AMA_REFERENCE_DATE == undefined &&
  //               this.data3.CGHS_AMA_REFERENCE_DATE == null &&
  //               this.data3.CGHS_AMA_REFERENCE_DATE == '' &&
  //               this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
  //             ) {
  //               this.data3.CGHS_AMA_REFERENCE_DATE = [];
  //             } else {
  //               this.data3.CGHS_AMA_REFERENCE_DATE =
  //                 this.data3.CGHS_AMA_REFERENCE_DATE.split(',');
  //               this.dateDifference();
  //             }
  //             if (
  //               this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined &&
  //               this.data3.CGHS_AMA_REFERENCE_END_DATE == null &&
  //               this.data3.CGHS_AMA_REFERENCE_END_DATE == '' &&
  //               this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
  //             ) {
  //               this.data3.CGHS_AMA_REFERENCE_END_DATE = [];
  //             } else {
  //               this.data3.CGHS_AMA_REFERENCE_END_DATE =
  //                 this.data3.CGHS_AMA_REFERENCE_END_DATE.split(',');
  //               this.dateDifference();
  //             }
  //             this.calculateDiff();
  //             this.billInTime();
  //             this.empanelledHospital();
  //             // this.current = 2;
  //             this.isSpinning = false;
  //             this.delayOrderSheetVisible = false;
  //           }
  //         } else {
  //           this.message.error('Something Went Wrong', '');
  //           this.isSpinning = false;
  //         }
  //       },
  //       (err) => {
  //         console.log(err);
  //       }
  //     );
  // }

  delayOrderSheetCloseNew(): void {
    this.api
      .getAllQuestions(0, 0, '', '', ' AND CLAIM_ID =' + this.claimID)
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            if (data['data'].length == 0) {
              this.data3 = new QuestionaryMaster();
              this.billInTime();
              this.empanelledHospital();
              this.delayOrderSheetnewVisible = false;
              this.isSpinning = false;
            } else {
              this.data3 = data['data'][0];
              this.emergencyTaken = data['data'][0]['EMERGENCY_TREATEMENT'];
              this.drCertificate =
                data['data'][0]['DOCTOR_EMERGENCY_CERTIFICATE_TAKEN'];
              this.empanelHospital = data['data'][0]['IS_HOSPITAL_EMPLANELLED'];
              this.expoFacto = data['data'][0]['EXPO_FACTO_PERMISSION'];
              this.referanceTaken =
                data['data'][0]['REFERENCE_FORM_CGHS_AMA_TAKEN'];
              this.hodPermission =
                data['data'][0]['IS_PERMISSION_TAKEN_FROM_HOD'];
              if (
                this.data3.CGHS_AMA_REFERENCE_DATE == undefined ||
                this.data3.CGHS_AMA_REFERENCE_DATE == null ||
                this.data3.CGHS_AMA_REFERENCE_DATE == '' ||
                this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
              ) {
                this.data3.CGHS_AMA_REFERENCE_DATE = [];
              } else {
                this.data3.CGHS_AMA_REFERENCE_DATE =
                  this.data3.CGHS_AMA_REFERENCE_DATE.split(',');
                // this.dateDifference();
              }
              if (
                this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined ||
                this.data3.CGHS_AMA_REFERENCE_END_DATE == null ||
                this.data3.CGHS_AMA_REFERENCE_END_DATE == '' ||
                this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
              ) {
                this.data3.CGHS_AMA_REFERENCE_END_DATE = [];
              } else {
                this.data3.CGHS_AMA_REFERENCE_END_DATE =
                  this.data3.CGHS_AMA_REFERENCE_END_DATE.split(',');
                this.dateDifference();
              }
              // this.calculateDiff();
              this.billInTime();
              this.empanelledHospital();
              this.delayOrderSheetnewVisible = false;
              this.isSpinning = false;
            }
          } else {
            this.message.error('Something Went Wrong', '');
            this.isSpinning = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }
  get delayOrderSheetCloseCallback() {
    return this.delayOrderSheetCloseNew.bind(this);
  }

  // delayPermissionVisible: boolean = false;
  // delayPermissionTitle: string = '';
  // delayPermissionData: any = [];
  // delayPermissionClaimData = [];
  // delayPermission() {
  //   this.delayPermissionTitle = 'Permission Letter';
  //   this.delayPermissionVisible = true;
  //   if (this.isOk) {
  //     this.isSpinning = true;
  //     this.data3.DELAY_CONDOLENCE_DATE = this.datepipe.transform(
  //       this.data3.DELAY_CONDOLENCE_DATE,
  //       'yyyy-MM-dd'
  //     );
  //     this.data3.EXPOST_FACTO_PERMISSION_DATE = this.datepipe.transform(
  //       this.data3.EXPOST_FACTO_PERMISSION_DATE,
  //       'yyyy-MM-dd'
  //     );
  //     this.data3.EXPO_FACTO_DATE = this.datepipe.transform(
  //       this.data3.EXPO_FACTO_DATE,
  //       'yyyy-MM-dd'
  //     );
  //     this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
  //       this.data3.HOD_PERMISSION_DATE,
  //       'yyyy-MM-dd'
  //     );
  //     this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
  //       this.data3.HOD_PERMISSION_DATE,
  //       'yyyy-MM-dd'
  //     );
  //     if (
  //       this.data3.CGHS_AMA_REFERENCE_DATE == undefined &&
  //       this.data3.CGHS_AMA_REFERENCE_DATE == null &&
  //       this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
  //     ) {
  //       this.data3.CGHS_AMA_REFERENCE_DATE = '';
  //     } else {
  //       this.data3.CGHS_AMA_REFERENCE_DATE = this.data3.CGHS_AMA_REFERENCE_DATE;
  //     }
  //     if (
  //       this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined &&
  //       this.data3.CGHS_AMA_REFERENCE_END_DATE == null &&
  //       this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
  //     ) {
  //       this.data3.CGHS_AMA_REFERENCE_END_DATE = '';
  //     } else {
  //       this.data3.CGHS_AMA_REFERENCE_END_DATE =
  //         this.data3.CGHS_AMA_REFERENCE_END_DATE;
  //     }

  //     if (
  //       this.data3.BILL_FILLED_INTIME == false &&
  //       (this.data3.DELAY_CONDOLENCE_DATE != undefined ||
  //         this.data3.DELAY_CONDOLENCE_DATE != null)
  //     ) {
  //       this.data3.DELAY_CONDOLENCE_DATE = this.data3.DELAY_CONDOLENCE_DATE;
  //     } else {
  //       this.data3.DELAY_CONDOLENCE_DATE = null;
  //     }
  //     if (
  //       this.data3.BILL_FILLED_INTIME == false &&
  //       (this.data3.DELAY_CONDOLENCE_NUMBER != undefined ||
  //         this.data3.DELAY_CONDOLENCE_NUMBER != null)
  //     ) {
  //       this.data3.DELAY_CONDOLENCE_NUMBER = this.data3.DELAY_CONDOLENCE_NUMBER;
  //     } else {
  //       this.data3.DELAY_CONDOLENCE_NUMBER = '';
  //     }
  //     {
  //       if (this.data3.ID) {
  //         this.api.updateQuestions(this.data3).subscribe((successCode) => {
  //           if (successCode.code == '200') {
  //             // this.message.success('Information Changed Successfully...', '');
  //             this.api
  //               .getclaimMaster(
  //                 0,
  //                 0,
  //                 '',
  //                 '',
  //                 ' AND ID=' + this.claimID,
  //                 '',
  //                 '',
  //                 '',
  //                 ''
  //               )
  //               .subscribe(
  //                 (data) => {
  //                   if (data['code'] == 200) {
  //                     console.log(data);
  //                     this.orderdata1 = data['data'][0];
  //                     this.claimID1 = data['data'][0]['ID'];
  //                     this.empID1 = data['data'][0]['EMP_ID'];
  //                     console.log('this.orderdata1', this.orderdata1);

  //                     this.api
  //                       .getHospitalMapping(
  //                         0,
  //                         0,
  //                         '',
  //                         '',
  //                         ' AND CLAIM_ID = ' + this.claimID
  //                       )
  //                       .subscribe(
  //                         (data) => {
  //                           if (data['code'] == 200) {
  //                             this.HospitalMapping = data['data'];
  //                             console.log(
  //                               'this.HospitalMapping',
  //                               this.HospitalMapping
  //                             );
  //                             // this.isSpinning = false;
  //                             this.api
  //                               .getAllQuestions(
  //                                 0,
  //                                 0,
  //                                 '',
  //                                 '',
  //                                 ' AND CLAIM_ID =' + this.claimID
  //                               )
  //                               .subscribe(
  //                                 (data) => {
  //                                   if (data['code'] == 200) {
  //                                     if (data['data'].length == 0) {
  //                                       this.queData = new QuestionaryMaster();
  //                                     } else {
  //                                       this.queData = data['data'][0];
  //                                       if (
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_DATE ==
  //                                           undefined &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_DATE == null &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_DATE == '' &&
  //                                         this.queData.CGHS_AMA_REFERENCE_DATE
  //                                           .length == 0
  //                                       ) {
  //                                         this.queData.CGHS_AMA_REFERENCE_DATE =
  //                                           [];
  //                                       } else {
  //                                         this.queData.CGHS_AMA_REFERENCE_DATE =
  //                                           this.queData.CGHS_AMA_REFERENCE_DATE.split(
  //                                             ','
  //                                           );
  //                                       }
  //                                       if (
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_END_DATE ==
  //                                           undefined &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_END_DATE ==
  //                                           null &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_END_DATE ==
  //                                           '' &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_END_DATE
  //                                           .length == 0
  //                                       ) {
  //                                         this.queData.CGHS_AMA_REFERENCE_END_DATE =
  //                                           [];
  //                                       } else {
  //                                         this.queData.CGHS_AMA_REFERENCE_END_DATE =
  //                                           this.queData.CGHS_AMA_REFERENCE_END_DATE.split(
  //                                             ','
  //                                           );
  //                                       }
  //                                     }
  //                                   } else {
  //                                     this.message.error(
  //                                       'Something Went Wrong',
  //                                       ''
  //                                     );
  //                                     // this.isSpinning = false;
  //                                   }
  //                                 },
  //                                 (err) => {
  //                                   console.log(err);
  //                                 }
  //                               );
  //                           } else {
  //                             this.message.error('Something Went Wrong', '');
  //                             // this.isSpinning = false;
  //                           }
  //                         },
  //                         (err) => {
  //                           console.log(err);
  //                         }
  //                       );
  //                   } else {
  //                     this.message.error("Can't Load Data", '');
  //                   }
  //                 },
  //                 (err) => {
  //                   console.log(err);
  //                 }
  //               );
  //             this.isSpinning = false;
  //             this.delayPermissionVisible = true;
  //           } else {
  //             this.message.error('Information Has Not Changed...', '');
  //             this.isSpinning = false;
  //           }
  //         });
  //       } else {
  //         this.api.createQuestions(this.data3).subscribe((successCode) => {
  //           if (successCode.code == '200') {
  //             // this.message.success('Information Save Successfully...', '');
  //             this.api
  //               .getclaimMaster(
  //                 0,
  //                 0,
  //                 '',
  //                 '',
  //                 ' AND ID=' + this.claimID,
  //                 '',
  //                 '',
  //                 '',
  //                 ''
  //               )
  //               .subscribe(
  //                 (data) => {
  //                   if (data['code'] == 200) {
  //                     console.log(data);
  //                     this.orderdata1 = data['data'][0];
  //                     this.claimID1 = data['data'][0]['ID'];
  //                     this.empID1 = data['data'][0]['EMP_ID'];
  //                     console.log('this.orderdata1', this.orderdata1);
  //                     this.api
  //                       .getHospitalMapping(
  //                         0,
  //                         0,
  //                         '',
  //                         '',
  //                         ' AND CLAIM_ID = ' + this.claimID
  //                       )
  //                       .subscribe(
  //                         (data) => {
  //                           if (data['code'] == 200) {
  //                             this.HospitalMapping = data['data'];
  //                             console.log(
  //                               'this.HospitalMapping',
  //                               this.HospitalMapping
  //                             );
  //                             // this.isSpinning = false;
  //                             this.api
  //                               .getAllQuestions(
  //                                 0,
  //                                 0,
  //                                 '',
  //                                 '',
  //                                 ' AND CLAIM_ID =' + this.claimID
  //                               )
  //                               .subscribe(
  //                                 (data) => {
  //                                   if (data['code'] == 200) {
  //                                     if (data['data'].length == 0) {
  //                                       this.queData = new QuestionaryMaster();
  //                                     } else {
  //                                       this.queData = data['data'][0];
  //                                       if (
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_DATE ==
  //                                           undefined &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_DATE == null &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_DATE == '' &&
  //                                         this.queData.CGHS_AMA_REFERENCE_DATE
  //                                           .length == 0
  //                                       ) {
  //                                         this.queData.CGHS_AMA_REFERENCE_DATE =
  //                                           [];
  //                                       } else {
  //                                         this.queData.CGHS_AMA_REFERENCE_DATE =
  //                                           this.queData.CGHS_AMA_REFERENCE_DATE.split(
  //                                             ','
  //                                           );
  //                                       }
  //                                       if (
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_END_DATE ==
  //                                           undefined &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_END_DATE ==
  //                                           null &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_END_DATE ==
  //                                           '' &&
  //                                         this.queData
  //                                           .CGHS_AMA_REFERENCE_END_DATE
  //                                           .length == 0
  //                                       ) {
  //                                         this.queData.CGHS_AMA_REFERENCE_END_DATE =
  //                                           [];
  //                                       } else {
  //                                         this.queData.CGHS_AMA_REFERENCE_END_DATE =
  //                                           this.queData.CGHS_AMA_REFERENCE_END_DATE.split(
  //                                             ','
  //                                           );
  //                                       }
  //                                     }
  //                                   } else {
  //                                     this.message.error(
  //                                       'Something Went Wrong',
  //                                       ''
  //                                     );
  //                                     this.isSpinning = false;
  //                                   }
  //                                 },
  //                                 (err) => {
  //                                   console.log(err);
  //                                 }
  //                               );
  //                           } else {
  //                             this.message.error('Something Went Wrong', '');
  //                             // this.isSpinning = false;
  //                           }
  //                         },
  //                         (err) => {
  //                           console.log(err);
  //                         }
  //                       );
  //                   } else {
  //                     this.message.error("Can't Load Data", '');
  //                   }
  //                 },
  //                 (err) => {
  //                   console.log(err);
  //                 }
  //               );
  //             this.delayPermissionVisible = true;
  //             this.isSpinning = false;
  //           } else {
  //             this.message.error('Failed To Fill Information...', '');
  //             this.isSpinning = false;
  //           }
  //         });
  //       }
  //     }
  //   }
  //   this.orderdata1 = [];
  // }

  // delayPermissionClose(): void {
  //   this.api
  //     .getAllQuestions(0, 0, '', '', ' AND CLAIM_ID =' + this.claimID)
  //     .subscribe(
  //       (data) => {
  //         if (data['code'] == 200) {
  //           if (data['data'].length == 0) {
  //             this.data3 = new QuestionaryMaster();
  //             this.billInTime();
  //             this.empanelledHospital();
  //             this.delayPermissionVisible = false;
  //             this.isSpinning = false;
  //           } else {
  //             this.data3 = data['data'][0];
  //             this.emergencyTaken = data['data'][0]['EMERGENCY_TREATEMENT'];
  //             this.drCertificate =
  //               data['data'][0]['DOCTOR_EMERGENCY_CERTIFICATE_TAKEN'];
  //             this.empanelHospital = data['data'][0]['IS_HOSPITAL_EMPLANELLED'];
  //             this.expoFacto = data['data'][0]['EXPO_FACTO_PERMISSION'];
  //             this.referanceTaken =
  //               data['data'][0]['REFERENCE_FORM_CGHS_AMA_TAKEN'];
  //             this.hodPermission =
  //               data['data'][0]['IS_PERMISSION_TAKEN_FROM_HOD'];
  //             if (
  //               this.data3.CGHS_AMA_REFERENCE_DATE == undefined ||
  //               this.data3.CGHS_AMA_REFERENCE_DATE == null ||
  //               this.data3.CGHS_AMA_REFERENCE_DATE == '' ||
  //               this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
  //             ) {
  //               this.data3.CGHS_AMA_REFERENCE_DATE = [];
  //             } else {
  //               this.data3.CGHS_AMA_REFERENCE_DATE =
  //                 this.data3.CGHS_AMA_REFERENCE_DATE.split(',');
  //               // this.dateDifference();
  //             }
  //             if (
  //               this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined ||
  //               this.data3.CGHS_AMA_REFERENCE_END_DATE == null ||
  //               this.data3.CGHS_AMA_REFERENCE_END_DATE == '' ||
  //               this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
  //             ) {
  //               this.data3.CGHS_AMA_REFERENCE_END_DATE = [];
  //             } else {
  //               this.data3.CGHS_AMA_REFERENCE_END_DATE =
  //                 this.data3.CGHS_AMA_REFERENCE_END_DATE.split(',');
  //               this.dateDifference();
  //             }
  //             // this.calculateDiff();
  //             this.billInTime();
  //             this.empanelledHospital();
  //             this.delayPermissionVisible = false;
  //             this.isSpinning = false;
  //           }
  //         } else {
  //           this.message.error('Something Went Wrong', '');
  //           this.isSpinning = false;
  //         }
  //       },
  //       (err) => {
  //         console.log(err);
  //       }
  //     );
  // }

  get delayPermissionCloseCallback() {
    return this.delayPermissionClosenew.bind(this);
  }

  delayPermissionVisiblenew: any;
  delayPermissionTitlenew: any;
  delayOrderSheetnewVisible: any;
  delayOrderSheetnewTitle: any;
  orderdata = [];
  dateBetweenDiff: any;
  sixMonthDate: any;
  addMonths(date: any, months) {
    date = new Date(date);
    this.sixMonthDate = date.setMonth(date.getMonth() + months);
    console.log('var dat', this.sixMonthDate);
    // return date;
  }
  delayOrderSheetnew() {
    this.delayOrderSheetnewTitle = 'Delay Order Sheet';
    // this.delayOrderSheetnewVisible = true;
    this.claimID1 = '';
    this.empID1 = '';
    this.data3.EMP_ID = this.empID;
    this.data3.CLAIM_ID = this.claimID;
    if (this.isOk) {
      this.isSpinning = true;
      this.data3.DELAY_CONDOLENCE_DATE = this.datepipe.transform(
        this.data3.DELAY_CONDOLENCE_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPOST_FACTO_PERMISSION_DATE = this.datepipe.transform(
        this.data3.EXPOST_FACTO_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPO_FACTO_DATE = this.datepipe.transform(
        this.data3.EXPO_FACTO_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      if (
        this.data3.CGHS_AMA_REFERENCE_DATE == undefined &&
        this.data3.CGHS_AMA_REFERENCE_DATE == null &&
        this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
      ) {
        this.data3.CGHS_AMA_REFERENCE_DATE = '';
      } else {
        this.data3.CGHS_AMA_REFERENCE_DATE = this.data3.CGHS_AMA_REFERENCE_DATE;
      }
      if (
        this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE == null &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
      ) {
        this.data3.CGHS_AMA_REFERENCE_END_DATE = '';
      } else {
        this.data3.CGHS_AMA_REFERENCE_END_DATE =
          this.data3.CGHS_AMA_REFERENCE_END_DATE;
      }

      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_DATE != undefined ||
          this.data3.DELAY_CONDOLENCE_DATE != null)
      ) {
        this.data3.DELAY_CONDOLENCE_DATE = this.data3.DELAY_CONDOLENCE_DATE;
      } else {
        this.data3.DELAY_CONDOLENCE_DATE = null;
      }

      if (
        this.data3.TREATEMENT_PERMISSION_START_DATE == undefined ||
        this.data3.TREATEMENT_PERMISSION_START_DATE == null ||
        this.data3.TREATEMENT_PERMISSION_START_DATE == ''
      ) {
        this.data3.TREATEMENT_PERMISSION_START_DATE = null;
      } else {
        this.data3.TREATEMENT_PERMISSION_START_DATE = this.datepipe.transform(
          this.data3.TREATEMENT_PERMISSION_START_DATE,
          'yyyy-MM-dd'
        );
      }
      if (
        this.data3.TREATEMENT_PERMISSION_END_DATE == undefined ||
        this.data3.TREATEMENT_PERMISSION_END_DATE == null ||
        this.data3.TREATEMENT_PERMISSION_END_DATE == ''
      ) {
        this.data3.TREATEMENT_PERMISSION_END_DATE = null;
      } else {
        this.data3.TREATEMENT_PERMISSION_END_DATE = this.datepipe.transform(
          this.data3.TREATEMENT_PERMISSION_END_DATE,
          'yyyy-MM-dd'
        );
      }
      if (
        this.data3.BILL_SUBMISSION_DATE == undefined ||
        this.data3.BILL_SUBMISSION_DATE == null ||
        this.data3.BILL_SUBMISSION_DATE == ''
      ) {
        this.data3.BILL_SUBMISSION_DATE = null;
      } else {
        this.data3.BILL_SUBMISSION_DATE = this.datepipe.transform(
          this.data3.BILL_SUBMISSION_DATE,
          'yyyy-MM-dd'
        );
      }

      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_NUMBER != undefined ||
          this.data3.DELAY_CONDOLENCE_NUMBER != null)
      ) {
        this.data3.DELAY_CONDOLENCE_NUMBER = this.data3.DELAY_CONDOLENCE_NUMBER;
      } else {
        this.data3.DELAY_CONDOLENCE_NUMBER = '';
      }
      {
        if (this.data3.ID) {
          this.api.updateQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              // this.message.success('Information Changed Successfully...', '');
              this.api
                .getclaimMaster(
                  0,
                  0,
                  '',
                  '',
                  ' AND ID=' + this.claimID,
                  '',
                  '',
                  '',
                  ''
                )
                .subscribe(
                  (data) => {
                    if (data['code'] == 200) {
                      this.orderdata1 = data['data'][0];
                      this.claimID1 = data['data'][0]['ID'];
                      this.empID1 = data['data'][0]['EMP_ID'];
                      var date1 = data['data'][0]['TREATMENT_END_DATE'];
                      console.log('date::', date1);

                      // var today = new Date(date1);
                      // today.setMonth(today.getMonth() + 6);
                      // console.log(
                      //   'toLocaleDateString',
                      //   today.toLocaleDateString()
                      // );

                      this.addMonths(
                        new Date(data['data'][0]['TREATMENT_END_DATE']),
                        6
                      );
                      console.log(
                        'sixMonthDatesixMonthDatea',
                        (this.sixMonthDate = this.datepipe.transform(
                          this.sixMonthDate,
                          'yyyy-MM-dd'
                        ))
                      );
                      if (
                        data['data'][0]['CLAIM_SUBMITTED_DATE'] != undefined &&
                        data['data'][0]['CLAIM_SUBMITTED_DATE'] != null &&
                        data['data'][0]['CLAIM_SUBMITTED_DATE'] != ''
                      ) {
                        var future = moment(
                          data['data'][0]['CLAIM_SUBMITTED_DATE']
                        );
                        var start = moment(this.sixMonthDate);
                        this.dateBetweenDiff = future.diff(start, 'days');
                      } else {
                      }
                      this.api
                        .getSignature(
                          0,
                          0,
                          'ID',
                          'desc',
                          ' AND ID = ' + data['data'][0]['SIGNATURE_ID']
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.SECTION_TYPE =
                                data['data'][0]['SECTION_TYPE'];
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                      this.api
                        .getHospitalMapping(
                          0,
                          0,
                          '',
                          'desc',
                          ' AND CLAIM_ID = ' + this.claimID
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.HospitalMapping = data['data'];
                              console.log(
                                'this.HospitalMapping',
                                this.HospitalMapping
                              );
                              // this.isSpinning = false;
                              this.api
                                .getAllQuestions(
                                  0,
                                  0,
                                  '',
                                  '',
                                  ' AND CLAIM_ID =' + this.claimID
                                )
                                .subscribe(
                                  (data) => {
                                    if (data['code'] == 200) {
                                      if (data['data'].length == 0) {
                                        this.queData = new QuestionaryMaster();
                                      } else {
                                        this.queData = data['data'][0];
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == '' &&
                                          this.queData.CGHS_AMA_REFERENCE_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_DATE.split(
                                              ','
                                            );
                                        }
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            '' &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_END_DATE.split(
                                              ','
                                            );
                                        }
                                      }
                                      this.delayOrderSheetnewVisible = true;
                                    } else {
                                      this.message.error(
                                        'Something Went Wrong',
                                        ''
                                      );
                                      // this.isSpinning = false;
                                    }
                                  },
                                  (err) => {
                                    console.log(err);
                                  }
                                );
                            } else {
                              this.message.error('Something Went Wrong', '');
                              // this.isSpinning = false;
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                    } else {
                      this.message.error("Can't Load Data", '');
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              this.isSpinning = false;
            } else {
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
        } else {
          this.api.createQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              // this.message.success('Information Save Successfully...', '');
              this.api
                .getclaimMaster(
                  0,
                  0,
                  '',
                  '',
                  ' AND ID=' + this.claimID,
                  '',
                  '',
                  '',
                  ''
                )
                .subscribe(
                  (data) => {
                    if (data['code'] == 200) {
                      this.orderdata1 = data['data'][0];
                      this.claimID1 = data['data'][0]['ID'];
                      this.empID1 = data['data'][0]['EMP_ID'];
                      var date = data['data'][0]['TREATMENT_END_DATE'];
                      console.log('date::', date);
                      this.addMonths(
                        new Date(data['data'][0]['TREATMENT_END_DATE']),
                        6
                      );
                      console.log(
                        'sixMonthDatesixMonthDatea',
                        (this.sixMonthDate = this.datepipe.transform(
                          this.sixMonthDate,
                          'yyyy-MM-dd'
                        ))
                      );
                      if (
                        data['data'][0]['CLAIM_SUBMITTED_DATE'] != undefined &&
                        data['data'][0]['CLAIM_SUBMITTED_DATE'] != null &&
                        data['data'][0]['CLAIM_SUBMITTED_DATE'] != ''
                      ) {
                        var future = moment(
                          data['data'][0]['CLAIM_SUBMITTED_DATE']
                        );
                        var start = moment(this.sixMonthDate);
                        this.dateBetweenDiff = future.diff(start, 'days');
                      } else {
                      }
                      this.api
                        .getSignature(
                          0,
                          0,
                          'ID',
                          'desc',
                          ' AND ID = ' + data['data'][0]['SIGNATURE_ID']
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.SECTION_TYPE =
                                data['data'][0]['SECTION_TYPE'];
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                      this.api
                        .getHospitalMapping(
                          0,
                          0,
                          '',
                          'desc',
                          ' AND CLAIM_ID = ' + this.claimID
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.HospitalMapping = data['data'];
                              console.log(
                                'this.HospitalMapping',
                                this.HospitalMapping
                              );
                              // this.isSpinning = false;
                              this.api
                                .getAllQuestions(
                                  0,
                                  0,
                                  '',
                                  '',
                                  ' AND CLAIM_ID =' + this.claimID
                                )
                                .subscribe(
                                  (data) => {
                                    if (data['code'] == 200) {
                                      if (data['data'].length == 0) {
                                        this.queData = new QuestionaryMaster();
                                      } else {
                                        this.queData = data['data'][0];
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == '' &&
                                          this.queData.CGHS_AMA_REFERENCE_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_DATE.split(
                                              ','
                                            );
                                        }
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            '' &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_END_DATE.split(
                                              ','
                                            );
                                        }
                                      }
                                      this.delayOrderSheetnewVisible = true;
                                    } else {
                                      this.message.error(
                                        'Something Went Wrong',
                                        ''
                                      );
                                      this.isSpinning = false;
                                    }
                                  },
                                  (err) => {
                                    console.log(err);
                                  }
                                );
                            } else {
                              this.message.error('Something Went Wrong', '');
                              // this.isSpinning = false;
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                    } else {
                      this.message.error("Can't Load Data", '');
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              this.isSpinning = false;
            } else {
              this.message.error('Failed To Fill Information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
    this.orderdata1 = [];
  }

  delayOrderSheetnewClose(): void {
    this.delayOrderSheetnewVisible = false;
  }
  delayPermissionClosenew(): void {
    this.delayPermissionVisiblenew = false;
  }
  SECTION_TYPE: any;
  NAME: any;
  NAME_HN: any;
  OFFICE_NAME: any;
  OFFICE_NAME_HN: any;
  POST: any;
  POST_HN: any;
  permissionnewLetter() {
    this.delayPermissionTitlenew = 'Delay Permission Letter';
    // this.delayPermissionVisiblenew = true;
    this.claimID1 = '';
    this.empID1 = '';
    this.data3.EMP_ID = this.empID;
    this.data3.CLAIM_ID = this.claimID;
    if (this.isOk) {
      this.isSpinning = true;
      this.data3.DELAY_CONDOLENCE_DATE = this.datepipe.transform(
        this.data3.DELAY_CONDOLENCE_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPOST_FACTO_PERMISSION_DATE = this.datepipe.transform(
        this.data3.EXPOST_FACTO_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPO_FACTO_DATE = this.datepipe.transform(
        this.data3.EXPO_FACTO_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      if (
        this.data3.CGHS_AMA_REFERENCE_DATE == undefined &&
        this.data3.CGHS_AMA_REFERENCE_DATE == null &&
        this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
      ) {
        this.data3.CGHS_AMA_REFERENCE_DATE = '';
      } else {
        this.data3.CGHS_AMA_REFERENCE_DATE = this.data3.CGHS_AMA_REFERENCE_DATE;
      }
      if (
        this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE == null &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
      ) {
        this.data3.CGHS_AMA_REFERENCE_END_DATE = '';
      } else {
        this.data3.CGHS_AMA_REFERENCE_END_DATE =
          this.data3.CGHS_AMA_REFERENCE_END_DATE;
      }

      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_DATE != undefined ||
          this.data3.DELAY_CONDOLENCE_DATE != null)
      ) {
        this.data3.DELAY_CONDOLENCE_DATE = this.data3.DELAY_CONDOLENCE_DATE;
      } else {
        this.data3.DELAY_CONDOLENCE_DATE = null;
      }

      if (
        this.data3.TREATEMENT_PERMISSION_START_DATE == undefined ||
        this.data3.TREATEMENT_PERMISSION_START_DATE == null ||
        this.data3.TREATEMENT_PERMISSION_START_DATE == ''
      ) {
        this.data3.TREATEMENT_PERMISSION_START_DATE = null;
      } else {
        this.data3.TREATEMENT_PERMISSION_START_DATE = this.datepipe.transform(
          this.data3.TREATEMENT_PERMISSION_START_DATE,
          'yyyy-MM-dd'
        );
      }
      if (
        this.data3.TREATEMENT_PERMISSION_END_DATE == undefined ||
        this.data3.TREATEMENT_PERMISSION_END_DATE == null ||
        this.data3.TREATEMENT_PERMISSION_END_DATE == ''
      ) {
        this.data3.TREATEMENT_PERMISSION_END_DATE = null;
      } else {
        this.data3.TREATEMENT_PERMISSION_END_DATE = this.datepipe.transform(
          this.data3.TREATEMENT_PERMISSION_END_DATE,
          'yyyy-MM-dd'
        );
      }

      if (
        this.data3.BILL_SUBMISSION_DATE == undefined ||
        this.data3.BILL_SUBMISSION_DATE == null ||
        this.data3.BILL_SUBMISSION_DATE == ''
      ) {
        this.data3.BILL_SUBMISSION_DATE = null;
      } else {
        this.data3.BILL_SUBMISSION_DATE = this.datepipe.transform(
          this.data3.BILL_SUBMISSION_DATE,
          'yyyy-MM-dd'
        );
      }

      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_NUMBER != undefined ||
          this.data3.DELAY_CONDOLENCE_NUMBER != null)
      ) {
        this.data3.DELAY_CONDOLENCE_NUMBER = this.data3.DELAY_CONDOLENCE_NUMBER;
      } else {
        this.data3.DELAY_CONDOLENCE_NUMBER = '';
      }
      {
        if (this.data3.ID) {
          this.api.updateQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              // this.message.success('Information Changed Successfully...', '');
              this.api
                .getclaimMaster(
                  0,
                  0,
                  '',
                  '',
                  ' AND ID=' + this.claimID,
                  '',
                  '',
                  '',
                  ''
                )
                .subscribe(
                  (data) => {
                    if (data['code'] == 200) {
                      this.orderdata1 = data['data'][0];
                      this.claimID1 = data['data'][0]['ID'];
                      this.empID1 = data['data'][0]['EMP_ID'];
                      console.log('this.orderdata1', this.orderdata1);
                      this.addMonths(
                        new Date(data['data'][0]['TREATMENT_END_DATE']),
                        6
                      );
                      console.log(
                        'sixMonthDatesixMonthDatea',
                        (this.sixMonthDate = this.datepipe.transform(
                          this.sixMonthDate,
                          'yyyy-MM-dd'
                        ))
                      );
                      if (
                        data['data'][0]['CLAIM_SUBMITTED_DATE'] != undefined &&
                        data['data'][0]['CLAIM_SUBMITTED_DATE'] != null &&
                        data['data'][0]['CLAIM_SUBMITTED_DATE'] != ''
                      ) {
                        var future = moment(
                          data['data'][0]['CLAIM_SUBMITTED_DATE']
                        );
                        var start = moment(this.sixMonthDate);
                        this.dateBetweenDiff = future.diff(start, 'days');
                      } else {
                      }
                      this.api
                        .getSignature(
                          0,
                          0,
                          'ID',
                          'desc',
                          ' AND ID = ' + data['data'][0]['SIGNATURE_ID']
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.SECTION_TYPE =
                                data['data'][0]['SECTION_TYPE'];
                              this.NAME = data['data'][0]['NAME'];
                              this.NAME_HN = data['data'][0]['NAME_HN'];
                              this.OFFICE_NAME = data['data'][0]['OFFICE_NAME'];
                              this.OFFICE_NAME_HN =
                                data['data'][0]['OFFICE_NAME_HN'];
                              this.POST = data['data'][0]['POST'];
                              this.POST_HN = data['data'][0]['POST_HN'];
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                      this.api
                        .getHospitalMapping(
                          0,
                          0,
                          '',
                          'desc',
                          ' AND CLAIM_ID = ' + this.claimID
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.HospitalMapping = data['data'];
                              console.log(
                                'this.HospitalMapping',
                                this.HospitalMapping
                              );
                              // this.isSpinning = false;
                              this.api
                                .getAllQuestions(
                                  0,
                                  0,
                                  '',
                                  '',
                                  ' AND CLAIM_ID =' + this.claimID
                                )
                                .subscribe(
                                  (data) => {
                                    if (data['code'] == 200) {
                                      if (data['data'].length == 0) {
                                        this.queData = new QuestionaryMaster();
                                      } else {
                                        this.queData = data['data'][0];
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == '' &&
                                          this.queData.CGHS_AMA_REFERENCE_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_DATE.split(
                                              ','
                                            );
                                        }
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            '' &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_END_DATE.split(
                                              ','
                                            );
                                        }
                                      }
                                    } else {
                                      this.message.error(
                                        'Something Went Wrong',
                                        ''
                                      );
                                      // this.isSpinning = false;
                                    }
                                  },
                                  (err) => {
                                    console.log(err);
                                  }
                                );
                            } else {
                              this.message.error('Something Went Wrong', '');
                              // this.isSpinning = false;
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                    } else {
                      this.message.error("Can't Load Data", '');
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              this.isSpinning = false;
              this.delayPermissionVisiblenew = true;
            } else {
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
        } else {
          this.api.createQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              // this.message.success('Information Save Successfully...', '');
              this.api
                .getclaimMaster(
                  0,
                  0,
                  '',
                  '',
                  ' AND ID=' + this.claimID,
                  '',
                  '',
                  '',
                  ''
                )
                .subscribe(
                  (data) => {
                    if (data['code'] == 200) {
                      this.orderdata1 = data['data'][0];
                      this.claimID1 = data['data'][0]['ID'];
                      this.empID1 = data['data'][0]['EMP_ID'];
                      console.log('this.orderdata1', this.orderdata1);
                      this.addMonths(
                        new Date(data['data'][0]['TREATMENT_END_DATE']),
                        6
                      );
                      console.log(
                        'sixMonthDatesixMonthDatea',
                        (this.sixMonthDate = this.datepipe.transform(
                          this.sixMonthDate,
                          'yyyy-MM-dd'
                        ))
                      );
                      if (
                        data['data'][0]['CLAIM_SUBMITTED_DATE'] != undefined &&
                        data['data'][0]['CLAIM_SUBMITTED_DATE'] != null &&
                        data['data'][0]['CLAIM_SUBMITTED_DATE'] != ''
                      ) {
                        var future = moment(
                          data['data'][0]['CLAIM_SUBMITTED_DATE']
                        );
                        var start = moment(this.sixMonthDate);
                        this.dateBetweenDiff = future.diff(start, 'days');
                      } else {
                      }
                      this.api
                        .getSignature(
                          0,
                          0,
                          'ID',
                          'desc',
                          ' AND ID = ' + data['data'][0]['SIGNATURE_ID']
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.SECTION_TYPE =
                                data['data'][0]['SECTION_TYPE'];
                              this.NAME = data['data'][0]['NAME'];
                              this.NAME_HN = data['data'][0]['NAME_HN'];
                              this.OFFICE_NAME = data['data'][0]['OFFICE_NAME'];
                              this.OFFICE_NAME_HN =
                                data['data'][0]['OFFICE_NAME_HN'];
                              this.POST = data['data'][0]['POST'];
                              this.POST_HN = data['data'][0]['POST_HN'];
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                      this.api
                        .getHospitalMapping(
                          0,
                          0,
                          '',
                          'desc',
                          ' AND CLAIM_ID = ' + this.claimID
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.HospitalMapping = data['data'];
                              console.log(
                                'this.HospitalMapping',
                                this.HospitalMapping
                              );
                              // this.isSpinning = false;
                              this.api
                                .getAllQuestions(
                                  0,
                                  0,
                                  '',
                                  '',
                                  ' AND CLAIM_ID =' + this.claimID
                                )
                                .subscribe(
                                  (data) => {
                                    if (data['code'] == 200) {
                                      if (data['data'].length == 0) {
                                        this.queData = new QuestionaryMaster();
                                      } else {
                                        this.queData = data['data'][0];
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == '' &&
                                          this.queData.CGHS_AMA_REFERENCE_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_DATE.split(
                                              ','
                                            );
                                        }
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            '' &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_END_DATE.split(
                                              ','
                                            );
                                        }
                                      }
                                    } else {
                                      this.message.error(
                                        'Something Went Wrong',
                                        ''
                                      );
                                      this.isSpinning = false;
                                    }
                                  },
                                  (err) => {
                                    console.log(err);
                                  }
                                );
                            } else {
                              this.message.error('Something Went Wrong', '');
                              // this.isSpinning = false;
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                    } else {
                      this.message.error("Can't Load Data", '');
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              this.delayPermissionVisiblenew = true;
              this.isSpinning = false;
            } else {
              this.message.error('Failed To Fill Information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
    this.orderdata1 = [];
  }

  refExpoOrderVisible: boolean = false;
  refExpoOrderTitle: string = '';
  refExpoOrderSheet() {
    this.refExpoOrderTitle = 'Referance Expo Facto Order Sheet';
    this.claimID1 = '';
    this.empID1 = '';
    this.data3.EMP_ID = this.empID;
    this.data3.CLAIM_ID = this.claimID;
    this.isOk = true;
    if (this.isOk) {
      this.isSpinning = true;
      this.data3.DELAY_CONDOLENCE_DATE = this.datepipe.transform(
        this.data3.DELAY_CONDOLENCE_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPOST_FACTO_PERMISSION_DATE = this.datepipe.transform(
        this.data3.EXPOST_FACTO_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPO_FACTO_DATE = this.datepipe.transform(
        this.data3.EXPO_FACTO_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      if (
        this.data3.CGHS_AMA_REFERENCE_DATE == undefined &&
        this.data3.CGHS_AMA_REFERENCE_DATE == null &&
        this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
      ) {
        this.data3.CGHS_AMA_REFERENCE_DATE = '';
      } else {
        this.data3.CGHS_AMA_REFERENCE_DATE = this.data3.CGHS_AMA_REFERENCE_DATE;
      }
      if (
        this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE == null &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
      ) {
        this.data3.CGHS_AMA_REFERENCE_END_DATE = '';
      } else {
        this.data3.CGHS_AMA_REFERENCE_END_DATE =
          this.data3.CGHS_AMA_REFERENCE_END_DATE;
      }

      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_DATE != undefined ||
          this.data3.DELAY_CONDOLENCE_DATE != null)
      ) {
        this.data3.DELAY_CONDOLENCE_DATE = this.data3.DELAY_CONDOLENCE_DATE;
      } else {
        this.data3.DELAY_CONDOLENCE_DATE = null;
      }

      if (
        this.data3.TREATEMENT_PERMISSION_START_DATE == undefined ||
        this.data3.TREATEMENT_PERMISSION_START_DATE == null ||
        this.data3.TREATEMENT_PERMISSION_START_DATE == ''
      ) {
        this.data3.TREATEMENT_PERMISSION_START_DATE = null;
      } else {
        this.data3.TREATEMENT_PERMISSION_START_DATE = this.datepipe.transform(
          this.data3.TREATEMENT_PERMISSION_START_DATE,
          'yyyy-MM-dd'
        );
      }
      if (
        this.data3.TREATEMENT_PERMISSION_END_DATE == undefined ||
        this.data3.TREATEMENT_PERMISSION_END_DATE == null ||
        this.data3.TREATEMENT_PERMISSION_END_DATE == ''
      ) {
        this.data3.TREATEMENT_PERMISSION_END_DATE = null;
      } else {
        this.data3.TREATEMENT_PERMISSION_END_DATE = this.datepipe.transform(
          this.data3.TREATEMENT_PERMISSION_END_DATE,
          'yyyy-MM-dd'
        );
      }

      if (
        this.data3.BILL_SUBMISSION_DATE == undefined ||
        this.data3.BILL_SUBMISSION_DATE == null ||
        this.data3.BILL_SUBMISSION_DATE == ''
      ) {
        this.data3.BILL_SUBMISSION_DATE = null;
      } else {
        this.data3.BILL_SUBMISSION_DATE = this.datepipe.transform(
          this.data3.BILL_SUBMISSION_DATE,
          'yyyy-MM-dd'
        );
      }

      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_NUMBER != undefined ||
          this.data3.DELAY_CONDOLENCE_NUMBER != null)
      ) {
        this.data3.DELAY_CONDOLENCE_NUMBER = this.data3.DELAY_CONDOLENCE_NUMBER;
      } else {
        this.data3.DELAY_CONDOLENCE_NUMBER = '';
      }
      {
        if (this.data3.ID) {
          this.api.updateQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              // this.message.success('Information Changed Successfully...', '');
              this.api
                .getclaimMaster(
                  0,
                  0,
                  '',
                  '',
                  ' AND ID=' + this.claimID,
                  '',
                  '',
                  '',
                  ''
                )
                .subscribe(
                  (data) => {
                    if (data['code'] == 200) {
                      this.orderdata1 = data['data'][0];
                      this.claimID1 = data['data'][0]['ID'];
                      this.empID1 = data['data'][0]['EMP_ID'];
                      console.log('this.orderdata1', this.orderdata1);
                      this.api
                        .getSignature(
                          0,
                          0,
                          'ID',
                          'desc',
                          ' AND ID = ' + data['data'][0]['SIGNATURE_ID']
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.SECTION_TYPE =
                                data['data'][0]['SECTION_TYPE'];
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                      this.api
                        .getHospitalMapping(
                          0,
                          0,
                          '',
                          'desc',
                          ' AND CLAIM_ID = ' + this.claimID
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.HospitalMapping = data['data'];
                              console.log(
                                'this.HospitalMapping',
                                this.HospitalMapping
                              );
                              // this.isSpinning = false;
                              this.api
                                .getAllQuestions(
                                  0,
                                  0,
                                  '',
                                  '',
                                  ' AND CLAIM_ID =' + this.claimID
                                )
                                .subscribe(
                                  (data) => {
                                    if (data['code'] == 200) {
                                      if (data['data'].length == 0) {
                                        this.queData = new QuestionaryMaster();
                                      } else {
                                        this.queData = data['data'][0];
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == '' &&
                                          this.queData.CGHS_AMA_REFERENCE_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_DATE.split(
                                              ','
                                            );
                                        }
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            '' &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_END_DATE.split(
                                              ','
                                            );
                                        }
                                      }
                                    } else {
                                      this.message.error(
                                        'Something Went Wrong',
                                        ''
                                      );
                                      // this.isSpinning = false;
                                    }
                                  },
                                  (err) => {
                                    console.log(err);
                                  }
                                );
                            } else {
                              this.message.error('Something Went Wrong', '');
                              // this.isSpinning = false;
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                    } else {
                      this.message.error("Can't Load Data", '');
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              this.isSpinning = false;
              this.refExpoOrderVisible = true;
            } else {
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
        } else {
          this.api.createQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              // this.message.success('Information Save Successfully...', '');
              this.api
                .getclaimMaster(
                  0,
                  0,
                  '',
                  '',
                  ' AND ID=' + this.claimID,
                  '',
                  '',
                  '',
                  ''
                )
                .subscribe(
                  (data) => {
                    if (data['code'] == 200) {
                      this.orderdata1 = data['data'][0];
                      this.claimID1 = data['data'][0]['ID'];
                      this.empID1 = data['data'][0]['EMP_ID'];
                      console.log('this.orderdata1', this.orderdata1);
                      this.api
                        .getSignature(
                          0,
                          0,
                          'ID',
                          'desc',
                          ' AND ID = ' + data['data'][0]['SIGNATURE_ID']
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.SECTION_TYPE =
                                data['data'][0]['SECTION_TYPE'];
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                      this.api
                        .getHospitalMapping(
                          0,
                          0,
                          '',
                          'desc',
                          ' AND CLAIM_ID = ' + this.claimID
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.HospitalMapping = data['data'];
                              console.log(
                                'this.HospitalMapping',
                                this.HospitalMapping
                              );
                              // this.isSpinning = false;
                              this.api
                                .getAllQuestions(
                                  0,
                                  0,
                                  '',
                                  '',
                                  ' AND CLAIM_ID =' + this.claimID
                                )
                                .subscribe(
                                  (data) => {
                                    if (data['code'] == 200) {
                                      if (data['data'].length == 0) {
                                        this.queData = new QuestionaryMaster();
                                      } else {
                                        this.queData = data['data'][0];
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == '' &&
                                          this.queData.CGHS_AMA_REFERENCE_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_DATE.split(
                                              ','
                                            );
                                        }
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            '' &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_END_DATE.split(
                                              ','
                                            );
                                        }
                                      }
                                    } else {
                                      this.message.error(
                                        'Something Went Wrong',
                                        ''
                                      );
                                      this.isSpinning = false;
                                    }
                                  },
                                  (err) => {
                                    console.log(err);
                                  }
                                );
                            } else {
                              this.message.error('Something Went Wrong', '');
                              // this.isSpinning = false;
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                    } else {
                      this.message.error("Can't Load Data", '');
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              this.refExpoOrderVisible = true;
              this.isSpinning = false;
            } else {
              this.message.error('Failed To Fill Information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
  }

  refExpoOrderClose(): void {
    // this.refExpoOrderVisible = false;
    this.api
      .getAllQuestions(0, 0, '', '', ' AND CLAIM_ID =' + this.claimID)
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            if (data['data'].length == 0) {
              this.data3 = new QuestionaryMaster();
              this.billInTime();
              this.empanelledHospital();
              this.refExpoOrderVisible = false;
              this.isSpinning = false;
            } else {
              this.data3 = data['data'][0];
              this.emergencyTaken = data['data'][0]['EMERGENCY_TREATEMENT'];
              this.drCertificate =
                data['data'][0]['DOCTOR_EMERGENCY_CERTIFICATE_TAKEN'];
              this.empanelHospital = data['data'][0]['IS_HOSPITAL_EMPLANELLED'];
              this.expoFacto = data['data'][0]['EXPO_FACTO_PERMISSION'];
              this.referanceTaken =
                data['data'][0]['REFERENCE_FORM_CGHS_AMA_TAKEN'];
              this.hodPermission =
                data['data'][0]['IS_PERMISSION_TAKEN_FROM_HOD'];
              if (
                this.data3.CGHS_AMA_REFERENCE_DATE == undefined ||
                this.data3.CGHS_AMA_REFERENCE_DATE == null ||
                this.data3.CGHS_AMA_REFERENCE_DATE == '' ||
                this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
              ) {
                this.data3.CGHS_AMA_REFERENCE_DATE = [];
              } else {
                this.data3.CGHS_AMA_REFERENCE_DATE =
                  this.data3.CGHS_AMA_REFERENCE_DATE.split(',');
                // this.dateDifference();
              }
              if (
                this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined ||
                this.data3.CGHS_AMA_REFERENCE_END_DATE == null ||
                this.data3.CGHS_AMA_REFERENCE_END_DATE == '' ||
                this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
              ) {
                this.data3.CGHS_AMA_REFERENCE_END_DATE = [];
              } else {
                this.data3.CGHS_AMA_REFERENCE_END_DATE =
                  this.data3.CGHS_AMA_REFERENCE_END_DATE.split(',');
                this.dateDifference();
              }
              // this.calculateDiff();
              this.billInTime();
              this.empanelledHospital();
              this.refExpoOrderVisible = false;
              this.isSpinning = false;
            }
          } else {
            this.message.error('Something Went Wrong', '');
            this.isSpinning = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  get refExpoOrderCloseCallback() {
    return this.refExpoOrderClose.bind(this);
  }

  refExpoPermissionLetterVisible: boolean = false;
  isRefExpoPermission: boolean = false;
  refExpoPermissionLetterTitle: string = '';
  refExpoPermissionLetter() {
    this.refExpoPermissionLetterTitle =
      'Referance Expo Facto Permission Letter';
    this.claimID1 = '';
    this.empID1 = '';
    this.data3.EMP_ID = this.empID;
    this.data3.CLAIM_ID = this.claimID;
    this.isRefExpoPermission = false;
    this.isOk = true;
    if (this.isOk) {
      this.isSpinning = true;
      this.data3.DELAY_CONDOLENCE_DATE = this.datepipe.transform(
        this.data3.DELAY_CONDOLENCE_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPOST_FACTO_PERMISSION_DATE = this.datepipe.transform(
        this.data3.EXPOST_FACTO_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPO_FACTO_DATE = this.datepipe.transform(
        this.data3.EXPO_FACTO_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      if (
        this.data3.CGHS_AMA_REFERENCE_DATE == undefined &&
        this.data3.CGHS_AMA_REFERENCE_DATE == null &&
        this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
      ) {
        this.data3.CGHS_AMA_REFERENCE_DATE = '';
      } else {
        this.data3.CGHS_AMA_REFERENCE_DATE = this.data3.CGHS_AMA_REFERENCE_DATE;
      }
      if (
        this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE == null &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
      ) {
        this.data3.CGHS_AMA_REFERENCE_END_DATE = '';
      } else {
        this.data3.CGHS_AMA_REFERENCE_END_DATE =
          this.data3.CGHS_AMA_REFERENCE_END_DATE;
      }

      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_DATE != undefined ||
          this.data3.DELAY_CONDOLENCE_DATE != null)
      ) {
        this.data3.DELAY_CONDOLENCE_DATE = this.data3.DELAY_CONDOLENCE_DATE;
      } else {
        this.data3.DELAY_CONDOLENCE_DATE = null;
      }

      if (
        this.data3.TREATEMENT_PERMISSION_START_DATE == undefined ||
        this.data3.TREATEMENT_PERMISSION_START_DATE == null ||
        this.data3.TREATEMENT_PERMISSION_START_DATE == ''
      ) {
        this.data3.TREATEMENT_PERMISSION_START_DATE = null;
      } else {
        this.data3.TREATEMENT_PERMISSION_START_DATE = this.datepipe.transform(
          this.data3.TREATEMENT_PERMISSION_START_DATE,
          'yyyy-MM-dd'
        );
      }
      if (
        this.data3.TREATEMENT_PERMISSION_END_DATE == undefined ||
        this.data3.TREATEMENT_PERMISSION_END_DATE == null ||
        this.data3.TREATEMENT_PERMISSION_END_DATE == ''
      ) {
        this.data3.TREATEMENT_PERMISSION_END_DATE = null;
      } else {
        this.data3.TREATEMENT_PERMISSION_END_DATE = this.datepipe.transform(
          this.data3.TREATEMENT_PERMISSION_END_DATE,
          'yyyy-MM-dd'
        );
      }

      if (
        this.data3.BILL_SUBMISSION_DATE == undefined ||
        this.data3.BILL_SUBMISSION_DATE == null ||
        this.data3.BILL_SUBMISSION_DATE == ''
      ) {
        this.data3.BILL_SUBMISSION_DATE = null;
      } else {
        this.data3.BILL_SUBMISSION_DATE = this.datepipe.transform(
          this.data3.BILL_SUBMISSION_DATE,
          'yyyy-MM-dd'
        );
      }

      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_NUMBER != undefined ||
          this.data3.DELAY_CONDOLENCE_NUMBER != null)
      ) {
        this.data3.DELAY_CONDOLENCE_NUMBER = this.data3.DELAY_CONDOLENCE_NUMBER;
      } else {
        this.data3.DELAY_CONDOLENCE_NUMBER = '';
      }
      {
        if (this.data3.ID) {
          this.api.updateQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              // this.message.success('Information Changed Successfully...', '');
              this.api
                .getclaimMaster(
                  0,
                  0,
                  '',
                  '',
                  ' AND ID=' + this.claimID,
                  '',
                  '',
                  '',
                  ''
                )
                .subscribe(
                  (data) => {
                    if (data['code'] == 200) {
                      this.orderdata1 = data['data'][0];
                      this.claimID1 = data['data'][0]['ID'];
                      this.empID1 = data['data'][0]['EMP_ID'];
                      console.log('this.orderdata1', this.orderdata1);
                      this.api
                        .getSignature(
                          0,
                          0,
                          'ID',
                          'desc',
                          ' AND ID = ' + data['data'][0]['SIGNATURE_ID']
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.SECTION_TYPE =
                                data['data'][0]['SECTION_TYPE'];
                              this.NAME = data['data'][0]['NAME'];
                              this.NAME_HN = data['data'][0]['NAME_HN'];
                              this.OFFICE_NAME = data['data'][0]['OFFICE_NAME'];
                              this.OFFICE_NAME_HN =
                                data['data'][0]['OFFICE_NAME_HN'];
                              this.POST = data['data'][0]['POST'];
                              this.POST_HN = data['data'][0]['POST_HN'];
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                      this.api
                        .getHospitalMapping(
                          0,
                          0,
                          '',
                          'desc',
                          ' AND CLAIM_ID = ' + this.claimID
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.HospitalMapping = data['data'];
                              console.log(
                                'this.HospitalMapping',
                                this.HospitalMapping
                              );
                              // this.isSpinning = false;
                              this.api
                                .getAllQuestions(
                                  0,
                                  0,
                                  '',
                                  '',
                                  ' AND CLAIM_ID =' + this.claimID
                                )
                                .subscribe(
                                  (data) => {
                                    if (data['code'] == 200) {
                                      if (data['data'].length == 0) {
                                        this.queData = new QuestionaryMaster();
                                      } else {
                                        this.queData = data['data'][0];
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == '' &&
                                          this.queData.CGHS_AMA_REFERENCE_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_DATE.split(
                                              ','
                                            );
                                        }
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            '' &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_END_DATE.split(
                                              ','
                                            );
                                        }
                                      }
                                    } else {
                                      this.message.error(
                                        'Something Went Wrong',
                                        ''
                                      );
                                      // this.isSpinning = false;
                                    }
                                  },
                                  (err) => {
                                    console.log(err);
                                  }
                                );
                            } else {
                              this.message.error('Something Went Wrong', '');
                              // this.isSpinning = false;
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                    } else {
                      this.message.error("Can't Load Data", '');
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              this.isSpinning = false;
              this.refExpoPermissionLetterVisible = true;
            } else {
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
        } else {
          this.api.createQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              // this.message.success('Information Save Successfully...', '');
              this.api
                .getclaimMaster(
                  0,
                  0,
                  '',
                  '',
                  ' AND ID=' + this.claimID,
                  '',
                  '',
                  '',
                  ''
                )
                .subscribe(
                  (data) => {
                    if (data['code'] == 200) {
                      this.orderdata1 = data['data'][0];
                      this.claimID1 = data['data'][0]['ID'];
                      this.empID1 = data['data'][0]['EMP_ID'];
                      console.log('this.orderdata1', this.orderdata1);
                      this.api
                        .getSignature(
                          0,
                          0,
                          'ID',
                          'desc',
                          ' AND ID = ' + data['data'][0]['SIGNATURE_ID']
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.SECTION_TYPE =
                                data['data'][0]['SECTION_TYPE'];
                              this.NAME = data['data'][0]['NAME'];
                              this.NAME_HN = data['data'][0]['NAME_HN'];
                              this.OFFICE_NAME = data['data'][0]['OFFICE_NAME'];
                              this.OFFICE_NAME_HN =
                                data['data'][0]['OFFICE_NAME_HN'];
                              this.POST = data['data'][0]['POST'];
                              this.POST_HN = data['data'][0]['POST_HN'];
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                      this.api
                        .getHospitalMapping(
                          0,
                          0,
                          '',
                          'desc',
                          ' AND CLAIM_ID = ' + this.claimID
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.HospitalMapping = data['data'];
                              console.log(
                                'this.HospitalMapping',
                                this.HospitalMapping
                              );
                              // this.isSpinning = false;
                              this.api
                                .getAllQuestions(
                                  0,
                                  0,
                                  '',
                                  '',
                                  ' AND CLAIM_ID =' + this.claimID
                                )
                                .subscribe(
                                  (data) => {
                                    if (data['code'] == 200) {
                                      if (data['data'].length == 0) {
                                        this.queData = new QuestionaryMaster();
                                      } else {
                                        this.queData = data['data'][0];
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == '' &&
                                          this.queData.CGHS_AMA_REFERENCE_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_DATE.split(
                                              ','
                                            );
                                        }
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            '' &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_END_DATE.split(
                                              ','
                                            );
                                        }
                                      }
                                    } else {
                                      this.message.error(
                                        'Something Went Wrong',
                                        ''
                                      );
                                      this.isSpinning = false;
                                    }
                                  },
                                  (err) => {
                                    console.log(err);
                                  }
                                );
                            } else {
                              this.message.error('Something Went Wrong', '');
                              // this.isSpinning = false;
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                    } else {
                      this.message.error("Can't Load Data", '');
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              this.refExpoPermissionLetterVisible = true;
              this.isSpinning = false;
            } else {
              this.message.error('Failed To Fill Information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
  }

  refExpoPermissionLetterClose(): void {
    // this.refExpoPermissionLetterVisible = false;
    this.api
      .getAllQuestions(0, 0, '', '', ' AND CLAIM_ID =' + this.claimID)
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            if (data['data'].length == 0) {
              this.data3 = new QuestionaryMaster();
              this.billInTime();
              this.empanelledHospital();
              this.refExpoPermissionLetterVisible = false;
              this.isSpinning = false;
            } else {
              this.data3 = data['data'][0];
              this.emergencyTaken = data['data'][0]['EMERGENCY_TREATEMENT'];
              this.drCertificate =
                data['data'][0]['DOCTOR_EMERGENCY_CERTIFICATE_TAKEN'];
              this.empanelHospital = data['data'][0]['IS_HOSPITAL_EMPLANELLED'];
              this.expoFacto = data['data'][0]['EXPO_FACTO_PERMISSION'];
              this.referanceTaken =
                data['data'][0]['REFERENCE_FORM_CGHS_AMA_TAKEN'];
              this.hodPermission =
                data['data'][0]['IS_PERMISSION_TAKEN_FROM_HOD'];
              if (
                this.data3.CGHS_AMA_REFERENCE_DATE == undefined ||
                this.data3.CGHS_AMA_REFERENCE_DATE == null ||
                this.data3.CGHS_AMA_REFERENCE_DATE == '' ||
                this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
              ) {
                this.data3.CGHS_AMA_REFERENCE_DATE = [];
              } else {
                this.data3.CGHS_AMA_REFERENCE_DATE =
                  this.data3.CGHS_AMA_REFERENCE_DATE.split(',');
                // this.dateDifference();
              }
              if (
                this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined ||
                this.data3.CGHS_AMA_REFERENCE_END_DATE == null ||
                this.data3.CGHS_AMA_REFERENCE_END_DATE == '' ||
                this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
              ) {
                this.data3.CGHS_AMA_REFERENCE_END_DATE = [];
              } else {
                this.data3.CGHS_AMA_REFERENCE_END_DATE =
                  this.data3.CGHS_AMA_REFERENCE_END_DATE.split(',');
                this.dateDifference();
              }
              // this.calculateDiff();
              this.billInTime();
              this.empanelledHospital();
              this.refExpoPermissionLetterVisible = false;
              this.isSpinning = false;
            }
          } else {
            this.message.error('Something Went Wrong', '');
            this.isSpinning = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  get refExpoPermissionLetterCloseCallback() {
    return this.refExpoPermissionLetterClose.bind(this);
  }

  refhodorderVisible: boolean = false;
  refhodorderSheetTitle: string = '';
  refHODOrderSheet() {
    this.isSpinning = true;
    this.claimID1 = '';
    this.empID1 = '';
    this.data3.EMP_ID = this.empID;
    this.data3.CLAIM_ID = this.claimID;
    this.refhodorderSheetTitle = 'Referance HOD Order Letter';
    this.isOk = true;
    if (this.isOk) {
      this.isSpinning = true;
      this.data3.DELAY_CONDOLENCE_DATE = this.datepipe.transform(
        this.data3.DELAY_CONDOLENCE_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPOST_FACTO_PERMISSION_DATE = this.datepipe.transform(
        this.data3.EXPOST_FACTO_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPO_FACTO_DATE = this.datepipe.transform(
        this.data3.EXPO_FACTO_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      if (
        this.data3.CGHS_AMA_REFERENCE_DATE == undefined &&
        this.data3.CGHS_AMA_REFERENCE_DATE == null &&
        this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
      ) {
        this.data3.CGHS_AMA_REFERENCE_DATE = '';
      } else {
        this.data3.CGHS_AMA_REFERENCE_DATE = this.data3.CGHS_AMA_REFERENCE_DATE;
      }
      if (
        this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE == null &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
      ) {
        this.data3.CGHS_AMA_REFERENCE_END_DATE = '';
      } else {
        this.data3.CGHS_AMA_REFERENCE_END_DATE =
          this.data3.CGHS_AMA_REFERENCE_END_DATE;
      }

      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_DATE != undefined ||
          this.data3.DELAY_CONDOLENCE_DATE != null)
      ) {
        this.data3.DELAY_CONDOLENCE_DATE = this.data3.DELAY_CONDOLENCE_DATE;
      } else {
        this.data3.DELAY_CONDOLENCE_DATE = null;
      }

      if (
        this.data3.TREATEMENT_PERMISSION_START_DATE == undefined ||
        this.data3.TREATEMENT_PERMISSION_START_DATE == null ||
        this.data3.TREATEMENT_PERMISSION_START_DATE == ''
      ) {
        this.data3.TREATEMENT_PERMISSION_START_DATE = null;
      } else {
        this.data3.TREATEMENT_PERMISSION_START_DATE = this.datepipe.transform(
          this.data3.TREATEMENT_PERMISSION_START_DATE,
          'yyyy-MM-dd'
        );
      }
      if (
        this.data3.TREATEMENT_PERMISSION_END_DATE == undefined ||
        this.data3.TREATEMENT_PERMISSION_END_DATE == null ||
        this.data3.TREATEMENT_PERMISSION_END_DATE == ''
      ) {
        this.data3.TREATEMENT_PERMISSION_END_DATE = null;
      } else {
        this.data3.TREATEMENT_PERMISSION_END_DATE = this.datepipe.transform(
          this.data3.TREATEMENT_PERMISSION_END_DATE,
          'yyyy-MM-dd'
        );
      }

      if (
        this.data3.BILL_SUBMISSION_DATE == undefined ||
        this.data3.BILL_SUBMISSION_DATE == null ||
        this.data3.BILL_SUBMISSION_DATE == ''
      ) {
        this.data3.BILL_SUBMISSION_DATE = null;
      } else {
        this.data3.BILL_SUBMISSION_DATE = this.datepipe.transform(
          this.data3.BILL_SUBMISSION_DATE,
          'yyyy-MM-dd'
        );
      }

      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_NUMBER != undefined ||
          this.data3.DELAY_CONDOLENCE_NUMBER != null)
      ) {
        this.data3.DELAY_CONDOLENCE_NUMBER = this.data3.DELAY_CONDOLENCE_NUMBER;
      } else {
        this.data3.DELAY_CONDOLENCE_NUMBER = '';
      }
      {
        if (this.data3.ID) {
          this.api.updateQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              // this.message.success('Information Changed Successfully...', '');
              this.api
                .getclaimMaster(
                  0,
                  0,
                  '',
                  '',
                  ' AND ID=' + this.claimID,
                  '',
                  '',
                  '',
                  ''
                )
                .subscribe(
                  (data) => {
                    if (data['code'] == 200) {
                      this.orderdata1 = data['data'][0];
                      this.claimID1 = data['data'][0]['ID'];
                      this.empID1 = data['data'][0]['EMP_ID'];
                      console.log('this.orderdata1', this.orderdata1);
                      this.api
                        .getSignature(
                          0,
                          0,
                          'ID',
                          'desc',
                          ' AND ID = ' + data['data'][0]['SIGNATURE_ID']
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.SECTION_TYPE =
                                data['data'][0]['SECTION_TYPE'];
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                      this.api
                        .getHospitalMapping(
                          0,
                          0,
                          '',
                          'desc',
                          ' AND CLAIM_ID = ' + this.claimID
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.HospitalMapping = data['data'];
                              console.log(
                                'this.HospitalMapping',
                                this.HospitalMapping
                              );
                              // this.isSpinning = false;
                              this.api
                                .getAllQuestions(
                                  0,
                                  0,
                                  '',
                                  '',
                                  ' AND CLAIM_ID =' + this.claimID
                                )
                                .subscribe(
                                  (data) => {
                                    if (data['code'] == 200) {
                                      if (data['data'].length == 0) {
                                        this.queData = new QuestionaryMaster();
                                      } else {
                                        this.queData = data['data'][0];
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == '' &&
                                          this.queData.CGHS_AMA_REFERENCE_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_DATE.split(
                                              ','
                                            );
                                        }
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            '' &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_END_DATE.split(
                                              ','
                                            );
                                        }
                                      }
                                    } else {
                                      this.message.error(
                                        'Something Went Wrong',
                                        ''
                                      );
                                      // this.isSpinning = false;
                                    }
                                  },
                                  (err) => {
                                    console.log(err);
                                  }
                                );
                            } else {
                              this.message.error('Something Went Wrong', '');
                              // this.isSpinning = false;
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                    } else {
                      this.message.error("Can't Load Data", '');
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              this.isSpinning = false;
              this.refhodorderVisible = true;
            } else {
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
        } else {
          this.api.createQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              // this.message.success('Information Save Successfully...', '');
              this.api
                .getclaimMaster(
                  0,
                  0,
                  '',
                  '',
                  ' AND ID=' + this.claimID,
                  '',
                  '',
                  '',
                  ''
                )
                .subscribe(
                  (data) => {
                    if (data['code'] == 200) {
                      this.orderdata1 = data['data'][0];
                      this.claimID1 = data['data'][0]['ID'];
                      this.empID1 = data['data'][0]['EMP_ID'];
                      console.log('this.orderdata1', this.orderdata1);
                      this.api
                        .getSignature(
                          0,
                          0,
                          'ID',
                          'desc',
                          ' AND ID = ' + data['data'][0]['SIGNATURE_ID']
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.SECTION_TYPE =
                                data['data'][0]['SECTION_TYPE'];
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                      this.api
                        .getHospitalMapping(
                          0,
                          0,
                          '',
                          'desc',
                          ' AND CLAIM_ID = ' + this.claimID
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.HospitalMapping = data['data'];
                              console.log(
                                'this.HospitalMapping',
                                this.HospitalMapping
                              );
                              // this.isSpinning = false;
                              this.api
                                .getAllQuestions(
                                  0,
                                  0,
                                  '',
                                  '',
                                  ' AND CLAIM_ID =' + this.claimID
                                )
                                .subscribe(
                                  (data) => {
                                    if (data['code'] == 200) {
                                      if (data['data'].length == 0) {
                                        this.queData = new QuestionaryMaster();
                                      } else {
                                        this.queData = data['data'][0];
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == '' &&
                                          this.queData.CGHS_AMA_REFERENCE_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_DATE.split(
                                              ','
                                            );
                                        }
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            '' &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_END_DATE.split(
                                              ','
                                            );
                                        }
                                      }
                                    } else {
                                      this.message.error(
                                        'Something Went Wrong',
                                        ''
                                      );
                                      this.isSpinning = false;
                                    }
                                  },
                                  (err) => {
                                    console.log(err);
                                  }
                                );
                            } else {
                              this.message.error('Something Went Wrong', '');
                              // this.isSpinning = false;
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                    } else {
                      this.message.error("Can't Load Data", '');
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              this.refhodorderVisible = true;
              this.isSpinning = false;
            } else {
              this.message.error('Failed To Fill Information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
    this.orderdata1 = [];
  }

  refhodorderSheetClose(): void {
    // this.delayOrderSheetVisible = false;
    this.disableReferanceDate();
    this.api
      .getAllQuestions(0, 0, '', '', ' AND CLAIM_ID =' + this.claimID)
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            if (data['data'].length == 0) {
              this.data3 = new QuestionaryMaster();
              this.billInTime();
              this.empanelledHospital();
              // this.current = 2;
              this.refhodorderVisible = false;
              this.isSpinning = false;
            } else {
              this.data3 = data['data'][0];
              this.emergencyTaken = data['data'][0]['EMERGENCY_TREATEMENT'];
              this.drCertificate =
                data['data'][0]['DOCTOR_EMERGENCY_CERTIFICATE_TAKEN'];
              this.empanelHospital = data['data'][0]['IS_HOSPITAL_EMPLANELLED'];
              this.expoFacto = data['data'][0]['EXPO_FACTO_PERMISSION'];
              this.referanceTaken =
                data['data'][0]['REFERENCE_FORM_CGHS_AMA_TAKEN'];
              this.hodPermission =
                data['data'][0]['IS_PERMISSION_TAKEN_FROM_HOD'];
              if (
                this.data3.CGHS_AMA_REFERENCE_DATE == undefined &&
                this.data3.CGHS_AMA_REFERENCE_DATE == null &&
                this.data3.CGHS_AMA_REFERENCE_DATE == '' &&
                this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
              ) {
                this.data3.CGHS_AMA_REFERENCE_DATE = [];
              } else {
                this.data3.CGHS_AMA_REFERENCE_DATE =
                  this.data3.CGHS_AMA_REFERENCE_DATE.split(',');
                this.dateDifference();
              }
              if (
                this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined &&
                this.data3.CGHS_AMA_REFERENCE_END_DATE == null &&
                this.data3.CGHS_AMA_REFERENCE_END_DATE == '' &&
                this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
              ) {
                this.data3.CGHS_AMA_REFERENCE_END_DATE = [];
              } else {
                this.data3.CGHS_AMA_REFERENCE_END_DATE =
                  this.data3.CGHS_AMA_REFERENCE_END_DATE.split(',');
                this.dateDifference();
              }
              this.calculateDiff();
              this.billInTime();
              this.empanelledHospital();
              // this.current = 2;
              this.isSpinning = false;
              this.refhodorderVisible = false;
            }
          } else {
            this.message.error('Something Went Wrong', '');
            this.isSpinning = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }
  get refhodorderSheetCloseCallback() {
    return this.refhodorderSheetClose.bind(this);
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////
  refhodPermissionVisible: boolean = false;
  refhodPermissionSheetTitle: string = '';
  refHODPermission() {
    this.isSpinning = true;
    this.claimID1 = '';
    this.empID1 = '';
    this.data3.EMP_ID = this.empID;
    this.data3.CLAIM_ID = this.claimID;
    this.refhodPermissionSheetTitle = 'Referance HOD Permission Letter';
    this.isOk = true;
    if (this.isOk) {
      this.isSpinning = true;
      this.data3.DELAY_CONDOLENCE_DATE = this.datepipe.transform(
        this.data3.DELAY_CONDOLENCE_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPOST_FACTO_PERMISSION_DATE = this.datepipe.transform(
        this.data3.EXPOST_FACTO_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPO_FACTO_DATE = this.datepipe.transform(
        this.data3.EXPO_FACTO_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      if (
        this.data3.CGHS_AMA_REFERENCE_DATE == undefined &&
        this.data3.CGHS_AMA_REFERENCE_DATE == null &&
        this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
      ) {
        this.data3.CGHS_AMA_REFERENCE_DATE = '';
      } else {
        this.data3.CGHS_AMA_REFERENCE_DATE = this.data3.CGHS_AMA_REFERENCE_DATE;
      }
      if (
        this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE == null &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
      ) {
        this.data3.CGHS_AMA_REFERENCE_END_DATE = '';
      } else {
        this.data3.CGHS_AMA_REFERENCE_END_DATE =
          this.data3.CGHS_AMA_REFERENCE_END_DATE;
      }

      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_DATE != undefined ||
          this.data3.DELAY_CONDOLENCE_DATE != null)
      ) {
        this.data3.DELAY_CONDOLENCE_DATE = this.data3.DELAY_CONDOLENCE_DATE;
      } else {
        this.data3.DELAY_CONDOLENCE_DATE = null;
      }

      if (
        this.data3.TREATEMENT_PERMISSION_START_DATE == undefined ||
        this.data3.TREATEMENT_PERMISSION_START_DATE == null ||
        this.data3.TREATEMENT_PERMISSION_START_DATE == ''
      ) {
        this.data3.TREATEMENT_PERMISSION_START_DATE = null;
      } else {
        this.data3.TREATEMENT_PERMISSION_START_DATE = this.datepipe.transform(
          this.data3.TREATEMENT_PERMISSION_START_DATE,
          'yyyy-MM-dd'
        );
      }
      if (
        this.data3.TREATEMENT_PERMISSION_END_DATE == undefined ||
        this.data3.TREATEMENT_PERMISSION_END_DATE == null ||
        this.data3.TREATEMENT_PERMISSION_END_DATE == ''
      ) {
        this.data3.TREATEMENT_PERMISSION_END_DATE = null;
      } else {
        this.data3.TREATEMENT_PERMISSION_END_DATE = this.datepipe.transform(
          this.data3.TREATEMENT_PERMISSION_END_DATE,
          'yyyy-MM-dd'
        );
      }

      if (
        this.data3.BILL_SUBMISSION_DATE == undefined ||
        this.data3.BILL_SUBMISSION_DATE == null ||
        this.data3.BILL_SUBMISSION_DATE == ''
      ) {
        this.data3.BILL_SUBMISSION_DATE = null;
      } else {
        this.data3.BILL_SUBMISSION_DATE = this.datepipe.transform(
          this.data3.BILL_SUBMISSION_DATE,
          'yyyy-MM-dd'
        );
      }

      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_NUMBER != undefined ||
          this.data3.DELAY_CONDOLENCE_NUMBER != null)
      ) {
        this.data3.DELAY_CONDOLENCE_NUMBER = this.data3.DELAY_CONDOLENCE_NUMBER;
      } else {
        this.data3.DELAY_CONDOLENCE_NUMBER = '';
      }
      {
        if (this.data3.ID) {
          this.api.updateQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              // this.message.success('Information Changed Successfully...', '');
              this.api
                .getclaimMaster(
                  0,
                  0,
                  '',
                  '',
                  ' AND ID=' + this.claimID,
                  '',
                  '',
                  '',
                  ''
                )
                .subscribe(
                  (data) => {
                    if (data['code'] == 200) {
                      this.orderdata1 = data['data'][0];
                      this.claimID1 = data['data'][0]['ID'];
                      this.empID1 = data['data'][0]['EMP_ID'];
                      console.log('this.orderdata1', this.orderdata1);
                      this.api
                        .getSignature(
                          0,
                          0,
                          'ID',
                          'desc',
                          ' AND ID = ' + data['data'][0]['SIGNATURE_ID']
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.SECTION_TYPE =
                                data['data'][0]['SECTION_TYPE'];
                              this.NAME = data['data'][0]['NAME'];
                              this.NAME_HN = data['data'][0]['NAME_HN'];
                              this.OFFICE_NAME = data['data'][0]['OFFICE_NAME'];
                              this.OFFICE_NAME_HN =
                                data['data'][0]['OFFICE_NAME_HN'];
                              this.POST = data['data'][0]['POST'];
                              this.POST_HN = data['data'][0]['POST_HN'];
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                      this.api
                        .getHospitalMapping(
                          0,
                          0,
                          '',
                          'desc',
                          ' AND CLAIM_ID = ' + this.claimID
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.HospitalMapping = data['data'];
                              console.log(
                                'this.HospitalMapping',
                                this.HospitalMapping
                              );
                              // this.isSpinning = false;
                              this.api
                                .getAllQuestions(
                                  0,
                                  0,
                                  '',
                                  '',
                                  ' AND CLAIM_ID =' + this.claimID
                                )
                                .subscribe(
                                  (data) => {
                                    if (data['code'] == 200) {
                                      if (data['data'].length == 0) {
                                        this.queData = new QuestionaryMaster();
                                      } else {
                                        this.queData = data['data'][0];
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == '' &&
                                          this.queData.CGHS_AMA_REFERENCE_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_DATE.split(
                                              ','
                                            );
                                        }
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            '' &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_END_DATE.split(
                                              ','
                                            );
                                        }
                                      }
                                    } else {
                                      this.message.error(
                                        'Something Went Wrong',
                                        ''
                                      );
                                      // this.isSpinning = false;
                                    }
                                  },
                                  (err) => {
                                    console.log(err);
                                  }
                                );
                            } else {
                              this.message.error('Something Went Wrong', '');
                              // this.isSpinning = false;
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                    } else {
                      this.message.error("Can't Load Data", '');
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              this.isSpinning = false;
              this.refhodPermissionVisible = true;
            } else {
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
        } else {
          this.api.createQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              // this.message.success('Information Save Successfully...', '');
              this.api
                .getclaimMaster(
                  0,
                  0,
                  '',
                  '',
                  ' AND ID=' + this.claimID,
                  '',
                  '',
                  '',
                  ''
                )
                .subscribe(
                  (data) => {
                    if (data['code'] == 200) {
                      this.orderdata1 = data['data'][0];
                      this.claimID1 = data['data'][0]['ID'];
                      this.empID1 = data['data'][0]['EMP_ID'];
                      console.log('this.orderdata1', this.orderdata1);
                      this.api
                        .getSignature(
                          0,
                          0,
                          'ID',
                          'desc',
                          ' AND ID = ' + data['data'][0]['SIGNATURE_ID']
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.SECTION_TYPE =
                                data['data'][0]['SECTION_TYPE'];
                              this.NAME = data['data'][0]['NAME'];
                              this.NAME_HN = data['data'][0]['NAME_HN'];
                              this.OFFICE_NAME = data['data'][0]['OFFICE_NAME'];
                              this.OFFICE_NAME_HN =
                                data['data'][0]['OFFICE_NAME_HN'];
                              this.POST = data['data'][0]['POST'];
                              this.POST_HN = data['data'][0]['POST_HN'];
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                      this.api
                        .getHospitalMapping(
                          0,
                          0,
                          '',
                          'desc',
                          ' AND CLAIM_ID = ' + this.claimID
                        )
                        .subscribe(
                          (data) => {
                            if (data['code'] == 200) {
                              this.HospitalMapping = data['data'];
                              console.log(
                                'this.HospitalMapping',
                                this.HospitalMapping
                              );
                              // this.isSpinning = false;
                              this.api
                                .getAllQuestions(
                                  0,
                                  0,
                                  '',
                                  '',
                                  ' AND CLAIM_ID =' + this.claimID
                                )
                                .subscribe(
                                  (data) => {
                                    if (data['code'] == 200) {
                                      if (data['data'].length == 0) {
                                        this.queData = new QuestionaryMaster();
                                      } else {
                                        this.queData = data['data'][0];
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_DATE == '' &&
                                          this.queData.CGHS_AMA_REFERENCE_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_DATE.split(
                                              ','
                                            );
                                        }
                                        if (
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            undefined &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            null &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE ==
                                            '' &&
                                          this.queData
                                            .CGHS_AMA_REFERENCE_END_DATE
                                            .length == 0
                                        ) {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            [];
                                        } else {
                                          this.queData.CGHS_AMA_REFERENCE_END_DATE =
                                            this.queData.CGHS_AMA_REFERENCE_END_DATE.split(
                                              ','
                                            );
                                        }
                                      }
                                    } else {
                                      this.message.error(
                                        'Something Went Wrong',
                                        ''
                                      );
                                      this.isSpinning = false;
                                    }
                                  },
                                  (err) => {
                                    console.log(err);
                                  }
                                );
                            } else {
                              this.message.error('Something Went Wrong', '');
                              // this.isSpinning = false;
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                    } else {
                      this.message.error("Can't Load Data", '');
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              this.refhodPermissionVisible = true;
              this.isSpinning = false;
            } else {
              this.message.error('Failed To Fill Information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
    this.orderdata1 = [];
  }

  refhodPermissionSheetClose(): void {
    // this.delayOrderSheetVisible = false;
    this.disableReferanceDate();
    this.api
      .getAllQuestions(0, 0, '', '', ' AND CLAIM_ID =' + this.claimID)
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            if (data['data'].length == 0) {
              this.data3 = new QuestionaryMaster();
              this.billInTime();
              this.empanelledHospital();
              // this.current = 2;
              this.refhodPermissionVisible = false;
              this.isSpinning = false;
            } else {
              this.data3 = data['data'][0];
              this.emergencyTaken = data['data'][0]['EMERGENCY_TREATEMENT'];
              this.drCertificate =
                data['data'][0]['DOCTOR_EMERGENCY_CERTIFICATE_TAKEN'];
              this.empanelHospital = data['data'][0]['IS_HOSPITAL_EMPLANELLED'];
              this.expoFacto = data['data'][0]['EXPO_FACTO_PERMISSION'];
              this.referanceTaken =
                data['data'][0]['REFERENCE_FORM_CGHS_AMA_TAKEN'];
              this.hodPermission =
                data['data'][0]['IS_PERMISSION_TAKEN_FROM_HOD'];
              if (
                this.data3.CGHS_AMA_REFERENCE_DATE == undefined &&
                this.data3.CGHS_AMA_REFERENCE_DATE == null &&
                this.data3.CGHS_AMA_REFERENCE_DATE == '' &&
                this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
              ) {
                this.data3.CGHS_AMA_REFERENCE_DATE = [];
              } else {
                this.data3.CGHS_AMA_REFERENCE_DATE =
                  this.data3.CGHS_AMA_REFERENCE_DATE.split(',');
                this.dateDifference();
              }
              if (
                this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined &&
                this.data3.CGHS_AMA_REFERENCE_END_DATE == null &&
                this.data3.CGHS_AMA_REFERENCE_END_DATE == '' &&
                this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
              ) {
                this.data3.CGHS_AMA_REFERENCE_END_DATE = [];
              } else {
                this.data3.CGHS_AMA_REFERENCE_END_DATE =
                  this.data3.CGHS_AMA_REFERENCE_END_DATE.split(',');
                this.dateDifference();
              }
              this.calculateDiff();
              this.billInTime();
              this.empanelledHospital();
              // this.current = 2;
              this.isSpinning = false;
              this.refhodPermissionVisible = false;
            }
          } else {
            this.message.error('Something Went Wrong', '');
            this.isSpinning = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }
  get refhodPermissionSheetCloseCallback() {
    return this.refhodPermissionSheetClose.bind(this);
  }
  ///////////////////////////Permission Drawer Code //////////////////////////
  HODPermissionVisible: boolean = false;
  HODPermissionTitle: string = '';
  HODPermissionID: any;
  genHODPermission() {
    this.isSpinning = true;
    this.HODPermissionID = this.empID;
    this.data3.EMP_ID = this.empID;
    this.data3.CLAIM_ID = this.claimID;
    this.isOk = true;
    if (this.isOk) {
      this.isSpinning = true;
      this.data3.DELAY_CONDOLENCE_DATE = this.datepipe.transform(
        this.data3.DELAY_CONDOLENCE_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPOST_FACTO_PERMISSION_DATE = this.datepipe.transform(
        this.data3.EXPOST_FACTO_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPO_FACTO_DATE = this.datepipe.transform(
        this.data3.EXPO_FACTO_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      if (
        this.data3.CGHS_AMA_REFERENCE_DATE == undefined &&
        this.data3.CGHS_AMA_REFERENCE_DATE == null &&
        this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
      ) {
        this.data3.CGHS_AMA_REFERENCE_DATE = '';
      } else {
        this.data3.CGHS_AMA_REFERENCE_DATE = this.data3.CGHS_AMA_REFERENCE_DATE;
      }
      if (
        this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE == null &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
      ) {
        this.data3.CGHS_AMA_REFERENCE_END_DATE = '';
      } else {
        this.data3.CGHS_AMA_REFERENCE_END_DATE =
          this.data3.CGHS_AMA_REFERENCE_END_DATE;
      }

      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_DATE != undefined ||
          this.data3.DELAY_CONDOLENCE_DATE != null)
      ) {
        this.data3.DELAY_CONDOLENCE_DATE = this.data3.DELAY_CONDOLENCE_DATE;
      } else {
        this.data3.DELAY_CONDOLENCE_DATE = null;
      }

      if (
        this.data3.TREATEMENT_PERMISSION_START_DATE == undefined ||
        this.data3.TREATEMENT_PERMISSION_START_DATE == null ||
        this.data3.TREATEMENT_PERMISSION_START_DATE == ''
      ) {
        this.data3.TREATEMENT_PERMISSION_START_DATE = null;
      } else {
        this.data3.TREATEMENT_PERMISSION_START_DATE = this.datepipe.transform(
          this.data3.TREATEMENT_PERMISSION_START_DATE,
          'yyyy-MM-dd'
        );
      }
      if (
        this.data3.TREATEMENT_PERMISSION_END_DATE == undefined ||
        this.data3.TREATEMENT_PERMISSION_END_DATE == null ||
        this.data3.TREATEMENT_PERMISSION_END_DATE == ''
      ) {
        this.data3.TREATEMENT_PERMISSION_END_DATE = null;
      } else {
        this.data3.TREATEMENT_PERMISSION_END_DATE = this.datepipe.transform(
          this.data3.TREATEMENT_PERMISSION_END_DATE,
          'yyyy-MM-dd'
        );
      }

      if (
        this.data3.BILL_SUBMISSION_DATE == undefined ||
        this.data3.BILL_SUBMISSION_DATE == null ||
        this.data3.BILL_SUBMISSION_DATE == ''
      ) {
        this.data3.BILL_SUBMISSION_DATE = null;
      } else {
        this.data3.BILL_SUBMISSION_DATE = this.datepipe.transform(
          this.data3.BILL_SUBMISSION_DATE,
          'yyyy-MM-dd'
        );
      }
      if (
        this.data3.EXPOST_FACTO_ID == undefined ||
        this.data3.EXPOST_FACTO_ID == null ||
        this.data3.EXPOST_FACTO_ID == ''
      ) {
        this.data3.EXPOST_FACTO_ID = null;
      } else {
        this.data3.EXPOST_FACTO_ID = this.data3.EXPOST_FACTO_ID;
      }
      if (
        this.data3.DELAY_CONDOLATION_ID == undefined ||
        this.data3.DELAY_CONDOLATION_ID == null ||
        this.data3.DELAY_CONDOLATION_ID == ''
      ) {
        this.data3.DELAY_CONDOLATION_ID = null;
      } else {
        this.data3.DELAY_CONDOLATION_ID = this.data3.DELAY_CONDOLATION_ID;
      }
      if (
        this.data3.HOD_ID == undefined ||
        this.data3.HOD_ID == null ||
        this.data3.HOD_ID == ''
      ) {
        this.data3.HOD_ID = null;
      } else {
        this.data3.HOD_ID = this.data3.HOD_ID.toString();
      }

      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_NUMBER != undefined ||
          this.data3.DELAY_CONDOLENCE_NUMBER != null)
      ) {
        this.data3.DELAY_CONDOLENCE_NUMBER = this.data3.DELAY_CONDOLENCE_NUMBER;
      } else {
        this.data3.DELAY_CONDOLENCE_NUMBER = '';
      }
      {
        if (this.data3.ID) {
          this.api.updateQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              // this.message.success('Information Changed Successfully...', '');
              this.isSpinning = false;
              this.api
                .generateHODPermission(this.claimID)
                .subscribe((successCode) => {
                  if (successCode.code == '200') {
                    this.message.success(
                      'Permission Generated Successfully...',
                      ''
                    );
                    this.isSpinning = false;
                    this.api
                      .getHODPermissionMaster(
                        0,
                        0,
                        '',
                        '',
                        ' AND EMP_ID =' + this.empID
                      )
                      .subscribe(
                        (data) => {
                          if (data['code'] == 200) {
                            console.log(data);
                            this.totalRecords = data['count'];
                            // this.dataList = data['data'];
                            this.HODPermissionList = data['data'];
                            this.delayLoadingRecords = false;
                            this.api
                              .getAllQuestions(
                                0,
                                0,
                                '',
                                '',
                                ' AND CLAIM_ID =' + this.claimID
                              )
                              .subscribe(
                                (data) => {
                                  if (data['code'] == 200) {
                                    if (data['data'].length == 0) {
                                      this.isSpinning = false;
                                    } else {
                                      this.data3 = data['data'][0];
                                      this.emergencyTaken =
                                        data['data'][0]['EMERGENCY_TREATEMENT'];
                                      this.drCertificate =
                                        data['data'][0][
                                          'DOCTOR_EMERGENCY_CERTIFICATE_TAKEN'
                                        ];
                                      this.empanelHospital =
                                        data['data'][0][
                                          'IS_HOSPITAL_EMPLANELLED'
                                        ];
                                      this.expoFacto =
                                        data['data'][0][
                                          'EXPO_FACTO_PERMISSION'
                                        ];
                                      this.referanceTaken =
                                        data['data'][0][
                                          'REFERENCE_FORM_CGHS_AMA_TAKEN'
                                        ];
                                      this.hodPermission =
                                        data['data'][0][
                                          'IS_PERMISSION_TAKEN_FROM_HOD'
                                        ];
                                      if (
                                        this.data3.CGHS_AMA_REFERENCE_DATE ==
                                          undefined &&
                                        this.data3.CGHS_AMA_REFERENCE_DATE ==
                                          null &&
                                        this.data3.CGHS_AMA_REFERENCE_DATE ==
                                          '' &&
                                        this.data3.CGHS_AMA_REFERENCE_DATE
                                          .length == 0
                                      ) {
                                        this.data3.CGHS_AMA_REFERENCE_DATE = [];
                                      } else {
                                        this.data3.CGHS_AMA_REFERENCE_DATE =
                                          this.data3.CGHS_AMA_REFERENCE_DATE.split(
                                            ','
                                          );
                                      }
                                      if (
                                        this.data3
                                          .CGHS_AMA_REFERENCE_END_DATE ==
                                          undefined &&
                                        this.data3
                                          .CGHS_AMA_REFERENCE_END_DATE ==
                                          null &&
                                        this.data3
                                          .CGHS_AMA_REFERENCE_END_DATE == '' &&
                                        this.data3.CGHS_AMA_REFERENCE_END_DATE
                                          .length == 0
                                      ) {
                                        this.data3.CGHS_AMA_REFERENCE_END_DATE =
                                          [];
                                      } else {
                                        this.data3.CGHS_AMA_REFERENCE_END_DATE =
                                          this.data3.CGHS_AMA_REFERENCE_END_DATE.split(
                                            ','
                                          );
                                      }
                                      this.isSpinning = false;
                                    }
                                    this.allDelayCondonationList();
                                    this.allExpostFactoList();
                                  } else {
                                    this.message.error(
                                      'Something Went Wrong',
                                      ''
                                    );
                                    this.isSpinning = false;
                                  }
                                },
                                (err) => {
                                  console.log(err);
                                }
                              );
                            // this.HODPermissionVisible = true;
                          } else {
                            this.message.error("Can't Load Data", '');
                          }
                        },
                        (err) => {
                          console.log(err);
                        }
                      );
                  } else {
                    this.message.error('Failed To Generate Permission ...', '');
                    this.isSpinning = false;
                  }
                });
            } else {
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
        } else {
          this.api.createQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              // this.message.success('Information Save Successfully...', '');
              this.isSpinning = false;
              this.api
                .generateHODPermission(this.claimID)
                .subscribe((successCode) => {
                  if (successCode.code == '200') {
                    this.message.success(
                      'Permission Generated Successfully...',
                      ''
                    );
                    this.isSpinning = false;
                    this.api
                      .getHODPermissionMaster(
                        0,
                        0,
                        '',
                        '',
                        ' AND EMP_ID =' + this.empID
                      )
                      .subscribe(
                        (data) => {
                          if (data['code'] == 200) {
                            console.log(data);
                            this.totalRecords = data['count'];
                            this.HODPermissionList = data['data'];
                            // this.dataList = data['data'];
                            this.delayLoadingRecords = false;
                            this.api
                              .getAllQuestions(
                                0,
                                0,
                                '',
                                '',
                                ' AND CLAIM_ID =' + this.claimID
                              )
                              .subscribe(
                                (data) => {
                                  if (data['code'] == 200) {
                                    if (data['data'].length == 0) {
                                      this.isSpinning = false;
                                    } else {
                                      this.data3 = data['data'][0];
                                      this.emergencyTaken =
                                        data['data'][0]['EMERGENCY_TREATEMENT'];
                                      this.drCertificate =
                                        data['data'][0][
                                          'DOCTOR_EMERGENCY_CERTIFICATE_TAKEN'
                                        ];
                                      this.empanelHospital =
                                        data['data'][0][
                                          'IS_HOSPITAL_EMPLANELLED'
                                        ];
                                      this.expoFacto =
                                        data['data'][0][
                                          'EXPO_FACTO_PERMISSION'
                                        ];
                                      this.referanceTaken =
                                        data['data'][0][
                                          'REFERENCE_FORM_CGHS_AMA_TAKEN'
                                        ];
                                      this.hodPermission =
                                        data['data'][0][
                                          'IS_PERMISSION_TAKEN_FROM_HOD'
                                        ];
                                      if (
                                        this.data3.CGHS_AMA_REFERENCE_DATE ==
                                          undefined &&
                                        this.data3.CGHS_AMA_REFERENCE_DATE ==
                                          null &&
                                        this.data3.CGHS_AMA_REFERENCE_DATE ==
                                          '' &&
                                        this.data3.CGHS_AMA_REFERENCE_DATE
                                          .length == 0
                                      ) {
                                        this.data3.CGHS_AMA_REFERENCE_DATE = [];
                                      } else {
                                        this.data3.CGHS_AMA_REFERENCE_DATE =
                                          this.data3.CGHS_AMA_REFERENCE_DATE.split(
                                            ','
                                          );
                                      }
                                      if (
                                        this.data3
                                          .CGHS_AMA_REFERENCE_END_DATE ==
                                          undefined &&
                                        this.data3
                                          .CGHS_AMA_REFERENCE_END_DATE ==
                                          null &&
                                        this.data3
                                          .CGHS_AMA_REFERENCE_END_DATE == '' &&
                                        this.data3.CGHS_AMA_REFERENCE_END_DATE
                                          .length == 0
                                      ) {
                                        this.data3.CGHS_AMA_REFERENCE_END_DATE =
                                          [];
                                      } else {
                                        this.data3.CGHS_AMA_REFERENCE_END_DATE =
                                          this.data3.CGHS_AMA_REFERENCE_END_DATE.split(
                                            ','
                                          );
                                      }
                                      this.isSpinning = false;
                                    }
                                    this.allDelayCondonationList();
                                    this.allExpostFactoList();
                                  } else {
                                    this.message.error(
                                      'Something Went Wrong',
                                      ''
                                    );
                                    this.isSpinning = false;
                                  }
                                },
                                (err) => {
                                  console.log(err);
                                }
                              );
                            // this.HODPermissionVisible = true;
                          } else {
                            this.message.error("Can't Load Data", '');
                          }
                        },
                        (err) => {
                          console.log(err);
                        }
                      );
                  } else {
                    this.message.error('Failed To Generate Permission ...', '');
                    this.isSpinning = false;
                  }
                });
            } else {
              this.message.error('Failed To Fill Information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
  }

  HODPermissionDrawerClose(): void {
    this.HODPermissionVisible = false;
    this.allHODPermissionList();
  }

  get HODPermissionCloseCallback() {
    return this.HODPermissionDrawerClose.bind(this);
  }
  delayCondonationVisible: boolean = false;
  delayCondonationTitle: string = '';
  delayCondonationID: any;
  dataList = [];
  totalRecords: any;
  delayLoadingRecords: boolean = false;
  genDelayCondonation() {
    this.isSpinning = true;
    this.empID = this.empID;
    this.data3.EMP_ID = this.empID;
    this.data3.CLAIM_ID = this.claimID;
    this.isOk = true;
    if (this.isOk) {
      this.isSpinning = true;
      this.data3.DELAY_CONDOLENCE_DATE = this.datepipe.transform(
        this.data3.DELAY_CONDOLENCE_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPOST_FACTO_PERMISSION_DATE = this.datepipe.transform(
        this.data3.EXPOST_FACTO_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPO_FACTO_DATE = this.datepipe.transform(
        this.data3.EXPO_FACTO_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      if (
        this.data3.CGHS_AMA_REFERENCE_DATE == undefined &&
        this.data3.CGHS_AMA_REFERENCE_DATE == null &&
        this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
      ) {
        this.data3.CGHS_AMA_REFERENCE_DATE = '';
      } else {
        this.data3.CGHS_AMA_REFERENCE_DATE = this.data3.CGHS_AMA_REFERENCE_DATE;
      }
      if (
        this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE == null &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
      ) {
        this.data3.CGHS_AMA_REFERENCE_END_DATE = '';
      } else {
        this.data3.CGHS_AMA_REFERENCE_END_DATE =
          this.data3.CGHS_AMA_REFERENCE_END_DATE;
      }

      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_DATE != undefined ||
          this.data3.DELAY_CONDOLENCE_DATE != null)
      ) {
        this.data3.DELAY_CONDOLENCE_DATE = this.data3.DELAY_CONDOLENCE_DATE;
      } else {
        this.data3.DELAY_CONDOLENCE_DATE = null;
      }

      if (
        this.data3.TREATEMENT_PERMISSION_START_DATE == undefined ||
        this.data3.TREATEMENT_PERMISSION_START_DATE == null ||
        this.data3.TREATEMENT_PERMISSION_START_DATE == ''
      ) {
        this.data3.TREATEMENT_PERMISSION_START_DATE = null;
      } else {
        this.data3.TREATEMENT_PERMISSION_START_DATE = this.datepipe.transform(
          this.data3.TREATEMENT_PERMISSION_START_DATE,
          'yyyy-MM-dd'
        );
      }
      if (
        this.data3.TREATEMENT_PERMISSION_END_DATE == undefined ||
        this.data3.TREATEMENT_PERMISSION_END_DATE == null ||
        this.data3.TREATEMENT_PERMISSION_END_DATE == ''
      ) {
        this.data3.TREATEMENT_PERMISSION_END_DATE = null;
      } else {
        this.data3.TREATEMENT_PERMISSION_END_DATE = this.datepipe.transform(
          this.data3.TREATEMENT_PERMISSION_END_DATE,
          'yyyy-MM-dd'
        );
      }

      if (
        this.data3.BILL_SUBMISSION_DATE == undefined ||
        this.data3.BILL_SUBMISSION_DATE == null ||
        this.data3.BILL_SUBMISSION_DATE == ''
      ) {
        this.data3.BILL_SUBMISSION_DATE = null;
      } else {
        this.data3.BILL_SUBMISSION_DATE = this.datepipe.transform(
          this.data3.BILL_SUBMISSION_DATE,
          'yyyy-MM-dd'
        );
      }
      if (
        this.data3.EXPOST_FACTO_ID == undefined ||
        this.data3.EXPOST_FACTO_ID == null ||
        this.data3.EXPOST_FACTO_ID == ''
      ) {
        this.data3.EXPOST_FACTO_ID = null;
      } else {
        this.data3.EXPOST_FACTO_ID = this.data3.EXPOST_FACTO_ID;
      }
      if (
        this.data3.DELAY_CONDOLATION_ID == undefined ||
        this.data3.DELAY_CONDOLATION_ID == null ||
        this.data3.DELAY_CONDOLATION_ID == ''
      ) {
        this.data3.DELAY_CONDOLATION_ID = null;
      } else {
        this.data3.DELAY_CONDOLATION_ID = this.data3.DELAY_CONDOLATION_ID;
      }
      if (
        this.data3.HOD_ID == undefined ||
        this.data3.HOD_ID == null ||
        this.data3.HOD_ID == ''
      ) {
        this.data3.HOD_ID = null;
      } else {
        this.data3.HOD_ID = this.data3.HOD_ID.toString();
      }

      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_NUMBER != undefined ||
          this.data3.DELAY_CONDOLENCE_NUMBER != null)
      ) {
        this.data3.DELAY_CONDOLENCE_NUMBER = this.data3.DELAY_CONDOLENCE_NUMBER;
      } else {
        this.data3.DELAY_CONDOLENCE_NUMBER = '';
      }
      {
        if (this.data3.ID) {
          this.api.updateQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              // this.message.success('Information Changed Successfully...', '');
              this.isSpinning = false;
              this.api
                .generateDelayCondonation(this.claimID)
                .subscribe((successCode) => {
                  if (successCode.code == '200') {
                    this.message.success(
                      'Permission Generated Successfully...',
                      ''
                    );
                    this.isSpinning = false;
                    this.api
                      .getDelayCondolationMaster(
                        0,
                        0,
                        '',
                        '',
                        ' AND EMP_ID =' + this.empID
                      )
                      .subscribe(
                        (data) => {
                          if (data['code'] == 200) {
                            console.log(data);
                            this.totalRecords = data['count'];
                            // this.dataList = data['data'];
                            this.delayCondolationList = data['data'];
                            this.delayLoadingRecords = false;
                            this.api
                              .getAllQuestions(
                                0,
                                0,
                                '',
                                '',
                                ' AND CLAIM_ID =' + this.claimID
                              )
                              .subscribe(
                                (data) => {
                                  if (data['code'] == 200) {
                                    if (data['data'].length == 0) {
                                      this.isSpinning = false;
                                    } else {
                                      this.data3 = data['data'][0];
                                      this.emergencyTaken =
                                        data['data'][0]['EMERGENCY_TREATEMENT'];
                                      this.drCertificate =
                                        data['data'][0][
                                          'DOCTOR_EMERGENCY_CERTIFICATE_TAKEN'
                                        ];
                                      this.empanelHospital =
                                        data['data'][0][
                                          'IS_HOSPITAL_EMPLANELLED'
                                        ];
                                      this.expoFacto =
                                        data['data'][0][
                                          'EXPO_FACTO_PERMISSION'
                                        ];
                                      this.referanceTaken =
                                        data['data'][0][
                                          'REFERENCE_FORM_CGHS_AMA_TAKEN'
                                        ];
                                      this.hodPermission =
                                        data['data'][0][
                                          'IS_PERMISSION_TAKEN_FROM_HOD'
                                        ];
                                      if (
                                        this.data3.CGHS_AMA_REFERENCE_DATE ==
                                          undefined &&
                                        this.data3.CGHS_AMA_REFERENCE_DATE ==
                                          null &&
                                        this.data3.CGHS_AMA_REFERENCE_DATE ==
                                          '' &&
                                        this.data3.CGHS_AMA_REFERENCE_DATE
                                          .length == 0
                                      ) {
                                        this.data3.CGHS_AMA_REFERENCE_DATE = [];
                                      } else {
                                        this.data3.CGHS_AMA_REFERENCE_DATE =
                                          this.data3.CGHS_AMA_REFERENCE_DATE.split(
                                            ','
                                          );
                                      }
                                      if (
                                        this.data3
                                          .CGHS_AMA_REFERENCE_END_DATE ==
                                          undefined &&
                                        this.data3
                                          .CGHS_AMA_REFERENCE_END_DATE ==
                                          null &&
                                        this.data3
                                          .CGHS_AMA_REFERENCE_END_DATE == '' &&
                                        this.data3.CGHS_AMA_REFERENCE_END_DATE
                                          .length == 0
                                      ) {
                                        this.data3.CGHS_AMA_REFERENCE_END_DATE =
                                          [];
                                      } else {
                                        this.data3.CGHS_AMA_REFERENCE_END_DATE =
                                          this.data3.CGHS_AMA_REFERENCE_END_DATE.split(
                                            ','
                                          );
                                      }
                                      this.isSpinning = false;
                                    }
                                    this.allHODPermissionList();
                                    this.allExpostFactoList();
                                  } else {
                                    this.message.error(
                                      'Something Went Wrong',
                                      ''
                                    );
                                    this.isSpinning = false;
                                  }
                                },
                                (err) => {
                                  console.log(err);
                                }
                              );
                            // this.HODPermissionVisible = true;
                          } else {
                            this.message.error("Can't Load Data", '');
                          }
                        },
                        (err) => {
                          console.log(err);
                        }
                      );
                  } else {
                    this.message.error('Failed To Generate Permission ...', '');
                    this.isSpinning = false;
                  }
                });
            } else {
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
        } else {
          this.api.createQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              // this.message.success('Information Save Successfully...', '');
              this.isSpinning = false;
              this.api
                .generateDelayCondonation(this.claimID)
                .subscribe((successCode) => {
                  if (successCode.code == '200') {
                    this.message.success(
                      'Permission Generated Successfully...',
                      ''
                    );
                    this.isSpinning = false;
                    this.api
                      .getDelayCondolationMaster(
                        0,
                        0,
                        '',
                        '',
                        ' AND EMP_ID =' + this.empID
                      )
                      .subscribe(
                        (data) => {
                          if (data['code'] == 200) {
                            console.log(data);
                            this.totalRecords = data['count'];
                            this.delayCondolationList = data['data'];
                            // this.dataList = data['data'];
                            this.delayLoadingRecords = false;
                            this.api
                              .getAllQuestions(
                                0,
                                0,
                                '',
                                '',
                                ' AND CLAIM_ID =' + this.claimID
                              )
                              .subscribe(
                                (data) => {
                                  if (data['code'] == 200) {
                                    if (data['data'].length == 0) {
                                      this.isSpinning = false;
                                    } else {
                                      this.data3 = data['data'][0];
                                      this.emergencyTaken =
                                        data['data'][0]['EMERGENCY_TREATEMENT'];
                                      this.drCertificate =
                                        data['data'][0][
                                          'DOCTOR_EMERGENCY_CERTIFICATE_TAKEN'
                                        ];
                                      this.empanelHospital =
                                        data['data'][0][
                                          'IS_HOSPITAL_EMPLANELLED'
                                        ];
                                      this.expoFacto =
                                        data['data'][0][
                                          'EXPO_FACTO_PERMISSION'
                                        ];
                                      this.referanceTaken =
                                        data['data'][0][
                                          'REFERENCE_FORM_CGHS_AMA_TAKEN'
                                        ];
                                      this.hodPermission =
                                        data['data'][0][
                                          'IS_PERMISSION_TAKEN_FROM_HOD'
                                        ];
                                      if (
                                        this.data3.CGHS_AMA_REFERENCE_DATE ==
                                          undefined &&
                                        this.data3.CGHS_AMA_REFERENCE_DATE ==
                                          null &&
                                        this.data3.CGHS_AMA_REFERENCE_DATE ==
                                          '' &&
                                        this.data3.CGHS_AMA_REFERENCE_DATE
                                          .length == 0
                                      ) {
                                        this.data3.CGHS_AMA_REFERENCE_DATE = [];
                                      } else {
                                        this.data3.CGHS_AMA_REFERENCE_DATE =
                                          this.data3.CGHS_AMA_REFERENCE_DATE.split(
                                            ','
                                          );
                                      }
                                      if (
                                        this.data3
                                          .CGHS_AMA_REFERENCE_END_DATE ==
                                          undefined &&
                                        this.data3
                                          .CGHS_AMA_REFERENCE_END_DATE ==
                                          null &&
                                        this.data3
                                          .CGHS_AMA_REFERENCE_END_DATE == '' &&
                                        this.data3.CGHS_AMA_REFERENCE_END_DATE
                                          .length == 0
                                      ) {
                                        this.data3.CGHS_AMA_REFERENCE_END_DATE =
                                          [];
                                      } else {
                                        this.data3.CGHS_AMA_REFERENCE_END_DATE =
                                          this.data3.CGHS_AMA_REFERENCE_END_DATE.split(
                                            ','
                                          );
                                      }
                                      this.isSpinning = false;
                                    }
                                    this.allHODPermissionList();
                                    this.allExpostFactoList();
                                  } else {
                                    this.message.error(
                                      'Something Went Wrong',
                                      ''
                                    );
                                    this.isSpinning = false;
                                  }
                                },
                                (err) => {
                                  console.log(err);
                                }
                              );
                            // this.HODPermissionVisible = true;
                          } else {
                            this.message.error("Can't Load Data", '');
                          }
                        },
                        (err) => {
                          console.log(err);
                        }
                      );
                  } else {
                    this.message.error('Failed To Generate Permission ...', '');
                    this.isSpinning = false;
                  }
                });
            } else {
              this.message.error('Failed To Fill Information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
    ///////////////////////////////////
    // this.api.generateDelayCondonation(this.data.ID).subscribe((successCode) => {
    //   if (successCode.code == '200') {
    //     this.delayCondonationID = successCode.DELAY_CONDOLATION_ID;
    //     this.message.success('Permission Generate Successfully...', '');
    //     this.api
    //       .getDelayCondolationMaster(0, 0, '', '', ' AND EMP_ID =' + this.empID)
    //       .subscribe(
    //         (data) => {
    //           if (data['code'] == 200) {
    //             console.log(data);
    //             this.totalRecords = data['count'];
    //             // this.dataList = data['data'];
    //             this.delayCondolationList = data['data'];
    //             this.delayLoadingRecords = false;
    //             // this.delayCondonationVisible = true;
    //           } else {
    //             this.message.error("Can't Load Data", '');
    //           }
    //         },
    //         (err) => {
    //           console.log(err);
    //         }
    //       );
    //     this.delayCondonationTitle = 'Delay Condonation Permission List';
    //     this.isSpinning = false;
    //   } else {
    //     this.message.error('Failed To Generated Permission ...', '');
    //     this.isSpinning = false;
    //   }
    // });
  }

  delayCondonationDrawerClose(): void {
    this.delayCondonationVisible = false;
    this.allDelayCondonationList();
  }

  get delayCondonationCloseCallback() {
    return this.delayCondonationDrawerClose.bind(this);
  }

  expostFactoVisible: boolean = false;
  ExpostLoadingRecords: boolean = false;
  expostFactoTitle: string = '';
  expostFactoID: any;
  genExpostFacto() {
    this.isSpinning = true;
    this.ExpostLoadingRecords = false;
    this.expostFactoID = this.empID;
    this.data3.EMP_ID = this.empID;
    this.data3.CLAIM_ID = this.claimID;
    this.isOk = true;
    if (this.isOk) {
      this.isSpinning = true;
      this.data3.DELAY_CONDOLENCE_DATE = this.datepipe.transform(
        this.data3.DELAY_CONDOLENCE_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPOST_FACTO_PERMISSION_DATE = this.datepipe.transform(
        this.data3.EXPOST_FACTO_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.EXPO_FACTO_DATE = this.datepipe.transform(
        this.data3.EXPO_FACTO_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(
        this.data3.HOD_PERMISSION_DATE,
        'yyyy-MM-dd'
      );
      if (
        this.data3.CGHS_AMA_REFERENCE_DATE == undefined &&
        this.data3.CGHS_AMA_REFERENCE_DATE == null &&
        this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
      ) {
        this.data3.CGHS_AMA_REFERENCE_DATE = '';
      } else {
        this.data3.CGHS_AMA_REFERENCE_DATE = this.data3.CGHS_AMA_REFERENCE_DATE;
      }
      if (
        this.data3.CGHS_AMA_REFERENCE_END_DATE == undefined &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE == null &&
        this.data3.CGHS_AMA_REFERENCE_END_DATE.length == 0
      ) {
        this.data3.CGHS_AMA_REFERENCE_END_DATE = '';
      } else {
        this.data3.CGHS_AMA_REFERENCE_END_DATE =
          this.data3.CGHS_AMA_REFERENCE_END_DATE;
      }

      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_DATE != undefined ||
          this.data3.DELAY_CONDOLENCE_DATE != null)
      ) {
        this.data3.DELAY_CONDOLENCE_DATE = this.data3.DELAY_CONDOLENCE_DATE;
      } else {
        this.data3.DELAY_CONDOLENCE_DATE = null;
      }

      if (
        this.data3.TREATEMENT_PERMISSION_START_DATE == undefined ||
        this.data3.TREATEMENT_PERMISSION_START_DATE == null ||
        this.data3.TREATEMENT_PERMISSION_START_DATE == ''
      ) {
        this.data3.TREATEMENT_PERMISSION_START_DATE = null;
      } else {
        this.data3.TREATEMENT_PERMISSION_START_DATE = this.datepipe.transform(
          this.data3.TREATEMENT_PERMISSION_START_DATE,
          'yyyy-MM-dd'
        );
      }
      if (
        this.data3.TREATEMENT_PERMISSION_END_DATE == undefined ||
        this.data3.TREATEMENT_PERMISSION_END_DATE == null ||
        this.data3.TREATEMENT_PERMISSION_END_DATE == ''
      ) {
        this.data3.TREATEMENT_PERMISSION_END_DATE = null;
      } else {
        this.data3.TREATEMENT_PERMISSION_END_DATE = this.datepipe.transform(
          this.data3.TREATEMENT_PERMISSION_END_DATE,
          'yyyy-MM-dd'
        );
      }

      if (
        this.data3.BILL_SUBMISSION_DATE == undefined ||
        this.data3.BILL_SUBMISSION_DATE == null ||
        this.data3.BILL_SUBMISSION_DATE == ''
      ) {
        this.data3.BILL_SUBMISSION_DATE = null;
      } else {
        this.data3.BILL_SUBMISSION_DATE = this.datepipe.transform(
          this.data3.BILL_SUBMISSION_DATE,
          'yyyy-MM-dd'
        );
      }
      if (
        this.data3.EXPOST_FACTO_ID == undefined ||
        this.data3.EXPOST_FACTO_ID == null ||
        this.data3.EXPOST_FACTO_ID == ''
      ) {
        this.data3.EXPOST_FACTO_ID = null;
      } else {
        this.data3.EXPOST_FACTO_ID = this.data3.EXPOST_FACTO_ID;
      }
      if (
        this.data3.DELAY_CONDOLATION_ID == undefined ||
        this.data3.DELAY_CONDOLATION_ID == null ||
        this.data3.DELAY_CONDOLATION_ID == ''
      ) {
        this.data3.DELAY_CONDOLATION_ID = null;
      } else {
        this.data3.DELAY_CONDOLATION_ID = this.data3.DELAY_CONDOLATION_ID;
      }
      if (
        this.data3.HOD_ID == undefined ||
        this.data3.HOD_ID == null ||
        this.data3.HOD_ID == ''
      ) {
        this.data3.HOD_ID = null;
      } else {
        this.data3.HOD_ID = this.data3.HOD_ID.toString();
      }

      if (
        this.data3.BILL_FILLED_INTIME == false &&
        (this.data3.DELAY_CONDOLENCE_NUMBER != undefined ||
          this.data3.DELAY_CONDOLENCE_NUMBER != null)
      ) {
        this.data3.DELAY_CONDOLENCE_NUMBER = this.data3.DELAY_CONDOLENCE_NUMBER;
      } else {
        this.data3.DELAY_CONDOLENCE_NUMBER = '';
      }
      {
        if (this.data3.ID) {
          this.api.updateQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              // this.message.success('Information Changed Successfully...', '');
              this.isSpinning = false;
              this.api
                .generateExpostFacto(this.claimID)
                .subscribe((successCode) => {
                  if (successCode.code == '200') {
                    this.message.success(
                      'Permission Generated Successfully...',
                      ''
                    );
                    this.isSpinning = false;
                    this.api
                      .getExpostFactoMaster(
                        0,
                        0,
                        '',
                        '',
                        ' AND EMP_ID =' + this.empID
                      )
                      .subscribe(
                        (data) => {
                          if (data['code'] == 200) {
                            console.log(data);
                            this.totalRecords = data['count'];
                            // this.dataList = data['data'];
                            this.expostFactoList = data['data'];
                            this.delayLoadingRecords = false;
                            this.api
                              .getAllQuestions(
                                0,
                                0,
                                '',
                                '',
                                ' AND CLAIM_ID =' + this.claimID
                              )
                              .subscribe(
                                (data) => {
                                  if (data['code'] == 200) {
                                    if (data['data'].length == 0) {
                                      this.isSpinning = false;
                                    } else {
                                      this.data3 = data['data'][0];
                                      this.emergencyTaken =
                                        data['data'][0]['EMERGENCY_TREATEMENT'];
                                      this.drCertificate =
                                        data['data'][0][
                                          'DOCTOR_EMERGENCY_CERTIFICATE_TAKEN'
                                        ];
                                      this.empanelHospital =
                                        data['data'][0][
                                          'IS_HOSPITAL_EMPLANELLED'
                                        ];
                                      this.expoFacto =
                                        data['data'][0][
                                          'EXPO_FACTO_PERMISSION'
                                        ];
                                      this.referanceTaken =
                                        data['data'][0][
                                          'REFERENCE_FORM_CGHS_AMA_TAKEN'
                                        ];
                                      this.hodPermission =
                                        data['data'][0][
                                          'IS_PERMISSION_TAKEN_FROM_HOD'
                                        ];
                                      if (
                                        this.data3.CGHS_AMA_REFERENCE_DATE ==
                                          undefined &&
                                        this.data3.CGHS_AMA_REFERENCE_DATE ==
                                          null &&
                                        this.data3.CGHS_AMA_REFERENCE_DATE ==
                                          '' &&
                                        this.data3.CGHS_AMA_REFERENCE_DATE
                                          .length == 0
                                      ) {
                                        this.data3.CGHS_AMA_REFERENCE_DATE = [];
                                      } else {
                                        this.data3.CGHS_AMA_REFERENCE_DATE =
                                          this.data3.CGHS_AMA_REFERENCE_DATE.split(
                                            ','
                                          );
                                      }
                                      if (
                                        this.data3
                                          .CGHS_AMA_REFERENCE_END_DATE ==
                                          undefined &&
                                        this.data3
                                          .CGHS_AMA_REFERENCE_END_DATE ==
                                          null &&
                                        this.data3
                                          .CGHS_AMA_REFERENCE_END_DATE == '' &&
                                        this.data3.CGHS_AMA_REFERENCE_END_DATE
                                          .length == 0
                                      ) {
                                        this.data3.CGHS_AMA_REFERENCE_END_DATE =
                                          [];
                                      } else {
                                        this.data3.CGHS_AMA_REFERENCE_END_DATE =
                                          this.data3.CGHS_AMA_REFERENCE_END_DATE.split(
                                            ','
                                          );
                                      }
                                      this.isSpinning = false;
                                    }
                                    this.allHODPermissionList();
                                    this.allDelayCondonationList();
                                  } else {
                                    this.message.error(
                                      'Something Went Wrong',
                                      ''
                                    );
                                    this.isSpinning = false;
                                  }
                                },
                                (err) => {
                                  console.log(err);
                                }
                              );
                            // this.HODPermissionVisible = true;
                          } else {
                            this.message.error("Can't Load Data", '');
                          }
                        },
                        (err) => {
                          console.log(err);
                        }
                      );
                  } else {
                    this.message.error('Failed To Generate Permission ...', '');
                    this.isSpinning = false;
                  }
                });
            } else {
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
        } else {
          this.api.createQuestions(this.data3).subscribe((successCode) => {
            if (successCode.code == '200') {
              // this.message.success('Information Save Successfully...', '');
              this.isSpinning = false;
              this.api
                .generateExpostFacto(this.claimID)
                .subscribe((successCode) => {
                  if (successCode.code == '200') {
                    this.message.success(
                      'Permission Generated Successfully...',
                      ''
                    );
                    this.isSpinning = false;
                    this.api
                      .getExpostFactoMaster(
                        0,
                        0,
                        '',
                        '',
                        ' AND EMP_ID =' + this.empID
                      )
                      .subscribe(
                        (data) => {
                          if (data['code'] == 200) {
                            console.log(data);
                            this.totalRecords = data['count'];
                            this.expostFactoList = data['data'];
                            // this.dataList = data['data'];
                            this.delayLoadingRecords = false;
                            this.api
                              .getAllQuestions(
                                0,
                                0,
                                '',
                                '',
                                ' AND CLAIM_ID =' + this.claimID
                              )
                              .subscribe(
                                (data) => {
                                  if (data['code'] == 200) {
                                    if (data['data'].length == 0) {
                                      this.isSpinning = false;
                                    } else {
                                      this.data3 = data['data'][0];
                                      this.emergencyTaken =
                                        data['data'][0]['EMERGENCY_TREATEMENT'];
                                      this.drCertificate =
                                        data['data'][0][
                                          'DOCTOR_EMERGENCY_CERTIFICATE_TAKEN'
                                        ];
                                      this.empanelHospital =
                                        data['data'][0][
                                          'IS_HOSPITAL_EMPLANELLED'
                                        ];
                                      this.expoFacto =
                                        data['data'][0][
                                          'EXPO_FACTO_PERMISSION'
                                        ];
                                      this.referanceTaken =
                                        data['data'][0][
                                          'REFERENCE_FORM_CGHS_AMA_TAKEN'
                                        ];
                                      this.hodPermission =
                                        data['data'][0][
                                          'IS_PERMISSION_TAKEN_FROM_HOD'
                                        ];
                                      if (
                                        this.data3.CGHS_AMA_REFERENCE_DATE ==
                                          undefined &&
                                        this.data3.CGHS_AMA_REFERENCE_DATE ==
                                          null &&
                                        this.data3.CGHS_AMA_REFERENCE_DATE ==
                                          '' &&
                                        this.data3.CGHS_AMA_REFERENCE_DATE
                                          .length == 0
                                      ) {
                                        this.data3.CGHS_AMA_REFERENCE_DATE = [];
                                      } else {
                                        this.data3.CGHS_AMA_REFERENCE_DATE =
                                          this.data3.CGHS_AMA_REFERENCE_DATE.split(
                                            ','
                                          );
                                      }
                                      if (
                                        this.data3
                                          .CGHS_AMA_REFERENCE_END_DATE ==
                                          undefined &&
                                        this.data3
                                          .CGHS_AMA_REFERENCE_END_DATE ==
                                          null &&
                                        this.data3
                                          .CGHS_AMA_REFERENCE_END_DATE == '' &&
                                        this.data3.CGHS_AMA_REFERENCE_END_DATE
                                          .length == 0
                                      ) {
                                        this.data3.CGHS_AMA_REFERENCE_END_DATE =
                                          [];
                                      } else {
                                        this.data3.CGHS_AMA_REFERENCE_END_DATE =
                                          this.data3.CGHS_AMA_REFERENCE_END_DATE.split(
                                            ','
                                          );
                                      }
                                      this.isSpinning = false;
                                    }
                                    this.allHODPermissionList();
                                    this.allDelayCondonationList();
                                  } else {
                                    this.message.error(
                                      'Something Went Wrong',
                                      ''
                                    );
                                    this.isSpinning = false;
                                  }
                                },
                                (err) => {
                                  console.log(err);
                                }
                              );
                            // this.HODPermissionVisible = true;
                          } else {
                            this.message.error("Can't Load Data", '');
                          }
                        },
                        (err) => {
                          console.log(err);
                        }
                      );
                  } else {
                    this.message.error('Failed To Generate Permission ...', '');
                    this.isSpinning = false;
                  }
                });
            } else {
              this.message.error('Failed To Fill Information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
    //////////////////////////////////////////////
    // this.api.generateExpostFacto(this.data.ID).subscribe((successCode) => {
    //   if (successCode.code == '200') {
    //     this.message.success('Permission Generate Successfully...', '');
    //     this.api
    //       .getExpostFactoMaster(0, 0, '', '', ' AND EMP_ID =' + this.empID)
    //       .subscribe(
    //         (data) => {
    //           if (data['code'] == 200) {
    //             console.log(data);
    //             this.totalRecords = data['count'];
    //             // this.dataList = data['data'];
    //             this.expostFactoList = data['data'];
    //             this.delayLoadingRecords = false;
    //             // this.expostFactoVisible = true;
    //           } else {
    //             this.message.error("Can't Load Data", '');
    //           }
    //         },
    //         (err) => {
    //           console.log(err);
    //         }
    //       );
    //     this.isSpinning = false;
    //   } else {
    //     this.message.error('Failed To Generated Permission ...', '');
    //     this.isSpinning = false;
    //   }
    // });
  }

  expostFactoDrawerClose(): void {
    this.expostFactoVisible = false;
    this.allExpostFactoList();
  }

  get expostFactoCloseCallback() {
    return this.expostFactoDrawerClose.bind(this);
  }
  ///////////////////////////Permission Drawer Code //////////////////////////
}
