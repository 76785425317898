<div nz-row nzType="flex" style="margin-bottom: 5px">
  <div
    nz-col
    nzXs="24"
    nzSm="24"
    nzMd="20"
    nzLg="20"
    nzXl="12"
    style="padding-left: 30px"
  >
    <div class="formname">{{ formTitle }}</div>
  </div>

  <div
    nz-col
    nzXs="24"
    nzSm="24"
    nzMd="4"
    nzLg="4"
    nzXl="12"
    style="display: flex; justify-content: flex-end"
  >
    <form nz-form [nzLayout]="'inline'">
      <nz-form-item>
        <button
          nz-button
          nzTooltipTitle="Filter"
          nzTooltipPlacement="top"
          nz-tooltip
          [nzType]="isFilterApplied"
          (click)="showFilter()"
        >
          <i nz-icon nzType="filter"></i>
        </button>
      </nz-form-item>

      <nz-form-item>
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
          <input
            type="text"
            name="postsearch"
            (keyup)="search(true)"
            (keyEnter)="onKeypressEvent($event)"
            [(ngModel)]="searchText"
            nz-input
            placeholder="Search Record"
          />
        </nz-input-group>
        <ng-template #suffixIconButton>
          <button
            nz-button
            nzType="primary"
            (click)="search(true)"
            nzSearch
            id="button"
          >
            <i nz-icon nzType="search"></i>
          </button>
        </ng-template>
      </nz-form-item>
    </form>
  </div>
</div>

<div nz-row>
  <div nz-col nzSpan="24">
    <div class="filter {{ filterClass }}">
      <div class="filter-box" style="margin-bottom: 5px; width: 973px">
        <form nz-form>
          <div nz-row>
            <!-- [(ngModel)]="data" -->
            <div nz-col nzSpan="5" style="margin-left: 39px">
              <nz-form-item>
                <nz-form-label nzNoColon style="margin-left: -31px"
                  >Inspector Name</nz-form-label
                >
                <nz-select
                  nzShowSearch
                  nzAllowClear
                  style="margin-left: -33px"
                  name="inspectorname"
                  [(ngModel)]="INSPECTOR_ID"
                  nzPlaceHolder="Select Inspector Name"
                >
                  <nz-option
                    *ngFor="let iname of usernames"
                    [nzValue]="iname.ID"
                    [nzLabel]="iname.NAME"
                  >
                  </nz-option>
                </nz-select>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="7">
              <nz-form-label nzNoColon></nz-form-label>
              <nz-form-item style="margin-left: 45px">
                <button
                  style="margin: 0 10px 0 -68px"
                  nz-button
                  [nzLoading]="isSpinning"
                  nzType="primary"
                  (click)="applyFilter()"
                  nzJustify="end"
                >
                  <i nz-icon nzType="filter"></i>Apply Filter
                </button>
                <button
                  nz-button
                  nzType="primary"
                  style="margin: -31px 0 0 73px"
                  (click)="clearFilter()"
                >
                  <i nz-icon nzType="filter"></i>Clear filter
                </button>
              </nz-form-item>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div nz-row>
  <div nz-col nzSpan="24">
    <nz-table
      class="my-scroll no-wrap1"
      nzBordered
      #basicTable
      nzShowSizeChanger
      [nzFrontPagination]="false"
      [nzData]="dataList"
      [nzLoading]="loadingRecords"
      [nzSize]="'small'"
      [nzScroll]="{ x: '1400px', y: '420px' }"
      [nzTotal]="totalRecords"
      [(nzPageIndex)]="pageIndex"
      [(nzPageSize)]="pageSize"
      (nzPageIndexChange)="search()"
      (nzPageSizeChange)="search(true)"
      (nzQueryParams)="sort($event)"
    >
      <thead>
        <tr>
          <th
            nzWidth="250px"
            style="text-align: center"
            nzColumnKey="INSPECTOR_NAME"
            [nzSortFn]="true"
          >
            Inspector name
          </th>
          <th
            style="text-align: center"
            nzColumnKey="TOTAL_CLAIM"
            [nzSortFn]="true"
          >
            Total Claim
          </th>
          <th
            style="text-align: center"
            nzColumnKey="SUBMITTED"
            [nzSortFn]="true"
          >
            Submitted
          </th>
          <th
            style="text-align: center"
            nzColumnKey="VERIFIED"
            [nzSortFn]="true"
          >
            Verified
          </th>
          <th
            style="text-align: center"
            nzColumnKey="ANNEXTURE"
            [nzSortFn]="true"
          >
            Annexure
          </th>
          <th style="text-align: center" nzColumnKey="ORDERS" [nzSortFn]="true">
            Orders
          </th>
          <th
            style="text-align: center"
            nzColumnKey="CLAIM_FILE"
            [nzSortFn]="true"
          >
            Claim File
          </th>
          <!-- <div>
                    <th nzWidth="150px" nzColumnKey="BILL_FILIING_DATE" [nzSortFn]="true">
                        Bill Filling Date
                    </th>
                    <th nzWidth="190px" nzColumnKey="ADMISSIBLE_AMOUNT" [nzSortFn]="true">
                        Admissible Amount(₹)
                    </th>

                    <th nzWidth="370px" nzColumnKey="TREATEMENT_TYPE" [nzSortFn]="true">
                        Treatment Type
                    </th>
                    <th nzWidth="180px" nzColumnKey="TREATMENT_START_DATE" [nzSortFn]="true">
                        Treatment Start Date
                    </th>
                    <th nzWidth="180px" nzColumnKey="TREATMENT_END_DATE" [nzSortFn]="true">
                        Treatment End Date
                    </th>
                    <th nzWidth="260px" nzColumnKey="ACCREDITATION" [nzSortFn]="true">
                        Empanelled Hospital Accrediation
                    </th>
                    <th nzWidth="210px" nzColumnKey="CLAIM_ACCREDITATION" [nzSortFn]="true">
                        Accrediation
                    </th>

                    <th nzWidth="180px" nzColumnKey="HOSPITAL_TYPE" [nzSortFn]="true">
                        Hospital Type
                    </th>
                    <th nzWidth="400px" nzColumnKey="HOSPITAL_NAME" [nzSortFn]="true">
                        Hospital Name
                    </th>
                    <th nzWidth="400px" nzColumnKey="EMPANELLED_HOSPITAL_NAME" [nzSortFn]="true">
                        Empanelled Hospital Name
                    </th>
                    
                    <th nzWidth="200px" nzColumnKey="WARD_TYPE" [nzSortFn]="true">
                        Ward Entitlement
                    </th>

                    <th nzWidth="400px" nzColumnKey="NATURE_OF_TREATMENT" [nzSortFn]="true">
                        Nature Of Treatment
                    </th>
                    <th nzWidth="150px" nzColumnKey="CHECKLIST_STATUS" [nzSortFn]="true">
                        Accept / Reject
                    </th>
                    <th nzWidth="300px" nzColumnKey="REJECT_REMARK" [nzSortFn]="true">
                        Remark
                    </th>
                </div> -->
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let data of basicTable.data; let i = index">
          <td style="text-align: center; white-space: pre-line">
            {{ data.INSPECTOR_NAME }}
          </td>
          <td style="text-align: center; white-space: pre-line">
            {{ data.TOTAL_CLAIM }}
          </td>
          <td style="text-align: center; white-space: pre-line">
            {{ data.SUBMITTED }}
          </td>
          <td style="text-align: center; white-space: pre-line">
            {{ data.VERIFIED }}
          </td>
          <td style="text-align: center; white-space: pre-line">
            {{ data.ANNEXTURE }}
          </td>
          <td style="text-align: center; white-space: pre-line">
            {{ data.ORDERS }}
          </td>
          <td style="text-align: center; white-space: pre-line">
            {{ data.CLAIM_FILE }}
          </td>
          <!-- <div>
                        <td style="text-align: center; white-space: pre-line" nzWidth="170px">
                            {{ data.RELATION_WITH_PATIENT }}
                        </td>
                        <td style="text-align: center; white-space: pre-line" nzWidth="150px">
                            {{ data.BILL_FILIING_DATE }}
                        </td>
                        <td style="text-align: center; white-space: pre-line" nzWidth="190px">
                            {{ data.ADMISSIBLE_AMOUNT }}
                        </td>

                        
                        <td style="text-align: center" nzWidth="370px">
                            <span nz-icon *ngIf="data['TREATEMENT_TYPE'] == 'OT'">OPD Treatment / Test Entitled</span>
                            <span nz-icon *ngIf="data['TREATEMENT_TYPE'] == 'IT'">Indoor Treatment</span>
                            <span nz-icon *ngIf="data['TREATEMENT_TYPE'] == 'OT,IT'">OPD Treatment / Test Entitled &
                                Indoor
                                Treatment</span>
                        </td>
                        <td style="text-align: center; white-space: pre-line" nzWidth="180px">
                            {{ data.TREATMENT_START_DATE }}
                        </td>
                        <td style="text-align: center; white-space: pre-line" nzWidth="180px">
                            {{ data.TREATMENT_END_DATE }}
                        </td>

                        <td style="text-align: center" nzWidth="260px">
                            <span nz-icon *ngIf="data['ACCREDITATION'] == 'N'">NAHB</span>
                            <span nz-icon *ngIf="data['ACCREDITATION'] == 'NN'">Non NAHB</span>
                            <span nz-icon *ngIf="data['ACCREDITATION'] == 'SP'">Super Speciality</span>
                        </td>
                        <td style="text-align: center" nzWidth="180px">
                            <span nz-icon *ngIf="data['CLAIM_ACCREDITATION'] == 'N'">NAHB</span>
                            <span nz-icon *ngIf="data['CLAIM_ACCREDITATION'] == 'NN'">Non NAHB</span>
                            <span nz-icon *ngIf="data['CLAIM_ACCREDITATION'] == 'SP'">Super Speciality</span>
                        </td>
                       
                        <td style="text-align: center" nzWidth="180px">
                            <span nz-icon *ngIf="data['HOSPITAL_TYPE'] == 'E'">Empanelled</span>
                            <span nz-icon *ngIf="data['HOSPITAL_TYPE'] == 'NE'">Non Empanelled</span>
                            <span nz-icon *ngIf="data['HOSPITAL_TYPE'] == 'G'">Government</span>
                        </td>
                        <td style="text-align: center; white-space: pre-line" nzWidth="400px">
                            {{ data.HOSPITAL_NAME }}
                        </td>
                        <td style="text-align: center; white-space: pre-line" nzWidth="400px">
                            {{ data.EMPANELLED_HOSPITAL_NAME }}
                        </td>
                        

                        <td style="text-align: center" nzWidth="200px">
                            <span nz-icon *ngIf="data['WARD_TYPE'] == 'P'">Pvt </span>
                            <span nz-icon *ngIf="data['WARD_TYPE'] == 'S'">Semi Pvt</span>
                            <span nz-icon *ngIf="data['WARD_TYPE'] == 'G'">General</span>
                        </td>
                        <td style="text-align: center; white-space: pre-line" nzWidth="400px">
                            {{ data.NATURE_OF_TREATMENT }}
                        </td>
                        <td style="text-align: center" nzWidth="150px">
                            <span nz-icon *ngIf="data['CHECKLIST_STATUS'] == 'A'">
                                Claim Accept
                            </span>
                            <span nz-icon *ngIf="data['CHECKLIST_STATUS'] == 'R'">Claim Reject</span>
                        </td>
                        <td style="text-align: center; white-space: pre-line" nzWidth="300px">
                            {{ data.REJECT_REMARK }}
                        </td>
                    </div> -->
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>
