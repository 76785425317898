import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { EmployeeMaster } from 'src/app/Models/Employee';
import { LTCMaster } from 'src/app/Models/LTCMaster';
import { LTCQuestionaryMaster } from 'src/app/Models/LTCQuestionaryMaster';
import { roadRail } from 'src/app/Models/RoadRail';
import { journeyP } from 'src/app/Models/journeyParticular';
import { Journeydetails } from 'src/app/Models/journeydetails';
import { Realtionshipdata } from 'src/app/Models/relationship';
import { Travelmode } from 'src/app/Models/travelmode';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-ltcemployeeadd',
  templateUrl: './ltcemployeeadd.component.html',
  styleUrls: ['./ltcemployeeadd.component.css'],
})
export class LtcemployeeaddComponent implements OnInit {
  constructor(
    private message: NzNotificationService,
    private api: ApiService,
    private datepipe: DatePipe,
    public cookie: CookieService
  ) {}
  userId: any;
  ngOnInit(): void {
    this.userId = Number(sessionStorage.getItem('userId'));
    this.allEmployeeList();
    this.blockYearList();
    this.current = this.current;
  }

  namepatt = /[a-zA-Z][a-zA-Z ]+/;
  emailpattern =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  @Input() isSpinning = false;
  @Input() relationdata: any = [];
  // @Input() Hospitalclaim: any = [];
  @Input() journey: any;
  isOk = true;
  HEADQUARTERS: any;

  @Input()
  drawerVisible: boolean = false;
  empDrawerVisible: boolean = false;
  @Input() drawerClose: Function;
  @Input() data: LTCMaster;
  @Input() editrelation: Realtionshipdata;

  @Input() empID: any;
  @Input() ltcID: any;
  // @Input() data2: ClaimMaster;
  //  data2: ClaimMaster;
  // @Input() QueData: QuestionaryMaster;
  // data2: ClaimMaster = new ClaimMaster();
  QueData: LTCQuestionaryMaster = new LTCQuestionaryMaster();
  // hospitalData: HospitalMappingMaster = new HospitalMappingMaster();
  // @Input() data4: CheckList;
  //  data4: CheckList;
  // data4: CheckList = new CheckList();

  @Input() current = 0;
  employee: EmployeeMaster[] = [];

  Cities: EmployeeMaster[];
  filteredOptions = [];
  Names = EmployeeMaster;
  hospitalList: any = [];
  diffDays: any;
  date1: any;
  date2: any;
  expoDiffDays: any;
  TREATEMENT_TYPE1 = false;
  TREATEMENT_TYPE2 = false;
  CGHS_AMA_REFERENCE_DATE: any = new Date();
  CGHS_AMA_REFERENCE_END_DATE: any = new Date();
  empLoader: boolean = false;
  orderdata1 = [];
  travelmode = new Travelmode();
  drawerTitleform1!: string;
  drawerVisible2form1: boolean = false;
  drawerData2form1: roadRail = new roadRail();
  blockYearList: any = [];
  BlockYears(): void {
    this.api
      .getBlockYearMaster(0, 0, '', 'asc', ' AND STATUS = 1')
      .subscribe((data) => {
        if (data['code'] == 200) {
          this.blockYearList = data['data'];
        }
      });
  }
  LTCID: any;
  addform1(): void {
    this.drawerTitleform1 = 'Add Places Details Which Are Connected By Rail';
    this.drawerData2form1 = new roadRail();
    // this.data.LTC_ID = this.ltcID;
    this.ltcID = this.ltcID;
    this.drawerVisible2form1 = true;
  }

  drawerClose2form1(): void {
    this.drawerVisible2form1 = false;
    this.getDataform1();
  }

  get closeCallbackform1form1() {
    return this.drawerClose2form1.bind(this);
  }

  switch = false;

  switch1 = false;
  switchChange1(e: boolean) {
    this.switch1 != e;
    this.data.IS_ADVANCE_TAKEN = null;
  }

  drawerTitleform3!: string;
  drawerVisibleform3: boolean = false;
  drawerDataform3: journeyP = new journeyP();

  addform3(): void {
    this.drawerTitleform3 = 'Add Details Of Accommodation Class';
    this.drawerDataform3 = new journeyP();
    this.ltcID = this.ltcID;
    this.drawerVisibleform3 = true;
  }

  drawerCloseform3(): void {
    this.getDataform3();
    this.drawerVisibleform3 = false;
  }

  get closeCallbackform3() {
    return this.drawerCloseform3.bind(this);
  }

  drawerTitleform7!: string;
  drawerVisibleform7: boolean = false;
  drawerDataform7: Journeydetails = new Journeydetails();

  addform7(): void {
    this.drawerTitleform7 = 'Add Details of Journeys(s)';
    this.drawerDataform7 = new Journeydetails();
    this.ltcID = this.ltcID;
    this.drawerVisibleform7 = true;
  }
  loadingRecords1 = false;
  drawerCloseform7(): void {
    this.loadingRecords1 = true;
    this.api
      .ltcJourneyDetail(0, 0, '', 'asc', ' AND LTC_ID =' + this.ltcID)
      .subscribe((data) => {
        if (data['code'] == 200) {
          this.loadingRecords1 = false;
          console.log(this.formdata7, 'formdata7');
          this.formdata7 = data['data'];
        }
      });
    this.drawerVisibleform7 = false;
  }

  get closeCallbackform7() {
    return this.drawerCloseform7.bind(this);
  }

  formdata3 = [];
  formdata2 = [];
  formdata1 = [];
  formdata7 = [];
  loadingRecords3 = false;
  loadingRecords2 = false;
  getDataform3() {
    this.api
      .getJourneyParticular(0, 0, '', 'asc', ' AND LTC_ID =' + this.ltcID)
      .subscribe((data) => {
        if (data['code'] == 200) {
          this.loadingRecords3 = false;
          this.formdata3 = data['data'];
        }
      });
  }

  getDataform2() {
    this.api
      .ltcJourneyDetail(0, 0, '', 'asc', ' AND LTC_ID =' + this.ltcID)
      .subscribe((data) => {
        if (data['code'] == 200) {
          this.formdata2 = data['data'];
        }
      });
  }
  // SHOW_TABLE: boolean = false;
  // showtable(event: { SHOW_TABLE: boolean; }) {
  //   event.IS_HIGHER_CLASS_ACCOMODATION = !event.SHOW_TABLE;
  // }

  getDataform1() {
    // this.loadingRecords2 = true;
    this.api
      .ltcRoadConnectedRail(0, 0, '', 'asc', ' AND LTC_ID =' + this.ltcID)
      .subscribe((data) => {
        if (data['code'] == 200) {
          // this.loadingRecords2 = false;
          this.formdata1 = data['data'];
        }
      });
  }

  getDataform7() {
    this.api
      .getAllltcMaster(0, 0, '', 'asc', ' AND ID =' + this.ltcID)
      .subscribe((data) => {
        if (data['code'] == 200) {
          console.log(this.formdata7, 'formdata7');
          this.formdata7 = data['data'];
        }
      });
  }

  // getrelationtable() {
  //   this.api
  //     .getrelationtable(0, 0, '', 'asc', ' AND TRANSFER_ID =' + this.ltcID)
  //     .subscribe((data) => {
  //       if (data['code'] == 200) {
  //         this.relationdata = data['data'];
  //       }
  //     });
  // }
  getTimeIn12Hour(time: any) {
    return this.datepipe.transform(new Date(), 'yyyy-MM-dd' + ' ' + time);
  }
  checkboxdatais: boolean = false;
  chechboxdata(event: any) {
    console.log(event, 'event');
    this.checkboxdatais = event;
  }

  showtable1(event: any) {
    // this.data.IS_HIGHER_CLASS_ACCOMODATION = event;
  }
  class = [];
  editform3(data: journeyP): void {
    this.drawerTitleform3 = 'Edit Details Of Accommodation Class';
    this.drawerDataform3 = Object.assign({}, data);
    console.log('this.drawerDataform3', this.drawerDataform3);
    this.ltcID = this.ltcID;
    console.log('this.ltcID', this.ltcID);
    this.api
      .gettravelclass(
        0,
        0,
        '',
        '',
        ' AND STATUS=1 AND MODE_ID=' + data.MODE_OF_CONVEYANCE_ID
      )
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            this.class = data['data'];
            this.drawerVisibleform3 = true;
          } else {
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  classdata: any;
  editform7(data: Journeydetails): void {
    this.drawerTitleform7 = 'Edit Details of Journeys(s)';
    this.drawerDataform7 = Object.assign({}, data);
    this.api
      .gettravelclass(
        0,
        0,
        '',
        '',
        ' AND STATUS=1 AND ID=' + data.TRAVEL_CLASS_ID
      )
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            this.classdata = data['data'];
          }
        },
        (err) => {
          console.log(err);
        }
      );
    // this.drawerDataform7.TRAVEL_CLASS_ID = data.TRAVEL_CLASS_ID;

    // console.log('data.TRAVEL_CLASS_ID', data.TRAVEL_CLASS_ID);

    this.ltcID = this.ltcID;
    this.drawerVisibleform7 = true;
  }
  dataList: any = [];

  editform1(data: roadRail): void {
    this.drawerTitleform1 = 'Edit Places Details Which Are Connected By Rail';
    this.drawerData2form1 = Object.assign({}, data);
    this.data.LTC_ID = this.ltcID;

    //   this.api.ltcRoadConnectedRail(0, 0, '', '', 'AND ID = ' + data.ID).subscribe(
    //     (data) => {
    //       if (data['code'] == 200) {
    //         this.drawerData2form1 = data['data'];
    //       }
    //     },
    //     (err) => {
    //       console.log(err);
    //     }
    //   );
    this.drawerVisible2form1 = true;
  }

  allEmployeeList() {
    this.empLoader = true;

    this.api.getEmployeeMaster(0, 0, '', '', ' AND STATUS = 1').subscribe(
      (data) => {
        if (data['code'] == 200) {
          this.employee = data['data'];
          this.filteredOptions = this.employee;
          this.empLoader = false;
        } else {
          this.message.error("Can't Load Employee Data", '');
          this.empLoader = false;
        }
      },
      (err) => {
        console.log(err);
      }
    );
    if (this.data.EMP_ID != null || this.data.EMP_ID != undefined) {
      this.api
        .getEmployeeMaster(0, 0, '', '', ' AND ID = ' + this.data.EMP_ID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              this.data.OFFICE_NAME = data['data'][0]['OFFICE_NAME'];
              this.data.DESIGNATION = data['data'][0]['DESIGNATION'];
              this.data.EMPLOYEE_CODE = data['data'][0]['EMPLOYEE_CODE'];
              this.data.LOCATION = data['data'][0]['LOCATION'];
              this.data.DDO_OF_THE_OFFICIAL =
                data['data'][0]['DDO_OF_THE_OFFICIAL'];
              this.data.GRADE_PAY = data['data'][0]['GRADE_PAY'];
              this.data.EMAIL_ID = data['data'][0]['EMAIL_ID'];
              this.data.MOBILE_NO = data['data'][0]['MOBILE_NO'];
              this.data.ADDRESS = data['data'][0]['ADDRESS'];
            } else {
              this.message.error("Can't Load Employee Data", '');
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
    }
  }

  allHospitalList() {
    this.api.getAllHospital(0, 0, '', '', ' AND STATUS = 1').subscribe(
      (data) => {
        if (data['code'] == 200) {
          this.hospitalList = data['data'];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  close(): void {
    this.current = 0;
    this.drawerClose();
  }

  applicantResetDrawer(ITCMasterPages: NgForm) {
    this.data = new LTCMaster();
    ITCMasterPages.form.reset();
  }

  // close(applicantMasterPages: NgForm,claimMasterPage: NgForm,queMasterPage: NgForm,checkListMasterPage: NgForm) {
  //   console.log('curren2t',this.current);
  //  this.current = 0;
  //   this.applicantResetDrawer(applicantMasterPages);
  //   this.claimResetDrawer(claimMasterPage);
  //   this.queResetDrawer(queMasterPage);
  //   this.checkResetDrawer(checkListMasterPage);
  //   // claimMasterPage.form.reset();
  //   this.drawerClose();
  // }

  omit(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  dateOmit(event: any) {
    return false;
  }

  save(addNew: boolean, claimMasterPage: NgForm): void {
    if (this.current == 0) {
      this.current = 1;
    } else if (this.current == 1) {
      this.current = 2;
    } else if (this.current == 2) {
      this.current = 3;
    }
    this.isSpinning = false;
    this.isOk = true;

    if (
      this.data.NAME.trim() == '' &&
      this.data.LOCATION.trim() == '' &&
      // this.data.DDO_OF_THE_OFFICIAL.trim() == '' &&
      this.data.DESIGNATION.trim() == '' &&
      this.data.OFFICE_NAME.trim() == '' &&
      this.data.EMPLOYEE_CODE == 0 &&
      this.data.GRADE_PAY == 0
    ) {
      this.isOk = false;
      this.message.error('Please Fill All The Required Fields ', '');
    } else if (this.data.NAME == null || this.data.NAME.trim() == '') {
      this.isOk = false;
      this.message.error(' Please Enter Employee Name.', '');
    } else if (
      this.data.EMPLOYEE_CODE == undefined ||
      this.data.EMPLOYEE_CODE <= 0
    ) {
      this.isOk = false;
      this.message.error('Please Enter Employee Code  ', '');
    } else if (this.data.GRADE_PAY == undefined || this.data.GRADE_PAY <= 0) {
      this.isOk = false;
      this.message.error('Please Enter Grade Pay  ', '');
    } else if (
      this.data.OFFICE_NAME == null ||
      this.data.OFFICE_NAME.trim() == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Office Name.', '');
    } else if (
      this.data.DESIGNATION == null ||
      this.data.DESIGNATION.trim() == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Designation', '');
    } else if (this.data.LOCATION == null || this.data.LOCATION.trim() == '') {
      this.isOk = false;
      this.message.error(' Please Enter Location', '');
      // } else if (
      //   this.data.DDO_OF_THE_OFFICIAL == null ||
      //   this.data.DDO_OF_THE_OFFICIAL.trim() == ''
      // ) {
      //   this.isOk = false;
      //   this.message.error(' Please Enter Designation', '');
    }

    if (this.isOk) {
      this.isSpinning = true;
      {
        if (this.data.ID) {
          this.api.updateEmployeeMaster(this.data).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information Changed Successfully...', '');
              if (!addNew) this.drawerClose();
              this.isSpinning = false;
            } else {
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
        } else {
          this.api.createEmployeeMaster(this.data).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information Save Successfully...', '');
              if (!addNew) this.drawerClose();
              else {
                this.data = new LTCMaster();
                // this.resetDrawer(claimMasterPage);
                // this.data.IMG_URL= '';

                this.api.getEmployeeMaster(0, 0, '', 'desc', '').subscribe(
                  (data) => {
                    // if (data['count']==0){
                    //   this.data.SEQUENCE_NUMBER=1;
                    // }else
                    // {
                    //   this.data.SEQUENCE_NUMBER=data['data'][0]['SEQUENCE_NUMBER']+1;
                    // }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              }
              this.isSpinning = false;
            } else {
              this.message.error('Failed To Fill Information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
  }
  STATION_NAME: string = '';
  TOTAL_AMOUNT: any;
  ADVANCE_TAKEN_DATE: any;
  ADVANCED_AMOUNT: any;
  AMOUNT: any;
  AlldataSave(addNew: boolean) {
    this.isOk = true;
    this.isSpinning = true;
    if (this.data.IS_ADVANCE_TAKEN == true || this.data.IS_ADVANCE_TAKEN == 1) {
      if (
        this.data.AMOUNT_OF_ADVANCE == undefined ||
        this.data.AMOUNT_OF_ADVANCE == 0
      ) {
        this.message.error('Please enter advance amount', '');
        this.isOk = false;
      }
    }

    console.log(this.data.SELF_DECLARATION, 'this.data.SELF_DECLARATION');
    this.data.ID = this.ltcID;
    if (this.isOk && this.data.ID != undefined) {
      // if (stage == 'V') {
      //   // this.data.CURRENT_STAGE_ID = 3;
      // }
      this.api.ltcMasterUpdate(this.data).subscribe((successCode) => {
        if (successCode.code == '200') {
          this.message.success('Information Saved Successfully...', '');
          this.isSpinning = false;
          this.ltcID = successCode.LTC_ID;
          // this.drawerClose();
          // this.current = 0;
          this.next();
        } else {
          this.message.error('Failed To Save Information....', '');
          this.isSpinning = false;
        }
      });
    }
  }

  empAllDataForUpdate: any;

  empSave(): void {
    this.isSpinning = false;
    this.data.INSPECTOR_ID = 0;
    this.data.familyData = this.relationdata;
    this.data.EMP_ID = Number(sessionStorage.getItem('userId'));
    // this.data.LTC_STATUS = 'C'
    // console.log(this.data.LTC_STATUS, "this.data.LTC_STATUS")

    this.isOk = true;

    if (
      this.data.NAME == undefined &&
      this.data.DESIGNATION == undefined &&
      // this.data.NEW_OFFICE_NAME == undefined &&
      // this.data.NEW_ADDRESS == undefined &&
      // this.data.OFFICE_NAME == undefined &&
      this.data.DDO_OF_THE_OFFICIAL == undefined &&
      this.data.EMPLOYEE_CODE == undefined &&
      (this.data.GRADE_PAY == undefined || this.data.GRADE_PAY == 0) &&
      (this.data.BLOCK_YEAR == undefined || this.data.BLOCK_YEAR == 0) &&
      (this.data.MOBILE_NO == undefined || this.data.MOBILE_NO == 0) &&
      (this.data.NATURE_OF_LEAVE == undefined ||
        this.data.NATURE_OF_LEAVE == 0) &&
      (this.data.LEAVE_START_DATE == undefined ||
        this.data.LEAVE_START_DATE == null) &&
      (this.data.LEAVE_END_DATE == undefined ||
        this.data.LEAVE_END_DATE == null) &&
      this.data.GRADE_PAY_LEVEL == undefined
    ) {
      this.isOk = false;
      this.message.error('Please Fill All The Required Fields ', '');
    } else if (
      this.data.NAME == undefined ||
      this.data.NAME == null ||
      this.data.NAME == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Select Employee Name', '');
    } else if (
      this.data.DESIGNATION == undefined ||
      this.data.DESIGNATION == null ||
      this.data.DESIGNATION == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Designation', '');
    } else if (
      this.data.DDO_OF_THE_OFFICIAL == undefined ||
      this.data.DDO_OF_THE_OFFICIAL == null ||
      this.data.DDO_OF_THE_OFFICIAL == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter DDO Of The Official', '');
    } else if (
      this.data.EMPLOYEE_CODE == undefined ||
      this.data.EMPLOYEE_CODE == null ||
      this.data.EMPLOYEE_CODE == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Employee Code', '');
    } else if (
      this.data.GRADE_PAY == undefined ||
      this.data.GRADE_PAY == null ||
      this.data.GRADE_PAY == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Select Basic Pay', '');
    } else if (
      this.data.MOBILE_NO == undefined ||
      this.data.MOBILE_NO == null ||
      this.data.MOBILE_NO == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Mobile Number', '');
    } else if (
      this.data.GRADE_PAY_LEVEL == undefined ||
      this.data.GRADE_PAY_LEVEL == null ||
      this.data.GRADE_PAY_LEVEL == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Grade Pay Level', '');
    }

    if (
      this.data.NATURE_OF_LEAVE == undefined ||
      this.data.NATURE_OF_LEAVE == 0
    ) {
      this.isOk = false;
      this.message.error(' Please Select Nature Of Leave', '');
    }
    if (
      this.data.LEAVE_START_DATE == undefined ||
      this.data.LEAVE_START_DATE == null ||
      this.data.LEAVE_START_DATE == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Select Leave Start Date', '');
    }
    if (
      this.data.LEAVE_END_DATE == undefined ||
      this.data.LEAVE_END_DATE == null ||
      this.data.LEAVE_END_DATE == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Select Leave End Date', '');
    }
    if (
      this.data.BLOCK_YEAR == undefined ||
      this.data.BLOCK_YEAR == null ||
      this.data.BLOCK_YEAR == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Block Year', '');
    }

    if (this.isOk) {
      this.data.familyData = this.relationdata;
      // this.data.memberTransferData = JSON.stringify(this.relationdata);

      this.isSpinning = true;
      if (
        this.data.OFFICE_NAME == undefined ||
        this.data.OFFICE_NAME == null ||
        this.data.OFFICE_NAME == '' ||
        this.data.OFFICE_NAME.trim() == ''
      ) {
        this.data.OFFICE_NAME = null;
      } else {
        this.data.OFFICE_NAME = this.data.OFFICE_NAME;
      }
      if (
        this.data.EMAIL_ID == undefined ||
        this.data.EMAIL_ID == null ||
        this.data.EMAIL_ID == '' ||
        this.data.EMAIL_ID.trim() == ''
      ) {
        this.data.EMAIL_ID = null;
      } else {
        this.data.EMAIL_ID = this.data.EMAIL_ID;
      }
      if (
        this.data.EMPLOYEE_CODE == undefined ||
        this.data.EMPLOYEE_CODE == null ||
        this.data.EMPLOYEE_CODE == '' ||
        this.data.EMPLOYEE_CODE.trim() == ''
      ) {
        this.data.EMPLOYEE_CODE = ' ';
      } else {
        this.data.EMPLOYEE_CODE = this.data.EMPLOYEE_CODE;
      }

      if (
        this.data.MOBILE_NO == undefined ||
        this.data.MOBILE_NO == null ||
        this.data.MOBILE_NO == '' ||
        this.data.MOBILE_NO.trim() == ''
      ) {
        this.data.MOBILE_NO = ' ';
      } else {
        this.data.MOBILE_NO = this.data.MOBILE_NO;
      }

      if (
        this.data.ADDRESS == undefined ||
        this.data.ADDRESS == null ||
        this.data.ADDRESS == '' ||
        this.data.ADDRESS.trim() == ''
      ) {
        this.data.ADDRESS = ' ';
      } else {
        this.data.ADDRESS = this.data.ADDRESS;
      }

      if (
        this.data.LEAVE_START_DATE != undefined &&
        this.data.LEAVE_START_DATE != null &&
        this.data.LEAVE_START_DATE != ''
      ) {
        this.data.LEAVE_START_DATE = this.datepipe.transform(
          this.data.LEAVE_START_DATE,
          'yyyy-MM-dd'
        );
      }

      if (
        this.data.LEAVE_END_DATE != undefined &&
        this.data.LEAVE_END_DATE != null &&
        this.data.LEAVE_END_DATE != ''
      ) {
        this.data.LEAVE_END_DATE = this.datepipe.transform(
          this.data.LEAVE_END_DATE,
          'yyyy-MM-dd'
        );
      }

      if (this.data.ID) {
        if (
          this.data.familyData == undefined ||
          this.data.familyData.length == 0
        ) {
        } else {
          for (var i = 0; this.relationdata.length > i; i++) {
            this.relationdata[i]['ID'] = undefined;
          }
          this.data.familyData = this.relationdata;
        }
        this.data['LTC_ID'] = this.ltcID;
        this.api.ltcMasterLTCCreate(this.data).subscribe((successCode) => {
          if (successCode.code == '200') {
            this.message.success('Information Saved Successfully...', '');
            this.ltcID = successCode.LTC_ID;
            this.next();
            this.isSpinning = false;
          } else if (successCode.code == '300') {
            this.message.error(
              'Email ID or Mobile Number Already Registered...',
              ''
            );
            this.isSpinning = false;
          } else {
            this.message.error('Information Has Not Saved...', '');
            this.isSpinning = false;
          }
        });
      } else {
        this.api.ltcMasterLTCCreate(this.data).subscribe((successCode) => {
          if (successCode.code == '200') {
            this.message.success('Information Save Successfully...', '');
            this.ltcID = successCode.LTC_ID;
            this.next();
            this.isSpinning = false;
          } else if (successCode.code == '300') {
            this.message.error(
              'Email ID or Mobile Number Already Registered...',
              ''
            );
            this.isSpinning = false;
          } else {
            this.message.error('Failed To Fill Information...', '');
            this.isSpinning = false;
          }
        });
      }
    }
  }

  queSave2(addNew: boolean): void {
    this.isSpinning = false;
    this.isOk = true;
    this.QueData.LTC_ID = this.ltcID;

    if (this.isOk) {
      this.isSpinning = true;

      {
        if (this.QueData.ID) {
          this.api.updateLTCquestions(this.QueData).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.api.ltcMasterUpdate(this.data).subscribe((successCode) => {
                if (successCode.code == '200') {
                  this.isSpinning = false;
                } else {
                  this.isSpinning = false;
                }
              });
              this.message.success('Information Changed Successfully...', '');
              this.drawerClose();
              this.current = 0;
              this.isSpinning = false;
            } else {
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
        } else {
          this.api.createLTCquestions(this.QueData).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.api.ltcMasterUpdate(this.data).subscribe((successCode) => {
                if (successCode.code == '200') {
                  this.isSpinning = false;
                } else {
                  this.isSpinning = false;
                }
              });
              this.message.success('Information Save Successfully...', '');
              this.drawerClose();
              this.current = 0;
              this.isSpinning = false;
            } else {
              this.message.error('Failed To Fill Information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
  }

  queSave1(addNew: boolean): void {
    this.isSpinning = false;
    this.isOk = true;
    this.QueData.LTC_ID = this.ltcID;
    this.data.LTC_STATUS = 'P';
    if (this.isOk) {
      this.isSpinning = true;

      {
        if (this.QueData.ID) {
          this.api.updateLTCquestions(this.QueData).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.api.ltcMasterUpdate(this.data).subscribe((successCode) => {
                if (successCode.code == '200') {
                  this.isSpinning = false;
                } else {
                  this.isSpinning = false;
                }
              });
              this.message.success('Information Changed Successfully...', '');
              this.drawerClose();
              this.current = 0;
              this.isSpinning = false;
            } else {
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
        } else {
          this.api.createLTCquestions(this.QueData).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.api.ltcMasterUpdate(this.data).subscribe((successCode) => {
                if (successCode.code == '200') {
                  this.isSpinning = false;
                } else {
                  this.isSpinning = false;
                }
              });
              this.message.success('Information Save Successfully...', '');
              this.drawerClose();
              this.current = 0;
              this.isSpinning = false;
            } else {
              this.message.error('Failed To Fill Information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
  }
  pre2(): void {
    if (this.current == 2 && this.data.ID > 0) {
      this.current -= 2;
    } else {
      this.current -= 1;
    }
  }

  pre(): void {
    console.log('ltcID:', this.ltcID);
    if (this.current == 1) {
      console.log('ltcID1', this.ltcID);
      this.isSpinning = true;

      // this.api
      //   .getltc_family_master(0, 0, '', ' ', ' AND LTC_ID = ' + this.ltcID)
      //   .subscribe(
      //     (data) => {
      //       if (data['code'] == 200 && data['data'].length > 0)
      //         this.relationdata = data['data'];

      //     },
      //     (err) => {
      //       console.log(err);
      //     }
      //   );
      this.blockYearList();
      this.api
        .getAllltcMaster(0, 0, '', '', ' AND ID =' + this.ltcID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              if (data['data'].length == 0) {
                // this.current -= 1;
                // this.isSpinning = false;
              } else {
                this.data = data['data'][0];
                this.data.DDO_OF_THE_OFFICIAL =
                  data['data'][0]['DDO_OF_THE_OFFICIAL'];
                this.data.OFFICE_NAME = data['data'][0]['OFFICE_NAME'];
                this.data.NAME = data['data'][0]['EMPLOYEE_NAME'];
                console.log('data:', this.data);
              }
              this.allEmployeeList();
              this.api
                .getltc_family_master(
                  0,
                  0,
                  '',
                  '',
                  ' AND LTC_ID = ' + this.ltcID
                )
                .subscribe(
                  (data) => {
                    if (data['code'] == 200) {
                      if (data['code'] == 200 && data['data'].length > 0) {
                        this.relationdata = data['data'];
                      } else {
                        this.relationdata = [];
                      }
                      this.current -= 1;
                      this.isSpinning = false;
                    } else {
                      this.isSpinning = false;
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
            } else {
              this.message.error('Something Went Wrong', '');
              this.isSpinning = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
      console.log('ltcID2', this.ltcID);
    } else if (this.current == 3) {
      this.isSpinning = true;
    } else if (this.current == 2) {
      this.isSpinning = true;
      this.api
        .getAllltcMaster(0, 0, '', '', ' AND ID =' + this.ltcID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              if (data['data'].length == 0) {
                this.data = new LTCMaster();
                this.current = 1;
                this.isSpinning = false;
              } else {
                this.data = data['data'][0];
                this.current = 1;
              }
              this.getDataform1();
              this.getDataform7();
              // this.getDataform2();
              this.getDataform3();
              this.api
                .ltcJourneyDetail(0, 0, '', 'asc', ' AND LTC_ID =' + this.ltcID)
                .subscribe((data) => {
                  if (data['code'] == 200) {
                    this.isSpinning = false;
                    console.log(this.formdata7, 'formdata7');
                    this.formdata7 = data['data'];
                  }
                });
            } else {
              this.message.error('Something Went Wrong', '');
              this.isSpinning = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );

      this.isSpinning = false;
    } else {
      this.isSpinning = true;
      this.current -= 1;
      this.isSpinning = false;
    }
  }

  filterEmpData(event: any) {
    console.log('eventevent', event);

    this.empLoader = true;
    if (event != null) {
      this.api.getEmployeeMaster(0, 0, '', '', ' AND ID =' + event).subscribe(
        (data) => {
          if (data['code'] == 200) {
            this.empLoader = false;
            this.data.EMP_ID = data['data'][0]['ID'];
            this.data.OFFICE_NAME = data['data'][0]['OFFICE_NAME'];
            this.data.DESIGNATION = data['data'][0]['DESIGNATION'];
            this.data.EMPLOYEE_CODE = data['data'][0]['EMPLOYEE_CODE'];
            this.data.LOCATION = data['data'][0]['LOCATION'];
            this.data.DDO_OF_THE_OFFICIAL =
              data['data'][0]['DDO_OF_THE_OFFICIAL'];
            this.data.GRADE_PAY = data['data'][0]['GRADE_PAY'];
            this.data.EMAIL_ID = data['data'][0]['EMAIL_ID'];
            this.data.MOBILE_NO = data['data'][0]['MOBILE_NO'];
            this.data.ADDRESS = data['data'][0]['ADDRESS'];
            this.data.EMPLOYEE_NAME = data['data'][0]['NAME'];
            // this.data.CGHS_CARD_NO = '';
            // this.data.CGHS_CARD_VALIDITY = '';
            // this.data.BENEFICIARY_TYPE = '';
          }
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      this.empLoader = false;
      this.data.ID = null;
      this.data.OFFICE_NAME = '';
      this.data.DESIGNATION = '';
      this.data.EMPLOYEE_CODE = '';
      this.data.LOCATION = '';
      this.data.DDO_OF_THE_OFFICIAL = '';
      this.data.GRADE_PAY = '';
      this.data.EMAIL_ID = '';
      this.data.MOBILE_NO = '';
      this.data.ADDRESS = '';
      // this.data.CGHS_CARD_NO = '';
      // this.data.CGHS_CARD_VALIDITY = '';
      // this.data.BENEFICIARY_TYPE = '';
    }
  }

  // name
  // onChange(value: string): void {
  //   console.log('value', value)
  //   this.name == value

  //   this.filteredOptions = this.employee.filter(option => option.NAME.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  //   console.log('filteredOptions', this.filteredOptions)

  //   // var a = this.employee.filter(obj => {
  //   //   return obj.NAME == value;
  //   // })
  //   console.log('name', this.name)

  // }

  name = '';
  onChange(value: string): void {
    this.name = value;
    this.filteredOptions = [];
    this.filteredOptions = this.employee.filter(
      (option) => option.NAME.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
    if (value != '') {
      if (this.filteredOptions.length > 0) {
        // this.name = this.filteredOptions[0]['NAME'];
        this.data.ID = this.filteredOptions[0]['ID'];

        this.data.OFFICE_NAME = this.filteredOptions[0]['OFFICE_NAME'];
        this.data.DESIGNATION = this.filteredOptions[0]['DESIGNATION'];
        this.data.EMPLOYEE_CODE = this.filteredOptions[0]['EMPLOYEE_CODE'];
        this.data.LOCATION = this.filteredOptions[0]['LOCATION'];
        this.data.DDO_OF_THE_OFFICIAL =
          this.filteredOptions[0]['DDO_OF_THE_OFFICIAL'];
        this.data.GRADE_PAY = this.filteredOptions[0]['GRADE_PAY'];
        this.data.EMAIL_ID = this.filteredOptions[0]['EMAIL_ID'];
        this.data.MOBILE_NO = this.filteredOptions[0]['MOBILE_NO'];
        this.data.ADDRESS = this.filteredOptions[0]['ADDRESS'];
      } else {
        this.data.ID = undefined;
        this.name = value;
        this.data.OFFICE_NAME = '';
        this.data.DESIGNATION = '';
        this.data.EMPLOYEE_CODE = '';
        this.data.LOCATION = '';
        this.data.DDO_OF_THE_OFFICIAL = '';
        this.data.GRADE_PAY = '';
        this.data.EMAIL_ID = '';
        this.data.MOBILE_NO = '';
        this.data.ADDRESS = '';
      }
    } else {
      this.name = value;
      this.data.OFFICE_NAME = '';
      this.data.DESIGNATION = '';
      this.data.EMPLOYEE_CODE = '';
      this.data.LOCATION = '';
      this.data.DDO_OF_THE_OFFICIAL = '';
      this.data.GRADE_PAY = '';
      this.data.EMAIL_ID = '';
      this.data.MOBILE_NO = '';
      this.data.ADDRESS = '';
    }
    this.data.NAME = this.name;
  }
  emergencyTaken;
  drCertificate;
  empanelHospital;
  expoFacto;
  referanceTaken;
  hodPermission;

  fileList: any = [];
  fileNumberList() {
    this.api
      .getFileMaster(
        0,
        0,
        'ID',
        'ASC',
        ' AND STATUS = 1 AND HIRARCHY_ID in (12,13)',
        sessionStorage.getItem('userId')
      )
      .subscribe(
        (data: any) => {
          if (data['code'] == 200 && data['count'] > 0) {
            this.fileList = data['data'];
          } else {
            this.fileList = [];
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  next() {
    if (this.current == 0) {
      this.isSpinning = true;
      this.api
        .getAllltcMaster(0, 0, '', '', ' AND ID =' + this.ltcID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              if (data['data'].length == 0) {
                this.data = new LTCMaster();
                this.isSpinning = false;
              } else {
                this.data = data['data'][0];
              }
              this.getDataform1();
              this.getDataform7();
              this.getDataform3();
              this.api
                .ltcJourneyDetail(0, 0, '', 'asc', ' AND LTC_ID =' + this.ltcID)
                .subscribe((data) => {
                  if (data['code'] == 200) {
                    this.isSpinning = false;
                    console.log(this.formdata7, 'formdata7');
                    this.formdata7 = data['data'];
                    this.current = 1;
                  }
                });
            } else {
              this.message.error('Something Went Wrong', '');
              this.isSpinning = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else if (this.current == 1) {
      this.api
        .getLTCquestions(0, 0, '', '', ' AND LTC_ID =' + this.ltcID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              if (data['data'].length == 0) {
                this.QueData = new LTCQuestionaryMaster();
                this.current = 2;
                this.isSpinning = false;
              } else {
                this.QueData = data['data'][0];
                this.current = 2;
                this.isSpinning = false;
              }
            } else {
              this.message.error('Something Went Wrong', '');
              this.isSpinning = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
    }
  }

  empanneledHospitalMapList = [];
  nonEmpanneled: boolean = false;

  // TREATEMENT_TYPE1 =false;
  // TREATEMENT_TYPE2 =false;

  list: any = [];
  ADD(CGHS_AMA_REFERENCE_DATE: any) {
    console.log('CGHS_AMA_REFERENCE_DATE', CGHS_AMA_REFERENCE_DATE);
    this.list.push(CGHS_AMA_REFERENCE_DATE);
    console.log('hh', this.list);
  }

  drawerData: EmployeeMaster = new EmployeeMaster();
  drawerTitle: string = '';

  add(): void {
    this.drawerTitle = 'Create New Employee';
    this.drawerData = new EmployeeMaster();
    this.empDrawerVisible = true;
  }
  editEmp;

  edit(data: any): void {
    this.drawerTitle = 'Edit Employee Details';
    this.api
      .getEmployeeMaster(0, 0, '', '', ' AND ID = ' + data.EMP_ID)
      .subscribe((data) => {
        if (data['code'] == 200) {
          this.drawerData = data['data'][0];
        } else {
          this.message.error("Can't Load Employee Data", '');
        }
      }),
      (this.empDrawerVisible = true);
  }

  empDrawerClose(): void {
    this.empDrawerVisible = false;
    this.allEmployeeList();
    // window.location.reload();
  }

  get closeCallback() {
    return this.empDrawerClose.bind(this);
  }

  employeeSearch(event: any) {
    var f = '';
    if (event.length >= 3) {
      this.api
        .getEmployeeMaster(
          0,
          0,
          '',
          'asc',
          " AND NAME like '%" +
            event +
            "%'" +
            " OR EMPLOYEE_CODE like '%" +
            event +
            "%'" +
            " OR OFFICE_NAME like '%" +
            event +
            "%'" +
            " OR DESIGNATION like '%" +
            event +
            "%'" +
            " OR DDO_OF_THE_OFFICIAL like '%" +
            event +
            "%'" +
            f
        )
        .subscribe(
          (empData) => {
            if (empData['code'] == 200) {
              var filteredOptions = empData['data'];

              // this.empLoader = false;
            } else {
              this.message.error("Can't Load Employee Data", '');
              // this.empLoader = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
    }
    // this.api
    //   .getinvestigationprocedure(
    //     0,
    //     0,
    //     'NAME',
    //     'asc',
    //     " and NAME like '%" + event + "%'" + f
    //   )
    //   .subscribe(
    //     (data) => {
    //       if (data['code'] == 200 && data['data'].length > 0)
    //         this.procedureList2 = data['data'];
    //       else this.procedureList2 = [];
    //     },
    //     (err) => {
    //       console.log(err);
    //       this.isSpinning = false;
    //     }
    //   );
    // }
  }
  pressCS(event) {
    if (event == 'CS') {
      // this.data.CGHS_CARD_VALIDITY = '';
      // this.data.CGHS_CARD_NO = '';
    } else {
    }
  }
  orderSheetVisible: boolean = false;
  orderSheetTitle: string = '';
  orderValue: any = '';
  orderSheet() {
    this.orderValue = '';
    this.orderSheetTitle = 'Order Sheet';
    this.orderSheetVisible = true;
    // this.orderValue = 1;
  }
  permissionLetter() {
    this.orderValue = '';
    this.orderSheetTitle = 'Permission Letter';
    this.orderSheetVisible = true;
    // this.orderValue = 2;
  }

  orderSheetClose(): void {
    this.orderSheetVisible = false;
  }

  get orderSheetCloseCallback() {
    return this.orderSheetClose.bind(this);
  }
  HospitalMapping = [];
  queData: any = [];
  empID1;
  claimID1;

  index = -1;
  // editrelation: Realtionshipdata = new Realtionshipdata();
  edit1(data: Realtionshipdata, i: any): void {
    this.index = i;
    this.editrelation = Object.assign({}, data);
  }

  addData(addNew: boolean, relation: NgForm) {
    this.isSpinning = false;
    this.isOk = true;

    if (
      (this.editrelation.NAME_OF_FAMILY_MEMBER == undefined ||
        this.editrelation.NAME_OF_FAMILY_MEMBER.trim() == '') &&
      (this.editrelation.AGE == undefined || this.editrelation.AGE == 0) &&
      (this.editrelation.RELATIONSHIP == undefined ||
        this.editrelation.RELATIONSHIP.trim() == '') &&
      (this.editrelation.GENDER == undefined ||
        this.editrelation.GENDER.trim() == '')
    ) {
      this.isOk = false;
      this.message.error('Please Fill All The Required Fields ', '');
    } else if (
      this.editrelation.NAME_OF_FAMILY_MEMBER == undefined ||
      this.editrelation.NAME_OF_FAMILY_MEMBER.trim() == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Name of Family Member.', '');
    } else if (
      this.editrelation.AGE == undefined ||
      this.editrelation.AGE <= 0
    ) {
      this.isOk = false;
      this.message.error('Please Enter Age  ', '');
    } else if (
      this.editrelation.RELATIONSHIP == null ||
      this.editrelation.RELATIONSHIP == undefined
    ) {
      this.isOk = false;
      this.message.error(
        ' Please Select Relationship with the Govt. Servant.',
        ''
      );
    } else if (
      this.editrelation.GENDER == null ||
      this.editrelation.GENDER == undefined
    ) {
      this.isOk = false;
      this.message.error(' Please Select Gender ', '');
    }

    if (this.isOk) {
      this.editrelation.ID = undefined;
      this.editrelation.CLIENT_ID = 1;

      if (this.index > -1) {
        this.relationdata[this.index] = Object.assign({}, this.editrelation);
      } else {
        this.relationdata.push(Object.assign({}, this.editrelation));
      }
      this.relationdata = [...[], ...this.relationdata];

      relation.form.reset();
      this.editrelation = new Realtionshipdata();
      this.index = -1;
    }
  }

  confirmDeleteHospital(data: any) {
    this.relationdata = this.relationdata.filter((item) => item.ID != data.ID);
  }

  registerButton = true;

  registerTrue() {
    this.registerButton = !this.data.SELF_DECLARATION;
  }
}
