// import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { QuestionaryMaster } from 'src/app/Models/questionarymaster';
import { ApiService } from 'src/app/Service/api.service';
import * as html2pdf from 'html2pdf.js';
import { SignatureMaster } from 'src/app/Models/SignatureMaster';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-ltcsheet1',
  templateUrl: './ltcsheet1.component.html',
  styleUrls: ['./ltcsheet1.component.css'],
})
export class Ltcsheet1Component implements OnInit {
  @Input() drawerClose: Function;

  @Input() formdata7: any;
  @Input() amount: any;
  @Input() namount: any;
  @Input() LTC1data: any;
  @Input() relationdata1: any;
  loadingRecords: boolean = false;
  @Input() POST: any;
  @Input() OFFICE_NAME: any;
  @Input() NAME: any;
  @Input() POST_HN: any;
  @Input() OFFICE_NAME_HN: any;
  @Input() NAME_HN: any;
  // @Input() orderdata: any;
  // @Input() HospitalMapping: any;
  // @Input() queData: QuestionaryMaster = new QuestionaryMaster();
  // @Input() amountinwords1: any;
  // @Input() amountinwordsh1: any;
  // @Input() claimID: any;
  // @Input() empID: any;
  // @Input() SECTION_TYPE: any;
  // FROM_DATE: Date = new Date();
  // TO_DATE: Date = new Date();
  // TODAYS_DATE: Date = new Date();
  // PRINTED_DATE: Date = new Date();
  // FILE_NO: string = '';
  // isSpinning: boolean = false;
  // IS_EMERG_TREAT_AVAILED = 0;
  // IS_BILL_FILLED_IN_TIME = 0;
  // IS_HOSPITAL_EMPLANELLED;
  // Hospital_Type = 'NE';
  // NAME: string = 'shubham';
  // NAME_MR: string = '';
  // Expose_facto = true;
  // AMOUNT: number;
  // AMOUNT_WORDS: string;
  // am = 1000;
  // PATIENT_NAME_HN: string;
  // RELATIONSHIP_HN: string;
  // EMPLOYEE_NAME_HN: string;
  // ADMISSIBLE_AMOUNT_HN: any;
  // dataa: any;
  // radioValue: any = 'A';
  // @Input() fileList1 = [];
  // @Input() HospitalMapping1 = [];

  constructor(
    private datePipe: DatePipe,
    private message: NzNotificationService,
    private api: ApiService,
    private datepipe: DatePipe
  ) {}

  Signaturearray = [];
  remarkCount: any = 0;
  allRemarks: any;
  ngOnInit(): void {
    this.remarkCount = Number(this.formdata7.length);

    this.api.getSignature(0, 0, 'ID', 'desc', '').subscribe(
      (data) => {
        if (data['code'] == 200) {
          this.Signaturearray = data['data'];
        }
      },
      (err) => {
        console.log(err);
      }
    );

    for (let i = 0; i < this.formdata7.length; i++) {
      const remark = this.formdata7[i]['REMARK'];
      if (remark !== null && remark !== undefined && remark.trim() !== '') {
        this.allRemarks =
          (this.allRemarks ? this.allRemarks + ', ' : '') + remark;
      }
    }

    // for (let i = 0; i < this.formdata7.length; i++) {
    //   if (
    //     this.formdata7[i]['REMARK'] !== null &&
    //     this.formdata7[i]['REMARK'] !== undefined &&
    //     this.formdata7[i]['REMARK'].trim() !== '' &&
    //     this.formdata7[i]['REMARK'] !== ''
    //   ) {
    //     // this.allRemarks += (this.allRemarks.length > 0 ? ', ' : '') + remark;
    //     this.allRemarks = this.formdata7.map((data) => data.REMARK).join(', ');
    //   }
    // }
  }

  editorConfigPara: AngularEditorConfig = {
    editable: true,
    height: '50',
    minHeight: '50',
    maxHeight: '50',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: false,
    showToolbar: false,
    placeholder: 'Enter Remark...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
  };

  calculateRemarkRowspan(): number {
    // Count the total number of rows
    const totalRows = this.formdata7.length;

    // Return the calculated rowspan value
    return totalRows > 1 ? totalRows : 1;
  }

  signature(event: any) {
    var f = this.Signaturearray.filter((item) => item.ID == event);
    // this.SECTION_TYPE = f[0]['SECTION_TYPE'];
    this.NAME = f[0]['NAME'];
    this.NAME_HN = f[0]['NAME_HN'];
    this.OFFICE_NAME = f[0]['OFFICE_NAME'];
    this.OFFICE_NAME_HN = f[0]['OFFICE_NAME_HN'];
    this.POST = f[0]['POST'];
    this.POST_HN = f[0]['POST_HN'];
  }
  claimHospitalMapping = [];

  Save() {
    // this.openPrintOrderModal();
    this.LTC1data.BILL_SUBMITTED_DATE = this.datepipe.transform(
      this.LTC1data.BILL_SUBMITTED_DATE,
      'yyyy-MM-dd'
    );
    this.loadingRecords = true;
    this.api.ltcMasterUpdate(this.LTC1data).subscribe((successCode) => {
      if (successCode.code == '200') {
        // this.drawerClose();
        this.openPrintOrderModal();

        this.message.success('Information Saved Successfully...', '');
      } else {
        this.message.error('Failed To Save Information....', '');
        this.loadingRecords = false;
      }
    });
  }
  close(): void {
    this.drawerClose();
  }

  printOrderModalVisible: boolean = false;

  openPrintOrderModal() {
    if (
      this.LTC1data.ID != undefined ||
      this.LTC1data.ID != null ||
      this.LTC1data.ID != ''
    ) {
      this.loadingRecords = true;
      this.api
        .getAllltcMaster(0, 0, '', '', ' AND ID = ' + this.LTC1data.ID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              this.LTC1data = data['data'][0];
              this.loadingRecords = false;
              this.printOrderModalVisible = true;
            } else {
              this.message.error("Can't Load Data", '');
              this.loadingRecords = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
    }
  }

  printOrderModalCancel() {
    this.printOrderModalVisible = false;
  }

  getwidth() {
    if (window.innerWidth <= 400) {
      return 400;
    } else {
      return 850;
    }
  }

  // auto(event: number) {

  // }

  isfound = 0;

  fileName: string = 'HOD Order Letter';
  pdfDownload: boolean = false;

  generatePDF() {
    const element = document.getElementById('printOrderModal');
    const opt = {
      margin: 0.2,
      filename: 'Download.pdf',
      image: { type: 'jpeg', quality: 5 },
      html2canvas: { scale: 5 },
      jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
    };
    html2pdf().from(element).set(opt).save();
  }
  getdateeeee() {
    var date: any = new Date();
    date = this.datepipe.transform(date, 'dd/MM/yyyy');
    return date;
  }
  getlevel(level: any) {
    if (level != null && level != undefined && level != '') {
      const numbers = level.match(/\d+/g);
      return numbers;
    } else {
      return '';
    }
  }
}
