import { Component, OnInit, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { FileHierarchy } from 'src/app/Models/filehierarchy';
import { ApiService } from 'src/app/Service/api.service';
import { FilehierarchiesComponent } from '../filehierarchies/filehierarchies.component';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

@Component({
  selector: 'app-filehierarchy',
  templateUrl: './filehierarchy.component.html',
  styleUrls: ['./filehierarchy.component.css'],
})
export class FilehierarchyComponent implements OnInit {
  formTitle = 'Manage File Hierarchy';
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];

  loadingRecords = true;
  sortValue: string = 'desc';
  sortKey: string = 'ID';
  searchText: string = '';
  filterQuery: string = '';
  isFilterApplied: string = 'default';
  columns: string[][] = [
    ['PARENT_NAME', 'ParentName'],
    ['NAME', 'Name'],
    ['IS_LAST', 'Is last node'],
  ];
  isApproveVisible = false;
  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  REMARK: string;
  drawerData: FileHierarchy = new FileHierarchy();

  @ViewChild(FilehierarchiesComponent, { static: false })
  FilehierarchiesComponent: FilehierarchiesComponent;

  constructor(
    private api: ApiService,
    private message: NzNotificationService
  ) {}
  ngOnInit() {
    // this.search();
  }
  // Basic Methods
  // sort(sort: { key: string; value: string }): void {
  //   this.sortKey = sort.key;
  //   this.sortValue = sort.value;
  //   this.search(true);
  // }

  pageSize2 = 10;
  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find((item) => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'id';
    const sortOrder = (currentSort && currentSort.value) || 'desc';
    console.log(currentSort);
    console.log('sortOrder :' + sortOrder);
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if (this.pageSize2 != pageSize) {
      this.pageIndex = 1;
      this.pageSize2 = pageSize;
    }

    if (this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search();
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }
    console.log('search text:' + this.searchText);
    if (this.searchText != '') {
      var likeQuery = ' AND';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
      console.log('likeQuery' + likeQuery);
    }
    this.api
      .getAllFilehierarchy(
        this.pageIndex,
        this.pageSize,
        this.sortKey,
        sort,
        likeQuery
      )
      .subscribe(
        (data) => {
          console.log(data);
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
        },
        (err) => {
          console.log(err);
          if (err['ok'] == false) this.message.error('Server Not Found', '');
        }
      );
  }
  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  FileHierarchy = [];
  add(): void {
    this.drawerTitle = 'Create New File Hierarchy';
    this.drawerData = new FileHierarchy();
    this.api.getAllFilehierarchy(0, 0, '', '', 'AND IS_LAST=0').subscribe(
      (data) => {
        console.log(data);
        this.FileHierarchy = data['data'];
      },
      (err) => {
        console.log(err);
        //this.loadingForm = false;
      }
    );
    this.drawerVisible = true;
  }
  listdata2: any = [];
  IS_ADVANCE: boolean;
  IS_LAST: boolean;
  islast: any;
  edit(data: FileHierarchy): void {
    console.log(data);
    this.IS_ADVANCE = undefined;

    this.drawerTitle = 'Update File Hierarchy';
    this.drawerData = Object.assign({}, data);
    this.islast = data.IS_LAST;
    if (this.islast == '0') {
      this.IS_LAST = false;
    } else {
      this.IS_LAST = true;
    }
    if (data.IS_ADVANCE == 'Y') {
      this.IS_ADVANCE = true;
    } else {
      this.IS_ADVANCE = false;
    }

    console.log(data.IS_ADVANCE == 'Y', 'data.IS_ADVANCE');

    this.drawerVisible = true;
    this.api
      .getAllFilehierarchy(0, 0, 'ID', 'desc', ' AND ID !=' + data.ID)
      .subscribe((data) => {
        if (data['code'] == 200) {
          this.listdata2 = data['data'];
        }
      });
    // this.FilehierarchiesComponent.getallorg2(data.ID);
  }
  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
    this.IS_ADVANCE = null;
    this.IS_LAST = null;
  }
}
