import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

@Component({
  selector: 'app-claim-log-drawer',
  templateUrl: './claim-log-drawer.component.html',
  styleUrls: ['./claim-log-drawer.component.css'],
})
export class ClaimLogDrawerComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: any = [];
  dataList: any;
  isSpinning = false;
  pageIndex = 1;
  pageSize2 = 10;
  pageSize = 10;
  totalRecords = 1;
  loadingRecords = false;
  sortValue: string = 'desc';
  sortKey: string = 'ID';
  searchText: string = '';
  filterQuery: string = '';

  LOG_MASTER = [
    {
      ID: 1,
      DATE: '12 Feb 2023 05:15 PM',
      ACTION: 'Annuxure Download',
      PERFORMEDBY: 'Amit Chougule',
      IS_ACTIVE: true,
    },
    {
      ID: 2,
      DATE: '14 Feb 2023 11:15 AM',
      ACTION: 'Order Print',
      PERFORMEDBY: 'Ramchandra Mane',
      IS_ACTIVE: true,
    },
    {
      ID: 3,
      DATE: '15 Feb 2023 10:15 AM',
      ACTION: 'Email Sent',
      PERFORMEDBY: 'Umesh Patil',
      IS_ACTIVE: true,
    },
    {
      ID: 4,
      DATE: '20 Feb 2023 1:15 PM',
      ACTION: 'Annuxure Download',
      PERFORMEDBY: 'Sachin More',
      IS_ACTIVE: true,
    },
    {
      ID: 5,
      DATE: '02 Feb 2023 11:15 AM',
      ACTION: 'Email Sent',
      PERFORMEDBY: 'Sunil Magdum',
      IS_ACTIVE: true,
    },
    {
      ID: 6,
      DATE: '22 Feb 2023 10:15 AM',
      ACTION: 'Order Print',
      PERFORMEDBY: 'Pratik Joshi',
      IS_ACTIVE: true,
    },
    {
      ID: 7,
      DATE: '22 Feb 2023 10:15 AM',
      ACTION: 'Email Sent',
      PERFORMEDBY: 'Kavita Patil',
      IS_ACTIVE: true,
    },
  ];

  status: boolean = true;

  constructor(private message: NzNotificationService) {}

  ngOnInit() {}

  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find((item) => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'id';
    const sortOrder = (currentSort && currentSort.value) || 'desc';

    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if (this.pageSize2 != pageSize) {
      this.pageIndex = 1;
      this.pageSize2 = pageSize;
    }

    if (this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search();
  }

  categoryList = [];

  close(): void {
    this.drawerClose();
  }

  isValidMobile(mobile) {
    const expression = /^[6-9]\d{9}$/;
    return expression.test(String('' + mobile).toLowerCase());
  }

  isValidEmail(email) {
    const expression =
      /^[_a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/;
    return expression.test(String(email).toLowerCase());
  }

  save(addNew: boolean): void {}
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = false;
    var sort: string;

    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }

    var likeQuery = '';
  }
}
