export class Claiemeded{
ID:number;
EMP_ID:number;
GRADE_PAY:any
OFFICE_NAME:string='';
DESIGNATION:string='';
LOCATION:string='';
DDO_OF_THE_OFFICIAL:string='';
PATIENT_NAME:string='';
RELATION_WITH_PATIENT:string='';
BENEFICIARY_TYPE:string='';
CGHS_CARD_NO:any
IS_EMERGENCY_TREATMENT_APPLIED:boolean=true;
EMERGENCY_REF_DATE:any
HOSPITAL_ID:any

IS_PRIVATE_HOSPITAL:any
IS_PERMISSION_TAKEN:any

NATURE_OF_TREATMENT:any
TREATMENT_START_DATE:any
TREATMENT_END_DATE:any
IS_FORWARDING_LETTER:boolean=true;
IS_MEDICAL_REIMBURSEMENT_CLAIMED:boolean=true;
IS_COPY_OF_CGHS_CARD_FOR_BOTH_BENEFICIARY_AND_PATIENT:boolean=true;
IS_DISCHARGE_CARD:boolean=true;
FORM_NO_3_IN_CASE_THE_MEDICAL_CLAIM:any
IS_FINAL_BILL_IN_ORIGINAL_ALONG_WITH_ONE_XEROX_ONE_COPY:boolean=true;
IS_PAY_SLIP_OF_THE_PERIOD_OF_TRATMENT:boolean=true;
IS_BANK_MANDATE_FROM_ALONG_WITH_CANCELLED_CHEQUE:boolean=true;
IS_PRESCRIPTION_OF_MEDICINES_PURCHASED_FROM_OUTSIDE:boolean=true;
EMPLOYEE_CODE:number;

STAGE_ID:any
DATE_OF_BILL:any
IS_BILL_FILLED_IN_TIME:any
AMOUNT_OF_REIMBUSMENT_CLAIMED:any
AMOUNT_OF_REIMBUSMENT_ADMISSIBLE:any
IS_ADVANCE_TAKEN:any
ADVANCE_AMOUNT:any
NET_AMOUNT_PAYABLE:any
}