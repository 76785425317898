import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import * as html2pdf from 'html2pdf.js';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from 'src/app/Service/api.service';
// import { ToWords } from 'to-words';
// const toWords = new ToWords();
import { ToWords } from 'to-words';
const toWords = new ToWords({
  localeCode: 'en-IN',
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    // currencyOptions: {
    //   // can be used to override defaults for the selected locale
    //   name: 'Rupee',
    //   plural: 'Rupees',
    //   symbol: '₹',
    //   fractionalUnit: {
    //     name: 'Paisa',
    //     plural: 'Paise',
    //     symbol: '',
    //   },
    // },
  },
});
const toWordsen = new ToWords({
  localeCode: 'hi-IN',
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    // currencyOptions: {
    //   // can be used to override defaults for the selected locale
    //   name: 'Rupee',
    //   plural: 'रुपये',
    //   symbol: '₹',
    //   fractionalUnit: {
    //     name: 'Paisa',
    //     plural: 'Paise',
    //     symbol: '',
    //   },
    // },
  },
});
@Component({
  selector: 'app-claim-application-drawer',
  templateUrl: './claim-application-drawer.component.html',
  styleUrls: ['./claim-application-drawer.component.css'],
})
export class ClaimApplicationDrawerComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() orderdata: any;
  @Input() amountinwords: any;
  @Input() amountinwordsh: any;
  @Input() amountinwordssh: any;
  @Input() amountinwordss: any;
  @Input() SECTION_TYPE: any;
  @Input() SIGNNAME: any;
  @Input() NAME_HN: any;
  @Input() OFFICE_NAME: any;
  @Input() OFFICE_NAME_HN: any;
  @Input() POST: any;
  @Input() POST_HN: any;
  @Input() Order: any;
  @Input() isAdvanceSameAsAdmissible: any;
  @Input() Signaturearray = [];
  @Input() admissibleineng: any;
  @Input() admissibleinhindi: any;
  @Input() advadmissibleineng: any;
  @Input() advadmissibleinhindi: any;
  @Input() advanceineng: any;
  @Input() advanceinhindi: any;
  @Input() finaladmissibleineng: any;
  @Input() finaladmissibleinhindi: any;
  @Input() finalclaimedineng: any;
  @Input() finalclaimedinhindi: any;
  @Input() finalremainingineng: any;
  @Input() finalremaininginhindi: any;
  FROM_DATE: Date = new Date();
  TO_DATE: Date = new Date();
  TODAYS_DATE: Date = new Date();
  PRINTED_DATE: Date = new Date();
  FILE_NO: string = '';
  @Input() isSpinning: boolean = false;
  IS_EMERG_TREAT_AVAILED = 0;
  IS_BILL_FILLED_IN_TIME = 0;
  IS_HOSPITAL_EMPLANELLED;
  Hospital_Type = 'NE';
  NAME: string = 'shubham';
  NAME_MR: string = '';
  Expose_facto = true;
  AMOUNT: number;
  AMOUNT_WORDS: string;
  am = 1000;
  PATIENT_NAME_HN: string;
  RELATIONSHIP_HN: string;
  EMPLOYEE_NAME_HN: string;
  ADMISSIBLE_AMOUNT_HN: any;
  dataa: any;
  radioValue: any = 'A';
  @Input() fileList = [];
  @Input() HospitalMapping = [];

  constructor(
    private datePipe: DatePipe,
    private message: NzNotificationService,
    private api: ApiService
  ) {}
  userId: any;
  mailId: any;

  ngOnInit(): void {
    // this.getAllUsers();
    this.userId = Number(sessionStorage.getItem('userId'));
    this.mailId = sessionStorage.getItem('emailId');
  }
  printLatestOrderModalVisible1: boolean = false;
  save() {}

  close(): void {
    this.isSpinning = false;
    this.drawerClose();
  }

  printOrderModalVisible: boolean = false;

  openPrintOrderModal() {
    this.api
      .getclaimMaster2(0, 0, 'ID', 'ASC', ' AND ID= ' + this.orderdata.ID)
      .subscribe(
        (data) => {
          this.orderdata = data['data'][0];
        },
        (err) => {
          console.log(err);
        }
      );
    this.printOrderModalVisible = true;
  }

  printLatestOrderModalVisible: boolean = false;
  openPrintOrderModalLatest() {
    this.api
      .getclaimMaster2(0, 0, 'ID', 'ASC', ' AND ID= ' + this.orderdata.ID)
      .subscribe(
        (data) => {
          this.orderdata = data['data'][0];
        },
        (err) => {
          console.log(err);
        }
      );
    this.printLatestOrderModalVisible = true;
  }

  printLatestAdvanceOrderModalVisible: boolean = false;
  openPrintAdvanceOrderModalLatest() {
    this.api
      .getclaimMaster2(0, 0, 'ID', 'ASC', ' AND ID= ' + this.orderdata.ID)
      .subscribe(
        (data) => {
          this.orderdata = data['data'][0];
        },
        (err) => {
          console.log(err);
        }
      );
    this.printLatestAdvanceOrderModalVisible = true;
  }

  printOrderModalCancel() {
    this.printOrderModalVisible = false;
  }
  printLatestOrderModalCancel() {
    this.printLatestOrderModalVisible = false;
  }
  printAdvanceOrderModalCancel() {
    this.printLatestAdvanceOrderModalVisible = false;
  }

  getwidth() {
    if (window.innerWidth <= 400) {
      return 400;
    } else {
      return 850;
    }
  }

  // auto(event: number) {

  // }
  isfound = 0;
  pakagedata() {
    this.ADMISSIBLE_AMOUNT_HN = this.amountinwordsh;
    // this.data=showLayout

    // this.isOk = true;
    // if (this.orderdata.ORDER_DATE == undefined || this.orderdata.ORDER_DATE == null) {
    //   // this.isOk = false;
    //   this.message.error('Please enter date', '');
    // } else
    // if (
    //   this.orderdata.FILE_ID == undefined ||
    //   this.orderdata.FILE_ID == null ||
    //   this.orderdata.FILE_ID == 0
    // ) {
    //   // this.isOk = false;
    //   this.message.error('Please select file no', '');
    // } else
    if (
      this.orderdata.SIGNATURE_ID == undefined ||
      this.orderdata.SIGNATURE_ID == null ||
      this.orderdata.SIGNATURE_ID == ''
    ) {
      // this.isOk = false;
      this.message.error('Please Select Signature', '');
    } else if (
      this.orderdata.ORDER_NO == undefined ||
      this.orderdata.ORDER_NO == null ||
      this.orderdata.ORDER_NO == ''
    ) {
      // this.isOk = false;
      this.message.error('Please enter Order Number', '');
    } else if (
      this.orderdata.EMPLOYEE_NAME_HN == undefined ||
      this.orderdata.EMPLOYEE_NAME_HN == null ||
      this.orderdata.EMPLOYEE_NAME_HN == ''
    ) {
      // this.isOk = false;
      this.message.error('Please enter name of applicant (in hindi)', '');
    } else if (
      this.orderdata.PATIENT_NAME_HN == undefined ||
      this.orderdata.PATIENT_NAME_HN == null ||
      this.orderdata.PATIENT_NAME_HN == ''
    ) {
      // this.isOk = false;
      this.message.error('Please enter Name of patient(in hindi)', '');
      // } else if (
      //   this.orderdata.HOSPITAL_NAME_HN == undefined ||
      //   this.orderdata.HOSPITAL_NAME_HN == null ||
      //   this.orderdata.HOSPITAL_NAME_HN == ''
      // ) {
      //   // this.isOk = false;
      //   this.message.error('Please Enter Name Of Hospital(in hindi)', '');
      // } else if (
      //   this.orderdata.HOSPITAL_ADDRESS_HN == undefined ||
      //   this.orderdata.HOSPITAL_ADDRESS_HN == null ||
      //   this.orderdata.HOSPITAL_ADDRESS_HN == ''
      // ) {
      //   // this.isOk = false;
      //   this.message.error('Please Enter Address Of Hospital(in hindi)', '');
      // } else {
    } else {
      for (let index = 0; index < this.HospitalMapping.length; index++) {
        if (this.HospitalMapping[index].NAME_HN == '') {
          this.isfound = 1;
        } else if (this.HospitalMapping[index].ADDRESS_HN == '') {
          this.isfound = 2;
        }
      }
      if (this.isfound == 1) {
        this.message.error('Please Enter Name Of Hospital(in hindi)', '');
        this.isfound = null;
      } else if (this.isfound == 2) {
        this.message.error('Please Enter Address Of Hospital(in hindi)', '');
        this.isfound = null;
      } else {
        this.isSpinning = true;
        this.api
          .getEmployeeMaster(
            0,
            0,
            '',
            'asc',
            ' AND ID=' + this.orderdata.EMP_ID
          )
          .subscribe(
            (data) => {
              this.dataa = data['data'][0];
              this.dataa.EMPLOYEE_NAME_HN = this.orderdata.EMPLOYEE_NAME_HN;
              this.orderdata.SUB_STAGE = 'O';
              this.orderdata.ORDER_DATE = this.datePipe.transform(
                this.orderdata.ORDER_DATE,
                'yyyy-MM-dd'
              );
              this.api
                .updateEmployeeMaster1(this.dataa)
                .subscribe((successCode) => {
                  if (successCode.code == '200') {
                    // this.message.success('Information updated Successfully', '');
                    this.api
                      .updateClaimMaster(this.orderdata)
                      .subscribe((successCode) => {
                        if (successCode.code == '200') {
                          // this.message.success('Information updated Successfully','');

                          for (
                            let index = 0;
                            index < this.HospitalMapping.length;
                            index++
                          ) {
                            this.HospitalMapping[index].CREATED_MODIFIED_DATE =
                              '';
                            this.HospitalMapping[index].IS_VISIBLE_IN_ORDER =
                              this.HospitalMapping[index].IS_VISIBLE_IN_ORDER ==
                              true
                                ? 1
                                : 0;
                          }

                          this.api
                            .updatebulk(this.HospitalMapping)
                            .subscribe((successCode) => {
                              if (successCode.code == '200') {
                                this.message.success(
                                  'Information updated Successfully',
                                  ''
                                );
                              } else {
                                this.message.error('Information Not Saved', '');
                              }
                            });
                          this.isSpinning = false;
                          this.openPrintOrderModal();
                        } else {
                          this.message.error('Information Not Saved', '');
                        }
                      });
                  } else if (successCode.code == '300') {
                    this.message.error(
                      'Email ID or Mobile No. Already Registered',
                      ''
                    );
                  } else {
                    this.message.error('Information Not Saved', '');
                  }
                });
            },
            (err) => {
              console.log(err);
              this.isSpinning = false;
            }
          );
      }
    }

    // }
  }
  isfoundLatest = 0;
  pakagedataLatest() {
    this.ADMISSIBLE_AMOUNT_HN = this.amountinwordsh;
    // this.data=showLayout

    // this.isOk = true;
    // if (this.orderdata.ORDER_DATE == undefined || this.orderdata.ORDER_DATE == null) {
    //   // this.isOk = false;
    //   this.message.error('Please enter date', '');
    // } else
    // if (
    //   this.orderdata.FILE_ID == undefined ||
    //   this.orderdata.FILE_ID == null ||
    //   this.orderdata.FILE_ID == 0
    // ) {
    //   // this.isOk = false;
    //   this.message.error('Please select file no', '');
    // } else
    if (
      this.orderdata.SIGNATURE_ID == undefined ||
      this.orderdata.SIGNATURE_ID == null ||
      this.orderdata.SIGNATURE_ID == ''
    ) {
      // this.isOk = false;
      this.message.error('Please Select Signature', '');
    } else if (
      this.orderdata.ORDER_NO == undefined ||
      this.orderdata.ORDER_NO == null ||
      this.orderdata.ORDER_NO == ''
    ) {
      // this.isOk = false;
      this.message.error('Please enter Order Number', '');
    } else if (
      this.orderdata.EMPLOYEE_NAME_HN == undefined ||
      this.orderdata.EMPLOYEE_NAME_HN == null ||
      this.orderdata.EMPLOYEE_NAME_HN == ''
    ) {
      // this.isOk = false;
      this.message.error('Please enter name of applicant (in hindi)', '');
    } else if (
      this.orderdata.PATIENT_NAME_HN == undefined ||
      this.orderdata.PATIENT_NAME_HN == null ||
      this.orderdata.PATIENT_NAME_HN == ''
    ) {
      // this.isOk = false;
      this.message.error('Please enter Name of patient(in hindi)', '');
      // } else if (
      //   this.orderdata.HOSPITAL_NAME_HN == undefined ||
      //   this.orderdata.HOSPITAL_NAME_HN == null ||
      //   this.orderdata.HOSPITAL_NAME_HN == ''
      // ) {
      //   // this.isOk = false;
      //   this.message.error('Please Enter Name Of Hospital(in hindi)', '');
      // } else if (
      //   this.orderdata.HOSPITAL_ADDRESS_HN == undefined ||
      //   this.orderdata.HOSPITAL_ADDRESS_HN == null ||
      //   this.orderdata.HOSPITAL_ADDRESS_HN == ''
      // ) {
      //   // this.isOk = false;
      //   this.message.error('Please Enter Address Of Hospital(in hindi)', '');
      // } else {
    } else {
      for (let index = 0; index < this.HospitalMapping.length; index++) {
        if (this.HospitalMapping[index].NAME_HN == '') {
          this.isfoundLatest = 1;
        } else if (this.HospitalMapping[index].ADDRESS_HN == '') {
          this.isfoundLatest = 2;
        }
      }
      if (this.isfoundLatest == 1) {
        this.message.error('Please Enter Name Of Hospital(in hindi)', '');
        this.isfoundLatest = null;
      } else if (this.isfoundLatest == 2) {
        this.message.error('Please Enter Address Of Hospital(in hindi)', '');
        this.isfoundLatest = null;
      } else {
        this.isSpinning = true;
        this.api
          .getEmployeeMaster(
            0,
            0,
            '',
            'asc',
            ' AND ID=' + this.orderdata.EMP_ID
          )
          .subscribe(
            (data) => {
              this.dataa = data['data'][0];
              this.dataa.EMPLOYEE_NAME_HN = this.orderdata.EMPLOYEE_NAME_HN;
              this.orderdata.SUB_STAGE = 'O';
              this.orderdata.ORDER_DATE = this.datePipe.transform(
                this.orderdata.ORDER_DATE,
                'yyyy-MM-dd'
              );
              this.api
                .updateEmployeeMaster1(this.dataa)
                .subscribe((successCode) => {
                  if (successCode.code == '200') {
                    // this.message.success('Information updated Successfully', '');
                    this.api
                      .updateClaimMaster(this.orderdata)
                      .subscribe((successCode) => {
                        if (successCode.code == '200') {
                          // this.message.success('Information updated Successfully','');

                          for (
                            let index = 0;
                            index < this.HospitalMapping.length;
                            index++
                          ) {
                            this.HospitalMapping[index].CREATED_MODIFIED_DATE =
                              '';
                            this.HospitalMapping[index].IS_VISIBLE_IN_ORDER =
                              this.HospitalMapping[index].IS_VISIBLE_IN_ORDER ==
                              true
                                ? 1
                                : 0;
                          }

                          this.api
                            .updatebulk(this.HospitalMapping)
                            .subscribe((successCode) => {
                              if (successCode.code == '200') {
                                this.message.success(
                                  'Information updated Successfully',
                                  ''
                                );
                              } else {
                                this.message.error('Information Not Saved', '');
                              }
                            });
                          this.isSpinning = false;
                          this.openPrintOrderModalLatest();
                        } else {
                          this.message.error('Information Not Saved', '');
                        }
                      });
                  } else if (successCode.code == '300') {
                    this.message.error(
                      'Email ID or Mobile No. Already Registered',
                      ''
                    );
                  } else {
                    this.message.error('Information Not Saved', '');
                  }
                });
            },
            (err) => {
              console.log(err);
              this.isSpinning = false;
            }
          );
      }
    }

    // }
  }
  pakageadvancedataLatest() {
    this.ADMISSIBLE_AMOUNT_HN = this.amountinwordsh;
    // this.data=showLayout

    // this.isOk = true;
    // if (this.orderdata.ORDER_DATE == undefined || this.orderdata.ORDER_DATE == null) {
    //   // this.isOk = false;
    //   this.message.error('Please enter date', '');
    // } else
    // if (
    //   this.orderdata.FILE_ID == undefined ||
    //   this.orderdata.FILE_ID == null ||
    //   this.orderdata.FILE_ID == 0
    // ) {
    //   // this.isOk = false;
    //   this.message.error('Please select file no', '');
    // } else
    if (
      this.orderdata.SIGNATURE_ID == undefined ||
      this.orderdata.SIGNATURE_ID == null ||
      this.orderdata.SIGNATURE_ID == ''
    ) {
      // this.isOk = false;
      this.message.error('Please Select Signature', '');
    } else if (
      this.orderdata.ORDER_NO == undefined ||
      this.orderdata.ORDER_NO == null ||
      this.orderdata.ORDER_NO == ''
    ) {
      // this.isOk = false;
      this.message.error('Please enter Order Number', '');
    } else if (
      this.orderdata.EMPLOYEE_NAME_HN == undefined ||
      this.orderdata.EMPLOYEE_NAME_HN == null ||
      this.orderdata.EMPLOYEE_NAME_HN == ''
    ) {
      // this.isOk = false;
      this.message.error('Please enter name of applicant (in hindi)', '');
    } else if (
      this.orderdata.PATIENT_NAME_HN == undefined ||
      this.orderdata.PATIENT_NAME_HN == null ||
      this.orderdata.PATIENT_NAME_HN == ''
    ) {
      // this.isOk = false;
      this.message.error('Please enter Name of patient(in hindi)', '');
      // } else if (
      //   this.orderdata.HOSPITAL_NAME_HN == undefined ||
      //   this.orderdata.HOSPITAL_NAME_HN == null ||
      //   this.orderdata.HOSPITAL_NAME_HN == ''
      // ) {
      //   // this.isOk = false;
      //   this.message.error('Please Enter Name Of Hospital(in hindi)', '');
      // } else if (
      //   this.orderdata.HOSPITAL_ADDRESS_HN == undefined ||
      //   this.orderdata.HOSPITAL_ADDRESS_HN == null ||
      //   this.orderdata.HOSPITAL_ADDRESS_HN == ''
      // ) {
      //   // this.isOk = false;
      //   this.message.error('Please Enter Address Of Hospital(in hindi)', '');
      // } else {
    } else {
      for (let index = 0; index < this.HospitalMapping.length; index++) {
        if (this.HospitalMapping[index].NAME_HN == '') {
          this.isfoundLatest = 1;
        } else if (this.HospitalMapping[index].ADDRESS_HN == '') {
          this.isfoundLatest = 2;
        }
      }
      if (this.isfoundLatest == 1) {
        this.message.error('Please Enter Name Of Hospital(in hindi)', '');
        this.isfoundLatest = null;
      } else if (this.isfoundLatest == 2) {
        this.message.error('Please Enter Address Of Hospital(in hindi)', '');
        this.isfoundLatest = null;
      } else {
        this.isSpinning = true;
        this.api
          .getEmployeeMaster(
            0,
            0,
            '',
            'asc',
            ' AND ID=' + this.orderdata.EMP_ID
          )
          .subscribe(
            (data) => {
              this.dataa = data['data'][0];
              this.dataa.EMPLOYEE_NAME_HN = this.orderdata.EMPLOYEE_NAME_HN;
              this.orderdata.SUB_STAGE = 'O';
              this.orderdata.ORDER_DATE = this.datePipe.transform(
                this.orderdata.ORDER_DATE,
                'yyyy-MM-dd'
              );
              this.api
                .updateEmployeeMaster1(this.dataa)
                .subscribe((successCode) => {
                  if (successCode.code == '200') {
                    // this.message.success('Information updated Successfully', '');
                    this.api
                      .updateClaimMaster(this.orderdata)
                      .subscribe((successCode) => {
                        if (successCode.code == '200') {
                          // this.message.success('Information updated Successfully','');

                          for (
                            let index = 0;
                            index < this.HospitalMapping.length;
                            index++
                          ) {
                            this.HospitalMapping[index].CREATED_MODIFIED_DATE =
                              '';
                            this.HospitalMapping[index].IS_VISIBLE_IN_ORDER =
                              this.HospitalMapping[index].IS_VISIBLE_IN_ORDER ==
                              true
                                ? 1
                                : 0;
                          }

                          this.api
                            .updatebulk(this.HospitalMapping)
                            .subscribe((successCode) => {
                              if (successCode.code == '200') {
                                this.message.success(
                                  'Information updated Successfully',
                                  ''
                                );
                              } else {
                                this.message.error('Information Not Saved', '');
                              }
                            });
                          this.isSpinning = false;
                          this.openPrintAdvanceOrderModalLatest();
                        } else {
                          this.message.error('Information Not Saved', '');
                        }
                      });
                  } else if (successCode.code == '300') {
                    this.message.error(
                      'Email ID or Mobile No. Already Registered',
                      ''
                    );
                  } else {
                    this.message.error('Information Not Saved', '');
                  }
                });
            },
            (err) => {
              console.log(err);
              this.isSpinning = false;
            }
          );
      }
    }

    // }
  }

  fileName: string = 'Sanction Order';
  pdfDownload: boolean = false;

  public generatePDF() {
    var i = 0;
    var date = new Date();
    var datef = this.datePipe.transform(date, 'dd-MM-yyyy');
    var dates = this.datePipe.transform(date, 'hh-mm-ss a');
    var data = document.getElementById('printOrderModal');

    html2pdf()
      .from(data)
      .set({
        image: { type: 'jpeg', quality: 1.0 },
        html2canvas: { scale: 3, useCORS: true },
        margin: [2, 10, 2, 5],
        pagebreak: { mode: ['css', 'legecy'] },
        jsPDF: { unit: 'mm', format: 'A4', orientation: 'portrait' },
      })
      .toPdf()
      .get('pdf')
      .then(function (pdf) {
        this.pdfDownload = true;
        var totalPages = pdf.internal.getNumberOfPages();

        for (i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(12);
          pdf.setTextColor(150);
          pdf.text(i.toString(), pdf.internal.pageSize.getWidth() / 2, 10);
        }

        this.pdfDownload = false;
      })
      .save(this.fileName + '_' + datef + '_' + dates + '.pdf');

    // var i = 0;
    // var date = new Date();
    // var datef = this.datePipe.transform(date, "dd-MM-yyyy");
    // var dates = this.datePipe.transform(date, "h:mm:ss a");
    // var data = document.getElementById('print');
    // var opt = {
    //   margin: 0.2,
    //   image: { type: 'jpeg', quality: 0.98 },
    //   html2canvas: { scale: 4 },
    //   jsPDF: { unit: 'in', orientation: 'landscape', width: 'imgWidth' }
    // };

    // html2pdf().set(opt).from(data).save(this.formTitle);
  }
  public generateLatestOrderPDF() {
    var i = 0;
    var date = new Date();
    var datef = this.datePipe.transform(date, 'dd-MM-yyyy');
    var dates = this.datePipe.transform(date, 'hh-mm-ss a');
    var data = document.getElementById('printOrderModalLatest');

    html2pdf()
      .from(data)
      .set({
        image: { type: 'jpeg', quality: 1.0 },
        html2canvas: { scale: 3, useCORS: true },
        margin: [2, 10, 2, 5],
        pagebreak: { mode: ['css', 'legecy'] },
        jsPDF: { unit: 'mm', format: 'A4', orientation: 'portrait' },
      })
      .toPdf()
      .get('pdf')
      .then(function (pdf) {
        this.loadingRecords = true;
        var totalPages = pdf.internal.getNumberOfPages();

        for (i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(12);
          pdf.setTextColor(150);
          pdf.text(i.toString(), pdf.internal.pageSize.getWidth() / 2, 10);
        }

        this.loadingRecords = false;
      })
      .save(this.fileName + '_' + datef + '_' + dates + '.pdf');

    // var i = 0;
    // var date = new Date();
    // var datef = this.datePipe.transform(date, "dd-MM-yyyy");
    // var dates = this.datePipe.transform(date, "h:mm:ss a");
    // var data = document.getElementById('print');
    // var opt = {
    //   margin: 0.2,
    //   image: { type: 'jpeg', quality: 0.98 },
    //   html2canvas: { scale: 4 },
    //   jsPDF: { unit: 'in', orientation: 'landscape', width: 'imgWidth' }
    // };

    // html2pdf().set(opt).from(data).save(this.formTitle);
  }
  public generateAdvanceOrderPDF() {
    var i = 0;
    var date = new Date();
    var datef = this.datePipe.transform(date, 'dd-MM-yyyy');
    var dates = this.datePipe.transform(date, 'hh-mm-ss a');
    var data = document.getElementById('printOrderModalAdvance');

    html2pdf()
      .from(data)
      .set({
        image: { type: 'jpeg', quality: 1.0 },
        html2canvas: { scale: 3, useCORS: true },
        margin: [2, 10, 2, 5],
        pagebreak: { mode: ['css', 'legecy'] },
        jsPDF: { unit: 'mm', format: 'A4', orientation: 'portrait' },
      })
      .toPdf()
      .get('pdf')
      .then(function (pdf) {
        this.loadingRecords = true;
        var totalPages = pdf.internal.getNumberOfPages();

        for (i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(12);
          pdf.setTextColor(150);
          pdf.text(i.toString(), pdf.internal.pageSize.getWidth() / 2, 10);
        }

        this.loadingRecords = false;
      })
      .save(this.fileName + '_' + datef + '_' + dates + '.pdf');

    // var i = 0;
    // var date = new Date();
    // var datef = this.datePipe.transform(date, "dd-MM-yyyy");
    // var dates = this.datePipe.transform(date, "h:mm:ss a");
    // var data = document.getElementById('print');
    // var opt = {
    //   margin: 0.2,
    //   image: { type: 'jpeg', quality: 0.98 },
    //   html2canvas: { scale: 4 },
    //   jsPDF: { unit: 'in', orientation: 'landscape', width: 'imgWidth' }
    // };

    // html2pdf().set(opt).from(data).save(this.formTitle);
  }

  getAllUsers() {
    this.api.getSignature(0, 0, 'ID', 'desc', '').subscribe(
      (data) => {
        if (data['code'] == 200) {
          this.Signaturearray = data['data'];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  signatureData: any = [];
  Name = '';
  signature(event: any) {
    var f = this.Signaturearray.filter((item) => item.ID == event);
    this.SECTION_TYPE = f[0]['SECTION_TYPE'];
    this.SIGNNAME = f[0]['NAME'];
    this.NAME_HN = f[0]['NAME_HN'];
    this.OFFICE_NAME = f[0]['OFFICE_NAME'];
    this.OFFICE_NAME_HN = f[0]['OFFICE_NAME_HN'];
    this.POST = f[0]['POST'];
    this.POST_HN = f[0]['POST_HN'];
  }
}
