<div *ngIf="orderdata != undefined && Order == 'O'">
  <form nz-form>
    <nz-spin [nzSpinning]="isSpinning">
      <div nz-row [nzGutter]="10">
        <div
          nz-col
          nzXs="8"
          nzSm="8"
          nzMd="8"
          nzLg="8"
          nzXl="8"
          nzSpan="8"
          style="height: 590px; overflow-y: scroll"
        >
          <div class="my-box">
            <div nz-row>
              <div
                style="display: none"
                nz-col
                nzXs="12"
                nzSm="12"
                nzMd="12"
                nzLg="12"
                nzXl="12"
                nzSpan="12"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>Date</nz-form-label>

                  <nz-form-control>
                    <nz-date-picker
                      style="width: 100%"
                      nzFormat="dd/MM/yyyy"
                      name="printedDate"
                      [(ngModel)]="orderdata.ORDER_DATE"
                    >
                    </nz-date-picker>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <!-- <div nz-col nzXs="12" nzSm="12" nzMd="12" nzLg="12" nzXl="12" nzSpan="12">
                                <nz-form-item>
                                    <nz-form-label nzRequired nzNoColon>From Date</nz-form-label>

                                    <nz-form-control>
                                        <nz-date-picker style="width: 100%;" nzFormat="dd/MM/yyyy" name="fromDate"
                                            [(ngModel)]="FROM_DATE">
                                        </nz-date-picker>
                                    </nz-form-control>
                                </nz-form-item>
                            </div> -->

              <!-- <div nz-col nzXs="12" nzSm="12" nzMd="12" nzLg="12" nzXl="12" nzSpan="12">
                                <nz-form-item>
                                    <nz-form-label nzRequired nzNoColon>To Date</nz-form-label>

                                    <nz-form-control>
                                        <nz-date-picker style="width: 100%;" nzFormat="dd/MM/yyyy" name="toDate"
                                            [(ngModel)]="TO_DATE">
                                        </nz-date-picker>
                                    </nz-form-control>
                                </nz-form-item>
                            </div> -->
            </div>

            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <!-- <div>
                  <nz-form-item>
                    <nz-form-label  nzNoColon>File No.</nz-form-label>  
                    <nz-radio-group
                      [(ngModel)]="orderdata.PAY_BILL"
                      name="radiogaz"
                    >
                      <label nz-radio nzValue="NonGazPay">Welfare & S.G </label>
                      <label nz-radio nzValue="GazPay">Gaz Pay Bill</label>
                    </nz-radio-group>
                  </nz-form-item>
                </div> -->
                <!-- <nz-form-item>
                  <nz-form-label nzNoColon>File No.</nz-form-label>

                  <nz-form-control nzHasFeedback>
                    <nz-select
                      *ngIf="
                        orderdata.FILE_NUMBER == undefined ||
                        orderdata.FILE_NUMBER == null
                      "
                      nzShowSearch
                      name="FILE_NUMBER"
                      [nzOptionHeightPx]="60"
                      [(ngModel)]="orderdata.FILE_ID"
                    >
                      <nz-option
                        *ngFor="let option of fileList"
                        [nzLabel]="option.FILE_NUMBER"
                        [nzValue]="option.ID"
                        nzCustomContent
                      >
                        <div style="background-color: #fffcfc; padding: 5px">
                          <div nz-row>
                            <div
                              nz-col
                              nzSpan="24"
                              style="width: 100%; color: black; font-size: 14px"
                            >
                              <b>{{ option.FILE_NAME }} </b>
                            </div>

                            <div
                              nz-col
                              nzSpan="24"
                              style="font-size: 14px; font-weight: 500"
                            >
                              {{ option.FILE_NUMBER }}
                            </div>
                          </div>
                        </div>
                      </nz-option>
                    </nz-select>
                    <input
                      style="width: 100%"
                      nz-input
                      type="text"
                      *ngIf="
                        orderdata.FILE_NUMBER != undefined &&
                        orderdata.FILE_NUMBER != null
                      "
                      name="FILE_NUMBERsafsf"
                      [(ngModel)]="orderdata.FILE_NUMBER"
                      disabled="true"
                    />
                  </nz-form-control>
                </nz-form-item> -->
              </div>
            </div>
            <div nz-row>
              <div nz-col nzSpan="24">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>Signature</nz-form-label>
                  <nz-form-control>
                    <nz-select
                      name="userid"
                      [(ngModel)]="orderdata.SIGNATURE_ID"
                      nzAllowClear
                      nzPlaceHolder="Choose Signature"
                      (ngModelChange)="signature($event)"
                    >
                      <nz-option
                        *ngFor="let user of Signaturearray"
                        [nzValue]="user.ID"
                        [nzLabel]="user.NAME"
                      >
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>

            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Order Number</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Order no. is required"
                  >
                    <input
                      style="width: 100%"
                      name="OrderNumber"
                      nz-input
                      placeholder="Enter order number"
                      type="text"
                      [(ngModel)]="orderdata.ORDER_NO"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzNoColon>Name of applicant</nz-form-label>

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Name of applicant is required"
                  >
                    <input
                      style="width: 100%"
                      name="EMPLOYEE_NAME"
                      nz-input
                      placeholder="Enter name in english"
                      autocomplete="off"
                      type="text"
                      disabled="true"
                      [(ngModel)]="orderdata.EMPLOYEE_NAME"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Name of applicant (in hindi)</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="City Name is required"
                  >
                    <input
                      style="width: 100%"
                      nz-input
                      placeholder="Enter name in hindi"
                      autocomplete="off"
                      name="EMPLOYEE_NAME_HN"
                      type="text"
                      [(ngModel)]="orderdata.EMPLOYEE_NAME_HN"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzNoColon>Designation</nz-form-label>

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Enter Designation in English"
                  >
                    <input
                      style="width: 100%"
                      name="DESIGNATION"
                      nz-input
                      placeholder="Enter Designation in English"
                      autocomplete="off"
                      type="text"
                      disabled="true"
                      [(ngModel)]="orderdata.DESIGNATION"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Designation (in hindi)</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Enter Designation In Hindi"
                  >
                    <input
                      style="width: 100%"
                      nz-input
                      placeholder="Enter Designation in hindi"
                      autocomplete="off"
                      name="DESIGNATION_NAME_HN"
                      type="text"
                      [(ngModel)]="orderdata.DESIGNATION_NAME_HN"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>

            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzNoColon>Office Name</nz-form-label>

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Enter Office Name in English"
                  >
                    <input
                      style="width: 100%"
                      name="OFFICE_NAME"
                      nz-input
                      placeholder="Enter Office Name in English"
                      autocomplete="off"
                      type="text"
                      disabled="true"
                      [(ngModel)]="orderdata.OFFICE_NAME"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Office Name (in hindi)</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Enter Office Name In Hindi"
                  >
                    <input
                      style="width: 100%"
                      nz-input
                      placeholder="Enter Office Name in hindi"
                      autocomplete="off"
                      name="OFFICE_NAME_HN"
                      type="text"
                      [(ngModel)]="orderdata.OFFICE_NAME_HN"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>

            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzNoColon>Name of patient </nz-form-label>

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Name of patient is required"
                  >
                    <input
                      style="width: 100%"
                      name="PATIENT_NAME"
                      nz-input
                      placeholder="Enter name in english"
                      autocomplete="off"
                      type="text"
                      disabled="true"
                      [(ngModel)]="orderdata.PATIENT_NAME"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Cheque drawn in form of</nz-form-label>
                <nz-form-control >
                  <nz-switch
                    [(ngModel)]="orderdata.CHEQUE_DRAWN"
                    name="CHEQUE_DRAWN"
                    nzCheckedChildren="Yes"
                    nzUnCheckedChildren="No"
                  >
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
              </div>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
                *ngIf="orderdata.CHEQUE_DRAWN==1"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >ZAO Hindi Remark</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Enter ZAO Hindi Remark"
                  >
                    <input
                      style="width: 100%"
                      nz-input
                      placeholder="Enter ZAO Hindi Remark"
                      autocomplete="off"
                      name="ZAO_HN"
                      type="text"
                      [(ngModel)]="orderdata.ZAO_HN"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
                *ngIf="orderdata.CHEQUE_DRAWN==1"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >ZAO English Remark</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Enter ZAO English Remark"
                  >
                    <input
                      style="width: 100%"
                      nz-input
                      placeholder="Enter ZAO English Remark"
                      autocomplete="off"
                      name="ZAO"
                      type="text"
                      [(ngModel)]="orderdata.ZAO"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Name of patient(in hindi)</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Name of patient(in hindi) is required"
                  >
                    <input
                      style="width: 100%"
                      nz-input
                      placeholder="Enter name of patient in hindi"
                      autocomplete="off"
                      name="PATIENT_NAME_HN"
                      type="text"
                      [(ngModel)]="orderdata.PATIENT_NAME_HN"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
          <div *ngFor="let data of HospitalMapping; let i = index">
            <div class="my-box">
              <div nz-row>
                <div nz-col nzSpan="24">
                  <nz-form-item>
                    <nz-form-label nzNoColon> Name of Hospital </nz-form-label>

                    <nz-form-control
                      nzHasFeedback
                      nzErrorTip="Name of Hospital is required"
                    >
                      <input
                        style="width: 100%"
                        name="NAMENAME{{ i }}"
                        nz-input
                        placeholder="Enter name in english"
                        autocomplete="off"
                        type="text"
                        disabled="true"
                        [(ngModel)]="data.NAME"
                      />
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
              <div nz-row>
                <div
                  nz-col
                  nzXs="24"
                  nzSm="24"
                  nzMd="24"
                  nzLg="24"
                  nzXl="24"
                  nzSpan="24"
                >
                  <nz-form-item>
                    <nz-form-label nzRequired nzNoColon
                      >Name of Hospital(in hindi)</nz-form-label
                    >

                    <nz-form-control
                      nzHasFeedback
                      nzErrorTip="Name of Hospital(in hindi) is required"
                    >
                      <input
                        style="width: 100%"
                        nz-input
                        placeholder="Enter name of Hospital in hindi"
                        autocomplete="off"
                        name="NAME_HN{{ i }}"
                        type="text"
                        [(ngModel)]="data.NAME_HN"
                      />
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>

              <div nz-row>
                <div
                  nz-col
                  nzXs="24"
                  nzSm="24"
                  nzMd="24"
                  nzLg="24"
                  nzXl="24"
                  nzSpan="24"
                >
                  <nz-form-item>
                    <nz-form-label nzNoColon
                      >Address of Hospital
                    </nz-form-label>

                    <nz-form-control
                      nzHasFeedback
                      nzErrorTip="Name of Address is required"
                    >
                      <input
                        style="width: 100%"
                        name="ADDRESS{{ i }}"
                        nz-input
                        placeholder="Enter Address in english"
                        autocomplete="off"
                        type="text"
                        [(ngModel)]="data.ADDRESS"
                      />
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
              <div nz-row>
                <div
                  nz-col
                  nzXs="24"
                  nzSm="24"
                  nzMd="24"
                  nzLg="24"
                  nzXl="24"
                  nzSpan="24"
                >
                  <nz-form-item>
                    <nz-form-label nzRequired nzNoColon
                      >Address of Hospital(in hindi)</nz-form-label
                    >

                    <nz-form-control
                      nzHasFeedback
                      nzErrorTip="Address of Hospital(in hindi) is required"
                    >
                      <input
                        style="width: 100%"
                        nz-input
                        placeholder="Enter Address of Hospital in hindi"
                        autocomplete="off"
                        name="ADDRESS_HN{{ i }}"
                        type="text"
                        [(ngModel)]="data.ADDRESS_HN"
                      />
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>

              <!-- <div>
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Show In Order</nz-form-label
                  >
                  <nz-form-control>
                    <nz-switch
                      name="IS_VISIBLE_IN_ORDER{{ i }}"
                      nzCheckedChildren="Yes"
                      nzUnCheckedChildren="No"
                      [(ngModel)]="HospitalMapping[i].IS_VISIBLE_IN_ORDER"
                    ></nz-switch>
                  </nz-form-control>
                </nz-form-item>
              </div> -->
            </div>
          </div>
          <!-- <div nz-row *ngIf="orderdata.HOSPITAL_TYPE == 'E'">
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzNoColon>Name of Hospital </nz-form-label>

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Name of patient is required"
                  >
                    <input
                      style="width: 100%"
                      name="EMPANELLED_HOSPITAL_NAME"
                      nz-input
                      placeholder="Enter name in english"
                      autocomplete="off"
                      type="text"
                      disabled="true"
                      [(ngModel)]="orderdata.EMPANELLED_HOSPITAL_NAME"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div> -->
          <div class="my-box">
            <div nz-row>
              <div
                nz-col
                nzXs="12"
                nzSm="12"
                nzMd="12"
                nzLg="12"
                nzXl="12"
                nzSpan="12"
              >
                <nz-form-item>
                  <nz-form-label nzNoColon>Realationship</nz-form-label>

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Realationship is required"
                  >
                    <input
                      style="width: 100%"
                      nz-input
                      placeholder="Enter Realationship"
                      autocomplete="off"
                      name="RELATION_WITH_PATIENT"
                      disabled="true"
                      type="text"
                      [(ngModel)]="orderdata.RELATION_WITH_PATIENT"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <!-- <div
                nz-col
                nzXs="12"
                nzSm="12"
                nzMd="12"
                nzLg="12"
                nzXl="12"
                nzSpan="12"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Realationship(in hindi)</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Realationship(in hindi) is required"
                  >
                    <input
                      style="width: 100%"
                      nz-input
                      placeholder="Enter Realationship in hindi"
                      autocomplete="off"
                      name="RELATION_WITH_PATIENT_MR"
                      type="text"
                      [(ngModel)]="orderdata.RELATIONSHIP_HN"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div> -->
            </div>
          </div>
          <!-- <div nz-row>
                            <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" nzSpan="24">
                                <nz-form-item>
                                    <nz-form-label  nzNoColon>Amount (in words)</nz-form-label>

                                    <nz-form-control nzHasFeedback nzErrorTip="City Name is required">
                                        <input style="width: 100%;" nz-input placeholder="Enter Amount"
                                            autocomplete="off"  name="amountinwords" type="text"
                                            [(ngModel)]="amountinwords" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                           
                        </div>
                        <div nz-row>
                            <div  nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" nzSpan="24">
                                <nz-form-item>
                                    <nz-form-label nzRequired nzNoColon>Amount (in hindi)</nz-form-label>

                                    <nz-form-control nzHasFeedback nzErrorTip="City Name is required">
                                        <input style="width: 100%;" nz-input placeholder="Enter amount in hindi"
                                            autocomplete="off" name="amountinwordsh"  type="text"
                                            [(ngModel)]="amountinwordsh" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div> -->
        </div>

        <div nz-col nzXs="16" nzSm="16" nzMd="16" nzLg="16" nzXl="16">
          <div
            class="my-box"
            style="padding: 10px; height: 590px; overflow-y: scroll"
          >
            <div>
              <div nz-row>
                <div nz-col nzXs="2" nzSm="2" nzMd="2" nzLg="2" nzXl="2">
                  <div style="text-align: right">
                    <img
                      src="assets/govtOfIndia.png"
                      alt=""
                      height="140%"
                      width="140%"
                    />
                  </div>
                </div>

                <div nz-col nzXs="22" nzSm="22" nzMd="22" nzLg="22" nzXl="22">
                  <div
                    style="width: 100%; text-align: center; font-weight: bold"
                  >
                    <div>भारत सरकार</div>
                    <div style="text-transform: uppercase">
                      Government Of India
                    </div>
                    <div>
                      कार्यालय , आयकर आयुक्त (प्रशासन एवं टीपीएस), मुंबई
                    </div>
                    <div style="text-transform: uppercase">
                      Office Of The Commissioner of Income-Tax (Admin, & TPS)
                    </div>
                    <div>
                      तीसरी मंजिल, आयकर भवन, महर्षी कर्वे रोड, मुंबई -20
                    </div>
                    <div style="text-transform: uppercase">
                      3<sup style="text-transform: lowercase">rd</sup> Floor,
                      aayakar bhavan, Maharshi Karve Road, Mumbai-20
                    </div>
                  </div>
                </div>
              </div>

              <div
                style="
                  width: 100%;
                  border: 1px solid black;
                  padding: 5px;
                  margin-top: 10px;
                  font-size: 13px;
                "
              >
                <div nz-row>
                  <div nz-col nzSpan="12">{{ orderdata.ORDER_NO }}</div>

                  <div nz-col nzSpan="12" style="text-align: right">
                    दिनांक
                    :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                  <!-- {{ orderdata.ORDER_DATE | date : "dd MMM yyyy" }} -->
                </div>
              </div>
              <div
                style="font-family: Arial, Helvetica, sans-serif !important"
                *ngIf="orderdata.IS_ADVANCE_TAKEN == 0"
              >
                <div
                  style="
                    text-align: center;
                    margin-top: 20px;
                    margin-bottom: 10px;
                    font-size: 13px;
                  "
                >
                  <b>स्वीकृति आदेश/ SANCTION ORDER</b>
                </div>
                <div style="font-size: 13px !important">
                  <p style="margin-bottom: 2px">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {{ orderdata.EMPLOYEE_NAME_HN }}
                    {{ orderdata.DESIGNATION_NAME_HN }}, को उनके द्वारा दिनांक
                    {{
                      orderdata.TREATMENT_START_DATE | date : "dd MMM yyyy"
                    }}&nbsp; से
                    {{
                      orderdata.TREATMENT_END_DATE | date : "dd MMM yyyy"
                    }}&nbsp; को

                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                      {{ data.NAME_HN }} , {{ data.ADDRESS_HN }}
                      <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                      <span *ngIf="HospitalMapping.length - 2 == k">और</span>
                      <!-- </span> -->
                    </span>

                    में
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Father'"
                        >उनके पिता</span
                      >
                      <!-- <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Father'
                        "
                        >उनके दादाजी</span
                      > -->
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Wife'"
                        >उनकी पत्नी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Husband'"
                        >उनके पती</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Son'"
                        >उनका बेटा</span
                      >
                      <span
                        *ngIf="orderdata.RELATION_WITH_PATIENT == 'Daughter'"
                        >उनकी बेटी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Sister'"
                        >उनकी बहन</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Brother'"
                        >उनके भाई</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'"
                        >उनकी मां</span
                      >
                      <!-- <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Mother'
                        "
                        >उनकी दादी मां</span
                      > -->
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Father-In-Law'
                        "
                        >उनके ससुर</span
                      >
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Mother-In-Law'
                        "
                        >उनकी सासू मां</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} के</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} के</span
                      >
                    </span>

                    &nbsp;
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      स्वयं के</span
                    >
                    {{ orderdata.NATURE_OF_TREATMENT }}
                    के इलाज के लिए खर्च की गई राशि,
                    <span
                      >जो
                      <b>
                        {{
                          orderdata.ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-&nbsp; ({{ admissibleinhindi }})</b
                      >
                      से अधिक नहीं है,</span
                    >
                    को सीजीएचएस दर के अनुसार चिकित्सा व्यय की प्रतिपूर्ती के लिए
                    स्वीकृति प्रदान की जाती है।
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CG'"
                      >यह मंजूरी स्वास्थ्य एवं परिवार कल्याण मंत्रालय, भारत
                      सरकार द्वारा जारी दिनांक 18.03.1992 के कार्यालय ज्ञापन सं.
                      एस-11012/1/91/सीजीएचएस (पी) खंड 1 के माध्यम से मुझे
                      प्रदत्त शक्तियो का प्रयोग करते हुए जारी की जाती है।</span
                    >
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CS'">
                      यह मंजूरी आदेश सेंट्रल सर्विसेस (मेडिकल अटेनडंस),
                      नियमावली, 1944 के परिशिष्ठ । के प्रावधानों के अनुसार जारी
                      किया जाता है।
                    </span>
                  </p>

                  <p style="margin-bottom: 2px">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sanction is
                    hereby accorded for the reimbursement of medical expenses as
                    per CGHS rate for an
                    <span>
                      amount not exceeding
                      <b>
                        {{
                          orderdata.ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-&nbsp; ({{ admissibleineng }})</b
                      >
                    </span>
                     incurred by {{ orderdata.EMPLOYEE_NAME }}&nbsp;,
                    {{ orderdata.DESIGNATION }}
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      for his/her {{ orderdata.RELATION_WITH_PATIENT }}
                      {{ orderdata.PATIENT_NAME }}</span
                    >

                    &nbsp; for&nbsp;{{ orderdata.NATURE_OF_TREATMENT }}
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      of Self</span
                    >, at
                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                      {{ data.NAME }} ,{{ data.ADDRESS }}
                      <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                      <span *ngIf="HospitalMapping.length - 2 == k">and</span>
                      <!-- </span> -->
                    </span>

                    from
                    {{ orderdata.TREATMENT_START_DATE | date : "dd MMM yyyy" }}
                    to
                    {{ orderdata.TREATMENT_END_DATE | date : "dd MMM yyyy" }}.
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CG'">
                      This sanction is issued in exercise of the powers vested
                      in me vide Office Memorandum No.S-11012/1/91/CGHS(P) Vol.
                      I dated 18.03.1992 issued by the Ministry of Health &
                      Family Welfare , Government of India.</span
                    >
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CS'">
                      This sanction is issued in accordance with the provisions
                      of Appendix VIII of the Central Services (Medical
                      Attendance) Rules, 1944.
                    </span>
                  </p>

                  <p
                    style="margin-bottom: 15px; margin-top: 10px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    यह राशि डिस्चार्ज की तारीख से एक महीने के भीतर श्री
                    {{ orderdata.EMPLOYEE_NAME_HN }} द्वारा प्रस्तुत किए जाने
                    वाले नियमित दावों के खिलाफ समायोजित की जाएगी।
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    This amount will be adjusted against regular claims ,
                    within one month from the date of discharge. to be submitted
                    by {{ orderdata.EMPLOYEE_NAME }}.
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    उक्त व्यय को वित्तीय वर्ष 2022-2023 के लिए विभाग को आबंटित
                    मुख्य शीर्ष "2021 चिकित्सा" के अंतर्गत अनुदान के नामे डाला
                    जाए।
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    The above expenditure should be debited to the grant under
                    the major head "2021 - Medical allotted to the Department
                    for Financial Year 2022-23.
                  </p>

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 0
                    "
                  >
                    यह आपात स्थिति का मामला था, जिसके लिए तत्काल उपचार की
                    आवश्यकता थी अतः गैर-पैनलबद्ध अस्पताल में भर्ती कराया गया था|
                  </p>

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 0
                    "
                  >
                    It was a case of emergency , necessitating immediate
                    treatment and was admitted in a non-empanelled hospital.
                  </p>

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 1 &&
                        orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 0 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 1 &&
                        orderdata.IS_HOSPITAL_EMPLANELLED == 0 &&
                        orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 0 &&
                        orderdata.EMERGENCY_TREATEMENT == 0) ||
                      orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1
                    "
                  >
                    दिनांक
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0">
                      {{ orderdata.EXPO_FACTO_DATE }}
                    </span>
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1">
                      {{ orderdata.HOD_PERMISSION_DATE }}
                    </span>
                    के पत्र द्वारा उपरोक्त उपचार के लिये कार्योत्तर अनुमति
                    प्रदान की गई है।
                  </p>

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 1 &&
                        orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 0 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 1 &&
                        orderdata.IS_HOSPITAL_EMPLANELLED == 0 &&
                        orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 0 &&
                        orderdata.EMERGENCY_TREATEMENT == 0) ||
                      orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1
                    "
                  >
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0">
                      Expost Facto Permission for the above treatment has been
                      granted vide letter dated {{ orderdata.EXPO_FACTO_DATE }}
                    </span>
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1">
                      Permission for the above treatment has been granted vide
                      letter dated {{ orderdata.HOD_PERMISSION_DATE }}
                    </span>
                  </p>
                  <!-- <p
                *ngIf="
                  orderdata.IS_BILL_FILLED_IN_TIME == 1 &&
                  orderdata.IS_HOSPITAL_EMPLANELLED == 1 &&
                  orderdata.EXPO_FACTO_PERMISSION == 1
                "
              >
                दिनांक 10/01/2023 के पत्र द्वारा उपरोक्त उपचार के लिए अनुमति
                प्रदान की गई है।
              </p>
              <p
                *ngIf="
                  orderdata.IS_BILL_FILLED_IN_TIME == 1 &&
                  orderdata.IS_HOSPITAL_EMPLANELLED == 1 &&
                  orderdata.EXPO_FACTO_PERMISSION == 1
                "
              >
                Permission for the above treatment has been granted vide letter
                dated 10/01/2023
              </p> -->

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 1
                    "
                  >
                    यह आपात स्थिति का मामला था जिसमें तत्काल प्रवेश की आवश्यकता
                    थी और उसे पैनलबद्ध अस्पताल में भर्ती कराया गया था.
                  </p>
                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 1
                    "
                  >
                    It was a case of Emergency necessitating immediate admission
                    and was admitted in empanelled hospital.
                  </p>
                  <p style="margin-bottom: 2px">
                    उक्त व्यथ को वित्तीय वर्ष 2023-2024 के लिए विभाग को आबंटित
                    मुख्य शीर्ष "2020-चिकित्सा उपचार" के अंतर्गत अनुदान के नामे
                    डाला जाए।
                  </p>

                  <p style="margin-bottom: 2px">
                    The above expenditure should be debited to the grant under
                    major head 2020 - Medical Treatment Alloted to the
                    Department for the
                    <b>F.Y 2023-2024</b>
                  </p>

                  <p style="margin-bottom: 2px">
                    हिंदी और अंग्रेजी संस्करण में किसी भी भिन्नता के मामले में
                    आदेश का अंग्रेजी संस्करण मान्य होगा।
                  </p>

                  <p style="margin-bottom: 2px">
                    The English version of the order will prevail in case of any
                    variation in Hindi & English version
                  </p>

                  <div style="margin-top: 60px; font-size: 13px">
                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div>(विनय सिन्हा)</div>
                        <div style="font-weight: bold">
                          आयकर आयुक्त (प्रशासन एवं टीपीएस) , मुंबई
                        </div>
                      </div>
                    </div>

                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div>(Vinay Sinha)</div>
                        <div style="font-weight: bold; font-size: 13px">
                          Commissioner Of Income Tax(Admin & TPS),Mumbai.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      style="
                        page-break-inside: avoid !important;
                        font-size: 13px;
                        page-break-before: avoid !important;
                      "
                    >
                      <div style="margin-top: 30px">
                        <b>प्रतिलिपी/Copy to:</b>
                      </div>
                      <div>
                        <span *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                          1)अराजपत्रित वेतन बिल, मुंबई (2 प्रतियां) फॉर्म एमआरसी
                          (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ
                        </span>
                      </div>
                      <div>
                        <span *ngIf="SECTION_TYPE == 'welfare'">
                          1) डीडीओ, कल्याण और एसजी,मुंबई (2 प्रतियां) फॉर्म
                          एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के
                          साथ|</span
                        >
                        <span *ngIf="SECTION_TYPE == 'gazpay'">
                          1) डीडीओ, राजपत्रित वेतन बिल अनुभाग II (कार्मिक),मुंबई
                          (2 प्रतियां) फॉर्म एमआरसी (एस), अस्पताल रसीद,
                          डिस्चार्ज सारांश आदि के साथ|</span
                        >
                        <!-- 1) डीडीओ,कल्याण और एसजी,मुंबई (2 प्रतियां)
                      फॉर्म एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ| -->
                      </div>
                      <div>
                        <!-- 1) DDO , Welfare & SG , Mumbai (2 copies) alongwith Form
                      MRC(S) , hospital receipts , discharge summary etc. -->

                        <span *ngIf="SECTION_TYPE == 'welfare'">
                          1) DDO , Welfare & SG , Mumbai (2 copies) alongwith
                          Form MRC(S) , hospital receipts , discharge summary
                          etc.
                        </span>
                        <span *ngIf="SECTION_TYPE == 'gazpay'">
                          1) DDO , Gazetted Pay bill Section II, Personnel
                          Mumbai (2 copies) alongwith Form MRC(S) , hospital
                          receipts , discharge summary etc.
                        </span>
                      </div>
                    </div>
                    <div
                      style="
                        page-break-inside: avoid !important;
                        font-size: 13px;
                      "
                    >

                    <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 0">
                      <div>
                        2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                        <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                        तैयार किया जा सकता है। |
                      </div>
                      <div>
                         ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                        in favour of {{ orderdata.EMPLOYEE_NAME }}.
                      </div>
                    </div>
                    <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                      <div  *ngIf="orderdata.CHEQUE_DRAWN==0">
                        2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                        <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                        तैयार किया जा सकता है। |
                      </div>
                      <div  *ngIf="orderdata.CHEQUE_DRAWN==0">
                         ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                        in favour of {{ orderdata.EMPLOYEE_NAME }}.
                      </div>
                      <div  *ngIf="orderdata.CHEQUE_DRAWN==1">
                        2) {{orderdata.ZAO_HN}}
                      </div>
                      <div  *ngIf="orderdata.CHEQUE_DRAWN==1">
                          {{orderdata.ZAO}}
                      </div>
                    </div>

                      <div>
                        3)<b>
                          {{ orderdata.EMPLOYEE_NAME_HN }},
                          {{ orderdata.DESIGNATION_NAME_HN }}
                          {{ orderdata.OFFICE_NAME_HN }}
                          <!-- {{ orderdata.LOCATION }}., -->
                        </b>
                        स्वीकार्य राशि की गणना की प्रति के साथ |
                      </div>

                      <div>
                        3)
                        <b
                          >{{ orderdata.EMPLOYEE_NAME }} ,
                          {{ orderdata.DESIGNATION }}
                          {{ orderdata.EMPLOYEE_OFFICE_NAME }}
                          <!-- {{ orderdata.LOCATION }}., -->
                        </b>
                        along with copy of working of admissible amount
                      </div>

                      <div style="margin-top: 60px">
                        <div nz-row>
                          <div nz-col nzSpan="15"></div>

                          <div nz-col nzSpan="9" style="text-align: center">
                            <div style="font-weight: bold">({{ NAME_HN }})</div>
                            <div>{{ POST_HN }}</div>
                            <div>{{ OFFICE_NAME_HN }}</div>
                            <div style="font-weight: bold">
                              ({{ SIGNNAME }})
                            </div>
                            <div>{{ POST }}</div>
                            <div>{{ OFFICE_NAME }}</div>
                            <!-- <span *ngIf="SECTION_TYPE == 'welfare'">
                              <div style="font-weight: bold">
                                (रीतेश कुमार)
                              </div>
                              <div>आयकर अधिकारी (मुख्‍यालय)</div>
                              <div>कल्याण और कर्मचारी शिकायत, मुंबई</div>
                            </span>

                            <span *ngIf="SECTION_TYPE == 'gazpay'">
                              <div style="font-weight: bold">
                                (जितेंद्र रा चांदेकर)
                              </div>
                              <div>आयकर अधिकारी</div>
                              <div>राजपत्रित वेतन बिल अनुभाग II, मुंबई</div>
                            </span> -->
                          </div>
                        </div>

                        <!-- <div nz-row>
                          <div nz-col nzSpan="15"></div>

                          <div nz-col nzSpan="9" style="text-align: center">
                            <span *ngIf="SECTION_TYPE == 'welfare'">
                              <div style="font-weight: bold">
                                (Ritesh Kumar)
                              </div>
                              <div>Income Tax Officer(HQ)</div>
                              <div>Welfare & S.G, Mumbai</div>
                            </span>
                            <span *ngIf="SECTION_TYPE == 'gazpay'">
                              <div style="font-weight: bold">
                                (Jitendra R Chandekar)
                              </div>
                              <div>Income Tax Officer</div>
                              <div>
                                Gazetted Pay Bill Section II Personnel, Mumbai
                              </div>
                            </span>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style="font-family: Arial, Helvetica, sans-serif !important"
                *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
              >
                <div
                  style="
                    text-align: center;
                    margin-top: 20px;
                    margin-bottom: 10px;
                    font-size: 13px;
                  "
                >
                  <b>स्वीकृति आदेश/ SANCTION ORDER</b>
                </div>
                <div style="font-size: 13px !important">
                  <p style="margin-bottom: 2px">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {{ orderdata.EMPLOYEE_NAME_HN }}
                    {{ orderdata.DESIGNATION_NAME_HN }}, को उनके द्वारा दिनांक
                    {{
                      orderdata.TREATMENT_START_DATE | date : "dd MMM yyyy"
                    }}&nbsp; से
                    {{
                      orderdata.TREATMENT_END_DATE | date : "dd MMM yyyy"
                    }}&nbsp; को

                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                      {{ data.NAME_HN }} , {{ data.ADDRESS_HN }}
                      <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                      <span *ngIf="HospitalMapping.length - 2 == k">और</span>
                      <!-- </span> -->
                    </span>

                    में
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Father'"
                        >उनके पिता</span
                      >
                      <!-- <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Father'
                        "
                        >उनके दादाजी</span
                      > -->
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Wife'"
                        >उनकी पत्नी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Husband'"
                        >उनके पती</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Son'"
                        >उनका बेटा</span
                      >
                      <span
                        *ngIf="orderdata.RELATION_WITH_PATIENT == 'Daughter'"
                        >उनकी बेटी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Sister'"
                        >उनकी बहन</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Brother'"
                        >उनके भाई</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'"
                        >उनकी मां</span
                      >
                      <!-- <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Mother'
                        "
                        >उनकी दादी मां</span
                      > -->
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Father-In-Law'
                        "
                        >उनके ससुर</span
                      >
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Mother-In-Law'
                        "
                        >उनकी सासू मां</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} के</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} के</span
                      >
                    </span>

                    &nbsp;
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      स्वयं के</span
                    >
                    {{ orderdata.NATURE_OF_TREATMENT }}
                    के इलाज के लिए खर्च की गई राशि,
                    <span *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                      >जो
                      <b
                        >{{
                          orderdata.ADVANCE_ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-&nbsp; ({{ advadmissibleinhindi }})</b
                      >
                      <b>
                        जो कि
                        {{
                          orderdata.ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-</b
                      >
                      की स्वीकार्य राशि का 90% (लगभग) से अधिक नहीं है,</span
                    >
                    को सीजीएचएस दर के अनुसार चिकित्सा व्यय की प्रतिपूर्ती के लिए
                    स्वीकृति प्रदान की जाती है।
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CG'"
                      >यह मंजूरी स्वास्थ्य एवं परिवार कल्याण मंत्रालय, भारत
                      सरकार द्वारा जारी दिनांक 18.03.1992 के कार्यालय ज्ञापन सं.
                      एस-11012/1/91/सीजीएचएस (पी) खंड 1 के माध्यम से मुझे
                      प्रदत्त शक्तियो का प्रयोग करते हुए जारी की जाती है।</span
                    >
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CS'">
                      यह मंजूरी आदेश सेंट्रल सर्विसेस (मेडिकल अटेनडंस),
                      नियमावली, 1944 के परिशिष्ठ । के प्रावधानों के अनुसार जारी
                      किया जाता है।
                    </span>
                  </p>

                  <p style="margin-bottom: 2px">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sanction is
                    hereby accorded for the reimbursement of medical expenses as
                    per CGHS rate for an
                    <span *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                      amount not exceeding
                      <b>
                        {{
                          orderdata.ADVANCE_ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-&nbsp; ({{ advadmissibleineng }})</b
                      >
                      being 90% (approximately) of the admissible amount of
                      <b>
                        {{
                          orderdata.ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-</b
                      >
                    </span>
                     incurred by {{ orderdata.EMPLOYEE_NAME }}&nbsp;,
                    {{ orderdata.DESIGNATION }}
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      for his/her {{ orderdata.RELATION_WITH_PATIENT }}
                      {{ orderdata.PATIENT_NAME }}</span
                    >

                    &nbsp; for&nbsp;{{ orderdata.NATURE_OF_TREATMENT }}
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      of Self</span
                    >, at
                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                      {{ data.NAME }} ,{{ data.ADDRESS }}
                      <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                      <span *ngIf="HospitalMapping.length - 2 == k">and</span>
                      <!-- </span> -->
                    </span>

                    from
                    {{ orderdata.TREATMENT_START_DATE | date : "dd MMM yyyy" }}
                    to
                    {{ orderdata.TREATMENT_END_DATE | date : "dd MMM yyyy" }}.
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CG'">
                      This sanction is issued in exercise of the powers vested
                      in me vide Office Memorandum No.S-11012/1/91/CGHS(P) Vol.
                      I dated 18.03.1992 issued by the Ministry of Health &
                      Family Welfare , Government of India.</span
                    >
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CS'">
                      This sanction is issued in accordance with the provisions
                      of Appendix VIII of the Central Services (Medical
                      Attendance) Rules, 1944.
                    </span>
                  </p>

                  <p
                    style="margin-bottom: 15px; margin-top: 10px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    यह राशि डिस्चार्ज की तारीख से एक महीने के भीतर श्री
                    {{ orderdata.EMPLOYEE_NAME_HN }} द्वारा प्रस्तुत किए जाने
                    वाले नियमित दावों के खिलाफ समायोजित की जाएगी।
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    This amount will be adjusted against regular claims ,
                    within one month from the date of discharge. to be submitted
                    by {{ orderdata.EMPLOYEE_NAME }}.
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    उक्त व्यय को वित्तीय वर्ष 2022-2023 के लिए विभाग को आबंटित
                    मुख्य शीर्ष "2021 चिकित्सा" के अंतर्गत अनुदान के नामे डाला
                    जाए।
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    The above expenditure should be debited to the grant under
                    the major head "2021 - Medical allotted to the Department
                    for Financial Year 2022-23.
                  </p>

                  <div style="margin-top: 60px; font-size: 13px">
                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div>(विनय सिन्हा)</div>
                        <div style="font-weight: bold">
                          आयकर आयुक्त (प्रशासन एवं टीपीएस) , मुंबई
                        </div>
                      </div>
                    </div>

                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div>(Vinay Sinha)</div>
                        <div style="font-weight: bold; font-size: 13px">
                          Commissioner Of Income Tax(Admin & TPS),Mumbai.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      style="
                        page-break-inside: avoid !important;
                        font-size: 13px;
                        page-break-before: avoid !important;
                      "
                    >
                      <div style="margin-top: 30px">
                        <b>प्रतिलिपी/Copy to:</b>
                      </div>
                      <div>
                        <span *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                          1)अराजपत्रित वेतन बिल, मुंबई (2 प्रतियां) फॉर्म एमआरसी
                          (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ
                        </span>
                      </div>
                      <div>
                        <span *ngIf="SECTION_TYPE == 'welfare'">
                          1) डीडीओ, कल्याण और एसजी,मुंबई (2 प्रतियां) फॉर्म
                          एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के
                          साथ|</span
                        >
                        <span *ngIf="SECTION_TYPE == 'gazpay'">
                          1) डीडीओ, राजपत्रित वेतन बिल अनुभाग II (कार्मिक),मुंबई
                          (2 प्रतियां) फॉर्म एमआरसी (एस), अस्पताल रसीद,
                          डिस्चार्ज सारांश आदि के साथ|</span
                        >
                        <!-- 1) डीडीओ,कल्याण और एसजी,मुंबई (2 प्रतियां)
                      फॉर्म एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ| -->
                      </div>
                      <div>
                        <!-- 1) DDO , Welfare & SG , Mumbai (2 copies) alongwith Form
                      MRC(S) , hospital receipts , discharge summary etc. -->

                        <span *ngIf="SECTION_TYPE == 'welfare'">
                          1) DDO , Welfare & SG , Mumbai (2 copies) alongwith
                          Form MRC(S) , hospital receipts , discharge summary
                          etc.
                        </span>
                        <span *ngIf="SECTION_TYPE == 'gazpay'">
                          1) DDO , Gazetted Pay bill Section II, Personnel
                          Mumbai (2 copies) alongwith Form MRC(S) , hospital
                          receipts , discharge summary etc.
                        </span>
                      </div>
                    </div>
                    <div
                      style="
                        page-break-inside: avoid !important;
                        font-size: 13px;
                      "
                    >
                      <!-- <div>
                        2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                        <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                        तैयार किया जा सकता है। |
                      </div>
                      <div>
                         ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                        in favour of {{ orderdata.EMPLOYEE_NAME }}.
                      </div> -->
                      <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 0">
                        <div>
                          2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                          <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                          तैयार किया जा सकता है। |
                        </div>
                        <div style="padding-left: 15px">
                           ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                          in favour of {{ orderdata.EMPLOYEE_NAME }}.
                        </div>
                      </div>
                      <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                        <div  *ngIf="orderdata.CHEQUE_DRAWN==0">
                          2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                          <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                          तैयार किया जा सकता है। |
                        </div>
                        <div style="padding-left: 15px"  *ngIf="orderdata.CHEQUE_DRAWN==0">
                           ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                          in favour of {{ orderdata.EMPLOYEE_NAME }}.
                        </div>
                        <div  *ngIf="orderdata.CHEQUE_DRAWN==1">
                          2) {{orderdata.ZAO_HN}}
                        </div>
                        <div style="padding-left: 15px"  *ngIf="orderdata.CHEQUE_DRAWN==1">
                            {{orderdata.ZAO}}
                        </div>
                      </div>

                      <div>
                        3)<b>
                          {{ orderdata.EMPLOYEE_NAME_HN }},
                          {{ orderdata.DESIGNATION_NAME_HN }}
                          {{ orderdata.OFFICE_NAME_HN }}
                          <!-- {{ orderdata.LOCATION }}., -->
                        </b>
                        स्वीकार्य राशि की गणना की प्रति के साथ |
                      </div>

                      <div>
                        3)
                        <b
                          >{{ orderdata.EMPLOYEE_NAME }} ,
                          {{ orderdata.DESIGNATION }}
                          {{ orderdata.EMPLOYEE_OFFICE_NAME }}
                          <!-- {{ orderdata.LOCATION }}., -->
                        </b>
                        along with copy of working of admissible amount
                      </div>

                      <div style="margin-top: 60px">
                        <div nz-row>
                          <div nz-col nzSpan="15"></div>

                          <div nz-col nzSpan="9" style="text-align: center">
                            <div style="font-weight: bold">({{ NAME_HN }})</div>
                            <div>{{ POST_HN }}</div>
                            <div>{{ OFFICE_NAME_HN }}</div>
                            <div style="font-weight: bold">
                              ({{ SIGNNAME }})
                            </div>
                            <div>{{ POST }}</div>
                            <div>{{ OFFICE_NAME }}</div>
                            <!-- <span *ngIf="SECTION_TYPE == 'welfare'">
                              <div style="font-weight: bold">
                                (रीतेश कुमार)
                              </div>
                              <div>आयकर अधिकारी (मुख्‍यालय)</div>
                              <div>कल्याण और कर्मचारी शिकायत, मुंबई</div>
                            </span>

                            <span *ngIf="SECTION_TYPE == 'gazpay'">
                              <div style="font-weight: bold">
                                (जितेंद्र रा चांदेकर)
                              </div>
                              <div>आयकर अधिकारी</div>
                              <div>राजपत्रित वेतन बिल अनुभाग II, मुंबई</div>
                            </span> -->
                          </div>
                        </div>

                        <!-- <div nz-row>
                          <div nz-col nzSpan="15"></div>

                          <div nz-col nzSpan="9" style="text-align: center">
                            <span *ngIf="SECTION_TYPE == 'welfare'">
                              <div style="font-weight: bold">
                                (Ritesh Kumar)
                              </div>
                              <div>Income Tax Officer(HQ)</div>
                              <div>Welfare & S.G, Mumbai</div>
                            </span>
                            <span *ngIf="SECTION_TYPE == 'gazpay'">
                              <div style="font-weight: bold">
                                (Jitendra R Chandekar)
                              </div>
                              <div>Income Tax Officer</div>
                              <div>
                                Gazetted Pay Bill Section II Personnel, Mumbai
                              </div>
                            </span>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>
  </form>

  <div class="footer">
    <button
      type="button"
      [nzLoading]="isSpinning"
      class="ant-btn ant-btn-primary"
      nz-button
      (click)="pakagedata()"
    >
      <span>Save & Preview</span>
    </button>
    &nbsp;&nbsp;

    <button
      type="button"
      nz-button
      class="ant-btn"
      style="margin-right: 8px"
      (click)="close()"
    >
      <span>Cancel</span>
    </button>

    <!-- <button nz-button nzType="primary"  (click)="pakagedata()">
            Save and Next
        </button> -->

    <!-- <button
      type="button"
      class="ant-btn ant-btn-primary"
      nz-button
      (click)="openPrintOrderModal()"
    >
      <span>Preview</span>
    </button> -->
  </div>
</div>

<div *ngIf="orderdata != undefined && Order == 'LO'">
  <form nz-form>
    <nz-spin [nzSpinning]="isSpinning">
      <div nz-row [nzGutter]="10">
        <div
          nz-col
          nzXs="8"
          nzSm="8"
          nzMd="8"
          nzLg="8"
          nzXl="8"
          nzSpan="8"
          style="height: 590px; overflow-y: scroll"
        >
          <div class="my-box">
            <div nz-row>
              <div
                style="display: none"
                nz-col
                nzXs="12"
                nzSm="12"
                nzMd="12"
                nzLg="12"
                nzXl="12"
                nzSpan="12"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>Date</nz-form-label>

                  <nz-form-control>
                    <nz-date-picker
                      style="width: 100%"
                      nzFormat="dd/MM/yyyy"
                      name="printedDate"
                      [(ngModel)]="orderdata.ORDER_DATE"
                    >
                    </nz-date-picker>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <!-- <div nz-col nzXs="12" nzSm="12" nzMd="12" nzLg="12" nzXl="12" nzSpan="12">
                                <nz-form-item>
                                    <nz-form-label nzRequired nzNoColon>From Date</nz-form-label>

                                    <nz-form-control>
                                        <nz-date-picker style="width: 100%;" nzFormat="dd/MM/yyyy" name="fromDate"
                                            [(ngModel)]="FROM_DATE">
                                        </nz-date-picker>
                                    </nz-form-control>
                                </nz-form-item>
                            </div> -->

              <!-- <div nz-col nzXs="12" nzSm="12" nzMd="12" nzLg="12" nzXl="12" nzSpan="12">
                                <nz-form-item>
                                    <nz-form-label nzRequired nzNoColon>To Date</nz-form-label>

                                    <nz-form-control>
                                        <nz-date-picker style="width: 100%;" nzFormat="dd/MM/yyyy" name="toDate"
                                            [(ngModel)]="TO_DATE">
                                        </nz-date-picker>
                                    </nz-form-control>
                                </nz-form-item>
                            </div> -->
            </div>

            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <!-- <div>
                  <nz-form-item>
                    <nz-form-label  nzNoColon>File No.</nz-form-label>  
                    <nz-radio-group
                      [(ngModel)]="orderdata.PAY_BILL"
                      name="radiogaz"
                    >
                      <label nz-radio nzValue="NonGazPay">Welfare & S.G </label>
                      <label nz-radio nzValue="GazPay">Gaz Pay Bill</label>
                    </nz-radio-group>
                  </nz-form-item>
                </div> -->
                <!-- <nz-form-item>
                  <nz-form-label nzNoColon>File No.</nz-form-label>

                  <nz-form-control nzHasFeedback>
                    <nz-select
                      *ngIf="
                        orderdata.FILE_NUMBER == undefined ||
                        orderdata.FILE_NUMBER == null
                      "
                      nzShowSearch
                      name="FILE_NUMBER"
                      [nzOptionHeightPx]="60"
                      [(ngModel)]="orderdata.FILE_ID"
                    >
                      <nz-option
                        *ngFor="let option of fileList"
                        [nzLabel]="option.FILE_NUMBER"
                        [nzValue]="option.ID"
                        nzCustomContent
                      >
                        <div style="background-color: #fffcfc; padding: 5px">
                          <div nz-row>
                            <div
                              nz-col
                              nzSpan="24"
                              style="width: 100%; color: black; font-size: 14px"
                            >
                              <b>{{ option.FILE_NAME }} </b>
                            </div>

                            <div
                              nz-col
                              nzSpan="24"
                              style="font-size: 14px; font-weight: 500"
                            >
                              {{ option.FILE_NUMBER }}
                            </div>
                          </div>
                        </div>
                      </nz-option>
                    </nz-select>
                    <input
                      style="width: 100%"
                      nz-input
                      type="text"
                      *ngIf="
                        orderdata.FILE_NUMBER != undefined &&
                        orderdata.FILE_NUMBER != null
                      "
                      name="FILE_NUMBERsafsf"
                      [(ngModel)]="orderdata.FILE_NUMBER"
                      disabled="true"
                    />
                  </nz-form-control>
                </nz-form-item> -->
              </div>
            </div>
            <div nz-row>
              <div nz-col nzSpan="24">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>Signature</nz-form-label>
                  <nz-form-control>
                    <nz-select
                      name="userid"
                      [(ngModel)]="orderdata.SIGNATURE_ID"
                      nzAllowClear
                      nzPlaceHolder="Choose Signature"
                      (ngModelChange)="signature($event)"
                    >
                      <nz-option
                        *ngFor="let user of Signaturearray"
                        [nzValue]="user.ID"
                        [nzLabel]="user.NAME"
                      >
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>

            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Order Number</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Order no. is required"
                  >
                    <input
                      style="width: 100%"
                      name="OrderNumber"
                      nz-input
                      placeholder="Enter order number"
                      type="text"
                      [(ngModel)]="orderdata.ORDER_NO"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzNoColon>Name of applicant</nz-form-label>

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Name of applicant is required"
                  >
                    <input
                      style="width: 100%"
                      name="EMPLOYEE_NAME"
                      nz-input
                      placeholder="Enter name in english"
                      autocomplete="off"
                      type="text"
                      disabled="true"
                      [(ngModel)]="orderdata.EMPLOYEE_NAME"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Name of applicant (in hindi)</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="City Name is required"
                  >
                    <input
                      style="width: 100%"
                      nz-input
                      placeholder="Enter name in hindi"
                      autocomplete="off"
                      name="EMPLOYEE_NAME_HN"
                      type="text"
                      [(ngModel)]="orderdata.EMPLOYEE_NAME_HN"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzNoColon>Designation</nz-form-label>

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Enter Designation in English"
                  >
                    <input
                      style="width: 100%"
                      name="DESIGNATION"
                      nz-input
                      placeholder="Enter Designation in English"
                      autocomplete="off"
                      type="text"
                      disabled="true"
                      [(ngModel)]="orderdata.DESIGNATION"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Designation (in hindi)</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Enter Designation In Hindi"
                  >
                    <input
                      style="width: 100%"
                      nz-input
                      placeholder="Enter Designation in hindi"
                      autocomplete="off"
                      name="DESIGNATION_NAME_HN"
                      type="text"
                      [(ngModel)]="orderdata.DESIGNATION_NAME_HN"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>

            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzNoColon>Office Name</nz-form-label>

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Enter Office Name in English"
                  >
                    <input
                      style="width: 100%"
                      name="OFFICE_NAME"
                      nz-input
                      placeholder="Enter Office Name in English"
                      autocomplete="off"
                      type="text"
                      disabled="true"
                      [(ngModel)]="orderdata.OFFICE_NAME"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Office Name (in hindi)</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Enter Office Name In Hindi"
                  >
                    <input
                      style="width: 100%"
                      nz-input
                      placeholder="Enter Office Name in hindi"
                      autocomplete="off"
                      name="OFFICE_NAME_HN"
                      type="text"
                      [(ngModel)]="orderdata.OFFICE_NAME_HN"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>

            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzNoColon>Name of patient </nz-form-label>

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Name of patient is required"
                  >
                    <input
                      style="width: 100%"
                      name="PATIENT_NAME"
                      nz-input
                      placeholder="Enter name in english"
                      autocomplete="off"
                      type="text"
                      disabled="true"
                      [(ngModel)]="orderdata.PATIENT_NAME"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Name of patient(in hindi)</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Name of patient(in hindi) is required"
                  >
                    <input
                      style="width: 100%"
                      nz-input
                      placeholder="Enter name of patient in hindi"
                      autocomplete="off"
                      name="PATIENT_NAME_HN"
                      type="text"
                      [(ngModel)]="orderdata.PATIENT_NAME_HN"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Cheque drawn in form of</nz-form-label>
                <nz-form-control >
                  <nz-switch
                    [(ngModel)]="orderdata.CHEQUE_DRAWN"
                    name="CHEQUE_DRAWN"
                    nzCheckedChildren="Yes"
                    nzUnCheckedChildren="No"
                  >
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
              </div>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
                *ngIf="orderdata.CHEQUE_DRAWN==1"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >ZAO Hindi Remark</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Enter ZAO Hindi Remark"
                  >
                    <input
                      style="width: 100%"
                      nz-input
                      placeholder="Enter ZAO Hindi Remark"
                      autocomplete="off"
                      name="ZAO_HN"
                      type="text"
                      [(ngModel)]="orderdata.ZAO_HN"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
                *ngIf="orderdata.CHEQUE_DRAWN==1"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >ZAO English Remark</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Enter ZAO English Remark"
                  >
                    <input
                      style="width: 100%"
                      nz-input
                      placeholder="Enter ZAO English Remark"
                      autocomplete="off"
                      name="ZAO"
                      type="text"
                      [(ngModel)]="orderdata.ZAO"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
          <div *ngFor="let data of HospitalMapping; let i = index">
            <div class="my-box">
              <div nz-row>
                <div nz-col nzSpan="24">
                  <nz-form-item>
                    <nz-form-label nzNoColon> Name of Hospital </nz-form-label>

                    <nz-form-control
                      nzHasFeedback
                      nzErrorTip="Name of Hospital is required"
                    >
                      <input
                        style="width: 100%"
                        name="NAMENAME{{ i }}"
                        nz-input
                        placeholder="Enter name in english"
                        autocomplete="off"
                        type="text"
                        disabled="true"
                        [(ngModel)]="data.NAME"
                      />
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
              <div nz-row>
                <div
                  nz-col
                  nzXs="24"
                  nzSm="24"
                  nzMd="24"
                  nzLg="24"
                  nzXl="24"
                  nzSpan="24"
                >
                  <nz-form-item>
                    <nz-form-label nzRequired nzNoColon
                      >Name of Hospital(in hindi)</nz-form-label
                    >

                    <nz-form-control
                      nzHasFeedback
                      nzErrorTip="Name of Hospital(in hindi) is required"
                    >
                      <input
                        style="width: 100%"
                        nz-input
                        placeholder="Enter name of Hospital in hindi"
                        autocomplete="off"
                        name="NAME_HN{{ i }}"
                        type="text"
                        [(ngModel)]="data.NAME_HN"
                      />
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>

              <div nz-row>
                <div
                  nz-col
                  nzXs="24"
                  nzSm="24"
                  nzMd="24"
                  nzLg="24"
                  nzXl="24"
                  nzSpan="24"
                >
                  <nz-form-item>
                    <nz-form-label nzNoColon
                      >Address of Hospital
                    </nz-form-label>

                    <nz-form-control
                      nzHasFeedback
                      nzErrorTip="Name of Address is required"
                    >
                      <input
                        style="width: 100%"
                        name="ADDRESS{{ i }}"
                        nz-input
                        placeholder="Enter Address in english"
                        autocomplete="off"
                        type="text"
                        [(ngModel)]="data.ADDRESS"
                      />
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
              <div nz-row>
                <div
                  nz-col
                  nzXs="24"
                  nzSm="24"
                  nzMd="24"
                  nzLg="24"
                  nzXl="24"
                  nzSpan="24"
                >
                  <nz-form-item>
                    <nz-form-label nzRequired nzNoColon
                      >Address of Hospital(in hindi)</nz-form-label
                    >

                    <nz-form-control
                      nzHasFeedback
                      nzErrorTip="Address of Hospital(in hindi) is required"
                    >
                      <input
                        style="width: 100%"
                        nz-input
                        placeholder="Enter Address of Hospital in hindi"
                        autocomplete="off"
                        name="ADDRESS_HN{{ i }}"
                        type="text"
                        [(ngModel)]="data.ADDRESS_HN"
                      />
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>

              <!-- <div>
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Show In Order</nz-form-label
                  >
                  <nz-form-control>
                    <nz-switch
                      name="IS_VISIBLE_IN_ORDER{{ i }}"
                      nzCheckedChildren="Yes"
                      nzUnCheckedChildren="No"
                      [(ngModel)]="HospitalMapping[i].IS_VISIBLE_IN_ORDER"
                    ></nz-switch>
                  </nz-form-control>
                </nz-form-item>
              </div> -->
            </div>
          </div>
          <!-- <div nz-row *ngIf="orderdata.HOSPITAL_TYPE == 'E'">
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzNoColon>Name of Hospital </nz-form-label>

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Name of patient is required"
                  >
                    <input
                      style="width: 100%"
                      name="EMPANELLED_HOSPITAL_NAME"
                      nz-input
                      placeholder="Enter name in english"
                      autocomplete="off"
                      type="text"
                      disabled="true"
                      [(ngModel)]="orderdata.EMPANELLED_HOSPITAL_NAME"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div> -->
          <div class="my-box">
            <div nz-row>
              <div
                nz-col
                nzXs="12"
                nzSm="12"
                nzMd="12"
                nzLg="12"
                nzXl="12"
                nzSpan="12"
              >
                <nz-form-item>
                  <nz-form-label nzNoColon>Realationship</nz-form-label>

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Realationship is required"
                  >
                    <input
                      style="width: 100%"
                      nz-input
                      placeholder="Enter Realationship"
                      autocomplete="off"
                      name="RELATION_WITH_PATIENT"
                      disabled="true"
                      type="text"
                      [(ngModel)]="orderdata.RELATION_WITH_PATIENT"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <!-- <div
                nz-col
                nzXs="12"
                nzSm="12"
                nzMd="12"
                nzLg="12"
                nzXl="12"
                nzSpan="12"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Realationship(in hindi)</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Realationship(in hindi) is required"
                  >
                    <input
                      style="width: 100%"
                      nz-input
                      placeholder="Enter Realationship in hindi"
                      autocomplete="off"
                      name="RELATION_WITH_PATIENT_MR"
                      type="text"
                      [(ngModel)]="orderdata.RELATIONSHIP_HN"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div> -->
            </div>
          </div>
          <!-- <div nz-row>
                            <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" nzSpan="24">
                                <nz-form-item>
                                    <nz-form-label  nzNoColon>Amount (in words)</nz-form-label>

                                    <nz-form-control nzHasFeedback nzErrorTip="City Name is required">
                                        <input style="width: 100%;" nz-input placeholder="Enter Amount"
                                            autocomplete="off"  name="amountinwords" type="text"
                                            [(ngModel)]="amountinwords" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                           
                        </div>
                        <div nz-row>
                            <div  nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" nzSpan="24">
                                <nz-form-item>
                                    <nz-form-label nzRequired nzNoColon>Amount (in hindi)</nz-form-label>

                                    <nz-form-control nzHasFeedback nzErrorTip="City Name is required">
                                        <input style="width: 100%;" nz-input placeholder="Enter amount in hindi"
                                            autocomplete="off" name="amountinwordsh"  type="text"
                                            [(ngModel)]="amountinwordsh" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div> -->
        </div>

        <div nz-col nzXs="16" nzSm="16" nzMd="16" nzLg="16" nzXl="16">
          <div
            class="my-box"
            style="padding: 10px; height: 590px; overflow-y: scroll"
          >
            <div>
              <div nz-row>
                <div
                  style="border: 1px solid"
                  nz-col
                  nzXs="4"
                  nzSm="4"
                  nzMd="4"
                  nzLg="4"
                  nzXl="4"
                >
                  <div style="text-align: center; padding: 36px 9px 9px 9px">
                    <img
                      src="assets/govtOfIndia.png"
                      alt=""
                      height="140px"
                      width="100%"
                    />
                  </div>
                </div>
                <!-- <div
                  style="
                    border-bottom: 1px solid;
                    border-top: 1px solid;
                    border-right: 1px solid;
                  "
                  nz-col
                  nzXs="1"
                  nzSm="1"
                  nzMd="1"
                  nzLg="1"
                  nzXl="1"
                ></div> -->
                <div
                  style="
                    border-bottom: 1px solid;
                    border-top: 1px solid;
                    border-right: 1px solid;
                  "
                  nz-col
                  nzXs="20"
                  nzSm="20"
                  nzMd="20"
                  nzLg="20"
                  nzXl="20"
                >
                  <div
                    style="width: 100%; text-align: center; font-weight: bold"
                  >
                    <div>भारत सरकार</div>
                    <div style="text-transform: uppercase">
                      Government Of India
                    </div>
                    <div>कार्यालय, प्रधान मुख्य आयकर आयुक्त, मुंबई</div>
                    <div style="text-transform: uppercase">OFFICE OF THE</div>
                    <div style="text-transform: uppercase">
                      PRINCIPAL CHIEF COMMISSIONER OF INCOME TAX,
                    </div>
                    <div style="text-transform: uppercase">MUMBAI</div>
                    <!-- <div>
                      तीसरी मंजिल, आयकर भवन, महर्षी कर्वे रोड, मुंबई -20
                    </div> -->
                    <div style="text-transform: uppercase">
                      3<sup style="text-transform: lowercase">rd</sup> Floor,
                      aayakar bhavan, Maharshi Karve Road, Mumbai - 400020
                    </div>
                    <div>
                      (022) - 22016691/22077187 / Fax: 022- 22079273/22077187
                    </div>
                    <div>Email: mumbai.ito.hq.pers@incometax.gov.in</div>
                  </div>
                </div>
              </div>

              <div
                style="
                  width: 100%;
                  padding: 5px;
                  font-size: 13px;
                  border-bottom: 1px solid;
                  border-left: 1px solid;
                  border-right: 1px solid;
                "
              >
                <div nz-row>
                  <div nz-col nzSpan="12">{{ orderdata.ORDER_NO }}</div>

                  <div nz-col nzSpan="12" style="text-align: right">
                    दिनांक
                    :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                  <!-- {{ orderdata.ORDER_DATE | date : "dd MMM yyyy" }} -->
                </div>
              </div>
              <div
                style="font-family: Arial, Helvetica, sans-serif !important"
                *ngIf="orderdata.IS_ADVANCE_TAKEN == 0"
              >
                <div
                  style="
                    text-align: center;
                    margin-top: 20px;
                    margin-bottom: 10px;
                    font-size: 13px;
                  "
                >
                  <b>स्वीकृति आदेश/ SANCTION ORDER</b>
                </div>
                <div style="font-size: 13px !important">
                  <p style="margin-bottom: 2px">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {{ orderdata.EMPLOYEE_NAME_HN }}
                    {{ orderdata.DESIGNATION_NAME_HN }}, को उनके द्वारा दिनांक
                    {{
                      orderdata.TREATMENT_START_DATE | date : "dd MMM yyyy"
                    }}&nbsp; से
                    {{
                      orderdata.TREATMENT_END_DATE | date : "dd MMM yyyy"
                    }}&nbsp; को

                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                      {{ data.NAME_HN }} , {{ data.ADDRESS_HN }}
                      <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                      <span *ngIf="HospitalMapping.length - 2 == k">और</span>
                      <!-- </span> -->
                    </span>

                    में
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Father'"
                        >उनके पिता</span
                      >
                      <!-- <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Father'
                        "
                        >उनके दादाजी</span
                      > -->
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Wife'"
                        >उनकी पत्नी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Husband'"
                        >उनके पती</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Son'"
                        >उनका बेटा</span
                      >
                      <span
                        *ngIf="orderdata.RELATION_WITH_PATIENT == 'Daughter'"
                        >उनकी बेटी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Sister'"
                        >उनकी बहन</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Brother'"
                        >उनके भाई</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'"
                        >उनकी मां</span
                      >
                      <!-- <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Mother'
                        "
                        >उनकी दादी मां</span
                      > -->
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Father-In-Law'
                        "
                        >उनके ससुर</span
                      >
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Mother-In-Law'
                        "
                        >उनकी सासू मां</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} के</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} के</span
                      >
                    </span>

                    &nbsp;
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      स्वयं के</span
                    >
                    {{ orderdata.NATURE_OF_TREATMENT }}
                    के इलाज के लिए खर्च की गई राशि,
                    <span
                      >जो
                      <b>
                        {{
                          orderdata.ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-&nbsp; ({{ admissibleinhindi }})</b
                      >
                      से अधिक नहीं है,</span
                    >
                    को सीजीएचएस दर के अनुसार चिकित्सा व्यय की प्रतिपूर्ती के लिए
                    स्वीकृति प्रदान की जाती है।
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CG'"
                      >यह मंजूरी स्वास्थ्य एवं परिवार कल्याण मंत्रालय, भारत
                      सरकार द्वारा जारी दिनांक 18.03.1992 के कार्यालय ज्ञापन सं.
                      एस-11012/1/91/सीजीएचएस (पी) खंड 1 के माध्यम से मुझे
                      प्रदत्त शक्तियो का प्रयोग करते हुए जारी की जाती है।</span
                    >
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CS'">
                      यह मंजूरी आदेश सेंट्रल सर्विसेस (मेडिकल अटेनडंस),
                      नियमावली, 1944 के परिशिष्ठ । के प्रावधानों के अनुसार जारी
                      किया जाता है।
                    </span>
                  </p>

                  <p style="margin-bottom: 2px">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sanction is
                    hereby accorded for the reimbursement of medical expenses as
                    per CGHS rate for an
                    <span>
                      amount not exceeding
                      <b>
                        {{
                          orderdata.ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-&nbsp; ({{ admissibleineng }})</b
                      >
                    </span>
                     incurred by {{ orderdata.EMPLOYEE_NAME }}&nbsp;,
                    {{ orderdata.DESIGNATION }}
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      for his/her {{ orderdata.RELATION_WITH_PATIENT }}
                      {{ orderdata.PATIENT_NAME }}</span
                    >

                    &nbsp; for&nbsp;{{ orderdata.NATURE_OF_TREATMENT }}
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      of Self</span
                    >, at
                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                      {{ data.NAME }} ,{{ data.ADDRESS }}
                      <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                      <span *ngIf="HospitalMapping.length - 2 == k">and</span>
                      <!-- </span> -->
                    </span>

                    from
                    {{ orderdata.TREATMENT_START_DATE | date : "dd MMM yyyy" }}
                    to
                    {{ orderdata.TREATMENT_END_DATE | date : "dd MMM yyyy" }}.
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CG'">
                      This sanction is issued in exercise of the powers vested
                      in me vide Office Memorandum No.S-11012/1/91/CGHS(P) Vol.
                      I dated 18.03.1992 issued by the Ministry of Health &
                      Family Welfare , Government of India.</span
                    >
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CS'">
                      This sanction is issued in accordance with the provisions
                      of Appendix VIII of the Central Services (Medical
                      Attendance) Rules, 1944.
                    </span>
                  </p>
                  <br />

                  <p
                    style="margin-bottom: 15px; margin-top: 10px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    यह राशि डिस्चार्ज की तारीख से एक महीने के भीतर श्री
                    {{ orderdata.EMPLOYEE_NAME_HN }} द्वारा प्रस्तुत किए जाने
                    वाले नियमित दावों के खिलाफ समायोजित की जाएगी।
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    This amount will be adjusted against regular claims ,
                    within one month from the date of discharge. to be submitted
                    by {{ orderdata.EMPLOYEE_NAME }}.
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    उक्त व्यय को वित्तीय वर्ष 2022-2023 के लिए विभाग को आबंटित
                    मुख्य शीर्ष "2021 चिकित्सा" के अंतर्गत अनुदान के नामे डाला
                    जाए।
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    The above expenditure should be debited to the grant under
                    the major head "2021 - Medical allotted to the Department
                    for Financial Year 2022-23.
                  </p>

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 0
                    "
                  >
                    यह आपात स्थिति का मामला था, जिसके लिए तत्काल उपचार की
                    आवश्यकता थी अतः गैर-पैनलबद्ध अस्पताल में भर्ती कराया गया था|
                  </p>

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 0
                    "
                  >
                    It was a case of emergency , necessitating immediate
                    treatment and was admitted in a non-empanelled hospital.
                  </p>

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 1 &&
                        orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 0 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 1 &&
                        orderdata.IS_HOSPITAL_EMPLANELLED == 0 &&
                        orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 0 &&
                        orderdata.EMERGENCY_TREATEMENT == 0) ||
                      orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1
                    "
                  >
                    दिनांक
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0">
                      {{ orderdata.EXPO_FACTO_DATE }}
                    </span>
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1">
                      {{ orderdata.HOD_PERMISSION_DATE }}
                    </span>
                    के पत्र द्वारा उपरोक्त उपचार के लिये कार्योत्तर अनुमति
                    प्रदान की गई है।
                  </p>

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 1 &&
                        orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 0 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 1 &&
                        orderdata.IS_HOSPITAL_EMPLANELLED == 0 &&
                        orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 0 &&
                        orderdata.EMERGENCY_TREATEMENT == 0) ||
                      orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1
                    "
                  >
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0">
                      Expost Facto Permission for the above treatment has been
                      granted vide letter dated {{ orderdata.EXPO_FACTO_DATE }}
                    </span>
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1">
                      Permission for the above treatment has been granted vide
                      letter dated {{ orderdata.HOD_PERMISSION_DATE }}
                    </span>
                  </p>
                  <!-- <p
                *ngIf="
                  orderdata.IS_BILL_FILLED_IN_TIME == 1 &&
                  orderdata.IS_HOSPITAL_EMPLANELLED == 1 &&
                  orderdata.EXPO_FACTO_PERMISSION == 1
                "
              >
                दिनांक 10/01/2023 के पत्र द्वारा उपरोक्त उपचार के लिए अनुमति
                प्रदान की गई है।
              </p>
              <p
                *ngIf="
                  orderdata.IS_BILL_FILLED_IN_TIME == 1 &&
                  orderdata.IS_HOSPITAL_EMPLANELLED == 1 &&
                  orderdata.EXPO_FACTO_PERMISSION == 1
                "
              >
                Permission for the above treatment has been granted vide letter
                dated 10/01/2023
              </p> -->

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 1
                    "
                  >
                    यह आपात स्थिति का मामला था जिसमें तत्काल प्रवेश की आवश्यकता
                    थी और उसे पैनलबद्ध अस्पताल में भर्ती कराया गया था.
                  </p>
                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 1
                    "
                  >
                    It was a case of Emergency necessitating immediate admission
                    and was admitted in empanelled hospital.
                  </p>
                  <p style="margin-bottom: 2px">
                    उक्त व्यय को वित्तीय वर्ष 2023-2024 के लिए विभाग को आबंटित
                    अनुदान के अंतर्गत मुख्य शीर्ष "2020-चिकित्सा उपचार" से किया
                    जाए।
                  </p>

                  <p style="margin-bottom: 2px">
                    The above expenditure should be debited to the grant under
                    major head 2020 - Medical Treatment Alloted to the
                    Department for the
                    <b>F.Y 2023-2024</b>
                  </p>
                  <br />

                  <p style="margin-bottom: 2px">
                    हिंदी और अंग्रेजी संस्करण में किसी भी भिन्नता के मामले में
                    आदेश का अंग्रेजी संस्करण मान्य होगा।
                  </p>
                  <br />

                  <p style="margin-bottom: 2px">
                    The English version of the order will prevail in case of any
                    variation in Hindi & English version
                  </p>

                  <div style="margin-top: 60px; font-size: 13px">
                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div>(विनय सिन्हा)</div>
                        <div style="font-weight: bold">
                          आयकर आयुक्त (प्रशासन एवं टीपीएस) , मुंबई
                        </div>
                      </div>
                    </div>

                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div>(Vinay Sinha)</div>
                        <div style="font-weight: bold; font-size: 13px">
                          Commissioner Of Income Tax(Admin & TPS),Mumbai.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      style="
                        page-break-inside: avoid !important;
                        font-size: 13px;
                        page-break-before: avoid !important;
                      "
                    >
                      <div style="margin-top: 30px">
                        <b>प्रतिलिपी/Copy to:</b>
                      </div>
                      <div>
                        <span *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                          1)अराजपत्रित वेतन बिल, मुंबई (2 प्रतियां) फॉर्म एमआरसी
                          (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ
                        </span>
                      </div>
                      <div>
                        <span *ngIf="SECTION_TYPE == 'welfare'">
                          1) डीडीओ, कल्याण और एसजी,मुंबई (2 प्रतियां) फॉर्म
                          एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के
                          साथ|</span
                        >
                        <span *ngIf="SECTION_TYPE == 'gazpay'">
                          1) डीडीओ, राजपत्रित वेतन बिल अनुभाग II (कार्मिक),मुंबई
                          (2 प्रतियां) फॉर्म एमआरसी (एस), अस्पताल रसीद,
                          डिस्चार्ज सारांश आदि के साथ|</span
                        >
                        <!-- 1) डीडीओ,कल्याण और एसजी,मुंबई (2 प्रतियां)
                      फॉर्म एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ| -->
                      </div>
                      <div>
                        <!-- 1) DDO , Welfare & SG , Mumbai (2 copies) alongwith Form
                      MRC(S) , hospital receipts , discharge summary etc. -->

                        <span
                          *ngIf="SECTION_TYPE == 'welfare'"
                          style="padding-left: 15px"
                        >
                          DDO , Welfare & SG , Mumbai (2 copies) alongwith Form
                          MRC(S) , hospital receipts , discharge summary etc.
                        </span>
                        <span
                          *ngIf="SECTION_TYPE == 'gazpay'"
                          style="padding-left: 15px"
                        >
                          DDO , Gazetted Pay bill Section II, Personnel Mumbai
                          (2 copies) alongwith Form MRC(S) , hospital receipts ,
                          discharge summary etc.
                        </span>
                      </div>
                    </div>
                    <div
                      style="
                        page-break-inside: avoid !important;
                        font-size: 13px;
                      "
                    >
                    <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 0">
                      <div>
                        2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                        <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                        तैयार किया जा सकता है। |
                      </div>
                      <div>
                         ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                        in favour of {{ orderdata.EMPLOYEE_NAME }}.
                      </div>
                    </div>
                    <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                      <div  *ngIf="orderdata.CHEQUE_DRAWN==0">
                        2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                        <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                        तैयार किया जा सकता है। |
                      </div>
                      <div  *ngIf="orderdata.CHEQUE_DRAWN==0">
                         ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                        in favour of {{ orderdata.EMPLOYEE_NAME }}.
                      </div>
                      <div  *ngIf="orderdata.CHEQUE_DRAWN==1">
                        2) {{orderdata.ZAO_HN}}
                      </div>
                      <div  *ngIf="orderdata.CHEQUE_DRAWN==1">
                          {{orderdata.ZAO}}
                      </div>
                    </div>

                      <div>
                        3)<b>
                          {{ orderdata.EMPLOYEE_NAME_HN }},
                          {{ orderdata.DESIGNATION_NAME_HN }}
                          {{ orderdata.OFFICE_NAME_HN }}
                          <!-- {{ orderdata.LOCATION_HN }}., -->
                        </b>
                        स्वीकार्य राशि की गणना की प्रति के साथ |
                      </div>

                      <div style="padding-left: 15px">
                        <b
                          >{{ orderdata.EMPLOYEE_NAME }} ,
                          {{ orderdata.DESIGNATION }}
                          {{ orderdata.EMPLOYEE_OFFICE_NAME }}
                          <!-- {{ orderdata.LOCATION }}., -->
                        </b>
                        along with copy of working of admissible amount
                      </div>

                      <div style="margin-top: 60px">
                        <div nz-row>
                          <div nz-col nzSpan="15"></div>

                          <div nz-col nzSpan="9" style="text-align: center">
                            <div style="font-weight: bold">({{ NAME_HN }})</div>
                            <div>{{ POST_HN }}</div>
                            <div>{{ OFFICE_NAME_HN }}</div>
                            <div style="font-weight: bold">
                              ({{ SIGNNAME }})
                            </div>
                            <div>{{ POST }}</div>
                            <div>{{ OFFICE_NAME }}</div>
                            <!-- <span *ngIf="SECTION_TYPE == 'welfare'">
                              <div style="font-weight: bold">
                                (रीतेश कुमार)
                              </div>
                              <div>आयकर अधिकारी (मुख्‍यालय)</div>
                              <div>कल्याण और कर्मचारी शिकायत, मुंबई</div>
                            </span>

                            <span *ngIf="SECTION_TYPE == 'gazpay'">
                              <div style="font-weight: bold">
                                (जितेंद्र रा चांदेकर)
                              </div>
                              <div>आयकर अधिकारी</div>
                              <div>राजपत्रित वेतन बिल अनुभाग II, मुंबई</div>
                            </span> -->
                          </div>
                        </div>

                        <!-- <div nz-row>
                          <div nz-col nzSpan="15"></div>

                          <div nz-col nzSpan="9" style="text-align: center">
                            <span *ngIf="SECTION_TYPE == 'welfare'">
                              <div style="font-weight: bold">
                                (Ritesh Kumar)
                              </div>
                              <div>Income Tax Officer(HQ)</div>
                              <div>Welfare & S.G, Mumbai</div>
                            </span>
                            <span *ngIf="SECTION_TYPE == 'gazpay'">
                              <div style="font-weight: bold">
                                (Jitendra R Chandekar)
                              </div>
                              <div>Income Tax Officer</div>
                              <div>
                                Gazetted Pay Bill Section II Personnel, Mumbai
                              </div>
                            </span>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style="font-family: Arial, Helvetica, sans-serif !important"
                *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
              >
                <div
                  style="
                    text-align: center;
                    margin-top: 20px;
                    margin-bottom: 10px;
                    font-size: 13px;
                  "
                >
                  <b>स्वीकृति आदेश/ SANCTION ORDER</b>
                </div>
                <div style="font-size: 13px !important">
                  <p style="margin-bottom: 2px">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {{ orderdata.EMPLOYEE_NAME_HN }}
                    {{ orderdata.DESIGNATION_NAME_HN }}, को उनके द्वारा दिनांक
                    {{
                      orderdata.TREATMENT_START_DATE | date : "dd MMM yyyy"
                    }}&nbsp; से
                    {{
                      orderdata.TREATMENT_END_DATE | date : "dd MMM yyyy"
                    }}&nbsp; को

                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                      {{ data.NAME_HN }} , {{ data.ADDRESS_HN }}
                      <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                      <span *ngIf="HospitalMapping.length - 2 == k">और</span>
                      <!-- </span> -->
                    </span>

                    में
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Father'"
                        >उनके पिता</span
                      >
                      <!-- <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Father'
                        "
                        >उनके दादाजी</span
                      > -->
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Wife'"
                        >उनकी पत्नी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Husband'"
                        >उनके पती</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Son'"
                        >उनका बेटा</span
                      >
                      <span
                        *ngIf="orderdata.RELATION_WITH_PATIENT == 'Daughter'"
                        >उनकी बेटी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Sister'"
                        >उनकी बहन</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Brother'"
                        >उनके भाई</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'"
                        >उनकी मां</span
                      >
                      <!-- <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Mother'
                        "
                        >उनकी दादी मां</span
                      > -->
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Father-In-Law'
                        "
                        >उनके ससुर</span
                      >
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Mother-In-Law'
                        "
                        >उनकी सासू मां</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} के</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} के</span
                      >
                    </span>

                    &nbsp;
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      स्वयं के</span
                    >
                    {{ orderdata.NATURE_OF_TREATMENT }}
                    के इलाज के लिए खर्च की गई राशि,
                    <span *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                      >जो
                      <b
                        >{{
                          orderdata.ADVANCE_ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-&nbsp; ({{ advadmissibleinhindi }})</b
                      >
                      <b>
                        जो कि
                        {{
                          orderdata.ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-</b
                      >
                      की स्वीकार्य राशि का 90% (लगभग) से अधिक नहीं है,</span
                    >
                    को सीजीएचएस दर के अनुसार चिकित्सा व्यय की प्रतिपूर्ती के लिए
                    स्वीकृति प्रदान की जाती है।
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CG'"
                      >यह मंजूरी स्वास्थ्य एवं परिवार कल्याण मंत्रालय, भारत
                      सरकार द्वारा जारी दिनांक 18.03.1992 के कार्यालय ज्ञापन सं.
                      एस-11012/1/91/सीजीएचएस (पी) खंड 1 के माध्यम से मुझे
                      प्रदत्त शक्तियो का प्रयोग करते हुए जारी की जाती है।</span
                    >
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CS'">
                      यह मंजूरी आदेश सेंट्रल सर्विसेस (मेडिकल अटेनडंस),
                      नियमावली, 1944 के परिशिष्ठ । के प्रावधानों के अनुसार जारी
                      किया जाता है।
                    </span>
                  </p>

                  <p style="margin-bottom: 2px">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sanction is
                    hereby accorded for the reimbursement of medical expenses as
                    per CGHS rate for an
                    <span *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                      amount not exceeding
                      <b>
                        {{
                          orderdata.ADVANCE_ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-&nbsp; ({{ advadmissibleineng }})</b
                      >
                      being 90% (approximately) of the admissible amount of
                      <b>
                        {{
                          orderdata.ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-</b
                      >
                    </span>
                    to be incurred by {{ orderdata.EMPLOYEE_NAME }}&nbsp;,
                    {{ orderdata.DESIGNATION }}
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      for his/her {{ orderdata.RELATION_WITH_PATIENT }}
                      {{ orderdata.PATIENT_NAME }}</span
                    >

                    &nbsp; for&nbsp;{{ orderdata.NATURE_OF_TREATMENT }}
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      of Self</span
                    >, at
                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                      {{ data.NAME }} ,{{ data.ADDRESS }}
                      <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                      <span *ngIf="HospitalMapping.length - 2 == k">and</span>
                      <!-- </span> -->
                    </span>

                    from
                    {{ orderdata.TREATMENT_START_DATE | date : "dd MMM yyyy" }}
                    to
                    {{ orderdata.TREATMENT_END_DATE | date : "dd MMM yyyy" }}.
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CG'">
                      This sanction is issued in exercise of the powers vested
                      in me vide Office Memorandum No.S-11012/1/91/CGHS(P) Vol.
                      I dated 18.03.1992 issued by the Ministry of Health &
                      Family Welfare , Government of India.</span
                    >
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CS'">
                      This sanction is issued in accordance with the provisions
                      of Appendix VIII of the Central Services (Medical
                      Attendance) Rules, 1944.
                    </span>
                  </p>

                  <p
                    style="margin-bottom: 15px; margin-top: 10px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    यह राशि डिस्चार्ज की तारीख से एक महीने के भीतर श्री
                    {{ orderdata.EMPLOYEE_NAME_HN }} द्वारा प्रस्तुत किए जाने
                    वाले नियमित दावों के खिलाफ समायोजित की जाएगी।
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    This amount will be adjusted against regular claims ,
                    within one month from the date of discharge. to be submitted
                    by {{ orderdata.EMPLOYEE_NAME }}.
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    उक्त व्यय को वित्तीय वर्ष 2022-2023 के लिए विभाग को आबंटित
                    मुख्य शीर्ष "2021 चिकित्सा" के अंतर्गत अनुदान के नामे डाला
                    जाए।
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    The above expenditure should be debited to the grant under
                    the major head "2021 - Medical allotted to the Department
                    for Financial Year 2022-23.
                  </p>

                  <div style="margin-top: 60px; font-size: 13px">
                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div>(विनय सिन्हा)</div>
                        <div style="font-weight: bold">
                          आयकर आयुक्त (प्रशासन एवं टीपीएस) , मुंबई
                        </div>
                      </div>
                    </div>

                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div>(Vinay Sinha)</div>
                        <div style="font-weight: bold; font-size: 13px">
                          Commissioner Of Income Tax(Admin & TPS),Mumbai.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      style="
                        page-break-inside: avoid !important;
                        font-size: 13px;
                        page-break-before: avoid !important;
                      "
                    >
                      <div style="margin-top: 30px">
                        <b>प्रतिलिपी/Copy to:</b>
                      </div>
                      <div>
                        <span *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                          1)अराजपत्रित वेतन बिल, मुंबई (2 प्रतियां) फॉर्म एमआरसी
                          (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ
                        </span>
                      </div>
                      <div>
                        <span *ngIf="SECTION_TYPE == 'welfare'">
                          1) डीडीओ, कल्याण और एसजी,मुंबई (2 प्रतियां) फॉर्म
                          एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के
                          साथ|</span
                        >
                        <span *ngIf="SECTION_TYPE == 'gazpay'">
                          1) डीडीओ, राजपत्रित वेतन बिल अनुभाग II (कार्मिक),मुंबई
                          (2 प्रतियां) फॉर्म एमआरसी (एस), अस्पताल रसीद,
                          डिस्चार्ज सारांश आदि के साथ|</span
                        >
                        <!-- 1) डीडीओ,कल्याण और एसजी,मुंबई (2 प्रतियां)
                      फॉर्म एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ| -->
                      </div>
                      <div>
                        <!-- 1) DDO , Welfare & SG , Mumbai (2 copies) alongwith Form
                      MRC(S) , hospital receipts , discharge summary etc. -->

                        <span
                          *ngIf="SECTION_TYPE == 'welfare'"
                          style="padding-left: 15px"
                        >
                          DDO , Welfare & SG , Mumbai (2 copies) alongwith Form
                          MRC(S) , hospital receipts , discharge summary etc.
                        </span>
                        <span
                          *ngIf="SECTION_TYPE == 'gazpay'"
                          style="padding-left: 15px"
                        >
                          DDO , Gazetted Pay bill Section II, Personnel Mumbai
                          (2 copies) alongwith Form MRC(S) , hospital receipts ,
                          discharge summary etc.
                        </span>
                      </div>
                    </div>
                    <div
                      style="
                        page-break-inside: avoid !important;
                        font-size: 13px;
                      "
                    >
                      <!-- <div>
                        2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                        <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                        तैयार किया जा सकता है। |
                      </div>
                      <div style="padding-left: 15px">
                          ZAO , Pre-check unit , Mumbai - Cheque may be drawn in
                        favour of {{ orderdata.EMPLOYEE_NAME }}.
                      </div> -->
                      <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 0">
                        <div>
                          2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                          <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                          तैयार किया जा सकता है। |
                        </div>
                        <div >
                           ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                          in favour of {{ orderdata.EMPLOYEE_NAME }}.
                        </div>
                      </div>
                      <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                        <div  *ngIf="orderdata.CHEQUE_DRAWN==0">
                          2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                          <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                          तैयार किया जा सकता है। |
                        </div>
                        <div   *ngIf="orderdata.CHEQUE_DRAWN==0">
                           ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                          in favour of {{ orderdata.EMPLOYEE_NAME }}.
                        </div>
                        <div  *ngIf="orderdata.CHEQUE_DRAWN==1">
                          2) {{orderdata.ZAO_HN}}
                        </div>
                        <div   *ngIf="orderdata.CHEQUE_DRAWN==1">
                            {{orderdata.ZAO}}
                        </div>
                      </div>

                      <div>
                        3)<b>
                          {{ orderdata.EMPLOYEE_NAME_HN }},
                          {{ orderdata.DESIGNATION_NAME_HN }}
                          {{ orderdata.OFFICE_NAME_HN }}
                          <!-- {{ orderdata.LOCATION_HN }}., -->
                        </b>
                        स्वीकार्य राशि की गणना की प्रति के साथ |
                      </div>

                      <div style="padding-left: 15px">
                        <b
                          >{{ orderdata.EMPLOYEE_NAME }} ,
                          {{ orderdata.DESIGNATION }}
                          {{ orderdata.EMPLOYEE_OFFICE_NAME }}
                          <!-- {{ orderdata.LOCATION }}., -->
                        </b>
                        along with copy of working of admissible amount
                      </div>

                      <div style="margin-top: 60px">
                        <div nz-row>
                          <div nz-col nzSpan="15"></div>

                          <div nz-col nzSpan="9" style="text-align: center">
                            <div style="font-weight: bold">({{ NAME_HN }})</div>
                            <div>{{ POST_HN }}</div>
                            <div>{{ OFFICE_NAME_HN }}</div>
                            <div style="font-weight: bold">
                              ({{ SIGNNAME }})
                            </div>
                            <div>{{ POST }}</div>
                            <div>{{ OFFICE_NAME }}</div>
                            <!-- <span *ngIf="SECTION_TYPE == 'welfare'">
                              <div style="font-weight: bold">
                                (रीतेश कुमार)
                              </div>
                              <div>आयकर अधिकारी (मुख्‍यालय)</div>
                              <div>कल्याण और कर्मचारी शिकायत, मुंबई</div>
                            </span>

                            <span *ngIf="SECTION_TYPE == 'gazpay'">
                              <div style="font-weight: bold">
                                (जितेंद्र रा चांदेकर)
                              </div>
                              <div>आयकर अधिकारी</div>
                              <div>राजपत्रित वेतन बिल अनुभाग II, मुंबई</div>
                            </span> -->
                          </div>
                        </div>

                        <!-- <div nz-row>
                          <div nz-col nzSpan="15"></div>

                          <div nz-col nzSpan="9" style="text-align: center">
                            <span *ngIf="SECTION_TYPE == 'welfare'">
                              <div style="font-weight: bold">
                                (Ritesh Kumar)
                              </div>
                              <div>Income Tax Officer(HQ)</div>
                              <div>Welfare & S.G, Mumbai</div>
                            </span>
                            <span *ngIf="SECTION_TYPE == 'gazpay'">
                              <div style="font-weight: bold">
                                (Jitendra R Chandekar)
                              </div>
                              <div>Income Tax Officer</div>
                              <div>
                                Gazetted Pay Bill Section II Personnel, Mumbai
                              </div>
                            </span>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>
  </form>

  <div class="footer">
    <button
      type="button"
      [nzLoading]="isSpinning"
      class="ant-btn ant-btn-primary"
      nz-button
      (click)="pakagedataLatest()"
    >
      <span>Save & Preview</span>
    </button>
    &nbsp;&nbsp;

    <button
      type="button"
      nz-button
      class="ant-btn"
      style="margin-right: 8px"
      (click)="close()"
    >
      <span>Cancel</span>
    </button>

    <!-- <button nz-button nzType="primary"  (click)="pakagedata()">
            Save and Next
        </button> -->

    <!-- <button
      type="button"
      class="ant-btn ant-btn-primary"
      nz-button
      (click)="openPrintOrderModal()"
    >
      <span>Preview</span>
    </button> -->
  </div>
</div>

<div *ngIf="orderdata != undefined && Order == 'L'">
  <form nz-form>
    <nz-spin [nzSpinning]="isSpinning">
      <div nz-row [nzGutter]="10">
        <div
          nz-col
          nzXs="8"
          nzSm="8"
          nzMd="8"
          nzLg="8"
          nzXl="8"
          nzSpan="8"
          style="height: 590px; overflow-y: scroll"
        >
          <div class="my-box">
            <div nz-row>
              <div
                style="display: none"
                nz-col
                nzXs="12"
                nzSm="12"
                nzMd="12"
                nzLg="12"
                nzXl="12"
                nzSpan="12"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>Date</nz-form-label>

                  <nz-form-control>
                    <nz-date-picker
                      style="width: 100%"
                      nzFormat="dd/MM/yyyy"
                      name="printedDate"
                      [(ngModel)]="orderdata.ORDER_DATE"
                    >
                    </nz-date-picker>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <!-- <div nz-col nzXs="12" nzSm="12" nzMd="12" nzLg="12" nzXl="12" nzSpan="12">
                                <nz-form-item>
                                    <nz-form-label nzRequired nzNoColon>From Date</nz-form-label>

                                    <nz-form-control>
                                        <nz-date-picker style="width: 100%;" nzFormat="dd/MM/yyyy" name="fromDate"
                                            [(ngModel)]="FROM_DATE">
                                        </nz-date-picker>
                                    </nz-form-control>
                                </nz-form-item>
                            </div> -->

              <!-- <div nz-col nzXs="12" nzSm="12" nzMd="12" nzLg="12" nzXl="12" nzSpan="12">
                                <nz-form-item>
                                    <nz-form-label nzRequired nzNoColon>To Date</nz-form-label>

                                    <nz-form-control>
                                        <nz-date-picker style="width: 100%;" nzFormat="dd/MM/yyyy" name="toDate"
                                            [(ngModel)]="TO_DATE">
                                        </nz-date-picker>
                                    </nz-form-control>
                                </nz-form-item>
                            </div> -->
            </div>

            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <!-- <div>
                  <nz-form-item>
                    <nz-form-label  nzNoColon>File No.</nz-form-label>  
                    <nz-radio-group
                      [(ngModel)]="orderdata.PAY_BILL"
                      name="radiogaz"
                    >
                      <label nz-radio nzValue="NonGazPay">Welfare & S.G </label>
                      <label nz-radio nzValue="GazPay">Gaz Pay Bill</label>
                    </nz-radio-group>
                  </nz-form-item>
                </div> -->
                <!-- <nz-form-item>
                  <nz-form-label nzNoColon>File No.</nz-form-label>

                  <nz-form-control nzHasFeedback>
                    <nz-select
                      *ngIf="
                        orderdata.FILE_NUMBER == undefined ||
                        orderdata.FILE_NUMBER == null
                      "
                      nzShowSearch
                      name="FILE_NUMBER"
                      [nzOptionHeightPx]="60"
                      [(ngModel)]="orderdata.FILE_ID"
                    >
                      <nz-option
                        *ngFor="let option of fileList"
                        [nzLabel]="option.FILE_NUMBER"
                        [nzValue]="option.ID"
                        nzCustomContent
                      >
                        <div style="background-color: #fffcfc; padding: 5px">
                          <div nz-row>
                            <div
                              nz-col
                              nzSpan="24"
                              style="width: 100%; color: black; font-size: 14px"
                            >
                              <b>{{ option.FILE_NAME }} </b>
                            </div>

                            <div
                              nz-col
                              nzSpan="24"
                              style="font-size: 14px; font-weight: 500"
                            >
                              {{ option.FILE_NUMBER }}
                            </div>
                          </div>
                        </div>
                      </nz-option>
                    </nz-select>
                    <input
                      style="width: 100%"
                      nz-input
                      type="text"
                      *ngIf="
                        orderdata.FILE_NUMBER != undefined &&
                        orderdata.FILE_NUMBER != null
                      "
                      name="FILE_NUMBERsafsf"
                      [(ngModel)]="orderdata.FILE_NUMBER"
                      disabled="true"
                    />
                  </nz-form-control>
                </nz-form-item> -->
              </div>
            </div>
            <div nz-row>
              <div nz-col nzSpan="24">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>Signature</nz-form-label>
                  <nz-form-control>
                    <nz-select
                      name="userid"
                      [(ngModel)]="orderdata.SIGNATURE_ID"
                      nzAllowClear
                      nzPlaceHolder="Choose Signature"
                      (ngModelChange)="signature($event)"
                    >
                      <nz-option
                        *ngFor="let user of Signaturearray"
                        [nzValue]="user.ID"
                        [nzLabel]="user.NAME"
                      >
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>

            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Order Number</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Order no. is required"
                  >
                    <input
                      style="width: 100%"
                      name="OrderNumber"
                      nz-input
                      placeholder="Enter order number"
                      type="text"
                      [(ngModel)]="orderdata.ORDER_NO"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzNoColon>Name of applicant</nz-form-label>

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Name of applicant is required"
                  >
                    <input
                      style="width: 100%"
                      name="EMPLOYEE_NAME"
                      nz-input
                      placeholder="Enter name in english"
                      autocomplete="off"
                      type="text"
                      disabled="true"
                      [(ngModel)]="orderdata.EMPLOYEE_NAME"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Name of applicant (in hindi)</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="City Name is required"
                  >
                    <input
                      style="width: 100%"
                      nz-input
                      placeholder="Enter name in hindi"
                      autocomplete="off"
                      name="EMPLOYEE_NAME_HN"
                      type="text"
                      [(ngModel)]="orderdata.EMPLOYEE_NAME_HN"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzNoColon>Designation</nz-form-label>

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Enter Designation in English"
                  >
                    <input
                      style="width: 100%"
                      name="DESIGNATION"
                      nz-input
                      placeholder="Enter Designation in English"
                      autocomplete="off"
                      type="text"
                      disabled="true"
                      [(ngModel)]="orderdata.DESIGNATION"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Designation (in hindi)</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Enter Designation In Hindi"
                  >
                    <input
                      style="width: 100%"
                      nz-input
                      placeholder="Enter Designation in hindi"
                      autocomplete="off"
                      name="DESIGNATION_NAME_HN"
                      type="text"
                      [(ngModel)]="orderdata.DESIGNATION_NAME_HN"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>

            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzNoColon>Office Name</nz-form-label>

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Enter Office Name in English"
                  >
                    <input
                      style="width: 100%"
                      name="OFFICE_NAME"
                      nz-input
                      placeholder="Enter Office Name in English"
                      autocomplete="off"
                      type="text"
                      disabled="true"
                      [(ngModel)]="orderdata.OFFICE_NAME"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Office Name (in hindi)</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Enter Office Name In Hindi"
                  >
                    <input
                      style="width: 100%"
                      nz-input
                      placeholder="Enter Office Name in hindi"
                      autocomplete="off"
                      name="OFFICE_NAME_HN"
                      type="text"
                      [(ngModel)]="orderdata.OFFICE_NAME_HN"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>

            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzNoColon>Name of patient </nz-form-label>

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Name of patient is required"
                  >
                    <input
                      style="width: 100%"
                      name="PATIENT_NAME"
                      nz-input
                      placeholder="Enter name in english"
                      autocomplete="off"
                      type="text"
                      disabled="true"
                      [(ngModel)]="orderdata.PATIENT_NAME"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Name of patient(in hindi)</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Name of patient(in hindi) is required"
                  >
                    <input
                      style="width: 100%"
                      nz-input
                      placeholder="Enter name of patient in hindi"
                      autocomplete="off"
                      name="PATIENT_NAME_HN"
                      type="text"
                      [(ngModel)]="orderdata.PATIENT_NAME_HN"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Cheque drawn in form of</nz-form-label>
                <nz-form-control >
                  <nz-switch
                    [(ngModel)]="orderdata.CHEQUE_DRAWN"
                    name="CHEQUE_DRAWN"
                    nzCheckedChildren="Yes"
                    nzUnCheckedChildren="No"
                  >
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
              </div>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
                *ngIf="orderdata.CHEQUE_DRAWN==1"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >ZAO Hindi Remark</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Enter ZAO Hindi Remark"
                  >
                    <input
                      style="width: 100%"
                      nz-input
                      placeholder="Enter ZAO Hindi Remark"
                      autocomplete="off"
                      name="ZAO_HN"
                      type="text"
                      [(ngModel)]="orderdata.ZAO_HN"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
                *ngIf="orderdata.CHEQUE_DRAWN==1"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >ZAO English Remark</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Enter ZAO English Remark"
                  >
                    <input
                      style="width: 100%"
                      nz-input
                      placeholder="Enter ZAO English Remark"
                      autocomplete="off"
                      name="ZAO"
                      type="text"
                      [(ngModel)]="orderdata.ZAO"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
          <div *ngFor="let data of HospitalMapping; let i = index">
            <div class="my-box">
              <div nz-row>
                <div nz-col nzSpan="24">
                  <nz-form-item>
                    <nz-form-label nzNoColon> Name of Hospital </nz-form-label>

                    <nz-form-control
                      nzHasFeedback
                      nzErrorTip="Name of Hospital is required"
                    >
                      <input
                        style="width: 100%"
                        name="NAMENAME{{ i }}"
                        nz-input
                        placeholder="Enter name in english"
                        autocomplete="off"
                        type="text"
                        disabled="true"
                        [(ngModel)]="data.NAME"
                      />
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
              <div nz-row>
                <div
                  nz-col
                  nzXs="24"
                  nzSm="24"
                  nzMd="24"
                  nzLg="24"
                  nzXl="24"
                  nzSpan="24"
                >
                  <nz-form-item>
                    <nz-form-label nzRequired nzNoColon
                      >Name of Hospital(in hindi)</nz-form-label
                    >

                    <nz-form-control
                      nzHasFeedback
                      nzErrorTip="Name of Hospital(in hindi) is required"
                    >
                      <input
                        style="width: 100%"
                        nz-input
                        placeholder="Enter name of Hospital in hindi"
                        autocomplete="off"
                        name="NAME_HN{{ i }}"
                        type="text"
                        [(ngModel)]="data.NAME_HN"
                      />
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>

              <div nz-row>
                <div
                  nz-col
                  nzXs="24"
                  nzSm="24"
                  nzMd="24"
                  nzLg="24"
                  nzXl="24"
                  nzSpan="24"
                >
                  <nz-form-item>
                    <nz-form-label nzNoColon
                      >Address of Hospital
                    </nz-form-label>

                    <nz-form-control
                      nzHasFeedback
                      nzErrorTip="Name of Address is required"
                    >
                      <input
                        style="width: 100%"
                        name="ADDRESS{{ i }}"
                        nz-input
                        placeholder="Enter Address in english"
                        autocomplete="off"
                        type="text"
                        [(ngModel)]="data.ADDRESS"
                      />
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
              <div nz-row>
                <div
                  nz-col
                  nzXs="24"
                  nzSm="24"
                  nzMd="24"
                  nzLg="24"
                  nzXl="24"
                  nzSpan="24"
                >
                  <nz-form-item>
                    <nz-form-label nzRequired nzNoColon
                      >Address of Hospital(in hindi)</nz-form-label
                    >

                    <nz-form-control
                      nzHasFeedback
                      nzErrorTip="Address of Hospital(in hindi) is required"
                    >
                      <input
                        style="width: 100%"
                        nz-input
                        placeholder="Enter Address of Hospital in hindi"
                        autocomplete="off"
                        name="ADDRESS_HN{{ i }}"
                        type="text"
                        [(ngModel)]="data.ADDRESS_HN"
                      />
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>

              <!-- <div>
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Show In Order</nz-form-label
                  >
                  <nz-form-control>
                    <nz-switch
                      name="IS_VISIBLE_IN_ORDER{{ i }}"
                      nzCheckedChildren="Yes"
                      nzUnCheckedChildren="No"
                      [(ngModel)]="HospitalMapping[i].IS_VISIBLE_IN_ORDER"
                    ></nz-switch>
                  </nz-form-control>
                </nz-form-item>
              </div> -->
            </div>
          </div>
          <!-- <div nz-row *ngIf="orderdata.HOSPITAL_TYPE == 'E'">
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzNoColon>Name of Hospital </nz-form-label>

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Name of patient is required"
                  >
                    <input
                      style="width: 100%"
                      name="EMPANELLED_HOSPITAL_NAME"
                      nz-input
                      placeholder="Enter name in english"
                      autocomplete="off"
                      type="text"
                      disabled="true"
                      [(ngModel)]="orderdata.EMPANELLED_HOSPITAL_NAME"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div> -->
          <div class="my-box">
            <div nz-row>
              <div
                nz-col
                nzXs="12"
                nzSm="12"
                nzMd="12"
                nzLg="12"
                nzXl="12"
                nzSpan="12"
              >
                <nz-form-item>
                  <nz-form-label nzNoColon>Realationship</nz-form-label>

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Realationship is required"
                  >
                    <input
                      style="width: 100%"
                      nz-input
                      placeholder="Enter Realationship"
                      autocomplete="off"
                      name="RELATION_WITH_PATIENT"
                      disabled="true"
                      type="text"
                      [(ngModel)]="orderdata.RELATION_WITH_PATIENT"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <!-- <div
                nz-col
                nzXs="12"
                nzSm="12"
                nzMd="12"
                nzLg="12"
                nzXl="12"
                nzSpan="12"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Realationship(in hindi)</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Realationship(in hindi) is required"
                  >
                    <input
                      style="width: 100%"
                      nz-input
                      placeholder="Enter Realationship in hindi"
                      autocomplete="off"
                      name="RELATION_WITH_PATIENT_MR"
                      type="text"
                      [(ngModel)]="orderdata.RELATIONSHIP_HN"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div> -->
            </div>
          </div>
          <!-- <div nz-row>
                            <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" nzSpan="24">
                                <nz-form-item>
                                    <nz-form-label  nzNoColon>Amount (in words)</nz-form-label>

                                    <nz-form-control nzHasFeedback nzErrorTip="City Name is required">
                                        <input style="width: 100%;" nz-input placeholder="Enter Amount"
                                            autocomplete="off"  name="amountinwords" type="text"
                                            [(ngModel)]="amountinwords" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                           
                        </div>
                        <div nz-row>
                            <div  nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" nzSpan="24">
                                <nz-form-item>
                                    <nz-form-label nzRequired nzNoColon>Amount (in hindi)</nz-form-label>

                                    <nz-form-control nzHasFeedback nzErrorTip="City Name is required">
                                        <input style="width: 100%;" nz-input placeholder="Enter amount in hindi"
                                            autocomplete="off" name="amountinwordsh"  type="text"
                                            [(ngModel)]="amountinwordsh" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div> -->
        </div>

        <div nz-col nzXs="16" nzSm="16" nzMd="16" nzLg="16" nzXl="16">
          <div
            class="my-box"
            style="padding: 10px; height: 590px; overflow-y: scroll"
          >
            <div>
              <div nz-row>
                <div
                  style="border: 1px solid"
                  nz-col
                  nzXs="4"
                  nzSm="4"
                  nzMd="4"
                  nzLg="4"
                  nzXl="4"
                >
                  <div style="text-align: center; padding: 36px 9px 9px 9px">
                    <img
                      src="assets/govtOfIndia.png"
                      alt=""
                      height="140px"
                      width="100%"
                    />
                  </div>
                </div>
                <!-- <div
                  style="
                    border-bottom: 1px solid;
                    border-top: 1px solid;
                    border-right: 1px solid;
                  "
                  nz-col
                  nzXs="1"
                  nzSm="1"
                  nzMd="1"
                  nzLg="1"
                  nzXl="1"
                ></div> -->
                <div
                  style="
                    border-bottom: 1px solid;
                    border-top: 1px solid;
                    border-right: 1px solid;
                  "
                  nz-col
                  nzXs="20"
                  nzSm="20"
                  nzMd="20"
                  nzLg="20"
                  nzXl="20"
                >
                  <div
                    style="width: 100%; text-align: center; font-weight: bold"
                  >
                    <div>भारत सरकार</div>
                    <div style="text-transform: uppercase" class="family">
                      Government Of India
                    </div>
                    <div>कार्यालय, प्रधान मुख्य आयकर आयुक्त, मुंबई</div>
                    <div style="text-transform: uppercase" class="family">
                      OFFICE OF THE
                    </div>
                    <div style="text-transform: uppercase" class="family">
                      PRINCIPAL CHIEF COMMISSIONER OF INCOME TAX,
                    </div>
                    <div class="family" style="text-transform: uppercase">
                      MUMBAI
                    </div>
                    <!-- <div>
                      तीसरी मंजिल, आयकर भवन, महर्षी कर्वे रोड, मुंबई -20
                    </div> -->
                    <div style="text-transform: uppercase" class="family">
                      3<sup style="text-transform: lowercase">rd</sup> Floor,
                      aayakar bhavan, Maharshi Karve Road, Mumbai - 400020
                    </div>
                    <div>
                      (022) - 22016691/22077187 / Fax: 022- 22079273/22077187
                    </div>
                    <div class="family">
                      Email:
                      <span *ngIf="orderdata.SIGNATURE_ID == 2">
                        mumbai.ito.gpbs2@incometax.gov.in
                      </span>
                      <span *ngIf="orderdata.SIGNATURE_ID == 3">
                        mumbai.ito.hq.pers@incometax.gov.in
                      </span>
                      <!-- <span *ngIf="userId != 18 && userId != 48">
                        mumbai.ito.hq.pers@incometax.gov.in</span
                      > -->
                    </div>
                  </div>
                </div>
              </div>

              <div
                style="
                  width: 100%;
                  padding: 5px;
                  font-size: 13px;
                  border-bottom: 1px solid;
                  border-left: 1px solid;
                  border-right: 1px solid;
                "
              >
                <div nz-row>
                  <div nz-col nzSpan="17">{{ orderdata.ORDER_NO }}</div>
                  <div nz-col nzSpan="1"></div>
                  <div nz-col nzSpan="6">
                    दिनांक
                    :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                  <!-- {{ orderdata.ORDER_DATE | date : "dd MMM yyyy" }} -->
                </div>
              </div>
              <div
                style="font-family: Arial, Helvetica, sans-serif !important"
                *ngIf="orderdata.IS_ADVANCE_TAKEN == 0"
              >
                <div
                  style="
                    text-align: center;
                    margin-top: 10px;
                    margin-bottom: 5px;
                    font-size: 13px;
                  "
                >
                  <b>स्वीकृति आदेश/ SANCTION ORDER</b>
                </div>
                <div style="font-size: 13px !important">
                  <p style="margin-bottom: 2px">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {{ orderdata.EMPLOYEE_NAME_HN }},
                    {{ orderdata.DESIGNATION_NAME_HN }}, को उनके द्वारा दिनांक
                    {{
                      orderdata.TREATMENT_START_DATE | date : "dd MMM yyyy"
                    }}&nbsp; से
                    {{
                      orderdata.TREATMENT_END_DATE | date : "dd MMM yyyy"
                    }}&nbsp; तक

                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                      {{ data.NAME_HN }} , {{ data.ADDRESS_HN }}
                      <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                      <span *ngIf="HospitalMapping.length - 2 == k">और</span>
                      <!-- </span> -->
                    </span>

                    में
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Father'"
                        >उनके पिता</span
                      >
                      <!-- <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Father'
                        "
                        >उनके दादाजी</span
                      > -->
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Wife'"
                        >उनकी पत्नी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Husband'"
                        >उनके पती</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Son'"
                        >उनका बेटा</span
                      >
                      <span
                        *ngIf="orderdata.RELATION_WITH_PATIENT == 'Daughter'"
                        >उनकी बेटी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Sister'"
                        >उनकी बहन</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Brother'"
                        >उनके भाई</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'"
                        >उनकी मां</span
                      >
                      <!-- <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Mother'
                        "
                        >उनकी दादी मां</span
                      > -->
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Father-In-Law'
                        "
                        >उनके ससुर</span
                      >
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Mother-In-Law'
                        "
                        >उनकी सासू मां</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} के</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} के</span
                      >
                    </span>

                    &nbsp;
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      स्वयं के</span
                    >
                    {{ orderdata.NATURE_OF_TREATMENT }}
                    के लिए खर्च की गई राशि,
                    <!-- के चिकित्सा हेतु परमार्श और दवा के लिए खर्च की गई राशि, -->
                    <span
                      >जो
                      <b>
                        {{
                          orderdata.ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-&nbsp; ({{ admissibleinhindi }})</b
                      >
                      से अधिक नहीं है,</span
                    >
                    के लिए सीजीएचएस दर के अनुसार चिकित्सा व्यय की प्रतिपूर्ती की
                    स्वीकृति प्रदान की जाती है।
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CG'"
                      >यह स्वीकृति स्वास्थ्य एवं परिवार कल्याण मंत्रालय, भारत
                      सरकार द्वारा जारी दिनांक 18.03.1992 के कार्यालय ज्ञापन सं.
                      एस-11012/1/91/सीजीएचएस (पी) खंड 1 के माध्यम से मुझे
                      प्रदत्त शक्तियो का प्रयोग करते हुए जारी की जाती है।</span
                    >
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CS'">
                      यह स्वीकृति आदेश सेंट्रल सर्विसेस (मेडिकल अटेनडंस),
                      नियमावली, 1944 के परिशिष्ठ । के प्रावधानों के अनुसार जारी
                      किया जाता है।
                    </span>
                  </p>

                  <p style="margin-bottom: 2px">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sanction is
                    hereby accorded for the reimbursement of medical expenses as
                    per CGHS rate for an
                    <span>
                      amount not exceeding
                      <b>
                        {{
                          orderdata.ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-&nbsp; ({{ admissibleineng }})</b
                      >
                    </span>
                     incurred by {{ orderdata.EMPLOYEE_NAME }}&nbsp;,
                    {{ orderdata.DESIGNATION }}
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      for his/her {{ orderdata.RELATION_WITH_PATIENT }}
                      {{ orderdata.PATIENT_NAME }}</span
                    >

                    &nbsp; for&nbsp;{{ orderdata.NATURE_OF_TREATMENT }}
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      of Self</span
                    >, at
                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                      {{ data.NAME }} ,{{ data.ADDRESS }}
                      <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                      <span *ngIf="HospitalMapping.length - 2 == k">and</span>
                      <!-- </span> -->
                    </span>

                    from
                    {{ orderdata.TREATMENT_START_DATE | date : "dd MMM yyyy" }}
                    to
                    {{ orderdata.TREATMENT_END_DATE | date : "dd MMM yyyy" }}.
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CG'">
                      This sanction is issued in exercise of the powers vested
                      in me vide Office Memorandum No.S-11012/1/91/CGHS(P) Vol.
                      I dated 18.03.1992 issued by the Ministry of Health &
                      Family Welfare , Government of India.</span
                    >
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CS'">
                      This sanction is issued in accordance with the provisions
                      of Appendix VIII of the Central Services (Medical
                      Attendance) Rules, 1944.
                    </span>
                  </p>

                  <p
                    style="margin-bottom: 15px; margin-top: 10px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    यह राशि डिस्चार्ज की तारीख से एक महीने के भीतर श्री
                    {{ orderdata.EMPLOYEE_NAME_HN }} द्वारा प्रस्तुत किए जाने
                    वाले नियमित दावों के खिलाफ समायोजित की जाएगी।
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    This amount will be adjusted against regular claims ,
                    within one month from the date of discharge. to be submitted
                    by {{ orderdata.EMPLOYEE_NAME }}.
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    उक्त व्यय को वित्तीय वर्ष 2023-2024 के लिए विभाग को आबंटित
                    मुख्य शीर्ष "2020 चिकित्सा" के अंतर्गत अनुदान के नामे डाला
                    जाए।
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    The above expenditure should be debited to the grant under
                    the major head "2020 - Medical allotted to the Department
                    for Financial Year 2023-2024.
                  </p>

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 0
                    "
                  >
                    यह आपात स्थिति का मामला था, जिसके लिए तत्काल उपचार की
                    आवश्यकता थी अतः गैर-पैनलबद्ध अस्पताल में भर्ती कराया गया था|
                  </p>

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 0
                    "
                  >
                    It was a case of emergency , necessitating immediate
                    treatment and was admitted in a non-empanelled hospital.
                  </p>

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 1 &&
                        orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 0 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 1 &&
                        orderdata.IS_HOSPITAL_EMPLANELLED == 0 &&
                        orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 0 &&
                        orderdata.EMERGENCY_TREATEMENT == 0) ||
                      orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1
                    "
                  >
                    दिनांक
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0">
                      {{ orderdata.EXPO_FACTO_DATE }}
                    </span>
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1">
                      {{ orderdata.HOD_PERMISSION_DATE }}
                    </span>
                    <!-- के पत्र द्वारा उपरोक्त उपचार के लिये कार्योत्तर अनुमति -->
                    के पत्र द्वारा उपरोक्त उपचार के लिये अनुमति प्रदान की गई है।
                  </p>

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 1 &&
                        orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 0 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 1 &&
                        orderdata.IS_HOSPITAL_EMPLANELLED == 0 &&
                        orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 0 &&
                        orderdata.EMERGENCY_TREATEMENT == 0) ||
                      orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1
                    "
                  >
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0">
                      Expost Facto Permission for the above treatment has been
                      granted vide letter dated {{ orderdata.EXPO_FACTO_DATE }}
                    </span>
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1">
                      Permission for the above treatment has been granted vide
                      letter dated {{ orderdata.HOD_PERMISSION_DATE }}
                    </span>
                  </p>
                  <!-- <p
                *ngIf="
                  orderdata.IS_BILL_FILLED_IN_TIME == 1 &&
                  orderdata.IS_HOSPITAL_EMPLANELLED == 1 &&
                  orderdata.EXPO_FACTO_PERMISSION == 1
                "
              >
                दिनांक 10/01/2023 के पत्र द्वारा उपरोक्त उपचार के लिए अनुमति
                प्रदान की गई है।
              </p>
              <p
                *ngIf="
                  orderdata.IS_BILL_FILLED_IN_TIME == 1 &&
                  orderdata.IS_HOSPITAL_EMPLANELLED == 1 &&
                  orderdata.EXPO_FACTO_PERMISSION == 1
                "
              >
                Permission for the above treatment has been granted vide letter
                dated 10/01/2023
              </p> -->

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 1
                    "
                  >
                    यह आपात स्थिति का मामला था जिसमें तत्काल प्रवेश की आवश्यकता
                    थी और उसे पैनलबद्ध अस्पताल में भर्ती कराया गया था.
                  </p>
                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 1
                    "
                  >
                    It was a case of Emergency necessitating immediate admission
                    and was admitted in empanelled hospital.
                  </p>
                  <p style="margin-bottom: 2px">
                    उक्त व्यय को वित्तीय वर्ष 2023-2024 के लिए विभाग को आबंटित
                    अनुदान के अंतर्गत मुख्य शीर्ष "2020-चिकित्सा उपचार" से किया
                    जाए।
                  </p>

                  <p style="margin-bottom: 2px">
                    The above expenditure should be debited to the grant under
                    major head 2020 - Medical Treatment Alloted to the
                    Department for the
                    <b>F.Y 2023-2024.</b>
                  </p>

                  <p style="margin-bottom: 2px">
                    हिंदी और अंग्रेजी संस्करण में किसी भी भिन्नता के मामले में
                    आदेश का अंग्रेजी संस्करण मान्य होगा।
                  </p>

                  <p style="margin-bottom: 2px">
                    The English version of the order will prevail in case of any
                    variation in Hindi & English version.
                  </p>

                  <div style="margin-top: 60px; font-size: 13px">
                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div><b> (विनय सिन्हा) </b></div>
                        <div style="font-weight: bold">
                          आयकर आयुक्त (प्रशासन एवं करदाता सेवाएँ) , मुंबई
                          <!-- <span *ngIf="orderdata.SIGNATURE_ID == 2">
                            आयकर आयुक्त (प्रशासन एवं करदाता सेवाएँ) , मुंबई
                          </span>
                          <span *ngIf="orderdata.SIGNATURE_ID != 2">
                            आयकर आयुक्त (प्रशासन एवं टीपीएस) , मुंबई</span
                          > -->
                        </div>
                      </div>
                    </div>

                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div><b> (Vinay Sinha) </b></div>
                        <div style="font-weight: bold; font-size: 13px">
                          Commissioner Of Income Tax(Admin & TPS),Mumbai.
                          <!-- <span *ngIf="orderdata.SIGNATURE_ID != 2"
                            >Commissioner Of Income Tax(Admin &
                            TPS),Mumbai.</span
                          >
                          <span *ngIf="orderdata.SIGNATURE_ID == 2">
                            For Principal Chief Commissioner of Income Tax
                            Mumbai</span
                          > -->
                        </div>
                        <!-- <div
                          style="font-weight: bold; font-size: 13px"
                          *ngIf="orderdata.SIGNATURE_ID != 3"
                        >
                          for Pr. Chief Commissioner of Income-Tax, Mumbai
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      style="
                        page-break-inside: avoid !important;
                        font-size: 13px;
                        page-break-before: avoid !important;
                      "
                    >
                      <div style="margin-top: 30px">
                        <b>प्रतिलिपी/Copy to:</b>
                      </div>
                      <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                        <span>
                          1)अराजपत्रित वेतन बिल, मुंबई (2 प्रतियां) फॉर्म एमआरसी
                          (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ
                        </span>
                        <br />
                        <span style="padding-left: 15px">
                          The Non Gazetted Pay Bill Section (2 copies) alongwith
                          Form MRC(S) , hospital receipts , discharge summary
                          etc.
                        </span>
                        <br />

                        <!-- <span *ngIf="SECTION_TYPE == 'welfare'">
                          1) डीडीओ, कल्याण और एसजी,मुंबई (2 प्रतियां) फॉर्म
                          एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के
                          साथ|</span
                        > <br>
                        <span *ngIf="SECTION_TYPE == 'gazpay'">
                          1) डीडीओ, राजपत्रित वेतन बिल अनुभाग II (कार्मिक),मुंबई
                          (2 प्रतियां) फॉर्म एमआरसी (एस), अस्पताल रसीद,
                          डिस्चार्ज सारांश आदि के साथ |</span
                        >
                        <br>
                        <span
                        *ngIf="SECTION_TYPE == 'welfare'"
                        style="padding-left: 15px"
                      >
                        DDO , Welfare & SG , Mumbai (2 copies) alongwith Form
                        MRC(S) , hospital receipts , discharge summary etc.
                      </span>
                      <br>
                      <span
                        *ngIf="SECTION_TYPE == 'gazpay'"
                        style="padding-left: 15px"
                      >
                        DDO , Gazetted Pay bill Section II, Personnel Mumbai
                        (2 copies) alongwith Form MRC(S) , hospital receipts ,
                        discharge summary etc.
                      </span> -->

                      <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 0">
                        <div>
                          2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                          <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                          तैयार किया जा सकता है। |
                        </div>
                        <div>
                           ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                          in favour of {{ orderdata.EMPLOYEE_NAME }}.
                        </div>
                      </div>
                      <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                        <div  *ngIf="orderdata.CHEQUE_DRAWN==0">
                          2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                          <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                          तैयार किया जा सकता है। |
                        </div>
                        <div  *ngIf="orderdata.CHEQUE_DRAWN==0">
                          ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                          in favour of {{ orderdata.EMPLOYEE_NAME }}.
                        </div>
                        <div  *ngIf="orderdata.CHEQUE_DRAWN==1">
                          2) {{orderdata.ZAO_HN}}
                        </div>
                        <div  *ngIf="orderdata.CHEQUE_DRAWN==1">
                            {{orderdata.ZAO}}
                        </div>
                      </div>

                        <div>
                          3)<b>
                            {{ orderdata.EMPLOYEE_NAME_HN }},
                            {{ orderdata.DESIGNATION_NAME_HN }} कार्यालय
                            {{ orderdata.OFFICE_NAME_HN }}
                            <!-- {{ orderdata.LOCATION_HN }}., -->
                          </b>
                          स्वीकार्य राशि की गणना की प्रति के साथ |
                        </div>

                        <div style="padding-left: 15px">
                          <b
                            >{{ orderdata.EMPLOYEE_NAME }},
                            {{ orderdata.DESIGNATION }} O/o
                            {{ orderdata.EMPLOYEE_OFFICE_NAME }}
                            <!-- {{ orderdata.LOCATION }}., -->
                          </b>
                          along with copy of working of admissible amount.
                        </div>
                      </div>

                      <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 0">
                        <div>
                          <span *ngIf="SECTION_TYPE == 'welfare'">
                            1) डीडीओ, कल्याण और एसजी,मुंबई (2 प्रतियां) फॉर्म
                            एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के
                            साथ|</span
                          >
                          <span *ngIf="SECTION_TYPE == 'gazpay'">
                            1) डीडीओ, राजपत्रित वेतन बिल अनुभाग II
                            (कार्मिक),मुंबई (2 प्रतियां) फॉर्म एमआरसी (एस),
                            अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ |</span
                          >
                          <!-- 1) डीडीओ,कल्याण और एसजी,मुंबई (2 प्रतियां)
                      फॉर्म एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ| -->
                        </div>
                        <div>
                          <!-- 1) DDO , Welfare & SG , Mumbai (2 copies) alongwith Form
                      MRC(S) , hospital receipts , discharge summary etc. -->

                          <span
                            *ngIf="SECTION_TYPE == 'welfare'"
                            style="padding-left: 15px"
                          >
                            DDO , Welfare & SG , Mumbai (2 copies) alongwith
                            Form MRC(S) , hospital receipts , discharge summary
                            etc.
                          </span>
                          <span
                            *ngIf="SECTION_TYPE == 'gazpay'"
                            style="padding-left: 15px"
                          >
                            DDO , Gazetted Pay bill Section II, Personnel Mumbai
                            (2 copies) alongwith Form MRC(S) , hospital receipts
                            , discharge summary etc.
                          </span>
                        </div>
                        <div>
                          2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                          <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                          तैयार किया जा सकता है।
                        </div>
                        <div style="padding-left: 15px">
                            ZAO , Pre-check unit , Mumbai - Cheque may be drawn in
                          favour of {{ orderdata.EMPLOYEE_NAME }}.
                        </div>

                        <div>
                          3)<b>
                            {{ orderdata.EMPLOYEE_NAME_HN }},
                            {{ orderdata.DESIGNATION_NAME_HN }} कार्यालय
                            {{ orderdata.OFFICE_NAME_HN }}
                            <!-- {{ orderdata.LOCATION_HN }}., -->
                          </b>
                          स्वीकार्य राशि की गणना की प्रति के साथ |
                        </div>

                        <div style="padding-left: 15px">
                          <b
                            >{{ orderdata.EMPLOYEE_NAME }},
                            {{ orderdata.DESIGNATION }} O/o
                            {{ orderdata.EMPLOYEE_OFFICE_NAME }}
                            <!-- {{ orderdata.LOCATION }}., -->
                          </b>
                          along with copy of working of admissible amount.
                        </div>
                      </div>
                      <div style="margin-top: 60px">
                        <div nz-row>
                          <div nz-col nzSpan="12"></div>

                          <div nz-col nzSpan="12" style="text-align: center">
                            <div style="font-weight: bold">({{ NAME_HN }})</div>
                            <div>
                              <b> {{ POST_HN }} </b>
                            </div>
                            <div>
                              <b> {{ OFFICE_NAME_HN }} </b>
                            </div>
                            <div>
                              <b> ({{ SIGNNAME }}) </b>
                            </div>
                            <div>
                              <b>{{ POST }} </b>
                            </div>
                            <div>
                              <b>{{ OFFICE_NAME }} </b>
                            </div>
                            <!-- <span *ngIf="SECTION_TYPE == 'welfare'">
                              <div style="font-weight: bold">
                                (रीतेश कुमार)
                              </div>
                              <div>आयकर अधिकारी (मुख्‍यालय)</div>
                              <div>कल्याण और कर्मचारी शिकायत, मुंबई</div>
                            </span>

                            <span *ngIf="SECTION_TYPE == 'gazpay'">
                              <div style="font-weight: bold">
                                (जितेंद्र रा चांदेकर)
                              </div>
                              <div>आयकर अधिकारी</div>
                              <div>राजपत्रित वेतन बिल अनुभाग II, मुंबई</div>
                            </span> -->
                          </div>
                        </div>

                        <!-- <div nz-row>
                          <div nz-col nzSpan="15"></div>

                          <div nz-col nzSpan="9" style="text-align: center">
                            <span *ngIf="SECTION_TYPE == 'welfare'">
                              <div style="font-weight: bold">
                                (Ritesh Kumar)
                              </div>
                              <div>Income Tax Officer(HQ)</div>
                              <div>Welfare & S.G, Mumbai</div>
                            </span>
                            <span *ngIf="SECTION_TYPE == 'gazpay'">
                              <div style="font-weight: bold">
                                (Jitendra R Chandekar)
                              </div>
                              <div>Income Tax Officer</div>
                              <div>
                                Gazetted Pay Bill Section II Personnel, Mumbai
                              </div>
                            </span>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style="font-family: Arial, Helvetica, sans-serif !important"
                *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
              >
                <div
                  style="
                    text-align: center;
                    margin-top: 20px;
                    margin-bottom: 10px;
                    font-size: 13px;
                  "
                >
                  <b>स्वीकृति आदेश/ SANCTION ORDER</b>
                </div>
                <div style="font-size: 13px !important">
                  <p style="margin-bottom: 2px">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {{ orderdata.EMPLOYEE_NAME_HN }}
                    {{ orderdata.DESIGNATION_NAME_HN }}, को उनके द्वारा दिनांक
                    {{
                      orderdata.TREATMENT_START_DATE | date : "dd MMM yyyy"
                    }}&nbsp; से
                    {{
                      orderdata.TREATMENT_END_DATE | date : "dd MMM yyyy"
                    }}&nbsp; को

                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                      {{ data.NAME_HN }} , {{ data.ADDRESS_HN }}
                      <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                      <span *ngIf="HospitalMapping.length - 2 == k">और</span>
                      <!-- </span> -->
                    </span>

                    में
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Father'"
                        >उनके पिता</span
                      >
                      <!-- <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Father'
                        "
                        >उनके दादाजी</span
                      > -->
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Wife'"
                        >उनकी पत्नी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Husband'"
                        >उनके पती</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Son'"
                        >उनका बेटा</span
                      >
                      <span
                        *ngIf="orderdata.RELATION_WITH_PATIENT == 'Daughter'"
                        >उनकी बेटी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Sister'"
                        >उनकी बहन</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Brother'"
                        >उनके भाई</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'"
                        >उनकी मां</span
                      >
                      <!-- <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Mother'
                        "
                        >उनकी दादी मां</span
                      > -->
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Father-In-Law'
                        "
                        >उनके ससुर</span
                      >
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Mother-In-Law'
                        "
                        >उनकी सासू मां</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} के</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} के</span
                      >
                    </span>

                    &nbsp;
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      स्वयं के</span
                    >
                    {{ orderdata.NATURE_OF_TREATMENT }}
                    के लिए खर्च की गई राशि,
                    <span *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                      <span *ngIf="isAdvanceSameAsAdmissible == true">
                        जो
                        <b
                          >{{
                            orderdata.ADVANCE_ADMISSIBLE_AMOUNT
                              | currency:'INR':'symbol'
                          }}/-&nbsp; ({{ advadmissibleinhindi }})</b
                        >
                        <b>
                          जो कि
                          {{
                            orderdata.ADMISSIBLE_AMOUNT
                              | currency:'INR':'symbol'
                          }}/-</b
                        >
                        की स्वीकार्य राशि का 90% (लगभग) से अधिक नहीं है,
                      </span>
                      <span *ngIf="isAdvanceSameAsAdmissible == false">
                        जो
                        <b>
                          {{
                            orderdata.ADVANCE_AMOUNT
                              | currency:'INR':'symbol'
                          }}/-&nbsp; ({{ advanceinhindi }})</b
                        >
                      </span>
                    </span>
                    को सीजीएचएस दर के अनुसार चिकित्सा व्यय की प्रतिपूर्ती की
                    स्वीकृति प्रदान की जाती है।
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CG'"
                      >यह स्वीकृति स्वास्थ्य एवं परिवार कल्याण मंत्रालय, भारत
                      सरकार द्वारा जारी दिनांक 18.03.1992 के कार्यालय ज्ञापन सं.
                      एस-11012/1/91/सीजीएचएस (पी) खंड 1 के माध्यम से मुझे
                      प्रदत्त शक्तियो का प्रयोग करते हुए जारी की जाती है।</span
                    >
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CS'">
                      यह स्वीकृति आदेश सेंट्रल सर्विसेस (मेडिकल अटेनडंस),
                      नियमावली, 1944 के परिशिष्ठ । के प्रावधानों के अनुसार जारी
                      किया जाता है।
                    </span>
                  </p>

                  <p style="margin-bottom: 2px">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sanction is
                    hereby accorded for the reimbursement of medical expenses as
                    per CGHS rate for an
                    <span *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                      <span *ngIf="isAdvanceSameAsAdmissible == true">
                        amount not exceeding
                        <b
                          >{{
                            orderdata.ADVANCE_ADMISSIBLE_AMOUNT
                              | currency:'INR':'symbol'
                          }}/-&nbsp; ({{ advadmissibleineng }})</b
                        >
                        being 90% (approximately) of the admissible amount of
                        <b>
                          {{
                            orderdata.ADMISSIBLE_AMOUNT
                              | currency:'INR':'symbol'
                          }}/-</b
                        >
                      </span>
                      <span *ngIf="isAdvanceSameAsAdmissible == false">
                        amount
                        <b>
                          {{
                            orderdata.ADVANCE_AMOUNT
                              | currency:'INR':'symbol'
                          }}/-&nbsp; ({{ advanceineng }})</b
                        >
                      </span>
                    </span>
                     incurred by {{ orderdata.EMPLOYEE_NAME }}&nbsp;,
                    {{ orderdata.DESIGNATION }}
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      for his/her {{ orderdata.RELATION_WITH_PATIENT }}
                      {{ orderdata.PATIENT_NAME }}</span
                    >

                    &nbsp; for&nbsp;{{ orderdata.NATURE_OF_TREATMENT }}
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      of Self</span
                    >, at
                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                      {{ data.NAME }} ,{{ data.ADDRESS }}
                      <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                      <span *ngIf="HospitalMapping.length - 2 == k">and</span>
                      <!-- </span> -->
                    </span>

                    from
                    {{ orderdata.TREATMENT_START_DATE | date : "dd MMM yyyy" }}
                    to
                    {{ orderdata.TREATMENT_END_DATE | date : "dd MMM yyyy" }}.
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CG'">
                      This sanction is issued in exercise of the powers vested
                      in me vide Office Memorandum No.S-11012/1/91/CGHS(P) Vol.
                      I dated 18.03.1992 issued by the Ministry of Health &
                      Family Welfare , Government of India.</span
                    >
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CS'">
                      This sanction is issued in accordance with the provisions
                      of Appendix VIII of the Central Services (Medical
                      Attendance) Rules, 1944.
                    </span>
                  </p>

                  <p
                    style="margin-bottom: 15px; margin-top: 10px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    यह राशि डिस्चार्ज की तारीख से एक महीने के भीतर श्री
                    {{ orderdata.EMPLOYEE_NAME_HN }} द्वारा प्रस्तुत किए जाने
                    वाले नियमित दावों के खिलाफ समायोजित की जाएगी।
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    This amount will be adjusted against regular claims ,
                    within one month from the date of discharge. to be submitted
                    by {{ orderdata.EMPLOYEE_NAME }}.
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    उक्त व्यय को वित्तीय वर्ष 2023-2024 के लिए विभाग को आबंटित
                    मुख्य शीर्ष "2020 चिकित्सा" के अंतर्गत अनुदान के नामे डाला
                    जाए।
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    The above expenditure should be debited to the grant under
                    the major head "2020 - Medical allotted to the Department
                    for Financial Year 2023-2024.
                  </p>

                  <div style="margin-top: 60px; font-size: 13px">
                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div><b> (विनय सिन्हा) </b></div>
                        <div style="font-weight: bold">
                          आयकर आयुक्त (प्रशासन एवं करदाता सेवाएँ) , मुंबई
                          <!-- आयकर आयुक्त (प्रशासन एवं टीपीएस) , मुंबई -->
                        </div>
                      </div>
                    </div>

                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div><b> (Vinay Sinha) </b></div>
                        <div style="font-weight: bold; font-size: 13px">
                          Commissioner Of Income Tax(Admin & TPS),Mumbai.
                          <!-- <span *ngIf="orderdata.SIGNATURE_ID != 2"
                            >Commissioner Of Income Tax(Admin &
                            TPS),Mumbai.</span
                          >
                          <span *ngIf="orderdata.SIGNATURE_ID == 2">
                            For Principal Chief Commissioner of Income Tax
                            Mumbai</span
                          > -->
                        </div>
                        <!-- <div
                          style="font-weight: bold; font-size: 13px"
                          *ngIf="orderdata.SIGNATURE_ID != 3"
                        >
                          for Pr. Chief Commissioner of Income-Tax, Mumbai
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      style="
                        page-break-inside: avoid !important;
                        font-size: 13px;
                        page-break-before: avoid !important;
                      "
                    >
                      <div style="margin-top: 30px">
                        <b>प्रतिलिपी/Copy to:</b>
                      </div>
                      <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                        <span>
                          1)अराजपत्रित वेतन बिल, मुंबई (2 प्रतियां) फॉर्म एमआरसी
                          (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ
                        </span>
                        <br />
                        <span style="padding-left: 15px">
                          The Non Gazetted Pay Bill Section (2 copies) alongwith
                          Form MRC(S) , hospital receipts , discharge summary
                          etc.
                        </span>
                        <br />

                        <!-- <span *ngIf="SECTION_TYPE == 'welfare'">
                          1) डीडीओ, कल्याण और एसजी,मुंबई (2 प्रतियां) फॉर्म
                          एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के
                          साथ|</span
                        > <br>
                        <span *ngIf="SECTION_TYPE == 'gazpay'">
                          1) डीडीओ, राजपत्रित वेतन बिल अनुभाग II (कार्मिक),मुंबई
                          (2 प्रतियां) फॉर्म एमआरसी (एस), अस्पताल रसीद,
                          डिस्चार्ज सारांश आदि के साथ |</span
                        >
                        <br>
                        <span
                        *ngIf="SECTION_TYPE == 'welfare'"
                        style="padding-left: 15px"
                      >
                        DDO , Welfare & SG , Mumbai (2 copies) alongwith Form
                        MRC(S) , hospital receipts , discharge summary etc.
                      </span>
                      <br>
                      <span
                        *ngIf="SECTION_TYPE == 'gazpay'"
                        style="padding-left: 15px"
                      >
                        DDO , Gazetted Pay bill Section II, Personnel Mumbai
                        (2 copies) alongwith Form MRC(S) , hospital receipts ,
                        discharge summary etc.
                      </span> -->

                        <!-- <div>
                          2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                          <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                          तैयार किया जा सकता है।
                        </div>
                        <div style="padding-left: 15px">
                            ZAO , Pre-check unit , Mumbai - Cheque may be drawn in
                          favour of {{ orderdata.EMPLOYEE_NAME }}.
                        </div> -->
                        <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 0">
                          <div>
                            2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                            <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                            तैयार किया जा सकता है। |
                          </div>
                          <div style="padding-left: 15px">
                             ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                            in favour of {{ orderdata.EMPLOYEE_NAME }}.
                          </div>
                        </div>
                        <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                          <div  *ngIf="orderdata.CHEQUE_DRAWN==0">
                            2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                            <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                            तैयार किया जा सकता है। |
                          </div>
                          <div style="padding-left: 15px"  *ngIf="orderdata.CHEQUE_DRAWN==0">
                             ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                            in favour of {{ orderdata.EMPLOYEE_NAME }}.
                          </div>
                          <div  *ngIf="orderdata.CHEQUE_DRAWN==1">
                            2) {{orderdata.ZAO_HN}}
                          </div>
                          <div style="padding-left: 15px"  *ngIf="orderdata.CHEQUE_DRAWN==1">
                              {{orderdata.ZAO}}
                          </div>
                        </div>

                        <div>
                          3)<b>
                            {{ orderdata.EMPLOYEE_NAME_HN }},
                            {{ orderdata.DESIGNATION_NAME_HN }} कार्यालय
                            {{ orderdata.OFFICE_NAME_HN }}
                            <!-- {{ orderdata.LOCATION_HN }}., -->
                          </b>
                          स्वीकार्य राशि की गणना की प्रति के साथ |
                        </div>

                        <div style="padding-left: 15px">
                          <b
                            >{{ orderdata.EMPLOYEE_NAME }},
                            {{ orderdata.DESIGNATION }} O/o
                            {{ orderdata.EMPLOYEE_OFFICE_NAME }}
                            <!-- {{ orderdata.LOCATION }}., -->
                          </b>
                          along with copy of working of admissible amount.
                        </div>
                      </div>

                      <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 0">
                        <div>
                          <span *ngIf="SECTION_TYPE == 'welfare'">
                            1) डीडीओ, कल्याण और एसजी,मुंबई (2 प्रतियां) फॉर्म
                            एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के
                            साथ|</span
                          >
                          <span *ngIf="SECTION_TYPE == 'gazpay'">
                            1) डीडीओ, राजपत्रित वेतन बिल अनुभाग II
                            (कार्मिक),मुंबई (2 प्रतियां) फॉर्म एमआरसी (एस),
                            अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ |</span
                          >
                          <!-- 1) डीडीओ,कल्याण और एसजी,मुंबई (2 प्रतियां)
                      फॉर्म एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ| -->
                        </div>
                        <div>
                          <!-- 1) DDO , Welfare & SG , Mumbai (2 copies) alongwith Form
                      MRC(S) , hospital receipts , discharge summary etc. -->

                          <span
                            *ngIf="SECTION_TYPE == 'welfare'"
                            style="padding-left: 15px"
                          >
                            DDO , Welfare & SG , Mumbai (2 copies) alongwith
                            Form MRC(S) , hospital receipts , discharge summary
                            etc.
                          </span>
                          <span
                            *ngIf="SECTION_TYPE == 'gazpay'"
                            style="padding-left: 15px"
                          >
                            DDO , Gazetted Pay bill Section II, Personnel Mumbai
                            (2 copies) alongwith Form MRC(S) , hospital receipts
                            , discharge summary etc.
                          </span>
                        </div>
                        <div>
                          2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                          <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                          तैयार किया जा सकता है।
                        </div>
                        <div style="padding-left: 15px">
                            ZAO , Pre-check unit , Mumbai - Cheque may be drawn in
                          favour of {{ orderdata.EMPLOYEE_NAME }}.
                        </div>

                        <div>
                          3)<b>
                            {{ orderdata.EMPLOYEE_NAME_HN }},
                            {{ orderdata.DESIGNATION_NAME_HN }} कार्यालय
                            {{ orderdata.OFFICE_NAME_HN }}
                            <!-- {{ orderdata.LOCATION_HN }}., -->
                          </b>
                          स्वीकार्य राशि की गणना की प्रति के साथ |
                        </div>

                        <div style="padding-left: 15px">
                          <b
                            >{{ orderdata.EMPLOYEE_NAME }},
                            {{ orderdata.DESIGNATION }} O/o
                            {{ orderdata.EMPLOYEE_OFFICE_NAME }}
                            <!-- {{ orderdata.LOCATION }}., -->
                          </b>
                          along with copy of working of admissible amount.
                        </div>
                      </div>
                      <div style="margin-top: 60px">
                        <div nz-row>
                          <div nz-col nzSpan="12"></div>

                          <div nz-col nzSpan="12" style="text-align: center">
                            <div style="font-weight: bold">({{ NAME_HN }})</div>
                            <div>
                              <b> {{ POST_HN }} </b>
                            </div>
                            <div>
                              <b> {{ OFFICE_NAME_HN }} </b>
                            </div>
                            <div>
                              <b>({{ SIGNNAME }})</b>
                            </div>
                            <div>
                              <b>{{ POST }} </b>
                            </div>
                            <div>
                              <b>{{ OFFICE_NAME }} </b>
                            </div>
                            <!-- <span *ngIf="SECTION_TYPE == 'welfare'">
                              <div style="font-weight: bold">
                                (रीतेश कुमार)
                              </div>
                              <div>आयकर अधिकारी (मुख्‍यालय)</div>
                              <div>कल्याण और कर्मचारी शिकायत, मुंबई</div>
                            </span>

                            <span *ngIf="SECTION_TYPE == 'gazpay'">
                              <div style="font-weight: bold">
                                (जितेंद्र रा चांदेकर)
                              </div>
                              <div>आयकर अधिकारी</div>
                              <div>राजपत्रित वेतन बिल अनुभाग II, मुंबई</div>
                            </span> -->
                          </div>
                        </div>

                        <!-- <div nz-row>
                          <div nz-col nzSpan="15"></div>

                          <div nz-col nzSpan="9" style="text-align: center">
                            <span *ngIf="SECTION_TYPE == 'welfare'">
                              <div style="font-weight: bold">
                                (Ritesh Kumar)
                              </div>
                              <div>Income Tax Officer(HQ)</div>
                              <div>Welfare & S.G, Mumbai</div>
                            </span>
                            <span *ngIf="SECTION_TYPE == 'gazpay'">
                              <div style="font-weight: bold">
                                (Jitendra R Chandekar)
                              </div>
                              <div>Income Tax Officer</div>
                              <div>
                                Gazetted Pay Bill Section II Personnel, Mumbai
                              </div>
                            </span>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>
  </form>

  <div class="footer">
    <button
      type="button"
      [nzLoading]="isSpinning"
      class="ant-btn ant-btn-primary"
      nz-button
      (click)="pakagedataLatest()"
    >
      <span>Save & Preview</span>
    </button>
    &nbsp;&nbsp;

    <button
      type="button"
      nz-button
      class="ant-btn"
      style="margin-right: 8px"
      (click)="close()"
    >
      <span>Cancel</span>
    </button>

    <!-- <button nz-button nzType="primary"  (click)="pakagedata()">
            Save and Next
        </button> -->

    <!-- <button
      type="button"
      class="ant-btn ant-btn-primary"
      nz-button
      (click)="openPrintOrderModal()"
    >
      <span>Preview</span>
    </button> -->
  </div>
</div>

<div *ngIf="orderdata != undefined && Order == 'AO'">
  <form nz-form>
    <nz-spin [nzSpinning]="isSpinning">
      <div nz-row [nzGutter]="10">
        <div
          nz-col
          nzXs="8"
          nzSm="8"
          nzMd="8"
          nzLg="8"
          nzXl="8"
          nzSpan="8"
          style="height: 590px; overflow-y: scroll"
        >
          <div class="my-box">
            <div nz-row>
              <div
                style="display: none"
                nz-col
                nzXs="12"
                nzSm="12"
                nzMd="12"
                nzLg="12"
                nzXl="12"
                nzSpan="12"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>Date</nz-form-label>

                  <nz-form-control>
                    <nz-date-picker
                      style="width: 100%"
                      nzFormat="dd/MM/yyyy"
                      name="printedDate"
                      [(ngModel)]="orderdata.ORDER_DATE"
                    >
                    </nz-date-picker>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <!-- <div nz-col nzXs="12" nzSm="12" nzMd="12" nzLg="12" nzXl="12" nzSpan="12">
                                <nz-form-item>
                                    <nz-form-label nzRequired nzNoColon>From Date</nz-form-label>

                                    <nz-form-control>
                                        <nz-date-picker style="width: 100%;" nzFormat="dd/MM/yyyy" name="fromDate"
                                            [(ngModel)]="FROM_DATE">
                                        </nz-date-picker>
                                    </nz-form-control>
                                </nz-form-item>
                            </div> -->

              <!-- <div nz-col nzXs="12" nzSm="12" nzMd="12" nzLg="12" nzXl="12" nzSpan="12">
                                <nz-form-item>
                                    <nz-form-label nzRequired nzNoColon>To Date</nz-form-label>

                                    <nz-form-control>
                                        <nz-date-picker style="width: 100%;" nzFormat="dd/MM/yyyy" name="toDate"
                                            [(ngModel)]="TO_DATE">
                                        </nz-date-picker>
                                    </nz-form-control>
                                </nz-form-item>
                            </div> -->
            </div>

            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <!-- <div>
                  <nz-form-item>
                    <nz-form-label  nzNoColon>File No.</nz-form-label>  
                    <nz-radio-group
                      [(ngModel)]="orderdata.PAY_BILL"
                      name="radiogaz"
                    >
                      <label nz-radio nzValue="NonGazPay">Welfare & S.G </label>
                      <label nz-radio nzValue="GazPay">Gaz Pay Bill</label>
                    </nz-radio-group>
                  </nz-form-item>
                </div> -->
                <!-- <nz-form-item>
                  <nz-form-label nzNoColon>File No.</nz-form-label>

                  <nz-form-control nzHasFeedback>
                    <nz-select
                      *ngIf="
                        orderdata.FILE_NUMBER == undefined ||
                        orderdata.FILE_NUMBER == null
                      "
                      nzShowSearch
                      name="FILE_NUMBER"
                      [nzOptionHeightPx]="60"
                      [(ngModel)]="orderdata.FILE_ID"
                    >
                      <nz-option
                        *ngFor="let option of fileList"
                        [nzLabel]="option.FILE_NUMBER"
                        [nzValue]="option.ID"
                        nzCustomContent
                      >
                        <div style="background-color: #fffcfc; padding: 5px">
                          <div nz-row>
                            <div
                              nz-col
                              nzSpan="24"
                              style="width: 100%; color: black; font-size: 14px"
                            >
                              <b>{{ option.FILE_NAME }} </b>
                            </div>

                            <div
                              nz-col
                              nzSpan="24"
                              style="font-size: 14px; font-weight: 500"
                            >
                              {{ option.FILE_NUMBER }}
                            </div>
                          </div>
                        </div>
                      </nz-option>
                    </nz-select>
                    <input
                      style="width: 100%"
                      nz-input
                      type="text"
                      *ngIf="
                        orderdata.FILE_NUMBER != undefined &&
                        orderdata.FILE_NUMBER != null
                      "
                      name="FILE_NUMBERsafsf"
                      [(ngModel)]="orderdata.FILE_NUMBER"
                      disabled="true"
                    />
                  </nz-form-control>
                </nz-form-item> -->
              </div>
            </div>
            <div nz-row>
              <div nz-col nzSpan="24">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>Signature</nz-form-label>
                  <nz-form-control>
                    <nz-select
                      name="userid"
                      [(ngModel)]="orderdata.SIGNATURE_ID"
                      nzAllowClear
                      nzPlaceHolder="Choose Signature"
                      (ngModelChange)="signature($event)"
                    >
                      <nz-option
                        *ngFor="let user of Signaturearray"
                        [nzValue]="user.ID"
                        [nzLabel]="user.NAME"
                      >
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>

            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Order Number</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Order no. is required"
                  >
                    <input
                      style="width: 100%"
                      name="OrderNumber"
                      nz-input
                      placeholder="Enter order number"
                      type="text"
                      [(ngModel)]="orderdata.ORDER_NO"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzNoColon>Name of applicant</nz-form-label>

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Name of applicant is required"
                  >
                    <input
                      style="width: 100%"
                      name="EMPLOYEE_NAME"
                      nz-input
                      placeholder="Enter name in english"
                      autocomplete="off"
                      type="text"
                      disabled="true"
                      [(ngModel)]="orderdata.EMPLOYEE_NAME"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Name of applicant (in hindi)</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="City Name is required"
                  >
                    <input
                      style="width: 100%"
                      nz-input
                      placeholder="Enter name in hindi"
                      autocomplete="off"
                      name="EMPLOYEE_NAME_HN"
                      type="text"
                      [(ngModel)]="orderdata.EMPLOYEE_NAME_HN"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzNoColon>Designation</nz-form-label>

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Enter Designation in English"
                  >
                    <input
                      style="width: 100%"
                      name="DESIGNATION"
                      nz-input
                      placeholder="Enter Designation in English"
                      autocomplete="off"
                      type="text"
                      disabled="true"
                      [(ngModel)]="orderdata.DESIGNATION"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Designation (in hindi)</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Enter Designation In Hindi"
                  >
                    <input
                      style="width: 100%"
                      nz-input
                      placeholder="Enter Designation in hindi"
                      autocomplete="off"
                      name="DESIGNATION_NAME_HN"
                      type="text"
                      [(ngModel)]="orderdata.DESIGNATION_NAME_HN"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>

            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzNoColon>Office Name</nz-form-label>

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Enter Office Name in English"
                  >
                    <input
                      style="width: 100%"
                      name="OFFICE_NAME"
                      nz-input
                      placeholder="Enter Office Name in English"
                      autocomplete="off"
                      type="text"
                      disabled="true"
                      [(ngModel)]="orderdata.OFFICE_NAME"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Office Name (in hindi)</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Enter Office Name In Hindi"
                  >
                    <input
                      style="width: 100%"
                      nz-input
                      placeholder="Enter Office Name in hindi"
                      autocomplete="off"
                      name="OFFICE_NAME_HN"
                      type="text"
                      [(ngModel)]="orderdata.OFFICE_NAME_HN"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>

            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzNoColon>Name of patient </nz-form-label>

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Name of patient is required"
                  >
                    <input
                      style="width: 100%"
                      name="PATIENT_NAME"
                      nz-input
                      placeholder="Enter name in english"
                      autocomplete="off"
                      type="text"
                      disabled="true"
                      [(ngModel)]="orderdata.PATIENT_NAME"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Name of patient(in hindi)</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Name of patient(in hindi) is required"
                  >
                    <input
                      style="width: 100%"
                      nz-input
                      placeholder="Enter name of patient in hindi"
                      autocomplete="off"
                      name="PATIENT_NAME_HN"
                      type="text"
                      [(ngModel)]="orderdata.PATIENT_NAME_HN"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Cheque drawn in form of</nz-form-label>
                <nz-form-control >
                  <nz-switch
                    [(ngModel)]="orderdata.CHEQUE_DRAWN"
                    name="CHEQUE_DRAWN"
                    nzCheckedChildren="Yes"
                    nzUnCheckedChildren="No"
                  >
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
              </div>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
                *ngIf="orderdata.CHEQUE_DRAWN==1"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >ZAO Hindi Remark</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Enter ZAO Hindi Remark"
                  >
                    <input
                      style="width: 100%"
                      nz-input
                      placeholder="Enter ZAO Hindi Remark"
                      autocomplete="off"
                      name="ZAO_HN"
                      type="text"
                      [(ngModel)]="orderdata.ZAO_HN"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
                *ngIf="orderdata.CHEQUE_DRAWN==1"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >ZAO English Remark</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Enter ZAO English Remark"
                  >
                    <input
                      style="width: 100%"
                      nz-input
                      placeholder="Enter ZAO English Remark"
                      autocomplete="off"
                      name="ZAO"
                      type="text"
                      [(ngModel)]="orderdata.ZAO"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
          <div *ngFor="let data of HospitalMapping; let i = index">
            <div class="my-box">
              <div nz-row>
                <div nz-col nzSpan="24">
                  <nz-form-item>
                    <nz-form-label nzNoColon> Name of Hospital </nz-form-label>

                    <nz-form-control
                      nzHasFeedback
                      nzErrorTip="Name of Hospital is required"
                    >
                      <input
                        style="width: 100%"
                        name="NAMENAME{{ i }}"
                        nz-input
                        placeholder="Enter name in english"
                        autocomplete="off"
                        type="text"
                        disabled="true"
                        [(ngModel)]="data.NAME"
                      />
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
              <div nz-row>
                <div
                  nz-col
                  nzXs="24"
                  nzSm="24"
                  nzMd="24"
                  nzLg="24"
                  nzXl="24"
                  nzSpan="24"
                >
                  <nz-form-item>
                    <nz-form-label nzRequired nzNoColon
                      >Name of Hospital(in hindi)</nz-form-label
                    >

                    <nz-form-control
                      nzHasFeedback
                      nzErrorTip="Name of Hospital(in hindi) is required"
                    >
                      <input
                        style="width: 100%"
                        nz-input
                        placeholder="Enter name of Hospital in hindi"
                        autocomplete="off"
                        name="NAME_HN{{ i }}"
                        type="text"
                        [(ngModel)]="data.NAME_HN"
                      />
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>

              <div nz-row>
                <div
                  nz-col
                  nzXs="24"
                  nzSm="24"
                  nzMd="24"
                  nzLg="24"
                  nzXl="24"
                  nzSpan="24"
                >
                  <nz-form-item>
                    <nz-form-label nzNoColon
                      >Address of Hospital
                    </nz-form-label>

                    <nz-form-control
                      nzHasFeedback
                      nzErrorTip="Name of Address is required"
                    >
                      <input
                        style="width: 100%"
                        name="ADDRESS{{ i }}"
                        nz-input
                        placeholder="Enter Address in english"
                        autocomplete="off"
                        type="text"
                        [(ngModel)]="data.ADDRESS"
                      />
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
              <div nz-row>
                <div
                  nz-col
                  nzXs="24"
                  nzSm="24"
                  nzMd="24"
                  nzLg="24"
                  nzXl="24"
                  nzSpan="24"
                >
                  <nz-form-item>
                    <nz-form-label nzRequired nzNoColon
                      >Address of Hospital(in hindi)</nz-form-label
                    >

                    <nz-form-control
                      nzHasFeedback
                      nzErrorTip="Address of Hospital(in hindi) is required"
                    >
                      <input
                        style="width: 100%"
                        nz-input
                        placeholder="Enter Address of Hospital in hindi"
                        autocomplete="off"
                        name="ADDRESS_HN{{ i }}"
                        type="text"
                        [(ngModel)]="data.ADDRESS_HN"
                      />
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>

              <!-- <div>
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Show In Order</nz-form-label
                  >
                  <nz-form-control>
                    <nz-switch
                      name="IS_VISIBLE_IN_ORDER{{ i }}"
                      nzCheckedChildren="Yes"
                      nzUnCheckedChildren="No"
                      [(ngModel)]="HospitalMapping[i].IS_VISIBLE_IN_ORDER"
                    ></nz-switch>
                  </nz-form-control>
                </nz-form-item>
              </div> -->
            </div>
          </div>
          <!-- <div nz-row *ngIf="orderdata.HOSPITAL_TYPE == 'E'">
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzNoColon>Name of Hospital </nz-form-label>

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Name of patient is required"
                  >
                    <input
                      style="width: 100%"
                      name="EMPANELLED_HOSPITAL_NAME"
                      nz-input
                      placeholder="Enter name in english"
                      autocomplete="off"
                      type="text"
                      disabled="true"
                      [(ngModel)]="orderdata.EMPANELLED_HOSPITAL_NAME"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div> -->
          <div class="my-box">
            <div nz-row>
              <div
                nz-col
                nzXs="12"
                nzSm="12"
                nzMd="12"
                nzLg="12"
                nzXl="12"
                nzSpan="12"
              >
                <nz-form-item>
                  <nz-form-label nzNoColon>Realationship</nz-form-label>

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Realationship is required"
                  >
                    <input
                      style="width: 100%"
                      nz-input
                      placeholder="Enter Realationship"
                      autocomplete="off"
                      name="RELATION_WITH_PATIENT"
                      disabled="true"
                      type="text"
                      [(ngModel)]="orderdata.RELATION_WITH_PATIENT"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <!-- <div
                nz-col
                nzXs="12"
                nzSm="12"
                nzMd="12"
                nzLg="12"
                nzXl="12"
                nzSpan="12"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Realationship(in hindi)</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Realationship(in hindi) is required"
                  >
                    <input
                      style="width: 100%"
                      nz-input
                      placeholder="Enter Realationship in hindi"
                      autocomplete="off"
                      name="RELATION_WITH_PATIENT_MR"
                      type="text"
                      [(ngModel)]="orderdata.RELATIONSHIP_HN"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div> -->
            </div>
          </div>
          <!-- <div nz-row>
                            <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" nzSpan="24">
                                <nz-form-item>
                                    <nz-form-label  nzNoColon>Amount (in words)</nz-form-label>

                                    <nz-form-control nzHasFeedback nzErrorTip="City Name is required">
                                        <input style="width: 100%;" nz-input placeholder="Enter Amount"
                                            autocomplete="off"  name="amountinwords" type="text"
                                            [(ngModel)]="amountinwords" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                           
                        </div>
                        <div nz-row>
                            <div  nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" nzSpan="24">
                                <nz-form-item>
                                    <nz-form-label nzRequired nzNoColon>Amount (in hindi)</nz-form-label>

                                    <nz-form-control nzHasFeedback nzErrorTip="City Name is required">
                                        <input style="width: 100%;" nz-input placeholder="Enter amount in hindi"
                                            autocomplete="off" name="amountinwordsh"  type="text"
                                            [(ngModel)]="amountinwordsh" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div> -->
        </div>

        <div nz-col nzXs="16" nzSm="16" nzMd="16" nzLg="16" nzXl="16">
          <div
            class="my-box"
            style="padding: 10px; height: 590px; overflow-y: scroll"
          >
            <div>
              <div nz-row>
                <div
                  style="border: 1px solid"
                  nz-col
                  nzXs="4"
                  nzSm="4"
                  nzMd="4"
                  nzLg="4"
                  nzXl="4"
                >
                  <div style="text-align: center; padding: 36px 9px 9px 9px">
                    <img
                      src="assets/govtOfIndia.png"
                      alt=""
                      height="140px"
                      width="100%"
                    />
                  </div>
                </div>
                <!-- <div
                  style="
                    border-bottom: 1px solid;
                    border-top: 1px solid;
                    border-right: 1px solid;
                  "
                  nz-col
                  nzXs="1"
                  nzSm="1"
                  nzMd="1"
                  nzLg="1"
                  nzXl="1"
                ></div> -->
                <div
                  style="
                    border-bottom: 1px solid;
                    border-top: 1px solid;
                    border-right: 1px solid;
                  "
                  nz-col
                  nzXs="20"
                  nzSm="20"
                  nzMd="20"
                  nzLg="20"
                  nzXl="20"
                >
                  <div
                    style="width: 100%; text-align: center; font-weight: bold"
                  >
                    <div>भारत सरकार</div>
                    <div style="text-transform: uppercase" class="family">
                      Government Of India
                    </div>
                    <div>कार्यालय, प्रधान मुख्य आयकर आयुक्त, मुंबई</div>
                    <div style="text-transform: uppercase" class="family">
                      OFFICE OF THE
                    </div>
                    <div style="text-transform: uppercase" class="family">
                      PRINCIPAL CHIEF COMMISSIONER OF INCOME TAX,
                    </div>
                    <div class="family" style="text-transform: uppercase">
                      MUMBAI
                    </div>
                    <!-- <div>
                      तीसरी मंजिल, आयकर भवन, महर्षी कर्वे रोड, मुंबई -20
                    </div> -->
                    <div style="text-transform: uppercase" class="family">
                      3<sup style="text-transform: lowercase">rd</sup> Floor,
                      aayakar bhavan, Maharshi Karve Road, Mumbai - 400020
                    </div>
                    <div>
                      (022) - 22016691/22077187 / Fax: 022- 22079273/22077187
                    </div>
                    <div class="family">
                      Email:
                      <span *ngIf="orderdata.SIGNATURE_ID == 2">
                        mumbai.ito.gpbs2@incometax.gov.in
                      </span>
                      <span *ngIf="orderdata.SIGNATURE_ID == 3">
                        mumbai.ito.hq.pers@incometax.gov.in
                      </span>
                      <!-- <span *ngIf="userId != 18 && userId != 48">
                        mumbai.ito.hq.pers@incometax.gov.in</span
                      > -->
                    </div>
                  </div>
                </div>
              </div>

              <div
                style="
                  width: 100%;
                  padding: 5px;
                  font-size: 13px;
                  border-bottom: 1px solid;
                  border-left: 1px solid;
                  border-right: 1px solid;
                "
              >
                <div nz-row>
                  <div nz-col nzSpan="17">{{ orderdata.ORDER_NO }}</div>
                  <div nz-col nzSpan="1"></div>
                  <div nz-col nzSpan="6">
                    दिनांक
                    :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                  <!-- {{ orderdata.ORDER_DATE | date : "dd MMM yyyy" }} -->
                </div>
              </div>
              <div
                style="font-family: Arial, Helvetica, sans-serif !important"
                *ngIf="orderdata.IS_ADVANCE_TAKEN == 0"
              >
                <div
                  style="
                    text-align: center;
                    margin-top: 10px;
                    margin-bottom: 5px;
                    font-size: 13px;
                  "
                >
                  <b>स्वीकृति आदेश/ SANCTION ORDER</b>
                </div>
                <div style="font-size: 13px !important">
                  <p style="margin-bottom: 2px">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {{ orderdata.EMPLOYEE_NAME_HN }},
                    {{ orderdata.DESIGNATION_NAME_HN }}, को उनके द्वारा दिनांक
                    {{
                      orderdata.TREATMENT_START_DATE | date : "dd MMM yyyy"
                    }}&nbsp; से
                    {{
                      orderdata.TREATMENT_END_DATE | date : "dd MMM yyyy"
                    }}&nbsp; तक

                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                      {{ data.NAME_HN }} , {{ data.ADDRESS_HN }}
                      <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                      <span *ngIf="HospitalMapping.length - 2 == k">और</span>
                      <!-- </span> -->
                    </span>

                    में
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Father'"
                        >उनके पिता</span
                      >
                      <!-- <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Father'
                        "
                        >उनके दादाजी</span
                      > -->
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Wife'"
                        >उनकी पत्नी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Husband'"
                        >उनके पती</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Son'"
                        >उनका बेटा</span
                      >
                      <span
                        *ngIf="orderdata.RELATION_WITH_PATIENT == 'Daughter'"
                        >उनकी बेटी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Sister'"
                        >उनकी बहन</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Brother'"
                        >उनके भाई</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'"
                        >उनकी मां</span
                      >
                      <!-- <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Mother'
                        "
                        >उनकी दादी मां</span
                      > -->
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Father-In-Law'
                        "
                        >उनके ससुर</span
                      >
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Mother-In-Law'
                        "
                        >उनकी सासू मां</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} के</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} के</span
                      >
                    </span>

                    &nbsp;
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      स्वयं के</span
                    >
                    {{ orderdata.NATURE_OF_TREATMENT }}
                    के लिए खर्च की गई राशि,
                    <!-- के चिकित्सा हेतु परमार्श और दवा के लिए खर्च की गई राशि, -->
                    <span
                      >जो
                      <b>
                        {{
                          orderdata.FINAL_REMAINING_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-
                        <!-- &nbsp; ({{ amountinwordsh }}) -->
                      </b>
                      जो कि स्वीकार्य राशि
                      <b>
                        {{
                          orderdata.FINAL_ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-</b
                      >की शेष राशि से अधिक नहीं है,</span
                    >
                    को सीजीएचएस दर के अनुसार चिकित्सा व्यय की प्रतिपूर्ती की
                    स्वीकृति प्रदान की जाती है। दिनांक
                    {{ orderdata.ADVANCE_TAKEN_DATE | date : "dd/MM/yyyy" }} को
                    <b>
                      {{
                        orderdata.ADMISSIBLE_AMOUNT
                          | currency:'INR':'symbol'
                      }}/-
                      <!-- &nbsp; ({{ amountinwordsh }}) -->
                    </b>
                    रुपये की
                    <span *ngIf="isAdvanceSameAsAdmissible == true">
                      स्वीकार्य राशि का 90% होने के कारण
                    </span>
                    <b>
                      {{
                        orderdata.ADVANCE_AMOUNT
                          | currency:'INR':'symbol'
                      }}/-</b
                    >
                    रुपये की अग्रिम राशी मंजुरी की गई थी।
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CG'"
                      >यह स्वीकृति स्वास्थ्य एवं परिवार कल्याण मंत्रालय, भारत
                      सरकार द्वारा जारी दिनांक 18.03.1992 के कार्यालय ज्ञापन सं.
                      एस-11012/1/91/सीजीएचएस (पी) खंड 1 के माध्यम से मुझे
                      प्रदत्त शक्तियो का प्रयोग करते हुए जारी की जाती है।</span
                    >
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CS'">
                      यह स्वीकृति आदेश सेंट्रल सर्विसेस (मेडिकल अटेनडंस),
                      नियमावली, 1944 के परिशिष्ठ । के प्रावधानों के अनुसार जारी
                      किया जाता है।
                    </span>
                  </p>

                  <p style="margin-bottom: 2px">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sanction is
                    hereby accorded for the reimbursement of medical expenses as
                    per CGHS rate for an
                    <span>
                      amount not exceeding
                      <b>
                        {{
                          orderdata.FINAL_REMAINING_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-
                        <!-- &nbsp; ({{ amountinwords }}) -->
                      </b>
                    </span>
                    being balance amount of the admissible amount of
                    <b>
                      {{
                        orderdata.FINAL_ADMISSIBLE_AMOUNT
                          | currency:'INR':'symbol'
                      }}/-</b
                    >
                    incurred by {{ orderdata.EMPLOYEE_NAME }}&nbsp;,
                    {{ orderdata.DESIGNATION }}
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      for his/her {{ orderdata.RELATION_WITH_PATIENT }}
                      {{ orderdata.PATIENT_NAME }}</span
                    >

                    &nbsp; for&nbsp;{{ orderdata.NATURE_OF_TREATMENT }}
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      of Self</span
                    >, at
                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                      {{ data.NAME }} ,{{ data.ADDRESS }}
                      <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                      <span *ngIf="HospitalMapping.length - 2 == k">and</span>
                      <!-- </span> -->
                    </span>

                    from
                    {{ orderdata.TREATMENT_START_DATE | date : "dd MMM yyyy" }}
                    to
                    {{ orderdata.TREATMENT_END_DATE | date : "dd MMM yyyy" }}.
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CG'">
                      An advance amount of
                      <b>
                        {{
                          orderdata.ADVANCE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-</b
                      >
                      <span *ngIf="isAdvanceSameAsAdmissible == true">
                        being if 90%
                      </span>
                      of admissible amount of
                      {{
                        orderdata.ADMISSIBLE_AMOUNT
                          | currency:'INR':'symbol'
                      }}/- was sanctioned on date
                      {{ orderdata.ADVANCE_TAKEN_DATE | date : "dd/MM/yyyy" }}.
                      This sanction is issued in exercise of the powers vested
                      in me vide Office Memorandum No.S-11012/1/91/CGHS(P) Vol.
                      I dated 18.03.1992 issued by the Ministry of Health &
                      Family Welfare , Government of India.</span
                    >
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CS'">
                      An advance amount of
                      <b>
                        {{
                          orderdata.ADVANCE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-</b
                      >
                      <span *ngIf="isAdvanceSameAsAdmissible == true">
                        being if 90%
                      </span>
                      of admissible amount of
                      {{
                        orderdata.ADMISSIBLE_AMOUNT
                          | currency:'INR':'symbol'
                      }}/- was sanctioned on date
                      {{ orderdata.ADVANCE_TAKEN_DATE | date : "dd/MM/yyyy" }}.
                      This sanction is issued in accordance with the provisions
                      of Appendix VIII of the Central Services (Medical
                      Attendance) Rules, 1944.
                    </span>
                  </p>

                  <!-- <p
                    style="margin-bottom: 15px; margin-top: 10px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    यह राशि डिस्चार्ज की तारीख से एक महीने के भीतर श्री
                    {{ orderdata.EMPLOYEE_NAME_HN }} द्वारा प्रस्तुत किए जाने
                    वाले नियमित दावों के खिलाफ समायोजित की जाएगी।
                  </p> -->

                  <!-- <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    This amount will be adjusted against regular claims ,
                    within one month from the date of discharge. to be submitted
                    by {{ orderdata.EMPLOYEE_NAME }}.
                  </p> -->

                  <p
                    style="margin-bottom: 15px; margin-top: 10px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    उक्त व्यय को वित्तीय वर्ष 2023-2024 के लिए विभाग को आबंटित
                    मुख्य शीर्ष "2020 चिकित्सा" के अंतर्गत अनुदान के नामे डाला
                    जाए।
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    The above expenditure should be debited to the grant under
                    the major head "2020 - Medical allotted to the Department
                    for Financial Year 2023-2024.
                  </p>

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 0
                    "
                  >
                    यह आपात स्थिति का मामला था, जिसके लिए तत्काल उपचार की
                    आवश्यकता थी अतः गैर-पैनलबद्ध अस्पताल में भर्ती कराया गया था|
                  </p>

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 0
                    "
                  >
                    It was a case of emergency , necessitating immediate
                    treatment and was admitted in a non-empanelled hospital.
                  </p>

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 1 &&
                        orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 0 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 1 &&
                        orderdata.IS_HOSPITAL_EMPLANELLED == 0 &&
                        orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 0 &&
                        orderdata.EMERGENCY_TREATEMENT == 0) ||
                      orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1
                    "
                  >
                    दिनांक
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0">
                      {{ orderdata.EXPO_FACTO_DATE }}
                    </span>
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1">
                      {{ orderdata.HOD_PERMISSION_DATE }}
                    </span>
                    <!-- के पत्र द्वारा उपरोक्त उपचार के लिये कार्योत्तर अनुमति -->
                    के पत्र द्वारा उपरोक्त उपचार के लिये अनुमति प्रदान की गई है।
                  </p>

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 1 &&
                        orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 0 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 1 &&
                        orderdata.IS_HOSPITAL_EMPLANELLED == 0 &&
                        orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 0 &&
                        orderdata.EMERGENCY_TREATEMENT == 0) ||
                      orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1
                    "
                  >
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0">
                      Expost Facto Permission for the above treatment has been
                      granted vide letter dated {{ orderdata.EXPO_FACTO_DATE }}
                    </span>
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1">
                      Permission for the above treatment has been granted vide
                      letter dated {{ orderdata.HOD_PERMISSION_DATE }}
                    </span>
                  </p>
                  <!-- <p
                *ngIf="
                  orderdata.IS_BILL_FILLED_IN_TIME == 1 &&
                  orderdata.IS_HOSPITAL_EMPLANELLED == 1 &&
                  orderdata.EXPO_FACTO_PERMISSION == 1
                "
              >
                दिनांक 10/01/2023 के पत्र द्वारा उपरोक्त उपचार के लिए अनुमति
                प्रदान की गई है।
              </p>
              <p
                *ngIf="
                  orderdata.IS_BILL_FILLED_IN_TIME == 1 &&
                  orderdata.IS_HOSPITAL_EMPLANELLED == 1 &&
                  orderdata.EXPO_FACTO_PERMISSION == 1
                "
              >
                Permission for the above treatment has been granted vide letter
                dated 10/01/2023
              </p> -->

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 1
                    "
                  >
                    यह आपात स्थिति का मामला था जिसमें तत्काल प्रवेश की आवश्यकता
                    थी और उसे पैनलबद्ध अस्पताल में भर्ती कराया गया था.
                  </p>
                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 1
                    "
                  >
                    It was a case of Emergency necessitating immediate admission
                    and was admitted in empanelled hospital.
                  </p>
                  <p style="margin-bottom: 2px">
                    उक्त व्यय को वित्तीय वर्ष 2023-2024 के लिए विभाग को आबंटित
                    अनुदान के अंतर्गत मुख्य शीर्ष "2020-चिकित्सा उपचार" से किया
                    जाए।
                  </p>

                  <p style="margin-bottom: 2px">
                    The above expenditure should be debited to the grant under
                    major head 2020 - Medical Treatment Alloted to the
                    Department for the
                    <b>F.Y 2023-2024.</b>
                  </p>

                  <p style="margin-bottom: 2px">
                    हिंदी और अंग्रेजी संस्करण में किसी भी भिन्नता के मामले में
                    आदेश का अंग्रेजी संस्करण मान्य होगा।
                  </p>

                  <p style="margin-bottom: 2px">
                    The English version of the order will prevail in case of any
                    variation in Hindi & English version.
                  </p>

                  <div style="margin-top: 60px; font-size: 13px">
                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div><b> (विनय सिन्हा) </b></div>
                        <div style="font-weight: bold">
                          आयकर आयुक्त (प्रशासन एवं करदाता सेवाएँ) , मुंबई
                        </div>
                      </div>
                    </div>

                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div><b> (Vinay Sinha) </b></div>
                        <div style="font-weight: bold; font-size: 13px">
                          Commissioner Of Income Tax(Admin & TPS),Mumbai.
                          <!-- <span *ngIf="orderdata.SIGNATURE_ID != 2"
                            >Commissioner Of Income Tax(Admin &
                            TPS),Mumbai.</span
                          >
                          <span *ngIf="orderdata.SIGNATURE_ID == 2">
                            For Principal Chief Commissioner of Income Tax
                            Mumbai</span
                          > -->
                        </div>
                        <!-- <div
                          style="font-weight: bold; font-size: 13px"
                          *ngIf="orderdata.SIGNATURE_ID != 3"
                        >
                          for Pr. Chief Commissioner of Income-Tax, Mumbai
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      style="
                        page-break-inside: avoid !important;
                        font-size: 13px;
                        page-break-before: avoid !important;
                      "
                    >
                      <div style="margin-top: 30px">
                        <b>प्रतिलिपी/Copy to:</b>
                      </div>
                      <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                        <span>
                          1)अराजपत्रित वेतन बिल, मुंबई (2 प्रतियां) फॉर्म एमआरसी
                          (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ
                        </span>
                        <br />
                        <span style="padding-left: 15px">
                          The Non Gazetted Pay Bill Section (2 copies) alongwith
                          Form MRC(S) , hospital receipts , discharge summary
                          etc.
                        </span>
                        <br />

                        <!-- <span *ngIf="SECTION_TYPE == 'welfare'">
                          1) डीडीओ, कल्याण और एसजी,मुंबई (2 प्रतियां) फॉर्म
                          एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के
                          साथ|</span
                        > <br>
                        <span *ngIf="SECTION_TYPE == 'gazpay'">
                          1) डीडीओ, राजपत्रित वेतन बिल अनुभाग II (कार्मिक),मुंबई
                          (2 प्रतियां) फॉर्म एमआरसी (एस), अस्पताल रसीद,
                          डिस्चार्ज सारांश आदि के साथ |</span
                        >
                        <br>
                        <span
                        *ngIf="SECTION_TYPE == 'welfare'"
                        style="padding-left: 15px"
                      >
                        DDO , Welfare & SG , Mumbai (2 copies) alongwith Form
                        MRC(S) , hospital receipts , discharge summary etc.
                      </span>
                      <br>
                      <span
                        *ngIf="SECTION_TYPE == 'gazpay'"
                        style="padding-left: 15px"
                      >
                        DDO , Gazetted Pay bill Section II, Personnel Mumbai
                        (2 copies) alongwith Form MRC(S) , hospital receipts ,
                        discharge summary etc.
                      </span> -->

                      <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 0">
                        <div>
                          2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                          <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                          तैयार किया जा सकता है। |
                        </div>
                        <div>
                           ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                          in favour of {{ orderdata.EMPLOYEE_NAME }}.
                        </div>
                      </div>
                      <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                        <div  *ngIf="orderdata.CHEQUE_DRAWN==0">
                          2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                          <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                          तैयार किया जा सकता है। |
                        </div>
                        <div  *ngIf="orderdata.CHEQUE_DRAWN==0">
                           ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                          in favour of {{ orderdata.EMPLOYEE_NAME }}.
                        </div>
                        <div  *ngIf="orderdata.CHEQUE_DRAWN==1">
                          2) {{orderdata.ZAO_HN}}
                        </div>
                        <div  *ngIf="orderdata.CHEQUE_DRAWN==1">
                            {{orderdata.ZAO}}
                        </div>
                      </div>

                        <div>
                          3)<b>
                            {{ orderdata.EMPLOYEE_NAME_HN }},
                            {{ orderdata.DESIGNATION_NAME_HN }} कार्यालय
                            {{ orderdata.OFFICE_NAME_HN }}
                            <!-- {{ orderdata.LOCATION_HN }}., -->
                          </b>
                          स्वीकार्य राशि की गणना की प्रति के साथ |
                        </div>

                        <div style="padding-left: 15px">
                          <b
                            >{{ orderdata.EMPLOYEE_NAME }},
                            {{ orderdata.DESIGNATION }} O/o
                            {{ orderdata.EMPLOYEE_OFFICE_NAME }}
                            <!-- {{ orderdata.LOCATION }}., -->
                          </b>
                          along with copy of working of admissible amount.
                        </div>
                      </div>

                      <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 0">
                        <div>
                          <span *ngIf="SECTION_TYPE == 'welfare'">
                            1) डीडीओ, कल्याण और एसजी,मुंबई (2 प्रतियां) फॉर्म
                            एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के
                            साथ|</span
                          >
                          <span *ngIf="SECTION_TYPE == 'gazpay'">
                            1) डीडीओ, राजपत्रित वेतन बिल अनुभाग II
                            (कार्मिक),मुंबई (2 प्रतियां) फॉर्म एमआरसी (एस),
                            अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ |</span
                          >
                          <!-- 1) डीडीओ,कल्याण और एसजी,मुंबई (2 प्रतियां)
                      फॉर्म एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ| -->
                        </div>
                        <div>
                          <!-- 1) DDO , Welfare & SG , Mumbai (2 copies) alongwith Form
                      MRC(S) , hospital receipts , discharge summary etc. -->

                          <span
                            *ngIf="SECTION_TYPE == 'welfare'"
                            style="padding-left: 15px"
                          >
                            DDO , Welfare & SG , Mumbai (2 copies) alongwith
                            Form MRC(S) , hospital receipts , discharge summary
                            etc.
                          </span>
                          <span
                            *ngIf="SECTION_TYPE == 'gazpay'"
                            style="padding-left: 15px"
                          >
                            DDO , Gazetted Pay bill Section II, Personnel Mumbai
                            (2 copies) alongwith Form MRC(S) , hospital receipts
                            , discharge summary etc.
                          </span>
                        </div>
                        <div>
                          2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                          <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                          तैयार किया जा सकता है।
                        </div>
                        <div style="padding-left: 15px">
                            ZAO , Pre-check unit , Mumbai - Cheque may be drawn in
                          favour of {{ orderdata.EMPLOYEE_NAME }}.
                        </div>

                        <div>
                          3)<b>
                            {{ orderdata.EMPLOYEE_NAME_HN }},
                            {{ orderdata.DESIGNATION_NAME_HN }} कार्यालय
                            {{ orderdata.OFFICE_NAME_HN }}
                            <!-- {{ orderdata.LOCATION_HN }}., -->
                          </b>
                          स्वीकार्य राशि की गणना की प्रति के साथ |
                        </div>

                        <div style="padding-left: 15px">
                          <b
                            >{{ orderdata.EMPLOYEE_NAME }},
                            {{ orderdata.DESIGNATION }} O/o
                            {{ orderdata.EMPLOYEE_OFFICE_NAME }}
                            <!-- {{ orderdata.LOCATION }}., -->
                          </b>
                          along with copy of working of admissible amount.
                        </div>
                      </div>
                      <div style="margin-top: 60px">
                        <div nz-row>
                          <div nz-col nzSpan="12"></div>

                          <div nz-col nzSpan="12" style="text-align: center">
                            <div style="font-weight: bold">({{ NAME_HN }})</div>
                            <div>
                              <b> {{ POST_HN }} </b>
                            </div>
                            <div>
                              <b> {{ OFFICE_NAME_HN }} </b>
                            </div>
                            <div>
                              <b> ({{ SIGNNAME }}) </b>
                            </div>
                            <div>
                              <b>{{ POST }} </b>
                            </div>
                            <div>
                              <b>{{ OFFICE_NAME }} </b>
                            </div>
                            <!-- <span *ngIf="SECTION_TYPE == 'welfare'">
                              <div style="font-weight: bold">
                                (रीतेश कुमार)
                              </div>
                              <div>आयकर अधिकारी (मुख्‍यालय)</div>
                              <div>कल्याण और कर्मचारी शिकायत, मुंबई</div>
                            </span>

                            <span *ngIf="SECTION_TYPE == 'gazpay'">
                              <div style="font-weight: bold">
                                (जितेंद्र रा चांदेकर)
                              </div>
                              <div>आयकर अधिकारी</div>
                              <div>राजपत्रित वेतन बिल अनुभाग II, मुंबई</div>
                            </span> -->
                          </div>
                        </div>

                        <!-- <div nz-row>
                          <div nz-col nzSpan="15"></div>

                          <div nz-col nzSpan="9" style="text-align: center">
                            <span *ngIf="SECTION_TYPE == 'welfare'">
                              <div style="font-weight: bold">
                                (Ritesh Kumar)
                              </div>
                              <div>Income Tax Officer(HQ)</div>
                              <div>Welfare & S.G, Mumbai</div>
                            </span>
                            <span *ngIf="SECTION_TYPE == 'gazpay'">
                              <div style="font-weight: bold">
                                (Jitendra R Chandekar)
                              </div>
                              <div>Income Tax Officer</div>
                              <div>
                                Gazetted Pay Bill Section II Personnel, Mumbai
                              </div>
                            </span>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style="font-family: Arial, Helvetica, sans-serif !important"
                *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
              >
                <div
                  style="
                    text-align: center;
                    margin-top: 20px;
                    margin-bottom: 10px;
                    font-size: 13px;
                  "
                >
                  <b>स्वीकृति आदेश/ SANCTION ORDER</b>
                </div>
                <div style="font-size: 13px !important">
                  <p style="margin-bottom: 2px">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {{ orderdata.EMPLOYEE_NAME_HN }}
                    {{ orderdata.DESIGNATION_NAME_HN }}, को उनके द्वारा दिनांक
                    {{
                      orderdata.TREATMENT_START_DATE | date : "dd MMM yyyy"
                    }}&nbsp; से
                    {{
                      orderdata.TREATMENT_END_DATE | date : "dd MMM yyyy"
                    }}&nbsp; को

                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                      {{ data.NAME_HN }} , {{ data.ADDRESS_HN }}
                      <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                      <span *ngIf="HospitalMapping.length - 2 == k">और</span>
                      <!-- </span> -->
                    </span>

                    में
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Father'"
                        >उनके पिता</span
                      >
                      <!-- <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Father'
                        "
                        >उनके दादाजी</span
                      > -->
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Wife'"
                        >उनकी पत्नी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Husband'"
                        >उनके पती</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Son'"
                        >उनका बेटा</span
                      >
                      <span
                        *ngIf="orderdata.RELATION_WITH_PATIENT == 'Daughter'"
                        >उनकी बेटी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Sister'"
                        >उनकी बहन</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Brother'"
                        >उनके भाई</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'"
                        >उनकी मां</span
                      >
                      <!-- <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Mother'
                        "
                        >उनकी दादी मां</span
                      > -->
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Father-In-Law'
                        "
                        >उनके ससुर</span
                      >
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Mother-In-Law'
                        "
                        >उनकी सासू मां</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} के</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} के</span
                      >
                    </span>

                    &nbsp;
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      स्वयं के</span
                    >
                    {{ orderdata.NATURE_OF_TREATMENT }}
                    के लिए खर्च की गई राशि,
                    <span
                      >जो
                      <b>
                        {{
                          orderdata.FINAL_REMAINING_AMOUNT
                            | currency:'INR':'symbol'
                        }}/- &nbsp; ({{ finalremaininginhindi }})
                      </b>
                      जो कि स्वीकार्य राशि
                      <b>
                        {{
                          orderdata.FINAL_ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-
                        <!-- &nbsp; ({{ finaladmissibleinhindi }}) --> </b
                      >की शेष राशि से अधिक नहीं है,</span
                    >
                    को सीजीएचएस दर के अनुसार चिकित्सा व्यय की प्रतिपूर्ती की
                    स्वीकृति प्रदान की जाती है। दिनांक
                    {{ orderdata.ADVANCE_TAKEN_DATE | date : "dd/MM/yyyy" }} को
                    <b>
                      {{
                        orderdata.ADMISSIBLE_AMOUNT
                          | currency:'INR':'symbol'
                      }}/-
                      <!-- &nbsp; ({{ admissibleinhindi }}) -->
                    </b>
                    रुपये की
                    <span *ngIf="isAdvanceSameAsAdmissible == true">
                      स्वीकार्य राशि का 90% होने के कारण
                    </span>
                    <b>
                      {{
                        orderdata.ADVANCE_AMOUNT
                          | currency:'INR':'symbol'
                      }}/-
                      <!-- &nbsp; ({{ advanceinhindi }}) -->
                    </b>
                    रुपये की अग्रिम राशी मंजुरी की गई थी।
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CG'"
                      >यह स्वीकृति स्वास्थ्य एवं परिवार कल्याण मंत्रालय, भारत
                      सरकार द्वारा जारी दिनांक 18.03.1992 के कार्यालय ज्ञापन सं.
                      एस-11012/1/91/सीजीएचएस (पी) खंड 1 के माध्यम से मुझे
                      प्रदत्त शक्तियो का प्रयोग करते हुए जारी की जाती है।</span
                    >
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CS'">
                      यह स्वीकृति आदेश सेंट्रल सर्विसेस (मेडिकल अटेनडंस),
                      नियमावली, 1944 के परिशिष्ठ । के प्रावधानों के अनुसार जारी
                      किया जाता है।
                    </span>
                  </p>

                  <p style="margin-bottom: 2px">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sanction is
                    hereby accorded for the reimbursement of medical expenses as
                    per CGHS rate for an
                    <span *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                      amount not exceeding
                      <b>
                        {{
                          orderdata.FINAL_REMAINING_AMOUNT
                            | currency:'INR':'symbol'
                        }}/- &nbsp; ({{ finalremainingineng }})
                      </b>
                      being balance amount of the admissible amount of
                      <b>
                        {{
                          orderdata.FINAL_ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-
                        <!-- &nbsp; ({{ finaladmissibleineng }}) -->
                      </b>
                      <!-- being 90% (approximately) of the admissible amount of -->
                      <!-- <b>
                        {{
                          orderdata.ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-</b
                      > -->
                    </span>
                    incurred by {{ orderdata.EMPLOYEE_NAME }}&nbsp;,
                    {{ orderdata.DESIGNATION }}
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      for his/her {{ orderdata.RELATION_WITH_PATIENT }}
                      {{ orderdata.PATIENT_NAME }}</span
                    >

                    &nbsp; for&nbsp;{{ orderdata.NATURE_OF_TREATMENT }}
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      of Self</span
                    >, at
                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                      {{ data.NAME }} ,{{ data.ADDRESS }}
                      <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                      <span *ngIf="HospitalMapping.length - 2 == k">and</span>
                      <!-- </span> -->
                    </span>

                    from
                    {{ orderdata.TREATMENT_START_DATE | date : "dd MMM yyyy" }}
                    to
                    {{ orderdata.TREATMENT_END_DATE | date : "dd MMM yyyy" }}.
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CG'">
                      An advance amount of
                      <b>
                        {{
                          orderdata.ADVANCE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-
                        <!-- &nbsp; ({{ advanceineng }}) -->
                      </b>
                      <span *ngIf="isAdvanceSameAsAdmissible == true">
                        being if 90%
                      </span>
                      of admissible amount of
                      {{
                        orderdata.ADMISSIBLE_AMOUNT
                          | currency:'INR':'symbol'
                      }}/- was sanctioned on date
                      {{ orderdata.ADVANCE_TAKEN_DATE | date : "dd/MM/yyyy" }}.
                      This sanction is issued in exercise of the powers vested
                      in me vide Office Memorandum No.S-11012/1/91/CGHS(P) Vol.
                      I dated 18.03.1992 issued by the Ministry of Health &
                      Family Welfare , Government of India.</span
                    >
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CS'">
                      An advance amount of
                      <b>
                        {{
                          orderdata.ADVANCE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-
                        <!-- &nbsp; ({{ advanceineng }}) -->
                      </b>
                      <span *ngIf="isAdvanceSameAsAdmissible == true">
                        being if 90%
                      </span>
                      of admissible amount of
                      {{
                        orderdata.ADMISSIBLE_AMOUNT
                          | currency:'INR':'symbol'
                      }}/- was sanctioned on date
                      {{ orderdata.ADVANCE_TAKEN_DATE | date : "dd/MM/yyyy" }}.
                      This sanction is issued in accordance with the provisions
                      of Appendix VIII of the Central Services (Medical
                      Attendance) Rules, 1944.
                    </span>
                  </p>

                  <!-- <p
                    style="margin-bottom: 15px; margin-top: 10px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    यह राशि डिस्चार्ज की तारीख से एक महीने के भीतर श्री
                    {{ orderdata.EMPLOYEE_NAME_HN }} द्वारा प्रस्तुत किए जाने
                    वाले नियमित दावों के खिलाफ समायोजित की जाएगी।
                  </p> -->

                  <!-- <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    This amount will be adjusted against regular claims ,
                    within one month from the date of discharge. to be submitted
                    by {{ orderdata.EMPLOYEE_NAME }}.
                  </p> -->

                  <p
                    style="margin-bottom: 15px; margin-top: 10px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    उक्त व्यय को वित्तीय वर्ष 2023-2024 के लिए विभाग को आबंटित
                    मुख्य शीर्ष "2020 चिकित्सा" के अंतर्गत अनुदान के नामे डाला
                    जाए।
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    The above expenditure should be debited to the grant under
                    the major head "2020 - Medical allotted to the Department
                    for Financial Year 2023-2024.
                  </p>

                  <div style="margin-top: 60px; font-size: 13px">
                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div><b> (विनय सिन्हा) </b></div>
                        <div style="font-weight: bold">
                          आयकर आयुक्त (प्रशासन एवं करदाता सेवाएँ) , मुंबई
                        </div>
                      </div>
                    </div>

                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div><b> (Vinay Sinha) </b></div>
                        <div style="font-weight: bold; font-size: 13px">
                          Commissioner Of Income Tax(Admin & TPS),Mumbai.
                          <!-- <span *ngIf="orderdata.SIGNATURE_ID != 2"
                            >Commissioner Of Income Tax(Admin &
                            TPS),Mumbai.</span
                          >
                          <span *ngIf="orderdata.SIGNATURE_ID == 2">
                            For Principal Chief Commissioner of Income Tax
                            Mumbai</span
                          > -->
                        </div>
                        <!-- <div
                          style="font-weight: bold; font-size: 13px"
                          *ngIf="orderdata.SIGNATURE_ID != 3"
                        >
                          for Pr. Chief Commissioner of Income-Tax, Mumbai
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      style="
                        page-break-inside: avoid !important;
                        font-size: 13px;
                        page-break-before: avoid !important;
                      "
                    >
                      <div style="margin-top: 30px">
                        <b>प्रतिलिपी/Copy to:</b>
                      </div>
                      <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                        <span>
                          1)अराजपत्रित वेतन बिल, मुंबई (2 प्रतियां) फॉर्म एमआरसी
                          (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ
                        </span>
                        <br />
                        <span style="padding-left: 15px">
                          The Non Gazetted Pay Bill Section (2 copies) alongwith
                          Form MRC(S) , hospital receipts , discharge summary
                          etc.
                        </span>
                        <br />

                        <!-- <span *ngIf="SECTION_TYPE == 'welfare'">
                          1) डीडीओ, कल्याण और एसजी,मुंबई (2 प्रतियां) फॉर्म
                          एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के
                          साथ|</span
                        > <br>
                        <span *ngIf="SECTION_TYPE == 'gazpay'">
                          1) डीडीओ, राजपत्रित वेतन बिल अनुभाग II (कार्मिक),मुंबई
                          (2 प्रतियां) फॉर्म एमआरसी (एस), अस्पताल रसीद,
                          डिस्चार्ज सारांश आदि के साथ |</span
                        >
                        <br>
                        <span
                        *ngIf="SECTION_TYPE == 'welfare'"
                        style="padding-left: 15px"
                      >
                        DDO , Welfare & SG , Mumbai (2 copies) alongwith Form
                        MRC(S) , hospital receipts , discharge summary etc.
                      </span>
                      <br>
                      <span
                        *ngIf="SECTION_TYPE == 'gazpay'"
                        style="padding-left: 15px"
                      >
                        DDO , Gazetted Pay bill Section II, Personnel Mumbai
                        (2 copies) alongwith Form MRC(S) , hospital receipts ,
                        discharge summary etc.
                      </span> -->

                        <!-- <div>
                          2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                          <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                          तैयार किया जा सकता है।
                        </div>
                        <div style="padding-left: 15px">
                            ZAO , Pre-check unit , Mumbai - Cheque may be drawn in
                          favour of {{ orderdata.EMPLOYEE_NAME }}.
                        </div> -->
                        <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 0">
                          <div>
                            2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                            <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                            तैयार किया जा सकता है। |
                          </div>
                          <div style="padding-left: 15px">
                             ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                            in favour of {{ orderdata.EMPLOYEE_NAME }}.
                          </div>
                        </div>
                        <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                          <div  *ngIf="orderdata.CHEQUE_DRAWN==0">
                            2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                            <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                            तैयार किया जा सकता है। |
                          </div>
                          <div style="padding-left: 15px"  *ngIf="orderdata.CHEQUE_DRAWN==0">
                             ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                            in favour of {{ orderdata.EMPLOYEE_NAME }}.
                          </div>
                          <div  *ngIf="orderdata.CHEQUE_DRAWN==1">
                            2) {{orderdata.ZAO_HN}}
                          </div>
                          <div style="padding-left: 15px"  *ngIf="orderdata.CHEQUE_DRAWN==1">
                              {{orderdata.ZAO}}
                          </div>
                        </div>

                        <div>
                          3)<b>
                            {{ orderdata.EMPLOYEE_NAME_HN }},
                            {{ orderdata.DESIGNATION_NAME_HN }} कार्यालय
                            {{ orderdata.OFFICE_NAME_HN }}
                            <!-- {{ orderdata.LOCATION_HN }}., -->
                          </b>
                          स्वीकार्य राशि की गणना की प्रति के साथ |
                        </div>

                        <div style="padding-left: 15px">
                          <b
                            >{{ orderdata.EMPLOYEE_NAME }},
                            {{ orderdata.DESIGNATION }} O/o
                            {{ orderdata.EMPLOYEE_OFFICE_NAME }}
                            <!-- {{ orderdata.LOCATION }}., -->
                          </b>
                          along with copy of working of admissible amount.
                        </div>
                      </div>

                      <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 0">
                        <div>
                          <span *ngIf="SECTION_TYPE == 'welfare'">
                            1) डीडीओ, कल्याण और एसजी,मुंबई (2 प्रतियां) फॉर्म
                            एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के
                            साथ|</span
                          >
                          <span *ngIf="SECTION_TYPE == 'gazpay'">
                            1) डीडीओ, राजपत्रित वेतन बिल अनुभाग II
                            (कार्मिक),मुंबई (2 प्रतियां) फॉर्म एमआरसी (एस),
                            अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ |</span
                          >
                          <!-- 1) डीडीओ,कल्याण और एसजी,मुंबई (2 प्रतियां)
                      फॉर्म एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ| -->
                        </div>
                        <div>
                          <!-- 1) DDO , Welfare & SG , Mumbai (2 copies) alongwith Form
                      MRC(S) , hospital receipts , discharge summary etc. -->

                          <span
                            *ngIf="SECTION_TYPE == 'welfare'"
                            style="padding-left: 15px"
                          >
                            DDO , Welfare & SG , Mumbai (2 copies) alongwith
                            Form MRC(S) , hospital receipts , discharge summary
                            etc.
                          </span>
                          <span
                            *ngIf="SECTION_TYPE == 'gazpay'"
                            style="padding-left: 15px"
                          >
                            DDO , Gazetted Pay bill Section II, Personnel Mumbai
                            (2 copies) alongwith Form MRC(S) , hospital receipts
                            , discharge summary etc.
                          </span>
                        </div>
                        <div>
                          2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                          <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                          तैयार किया जा सकता है।
                        </div>
                        <div style="padding-left: 15px">
                            ZAO , Pre-check unit , Mumbai - Cheque may be drawn in
                          favour of {{ orderdata.EMPLOYEE_NAME }}.
                        </div>

                        <div>
                          3)<b>
                            {{ orderdata.EMPLOYEE_NAME_HN }},
                            {{ orderdata.DESIGNATION_NAME_HN }} कार्यालय
                            {{ orderdata.OFFICE_NAME_HN }}
                            <!-- {{ orderdata.LOCATION_HN }}., -->
                          </b>
                          स्वीकार्य राशि की गणना की प्रति के साथ |
                        </div>

                        <div style="padding-left: 15px">
                          <b
                            >{{ orderdata.EMPLOYEE_NAME }},
                            {{ orderdata.DESIGNATION }} O/o
                            {{ orderdata.EMPLOYEE_OFFICE_NAME }}
                            <!-- {{ orderdata.LOCATION }}., -->
                          </b>
                          along with copy of working of admissible amount.
                        </div>
                      </div>

                      <div style="margin-top: 60px">
                        <div nz-row>
                          <div nz-col nzSpan="12"></div>

                          <div nz-col nzSpan="12" style="text-align: center">
                            <div style="font-weight: bold">({{ NAME_HN }})</div>
                            <div>
                              <b> {{ POST_HN }} </b>
                            </div>
                            <div>
                              <b> {{ OFFICE_NAME_HN }} </b>
                            </div>
                            <div>
                              <b>({{ SIGNNAME }})</b>
                            </div>
                            <div>
                              <b>{{ POST }} </b>
                            </div>
                            <div>
                              <b>{{ OFFICE_NAME }} </b>
                            </div>
                            <!-- <span *ngIf="SECTION_TYPE == 'welfare'">
                              <div style="font-weight: bold">
                                (रीतेश कुमार)
                              </div>
                              <div>आयकर अधिकारी (मुख्‍यालय)</div>
                              <div>कल्याण और कर्मचारी शिकायत, मुंबई</div>
                            </span>

                            <span *ngIf="SECTION_TYPE == 'gazpay'">
                              <div style="font-weight: bold">
                                (जितेंद्र रा चांदेकर)
                              </div>
                              <div>आयकर अधिकारी</div>
                              <div>राजपत्रित वेतन बिल अनुभाग II, मुंबई</div>
                            </span> -->
                          </div>
                        </div>

                        <!-- <div nz-row>
                          <div nz-col nzSpan="15"></div>

                          <div nz-col nzSpan="9" style="text-align: center">
                            <span *ngIf="SECTION_TYPE == 'welfare'">
                              <div style="font-weight: bold">
                                (Ritesh Kumar)
                              </div>
                              <div>Income Tax Officer(HQ)</div>
                              <div>Welfare & S.G, Mumbai</div>
                            </span>
                            <span *ngIf="SECTION_TYPE == 'gazpay'">
                              <div style="font-weight: bold">
                                (Jitendra R Chandekar)
                              </div>
                              <div>Income Tax Officer</div>
                              <div>
                                Gazetted Pay Bill Section II Personnel, Mumbai
                              </div>
                            </span>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>
  </form>

  <div class="footer">
    <button
      type="button"
      [nzLoading]="isSpinning"
      class="ant-btn ant-btn-primary"
      nz-button
      (click)="pakageadvancedataLatest()"
    >
      <span>Save & Preview</span>
    </button>
    &nbsp;&nbsp;

    <button
      type="button"
      nz-button
      class="ant-btn"
      style="margin-right: 8px"
      (click)="close()"
    >
      <span>Cancel</span>
    </button>

    <!-- <button nz-button nzType="primary"  (click)="pakagedata()">
            Save and Next
        </button> -->

    <!-- <button
      type="button"
      class="ant-btn ant-btn-primary"
      nz-button
      (click)="openPrintOrderModal()"
    >
      <span>Preview</span>
    </button> -->
  </div>
</div>

<nz-modal
  [nzWidth]="getwidth()"
  [(nzVisible)]="printOrderModalVisible"
  nzMaskClosable="false"
  nzClosable="false"
  [nzContent]="printOrderModalContent"
  [nzTitle]="'Sanction Order'"
  (nzOnCancel)="printOrderModalCancel()"
  [nzFooter]="printOrderModalFooter"
>
  <ng-template #printOrderModalContent>
    <nz-spin [nzSpinning]="pdfDownload">
      <div style="height: 450px; overflow: auto">
        <div id="printOrderModal">
          <div style="width: 700px; padding: 3px; margin-left: 30px">
            <div>
              <div nz-row>
                <div nz-col nzXs="2" nzSm="2" nzMd="2" nzLg="2" nzXl="2">
                  <div style="text-align: right">
                    <img
                      src="assets/govtOfIndia.png"
                      alt=""
                      height="130px"
                      width="100px"
                    />
                  </div>
                </div>

                <div nz-col nzXs="22" nzSm="22" nzMd="22" nzLg="22" nzXl="22">
                  <div
                    style="width: 100%; text-align: center; font-weight: bold"
                  >
                    <div>भारत सरकार</div>
                    <div style="text-transform: uppercase">
                      Government Of India
                    </div>
                    <div>
                      कार्यालय , आयकर आयुक्त (प्रशासन एवं टीपीएस), मुंबई
                    </div>
                    <div style="text-transform: uppercase; font-size: 10px">
                      Office Of The
                    </div>
                    <div>Commissioner of Income-Tax (Admin. & TPS)</div>
                    <div style="text-transform: uppercase; font-size: 10px">
                      3<sup style="text-transform: lowercase">rd</sup> Floor,
                      aayakar bhavan, Maharshi Karve Road, Mumbai- -400020
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div nz-row>
                <div nz-col nzXs="2" nzSm="2" nzMd="2" nzLg="2" nzXl="2">
                  <div style="text-align: right">
                    <img
                      src="assets/govtOfIndia.png"
                      alt=""
                      height="140%"
                      width="140%"
                    />
                  </div>
                </div>

                <div nz-col nzXs="22" nzSm="22" nzMd="22" nzLg="22" nzXl="22">
                  <div
                    style="width: 100%; text-align: center; font-weight: bold"
                  >
                    <div>भारत सरकार</div>
                    <div style="text-transform: uppercase">
                      Government Of India
                    </div>
                    <div>
                      कार्यालय , आयकर आयुक्त (प्रशासन एवं टीपीएस), मुंबई
                    </div>
                    <div style="text-transform: uppercase">
                      Office Of The Commissioner of Income-Tax (Admin, & TPS)
                    </div>
                    <div>
                      तीसरी मंजिल, आयकर भवन, महर्षी कर्वे रोड, मुंबई -20
                    </div>
                    <div style="text-transform: uppercase">
                      3<sup style="text-transform: lowercase">rd</sup> Floor,
                      aayakar bhavan, Maharshi Karve Road, Mumbai-20
                    </div>
                  </div>
                </div>
              </div> -->

              <div
                style="
                  width: 100%;
                  border: 1px solid black;
                  padding: 5px;
                  margin-top: 10px;
                  font-size: 13px;
                "
              >
                <div nz-row>
                  <div nz-col nzSpan="12">{{ orderdata.ORDER_NO }}</div>

                  <div nz-col nzSpan="12" style="text-align: right">
                    दिनांक
                    :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                  <!-- {{ orderdata.ORDER_DATE | date : "dd MMM yyyy" }} -->
                </div>
              </div>

              <div
                style="font-family: Arial, Helvetica, sans-serif !important"
                *ngIf="orderdata.IS_ADVANCE_TAKEN == 0"
              >
                <div
                  style="
                    text-align: center;
                    margin-top: 20px;
                    margin-bottom: 10px;
                    font-size: 13px;
                  "
                >
                  <b>स्वीकृति आदेश/ SANCTION ORDER</b>
                </div>
                <div style="font-size: 13px !important">
                  <p style="margin-bottom: 2px">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {{ orderdata.EMPLOYEE_NAME_HN }}
                    {{ orderdata.DESIGNATION_NAME_HN }}, को उनके द्वारा दिनांक
                    {{
                      orderdata.TREATMENT_START_DATE | date : "dd MMM yyyy"
                    }}&nbsp; से
                    {{
                      orderdata.TREATMENT_END_DATE | date : "dd MMM yyyy"
                    }}&nbsp; को

                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                      {{ data.NAME_HN }} , {{ data.ADDRESS_HN }}
                      <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                      <span *ngIf="HospitalMapping.length - 2 == k">और</span>
                      <!-- </span> -->
                    </span>

                    में
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Father'"
                        >उनके पिता</span
                      >
                      <!-- <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Father'
                        "
                        >उनके दादाजी</span
                      > -->
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Wife'"
                        >उनकी पत्नी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Husband'"
                        >उनके पती</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Son'"
                        >उनका बेटा</span
                      >
                      <span
                        *ngIf="orderdata.RELATION_WITH_PATIENT == 'Daughter'"
                        >उनकी बेटी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Sister'"
                        >उनकी बहन</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Brother'"
                        >उनके भाई</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'"
                        >उनकी मां</span
                      >
                      <!-- <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Mother'
                        "
                        >उनकी दादी मां</span
                      > -->
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Father-In-Law'
                        "
                        >उनके ससुर</span
                      >
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Mother-In-Law'
                        "
                        >उनकी सासू मां</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} के</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} के</span
                      >
                    </span>

                    &nbsp;
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      स्वयं के</span
                    >
                    {{ orderdata.NATURE_OF_TREATMENT }}
                    के इलाज के लिए खर्च की गई राशि,
                    <span
                      >जो
                      <b>
                        {{
                          orderdata.ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-&nbsp; ({{ admissibleinhindi }})</b
                      >
                      से अधिक नहीं है,</span
                    >
                    को सीजीएचएस दर के अनुसार चिकित्सा व्यय की प्रतिपूर्ती के लिए
                    स्वीकृति प्रदान की जाती है।
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CG'"
                      >यह मंजूरी स्वास्थ्य एवं परिवार कल्याण मंत्रालय, भारत
                      सरकार द्वारा जारी दिनांक 18.03.1992 के कार्यालय ज्ञापन सं.
                      एस-11012/1/91/सीजीएचएस (पी) खंड 1 के माध्यम से मुझे
                      प्रदत्त शक्तियो का प्रयोग करते हुए जारी की जाती है।</span
                    >
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CS'">
                      यह मंजूरी आदेश सेंट्रल सर्विसेस (मेडिकल अटेनडंस),
                      नियमावली, 1944 के परिशिष्ठ । के प्रावधानों के अनुसार जारी
                      किया जाता है।
                    </span>
                  </p>

                  <p style="margin-bottom: 2px">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sanction is
                    hereby accorded for the reimbursement of medical expenses as
                    per CGHS rate for an
                    <span>
                      amount not exceeding
                      <b>
                        {{
                          orderdata.ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-
                        <!-- &nbsp; ({{ amountinwords }}) -->
                      </b>
                    </span>
                     incurred by {{ orderdata.EMPLOYEE_NAME }}&nbsp;,
                    {{ orderdata.DESIGNATION }}
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      for his/her {{ orderdata.RELATION_WITH_PATIENT }}
                      {{ orderdata.PATIENT_NAME }}</span
                    >

                    &nbsp; for&nbsp;{{ orderdata.NATURE_OF_TREATMENT }}
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      of Self</span
                    >, at
                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                      {{ data.NAME }} ,{{ data.ADDRESS }}
                      <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                      <span *ngIf="HospitalMapping.length - 2 == k">and</span>
                      <!-- </span> -->
                    </span>

                    from
                    {{ orderdata.TREATMENT_START_DATE | date : "dd MMM yyyy" }}
                    to
                    {{ orderdata.TREATMENT_END_DATE | date : "dd MMM yyyy" }}.
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CG'">
                      This sanction is issued in exercise of the powers vested
                      in me vide Office Memorandum No.S-11012/1/91/CGHS(P) Vol.
                      I dated 18.03.1992 issued by the Ministry of Health &
                      Family Welfare , Government of India.</span
                    >
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CS'">
                      This sanction is issued in accordance with the provisions
                      of Appendix VIII of the Central Services (Medical
                      Attendance) Rules, 1944.
                    </span>
                  </p>

                  <p
                    style="margin-bottom: 15px; margin-top: 10px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    यह राशि डिस्चार्ज की तारीख से एक महीने के भीतर श्री
                    {{ orderdata.EMPLOYEE_NAME_HN }} द्वारा प्रस्तुत किए जाने
                    वाले नियमित दावों के खिलाफ समायोजित की जाएगी।
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    This amount will be adjusted against regular claims ,
                    within one month from the date of discharge. to be submitted
                    by {{ orderdata.EMPLOYEE_NAME }}.
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    उक्त व्यय को वित्तीय वर्ष 2022-2023 के लिए विभाग को आबंटित
                    मुख्य शीर्ष "2021 चिकित्सा" के अंतर्गत अनुदान के नामे डाला
                    जाए।
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    The above expenditure should be debited to the grant under
                    the major head "2021 - Medical allotted to the Department
                    for Financial Year 2022-23.
                  </p>

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 0
                    "
                  >
                    यह आपात स्थिति का मामला था, जिसके लिए तत्काल उपचार की
                    आवश्यकता थी अतः गैर-पैनलबद्ध अस्पताल में भर्ती कराया गया था|
                  </p>

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 0
                    "
                  >
                    It was a case of emergency , necessitating immediate
                    treatment and was admitted in a non-empanelled hospital.
                  </p>

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 1 &&
                        orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 0 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 1 &&
                        orderdata.IS_HOSPITAL_EMPLANELLED == 0 &&
                        orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 0 &&
                        orderdata.EMERGENCY_TREATEMENT == 0) ||
                      orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1
                    "
                  >
                    दिनांक
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0">
                      {{ orderdata.EXPO_FACTO_DATE }}
                    </span>
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1">
                      {{ orderdata.HOD_PERMISSION_DATE }}
                    </span>
                    के पत्र द्वारा उपरोक्त उपचार के लिये कार्योत्तर अनुमति
                    प्रदान की गई है।
                  </p>

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 1 &&
                        orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 0 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 1 &&
                        orderdata.IS_HOSPITAL_EMPLANELLED == 0 &&
                        orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 0 &&
                        orderdata.EMERGENCY_TREATEMENT == 0) ||
                      orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1
                    "
                  >
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0">
                      Expost Facto Permission for the above treatment has been
                      granted vide letter dated {{ orderdata.EXPO_FACTO_DATE }}
                    </span>
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1">
                      Permission for the above treatment has been granted vide
                      letter dated {{ orderdata.HOD_PERMISSION_DATE }}
                    </span>
                  </p>
                  <!-- <p
                *ngIf="
                  orderdata.IS_BILL_FILLED_IN_TIME == 1 &&
                  orderdata.IS_HOSPITAL_EMPLANELLED == 1 &&
                  orderdata.EXPO_FACTO_PERMISSION == 1
                "
              >
                दिनांक 10/01/2023 के पत्र द्वारा उपरोक्त उपचार के लिए अनुमति
                प्रदान की गई है।
              </p>
              <p
                *ngIf="
                  orderdata.IS_BILL_FILLED_IN_TIME == 1 &&
                  orderdata.IS_HOSPITAL_EMPLANELLED == 1 &&
                  orderdata.EXPO_FACTO_PERMISSION == 1
                "
              >
                Permission for the above treatment has been granted vide letter
                dated 10/01/2023
              </p> -->

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 1
                    "
                  >
                    यह आपात स्थिति का मामला था जिसमें तत्काल प्रवेश की आवश्यकता
                    थी और उसे पैनलबद्ध अस्पताल में भर्ती कराया गया था.
                  </p>
                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 1
                    "
                  >
                    It was a case of Emergency necessitating immediate admission
                    and was admitted in empanelled hospital.
                  </p>
                  <p style="margin-bottom: 2px">
                    उक्त व्यथ को वित्तीय वर्ष 2023-2024 के लिए विभाग को आबंटित
                    मुख्य शीर्ष "2020-चिकित्सा उपचार" के अंतर्गत अनुदान के नामे
                    डाला जाए।
                  </p>

                  <p style="margin-bottom: 2px">
                    The above expenditure should be debited to the grant under
                    major head 2020 - Medical Treatment Alloted to the
                    Department for the
                    <b>F.Y 2023-2024</b>
                  </p>

                  <p style="margin-bottom: 2px">
                    हिंदी और अंग्रेजी संस्करण में किसी भी भिन्नता के मामले में
                    आदेश का अंग्रेजी संस्करण मान्य होगा।
                  </p>

                  <p style="margin-bottom: 2px">
                    The English version of the order will prevail in case of any
                    variation in Hindi & English version
                  </p>

                  <div style="margin-top: 60px; font-size: 13px">
                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div>(विनय सिन्हा)</div>
                        <div style="font-weight: bold">
                          आयकर आयुक्त (प्रशासन एवं टीपीएस) , मुंबई
                        </div>
                      </div>
                    </div>

                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div>(Vinay Sinha)</div>
                        <div style="font-weight: bold; font-size: 13px">
                          Commissioner Of Income Tax(Admin & TPS),Mumbai.
                        </div>
                        <!-- <div style="font-weight: bold; font-size: 13px">
                          for Pr. Chief Commissioner of Income-Tax, Mumbai
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      style="
                        page-break-inside: avoid !important;
                        font-size: 13px;
                        page-break-before: avoid !important;
                      "
                    >
                      <div style="margin-top: 30px">
                        <b>प्रतिलिपी/Copy to:</b>
                      </div>
                      <div>
                        <span *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                          1)अराजपत्रित वेतन बिल, मुंबई (2 प्रतियां) फॉर्म एमआरसी
                          (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ
                        </span>
                      </div>
                      <div>
                        <span *ngIf="SECTION_TYPE == 'welfare'">
                          1) डीडीओ, कल्याण और एसजी,मुंबई (2 प्रतियां) फॉर्म
                          एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के
                          साथ|</span
                        >
                        <span *ngIf="SECTION_TYPE == 'gazpay'">
                          1) डीडीओ, राजपत्रित वेतन बिल अनुभाग II (कार्मिक),मुंबई
                          (2 प्रतियां) फॉर्म एमआरसी (एस), अस्पताल रसीद,
                          डिस्चार्ज सारांश आदि के साथ|</span
                        >
                        <!-- 1) डीडीओ,कल्याण और एसजी,मुंबई (2 प्रतियां)
                      फॉर्म एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ| -->
                      </div>
                      <div>
                        <!-- 1) DDO , Welfare & SG , Mumbai (2 copies) alongwith Form
                      MRC(S) , hospital receipts , discharge summary etc. -->

                        <span *ngIf="SECTION_TYPE == 'welfare'">
                          1) DDO , Welfare & SG , Mumbai (2 copies) alongwith
                          Form MRC(S) , hospital receipts , discharge summary
                          etc.
                        </span>
                        <span *ngIf="SECTION_TYPE == 'gazpay'">
                          1) DDO , Gazetted Pay bill Section II, Personnel
                          Mumbai (2 copies) alongwith Form MRC(S) , hospital
                          receipts , discharge summary etc.
                        </span>
                      </div>
                    </div>
                    <div
                      style="
                        page-break-inside: avoid !important;
                        font-size: 13px;
                      "
                    >
                      <!-- <div>
                        2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                        <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                        तैयार किया जा सकता है। |
                      </div>
                      <div>
                         ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                        in favour of {{ orderdata.EMPLOYEE_NAME }}.
                      </div> -->
                      <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 0">
                        <div>
                          2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                          <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                          तैयार किया जा सकता है। |
                        </div>
                        <div >
                           ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                          in favour of {{ orderdata.EMPLOYEE_NAME }}.
                        </div>
                      </div>
                      <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                        <div  *ngIf="orderdata.CHEQUE_DRAWN==0">
                          2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                          <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                          तैयार किया जा सकता है। |
                        </div>
                        <div   *ngIf="orderdata.CHEQUE_DRAWN==0">
                           ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                          in favour of {{ orderdata.EMPLOYEE_NAME }}.
                        </div>
                        <div  *ngIf="orderdata.CHEQUE_DRAWN==1">
                          2) {{orderdata.ZAO_HN}}
                        </div>
                        <div style="padding-left: 15px"  *ngIf="orderdata.CHEQUE_DRAWN==1">
                            {{orderdata.ZAO}}
                        </div>
                      </div>

                      <div>
                        3)<b>
                          {{ orderdata.EMPLOYEE_NAME_HN }},
                          {{ orderdata.DESIGNATION }}
                          {{ orderdata.OFFICE_NAME_HN }}
                          <!-- {{ orderdata.LOCATION }}., -->
                        </b>
                        स्वीकार्य राशि की गणना की प्रति के साथ |
                      </div>

                      <div>
                        3)
                        <b
                          >{{ orderdata.EMPLOYEE_NAME }} ,
                          {{ orderdata.DESIGNATION }}
                          {{ orderdata.EMPLOYEE_OFFICE_NAME }}
                          <!-- {{ orderdata.LOCATION }}., -->
                        </b>
                        along with copy of working of admissible amount
                      </div>

                      <div style="margin-top: 60px">
                        <div nz-row>
                          <div nz-col nzSpan="15"></div>

                          <div nz-col nzSpan="9" style="text-align: center">
                            <div style="font-weight: bold">({{ NAME_HN }})</div>
                            <div>{{ POST_HN }}</div>
                            <div>{{ OFFICE_NAME_HN }}</div>
                            <div style="font-weight: bold">
                              ({{ SIGNNAME }})
                            </div>
                            <div>{{ POST }}</div>
                            <div>{{ OFFICE_NAME }}</div>
                            <!-- <span *ngIf="SECTION_TYPE == 'welfare'">
                              <div style="font-weight: bold">
                                (रीतेश कुमार)
                              </div>
                              <div>आयकर अधिकारी (मुख्‍यालय)</div>
                              <div>कल्याण और कर्मचारी शिकायत, मुंबई</div>
                            </span>

                            <span *ngIf="SECTION_TYPE == 'gazpay'">
                              <div style="font-weight: bold">
                                (जितेंद्र रा चांदेकर)
                              </div>
                              <div>आयकर अधिकारी</div>
                              <div>राजपत्रित वेतन बिल अनुभाग II, मुंबई</div>
                            </span> -->
                          </div>
                        </div>

                        <!-- <div nz-row>
                          <div nz-col nzSpan="15"></div>

                          <div nz-col nzSpan="9" style="text-align: center">
                            <span *ngIf="SECTION_TYPE == 'welfare'">
                              <div style="font-weight: bold">
                                (Ritesh Kumar)
                              </div>
                              <div>Income Tax Officer(HQ)</div>
                              <div>Welfare & S.G, Mumbai</div>
                            </span>
                            <span *ngIf="SECTION_TYPE == 'gazpay'">
                              <div style="font-weight: bold">
                                (Jitendra R Chandekar)
                              </div>
                              <div>Income Tax Officer</div>
                              <div>
                                Gazetted Pay Bill Section II Personnel, Mumbai
                              </div>
                            </span>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style="font-family: Arial, Helvetica, sans-serif !important"
                *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
              >
                <div
                  style="
                    text-align: center;
                    margin-top: 20px;
                    margin-bottom: 10px;
                    font-size: 13px;
                  "
                >
                  <b>स्वीकृति आदेश/ SANCTION ORDER</b>
                </div>
                <div style="font-size: 13px !important">
                  <p style="margin-bottom: 2px">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {{ orderdata.EMPLOYEE_NAME_HN }}
                    {{ orderdata.DESIGNATION }}, को उनके द्वारा दिनांक
                    {{
                      orderdata.TREATMENT_START_DATE | date : "dd MMM yyyy"
                    }}&nbsp; से
                    {{
                      orderdata.TREATMENT_END_DATE | date : "dd MMM yyyy"
                    }}&nbsp; को

                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                      {{ data.NAME_HN }} , {{ data.ADDRESS_HN }}
                      <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                      <span *ngIf="HospitalMapping.length - 2 == k">और</span>
                      <!-- </span> -->
                    </span>

                    में
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Father'"
                        >उनके पिता</span
                      >
                      <!-- <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Father'
                        "
                        >उनके दादाजी</span
                      > -->
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Wife'"
                        >उनकी पत्नी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Husband'"
                        >उनके पती</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Son'"
                        >उनका बेटा</span
                      >
                      <span
                        *ngIf="orderdata.RELATION_WITH_PATIENT == 'Daughter'"
                        >उनकी बेटी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Sister'"
                        >उनकी बहन</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Brother'"
                        >उनके भाई</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'"
                        >उनकी मां</span
                      >
                      <!-- <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Mother'
                        "
                        >उनकी दादी मां</span
                      > -->
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Father-In-Law'
                        "
                        >उनके ससुर</span
                      >
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Mother-In-Law'
                        "
                        >उनकी सासू मां</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} के</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} के</span
                      >
                    </span>

                    &nbsp;
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      स्वयं के</span
                    >
                    {{ orderdata.NATURE_OF_TREATMENT }}
                    के इलाज के लिए खर्च की गई राशि,
                    <span *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                      >जो
                      <b>
                        {{
                          orderdata.ADVANCE_ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-&nbsp; ({{ advadmissibleinhindi }})</b
                      >
                      <b>
                        जो कि
                        {{
                          orderdata.ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-</b
                      >
                      की स्वीकार्य राशि का 90% (लगभग) से अधिक नहीं है,</span
                    >
                    को सीजीएचएस दर के अनुसार चिकित्सा व्यय की प्रतिपूर्ती के लिए
                    स्वीकृति प्रदान की जाती है।
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CG'"
                      >यह मंजूरी स्वास्थ्य एवं परिवार कल्याण मंत्रालय, भारत
                      सरकार द्वारा जारी दिनांक 18.03.1992 के कार्यालय ज्ञापन सं.
                      एस-11012/1/91/सीजीएचएस (पी) खंड 1 के माध्यम से मुझे
                      प्रदत्त शक्तियो का प्रयोग करते हुए जारी की जाती है।</span
                    >
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CS'">
                      यह मंजूरी आदेश सेंट्रल सर्विसेस (मेडिकल अटेनडंस),
                      नियमावली, 1944 के परिशिष्ठ । के प्रावधानों के अनुसार जारी
                      किया जाता है।
                    </span>
                  </p>

                  <p style="margin-bottom: 2px">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sanction is
                    hereby accorded for the reimbursement of medical expenses as
                    per CGHS rate for an
                    <span *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                      amount not exceeding
                      <b>
                        {{
                          orderdata.ADVANCE_ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-&nbsp; ({{ advadmissibleineng }})</b
                      >
                      being 90% (approximately) of the admissible amount of
                      <b>
                        {{
                          orderdata.ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-</b
                      >
                    </span>
                     incurred by {{ orderdata.EMPLOYEE_NAME }}&nbsp;,
                    {{ orderdata.DESIGNATION }}
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      for his/her {{ orderdata.RELATION_WITH_PATIENT }}
                      {{ orderdata.PATIENT_NAME }}</span
                    >

                    &nbsp; for&nbsp;{{ orderdata.NATURE_OF_TREATMENT }}
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      of Self</span
                    >, at
                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                      {{ data.NAME }} ,{{ data.ADDRESS }}
                      <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                      <span *ngIf="HospitalMapping.length - 2 == k">and</span>
                      <!-- </span> -->
                    </span>

                    from
                    {{ orderdata.TREATMENT_START_DATE | date : "dd MMM yyyy" }}
                    to
                    {{ orderdata.TREATMENT_END_DATE | date : "dd MMM yyyy" }}.
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CG'">
                      This sanction is issued in exercise of the powers vested
                      in me vide Office Memorandum No.S-11012/1/91/CGHS(P) Vol.
                      I dated 18.03.1992 issued by the Ministry of Health &
                      Family Welfare , Government of India.</span
                    >
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CS'">
                      This sanction is issued in accordance with the provisions
                      of Appendix VIII of the Central Services (Medical
                      Attendance) Rules, 1944.
                    </span>
                  </p>

                  <p
                    style="margin-bottom: 15px; margin-top: 10px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    यह राशि डिस्चार्ज की तारीख से एक महीने के भीतर श्री
                    {{ orderdata.EMPLOYEE_NAME_HN }} द्वारा प्रस्तुत किए जाने
                    वाले नियमित दावों के खिलाफ समायोजित की जाएगी।
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    This amount will be adjusted against regular claims ,
                    within one month from the date of discharge. to be submitted
                    by {{ orderdata.EMPLOYEE_NAME }}.
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    उक्त व्यय को वित्तीय वर्ष 2022-2023 के लिए विभाग को आबंटित
                    मुख्य शीर्ष "2021 चिकित्सा" के अंतर्गत अनुदान के नामे डाला
                    जाए।
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    The above expenditure should be debited to the grant under
                    the major head "2021 - Medical allotted to the Department
                    for Financial Year 2022-23.
                  </p>

                  <div style="margin-top: 60px; font-size: 13px">
                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div>(विनय सिन्हा)</div>
                        <div style="font-weight: bold">
                          आयकर आयुक्त (प्रशासन एवं टीपीएस) , मुंबई
                        </div>
                      </div>
                    </div>

                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div>(Vinay Sinha)</div>
                        <div style="font-weight: bold; font-size: 13px">
                          Commissioner Of Income Tax(Admin & TPS),Mumbai.
                        </div>
                        <!-- <div style="font-weight: bold; font-size: 13px">
                          for Pr. Chief Commissioner of Income-Tax, Mumbai
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      style="
                        page-break-inside: avoid !important;
                        font-size: 13px;
                        page-break-before: avoid !important;
                      "
                    >
                      <div style="margin-top: 30px">
                        <b>प्रतिलिपी/Copy to:</b>
                      </div>
                      <div>
                        <span *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                          1)अराजपत्रित वेतन बिल, मुंबई (2 प्रतियां) फॉर्म एमआरसी
                          (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ
                        </span>
                      </div>
                      <div>
                        <span *ngIf="SECTION_TYPE == 'welfare'">
                          1) डीडीओ, कल्याण और एसजी,मुंबई (2 प्रतियां) फॉर्म
                          एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के
                          साथ|</span
                        >
                        <span *ngIf="SECTION_TYPE == 'gazpay'">
                          1) डीडीओ, राजपत्रित वेतन बिल अनुभाग II (कार्मिक),मुंबई
                          (2 प्रतियां) फॉर्म एमआरसी (एस), अस्पताल रसीद,
                          डिस्चार्ज सारांश आदि के साथ|</span
                        >
                        <!-- 1) डीडीओ,कल्याण और एसजी,मुंबई (2 प्रतियां)
                      फॉर्म एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ| -->
                      </div>
                      <div>
                        <!-- 1) DDO , Welfare & SG , Mumbai (2 copies) alongwith Form
                      MRC(S) , hospital receipts , discharge summary etc. -->

                        <span *ngIf="SECTION_TYPE == 'welfare'">
                          1) DDO , Welfare & SG , Mumbai (2 copies) alongwith
                          Form MRC(S) , hospital receipts , discharge summary
                          etc.
                        </span>
                        <span *ngIf="SECTION_TYPE == 'gazpay'">
                          1) DDO , Gazetted Pay bill Section II, Personnel
                          Mumbai (2 copies) alongwith Form MRC(S) , hospital
                          receipts , discharge summary etc.
                        </span>
                      </div>
                    </div>
                    <div
                      style="
                        page-break-inside: avoid !important;
                        font-size: 13px;
                      "
                    >
                    <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 0">
                      <div>
                        2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                        <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                        तैयार किया जा सकता है। |
                      </div>
                      <div>
                         ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                        in favour of {{ orderdata.EMPLOYEE_NAME }}.
                      </div>
                    </div>
                    <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                      <div  *ngIf="orderdata.CHEQUE_DRAWN==0">
                        2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                        <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                        तैयार किया जा सकता है। |
                      </div>
                      <div  *ngIf="orderdata.CHEQUE_DRAWN==0">
                         ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                        in favour of {{ orderdata.EMPLOYEE_NAME }}.
                      </div>
                      <div  *ngIf="orderdata.CHEQUE_DRAWN==1">
                        2) {{orderdata.ZAO_HN}}
                      </div>
                      <div  *ngIf="orderdata.CHEQUE_DRAWN==1">
                          {{orderdata.ZAO}}
                      </div>
                    </div>

                      <div>
                        3)<b>
                          {{ orderdata.EMPLOYEE_NAME_HN }},
                          {{ orderdata.DESIGNATION }}
                          {{ orderdata.OFFICE_NAME_HN }}
                          <!-- {{ orderdata.LOCATION }}., -->
                        </b>
                        स्वीकार्य राशि की गणना की प्रति के साथ |
                      </div>

                      <div>
                        3)
                        <b
                          >{{ orderdata.EMPLOYEE_NAME }} ,
                          {{ orderdata.DESIGNATION }}
                          {{ orderdata.EMPLOYEE_OFFICE_NAME }}
                          <!-- {{ orderdata.LOCATION }}., -->
                        </b>
                        along with copy of working of admissible amount
                      </div>

                      <div style="margin-top: 60px">
                        <div nz-row>
                          <div nz-col nzSpan="15"></div>

                          <div nz-col nzSpan="9" style="text-align: center">
                            <div style="font-weight: bold">({{ NAME_HN }})</div>
                            <div>{{ POST_HN }}</div>
                            <div>{{ OFFICE_NAME_HN }}</div>
                            <div style="font-weight: bold">
                              ({{ SIGNNAME }})
                            </div>
                            <div>{{ POST }}</div>
                            <div>{{ OFFICE_NAME }}</div>
                            <!-- <span *ngIf="SECTION_TYPE == 'welfare'">
                              <div style="font-weight: bold">
                                (रीतेश कुमार)
                              </div>
                              <div>आयकर अधिकारी (मुख्‍यालय)</div>
                              <div>कल्याण और कर्मचारी शिकायत, मुंबई</div>
                            </span>

                            <span *ngIf="SECTION_TYPE == 'gazpay'">
                              <div style="font-weight: bold">
                                (जितेंद्र रा चांदेकर)
                              </div>
                              <div>आयकर अधिकारी</div>
                              <div>राजपत्रित वेतन बिल अनुभाग II, मुंबई</div>
                            </span> -->
                          </div>
                        </div>

                        <!-- <div nz-row>
                          <div nz-col nzSpan="15"></div>

                          <div nz-col nzSpan="9" style="text-align: center">
                            <span *ngIf="SECTION_TYPE == 'welfare'">
                              <div style="font-weight: bold">
                                (Ritesh Kumar)
                              </div>
                              <div>Income Tax Officer(HQ)</div>
                              <div>Welfare & S.G, Mumbai</div>
                            </span>
                            <span *ngIf="SECTION_TYPE == 'gazpay'">
                              <div style="font-weight: bold">
                                (Jitendra R Chandekar)
                              </div>
                              <div>Income Tax Officer</div>
                              <div>
                                Gazetted Pay Bill Section II Personnel, Mumbai
                              </div>
                            </span>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>
  </ng-template>

  <ng-template #printOrderModalFooter>
    <button nz-button nzType="default" (click)="printOrderModalCancel()">
      Close
    </button>

    <button
      nz-button
      nzType="default"
      [useExistingCss]="true"
      printSectionId="printOrderModal"
      ngxPrint
      [nzLoading]="pdfDownload"
    >
      Print
    </button>

    <button
      nz-button
      nzType="primary"
      [nzLoading]="pdfDownload"
      (click)="generatePDF()"
    >
      Download
    </button>
  </ng-template>
</nz-modal>

<nz-modal
  [nzWidth]="getwidth()"
  [(nzVisible)]="printLatestOrderModalVisible1"
  nzMaskClosable="false"
  nzClosable="false"
  [nzContent]="printOrderModalContentLatest"
  [nzTitle]="'Sanction Order'"
  (nzOnCancel)="printLatestOrderModalCancel()"
  [nzFooter]="printLatestOrderModalFooter"
>
  <ng-template #printOrderModalContentLatest>
    <nz-spin [nzSpinning]="pdfDownload">
      <div style="height: 450px; overflow: auto">
        <div id="printOrderModalLatest1">
          <div style="width: 700px; padding: 3px; margin-left: 30px">
            <div>
              <div nz-row>
                <div
                  style="border: 1px solid"
                  nz-col
                  nzXs="4"
                  nzSm="4"
                  nzMd="4"
                  nzLg="4"
                  nzXl="4"
                >
                  <div style="text-align: center; padding: 36px 9px 9px 9px">
                    <img
                      src="assets/govtOfIndia.png"
                      alt=""
                      height="140px"
                      width="100%"
                    />
                  </div>
                </div>
                <!-- <div
                  style="
                    border-bottom: 1px solid;
                    border-top: 1px solid;
                    border-right: 1px solid;
                  "
                  nz-col
                  nzXs="1"
                  nzSm="1"
                  nzMd="1"
                  nzLg="1"
                  nzXl="1"
                ></div> -->
                <div
                  style="
                    border-bottom: 1px solid;
                    border-top: 1px solid;
                    border-right: 1px solid;
                  "
                  nz-col
                  nzXs="20"
                  nzSm="20"
                  nzMd="20"
                  nzLg="20"
                  nzXl="20"
                >
                  <div
                    style="width: 100%; text-align: center; font-weight: bold"
                  >
                    <div>भारत सरकार</div>
                    <div style="text-transform: uppercase">
                      Government Of India
                    </div>
                    <div>कार्यालय, प्रधान मुख्य आयकर आयुक्त, मुंबई</div>
                    <div style="text-transform: uppercase">OFFICE OF THE</div>
                    <div style="text-transform: uppercase">
                      PRINCIPAL CHIEF COMMISSIONER OF INCOME TAX,
                    </div>
                    <div style="text-transform: uppercase">MUMBAI</div>
                    <!-- <div>
                      तीसरी मंजिल, आयकर भवन, महर्षी कर्वे रोड, मुंबई -20
                    </div> -->
                    <div style="text-transform: uppercase">
                      3<sup style="text-transform: lowercase">rd</sup> Floor,
                      aayakar bhavan, Maharshi Karve Road, Mumbai - 400020
                    </div>
                    <div>
                      (022) - 22016691/22077187 / Fax: 022- 22079273/22077187
                    </div>
                    <div>Email: mumbai.ito.hq.pers@incometax.gov.in</div>
                  </div>
                </div>
              </div>

              <div
                style="
                  width: 100%;
                  padding: 5px;
                  font-size: 13px;
                  border-bottom: 1px solid;
                  border-left: 1px solid;
                  border-right: 1px solid;
                "
              >
                <div nz-row>
                  <div nz-col nzSpan="12">{{ orderdata.ORDER_NO }}</div>

                  <div nz-col nzSpan="12" style="text-align: right">
                    दिनांक
                    :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                  <!-- {{ orderdata.ORDER_DATE | date : "dd MMM yyyy" }} -->
                </div>
              </div>
              <div
                style="font-family: Arial, Helvetica, sans-serif !important"
                *ngIf="orderdata.IS_ADVANCE_TAKEN == 0"
              >
                <div
                  style="
                    text-align: center;
                    margin-top: 20px;
                    margin-bottom: 10px;
                    font-size: 13px;
                  "
                >
                  <b>स्वीकृति आदेश/ SANCTION ORDER</b>
                </div>
                <div style="font-size: 13px !important">
                  <p style="margin-bottom: 2px">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {{ orderdata.EMPLOYEE_NAME_HN }}
                    {{ orderdata.DESIGNATION_NAME_HN }}, को उनके द्वारा दिनांक
                    {{
                      orderdata.TREATMENT_START_DATE | date : "dd MMM yyyy"
                    }}&nbsp; से
                    {{
                      orderdata.TREATMENT_END_DATE | date : "dd MMM yyyy"
                    }}&nbsp; को

                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                      {{ data.NAME_HN }} , {{ data.ADDRESS_HN }}
                      <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                      <span *ngIf="HospitalMapping.length - 2 == k">और</span>
                      <!-- </span> -->
                    </span>

                    में
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Father'"
                        >उनके पिता</span
                      >
                      <!-- <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Father'
                        "
                        >उनके दादाजी</span
                      > -->
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Wife'"
                        >उनकी पत्नी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Husband'"
                        >उनके पती</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Son'"
                        >उनका बेटा</span
                      >
                      <span
                        *ngIf="orderdata.RELATION_WITH_PATIENT == 'Daughter'"
                        >उनकी बेटी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Sister'"
                        >उनकी बहन</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Brother'"
                        >उनके भाई</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'"
                        >उनकी मां</span
                      >
                      <!-- <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Mother'
                        "
                        >उनकी दादी मां</span
                      > -->
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Father-In-Law'
                        "
                        >उनके ससुर</span
                      >
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Mother-In-Law'
                        "
                        >उनकी सासू मां</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} के</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} के</span
                      >
                    </span>

                    &nbsp;
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      स्वयं के</span
                    >
                    {{ orderdata.NATURE_OF_TREATMENT }}
                    के इलाज के लिए खर्च की गई राशि,
                    <span
                      >जो
                      <b>
                        {{
                          orderdata.ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-&nbsp; ({{ admissibleinhindi }})</b
                      >
                      से अधिक नहीं है,</span
                    >
                    को सीजीएचएस दर के अनुसार चिकित्सा व्यय की प्रतिपूर्ती के लिए
                    स्वीकृति प्रदान की जाती है।
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CG'"
                      >यह मंजूरी स्वास्थ्य एवं परिवार कल्याण मंत्रालय, भारत
                      सरकार द्वारा जारी दिनांक 18.03.1992 के कार्यालय ज्ञापन सं.
                      एस-11012/1/91/सीजीएचएस (पी) खंड 1 के माध्यम से मुझे
                      प्रदत्त शक्तियो का प्रयोग करते हुए जारी की जाती है।</span
                    >
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CS'">
                      यह मंजूरी आदेश सेंट्रल सर्विसेस (मेडिकल अटेनडंस),
                      नियमावली, 1944 के परिशिष्ठ । के प्रावधानों के अनुसार जारी
                      किया जाता है।
                    </span>
                  </p>

                  <p style="margin-bottom: 2px">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sanction is
                    hereby accorded for the reimbursement of medical expenses as
                    per CGHS rate for an
                    <span>
                      amount not exceeding
                      <b>
                        {{
                          orderdata.ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-&nbsp; ({{ admissibleineng }})</b
                      >
                    </span>
                     incurred by {{ orderdata.EMPLOYEE_NAME }}&nbsp;,
                    {{ orderdata.DESIGNATION }}
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      for his/her {{ orderdata.RELATION_WITH_PATIENT }}
                      {{ orderdata.PATIENT_NAME }}</span
                    >

                    &nbsp; for&nbsp;{{ orderdata.NATURE_OF_TREATMENT }}
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      of Self</span
                    >, at
                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                      {{ data.NAME }} ,{{ data.ADDRESS }}
                      <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                      <span *ngIf="HospitalMapping.length - 2 == k">and</span>
                      <!-- </span> -->
                    </span>

                    from
                    {{ orderdata.TREATMENT_START_DATE | date : "dd MMM yyyy" }}
                    to
                    {{ orderdata.TREATMENT_END_DATE | date : "dd MMM yyyy" }}.
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CG'">
                      This sanction is issued in exercise of the powers vested
                      in me vide Office Memorandum No.S-11012/1/91/CGHS(P) Vol.
                      I dated 18.03.1992 issued by the Ministry of Health &
                      Family Welfare , Government of India.</span
                    >
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CS'">
                      This sanction is issued in accordance with the provisions
                      of Appendix VIII of the Central Services (Medical
                      Attendance) Rules, 1944.
                    </span>
                  </p>
                  <br />

                  <p
                    style="margin-bottom: 15px; margin-top: 10px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    यह राशि डिस्चार्ज की तारीख से एक महीने के भीतर श्री
                    {{ orderdata.EMPLOYEE_NAME_HN }} द्वारा प्रस्तुत किए जाने
                    वाले नियमित दावों के खिलाफ समायोजित की जाएगी।
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    This amount will be adjusted against regular claims ,
                    within one month from the date of discharge. to be submitted
                    by {{ orderdata.EMPLOYEE_NAME }}.
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    उक्त व्यय को वित्तीय वर्ष 2022-2023 के लिए विभाग को आबंटित
                    मुख्य शीर्ष "2021 चिकित्सा" के अंतर्गत अनुदान के नामे डाला
                    जाए।
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    The above expenditure should be debited to the grant under
                    the major head "2021 - Medical allotted to the Department
                    for Financial Year 2022-23.
                  </p>

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 0
                    "
                  >
                    यह आपात स्थिति का मामला था, जिसके लिए तत्काल उपचार की
                    आवश्यकता थी अतः गैर-पैनलबद्ध अस्पताल में भर्ती कराया गया था|
                  </p>

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 0
                    "
                  >
                    It was a case of emergency , necessitating immediate
                    treatment and was admitted in a non-empanelled hospital.
                  </p>

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 1 &&
                        orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 0 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 1 &&
                        orderdata.IS_HOSPITAL_EMPLANELLED == 0 &&
                        orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 0 &&
                        orderdata.EMERGENCY_TREATEMENT == 0) ||
                      orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1
                    "
                  >
                    दिनांक
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0">
                      {{ orderdata.EXPO_FACTO_DATE }}
                    </span>
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1">
                      {{ orderdata.HOD_PERMISSION_DATE }}
                    </span>
                    के पत्र द्वारा उपरोक्त उपचार के लिये कार्योत्तर अनुमति
                    प्रदान की गई है।
                  </p>

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 1 &&
                        orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 0 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 1 &&
                        orderdata.IS_HOSPITAL_EMPLANELLED == 0 &&
                        orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 0 &&
                        orderdata.EMERGENCY_TREATEMENT == 0) ||
                      orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1
                    "
                  >
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0">
                      Expost Facto Permission for the above treatment has been
                      granted vide letter dated {{ orderdata.EXPO_FACTO_DATE }}
                    </span>
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1">
                      Permission for the above treatment has been granted vide
                      letter dated {{ orderdata.HOD_PERMISSION_DATE }}
                    </span>
                  </p>
                  <!-- <p
                *ngIf="
                  orderdata.IS_BILL_FILLED_IN_TIME == 1 &&
                  orderdata.IS_HOSPITAL_EMPLANELLED == 1 &&
                  orderdata.EXPO_FACTO_PERMISSION == 1
                "
              >
                दिनांक 10/01/2023 के पत्र द्वारा उपरोक्त उपचार के लिए अनुमति
                प्रदान की गई है।
              </p>
              <p
                *ngIf="
                  orderdata.IS_BILL_FILLED_IN_TIME == 1 &&
                  orderdata.IS_HOSPITAL_EMPLANELLED == 1 &&
                  orderdata.EXPO_FACTO_PERMISSION == 1
                "
              >
                Permission for the above treatment has been granted vide letter
                dated 10/01/2023
              </p> -->

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 1
                    "
                  >
                    यह आपात स्थिति का मामला था जिसमें तत्काल प्रवेश की आवश्यकता
                    थी और उसे पैनलबद्ध अस्पताल में भर्ती कराया गया था.
                  </p>
                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 1
                    "
                  >
                    It was a case of Emergency necessitating immediate admission
                    and was admitted in empanelled hospital.
                  </p>
                  <p style="margin-bottom: 2px">
                    उक्त व्यय को वित्तीय वर्ष 2023-2024 के लिए विभाग को आबंटित
                    अनुदान के अंतर्गत मुख्य शीर्ष "2020-चिकित्सा उपचार" से किया
                    जाए।
                  </p>

                  <p style="margin-bottom: 2px">
                    The above expenditure should be debited to the grant under
                    major head 2020 - Medical Treatment Alloted to the
                    Department for the
                    <b>F.Y 2023-2024</b>
                  </p>
                  <br />

                  <p style="margin-bottom: 2px">
                    हिंदी और अंग्रेजी संस्करण में किसी भी भिन्नता के मामले में
                    आदेश का अंग्रेजी संस्करण मान्य होगा।
                  </p>

                  <p style="margin-bottom: 2px">
                    The English version of the order will prevail in case of any
                    variation in Hindi & English version
                  </p>

                  <div style="margin-top: 60px; font-size: 13px">
                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div>(विनय सिन्हा)</div>
                        <div style="font-weight: bold">
                          आयकर आयुक्त (प्रशासन एवं टीपीएस) , मुंबई
                        </div>
                      </div>
                    </div>

                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div>(Vinay Sinha)</div>
                        <div style="font-weight: bold; font-size: 13px">
                          Commissioner Of Income Tax(Admin & TPS),Mumbai.
                        </div>
                        <!-- <div style="font-weight: bold; font-size: 13px">
                          for Pr. Chief Commissioner of Income-Tax, Mumbai
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      style="
                        page-break-inside: avoid !important;
                        font-size: 13px;
                        page-break-before: avoid !important;
                      "
                    >
                      <div style="margin-top: 30px">
                        <b>प्रतिलिपी/Copy to:</b>
                      </div>
                      <div>
                        <span *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                          1)अराजपत्रित वेतन बिल, मुंबई (2 प्रतियां) फॉर्म एमआरसी
                          (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ
                        </span>
                      </div>
                      <div>
                        <span *ngIf="SECTION_TYPE == 'welfare'">
                          1) डीडीओ, कल्याण और एसजी,मुंबई (2 प्रतियां) फॉर्म
                          एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के
                          साथ|</span
                        >
                        <span *ngIf="SECTION_TYPE == 'gazpay'">
                          1) डीडीओ, राजपत्रित वेतन बिल अनुभाग II (कार्मिक),मुंबई
                          (2 प्रतियां) फॉर्म एमआरसी (एस), अस्पताल रसीद,
                          डिस्चार्ज सारांश आदि के साथ|</span
                        >
                        <!-- 1) डीडीओ,कल्याण और एसजी,मुंबई (2 प्रतियां)
                      फॉर्म एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ| -->
                      </div>
                      <div>
                        <!-- 1) DDO , Welfare & SG , Mumbai (2 copies) alongwith Form
                      MRC(S) , hospital receipts , discharge summary etc. -->

                        <span
                          *ngIf="SECTION_TYPE == 'welfare'"
                          style="padding-left: 15px"
                        >
                          DDO , Welfare & SG , Mumbai (2 copies) alongwith Form
                          MRC(S) , hospital receipts , discharge summary etc.
                        </span>
                        <span
                          *ngIf="SECTION_TYPE == 'gazpay'"
                          style="padding-left: 15px"
                        >
                          DDO , Gazetted Pay bill Section II, Personnel Mumbai
                          (2 copies) alongwith Form MRC(S) , hospital receipts ,
                          discharge summary etc.
                        </span>
                      </div>
                    </div>
                    <div
                      style="
                        page-break-inside: avoid !important;
                        font-size: 13px;
                      "
                    >
                    <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 0">
                      <div>
                        2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                        <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                        तैयार किया जा सकता है। |
                      </div>
                      <div>
                         ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                        in favour of {{ orderdata.EMPLOYEE_NAME }}.
                      </div>
                    </div>
                    <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                      <div  *ngIf="orderdata.CHEQUE_DRAWN==0">
                        2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                        <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                        तैयार किया जा सकता है। |
                      </div>
                      <div  *ngIf="orderdata.CHEQUE_DRAWN==0">
                         ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                        in favour of {{ orderdata.EMPLOYEE_NAME }}.
                      </div>
                      <div  *ngIf="orderdata.CHEQUE_DRAWN==1">
                        2) {{orderdata.ZAO_HN}}
                      </div>
                      <div  *ngIf="orderdata.CHEQUE_DRAWN==1">
                          {{orderdata.ZAO}}
                      </div>
                    </div>

                      <div>
                        3)<b>
                          {{ orderdata.EMPLOYEE_NAME_HN }},
                          {{ orderdata.DESIGNATION_NAME_HN }}
                          {{ orderdata.OFFICE_NAME_HN }}
                          <!-- {{ orderdata.LOCATION_HN }}., -->
                        </b>
                        स्वीकार्य राशि की गणना की प्रति के साथ |
                      </div>

                      <div style="padding-left: 15px">
                        <b
                          >{{ orderdata.EMPLOYEE_NAME }} ,
                          {{ orderdata.DESIGNATION }}
                          {{ orderdata.EMPLOYEE_OFFICE_NAME }}
                          <!-- {{ orderdata.LOCATION }}., -->
                        </b>
                        along with copy of working of admissible amount
                      </div>

                      <div style="margin-top: 60px">
                        <div nz-row>
                          <div nz-col nzSpan="15"></div>

                          <div nz-col nzSpan="9" style="text-align: center">
                            <div style="font-weight: bold">({{ NAME_HN }})</div>
                            <div>{{ POST_HN }}</div>
                            <div>{{ OFFICE_NAME_HN }}</div>
                            <div style="font-weight: bold">
                              ({{ SIGNNAME }})
                            </div>
                            <div>{{ POST }}</div>
                            <div>{{ OFFICE_NAME }}</div>
                            <!-- <span *ngIf="SECTION_TYPE == 'welfare'">
                              <div style="font-weight: bold">
                                (रीतेश कुमार)
                              </div>
                              <div>आयकर अधिकारी (मुख्‍यालय)</div>
                              <div>कल्याण और कर्मचारी शिकायत, मुंबई</div>
                            </span>

                            <span *ngIf="SECTION_TYPE == 'gazpay'">
                              <div style="font-weight: bold">
                                (जितेंद्र रा चांदेकर)
                              </div>
                              <div>आयकर अधिकारी</div>
                              <div>राजपत्रित वेतन बिल अनुभाग II, मुंबई</div>
                            </span> -->
                          </div>
                        </div>

                        <!-- <div nz-row>
                          <div nz-col nzSpan="15"></div>

                          <div nz-col nzSpan="9" style="text-align: center">
                            <span *ngIf="SECTION_TYPE == 'welfare'">
                              <div style="font-weight: bold">
                                (Ritesh Kumar)
                              </div>
                              <div>Income Tax Officer(HQ)</div>
                              <div>Welfare & S.G, Mumbai</div>
                            </span>
                            <span *ngIf="SECTION_TYPE == 'gazpay'">
                              <div style="font-weight: bold">
                                (Jitendra R Chandekar)
                              </div>
                              <div>Income Tax Officer</div>
                              <div>
                                Gazetted Pay Bill Section II Personnel, Mumbai
                              </div>
                            </span>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style="font-family: Arial, Helvetica, sans-serif !important"
                *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
              >
                <div
                  style="
                    text-align: center;
                    margin-top: 20px;
                    margin-bottom: 10px;
                    font-size: 13px;
                  "
                >
                  <b>स्वीकृति आदेश/ SANCTION ORDER</b>
                </div>
                <div style="font-size: 13px !important">
                  <p style="margin-bottom: 2px">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {{ orderdata.EMPLOYEE_NAME_HN }}
                    {{ orderdata.DESIGNATION_NAME_HN }}, को उनके द्वारा दिनांक
                    {{
                      orderdata.TREATMENT_START_DATE | date : "dd MMM yyyy"
                    }}&nbsp; से
                    {{
                      orderdata.TREATMENT_END_DATE | date : "dd MMM yyyy"
                    }}&nbsp; को

                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                      {{ data.NAME_HN }} , {{ data.ADDRESS_HN }}
                      <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                      <span *ngIf="HospitalMapping.length - 2 == k">और</span>
                      <!-- </span> -->
                    </span>

                    में
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Father'"
                        >उनके पिता</span
                      >
                      <!-- <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Father'
                        "
                        >उनके दादाजी</span
                      > -->
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Wife'"
                        >उनकी पत्नी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Husband'"
                        >उनके पती</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Son'"
                        >उनका बेटा</span
                      >
                      <span
                        *ngIf="orderdata.RELATION_WITH_PATIENT == 'Daughter'"
                        >उनकी बेटी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Sister'"
                        >उनकी बहन</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Brother'"
                        >उनके भाई</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'"
                        >उनकी मां</span
                      >
                      <!-- <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Mother'
                        "
                        >उनकी दादी मां</span
                      > -->
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Father-In-Law'
                        "
                        >उनके ससुर</span
                      >
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Mother-In-Law'
                        "
                        >उनकी सासू मां</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} के</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} के</span
                      >
                    </span>

                    &nbsp;
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      स्वयं के</span
                    >
                    {{ orderdata.NATURE_OF_TREATMENT }}
                    के इलाज के लिए खर्च की गई राशि,
                    <span *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                      >जो
                      <b
                        >{{
                          orderdata.ADVANCE_ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-&nbsp; ({{ advadmissibleinhindi }})</b
                      >
                      <b>
                        जो कि
                        {{
                          orderdata.ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-</b
                      >
                      की स्वीकार्य राशि का 90% (लगभग) से अधिक नहीं है,</span
                    >
                    को सीजीएचएस दर के अनुसार चिकित्सा व्यय की प्रतिपूर्ती के लिए
                    स्वीकृति प्रदान की जाती है।
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CG'"
                      >यह मंजूरी स्वास्थ्य एवं परिवार कल्याण मंत्रालय, भारत
                      सरकार द्वारा जारी दिनांक 18.03.1992 के कार्यालय ज्ञापन सं.
                      एस-11012/1/91/सीजीएचएस (पी) खंड 1 के माध्यम से मुझे
                      प्रदत्त शक्तियो का प्रयोग करते हुए जारी की जाती है।</span
                    >
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CS'">
                      यह मंजूरी आदेश सेंट्रल सर्विसेस (मेडिकल अटेनडंस),
                      नियमावली, 1944 के परिशिष्ठ । के प्रावधानों के अनुसार जारी
                      किया जाता है।
                    </span>
                  </p>

                  <p style="margin-bottom: 2px">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sanction is
                    hereby accorded for the reimbursement of medical expenses as
                    per CGHS rate for an
                    <span *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                      amount not exceeding
                      <b>
                        {{
                          orderdata.ADVANCE_ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-&nbsp; ({{ advadmissibleineng }})</b
                      >
                      being 90% (approximately) of the admissible amount of
                      <b>
                        {{
                          orderdata.ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-</b
                      >
                    </span>
                     incurred by {{ orderdata.EMPLOYEE_NAME }}&nbsp;,
                    {{ orderdata.DESIGNATION }}
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      for his/her {{ orderdata.RELATION_WITH_PATIENT }}
                      {{ orderdata.PATIENT_NAME }}</span
                    >

                    &nbsp; for&nbsp;{{ orderdata.NATURE_OF_TREATMENT }}
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      of Self</span
                    >, at
                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                      {{ data.NAME }} ,{{ data.ADDRESS }}
                      <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                      <span *ngIf="HospitalMapping.length - 2 == k">and</span>
                      <!-- </span> -->
                    </span>

                    from
                    {{ orderdata.TREATMENT_START_DATE | date : "dd MMM yyyy" }}
                    to
                    {{ orderdata.TREATMENT_END_DATE | date : "dd MMM yyyy" }}.
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CG'">
                      This sanction is issued in exercise of the powers vested
                      in me vide Office Memorandum No.S-11012/1/91/CGHS(P) Vol.
                      I dated 18.03.1992 issued by the Ministry of Health &
                      Family Welfare , Government of India.</span
                    >
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CS'">
                      This sanction is issued in accordance with the provisions
                      of Appendix VIII of the Central Services (Medical
                      Attendance) Rules, 1944.
                    </span>
                  </p>

                  <p
                    style="margin-bottom: 15px; margin-top: 10px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    यह राशि डिस्चार्ज की तारीख से एक महीने के भीतर श्री
                    {{ orderdata.EMPLOYEE_NAME_HN }} द्वारा प्रस्तुत किए जाने
                    वाले नियमित दावों के खिलाफ समायोजित की जाएगी।
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    This amount will be adjusted against regular claims ,
                    within one month from the date of discharge. to be submitted
                    by {{ orderdata.EMPLOYEE_NAME }}.
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    उक्त व्यय को वित्तीय वर्ष 2022-2023 के लिए विभाग को आबंटित
                    मुख्य शीर्ष "2021 चिकित्सा" के अंतर्गत अनुदान के नामे डाला
                    जाए।
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    The above expenditure should be debited to the grant under
                    the major head "2021 - Medical allotted to the Department
                    for Financial Year 2022-23.
                  </p>

                  <div style="margin-top: 60px; font-size: 13px">
                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div>(विनय सिन्हा)</div>
                        <div style="font-weight: bold">
                          आयकर आयुक्त (प्रशासन एवं टीपीएस) , मुंबई
                        </div>
                      </div>
                    </div>

                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div>(Vinay Sinha)</div>
                        <div style="font-weight: bold; font-size: 13px">
                          Commissioner Of Income Tax(Admin & TPS),Mumbai.
                        </div>
                        <!-- <div style="font-weight: bold; font-size: 13px">
                          for Pr. Chief Commissioner of Income-Tax, Mumbai
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      style="
                        page-break-inside: avoid !important;
                        font-size: 13px;
                        page-break-before: avoid !important;
                      "
                    >
                      <div style="margin-top: 30px">
                        <b>प्रतिलिपी/Copy to:</b>
                      </div>
                      <div>
                        <span *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                          1)अराजपत्रित वेतन बिल, मुंबई (2 प्रतियां) फॉर्म एमआरसी
                          (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ
                        </span>
                      </div>
                      <div>
                        <span *ngIf="SECTION_TYPE == 'welfare'">
                          1) डीडीओ, कल्याण और एसजी,मुंबई (2 प्रतियां) फॉर्म
                          एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के
                          साथ|</span
                        >
                        <span *ngIf="SECTION_TYPE == 'gazpay'">
                          1) डीडीओ, राजपत्रित वेतन बिल अनुभाग II (कार्मिक),मुंबई
                          (2 प्रतियां) फॉर्म एमआरसी (एस), अस्पताल रसीद,
                          डिस्चार्ज सारांश आदि के साथ|</span
                        >
                        <!-- 1) डीडीओ,कल्याण और एसजी,मुंबई (2 प्रतियां)
                      फॉर्म एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ| -->
                      </div>
                      <div>
                        <!-- 1) DDO , Welfare & SG , Mumbai (2 copies) alongwith Form
                      MRC(S) , hospital receipts , discharge summary etc. -->

                        <span
                          *ngIf="SECTION_TYPE == 'welfare'"
                          style="padding-left: 15px"
                        >
                          DDO , Welfare & SG , Mumbai (2 copies) alongwith Form
                          MRC(S) , hospital receipts , discharge summary etc.
                        </span>
                        <span
                          *ngIf="SECTION_TYPE == 'gazpay'"
                          style="padding-left: 15px"
                        >
                          DDO , Gazetted Pay bill Section II, Personnel Mumbai
                          (2 copies) alongwith Form MRC(S) , hospital receipts ,
                          discharge summary etc.
                        </span>
                      </div>
                    </div>
                    <div
                      style="
                        page-break-inside: avoid !important;
                        font-size: 13px;
                      "
                    >
                      <!-- <div>
                        2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                        <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                        तैयार किया जा सकता है। |
                      </div>
                      <div style="padding-left: 15px">
                          ZAO , Pre-check unit , Mumbai - Cheque may be drawn in
                        favour of {{ orderdata.EMPLOYEE_NAME }}.
                      </div> -->
                      <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 0">
                        <div>
                          2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                          <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                          तैयार किया जा सकता है। |
                        </div>
                        <div style="padding-left: 15px">
                           ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                          in favour of {{ orderdata.EMPLOYEE_NAME }}.
                        </div>
                      </div>
                      <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                        <div  *ngIf="orderdata.CHEQUE_DRAWN==0">
                          2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                          <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                          तैयार किया जा सकता है। |
                        </div>
                        <div style="padding-left: 15px"  *ngIf="orderdata.CHEQUE_DRAWN==0">
                           ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                          in favour of {{ orderdata.EMPLOYEE_NAME }}.
                        </div>
                        <div  *ngIf="orderdata.CHEQUE_DRAWN==1">
                          2) {{orderdata.ZAO_HN}}
                        </div>
                        <div style="padding-left: 15px"  *ngIf="orderdata.CHEQUE_DRAWN==1">
                            {{orderdata.ZAO}}
                        </div>
                      </div>

                      <div>
                        3)<b>
                          {{ orderdata.EMPLOYEE_NAME_HN }},
                          {{ orderdata.DESIGNATION_NAME_HN }}
                          {{ orderdata.OFFICE_NAME_HN }}
                          <!-- {{ orderdata.LOCATION_HN }}., -->
                        </b>
                        स्वीकार्य राशि की गणना की प्रति के साथ |
                      </div>

                      <div style="padding-left: 15px">
                        <b
                          >{{ orderdata.EMPLOYEE_NAME }} ,
                          {{ orderdata.DESIGNATION }}
                          {{ orderdata.EMPLOYEE_OFFICE_NAME }}
                          <!-- {{ orderdata.LOCATION }}., -->
                        </b>
                        along with copy of working of admissible amount
                      </div>

                      <div style="margin-top: 60px">
                        <div nz-row>
                          <div nz-col nzSpan="15"></div>

                          <div nz-col nzSpan="9" style="text-align: center">
                            <div style="font-weight: bold">({{ NAME_HN }})</div>
                            <div>{{ POST_HN }}</div>
                            <div>{{ OFFICE_NAME_HN }}</div>
                            <div style="font-weight: bold">
                              ({{ SIGNNAME }})
                            </div>
                            <div>{{ POST }}</div>
                            <div>{{ OFFICE_NAME }}</div>
                            <!-- <span *ngIf="SECTION_TYPE == 'welfare'">
                              <div style="font-weight: bold">
                                (रीतेश कुमार)
                              </div>
                              <div>आयकर अधिकारी (मुख्‍यालय)</div>
                              <div>कल्याण और कर्मचारी शिकायत, मुंबई</div>
                            </span>

                            <span *ngIf="SECTION_TYPE == 'gazpay'">
                              <div style="font-weight: bold">
                                (जितेंद्र रा चांदेकर)
                              </div>
                              <div>आयकर अधिकारी</div>
                              <div>राजपत्रित वेतन बिल अनुभाग II, मुंबई</div>
                            </span> -->
                          </div>
                        </div>

                        <!-- <div nz-row>
                          <div nz-col nzSpan="15"></div>

                          <div nz-col nzSpan="9" style="text-align: center">
                            <span *ngIf="SECTION_TYPE == 'welfare'">
                              <div style="font-weight: bold">
                                (Ritesh Kumar)
                              </div>
                              <div>Income Tax Officer(HQ)</div>
                              <div>Welfare & S.G, Mumbai</div>
                            </span>
                            <span *ngIf="SECTION_TYPE == 'gazpay'">
                              <div style="font-weight: bold">
                                (Jitendra R Chandekar)
                              </div>
                              <div>Income Tax Officer</div>
                              <div>
                                Gazetted Pay Bill Section II Personnel, Mumbai
                              </div>
                            </span>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>
  </ng-template>

  <ng-template #printLatestOrderModalFooter>
    <button nz-button nzType="default" (click)="printLatestOrderModalCancel()">
      Close
    </button>

    <button
      nz-button
      nzType="default"
      [useExistingCss]="true"
      printSectionId="printOrderModalLatest1"
      ngxPrint
      [nzLoading]="pdfDownload"
    >
      Print
    </button>

    <button
      nz-button
      nzType="primary"
      [nzLoading]="pdfDownload"
      (click)="generateLatestOrderPDF()"
    >
      Download
    </button>
  </ng-template>
</nz-modal>

<nz-modal
  [nzWidth]="getwidth()"
  [(nzVisible)]="printLatestOrderModalVisible"
  nzMaskClosable="false"
  nzClosable="false"
  [nzContent]="printOrderModalContentLatest"
  [nzTitle]="'Sanction Order'"
  (nzOnCancel)="printLatestOrderModalCancel()"
  [nzFooter]="printLatestOrderModalFooter"
>
  <ng-template #printOrderModalContentLatest>
    <nz-spin [nzSpinning]="pdfDownload">
      <div style="height: 450px; overflow: auto">
        <div id="printOrderModalLatest">
          <div style="width: 750px; padding: 3px; margin-left: 30px">
            <div>
              <div nz-row>
                <div
                  style="border: 1px solid"
                  nz-col
                  nzXs="4"
                  nzSm="4"
                  nzMd="4"
                  nzLg="4"
                  nzXl="4"
                >
                  <div style="text-align: center; padding: 36px 9px 9px 9px">
                    <img
                      src="assets/govtOfIndia.png"
                      alt=""
                      height="140px"
                      width="100%"
                    />
                  </div>
                </div>
                <!-- <div
                  style="
                    border-bottom: 1px solid;
                    border-top: 1px solid;
                    border-right: 1px solid;
                  "
                  nz-col
                  nzXs="1"
                  nzSm="1"
                  nzMd="1"
                  nzLg="1"
                  nzXl="1"
                ></div> -->
                <div
                  style="
                    border-bottom: 1px solid;
                    border-top: 1px solid;
                    border-right: 1px solid;
                  "
                  nz-col
                  nzXs="20"
                  nzSm="20"
                  nzMd="20"
                  nzLg="20"
                  nzXl="20"
                >
                  <div
                    style="width: 100%; text-align: center; font-weight: bold"
                  >
                    <div>भारत सरकार</div>
                    <div style="text-transform: uppercase" class="family">
                      Government Of India
                    </div>
                    <div>कार्यालय, प्रधान मुख्य आयकर आयुक्त, मुंबई</div>
                    <div style="text-transform: uppercase" class="family">
                      OFFICE OF THE
                    </div>
                    <div style="text-transform: uppercase" class="family">
                      PRINCIPAL CHIEF COMMISSIONER OF INCOME TAX,
                    </div>
                    <div class="family" style="text-transform: uppercase">
                      MUMBAI
                    </div>
                    <!-- <div>
                      तीसरी मंजिल, आयकर भवन, महर्षी कर्वे रोड, मुंबई -20
                    </div> -->
                    <div style="text-transform: uppercase" class="family">
                      3<sup style="text-transform: lowercase">rd</sup> Floor,
                      aayakar bhavan, Maharshi Karve Road, Mumbai - 400020
                    </div>
                    <div>
                      (022) - 22016691/22077187 / Fax: 022- 22079273/22077187
                    </div>
                    <div class="family">
                      Email:
                      <span *ngIf="orderdata.SIGNATURE_ID == 2">
                        mumbai.ito.gpbs2@incometax.gov.in
                      </span>
                      <span *ngIf="orderdata.SIGNATURE_ID == 3">
                        mumbai.ito.hq.pers@incometax.gov.in
                      </span>
                      <!-- <span *ngIf="userId != 18 && userId != 48">
                        mumbai.ito.hq.pers@incometax.gov.in</span
                      > -->
                    </div>
                  </div>
                </div>
              </div>

              <div
                style="
                  width: 100%;
                  padding: 5px;
                  font-size: 13px;
                  border-bottom: 1px solid;
                  border-left: 1px solid;
                  border-right: 1px solid;
                "
              >
                <div nz-row>
                  <div nz-col nzSpan="17">{{ orderdata.ORDER_NO }}</div>
                  <div nz-col nzSpan="1"></div>
                  <div nz-col nzSpan="6">
                    दिनांक
                    :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                  <!-- {{ orderdata.ORDER_DATE | date : "dd MMM yyyy" }} -->
                </div>
              </div>
              <div
                style="font-family: Arial, Helvetica, sans-serif !important"
                *ngIf="orderdata.IS_ADVANCE_TAKEN == 0"
              >
                <div
                  style="
                    text-align: center;
                    margin-top: 10px;
                    margin-bottom: 5px;
                    font-size: 13px;
                  "
                >
                  <b>स्वीकृति आदेश/ SANCTION ORDER</b>
                </div>
                <div style="font-size: 13px !important">
                  <p style="margin-bottom: 2px">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {{ orderdata.EMPLOYEE_NAME_HN }},
                    {{ orderdata.DESIGNATION_NAME_HN }}, को उनके द्वारा दिनांक
                    {{
                      orderdata.TREATMENT_START_DATE | date : "dd MMM yyyy"
                    }}&nbsp; से
                    {{
                      orderdata.TREATMENT_END_DATE | date : "dd MMM yyyy"
                    }}&nbsp; तक

                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                      {{ data.NAME_HN }} , {{ data.ADDRESS_HN }}
                      <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                      <span *ngIf="HospitalMapping.length - 2 == k">और</span>
                      <!-- </span> -->
                    </span>

                    में
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Father'"
                        >उनके पिता</span
                      >
                      <!-- <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Father'
                        "
                        >उनके दादाजी</span
                      > -->
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Wife'"
                        >उनकी पत्नी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Husband'"
                        >उनके पती</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Son'"
                        >उनका बेटा</span
                      >
                      <span
                        *ngIf="orderdata.RELATION_WITH_PATIENT == 'Daughter'"
                        >उनकी बेटी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Sister'"
                        >उनकी बहन</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Brother'"
                        >उनके भाई</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'"
                        >उनकी मां</span
                      >
                      <!-- <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Mother'
                        "
                        >उनकी दादी मां</span
                      > -->
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Father-In-Law'
                        "
                        >उनके ससुर</span
                      >
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Mother-In-Law'
                        "
                        >उनकी सासू मां</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} के</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} के</span
                      >
                    </span>

                    &nbsp;
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      स्वयं के</span
                    >
                    {{ orderdata.NATURE_OF_TREATMENT }}
                    के लिए खर्च की गई राशि,
                    <!-- के चिकित्सा हेतु परमार्श और दवा के लिए खर्च की गई राशि, -->
                    <span
                      >जो
                      <b>
                        {{
                          orderdata.ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-&nbsp; ({{ admissibleinhindi }})</b
                      >
                      से अधिक नहीं है,</span
                    >
                    के लिए सीजीएचएस दर के अनुसार चिकित्सा व्यय की प्रतिपूर्ती की
                    स्वीकृति प्रदान की जाती है।
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CG'"
                      >यह स्वीकृति स्वास्थ्य एवं परिवार कल्याण मंत्रालय, भारत
                      सरकार द्वारा जारी दिनांक 18.03.1992 के कार्यालय ज्ञापन सं.
                      एस-11012/1/91/सीजीएचएस (पी) खंड 1 के माध्यम से मुझे
                      प्रदत्त शक्तियो का प्रयोग करते हुए जारी की जाती है।</span
                    >
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CS'">
                      यह स्वीकृति आदेश सेंट्रल सर्विसेस (मेडिकल अटेनडंस),
                      नियमावली, 1944 के परिशिष्ठ । के प्रावधानों के अनुसार जारी
                      किया जाता है।
                    </span>
                  </p>

                  <p style="margin-bottom: 2px">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sanction is
                    hereby accorded for the reimbursement of medical expenses as
                    per CGHS rate for an
                    <span>
                      amount not exceeding
                      <b>
                        {{
                          orderdata.ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-&nbsp; ({{ admissibleineng }})</b
                      >
                    </span>
                     incurred by {{ orderdata.EMPLOYEE_NAME }}&nbsp;,
                    {{ orderdata.DESIGNATION }}
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      for his/her {{ orderdata.RELATION_WITH_PATIENT }}
                      {{ orderdata.PATIENT_NAME }}</span
                    >

                    &nbsp; for&nbsp;{{ orderdata.NATURE_OF_TREATMENT }}
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      of Self</span
                    >, at
                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                      {{ data.NAME }} ,{{ data.ADDRESS }}
                      <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                      <span *ngIf="HospitalMapping.length - 2 == k">and</span>
                      <!-- </span> -->
                    </span>

                    from
                    {{ orderdata.TREATMENT_START_DATE | date : "dd MMM yyyy" }}
                    to
                    {{ orderdata.TREATMENT_END_DATE | date : "dd MMM yyyy" }}.
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CG'">
                      This sanction is issued in exercise of the powers vested
                      in me vide Office Memorandum No.S-11012/1/91/CGHS(P) Vol.
                      I dated 18.03.1992 issued by the Ministry of Health &
                      Family Welfare , Government of India.</span
                    >
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CS'">
                      This sanction is issued in accordance with the provisions
                      of Appendix VIII of the Central Services (Medical
                      Attendance) Rules, 1944.
                    </span>
                  </p>

                  <p
                    style="margin-bottom: 15px; margin-top: 10px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    यह राशि डिस्चार्ज की तारीख से एक महीने के भीतर श्री
                    {{ orderdata.EMPLOYEE_NAME_HN }} द्वारा प्रस्तुत किए जाने
                    वाले नियमित दावों के खिलाफ समायोजित की जाएगी।
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    This amount will be adjusted against regular claims ,
                    within one month from the date of discharge. to be submitted
                    by {{ orderdata.EMPLOYEE_NAME }}.
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    उक्त व्यय को वित्तीय वर्ष 2023-2024 के लिए विभाग को आबंटित
                    मुख्य शीर्ष "2020 चिकित्सा" के अंतर्गत अनुदान के नामे डाला
                    जाए।
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    The above expenditure should be debited to the grant under
                    the major head "2020 - Medical allotted to the Department
                    for Financial Year 2023-2024.
                  </p>

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 0
                    "
                  >
                    यह आपात स्थिति का मामला था, जिसके लिए तत्काल उपचार की
                    आवश्यकता थी अतः गैर-पैनलबद्ध अस्पताल में भर्ती कराया गया था|
                  </p>

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 0
                    "
                  >
                    It was a case of emergency , necessitating immediate
                    treatment and was admitted in a non-empanelled hospital.
                  </p>

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 1 &&
                        orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 0 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 1 &&
                        orderdata.IS_HOSPITAL_EMPLANELLED == 0 &&
                        orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 0 &&
                        orderdata.EMERGENCY_TREATEMENT == 0) ||
                      orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1
                    "
                  >
                    दिनांक
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0">
                      {{ orderdata.EXPO_FACTO_DATE }}
                    </span>
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1">
                      {{ orderdata.HOD_PERMISSION_DATE }}
                    </span>
                    <!-- के पत्र द्वारा उपरोक्त उपचार के लिये कार्योत्तर अनुमति -->
                    के पत्र द्वारा उपरोक्त उपचार के लिये अनुमति प्रदान की गई है।
                  </p>

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 1 &&
                        orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 0 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 1 &&
                        orderdata.IS_HOSPITAL_EMPLANELLED == 0 &&
                        orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 0 &&
                        orderdata.EMERGENCY_TREATEMENT == 0) ||
                      orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1
                    "
                  >
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0">
                      Expost Facto Permission for the above treatment has been
                      granted vide letter dated {{ orderdata.EXPO_FACTO_DATE }}
                    </span>
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1">
                      Permission for the above treatment has been granted vide
                      letter dated {{ orderdata.HOD_PERMISSION_DATE }}
                    </span>
                  </p>
                  <!-- <p
                *ngIf="
                  orderdata.IS_BILL_FILLED_IN_TIME == 1 &&
                  orderdata.IS_HOSPITAL_EMPLANELLED == 1 &&
                  orderdata.EXPO_FACTO_PERMISSION == 1
                "
              >
                दिनांक 10/01/2023 के पत्र द्वारा उपरोक्त उपचार के लिए अनुमति
                प्रदान की गई है।
              </p>
              <p
                *ngIf="
                  orderdata.IS_BILL_FILLED_IN_TIME == 1 &&
                  orderdata.IS_HOSPITAL_EMPLANELLED == 1 &&
                  orderdata.EXPO_FACTO_PERMISSION == 1
                "
              >
                Permission for the above treatment has been granted vide letter
                dated 10/01/2023
              </p> -->

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 1
                    "
                  >
                    यह आपात स्थिति का मामला था जिसमें तत्काल प्रवेश की आवश्यकता
                    थी और उसे पैनलबद्ध अस्पताल में भर्ती कराया गया था.
                  </p>
                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 1
                    "
                  >
                    It was a case of Emergency necessitating immediate admission
                    and was admitted in empanelled hospital.
                  </p>
                  <p style="margin-bottom: 2px">
                    उक्त व्यय को वित्तीय वर्ष 2023-2024 के लिए विभाग को आबंटित
                    अनुदान के अंतर्गत मुख्य शीर्ष "2020-चिकित्सा उपचार" से किया
                    जाए।
                  </p>

                  <p style="margin-bottom: 2px">
                    The above expenditure should be debited to the grant under
                    major head 2020 - Medical Treatment Alloted to the
                    Department for the
                    <b>F.Y 2023-2024.</b>
                  </p>

                  <p style="margin-bottom: 2px">
                    हिंदी और अंग्रेजी संस्करण में किसी भी भिन्नता के मामले में
                    आदेश का अंग्रेजी संस्करण मान्य होगा।
                  </p>

                  <p style="margin-bottom: 2px">
                    The English version of the order will prevail in case of any
                    variation in Hindi & English version.
                  </p>

                  <div style="margin-top: 60px; font-size: 13px">
                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div><b> (विनय सिन्हा) </b></div>
                        <div style="font-weight: bold">
                          आयकर आयुक्त (प्रशासन एवं करदाता सेवाएँ) , मुंबई
                          <!-- <span *ngIf="orderdata.SIGNATURE_ID == 2">
                            आयकर आयुक्त (प्रशासन एवं करदाता सेवाएँ) , मुंबई
                          </span>
                          <span *ngIf="orderdata.SIGNATURE_ID != 2">
                            आयकर आयुक्त (प्रशासन एवं टीपीएस) , मुंबई</span
                          > -->
                        </div>
                      </div>
                    </div>

                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div><b> (Vinay Sinha) </b></div>
                        <div style="font-weight: bold; font-size: 13px">
                          Commissioner Of Income Tax(Admin & TPS),Mumbai.
                          <!-- <span *ngIf="orderdata.SIGNATURE_ID != 2"
                            >Commissioner Of Income Tax(Admin &
                            TPS),Mumbai.</span
                          >
                          <span *ngIf="orderdata.SIGNATURE_ID == 2">
                            For Principal Chief Commissioner of Income Tax
                            Mumbai</span
                          > -->
                        </div>
                        <!-- <div
                          style="font-weight: bold; font-size: 13px"
                          *ngIf="orderdata.SIGNATURE_ID != 3"
                        >
                          for Pr. Chief Commissioner of Income-Tax, Mumbai
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      style="
                        page-break-inside: avoid !important;
                        font-size: 13px;
                        page-break-before: avoid !important;
                      "
                    >
                      <div style="margin-top: 30px">
                        <b>प्रतिलिपी/Copy to:</b>
                      </div>
                      <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                        <span>
                          1)अराजपत्रित वेतन बिल, मुंबई (2 प्रतियां) फॉर्म एमआरसी
                          (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ
                        </span>
                        <br />
                        <span style="padding-left: 15px">
                          The Non Gazetted Pay Bill Section (2 copies) alongwith
                          Form MRC(S) , hospital receipts , discharge summary
                          etc.
                        </span>
                        <br />

                        <!-- <span *ngIf="SECTION_TYPE == 'welfare'">
                          1) डीडीओ, कल्याण और एसजी,मुंबई (2 प्रतियां) फॉर्म
                          एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के
                          साथ|</span
                        > <br>
                        <span *ngIf="SECTION_TYPE == 'gazpay'">
                          1) डीडीओ, राजपत्रित वेतन बिल अनुभाग II (कार्मिक),मुंबई
                          (2 प्रतियां) फॉर्म एमआरसी (एस), अस्पताल रसीद,
                          डिस्चार्ज सारांश आदि के साथ |</span
                        >
                        <br>
                        <span
                        *ngIf="SECTION_TYPE == 'welfare'"
                        style="padding-left: 15px"
                      >
                        DDO , Welfare & SG , Mumbai (2 copies) alongwith Form
                        MRC(S) , hospital receipts , discharge summary etc.
                      </span>
                      <br>
                      <span
                        *ngIf="SECTION_TYPE == 'gazpay'"
                        style="padding-left: 15px"
                      >
                        DDO , Gazetted Pay bill Section II, Personnel Mumbai
                        (2 copies) alongwith Form MRC(S) , hospital receipts ,
                        discharge summary etc.
                      </span> -->
                      <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 0">
                        <div>
                          2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                          <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                          तैयार किया जा सकता है। |
                        </div>
                        <div style="padding-left: 15px">
                           ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                          in favour of {{ orderdata.EMPLOYEE_NAME }}.
                        </div>
                      </div>
                      <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                        <div  *ngIf="orderdata.CHEQUE_DRAWN==0">
                          2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                          <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                          तैयार किया जा सकता है। |
                        </div>
                        <div style="padding-left: 15px"  *ngIf="orderdata.CHEQUE_DRAWN==0">
                           ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                          in favour of {{ orderdata.EMPLOYEE_NAME }}.
                        </div>
                        <div  *ngIf="orderdata.CHEQUE_DRAWN==1">
                          2) {{orderdata.ZAO_HN}}
                        </div>
                        <div style="padding-left: 15px"  *ngIf="orderdata.CHEQUE_DRAWN==1">
                            {{orderdata.ZAO}}
                        </div>
                      </div>

                        <!-- <div>
                          2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                          <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                          तैयार किया जा सकता है।
                        </div>
                        <div style="padding-left: 15px">
                            ZAO , Pre-check unit , Mumbai - Cheque may be drawn in
                          favour of {{ orderdata.EMPLOYEE_NAME }}.
                        </div> -->

                        <div>
                          3)<b>
                            {{ orderdata.EMPLOYEE_NAME_HN }},
                            {{ orderdata.DESIGNATION_NAME_HN }} कार्यालय
                            {{ orderdata.OFFICE_NAME_HN }}
                            <!-- {{ orderdata.LOCATION_HN }}., -->
                          </b>
                          स्वीकार्य राशि की गणना की प्रति के साथ |
                        </div>

                        <div style="padding-left: 15px">
                          <b
                            >{{ orderdata.EMPLOYEE_NAME }},
                            {{ orderdata.DESIGNATION }} O/o
                            {{ orderdata.EMPLOYEE_OFFICE_NAME }}
                            <!-- {{ orderdata.LOCATION }}., -->
                          </b>
                          along with copy of working of admissible amount.
                        </div>
                      </div>

                      <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 0">
                        <div>
                          <span *ngIf="SECTION_TYPE == 'welfare'">
                            1) डीडीओ, कल्याण और एसजी,मुंबई (2 प्रतियां) फॉर्म
                            एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के
                            साथ|</span
                          >
                          <span *ngIf="SECTION_TYPE == 'gazpay'">
                            1) डीडीओ, राजपत्रित वेतन बिल अनुभाग II
                            (कार्मिक),मुंबई (2 प्रतियां) फॉर्म एमआरसी (एस),
                            अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ |</span
                          >
                          <!-- 1) डीडीओ,कल्याण और एसजी,मुंबई (2 प्रतियां)
                      फॉर्म एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ| -->
                        </div>
                        <div>
                          <!-- 1) DDO , Welfare & SG , Mumbai (2 copies) alongwith Form
                      MRC(S) , hospital receipts , discharge summary etc. -->

                          <span
                            *ngIf="SECTION_TYPE == 'welfare'"
                            style="padding-left: 15px"
                          >
                            DDO , Welfare & SG , Mumbai (2 copies) alongwith
                            Form MRC(S) , hospital receipts , discharge summary
                            etc.
                          </span>
                          <span
                            *ngIf="SECTION_TYPE == 'gazpay'"
                            style="padding-left: 15px"
                          >
                            DDO , Gazetted Pay bill Section II, Personnel Mumbai
                            (2 copies) alongwith Form MRC(S) , hospital receipts
                            , discharge summary etc.
                          </span>
                        </div>
                        <div>
                          2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                          <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                          तैयार किया जा सकता है।
                        </div>
                        <div style="padding-left: 15px">
                            ZAO , Pre-check unit , Mumbai - Cheque may be drawn in
                          favour of {{ orderdata.EMPLOYEE_NAME }}.
                        </div>

                        <div>
                          3)<b>
                            {{ orderdata.EMPLOYEE_NAME_HN }},
                            {{ orderdata.DESIGNATION_NAME_HN }} कार्यालय
                            {{ orderdata.OFFICE_NAME_HN }}
                            <!-- {{ orderdata.LOCATION_HN }}., -->
                          </b>
                          स्वीकार्य राशि की गणना की प्रति के साथ |
                        </div>

                        <div style="padding-left: 15px">
                          <b
                            >{{ orderdata.EMPLOYEE_NAME }},
                            {{ orderdata.DESIGNATION }} O/o
                            {{ orderdata.EMPLOYEE_OFFICE_NAME }}
                            <!-- {{ orderdata.LOCATION }}., -->
                          </b>
                          along with copy of working of admissible amount.
                        </div>
                      </div>
                      <div style="margin-top: 60px">
                        <div nz-row>
                          <div nz-col nzSpan="12"></div>

                          <div nz-col nzSpan="12" style="text-align: center">
                            <div style="font-weight: bold">({{ NAME_HN }})</div>
                            <div>
                              <b> {{ POST_HN }} </b>
                            </div>
                            <div>
                              <b> {{ OFFICE_NAME_HN }} </b>
                            </div>
                            <div>
                              <b> ({{ SIGNNAME }}) </b>
                            </div>
                            <div>
                              <b>{{ POST }} </b>
                            </div>
                            <div>
                              <b>{{ OFFICE_NAME }} </b>
                            </div>
                            <!-- <span *ngIf="SECTION_TYPE == 'welfare'">
                              <div style="font-weight: bold">
                                (रीतेश कुमार)
                              </div>
                              <div>आयकर अधिकारी (मुख्‍यालय)</div>
                              <div>कल्याण और कर्मचारी शिकायत, मुंबई</div>
                            </span>

                            <span *ngIf="SECTION_TYPE == 'gazpay'">
                              <div style="font-weight: bold">
                                (जितेंद्र रा चांदेकर)
                              </div>
                              <div>आयकर अधिकारी</div>
                              <div>राजपत्रित वेतन बिल अनुभाग II, मुंबई</div>
                            </span> -->
                          </div>
                        </div>

                        <!-- <div nz-row>
                          <div nz-col nzSpan="15"></div>

                          <div nz-col nzSpan="9" style="text-align: center">
                            <span *ngIf="SECTION_TYPE == 'welfare'">
                              <div style="font-weight: bold">
                                (Ritesh Kumar)
                              </div>
                              <div>Income Tax Officer(HQ)</div>
                              <div>Welfare & S.G, Mumbai</div>
                            </span>
                            <span *ngIf="SECTION_TYPE == 'gazpay'">
                              <div style="font-weight: bold">
                                (Jitendra R Chandekar)
                              </div>
                              <div>Income Tax Officer</div>
                              <div>
                                Gazetted Pay Bill Section II Personnel, Mumbai
                              </div>
                            </span>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style="font-family: Arial, Helvetica, sans-serif !important"
                *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
              >
                <div
                  style="
                    text-align: center;
                    margin-top: 20px;
                    margin-bottom: 10px;
                    font-size: 13px;
                  "
                >
                  <b>स्वीकृति आदेश/ SANCTION ORDER</b>
                </div>
                <div style="font-size: 13px !important">
                  <p style="margin-bottom: 2px">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {{ orderdata.EMPLOYEE_NAME_HN }}
                    {{ orderdata.DESIGNATION_NAME_HN }}, को उनके द्वारा दिनांक
                    {{
                      orderdata.TREATMENT_START_DATE | date : "dd MMM yyyy"
                    }}&nbsp; से
                    {{
                      orderdata.TREATMENT_END_DATE | date : "dd MMM yyyy"
                    }}&nbsp; को

                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                      {{ data.NAME_HN }} , {{ data.ADDRESS_HN }}
                      <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                      <span *ngIf="HospitalMapping.length - 2 == k">और</span>
                      <!-- </span> -->
                    </span>

                    में
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Father'"
                        >उनके पिता</span
                      >
                      <!-- <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Father'
                        "
                        >उनके दादाजी</span
                      > -->
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Wife'"
                        >उनकी पत्नी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Husband'"
                        >उनके पती</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Son'"
                        >उनका बेटा</span
                      >
                      <span
                        *ngIf="orderdata.RELATION_WITH_PATIENT == 'Daughter'"
                        >उनकी बेटी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Sister'"
                        >उनकी बहन</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Brother'"
                        >उनके भाई</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'"
                        >उनकी मां</span
                      >
                      <!-- <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Mother'
                        "
                        >उनकी दादी मां</span
                      > -->
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Father-In-Law'
                        "
                        >उनके ससुर</span
                      >
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Mother-In-Law'
                        "
                        >उनकी सासू मां</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} के</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} के</span
                      >
                    </span>

                    &nbsp;
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      स्वयं के</span
                    >
                    {{ orderdata.NATURE_OF_TREATMENT }}
                    के लिए खर्च की गई राशि,
                    <span *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                      <span *ngIf="isAdvanceSameAsAdmissible == true">
                        जो
                        <b
                          >{{
                            orderdata.ADVANCE_ADMISSIBLE_AMOUNT
                              | currency:'INR':'symbol'
                          }}/-&nbsp; ({{ advadmissibleinhindi }})</b
                        >
                        <b>
                          जो कि
                          {{
                            orderdata.ADMISSIBLE_AMOUNT
                              | currency:'INR':'symbol'
                          }}/-</b
                        >
                        की स्वीकार्य राशि का 90% (लगभग) से अधिक नहीं है,
                      </span>
                      <span *ngIf="isAdvanceSameAsAdmissible == false">
                        जो
                        <b>
                          {{
                            orderdata.ADVANCE_AMOUNT
                              | currency:'INR':'symbol'
                          }}/-&nbsp; ({{ advanceinhindi }})</b
                        >
                      </span>
                    </span>
                    को सीजीएचएस दर के अनुसार चिकित्सा व्यय की प्रतिपूर्ती की
                    स्वीकृति प्रदान की जाती है।
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CG'"
                      >यह स्वीकृति स्वास्थ्य एवं परिवार कल्याण मंत्रालय, भारत
                      सरकार द्वारा जारी दिनांक 18.03.1992 के कार्यालय ज्ञापन सं.
                      एस-11012/1/91/सीजीएचएस (पी) खंड 1 के माध्यम से मुझे
                      प्रदत्त शक्तियो का प्रयोग करते हुए जारी की जाती है।</span
                    >
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CS'">
                      यह स्वीकृति आदेश सेंट्रल सर्विसेस (मेडिकल अटेनडंस),
                      नियमावली, 1944 के परिशिष्ठ । के प्रावधानों के अनुसार जारी
                      किया जाता है।
                    </span>
                  </p>

                  <p style="margin-bottom: 2px">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sanction is
                    hereby accorded for the reimbursement of medical expenses as
                    per CGHS rate for an
                    <span *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                      <span *ngIf="isAdvanceSameAsAdmissible == true">
                        amount not exceeding
                        <b>
                          {{
                            orderdata.ADVANCE_ADMISSIBLE_AMOUNT
                              | currency:'INR':'symbol'
                          }}/-&nbsp; ({{ advadmissibleineng }})</b
                        >
                        being 90% (approximately) of the admissible amount of
                        <b>
                          {{
                            orderdata.ADMISSIBLE_AMOUNT
                              | currency:'INR':'symbol'
                          }}/-</b
                        >
                      </span>
                      <span *ngIf="isAdvanceSameAsAdmissible == false">
                        amount
                        <b>
                          {{
                            orderdata.ADVANCE_AMOUNT
                              | currency:'INR':'symbol'
                          }}/-&nbsp; ({{ advanceineng }})</b
                        >
                      </span>
                    </span>
                     incurred by {{ orderdata.EMPLOYEE_NAME }}&nbsp;,
                    {{ orderdata.DESIGNATION }}
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      for his/her {{ orderdata.RELATION_WITH_PATIENT }}
                      {{ orderdata.PATIENT_NAME }}</span
                    >

                    &nbsp; for&nbsp;{{ orderdata.NATURE_OF_TREATMENT }}
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      of Self</span
                    >, at
                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                      {{ data.NAME }} ,{{ data.ADDRESS }}
                      <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                      <span *ngIf="HospitalMapping.length - 2 == k">and</span>
                      <!-- </span> -->
                    </span>

                    from
                    {{ orderdata.TREATMENT_START_DATE | date : "dd MMM yyyy" }}
                    to
                    {{ orderdata.TREATMENT_END_DATE | date : "dd MMM yyyy" }}.
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CG'">
                      This sanction is issued in exercise of the powers vested
                      in me vide Office Memorandum No.S-11012/1/91/CGHS(P) Vol.
                      I dated 18.03.1992 issued by the Ministry of Health &
                      Family Welfare , Government of India.</span
                    >
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CS'">
                      This sanction is issued in accordance with the provisions
                      of Appendix VIII of the Central Services (Medical
                      Attendance) Rules, 1944.
                    </span>
                  </p>

                  <p
                    style="margin-bottom: 15px; margin-top: 10px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    यह राशि डिस्चार्ज की तारीख से एक महीने के भीतर श्री
                    {{ orderdata.EMPLOYEE_NAME_HN }} द्वारा प्रस्तुत किए जाने
                    वाले नियमित दावों के खिलाफ समायोजित की जाएगी।
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    This amount will be adjusted against regular claims ,
                    within one month from the date of discharge. to be submitted
                    by {{ orderdata.EMPLOYEE_NAME }}.
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    उक्त व्यय को वित्तीय वर्ष 2023-2024 के लिए विभाग को आबंटित
                    मुख्य शीर्ष "2020 चिकित्सा" के अंतर्गत अनुदान के नामे डाला
                    जाए।
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    The above expenditure should be debited to the grant under
                    the major head "2020 - Medical allotted to the Department
                    for Financial Year 2023-2024.
                  </p>

                  <div style="margin-top: 60px; font-size: 13px">
                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div><b> (विनय सिन्हा) </b></div>
                        <div style="font-weight: bold">
                          आयकर आयुक्त (प्रशासन एवं करदाता सेवाएँ) , मुंबई
                          <!-- आयकर आयुक्त (प्रशासन एवं टीपीएस) , मुंबई -->
                        </div>
                      </div>
                    </div>

                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div><b> (Vinay Sinha) </b></div>
                        <div style="font-weight: bold; font-size: 13px">
                          Commissioner Of Income Tax(Admin & TPS),Mumbai.
                          <!-- <span *ngIf="orderdata.SIGNATURE_ID != 2"
                            >Commissioner Of Income Tax(Admin &
                            TPS),Mumbai.</span
                          >
                          <span *ngIf="orderdata.SIGNATURE_ID == 2">
                            For Principal Chief Commissioner of Income Tax
                            Mumbai</span
                          > -->
                        </div>
                        <!-- <div
                          style="font-weight: bold; font-size: 13px"
                          *ngIf="orderdata.SIGNATURE_ID != 3"
                        >
                          for Pr. Chief Commissioner of Income-Tax, Mumbai
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      style="
                        page-break-inside: avoid !important;
                        font-size: 13px;
                        page-break-before: avoid !important;
                      "
                    >
                      <div style="margin-top: 30px">
                        <b>प्रतिलिपी/Copy to:</b>
                      </div>
                      <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                        <span>
                          1)अराजपत्रित वेतन बिल, मुंबई (2 प्रतियां) फॉर्म एमआरसी
                          (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ
                        </span>
                        <br />
                        <span style="padding-left: 15px">
                          The Non Gazetted Pay Bill Section (2 copies) alongwith
                          Form MRC(S) , hospital receipts , discharge summary
                          etc.
                        </span>
                        <br />

                        <!-- <span *ngIf="SECTION_TYPE == 'welfare'">
                          1) डीडीओ, कल्याण और एसजी,मुंबई (2 प्रतियां) फॉर्म
                          एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के
                          साथ|</span
                        > <br>
                        <span *ngIf="SECTION_TYPE == 'gazpay'">
                          1) डीडीओ, राजपत्रित वेतन बिल अनुभाग II (कार्मिक),मुंबई
                          (2 प्रतियां) फॉर्म एमआरसी (एस), अस्पताल रसीद,
                          डिस्चार्ज सारांश आदि के साथ |</span
                        >
                        <br>
                        <span
                        *ngIf="SECTION_TYPE == 'welfare'"
                        style="padding-left: 15px"
                      >
                        DDO , Welfare & SG , Mumbai (2 copies) alongwith Form
                        MRC(S) , hospital receipts , discharge summary etc.
                      </span>
                      <br>
                      <span
                        *ngIf="SECTION_TYPE == 'gazpay'"
                        style="padding-left: 15px"
                      >
                        DDO , Gazetted Pay bill Section II, Personnel Mumbai
                        (2 copies) alongwith Form MRC(S) , hospital receipts ,
                        discharge summary etc.
                      </span> -->
<!-- 
                        <div>
                          2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                          <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                          तैयार किया जा सकता है।
                        </div>
                        <div style="padding-left: 15px">
                            ZAO , Pre-check unit , Mumbai - Cheque may be drawn in
                          favour of {{ orderdata.EMPLOYEE_NAME }}.
                        </div> -->
                        <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 0">
                          <div>
                            2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                            <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                            तैयार किया जा सकता है। |
                          </div>
                          <div style="padding-left: 15px">
                             ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                            in favour of {{ orderdata.EMPLOYEE_NAME }}.
                          </div>
                        </div>
                        <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                          <div  *ngIf="orderdata.CHEQUE_DRAWN==0">
                            2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                            <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                            तैयार किया जा सकता है। |
                          </div>
                          <div style="padding-left: 15px"  *ngIf="orderdata.CHEQUE_DRAWN==0">
                             ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                            in favour of {{ orderdata.EMPLOYEE_NAME }}.
                          </div>
                          <div  *ngIf="orderdata.CHEQUE_DRAWN==1">
                            2) {{orderdata.ZAO_HN}}
                          </div>
                          <div style="padding-left: 15px"  *ngIf="orderdata.CHEQUE_DRAWN==1">
                              {{orderdata.ZAO}}
                          </div>
                        </div>

                        <div>
                          3)<b>
                            {{ orderdata.EMPLOYEE_NAME_HN }},
                            {{ orderdata.DESIGNATION_NAME_HN }} कार्यालय
                            {{ orderdata.OFFICE_NAME_HN }}
                            <!-- {{ orderdata.LOCATION_HN }}., -->
                          </b>
                          स्वीकार्य राशि की गणना की प्रति के साथ |
                        </div>

                        <div style="padding-left: 15px">
                          <b
                            >{{ orderdata.EMPLOYEE_NAME }},
                            {{ orderdata.DESIGNATION }} O/o
                            {{ orderdata.EMPLOYEE_OFFICE_NAME }}
                            <!-- {{ orderdata.LOCATION }}., -->
                          </b>
                          along with copy of working of admissible amount.
                        </div>
                      </div>

                      <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 0">
                        <div>
                          <span *ngIf="SECTION_TYPE == 'welfare'">
                            1) डीडीओ, कल्याण और एसजी,मुंबई (2 प्रतियां) फॉर्म
                            एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के
                            साथ|</span
                          >
                          <span *ngIf="SECTION_TYPE == 'gazpay'">
                            1) डीडीओ, राजपत्रित वेतन बिल अनुभाग II
                            (कार्मिक),मुंबई (2 प्रतियां) फॉर्म एमआरसी (एस),
                            अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ |</span
                          >
                          <!-- 1) डीडीओ,कल्याण और एसजी,मुंबई (2 प्रतियां)
                      फॉर्म एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ| -->
                        </div>
                        <div>
                          <!-- 1) DDO , Welfare & SG , Mumbai (2 copies) alongwith Form
                      MRC(S) , hospital receipts , discharge summary etc. -->

                          <span
                            *ngIf="SECTION_TYPE == 'welfare'"
                            style="padding-left: 15px"
                          >
                            DDO , Welfare & SG , Mumbai (2 copies) alongwith
                            Form MRC(S) , hospital receipts , discharge summary
                            etc.
                          </span>
                          <span
                            *ngIf="SECTION_TYPE == 'gazpay'"
                            style="padding-left: 15px"
                          >
                            DDO , Gazetted Pay bill Section II, Personnel Mumbai
                            (2 copies) alongwith Form MRC(S) , hospital receipts
                            , discharge summary etc.
                          </span>
                        </div>
                        <div>
                          2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                          <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                          तैयार किया जा सकता है।
                        </div>
                        <div style="padding-left: 15px">
                            ZAO , Pre-check unit , Mumbai - Cheque may be drawn in
                          favour of {{ orderdata.EMPLOYEE_NAME }}.
                        </div>

                        <div>
                          3)<b>
                            {{ orderdata.EMPLOYEE_NAME_HN }},
                            {{ orderdata.DESIGNATION_NAME_HN }} कार्यालय
                            {{ orderdata.OFFICE_NAME_HN }}
                            <!-- {{ orderdata.LOCATION_HN }}., -->
                          </b>
                          स्वीकार्य राशि की गणना की प्रति के साथ |
                        </div>

                        <div style="padding-left: 15px">
                          <b
                            >{{ orderdata.EMPLOYEE_NAME }},
                            {{ orderdata.DESIGNATION }} O/o
                            {{ orderdata.EMPLOYEE_OFFICE_NAME }}
                            <!-- {{ orderdata.LOCATION }}., -->
                          </b>
                          along with copy of working of admissible amount.
                        </div>
                      </div>
                      <div style="margin-top: 60px">
                        <div nz-row>
                          <div nz-col nzSpan="12"></div>

                          <div nz-col nzSpan="12" style="text-align: center">
                            <div style="font-weight: bold">({{ NAME_HN }})</div>
                            <div>
                              <b> {{ POST_HN }} </b>
                            </div>
                            <div>
                              <b> {{ OFFICE_NAME_HN }} </b>
                            </div>
                            <div>
                              <b>({{ SIGNNAME }})</b>
                            </div>
                            <div>
                              <b>{{ POST }} </b>
                            </div>
                            <div>
                              <b>{{ OFFICE_NAME }} </b>
                            </div>
                            <!-- <span *ngIf="SECTION_TYPE == 'welfare'">
                              <div style="font-weight: bold">
                                (रीतेश कुमार)
                              </div>
                              <div>आयकर अधिकारी (मुख्‍यालय)</div>
                              <div>कल्याण और कर्मचारी शिकायत, मुंबई</div>
                            </span>

                            <span *ngIf="SECTION_TYPE == 'gazpay'">
                              <div style="font-weight: bold">
                                (जितेंद्र रा चांदेकर)
                              </div>
                              <div>आयकर अधिकारी</div>
                              <div>राजपत्रित वेतन बिल अनुभाग II, मुंबई</div>
                            </span> -->
                          </div>
                        </div>

                        <!-- <div nz-row>
                          <div nz-col nzSpan="15"></div>

                          <div nz-col nzSpan="9" style="text-align: center">
                            <span *ngIf="SECTION_TYPE == 'welfare'">
                              <div style="font-weight: bold">
                                (Ritesh Kumar)
                              </div>
                              <div>Income Tax Officer(HQ)</div>
                              <div>Welfare & S.G, Mumbai</div>
                            </span>
                            <span *ngIf="SECTION_TYPE == 'gazpay'">
                              <div style="font-weight: bold">
                                (Jitendra R Chandekar)
                              </div>
                              <div>Income Tax Officer</div>
                              <div>
                                Gazetted Pay Bill Section II Personnel, Mumbai
                              </div>
                            </span>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>
  </ng-template>

  <ng-template #printLatestOrderModalFooter>
    <button nz-button nzType="default" (click)="printLatestOrderModalCancel()">
      Close
    </button>

    <button
      nz-button
      nzType="default"
      [useExistingCss]="true"
      printSectionId="printOrderModalLatest"
      ngxPrint
      [nzLoading]="pdfDownload"
    >
      Print
    </button>

    <button
      nz-button
      nzType="primary"
      [nzLoading]="pdfDownload"
      (click)="generateLatestOrderPDF()"
    >
      Download
    </button>
  </ng-template>
</nz-modal>

<nz-modal
  [nzWidth]="getwidth()"
  [(nzVisible)]="printLatestAdvanceOrderModalVisible"
  nzMaskClosable="false"
  nzClosable="false"
  [nzContent]="printOrderModalContentAdvance"
  [nzTitle]="'Sanction Order'"
  (nzOnCancel)="printAdvanceOrderModalCancel()"
  [nzFooter]="printAdvanceOrderModalFooter"
>
  <ng-template #printOrderModalContentAdvance>
    <nz-spin [nzSpinning]="pdfDownload">
      <div style="height: 450px; overflow: auto">
        <div id="printOrderModalAdvance">
          <div style="width: 750px; padding: 3px; margin-left: 30px">
            <div>
              <div nz-row>
                <div
                  style="border: 1px solid"
                  nz-col
                  nzXs="4"
                  nzSm="4"
                  nzMd="4"
                  nzLg="4"
                  nzXl="4"
                >
                  <div style="text-align: center; padding: 36px 9px 9px 9px">
                    <img
                      src="assets/govtOfIndia.png"
                      alt=""
                      height="140px"
                      width="100%"
                    />
                  </div>
                </div>
                <!-- <div
                  style="
                    border-bottom: 1px solid;
                    border-top: 1px solid;
                    border-right: 1px solid;
                  "
                  nz-col
                  nzXs="1"
                  nzSm="1"
                  nzMd="1"
                  nzLg="1"
                  nzXl="1"
                ></div> -->
                <div
                  style="
                    border-bottom: 1px solid;
                    border-top: 1px solid;
                    border-right: 1px solid;
                  "
                  nz-col
                  nzXs="20"
                  nzSm="20"
                  nzMd="20"
                  nzLg="20"
                  nzXl="20"
                >
                  <div
                    style="width: 100%; text-align: center; font-weight: bold"
                  >
                    <div>भारत सरकार</div>
                    <div style="text-transform: uppercase" class="family">
                      Government Of India
                    </div>
                    <div>कार्यालय, प्रधान मुख्य आयकर आयुक्त, मुंबई</div>
                    <div style="text-transform: uppercase" class="family">
                      OFFICE OF THE
                    </div>
                    <div style="text-transform: uppercase" class="family">
                      PRINCIPAL CHIEF COMMISSIONER OF INCOME TAX,
                    </div>
                    <div class="family" style="text-transform: uppercase">
                      MUMBAI
                    </div>
                    <!-- <div>
                      तीसरी मंजिल, आयकर भवन, महर्षी कर्वे रोड, मुंबई -20
                    </div> -->
                    <div style="text-transform: uppercase" class="family">
                      3<sup style="text-transform: lowercase">rd</sup> Floor,
                      aayakar bhavan, Maharshi Karve Road, Mumbai - 400020
                    </div>
                    <div>
                      (022) - 22016691/22077187 / Fax: 022- 22079273/22077187
                    </div>
                    <div class="family">
                      Email:
                      <span *ngIf="orderdata.SIGNATURE_ID == 2">
                        mumbai.ito.gpbs2@incometax.gov.in
                      </span>
                      <span *ngIf="orderdata.SIGNATURE_ID == 3">
                        mumbai.ito.hq.pers@incometax.gov.in
                      </span>
                      <!-- <span *ngIf="userId != 18 && userId != 48">
                        mumbai.ito.hq.pers@incometax.gov.in</span
                      > -->
                    </div>
                  </div>
                </div>
              </div>

              <div
                style="
                  width: 100%;
                  padding: 5px;
                  font-size: 13px;
                  border-bottom: 1px solid;
                  border-left: 1px solid;
                  border-right: 1px solid;
                "
              >
                <div nz-row>
                  <div nz-col nzSpan="17">{{ orderdata.ORDER_NO }}</div>
                  <div nz-col nzSpan="1"></div>
                  <div nz-col nzSpan="6">
                    दिनांक
                    :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                  <!-- {{ orderdata.ORDER_DATE | date : "dd MMM yyyy" }} -->
                </div>
              </div>
              <div
                style="font-family: Arial, Helvetica, sans-serif !important"
                *ngIf="orderdata.IS_ADVANCE_TAKEN == 0"
              >
                <div
                  style="
                    text-align: center;
                    margin-top: 10px;
                    margin-bottom: 5px;
                    font-size: 13px;
                  "
                >
                  <b>स्वीकृति आदेश/ SANCTION ORDER</b>
                </div>
                <div style="font-size: 13px !important">
                  <p style="margin-bottom: 2px">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {{ orderdata.EMPLOYEE_NAME_HN }},
                    {{ orderdata.DESIGNATION_NAME_HN }}, को उनके द्वारा दिनांक
                    {{
                      orderdata.TREATMENT_START_DATE | date : "dd MMM yyyy"
                    }}&nbsp; से
                    {{
                      orderdata.TREATMENT_END_DATE | date : "dd MMM yyyy"
                    }}&nbsp; तक

                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                      {{ data.NAME_HN }} , {{ data.ADDRESS_HN }}
                      <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                      <span *ngIf="HospitalMapping.length - 2 == k">और</span>
                      <!-- </span> -->
                    </span>

                    में
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Father'"
                        >उनके पिता</span
                      >
                      <!-- <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Father'
                        "
                        >उनके दादाजी</span
                      > -->
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Wife'"
                        >उनकी पत्नी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Husband'"
                        >उनके पती</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Son'"
                        >उनका बेटा</span
                      >
                      <span
                        *ngIf="orderdata.RELATION_WITH_PATIENT == 'Daughter'"
                        >उनकी बेटी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Sister'"
                        >उनकी बहन</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Brother'"
                        >उनके भाई</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'"
                        >उनकी मां</span
                      >
                      <!-- <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Mother'
                        "
                        >उनकी दादी मां</span
                      > -->
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Father-In-Law'
                        "
                        >उनके ससुर</span
                      >
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Mother-In-Law'
                        "
                        >उनकी सासू मां</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} के</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} के</span
                      >
                    </span>

                    &nbsp;
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      स्वयं के</span
                    >
                    {{ orderdata.NATURE_OF_TREATMENT }}
                    के लिए खर्च की गई राशि,
                    <!-- के चिकित्सा हेतु परमार्श और दवा के लिए खर्च की गई राशि, -->
                    <span
                      >जो
                      <b>
                        {{
                          orderdata.FINAL_REMAINING_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-
                        <!-- &nbsp; ({{ amountinwordsh }}) -->
                      </b>
                      जो कि स्वीकार्य राशि
                      <b>
                        {{
                          orderdata.FINAL_ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-</b
                      >की शेष राशि से अधिक नहीं है,</span
                    >
                    को सीजीएचएस दर के अनुसार चिकित्सा व्यय की प्रतिपूर्ती की
                    स्वीकृति प्रदान की जाती है। दिनांक
                    {{ orderdata.ADVANCE_TAKEN_DATE | date : "dd/MM/yyyy" }} को
                    <b>
                      {{
                        orderdata.ADMISSIBLE_AMOUNT
                          | currency:'INR':'symbol'
                      }}/-
                      <!-- &nbsp; ({{ amountinwordsh }}) -->
                    </b>
                    रुपये की
                    <span *ngIf="isAdvanceSameAsAdmissible == true">
                      स्वीकार्य राशि का 90% होने के कारण
                    </span>
                    <b>
                      {{
                        orderdata.ADVANCE_AMOUNT
                          | currency:'INR':'symbol'
                      }}/-</b
                    >
                    रुपये की अग्रिम राशी मंजुरी की गई थी।
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CG'"
                      >यह स्वीकृति स्वास्थ्य एवं परिवार कल्याण मंत्रालय, भारत
                      सरकार द्वारा जारी दिनांक 18.03.1992 के कार्यालय ज्ञापन सं.
                      एस-11012/1/91/सीजीएचएस (पी) खंड 1 के माध्यम से मुझे
                      प्रदत्त शक्तियो का प्रयोग करते हुए जारी की जाती है।</span
                    >
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CS'">
                      यह स्वीकृति आदेश सेंट्रल सर्विसेस (मेडिकल अटेनडंस),
                      नियमावली, 1944 के परिशिष्ठ । के प्रावधानों के अनुसार जारी
                      किया जाता है।
                    </span>
                  </p>

                  <p style="margin-bottom: 2px">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sanction is
                    hereby accorded for the reimbursement of medical expenses as
                    per CGHS rate for an
                    <span>
                      amount not exceeding
                      <b>
                        {{
                          orderdata.FINAL_REMAINING_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-
                        <!-- &nbsp; ({{ amountinwords }}) -->
                      </b>
                    </span>
                    being balance amount of the admissible amount of
                    <b>
                      {{
                        orderdata.FINAL_ADMISSIBLE_AMOUNT
                          | currency:'INR':'symbol'
                      }}/-</b
                    >
                    incurred by {{ orderdata.EMPLOYEE_NAME }}&nbsp;,
                    {{ orderdata.DESIGNATION }}
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      for his/her {{ orderdata.RELATION_WITH_PATIENT }}
                      {{ orderdata.PATIENT_NAME }}</span
                    >

                    &nbsp; for&nbsp;{{ orderdata.NATURE_OF_TREATMENT }}
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      of Self</span
                    >, at
                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                      {{ data.NAME }} ,{{ data.ADDRESS }}
                      <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                      <span *ngIf="HospitalMapping.length - 2 == k">and</span>
                      <!-- </span> -->
                    </span>

                    from
                    {{ orderdata.TREATMENT_START_DATE | date : "dd MMM yyyy" }}
                    to
                    {{ orderdata.TREATMENT_END_DATE | date : "dd MMM yyyy" }}.
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CG'">
                      An advance amount of
                      <b>
                        {{
                          orderdata.ADVANCE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-</b
                      >
                      <span *ngIf="isAdvanceSameAsAdmissible == true">
                        being if 90%
                      </span>
                      of admissible amount of
                      {{
                        orderdata.ADMISSIBLE_AMOUNT
                          | currency:'INR':'symbol'
                      }}/- was sanctioned on date
                      {{ orderdata.ADVANCE_TAKEN_DATE | date : "dd/MM/yyyy" }}.
                      This sanction is issued in exercise of the powers vested
                      in me vide Office Memorandum No.S-11012/1/91/CGHS(P) Vol.
                      I dated 18.03.1992 issued by the Ministry of Health &
                      Family Welfare , Government of India.</span
                    >
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CS'">
                      An advance amount of
                      <b>
                        {{
                          orderdata.ADVANCE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-</b
                      >
                      <span *ngIf="isAdvanceSameAsAdmissible == true">
                        being if 90%
                      </span>
                      of admissible amount of
                      {{
                        orderdata.ADMISSIBLE_AMOUNT
                          | currency:'INR':'symbol'
                      }}/- was sanctioned on date
                      {{ orderdata.ADVANCE_TAKEN_DATE | date : "dd/MM/yyyy" }}.
                      This sanction is issued in accordance with the provisions
                      of Appendix VIII of the Central Services (Medical
                      Attendance) Rules, 1944.
                    </span>
                  </p>

                  <!-- <p
                    style="margin-bottom: 15px; margin-top: 10px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    यह राशि डिस्चार्ज की तारीख से एक महीने के भीतर श्री
                    {{ orderdata.EMPLOYEE_NAME_HN }} द्वारा प्रस्तुत किए जाने
                    वाले नियमित दावों के खिलाफ समायोजित की जाएगी।
                  </p> -->

                  <!-- <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    This amount will be adjusted against regular claims ,
                    within one month from the date of discharge. to be submitted
                    by {{ orderdata.EMPLOYEE_NAME }}.
                  </p> -->

                  <p
                    style="margin-bottom: 15px; margin-top: 10px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    उक्त व्यय को वित्तीय वर्ष 2023-2024 के लिए विभाग को आबंटित
                    मुख्य शीर्ष "2020 चिकित्सा" के अंतर्गत अनुदान के नामे डाला
                    जाए।
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    The above expenditure should be debited to the grant under
                    the major head "2020 - Medical allotted to the Department
                    for Financial Year 2023-2024.
                  </p>

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 0
                    "
                  >
                    यह आपात स्थिति का मामला था, जिसके लिए तत्काल उपचार की
                    आवश्यकता थी अतः गैर-पैनलबद्ध अस्पताल में भर्ती कराया गया था|
                  </p>

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 0
                    "
                  >
                    It was a case of emergency , necessitating immediate
                    treatment and was admitted in a non-empanelled hospital.
                  </p>

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 1 &&
                        orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 0 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 1 &&
                        orderdata.IS_HOSPITAL_EMPLANELLED == 0 &&
                        orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 0 &&
                        orderdata.EMERGENCY_TREATEMENT == 0) ||
                      orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1
                    "
                  >
                    दिनांक
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0">
                      {{ orderdata.EXPO_FACTO_DATE }}
                    </span>
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1">
                      {{ orderdata.HOD_PERMISSION_DATE }}
                    </span>
                    <!-- के पत्र द्वारा उपरोक्त उपचार के लिये कार्योत्तर अनुमति -->
                    के पत्र द्वारा उपरोक्त उपचार के लिये अनुमति प्रदान की गई है।
                  </p>

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 1 &&
                        orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 0 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 1 &&
                        orderdata.IS_HOSPITAL_EMPLANELLED == 0 &&
                        orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 0 &&
                        orderdata.EMERGENCY_TREATEMENT == 0) ||
                      orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1
                    "
                  >
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0">
                      Expost Facto Permission for the above treatment has been
                      granted vide letter dated {{ orderdata.EXPO_FACTO_DATE }}
                    </span>
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1">
                      Permission for the above treatment has been granted vide
                      letter dated {{ orderdata.HOD_PERMISSION_DATE }}
                    </span>
                  </p>
                  <!-- <p
                *ngIf="
                  orderdata.IS_BILL_FILLED_IN_TIME == 1 &&
                  orderdata.IS_HOSPITAL_EMPLANELLED == 1 &&
                  orderdata.EXPO_FACTO_PERMISSION == 1
                "
              >
                दिनांक 10/01/2023 के पत्र द्वारा उपरोक्त उपचार के लिए अनुमति
                प्रदान की गई है।
              </p>
              <p
                *ngIf="
                  orderdata.IS_BILL_FILLED_IN_TIME == 1 &&
                  orderdata.IS_HOSPITAL_EMPLANELLED == 1 &&
                  orderdata.EXPO_FACTO_PERMISSION == 1
                "
              >
                Permission for the above treatment has been granted vide letter
                dated 10/01/2023
              </p> -->

                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 1
                    "
                  >
                    यह आपात स्थिति का मामला था जिसमें तत्काल प्रवेश की आवश्यकता
                    थी और उसे पैनलबद्ध अस्पताल में भर्ती कराया गया था.
                  </p>
                  <p
                    style="margin-bottom: 2px"
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 1
                    "
                  >
                    It was a case of Emergency necessitating immediate admission
                    and was admitted in empanelled hospital.
                  </p>
                  <p style="margin-bottom: 2px">
                    उक्त व्यय को वित्तीय वर्ष 2023-2024 के लिए विभाग को आबंटित
                    अनुदान के अंतर्गत मुख्य शीर्ष "2020-चिकित्सा उपचार" से किया
                    जाए।
                  </p>

                  <p style="margin-bottom: 2px">
                    The above expenditure should be debited to the grant under
                    major head 2020 - Medical Treatment Alloted to the
                    Department for the
                    <b>F.Y 2023-2024.</b>
                  </p>

                  <p style="margin-bottom: 2px">
                    हिंदी और अंग्रेजी संस्करण में किसी भी भिन्नता के मामले में
                    आदेश का अंग्रेजी संस्करण मान्य होगा।
                  </p>

                  <p style="margin-bottom: 2px">
                    The English version of the order will prevail in case of any
                    variation in Hindi & English version.
                  </p>

                  <div style="margin-top: 60px; font-size: 13px">
                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div><b> (विनय सिन्हा) </b></div>
                        <div style="font-weight: bold">
                          आयकर आयुक्त (प्रशासन एवं करदाता सेवाएँ) , मुंबई
                        </div>
                      </div>
                    </div>

                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div><b> (Vinay Sinha) </b></div>
                        <div style="font-weight: bold; font-size: 13px">
                          Commissioner Of Income Tax(Admin & TPS),Mumbai.
                          <!-- <span *ngIf="orderdata.SIGNATURE_ID != 2"
                            >Commissioner Of Income Tax(Admin &
                            TPS),Mumbai.</span
                          >
                          <span *ngIf="orderdata.SIGNATURE_ID == 2">
                            For Principal Chief Commissioner of Income Tax
                            Mumbai</span
                          > -->
                        </div>
                        <!-- <div
                          style="font-weight: bold; font-size: 13px"
                          *ngIf="orderdata.SIGNATURE_ID != 3"
                        >
                          for Pr. Chief Commissioner of Income-Tax, Mumbai
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      style="
                        page-break-inside: avoid !important;
                        font-size: 13px;
                        page-break-before: avoid !important;
                      "
                    >
                      <div style="margin-top: 30px">
                        <b>प्रतिलिपी/Copy to:</b>
                      </div>
                      <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                        <span>
                          1)अराजपत्रित वेतन बिल, मुंबई (2 प्रतियां) फॉर्म एमआरसी
                          (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ
                        </span>
                        <br />
                        <span style="padding-left: 15px">
                          The Non Gazetted Pay Bill Section (2 copies) alongwith
                          Form MRC(S) , hospital receipts , discharge summary
                          etc.
                        </span>
                        <br />

                        <!-- <span *ngIf="SECTION_TYPE == 'welfare'">
                          1) डीडीओ, कल्याण और एसजी,मुंबई (2 प्रतियां) फॉर्म
                          एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के
                          साथ|</span
                        > <br>
                        <span *ngIf="SECTION_TYPE == 'gazpay'">
                          1) डीडीओ, राजपत्रित वेतन बिल अनुभाग II (कार्मिक),मुंबई
                          (2 प्रतियां) फॉर्म एमआरसी (एस), अस्पताल रसीद,
                          डिस्चार्ज सारांश आदि के साथ |</span
                        >
                        <br>
                        <span
                        *ngIf="SECTION_TYPE == 'welfare'"
                        style="padding-left: 15px"
                      >
                        DDO , Welfare & SG , Mumbai (2 copies) alongwith Form
                        MRC(S) , hospital receipts , discharge summary etc.
                      </span>
                      <br>
                      <span
                        *ngIf="SECTION_TYPE == 'gazpay'"
                        style="padding-left: 15px"
                      >
                        DDO , Gazetted Pay bill Section II, Personnel Mumbai
                        (2 copies) alongwith Form MRC(S) , hospital receipts ,
                        discharge summary etc.
                      </span> -->

                        <!-- <div>
                          2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                          <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                          तैयार किया जा सकता है।
                        </div>
                        <div style="padding-left: 15px">
                            ZAO , Pre-check unit , Mumbai - Cheque may be drawn in
                          favour of {{ orderdata.EMPLOYEE_NAME }}.
                        </div> -->
                        <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 0">
                          <div>
                            2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                            <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                            तैयार किया जा सकता है। |
                          </div>
                          <div style="padding-left: 15px">
                             ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                            in favour of {{ orderdata.EMPLOYEE_NAME }}.
                          </div>
                        </div>
                        <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                          <div  *ngIf="orderdata.CHEQUE_DRAWN==0">
                            2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                            <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                            तैयार किया जा सकता है। |
                          </div>
                          <div style="padding-left: 15px"  *ngIf="orderdata.CHEQUE_DRAWN==0">
                             ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                            in favour of {{ orderdata.EMPLOYEE_NAME }}.
                          </div>
                          <div  *ngIf="orderdata.CHEQUE_DRAWN==1">
                            2) {{orderdata.ZAO_HN}}
                          </div>
                          <div style="padding-left: 15px"  *ngIf="orderdata.CHEQUE_DRAWN==1">
                              {{orderdata.ZAO}}
                          </div>
                        </div>

                        <div>
                          3)<b>
                            {{ orderdata.EMPLOYEE_NAME_HN }},
                            {{ orderdata.DESIGNATION_NAME_HN }} कार्यालय
                            {{ orderdata.OFFICE_NAME_HN }}
                            <!-- {{ orderdata.LOCATION_HN }}., -->
                          </b>
                          स्वीकार्य राशि की गणना की प्रति के साथ |
                        </div>

                        <div style="padding-left: 15px">
                          <b
                            >{{ orderdata.EMPLOYEE_NAME }},
                            {{ orderdata.DESIGNATION }} O/o
                            {{ orderdata.EMPLOYEE_OFFICE_NAME }}
                            <!-- {{ orderdata.LOCATION }}., -->
                          </b>
                          along with copy of working of admissible amount.
                        </div>
                      </div>

                      <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 0">
                        <div>
                          <span *ngIf="SECTION_TYPE == 'welfare'">
                            1) डीडीओ, कल्याण और एसजी,मुंबई (2 प्रतियां) फॉर्म
                            एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के
                            साथ|</span
                          >
                          <span *ngIf="SECTION_TYPE == 'gazpay'">
                            1) डीडीओ, राजपत्रित वेतन बिल अनुभाग II
                            (कार्मिक),मुंबई (2 प्रतियां) फॉर्म एमआरसी (एस),
                            अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ |</span
                          >
                          <!-- 1) डीडीओ,कल्याण और एसजी,मुंबई (2 प्रतियां)
                      फॉर्म एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ| -->
                        </div>
                        <div>
                          <!-- 1) DDO , Welfare & SG , Mumbai (2 copies) alongwith Form
                      MRC(S) , hospital receipts , discharge summary etc. -->

                          <span
                            *ngIf="SECTION_TYPE == 'welfare'"
                            style="padding-left: 15px"
                          >
                            DDO , Welfare & SG , Mumbai (2 copies) alongwith
                            Form MRC(S) , hospital receipts , discharge summary
                            etc.
                          </span>
                          <span
                            *ngIf="SECTION_TYPE == 'gazpay'"
                            style="padding-left: 15px"
                          >
                            DDO , Gazetted Pay bill Section II, Personnel Mumbai
                            (2 copies) alongwith Form MRC(S) , hospital receipts
                            , discharge summary etc.
                          </span>
                        </div>
                        <div>
                          2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                          <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                          तैयार किया जा सकता है।
                        </div>
                        <div style="padding-left: 15px">
                            ZAO , Pre-check unit , Mumbai - Cheque may be drawn in
                          favour of {{ orderdata.EMPLOYEE_NAME }}.
                        </div>

                        <div>
                          3)<b>
                            {{ orderdata.EMPLOYEE_NAME_HN }},
                            {{ orderdata.DESIGNATION_NAME_HN }} कार्यालय
                            {{ orderdata.OFFICE_NAME_HN }}
                            <!-- {{ orderdata.LOCATION_HN }}., -->
                          </b>
                          स्वीकार्य राशि की गणना की प्रति के साथ |
                        </div>

                        <div style="padding-left: 15px">
                          <b
                            >{{ orderdata.EMPLOYEE_NAME }},
                            {{ orderdata.DESIGNATION }} O/o
                            {{ orderdata.EMPLOYEE_OFFICE_NAME }}
                            <!-- {{ orderdata.LOCATION }}., -->
                          </b>
                          along with copy of working of admissible amount.
                        </div>
                      </div>
                      <div style="margin-top: 60px">
                        <div nz-row>
                          <div nz-col nzSpan="12"></div>

                          <div nz-col nzSpan="12" style="text-align: center">
                            <div style="font-weight: bold">({{ NAME_HN }})</div>
                            <div>
                              <b> {{ POST_HN }} </b>
                            </div>
                            <div>
                              <b> {{ OFFICE_NAME_HN }} </b>
                            </div>
                            <div>
                              <b> ({{ SIGNNAME }}) </b>
                            </div>
                            <div>
                              <b>{{ POST }} </b>
                            </div>
                            <div>
                              <b>{{ OFFICE_NAME }} </b>
                            </div>
                            <!-- <span *ngIf="SECTION_TYPE == 'welfare'">
                              <div style="font-weight: bold">
                                (रीतेश कुमार)
                              </div>
                              <div>आयकर अधिकारी (मुख्‍यालय)</div>
                              <div>कल्याण और कर्मचारी शिकायत, मुंबई</div>
                            </span>

                            <span *ngIf="SECTION_TYPE == 'gazpay'">
                              <div style="font-weight: bold">
                                (जितेंद्र रा चांदेकर)
                              </div>
                              <div>आयकर अधिकारी</div>
                              <div>राजपत्रित वेतन बिल अनुभाग II, मुंबई</div>
                            </span> -->
                          </div>
                        </div>

                        <!-- <div nz-row>
                          <div nz-col nzSpan="15"></div>

                          <div nz-col nzSpan="9" style="text-align: center">
                            <span *ngIf="SECTION_TYPE == 'welfare'">
                              <div style="font-weight: bold">
                                (Ritesh Kumar)
                              </div>
                              <div>Income Tax Officer(HQ)</div>
                              <div>Welfare & S.G, Mumbai</div>
                            </span>
                            <span *ngIf="SECTION_TYPE == 'gazpay'">
                              <div style="font-weight: bold">
                                (Jitendra R Chandekar)
                              </div>
                              <div>Income Tax Officer</div>
                              <div>
                                Gazetted Pay Bill Section II Personnel, Mumbai
                              </div>
                            </span>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style="font-family: Arial, Helvetica, sans-serif !important"
                *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
              >
                <div
                  style="
                    text-align: center;
                    margin-top: 20px;
                    margin-bottom: 10px;
                    font-size: 13px;
                  "
                >
                  <b>स्वीकृति आदेश/ SANCTION ORDER</b>
                </div>
                <div style="font-size: 13px !important">
                  <p style="margin-bottom: 2px">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {{ orderdata.EMPLOYEE_NAME_HN }}
                    {{ orderdata.DESIGNATION_NAME_HN }}, को उनके द्वारा दिनांक
                    {{
                      orderdata.TREATMENT_START_DATE | date : "dd MMM yyyy"
                    }}&nbsp; से
                    {{
                      orderdata.TREATMENT_END_DATE | date : "dd MMM yyyy"
                    }}&nbsp; को

                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                      {{ data.NAME_HN }} , {{ data.ADDRESS_HN }}
                      <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                      <span *ngIf="HospitalMapping.length - 2 == k">और</span>
                      <!-- </span> -->
                    </span>

                    में
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Father'"
                        >उनके पिता</span
                      >
                      <!-- <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Father'
                        "
                        >उनके दादाजी</span
                      > -->
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Wife'"
                        >उनकी पत्नी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Husband'"
                        >उनके पती</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Son'"
                        >उनका बेटा</span
                      >
                      <span
                        *ngIf="orderdata.RELATION_WITH_PATIENT == 'Daughter'"
                        >उनकी बेटी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Sister'"
                        >उनकी बहन</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Brother'"
                        >उनके भाई</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'"
                        >उनकी मां</span
                      >
                      <!-- <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Mother'
                        "
                        >उनकी दादी मां</span
                      > -->
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Father-In-Law'
                        "
                        >उनके ससुर</span
                      >
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Mother-In-Law'
                        "
                        >उनकी सासू मां</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} के</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} के</span
                      >
                    </span>

                    &nbsp;
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      स्वयं के</span
                    >
                    {{ orderdata.NATURE_OF_TREATMENT }}
                    के लिए खर्च की गई राशि,
                    <span
                      >जो
                      <b>
                        {{
                          orderdata.FINAL_REMAINING_AMOUNT
                            | currency:'INR':'symbol'
                        }}/- &nbsp; ({{ finalremaininginhindi }})
                      </b>
                      जो कि स्वीकार्य राशि
                      <b>
                        {{
                          orderdata.FINAL_ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-
                        <!-- &nbsp; ({{ finaladmissibleinhindi }}) --> </b
                      >की शेष राशि से अधिक नहीं है,</span
                    >
                    को सीजीएचएस दर के अनुसार चिकित्सा व्यय की प्रतिपूर्ती की
                    स्वीकृति प्रदान की जाती है। दिनांक
                    {{ orderdata.ADVANCE_TAKEN_DATE | date : "dd/MM/yyyy" }} को
                    <b>
                      {{
                        orderdata.ADMISSIBLE_AMOUNT
                          | currency:'INR':'symbol'
                      }}/-
                      <!-- &nbsp; ({{ admissibleinhindi }}) -->
                    </b>
                    रुपये की
                    <span *ngIf="isAdvanceSameAsAdmissible == true">
                      स्वीकार्य राशि का 90% होने के कारण
                    </span>
                    <b>
                      {{
                        orderdata.ADVANCE_AMOUNT
                          | currency:'INR':'symbol'
                      }}/-
                      <!-- &nbsp; ({{ advanceinhindi }}) -->
                    </b>
                    रुपये की अग्रिम राशी मंजुरी की गई थी।
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CG'"
                      >यह स्वीकृति स्वास्थ्य एवं परिवार कल्याण मंत्रालय, भारत
                      सरकार द्वारा जारी दिनांक 18.03.1992 के कार्यालय ज्ञापन सं.
                      एस-11012/1/91/सीजीएचएस (पी) खंड 1 के माध्यम से मुझे
                      प्रदत्त शक्तियो का प्रयोग करते हुए जारी की जाती है।</span
                    >
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CS'">
                      यह स्वीकृति आदेश सेंट्रल सर्विसेस (मेडिकल अटेनडंस),
                      नियमावली, 1944 के परिशिष्ठ । के प्रावधानों के अनुसार जारी
                      किया जाता है।
                    </span>
                  </p>

                  <p style="margin-bottom: 2px">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sanction is
                    hereby accorded for the reimbursement of medical expenses as
                    per CGHS rate for an
                    <span *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                      amount not exceeding
                      <b>
                        {{
                          orderdata.FINAL_REMAINING_AMOUNT
                            | currency:'INR':'symbol'
                        }}/- &nbsp; ({{ finalremainingineng }})
                      </b>
                      being balance amount of the admissible amount of
                      <b>
                        {{
                          orderdata.FINAL_ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-
                        <!-- &nbsp; ({{ finaladmissibleineng }}) -->
                      </b>
                      <!-- being 90% (approximately) of the admissible amount of -->
                      <!-- <b>
                        {{
                          orderdata.ADMISSIBLE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-</b
                      > -->
                    </span>
                    incurred by {{ orderdata.EMPLOYEE_NAME }}&nbsp;,
                    {{ orderdata.DESIGNATION }}
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      for his/her {{ orderdata.RELATION_WITH_PATIENT }}
                      {{ orderdata.PATIENT_NAME }}</span
                    >

                    &nbsp; for&nbsp;{{ orderdata.NATURE_OF_TREATMENT }}
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      of Self</span
                    >, at
                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                      {{ data.NAME }} ,{{ data.ADDRESS }}
                      <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                      <span *ngIf="HospitalMapping.length - 2 == k">and</span>
                      <!-- </span> -->
                    </span>

                    from
                    {{ orderdata.TREATMENT_START_DATE | date : "dd MMM yyyy" }}
                    to
                    {{ orderdata.TREATMENT_END_DATE | date : "dd MMM yyyy" }}.
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CG'">
                      An advance amount of
                      <b>
                        {{
                          orderdata.ADVANCE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-
                        <!-- &nbsp; ({{ advanceineng }}) -->
                      </b>
                      <span *ngIf="isAdvanceSameAsAdmissible == true">
                        being if 90%
                      </span>
                      of admissible amount of
                      {{
                        orderdata.ADMISSIBLE_AMOUNT
                          | currency:'INR':'symbol'
                      }}/- was sanctioned on date
                      {{ orderdata.ADVANCE_TAKEN_DATE | date : "dd/MM/yyyy" }}.
                      This sanction is issued in exercise of the powers vested
                      in me vide Office Memorandum No.S-11012/1/91/CGHS(P) Vol.
                      I dated 18.03.1992 issued by the Ministry of Health &
                      Family Welfare , Government of India.</span
                    >
                    <span *ngIf="orderdata.BENEFICIARY_TYPE == 'CS'">
                      An advance amount of
                      <b>
                        {{
                          orderdata.ADVANCE_AMOUNT
                            | currency:'INR':'symbol'
                        }}/-
                        <!-- &nbsp; ({{ advanceineng }}) -->
                      </b>
                      <span *ngIf="isAdvanceSameAsAdmissible == true">
                        being if 90%
                      </span>
                      of admissible amount of
                      {{
                        orderdata.ADMISSIBLE_AMOUNT
                          | currency:'INR':'symbol'
                      }}/- was sanctioned on date
                      {{ orderdata.ADVANCE_TAKEN_DATE | date : "dd/MM/yyyy" }}.
                      This sanction is issued in accordance with the provisions
                      of Appendix VIII of the Central Services (Medical
                      Attendance) Rules, 1944.
                    </span>
                  </p>

                  <!-- <p
                    style="margin-bottom: 15px; margin-top: 10px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    यह राशि डिस्चार्ज की तारीख से एक महीने के भीतर श्री
                    {{ orderdata.EMPLOYEE_NAME_HN }} द्वारा प्रस्तुत किए जाने
                    वाले नियमित दावों के खिलाफ समायोजित की जाएगी।
                  </p> -->

                  <!-- <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    This amount will be adjusted against regular claims ,
                    within one month from the date of discharge. to be submitted
                    by {{ orderdata.EMPLOYEE_NAME }}.
                  </p> -->

                  <p
                    style="margin-bottom: 15px; margin-top: 10px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    उक्त व्यय को वित्तीय वर्ष 2023-2024 के लिए विभाग को आबंटित
                    मुख्य शीर्ष "2020 चिकित्सा" के अंतर्गत अनुदान के नामे डाला
                    जाए।
                  </p>

                  <p
                    style="margin-bottom: 15px"
                    *ngIf="orderdata.IS_ADVANCE_TAKEN == 1"
                  >
                    The above expenditure should be debited to the grant under
                    the major head "2020 - Medical allotted to the Department
                    for Financial Year 2023-2024.
                  </p>

                  <div style="margin-top: 60px; font-size: 13px">
                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div><b> (विनय सिन्हा) </b></div>
                        <div style="font-weight: bold">
                          आयकर आयुक्त (प्रशासन एवं करदाता सेवाएँ) , मुंबई
                        </div>
                      </div>
                    </div>

                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div><b> (Vinay Sinha) </b></div>
                        <div style="font-weight: bold; font-size: 13px">
                          Commissioner Of Income Tax(Admin & TPS),Mumbai.
                          <!-- <span *ngIf="orderdata.SIGNATURE_ID != 2"
                            >Commissioner Of Income Tax(Admin &
                            TPS),Mumbai.</span
                          >
                          <span *ngIf="orderdata.SIGNATURE_ID == 2">
                            For Principal Chief Commissioner of Income Tax
                            Mumbai</span
                          > -->
                        </div>
                        <!-- <div
                          style="font-weight: bold; font-size: 13px"
                          *ngIf="orderdata.SIGNATURE_ID != 3"
                        >
                          for Pr. Chief Commissioner of Income-Tax, Mumbai
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      style="
                        page-break-inside: avoid !important;
                        font-size: 13px;
                        page-break-before: avoid !important;
                      "
                    >
                      <div style="margin-top: 30px">
                        <b>प्रतिलिपी/Copy to:</b>
                      </div>
                      <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                        <span>
                          1)अराजपत्रित वेतन बिल, मुंबई (2 प्रतियां) फॉर्म एमआरसी
                          (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ
                        </span>
                        <br />
                        <span style="padding-left: 15px">
                          The Non Gazetted Pay Bill Section (2 copies) alongwith
                          Form MRC(S) , hospital receipts , discharge summary
                          etc.
                        </span>
                        <br />

                        <!-- <span *ngIf="SECTION_TYPE == 'welfare'">
                          1) डीडीओ, कल्याण और एसजी,मुंबई (2 प्रतियां) फॉर्म
                          एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के
                          साथ|</span
                        > <br>
                        <span *ngIf="SECTION_TYPE == 'gazpay'">
                          1) डीडीओ, राजपत्रित वेतन बिल अनुभाग II (कार्मिक),मुंबई
                          (2 प्रतियां) फॉर्म एमआरसी (एस), अस्पताल रसीद,
                          डिस्चार्ज सारांश आदि के साथ |</span
                        >
                        <br>
                        <span
                        *ngIf="SECTION_TYPE == 'welfare'"
                        style="padding-left: 15px"
                      >
                        DDO , Welfare & SG , Mumbai (2 copies) alongwith Form
                        MRC(S) , hospital receipts , discharge summary etc.
                      </span>
                      <br>
                      <span
                        *ngIf="SECTION_TYPE == 'gazpay'"
                        style="padding-left: 15px"
                      >
                        DDO , Gazetted Pay bill Section II, Personnel Mumbai
                        (2 copies) alongwith Form MRC(S) , hospital receipts ,
                        discharge summary etc.
                      </span> -->

                        <!-- <div>
                          2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                          <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                          तैयार किया जा सकता है।
                        </div>
                        <div style="padding-left: 15px">
                            ZAO , Pre-check unit , Mumbai - Cheque may be drawn in
                          favour of {{ orderdata.EMPLOYEE_NAME }}.
                        </div> -->
                        <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 0">
                          <div>
                            2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                            <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                            तैयार किया जा सकता है। |
                          </div>
                          <div style="padding-left: 15px">
                             ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                            in favour of {{ orderdata.EMPLOYEE_NAME }}.
                          </div>
                        </div>
                        <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 1">
                          <div  *ngIf="orderdata.CHEQUE_DRAWN==0">
                            2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                            <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                            तैयार किया जा सकता है। |
                          </div>
                          <div style="padding-left: 15px"  *ngIf="orderdata.CHEQUE_DRAWN==0">
                             ZAO , Pre-check unit , Mumbai - Cheque may be drawn
                            in favour of {{ orderdata.EMPLOYEE_NAME }}.
                          </div>
                          <div  *ngIf="orderdata.CHEQUE_DRAWN==1">
                            2) {{orderdata.ZAO_HN}}
                          </div>
                          <div style="padding-left: 15px"  *ngIf="orderdata.CHEQUE_DRAWN==1">
                              {{orderdata.ZAO}}
                          </div>
                        </div>

                        <div>
                          3)<b>
                            {{ orderdata.EMPLOYEE_NAME_HN }},
                            {{ orderdata.DESIGNATION_NAME_HN }} कार्यालय
                            {{ orderdata.OFFICE_NAME_HN }}
                            <!-- {{ orderdata.LOCATION_HN }}., -->
                          </b>
                          स्वीकार्य राशि की गणना की प्रति के साथ |
                        </div>

                        <div style="padding-left: 15px">
                          <b
                            >{{ orderdata.EMPLOYEE_NAME }},
                            {{ orderdata.DESIGNATION }} O/o
                            {{ orderdata.EMPLOYEE_OFFICE_NAME }}
                            <!-- {{ orderdata.LOCATION }}., -->
                          </b>
                          along with copy of working of admissible amount.
                        </div>
                      </div>

                      <div *ngIf="orderdata.IS_ADVANCE_TAKEN == 0">
                        <div>
                          <span *ngIf="SECTION_TYPE == 'welfare'">
                            1) डीडीओ, कल्याण और एसजी,मुंबई (2 प्रतियां) फॉर्म
                            एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के
                            साथ|</span
                          >
                          <span *ngIf="SECTION_TYPE == 'gazpay'">
                            1) डीडीओ, राजपत्रित वेतन बिल अनुभाग II
                            (कार्मिक),मुंबई (2 प्रतियां) फॉर्म एमआरसी (एस),
                            अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ |</span
                          >
                          <!-- 1) डीडीओ,कल्याण और एसजी,मुंबई (2 प्रतियां)
                      फॉर्म एमआरसी (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ| -->
                        </div>
                        <div>
                          <!-- 1) DDO , Welfare & SG , Mumbai (2 copies) alongwith Form
                      MRC(S) , hospital receipts , discharge summary etc. -->

                          <span
                            *ngIf="SECTION_TYPE == 'welfare'"
                            style="padding-left: 15px"
                          >
                            DDO , Welfare & SG , Mumbai (2 copies) alongwith
                            Form MRC(S) , hospital receipts , discharge summary
                            etc.
                          </span>
                          <span
                            *ngIf="SECTION_TYPE == 'gazpay'"
                            style="padding-left: 15px"
                          >
                            DDO , Gazetted Pay bill Section II, Personnel Mumbai
                            (2 copies) alongwith Form MRC(S) , hospital receipts
                            , discharge summary etc.
                          </span>
                        </div>
                        <div>
                          2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                          <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b> के पक्ष में
                          तैयार किया जा सकता है।
                        </div>
                        <div style="padding-left: 15px">
                            ZAO , Pre-check unit , Mumbai - Cheque may be drawn in
                          favour of {{ orderdata.EMPLOYEE_NAME }}.
                        </div>

                        <div>
                          3)<b>
                            {{ orderdata.EMPLOYEE_NAME_HN }},
                            {{ orderdata.DESIGNATION_NAME_HN }} कार्यालय
                            {{ orderdata.OFFICE_NAME_HN }}
                            <!-- {{ orderdata.LOCATION_HN }}., -->
                          </b>
                          स्वीकार्य राशि की गणना की प्रति के साथ |
                        </div>

                        <div style="padding-left: 15px">
                          <b
                            >{{ orderdata.EMPLOYEE_NAME }},
                            {{ orderdata.DESIGNATION }} O/o
                            {{ orderdata.EMPLOYEE_OFFICE_NAME }}
                            <!-- {{ orderdata.LOCATION }}., -->
                          </b>
                          along with copy of working of admissible amount.
                        </div>
                      </div>

                      <div style="margin-top: 60px">
                        <div nz-row>
                          <div nz-col nzSpan="12"></div>

                          <div nz-col nzSpan="12" style="text-align: center">
                            <div style="font-weight: bold">({{ NAME_HN }})</div>
                            <div>
                              <b> {{ POST_HN }} </b>
                            </div>
                            <div>
                              <b> {{ OFFICE_NAME_HN }} </b>
                            </div>
                            <div>
                              <b>({{ SIGNNAME }})</b>
                            </div>
                            <div>
                              <b>{{ POST }} </b>
                            </div>
                            <div>
                              <b>{{ OFFICE_NAME }} </b>
                            </div>
                            <!-- <span *ngIf="SECTION_TYPE == 'welfare'">
                              <div style="font-weight: bold">
                                (रीतेश कुमार)
                              </div>
                              <div>आयकर अधिकारी (मुख्‍यालय)</div>
                              <div>कल्याण और कर्मचारी शिकायत, मुंबई</div>
                            </span>

                            <span *ngIf="SECTION_TYPE == 'gazpay'">
                              <div style="font-weight: bold">
                                (जितेंद्र रा चांदेकर)
                              </div>
                              <div>आयकर अधिकारी</div>
                              <div>राजपत्रित वेतन बिल अनुभाग II, मुंबई</div>
                            </span> -->
                          </div>
                        </div>

                        <!-- <div nz-row>
                          <div nz-col nzSpan="15"></div>

                          <div nz-col nzSpan="9" style="text-align: center">
                            <span *ngIf="SECTION_TYPE == 'welfare'">
                              <div style="font-weight: bold">
                                (Ritesh Kumar)
                              </div>
                              <div>Income Tax Officer(HQ)</div>
                              <div>Welfare & S.G, Mumbai</div>
                            </span>
                            <span *ngIf="SECTION_TYPE == 'gazpay'">
                              <div style="font-weight: bold">
                                (Jitendra R Chandekar)
                              </div>
                              <div>Income Tax Officer</div>
                              <div>
                                Gazetted Pay Bill Section II Personnel, Mumbai
                              </div>
                            </span>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>
  </ng-template>

  <ng-template #printAdvanceOrderModalFooter>
    <button nz-button nzType="default" (click)="printAdvanceOrderModalCancel()">
      Close
    </button>

    <button
      nz-button
      nzType="default"
      [useExistingCss]="true"
      printSectionId="printOrderModalAdvance"
      ngxPrint
      [nzLoading]="pdfDownload"
    >
      Print
    </button>

    <button
      nz-button
      nzType="primary"
      [nzLoading]="pdfDownload"
      (click)="generateAdvanceOrderPDF()"
    >
      Download
    </button>
  </ng-template>
</nz-modal>
