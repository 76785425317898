<div nz-row nzType="flex">
    <div nz-col nzXs="24" nzSm="24" nzMd="20" nzLg="20" nzXl="12">
        <div class="formname">{{formTitle}}</div>
    </div>

    <div nz-col nzXs="24" nzSm="24" nzMd="4" nzLg="6" nzXl="12" style="display: flex; justify-content: flex-end">
        <form nz-form [nzLayout]="'inline'">
            <nz-form-item>
                <button nz-button nzTooltipTitle="Excel" nzTooltipPlacement="top" nz-tooltip nzType="primary"
                    (click)="importInExcel()" [nzLoading]="exportLoading">
                    <i nz-icon nzType="file-excel" nzTheme="outline"></i>
                </button>
            </nz-form-item>

            <nz-form-item>
                <button nz-button nzTooltipTitle="Filter" nzTooltipPlacement="top" nz-tooltip [nzType]="isFilterApplied"
                    (click)="showFilter()">
                    <i nz-icon nzType="filter"></i>
                </button>
            </nz-form-item>

            <nz-form-item>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                    <input type="text" name="search" [(ngModel)]="searchText" (keydown.enter)="onKeypressEvent($event)"
                        nz-input placeholder="Search" />
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" id="button" (click)="search(true)" nzSearch><i nz-icon
                            nzType="search"></i></button>
                </ng-template>
            </nz-form-item>
        </form>
    </div>
</div>

<div nz-row>
    <div nz-col nzSpan="24">
        <div class="filter {{ filterClass }}">
            <div class="filter-box">

                <form nz-form>
                    <div nz-row>
                        <div nz-col nzSpan="6">
                            <nz-form-item style="margin-top: 1px;width: 100%;">
                                <nz-form-label nzNoColon>Select Date</nz-form-label>
                                <nz-range-picker name="date" nzFormat="dd MMM yyyy" [(ngModel)]="DATE">
                                </nz-range-picker>
                            </nz-form-item>
                        </div>
                        <!-- [(ngModel)]="data" -->
                        <div nz-col nzSpan="5">
                            <nz-form-item>
                                <nz-form-label nzNoColon>User</nz-form-label>
                                <nz-select name="user" nzShowSearch nzAllowClear style="width: 100%"
                                    nzPlaceHolder="Select User Name" nzMode="multiple" [(ngModel)]="USERS" 
                                    (ngModelChange)="event1($event)">
                                    <nz-option *ngFor="let stage of usernames;" [nzValue]="stage.ID"
                                        [nzLabel]="stage.NAME">
                                    </nz-option>
                                </nz-select>
                            </nz-form-item>
                        </div>

                        <div nz-col nzSpan="5">
                            <nz-form-item>
                                <nz-form-label nzNoColon>Hierarchy Name</nz-form-label>
                                <nz-select name="InspectorName" nzShowSearch nzAllowClear style="width: 100%"
                                    nzPlaceHolder="Select Hierarchy Name" nzMode="multiple" [(ngModel)]="HIERARCHY"
                                    (ngModelChange)="event($event)">
                                    <nz-option *ngFor="let iname of Hierarchyname;" [nzValue]="iname.ID"
                                        [nzLabel]="iname.NAME">
                                    </nz-option>
                                </nz-select>
                            </nz-form-item>
                        </div>
                        <!-- <div nz-col nzSpan="6">
                            <nz-form-item>
                                <nz-form-label nzNoColon>File Year</nz-form-label>
                                <nz-select name="FileYear" nzShowSearch nzAllowClear style="width: 100%"
                                    nzPlaceHolder="Select File Year" nzMode="multiple"
                                    [(ngModel)]="FILE_YEAR">
                                    <nz-option *ngFor="let iname of inspectorname;" [nzValue]="iname.ID"
                                        [nzLabel]="iname.NAME">
                                    </nz-option>
                                </nz-select>
                            </nz-form-item>
                        </div> -->
                        <div nz-col nzSpan="8">
                            <nz-form-label nzNoColon></nz-form-label>
                            <nz-form-item>
                                <button nz-button [nzLoading]="isSpinning" nzType="primary" (click)="applyFilter()"
                                    nzJustify="end">
                                    <i nz-icon nzType="filter"></i>Apply Filter
                                </button>
                                <button nz-button nzType="primary" (click)="clearFilter()">
                                    <i nz-icon nzType="filter"></i>Clear filter
                                </button>
                            </nz-form-item>
                        </div>
                    </div>



                    <!-- <div nz-row>
                        <!-- <div nz-col nzSpan="6">
                            <nz-form-item>
                                <nz-form-label nzNoColon>STATUS</nz-form-label>
                                <nz-select nzShowSearch nzAllowClear name="Select Status"
                                    nzPlaceHolder="Select Status" [(ngModel)]="STATUS">
                                    <!-- <nz-option nzLabel="CGHS" nzValue="CG"></nz-option>
                                    <nz-option nzLabel="CS(MA)" nzValue="CS"></nz-option> 

                                </nz-select>
                            </nz-form-item>
                        </div> 

                    </div> -->
                </form>
            </div>
        </div>
    </div>
</div>





<!-- Table -->
<div nz-row>
    <div nz-col nzSpan="24">
        <!-- <nz-table id="excel-table" class="my-scroll no-wrap1" nzBordered #table nzShowSizeChanger [nzFrontPagination]="true"
            [nzData]="dataList" [nzLoading]="loadingRecords" [nzTotal]="totalRecords" [(nzPageIndex)]="pageIndex"
            [(nzPageSize)]="pageSize" (nzPageIndexChange)="search()" (nzPageSizeChange)="search(true)"> -->

        <nz-table id="excel-table" class="my-scroll no-wrap1" nzBordered #table nzShowSizeChanger
            [nzFrontPagination]="false" [nzData]="dataList" [nzLoading]="loadingRecords" [nzTotal]="totalRecords"
            [(nzPageIndex)]="pageIndex" [(nzPageSize)]="pageSize" (nzPageIndexChange)="search()"
            [nzScroll]="{x:'1900px',y:'420px'}" (nzPageSizeChange)="search()" (nzQueryParams)="sort($event)">

            <thead nzSingleSort>
                <tr>
                    <!-- <th nzWidth="355px"style="font-weight: bold; text-align: center;">Action</th>
                  <th nzWidth="90px" style="font-weight: bold; text-align: center;">Claim File</th>
                  <th nzWidth="80px" style="font-weight: bold; text-align: center;">Log</th> -->
                    <th nzWidth="100px" style="font-weight: bold; text-align: center;" nzColumnKey="{{'USER_NAME'}}"
                        [nzSortFn]="true">
                        User Name
                    </th>
                    <th nzWidth="70px" style="font-weight: bold; text-align: center;" nzColumnKey="{{'ROLE_NAME'}}"
                        [nzSortFn]="true">
                        Role Name
                    </th>
                    <th nzWidth="100px" style="font-weight: bold; text-align: center;" nzColumnKey="{{'HIRARCHY_NAME'}}"
                        [nzSortFn]="true">
                        Hierarchy Name
                    </th>
                    <th nzWidth="70px" style="font-weight: bold; text-align: center;" nzColumnKey="{{'FILE_NAME'}}"
                        [nzSortFn]="true">
                        File Name
                    </th>
                    <th nzWidth="80px" nzColumnKey="{{'FILE_NUMBER'}}" [nzSortFn]="true"
                        style="font-weight: bold; text-align: center;">
                        File Number
                    </th>
                    <th nzWidth="90px" nzColumnKey="{{'TRANSFER_DATETIME'}}" [nzSortFn]="true"
                        style="font-weight: bold; text-align: center;">
                        Action Date Time
                    </th>
                    <th nzWidth="150px" nzColumnKey="{{'REMARK'}}" [nzSortFn]="true"
                        style="font-weight: bold; text-align: center;">
                        Remark
                    </th>
                    <th nzWidth="80px" nzColumnKey="{{'ACTION_STATUS'}}" [nzSortFn]="true"
                        style="font-weight: bold; text-align: center;">
                        Action
                    </th>
                    <!-- <th nzWidth="70px" nzColumnKey="{{'CURRENTLY_AT'}}" [nzSortFn]="true"
                        style="font-weight: bold; text-align: center;">Currently At
                    </th>
                    <th nzWidth="90px" nzColumnKey="{{'TRANSFERRED_DATE_TIME'}}" [nzSortFn]="true"
                        style="font-weight: bold; text-align: center;">
                        Transferred Date & Time
                    </th>
                    <th nzWidth="80px" nzColumnKey="{{'IS_ADVANCE_FINAL_FILE'}}" [nzSortFn]="true"
                        style="font-weight: bold; text-align: center;">
                        Is Advance/Final File
                    </th>
                    <th nzWidth="90px" nzColumnKey="{{'STATUS'}}" [nzSortFn]="true"
                        style="font-weight: bold; text-align: center;">
                        Status
                    </th> -->
                 
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let data of table.data;">
                    <!-- <td style="white-space: pre-line;" align="center" *ngFor="let column of columns;">
                        {{data[column[0]] == null ? 'None' : data[column[0]]}}
                    </td> -->
                    <!-- <td nzAlign="center">{{data.FINANCE_COUNT}}</td> -->
                    <!-- <td style="white-space: pre-line;" align="center">{{data['FILE_YEAR']}}</td> -->
                    <!-- <td style="white-space: pre-line;" align="center">{{data['HOSPITAL_TYPE']}}</td> -->
                    <td style="white-space: pre-line;" align="center">{{data['USER_NAME']}}</td>
                    <!-- <td style="white-space: pre-line;" align="center">{{data['TYPE_OF_HOSPITAL']}}</td> -->
                    <!-- <td style="text-align: center;">
                        <span nz-icon *ngIf="data['HOSPITAL_TYPE']=='E'">Empanelled</span>
                        <span nz-icon *ngIf="data['HOSPITAL_TYPE']=='NE'">Non Empanelled</span>
                        <span nz-icon *ngIf="data['HOSPITAL_TYPE']=='G'">Government</span>
                    </td> -->
                    <td style="white-space: pre-line;" align="center">{{data['ROLE_NAME']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['HIRARCHY_NAME']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['FILE_NAME']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['FILE_NUMBER']}}</td>
                    <!-- <td style="text-align: center;">
                        <span nz-icon *ngIf="data['HOSPITAL_TYPE']=='E'">Empanelled</span>
                        <span nz-icon *ngIf="data['HOSPITAL_TYPE']=='NE'">Non Empanelled</span>
                        <span nz-icon *ngIf="data['HOSPITAL_TYPE']=='G'">Government</span>
                    </td>
                    <td style="white-space: pre-line;" align="center">{{data['HOSPITAL_NAME']}}</td> -->
                    <td style="white-space: pre-line;" align="center">{{data['TRANSFER_DATETIME']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['REMARK']}}</td>
                    <!-- <td style="white-space: pre-line;" align="center">{{data['ACTION']}}</td> -->
                    <td style="text-align: center;">
                        <span nz-icon *ngIf="data['ACTION_STATUS']=='A'">Accepted</span>
                        <span nz-icon *ngIf="data['ACTION_STATUS']=='R'">Rejected</span>
                        <span nz-icon *ngIf="data['ACTION_STATUS']=='M'">Created</span>
                        <span nz-icon *ngIf="data['ACTION_STATUS']=='P'">Pending</span>
                        <span nz-icon *ngIf="data['ACTION_STATUS']=='B'">Pull Back</span>
                        <span nz-icon *ngIf="data['ACTION_STATUS']=='C'">Closed</span>
                        <!-- <span nz-icon *ngIf="data['CREATOR_ROLE']=='G'">Government</span> -->
                    </td>
                    <!-- <td style="white-space: pre-line;" align="center">{{data['TREATEMENT_TYPE']}}</td> -->
                    <!-- <td style="text-align: center;white-space: pre-line">
                        <span nz-icon *ngIf="data['TREATEMENT_TYPE'] == 'OT'">OPD Treatment / Test Entitled</span>
                        <span nz-icon *ngIf="data['TREATEMENT_TYPE'] == 'IT'">Indoor Treatment</span>
                        <span nz-icon *ngIf="data['TREATEMENT_TYPE'] == 'OT,IT'">OPD Treatment / Test Entitled & Indoor
                            Treatment</span>
                    </td> -->
                    <!-- <td style="white-space: pre-line;" align="center">{{data['IS_ADVANCE_FINAL_FILE/OFFICE']}}</td>
                    <td style="text-align: center;white-space: pre-line;">
                        <span nz-icon *ngIf="data['STATUS']==''"></span>
                        <span nz-icon *ngIf="data['STATUS']==''"></span>

                    </td> -->
                    <!-- <td style="white-space: pre-line;" align="center">{{data['STATUS']}}</td> -->
                    <!-- <td style="white-space: pre-line;" align="center">{{data['RELATION_WITH_PATIENT']}}</td>
                    
                    <td style="white-space: pre-line;" align="center">{{data['IS_EMERG_TREAT_AVAILED']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['CGHS_AMA_REFERENCE_DATE']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['IS_BILL_FILLED_IN_TIME']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['NATURE_OF_TREATMENT']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['PERIOD_OF_TREATMENT']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['NET_PAYABLE_AMOUNT']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['CGHS_AMA_REFERENCE_NO']}}</td> -->

                </tr>
            </tbody>
        </nz-table>
    </div>
</div>