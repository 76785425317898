// import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { QuestionaryMaster } from 'src/app/Models/questionarymaster';
import { ApiService } from 'src/app/Service/api.service';
import * as html2pdf from 'html2pdf.js';
import { SignatureMaster } from 'src/app/Models/SignatureMaster';
@Component({
  selector: 'app-gar23',
  templateUrl: './gar23.component.html',
  styleUrls: ['./gar23.component.css'],
})
export class GAR23Component implements OnInit {
  @Input() drawerClose: Function;
  @Input() GARData;
  @Input() HospitalMapping;
  @Input() amountinWords;
  @Input() OFFICE_NAME;
  @Input() loadingRecords: boolean = false;
  @Input() Signaturearray = [];
  constructor(
    private datePipe: DatePipe,
    private message: NzNotificationService,
    private api: ApiService,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    // this.getAllUsers();
  }

  claimHospitalMapping = [];

  Save() {
    this.loadingRecords = true;
    this.claimHospitalMapping = [];
    for (var i = 0; this.HospitalMapping.length > i; i++) {
      var obj = Object.assign({}, this.HospitalMapping[i]);
      obj.ID = undefined;
      this.claimHospitalMapping.push(obj);
      // this.claimHospitalMapping[i]['CLAIM_ID'] = undefined;
      if (this.HospitalMapping.length == i + 1) {
        this.GARData.hospitalData = this.claimHospitalMapping;
      }
    }
    this.GARData['CLAIM_ID'] = this.GARData.ID;
    this.api.updateclaimed(this.GARData).subscribe((successCode) => {
      if (successCode.code == '200') {
        this.message.success('Information Changed Successfully...', '');
        // this.loadingRecords = false;
        this.api
          .getclaimMaster(
            1,
            1,
            '',
            '',
            ' AND ID = ' + this.GARData.ID,
            '',
            '',
            '',
            ''
          )
          .subscribe(
            (data) => {
              if (data['code'] == 200) {
                console.log(data);
                this.loadingRecords = false;
                this.openPrintOrderModal();
              } else {
                this.message.error("Can't Load Data", '');
                this.loadingRecords = false;
              }
            },
            (err) => {
              console.log(err);
            }
          );
      } else {
        this.message.error('Information Has Not Changed...', '');
        this.loadingRecords = false;
      }
    });
  }
  close(): void {
    this.loadingRecords = false;
    this.drawerClose();
  }

  printOrderModalVisible: boolean = false;

  openPrintOrderModal() {
    this.printOrderModalVisible = true;
  }

  printOrderModalCancel() {
    this.printOrderModalVisible = false;
  }

  getwidth() {
    if (window.innerWidth <= 400) {
      return 400;
    } else {
      return 850;
    }
  }

  // auto(event: number) {

  // }

  isfound = 0;

  fileName: string = 'HOD Order Letter';
  pdfDownload: boolean = false;

  generatePDF() {
    this.pdfDownload = true;
    const element = document.getElementById('printOrderModal');
    const opt = {
      margin: 0.2,
      filename: 'Download.pdf',
      image: { type: 'jpeg', quality: 5 },
      html2canvas: { scale: 5 },
      jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
    };
    // html2pdf().from(element).set(opt).save();
    html2pdf()
      .from(element)
      .set(opt)
      .save()
      .then(() => {
        this.pdfDownload = false;
      });
  }

  signature(event: any) {
    var f = this.Signaturearray.filter((item) => item.ID == event);
    // this.SECTION_TYPE = f[0]['SECTION_TYPE'];
    // this.SIGNNAME = f[0]['NAME'];
    // this.NAME_HN = f[0]['NAME_HN'];
    this.OFFICE_NAME = f[0]['OFFICE_NAME'];
    // this.OFFICE_NAME_HN = f[0]['OFFICE_NAME_HN'];
    // this.POST = f[0]['POST'];
    // this.POST_HN = f[0]['POST_HN'];
  }
  getAllUsers() {
    this.api.getSignature(0, 0, 'ID', 'desc', '').subscribe(
      (data) => {
        if (data['code'] == 200) {
          this.Signaturearray = data['data'];
          console.log(this.Signaturearray);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
