// import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { QuestionaryMaster } from 'src/app/Models/questionarymaster';
import { ApiService } from 'src/app/Service/api.service';
import * as html2pdf from 'html2pdf.js';
import { SignatureMaster } from 'src/app/Models/SignatureMaster';
@Component({
  selector: 'app-ltcsheet2',
  templateUrl: './ltcsheet2.component.html',
  styleUrls: ['./ltcsheet2.component.css'],
})
export class Ltcsheet2Component implements OnInit {
  @Input() drawerClose: Function;
  @Input() amount: any;
  @Input() namount: any;
  @Input() LTC2data: any;
  @Input() POST: any;
  @Input() OFFICE_NAME: any;
  @Input() NAME: any;
  @Input() POST_HN: any;
  @Input() OFFICE_NAME_HN: any;
  @Input() NAME_HN: any;
  @Input() destinationList: any;
  @Input() FamilyList: any;
  // @Input() orderdata: any;
  // @Input() HospitalMapping: any;
  // @Input() queData: QuestionaryMaster = new QuestionaryMaster();
  // @Input() amountinwords1: any;
  // @Input() amountinwordsh1: any;
  // @Input() claimID: any;
  // @Input() empID: any;
  // @Input() SECTION_TYPE: any;
  // FROM_DATE: Date = new Date();
  // TO_DATE: Date = new Date();
  // TODAYS_DATE: Date = new Date();
  // PRINTED_DATE: Date = new Date();
  // FILE_NO: string = '';
  // isSpinning: boolean = false;
  // IS_EMERG_TREAT_AVAILED = 0;
  // IS_BILL_FILLED_IN_TIME = 0;
  // IS_HOSPITAL_EMPLANELLED;
  // Hospital_Type = 'NE';
  // NAME: string = 'shubham';
  // NAME_MR: string = '';
  // Expose_facto = true;
  // AMOUNT: number;
  // AMOUNT_WORDS: string;
  // am = 1000;
  // PATIENT_NAME_HN: string;
  // RELATIONSHIP_HN: string;
  // EMPLOYEE_NAME_HN: string;
  // ADMISSIBLE_AMOUNT_HN: any;
  // dataa: any;
  // radioValue: any = 'A';
  // @Input() fileList1 = [];
  // @Input() HospitalMapping1 = [];

  constructor(
    private datePipe: DatePipe,
    private message: NzNotificationService,
    private api: ApiService,
    private datepipe: DatePipe
  ) {}
  Signaturearray = [];

  ngOnInit(): void {
    this.api.getSignature(0, 0, 'ID', 'desc', '').subscribe(
      (data) => {
        if (data['code'] == 200) {
          this.Signaturearray = data['data'];
        }
      },
      (err) => {
        console.log(err);
      }
    );
    this.getdestinationList();
  }
  omit(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  claimHospitalMapping = [];
  loadingRecords: boolean = false;
  Save() {
    this.loadingRecords = true;
    this.api.ltcMasterUpdate(this.LTC2data).subscribe((successCode) => {
      if (successCode.code == '200') {
        // this.drawerClose();
        this.loadingRecords = false;
        this.printOrderModalVisible = true;

        this.message.success('Information Saved Successfully...', '');
      } else {
        this.loadingRecords = false;
        this.message.error('Failed To Save Information....', '');
      }
    });
  }
  close(): void {
    this.drawerClose();
  }

  printOrderModalVisible: boolean = false;

  openPrintOrderModal() {}

  printOrderModalCancel() {
    this.printOrderModalVisible = false;
  }

  getwidth() {
    if (window.innerWidth <= 400) {
      return 400;
    } else {
      return 850;
    }
  }

  // auto(event: number) {

  // }

  isfound = 0;

  fileName: string = 'HOD Order Letter';
  pdfDownload: boolean = false;

  generatePDF() {
    const element = document.getElementById('printOrderModal');
    const opt = {
      margin: 0.2,
      filename: 'Download.pdf',
      image: { type: 'jpeg', quality: 5 },
      html2canvas: { scale: 5 },
      jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
    };
    html2pdf().from(element).set(opt).save();
  }

  signature(event: any) {
    var f = this.Signaturearray.filter((item) => item.ID == event);
    // this.SECTION_TYPE = f[0]['SECTION_TYPE'];
    this.NAME = f[0]['NAME'];
    this.NAME_HN = f[0]['NAME_HN'];
    this.OFFICE_NAME = f[0]['OFFICE_NAME'];
    this.OFFICE_NAME_HN = f[0]['OFFICE_NAME_HN'];
    this.POST = f[0]['POST'];
    this.POST_HN = f[0]['POST_HN'];
  }
  nnamount = 0;
  changeamountt(event: any) {
    this.nnamount = 0;

    this.nnamount = Number(this.namount) + Number(event);
  }
  getlevel(level: any) {
    if (level != null && level != undefined && level != '') {
      const numbers = level.match(/\d+/g);
      return numbers;
    }
  }
  destinationnn: any = '';
  getdestinationList() {
    this.destinationnn = '';
    if (this.destinationList.length > 0) {
      var dataaa = '';
      for (var i = 0; i < this.destinationList.length; i++) {
        if (dataaa != '') {
          dataaa = dataaa + ',' + this.destinationList[i];
        } else {
          dataaa = this.destinationList[i];
        }
      }
    }
    if (dataaa != '') {
      this.destinationnn = dataaa + ' only';
    } else {
      this.destinationnn = '';
    }
    var dataaaaa = this.destinationnn;
  }

  clearDesignation(event) {
    if (event == false) {
      this.LTC2data.DESTIONATION_REMARK = null;
    } else {
    }
  }
}
