import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { CookieService } from 'ngx-cookie-service';
import { ClaimMaster } from 'src/app/Models/claimmaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-pending-claims',
  templateUrl: './pending-claims.component.html',
  styleUrls: ['./pending-claims.component.css'],
})
export class PendingClaimsComponent implements OnInit {
  userId = sessionStorage.getItem('userId');
  userName = sessionStorage.getItem('userName');
  roleId = sessionStorage.getItem('roleId');
  pageSize2 = 10;

  formTitle = 'Manage Pending Claims';
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = false;
  sortValue: string = 'desc';
  sortKey: string = 'ID';
  searchText: string = '';
  filterQuery: string = '';
  size = 'small';
  isFilterApplied: string = 'default';
  newButton: string = 'default';
  forwardButton: string = 'default';
  rejectButton: string = 'default';
  queryButton: string = 'default';
  approvedButton: string = 'default';
  readyButton: string = 'default';
  totalButton: string = 'default';
  claimData: any;
  filterClass = 'filter-invisible';
  columns: string[][] = [
    ['CLAIM_STAGE_NAME', ''],
    ['CLAIM_NO', ''],
    ['EMPLOYEE_NAME', ''],
    ['EMPLOYEE_CODE', ''],
    ['DESIGNATION', ''],
    ['PATIENT_NAME', ''],
    ['RELATION_WITH_PATIENT', ''],
    ['BILL_FILIING_DATE', ''],
    ['ADMISSIBLE_AMOUNT', ''],
    ['TREATMENT_START_DATE', ''],
    ['TREATMENT_END_DATE', ''],
    ['REJECT_REMARK', ''],
    ['MOBILE_NO', ''],
    ['EMPANELLED_HOSPITAL_NAME', ''],
  ];

  TYPE_OF_HOSPITAL: any;
  STAGE_NAME = [1, 2];
  isSpinning = false;

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'default';
    this.filterQuery = '';
    this.BILL_FILIING_DATE = [];
    this.TYPE_OF_HOSPITAL = [];
    this.dataList = [];
    this.applyFilter();
  }

  dateFormat = 'dd/MM/yyyy';
  isButtonSpinning: boolean = false;

  // TYPE_OF_HOSPITAL: any = [];

  START_DATE: any;
  END_DATE: any;
  BILL_FILIING_DATE = [];
  current = new Date();
  rejectClaimVisible: boolean = false;
  constructor(
    private message: NzNotificationService,
    private api: ApiService,
    private datePipe: DatePipe
  ) {}
  ngOnInit(): void {}

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(reset);
  }

  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find((item) => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'id';
    const sortOrder = (currentSort && currentSort.value) || 'desc';
    console.log(currentSort);
    console.log('sortOrder :' + sortOrder);
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if (this.pageSize2 != pageSize) {
      this.pageIndex = 1;
      this.pageSize2 = pageSize;
    }

    if (this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search();
  }

  search(reset: boolean = false) {
    var filter = '';
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }
    console.log('search text : ' + this.searchText);

    var likeQuery = '';

    if (this.searchText != '') {
      likeQuery = ' AND (';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    }

    // if (
    //   this.BILL_FILIING_DATE != undefined &&
    //   this.BILL_FILIING_DATE.length != 0
    // ) {
    //   this.START_DATE = this.datePipe.transform(
    //     this.BILL_FILIING_DATE[0],
    //     'yyyy-MM-dd'
    //   );
    //   this.END_DATE = this.datePipe.transform(
    //     this.BILL_FILIING_DATE[1],
    //     'yyyy-MM-dd'
    //   );
    // } else {
    //   this.START_DATE = null;
    //   this.END_DATE = null;
    // }

    this.loadingRecords = true;
    this.api
      .getclaimMaster2(
        this.pageIndex,
        this.pageSize,
        this.sortKey,
        sort,
        this.filterQuery +
          likeQuery +
          ' AND CURRENT_STAGE_ID = 3 AND INSPECTOR_ID = 0'
        // ' AND (CURRENT_STAGE_ID = 1 OR CURRENT_STAGE_ID = 2 OR CURRENT_STAGE_ID = 3) AND INSPECTOR_ID = 0'
      )
      .subscribe(
        (data) => {
          console.log(data);
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';
        },
        (err) => {
          console.log(err);
        }
      );
  }

  applyFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'primary';
    this.loadingRecords = false;

    this.filterQuery = '';
    if (
      this.BILL_FILIING_DATE != undefined &&
      this.BILL_FILIING_DATE.length > 0
    ) {
      var BILL_FILIING_DATE = this.datePipe.transform(
        this.BILL_FILIING_DATE[0],
        'yyyy-MM-dd 00:00:00'
      );
      var BILL_FILIING_DATE2 = this.datePipe.transform(
        this.BILL_FILIING_DATE[1],
        'yyyy-MM-dd 23:59:59'
      );

      if (this.BILL_FILIING_DATE[0] != null) {
        this.START_DATE = this.BILL_FILIING_DATE[0];
      }

      if (this.BILL_FILIING_DATE[1] != null) {
        this.END_DATE = this.BILL_FILIING_DATE[1];
      }

      this.filterQuery =
        ' AND (BILL_FILIING_DATE BETWEEN ' +
        BILL_FILIING_DATE +
        ' AND ' +
        BILL_FILIING_DATE2 +
        ')';
    } else {
      this.filterQuery = '';
      this.START_DATE = null;
      this.END_DATE = null;
      this.BILL_FILIING_DATE = [];
    }

    if (
      this.TYPE_OF_HOSPITAL != undefined &&
      this.TYPE_OF_HOSPITAL.length > 0
    ) {
      var f = ' AND(';
      for (var i = 0; i < this.TYPE_OF_HOSPITAL.length; i++) {
        f = f + 'TYPE_OF_HOSPITAL="N" OR';
        if (i + 1 == this.TYPE_OF_HOSPITAL.length) {
          f = f.substring(0, f.length - 2) + ')';
          this.filterQuery = this.filterQuery + f;
        }
      }
    }

    this.search();
  }

  showFilter() {
    if (this.filterClass === 'filter-visible')
      this.filterClass = 'filter-invisible';
    else this.filterClass = 'filter-visible';
  }

  pickClaim(data: any) {
    console.log('data', data);
    this.api
      .getHospitalMapping(0, 0, '', '', ' AND CLAIM_ID = ' + data.ID)
      .subscribe(
        (data2) => {
          if (data2['code'] == 200) {
            var hospitalMapList = data2['data'];
            data = {
              ADDRESS: data.ADDRESS,
              ADMISSIBLE_AMOUNT: data.ADMISSIBLE_AMOUNT,
              ADVANCE_AMOUNT: data.ADVANCE_AMOUNT,
              ADVANCE_TAKEN_DATE: data.ADVANCE_TAKEN_DATE,
              ANNEXTURE_REMARK: data.ANNEXTURE_REMARK,
              AO_ID: data.AO_ID,
              ARCHIVE_FLAG: data.ARCHIVE_FLAG,
              BANK_ACCOUNT_NO: data.BANK_ACCOUNT_NO,
              BANK_MANDATE_FORM_ALONG_WITH_CANCELLED_CHEQUE:
                data.BANK_MANDATE_FORM_ALONG_WITH_CANCELLED_CHEQUE,
              BANK_NAME: data.BANK_NAME,
              BENEFICIARY_TYPE: data.BENEFICIARY_TYPE,
              BILL_FILIING_DATE: data.BILL_FILIING_DATE,
              BILL_FILLED_INTIME: data.BILL_FILLED_INTIME,
              CGHS_AMA_REFERENCE_DATE: data.CGHS_AMA_REFERENCE_DATE,
              CGHS_AMA_REFERENCE_NO: data.CGHS_AMA_REFERENCE_NO,
              CGHS_CARD_NO: data.CGHS_CARD_NO,
              CGHS_CARD_VALIDITY: data.CGHS_CARD_VALIDITY,
              CHECKLIST_STATUS: data.CHECKLIST_STATUS,
              CIT_ID: data.CIT_ID,
              CLAIMED_AMOUNT: data.CLAIMED_AMOUNT,
              CLAIM_ACCREDITATION: data.CLAIM_ACCREDITATION,
              CLAIM_NO: data.CLAIM_NO,
              CLAIM_STAGE_NAME: data.CLAIM_STAGE_NAME,
              CLIENT_ID: data.CLIENT_ID,
              COPY_OF_CGHS_CARD: data.COPY_OF_CGHS_CARD,
              COPY_OF_CGHS_CARD_1: data.COPY_OF_CGHS_CARD_1,
              CREATED_MODIFIED_DATE: data.CREATED_MODIFIED_DATE,
              CURRENT_POSITION_ID: data.CURRENT_POSITION_ID,
              CURRENT_STAGE_ID: data.CURRENT_STAGE_ID,
              DDO_OF_THE_OFFICIAL: data.DDO_OF_THE_OFFICIAL,
              DELAY_CONDOLENCE_DATE: data.DELAY_CONDOLENCE_DATE,
              DELAY_CONDOLENCE_NUMBER: data.DELAY_CONDOLENCE_NUMBER,
              DESIGNATION: data.DESIGNATION,
              DISCHARGE_CARD: data.DISCHARGE_CARD,
              DOCTOR_EMERGENCY_CERTIFICATE_TAKEN:
                data.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN,
              EMAIL_ID: data.EMAIL_ID,
              EMERGENCY_TREATEMENT: data.EMERGENCY_TREATEMENT,
              EMPLOYEE_CODE: data.EMPLOYEE_CODE,
              EMPLOYEE_DDO: data.EMPLOYEE_DDO,
              EMPLOYEE_DESIGNATION: data.EMPLOYEE_DESIGNATION,
              EMPLOYEE_GRADE_PAY: data.EMPLOYEE_GRADE_PAY,
              EMPLOYEE_NAME: data.EMPLOYEE_NAME,
              EMPLOYEE_NAME_HN: data.EMPLOYEE_NAME_HN,
              EMPLOYEE_OFFICE_NAME: data.EMPLOYEE_OFFICE_NAME,
              EMP_ID: data.EMP_ID,
              EXPO_FACTO_DATE: data.EXPO_FACTO_DATE,
              EXPO_FACTO_PERMISSION: data.EXPO_FACTO_PERMISSION,
              EXPO_FACTO_REFERENCE_NO: data.EXPO_FACTO_REFERENCE_NO,
              FILE_ID: data.FILE_ID,
              FILE_NUMBER: data.FILE_NUMBER,
              FINAL_BILL_ORIGINAL_ALONG_WITH_XEROX:
                data.FINAL_BILL_ORIGINAL_ALONG_WITH_XEROX,
              FORM_NO_3_MEDICAL_CLAIM: data.FORM_NO_3_MEDICAL_CLAIM,
              GRADE_PAY: data.GRADE_PAY,
              HOD_PERMISSION_DATE: data.HOD_PERMISSION_DATE,
              HOD_PERMISSION_NO: data.HOD_PERMISSION_NO,
              ID: data.ID,
              IFSC_CODE: data.IFSC_CODE,
              INSPECTOR_ID: Number(this.userId),
              INSPECTOR_NAME: data.INSPECTOR_NAME,
              IS_ADVANCE_TAKEN: data.IS_ADVANCE_TAKEN,
              IS_AT_MY_DESK: data.IS_AT_MY_DESK,
              IS_BILL_FILLED_IN_TIME: data.IS_BILL_FILLED_IN_TIME,
              IS_EXPOST_FACTO_PERMISSION_TAKEN:
                data.IS_EXPOST_FACTO_PERMISSION_TAKEN,
              IS_FORWARDING_LETTER: data.IS_FORWARDING_LETTER,
              IS_HOSPITAL_EMPLANELLED: data.IS_HOSPITAL_EMPLANELLED,
              IS_PERMISSION_TAKEN_FROM_HOD: data.IS_PERMISSION_TAKEN_FROM_HOD,
              ITO_ID: data.ITO_ID,
              JCIT_ID: data.JCIT_ID,
              MEDICAL_REIMBURSMENT_CLAIM_FORM_FOR_CGHS:
                data.MEDICAL_REIMBURSMENT_CLAIM_FORM_FOR_CGHS,
              MICR_CODE: data.MICR_CODE,
              MOBILE_NO: data.MOBILE_NO,
              NATURE_OF_TREATMENT: data.NATURE_OF_TREATMENT,
              OFFICE_NAME: data.OFFICE_NAME,
              ORDER_DATE: data.ORDER_DATE,
              ORDER_NO: data.ORDER_NO,
              PATIENT_CGHS_BENEFICIERY_NO: data.PATIENT_CGHS_BENEFICIERY_NO,
              PATIENT_NAME: data.PATIENT_NAME,
              PATIENT_NAME_HN: data.PATIENT_NAME_HN,
              PAYSLIP_OF_PERIOD_OF_TREATMENT:
                data.PAYSLIP_OF_PERIOD_OF_TREATMENT,
              PAY_BILL: data.PAY_BILL,
              PRESCIPTION_OF_MEDICINES_PURSCHASED_OUTSIDE:
                data.PRESCIPTION_OF_MEDICINES_PURSCHASED_OUTSIDE,
              READ_ONLY: data.READ_ONLY,
              REFERENCE_FORM_CGHS_AMA_TAKEN: data.REFERENCE_FORM_CGHS_AMA_TAKEN,
              REJECT_REMARK: data.REJECT_REMARK,
              RELATIONSHIP_HN: data.RELATIONSHIP_HN,
              RELATION_WITH_PATIENT: data.RELATION_WITH_PATIENT,
              REMARK: data.REMARK,
              SUB_STAGE: data.SUB_STAGE,
              TREATEMENT_TYPE: data.TREATEMENT_TYPE,
              TREATMENT_END_DATE: data.TREATMENT_END_DATE,
              TREATMENT_START_DATE: data.TREATMENT_START_DATE,
              WARD_TYPE: data.WARD_TYPE,
              ZONAL_ID: data.ZONAL_ID,
              hospitalData: hospitalMapList,
            };
            this.api.pickClaim(data).subscribe((successCode) => {
              if (successCode.code == '200') {
                this.message.success('Claim Picked Successfully...', '');
                this.applyFilter();
              }
            });
            this.isSpinning = false;
          } else {
            this.message.error("Can't Load Hospital Mapped Data.", '');
            this.isSpinning = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }
}
