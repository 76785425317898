import { Component, Input, OnInit } from '@angular/core';
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-tour-part-b',
  templateUrl: './tour-part-b.component.html',
  styleUrls: ['./tour-part-b.component.css'],
})
export class TourPartBComponent implements OnInit {
  @Input() drawerClose;
  @Input() tourpartbdata;
  @Input() totalamountofpartb;
  @Input() journeytotalamounttourpartb;
  @Input() foodAdmissibleAmount;
  @Input() accommodationAdmissibleAmount;
  @Input() hotelAdmissibleAmount;
  @Input() intercityAdmissibleAmount;
  @Input() actualExpences;
  @Input() grossAmount;
  constructor() {}

  ngOnInit(): void {}
  close(): void {
    this.drawerClose();
  }

  printOrderModalVisible: boolean = false;
  showmodal() {
    this.printOrderModalVisible = true;
  }

  printOrderModalCancel() {
    this.printOrderModalVisible = false;
  }
  getwidth() {
    if (window.innerWidth <= 400) {
      return 400;
    } else {
      return 850;
    }
  }

  openpdf() {
    const element = document.getElementById('excel-table');
    const opt = {
      margin: 0.2,
      filename: 'Part-B.pdf',
      image: { type: 'jpeg', quality: 7 },
      html2canvas: { scale: 7 },
      jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
    };
    html2pdf().from(element).set(opt).save();
  }
}
