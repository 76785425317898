export class Investigation {
ID:number
INVESTIGATION_CATEGORY_ID:any
NAME:string
NABH_AMOUNT:number=0
NON_NABH_AMOUNT:number=0
SUPER_SPECIALITY_AMOUNT:number=0
MEMO_NOTIFICATION_ID:string
CITY_ID:any
PRICELIST_NAME:any;
SCHEDULE_NO:any;
NOTIFICATION_MEMORAMDUM_ID: any;
SHORT_CODE:any
}
