<!-- <div nz-row nzType="flex">
    <div nz-col nzXs="24" nzSm="24" nzMd="20" nzLg="20" nzXl="12">
        <div class="formname">
            {{formTitle}}
        </div>
    </div>

    <div nz-col nzXs="24" nzSm="24" nzMd="4" nzLg="6" nzXl="12" style="display: flex;justify-content: flex-end">
        <form nz-form [nzLayout]="'inline'">
            <nz-form-item>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                    <input type="text" autocomplete="off" name="search" nz-input placeholder="Search Records"
                        [(ngModel)]="searchText">
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" (click)="search()" nzSearch><i nz-icon
                            nzType="search"></i></button>
                </ng-template>
            </nz-form-item>
            <nz-form-item>
                <button nz-button nzType="primary" (click)="add()" nzJustify="end">
                    <i nz-icon nzType="plus"></i>Add New City
                </button>
            </nz-form-item>
        </form>
    </div>
</div>

<div nz-row>
    <div nz-col nzSpan="24">
        <nz-table class="my-scroll no-wrap1" nzBordered #table nzShowSizeChanger [nzFrontPagination]="false"
            [nzData]="cityMaster" [nzTotal]="totalRecords" [(nzPageIndex)]="pageIndex" [(nzPageSize)]="pageSize">
            <thead>
                <tr>
                    <th style="text-align: center;">Edit</th>
                    <th *ngFor="let column of columns" nzColumnKey="{{column[0]}}" [nzSortFn]="true">{{column[1]}}

                    </th>
                    <th style="text-align: center;" nzColumnKey="STATUS" [nzSortFn]="true">Status</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let data of table.data">
                    <td style="text-align: center;"><a (click)="edit(data)"><i nz-icon nzType="edit"
                                nzTheme="outline"></i></a>
                    </td>
                    <td *ngFor="let column of columns">{{data[column[0]]}}</td>
                    <td style="text-align: center;">
                        <i nz-icon *ngIf="data.STATUS" [nzType]="'check-circle'" [nzTheme]="'twotone'"
                            [nzTwotoneColor]="'#52c41a'"></i>
                        <i nz-icon *ngIf="!data.STATUS" [nzType]="'close-circle'" [nzTheme]="'twotone'"
                            [nzTwotoneColor]="'#f50'"></i>
                    </td>
                </tr>
                <tr></tr>
            </tbody>
        </nz-table>
    </div>
</div>

<nz-drawer [nzBodyStyle]="{ height: 'calc(100% - 55px)', overflow: 'auto', 'padding-bottom': '53px' }"
    [nzClosable]="true" [nzMask]="true" [nzMaskClosable]="false" [nzWidth]="450" [nzVisible]="drawerVisible"
    [nzTitle]="drawerTitle" (nzOnClose)="drawerClose()" (nzKeyboard)="true">
    <app-city-drawer [drawerClose]="closeCallback" [data]="drawerData"></app-city-drawer>
</nz-drawer> -->


<div nz-row nzType="flex">
    <div nz-col nzXs="24" nzSm="24" nzMd="20" nzLg="20" nzXl="12">
        <div class="formname"> <b> {{formTitle}} </b></div>
    </div>

    <div nz-col nzXs="24" nzSm="24" nzMd="4" nzLg="6" nzXl="12" style="display: flex;justify-content: flex-end">
        <form nz-form [nzLayout]="'inline'">
            <nz-form-item>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                    <input type="text" name="postsearch" (keydown.enter)="onKeypressEvent($event)" [(ngModel)]="searchText" nz-input
                        placeholder="Search Record" />
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" id="button" (click)="search(true)" nzSearch><i nz-icon
                            nzType="search"></i></button>
                </ng-template>
            </nz-form-item>
            <nz-form-item>
                <button nz-button nzType="primary" (click)="add()" nzJustify="end">
                    <i nz-icon nzType="plus"></i>Add New City </button>
            </nz-form-item>

        </form>
    </div>
</div>

<br>
<div nz-row>
    <div nz-col nzSpan="24">
        <nz-table class="my-scroll no-wrap1" nzBordered #datatable nzShowSizeChanger [nzFrontPagination]="false"
            [nzData]="dataList" [nzLoading]="loadingRecords" [nzSize]="'small'" [nzScroll]="{ x: '800px', y: '420px' }"
            [nzTotal]="totalRecords" [(nzPageIndex)]="pageIndex" [(nzPageSize)]="pageSize"
            (nzPageIndexChange)="search()" (nzPageSizeChange)="search(true)" (nzQueryParams)="sort($event)">
            <thead>
                <tr>
                    <th nzWidth="20px" style="font-weight: bold; text-align: center;">Action</th>
                    <!-- <th nzWidth="20px" style="font-weight: bold; text-align: center;" [nzSortFn]="true">Seq No.</th> -->

                    <th nzWidth="50px" style="font-weight: bold; text-align: center;" nzColumnKey="{{ 'NAME' }}"  [nzSortFn]="true">Name</th>

                    <th nzWidth="20px" style="font-weight: bold; text-align: center;" nzColumnKey="{{ 'STATUS' }}"  [nzSortFn]="true">Status</th>


                </tr>
            </thead>
            <tbody style="text-align: center">
                <tr *ngFor="let data of datatable.data" style="text-align: center;">
                    <td style="text-align: center;"><a (click)="edit(data)"><i nz-icon nzType="edit"
                                nzTheme="outline"></i></a></td>
                    <!-- <td style="text-align: center;white-space: pre-line;">{{data['SEQUENCE_NUMBER']}}</td> -->

                    <td style="text-align: left;white-space: pre-line; ">{{data['NAME']}}</td>

                    <!-- <td style="text-align: center;white-space: pre-line;">{{data['STATUS']}}</td> -->

                    <td style="text-align: center;white-space: pre-line;">
                        <i nz-icon *ngIf="data['STATUS']" [nzType]="'check-circle'" [nzTheme]="'twotone'"
                            [nzTwotoneColor]="'#52c41a'"></i>
                        <i nz-icon *ngIf="!data['STATUS']" [nzType]="'close-circle'" [nzTheme]="'twotone'"
                            [nzTwotoneColor]="'#f50'"></i>
                    </td>
                </tr>
            </tbody>
        </nz-table>
    </div>


    <!-- <nz-drawer [nzBodyStyle]="{ height: 'calc(100% - 55px)', overflow: 'auto', 'padding-bottom': '53px' }"
        [nzClosable]="true" [nzMask]="true" [nzMaskClosable]="false" [nzWidth]="450" [nzVisible]="drawerVisible"
        [nzTitle]="drawerTitle" (nzOnClose)="drawerClose()" (nzKeyboard)="true">
        <ng-container *nzDrawerContent>
            <app-city-drawer [drawerClose]="closeCallback" [drawerVisible]="drawerVisible"
                [data]="drawerData"></app-city-drawer>
        </ng-container>
    </nz-drawer> -->

    <nz-drawer [nzBodyStyle]="{ height: 'calc(100% - 55px)', overflow: 'auto', 'padding-bottom': '53px' }"
        [nzClosable]="false" [nzMask]="true" [nzMaskClosable]="false" [nzWidth]="450" [nzVisible]="drawerVisible"
        [nzTitle]="drawerTitle" (nzOnClose)="drawerClose()" (nzKeyboard)="true">
        <ng-container *nzDrawerContent>
            <app-city-drawer [drawerClose]="closeCallback" [drawerVisible]="drawerVisible"
                [data]="drawerData"></app-city-drawer>
        </ng-container>
    </nz-drawer>
</div>