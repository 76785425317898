<div nz-row nzType="flex">
  <div nz-col nzSpan="8">
    <div class="formname">{{ formTitle }}</div>
  </div>
  <div nz-col nzSpan="16" style="display: flex; justify-content: flex-end">
    <form nz-form [nzLayout]="'inline'">
      <nz-form-item>
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
          <input
            type="text"
            autocomplete="off"
            name="search"
            (keydown.enter)="onKeypressEvent($event)"
            nz-input
            placeholder="Search Records"
            [(ngModel)]="searchText"
          />
        </nz-input-group>
        <ng-template #suffixIconButton>
          <button
            nz-button
            nzType="primary"
            id="button"
            (click)="search()"
            nzSearch
          >
            <i nz-icon nzType="search"></i>
          </button>
        </ng-template>
      </nz-form-item>
      <nz-form-item>
        <button nz-button nzType="primary" (click)="AddTitle()" nzJustify="end">
          <i nz-icon nzType="plus"></i>New Investigation Category
        </button>
      </nz-form-item>
    </form>
  </div>
</div>

<div nz-row>
  <div nz-col nzSpan="24">
    <nz-table
      class="my-scroll no-wrap1"
      nzBordered
      #table
      nzShowSizeChanger
      [nzFrontPagination]="false"
      [nzData]="dataList"
      [nzLoading]="loadingRecords"
      [nzSize]="'small'"
      [nzScroll]="{ x: '900px', y: '420px' }"
      [nzTotal]="totalRecords"
      [(nzPageIndex)]="pageIndex"
      [(nzPageSize)]="pageSize"
      (nzPageIndexChange)="search()"
      (nzPageSizeChange)="search(true)"
      (nzQueryParams)="sort($event)"
    >
      <thead>
        <tr>
          <th nzWidth="10px" style="text-align: center">Edit</th>

          <th
            nzWidth="50px"
            style="text-align: left"
            [nzSortFn]="true"
            nzColumnKey="{{ 'NAME' }}"
          >
            Category Name
          </th>
          <th
            nzWidth="20px"
            style="text-align: center"
            nzColumnKey="{{ 'STATUS' }}"
            [nzSortFn]="true"
          >
            Status
          </th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let data of table.data">
          <td style="text-align: center">
            <a (click)="edit(data)"
              ><i nz-icon nzType="edit" nzTheme="outline"></i
            ></a>
          </td>

          <td style="text-align: left; white-space: pre-line">
            {{ data.NAME }}
          </td>

          <td style="text-align: center">
            <i
              nz-icon
              *ngIf="data.STATUS"
              [nzType]="'check-circle'"
              [nzTheme]="'twotone'"
              [nzTwotoneColor]="'#52c41a'"
            ></i>

            <i
              nz-icon
              *ngIf="!data.STATUS"
              [nzType]="'close-circle'"
              [nzTheme]="'twotone'"
              [nzTwotoneColor]="'#f50'"
            ></i>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>

<div *ngIf="Visible">
  <nz-drawer
    [nzBodyStyle]="{
      height: 'calc(100% - 55px)',
      overflow: 'auto',
      'padding-bottom': '53px'
    }"
    [nzClosable]="false"
    [nzMask]="true"
    [nzMaskClosable]="false"
    [nzWidth]="getwidth()"
    [nzVisible]="Visible"
    [nzTitle]="drawerTitle"
    (nzOnClose)="closeDrawer()"
    (nzKeyboard)="(true)"
  >
    <app-investigation-drawer
      [drawerClose]="closeDrawercallback"
      [drawerVisible]="Visible"
      [data]="drawerData"
    >
    </app-investigation-drawer>
  </nz-drawer>
</div>
