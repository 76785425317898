<div nz-row nzType="flex">
    <div nz-col nzXs="24" nzSm="24" nzMd="20" nzLg="20" nzXl="12">
        <div class="formname"> <b> {{formTitle}} </b></div>
    </div>

    <div nz-col nzXs="24" nzSm="24" nzMd="4" nzLg="6" nzXl="12"
        style="display: flex;justify-content: flex-end">
        <form nz-form [nzLayout]="'inline'">
            <nz-form-item>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                    <input type="text" name="postsearch"
                        (keyup)="keyup($event)"
                        (keydown.enter)="onKeypressEvent($event)"
                        [(ngModel)]="searchText" nz-input
                        placeholder="Search Record" />
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" id="button"
                        (click)="search(true)"
                        nzSearch><i nz-icon
                            nzType="search"></i></button>
                </ng-template>
            </nz-form-item>
            <nz-form-item>

            </nz-form-item>

        </form>
    </div>
</div>

<br>
<div nz-row>
    <div nz-col nzSpan="24">
        <nz-table
            class="my-scroll no-wrap1"
            #basicTable
            nzShowSizeChanger
            [nzData]="dataList"
            [(nzPageIndex)]="pageIndex"
            [nzFrontPagination]="false"
            [nzLoading]="loadingRecords"
            [nzTotal]="totalRecords"
            [(nzPageSize)]="pageSize"
            (nzPageIndexChange)="search()"
            (nzPageSizeChange)="search(true)"
            (nzQueryParams)="sort($event)"
            [nzScroll]="{ x: '3350px', y: '420px' }"
            nzBordered>
            <thead (nzSortChange)="sort($event)" nzSingleSort>
                <tr>
                    <th style="font-weight: bold; text-align: center"
                        nzWidth="100px">Action</th>
                    <th
                        nzWidth="250px"
                        style="font-weight: bold; text-align: center"
                        nzColumnKey="NAME"
                        [nzSortFn]="true">
                        Applicant Name
                    </th>

                    <th
                        nzWidth="110px"
                        style="font-weight: bold; text-align: center"
                        nzColumnKey="MOBILE_NO"
                        [nzSortFn]="true">
                        Mobile No.
                    </th>
                    <th
                        nzWidth="150px"
                        style="font-weight: bold; text-align: center"
                        nzColumnKey="EMPLOYEE_CODE"
                        [nzSortFn]="true">
                        Employee Code
                    </th>
                    <th
                        nzWidth="270px"
                        style="font-weight: bold; text-align: center"
                        nzColumnKey="DESIGNATION"
                        [nzSortFn]="true">
                        Employee Designation
                    </th>

                    <th
                        nzWidth="170px"
                        style="font-weight: bold; text-align: center"
                        nzColumnKey="DDO_OF_THE_OFFICIAL"
                        [nzSortFn]="true">
                        DDO official
                    </th>
                    <th
                        nzWidth="220px"
                        style="font-weight: bold; text-align: center"
                        nzColumnKey="OFFICE_NAME"
                        [nzSortFn]="true">
                        Office Name
                    </th>
                    <th
                        nzWidth="250px"
                        style="font-weight: bold; text-align: center"
                        nzColumnKey="ADDRESS"
                        [nzSortFn]="true">
                        Old Residential Address
                    </th>
                    <th
                        nzWidth="220px"
                        style="font-weight: bold; text-align: center"
                        nzColumnKey="NEW_HEADQUARTERS_NAME"
                        [nzSortFn]="true">
                        New Head Quater Name
                    </th>
                    <th
                        nzWidth="250px"
                        style="font-weight: bold; text-align: center"
                        nzColumnKey="NEW_ADDRESS"
                        [nzSortFn]="true">
                        New Residential Address
                    </th>

                    <th
                        nzWidth="170px"
                        style="font-weight: bold; text-align: center"
                        nzColumnKey="NAME_OF_FAMILY_MEMBER"
                        [nzSortFn]="true">
                        Name of Family Member
                    </th>
                    <th
                        nzWidth="80px"
                        style="font-weight: bold; text-align: center"
                        nzColumnKey="AGE"
                        [nzSortFn]="true">
                        Age
                    </th>
                    <th
                        nzWidth="190px"
                        style="font-weight: bold; text-align: center"
                        nzColumnKey="RELATIONSHIP"
                        [nzSortFn]="true">
                        Relation with Patient
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let data of basicTable.data; let i = index">
                    <td style="text-align: center; white-space: pre-line"
                        nzWidth="100px">
                        <div style="padding: 3px">
                            <span (click)="update(data)">
                                <nz-tag
                                    style="
                                  padding: 3px;
                                  border: 1px blue solid;
                                  border-radius: 0px;
                                "
                                    [nzColor]="'blue'">
                                    Pick Claim
                                </nz-tag>
                            </span>

                        </div>
                    </td>

                    <td style="text-align: center; white-space: pre-line"
                        nzWidth="250px">
                        {{ data.NAME }}
                    </td>

                    <td style="text-align: center; white-space: pre-line"
                        nzWidth="110px">
                        {{ data.MOBILE_NO }}
                    </td>
                    <td style="text-align: center; white-space: pre-line"
                        nzWidth="150px">
                        {{ data.EMPLOYEE_CODE }}
                    </td>
                    <td style="text-align: center; white-space: pre-line"
                        nzWidth="270px">
                        {{ data.DESIGNATION }}
                    </td>

                    <td style="text-align: center; white-space: pre-line"
                        nzWidth="170px">
                        {{ data.DDO_OF_THE_OFFICIAL }}
                    </td>
                    <td style="text-align: center; white-space: pre-line"
                        nzWidth="220px">
                        {{ data.OFFICE_NAME }}
                    </td>
                    <td style="text-align: center; white-space: pre-line"
                        nzWidth="150px">
                        {{ data.ADDRESS }}
                    </td>
                    <td style="text-align: center; white-space: pre-line"
                        nzWidth="150px">
                        {{ data.NEW_HEADQUARTERS_NAME }}
                    </td>
                    <td style="text-align: center; white-space: pre-line"
                        nzWidth="150px">
                        {{ data.NEW_ADDRESS }}
                    </td>
                    <td style="text-align: center; white-space: pre-line"
                        nzWidth="190px">
                        {{ data.NAME_OF_FAMILY_MEMBER }}
                    </td>
                    <td style="text-align: center; white-space: pre-line"
                        nzWidth="190px">
                        {{ data.AGE }}
                    </td>
                    <td style="text-align: center; white-space: pre-line"
                        nzWidth="190px">
                        {{ data.RELATIONSHIP }}
                    </td>
                </tr>
            </tbody>
        </nz-table>
    </div>

</div>