<div *ngIf="LTC2data != undefined">
  <form nz-form>
    <nz-spin [nzSpinning]="loadingRecords">
      <div nz-row [nzGutter]="10">
        <div nz-col nzXs="8" nzSm="8" nzMd="8" nzLg="8" nzXl="8" nzSpan="8">
          <div class="my-box" style="height: 500px">
            <!-- <div nz-col nzSpan="24">
              <nz-form-item>
                <nz-form-label nzNoColon>Signature</nz-form-label>
                <nz-form-control>
                  <nz-select
                    name="userid"
                    [(ngModel)]="LTC2data.SIGNATURE_ID"
                    nzAllowClear
                    nzPlaceHolder="Choose Signature"
                    (ngModelChange)="signature($event)"
                  >
                    <nz-option
                      *ngFor="let user of Signaturearray"
                      [nzValue]="user.ID"
                      [nzLabel]="user.NAME"
                    >
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div> -->
            <!-- <div nz-col nzSpan="24">
              <nz-form-item>
                <nz-form-label nzNoColon>Destination</nz-form-label>

                <nz-input-group>
                  <input
                    type="text"
                    autocomplete="off"
                    name="Destination"
                    nz-input
                    placeholder="Enter Destination"
                    [(ngModel)]="LTC2data.DESTINATION"
                  />
                </nz-input-group>
              </nz-form-item>
            </div> -->
            <div nz-col nzSpan="24">
              <nz-form-item>
                <nz-form-label nzNoColon>Amount Details</nz-form-label>

                <nz-input-group>
                  <textarea
                    type="text"
                    autocomplete="off"
                    name="Amount Details"
                    nz-input
                    maxlength="240"
                    placeholder="Enter Amount Details"
                    [(ngModel)]="LTC2data.AMOUNT_DETAILS"
                  ></textarea>
                  <h6
                    style="color: red"
                    *ngIf="LTC2data.AMOUNT_DETAILS != undefined"
                  >
                    {{ LTC2data.AMOUNT_DETAILS.length }}/240
                  </h6>
                </nz-input-group>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="24">
              <nz-form-item>
                <nz-form-label nzNoColon>Amount</nz-form-label>

                <nz-input-group nzAddOnBefore="₹">
                  <input
                    type="text"
                    autocomplete="off"
                    name="Amount"
                    nz-input
                    (ngModelChange)="changeamountt($event)"
                    (keypress)="omit($event)"
                    placeholder="Enter Amount"
                    [(ngModel)]="LTC2data.AMOUNT"
                  />
                </nz-input-group>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="24">
              <nz-form-item>
                <nz-form-label nzNoColon>Family</nz-form-label>

                <nz-input-group>
                  <input
                    type="text"
                    autocomplete="off"
                    name="Family"
                    nz-input
                    placeholder="Enter Family"
                    [(ngModel)]="LTC2data.FAMILY"
                  />
                </nz-input-group>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="24">
              <nz-form-item>
                <nz-form-label nzNoColon>Entry Made By</nz-form-label>

                <nz-input-group>
                  <input
                    type="text"
                    autocomplete="off"
                    name="Entry Made By"
                    nz-input
                    placeholder="Enter Entry Made By"
                    [(ngModel)]="LTC2data.ENTRY_MADE_BY"
                  />
                </nz-input-group>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="24">
              <nz-form-item>
                <nz-form-label nzNoColon
                  >Is Destination Editable ?</nz-form-label
                >
                <nz-form-control>
                  <nz-switch
                    name="IS_DESTINATION_EDIT"
                    nzCheckedChildren="Yes"
                    nzUnCheckedChildren="No"
                    [(ngModel)]="LTC2data.IS_DESTINATION_EDIT"
                    (ngModelChange)="clearDesignation($event)"
                  ></nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="24" *ngIf="LTC2data.IS_DESTINATION_EDIT == 1">
              <nz-form-item>
                <nz-form-label nzNoColon>Destination</nz-form-label>

                <nz-input-group>
                  <input
                    type="text"
                    autocomplete="off"
                    name="DestinationDestination"
                    nz-input
                    placeholder="Enter Destination"
                    [(ngModel)]="LTC2data.DESTIONATION_REMARK"
                  />
                </nz-input-group>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="24">
              <nz-form-item>
                <nz-form-label nzNoColon>Enter Year Type</nz-form-label>

                <nz-input-group>
                  <input
                    type="text"
                    autocomplete="off"
                    name="PART_B_YEAR"
                    nz-input
                    placeholder="Enter Year Type"
                    [(ngModel)]="LTC2data.PART_B_YEAR"
                  />
                </nz-input-group>
              </nz-form-item>
            </div>
          </div>
        </div>

        <div nz-col nzXs="16" nzSm="16" nzMd="16" nzLg="16" nzXl="16">
          <div
            class="my-box"
            style="padding: 10px; height: 590px; overflow-y: scroll"
          >
            <div>
              <div
                style="font-family: 'Times New Roman', Times, serif !important"
              >
                <div
                  style="
                    text-align: center;
                    margin-top: 20px;
                    margin-bottom: 10px;
                    font-size: 13px;
                  "
                ></div>
                <div style="font-size: 13px !important">
                  <!-- <div style="text-align: center;font-weight: bold;"><h2 >
                    पार्ट बी
                  </h2></div> -->
                  <div style="text-align: center; font-weight: bold">
                    <h2 style="font-weight: bold">PART- B</h2>
                  </div>
                  <div
                    nz-row
                    [nzGutter]="10"
                    style="margin-top: 15px; margin-bottom: 28px"
                  >
                    <div nz-col nzSpan="20">
                      <table style="border: none !important; width: 100%">
                        <tr colspan="3" style="border: none !important">
                          <td style="text-align: left; border: none !important">
                            The net entitlement on account of leave travel
                            concession works out to
                          </td>
                        </tr>
                        <tr style="border: none !important">
                          <td style="border: none !important">
                            A)&nbsp;&nbsp;&nbsp;&nbsp; Railway/Air/Bus/Steamer
                            Fare /SCPLTC
                          </td>
                          <td style="border: none !important; width: 50px">
                            ₹
                          </td>
                          <td
                            style="border: none !important; text-align: center"
                          >
                            <b> {{ amount }}/- </b>
                          </td>
                        </tr>
                        <tr>
                          <td style="text-align: left; border: none !important">
                            B)&nbsp;&nbsp;&nbsp;&nbsp; Less: Amount of advance
                            drawn
                          </td>
                          <td style="border: none !important; width: 50px">
                            ₹
                          </td>
                          <td
                            style="border: none !important; text-align: center"
                          >
                            <b> {{ LTC2data.AMOUNT_OF_ADVANCE }}/- </b>
                          </td>
                        </tr>
                        <tr
                          style="border: none !important"
                          *ngIf="
                            LTC2data.AMOUNT_DETAILS != undefined &&
                            LTC2data.AMOUNT_DETAILS != ''
                          "
                        >
                          <td style="text-align: left; border: none !important">
                            C)&nbsp;&nbsp;&nbsp;&nbsp;
                            {{ LTC2data.AMOUNT_DETAILS }}
                          </td>
                          <td style="border: none !important; width: 50px">
                            ₹
                          </td>
                          <td
                            style="border: none !important; text-align: center"
                          >
                            <b> {{ LTC2data.AMOUNT }} </b>
                          </td>
                        </tr>
                        <tr style="border: none !important">
                          <td style="text-align: left; border: none !important">
                            NET PAY AMOUNT
                          </td>
                          <td>₹</td>
                          <td
                            style="border: none !important; text-align: center"
                          >
                            <span *ngIf="nnamount == 0"
                              ><b>{{ namount }}/- </b></span
                            >
                            <span *ngIf="nnamount != 0"
                              ><b>{{ nnamount }}/- </b></span
                            >
                          </td>
                        </tr>
                        <tr>
                          <td
                            colspan="3"
                            style="text-align: left; border: none !important"
                          >
                            The expenditure is debitable to LTC Head
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div nz-col nzSpan="4"></div>
                  </div>
                  <!-- <p>The expenditure is debitable to LTC Head</p> -->
                  <div
                    nz-row
                    [nzGutter]="10"
                    style="margin-top: 15px; margin-bottom: 20px"
                  >
                    <div
                      nz-col
                      nzXs="12"
                      nzSm="12"
                      nzMd="12"
                      nzLg="12"
                      nzXl="12"
                      nzSpan="12"
                      style="text-align: left"
                    ></div>
                    <div
                      nz-col
                      nzXs="12"
                      nzSm="12"
                      nzMd="12"
                      nzLg="12"
                      nzXl="12"
                      nzSpan="12"
                      style="text-align: center"
                    >
                      <span style="text-align: center">
                        <div>(<b>जितेंद्र रा. चांदेकर)</b></div>
                        <div>
                          <b style="text-transform: uppercase">
                            (Jitendra R Chandekar)
                          </b>
                        </div>
                        <div>आयकर अधिकारी</div>
                        <div>
                          <b> Income Tax Officer </b>
                        </div>
                        <div>राजपत्रित वेतन बिल कक्ष - २, मुंबई</div>
                        <div>
                          <b> Gazetted Pay Bill - II, Mumbai </b>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div
                    nz-row
                    [nzGutter]="10"
                    style="margin-top: 15px; margin-bottom: 20px"
                  >
                    <div
                      nz-col
                      nzXs="10"
                      nzSm="10"
                      nzMd="10"
                      nzLg="10"
                      nzXl="10"
                      nzSpan="10"
                      style="text-align: center"
                    >
                      <span style="text-align: center">
                        <b> (मुदित श्रीवास्तव, भा.रा.से.) </b><br />
                        <b> (MUDIT SRIVASTAVA, I.R.S.)</b><br />
                        अपर आयकर आयुक्त (मुख्या.) कार्मिक, मुंबई <br />
                        <b>Addl. Commissioner of Income Tax (HQ)</b> <br />
                        <b> Personnel, Mumbai </b>
                      </span>
                    </div>
                    <div
                      nz-col
                      nzXs="2"
                      nzSm="2"
                      nzMd="2"
                      nzLg="2"
                      nzXl="2"
                      nzSpan="2"
                    ></div>
                    <div
                      nz-col
                      nzXs="12"
                      nzSm="12"
                      nzMd="12"
                      nzLg="12"
                      nzXl="12"
                      nzSpan="12"
                      style="text-align: center"
                    ></div>
                  </div>
                  <div
                    nz-row
                    [nzGutter]="10"
                    style="margin-top: 15px; margin-bottom: 20px"
                  >
                    <div
                      nz-col
                      nzXs="12"
                      nzSm="12"
                      nzMd="12"
                      nzLg="12"
                      nzXl="12"
                      nzSpan="12"
                      style="text-align: center"
                    ></div>
                    <div
                      nz-col
                      nzXs="12"
                      nzSm="12"
                      nzMd="12"
                      nzLg="12"
                      nzXl="12"
                      nzSpan="12"
                      style="text-align: center"
                    >
                      <span style="text-align: center">
                        <b> (विनय सिन्हा, भा.रा.से.)</b><br />
                        <b> (VINAY SINHA, I.R.S.) </b><br />
                        आयकर आयुक्त प्रशासन एव करदाता सेवाएँ, मुंबई <br />
                        <b> Commissioner of Income Tax </b><br />
                        <b> Admn And TPS Mumbai </b><br />
                      </span>
                    </div>
                  </div>
                  <p>
                    Certified that necessary entries have been made in the
                    service book of
                    <span
                      >{{ LTC2data.SALUTATION }} {{ LTC2data.EMPLOYEE_NAME }},
                      {{ LTC2data.DESIGNATION }} Pay level=
                      {{ getlevel(LTC2data.GRADE_PAY_LEVEL) }}</span
                    >, for the {{ LTC2data.PART_B_YEAR }}
                    <b>{{ LTC2data.BLOCK_YEAR }}</b>
                  </p>
                  <div
                    nz-row
                    [nzGutter]="10"
                    style="margin-top: 20px; margin-bottom: 20px"
                  >
                    <div
                      nz-col
                      nzXs="24"
                      nzSm="24"
                      nzMd="24"
                      nzLg="24"
                      nzXl="24"
                      nzSpan="24"
                      style="text-align: left"
                    >
                      <div nz-row [nzGutter]="10">
                        <div
                          nz-col
                          nzXs="6"
                          nzSm="6"
                          nzMd="6"
                          nzLg="6"
                          nzXl="6"
                          nzSpan="6"
                        >
                          <p>Destination</p>
                          <p>Family</p>
                          <p>Entry Made By</p>
                          <p>Checked by</p>
                        </div>
                        <div
                          nz-col
                          nzXs="18"
                          nzSm="18"
                          nzMd="18"
                          nzLg="18"
                          nzXl="18"
                          nzSpan="18"
                        >
                          <p *ngIf="LTC2data.IS_DESTINATION_EDIT == 1">
                            {{ LTC2data.DESTIONATION_REMARK }}
                          </p>
                          <p
                            *ngIf="
                              destinationList.length > 0 &&
                              LTC2data.IS_DESTINATION_EDIT == 0
                            "
                          >
                            <span *ngIf="destinationList.length > 0">
                              <ng-container
                                *ngFor="
                                  let item of destinationList;
                                  let last = last;
                                  let i = index
                                "
                              >
                                {{ item }}
                                <ng-container
                                  *ngIf="destinationList.length > 1 && !last"
                                >
                                  <ng-container
                                    *ngIf="
                                      destinationList.length === 2 && i === 0
                                    "
                                  >
                                    and
                                  </ng-container>
                                  <ng-container
                                    *ngIf="
                                      destinationList.length > 2 &&
                                      i < destinationList.length - 2
                                    "
                                    >,
                                  </ng-container>
                                  <ng-container
                                    *ngIf="
                                      destinationList.length > 2 &&
                                      i === destinationList.length - 2
                                    "
                                  >
                                    and
                                  </ng-container>
                                </ng-container>
                              </ng-container>
                            </span>
                            <!-- <span
                              *ngFor="
                                let data of destinationList;
                                let k = index
                              "
                            >
                              <span>{{ data }}</span>

                              <span *ngIf="destinationList.length > 1">
                                <span *ngIf="destinationList.length - 2 == k">
                                  ,
                                </span>
                              </span>
                            </span> -->
                            only
                          </p>
                          <p *ngIf="destinationList.length <= 0"></p>
                          <p>{{ LTC2data.FAMILY }}</p>
                          <!-- <p>{{ LTC2data.FAMILY }}</p> -->
                          <p>
                            {{ LTC2data.ENTRY_MADE_BY }}
                            <!-- <span *ngIf="FamilyList.length > 0">Yes</span>
                            <span *ngIf="FamilyList.length <= 0">No</span> -->
                          </p>
                          <!-- <p>{{ LTC2data.ENTRY_MADE_BY }}</p> -->
                          <p></p>
                        </div>
                      </div>
                    </div>
                    <!-- <div
                      nz-col
                      nzXs="14"
                      nzSm="14"
                      nzMd="14"
                      nzLg="14"
                      nzXl="14"
                      nzSpan="14"
                      style="text-align: right"
                    ></div> -->
                  </div>
                  <div
                    nz-row
                    [nzGutter]="10"
                    style="margin-top: 20px; margin-bottom: 20px"
                  >
                    <div
                      nz-col
                      nzXs="12"
                      nzSm="12"
                      nzMd="12"
                      nzLg="12"
                      nzXl="12"
                      nzSpan="12"
                      style="text-align: center"
                    ></div>
                    <div
                      nz-col
                      nzXs="12"
                      nzSm="12"
                      nzMd="12"
                      nzLg="12"
                      nzXl="12"
                      nzSpan="12"
                      style="text-align: center"
                    >
                      <span style="text-align: center">
                        आयकर अधिकारी <br />
                        <b>Income Tax Officer </b><br />
                        राजपत्रित सेवा पुस्तिका कक्ष, मुंबई <br />
                        <b>Gaz Service Book Cell, Mumbai </b><br />
                      </span>
                    </div>
                  </div>

                  <p>
                    <b>CERTIFICATE TO BE GIVEN BY THE CONTROLLING OFFICER</b>
                  </p>
                  <p>Certified-</p>
                  <p style="padding-left: 50px">
                    <b>A)</b> That of
                    <b
                      >{{ LTC2data.SALUTATION }} {{ LTC2data.EMPLOYEE_NAME }},
                      {{ LTC2data.DESIGNATION }},</b
                    >
                    as rendered continuous service for one year or more on the
                    date of commencing the outward journey.<br />
                    B) That necessary entry as required under para 3 of the
                    ministry of home affairs O.M. No. 43/1/55-Ests. (A) Part II,
                    dated 11th October, 1956 have been made in the service book
                    of
                    <b
                      >{{ LTC2data.SALUTATION }} {{ LTC2data.EMPLOYEE_NAME }},
                      {{ LTC2data.DESIGNATION }}.</b
                    >
                  </p>
                  <div
                    nz-row
                    [nzGutter]="10"
                    style="margin-top: 50px; margin-bottom: 20px"
                  >
                    <div
                      nz-col
                      nzXs="12"
                      nzSm="12"
                      nzMd="12"
                      nzLg="12"
                      nzXl="12"
                      nzSpan="12"
                      style="text-align: center"
                    ></div>
                    <div
                      nz-col
                      nzXs="12"
                      nzSm="12"
                      nzMd="12"
                      nzLg="12"
                      nzXl="12"
                      nzSpan="12"
                      style="text-align: center"
                    >
                      <span style="text-align: center">
                        <b> (मुदित श्रीवास्तव, भा.रा.से.) </b><br />
                        <b> (MUDIT SRIVASTAVA, I.R.S.)</b><br />
                        अपर आयकर आयुक्त (मुख्या.) कार्मिक, मुंबई <br />
                        <b> Addl. Commissioner of Income Tax (HQ) </b><br />
                        <b> Personnel, Mumbai</b>
                      </span>
                      <!-- <b style="text-align: center">(MAHESH S SHINGATE)<br />
                        Joint/Addl Commissioner of Income Tax<br />
                        HQ Personnel Mumbai
                      </b> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>
  </form>

  <div class="footer">
    <button
      type="button"
      nz-button
      class="ant-btn"
      style="margin-right: 8px"
      (click)="close()"
    >
      <span>Cancel</span>
    </button>

    <button
      type="button"
      class="ant-btn ant-btn-primary"
      nz-button
      (click)="Save()"
      [nzLoading]="loadingRecords"
    >
      <span>Save & Preview</span>
    </button>
  </div>
</div>

<nz-modal
  [nzWidth]="getwidth()"
  [(nzVisible)]="printOrderModalVisible"
  nzMaskClosable="false"
  nzClosable="false"
  [nzContent]="printOrderModalContent"
  [nzTitle]="'PART B'"
  (nzOnCancel)="printOrderModalCancel()"
  [nzFooter]="printOrderModalFooter"
>
  <ng-template #printOrderModalContent>
    <nz-spin [nzSpinning]="loadingRecords">
      <div style="height: 445px; overflow: auto">
        <div id="printOrderModal1">
          <div style="font-family: 'Times New Roman', Times, serif !important">
            <div
              style="
                text-align: center;
                margin-top: 5px;
                margin-bottom: 5px;
                font-size: 14px;
              "
            ></div>
            <div style="font-size: 14px !important">
              <!-- <div style="text-align: center;font-weight: bold;margin-bottom: 0px !important;"><h3 style="margin-bottom: 0px !important;">
                    पार्ट बी
                  </h3></div> -->
              <div
                style="
                  text-align: center;
                  font-weight: bold;
                  margin-bottom: 0px !important;
                "
              >
                <h3 style="margin-bottom: 0px !important; font-weight: bold">
                  PART- B
                </h3>
              </div>
              <div
                nz-row
                [nzGutter]="10"
                style="margin-top: 5px; margin-bottom: 10px"
              >
                <div nz-col nzSpan="20">
                  <table style="border: none !important; width: 100%">
                    <tr colspan="3" style="border: none !important">
                      <td style="text-align: left; border: none !important">
                        The net entitlement on account of leave travel
                        concession works out to
                      </td>
                    </tr>
                    <tr style="border: none !important">
                      <td style="border: none !important">
                        A)&nbsp;&nbsp;&nbsp;&nbsp; Railway/Air/Bus/Steamer Fare
                        /SCPLTC
                      </td>
                      <td style="border: none !important; width: 50px">₹</td>
                      <td style="border: none !important; text-align: center">
                        <b> {{ amount }}/- </b>
                      </td>
                    </tr>
                    <tr>
                      <td style="text-align: left; border: none !important">
                        B)&nbsp;&nbsp;&nbsp;&nbsp; Less: Amount of advance drawn
                      </td>
                      <td style="border: none !important; width: 50px">₹</td>
                      <td style="border: none !important; text-align: center">
                        <b> {{ LTC2data.AMOUNT_OF_ADVANCE }}/- </b>
                      </td>
                    </tr>
                    <tr
                      style="border: none !important"
                      *ngIf="
                        LTC2data.AMOUNT_DETAILS != undefined &&
                        LTC2data.AMOUNT_DETAILS != ''
                      "
                    >
                      <td style="text-align: left; border: none !important">
                        C)&nbsp;&nbsp;&nbsp;&nbsp; {{ LTC2data.AMOUNT_DETAILS }}
                      </td>
                      <td style="border: none !important; width: 50px">₹</td>
                      <td style="border: none !important; text-align: center">
                        <b> {{ LTC2data.AMOUNT }} </b>
                      </td>
                    </tr>
                    <tr style="border: none !important">
                      <td style="text-align: left; border: none !important">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;NET PAY
                        AMOUNT
                      </td>
                      <td>₹</td>
                      <td style="border: none !important; text-align: center">
                        <span *ngIf="nnamount == 0"
                          ><b>{{ namount }}/- </b></span
                        >
                        <span *ngIf="nnamount != 0"
                          ><b>{{ nnamount }}/- </b></span
                        >
                      </td>
                    </tr>
                    <tr>
                      <td
                        colspan="3"
                        style="text-align: left; border: none !important"
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The
                        expenditure is debitable to LTC Head
                      </td>
                    </tr>
                  </table>
                </div>
                <div nz-col nzSpan="4"></div>
              </div>
              <div
                nz-row
                [nzGutter]="10"
                style="margin-top: 30px; margin-bottom: 0px"
              >
                <div
                  nz-col
                  nzXs="12"
                  nzSm="12"
                  nzMd="12"
                  nzLg="12"
                  nzXl="12"
                  nzSpan="12"
                  style="text-align: left"
                ></div>
                <div
                  nz-col
                  nzXs="12"
                  nzSm="12"
                  nzMd="12"
                  nzLg="12"
                  nzXl="12"
                  nzSpan="12"
                  style="text-align: center"
                >
                  <span style="text-align: center">
                    <div><B>जितेंद्र रा. चांदेकर</B></div>
                    <div>
                      <b style="text-transform: uppercase">
                        (Jitendra R Chandekar)
                      </b>
                    </div>
                    <div>आयकर अधिकारी</div>
                    <div>
                      <b> Income Tax Officer </b>
                    </div>
                    <div>राजपत्रित वेतन बिल कक्ष - २, मुंबई</div>
                    <div>
                      <b> Gazetted Pay Bill - II, Mumbai </b>
                    </div>
                  </span>
                </div>
              </div>
              <div
                nz-row
                [nzGutter]="10"
                style="margin-top: 0px; margin-bottom: 0px"
              >
                <div
                  nz-col
                  nzXs="10"
                  nzSm="10"
                  nzMd="10"
                  nzLg="10"
                  nzXl="10"
                  nzSpan="10"
                  style="text-align: center"
                >
                  <span style="text-align: center">
                    <b> (मुदित श्रीवास्तव, भा.रा.से.) </b><br />
                    <b> (MUDIT SRIVASTAVA, I.R.S.)</b><br />
                    अपर आयकर आयुक्त (मुख्या.) कार्मिक, मुंबई <br />
                    <b> Addl. Commissioner of Income Tax (HQ)</b> <br />
                    <b> Personnel, Mumbai</b>
                  </span>
                </div>
                <div
                  nz-col
                  nzXs="2"
                  nzSm="2"
                  nzMd="2"
                  nzLg="2"
                  nzXl="2"
                  nzSpan="2"
                ></div>
                <div
                  nz-col
                  nzXs="12"
                  nzSm="12"
                  nzMd="12"
                  nzLg="12"
                  nzXl="12"
                  nzSpan="12"
                  style="text-align: center"
                ></div>
              </div>
              <div
                nz-row
                [nzGutter]="10"
                style="margin-top: 0px; margin-bottom: 5px"
              >
                <div
                  nz-col
                  nzXs="12"
                  nzSm="12"
                  nzMd="12"
                  nzLg="12"
                  nzXl="12"
                  nzSpan="12"
                  style="text-align: center"
                ></div>
                <div
                  nz-col
                  nzXs="12"
                  nzSm="12"
                  nzMd="12"
                  nzLg="12"
                  nzXl="12"
                  nzSpan="12"
                  style="text-align: center"
                >
                  <span style="text-align: center">
                    <B>(विनय सिन्हा, भा.रा.से.)</B><br />
                    <b> (VINAY SINHA, I.R.S.) </b><br />
                    आयकर आयुक्त प्रशासन एव करदाता सेवाएँ, मुंबई <br />
                    <b> Commissioner of Income Tax </b><br />
                    <b> Admn And TPS Mumbai </b><br />
                  </span>
                </div>
              </div>
              <p style="margin-bottom: 0px !important">
                Certified that necessary entries have been made in the service
                book of
                <span
                  >{{ LTC2data.SALUTATION }} {{ LTC2data.EMPLOYEE_NAME }},
                  {{ LTC2data.DESIGNATION }} Pay level=
                  {{ getlevel(LTC2data.GRADE_PAY_LEVEL) }}</span
                >, for the {{ LTC2data.PART_B_YEAR }}
                <b>{{ LTC2data.BLOCK_YEAR }}</b>
              </p>
              <div
                nz-row
                [nzGutter]="10"
                style="margin-top: 5px; margin-bottom: 5px"
              >
                <div
                  nz-col
                  nzXs="24"
                  nzSm="24"
                  nzMd="24"
                  nzLg="24"
                  nzXl="24"
                  nzSpan="24"
                  style="text-align: left"
                >
                  <div nz-row [nzGutter]="10">
                    <div
                      nz-col
                      nzXs="6"
                      nzSm="6"
                      nzMd="6"
                      nzLg="6"
                      nzXl="6"
                      nzSpan="6"
                    >
                      <p style="margin-bottom: 0px !important">Destination</p>
                      <p style="margin-bottom: 0px !important">Family</p>
                      <p style="margin-bottom: 0px !important">Entry Made By</p>
                      <p style="margin-bottom: 0px !important">Checked by</p>
                    </div>
                    <div
                      nz-col
                      nzXs="18"
                      nzSm="18"
                      nzMd="18"
                      nzLg="18"
                      nzXl="18"
                      nzSpan="18"
                    >
                      <p
                        style="margin-bottom: 0px !important"
                        *ngIf="LTC2data.IS_DESTINATION_EDIT == 1"
                      >
                        {{ LTC2data.DESTIONATION_REMARK }}
                      </p>
                      <p
                        style="margin-bottom: 0px !important"
                        *ngIf="
                          destinationList.length > 0 &&
                          LTC2data.IS_DESTINATION_EDIT == 0
                        "
                      >
                        <span *ngIf="destinationList.length > 0">
                          <ng-container
                            *ngFor="
                              let item of destinationList;
                              let last = last;
                              let i = index
                            "
                          >
                            {{ item }}
                            <ng-container
                              *ngIf="destinationList.length > 1 && !last"
                            >
                              <ng-container
                                *ngIf="destinationList.length === 2 && i === 0"
                              >
                                and
                              </ng-container>
                              <ng-container
                                *ngIf="
                                  destinationList.length > 2 &&
                                  i < destinationList.length - 2
                                "
                                >,
                              </ng-container>
                              <ng-container
                                *ngIf="
                                  destinationList.length > 2 &&
                                  i === destinationList.length - 2
                                "
                              >
                                and
                              </ng-container>
                            </ng-container>
                          </ng-container>
                        </span>
                        <!-- <span
                          *ngFor="let data of destinationList; let k = index"
                        >
                          <span>{{ data }}</span>
                          <span *ngIf="destinationList.length > 1">
                            <span *ngIf="destinationList.length - 2 == k">
                              ,
                            </span>
                          </span>
                        </span> -->
                        only
                      </p>
                      <p style="margin-bottom: 0px !important">
                        {{ LTC2data.FAMILY }}
                      </p>
                      <p style="margin-bottom: 0px !important">
                        {{ LTC2data.ENTRY_MADE_BY }}
                        <!-- <span *ngIf="FamilyList.length > 0">Yes</span>
                        <span *ngIf="FamilyList.length <= 0">No</span> -->
                      </p>
                      <p></p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                nz-row
                [nzGutter]="10"
                style="margin-top: 5px; margin-bottom: 2px"
              >
                <div
                  nz-col
                  nzXs="12"
                  nzSm="12"
                  nzMd="12"
                  nzLg="12"
                  nzXl="12"
                  nzSpan="12"
                  style="text-align: center"
                ></div>
                <div
                  nz-col
                  nzXs="12"
                  nzSm="12"
                  nzMd="12"
                  nzLg="12"
                  nzXl="12"
                  nzSpan="12"
                  style="text-align: center"
                >
                  <span style="text-align: center">
                    आयकर अधिकारी <br />
                    <b>Income Tax Officer </b><br />
                    राजपत्रित सेवा पुस्तिका कक्ष, मुंबई <br />
                    <b>Gaz Service Book Cell, Mumbai </b><br />
                  </span>
                </div>
              </div>
              <p style="margin-bottom: 0px !important">
                <b>CERTIFICATE TO BE GIVEN BY THE CONTROLLING OFFICER</b>
              </p>
              <p style="margin-bottom: 0px !important">Certified-</p>
              <p style="padding-left: 50px">
                <b>A)</b> That of
                <b
                  >{{ LTC2data.SALUTATION }} {{ LTC2data.EMPLOYEE_NAME }},
                  {{ LTC2data.DESIGNATION }},</b
                >
                as rendered continuous service for one year or more on the date
                of commencing the outward journey.<br />
                B) That necessary entry as required under para 3 of the ministry
                of home affairs O.M. No. 43/1/55-Ests. (A) Part II, dated 11th
                October, 1956 have been made in the service book of
                <b
                  >{{ LTC2data.SALUTATION }} {{ LTC2data.EMPLOYEE_NAME }},
                  {{ LTC2data.DESIGNATION }}.</b
                >
              </p>
              <div nz-row [nzGutter]="10" style="margin-top: 36px">
                <div
                  nz-col
                  nzXs="12"
                  nzSm="12"
                  nzMd="12"
                  nzLg="12"
                  nzXl="12"
                  nzSpan="12"
                  style="text-align: center"
                ></div>
                <div
                  nz-col
                  nzXs="12"
                  nzSm="12"
                  nzMd="12"
                  nzLg="12"
                  nzXl="12"
                  nzSpan="12"
                  style="text-align: center"
                >
                  <span style="text-align: center">
                    <b> (मुदित श्रीवास्तव, भा.रा.से.) </b><br />
                    <b> (MUDIT SRIVASTAVA, I.R.S.)</b><br />
                    अपर आयकर आयुक्त (मुख्या.) कार्मिक, मुंबई <br />
                    <b> Addl. Commissioner of Income Tax (HQ)</b> <br />
                    <b> Personnel, Mumbai</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>
  </ng-template>

  <ng-template #printOrderModalFooter>
    <button nz-button nzType="default" (click)="printOrderModalCancel()">
      Close
    </button>

    <button
      nz-button
      nzType="default"
      [useExistingCss]="true"
      printSectionId="printOrderModal1"
      ngxPrint
    >
      Print
    </button>

    <button
      nz-button
      nzType="primary"
      [nzLoading]="pdfDownload"
      (click)="generatePDF()"
    >
      Download
    </button>
  </ng-template>
</nz-modal>
