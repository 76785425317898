import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { Filemaster } from 'src/app/Models/filemaster';
// import { NzNotificationService } from 'ng-zorro-antd';
// import { areaMaster } from 'src/app/Models/supportAreaMaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-viewlogs',
  templateUrl: './viewlogs.component.html',
  styleUrls: ['./viewlogs.component.css'],
})
export class ViewlogsComponent implements OnInit {
  @Input() drawerClose1: Function;
  @Input() data;
  @Input() type;
  @Input() LOGS1: any = [];

  @Input() filename: string;
  @Input() FILE_OR_CLAIM;

  constructor(
    private message: NzNotificationService,
    private api: ApiService,
    private datepipe: DatePipe,
    private cookie: CookieService
  ) {}

  ngOnInit() {}
  LOGS2: any = [];
  startDateChange(event) {
    console.log('this.data', this.data);
    this.LOGS2 = [];
    this.LOGS1 = [];
    if (this.type == 'e') {
      console.log('this.data', this.data);
      // console.log("e");
      // console.log(this.data1);
      if (event == true) {
        this.api
          .getLogs(0, 0, 'ID', 'asc', ' AND FILE_ID = ' + this.data.FILE_ID)
          .subscribe(
            (userData1) => {
              if (userData1['count'] == 0) {
                this.LOGS2 = [];
              } else {
                this.LOGS2 = userData1['data'];
                this.filename = this.LOGS2[0]['FILE_NUMBER'];
                console.log(this.filename);
              }
            },
            (err) => {
              console.log(err);
            }
          );
      } else {
        this.api
          .getclaimLogs(0, 0, 'ID', 'asc', ' AND CLAIM_ID = ' + this.data.ID)
          .subscribe(
            (userData1) => {
              if (userData1['count'] == 0) {
                this.LOGS1 = [];
              } else {
                this.LOGS1 = userData1['data'];
              }
            },
            (err) => {
              console.log(err);
            }
          );
      }
    } else {
      // console.log("f");
      if (this.FILE_OR_CLAIM == true) {
        this.api
          .getLogs(0, 0, 'ID', 'asc', ' AND FILE_ID = ' + this.data.ID)
          .subscribe(
            (userData1) => {
              if (userData1['count'] == 0) {
                this.LOGS2 = [];
              } else {
                this.LOGS2 = userData1['data'];
                this.filename = this.LOGS2[0]['FILE_NUMBER'];
                console.log(this.filename);
              }
              // this.isVisible11 = true
            },
            (err) => {
              console.log(err);
            }
          );
      } else {
        this.api
          .getclaimLogs(
            0,
            0,
            'ID',
            'asc',
            ' AND CLAIM_ID = ' + this.data.CLAIM_ID
          )
          .subscribe(
            (userData2) => {
              if (userData2['count'] == 0) {
                this.LOGS1 = [];
              } else {
                this.LOGS1 = userData2['data'];
              }
              // this.isVisible11 = true
            },
            (err) => {
              console.log(err);
            }
          );
      }
    }
  }

  close() {
    this.LOGS2 = [];
    this.LOGS1 = [];
    this.filename = '';
    this.data = [];
    this.FILE_OR_CLAIM = false;
    this.drawerClose1();

    // console.log( this.data);
  }

  reset(httpForm: NgForm) {
    // this.isSpinning = true;
    httpForm.reset();
  }
}
