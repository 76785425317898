<div>
  <form nz-form>
    <div style="font-size: 14px; height: 590px; overflow-y: scroll">
      <!-- <p style="text-align: center; font-size: 16px">
          <u>(To be filled in the Bill Section)</u>
        </p> -->
      <div>
        <p>
          The net entitlement on account of Travelling Allowance works out to
        </p>
        <div nz-row>
          <div nz-col nzSpan="15">
            (A) Railway / Air / Bus / Steamer fare / SCPLTC
          </div>
          <div nz-col nzSpan="2">₹</div>
          <div nz-col nzSpan="7">{{ totalamountofpartb }}/-</div>
        </div>

        <div nz-row>
          <div nz-col nzSpan="15">(B) Less: Amount of advance drawn</div>
          <div nz-col nzSpan="2">₹</div>
          <div nz-col nzSpan="7">{{ tourpartbdata.AMOUNT_OF_T_A }}/-</div>
        </div>

        <div nz-row>
          <div nz-col nzSpan="15">NET PAY AMOUNT</div>
          <div nz-col nzSpan="2">₹</div>
          <div nz-col nzSpan="7">
            <span *ngIf="tourpartbdata.IS_ADVANCE_TAKEN == 0"
              >{{ totalamountofpartb }}/-</span
            >
            <span *ngIf="tourpartbdata.IS_ADVANCE_TAKEN == 1"
              >{{ netPayAmount }}/-</span
            >
          </div>
        </div>

        <p>The expenditure is debitable to LTC Head</p>

        <div style="margin-top: 60px">
          <div nz-row>
            <div nz-col nzSpan="15"></div>

            <div nz-col nzSpan="9" style="text-align: center">
              <div style="font-weight: bold">({{ NAME_HN }})</div>
              <div>{{ POST_HN }}</div>
              <div>{{ OFFICE_NAME_HN }}</div>
              <div style="font-weight: bold">({{ SIGNNAME }})</div>
              <div>{{ POST }}</div>
              <div>{{ OFFICE_NAME }}</div>
            </div>
          </div>
        </div>

        <div style="margin-top: 60px">
          <div nz-row>
            <div nz-col nzSpan="9" style="text-align: center">
              <div style="font-weight: bold">(मुदित श्रीवास्तव, भा.रा.से.)</div>
              <div>(MUDIT SRIVASTAVA, I.R.S.)</div>
              <div>अपर आयुक्त (मुख्य) कार्मिक, मुंबई</div>
              <div>Addl. Commissioner of Income Tax (HQ) Personnel, Mumbai</div>
            </div>
            <div nz-col nzSpan="15"></div>
          </div>
        </div>

        <div style="margin-top: 60px">
          <div nz-row>
            <div nz-col nzSpan="15"></div>
            <div nz-col nzSpan="9" style="text-align: center">
              <div style="font-weight: bold">(विनय सिन्हा, भा.रा.से.)</div>
              <div>(VINAY SINHA, I.R.S.)</div>
              <div>आयकर आयुत प्रशासन एवं करदाता सेवाएँ, मुंबई</div>
              <div>Commissioner of Income Tax</div>
              <div>Admin and TPS Mumbai</div>
            </div>
          </div>
        </div>

        <p style="margin-top: 10px">
          Certified that necessary entries have been made in the service book of
          {{ tourpartbdata.EMPLOYEE_NAME }}, {{ tourpartbdata.DESIGNATION }},
          pay {{ tourpartbdata.GRADE_PAY_LEVEL }}, for the block year 2022-2023
        </p>
        <div nz-row>
          <div nz-col nzSpan="4">Destination</div>
          <div nz-col nzSpan="20">
            <span *ngFor="let data of destinationList; let k = index">
              <span
                ><b>{{ data }}</b></span
              >

              <span *ngIf="destinationList.length > 1">
                <span
                  *ngIf="
                    destinationList.length - 2 != k &&
                    destinationList.length != k + 1
                  "
                >
                  ,
                </span>
                <span *ngIf="destinationList.length - 2 == k"> & </span>
              </span>
            </span>
          </div>

          <div nz-col nzSpan="4">Family</div>
          <div nz-col nzSpan="20"></div>

          <div nz-col nzSpan="4">Entry made by</div>
          <div nz-col nzSpan="20"></div>
          <div nz-col nzSpan="4">Checked by</div>
          <div nz-col nzSpan="20"></div>
        </div>

        <div style="margin-top: 60px">
          <div nz-row>
            <div nz-col nzSpan="15"></div>
            <div nz-col nzSpan="9" style="text-align: center">
              <div style="font-weight: bold">आयकर अधिकारी</div>
              <div>Income Tax Officer</div>
              <div>राजपत्रित सेवा पुस्तिका, मुंबई</div>
              <div>Gaz Service Book Cell, Mumbai</div>
            </div>
          </div>
        </div>

        <div
          style="
            margin-top: 100px;
            font-size: 13px;
            page-break-inside: avoid !important;
          "
        >
          <div nz-row>
            <div nz-col nzSpan="24">
              CERTIFICATE TO BE GIVEN BY THE CONTROLLING OFFICER Certified-
            </div>

            <div nz-col nzSpan="24">
              A) That of
              <b
                >{{ tourpartbdata.EMPLOYEE_NAME }},
                {{ tourpartbdata.DESIGNATION }}</b
              >, has rendered continuous service for one year or more on the
              date of commencing the outward journey.
            </div>
            <div nz-col nzSpan="24">
              B) That necessary entry as required under para 3 of the ministry
              of home affairs O.M. No. 43/1/55-Ests. (A) Part II. dated 11th
              October, 1956 have been made in the service book of
              <b
                >{{ tourpartbdata.EMPLOYEE_NAME }},
                {{ tourpartbdata.DESIGNATION }}</b
              >.
            </div>
          </div>
        </div>

        <div style="margin-top: 60px; page-break-inside: avoid !important">
          <div nz-row>
            <div nz-col nzSpan="15"></div>
            <div nz-col nzSpan="9" style="text-align: center">
              <div style="font-weight: bold">आयकर अधिकारी</div>
              <div>Income Tax Officer</div>
              <div>राजपत्रित सेवा पुस्तिका, मुंबई</div>
              <div>Gaz Service Book Cell, Mumbai</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="footer">
    <button
      type="button"
      nz-button
      (click)="close()"
      class="ant-btn"
      style="margin-right: 8px"
    >
      <span>Cancel</span>
    </button>
    <button
      type="button"
      nzType="primary"
      class="ant-btn ant-btn-primary"
      nz-button
      (click)="showmodal()"
    >
      <span>Download or Print</span>
    </button>
  </div>
</div>

<nz-modal
  [nzWidth]="getwidth()"
  [(nzVisible)]="printOrderModalVisible"
  nzMaskClosable="false"
  nzClosable="false"
  [nzContent]="printOrderModalContent"
  [nzTitle]="'Tour Part B'"
  (nzOnCancel)="printOrderModalCancel()"
  [nzFooter]="printOrderModalFooter"
>
  <ng-template #printOrderModalContent>
    <nz-spin [nzSpinning]="loadingRecords">
      <div style="height: 450px; overflow: auto">
        <div id="excel-table">
          <div
            style="
              width: 700px;
              padding-right: 3px;
              margin-left: 50px;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 14px;
            "
          >
            <div>
              <p>
                The net entitlement on account of Travelling Allowance works out
                to
              </p>
              <div nz-row>
                <div nz-col nzSpan="15">
                  (A) Railway / Air / Bus / Steamer fare / SCPLTC
                </div>
                <div nz-col nzSpan="2">₹</div>
                <div nz-col nzSpan="7">{{ totalamountofpartb }}/-</div>
              </div>

              <div nz-row>
                <div nz-col nzSpan="15">(B) Less: Amount of advance drawn</div>
                <div nz-col nzSpan="2">₹</div>
                <div nz-col nzSpan="7">{{ tourpartbdata.AMOUNT_OF_T_A }}/-</div>
              </div>

              <div nz-row>
                <div nz-col nzSpan="15">NET PAY AMOUNT</div>
                <div nz-col nzSpan="2">₹</div>
                <div nz-col nzSpan="7">
                  <span *ngIf="tourpartbdata.IS_ADVANCE_TAKEN == 0"
                    >{{ totalamountofpartb }}/-</span
                  >
                  <span *ngIf="tourpartbdata.IS_ADVANCE_TAKEN == 1"
                    >{{ netPayAmount }}/-</span
                  >
                </div>
              </div>

              <p>The expenditure is debitable to LTC Head</p>

              <div style="margin-top: 60px">
                <div nz-row>
                  <div nz-col nzSpan="15"></div>

                  <div nz-col nzSpan="9" style="text-align: center">
                    <div style="font-weight: bold">({{ NAME_HN }})</div>
                    <div>{{ POST_HN }}</div>
                    <div>{{ OFFICE_NAME_HN }}</div>
                    <div style="font-weight: bold">({{ SIGNNAME }})</div>
                    <div>{{ POST }}</div>
                    <div>{{ OFFICE_NAME }}</div>
                  </div>
                </div>
              </div>

              <div style="margin-top: 60px">
                <div nz-row>
                  <div nz-col nzSpan="9" style="text-align: center">
                    <div style="font-weight: bold">
                      (मुदित श्रीवास्तव, भा.रा.से.)
                    </div>
                    <div>(MUDIT SRIVASTAVA, I.R.S.)</div>
                    <div>अपर आयुक्त (मुख्य) कार्मिक, मुंबई</div>
                    <div>
                      Addl. Commissioner of Income Tax (HQ) Personnel, Mumbai
                    </div>
                  </div>
                  <div nz-col nzSpan="15"></div>
                </div>
              </div>

              <div style="margin-top: 60px">
                <div nz-row>
                  <div nz-col nzSpan="15"></div>
                  <div nz-col nzSpan="9" style="text-align: center">
                    <div style="font-weight: bold">
                      (विनय सिन्हा, भा.रा.से.)
                    </div>
                    <div>(VINAY SINHA, I.R.S.)</div>
                    <div>आयकर आयुत प्रशासन एवं करदाता सेवाएँ, मुंबई</div>
                    <div>Commissioner of Income Tax</div>
                    <div>Admin and TPS Mumbai</div>
                  </div>
                </div>
              </div>

              <p style="margin-top: 10px">
                Certified that necessary entries have been made in the service
                book of
                {{ tourpartbdata.EMPLOYEE_NAME }},
                {{ tourpartbdata.DESIGNATION }}, pay
                {{ tourpartbdata.GRADE_PAY_LEVEL }}, for the block year
                2022-2023
              </p>
              <div nz-row>
                <div nz-col nzSpan="4">Destination</div>
                <div nz-col nzSpan="20">
                  <span *ngFor="let data of destinationList; let k = index">
                    <span
                      ><b>{{ data }}</b></span
                    >

                    <span *ngIf="destinationList.length > 1">
                      <span
                        *ngIf="
                          destinationList.length - 2 != k &&
                          destinationList.length != k + 1
                        "
                      >
                        ,
                      </span>
                      <span *ngIf="destinationList.length - 2 == k"> & </span>
                    </span>
                  </span>
                </div>

                <div nz-col nzSpan="4">Family</div>
                <div nz-col nzSpan="20"></div>

                <div nz-col nzSpan="4">Entry made by</div>
                <div nz-col nzSpan="20"></div>
                <div nz-col nzSpan="4">Checked by</div>
                <div nz-col nzSpan="20"></div>
              </div>

              <div style="margin-top: 60px">
                <div nz-row>
                  <div nz-col nzSpan="15"></div>
                  <div nz-col nzSpan="9" style="text-align: center">
                    <div style="font-weight: bold">आयकर अधिकारी</div>
                    <div>Income Tax Officer</div>
                    <div>राजपत्रित सेवा पुस्तिका, मुंबई</div>
                    <div>Gaz Service Book Cell, Mumbai</div>
                  </div>
                </div>
              </div>

              <div
                style="
                  margin-top: 100px;
                  font-size: 13px;
                  page-break-inside: avoid !important;
                "
              >
                <div nz-row>
                  <div nz-col nzSpan="24">
                    CERTIFICATE TO BE GIVEN BY THE CONTROLLING OFFICER
                    Certified-
                  </div>

                  <div nz-col nzSpan="24">
                    A) That of
                    <b
                      >{{ tourpartbdata.EMPLOYEE_NAME }},
                      {{ tourpartbdata.DESIGNATION }}</b
                    >, has rendered continuous service for one year or more on
                    the date of commencing the outward journey.
                  </div>
                  <div nz-col nzSpan="24">
                    B) That necessary entry as required under para 3 of the
                    ministry of home affairs O.M. No. 43/1/55-Ests. (A) Part II.
                    dated 11th October, 1956 have been made in the service book
                    of
                    <b
                      >{{ tourpartbdata.EMPLOYEE_NAME }},
                      {{ tourpartbdata.DESIGNATION }}</b
                    >.
                  </div>
                </div>
              </div>

              <div
                style="margin-top: 60px; page-break-inside: avoid !important"
              >
                <div nz-row>
                  <div nz-col nzSpan="15"></div>
                  <div nz-col nzSpan="9" style="text-align: center">
                    <div style="font-weight: bold">आयकर अधिकारी</div>
                    <div>Income Tax Officer</div>
                    <div>राजपत्रित सेवा पुस्तिका, मुंबई</div>
                    <div>Gaz Service Book Cell, Mumbai</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>
  </ng-template>
  <ng-template #printOrderModalFooter>
    <button nz-button nzType="default" (click)="printOrderModalCancel()">
      Close
    </button>

    <button
      nz-button
      nzType="default"
      [useExistingCss]="true"
      printSectionId="excel-table"
      ngxPrint
    >
      Print
    </button>

    <button
      nz-button
      nzType="primary"
      [nzLoading]="pdfDownload"
      (click)="openpdf()"
    >
      Download
    </button>
  </ng-template>
</nz-modal>
