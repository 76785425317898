<div>
  <form nz-form>
    <nz-spin [nzSpinning]="loadingRecords">
      <div nz-row [nzGutter]="10">
        <div nz-col nzXs="8" nzSm="8" nzMd="8" nzLg="8" nzXl="8" nzSpan="8">
          <div class="my-box">
            <div nz-row>
              <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" nzSpan="24">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>Order Number</nz-form-label>

                  <nz-form-control nzHasFeedback nzErrorTip="Order no. is required">
                    <input style="width: 100%" name="OrderNumber" nz-input placeholder="Enter order number" type="text"
                      [(ngModel)]="adheshdata.ADESH_ORDER_NO" />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div nz-col nzSpan="24">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>Signature</nz-form-label>
                  <nz-form-control>
                    <nz-select name="userid" [(ngModel)]="adheshdata.SIGNATURE_ID" nzAllowClear
                      nzPlaceHolder="Choose Signature" (ngModelChange)="signature($event)">
                      <nz-option *ngFor="let user of Signaturearray" [nzValue]="user.ID" [nzLabel]="user.NAME">
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" nzSpan="24">
                <nz-form-item>
                  <nz-form-label nzNoColon>Name of applicant</nz-form-label>

                  <nz-form-control nzHasFeedback nzErrorTip="Name of applicant is required">
                    <input style="width: 100%" name="EMPLOYEE_NAME" nz-input placeholder="Enter name in english"
                      autocomplete="off" type="text" disabled="true" [(ngModel)]="adheshdata.EMPLOYEE_NAME" />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" nzSpan="24">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>Name of applicant (in hindi)</nz-form-label>

                  <nz-form-control nzHasFeedback nzErrorTip="City Name is required">
                    <input style="width: 100%" nz-input placeholder="Enter name in hindi" autocomplete="off"
                      name="employeeinhindi" type="text" [(ngModel)]="adheshdata.EMPLOYEE_NAME_HN" />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" nzSpan="24">
                <nz-form-item>
                  <nz-form-label nzNoColon> Designation</nz-form-label>

                  <nz-form-control nzHasFeedback nzErrorTip="Name of patient is required">
                    <input style="width: 100%" name="PATIENT_NAME" nz-input placeholder="Enter name in Designation"
                      autocomplete="off" type="text" disabled="true" [(ngModel)]="adheshdata.DESIGNATION" />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" nzSpan="24">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>Designation (in hindi)</nz-form-label>

                  <nz-form-control nzHasFeedback nzErrorTip="Name of patient(in hindi) is required">
                    <input style="width: 100%" nz-input placeholder="Enter name of Designation in hindi"
                      autocomplete="off" name="PATIENT_NAME_HN" type="text" [(ngModel)]="adheshdata.DESIGNATION_HN" />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" nzSpan="24">
                <nz-form-item>
                  <nz-form-label nzNoColon> Office Name</nz-form-label>

                  <nz-form-control nzHasFeedback nzErrorTip="Please Enter Correct Office Name">
                    <input style="width: 100%" name="officename" nz-input placeholder="Enter Office Name"
                      autocomplete="off" type="text" disabled="true" [(ngModel)]="adheshdata.OFFICE_NAME" />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" nzSpan="24">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>Office Name (in hindi)</nz-form-label>

                  <nz-form-control nzHasFeedback nzErrorTip="Please Enter Correct Office Name (in hindi)">
                    <input style="width: 100%" nz-input placeholder="Enter Office Name (in hindi)" autocomplete="off"
                      name="officenamehindi" type="text" [(ngModel)]="adheshdata.OFFICE_NAME_HN" />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div nz-col nzSpan="24">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon> Reason </nz-form-label>
                  <nz-form-control nzHasFeedback nzErrorTip="Enter Reason">
                    <textarea style="width: 100%" nz-input placeholder="Please Enter Reason" name="reason" type="text"
                      rows="3" maxlength="1024" [(ngModel)]="adheshdata.ADESH_REASON"></textarea>
                  </nz-form-control>
                  <h6 style="color: red" *ngIf="adheshdata.ADESH_REASON != undefined">
                    {{ adheshdata.ADESH_REASON.length }}/1024
                  </h6>
                </nz-form-item>
              </div>
            </div>
          </div>
        </div>

        <div nz-col nzXs="16" nzSm="16" nzMd="16" nzLg="16" nzXl="16">
          <div class="my-box" style="padding: 10px">
            <div>
              <div>
                <div style="font-family: Arial, Helvetica, sans-serif !important">
                  <div nz-row>
                    <div nz-col nzXs="2" nzSm="2" nzMd="2" nzLg="2" nzXl="2">
                      <div style="text-align: right">
                        <img src="assets/govtOfIndia.png" alt="" height="140%" width="140%" />
                      </div>
                    </div>

                    <div nz-col nzXs="22" nzSm="22" nzMd="22" nzLg="22" nzXl="22">
                      <div style="width: 100%; text-align: center; font-weight: bold">
                        <br>
                        <div>कार्यालय</div>
                        <div style="text-transform: uppercase">
                          प्रधान आयकर आयुक्त-19,
                        </div>
                        <div>
                          क. सं0-617, पिरामल चैम्बर्स,
                          लालबाग, मुंबई-400012
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div style="
                    width: 100%;
                    border: 1px solid black;
                    padding: 10px;
                    margin-top: 10px;
                    font-size: 15px;
                  ">
                  <div nz-row>
                    <div nz-col nzSpan="16">
                      <b>{{ adheshdata.ADESH_ORDER_NO }}</b>
                    </div>
                    <div nz-col nzSpan="8" style="text-align: right">
                      <b> दिनांक : </b>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                  </div>
                </div>

                <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="21">
                  <br /><br />
                  <div style="text-align: center; font-weight: bold">
                    <div style="text-transform: uppercase">
                      <u> एस. आर-61 के अधीन आदेश</u>
                    </div>
                  </div>
                  <br />
                  <div>
                    <p>
                      &nbsp;&nbsp;&nbsp;<b>{{ adheshdata.EMPLOYEE_NAME_HN }},</b>
                      {{ adheshdata.DESIGNATION_HN }},&nbsp;
                      {{ adheshdata.OFFICE_NAME_HN }},&nbsp;
                      {{ adheshdata.ADESH_REASON }}
                    </p>
                  </div>
                  <div style="margin-top: 100px; font-size: 13px">
                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div>(जी. मल्लिकार्जुन)</div>
                        <div style="font-weight: bold; font-size: 13px">
                          प्रधान आयकर आयुक्त-19,
                        </div>
                        <div style="font-weight: bold; font-size: 13px">
                          मुंबई
                        </div>
                      </div>
                    </div>
                  </div>

                  <p><u> प्रतिलिपि प्रेषित-:- </u></p>
                  <p>१. वेतन बिल अनुभाग, मुंबई</p>
                  <p>२. क्षेत्रीय लेखा अधिकारी, मुंबई</p>
                  <p>३. संबंधित अधिकारी</p>
                  <!-- <div style="margin-top: 100px; font-size: 13px">
                                      <div nz-row>
                                          <div nz-col nzSpan="12"></div>
  
                                          <div nz-col nzSpan="12" style="text-align: center">
                                              <div>(ओंकार नाथ सिंह)</div>
                                              <div style="font-weight: bold; font-size: 13px">
                                                  आयकर अधिकारी (मुख्या.) (तक.)-19,
                                              </div>
                                              <div style="font-weight: bold; font-size: 13px">
                                                  मुंबई
                                              </div>
                                          </div>
                                      </div>
                                  </div> -->

                  <div nz-row>
                    <div nz-col nzSpan="15"></div>

                    <div nz-col nzSpan="9" style="text-align: center">
                      <div style="font-weight: bold">({{ NAME_HN }})</div>
                      <div>{{ POST_HN }}</div>
                      <div>{{ OFFICE_NAME_HN }}</div>
                      <div style="font-weight: bold">({{ SIGNNAME }})</div>
                      <div>{{ POST }}</div>
                      <div>{{ OFFICE_NAME }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>
  </form>

  <div class="footer">
    <button type="button" nz-button (click)="close()" class="ant-btn" style="margin-right: 8px">
      <span>Cancel</span>
    </button>
    <button type="button" class="ant-btn ant-btn-primary" nz-button (click)="Save()">
      <span>Save or Preview</span>
    </button>
  </div>
</div>

<nz-modal [nzWidth]="getwidth()" [(nzVisible)]="printOrderModalVisible" nzMaskClosable="false" nzClosable="false"
  [nzContent]="printOrderModalContent" [nzTitle]="'Aadhesh'" (nzOnCancel)="printOrderModalCancel()"
  [nzFooter]="printOrderModalFooter">
  <ng-template #printOrderModalContent>
    <nz-spin [nzSpinning]="loadingRecords">
      <div style="height: 450px; overflow: auto">
        <div id="excel-table">
          <div style="
              width: 700px;
              padding: 3px;
              margin-left: 50px;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 14px;
            ">
            <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
              <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="my-box">
                <div style="font-family: Arial, Helvetica, sans-serif !important">
                  <div nz-row>
                    <div nz-col nzXs="2" nzSm="2" nzMd="2" nzLg="2" nzXl="2">
                      <div style="text-align: right">
                        <img src="assets/govtOfIndia.png" alt="Government of India" class="print-image" />
                      </div>
                    </div>

                    <div nz-col nzXs="22" nzSm="22" nzMd="22" nzLg="22" nzXl="22">
                      <div style="width: 100%; text-align: center; font-weight: bold">
                        <br>
                        <div>कार्यालय</div>
                        <div style="text-transform: uppercase">
                          प्रधान आयकर आयुक्त-19,
                        </div>
                        <div>
                          क. सं0-617, पिरामल चैम्बर्स,
                          लालबाग, मुंबई-400012
                        </div>

                      </div>
                    </div>
                  </div>





                  <div style="
                      width: 100%;
                      border: 1px solid black;
                      padding: 10px;
                      margin-top: 10px;
                      font-size: 15px;
                    ">
                    <div nz-row>
                      <div nz-col nzSpan="16">
                        <b> {{ adheshdata.ADESH_ORDER_NO }}</b>
                      </div>
                      <div nz-col nzSpan="8" style="text-align: right">
                        <b> दिनांक : </b>
                        :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </div>
                    </div>
                  </div>

                  <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="21">
                    <br /><br />
                    <div style="text-align: center; font-weight: bold">
                      <div style="text-transform: uppercase">
                        <u> एस. आर-61 के अधीन आदेश</u>
                      </div>
                    </div>
                    <br />
                    <div>
                      <p>
                        &nbsp;&nbsp;&nbsp;<b>{{ adheshdata.EMPLOYEE_NAME_HN }},</b>
                        {{ adheshdata.DESIGNATION_HN }},&nbsp;
                        {{ adheshdata.OFFICE_NAME_HN }},&nbsp;
                        {{ adheshdata.ADESH_REASON }}
                      </p>
                    </div>
                    <div style="margin-top: 100px; font-size: 13px">
                      <div nz-row>
                        <div nz-col nzSpan="12"></div>

                        <div nz-col nzSpan="12" style="text-align: center">
                          <div>(जी. मल्लिकार्जुन)</div>
                          <div style="font-weight: bold; font-size: 13px">
                            प्रधान आयकर आयुक्त-19,
                          </div>
                          <div style="font-weight: bold; font-size: 13px">
                            मुंबई
                          </div>
                        </div>
                      </div>
                    </div>

                    <p><u> प्रतिलिपि प्रेषित-:- </u></p>
                    <p>१. वेतन बिल अनुभाग, मुंबई</p>
                    <p>२. क्षेत्रीय लेखा अधिकारी, मुंबई</p>
                    <p>३. संबंधित अधिकारी</p>
                    <div nz-row>
                      <div nz-col nzSpan="15"></div>

                      <div nz-col nzSpan="9" style="text-align: center">
                        <div style="font-weight: bold">({{ NAME_HN }})</div>
                        <div>{{ POST_HN }}</div>
                        <div>{{ OFFICE_NAME_HN }}</div>
                        <div style="font-weight: bold">({{ SIGNNAME }})</div>
                        <div>{{ POST }}</div>
                        <div>{{ OFFICE_NAME }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>
  </ng-template>
  <ng-template #printOrderModalFooter>
    <button nz-button nzType="default" (click)="printOrderModalCancel()">
      Close
    </button>

    <button nz-button nzType="default" [useExistingCss]="true" printSectionId="excel-table" ngxPrint>
      Print
    </button>

    <button nz-button nzType="primary" [nzLoading]="pdfDownload" (click)="openpdf()">
      Download
    </button>
  </ng-template>
</nz-modal>