import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { InvestigationMaster } from 'src/app/Models/InvestigationMaster';
import * as html2pdf from 'html2pdf.js';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-add-investigation-master',
  templateUrl: './add-investigation-master.component.html',
  styleUrls: ['./add-investigation-master.component.css'],
})
export class AddInvestigationMasterComponent implements OnInit {
  constructor(private datePipe: DatePipe, private api: ApiService) {}

  ngOnInit(): void {}

  @Input() drawerClose: Function;
  @Input() data: InvestigationMaster;
  Cities: InvestigationMaster[];
  isSpinning = false;
  Names = InvestigationMaster;
  isOk: boolean = false;
  isVisible: boolean = false;
  procedureList2: [];

  @Input() showlayoutDataList = [
    {
      ID: 1,
      PARTICULARS_ID: 1,
      NAME: 'Bed Charges',
      PARTICULARS: 'Blood Group & RHO Type (2002-SP)',
      CODE: 484,
      CLAIMED: 11690,
      ADMISSIBLE: 11690,
    },
    {
      ID: 2,
      PARTICULARS_ID: 2,
      NAME: 'ECG',
      PARTICULARS: 'ECG',
      CODE: 37,
      CLAIMED: 43,
      ADMISSIBLE: 43,
    },
    {
      ID: 3,
      PARTICULARS_ID: 3,
      NAME: 'HRCT Plain',
      PARTICULARS: 'HRCT Plain',
      CODE: 1834,
      CLAIMED: 1700,
      ADMISSIBLE: 1700,
    },
    {
      ID: 4,
      PARTICULARS_ID: 4,
      NAME: 'IPD Consultant',
      PARTICULARS: 'IPD Consultant',
      CODE: 2,
      CLAIMED: 1080,
      ADMISSIBLE: 1080,
    },
    {
      ID: 5,
      PARTICULARS_ID: 5,
      NAME: 'Oxygen',
      PARTICULARS: 'Oxygen',
      CODE: 485,
      CLAIMED: 4800,
      ADMISSIBLE: 4800,
    },
  ];

  procedureList = [
    {
      ID: 1,
      NAME: 'Blood Group & RHO Type (2002-SP)',
      NABH_RATE: '100',
      NON_NABH_RATE: '100',
      SUPEER_SPECIALITY_RATE: '0',
    },
    {
      ID: 2,
      NAME: ' Blood Bank bag and solution (2002-SP)',
      NABH_RATE: '175',
      NON_NABH_RATE: '175',
      SUPEER_SPECIALITY_RATE: '0',
    },
    {
      ID: 3,
      NAME: 'Blood gas analysis (2002-SP)',
      NABH_RATE: '352',
      NON_NABH_RATE: '352',
      SUPEER_SPECIALITY_RATE: '0',
    },
    {
      ID: 4,
      NAME: 'Cross match (2002-SP)',
      NABH_RATE: '50',
      NON_NABH_RATE: '50',
      SUPEER_SPECIALITY_RATE: '0',
    },
    {
      ID: 5,
      NAME: 'Complicated Ext. per Tooth including LA (2002-SP)',
      NABH_RATE: '252',
      NON_NABH_RATE: '252',
      SUPEER_SPECIALITY_RATE: '0',
    },
    {
      ID: 6,
      NAME: 'Extraction of tooth including LA (2002-SP)',
      NABH_RATE: '125',
      NON_NABH_RATE: '125',
      SUPEER_SPECIALITY_RATE: '0',
    },
    {
      ID: 7,
      NAME: 'X Ray Arthrography 2002',
      NABH_RATE: '1225',
      NON_NABH_RATE: '1225',
      SUPEER_SPECIALITY_RATE: '0',
    },
    {
      ID: 8,
      NAME: 'Gastroscopy (2002-SP)',
      NABH_RATE: '2020',
      NON_NABH_RATE: '2020',
      SUPEER_SPECIALITY_RATE: '0',
    },
    {
      ID: 9,
      NAME: 'Absolute Eosinophil count (2002-SP)',
      NABH_RATE: '50',
      NON_NABH_RATE: '50',
      SUPEER_SPECIALITY_RATE: '0',
    },
    {
      ID: 10,
      NAME: 'Gastroscopy (2002-SP)',
      NABH_RATE: '2020',
      NON_NABH_RATE: '2020',
      SUPEER_SPECIALITY_RATE: '0',
    },
  ];

  // procedureList2 = [
  //   { ID: 1, CATEGORY_NAME: "Blood Bank", NAME: "Blood Group & RHO Type (2002-SP)", NABH_RATE: "100", NON_NABH_RATE: "100", SUPEER_SPECIALITY_RATE: "0", MEMO_NOTIFICATION_ID: "CGHS Rates for 21 Treatement Procedures or Investigations in Continuation of 2014 CGHS Rates" },
  //   { ID: 2, CATEGORY_NAME: "Blood Bank", NAME: " Blood Bank bag and solution (2002-SP)", NABH_RATE: "175", NON_NABH_RATE: "175", SUPEER_SPECIALITY_RATE: "0", MEMO_NOTIFICATION_ID: "Notification Of CGHS Rates For 25 Investigations Under CGHS " },
  //   { ID: 3, CATEGORY_NAME: "Bio-Chemistry", NAME: "Blood gas analysis (2002-SP)", NABH_RATE: "352", NON_NABH_RATE: "352", SUPEER_SPECIALITY_RATE: "0", MEMO_NOTIFICATION_ID: "Notification Of CGHS Rates For 15 Investigations Under CGHS" },
  //   { ID: 4, CATEGORY_NAME: "Blood Bank", NAME: "Cross match (2002-SP)", NABH_RATE: "50", NON_NABH_RATE: "50", SUPEER_SPECIALITY_RATE: "0", MEMO_NOTIFICATION_ID: "Bhubaneshwar-Empanelled Hospitals, Diagnostic Centres and Rates" },
  //   { ID: 5, CATEGORY_NAME: "Dental", NAME: " Complicated Ext. per Tooth including LA (2002-SP)", NABH_RATE: "252", NON_NABH_RATE: "252", SUPEER_SPECIALITY_RATE: "0", MEMO_NOTIFICATION_ID: "List Of Empanelled HCO's-Mumbai as on " },
  //   { ID: 6, CATEGORY_NAME: "Dental", NAME: "Extraction of tooth including LA (2002-SP)", NABH_RATE: "125", NON_NABH_RATE: "125", SUPEER_SPECIALITY_RATE: "0", MEMO_NOTIFICATION_ID: "Empanelment Of Kaizen Super Speciality under CGHS Mumbai" },
  //   { ID: 7, CATEGORY_NAME: "X-Ray", NAME: "X Ray Arthrography 2002", NABH_RATE: "1225", NON_NABH_RATE: "1225", SUPEER_SPECIALITY_RATE: "0", MEMO_NOTIFICATION_ID: "Empanelment of Millennium Special Lab Pvt.Ltd .Under CGHS Mumbai" },
  //   { ID: 8, CATEGORY_NAME: "Gastro And Hepatobiliary", NAME: "Gastroscopy (2002-SP)", NABH_RATE: "2020", NON_NABH_RATE: "2020", SUPEER_SPECIALITY_RATE: "0", MEMO_NOTIFICATION_ID: "Empanelment Of Private HCO Oncocare Center Under CGHS Mumbai" },
  //   { ID: 9, CATEGORY_NAME: "Haematology", NAME: "Absolute Eosinophil count (2002-SP)", NABH_RATE: "50", NON_NABH_RATE: "50", SUPEER_SPECIALITY_RATE: "0", MEMO_NOTIFICATION_ID: "Empanelment Of Private HCO H.L Raheja Hospital (Diabetic Association Of India)Under CGHS Mumbai" },
  //   { ID: 10, CATEGORY_NAME: "Harmones", NAME: "Gastroscopy (2002-SP)", NABH_RATE: "2020", NON_NABH_RATE: "2020", SUPEER_SPECIALITY_RATE: "0", MEMO_NOTIFICATION_ID: "Empanelment Of Big Smile Dental Clinic Under CGHS Mumbai" }
  // ];

  save(form: boolean = false) {}

  addData(showLayout: NgForm) {
    // this.isOk = true;
    // if (this.data.LAYOUT_ID <= 0 && this.data.TICKET_RATE <= 0) {
    //   this.isOk = false;
    //   this.message.error('Please Fill All Required Fields', '');
    // } else if (this.data.LAYOUT_ID == null || this.data.LAYOUT_ID == 0) {
    //   this.isOk = false;
    //   this.message.error('Please Select Layout Group', '');
    // } else if (this.data.TICKET_RATE == null || this.data.TICKET_RATE <= 0) {
    //   this.isOk = false;
    //   this.message.error('Please Enter Price', '');
    // }
    // if (this.isOk) {
    //   this.isSpinning = true;
    //   {
    //     if (this.data.ID) {
    //       this.api.updateshowlayoutrates(this.data).subscribe((successCode) => {
    //         if (successCode.code == '200') {
    //           this.message.success('Information Updated Successfully', '');
    //           this.resetDrawer(showLayout);
    //           this.getData();
    //           // this.drawerClose();
    //           this.isSpinning = false;
    //         } else {
    //           this.message.error('Information Not Updated', '');
    //           this.isSpinning = false;
    //         }
    //       });
    //     } else {
    //       this.api.createshowlayoutrates(this.data).subscribe((successCode) => {
    //         if (successCode.code == '200') {
    //           this.message.success('Information Save Successfully', '');
    //           this.data = new Showlayoutrates();
    //           this.resetDrawer(showLayout);
    //           this.getData();
    //           // this.drawerClose();
    //           this.isSpinning = false;
    //         } else {
    //           this.message.error('Information Not Saved', '');
    //           this.isSpinning = false;
    //         }
    //       });
    //     }
    //   }
    // }
  }

  omit(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  peocedureRate(event: any) {
    if (event > 0) {
      var a = this.procedureList2.filter((obj) => {
        return obj == event;
      });

      this.data.ADMISSIBLE_AMOUNT = a[0]['NABH_RATE'];
    } else {
      this.data.ADMISSIBLE_AMOUNT = this.data.CLAIMED_AMOUNT;
    }
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  edit(data: any): void {
    this.data = Object.assign({}, data);
  }

  resetDrawer(bundleMasterPage: NgForm) {
    this.data = new InvestigationMaster();
    bundleMasterPage.form.markAsPristine();
    bundleMasterPage.form.markAsUntouched();
  }

  close(investigationMaster: NgForm) {
    this.drawerClose();
    this.resetDrawer(investigationMaster);
    investigationMaster.form.reset();
  }
  getname() {
    this.api
      .getinvestigationcategory(0, 0, '', 'asc', ' AND STATUS = 1')
      .subscribe(
        (data) => {
          this.procedureList2 = data['data'];
        },
        (err) => {
          console.log(err);
          this.isSpinning = false;
        }
      );
  }
  fileName: string = "Annexure 'A'";
  pdfDownload: boolean = false;

  public generatePDF() {
    var i = 0;
    var date = new Date();
    var datef = this.datePipe.transform(date, 'dd-MM-yyyy');
    var dates = this.datePipe.transform(date, 'hh-mm-ss a');
    var data = document.getElementById('printAnnexureModal');

    html2pdf()
      .from(data)
      .set({
        image: { type: 'jpeg', quality: 1.0 },
        html2canvas: { scale: 3, useCORS: true },
        margin: [2, 10, 2, 5],
        pagebreak: { mode: ['css', 'legecy'] },
        jsPDF: { unit: 'mm', format: 'A4', orientation: 'portrait' },
      })
      .toPdf()
      .get('pdf')
      .then(function (pdf) {
        this.pdfDownload = true;
        var totalPages = pdf.internal.getNumberOfPages();

        for (i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(12);
          pdf.setTextColor(150);
          pdf.text(i.toString(), pdf.internal.pageSize.getWidth() / 2, 10);
        }

        this.pdfDownload = false;
      })
      .save(this.fileName + '_' + datef + '_' + dates + '.pdf');

    // var i = 0;
    // var date = new Date();
    // var datef = this.datePipe.transform(date, "dd-MM-yyyy");
    // var dates = this.datePipe.transform(date, "h:mm:ss a");
    // var data = document.getElementById('print');
    // var opt = {
    //   margin: 0.2,
    //   image: { type: 'jpeg', quality: 0.98 },
    //   html2canvas: { scale: 4 },
    //   jsPDF: { unit: 'in', orientation: 'landscape', width: 'imgWidth' }
    // };

    // html2pdf().set(opt).from(data).save(this.formTitle);
  }
}
