<!-- <button nz-button nzType="primary" (click)="open()">Open</button>

<nz-drawer [nzClosable]="true" nzTitle="Calculation Sheet" [nzVisible]="visible" nzPlacement="right" [nzWidth]="1000"
    (nzOnClose)="close()">
    <ng-container *nzDrawerContent style="padding-right: 0;"> -->

<!-- <div style="text-align: right; margin-bottom: 5px">
            <button nz-button nzType="default" [useExistingCss]="true" printSectionId="calculation"
                ngxPrint>Print</button>

            <button nz-button nzType="primary" [nzLoading]="pdfDownload" (click)="openpdf()" style="margin-left: 20px">
                Download </button>
        </div> -->
<div *ngIf="LTC5data != undefined">
  <form nz-form>
    <nz-spin [nzSpinning]="loadingRecords">
      <div nz-row [nzGutter]="10">
        <div nz-col nzXs="6" nzSm="6" nzMd="6" nzLg="6" nzXl="6" nzSpan="6">
          <div class="my-box" style="height: 500px">
            <!-- <div
              nz-col
              nzXs="24"
              nzSm="24"
              nzMd="24"
              nzLg="24"
              nzXl="24"
              nzSpan="24"
            >
              <nz-form-item>
                <nz-form-label nzNoColon>Destination</nz-form-label>

                <nz-input-group>
                  <input
                    type="text"
                    autocomplete="off"
                    name="Destination"
                    nz-input
                    placeholder="Enter Destination"
                    [(ngModel)]="LTC5data.DESTINATION"
                  />
                </nz-input-group>
              </nz-form-item>
            </div> -->

            <!-- <div
              nz-col
              nzXs="24"
              nzSm="24"
              nzMd="24"
              nzLg="24"
              nzXl="24"
              nzSpan="24"
            >
              <nz-form-item>
                <nz-form-label nzNoColon>Remark</nz-form-label>

                <nz-input-group>
                  <textarea
                    type="text"
                    autocomplete="off"
                    name="Remark"
                    nz-input
                    placeholder="Enter Remark"
                    [(ngModel)]="LTC5data.CALCULATION_REMARK"
                  ></textarea>
                </nz-input-group>
              </nz-form-item>
            </div> -->
            <div
              nz-col
              nzXs="24"
              nzSm="24"
              nzMd="24"
              nzLg="24"
              nzXl="24"
              nzSpan="24"
            >
              <nz-form-item>
                <nz-form-label nzNoColon>Remark</nz-form-label>
                <angular-editor
                  style="resize: none; color: black"
                  name="paragraph_text22"
                  [(ngModel)]="LTC5data.CALCULATION_SHEET_REMARK"
                  [config]="editorConfigPara"
                ></angular-editor>
                <!-- <nz-input-group>
                  <textarea
                    type="text"
                    autocomplete="off"
                    name="Remark2"
                    nz-input
                    placeholder="Enter Remark"
                    [(ngModel)]="LTC5data.CALCULATION_SHEET_REMARK"
                  ></textarea>
                </nz-input-group> -->
              </nz-form-item>
            </div>
          </div>
        </div>
        <div
          nz-col
          nzSpan="18"
          class="my-box"
          style="
            height: 590px;
            overflow-y: scroll;
            font-size: 15px !important;
            font-family: Bookman Old Style;
          "
        >
          <div *ngIf="LTC5data != undefined">
            <h2><u>Calculation Sheet</u></h2>
            <p style="margin-bottom: 0px">
              Name : <b>{{ LTC5data.EMPLOYEE_NAME }}</b>
            </p>
            <p style="margin-bottom: 0px">
              Designation : <b>{{ LTC5data.DESIGNATION }}</b>
            </p>
            <p style="margin-bottom: 0px">
              Pay level : <b>{{ getlevel(LTC5data.GRADE_PAY_LEVEL) }}</b>
            </p>

            <div nz-row>
              <div nz-col nzSpan="12">
                <p *ngIf="LTC5data.IS_DESTINATION_EDIT == 1">
                  Destination :{{ LTC5data.DESTIONATION_REMARK }}
                </p>
                <p
                  style="margin-bottom: 0px"
                  *ngIf="LTC5data.IS_DESTINATION_EDIT == 0"
                >
                  <!-- Destination :<b> {{ LTC5data.DESTINATION }}</b> -->
                  Destination :<b>
                    <span *ngFor="let data of destinationList; let k = index">
                      <span>{{ data }}</span>

                      <span *ngIf="destinationList.length > 1">
                        <!-- <span
                        *ngIf="
                          destinationList.length - 2 != k &&
                          destinationList.length != k + 1
                        "
                      >
                        ,
                      </span> -->
                        <span *ngIf="destinationList.length - 2 == k"> , </span>
                      </span>
                    </span></b
                  >
                </p>
              </div>
              <div nz-col nzSpan="12" style="text-align: right">
                <p style="margin-bottom: 0px">
                  Block Year : <b>{{ LTC5data.BLOCK_YEAR }}</b>
                </p>
              </div>
            </div>
            <table style="width: 100%; margin-top: 40px">
              <thead>
                <tr>
                  <th>From</th>
                  <th>To</th>
                  <th>Mode of travel</th>
                  <th>No. of Passengers</th>
                  <th>Amount claimed with taxes/ fees (in Rs.)n</th>
                  <th>Base fare allowed for all passengers (in Rs.)</th>
                  <th>Taxes allowed (In Rs.)</th>
                  <th>Amount granted with taxes (In Rs.)</th>
                  <!-- <th>Remark</th> -->
                  <!-- <th>From</th>
                  <th>To</th>
                  <th>Mode</th>
                  <th>No of Far</th>
                  <th>Claimed amount with taxes in</th>
                  <th>LTC 80 fare allowed</th>
                  <th>Taxes allowed in Rs</th>
                  <th>Amount Granted with taxes in Rs</th>
                  <th>Remark</th> -->
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of formdata7; let k = index">
                  <td style="text-align: center">{{ data.DEPARTURE_FROM }}</td>
                  <td style="text-align: center">{{ data.ARRIVAL_TO }}</td>
                  <td style="text-align: center">
                    {{ data.TRAVEL_MODE_NAME }}
                  </td>
                  <td style="text-align: center">{{ data.NO_OF_FAIRS }}</td>
                  <td style="text-align: center">
                    {{ data.JOURNEY_CLAIMED_AMOUNT }}
                  </td>
                  <td style="text-align: center">{{ data.FAIR_PAID }}</td>
                  <td style="text-align: center">{{ data.ALLOWED_TOTAL }}</td>

                  <td style="text-align: center">{{ data.AMOUNT_WITH_TAX }}</td>
                  <!-- <td
                    style="text-align: center; word-break: break-all"
                    *ngIf="k == 0 && formdata7.length == 1"
                    rowspan="4"
                  >
                    {{ LTC5data.CALCULATION_REMARK }}
                  </td>
                  <td
                    style="text-align: center; word-break: break-all"
                    *ngIf="k == 0 && formdata7.length == 2"
                    rowspan="5"
                  >
                    {{ LTC5data.CALCULATION_REMARK }}
                  </td>
                  <td
                    style="text-align: center; word-break: break-all"
                    *ngIf="k == 0 && formdata7.length == 3"
                    rowspan="6"
                  >
                    {{ LTC5data.CALCULATION_REMARK }}
                  </td>
                  <td
                    style="text-align: center; word-break: break-all"
                    *ngIf="k == 0 && formdata7.length == 4"
                    rowspan="7"
                  >
                    {{ LTC5data.CALCULATION_REMARK }}
                  </td>
                  <td
                    style="text-align: center; word-break: break-all"
                    *ngIf="k == 0 && formdata7.length == 5"
                    rowspan="8"
                  >
                    {{ LTC5data.CALCULATION_REMARK }}
                  </td>
                  <td
                    style="text-align: center; word-break: break-all"
                    *ngIf="k == 0 && formdata7.length == 6"
                    rowspan="9"
                  >
                    {{ LTC5data.CALCULATION_REMARK }}
                  </td> -->
                </tr>

                <tr>
                  <td colspan="7" style="text-align: right">Total</td>
                  <td style="text-align: center">
                    <b>{{ amount }} </b>
                  </td>
                </tr>
                <tr>
                  <td colspan="7" style="text-align: right">
                    Advance LTC Claimed
                  </td>
                  <td style="text-align: center">
                    <b> {{ LTC5data.AMOUNT_OF_ADVANCE }} </b>
                  </td>
                </tr>
                <tr>
                  <td colspan="7" style="text-align: right">Net Amount</td>
                  <td style="text-align: center">
                    <b>{{ namount }} </b>
                  </td>
                </tr>
              </tbody>
            </table>
            <p
              style="margin-top: 10px; font-weight: bold"
              [innerHtml]="LTC5data.CALCULATION_SHEET_REMARK"
            >
              <!-- <b>{{ LTC5data.CALCULATION_SHEET_REMARK }}</b> -->
            </p>
          </div>
        </div>
      </div>
    </nz-spin>
  </form>
</div>

<div class="footer">
  <button
    type="button"
    nz-button
    (click)="close()"
    class="ant-btn"
    style="margin-right: 8px"
  >
    <span>Cancel</span>
  </button>
  <button
    type="button"
    class="ant-btn ant-btn-primary"
    nz-button
    (click)="save()"
    [nzLoading]="loadingRecords"
  >
    <span>Save & Preview</span>
  </button>
</div>
<!-- </ng-container> -->

<!-- </nz-drawer> -->

<nz-modal
  [nzWidth]="getwidth()"
  [(nzVisible)]="printOrderModalVisible"
  nzMaskClosable="false"
  nzClosable="false"
  [nzContent]="printOrderModalContent"
  [nzTitle]="'Calculation Sheet'"
  (nzOnCancel)="printOrderModalCancel()"
  [nzFooter]="printOrderModalFooter"
>
  <ng-template #printOrderModalContent>
    <nz-spin [nzSpinning]="loadingRecords">
      <div style="height: 450px; overflow: auto; font-size: 14px !important">
        <div id="calculation">
          <br /><br />
          <div
            style="
              width: 700px;
              padding: 3px;
              font-size: 15px !important;
              font-family: Bookman Old Style;
              margin-left: 50px;
            "
          >
            <div>
              <div style="padding-left: 7%; margin-right: 2%">
                <h2><u>Calculation Sheet</u></h2>
                <p style="margin-bottom: 0px">
                  Name : <b>{{ LTC5data.EMPLOYEE_NAME }}</b>
                </p>
                <p style="margin-bottom: 0px">
                  Designation : <b>{{ LTC5data.DESIGNATION }}</b>
                </p>
                <p style="margin-bottom: 0px">
                  Pay level : <b>{{ getlevel(LTC5data.GRADE_PAY_LEVEL) }}</b>
                </p>

                <div nz-row>
                  <div nz-col nzSpan="12">
                    <p *ngIf="LTC5data.IS_DESTINATION_EDIT == 1">
                      Destination : {{ LTC5data.DESTIONATION_REMARK }}
                    </p>
                    <p
                      style="margin-bottom: 0px"
                      *ngIf="LTC5data.IS_DESTINATION_EDIT == 0"
                    >
                      <!-- Destination :<b> {{ LTC5data.DESTINATION }}</b> -->
                      Destination :<b>
                        <span
                          *ngFor="let data of destinationList; let k = index"
                        >
                          <span>{{ data }}</span>

                          <span *ngIf="destinationList.length > 1">
                            <!-- <span
                            *ngIf="
                              destinationList.length - 2 != k &&
                              destinationList.length != k + 1
                            "
                          >
                            ,
                          </span> -->
                            <span *ngIf="destinationList.length - 2 == k">
                              ,
                            </span>
                          </span>
                        </span></b
                      >
                    </p>
                  </div>
                  <div nz-col nzSpan="12" style="text-align: right">
                    <p style="margin-bottom: 0px">
                      Block Year : <b>{{ LTC5data.BLOCK_YEAR }}</b>
                    </p>
                  </div>
                </div>
                <table style="width: 100%; margin-top: 40px">
                  <thead>
                    <tr>
                      <th>From</th>
                      <th>To</th>
                      <th>Mode of travel</th>
                      <th>No. of Passengers</th>
                      <th>Amount claimed with taxes/ fees (in Rs.)n</th>
                      <th>Base fare allowed for all passengers (in Rs.)</th>
                      <th>Taxes allowed (In Rs.)</th>
                      <th>Amount granted with taxes (In Rs.)</th>
                      <!-- <th>Remark</th> -->
                      <!-- <th>From</th>
                      <th>To</th>
                      <th>Mode</th>
                      <th>No of Far</th>
                      <th>Claimed amount with taxes in</th>
                      <th>LTC 80 fare allowed</th>
                      <th>Taxes allowed in Rs</th>
                      <th>Amount Granted with taxes in Rs</th>
                      <th>Remark</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of formdata7; let k = index">
                      <td style="text-align: center">
                        {{ data.DEPARTURE_FROM }}
                      </td>
                      <td style="text-align: center">{{ data.ARRIVAL_TO }}</td>
                      <td style="text-align: center">
                        {{ data.TRAVEL_MODE_NAME }}
                      </td>
                      <td style="text-align: center">{{ data.NO_OF_FAIRS }}</td>
                      <td style="text-align: center">
                        {{ data.JOURNEY_CLAIMED_AMOUNT }}
                      </td>
                      <td style="text-align: center">{{ data.FAIR_PAID }}</td>
                      <td style="text-align: center">
                        {{ data.ALLOWED_TOTAL }}
                      </td>

                      <td style="text-align: center">
                        {{ data.AMOUNT_WITH_TAX }}
                      </td>
                      <!-- <td
                        style="text-align: center; word-break: break-all"
                        *ngIf="k == 0 && formdata7.length == 1"
                        rowspan="4"
                      >
                        {{ LTC5data.CALCULATION_REMARK }}
                      </td>
                      <td
                        style="text-align: center; word-break: break-all"
                        *ngIf="k == 0 && formdata7.length == 2"
                        rowspan="5"
                      >
                        {{ LTC5data.CALCULATION_REMARK }}
                      </td>
                      <td
                        style="text-align: center; word-break: break-all"
                        *ngIf="k == 0 && formdata7.length == 3"
                        rowspan="6"
                      >
                        {{ LTC5data.CALCULATION_REMARK }}
                      </td>
                      <td
                        style="text-align: center; word-break: break-all"
                        *ngIf="k == 0 && formdata7.length == 4"
                        rowspan="7"
                      >
                        {{ LTC5data.CALCULATION_REMARK }}
                      </td>
                      <td
                        style="text-align: center; word-break: break-all"
                        *ngIf="k == 0 && formdata7.length == 5"
                        rowspan="8"
                      >
                        {{ LTC5data.CALCULATION_REMARK }}
                      </td>
                      <td
                        style="text-align: center; word-break: break-all"
                        *ngIf="k == 0 && formdata7.length == 6"
                        rowspan="9"
                      >
                        {{ LTC5data.CALCULATION_REMARK }}
                      </td> -->
                    </tr>

                    <tr>
                      <td colspan="7" style="text-align: right">Total</td>
                      <td style="text-align: center">
                        <b>{{ amount }} </b>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="7" style="text-align: right">
                        Advance LTC Claimed
                      </td>
                      <td style="text-align: center">
                        <b> {{ LTC5data.AMOUNT_OF_ADVANCE }} </b>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="7" style="text-align: right">Net Amount</td>
                      <td style="text-align: center">
                        <b>{{ namount }} </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p
                  style="margin-top: 10px; font-weight: bold"
                  [innerHtml]="LTC5data.CALCULATION_SHEET_REMARK"
                >
                  <!-- <b>{{ LTC5data.CALCULATION_SHEET_REMARK }}</b> -->
                </p>
                <br /><br /><br /><br /><br /><br />

                <!-- <div>
                              <h2><u>Calculation Sheet</u></h2>
                              <p>Name : </p>
                              <p>Designation : </p>
                              <p>Destination : </p>
                              <p>Block Year : </p>
                              <table style="width: 100%;">
                                  <thead>
                                      <tr>
                                          <th style="width: 200px;"> From</th>
                                          <th style="width: 200px;">To </th>
                                          <th style="width: 200px;">Mode</th>
                                          <th>No of Far </th>
                                          <th style="width: 200px;">Claimed amount with taxes in</th>
                                          <th>LTC 80 fare allowed</th>
                                          <th>Taxes allowed in Rs</th>
                                          <th style="width: 200px;">Amount Granted with taxes in Rs</th>
                                          <th style="width: 200px;">Remarks</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
              
                                      </tr>
                                      <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
              
                                      </tr>
                                      <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
              
                                      </tr>
                                      <tr>
                                          <td colspan="7" style="text-align: right;"> Total </td>
                                          <td></td>
              
                                      </tr>
                                      <tr>
                                          <td colspan="7" style="text-align: right;">Advance LTC Claimed </td>
                                          <td></td>
              
                                      </tr>
                                      <tr>
                                          <td colspan="7" style="text-align: right;">Net Amount </td>
                                          <td></td>
              
                                      </tr>
                                  </tbody>
                              </table>
                          </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>
  </ng-template>
  <ng-template #printOrderModalFooter>
    <button nz-button nzType="default" (click)="printOrderModalCancel()">
      Close
    </button>

    <button
      nz-button
      nzType="default"
      [useExistingCss]="true"
      printSectionId="calculation"
      ngxPrint
    >
      Print
    </button>

    <button
      nz-button
      nzType="primary"
      [nzLoading]="pdfDownload"
      (click)="openpdf()"
    >
      Download
    </button>
  </ng-template>
</nz-modal>
