export class HospitalMaster {
  ID: number;
  // city_name: any;
  // name: any;
  // address: any;
  // contacts: any = [];
  // emails: any = [];
  // nodalofficer: any = [];
  // mobilenos: any = [];
  // accreditation: any;
  // empanelledfor: any = [];
  // memorandumid: any;
  // IS_ACTIVE: boolean;
  // CITY_ID:any
  // NAME:string=''

  CITY_ID: number;
  NAME: any ;
  ADDRESS: string = '';
  CONTACT_NOS: any = [];
  EMAILS: any = [];
  NODAL_OFFICER_NAMES: any = [];
  MOBILE_NOS: any = [];
  ACCREDITATION:any
  FACILITIES_ID: any = [];
  NOTIFICATION_MEMORAMDUM_ID: number;
  STATUS: boolean = true;
  HOSPITAL_TYPE:any
}
