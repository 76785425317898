import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import * as html2pdf from 'html2pdf.js';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Tourmaster } from 'src/app/Models/Tourmaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-adheshorder',
  templateUrl: './adheshorder.component.html',
  styleUrls: ['./adheshorder.component.css'],
})
export class AdheshorderComponent implements OnInit {
  @Input() drawerClose;
  @Input() data;
  @Input() adheshdata: Tourmaster;
  // @Input() SECTION_TYPE: any;
  @Input() SIGNNAME: any;
  @Input() NAME_HN: any;
  @Input() OFFICE_NAME: any;
  @Input() OFFICE_NAME_HN: any;
  @Input() POST: any;
  @Input() POST_HN: any;
  constructor(
    private datePipe: DatePipe,
    private message: NzNotificationService,
    private api: ApiService
  ) {}
  ngOnInit(): void {
    this.getAllUsers();
  }
  visible = false;

  open(): void {
    this.visible = true;
  }

  close(): void {
    this.drawerClose();
  }

  printModel() {
    this.api
      .gettransferdata(0, 0, '', '', ' AND ID = ' + this.adheshdata.ID)
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            this.loadingRecords = false;
            this.adheshdata = data['data'][0];
            // this.printOrderModalVisible = true;
          } else {
            // this.message.error("Can't Load Data", '');
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  Signaturearray = [];
  getAllUsers() {
    this.api.getSignature(0, 0, 'ID', 'desc', '').subscribe(
      (data) => {
        if (data['code'] == 200) {
          this.Signaturearray = data['data'];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  signatureData: any = [];
  Name = '';
  signature(event: any) {
    var f = this.Signaturearray.filter((item) => item.ID == event);
    // this.SECTION_TYPE = f[0]['SECTION_TYPE'];
    this.OFFICE_NAME = f[0]['OFFICE_NAME'];

    this.SIGNNAME = f[0]['NAME'];
    this.NAME_HN = f[0]['NAME_HN'];
    this.OFFICE_NAME_HN = f[0]['OFFICE_NAME_HN'];
    this.POST = f[0]['POST'];
    this.POST_HN = f[0]['POST_HN'];
  }
  openpdf() {
    const element = document.getElementById('excel-table');
    const opt = {
      margin: 0.2,
      filename: 'Adhesh.pdf',
      image: { type: 'jpeg', quality: 7 },
      html2canvas: { scale: 7 },
      jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
    };
    html2pdf().from(element).set(opt).save();
  }

  loadingRecords = false;
  printOrderModalVisible: boolean = false;
  Save() {
    this.loadingRecords = true;
    this.api.updatetour(this.adheshdata).subscribe((successCode) => {
      if (successCode.code == '200') {
        this.loadingRecords = false;
        // this.printModel();
        this.api
          .gettouralldata(0, 0, '', '', ' AND ID = ' + this.adheshdata.ID)
          .subscribe(
            (data) => {
              if (data['code'] == 200) {
                this.loadingRecords = false;
                this.adheshdata = data['data'][0];
                this.printOrderModalVisible = true;
              } else {
                // this.message.error("Can't Load Data", '');
              }
            },
            (err) => {
              console.log(err);
            }
          );
        this.message.success('Information Saved Successfully...', '');
      } else {
        this.message.error('Information Has Not Saved...', '');
        this.loadingRecords = false;
      }
    });
  }
  getwidth() {
    if (window.innerWidth <= 400) {
      return 400;
    } else {
      return 850;
    }
  }

  printOrderModalCancel() {
    this.printOrderModalVisible = false;
  }
}
