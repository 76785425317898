import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { EmployeeMaster } from '../Models/Employee';
import { ApiService } from '../Service/api.service';
import countries from './_files/countries.json';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  EMAIL_ID = '';
  PASSWORD = '';
  passwordVisible = false;
  passwordVisible1 = false;
  isloginSpinning = false;
  userId = sessionStorage.getItem('userId');
  userName = sessionStorage.getItem('userName');
  roleId = sessionStorage.getItem('roleId');
  isLogedIn = false;
  emailId = '';
  isVisible = false;
  mobpattern = /^[6-9]\d{9}$/;
  sendOtp = false;
  password = false;
  mobileverified = false;
  NEWPASSWORD = '';
  CONFPASSWORD = '';
  fogotdata = false;
  MOBILE_NO = sessionStorage.getItem('mobileno');
  KEYWORD = 'OLD';
  otpSpinning = false;
  myInterval2: any;
  showTimer = false;
  timerMobile: any;
  showconfirm = false;
  isSpinning = false;
  Mobile1 = '';
  USER_ID = 0;
  OTP = '';
  resendTrue = false;
  mobilepattrn = /[6-9]{1}[0-9]{9}/;
  NEW_PASSWORD: string;
  constructor(
    private router: Router,
    private api: ApiService,
    private message: NzNotificationService,
    private cookie: CookieService
  ) {}

  ngOnInit(): void {
    if (this.cookie.get('token') === '' || this.cookie.get('token') === null) {
      this.router.navigate(['/login']);
    } else {
      if (this.userId == null || this.userName == null || this.roleId == null)
        this.api.logoutForSessionValues();
      else {
        this.isLogedIn = true;
        this.router.navigate(['/admin-dashboard']);
      }
    }
  }

  login(): void {
    if (this.EMAIL_ID == '' && this.PASSWORD == '')
      this.message.error('Please Enter Email Id and Password', '');
    else {
      this.isloginSpinning = true;
      this.api.login(this.EMAIL_ID, this.PASSWORD).subscribe(
        (data) => {
          console.log(data['data']);

          if (data['code'] == 200) {
            this.cookie.set(
              'token',
              data['data'][0]['token'],
              365,
              '',
              '',
              false,
              'Strict'
            );
            sessionStorage.setItem(
              'userId',
              data['data'][0]['UserData'][0]['USER_ID']
            );
            sessionStorage.setItem(
              'userName',
              data['data'][0]['UserData'][0]['NAME']
            );
            sessionStorage.setItem(
              'emailId',
              data['data'][0]['UserData'][0]['EMAIL_ID']
            );
            sessionStorage.setItem(
              'roleId',
              data['data'][0]['UserData'][0]['ROLE_DETAILS'][0]['ROLE_ID']
            );
            sessionStorage.setItem(
              'level',
              data['data'][0]['UserData'][0]['ROLE_DETAILS'][0]['LEVEL']
            );
            sessionStorage.setItem(
              'roleName',
              data['data'][0]['UserData'][0]['ROLE_DETAILS'][0]['ROLE_NAME']
            );
            sessionStorage.setItem(
              'userId',
              data['data'][0]['UserData'][0]['USER_ID']
            );
            sessionStorage.setItem(
              'clientmasterid',
              data['data'][0]['UserData'][0]['CLIENT_MASTER_ID']
            );
            this.message.info(data['message'], '');
            this.emailId = sessionStorage.getItem('emailId');
            clearInterval(this.myInterval2);
            window.location.reload();
            setTimeout(() => {
              this.isloginSpinning = false;
            }, 1000);
            this.api.addLog('L', 'Login Successfully ', this.emailId).subscribe(
              (data) => {
                console.log(data);
              },
              (err) => {
                console.log(err);
                if (err['ok'] == false)
                  this.message.error('Server Not Found', '');
              }
            );
          } else {
            this.message.error(data['message'], '');
            setTimeout(() => {
              this.isloginSpinning = false;
            }, 1000);
            this.api
              .addLog(
                'A',
                data['message'] +
                  ' ' +
                  'Login Failed with EmailId= ' +
                  this.EMAIL_ID +
                  ' and Password=' +
                  this.PASSWORD,
                '0'
              )
              .subscribe(
                (data) => {
                  console.log(data);
                },
                (err) => {
                  console.log(err);
                  if (err['ok'] == false)
                    this.message.error('Server Not Found', '');
                }
              );
          }
        },
        (err) => {
          this.message.error('Server not found', '');
          this.isloginSpinning = false;
        }
      );
    }
  }
  showModal(): void {
    this.fogotdata = true;
    this.isVisible = true;
    this.MOBILE_NO = '';
    this.OTP = '';
    this.NEWPASSWORD = '';
    this.CONFPASSWORD = '';
    this.PASSWORD = '';
  }
  omit(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  check() {
    console.log();
    if (this.MOBILE_NO.trim() != '' && this.MOBILE_NO != undefined) {
      this.otpSpinning = true;
      this.api.otplogin(this.MOBILE_NO).subscribe(
        (successCode) => {
          this.otpSpinning = false;
          if (successCode['code'] == 200) {
            this.sendOtp = true;
            this.mobileverified = false;
            this.password = false;
            this.message.success('Otp Sent Sucessfully', '');
            this.resendTrue = true;
            this.StartTimerMobile2();
          } else if (successCode['code'] == '404') {
            this.message.error('Please Enter Registered Mobile Number', '');
          } else {
            this.message.error('Something went wrong please try later', '');
          }
        },
        (err) => {
          this.message.error('Something went wrong. Please try again.', '');
          this.otpSpinning = false;
        }
      );
    } else {
      this.message.error('Mobile Number Required...', '');
    }
  }
  StartTimerMobile2() {
    this.showTimer = true;
    this.timerMobile = 0;
    this.myInterval2 = setInterval(() => {
      if (this.timerMobile == 180) {
        clearInterval(this.myInterval2);
        this.showTimer = false;
      } else {
        this.showTimer = true;
        this.timerMobile++;
      }
    }, 1000);
  }
  onOtpChange(otp: any) {
    this.OTP = otp.toString();
    console.log('the opt is ', this.OTP);
  }

  resendOTP() {
    this.check();
  }

  varify() {
    if (this.OTP == '') {
      this.message.error('Please Enter OTP', '');
    }
    this.otpSpinning = true;
    this.api.verifyOTP(this.MOBILE_NO, this.OTP).subscribe(
      (successCode) => {
        console.log(this.OTP);
        this.otpSpinning = false;
        if (successCode['code'] == '200') {
          this.mobileverified = true;

          sessionStorage.setItem('mobileno', this.MOBILE_NO);
          this.sendOtp = false;
          this.password = true;
          this.message.success('Mobile no. varified successfully!', '');
          this.USER_ID = successCode['data'][0]['UserData'][0]['ID'];
          this.cookie.set(
            'token',
            successCode['data'][0]['token'],
            365,
            '',
            '',
            true,
            'None'
          );
          console.log(
            'token',
            successCode['data'][0]['token'],
            365,
            '',
            '',
            true,
            'None'
          );
        } else if (successCode['code'] == '300') {
          this.message.error('Invalid OTP.Please Enter Correct OTP.', '');
          this.OTP = '';
        } else if (successCode['code'] == '400') {
          this.message.error('Something Went Wrong.Please Try Again.', '');
        }
      },
      (err) => {
        this.message.error('Something went wrong. Please try again.', '');
        this.isSpinning = false;
      }
    );
  }

  confpass() {
    this.isSpinning = false;
    if (this.NEW_PASSWORD == undefined || this.NEW_PASSWORD.trim() == '') {
      this.showconfirm = false;
      this.message.error('Please Enter New Password', '');
    } else if (
      this.CONFPASSWORD == undefined ||
      this.CONFPASSWORD.trim() == ''
    ) {
      this.showconfirm = false;
      this.message.error('Please Enter Correct Confirm Password', '');
    } else if (this.NEW_PASSWORD == this.CONFPASSWORD) {
      this.api
        .userchangepassordforgot(this.MOBILE_NO, this.NEW_PASSWORD)
        .subscribe((successCode) => {
          if (successCode['code'] == '200') {
            this.message.success('Password Changed Successfully...', '');
            window.location.reload();
            this.isVisible = false;
            this.NEW_PASSWORD = '';
            this.CONFPASSWORD = '';
            this.PASSWORD = '';
            this.isSpinning = false;
          } else {
            this.message.error('Password Change Failed...', '');
            this.isSpinning = false;
          }
        });
    } else {
      this.message.error('New Password & Confirm Password Must Be Same', '');
    }
  }

  handleCancel(): void {
    this.isVisible = false;
    this.MOBILE_NO = '';
    this.OTP = '';
    this.NEWPASSWORD = '';
    this.CONFPASSWORD = '';
    this.PASSWORD = '';
  }

  changepass(): void {
    this.NEW_PASSWORD = '';
    this.CONFPASSWORD = '';
    this.PASSWORD = '';
    this.isVisible = true;
  }
}
