import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ExportDirective } from './directives/export.directive';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IconsProviderModule } from './icons-provider.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { CookieService } from 'ngx-cookie-service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCommentModule } from 'ng-zorro-antd/comment';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzBackTopModule } from 'ng-zorro-antd/back-top';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';

import { FormsComponent } from './pages/CommonModule/Forms/forms/forms.component';
import { FormComponent } from './pages/CommonModule/Forms/form/form.component';
import { RolesComponent } from './pages/CommonModule/Roles/roles/roles.component';
import { RoleComponent } from './pages/CommonModule/Roles/role/role.component';
import { RoledetailsComponent } from './pages/CommonModule/Roles/roledetails/roledetails.component';
import { UsersComponent } from './pages/CommonModule/Users/users/users.component';
import { UserComponent } from './pages/CommonModule/Users/user/user.component';
import { LoginComponent } from './login/login.component';

// import { AdmindashboardComponent } from './pages/CommonModule/admindashboard/admindashboard.component';
import { AngularEditorModule } from '@kolkov/angular-editor';

// import { ClientMasterComponent } from '../app/pages/BasicForms/ClientM/client-master/client-master.component';
// import { ClientDrawerComponent } from './pages/BasicForms/ClientM/client-drawer/client-drawer.component';

// import { ProjectMasterComponent } from './pages/BasicForms/ProjectM/project-master/project-master.component';
// import { ProjectDrawerComponent } from './pages/BasicForms/ProjectM/project-drawer/project-drawer.component';
// import { ProjectMappingComponent } from './pages/BasicForms/ProjectM/project-mapping/project-mapping.component';

// import { ModuleMasterComponent } from './pages/BasicForms/ModuleM/module-master/module-master.component';
// import { ModuleDrawerComponent } from './pages/BasicForms/ModuleM/module-drawer/module-drawer.component';
// import { ModuleMappingComponent } from './pages/BasicForms/ModuleM/module-mapping/module-mapping.component';

// import { FeatureMasterComponent } from './pages/BasicForms/Feature/feature-master/feature-master.component';
// import { FeatureDrawerComponent } from './pages/BasicForms/Feature/feature-drawer/feature-drawer.component';
// import { FeatureMappingComponent } from './pages/BasicForms/Feature/feature-mapping/feature-mapping.component';

// import { CategoryMasterComponent } from './pages/BasicForms/Category/category-master/category-master.component';
// import { CategoryDrawerComponent } from './pages/BasicForms/Category/category-drawer/category-drawer.component';

// import { TaskMasterComponent } from './pages/BasicForms/TaskM/task-master/task-master.component';
// import { TaskDrawerComponent } from './pages/BasicForms/TaskM/task-drawer/task-drawer.component';
// import { TaskSubtaskMappingComponent } from './pages/BasicForms/TaskM/task-subtask-mapping/task-subtask-mapping.component';

// import { AttachmentMasterComponent } from '../app/pages/BasicForms/AttachmentM/attachment-master/attachment-master.component';
// import { AttachmentDrawerComponent } from '../app/pages/BasicForms/AttachmentM/attachment-drawer/attachment-drawer.component';

// import { WorklogComponent } from '../app/pages/BasicForms/workLog/worklog/worklog.component';
// import { WorklogDrawerComponent } from './pages/BasicForms/workLog/worklog-drawer/worklog-drawer.component';

// import { AssigneeMappingComponent } from './pages/BasicForms/assignee-mapping/assignee-mapping.component';

// import { CommentComponent } from '../app/pages/BasicForms/Comment/comment/comment.component';
// import { CommentDrawerComponent } from '../app/pages/BasicForms/Comment/comment-drawer/comment-drawer.component';

// import { TaskTransferHistoryComponent } from '../app/pages/BasicForms/taskTransfer/task-transfer-history/task-transfer-history.component';
// import { TaskTransferDrawerComponent } from '../app/pages/BasicForms/taskTransfer/task-transfer-drawer/task-transfer-drawer.component';

// import { TypeMasterComponent } from '../app/pages/BasicForms/Type/type-master/type-master.component';
// import { TypeDrawerComponent } from '../app/pages/BasicForms/Type/type-drawer/type-drawer.component';

// import { ModuleFeatureViewComponent } from '../app/pages/BasicForms/ModuleFeatureView/module-feature-view/module-feature-view.component';
// import { FeatureViewComponent } from '../app/pages/BasicForms/ModuleFeatureView/feature-view/feature-view.component';

// import { KanbanComponent } from '../app/pages/BasicForms/Kanban/kanban/kanban.component';
// import { KanbanProjectWiseComponent } from '../app/pages/BasicForms/Kanban/kanban-project-wise/kanban-project-wise.component';

// import { RoadmapComponent } from '../app/pages/BasicForms/roadmap/roadmap.component';

import { SecondToHoursPipe } from '../app/pipes/second-to-hours.pipe';
import { DashboardComponent } from './pages/CommonModule/Dashboard/dashboard/dashboard.component';
import { HospitalmasterlistComponent } from '../app/pages/HospitalMgtBasicForm/HospitalMaster/hospitalmasterlist/hospitalmasterlist.component';
import { AddhospitalmasterComponent } from '../app/pages/HospitalMgtBasicForm/HospitalMaster/addhospitalmaster/addhospitalmaster.component';
import { CityMasterComponent } from '../app/pages/HospitalMgtBasicForm/City/city-master/city-master.component';
import { CityDrawerComponent } from '../app/pages/HospitalMgtBasicForm/City/city-drawer/city-drawer.component';
import { NotificationAndMemorandomMasterComponent } from '../app/pages/HospitalMgtBasicForm/NotificationAndMemorandom/notification-and-memorandom-master/notification-and-memorandom-master.component';
import { NotificationAndMemorandomDrawerComponent } from '../app/pages/HospitalMgtBasicForm/NotificationAndMemorandom/notification-and-memorandom-drawer/notification-and-memorandom-drawer.component';
import { ListinvestigationComponent } from '../app/pages/HospitalMgtBasicForm/Investigation-procedure/listinvestigation/listinvestigation.component';
import { AddtinvestigationComponent } from '../app/pages/HospitalMgtBasicForm/Investigation-procedure/addtinvestigation/addtinvestigation.component';
import { AddMedicalFacilityComponent } from '../app/pages/HospitalMgtBasicForm/MedicalFacility/add-medical-facility/add-medical-facility.component';
import { MedicalFacilitiesMasterComponent } from '../app/pages/HospitalMgtBasicForm/MedicalFacility/medical-facilities-master/medical-facilities-master.component';
import { InvestigationComponentComponent } from '../app/pages/HospitalMgtBasicForm/InvestigationCategory/investigation-component/investigation-component.component';
import { InvestigationDrawerComponent } from '../app/pages/HospitalMgtBasicForm/InvestigationCategory/investigation-drawer/investigation-drawer.component';
import { ClaimApplicationMasterComponent } from './pages/HospitalMgtBasicForm/ClaimApplication/claim-application-master/claim-application-master.component';
import { ClaimApplicationDrawerComponent } from './pages/HospitalMgtBasicForm/ClaimApplication/claim-application-drawer/claim-application-drawer.component';
import { NgxPrintModule } from 'ngx-print';
import { ClaimstageslistComponent } from './pages/HospitalMgtBasicForm/ClaimStagesMaster/claimstageslist/claimstageslist.component';
import { AddclaimstagesComponent } from './pages/HospitalMgtBasicForm/ClaimStagesMaster/addclaimstages/addclaimstages.component';
import { ClaimsComponent } from './pages/HospitalMgtBasicForm/Claim/claims/claims.component';
import { ApplyclaimComponent } from './pages/HospitalMgtBasicForm/Claim/applyclaim/applyclaim.component';
import { AddInvestigationMasterComponent } from './pages/HospitalMgtBasicForm/Claim/add-investigation-master/add-investigation-master.component';
import { ClaimLogDrawerComponent } from './pages/HospitalMgtBasicForm/Claim/claim-log-drawer/claim-log-drawer.component';
import { ClaimUpdatedComponent } from './pages/HospitalMgtBasicForm/Claim/claim-updated/claim-updated.component';
import { AddInvestigationMasterRevisedComponent } from './pages/HospitalMgtBasicForm/Claim/add-investigation-master-revised/add-investigation-master-revised.component';
import { EmployeelistComponent } from './pages/HospitalMgtBasicForm/Employee/employeelist/employeelist.component';
import { EmployeeaddComponent } from './pages/HospitalMgtBasicForm/Employee/employeeadd/employeeadd.component';
import { ClaimmasterlistComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/claimmasterlist/claimmasterlist.component';
import { AddclaimmasterComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/addclaimmaster/addclaimmaster.component';
import { ImportExcelInvestigationRateComponent } from './pages/HospitalMgtBasicForm/import-excel-investigation-rate/import-excel-investigation-rate.component';
import { DatabasetablemasterlistComponent } from './pages/HospitalMgtBasicForm/databasetablemaster/databasetablemasterlist/databasetablemasterlist.component';
import { AdddatabasetablemasterComponent } from './pages/HospitalMgtBasicForm/databasetablemaster/adddatabasetablemaster/adddatabasetablemaster.component';
// import { EmployeeaddComponent } from './pages/employeeadd/employeeadd.component';
// import { EmployeelistComponent } from './pages/employeelist/employeelist.component';
import { HospitalWiseSummaryReportComponent } from './pages/Reports/Medical Reports/hospital-wise-summary-report/hospital-wise-summary-report.component';
import { InspectorWiseAmountStatsReportComponent } from './pages/Reports/Medical Reports/inspector-wise-amount-stats-report/inspector-wise-amount-stats-report.component';
import { InspectorWiseDetailedSummaryReportComponent } from './pages/Reports/Medical Reports/inspector-wise-detailed-summary-report/inspector-wise-detailed-summary-report.component';
import { InspectorWiseSummaryReportComponent } from './pages/Reports/Medical Reports/inspector-wise-summary-report/inspector-wise-summary-report.component';
import { StageWiseSummaryReportComponent } from './pages/Reports/Medical Reports/stage-wise-summary-report/stage-wise-summary-report.component';
import { EmployeeloginComponent } from './employeelogin/employeelogin.component';
import { EmployeeupdateComponent } from './employeeupdate/employeeupdate.component';
import { AddnewfileformComponent } from './pages/HospitalMgtBasicForm/FilesMaster/addnewfileform/addnewfileform.component';
import { FilemastertableComponent } from './pages/HospitalMgtBasicForm/FilesMaster/filemastertable/filemastertable.component';
import { FilehierarchyComponent } from './pages/HospitalMgtBasicForm/FilesMaster/filemodule/filehierarchy/filehierarchy.component';
import { FilehierarchiesComponent } from './pages/HospitalMgtBasicForm/FilesMaster/filemodule/filehierarchies/filehierarchies.component';
import { ViewlogsComponent } from './pages/HospitalMgtBasicForm/FilesMaster/viewlogs/viewlogs.component';
import { HispitalWiseAmountStatsComponent } from './pages/Reports/Medical Reports/hospital-wise-amount-stats/hispital-wise-amount-stats.component';
import { AdmindashboardComponent } from './pages/CommonModule/admindashboard/admindashboard.component';
import { BeneficiaryTypeAmountStatsComponent } from './pages/Reports/Medical Reports/beneficiary-type-amount-stats/beneficiary-type-amount-stats.component';
import { EmployeewisedetailedComponent } from './pages/Reports/Medical Reports/employeewisedetailed/employeewisedetailed.component';
import { EmployeeWiseSummaryReportComponent } from './pages/Reports/Medical Reports/employee-wise-summary-report/employee-wise-summary-report.component';
import { EmployeeWiseAmountStatsComponent } from './pages/Reports/Medical Reports/employee-wise-amount-stats/employee-wise-amount-stats.component';
import { BeneficiaryTypeWiseDetailedComponent } from './pages/Reports/Medical Reports/beneficiary-type-wise-detailed/beneficiary-type-wise-detailed.component';
import { BeneficiaryTypeSummaryReportsComponent } from './pages/Reports/Medical Reports/beneficiary-type-summary-reports/beneficiary-type-summary-reports.component';
import { AddclaimemployeeComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/addclaimemployee/addclaimemployee.component';
import { EmployeeclaimlistComponent } from './pages/HospitalMgtBasicForm/Employee/employeeclaimlist/employeeclaimlist.component';
import { EmployeeclaimaddComponent } from './pages/HospitalMgtBasicForm/Employee/employeeclaimadd/employeeclaimadd.component';
import { UserWiseFilesDetailedReportComponent } from './pages/Reports/Medical Reports/user-wise-files-detailed-report/user-wise-files-detailed-report.component';
import { UserWiseFilesSummaryReportComponent } from './pages/Reports/Medical Reports/user-wise-files-summary-report/user-wise-files-summary-report.component';
import { PendingClaimsComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/pending-claims/pending-claims.component';
import { HierarchyWiseFilesSummaryReportComponent } from './pages/Reports/Medical Reports/hierarchy-wise-files-summary-report/hierarchy-wise-files-summary-report.component';
import { DayWiseFilesSummaryReportComponent } from './pages/Reports/Medical Reports/day-wise-files-summary-report/day-wise-files-summary-report.component';
import { UserfileactivitydetailedreportComponent } from './pages/Reports/Medical Reports/userfileactivitydetailedreport/userfileactivitydetailedreport.component';
import { UserWiseFilesClosureDetailedReportComponent } from './pages/Reports/Medical Reports/user-wise-files-closure-detailed-report/user-wise-files-closure-detailed-report.component';
import { HospitalwisedetailedComponent } from './pages/Reports/Medical Reports/hospitalwisedetailed/hospitalwisedetailed.component';
import { AddforwardmasterComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/ForwardMaster/addforwardmaster/addforwardmaster.component';
import { ForwardmasterlistComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/ForwardMaster/forwardmasterlist/forwardmasterlist.component';
import { FilesystemdashboardComponent } from './pages/CommonModule/filesystemdashboard/filesystemdashboard.component';
import { ReportsviewComponent } from './pages/reportsview/reportsview.component';
import { AddsignaturemasterComponent } from './pages/HospitalMgtBasicForm/SignatureMaster/addsignaturemaster/addsignaturemaster.component';
import { SignaturemasterlistComponent } from './pages/HospitalMgtBasicForm/SignatureMaster/signaturemasterlist/signaturemasterlist.component';
import { InfoComponent } from './pages/HospitalMgtBasicForm/info/info.component';
import { RefexpoorderComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/OrderDrawers/refexpoorder/refexpoorder.component';
import { RefexpopermissionComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/OrderDrawers/refexpopermission/refexpopermission.component';
import { RefhodorderComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/OrderDrawers/refhodorder/refhodorder.component';
import { RefhodpermissionComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/OrderDrawers/refhodpermission/refhodpermission.component';
import { DelaycondolenceorderComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/OrderDrawers/delaycondolenceorder/delaycondolenceorder.component';
import { DelaycondolencepermissionComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/OrderDrawers/delaycondolencepermission/delaycondolencepermission.component';
import { TransferaddComponent } from './pages/HospitalMgtBasicForm/Transfer/Transfer/transferadd/transferadd.component';
import { TransferlistComponent } from './pages/HospitalMgtBasicForm/Transfer/Transfer/transferlist/transferlist.component';
import { DetailsjorneyComponent } from './pages/HospitalMgtBasicForm/Transfer/detailsjorney/detailsjorney.component';
import { Form1Component } from './pages/HospitalMgtBasicForm/Transfer/form1/form1.component';
import { Form2Component } from './pages/HospitalMgtBasicForm/Transfer/form2/form2.component';
import { Form3Component } from './pages/HospitalMgtBasicForm/Transfer/form3/form3.component';
import { OfficeaddComponent } from './pages/HospitalMgtBasicForm/Transfer/officemaster/officeadd/officeadd.component';
import { OfficelistComponent } from './pages/HospitalMgtBasicForm/Transfer/officemaster/officelist/officelist.component';
import { TravelclassaddComponent } from './pages/HospitalMgtBasicForm/Transfer/travelclassmaster/travelclassadd/travelclassadd.component';
import { TravelclasslistComponent } from './pages/HospitalMgtBasicForm/Transfer/travelclassmaster/travelclasslist/travelclasslist.component';
import { TraveladdComponent } from './pages/HospitalMgtBasicForm/Transfer/travelmaster/traveladd/traveladd.component';
import { TravellistComponent } from './pages/HospitalMgtBasicForm/Transfer/travelmaster/travellist/travellist.component';
import { AddHODPermissionComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/PermissionMaster/HODPermissionMaster/add-hodpermission/add-hodpermission.component';
import { HODPermissionlistComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/PermissionMaster/HODPermissionMaster/hodpermissionlist/hodpermissionlist.component';
import { AdddelaycondolationComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/PermissionMaster/DelayCondolationMaster/adddelaycondolation/adddelaycondolation.component';
import { DelaycondolationlistComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/PermissionMaster/DelayCondolationMaster/delaycondolationlist/delaycondolationlist.component';
import { AddexpostfactoComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/PermissionMaster/ExpostFactoMaster/addexpostfacto/addexpostfacto.component';
import { ExpostfactolistComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/PermissionMaster/ExpostFactoMaster/expostfactolist/expostfactolist.component';
import { AddComponent } from './pages/HospitalMgtBasicForm/Transfer print/add/add.component';
import { CertificateComponent } from './pages/HospitalMgtBasicForm/Transfer print/certificate/certificate.component';
import { OrderFormComponent } from './pages/HospitalMgtBasicForm/Transfer print/order-form/order-form.component';
import { TravelingAllowanceComponent } from './pages/HospitalMgtBasicForm/Transfer print/traveling-allowance/traveling-allowance.component';
import { DetailsjourneyperfomedbyrailComponent } from './pages/HospitalMgtBasicForm/Transfer/detailsjourneyperfomedbyrail/detailsjourneyperfomedbyrail.component';
import { TransportationchargesofpersonaleffectsComponent } from './pages/HospitalMgtBasicForm/Transfer/transportationchargesofpersonaleffects/transportationchargesofpersonaleffects.component';
import { AddltcmasterComponent } from './pages/HospitalMgtBasicForm/LTC/LTCMaster/addltcmaster/addltcmaster.component';
import { LtcmasterlistComponent } from './pages/HospitalMgtBasicForm/LTC/LTCMaster/ltcmasterlist/ltcmasterlist.component';
import { FamilymembersComponent } from './pages/HospitalMgtBasicForm/LTC/familymembers/familymembers.component';
import { DetailsofjourneyComponent } from './pages/HospitalMgtBasicForm/LTC/detailsofjourney/detailsofjourney.component';
import { ParticularsofjourneyComponent } from './pages/HospitalMgtBasicForm/LTC/particularsofjourney/particularsofjourney.component';
import { PlacesconnectedbyrailComponent } from './pages/HospitalMgtBasicForm/LTC/placesconnectedbyrail/placesconnectedbyrail.component';
import { AddgeneratedelaycondonationComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/PermissionMaster/GeneratePermission/DelayCondonation/addgeneratedelaycondonation/addgeneratedelaycondonation.component';
import { GeneratedelaycondonationlistComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/PermissionMaster/GeneratePermission/DelayCondonation/generatedelaycondonationlist/generatedelaycondonationlist.component';
import { GenerateexpostfactolistComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/PermissionMaster/GeneratePermission/ExpostFacto/generateexpostfactolist/generateexpostfactolist.component';
import { AddgeneratehodComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/PermissionMaster/GeneratePermission/HOD/addgeneratehod/addgeneratehod.component';
import { GeneratehodlistComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/PermissionMaster/GeneratePermission/HOD/generatehodlist/generatehodlist.component';
import { CalculationComponent } from './pages/HospitalMgtBasicForm/LTC/PrintDocument/calculation/calculation.component';
import { GoltcComponent } from './pages/HospitalMgtBasicForm/LTC/PrintDocument/goltc/goltc.component';
import { LtcsanstionorderComponent } from './pages/HospitalMgtBasicForm/LTC/PrintDocument/ltcsanstionorder/ltcsanstionorder.component';
import { Ltcsheet1Component } from './pages/HospitalMgtBasicForm/LTC/PrintDocument/ltcsheet1/ltcsheet1.component';
import { Ltcsheet2Component } from './pages/HospitalMgtBasicForm/LTC/PrintDocument/ltcsheet2/ltcsheet2.component';
import { LeaveTravelConcessionBillComponent } from './pages/HospitalMgtBasicForm/LTC/PrintDocument/leave-travel-concession-bill/leave-travel-concession-bill.component';
import { NatureComponent } from './pages/HospitalMgtBasicForm/LTC/PrintDocument/nature/nature.component';
import { GAR23Component } from './pages/HospitalMgtBasicForm/ClaimMaster/gar23/gar23.component';
import { ClaimcertificateComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/claimcertificate/claimcertificate.component';
import { AddgenerateexpostfactoComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/PermissionMaster/GeneratePermission/ExpostFacto/addgenerateexpostfacto/addgenerateexpostfacto.component';
import { DelayorderComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/PermissionMaster/DelayCondolationMaster/delayorder/delayorder.component';
import { DelayletterComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/PermissionMaster/DelayCondolationMaster/delayletter/delayletter.component';
import { ExpostorderComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/PermissionMaster/ExpostFactoMaster/expostorder/expostorder.component';
import { ExpostletterComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/PermissionMaster/ExpostFactoMaster/expostletter/expostletter.component';
import { HodorderComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/PermissionMaster/HODPermissionMaster/hodorder/hodorder.component';
import { HodletterComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/PermissionMaster/HODPermissionMaster/hodletter/hodletter.component';
import { AaykarbhvanComponent } from './pages/HospitalMgtBasicForm/Tour/Print Tour/aaykarbhvan/aaykarbhvan.component';
import { AdheshorderComponent } from './pages/HospitalMgtBasicForm/Tour/Print Tour/adheshorder/adheshorder.component';
import { BillsectionComponent } from './pages/HospitalMgtBasicForm/Tour/Print Tour/billsection/billsection.component';
import { CertificateduplicateComponent } from './pages/HospitalMgtBasicForm/Tour/Print Tour/certificateduplicate/certificateduplicate.component';
import { ChecklistComponent } from './pages/HospitalMgtBasicForm/Tour/Print Tour/checklist/checklist.component';
import { PartAComponent } from './pages/HospitalMgtBasicForm/Tour/Print Tour/part-a/part-a.component';
import { PlacealongsideComponent } from './pages/HospitalMgtBasicForm/Tour/Print Tour/placealongside/placealongside.component';
import { SelfDeclarationCertificateComponent } from './pages/HospitalMgtBasicForm/Tour/Print Tour/self-declaration-certificate/self-declaration-certificate.component';
import { TourPartBComponent } from './pages/HospitalMgtBasicForm/Tour/Print Tour/tour-part-b/tour-part-b.component';
import { ToursantionorderComponent } from './pages/HospitalMgtBasicForm/Tour/Print Tour/toursantionorder/toursantionorder.component';
import { TravellingallouncebillComponent } from './pages/HospitalMgtBasicForm/Tour/Print Tour/travellingallouncebill/travellingallouncebill.component';
import { AddtourmasterComponent } from './pages/HospitalMgtBasicForm/Tour/TourMaster/addtourmaster/addtourmaster.component';
import { TourmasterlistComponent } from './pages/HospitalMgtBasicForm/Tour/TourMaster/tourmasterlist/tourmasterlist.component';
import { CitytourComponent } from './pages/HospitalMgtBasicForm/Tour/citytour/citytour.component';
import { DetailsandpurposetourComponent } from './pages/HospitalMgtBasicForm/Tour/detailsandpurposetour/detailsandpurposetour.component';
import { JourneyparticularcityComponent } from './pages/HospitalMgtBasicForm/Tour/journeyparticularcity/journeyparticularcity.component';
import { JourneyparticulartoursComponent } from './pages/HospitalMgtBasicForm/Tour/journeyparticulartours/journeyparticulartours.component';
import { TourparticularhotelsComponent } from './pages/HospitalMgtBasicForm/Tour/tourparticularhotels/tourparticularhotels.component';
import { ClaimMasterReportComponent } from './pages/Reports/Medical Reports/claim-master-report/claim-master-report.component';
import { TransferchecklistComponent } from './pages/HospitalMgtBasicForm/Transfer print/transferchecklist/transferchecklist.component';
import { AdvancedAmountJourneyDetailComponent } from './pages/Reports/Transfer Reports/advanced-amount-journey-detail/advanced-amount-journey-detail.component';
import { DateWiseModeWiseCountComponent } from './pages/Reports/Transfer Reports/date-wise-mode-wise-count/date-wise-mode-wise-count.component';
import { EmployeeWiseDetailedReportComponent } from './pages/Reports/Transfer Reports/employee-wise-detailed-report/employee-wise-detailed-report.component';
import { ModeWiseAmountStatComponent } from './pages/Reports/Transfer Reports/mode-wise-amount-stat/mode-wise-amount-stat.component';
import { ModeWiseClassWiseCountComponent } from './pages/Reports/Transfer Reports/mode-wise-class-wise-count/mode-wise-class-wise-count.component';
import { InspectorWiseTransferSummaryReportComponent } from './pages/Reports/Transfer Reports/inspector-wise-transfer-summary-report/inspector-wise-transfer-summary-report.component';
import { TransferreddetailedreportComponent } from './pages/Reports/Transfer Reports/transferreddetailedreport/transferreddetailedreport.component';
import { TourdetailedreportsComponent } from './pages/Reports/Tour Reports/tourdetailedreports/tourdetailedreports.component';
import { InspectorWiseTourSummaryReportComponent } from './pages/Reports/Tour Reports/inspector-wise-tour-summary-report/inspector-wise-tour-summary-report.component';
import { InspectorWiseLtcSummaryReportComponent } from './pages/Reports/LTC Reports/inspector-wise-ltc-summary-report/inspector-wise-ltc-summary-report.component';
import { LtcdetailedreportComponent } from './pages/Reports/LTC Reports/ltcdetailedreport/ltcdetailedreport.component';
import { LTCdashboardComponent } from './pages/CommonModule/ltcdashboard/ltcdashboard.component';
import { TourdashboardComponent } from './pages/CommonModule/tourdashboard/tourdashboard.component';
import { TrasferdashboardComponent } from './pages/CommonModule/trasferdashboard/trasferdashboard.component';
import { AdvanceGivenForJourneyltcComponent } from './pages/Reports/LTC Reports/advance-given-for-journeyltc/advance-given-for-journeyltc.component';
import { DateWiseModeWiseJourneyCountltcComponent } from './pages/Reports/LTC Reports/date-wise-mode-wise-journey-countltc/date-wise-mode-wise-journey-countltc.component';
import { EmployeeWiseLtcDetailedReportltcComponent } from './pages/Reports/LTC Reports/employee-wise-ltc-detailed-reportltc/employee-wise-ltc-detailed-reportltc.component';
import { ModeWiseClassWiseLtcCountltcComponent } from './pages/Reports/LTC Reports/mode-wise-class-wise-ltc-countltc/mode-wise-class-wise-ltc-countltc.component';
import { MonthWiseClaimCountltcComponent } from './pages/Reports/LTC Reports/month-wise-claim-countltc/month-wise-claim-countltc.component';
import { MonthWiseTransferCountReportComponent } from './pages/Reports/Transfer Reports/month-wise-transfer-count-report/month-wise-transfer-count-report.component';
import { EmployeetouraddComponent } from './pages/HospitalMgtBasicForm/Employee/employeeTour/employeetouradd/employeetouradd.component';
import { EmployeetourlistComponent } from './pages/HospitalMgtBasicForm/Employee/employeeTour/employeetourlist/employeetourlist.component';
import { EmployeeltclistComponent } from './pages/HospitalMgtBasicForm/Employee/employeeltc/employeeltclist/employeeltclist.component';
import { LtcemployeeaddComponent } from './pages/HospitalMgtBasicForm/Employee/employeeltc/ltcemployeeadd/ltcemployeeadd.component';
import { PendingtourclaimsComponent } from './pages/HospitalMgtBasicForm/Tour/TourMaster/pendingtourclaims/pendingtourclaims.component';
import { ListPendingClaimComponent } from './pages/HospitalMgtBasicForm/LTC/LTCMaster/LTCPendingClaim/list-pending-claim/list-pending-claim.component';
import { TransferPendingClaimComponent } from './pages/HospitalMgtBasicForm/Transfer/Transfer/transfer-pending-claim/transfer-pending-claim.component';
import { EmployeetransferaddComponent } from './pages/HospitalMgtBasicForm/Employee/employeetransfer/employeetransferadd/employeetransferadd.component';
import { EmployeetransferlistComponent } from './pages/HospitalMgtBasicForm/Employee/employeetransfer/employeetransferlist/employeetransferlist.component';
import { TourfoodbilladdComponent } from './pages/HospitalMgtBasicForm/Tour/tourfoodbilladd/tourfoodbilladd.component';
import { AddblockyearComponent } from './pages/HospitalMgtBasicForm/LTC/LTCMaster/BlockYearMaster/addblockyear/addblockyear.component';
import { BlockyearlistComponent } from './pages/HospitalMgtBasicForm/LTC/LTCMaster/BlockYearMaster/blockyearlist/blockyearlist.component';
import { TourNewPartBComponent } from './pages/HospitalMgtBasicForm/Tour/Print Tour/tour-new-part-b/tour-new-part-b.component';
import { AdvanceAnnexureComponent } from './pages/HospitalMgtBasicForm/Claim/advance-annexure/advance-annexure.component';
import { TourpartBComponent } from './pages/HospitalMgtBasicForm/Tour/Print Tour/tourpart-b/tourpart-b.component';

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    ExportDirective,
    FormsComponent,
    FormComponent,
    RolesComponent,
    RoleComponent,
    RoledetailsComponent,
    UsersComponent,
    UserComponent,
    LoginComponent,
    AdmindashboardComponent,

    // ClientMasterComponent,
    // ClientDrawerComponent,

    // ProjectMasterComponent,
    // ProjectDrawerComponent,
    // ProjectMappingComponent,

    // ModuleMasterComponent,
    // ModuleDrawerComponent,
    // ModuleMappingComponent,

    // FeatureMasterComponent,
    // FeatureDrawerComponent,
    // FeatureMappingComponent,

    // ModuleFeatureViewComponent,
    // FeatureViewComponent,

    // CategoryMasterComponent,
    // CategoryDrawerComponent,

    // TaskMasterComponent,
    // TaskDrawerComponent,
    // TaskSubtaskMappingComponent,

    // TaskTransferHistoryComponent,
    // TaskTransferDrawerComponent,

    // AttachmentMasterComponent,
    // AttachmentDrawerComponent,

    // WorklogComponent,
    // WorklogDrawerComponent,

    // AssigneeMappingComponent,

    // CommentComponent,
    // CommentDrawerComponent,

    // TypeMasterComponent,
    // TypeDrawerComponent,

    // KanbanComponent,
    // KanbanProjectWiseComponent,
    // RoadmapComponent,

    SecondToHoursPipe,
    DashboardComponent,

    HospitalmasterlistComponent,
    AddhospitalmasterComponent,
    CityMasterComponent,
    CityDrawerComponent,
    NotificationAndMemorandomMasterComponent,
    NotificationAndMemorandomDrawerComponent,
    ListinvestigationComponent,
    AddtinvestigationComponent,
    AddMedicalFacilityComponent,
    MedicalFacilitiesMasterComponent,
    InvestigationComponentComponent,
    InvestigationDrawerComponent,
    ClaimApplicationMasterComponent,
    ClaimApplicationDrawerComponent,
    ClaimstageslistComponent,
    AddclaimstagesComponent,
    ClaimsComponent,
    ApplyclaimComponent,
    AddInvestigationMasterComponent,
    ClaimLogDrawerComponent,
    ClaimUpdatedComponent,
    AddInvestigationMasterRevisedComponent,
    // EmployeeaddComponent,
    // EmployeelistComponent
    EmployeelistComponent,
    EmployeeaddComponent,
    ClaimmasterlistComponent,
    AddclaimmasterComponent,
    ImportExcelInvestigationRateComponent,
    DatabasetablemasterlistComponent,
    AdddatabasetablemasterComponent,
    HospitalWiseSummaryReportComponent,
    InspectorWiseAmountStatsReportComponent,
    InspectorWiseDetailedSummaryReportComponent,
    InspectorWiseSummaryReportComponent,
    StageWiseSummaryReportComponent,
    EmployeeloginComponent,
    EmployeeupdateComponent,
    AddnewfileformComponent,
    FilemastertableComponent,
    FilehierarchyComponent,
    FilehierarchiesComponent,
    ViewlogsComponent,
    HispitalWiseAmountStatsComponent,
    BeneficiaryTypeAmountStatsComponent,
    EmployeewisedetailedComponent,
    EmployeeWiseSummaryReportComponent,
    EmployeeWiseAmountStatsComponent,
    BeneficiaryTypeWiseDetailedComponent,
    BeneficiaryTypeSummaryReportsComponent,
    EmployeeclaimlistComponent,
    EmployeeclaimaddComponent,
    AddclaimemployeeComponent,
    UserWiseFilesDetailedReportComponent,
    UserWiseFilesSummaryReportComponent,
    PendingClaimsComponent,
    HierarchyWiseFilesSummaryReportComponent,
    UserfileactivitydetailedreportComponent,
    DayWiseFilesSummaryReportComponent,
    UserWiseFilesClosureDetailedReportComponent,
    HospitalwisedetailedComponent,
    AddforwardmasterComponent,
    ForwardmasterlistComponent,
    FilesystemdashboardComponent,
    ReportsviewComponent,
    SignaturemasterlistComponent,
    AddsignaturemasterComponent,
    InfoComponent,
    RefexpoorderComponent,
    RefexpopermissionComponent,
    RefhodorderComponent,
    RefhodpermissionComponent,
    DelaycondolenceorderComponent,
    DelaycondolencepermissionComponent,
    TravellistComponent,
    TraveladdComponent,
    TravelclasslistComponent,
    TravelclassaddComponent,
    TransferlistComponent,
    TransferaddComponent,
    OfficelistComponent,
    OfficeaddComponent,
    Form3Component,
    Form2Component,
    Form1Component,
    DetailsjorneyComponent,
    AddHODPermissionComponent,
    HODPermissionlistComponent,
    AdddelaycondolationComponent,
    DelaycondolationlistComponent,
    AddexpostfactoComponent,
    ExpostfactolistComponent,
    TravelingAllowanceComponent,
    OrderFormComponent,
    CertificateComponent,
    AddComponent,
    DetailsjourneyperfomedbyrailComponent,
    TransportationchargesofpersonaleffectsComponent,
    AddltcmasterComponent,
    LtcmasterlistComponent,
    FamilymembersComponent,
    DetailsofjourneyComponent,
    ParticularsofjourneyComponent,
    PlacesconnectedbyrailComponent,
    AddgeneratedelaycondonationComponent,
    GeneratedelaycondonationlistComponent,
    GenerateexpostfactolistComponent,
    AddgeneratehodComponent,
    GeneratehodlistComponent,
    Ltcsheet2Component,
    Ltcsheet1Component,
    LtcsanstionorderComponent,
    GoltcComponent,
    CalculationComponent,
    LeaveTravelConcessionBillComponent,
    NatureComponent,
    GAR23Component,
    ClaimcertificateComponent,
    AddgenerateexpostfactoComponent,
    DelayorderComponent,
    DelayletterComponent,
    ExpostorderComponent,
    ExpostletterComponent,
    HodorderComponent,
    HodletterComponent,
    TourmasterlistComponent,
    AddtourmasterComponent,
    TourparticularhotelsComponent,
    CitytourComponent,
    DetailsandpurposetourComponent,
    JourneyparticularcityComponent,
    JourneyparticulartoursComponent,
    AaykarbhvanComponent,
    AdheshorderComponent,
    BillsectionComponent,
    CertificateduplicateComponent,
    ChecklistComponent,
    TravellingallouncebillComponent,
    ToursantionorderComponent,
    TourPartBComponent,
    SelfDeclarationCertificateComponent,
    PlacealongsideComponent,
    PartAComponent,
    InspectorWiseTransferSummaryReportComponent,
    InspectorWiseTourSummaryReportComponent,
    InspectorWiseLtcSummaryReportComponent,
    ClaimMasterReportComponent,
    TransferchecklistComponent,
    TransferreddetailedreportComponent,
    LtcdetailedreportComponent,
    TourdetailedreportsComponent,
    AdvancedAmountJourneyDetailComponent,
    DateWiseModeWiseCountComponent,
    EmployeeWiseDetailedReportComponent,
    ModeWiseAmountStatComponent,
    ModeWiseClassWiseCountComponent,
    LTCdashboardComponent,
    TourdashboardComponent,
    TrasferdashboardComponent,
    MonthWiseClaimCountltcComponent,
    ModeWiseClassWiseLtcCountltcComponent,
    EmployeeWiseLtcDetailedReportltcComponent,
    DateWiseModeWiseJourneyCountltcComponent,
    AdvanceGivenForJourneyltcComponent,
    MonthWiseTransferCountReportComponent,
    EmployeetouraddComponent,
    EmployeetourlistComponent,
    EmployeeltclistComponent,
    LtcemployeeaddComponent,
    PendingtourclaimsComponent,
    ListPendingClaimComponent,
    TransferPendingClaimComponent,
    EmployeetransferaddComponent,
    EmployeetransferlistComponent,
    TourfoodbilladdComponent,
    AddblockyearComponent,
    BlockyearlistComponent,
    TourNewPartBComponent,
    AdvanceAnnexureComponent,
    TourpartBComponent,
  ],

  imports: [
    BrowserModule,
    AngularEditorModule,
    AppRoutingModule,
    IconsProviderModule,
    NzLayoutModule,
    NzMenuModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NzFormModule,
    NzInputModule,
    NzTableModule,
    NzDrawerModule,
    NzSpinModule,
    NzSelectModule,
    NzDropDownModule,
    NzIconModule,
    NzNotificationModule,
    NzButtonModule,
    Ng2SearchPipeModule,
    NzSwitchModule,
    NzInputNumberModule,
    NzDatePickerModule,
    NzTreeSelectModule,
    NzRadioModule,
    NzDividerModule,
    NzTagModule,
    NzModalModule,
    NzPopoverModule,
    NzCheckboxModule,
    NgApexchartsModule,
    NzMessageModule,
    NzBadgeModule,
    NzStepsModule,
    NzCardModule,
    NzCommentModule,
    NzListModule,
    NzToolTipModule,
    NzAutocompleteModule,
    NzTimePickerModule,
    NgIdleKeepaliveModule.forRoot(),
    NzProgressModule,
    NzPopconfirmModule,
    NzBackTopModule,
    NzAvatarModule,
    NzTypographyModule,
    NzTabsModule,
    NzTreeModule,
    NgxPrintModule,
    NzTimelineModule,
  ],

  providers: [{ provide: NZ_I18N, useValue: en_US }, CookieService],
  bootstrap: [AppComponent],
})
export class AppModule {}
