export class QuestionaryMaster {
  ID: number;
  NAME: string;
  DATE: any;
  FILE_NO: number;
  PDF_URL: any;

  IS_CGHS_OR_CS: boolean = false;
  CGHS_CARD_NO: any;
  CGHS_CARD_VALIDITY_DATE: any;

  IS_EMERG_TREAT_AVAILED: boolean = false;
  IS_CGHS_OR_AMA_REFERENCE_TAKEN: boolean = false;
  CGHS_AMA_REFERENCE_NO: any = '';
  CGHS_AMA_REFERENCE_DATE: any = [];
  CGHS_AMA_REFERENCE_END_DATE: any = [];
  BILL_FILIING_DATE: any;
  IS_BILL_FILLED_IN_TIME: boolean = false;
  IS_DELAY_CONDOLENCE_PERMISSION_TAKEN: boolean = false;
  DELAY_CONDOLENCE_DATE: any;
  DELAY_CONDOLENCE_NUMBER: any;
  IS_HOSPITAL_EMPLANELLED: boolean = false;
  HOSPITAL_ID: any;
  NAME_OF_HOSPITAL: any;
  TYPE_OF_HOSPITAL: any;
  IS_PERMISSION_TAKEN_FROM_HOD: boolean = false;
  HOD_PERMISSION_DATE: any;
  HOD_PERMISSION_NO: any;
  EXPOST_FACTO_PERMISSION_DATE: any;
  EMP_ID: any;
  CLAIM_ID: any;

  //////////
  IS_EXPOST_FACTO_PERMISSION_TAKEN: boolean = false;

  CREATED_MODIFIED_DATE;
  READ_ONLY;
  ARCHIVE_FLAG;
  CLIENT_ID;

  EMERGENCY_TREATEMENT: boolean = false;
  REFERENCE_FORM_CGHS_AMA_TAKEN: boolean = false;
  DOCTOR_EMERGENCY_CERTIFICATE_TAKEN: boolean = false;
  EXPO_FACTO_PERMISSION: boolean = false;
  EXPO_FACTO_REFERENCE_NO: any;
  EXPO_FACTO_DATE: any;
  BILL_FILLED_INTIME: boolean = false;
  REFERRAL_DATE: any;
  RECEIVED_DATE: any;
  LETTER_DATE: any;
  CLAIM_REASON: any;
  CLAIM_FILLED_DATE: any;
  CLAIM_SUBMITTED_DATE: any;
  EXPENCE_AMOUNT: any;
  SALARY_SLIP: any;
  ADDRESS_PROOF: any;
  COPY_OF_CGHS_CARD_1: any;
  CGHS_AMA_DOCTOR_REFERENCE: any;
  FORWARDING_LETTER: any;
  CGHS_NAME: any;
  TREATEMENT_PERMISSION_START_DATE: any;
  TREATEMENT_PERMISSION_END_DATE: any;
  EXPOST_FACTO_PERMISSION_SENTENSE: any;
  EXPOST_FACTO_PERMISSION_SENTENSE_HN: any;
  BILL_SUBMISSION_DATE: any;
  DELAY_CONDOLATION_ID: any;
  EXPOST_FACTO_ID: any;
  HOD_ID: any = [];
}
