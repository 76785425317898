export class Role {
    ID: number;
    CLIENT_ID: number;
    NAME: string;
    DESCRIPTION: string;
    IS_ACTIVE: boolean;
    PARENT_ID: number;
    TYPE: string;
    START_PAGE: string;
    LEVEL: any;
}
