import { Component, Input, OnInit } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as html2pdf from 'html2pdf.js';
import { DatePipe } from '@angular/common';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css'],
})
export class AddComponent implements OnInit {
  @Input() drawerClose;
  @Input() partbdata;
  @Input() journeyDetailsData;
  @Input() multiplication;
  @Input() incidentalsAmonut;
  @Input() tranferchnagesorder;
  @Input() totalofweight;
  @Input() transferallounceorder;
  @Input() netpaymentofpartb;
  @Input() grosspaymentofpartb;
  @Input() amountofadvance;
  @Input() fairpaidofpartb;
  @Input() amountt;
  @Input() weightrate;
  @Input() distance;
  @Input() grosspaymentvalue;
  @Input() privateVehicleMinFair;
  @Input() showPrivate;
  @Input() TravelModeNames;
  rtoatal: any;
  constructor(
    private message: NzNotificationService,
    private api: ApiService,
    private datepipe: DatePipe,
    public cookie: CookieService
  ) {}

  ngOnInit(): void {
    // this.rtoatal = this.partbdata.ROAD_MILEAGE * this.partbdata.PER_KM;
  }
  visible = false;

  open(): void {
    this.visible = true;
  }

  total = 0;
  // gettotal() {
  //   var journey = 0;
  //   if (this.showPrivate == 1) {
  //     journey = this.partbdata.ROAD_MILEAGE_ADMISIBLE_AMOUNT;
  //   }

  //   if (
  //     this.partbdata.CTG_ADMISIBLE_AMOUNT != undefined &&
  //     this.partbdata.PERSONAL_EFFECT_ADMISIBLE_AMOUNT != undefined &&
  //     this.partbdata.ROAD_MILEAGE_ADMISIBLE_AMOUNT != undefined &&
  //     this.partbdata.JOURNEY_ADMISIBLE_AMOUNT != undefined &&
  //     this.partbdata.PRIVATE_CONVIENCE_ADMISIBLE_AMOUNT != undefined
  //   ) {
  //     this.total =
  //       Number(this.partbdata.CTG_ADMISIBLE_AMOUNT) +
  //       Number(this.partbdata.PERSONAL_EFFECT_ADMISIBLE_AMOUNT) +
  //       // Number(this.partbdata.ROAD_MILEAGE_ADMISIBLE_AMOUNT) +
  //       Number(journey) +
  //       Number(this.partbdata.JOURNEY_ADMISIBLE_AMOUNT) +
  //       Number(this.partbdata.PRIVATE_CONVIENCE_ADMISIBLE_AMOUNT);
  //   }

  //   return this.total;
  // }

  gettotal() {
    if (
      this.partbdata.CTG_TOTAL != undefined &&
      this.partbdata.ROAD_MILEAGE_TOTAL != undefined &&
      this.partbdata.JOURNEY_TOTAL != undefined &&
      this.partbdata.PERSONAL_EFFECT_TOTAL != undefined &&
      this.partbdata.PRIVATE_CONVEYANCE_TOTAL != undefined
    ) {
      this.total =
        Number(this.partbdata.CTG_TOTAL) +
        Number(this.partbdata.ROAD_MILEAGE_TOTAL) +
        Number(this.partbdata.JOURNEY_TOTAL) +
        Number(this.partbdata.PERSONAL_EFFECT_TOTAL) +
        Number(this.partbdata.PRIVATE_CONVEYANCE_TOTAL);
    }

    return this.total;
  }
  nettotal = 0;

  gettotall() {
    this.partbdata.ORDER_FORM_NET_TOTAL = 0;
    if (this.partbdata.ADVANCED_AMOUNT != undefined) {
      this.partbdata.ORDER_FORM_NET_TOTAL =
        this.total - this.partbdata.ADVANCED_AMOUNT;
    }
    return this.partbdata.ORDER_FORM_NET_TOTAL;
  }

  close(): void {
    this.drawerClose();
  }
  openpdf() {
    const element = document.getElementById('excel-table');
    const opt = {
      margin: 0.2,
      filename: 'Part-B.pdf',
      image: { type: 'jpeg', quality: 7 },
      html2canvas: { scale: 7 },
      jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
    };
    html2pdf().from(element).set(opt).save();
  }

  loadingRecords = false;
  printOrderModalVisible: boolean = false;
  showmodal() {
    this.printModel();
    // this.api.updatetransfer(this.partbdata).subscribe((successCode) => {
    //   if (successCode.code == '200') {
    //     this.loadingRecords = false;
    //     this.printModel();
    //     // this.drawerClose();
    //     // this.next();
    //     // this.resetDrawer(modedataform);
    //     this.message.success('Information Saved Successfully...', '');
    //   } else {
    //     this.message.error('Information Has Not Saved...', '');
    //     this.loadingRecords = false;
    //   }
    // });
  }
  getwidth() {
    if (window.innerWidth <= 400) {
      return 400;
    } else {
      return 850;
    }
  }

  printModel() {
    if (
      this.partbdata.ID != undefined ||
      this.partbdata.ID != null ||
      this.partbdata.ID != ''
    ) {
      this.loadingRecords = true;
      this.api
        .gettransferdata(0, 0, '', '', ' AND ID = ' + this.partbdata.ID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              this.loadingRecords = false;
              this.partbdata = data['data'][0];
              this.printOrderModalVisible = true;
            } else {
              this.message.error("Can't Load Data", '');
              this.loadingRecords = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
    }
  }
  printOrderModalCancel() {
    this.printOrderModalVisible = false;
  }

  // DRAWER2

  visible2 = false;

  open2(): void {
    this.visible2 = true;
  }

  close2(): void {
    this.visible2 = false;
  }

  openpdf2() {
    const element = document.getElementById('excel-table2');
    const opt = {
      margin: 0.2,
      filename: 'Travel-Allowance.pdf',
      image: { type: 'jpeg', quality: 7 },
      html2canvas: { scale: 7 },
      jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
    };
    html2pdf().from(element).set(opt).save();
  }
}
