<nz-layout class="app-layout" *ngIf="isLogedIn">
  <!-- <nz-layout class="app-layout" *ngIf="isLogedIn && pageName != 'livepreview'"> -->
  <nz-sider
    class="menu-sidebar"
    nzCollapsible
    nzWidth="256px"
    nzBreakpoint="md"
    [(nzCollapsed)]="isCollapsed"
    [nzTrigger]="null"
  >
    <div class="sidebar-logo">
      <!-- <a href="https://ng.ant.design/" target="_blank"> -->
      <!-- <img src="http://chitalelms.tecpool.in/assets/chitalelogo.jpeg" alt="logo"> -->
      <div>
        <h1
          style="
            font-family: 'Courier New', Courier, monospace;
            font-size: 18px;
          "
        >
          {{ isCollapsed ? "" : (USERNAME | uppercase) }}
        </h1>
      </div>
      <!-- </a> -->
    </div>

    <div style="overflow: auto; height: 561px">
      <ul nz-menu nzMode="inline" nzTheme="dark">
        <ng-container
          *ngTemplateOutlet="menuTpl; context: { $implicit: menus }"
        ></ng-container>

        <ng-template #menuTpl let-menus>
          <ng-container *ngFor="let menu of menus">
            <li
              *ngIf="!menu.children"
              nz-menu-item
              [nzPaddingLeft]="menu.level * 24"
              [nzDisabled]="menu.disabled"
              nzMatchRouter
            >
              <a [routerLink]="[menu.link]"
                ><i nz-icon [nzType]="menu.icon" *ngIf="menu.icon"></i
                >{{ isCollapsed ? "" : menu.title }}</a
              >
            </li>

            <li
              *ngIf="menu.children"
              nz-submenu
              [nzPaddingLeft]="menu.level * 24"
              [nzOpen]="menu.open"
              [nzTitle]="menu.title"
              [nzIcon]="menu.icon"
              [nzDisabled]="menu.disabled"
            >
              <ul>
                <ng-container
                  *ngTemplateOutlet="
                    menuTpl;
                    context: { $implicit: menu.children }
                  "
                ></ng-container>
              </ul>
            </li>
          </ng-container>
        </ng-template>
      </ul>
    </div>
  </nz-sider>

  <nz-layout>
    <nz-header class="header1">
      <div nz-row class="app-header">
        <div nz-col nzSpan="12">
          <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
            <i
              class="trigger"
              nz-icon
              [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
            >
            </i>
          </span>
        </div>

        <div nz-col nzSpan="12" style="text-align: right">
          <!-- <button nz-button nzType="primary" (click)="showModal()" nzShape="circle" nzTooltipTitle="Create Issue"
            nzTooltipPlacement="top" nz-tooltip>
            <i nz-icon nzType="plus"></i>
          </button> -->
          <span nz-tooltip nzTooltipTitle="About Us">
            <button
              nz-button
              nzType="primary"
              nzShape="circle"
              (click)="info($event)"
              style="margin-right: 10px"
            >
              <i nz-icon nzType="info" nzTheme="outline"></i></button
          ></span>
          <span
            *ngIf="employeeedit != 1"
            nz-tooltip
            nzTooltipTitle="Update Profile"
          >
            <button
              nz-button
              nzType="primary"
              nzShape="circle"
              (click)="edit(data)"
              style="margin-right: 10px"
            >
              <i nz-icon nzType="user" nzTheme="outline"></i></button
          ></span>
          <span nz-tooltip nzTooltipTitle="Change Password" *ngIf="roleId == 2">
            <button
              nz-button
              nzType="primary"
              nzShape="circle"
              (click)="changeEmpPass()"
              style="margin-right: 10px"
            >
              <i nz-icon nzType="setting" nzTheme="outline"></i></button
          ></span>

          <span nz-tooltip nzTooltipTitle="Change Password" *ngIf="roleId != 2">
            <button
              nz-button
              nzType="primary"
              nzShape="circle"
              (click)="changeUserPass()"
              style="margin-right: 10px"
            >
              <i nz-icon nzType="setting" nzTheme="outline"></i>
            </button>
          </span>
          <button
            nz-button
            nzType="primary"
            (click)="logout()"
            [nzLoading]="isSpinning"
            nzShape="circle"
            nzTooltipTitle="Logout"
            nzTooltipPlacement="top"
            nz-tooltip
          >
            <i nz-icon nzType="logout"></i>
          </button>
        </div>
      </div>
    </nz-header>

    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>

      <nz-footer>
        Copyright 2023
        <a href="https://www.uvtechsoft.com/" target="_blank"
          >UjjVilas Technologies & Software Pvt. Ltd.</a
        >
        All rights reserved.
      </nz-footer>
      <!-- <nz-footer>
        Proudly Designed & Developed By,
        <a href="https://www.uvtechsoft.com/" target="_blank"
          >UjjVilas Technologies & Software Pvt. Ltd.</a
        >
      </nz-footer> -->
    </nz-content>
  </nz-layout>
</nz-layout>

<nz-modal
  [(nzVisible)]="empPassVisible"
  [nzWidth]="'350px'"
  (nzOnCancel)="handleCancel1()"
  nzTitle="Change Password"
>
  <ng-container *nzModalContent>
    <form nz-form #resetform *ngIf="showEmpConfirm == false">
      <div nz-row class="my-box">
        <div nz-col nzSpan="24">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon
              >Current Password
            </nz-form-label>
            <nz-form-control nzErrorTip="Please Enter Valid Current Password">
              <input
                nz-input
                name="currpass"
                placeholder="Enter your current password"
                [(ngModel)]="PASSWORD"
                required
              />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </form>

    <form nz-form #resetform *ngIf="showEmpConfirm == true">
      <div nz-row class="my-box">
        <div nz-col nzSpan="24">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>New Password </nz-form-label>
            <nz-form-control nzErrorTip="Please Enter Valid New Password">
              <input
                nz-input
                name="newpass"
                placeholder="Enter your New Password"
                [(ngModel)]="NEW_PASSWORD"
                required
              />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="24">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon
              >Confirm Password
            </nz-form-label>
            <nz-form-control nzErrorTip="Please Enter Valid Confirm Password">
              <input
                nz-input
                name="confpass"
                placeholder="Enter New Password To Confirm"
                [(ngModel)]="CONFPASSWORD"
                required
              />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </form>
  </ng-container>
  <div *nzModalFooter>
    <button
      *ngIf="showEmpConfirm == false"
      nz-button
      nzType="default"
      (click)="checkEmpOldPass()"
    >
      Check
    </button>
    <button
      *ngIf="showEmpConfirm == true"
      nz-button
      nzType="default"
      (click)="confEmpPass()"
    >
      Change Password
    </button>
  </div>
</nz-modal>

<!-- <app-login *ngIf="!isLogedIn"></app-login> -->
<!-- <app-livescreen style="width: 100%;height: 100%;" *ngIf="isLogedIn && pageName=='livepreview'"></app-livescreen> -->

<nz-modal
  [nzBodyStyle]="{ height: '390px' }"
  [(nzVisible)]="isVisible"
  [nzTitle]="modalName"
  nzWidth="80%"
  (nzOnCancel)="handleCancel()"
  [nzFooter]="modalFooter"
>
  <ng-template #modalFooter>
    <!-- <button nz-button nzType="default" (click)="handleCancel()">Cancel</button> -->
    <button nz-button nzType="primary" (click)="handleOk(true)">
      Save & Close
    </button>
    <button nz-button nzType="primary" (click)="handleOk(false)">
      Save & New
    </button>
  </ng-template>

  <ng-container *nzModalContent>
    <div nz-row style="overflow-y: auto; overflow-x: hidden; height: 345px">
      <div nz-col nzSpan="12">
        <nz-form-item>
          <nz-form-label nzRequired nzNoColon>Project</nz-form-label>

          <nz-form-control>
            <nz-select
              name="projectName"
              nzShowSearch
              [nzLoading]="isListLoading"
              nzPlaceHolder="Select a Project"
              [(ngModel)]="PROJECT_ID"
              (ngModelChange)="loadModal()"
            >
              <span
                *ngIf="
                  sessionStorage.getItem('level') == '1' ||
                  sessionStorage.getItem('level') == '2' ||
                  sessionStorage.getItem('level') == '3'
                "
              >
                <nz-option
                  *ngFor="let project of projectList"
                  [nzValue]="project.ID"
                  [nzLabel]="project.NAME"
                >
                </nz-option>
              </span>

              <span *ngIf="sessionStorage.getItem('level') >= '4'">
                <nz-option
                  *ngFor="let project of projectList"
                  [nzValue]="project.PROJECT_ID"
                  [nzLabel]="project.NAME"
                >
                </nz-option>
              </span>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col nzSpan="12" *ngIf="PROJECT_ID > 0">
        <nz-form-item>
          <nz-form-label nzRequired nzNoColon>Issue Type</nz-form-label>

          <nz-form-control>
            <nz-radio-group
              name="issueType"
              [(ngModel)]="ISSUE_TYPE"
              nzButtonStyle="solid"
              (ngModelChange)="radioButtonChange()"
            >
              <label nz-radio-button nzValue="M" [hidden]="moduleButtonHidden"
                >Module</label
              >
              <label nz-radio-button nzValue="F" [hidden]="featureButtonHidden"
                >Feature</label
              >
              <label nz-radio-button nzValue="T" [hidden]="taskButtonHidden"
                >Task</label
              >
              <label nz-radio-button nzValue="S" [hidden]="subtaskButtonHidden"
                >Subtask</label
              >
            </nz-radio-group>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col nzSpan="24">
        <div *ngIf="moduleDrawerVisible">
          <div nz-row>
            <div nz-col nzSpan="12">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Module Name</nz-form-label>

                <nz-form-control
                  nzHasFeedback
                  nzErrorTip="Module Name is Required"
                >
                  <input
                    nz-input
                    autocomplete="off"
                    name="moduleName"
                    type="text"
                    placeHolder="Enter a Module Name"
                    [(ngModel)]="MODULE_NAME"
                    required
                  />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="12"></div>

            <div nz-col nzSpan="12">
              <nz-form-item>
                <nz-form-label nzNoColon>Module Description</nz-form-label>

                <nz-form-control>
                  <textarea
                    nz-input
                    [nzAutosize]="{ minRows: 3, maxRows: 6 }"
                    name="moduleDescription"
                    placeHolder="Enter a Module Description"
                    [(ngModel)]="MODULE_DESCRIPTION"
                    maxlength="500"
                  ></textarea>
                </nz-form-control>

                <span style="font-size: 10px; color: red"
                  >{{ MODULE_DESCRIPTION.length }}/ 500</span
                >
              </nz-form-item>
            </div>

            <div nz-col nzSpan="12">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Assignee</nz-form-label>

                <nz-form-control>
                  <nz-select
                    name="moduleAssigneeName"
                    nzMode="multiple"
                    nzShowSearch
                    nzAllowClear
                    [nzLoading]="isAssigneeListLoading"
                    nzPlaceHolder="Select Assignee"
                    [(ngModel)]="MODULE_ASSIGNEE_ID"
                    nzDisabled
                  >
                    <nz-option
                      nzCustomContent
                      *ngFor="let assignee of newAssigneeList"
                      [nzValue]="assignee.USER_ID"
                      [nzLabel]="assignee.USER_NAME"
                    >
                      <div>
                        {{ assignee.USER_NAME }}
                      </div>

                      <div
                        style="font-size: 12px; color: gray; text-align: right"
                      >
                        <span
                          >~&nbsp;{{ assignee.TASK_CATEGORY_NAME }} ({{
                            assignee.ROLE_NAME
                          }})</span
                        >
                      </div>
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="4">
              <nz-form-item>
                <nz-form-label nzNoColon>Start Date</nz-form-label>

                <nz-form-control>
                  <nz-date-picker
                    style="width: 100%"
                    nzFormat="dd/MM/yyyy"
                    name="moduleStartDate"
                    (nzOnOpenChange)="moduleStartDateHandle($event)"
                    [(ngModel)]="MODULE_START_DATE"
                  ></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="4">
              <nz-form-item>
                <nz-form-label nzNoColon>End Date</nz-form-label>

                <nz-form-control>
                  <nz-date-picker
                    #moduleEndDatePicker
                    style="width: 100%"
                    nzFormat="dd/MM/yyyy"
                    name="moduleEndDate"
                    [nzDisabledDate]="disabledModuleEndDate"
                    [(ngModel)]="MODULE_END_DATE"
                  ></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="4">
              <nz-form-item>
                <nz-form-label nzNoColon>Estimated Mandays</nz-form-label>

                <nz-form-control>
                  <input
                    nz-input
                    name="estimatedMandays"
                    type="number"
                    min="1"
                    oninput="this.value = Math.abs(this.value)"
                    placeHolder="Enter Mandays"
                    [(ngModel)]="MODULE_ESTIMATED_MANDAYS"
                    required
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </div>

        <div *ngIf="featureDrawerVisible">
          <div nz-row>
            <div nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Module</nz-form-label>

                <nz-form-control>
                  <nz-select
                    name="moduleName"
                    nzShowSearch
                    nzAllowClear
                    [nzLoading]="isFeatureModuleLoading"
                    nzPlaceHolder="Select a Module"
                    [(ngModel)]="MODULE_ID"
                  >
                    <nz-option
                      *ngFor="let module of moduleList"
                      [nzValue]="module.ID"
                      [nzLabel]="module.NAME"
                    >
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Feature Name</nz-form-label>

                <nz-form-control
                  nzHasFeedback
                  nzErrorTip="Feature Name is Required"
                >
                  <input
                    nz-input
                    autocomplete="off"
                    name="featureName"
                    type="text"
                    placeHolder="Enter a Feature Name"
                    [(ngModel)]="FEATURE_NAME"
                    required
                  />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="8"></div>

            <div nz-col nzSpan="16">
              <nz-form-item>
                <nz-form-label nzNoColon>Feature Description</nz-form-label>

                <nz-form-control>
                  <textarea
                    nz-input
                    [nzAutosize]="{ minRows: 3, maxRows: 6 }"
                    name="featureDescription"
                    placeHolder="Enter a Feature Description"
                    [(ngModel)]="FEATURE_DESCRIPTION"
                    maxlength="500"
                  ></textarea>
                </nz-form-control>

                <span style="font-size: 10px; color: red"
                  >{{ FEATURE_DESCRIPTION.length }}/ 500</span
                >
              </nz-form-item>
            </div>

            <div nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Assignee</nz-form-label>

                <nz-form-control>
                  <nz-select
                    name="featureAssigneeName"
                    nzMode="multiple"
                    nzShowSearch
                    nzAllowClear
                    nzPlaceHolder="Select Assignee"
                    [(ngModel)]="FEATURE_ASSIGNEE_ID"
                  >
                    <nz-option
                      nzCustomContent
                      *ngFor="let assignee of newAssigneeList"
                      [nzValue]="assignee.USER_ID"
                      [nzLabel]="assignee.USER_NAME"
                    >
                      <div>
                        {{ assignee.USER_NAME }}
                      </div>

                      <div
                        style="font-size: 12px; color: gray; text-align: right"
                      >
                        <span
                          >~&nbsp;{{ assignee.TASK_CATEGORY_NAME }} ({{
                            assignee.ROLE_NAME
                          }})</span
                        >
                      </div>
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="4">
              <nz-form-item>
                <nz-form-label nzNoColon>Start Date</nz-form-label>

                <nz-form-control>
                  <nz-date-picker
                    style="width: 100%"
                    nzFormat="dd/MM/yyyy"
                    name="featureStartDate"
                    (nzOnOpenChange)="featureStartDateHandle($event)"
                    [(ngModel)]="FEATURE_START_DATE"
                  ></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="4">
              <nz-form-item>
                <nz-form-label nzNoColon>End Date</nz-form-label>

                <nz-form-control>
                  <nz-date-picker
                    #featureEndDatePicker
                    style="width: 100%"
                    nzFormat="dd/MM/yyyy"
                    name="featureEndDate"
                    [nzDisabledDate]="disabledFeatureEndDate"
                    [(ngModel)]="FEATURE_END_DATE"
                  ></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="4">
              <nz-form-item>
                <nz-form-label nzNoColon>Estimated Mandays</nz-form-label>

                <nz-form-control>
                  <input
                    nz-input
                    name="estimatedMandays"
                    type="number"
                    min="1"
                    oninput="this.value = Math.abs(this.value)"
                    placeHolder="Enter Mandays"
                    [(ngModel)]="FEATURE_ESTIMATED_MANDAYS"
                    required
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </div>

        <div *ngIf="taskDrawerVisible">
          <div nz-row>
            <div nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Module</nz-form-label>

                <nz-form-control>
                  <nz-select
                    name="taskModuleName"
                    nzShowSearch
                    nzAllowClear
                    [nzLoading]="isTaskModuleLoading"
                    nzPlaceHolder="Select a Module"
                    (ngModelChange)="fillTaskFeatureModuleWise($event)"
                    [(ngModel)]="TASK_MODULE_ID"
                  >
                    <nz-option
                      *ngFor="let module of moduleList"
                      [nzValue]="module.ID"
                      [nzLabel]="module.NAME"
                    >
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Feature</nz-form-label>

                <nz-form-control>
                  <nz-select
                    name="taskFeatureName"
                    nzShowSearch
                    nzAllowClear
                    [nzLoading]="isTaskFeatureLoading"
                    nzPlaceHolder="Select a Feature"
                    [(ngModel)]="TASK_FEATURE_ID"
                  >
                    <nz-option
                      *ngFor="let feature of taskFeatureList"
                      [nzValue]="feature.ID"
                      [nzLabel]="feature.NAME"
                    >
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Task Name</nz-form-label>

                <nz-form-control
                  nzHasFeedback
                  nzErrorTip="Task Name is Required"
                >
                  <input
                    nz-input
                    autocomplete="off"
                    name="taskName"
                    type="text"
                    placeHolder="Enter a Task Name"
                    [(ngModel)]="TASK_NAME"
                    required
                  />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="16">
              <nz-form-item>
                <nz-form-label nzNoColon>Task Description</nz-form-label>

                <nz-form-control>
                  <textarea
                    nz-input
                    [nzAutosize]="{ minRows: 3, maxRows: 6 }"
                    name="taskDescription"
                    placeHolder="Enter a Task Description"
                    [(ngModel)]="TASK_DESCRIPTION"
                    maxlength="500"
                  ></textarea>
                </nz-form-control>

                <span style="font-size: 10px; color: red"
                  >{{ TASK_DESCRIPTION.length }}/ 500</span
                >
              </nz-form-item>
            </div>

            <div nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Assignee</nz-form-label>

                <nz-form-control>
                  <nz-select
                    name="taskAssigneeName"
                    nzMode="multiple"
                    nzShowSearch
                    nzAllowClear
                    nzPlaceHolder="Select Assignee"
                    [(ngModel)]="TASK_ASSIGNEE_ID"
                    [nzDisabled]="level == 3 ? false : true"
                  >
                    <nz-option
                      nzCustomContent
                      *ngFor="let assignee of newAssigneeList"
                      [nzValue]="assignee.USER_ID"
                      [nzLabel]="assignee.USER_NAME"
                    >
                      <div>
                        {{ assignee.USER_NAME }}
                      </div>

                      <div
                        style="font-size: 12px; color: gray; text-align: right"
                      >
                        <span
                          >~&nbsp;{{ assignee.TASK_CATEGORY_NAME }} ({{
                            assignee.ROLE_NAME
                          }})</span
                        >
                      </div>
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="6">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon
                  >Task Category</nz-form-label
                >

                <nz-form-control>
                  <nz-select
                    name="taskCategory"
                    nzShowSearch
                    nzAllowClear
                    [nzLoading]="isTaskCategoryLoading"
                    nzPlaceHolder="Select a Task Category"
                    [(ngModel)]="TASK_CATEGORY_ID"
                  >
                    <nz-option
                      *ngFor="let category of categoryList"
                      [nzValue]="category.ID"
                      [nzLabel]="category.NAME"
                    >
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="4">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon
                  >Task Priority</nz-form-label
                >

                <nz-form-control>
                  <nz-select
                    name="taskPriority"
                    nzShowSearch
                    nzAllowClear
                    nzPlaceHolder="Select a Priority"
                    [(ngModel)]="TASK_PRIORITY"
                  >
                    <nz-option nzLabel="Critical" nzValue="C"></nz-option>
                    <nz-option nzLabel="High" nzValue="ME"></nz-option>
                    <nz-option nzLabel="Medium" nzValue="MD"></nz-option>
                    <nz-option nzLabel="Low" nzValue="L"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="4">
              <nz-form-item>
                <nz-form-label nzNoColon>Start Date</nz-form-label>

                <nz-form-control>
                  <nz-date-picker
                    style="width: 100%"
                    nzFormat="dd/MM/yyyy"
                    name="taskStartDate"
                    (nzOnOpenChange)="taskStartDateHandle($event)"
                    [(ngModel)]="TASK_START_DATE"
                  ></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="4">
              <nz-form-item>
                <nz-form-label nzNoColon>End Date</nz-form-label>

                <nz-form-control>
                  <nz-date-picker
                    #taskEndDatePicker
                    style="width: 100%"
                    nzFormat="dd/MM/yyyy"
                    name="taskEndDate"
                    [nzDisabledDate]="disabledTaskEndDate"
                    [(ngModel)]="TASK_END_DATE"
                  ></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="6">
              <nz-form-item>
                <nz-form-label nzNoColon>Estimated Time</nz-form-label>

                <div style="display: inline-flex">
                  <nz-form-control>
                    <input
                      nz-input
                      name="taskEstimatedHours"
                      type="number"
                      min="0"
                      oninput="this.value = Math.abs(this.value)"
                      placeHolder="HH"
                      [(ngModel)]="TASK_ESTIMATED_HOURS"
                      required
                    />
                  </nz-form-control>

                  <nz-form-control>
                    <input
                      nz-input
                      name="taskEstimatedMinutes"
                      type="number"
                      min="0"
                      oninput="this.value = Math.abs(this.value)"
                      placeHolder="MM"
                      [(ngModel)]="TASK_ESTIMATED_MINUTES"
                      required
                    />
                  </nz-form-control>

                  <nz-form-control>
                    <input
                      nz-input
                      name="taskEstimatedSeconds"
                      type="number"
                      min="0"
                      oninput="this.value = Math.abs(this.value)"
                      placeHolder="SS"
                      [(ngModel)]="TASK_ESTIMATED_SECONDS"
                      required
                    />
                  </nz-form-control>
                </div>
              </nz-form-item>
            </div>
          </div>
        </div>

        <div *ngIf="subtaskDrawerVisible">
          <div nz-row>
            <div nz-col nzSpan="12">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Module</nz-form-label>

                <nz-form-control>
                  <nz-select
                    name="subtaskModuleName"
                    nzShowSearch
                    nzAllowClear
                    [nzLoading]="isSubtaskModuleLoading"
                    nzPlaceHolder="Select a Module"
                    (ngModelChange)="fillSubtaskFeatureModuleWise($event)"
                    [(ngModel)]="SUBTASK_MODULE_ID"
                  >
                    <nz-option
                      *ngFor="let module of moduleList"
                      [nzValue]="module.ID"
                      [nzLabel]="module.NAME"
                    >
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="12">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Feature</nz-form-label>

                <nz-form-control>
                  <nz-select
                    name="subtaskFeatureName"
                    nzShowSearch
                    nzAllowClear
                    [nzLoading]="isSubtaskFeatureLoading"
                    nzPlaceHolder="Select a Feature"
                    (ngModelChange)="fillTaskSubtaskFeatureWise($event)"
                    [(ngModel)]="SUBTASK_FEATURE_ID"
                  >
                    <nz-option
                      *ngFor="let feature of subtaskFeatureList"
                      [nzValue]="feature.ID"
                      [nzLabel]="feature.NAME"
                    >
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="12">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Parent Task</nz-form-label>

                <nz-form-control>
                  <nz-select
                    name="parentTask"
                    nzShowSearch
                    nzAllowClear
                    [nzLoading]="isSubtaskParentTaskLoading"
                    nzPlaceHolder="Select a Parent Task"
                    [(ngModel)]="PARENT_TASK_ID"
                  >
                    <nz-option
                      *ngFor="let task of parentTaskList"
                      [nzValue]="task.ID"
                      [nzLabel]="task.NAME"
                    >
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="12">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Subtask Name</nz-form-label>

                <nz-form-control
                  nzHasFeedback
                  nzErrorTip="Subtask Name is Required"
                >
                  <input
                    nz-input
                    autocomplete="off"
                    name="subtaskName"
                    type="text"
                    placeHolder="Enter a Subtask Name"
                    [(ngModel)]="SUBTASK_NAME"
                    required
                  />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="12">
              <nz-form-item>
                <nz-form-label nzNoColon>Subtask Description</nz-form-label>

                <nz-form-control>
                  <textarea
                    nz-input
                    [nzAutosize]="{ minRows: 3, maxRows: 6 }"
                    name="subtaskDescription"
                    placeHolder="Enter a Subtask Description"
                    [(ngModel)]="SUBTASK_DESCRIPTION"
                  ></textarea>
                </nz-form-control>

                <span style="font-size: 10px; color: red"
                  >{{ SUBTASK_DESCRIPTION.length }}/ 500</span
                >
              </nz-form-item>
            </div>

            <div nz-col nzSpan="12">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Assignee</nz-form-label>

                <nz-form-control>
                  <nz-select
                    name="subtaskAssigneeName"
                    nzMode="multiple"
                    nzShowSearch
                    nzAllowClear
                    nzPlaceHolder="Select Assignee"
                    [(ngModel)]="SUBTASK_ASSIGNEE_ID"
                  >
                    <nz-option
                      nzCustomContent
                      *ngFor="let assignee of newAssigneeList"
                      [nzValue]="assignee.USER_ID"
                      [nzLabel]="assignee.USER_NAME"
                    >
                      <div>
                        {{ assignee.USER_NAME }}
                      </div>

                      <div
                        style="font-size: 12px; color: gray; text-align: right"
                      >
                        <span
                          >~&nbsp;{{ assignee.TASK_CATEGORY_NAME }} ({{
                            assignee.ROLE_NAME
                          }})</span
                        >
                      </div>
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="6">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon
                  >Subtask Category</nz-form-label
                >

                <nz-form-control>
                  <nz-select
                    name="subtaskCategory"
                    nzShowSearch
                    nzAllowClear
                    [nzLoading]="isSubtaskCategoryLoading"
                    nzPlaceHolder="Select a Subtask Category"
                    [(ngModel)]="SUBTASK_CATEGORY_ID"
                  >
                    <nz-option
                      *ngFor="let category of categoryList"
                      [nzValue]="category.ID"
                      [nzLabel]="category.NAME"
                    >
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="4">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon
                  >Subtask Priority</nz-form-label
                >

                <nz-form-control>
                  <nz-select
                    name="subtaskPriority"
                    nzShowSearch
                    nzAllowClear
                    nzPlaceHolder="Select a Priority"
                    [(ngModel)]="SUBTASK_PRIORITY"
                  >
                    <nz-option nzLabel="Critical" nzValue="C"></nz-option>
                    <nz-option nzLabel="High" nzValue="ME"></nz-option>
                    <nz-option nzLabel="Medium" nzValue="MD"></nz-option>
                    <nz-option nzLabel="Low" nzValue="L"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="4">
              <nz-form-item>
                <nz-form-label nzNoColon>Start Date</nz-form-label>

                <nz-form-control>
                  <nz-date-picker
                    #subtaskStartDatePicker
                    style="width: 100%"
                    nzFormat="dd/MM/yyyy"
                    name="subtaskStartDate"
                    (nzOnOpenChange)="subtaskStartDateHandle($event)"
                    [(ngModel)]="SUBTASK_START_DATE"
                  ></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="4">
              <nz-form-item>
                <nz-form-label nzNoColon>End Date</nz-form-label>

                <nz-form-control>
                  <nz-date-picker
                    #subtaskEndDatePicker
                    style="width: 100%"
                    nzFormat="dd/MM/yyyy"
                    name="subtaskEndDate"
                    [nzDisabledDate]="disabledSubtaskEndDate"
                    [(ngModel)]="SUBTASK_END_DATE"
                  ></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="6">
              <nz-form-item>
                <nz-form-label nzNoColon>Estimated Time</nz-form-label>

                <div style="display: inline-flex">
                  <nz-form-control>
                    <input
                      nz-input
                      name="subtaskEstimatedHours"
                      type="number"
                      min="0"
                      oninput="this.value = Math.abs(this.value)"
                      placeHolder="HH"
                      [(ngModel)]="SUBTASK_ESTIMATED_HOURS"
                      required
                    />
                  </nz-form-control>

                  <nz-form-control>
                    <input
                      nz-input
                      name="subtaskEstimatedMinutes"
                      type="number"
                      min="0"
                      oninput="this.value = Math.abs(this.value)"
                      placeHolder="MM"
                      [(ngModel)]="SUBTASK_ESTIMATED_MINUTES"
                      required
                    />
                  </nz-form-control>

                  <nz-form-control>
                    <input
                      nz-input
                      name="subtaskEstimatedSeconds"
                      type="number"
                      min="0"
                      oninput="this.value = Math.abs(this.value)"
                      placeHolder="SS"
                      [(ngModel)]="SUBTASK_ESTIMATED_SECONDS"
                      required
                    />
                  </nz-form-control>
                </div>
              </nz-form-item>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</nz-modal>

<nz-drawer
  [nzBodyStyle]="{
    height: 'calc(100% - 55px)',
    overflow: 'auto',
    'padding-bottom': '53px'
  }"
  [nzClosable]="false"
  [nzMask]="true"
  [nzMaskClosable]="false"
  [nzWidth]="550"
  [nzVisible]="drawerVisible"
  [nzTitle]="drawerTitle"
  (nzOnClose)="drawerClose()"
  (nzKeyboard)="(true)"
>
  <ng-container *nzDrawerContent>
    <app-employeeupdate
      [drawerClose]="closeCallback"
      [drawerVisible]="drawerVisible"
      [data]="drawerData"
    ></app-employeeupdate>
  </ng-container>
</nz-drawer>

<nz-layout
  *ngIf="
    (!isLogedIn && pageName == 'login') ||
    (!isLogedIn && pageName != 'employeelogin')
  "
>
  <!-- <nz-layout class="app-layout"> -->
  <app-login></app-login>
</nz-layout>
<nz-layout *ngIf="!isLogedIn && pageName == 'employeelogin'">
  <app-employeelogin
    *ngIf="!isLogedIn && pageName == 'employeelogin'"
  ></app-employeelogin>
</nz-layout>

<nz-modal
  [(nzVisible)]="userPassVisible"
  [nzWidth]="'350px'"
  (nzOnCancel)="handleCancel11()"
  nzTitle="Change Password"
>
  <ng-container *nzModalContent>
    <form nz-form #resetform *ngIf="showUserConfirm == false">
      <div nz-row class="my-box">
        <div nz-col nzSpan="24">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon
              >Current Password
            </nz-form-label>
            <nz-form-control nzErrorTip="Please Enter Valid Current Password">
              <input
                nz-input
                name="currpass"
                placeholder="Enter your current password"
                [(ngModel)]="PASSWORD"
                required
              />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </form>

    <form nz-form #resetform *ngIf="showUserConfirm == true">
      <div nz-row class="my-box">
        <div nz-col nzSpan="24">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>New Password </nz-form-label>
            <nz-form-control nzErrorTip="Please Enter Valid New Password">
              <input
                nz-input
                name="newpass"
                placeholder="Enter your New Password"
                [(ngModel)]="NEW_PASSWORD"
                required
              />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="24">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon
              >Confirm Password
            </nz-form-label>
            <nz-form-control nzErrorTip="Please Enter Valid Confirm Password">
              <input
                nz-input
                name="confpass"
                placeholder="Enter New Password To Confirm"
                [(ngModel)]="CONFPASSWORD"
                required
              />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </form>
  </ng-container>
  <div *nzModalFooter>
    <button
      *ngIf="showUserConfirm == false"
      nz-button
      nzType="default"
      (click)="checkPassUser()"
    >
      Check
    </button>
    <button
      *ngIf="showUserConfirm == true"
      nz-button
      nzType="default"
      (click)="confPassUser()"
    >
      Change Password
    </button>
  </div>
</nz-modal>
