<div>
  <form nz-form #AddNewRecord="ngForm">
    <nz-spin [nzSpinning]="isSpinning">
      <div style="display: flex; gap: 278px">
        <h3 style="margin-left: 88px">
          <b>Departure</b>
        </h3>

        <h3><b>Arival</b></h3>
      </div>

      <div nz-row>
        <div nz-col nzSpan="11">
          <div nz-row class="my-box">
            <nz-form-item>
              <nz-form-label nzRequired nzNoColon>From Date</nz-form-label>

              <nz-form-control nzErrorTip="Please Select Date">
                <nz-form-control nzHasFeedback nzErrorTip="Please Select Date">
                  <nz-date-picker
                    name="date"
                    [(ngModel)]="data.FROM_DATETIME"
                    style="width: 100%"
                  ></nz-date-picker>
                </nz-form-control>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-label nzRequired nzNoColon>Journey From </nz-form-label>

              <nz-form-control nzErrorTip="Please Enter Journey From">
                <nz-input-group style="width: 100%">
                  <input
                    nz-input
                    autocomplete="off"
                    [(ngModel)]="data.DEPARTURE_FROM"
                    name="fairPaid"
                    type="text"
                    maxlength="256"
                    placeHolder="Enter Journey From"
                    required
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div nz-col nzSpan="1"></div>

        <div nz-col nzSpan="12">
          <div nz-row class="my-box">
            <nz-form-item>
              <nz-form-label nzRequired nzNoColon>To Date</nz-form-label>

              <nz-form-control nzErrorTip="Please Select to Date">
                <nz-form-control
                  nzHasFeedback
                  nzErrorTip="Please Select to Date"
                >
                  <nz-date-picker
                    name="ddddddddate"
                    [(ngModel)]="data.TO_DATETIME"
                    style="width: 100%"
                  ></nz-date-picker>
                </nz-form-control>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-label nzRequired nzNoColon>Journey To </nz-form-label>

              <nz-form-control nzErrorTip="Please Enter Journey To">
                <nz-input-group style="width: 100%">
                  <input
                    nz-input
                    autocomplete="off"
                    [(ngModel)]="data.ARRIVAL_TO"
                    name="faidfdfgrPaid"
                    type="text"
                    maxlength="256"
                    placeHolder="Enter Journey To"
                    required
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="24">
          <span
            style="color: red; padding-left: 10px"
            *ngIf="
              data.TRAVEL_MODE_ID == 5 &&
              (gradePayLevel == 'Level 1' ||
                gradePayLevel == 'Level 2' ||
                gradePayLevel == 'Level 3' ||
                gradePayLevel == 'Level 4' ||
                gradePayLevel == 'Level 5')
            "
            >Note: Applicant Eligible For First Class of Mode.
          </span>
          <span
            style="color: red; padding-left: 10px"
            *ngIf="
              data.TRAVEL_MODE_ID == 5 &&
              (gradePayLevel == 'Level 6' ||
                gradePayLevel == 'Level 7' ||
                gradePayLevel == 'Level 8' ||
                gradePayLevel == 'Level 9' ||
                gradePayLevel == 'Level 10' ||
                gradePayLevel == 'Level 11' ||
                gradePayLevel == 'Level 12' ||
                gradePayLevel == 'Level 13')
            "
            >Note: Applicant Eligible For Economy Class of Mode.
          </span>
          <span
            style="color: red; padding-left: 10px"
            *ngIf="
              data.TRAVEL_MODE_ID == 5 &&
              (gradePayLevel == 'Level 14' ||
                gradePayLevel == 'Level 15' ||
                gradePayLevel == 'Level 16' ||
                gradePayLevel == 'Level 17')
            "
            >Note: Applicant Eligible For Business, Club Class of Mode.
          </span>
          <span
            style="color: red; padding-left: 10px"
            *ngIf="
              data.TRAVEL_MODE_ID == 4 &&
              (gradePayLevel == 'Level 1' ||
                gradePayLevel == 'Level 2' ||
                gradePayLevel == 'Level 3' ||
                gradePayLevel == 'Level 4' ||
                gradePayLevel == 'Level 5')
            "
            >Note: Applicant Eligible For AC II, AC Chair Class of Mode.
          </span>
          <span
            style="color: red; padding-left: 10px"
            *ngIf="
              data.TRAVEL_MODE_ID == 4 &&
              (gradePayLevel == 'Level 6' ||
                gradePayLevel == 'Level 7' ||
                gradePayLevel == 'Level 8' ||
                gradePayLevel == 'Level 9' ||
                gradePayLevel == 'Level 10')
            "
            >Note: Applicant Eligible For AC II Class of Mode.
          </span>
          <span
            style="color: red; padding-left: 10px"
            *ngIf="
              data.TRAVEL_MODE_ID == 4 &&
              (gradePayLevel == 'Level 12' ||
                gradePayLevel == 'Level 13' ||
                gradePayLevel == 'Level 14' ||
                gradePayLevel == 'Level 15' ||
                gradePayLevel == 'Level 16' ||
                gradePayLevel == 'Level 17')
            "
            >Note: Applicant Eligible For AC I Class of Mode.
          </span>
        </div>

        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>Travel Mode</nz-form-label>
            <nz-form-control nzHasFeedback nzErrorTip="Select Mode">
              <nz-select
                nzAllowClear
                name="travelmode"
                [(ngModel)]="data.TRAVEL_MODE_ID"
                style="width: 100%"
                nzPlaceHolder="Select Mode"
                (ngModelChange)="travelMode($event)"
              >
                <nz-option
                  *ngFor="let role of modedata"
                  [nzValue]="role.ID"
                  [nzLabel]="role.NAME"
                >
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>Class of Mode</nz-form-label>
            <nz-form-control nzHasFeedback nzErrorTip="Select Class Mode">
              <nz-select
                nzAllowClear
                name="room"
                [(ngModel)]="data.TRAVEL_CLASS_ID"
                style="width: 100%"
                nzPlaceHolder="Select Class Mode"
              >
                <nz-option
                  *ngFor="let role of classdata"
                  [nzValue]="role.ID"
                  [nzLabel]="role.NAME"
                >
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>No. of Fairs</nz-form-label>
            <nz-form-control nzErrorTip="Please Enter No. of Fairs">
              <nz-input-group style="width: 100%" nzAddOnAfter="No's">
                <input
                  nz-input
                  autocomplete="off"
                  (keypress)="omit($event)"
                  [(ngModel)]="data.NO_OF_FAIRS"
                  name="amsdfdount"
                  type="text"
                  maxlength="9"
                  placeHolder="Enter No. of Fairs"
                  required
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>Distance</nz-form-label>
            <nz-form-control nzErrorTip="Please Enter Distance">
              <nz-input-group style="width: 100%" nzAddOnAfter="Kms">
                <input
                  nz-input
                  autocomplete="off"
                  (keypress)="omit($event)"
                  [(ngModel)]="data.DISTANCE_BY_ROAD"
                  name="rafdsgte"
                  type="text"
                  maxlength="8"
                  placeHolder="Enter Distance"
                  required
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon
              >Fair Paid Claimed
            </nz-form-label>
            <nz-form-control nzErrorTip="Please Enter Fair Paid Claimed">
              <nz-input-group style="width: 100%" nzAddOnBefore="₹">
                <input
                  nz-input
                  autocomplete="off"
                  (keypress)="omit($event)"
                  [(ngModel)]="data.TRANSFER_JOURNEY_CLAIMED_AMOUNT"
                  name="FAIR_PAID"
                  type="text"
                  maxlength="8"
                  placeHolder="Enter Fair Paid Claimed"
                  required
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon
              >Fair Paid Admisible</nz-form-label
            >
            <nz-form-control nzErrorTip="Please Enter Fair Paid Admisible">
              <nz-input-group style="width: 100%" nzAddOnBefore="₹">
                <input
                  nz-input
                  autocomplete="off"
                  (keypress)="omit($event)"
                  [(ngModel)]="data.FAIR_PAID"
                  name="rate"
                  type="text"
                  maxlength="8"
                  placeHolder="Enter Fair Paid Admisible"
                  required
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="12" *ngIf="data.TRAVEL_MODE_ID == 5">
          <nz-form-item>
            <nz-form-label nzNoColon>Ticket</nz-form-label>

            <nz-form-control nzHasFeedback nzErrorTip="PDF File is required">
              <button
                nz-button
                style="height: 28px; padding: 0px 7px; font-size: 12px"
                (click)="fileInput1.click()"
              >
                Select File
                <input
                  #fileInput1
                  name="AIR_TICKET"
                  [(ngModel)]="data.AIR_TICKET"
                  type="file"
                  (change)="onFileSelected($event)"
                  style="display: none"
                  required
                />
              </button>
            </nz-form-control>
          </nz-form-item>
          <div
            nz-col
            nzSpan="23"
            *ngIf="data.AIR_TICKET != null"
            style="overflow: hidden; padding-left: 11px; font-size: 13px"
          >
            {{ data.AIR_TICKET }} <br /><br />
            <div *ngIf="data.AIR_TICKET != null">
              <button
                nz-button
                style="height: 28px; padding: 0px 7px; font-size: 12px"
                (click)="airTickets(data.AIR_TICKET)"
              >
                Open Air Ticket PDF File
              </button>
            </div>
          </div>
        </div>

        <div nz-col nzSpan="12" *ngIf="data.TRAVEL_MODE_ID == 5">
          <nz-form-item>
            <nz-form-label nzNoColon>Bording Pass</nz-form-label>

            <nz-form-control nzHasFeedback nzErrorTip="PDF File is required">
              <button
                nz-button
                style="height: 28px; padding: 0px 7px; font-size: 12px"
                (click)="fileInput2.click()"
              >
                Select File
                <input
                  #fileInput2
                  name="BOARDING_PASS"
                  [(ngModel)]="data.BOARDING_PASS"
                  type="file"
                  (change)="onFileSelected1($event)"
                  style="display: none"
                  required
                />
              </button>
            </nz-form-control>
          </nz-form-item>
          <div
            nz-col
            nzSpan="23"
            *ngIf="data.BOARDING_PASS != null"
            style="overflow: hidden; padding-left: 11px; font-size: 13px"
          >
            {{ data.BOARDING_PASS }} <br /><br />
            <div *ngIf="data.BOARDING_PASS != null">
              <button
                nz-button
                style="height: 28px; padding: 0px 7px; font-size: 12px"
                (click)="boardingPass(data.BOARDING_PASS)"
              >
                Open Ticket Boarding Pass PDF File
              </button>
            </div>
          </div>
        </div>

        <div nz-col nzSpan="12" *ngIf="data.TRAVEL_MODE_ID == 4">
          <nz-form-item>
            <nz-form-label nzNoColon>Ticket</nz-form-label>

            <nz-form-control nzHasFeedback nzErrorTip="PDF File is required">
              <button
                nz-button
                style="height: 28px; padding: 0px 7px; font-size: 12px"
                (click)="fileInput3.click()"
              >
                Select File
                <input
                  #fileInput3
                  name="IRCTC_TICKET"
                  [(ngModel)]="data.IRCTC_TICKET"
                  type="file"
                  (change)="onFileSelected3($event)"
                  style="display: none"
                  required
                />
              </button>
            </nz-form-control>
          </nz-form-item>
          <div
            nz-col
            nzSpan="23"
            *ngIf="data.IRCTC_TICKET != null"
            style="overflow: hidden; padding-left: 11px; font-size: 13px"
          >
            {{ data.IRCTC_TICKET }} <br /><br />
            <div *ngIf="data.IRCTC_TICKET != null">
              <button
                nz-button
                style="height: 28px; padding: 0px 7px; font-size: 12px"
                (click)="irctcTickets(data.IRCTC_TICKET)"
              >
                Open IRCTC Ticket PDF File
              </button>
            </div>
          </div>
        </div>

        <div nz-col nzSpan="12" *ngIf="data.TRAVEL_MODE_ID == 6">
          <nz-form-item>
            <nz-form-label nzNoColon>Ticket</nz-form-label>

            <nz-form-control nzHasFeedback nzErrorTip="PDF File is required">
              <button
                nz-button
                style="height: 28px; padding: 0px 7px; font-size: 12px"
                (click)="fileInput4.click()"
              >
                Select File
                <input
                  #fileInput4
                  name="BOAT_SHIP_TICKET"
                  [(ngModel)]="data.BOAT_SHIP_TICKET"
                  type="file"
                  (change)="onFileSelected4($event)"
                  style="display: none"
                  required
                />
              </button>
            </nz-form-control>
          </nz-form-item>
          <div
            nz-col
            nzSpan="23"
            *ngIf="data.BOAT_SHIP_TICKET != null"
            style="overflow: hidden; padding-left: 11px; font-size: 13px"
          >
            {{ data.BOAT_SHIP_TICKET }} <br /><br />
            <div *ngIf="data.BOAT_SHIP_TICKET != null">
              <button
                nz-button
                style="height: 28px; padding: 0px 7px; font-size: 12px"
                (click)="boatShipTickets(data.BOAT_SHIP_TICKET)"
              >
                Open Boat Ship Ticket PDF File
              </button>
            </div>
          </div>
        </div>

        <div nz-col nzSpan="12" *ngIf="data.TRAVEL_MODE_ID == 3">
          <nz-form-item>
            <nz-form-label nzNoColon>Ticket</nz-form-label>

            <nz-form-control nzHasFeedback nzErrorTip="PDF File is required">
              <button
                nz-button
                style="height: 28px; padding: 0px 7px; font-size: 12px"
                (click)="fileInput4.click()"
              >
                Select File
                <input
                  #fileInput4
                  name="ROAD_TICKET"
                  [(ngModel)]="data.ROAD_TICKET"
                  type="file"
                  (change)="onFileSelected2($event)"
                  style="display: none"
                  required
                />
              </button>
            </nz-form-control>
          </nz-form-item>
          <div
            nz-col
            nzSpan="23"
            *ngIf="data.ROAD_TICKET != null"
            style="overflow: hidden; padding-left: 11px; font-size: 13px"
          >
            {{ data.ROAD_TICKET }} <br /><br />
            <div *ngIf="data.ROAD_TICKET != null">
              <button
                nz-button
                style="height: 28px; padding: 0px 7px; font-size: 12px"
                (click)="roadTickets(data.ROAD_TICKET)"
              >
                Open Road Ticket PDF File
              </button>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>
  </form>
</div>

<div class="footer">
  <button
    type="button"
    nz-button
    (click)="close(AddNewRecord)"
    class="ant-btn"
    style="margin-right: 8px"
  >
    <span>Cancel</span>
  </button>
  <button
    type="button"
    class="ant-btn ant-btn-primary"
    nzType="primary"
    *ngIf="!data.ID"
    nz-button
    nz-dropdown
    [nzDropdownMenu]="menu"
    [nzLoading]="isSpinning"
    (click)="save(false, AddNewRecord)"
  >
    <span>Save & Close</span>
  </button>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu>
      <li
        nz-menu-item
        class="menuc"
        style="border-radius: 4px; background-color: #40a9ff"
      >
        <a style="color: white" (click)="save(true, AddNewRecord)"
          >Save & New</a
        >
      </li>
    </ul>
  </nz-dropdown-menu>
  <button
    type="button"
    class="ant-btn ant-btn-primary"
    nz-button
    [nzLoading]="isSpinning"
    *ngIf="data.ID"
    (click)="save(false, AddNewRecord)"
  >
    <span>Update & Close</span>
  </button>
</div>
<div class="header">
  <button
    type="button"
    nz-button
    (click)="close(AddNewRecord)"
    class="ant-btn"
    style="margin-right: 8px"
  >
    <i nz-icon nzType="close" nzTheme="outline"></i>
  </button>
</div>
