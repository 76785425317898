<div nz-row nzType="flex">
    <div nz-col nzXs="24" nzSm="24" nzMd="20" nzLg="20" nzXl="12">
        <div class="formname">{{formTitle}}</div>
    </div>

    <div nz-col nzXs="24" nzSm="24" nzMd="4" nzLg="6" nzXl="12" style="display: flex; justify-content: flex-end">
        <form nz-form [nzLayout]="'inline'">
            <nz-form-item>
                <button nz-button nzTooltipTitle="Excel" nzTooltipPlacement="top" nz-tooltip nzType="primary"
                    (click)="importInExcel()" [nzLoading]="exportLoading">
                    <i nz-icon nzType="file-excel" nzTheme="outline"></i>
                </button>
            </nz-form-item>
            
            <nz-form-item>
                <button nz-button nzTooltipTitle="Filter" nzTooltipPlacement="top" nz-tooltip [nzType]="isFilterApplied"
                    (click)="showFilter()">
                    <i nz-icon nzType="filter"></i>
                </button>
            </nz-form-item>
            <nz-form-item>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                    <input type="text" name="search" [(ngModel)]="searchText" (keydown.enter)="onKeypressEvent($event)"
                        nz-input placeholder="Search" />
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" id="button" (click)="search(true)" nzSearch><i nz-icon
                            nzType="search"></i></button>
                </ng-template>
            </nz-form-item>
        </form>
    </div>
</div>

<div nz-row>
    <div nz-col nzSpan="24">
        <div class="filter {{ filterClass }}">
            <div class="filter-box">

                <form nz-form>
                    <div nz-row>
                        <div nz-col nzSpan="6">
                            <nz-form-item style="margin-top: 1px;width: 100%;">
                                <nz-form-label nzNoColon>Select Date</nz-form-label>
                                <nz-range-picker name="date" nzFormat="dd MMM yyyy" [(ngModel)]="DATE">
                                </nz-range-picker>
                            </nz-form-item>
                        </div>

                        <div nz-col nzSpan="8">
                            <nz-form-label nzNoColon></nz-form-label>
                            <nz-form-item style=" margin-left: 45px">
                                <button style="margin-right: 10px" nz-button [nzLoading]="isSpinning" nzType="primary"
                                    (click)="applyFilter()" nzJustify="end">
                                    <i nz-icon nzType="filter"></i>Apply Filter
                                </button>
                                <button nz-button nzType="primary" (click)="clearFilter()">
                                    <i nz-icon nzType="filter"></i>Clear filter
                                </button>
                            </nz-form-item>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>





<!-- Table -->
<div nz-row>
    <div nz-col nzSpan="24">
        <nz-table id="excel-table" class="my-scroll no-wrap1" nzBordered #table nzShowSizeChanger
            [nzFrontPagination]="false" [nzData]="dataList" [nzLoading]="loadingRecords" [nzTotal]="totalRecords"
            [nzScroll]="{x:'1200px',y:'420px'}"
            [(nzPageIndex)]="pageIndex" [(nzPageSize)]="pageSize" (nzPageIndexChange)="search()"
            (nzPageSizeChange)="search()" (nzQueryParams)="sort($event)">

            <thead nzSingleSort>
                <tr>
                    <!-- <th nzAlign="center" [nzSortFn]="true" nzColumnKey="{{''+column[0] }}" *ngFor="let column of columns;">
                        <b style="max-width: max-content;">{{column[1]}}</b>
                    </th> -->
                    <th nzWidth="100px" style="font-weight: bold; text-align: center;" nzColumnKey="{{'INSPECTOR_NAME'}}" [nzSortFn]="true">
                        Inspector Name
                    </th>
                    <th nzWidth="80px" style="font-weight: bold; text-align: center;" nzColumnKey="{{'CLAIMED_AMOUNT'}}"  [nzSortFn]="true">
                        Claimed Amount
                    </th>
                    <th nzWidth="70px" style="font-weight: bold; text-align: center;" nzColumnKey="{{'ADMISSIBLE_AMOUNT'}}"  [nzSortFn]="true">
                        Admissible Amount
                    </th>
                    <th nzWidth="60px" nzColumnKey="{{'ADVANCE_AMOUNT'}}" [nzSortFn]="true"
                        style="font-weight: bold; text-align: center;">
                        Advance Amount
                    </th>
                    <th nzWidth="70px" nzColumnKey="{{'NET_PAYABLE_AMOUNT'}}" [nzSortFn]="true"
                        style="font-weight: bold; text-align: center;">
                        Net Payable Amount
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let data of table.data;">
                    <!-- <td style="white-space: pre-line;" align="center" *ngFor="let column of columns;">
                        {{data[column[0]] == null ? 'None' : data[column[0]]}}
                    </td> -->
                    <td style="white-space: pre-line;" align="center">{{data['INSPECTOR_NAME']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['CLAIMED_AMOUNT']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['ADMISSIBLE_AMOUNT']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['ADVANCE_AMOUNT']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['NET_PAYABLE_AMOUNT']}}</td>
                </tr>
            </tbody>
        </nz-table>
    </div>
</div>