import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Travelmode } from 'src/app/Models/travelmode';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-traveladd',
  templateUrl: './traveladd.component.html',
  styleUrls: ['./traveladd.component.css']
})
export class TraveladdComponent implements OnInit {
  @Input()
  data: Travelmode = new Travelmode();
  @Input()
  drawerClose!: Function;
  @Input()
  drawerVisible: boolean = false;
  screenwidth: any;
  isSpinning = false;
  isOk = true;
  namepatt = /[a-zA-Z][a-zA-Z ]+/;
  constructor(
    private message: NzNotificationService,
    private api: ApiService
  ) {}

  ngOnInit(): void {
    this.screenwidth = window.innerWidth;
  }

   //// Only number
   omit(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


  close(websitebannerPage: NgForm) {
    this.drawerClose();
    this.resetDrawer(websitebannerPage);
    websitebannerPage.form.reset();
  }

  resetDrawer(websitebannerPage: NgForm) {
    this.data=new Travelmode();
    // this.data.PARENT_GROUP_ID=this.parentgroup;
    websitebannerPage.form.markAsPristine();
    websitebannerPage.form.markAsUntouched();

  }
  
   //save
   save(addNew: boolean,websitebannerPage: NgForm): void {
    this.isSpinning = false;
    this.isOk=true;

    if(  
    this.data.NAME.trim() == ''  
  )
  
    {
      this.isOk=false;
      this.message.error("Please Fill All The Required Fields " ,"") 
  
   

    }


    else if (this.data.NAME == null || this.data.NAME.trim() == '') {
      this.isOk = false;
      this.message.error(' Please Enter Travel Mode Name.', '');
    } 
      

          // create update

     if (this.isOk) {
      this.isSpinning = true;
      {
          if (this.data.ID) {
            this.api.updatetravelmode(this.data).subscribe((successCode) => {
              if (successCode.code == '200') {
                this.message.success('Information Changed Successfully...', '');
                if (!addNew) this.drawerClose();
                this.isSpinning = false;
              } else {
                this.message.error('Information Has Not Changed...', '');
                this.isSpinning = false;
              }
            });
          }
          else{
            this.api.createtravelmode(this.data).subscribe((successCode) => {
              if (successCode.code == '200') {
                this.message.success('Information Save Successfully...', '');
                if (!addNew) this.drawerClose();
                else {
                  this.data = new Travelmode();
                  this.resetDrawer(websitebannerPage);
               
                  
                
                }
                this.isSpinning = false;
              } else {
                this.message.error('Failed To Fill Information...', '');
                this.isSpinning = false;
              }
            });
          }
        }
      }


}

}
