<div>
  <form nz-form #AddNewRecord="ngForm">
    <nz-spin [nzSpinning]="isSpinning">
      <div nz-row>
        <div nz-col nzSpan="11" class="my-box">
          <nz-divider style="font-weight: bold" nzText="Departure"></nz-divider>

          <div nz-row>
            <div nz-col nzSpan="11">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>From Date</nz-form-label>
                <nz-form-control nzErrorTip="Please Select Date">
                  <nz-form-control nzErrorTip="Please Select Date">
                    <nz-date-picker
                      name="FROM_DATETIME"
                      [(ngModel)]="data.FROM_DATETIME"
                      style="width: 100%"
                      [nzShowTime]="{ nzFormat: 'HH:mm' }"
                      nzFormat="yyyy-MM-dd HH:mm"
                      (ngModelChange)="assignToDate($event)"
                    ></nz-date-picker>
                  </nz-form-control>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="13">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon
                  >Journey From
                </nz-form-label>
                <nz-form-control nzErrorTip="Please Enter Fair Paid">
                  <nz-input-group>
                    <input
                      nz-input
                      autocomplete="off"
                      [(ngModel)]="data.DEPARTURE_FROM"
                      name="DEPARTURE_FROM"
                      type="text"
                      maxlength="256"
                      placeHolder="Enter Journey From"
                      required
                    />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </div>

        <div nz-col nzSpan="1"></div>
        <div nz-col nzSpan="12" class="my-box">
          <nz-divider style="font-weight: bold" nzText="Arrival"></nz-divider>
          <div nz-row>
            <div nz-col nzSpan="11">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>To Date</nz-form-label>
                <nz-form-control nzErrorTip="Please Select to Date">
                  <nz-form-control nzErrorTip="Please Select to Date">
                    <nz-date-picker
                      name="TO_DATETIME"
                      [(ngModel)]="data.TO_DATETIME"
                      style="width: 100%"
                      [nzShowTime]="{ nzFormat: 'HH:mm' }"
                      nzFormat="yyyy-MM-dd HH:mm"
                    ></nz-date-picker>
                  </nz-form-control>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="13">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Journey To </nz-form-label>
                <nz-form-control nzErrorTip="Please Enter Journey To">
                  <nz-input-group>
                    <input
                      nz-input
                      autocomplete="off"
                      [(ngModel)]="data.ARRIVAL_TO"
                      name="ARRIVAL_TO"
                      type="text"
                      maxlength="256"
                      placeHolder="Enter Journey To"
                      required
                    />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </div>
      </div>
      <!-- <div nz-col nzSpan="12"> -->

      <div nz-row class="my-box">
        <div nz-col nzSpan="24">
          <span
            style="color: red; padding-left: 10px"
            *ngIf="
              data.TRAVEL_MODE_ID == 5 &&
              (gradePayLevel == 'Level 1' ||
                gradePayLevel == 'Level 2' ||
                gradePayLevel == 'Level 3' ||
                gradePayLevel == 'Level 4' ||
                gradePayLevel == 'Level 5')
            "
            >Note: Applicant Eligible For First Class of Mode.
          </span>
          <span
            style="color: red; padding-left: 10px"
            *ngIf="
              data.TRAVEL_MODE_ID == 5 &&
              (gradePayLevel == 'Level 6' ||
                gradePayLevel == 'Level 7' ||
                gradePayLevel == 'Level 8' ||
                gradePayLevel == 'Level 9' ||
                gradePayLevel == 'Level 10' ||
                gradePayLevel == 'Level 11' ||
                gradePayLevel == 'Level 12' ||
                gradePayLevel == 'Level 13')
            "
            >Note: Applicant Eligible For Economy Class of Mode.
          </span>
          <span
            style="color: red; padding-left: 10px"
            *ngIf="
              data.TRAVEL_MODE_ID == 5 &&
              (gradePayLevel == 'Level 14' ||
                gradePayLevel == 'Level 15' ||
                gradePayLevel == 'Level 16' ||
                gradePayLevel == 'Level 17')
            "
            >Note: Applicant Eligible For Business, Club Class of Mode.
          </span>
          <span
            style="color: red; padding-left: 10px"
            *ngIf="
              data.TRAVEL_MODE_ID == 4 &&
              (gradePayLevel == 'Level 1' ||
                gradePayLevel == 'Level 2' ||
                gradePayLevel == 'Level 3' ||
                gradePayLevel == 'Level 4' ||
                gradePayLevel == 'Level 5')
            "
            >Note: Applicant Eligible For AC II, AC Chair Class of Mode.
          </span>
          <span
            style="color: red; padding-left: 10px"
            *ngIf="
              data.TRAVEL_MODE_ID == 4 &&
              (gradePayLevel == 'Level 6' ||
                gradePayLevel == 'Level 7' ||
                gradePayLevel == 'Level 8' ||
                gradePayLevel == 'Level 9' ||
                gradePayLevel == 'Level 10')
            "
            >Note: Applicant Eligible For AC II Class of Mode.
          </span>
          <span
            style="color: red; padding-left: 10px"
            *ngIf="
              data.TRAVEL_MODE_ID == 4 &&
              (gradePayLevel == 'Level 12' ||
                gradePayLevel == 'Level 13' ||
                gradePayLevel == 'Level 14' ||
                gradePayLevel == 'Level 15' ||
                gradePayLevel == 'Level 16' ||
                gradePayLevel == 'Level 17')
            "
            >Note: Applicant Eligible For AC I Class of Mode.
          </span>
        </div>

        <div nz-col nzSpan="5">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>Travel Mode</nz-form-label>
            <nz-form-control nzErrorTip="Select Mode">
              <nz-select
                nzAllowClear
                name="TRAVEL_MODE_ID"
                (ngModelChange)="getClasses($event)"
                [(ngModel)]="data.TRAVEL_MODE_ID"
                style="width: 100%"
                nzPlaceHolder="Select Mode"
              >
                <nz-option
                  *ngFor="let role of modedata"
                  [nzValue]="role.ID"
                  [nzLabel]="role.NAME"
                >
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="5">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>Class of Mode</nz-form-label>
            <nz-form-control nzErrorTip="Select Class Mode">
              <nz-select
                nzAllowClear
                name="room"
                [(ngModel)]="data.TRAVEL_CLASS_ID"
                style="width: 100%"
                nzPlaceHolder="Select Class Mode"
              >
                <nz-option
                  *ngFor="let role of classdata"
                  [nzValue]="role.ID"
                  [nzLabel]="role.NAME"
                >
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="4">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>No of Fairs</nz-form-label>
            <nz-form-control nzErrorTip="Please Enter No of Fairs">
              <nz-input-group nzAddOnAfter="No's">
                <input
                  nz-input
                  autocomplete="off"
                  (keypress)="omit($event)"
                  [(ngModel)]="data.NO_OF_FAIRS"
                  name="NO_OF_FAIRS"
                  type="text"
                  maxlength="8"
                  placeHolder="Enter No of Fairs"
                  required
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="5">
          <nz-form-item>
            <nz-form-label nzNoColon>Distance of Road</nz-form-label>
            <nz-form-control nzErrorTip="Please Enter Distance of Road">
              <nz-input-group nzAddOnAfter="Kms">
                <input
                  nz-input
                  autocomplete="off"
                  (keypress)="omit($event)"
                  [(ngModel)]="data.DISTANCE_BY_ROAD"
                  name="DISTANCE_BY_ROAD"
                  type="text"
                  maxlength="8"
                  placeHolder="Enter Distance of Road"
                  required
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="5">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon
              >Fair Paid claimed</nz-form-label
            >
            <nz-form-control nzErrorTip="Please Enter Fair Paid claimed">
              <nz-input-group nzAddOnBefore="₹">
                <input
                  nz-input
                  autocomplete="off"
                  (keypress)="omit($event)"
                  [(ngModel)]="data.JOURNEY_CLAIMED_AMOUNT"
                  name="JOURNEY_CLAIMED_AMOUNT"
                  type="text"
                  maxlength="8"
                  placeHolder="Enter Fair Paid claimed"
                  required
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="5">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon
              >Base Fare</nz-form-label
            >
            <nz-form-control nzErrorTip="Please Enter Base Fare">
              <nz-input-group nzAddOnBefore="₹">
                <input
                  nz-input
                  autocomplete="off"
                  (keypress)="omit($event)"
                  [(ngModel)]="data.FAIR_PAID"
                  name="FAIR_PAID"
                  type="text"
                  maxlength="8"
                  placeHolder="Enter Fair Paid Admisible"
                  required
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div nz-row>
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzNoColon>Remark</nz-form-label>
            <nz-form-control>
              <textarea
                rows="2"
                nz-input
                [(ngModel)]="data.REMARK"
                name="REMARK"
                maxlength="1024"
              ></textarea>
            </nz-form-control>
            <h6 style="color: red" *ngIf="data.REMARK != undefined">
              {{ data.REMARK.length }}/1024
            </h6>
          </nz-form-item>
        </div>
        <!-- <div nz-col nzSpan="6" *ngIf="data.TRAVEL_MODE_ID == 5">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>Other Charges</nz-form-label>
            <nz-form-control nzErrorTip="Please Enter Other Charges">
              <nz-input-group nzAddOnBefore="₹">
                <input
                  nz-input
                  autocomplete="off"
                  [(ngModel)]="data.OTHER_CHARGES"
                  name="OTHER_CHARGES"
                  type="text"
                  maxlength="8"
                  (keypress)="omit($event)"
                  placeHolder="Enter Other Charges"
                  required
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div> -->
      </div>

      <div nz-row>
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzNoColon>Ticket</nz-form-label>

            <nz-form-control nzHasFeedback nzErrorTip="PDF File is required">
              <button
                nz-button
                style="height: 28px; padding: 0px 7px; font-size: 12px"
                (click)="fileInput1.click()"
              >
                Select File
                <input
                  #fileInput1
                  name="TICKET_URL"
                  [(ngModel)]="data.TICKET_URL"
                  type="file"
                  (change)="onFileSelected($event)"
                  style="display: none"
                  required
                />
              </button>
            </nz-form-control>
          </nz-form-item>
          <div
            nz-col
            nzSpan="23"
            *ngIf="data.TICKET_URL != null"
            style="overflow: hidden; padding-left: 11px; font-size: 13px"
          >
            {{ data.TICKET_URL }} <br /><br />
            <div *ngIf="data.TICKET_URL != null">
              <button
                nz-button
                style="height: 28px; padding: 0px 7px; font-size: 12px"
                (click)="ticketsView(data.TICKET_URL)"
              >
                Open Ticket PDF File
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </nz-spin>
  </form>
</div>

<div class="footer">
  <button
    type="button"
    nz-button
    (click)="close(AddNewRecord)"
    class="ant-btn"
    style="margin-right: 8px"
  >
    <span>Cancel</span>
  </button>
  <button
    type="button"
    class="ant-btn ant-btn-primary"
    nzType="primary"
    *ngIf="!data.ID"
    nz-button
    nz-dropdown
    [nzDropdownMenu]="menu"
    [nzLoading]="isSpinning"
    (click)="save(false, AddNewRecord)"
  >
    <span>Save & Close</span>
  </button>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu>
      <li
        nz-menu-item
        class="menuc"
        style="border-radius: 4px; background-color: #40a9ff"
      >
        <a style="color: white" (click)="save(true, AddNewRecord)"
          >Save & New</a
        >
      </li>
    </ul>
  </nz-dropdown-menu>
  <button
    type="button"
    class="ant-btn ant-btn-primary"
    nz-button
    [nzLoading]="isSpinning"
    *ngIf="data.ID"
    (click)="save(false, AddNewRecord)"
  >
    <span>Update & Close</span>
  </button>
</div>
<div class="header">
  <button
    type="button"
    nz-button
    (click)="close(AddNewRecord)"
    class="ant-btn"
    style="margin-right: 8px"
  >
    <i nz-icon nzType="close" nzTheme="outline"></i>
  </button>
</div>
