export class Tourmaster {
  ID: any;
  EMP_ID: any;
  CLIENT_ID = 0;
  TOUR_ID: any;
  EMPLOYEE_NAME: any;
  DESIGNATION: any;
  TRAVELLED_M_E_OT: any;
  BASIC_PAY: any;
  HEADQUARTERS_NAME: any;
  TRAVEL_MODE_ID: any;
  IS_EXCHANGE_VOUCHER_ARRANGED_BY_OFFICE: any;
  TICKET_EXCHANGE_VOUCHER_ARRANGED_BY: any;
  WHETHER_TRAVELLED_BY_M_E_OT: any;
  WHETHER_RETURN_TICKET_WAS_AVAILABLE: any;
  IS_WHETHER_RETURN_TICKET_PURCHASED: any;
  REASON: any;
  MODE_OF_CONVEYANCE_USED: any;
  DATES_OF_ABSENT_FROM_PLACE: any;
  R_H: any;
  C_L: any;
  NOT_BEING_ACTUALLY_IN_CAMP_ON_SUNDAY_HOLIDAY: any;
  DATES_OF_FREE_BOARD_AND_LODGING: any;
  BOARD: Boolean = false;
  LODGING: Boolean = false;
  BOARD_AND_LODGING: Boolean = false;
  HIGH_CLASS_ACCOMODATION_PERFORMED: any;
  APPROVAL_OF_AUTHORITY_NO: any;
  DATE_OF_SANCTION: any;
  AMOUNT_OF_T_A: any;
  CERTIFY_INFORMATION: any;
  DATE_OF_CERTIFICATION: any;
  // IS_EXCHANGE_VOUCHER_ARRANGED_BY_OFFICE:any

  journeyData = [];
  EMPLOYEE_NAME_HN: any;
  ADESH_ORDER_NO: any;
  DESIGNATION_HN: any;
  MOBILE_NO: any;
  IKAR_PRN_NO: any;
  IKAR_BANK_ACCOUNT_NO: any;
  SIGNATURE_ID: number = 1;
  DATE: any;
  GRADE_PAY: any;
  GRADE_PAY_LEVEL: any;
  INSPECTOR_ID: any;
  SANCTION_NO: any;
  RETURN_TICKET_PURCHASED_REMARK: any;
  TRAVELLING_ALLOWANCE_DATE: any;
  KILOMETER: any;
  MILAGE_BY_ROAD: any;
  BILL_SECTION_DATE: any;
  ADVANCE_TAKEN_DATE: any;
  ADVANCE_TAKEN_VOUCHER_NO: any;
  OFFICE_NAME_HN: any;
  OFFICE_NAME: any;
  IS_ADVANCE_TAKEN: boolean = false;
  ADESH_REASON: any =
    'के दिनांक 30.04.2023 से 02.05.2023 तक जिला एंव सत्र न्यायालय चण्डीगढ़ में केन्द्रीय जाँच ब्यूरो बनाम गुरुनाम सिंह केस के सिलसिले में मुंबई से चण्डीगढ़ जाने तथा वापस आने हेतु एस. आर. 61 के अधीन एतदद्वारा स्वीकृति प्रदान की जाती है । प्रमाणित किया जाता है कि यह दौरा सरकारी कामकाज के सिलसिले में किया जा रहा है और दौरा लोकहित में है ।';

  // PLACE_ORDER_NET_AMOUNT: any;

  PLACE_ORDER_ROAD_ADMISSIBLE_AMOUNT: any = 0;
  PLACE_ORDER_ROAD_REMARK;
  PLACE_ORDER_SHIP_ADMISSIBLE_AMOUNT: any = 0;
  PLACE_ORDER_SHIP_REMARK;
  PLACE_ORDER_ACCOMODATION_ADMISSIBLE_AMOUNT: any = 0;
  PLACE_ORDER_ACCOMODATION_REMARK;
  PLACE_ORDER_AIR_ADMISSIBLE_AMOUNT: any = 0;
  PLACE_ORDER_AIR_REMARK;
  PLACE_ORDER_RAIL_ADMISSIBLE_AMOUNT: any = 0;
  PLACE_ORDER_RAIL_REMARK;
  PLACE_ORDER_GROSS_AMOUNT: any = 0;
  PLACE_ORDER_NET_AMOUNT: any = 0;
  ADDRESS: any;
  FILE_ID: any;
  TOUR_STATUS: any = '';
  FOOD_ADMISIBLE_AMOUNT: any = 0;
  FOOD_REMARK: any = '';
  S_R_61: any;
  BANK_MANDATE_FORM: any;
  TOUR_SELF_DECLARATION: any;
  TOUR_DELAY_CONDOLATION_URL: any;

  // ACTION_DATE:any=null;
  // ACTION_REMARK:any=null;
  // ACTION_STATUS:any='';
}
