<div>
  <form nz-form #AddNewRecord="ngForm">
    <nz-spin [nzSpinning]="isSpinning">
      <div nz-row>
        <!-- <div nz-row class="my-box"> -->
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>Date</nz-form-label>
            <nz-form-control nzHasFeedback nzErrorTip="Please Select Date">
              <nz-date-picker
                name="date"
                [(ngModel)]="data.DATE"
                style="width: 100%"
                required
              ></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>Transport Mode</nz-form-label>
            <nz-form-control nzHasFeedback nzErrorTip="Select Transport Mode">
              <nz-select
                nzAllowClear
                name="room"
                [(ngModel)]="data.TRANSPORT_MODE_ID"
                style="width: 100%"
                nzPlaceHolder="Select Transport Mode"
              >
                <nz-option
                  *ngFor="let role of modedata"
                  [nzValue]="role.ID"
                  [nzLabel]="role.NAME"
                >
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>Station From</nz-form-label>
            <nz-form-control nzErrorTip="Please Enter Station From">
              <input
                nz-input
                name="fromstation"
                placeHolder="Enter Station From"
                autocomplete="off"
                maxlength="256"
                [(ngModel)]="data.FROM_STATION"
                required
              />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>Station To</nz-form-label>
            <nz-form-control nzErrorTip="Please Enter Station To">
              <input
                nz-input
                name="tostation"
                maxlength="256"
                placeHolder="Enter Station To"
                autocomplete="off"
                [(ngModel)]="data.TO_STATION"
                required
              />
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>Weight</nz-form-label>
            <nz-form-control nzErrorTip="Please Enter Weight">
              <nz-input-group style="width: 100%" nzAddOnAfter="Kgs.">
                <input
                  nz-input
                  autocomplete="off"
                  (keypress)="omit($event)"
                  (ngModelChange)="weightCalculation($event)"
                  [(ngModel)]="data.WEIGHT_IN_KG"
                  name="weight"
                  type="text"
                  maxlength="9"
                  placeHolder="Enter Weight"
                  required
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>Rate</nz-form-label>
            <nz-form-control nzErrorTip="Please Enter Rate">
              <nz-input-group style="width: 100%" nzAddOnBefore="₹">
                <input
                  nz-input
                  autocomplete="off"
                  (keypress)="omit($event)"
                  (ngModelChange)="rateCalculation($event)"
                  [(ngModel)]="data.RATE"
                  name="rate"
                  type="text"
                  maxlength="8"
                  placeHolder="Enter Rate"
                  required
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>Distance</nz-form-label>
            <nz-form-control nzErrorTip="Please Enter Distance">
              <nz-input-group style="width: 100%" nzAddOnAfter="km">
                <input
                  nz-input
                  autocomplete="off"
                  (keypress)="omit($event)"
                  [(ngModel)]="data.DISTANCE"
                  (ngModelChange)="distanceCalculation($event)"
                  name="Distance"
                  type="text"
                  maxlength="8"
                  placeHolder="Enter Distance"
                  required
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon
              >Admissible Amount</nz-form-label
            >
            <nz-form-control nzErrorTip="Please Enter Admissible Amount">
              <nz-input-group style="width: 100%" nzAddOnBefore="₹">
                <input
                  nz-input
                  autocomplete="off"
                  (keypress)="omit($event)"
                  [(ngModel)]="data.AMOUNT"
                  name="amount"
                  type="text"
                  maxlength="8"
                  placeHolder="Enter Admissible Amount"
                  required
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>Claimed Amount</nz-form-label>
            <nz-form-control nzErrorTip="Please Enter Claimed Amount">
              <nz-input-group style="width: 100%" nzAddOnBefore="₹">
                <input
                  nz-input
                  autocomplete="off"
                  (keypress)="omit($event)"
                  [(ngModel)]="data.TRANSPORTATION_CLAIMED_AMOUNT"
                  name="TRANSPORTATION_CLAIMED_AMOUNT"
                  type="text"
                  maxlength="8"
                  placeHolder="Enter Claimed Amount"
                  required
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="24">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>Remark</nz-form-label>
            <nz-form-control>
              <textarea
                nz-input
                [nzAutosize]="{ minRows: 3, maxRows: 6 }"
                name="remark"
                placeHolder="Enter Remark"
                [(ngModel)]="data.REMARKS"
                maxlength="1024"
              ></textarea>
              <h6 style="color: red" *ngIf="data.REMARKS != undefined">
                {{ data.REMARKS.length }}/1024
              </h6>
            </nz-form-control>

            <!-- <span
              style="font-size: 10px; color: red"
              *ngIf="data.REMARKS != undefined"
              >{{ REMARKS.length }}/ 1024</span
            > -->
          </nz-form-item>
        </div>
      </div>
    </nz-spin>
  </form>

  <div class="footer">
    <button
      type="button"
      nz-button
      (click)="close(AddNewRecord)"
      class="ant-btn"
      style="margin-right: 8px"
    >
      <span>Cancel</span>
    </button>
    <button
      type="button"
      class="ant-btn ant-btn-primary"
      nzType="primary"
      *ngIf="!data.ID"
      nz-button
      nz-dropdown
      [nzDropdownMenu]="menu"
      [nzLoading]="isSpinning"
      (click)="save(false, websitebannerPage)"
    >
      <span>Save & Close</span>
    </button>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li
          nz-menu-item
          class="menuc"
          style="border-radius: 4px; background-color: #40a9ff"
        >
          <a style="color: white" (click)="save(true, AddNewRecord)"
            >Save & New</a
          >
        </li>
      </ul>
    </nz-dropdown-menu>
    <button
      type="button"
      class="ant-btn ant-btn-primary"
      nz-button
      [nzLoading]="isSpinning"
      *ngIf="data.ID"
      (click)="save(false, AddNewRecord)"
    >
      <span>Update & Close</span>
    </button>
  </div>
  <div class="header">
    <button
      type="button"
      nz-button
      (click)="close(AddNewRecord)"
      class="ant-btn"
      style="margin-right: 8px"
    >
      <i nz-icon nzType="close" nzTheme="outline"></i>
    </button>
  </div>
</div>
