<div>
  <form nz-form #delayCondolencePermissionPage="ngForm">
    <nz-spin [nzSpinning]="isSpinning">
      <div nz-row [nzGutter]="10">
        <div nz-col nzXs="8" nzSm="8" nzMd="8" nzLg="8" nzXl="8" nzSpan="8">
          <div class="my-box" style="height: 78vh; overflow-y: auto">
            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <!-- <div>
                    <nz-form-item>
                     
                      <nz-radio-group
                        [(ngModel)]="data.PAY_BILL"
                        name="radiogaz"
                      >
                        <label nz-radio nzValue="NonGazPay">Welfare & S.G </label>
                        <label nz-radio nzValue="GazPay">Gaz Pay Bill</label>
                      </nz-radio-group>
                    </nz-form-item>
                  </div> -->

                <div nz-col nzSpan="24">
                  <nz-form-item>
                    <nz-form-label nzRequired nzNoColon
                      >Signature</nz-form-label
                    >
                    <nz-form-control>
                      <nz-select
                        name="userid"
                        [(ngModel)]="data.SIGNATURE_ID"
                        nzAllowClear
                        nzPlaceHolder="Choose Signature"
                        (ngModelChange)="signature($event)"
                      >
                        <nz-option
                          *ngFor="let user of Signaturearray"
                          [nzValue]="user.ID"
                          [nzLabel]="user.NAME"
                        >
                        </nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
            </div>
            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="12"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Delay Condolence Letter Date
                  </nz-form-label>

                  <nz-form-control>
                    <nz-date-picker
                      style="width: 100%"
                      nzFormat="dd/MM/yyyy"
                      name="LETTER_DATE"
                      [(ngModel)]="data.LETTER_DATE"
                    >
                    </nz-date-picker>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>
                    Delay Condolence Expence amount</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Expence amount is required"
                  >
                    <input
                      style="width: 100%"
                      name="EXPENCE_AMOUNT"
                      nz-input
                      placeholder="Enter Expence amount"
                      type="text"
                      (keypress)="omit($event)"
                      [(ngModel)]="data.EXPENCE_AMOUNT"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item
                  style="
                    display: inline-block;
                    margin-bottom: 5px;
                    width: -webkit-fill-available;
                    padding: 0px 10px;
                  "
                >
                  <nz-form-label nzRequired nzNoColon>
                    Delay Condolence Permission Number</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Permission Number is required"
                  >
                    <input
                      style="width: 100%"
                      name="permissionno"
                      nz-input
                      placeholder="Enter Permission Number"
                      type="text"
                      maxlength="60"
                      [(ngModel)]="data.PERMISSION_NO"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>

            <div *ngFor="let data of HospitalMapping; let i = index">
              <div class="my-box">
                <div nz-row>
                  <div nz-col nzSpan="24">
                    <nz-form-item>
                      <nz-form-label nzNoColon>
                        Name of Hospital
                      </nz-form-label>

                      <nz-form-control
                        nzHasFeedback
                        nzErrorTip="Name of Hospital is required"
                      >
                        <input
                          style="width: 100%"
                          name="NAMENAME{{ i }}"
                          nz-input
                          placeholder="Enter name in english"
                          autocomplete="off"
                          type="text"
                          disabled="true"
                          [(ngModel)]="data.NAME"
                        />
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
                <!-- <div nz-row>
                    <div
                      nz-col
                      nzXs="24"
                      nzSm="24"
                      nzMd="24"
                      nzLg="24"
                      nzXl="24"
                      nzSpan="24"
                    >
                      <nz-form-item>
                        <nz-form-label nzRequired nzNoColon
                          >Name of Hospital(in hindi)</nz-form-label
                        >
    
                        <nz-form-control
                          nzHasFeedback
                          nzErrorTip="Name of Hospital(in hindi) is required"
                        >
                          <input
                            style="width: 100%"
                            nz-input
                            placeholder="Enter name of Hospital in hindi"
                            autocomplete="off"
                            name="NAME_HN{{ i }}"
                            type="text"
                            [(ngModel)]="data.NAME_HN"
                          />
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                  </div> -->

                <div nz-row>
                  <div
                    nz-col
                    nzXs="24"
                    nzSm="24"
                    nzMd="24"
                    nzLg="24"
                    nzXl="24"
                    nzSpan="24"
                  >
                    <nz-form-item>
                      <nz-form-label nzNoColon
                        >Address of Hospital
                      </nz-form-label>

                      <nz-form-control
                        nzHasFeedback
                        nzErrorTip="Name of Address is required"
                      >
                        <input
                          style="width: 100%"
                          name="ADDRESS{{ i }}"
                          nz-input
                          placeholder="Enter Address in english"
                          autocomplete="off"
                          type="text"
                          [(ngModel)]="data.ADDRESS"
                        />
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
                <!-- <div nz-row>
                    <div
                      nz-col
                      nzXs="24"
                      nzSm="24"
                      nzMd="24"
                      nzLg="24"
                      nzXl="24"
                      nzSpan="24"
                    >
                      <nz-form-item>
                        <nz-form-label nzRequired nzNoColon
                          >Address of Hospital(in hindi)</nz-form-label
                        >
    
                        <nz-form-control
                          nzHasFeedback
                          nzErrorTip="Address of Hospital(in hindi) is required"
                        >
                          <input
                            style="width: 100%"
                            nz-input
                            placeholder="Enter Address of Hospital in hindi"
                            autocomplete="off"
                            name="ADDRESS_HN{{ i }}"
                            type="text"
                            [(ngModel)]="data.ADDRESS_HN"
                          />
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                  </div> -->

                <div>
                  <nz-form-item>
                    <nz-form-label nzRequired nzNoColon
                      >Show In Order</nz-form-label
                    >
                    <nz-form-control>
                      <nz-switch
                        name="IS_VISIBLE_IN_ORDER{{ i }}"
                        nzCheckedChildren="Yes"
                        nzUnCheckedChildren="No"
                        [(ngModel)]="HospitalMapping[i].IS_VISIBLE_IN_ORDER"
                      ></nz-switch>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
            </div>

            <!-- <div nz-row *ngIf="data.HOSPITAL_TYPE == 'E'">
                <div
                  nz-col
                  nzXs="24"
                  nzSm="24"
                  nzMd="24"
                  nzLg="24"
                  nzXl="24"
                  nzSpan="24"
                >
                  <nz-form-item>
                    <nz-form-label nzNoColon>Name of Hospital </nz-form-label>
  
                    <nz-form-control
                      nzHasFeedback
                      nzErrorTip="Name of patient is required"
                    >
                      <input
                        style="width: 100%"
                        name="EMPANELLED_HOSPITAL_NAME"
                        nz-input
                        placeholder="Enter name in english"
                        autocomplete="off"
                        type="text"
                        disabled="true"
                        [(ngModel)]="data.EMPANELLED_HOSPITAL_NAME"
                      />
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div> -->

            <!-- <div nz-row>
                              <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" nzSpan="24">
                                  <nz-form-item>
                                      <nz-form-label  nzNoColon>Amount (in words)</nz-form-label>
  
                                      <nz-form-control nzHasFeedback nzErrorTip="City Name is required">
                                          <input style="width: 100%;" nz-input placeholder="Enter Amount"
                                              autocomplete="off"  name="amountinwords" type="text"
                                              [(ngModel)]="amountinwords" />
                                      </nz-form-control>
                                  </nz-form-item>
                              </div>
                             
                          </div>
                          <div nz-row>
                              <div  nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" nzSpan="24">
                                  <nz-form-item>
                                      <nz-form-label nzRequired nzNoColon>Amount (in hindi)</nz-form-label>
  
                                      <nz-form-control nzHasFeedback nzErrorTip="City Name is required">
                                          <input style="width: 100%;" nz-input placeholder="Enter amount in hindi"
                                              autocomplete="off" name="amountinwordsh"  type="text"
                                              [(ngModel)]="amountinwordsh" />
                                      </nz-form-control>
                                  </nz-form-item>
                              </div>
                          </div> -->
          </div>
        </div>

        <div nz-col nzXs="16" nzSm="16" nzMd="16" nzLg="16" nzXl="16">
          <div
            class="my-box"
            style="padding: 10px; height: 78vh; overflow-y: auto"
          >
            <div>
              <div nz-row>
                <div
                  style="border: 1px solid"
                  nz-col
                  nzXs="4"
                  nzSm="4"
                  nzMd="4"
                  nzLg="4"
                  nzXl="4"
                >
                  <div style="text-align: center; padding: 36px 9px 9px 9px">
                    <img
                      src="assets/govtOfIndia.png"
                      alt=""
                      height="140px"
                      width="100%"
                    />
                  </div>
                </div>
                <!-- <div
                  style="
                    border-bottom: 1px solid;
                    border-top: 1px solid;
                    border-right: 1px solid;
                  "
                  nz-col
                  nzXs="1"
                  nzSm="1"
                  nzMd="1"
                  nzLg="1"
                  nzXl="1"
                ></div> -->
                <div
                  style="
                    border-bottom: 1px solid;
                    border-top: 1px solid;
                    border-right: 1px solid;
                  "
                  nz-col
                  nzXs="20"
                  nzSm="20"
                  nzMd="20"
                  nzLg="20"
                  nzXl="20"
                >
                  <div
                    style="width: 100%; text-align: center; font-weight: bold"
                  >
                    <div>भारत सरकार</div>
                    <div style="text-transform: uppercase" class="family">
                      Government Of India
                    </div>
                    <div>कार्यालय, प्रधान मुख्य आयकर आयुक्त, मुंबई</div>
                    <div style="text-transform: uppercase" class="family">
                      OFFICE OF THE
                    </div>
                    <div style="text-transform: uppercase" class="family">
                      PRINCIPAL CHIEF COMMISSIONER OF INCOME TAX,
                    </div>
                    <div class="family" style="text-transform: uppercase">
                      MUMBAI
                    </div>
                    <!-- <div>
                      तीसरी मंजिल, आयकर भवन, महर्षी कर्वे रोड, मुंबई -20
                    </div> -->
                    <div style="text-transform: uppercase" class="family">
                      3<sup style="text-transform: lowercase">rd</sup> Floor,
                      aayakar bhavan, Maharshi Karve Road, Mumbai - 400020
                    </div>
                    <div>
                      (022) - 22016691/22077187 / Fax: 022- 22079273/22077187
                    </div>
                    <div class="family">
                      Email:
                      <span *ngIf="data.SIGNATURE_ID == 2">
                        mumbai.ito.gpbs2@incometax.gov.in
                      </span>
                      <span *ngIf="data.SIGNATURE_ID == 3">
                        mumbai.ito.hq.pers@incometax.gov.in
                      </span>
                      <!-- <span *ngIf="userId != 18 && userId != 48">
                        mumbai.ito.hq.pers@incometax.gov.in</span
                      > -->
                    </div>
                  </div>
                </div>
              </div>

              <div
                style="
                  width: 100%;
                  padding: 5px;
                  font-size: 13px;
                  border-bottom: 1px solid;
                  border-left: 1px solid;
                  border-right: 1px solid;
                "
              >
                <div nz-row>
                  <div nz-col nzSpan="17">{{ data.PERMISSION_NO }}</div>
                  <div nz-col nzSpan="1"></div>
                  <div nz-col nzSpan="6">
                    दिनांक
                    :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                  <!-- {{ orderdata.ORDER_DATE | date : "dd MMM yyyy" }} -->
                </div>
              </div>
              <div style="font-family: Arial, Helvetica, sans-serif !important">
                <div
                  style="
                    text-align: center;
                    margin-top: 20px;
                    margin-bottom: 10px;
                    font-size: 13px;
                  "
                >
                  <b style="text-decoration: underline">O R D E R</b>
                </div>
                <div style="font-size: 14px !important">
                  <p style="margin-bottom: 2px; text-align: justify">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>{{ data.EMPLOYEE_NAME }}, {{ data.DESIGNATION }}</b
                    >. vide letter dt.<b>{{
                      data.LETTER_DATE | date : "dd/MM/yyyy"
                    }}</b>
                    submitted the claim for reimbursement of medical expenses
                    amounting to Rs. <b>{{ data.EXPENCE_AMOUNT }}</b
                    >/- incurred for Tests/Investigations at
                    <!-- {{ HospitalMapping.NAME }}
                      {{ HospitalMapping.ADDRESS }} -->
                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <span *ngIf="data.IS_VISIBLE_IN_ORDER == true">
                        {{ data.NAME }} ,{{ data.ADDRESS }} ,{{
                          data.TYPE == "E"
                            ? "[Empanelled]"
                            : data.TYPE == "NE"
                            ? "[Non Empanelled]"
                            : data.TYPE == "G"
                            ? "Government"
                            : ""
                        }}
                        <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                        <!-- <span *ngIf="HospitalMapping.length - 2 == k">and</span> -->
                      </span>
                    </span>
                    from
                    {{ data.TREATMENT_START_DATE }} to
                    {{ data.TREATMENT_END_DATE }} for
                    <span *ngIf="data.RELATION_WITH_PATIENT != 'Self'">
                      his/her {{ data.RELATION_WITH_PATIENT }}
                      {{ data.PATIENT_NAME }}</span
                    >

                    <!-- &nbsp; for&nbsp;{{ data.NATURE_OF_TREATMENT }} -->
                    <span *ngIf="data.RELATION_WITH_PATIENT == 'Self'">
                      of Self</span
                    >
                    The claim for reimbursement of medical expenses is delayed
                    by
                    <b>{{ diffdate }}</b>
                    <!-- <b>{{ dateBetweenDiff }}</b>  -->
                    days
                  </p>
                  <p style="margin-bottom: 2px; text-align: justify">
                    2.Considering the explanation given for delay in submission
                    of medical reimbursement claim and in exercise of the powers
                    vested in me vide Office Memorandum No.S.14025/8/99-MS dated
                    25/05/1999, the delay in submission of the claim for
                    reimbursement of medical expenses is hereby condoned.
                  </p>

                  <div style="margin-top: 100px; font-size: 13px">
                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div>(Vinay Sinha)</div>
                        <div style="font-weight: bold; font-size: 13px">
                          Commissioner of Income Tax (Admn. & TPS) Mumbai.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      style="
                        page-break-inside: avoid !important;
                        font-size: 13px;
                        page-break-before: avoid !important;
                      "
                    >
                      <div style="margin-top: 50px">
                        <b
                          >Copy to: {{ data.EMPLOYEE_NAME }},
                          {{ data.DESIGNATION }}
                        </b>
                      </div>
                    </div>
                    <div
                      style="
                        page-break-inside: avoid !important;
                        font-size: 13px;
                      "
                    >
                      <div style="margin-top: 100px">
                        <div nz-row>
                          <div nz-col nzSpan="12"></div>

                          <div nz-col nzSpan="12" style="text-align: center">
                            <!-- <span *ngIf="SECTION_TYPE == 'welfare'"> -->
                            <!-- <div style="font-weight: bold">
                                  (Suhas S Salaskar)
                                </div> -->
                            <!-- <div style="font-weight: bold">
                                  (Ritesh Kumar)
                                </div>
                                <div>Income Tax Officer(HQ)</div>
                                <div>Welfare & S.G, Mumbai</div>
                              </span>
                              <span *ngIf="SECTION_TYPE == 'gazpay'">
                                <div style="font-weight: bold">
                                  (Jitendra R Chandekar)
                                </div>
                                <div>Income Tax Officer</div>
                                <div>
                                  Gazetted Pay Bill Section II Personnel, Mumbai
                                </div>
                              </span> -->
                            <div style="font-weight: bold">
                              ({{ SIGNNAME }})
                            </div>
                            <div>{{ POST }}</div>
                            <div>{{ OFFICE_NAME }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>
  </form>

  <div class="footer">
    <button
      type="button"
      nz-button
      class="ant-btn"
      style="margin-right: 8px"
      (click)="close(delayCondolencePermissionPage)"
    >
      <span>Cancel</span>
    </button>

    <button
      type="button"
      class="ant-btn ant-btn-primary"
      nz-button
      (click)="Save()"
    >
      <span>Save & Preview</span>
    </button>
    <!-- <button nz-button nzType="primary"  (click)="pakagedata()">
              Save and Next
          </button> -->

    <!-- <button
        type="button"
        class="ant-btn ant-btn-primary"
        nz-button
        (click)="openPrintOrderModal()"
      >
        <span>Preview</span>
      </button> -->
  </div>
  <div class="header">
    <button
      type="button"
      nz-button
      (click)="close(delayCondolencePermissionPage)"
      class="ant-btn"
      style="margin-right: 8px"
    >
      <i nz-icon nzType="close" nzTheme="outline"></i>
    </button>
  </div>
</div>

<nz-modal
  [nzWidth]="getwidth()"
  [(nzVisible)]="printOrderModalVisible"
  nzMaskClosable="false"
  nzClosable="false"
  [nzContent]="printOrderModalContent"
  [nzTitle]="'Delay Permission Letter'"
  (nzOnCancel)="printOrderModalCancel()"
  [nzFooter]="printOrderModalFooter"
>
  <ng-template #printOrderModalContent>
    <nz-spin [nzSpinning]="isSpinning">
      <div style="height: 450px; overflow: auto">
        <div id="printOrderModal">
          <div style="width: 700px; padding: 3px; margin-left: 15px">
            <div>
              <div nz-row>
                <div
                  style="border: 1px solid"
                  nz-col
                  nzXs="4"
                  nzSm="4"
                  nzMd="4"
                  nzLg="4"
                  nzXl="4"
                >
                  <div style="text-align: center; padding: 36px 9px 9px 9px">
                    <img
                      src="assets/govtOfIndia.png"
                      alt=""
                      height="140px"
                      width="100%"
                    />
                  </div>
                </div>
                <!-- <div
                  style="
                    border-bottom: 1px solid;
                    border-top: 1px solid;
                    border-right: 1px solid;
                  "
                  nz-col
                  nzXs="1"
                  nzSm="1"
                  nzMd="1"
                  nzLg="1"
                  nzXl="1"
                ></div> -->
                <div
                  style="
                    border-bottom: 1px solid;
                    border-top: 1px solid;
                    border-right: 1px solid;
                  "
                  nz-col
                  nzXs="20"
                  nzSm="20"
                  nzMd="20"
                  nzLg="20"
                  nzXl="20"
                >
                  <div
                    style="width: 100%; text-align: center; font-weight: bold"
                  >
                    <div>भारत सरकार</div>
                    <div style="text-transform: uppercase" class="family">
                      Government Of India
                    </div>
                    <div>कार्यालय, प्रधान मुख्य आयकर आयुक्त, मुंबई</div>
                    <div style="text-transform: uppercase" class="family">
                      OFFICE OF THE
                    </div>
                    <div style="text-transform: uppercase" class="family">
                      PRINCIPAL CHIEF COMMISSIONER OF INCOME TAX,
                    </div>
                    <div class="family" style="text-transform: uppercase">
                      MUMBAI
                    </div>
                    <!-- <div>
                      तीसरी मंजिल, आयकर भवन, महर्षी कर्वे रोड, मुंबई -20
                    </div> -->
                    <div style="text-transform: uppercase" class="family">
                      3<sup style="text-transform: lowercase">rd</sup> Floor,
                      aayakar bhavan, Maharshi Karve Road, Mumbai - 400020
                    </div>
                    <div>
                      (022) - 22016691/22077187 / Fax: 022- 22079273/22077187
                    </div>
                    <div class="family">
                      Email:
                      <span *ngIf="data.SIGNATURE_ID == 2">
                        mumbai.ito.gpbs2@incometax.gov.in
                      </span>
                      <span *ngIf="data.SIGNATURE_ID == 3">
                        mumbai.ito.hq.pers@incometax.gov.in
                      </span>
                      <!-- <span *ngIf="userId != 18 && userId != 48">
                        mumbai.ito.hq.pers@incometax.gov.in</span
                      > -->
                    </div>
                  </div>
                </div>
              </div>

              <div
                style="
                  width: 100%;
                  padding: 5px;
                  font-size: 13px;
                  border-bottom: 1px solid;
                  border-left: 1px solid;
                  border-right: 1px solid;
                "
              >
                <div nz-row>
                  <div nz-col nzSpan="17">{{ data.PERMISSION_NO }}</div>
                  <div nz-col nzSpan="1"></div>
                  <div nz-col nzSpan="6">
                    दिनांक
                    :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                  <!-- {{ orderdata.ORDER_DATE | date : "dd MMM yyyy" }} -->
                </div>
              </div>
              <div style="font-family: Arial, Helvetica, sans-serif !important">
                <div
                  style="
                    text-align: center;
                    margin-top: 20px;
                    margin-bottom: 10px;
                    font-size: 13px;
                  "
                >
                  <b style="text-decoration: underline">O R D E R</b>
                </div>
                <div style="font-size: 14px !important">
                  <p style="margin-bottom: 2px; text-align: justify">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {{ data.EMPLOYEE_NAME }}, {{ data.DESIGNATION }}. vide
                    letter dt.<b>{{
                      data.LETTER_DATE | date : "dd/MM/yyyy"
                    }}</b>
                    submitted the claim for reimbursement of medical expenses
                    amounting to Rs. <b>{{ data.EXPENCE_AMOUNT }}</b
                    >/- incurred for Tests/Investigations at
                    <!-- {{ HospitalMapping.NAME }}
                      {{ HospitalMapping.ADDRESS }} -->
                    <span *ngFor="let data of HospitalMapping; let k = index">
                      <span *ngIf="data.IS_VISIBLE_IN_ORDER == true">
                        {{ data.NAME }} ,{{ data.ADDRESS }} ,{{
                          data.TYPE == "E"
                            ? "[Empanelled]"
                            : data.TYPE == "NE"
                            ? "[Non Empanelled]"
                            : data.TYPE == "G"
                            ? "Government"
                            : ""
                        }}
                        <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                        <!-- <span *ngIf="HospitalMapping.length - 2 == k">and</span> -->
                      </span>
                    </span>
                    from
                    {{ data.TREATMENT_START_DATE }}
                    to
                    {{ data.TREATMENT_END_DATE }}
                    for
                    <span *ngIf="data.RELATION_WITH_PATIENT != 'Self'">
                      for his/her {{ data.RELATION_WITH_PATIENT }}
                      {{ data.PATIENT_NAME }}</span
                    >

                    <!-- &nbsp; for&nbsp;{{ data.NATURE_OF_TREATMENT }} -->
                    <span *ngIf="data.RELATION_WITH_PATIENT == 'Self'">
                      of Self</span
                    >
                    The claim for reimbursement of medical expenses is delayed
                    by
                    <!-- <b>{{ dateBetweenDiff }}</b> -->
                    <b>{{ diffdate }}</b>
                    days
                  </p>
                  <p style="margin-bottom: 2px; text-align: justify">
                    2.Considering the explanation given for delay in submission
                    of medical reimbursement claim and in exercise of the powers
                    vested in me vide Office Memorandum No.S.14025/8/99-MS dated
                    25/05/1999, the delay in submission of the claim for
                    reimbursement of medical expenses is hereby condoned.
                  </p>

                  <div style="margin-top: 100px; font-size: 13px">
                    <div nz-row>
                      <div nz-col nzSpan="12"></div>

                      <div nz-col nzSpan="12" style="text-align: center">
                        <div>(Vinay Sinha)</div>
                        <div style="font-weight: bold; font-size: 13px">
                          Commissioner of Income Tax (Admn. & TPS) Mumbai.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      style="
                        page-break-inside: avoid !important;
                        font-size: 13px;
                        page-break-before: avoid !important;
                      "
                    >
                      <div style="margin-top: 50px">
                        <b
                          >Copy to: {{ data.EMPLOYEE_NAME }},
                          {{ data.DESIGNATION }},
                          <br />
                          {{ data.ADDRESS }}
                        </b>
                      </div>
                    </div>
                    <div
                      style="
                        page-break-inside: avoid !important;
                        font-size: 13px;
                      "
                    >
                      <div style="margin-top: 100px">
                        <div nz-row>
                          <div nz-col nzSpan="12"></div>

                          <div nz-col nzSpan="12" style="text-align: center">
                            <!-- <span *ngIf="SECTION_TYPE == 'welfare'"> -->
                            <!-- <div style="font-weight: bold">
                                  (Suhas S Salaskar)
                                </div> -->
                            <!-- <div style="font-weight: bold">
                                  (Ritesh Kumar)
                                </div>
                                <div>Income Tax Officer(HQ)</div>
                                <div>Welfare & S.G, Mumbai</div>
                              </span>
                              <span *ngIf="SECTION_TYPE == 'gazpay'">
                                <div style="font-weight: bold">
                                  (Jitendra R Chandekar)
                                </div>
                                <div>Income Tax Officer</div>
                                <div>
                                  Gazetted Pay Bill Section II Personnel, Mumbai
                                </div>
                              </span> -->
                            <div style="font-weight: bold">
                              ({{ SIGNNAME }})
                            </div>
                            <div>{{ POST }}</div>
                            <div>{{ OFFICE_NAME }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>
  </ng-template>

  <ng-template #printOrderModalFooter>
    <button nz-button nzType="default" (click)="printOrderModalCancel()">
      Close
    </button>

    <button
      nz-button
      nzType="default"
      [useExistingCss]="true"
      printSectionId="printOrderModal"
      ngxPrint
    >
      Print
    </button>

    <button
      nz-button
      nzType="primary"
      [nzLoading]="pdfDownload"
      (click)="generatePDF()"
    >
      Download
    </button>
  </ng-template>
</nz-modal>
