<div nz-row nzType="flex" style="margin-bottom: 5px">
    <div
      nz-col
      nzXs="24"
      nzSm="24"
      nzMd="12"
      nzLg="12"
      nzXl="12"
    >
      <div class="formname">{{ formTitle }}</div>
    </div>
  
    <div
      nz-col
      nzXs="24"
      nzSm="24"
      nzMd="12"
      nzLg="12"
      nzXl="12"
      style="display: flex; justify-content: flex-end"
    >
      <form nz-form [nzLayout]="'inline'">
        <nz-form-item>
          <button
            nz-button
            nzTooltipTitle="Filter"
            nzTooltipPlacement="top"
            nz-tooltip
            [nzType]="isFilterApplied"
            (click)="showFilter()"
          >
            <i nz-icon nzType="filter"></i>
          </button>
        </nz-form-item>
  
        <nz-form-item >
          <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
            <input
              type="text"
              autocomplete="off"
              name="search"
              nz-input
              (keydown.enter)="onKeypressEvent($event)"
              placeholder="Search Records"
              [(ngModel)]="searchText"
            />
          </nz-input-group>
  
          <ng-template #suffixIconButton>
            <button nz-button nzType="primary" (click)="search()" nzSearch>
              <i nz-icon nzType="search" id="button"></i>
            </button>
          </ng-template>
        </nz-form-item>
  
       
      </form>
    </div>
  </div>
  
  <div nz-row>
    <div nz-col nzSpan="24">
      <div class="filter {{ filterClass }}">
        <div class="filter-box" style="margin-bottom: 5px">
          <form nz-form>
            <div nz-row>
              <div nz-col nzSpan="5">
                <nz-form-item style="margin-top: 1px; width: 113%">
                  <nz-form-label nzNoColon>Select Date</nz-form-label>
                  <nz-range-picker
                    name="date"
                    nzFormat="dd MMM yyyy"
                    [(ngModel)]="BILL_FILIING_DATE"
                  >
                  </nz-range-picker>
                </nz-form-item>
              </div>
              <!-- [(ngModel)]="data" -->
              <div nz-col nzSpan="5" style="margin-left: 39px">
                <nz-form-item>
                  <nz-form-label nzNoColon>Hospital Type</nz-form-label>
                  <nz-select
                    nzShowSearch
                    nzAllowClear
                    name="hospitalType"
                    [(ngModel)]="TYPE_OF_HOSPITAL"
                    nzPlaceHolder="Select Hospital Type"
                  >
                    <nz-option nzLabel="Empanelled" nzValue="E"></nz-option>
                    <nz-option nzLabel="Non-Empanelled" nzValue="NE"></nz-option>
                    <nz-option nzLabel="Governemnt" nzValue="G"></nz-option>
                  </nz-select>
                </nz-form-item>
              </div>
              <!-- [(ngModel)]="STAGE_ID" -->
        
  
              <!-- </div>
                      <div nz-row nzGutter="8"> -->
  
              <div nz-col nzSpan="7">
                <nz-form-label nzNoColon></nz-form-label>
                <nz-form-item style="margin-left: 45px">
                  <button
                    style="margin-right: 10px"
                    nz-button
                    [nzLoading]="isSpinning"
                    nzType="primary"
                    (click)="applyFilter()"
                    nzJustify="end"
                  >
                    <i nz-icon nzType="filter"></i>Apply Filter
                  </button>
                  <button nz-button nzType="primary" (click)="clearFilter()">
                    <i nz-icon nzType="filter"></i>Clear filter
                  </button>
                </nz-form-item>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  
 
  <div nz-row>
    <div nz-col nzSpan="24">
      <nz-table
        class="my-scroll no-wrap1"
        #basicTable
        nzShowSizeChanger
        [nzData]="dataList"
        [(nzPageIndex)]="pageIndex"
        [nzFrontPagination]="false"
        [nzLoading]="loadingRecords"
        [nzTotal]="totalRecords"
        [(nzPageSize)]="pageSize"
        (nzPageIndexChange)="search()"
        (nzPageSizeChange)="search(true)"
        (nzQueryParams)="sort($event)"
        [nzScroll]="{ x: '6000px', y: '420px' }"
        nzBordered
      >
        <thead (nzSortChange)="sort($event)" nzSingleSort>
          <tr>
            <th nzWidth="100px">Action</th> 
            <th nzWidth="180px" nzColumnKey="CLAIM_STAGE_NAME" [nzSortFn]="true">
              Status
            </th>
            <th nzWidth="150px" nzColumnKey="CLAIM_NO" [nzSortFn]="true">
              Claim No
            </th>
            <th nzWidth="250px" nzColumnKey="EMPLOYEE_NAME" [nzSortFn]="true">
              Applicant Name
            </th>
            <th nzWidth="110px" nzColumnKey="MOBILE_NO" [nzSortFn]="true">
              Mobile No.
            </th>
            <th nzWidth="150px" nzColumnKey="EMPLOYEE_CODE" [nzSortFn]="true">
              Employee Code
            </th>
            <th
              nzWidth="270px"
              nzColumnKey="EMPLOYEE_DESIGNATION"
              [nzSortFn]="true"
            >
              Employee Designation
            </th>
            <th nzWidth="220px" nzColumnKey="PATIENT_NAME" [nzSortFn]="true">
              Patient Name
            </th>
            <th
              nzWidth="170px"
              nzColumnKey="RELATION_WITH_PATIENT"
              [nzSortFn]="true"
            >
              Patient Relation
            </th>
            <th nzWidth="150px" nzColumnKey="BILL_FILIING_DATE" [nzSortFn]="true">
              Bill Filling Date
            </th>
            <th nzWidth="190px" nzColumnKey="ADMISSIBLE_AMOUNT" [nzSortFn]="true">
              Admissible Amount(₹)
            </th>
  
            <th nzWidth="370px" nzColumnKey="TREATEMENT_TYPE" [nzSortFn]="true">
              Treatment Type
            </th>
            <th
              nzWidth="180px"
              nzColumnKey="TREATMENT_START_DATE"
              [nzSortFn]="true"
            >
              Treatment Start Date
            </th>
            <th
              nzWidth="180px"
              nzColumnKey="TREATMENT_END_DATE"
              [nzSortFn]="true"
            >
              Treatment End Date
            </th>
            <th nzWidth="260px" nzColumnKey="ACCREDITATION" [nzSortFn]="true">
              Empanelled Hospital Accrediation
            </th>
            <th nzWidth="210px" nzColumnKey="CLAIM_ACCREDITATION" [nzSortFn]="true">
              Accrediation
            </th>
  
            <th nzWidth="180px" nzColumnKey="HOSPITAL_TYPE" [nzSortFn]="true">
              Hospital Type
            </th>
            <th nzWidth="400px" nzColumnKey="HOSPITAL_NAME" [nzSortFn]="true">
              Hospital Name
            </th>
            <th
              nzWidth="400px"
              nzColumnKey="EMPANELLED_HOSPITAL_NAME"
              [nzSortFn]="true"
            >
              Empanelled Hospital Name
            </th>
            <!-- <th nzWidth="25px" nzColumnKey="EMPLOYEE_NAME" [nzSortFn]="true" >Advance Amount</th>
                      <th style="width: 40px" nzColumnKey="EMPLOYEE_NAME" [nzSortFn]="true" >Date Of Advance Taken</th> -->
            <th nzWidth="200px" nzColumnKey="WARD_TYPE" [nzSortFn]="true">
              Ward Entitlement
            </th>
  
            <th
              nzWidth="400px"
              nzColumnKey="NATURE_OF_TREATMENT"
              [nzSortFn]="true"
            >
              Nature Of Treatment
            </th>
            <th nzWidth="150px" nzColumnKey="CHECKLIST_STATUS" [nzSortFn]="true">
              Accept / Reject
            </th>
            <th nzWidth="300px" nzColumnKey="REJECT_REMARK" [nzSortFn]="true">
              Remark
            </th>
          </tr>
        </thead>
  
        <tbody>
          <tr *ngFor="let data of basicTable.data; let i = index">
            <!-- <td align="center">
                          <a (click)="edit()"><i nz-icon nzType="edit"></i></a>
                      </td> -->
  
            <td nzWidth="100px">
              <div style="padding: 3px">
                <span *ngIf="data.CURRENT_STAGE_ID <= 3" style="cursor: pointer" (click)="pickClaim(data)">
                  <nz-tag
                    style="
                      padding: 3px;
                      border: 1px blue solid;
                      border-radius: 0px;
                    "
                    [nzColor]="'blue'"
                  >
                     Pick Claim
                  </nz-tag>
                </span >
      
              
   
  
                
              </div>
            </td>
      
            
            <td style="text-align: center; white-space: pre-line" nzWidth="180px">
              {{ data.CLAIM_STAGE_NAME }}
            </td>
            <td style="text-align: center; white-space: pre-line" nzWidth="150px">
              {{ data.CLAIM_NO }}
            </td>
            <td style="text-align: center; white-space: pre-line" nzWidth="250px">
              {{ data.EMPLOYEE_NAME }}
            </td>
            <td style="text-align: center; white-space: pre-line" nzWidth="110px">
              {{ data.MOBILE_NO }}
            </td>
            <td style="text-align: center; white-space: pre-line" nzWidth="150px">
              {{ data.EMPLOYEE_CODE }}
            </td>
            <td style="text-align: center; white-space: pre-line" nzWidth="270px">
              {{ data.EMPLOYEE_DESIGNATION }}
            </td>
            <td style="text-align: center; white-space: pre-line" nzWidth="220px">
              {{ data.PATIENT_NAME }}
            </td>
            <td style="text-align: center; white-space: pre-line" nzWidth="170px">
              {{ data.RELATION_WITH_PATIENT }}
            </td>
            <td style="text-align: center; white-space: pre-line" nzWidth="150px">
              {{ data.BILL_FILIING_DATE }}
            </td>
            <td style="text-align: center; white-space: pre-line" nzWidth="190px">
              {{ data.ADMISSIBLE_AMOUNT }}
            </td>
  
            <!-- <td style="text-align: center;white-space: pre-line;" >{{ data.TREATEMENT_TYPE }}</td> -->
            <td style="text-align: center" nzWidth="370px">
              <span nz-icon *ngIf="data['TREATEMENT_TYPE'] == 'OT'"
                >OPD Treatment / Test Entitled</span
              >
              <span nz-icon *ngIf="data['TREATEMENT_TYPE'] == 'IT'"
                >Indoor Treatment</span
              >
              <span nz-icon *ngIf="data['TREATEMENT_TYPE'] == 'OT,IT'"
                >OPD Treatment / Test Entitled & Indoor Treatment</span
              >
            </td>
            <td style="text-align: center; white-space: pre-line" nzWidth="180px">
              {{ data.TREATMENT_START_DATE }}
            </td>
            <td style="text-align: center; white-space: pre-line" nzWidth="180px">
              {{ data.TREATMENT_END_DATE }}
            </td>
  
            <td style="text-align: center" nzWidth="260px">
              <span nz-icon *ngIf="data['ACCREDITATION'] == 'N'">NAHB</span>
              <span nz-icon *ngIf="data['ACCREDITATION'] == 'NN'">Non NAHB</span>
              <span nz-icon *ngIf="data['ACCREDITATION'] == 'SP'"
                >Super Speciality</span
              >
            </td>
            <td style="text-align: center" nzWidth="180px">
              <span nz-icon *ngIf="data['CLAIM_ACCREDITATION'] == 'N'">NAHB</span>
              <span nz-icon *ngIf="data['CLAIM_ACCREDITATION'] == 'NN'">Non NAHB</span>
              <span nz-icon *ngIf="data['CLAIM_ACCREDITATION'] == 'SP'"
                >Super Speciality</span
              >
            </td>
            <!-- <td style="text-align: center;white-space: pre-line;" >{{ data.HOSPITAL_TYPE }}</td> -->
            <td style="text-align: center" nzWidth="180px">
              <span nz-icon *ngIf="data['HOSPITAL_TYPE'] == 'E'">Empanelled</span>
              <span nz-icon *ngIf="data['HOSPITAL_TYPE'] == 'NE'"
                >Non Empanelled</span
              >
              <span nz-icon *ngIf="data['HOSPITAL_TYPE'] == 'G'">Government</span>
            </td>
            <td style="text-align: center; white-space: pre-line" nzWidth="400px">
              {{ data.HOSPITAL_NAME }}
            </td>
            <td style="text-align: center; white-space: pre-line" nzWidth="400px">
              {{ data.EMPANELLED_HOSPITAL_NAME }}
            </td>
            <!-- <td style="text-align: center;white-space: pre-line;" >{{ data.ADVANCE_AMOUNT }}</td>
                      <td style="text-align: center;white-space: pre-line;" >{{ data.DATE_OF_ADVANCE_TAKEN }}</td> -->
  
            <td style="text-align: center" nzWidth="200px">
              <span nz-icon *ngIf="data['WARD_TYPE'] == 'P'">Pvt </span>
              <span nz-icon *ngIf="data['WARD_TYPE'] == 'S'">Semi Pvt</span>
              <span nz-icon *ngIf="data['WARD_TYPE'] == 'G'">General</span>
            </td>
            <td style="text-align: center; white-space: pre-line" nzWidth="400px">
              {{ data.NATURE_OF_TREATMENT }}
            </td>
            <td style="text-align: center" nzWidth="150px">
              <span nz-icon *ngIf="data['CHECKLIST_STATUS'] == 'A'">
                Claim Accept
              </span>
              <span nz-icon *ngIf="data['CHECKLIST_STATUS'] == 'R'"
                >Claim Reject</span
              >
            </td>
            <td style="text-align: center; white-space: pre-line" nzWidth="300px">
              {{ data.REJECT_REMARK }}
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
  
 