export class Journeydetails {
  FROM_DATETIME: any;
  TO_DATETIME: any;
  EMP_ID: any;
  DEPARTURE_FROM: any;
  ARRIVAL_TO: any;
  TRAVEL_MODE_ID: any;
  TRAVEL_CLASS_ID: any;
  NO_OF_FAIRS: any;
  FAIR_PAID: any;
  DISTANCE_BY_ROAD: any;
  TRANSFER_ID: any;
  ID: any;
  LTC_ID: any;
  REMARK: any;
  AIR_TICKET: any;
  BOAT_SHIP_TICKET: any;
  ROAD_TICKET: any;
  IRCTC_TICKET: any;
  BOARDING_PASS: any;
  TICKET_URL: any;
  OTHER_CHARGES: any;

  CUTE_RCS_FEES_CLAIMED: any = 0;
  USER_DEVELOPEMENT_FEES_CLAIMED: any = 0;
  DEVELOPEMENT_FEES_CLAIMED: any = 0;
  AVIATION_SECURITY_FEES_CLAIMED: any = 0;
  GST_CLAIMED: any = 0;
  AIRPORT_SECURITY_FEES_CLAIMED: any = 0;
  REGIONAL_CONNECTIVITY_FEES_CLAIMED: any = 0;
  PASSENGER_SERVICES_FEES_CLAIMED: any = 0;
  OTHERS_CLAIMED: any = 0;
  CUTE_RCS_FEES_ALLOWED: any = 0;
  USER_DEVELOPEMENT_FEES_ALLOWED: any = 0;
  DEVELOPEMENT_FEES_ALLOWED: any = 0;
  AVIATION_SECURITY_FEES_ALLOWED: any = 0;
  GST_ALLOWED: any = 0;
  AIRPORT_SECURITY_FEES_ALLOWED: any = 0;
  REGIONAL_CONNECTIVITY_FEES_ALLOWED: any = 0;
  PASSENGER_SERVICES_FEES_ALLOWED: any = 0;
  OTHERS_ALLOWED: any = 0;
  ALLOWED_TOTAL: any = 0;
  BASE_FARE: any = 0;
  IS_DELETED: any;
  JOURNEY_CLAIMED_AMOUNT = 0;
  TRANSFER_JOURNEY_CLAIMED_AMOUNT = 0;
}
