export class SignatureMaster {
  ID: number = 0;
  USER_ID: string = '';
  POST: string = '';
  OFFICE_NAME: string = '';
  SECTION_TYPE: string = '';
  NAME_HN: string = '';
  POST_HN: string = '';
  OFFICE_NAME_HN: string = '';
  NAME: string = '';
  STATUS: boolean = true;
}
