export class LTCQuestionaryMaster {
  ID: number;
  LTC_ID: any;
  IS_ORIGINAL_BILL_SUBMITTED: boolean = false;
  WHETHER_BLOCK_YEAR_MENTIONED: boolean = false;
  IS_BILL_SUBMITTED_PROPER_TIME: boolean = false;
  IS_PASS_OR_TICKET_SUBMITTED: boolean = false;
  IS_PRINTOUT_SUBMITTED_FOR_CONCERNED_TIME_SLOT: any = '';
  IS_BANK_MANDATE_FORM_SUBMITTED: boolean = false;
  IS_COPY_OF_INTIMATION_OR_LEAVE_APPLICATION_GIVEN: boolean = false;
  IS_COPY_OF_LEAVE_LEAVE_SANCTIONED_ORDER_GIVEN: any = '';
  IS_LATEST_PAYMENT_SLIP_FOR_PAY_LEVEL: boolean = false;
  IS_DEPENDENCY_CERTIFICATE_GIVEN: any = '';
  IS_ADVANCE_FOR_LTC_GRANTED: boolean = false;
  WHETHER_HOME_TOWN: boolean = false;
  WHETHER_ALL_OVER_INDIA: boolean = false;
  WHETHER_CONVERTED_HOME_TO_NER_AN_JK_L: boolean = false;
}
