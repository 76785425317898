import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { InvestigationMaster } from 'src/app/Models/InvestigationMaster';
import * as html2pdf from 'html2pdf.js';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from 'src/app/Service/api.service';
@Component({
  selector: 'app-advance-annexure',
  templateUrl: './advance-annexure.component.html',
  styleUrls: ['./advance-annexure.component.css'],
})
export class AdvanceAnnexureComponent implements OnInit {
  constructor(
    private datePipe: DatePipe,
    private message: NzNotificationService,
    private api: ApiService
  ) {}
  additionalChange = 0;
  countt: any;
  @Input() total: any = 0;
  @Input() total1: any = 0;
  @Input() citylist = [];
  @Input() CLAIM_ID: any;
  @Input() drawerClose: Function;
  @Input() data: InvestigationMaster;
  @Input() claimData: any;
  @Input() hospitallist = [];
  Cities: InvestigationMaster[];
  isSpinning = false;
  Names = InvestigationMaster;
  isOk: boolean = false;
  isVisible: boolean = false;
  TYPE: string = 'I';
  PACKAGE_RATE: number = 0;
  procedureList2 = [];
  @Input() showlayoutDataList: [];
  city = '';
  currentDate = new Date('27-10-2022');
  CITY_ID = 0;
  procedureList = [];
  fileName: string = "Annexure 'A'";
  pdfDownload: boolean = false;
  packages = [];
  userName = sessionStorage.getItem('userName');

  @Input() advanceAdmissible: any;

  ngOnInit(): void {}

  setType(event: any) {
    this.data.IS_PACKAGE = event;
    if (this.data.IS_PACKAGE == true) {
      this.data.TYPE = 'P';
      this.setAmount();
    } else {
      this.changePerc(0);
      this.data.TYPE = 'I';
    }
  }

  setDiscount(event: any) {
    this.data.IS_DISCOUNT_APPLIED = event;
    this.data.CLAIMED_AMOUNT = 0;
    this.data.DESCRIPTION = '';
    this.data.ADMISSIBLE_AMOUNT = 0;
  }

  setAmount() {
    var FINALE_AMOUNT = this.data.RATE * this.data.QTY;

    if (
      this.data.SHORT_CODE != null &&
      this.data.SHORT_CODE.includes('2002') &&
      this.city.includes('mumbai')
    ) {
      this.data.FINALE_AMOUNT = FINALE_AMOUNT + (25 / 100) * FINALE_AMOUNT;
      this.additionalChange = 25; // if (this.currentDate <= new Date(this.claimData.BILL_FILIING_DATE)) {
      if (this.claimData.WARD_TYPE == 'G') {
        this.data.ADMISSIBLE_AMOUNT =
          this.data.FINALE_AMOUNT - (10 / 100) * this.data.FINALE_AMOUNT;
        this.data.CHANGE = -10;
      } else if (this.claimData.WARD_TYPE == 'P') {
        this.data.ADMISSIBLE_AMOUNT =
          this.data.FINALE_AMOUNT + (15 / 100) * this.data.FINALE_AMOUNT;
        this.data.CHANGE = 15;
      } else {
        this.data.ADMISSIBLE_AMOUNT = this.data.FINALE_AMOUNT;
        this.data.CHANGE = 0;
      } // }
    } else {
      this.additionalChange = 0;
      this.data.FINALE_AMOUNT = FINALE_AMOUNT;
      if (this.claimData.WARD_TYPE == 'G') {
        this.data.ADMISSIBLE_AMOUNT =
          this.data.FINALE_AMOUNT - (10 / 100) * this.data.FINALE_AMOUNT;
        this.data.CHANGE = -10;
      } else if (this.claimData.WARD_TYPE == 'P') {
        this.data.ADMISSIBLE_AMOUNT =
          this.data.FINALE_AMOUNT + (15 / 100) * this.data.FINALE_AMOUNT;
        this.data.CHANGE = 15;
      } else {
        this.data.ADMISSIBLE_AMOUNT = this.data.FINALE_AMOUNT;
        this.data.CHANGE = 0;
      }
    }
  }
  HOSPITAL_TYPE = '';
  @Input() ACCREDITATION = '';
  setHospital(event: any) {
    this.data.CLAIM_HOSPITAL_MAPPING_ID = event;
    var h = this.hospitallist.filter((obj) => {
      return obj.ID == event;
    });
    this.HOSPITAL_TYPE = h[0]['TYPE'];
    this.ACCREDITATION = h[0]['ACCREDATION'];

    if (
      this.data.INVESTIGATION_PROCEDURE_ID != undefined &&
      this.data.INVESTIGATION_PROCEDURE_ID != null
    ) {
      this.peocedureRate(this.data.INVESTIGATION_PROCEDURE_ID);
    }
  }

  setCity(event: any) {
    this.CITY_ID = event;
    this.procedureList2 = [];
  }

  changeRateData(event: any) {
    this.data.RATE = event;
    if (this.data.IS_PACKAGE == true) {
      this.setAmount();
    } else {
      this.changePerc(0);
    }
  }

  changeQtyData(event: any) {
    this.data.QTY = event;
    if (this.data.IS_PACKAGE == true) {
      this.setAmount();
    } else {
      this.changePerc(0);
    }
  }

  cityID;
  hospitalID = 0;
  pakagedata(investigationMaster: NgForm) {
    this.isOk = true;

    if (
      this.data.IS_DISCOUNT_APPLIED != undefined &&
      this.data.IS_DISCOUNT_APPLIED == true
    ) {
      if (this.data.DESCRIPTION == undefined || this.data.DESCRIPTION == '') {
        this.isOk = false;
        this.message.error('Please Enter Description', '');
      }
      if (this.data.CLAIMED_AMOUNT == undefined) {
        this.isOk = false;
        this.message.error('Please Enter Claimed Amount', '');
      }
      // this.data.ADMISSIBLE_AMOUNT = 0;
    } else {
      if (
        this.data.CLAIM_HOSPITAL_MAPPING_ID == undefined ||
        this.data.CLAIM_HOSPITAL_MAPPING_ID == null ||
        this.data.CLAIM_HOSPITAL_MAPPING_ID == 0
      ) {
        this.isOk = false;
        this.message.error('Please Select Hospital', '');
      } else if (
        this.data.PARTICULARS == undefined ||
        this.data.PARTICULARS == null
      ) {
        this.isOk = false;
        this.message.error('Please Enter Bill Item Name/ Particulars', '');
      } else if (
        this.data.INVESTIGATION_PROCEDURE_ID == undefined ||
        this.data.INVESTIGATION_PROCEDURE_ID < 0
      ) {
        this.isOk = false;
        this.message.error('Please Select Investigation/ Procedure', '');
      } else if (
        this.data.CLAIMED_AMOUNT == undefined ||
        this.data.CLAIMED_AMOUNT <= 0
      ) {
        this.isOk = false;
        this.message.error('Please Enter Claimed Amount', '');
      } else if (
        this.data.ADMISSIBLE_AMOUNT == undefined ||
        this.data.ADMISSIBLE_AMOUNT < 0
      ) {
        this.isOk = false;
        this.message.error('Please Enter Rate', '');
      }
    }

    if (this.isOk == true) {
      if (this.data.CODE_NO == undefined || this.data.CODE_NO == null) {
        this.data.CODE_NO = '';
      }
      if (this.data.SHORT_CODE == undefined && this.data.SHORT_CODE == null) {
        this.data.SHORT_CODE = '';
      }
      if (
        this.data.INVESTIGATION_PROCEDURE_ID == undefined ||
        this.data.INVESTIGATION_PROCEDURE_ID == null
      ) {
        this.data.INVESTIGATION_PROCEDURE_ID = 0;
      }
      if (this.data.PARTICULARS == undefined || this.data.PARTICULARS == null) {
        this.data.PARTICULARS = '';
      }
      if (
        this.data.FINALE_AMOUNT == undefined ||
        this.data.FINALE_AMOUNT == null
      ) {
        this.data.FINALE_AMOUNT = 0;
      }
      if (this.data.RATE == undefined || this.data.RATE == null) {
        this.data.RATE = 0;
      }

      if (this.data.QTY == undefined || this.data.QTY == null) {
        this.data.QTY = 0;
      }

      if (
        this.data.IS_DISCOUNT_APPLIED == false &&
        this.data.CLAIMED_AMOUNT < this.data.ADMISSIBLE_AMOUNT
      ) {
        this.data.ADMISSIBLE_AMOUNT = this.data.CLAIMED_AMOUNT;
      } else {
        // if (this.data.IS_DISCOUNT_APPLIED == true) {
        //   this.data.ADMISSIBLE_AMOUNT = 0;
      }

      this.advanceAdmissible = 0;

      if (this.data.ID) {
        this.isSpinning = true;
        this.cityID = this.CITY_ID;
        this.hospitalID = this.data.CLAIM_HOSPITAL_MAPPING_ID;
        this.data.IS_ADVANCE = 1;
        this.api.updateannextureadd(this.data).subscribe((successCode) => {
          if (successCode.code == '200') {
            this.message.success('Information Updated Successfully', '');
            this.isSpinning = false;
            this.api
              .getannexture(
                0,
                0,
                'ID',
                'ASC',
                ' AND STATUS = 1 AND IS_ADVANCE = 1 AND CLAIM_ID=' +
                  this.claimData.ID
              )
              .subscribe(
                (data) => {
                  this.countt = data['count'];
                  this.showlayoutDataList = data['data'];
                  this.total = 0;
                  this.total1 = 0;
                  this.advanceAdmissible = 0;
                  for (var i = 0; this.countt > i; i++) {
                    this.total =
                      this.total + this.showlayoutDataList[i]['CLAIMED_AMOUNT'];
                    this.total1 =
                      this.total1 +
                      this.showlayoutDataList[i]['ADMISSIBLE_AMOUNT'];
                    // if (
                    //   this.claimData.IS_ADVANCE_TAKEN == 1 &&
                    //   (this.showlayoutDataList[i][
                    //     'REMAINING_ADMISSIBLE_AMOUNT'
                    //   ] != undefined ||
                    //     this.showlayoutDataList[i][
                    //       'REMAINING_ADMISSIBLE_AMOUNT'
                    //     ] != null)
                    // ) {
                    //   this.advanceAdmissible +
                    //     this.showlayoutDataList[i]['REMAINING_ADMISSIBLE_AMOUNT'];
                    // } else {
                    // }
                  }
                  this.claimData.FINAL_ADMISSIBLE_AMOUNT = Math.round(
                    this.total1
                  );
                  this.claimData.FINAL_CLAIMED_AMOUNT = this.total;
                  if (this.claimData.IS_ADVANCE_TAKEN == 1) {
                    this.advanceAdmissible = Math.round(this.total1);
                    // this.advanceAdmissible = Math.round(
                    //   (this.total1 * 90) / 100
                    // );
                  } else {
                    this.advanceAdmissible = 0;
                  }
                  // this.claimData.REMAINING_ADMISSIBLE_AMOUNT = Math.round(
                  //   this.advanceAdmissible
                  // );
                  this.total1 = Math.round(this.total1);

                  this.api
                    .updateClaimedAnnexure(this.claimData)
                    .subscribe((successCode) => {
                      if (successCode.code == '200') {
                        this.claimData.FINAL_ADMISSIBLE_AMOUNT = Math.round(
                          this.advanceAdmissible
                        );
                        this.claimData.FINAL_REMAINING_AMOUNT = Math.round(
                          Number(this.total1) -
                            Number(this.claimData.ADVANCE_AMOUNT)
                        );
                        this.api
                          .updateClaimWithoutHospitalData(this.claimData)
                          .subscribe((successCode) => {
                            if (successCode.code == '200') {
                              this.message.success(
                                'Information updated Successfully',
                                ''
                              );
                              this.isSpinning = false;
                            } else {
                              this.message.error(
                                'Information Has Not Changed...',
                                ''
                              );
                              this.isSpinning = false;
                            }
                          });
                        this.isSpinning = false;
                      } else {
                        this.isSpinning = false;
                      }
                    });
                  this.isSpinning = false;
                },
                (err) => {
                  console.log(err);
                  this.isSpinning = false;
                }
              );
            this.resetDrawer(investigationMaster);
            // investigationMaster.form.reset();
            // this.drawerClose();

            this.isSpinning = false;
          } else {
            this.message.error('Information Not Saved', '');
            this.isSpinning = false;
          }
        });
      } else {
        this.isSpinning = true;
        this.data.CLAIM_ID = this.claimData.ID;
        this.data.IS_ADVANCE = 1;
        this.api.createannexture(this.data).subscribe((successCode) => {
          if (successCode.code == '200') {
            this.message.success('Information Save Successfully', '');
            this.isSpinning = false;
            this.api
              .getannexture(
                0,
                0,
                'ID',
                'ASC',
                ' AND STATUS = 1 AND IS_ADVANCE = 1 AND CLAIM_ID=' +
                  this.claimData.ID
              )
              .subscribe(
                (data) => {
                  this.countt = data['count'];
                  this.showlayoutDataList = data['data'];
                  this.total = 0;
                  this.total1 = 0;
                  for (var i = 0; this.countt > i; i++) {
                    this.total =
                      this.total + this.showlayoutDataList[i]['CLAIMED_AMOUNT'];
                    this.total1 =
                      this.total1 +
                      this.showlayoutDataList[i]['ADMISSIBLE_AMOUNT'];
                  }
                  this.total1 = Math.round(this.total1);
                  this.total = Math.round(this.total);
                  this.claimData.FINAL_ADMISSIBLE_AMOUNT = this.total1;
                  this.claimData.FINAL_CLAIMED_AMOUNT = this.total;
                  if (this.claimData.IS_ADVANCE_TAKEN == 1) {
                    this.advanceAdmissible = this.total1;
                  } else {
                    this.advanceAdmissible = 0;
                  }
                  // this.claimData.REMAINING_ADMISSIBLE_AMOUNT = Math.round(
                  //   this.advanceAdmissible
                  // );
                  this.api
                    .updateClaimedAnnexure(this.claimData)
                    .subscribe((successCode) => {
                      if (successCode.code == '200') {
                        this.claimData.FINAL_ADMISSIBLE_AMOUNT = Math.round(
                          this.advanceAdmissible
                        );
                        this.claimData.FINAL_REMAINING_AMOUNT = Math.round(
                          Number(this.total1) -
                            Number(this.claimData.ADVANCE_AMOUNT)
                        );
                        this.api
                          .updateClaimWithoutHospitalData(this.claimData)
                          .subscribe((successCode) => {
                            if (successCode.code == '200') {
                              this.message.success(
                                'Information updated Successfully',
                                ''
                              );
                              this.isSpinning = false;
                            } else {
                              this.message.error(
                                'Information Has Not Changed...',
                                ''
                              );
                              this.isSpinning = false;
                            }
                          });
                        this.isSpinning = false;
                      } else {
                        this.isSpinning = false;
                      }
                    });
                  this.isSpinning = false;
                },
                (err) => {
                  console.log(err);
                  this.isSpinning = false;
                }
              );
            this.resetDrawer(investigationMaster);
            // investigationMaster.form.reset();
            // this.drawerClose();

            this.isSpinning = false;
          } else {
            this.message.error('Information Not Saved', '');
            this.isSpinning = false;
          }
        });
      }
      // }
    }
  }

  bulkddata(data: any) {
    this.api.addannexturebulk(this.data).subscribe((successCode) => {
      if (successCode.code == '200') {
        this.message.success('Information Save Successfully', '');

        // this.showModal(showlayoutDataList)

        this.isSpinning = false;
      } else {
        this.message.error('Information Not Saved', '');
        this.isSpinning = false;
      }
    });
  }

  omit(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 46) {
      return true;
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  omit2(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (
      this.data.IS_DISCOUNT_APPLIED == true &&
      (charCode == 45 || charCode == 109)
    ) {
      return true;
    } else if (charCode == 46) {
      return true;
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  changedata(event: any) {
    if (this.data.INVESTIGATION_PROCEDURE_ID == 0) {
      this.data.ADMISSIBLE_AMOUNT = event;
    }
  }

  changePerc(event: number) {
    this.data.CHANGE = event;
    var FINALE_AMOUNT = 0;
    if (this.data.RATE != undefined && this.data.RATE != null) {
      if (this.data.QTY != undefined && this.data.QTY != null)
        FINALE_AMOUNT = this.data.RATE * this.data.QTY;
      else FINALE_AMOUNT = this.data.RATE;
      if (
        this.data.SHORT_CODE != null &&
        this.data.SHORT_CODE.includes('2002') &&
        this.city.includes('mumbai')
      ) {
        this.data.FINALE_AMOUNT = FINALE_AMOUNT + (25 / 100) * FINALE_AMOUNT;
        this.additionalChange = 25;
        this.data.ADMISSIBLE_AMOUNT =
          this.data.FINALE_AMOUNT +
          (this.data.CHANGE / 100) * this.data.FINALE_AMOUNT;
      } else {
        this.data.FINALE_AMOUNT = FINALE_AMOUNT;
        this.additionalChange = 0;
        this.data.ADMISSIBLE_AMOUNT =
          this.data.FINALE_AMOUNT +
          (this.data.CHANGE / 100) * this.data.FINALE_AMOUNT;
      }
    }
  }

  changeSheduleNo(event: any) {
    this.data.SHORT_CODE = event;

    if (this.data.IS_PACKAGE == true) {
      this.setAmount();
    } else {
      this.changePerc(0);
    }
  }

  peocedureRate2(event: any) {
    this.procedureList2 = [];
    var f = '';
    if (this.CITY_ID > 0) {
      f = ' AND CITY_ID =' + this.CITY_ID;
    }
    if (event != null && event != '' && event.toString().length >= 3) {
      this.api
        .getinvestigationprocedure(
          0,
          0,
          'NAME',
          'asc',
          " and NAME like '%" + event + "%'" + f
        )
        .subscribe(
          (data) => {
            if (data['code'] == 200 && data['data'].length > 0)
              this.procedureList2 = data['data'];
            else this.procedureList2 = [];
          },
          (err) => {
            console.log(err);
            this.isSpinning = false;
          }
        );
    }
  }

  peocedureRate(event: any) {
    this.data.INVESTIGATION_PROCEDURE_ID = event;
    if (
      (this.data.CLAIM_HOSPITAL_MAPPING_ID == undefined ||
        this.data.CLAIM_HOSPITAL_MAPPING_ID == null ||
        this.data.CLAIM_HOSPITAL_MAPPING_ID == 0) &&
      this.data.INVESTIGATION_PROCEDURE_ID != undefined &&
      this.data.INVESTIGATION_PROCEDURE_ID != null
    ) {
      this.isOk = false;
      this.message.error('Please select hospital', '');
    } else {
      if (event > 0) {
        var a = this.procedureList2.filter((obj) => {
          return obj.ID == event;
        });
        if (this.ACCREDITATION == 'NN')
          this.data.RATE = a[0]['NON_NABH_AMOUNT'];
        else if (this.ACCREDITATION == 'N')
          this.data.RATE = a[0]['NABH_AMOUNT'];
        else this.data.RATE = a[0]['SUPER_SPECIALITY_AMOUNT'];

        this.city = a[0]['CITY_NAME'].toLowerCase();
        this.data.QTY = 1;
        this.data.FINALE_AMOUNT = this.data.RATE;
        this.data.SHORT_CODE = a[0]['SHORT_CODE'];
        this.data.CODE_NO = a[0]['SCHEDULE_NO'];
        this.data.PARTICULARS = a[0]['NAME'];

        if (this.data.IS_PACKAGE == true) {
          this.data.TYPE = 'P';
          this.setAmount();
        } else {
          this.changePerc(0);
          this.data.TYPE = 'I';
        }
      } else {
        this.data.QTY = 1;
        this.data.FINALE_AMOUNT = 0;
        this.data.SHORT_CODE = '';
        this.data.CODE_NO = 'NIS';
        this.data.CHANGE = 0;
        this.data.ADMISSIBLE_AMOUNT = 0;
        this.data.RATE = 0;
        this.data.IS_PACKAGE = false;
        this.city = '';
        this.additionalChange = 0;
      }
    }
  }

  getPackageRate(event: any) {
    if (event > 0) {
      var a = this.packages.filter((obj) => {
        return obj.ID == event;
      });

      this.PACKAGE_RATE = a[0]['AMT'];
    } else {
      this.PACKAGE_RATE = 0;
    }
  }

  REFRED_RATE_LIST = '';
  showModal(data: any): void {
    // this.api.addannexturebulk(data).subscribe((successCode) => {
    //   if (successCode.code == '200') {
    //     this.message.success('Information Save Successfully', '');
    this.REFRED_RATE_LIST = '';
    var arr = [];
    for (var i = 0; i < this.showlayoutDataList.length; i++) {
      arr.push(this.showlayoutDataList[i]['SHORT_CODE']);
      if (i + 1 == this.showlayoutDataList.length) {
        var unique = arr.filter(
          (value, index, array) => array.indexOf(value) === index
        );
        this.REFRED_RATE_LIST = unique.toString();
      }
    }
    this.api
      .getclaimMaster(
        0,
        0,
        '',
        '',
        ' AND ID = ' + this.claimData.ID,
        '',
        '',
        '',
        ''
      )
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            this.claimData = data['data'][0];
            this.isSpinning = false;
            this.isVisible = true;
          } else {
            this.message.error("Can't Load Data", '');
            this.isSpinning = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );

    //     // this.drawerClose();

    //     this.isSpinning = false;
    //   } else {
    //     this.message.error('Information Not Saved', '');
    //     this.isSpinning = false;
    //   }
    // });
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  edit(data: any): void {
    this.data = Object.assign({}, data);
    this.api
      .getinvestigationprocedure(
        0,
        0,
        'ID',
        'asc',
        ' AND ID =' + data.INVESTIGATION_PROCEDURE_ID
      )
      .subscribe(
        (data1) => {
          if (data1['code'] == 200 && data1['data'].length > 0)
            this.procedureList2 = data1['data'];
          else this.procedureList2 = [];
          this.peocedureRate(data.INVESTIGATION_PROCEDURE_ID);
        },
        (err) => {
          console.log(err);
          this.isSpinning = false;
        }
      );
  }

  resetDrawer(bundleMasterPage: NgForm) {
    var CITY_ID = this.CITY_ID;
    var SEQ_NO = this.data.SEQ_NO;
    var HOSP_ID = this.data.CLAIM_HOSPITAL_MAPPING_ID;
    this.data = new InvestigationMaster();
    bundleMasterPage.form.markAsPristine();
    bundleMasterPage.form.markAsUntouched();
    this.CITY_ID = CITY_ID;
    this.data.CLAIM_HOSPITAL_MAPPING_ID = HOSP_ID;
    this.data.SEQ_NO = SEQ_NO;

    this.additionalChange = 0;
  }

  close(investigationMaster: NgForm) {
    this.drawerClose();
    this.resetDrawer(investigationMaster);
    investigationMaster.form.reset();
  }

  public generatePDF() {
    var i = 0;
    var date = new Date();
    var datef = this.datePipe.transform(date, 'dd-MM-yyyy');
    var dates = this.datePipe.transform(date, 'hh-mm-ss a');
    var data = document.getElementById('printAnnexureModal');

    html2pdf()
      .from(data)
      .set({
        image: { type: 'jpeg', quality: 1.0 },
        html2canvas: { scale: 3, useCORS: true },
        margin: [2, 10, 2, 5],
        pagebreak: { mode: ['css', 'legecy'] },
        jsPDF: { unit: 'mm', format: 'A4', orientation: 'portrait' },
      })
      .toPdf()
      .get('pdf')
      .then(function (pdf) {
        this.pdfDownload = true;
        var totalPages = pdf.internal.getNumberOfPages();

        for (i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(12);
          pdf.setTextColor(150);
          pdf.text(i.toString(), pdf.internal.pageSize.getWidth() / 2, 10);
        }

        this.pdfDownload = false;
      })
      .save(this.fileName + '_' + datef + '_' + dates + '.pdf');
  }

  getdata() {
    this.isSpinning = true;
    this.api
      .getannexture(
        0,
        0,
        'ID',
        'ASC',
        ' AND STATUS = 1 AND IS_ADVANCE = 1 AND CLAIM_ID=' + this.claimData.ID
      )
      .subscribe(
        (data) => {
          this.countt = data['count'];
          this.showlayoutDataList = data['data'];
          this.total = 0;
          this.total1 = 0;
          for (var i = 0; this.countt > i; i++) {
            this.total =
              this.total + this.showlayoutDataList[i]['CLAIMED_AMOUNT'];
            this.total1 =
              this.total1 + this.showlayoutDataList[i]['ADMISSIBLE_AMOUNT'];
          }
          if (this.claimData.IS_ADVANCE_TAKEN == 1) {
            this.advanceAdmissible = Math.round(this.total1);
          } else {
            this.advanceAdmissible = 0;
          }
          // this.claimData.REMAINING_ADMISSIBLE_AMOUNT = Math.round(
          //   this.advanceAdmissible
          // );
          this.isSpinning = false;
        },
        (err) => {
          console.log(err);
          this.isSpinning = false;
        }
      );
  }

  updateClaim(showlayoutDataList: any) {
    this.isSpinning = true;
    this.claimData.SUB_STAGE = 'A';
    this.claimData.FINAL_ADMISSIBLE_AMOUNT = this.total1;
    this.claimData.FINAL_CLAIMED_AMOUNT = this.total;
    this.api.updateClaimedAnnexure(this.claimData).subscribe((successCode) => {
      if (successCode.code == '200') {
        this.claimData.FINAL_ADMISSIBLE_AMOUNT = this.advanceAdmissible;
        this.claimData.FINAL_REMAINING_AMOUNT = Math.round(
          Number(this.total1) - Number(this.claimData.ADVANCE_AMOUNT)
        );
        this.api
          .updateClaimWithoutHospitalData(this.claimData)
          .subscribe((successCode) => {
            if (successCode.code == '200') {
              // this.message.success('Information Changed Successfully...', '');
              // if (!addNew) this.drawerClose();
              this.message.success('Information updated Successfully', '');

              // this.getdata();

              this.showModal(showlayoutDataList);
              this.isSpinning = false;
              this.isSpinning = false;
            } else {
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
      } else {
        this.message.error('Information Not Saved', '');
        this.isSpinning = false;
      }
    });
  }

  confirm(data) {
    data.STATUS = 0;
    data.CODE_NO = ' ';
    data.INVESTIGATION_PROCEDURE_ID = 0;
    // data.ADMISSIBLE_AMOUNT = 0;
    // data.CLAIMED_AMOUNT = 0;
    data.IS_ADVANCE = 1;
    this.api.updateannextureadd(data).subscribe((successCode) => {
      if (successCode.code == '200') {
        this.message.success('Particular removed successfully', '');
        this.isSpinning = false;
        this.api
          .getannexture(
            0,
            0,
            'ID',
            'ASC',
            ' AND STATUS = 1 AND IS_ADVANCE = 1 AND CLAIM_ID=' +
              this.claimData.ID
          )
          .subscribe(
            (data) => {
              this.countt = data['count'];
              this.showlayoutDataList = data['data'];
              this.total = 0;
              this.total1 = 0;
              for (var i = 0; this.countt > i; i++) {
                this.total =
                  this.total + this.showlayoutDataList[i]['CLAIMED_AMOUNT'];
                this.total1 =
                  this.total1 + this.showlayoutDataList[i]['ADMISSIBLE_AMOUNT'];
              }
              this.claimData.FINAL_ADMISSIBLE_AMOUNT = this.total1;
              this.claimData.FINAL_CLAIMED_AMOUNT = this.total;
              if (this.claimData.IS_ADVANCE_TAKEN == 1) {
                this.advanceAdmissible = Math.round(this.total1);
              } else {
                this.advanceAdmissible = 0;
              }
              // this.claimData.REMAINING_ADMISSIBLE_AMOUNT = Math.round(
              //   this.advanceAdmissible
              // );
              this.api
                .updateClaimedAnnexure(this.claimData)
                .subscribe((successCode) => {
                  if (successCode.code == '200') {
                    this.claimData.FINAL_ADMISSIBLE_AMOUNT =
                      this.advanceAdmissible;
                    this.claimData.FINAL_REMAINING_AMOUNT = Math.round(
                      Number(this.total1) -
                        Number(this.claimData.ADVANCE_AMOUNT)
                    );
                    this.api
                      .updateClaimWithoutHospitalData(this.claimData)
                      .subscribe((successCode) => {
                        if (successCode.code == '200') {
                          this.api
                            .getclaimMaster(
                              0,
                              0,
                              '',
                              '',
                              ' AND ID = ' + this.claimData.ID,
                              '',
                              '',
                              '',
                              ''
                            )
                            .subscribe(
                              (data) => {
                                if (data['code'] == 200) {
                                  this.claimData = data['data'][0];
                                  this.isSpinning = false;
                                  this.isVisible = true;
                                } else {
                                  this.message.error("Can't Load Data", '');
                                  this.isSpinning = false;
                                }
                              },
                              (err) => {
                                console.log(err);
                              }
                            );
                          this.message.success(
                            'Information updated Successfully',
                            ''
                          );
                          this.isSpinning = false;
                        } else {
                          this.message.error(
                            'Information Has Not Changed...',
                            ''
                          );
                          this.isSpinning = false;
                        }
                      });
                    this.isSpinning = false;
                  } else {
                    this.isSpinning = false;
                  }
                });
              this.isSpinning = false;
            },
            (err) => {
              console.log(err);
              this.isSpinning = false;
            }
          );
      } else {
        this.message.error('Failed to remove particular', '');
        this.isSpinning = false;
      }
    });
  }

  cancel(): void {}
}
