import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmployeeloginComponent } from './employeelogin/employeelogin.component';
import { LoginComponent } from './login/login.component';
// import { CategoryMasterComponent } from './pages/BasicForms/Category/category-master/category-master.component';
// import { ClientMasterComponent } from './pages/BasicForms/ClientM/client-master/client-master.component';
// import { FeatureMasterComponent } from './pages/BasicForms/Feature/feature-master/feature-master.component';
// import { KanbanProjectWiseComponent } from './pages/BasicForms/Kanban/kanban-project-wise/kanban-project-wise.component';
// import { KanbanComponent } from './pages/BasicForms/Kanban/kanban/kanban.component';
// import { ModuleMasterComponent } from './pages/BasicForms/ModuleM/module-master/module-master.component';
// import { ProjectMasterComponent } from './pages/BasicForms/ProjectM/project-master/project-master.component';
// import { RoadmapComponent } from './pages/BasicForms/roadmap/roadmap.component';
// import { TaskMasterComponent } from './pages/BasicForms/TaskM/task-master/task-master.component';
// import { TaskTransferHistoryComponent } from './pages/BasicForms/taskTransfer/task-transfer-history/task-transfer-history.component';
// import { TypeMasterComponent } from './pages/BasicForms/Type/type-master/type-master.component';
// import { AdmindashboardComponent } from './pages/CommonModule/admindashboard/admindashboard.component';
import { DashboardComponent } from './pages/CommonModule/Dashboard/dashboard/dashboard.component';
import { FormsComponent } from './pages/CommonModule/Forms/forms/forms.component';
import { RolesComponent } from './pages/CommonModule/Roles/roles/roles.component';
import { UsersComponent } from './pages/CommonModule/Users/users/users.component';
import { CityMasterComponent } from './pages/HospitalMgtBasicForm/City/city-master/city-master.component';
import { ClaimUpdatedComponent } from './pages/HospitalMgtBasicForm/Claim/claim-updated/claim-updated.component';
import { ClaimsComponent } from './pages/HospitalMgtBasicForm/Claim/claims/claims.component';
import { ClaimApplicationMasterComponent } from './pages/HospitalMgtBasicForm/ClaimApplication/claim-application-master/claim-application-master.component';
import { ClaimmasterlistComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/claimmasterlist/claimmasterlist.component';
import { ClaimstageslistComponent } from './pages/HospitalMgtBasicForm/ClaimStagesMaster/claimstageslist/claimstageslist.component';
import { DatabasetablemasterlistComponent } from './pages/HospitalMgtBasicForm/databasetablemaster/databasetablemasterlist/databasetablemasterlist.component';
import { EmployeelistComponent } from './pages/HospitalMgtBasicForm/Employee/employeelist/employeelist.component';
import { FilemastertableComponent } from './pages/HospitalMgtBasicForm/FilesMaster/filemastertable/filemastertable.component';
import { FilehierarchyComponent } from './pages/HospitalMgtBasicForm/FilesMaster/filemodule/filehierarchy/filehierarchy.component';
import { HospitalmasterlistComponent } from './pages/HospitalMgtBasicForm/HospitalMaster/hospitalmasterlist/hospitalmasterlist.component';
import { ImportExcelInvestigationRateComponent } from './pages/HospitalMgtBasicForm/import-excel-investigation-rate/import-excel-investigation-rate.component';
import { ListinvestigationComponent } from './pages/HospitalMgtBasicForm/Investigation-procedure/listinvestigation/listinvestigation.component';
import { InvestigationComponentComponent } from './pages/HospitalMgtBasicForm/InvestigationCategory/investigation-component/investigation-component.component';
import { MedicalFacilitiesMasterComponent } from './pages/HospitalMgtBasicForm/MedicalFacility/medical-facilities-master/medical-facilities-master.component';
import { NotificationAndMemorandomMasterComponent } from './pages/HospitalMgtBasicForm/NotificationAndMemorandom/notification-and-memorandom-master/notification-and-memorandom-master.component';
import { HospitalWiseSummaryReportComponent } from './pages/Reports/Medical Reports/hospital-wise-summary-report/hospital-wise-summary-report.component';
import { InspectorWiseAmountStatsReportComponent } from './pages/Reports/Medical Reports/inspector-wise-amount-stats-report/inspector-wise-amount-stats-report.component';
import { InspectorWiseDetailedSummaryReportComponent } from './pages/Reports/Medical Reports/inspector-wise-detailed-summary-report/inspector-wise-detailed-summary-report.component';
import { InspectorWiseSummaryReportComponent } from './pages/Reports/Medical Reports/inspector-wise-summary-report/inspector-wise-summary-report.component';
import { StageWiseSummaryReportComponent } from './pages/Reports/Medical Reports/stage-wise-summary-report/stage-wise-summary-report.component';
import { HispitalWiseAmountStatsComponent } from './pages/Reports/Medical Reports/hospital-wise-amount-stats/hispital-wise-amount-stats.component';
import { AdmindashboardComponent } from './pages/CommonModule/admindashboard/admindashboard.component';
import { BeneficiaryTypeAmountStatsComponent } from './pages/Reports/Medical Reports/beneficiary-type-amount-stats/beneficiary-type-amount-stats.component';
import { BeneficiaryTypeSummaryReportsComponent } from './pages/Reports/Medical Reports/beneficiary-type-summary-reports/beneficiary-type-summary-reports.component';
import { BeneficiaryTypeWiseDetailedComponent } from './pages/Reports/Medical Reports/beneficiary-type-wise-detailed/beneficiary-type-wise-detailed.component';
import { EmployeeWiseAmountStatsComponent } from './pages/Reports/Medical Reports/employee-wise-amount-stats/employee-wise-amount-stats.component';
import { EmployeeWiseSummaryReportComponent } from './pages/Reports/Medical Reports/employee-wise-summary-report/employee-wise-summary-report.component';
import { EmployeewisedetailedComponent } from './pages/Reports/Medical Reports/employeewisedetailed/employeewisedetailed.component';
import { EmployeeclaimlistComponent } from './pages/HospitalMgtBasicForm/Employee/employeeclaimlist/employeeclaimlist.component';
import { PendingClaimsComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/pending-claims/pending-claims.component';
import { HierarchyWiseFilesSummaryReportComponent } from './pages/Reports/Medical Reports/hierarchy-wise-files-summary-report/hierarchy-wise-files-summary-report.component';
import { UserfileactivitydetailedreportComponent } from './pages/Reports/Medical Reports/userfileactivitydetailedreport/userfileactivitydetailedreport.component';
import { DayWiseFilesSummaryReportComponent } from './pages/Reports/Medical Reports/day-wise-files-summary-report/day-wise-files-summary-report.component';
import { UserWiseFilesClosureDetailedReportComponent } from './pages/Reports/Medical Reports/user-wise-files-closure-detailed-report/user-wise-files-closure-detailed-report.component';
import { HospitalwisedetailedComponent } from './pages/Reports/Medical Reports/hospitalwisedetailed/hospitalwisedetailed.component';
import { UserWiseFilesDetailedReportComponent } from './pages/Reports/Medical Reports/user-wise-files-detailed-report/user-wise-files-detailed-report.component';
import { ForwardmasterlistComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/ForwardMaster/forwardmasterlist/forwardmasterlist.component';
import { ReportsviewComponent } from './pages/reportsview/reportsview.component';
import { FilesystemdashboardComponent } from './pages/CommonModule/filesystemdashboard/filesystemdashboard.component';
import { UserWiseFilesSummaryReportComponent } from './pages/Reports/Medical Reports/user-wise-files-summary-report/user-wise-files-summary-report.component';
import { SignaturemasterlistComponent } from './pages/HospitalMgtBasicForm/SignatureMaster/signaturemasterlist/signaturemasterlist.component';
import { InfoComponent } from './pages/HospitalMgtBasicForm/info/info.component';
import { TransferlistComponent } from './pages/HospitalMgtBasicForm/Transfer/Transfer/transferlist/transferlist.component';
import { HODPermissionlistComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/PermissionMaster/HODPermissionMaster/hodpermissionlist/hodpermissionlist.component';
import { DelaycondolationlistComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/PermissionMaster/DelayCondolationMaster/delaycondolationlist/delaycondolationlist.component';
import { ExpostfactolistComponent } from './pages/HospitalMgtBasicForm/ClaimMaster/PermissionMaster/ExpostFactoMaster/expostfactolist/expostfactolist.component';
import { LtcmasterlistComponent } from './pages/HospitalMgtBasicForm/LTC/LTCMaster/ltcmasterlist/ltcmasterlist.component';
import { TravelclasslistComponent } from './pages/HospitalMgtBasicForm/Transfer/travelclassmaster/travelclasslist/travelclasslist.component';
import { TravellistComponent } from './pages/HospitalMgtBasicForm/Transfer/travelmaster/travellist/travellist.component';
import { TourmasterlistComponent } from './pages/HospitalMgtBasicForm/Tour/TourMaster/tourmasterlist/tourmasterlist.component';
import { ClaimMasterReportComponent } from './pages/Reports/Medical Reports/claim-master-report/claim-master-report.component';
import { InspectorWiseLtcSummaryReportComponent } from './pages/Reports/LTC Reports/inspector-wise-ltc-summary-report/inspector-wise-ltc-summary-report.component';
import { LtcdetailedreportComponent } from './pages/Reports/LTC Reports/ltcdetailedreport/ltcdetailedreport.component';
import { InspectorWiseTourSummaryReportComponent } from './pages/Reports/Tour Reports/inspector-wise-tour-summary-report/inspector-wise-tour-summary-report.component';
import { TourdetailedreportsComponent } from './pages/Reports/Tour Reports/tourdetailedreports/tourdetailedreports.component';
import { InspectorWiseTransferSummaryReportComponent } from './pages/Reports/Transfer Reports/inspector-wise-transfer-summary-report/inspector-wise-transfer-summary-report.component';
import { TransferreddetailedreportComponent } from './pages/Reports/Transfer Reports/transferreddetailedreport/transferreddetailedreport.component';
import { LTCdashboardComponent } from './pages/CommonModule/ltcdashboard/ltcdashboard.component';
import { TourdashboardComponent } from './pages/CommonModule/tourdashboard/tourdashboard.component';
import { TrasferdashboardComponent } from './pages/CommonModule/trasferdashboard/trasferdashboard.component';
import { AdvanceGivenForJourneyltcComponent } from './pages/Reports/LTC Reports/advance-given-for-journeyltc/advance-given-for-journeyltc.component';
import { DateWiseModeWiseJourneyCountltcComponent } from './pages/Reports/LTC Reports/date-wise-mode-wise-journey-countltc/date-wise-mode-wise-journey-countltc.component';
import { EmployeeWiseLtcDetailedReportltcComponent } from './pages/Reports/LTC Reports/employee-wise-ltc-detailed-reportltc/employee-wise-ltc-detailed-reportltc.component';
import { ModeWiseClassWiseLtcCountltcComponent } from './pages/Reports/LTC Reports/mode-wise-class-wise-ltc-countltc/mode-wise-class-wise-ltc-countltc.component';
import { MonthWiseClaimCountltcComponent } from './pages/Reports/LTC Reports/month-wise-claim-countltc/month-wise-claim-countltc.component';
import { EmployeetourlistComponent } from './pages/HospitalMgtBasicForm/Employee/employeeTour/employeetourlist/employeetourlist.component';
import { EmployeeltclistComponent } from './pages/HospitalMgtBasicForm/Employee/employeeltc/employeeltclist/employeeltclist.component';
import { PendingtourclaimsComponent } from './pages/HospitalMgtBasicForm/Tour/TourMaster/pendingtourclaims/pendingtourclaims.component';
import { ListPendingClaimComponent } from './pages/HospitalMgtBasicForm/LTC/LTCMaster/LTCPendingClaim/list-pending-claim/list-pending-claim.component';
import { TransferPendingClaimComponent } from './pages/HospitalMgtBasicForm/Transfer/Transfer/transfer-pending-claim/transfer-pending-claim.component';
import { EmployeetransferlistComponent } from './pages/HospitalMgtBasicForm/Employee/employeetransfer/employeetransferlist/employeetransferlist.component';
import { BlockyearlistComponent } from './pages/HospitalMgtBasicForm/LTC/LTCMaster/BlockYearMaster/blockyearlist/blockyearlist.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/admin-dashboard' },
  {
    path: 'welcome',
    loadChildren: () =>
      import('./pages/welcome/welcome.module').then((m) => m.WelcomeModule),
  },
  { path: 'login', component: LoginComponent },
  { path: 'forms', component: FormsComponent },
  { path: 'users', component: UsersComponent },
  { path: 'roles', component: RolesComponent },
  // { path: 'clients', component: ClientMasterComponent },
  // { path: 'projects', component: ProjectMasterComponent },
  // { path: 'modules', component: ModuleMasterComponent },
  // { path: 'features', component: FeatureMasterComponent },
  // { path: 'category', component: CategoryMasterComponent },
  // { path: 'tasks', component: TaskMasterComponent },
  // { path: 'type', component: TypeMasterComponent },
  // { path: 'kanban', component: KanbanComponent },
  // { path: 'projectID/:id', component: KanbanProjectWiseComponent },
  // { path: 'roadmap', component: RoadmapComponent },
  // { path: 'attachment', component: TaskTransferHistoryComponent },
  { path: 'index', component: AdmindashboardComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'hospital-master', component: HospitalmasterlistComponent },
  { path: 'city-master', component: CityMasterComponent },
  {
    path: 'notification-memorandum-master',
    component: NotificationAndMemorandomMasterComponent,
  },
  {
    path: 'investigation-procedure-master',
    component: ListinvestigationComponent,
  },
  {
    path: 'medical-facility-master',
    component: MedicalFacilitiesMasterComponent,
  },
  {
    path: 'investigation-procedure-categories',
    component: InvestigationComponentComponent,
  },
  { path: 'claim-application', component: ClaimsComponent },
  { path: 'claim-stages-master', component: ClaimstageslistComponent },
  { path: 'claim-application-revised', component: ClaimUpdatedComponent },
  { path: 'admin-dashboard', component: AdmindashboardComponent },
  { path: 'employee', component: EmployeelistComponent },
  { path: 'claimmaster', component: ClaimmasterlistComponent },
  { path: 'databaseexcel', component: DatabasetablemasterlistComponent },
  { path: 'importexcel', component: ImportExcelInvestigationRateComponent },
  // { path: 'hospitalReport', component: HospitalWiseSummaryReportComponent },
  { path: 'fileHierarchy', component: FilehierarchyComponent },
  { path: 'fileMaster', component: FilemastertableComponent },
  { path: 'employeelogin', component: EmployeeloginComponent },
  { path: 'employeeclaimmaster', component: EmployeeclaimlistComponent },
  { path: 'pendingclaims', component: PendingClaimsComponent },
  { path: 'forwardmaster', component: ForwardmasterlistComponent },
  { path: 'reportview', component: ReportsviewComponent },
  { path: 'signaturemaster', component: SignaturemasterlistComponent },
  { path: 'aboutInfo', component: InfoComponent },
  { path: 'transfermaster', component: TransferlistComponent },
  { path: 'hodpermissionmaster', component: HODPermissionlistComponent },
  { path: 'expostfactomaster', component: ExpostfactolistComponent },
  { path: 'delaycondolationmaster', component: DelaycondolationlistComponent },
  { path: 'ltcmaster', component: LtcmasterlistComponent },
  { path: 'travelclassmaster', component: TravelclasslistComponent },
  { path: 'travelmaster', component: TravellistComponent },
  { path: 'tourmaster', component: TourmasterlistComponent },
  {
    path: 'ltcdarshboard',
    component: LTCdashboardComponent,
  },
  {
    path: 'tourdarshboard',
    component: TourdashboardComponent,
  },
  {
    path: 'transferdarshboard',
    component: TrasferdashboardComponent,
  },
  {
    path: 'employeetour',
    component: EmployeetourlistComponent,
  },
  {
    path: 'employeeltcmaster',
    component: EmployeeltclistComponent,
  },
  {
    path: 'pendingtourclaims',
    component: PendingtourclaimsComponent,
  },
  {
    path: 'pendingltcclaims',
    component: ListPendingClaimComponent,
  },
  {
    path: 'pendingtransferclaims',
    component: TransferPendingClaimComponent,
  },
  {
    path: 'employeetransfer',
    component: EmployeetransferlistComponent,
  },
  {
    path: 'blockyear',
    component: BlockyearlistComponent,
  },

  //// reports ////
  // { path: 'hospitalwiseamountreport', component: HispitalWiseAmountStatsComponent },
  {
    path: 'beneficiarytypeamount',
    component: BeneficiaryTypeAmountStatsComponent,
  },
  {
    path: 'beneficiarytypesummary',
    component: BeneficiaryTypeSummaryReportsComponent,
  },
  {
    path: 'beneficiarytypedeailed',
    component: BeneficiaryTypeWiseDetailedComponent,
  },
  {
    path: 'employeewiseamountstats',
    component: EmployeeWiseAmountStatsComponent,
  },
  {
    path: 'employeewisesummary',
    component: EmployeeWiseSummaryReportComponent,
  },
  {
    path: 'inspectorSummaryReport',
    component: InspectorWiseSummaryReportComponent,
  },
  {
    path: 'inspectorDailyDetailedReport',
    component: InspectorWiseDetailedSummaryReportComponent,
  },
  {
    path: 'hostpitalwisesummary',
    component: HospitalWiseSummaryReportComponent,
  },
  { path: 'stageReport', component: StageWiseSummaryReportComponent },
  {
    path: 'inspectorAmountReport',
    component: InspectorWiseAmountStatsReportComponent,
  },
  { path: 'hostpitalwiseamounts', component: HispitalWiseAmountStatsComponent },
  { path: 'employeewisedetail', component: EmployeewisedetailedComponent },
  {
    path: 'userwisefiledetail',
    component: UserWiseFilesDetailedReportComponent,
  },
  { path: 'hospitalwisedetailed', component: HospitalwisedetailedComponent },
  {
    path: 'userwisefilecloserdetail',
    component: UserWiseFilesClosureDetailedReportComponent,
  },
  { path: 'daywisefilesummary', component: DayWiseFilesSummaryReportComponent },
  {
    path: 'userfileactivitydetail',
    component: UserfileactivitydetailedreportComponent,
  },
  {
    path: 'hierarchywisefilesummary',
    component: HierarchyWiseFilesSummaryReportComponent,
  },
  { path: 'filesystemdashboard', component: FilesystemdashboardComponent },
  {
    path: 'userwisefilesummary',
    component: UserWiseFilesSummaryReportComponent,
  },
  {
    path: 'claimcountreport',
    component: ClaimMasterReportComponent,
  },
  {
    path: 'ltcdetailreport',
    component: LtcdetailedreportComponent,
  },
  {
    path: 'inspectorwiseltcsummary',
    component: InspectorWiseLtcSummaryReportComponent,
  },
  {
    path: 'inspectorwisetoursummary',
    component: InspectorWiseTourSummaryReportComponent,
  },
  {
    path: 'inspectorwisetransfersummary',
    component: InspectorWiseTransferSummaryReportComponent,
  },
  {
    path: 'transferdetailreport',
    component: TransferreddetailedreportComponent,
  },
  {
    path: 'tourdetailreport',
    component: TourdetailedreportsComponent,
  },
  {
    path: 'advanceGivenForLtcJorney',
    component: AdvanceGivenForJourneyltcComponent,
  },
  {
    path: 'dateWiseModeWiseJourneyCount',
    component: DateWiseModeWiseJourneyCountltcComponent,
  },
  {
    path: 'employeewiseltcdetailed',
    component: EmployeeWiseLtcDetailedReportltcComponent,
  },
  {
    path: 'modewiseclasswiseltccount',
    component: ModeWiseClassWiseLtcCountltcComponent,
  },
  {
    path: 'monthwiseltcclaimcount',
    component: MonthWiseClaimCountltcComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
