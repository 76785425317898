<div>
  <form nz-form #AddNewRecord="ngForm">
    <nz-spin [nzSpinning]="isSpinning">
      <div nz-row>
        <!-- <div nz-row class="my-box"> -->
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>Date</nz-form-label>
            <nz-form-control nzErrorTip="Please Select Date">
              <nz-form-control nzHasFeedback nzErrorTip="Please Select Date">
                <nz-date-picker
                  name="date"
                  [(ngModel)]="data.DATE"
                  style="width: 100%"
                ></nz-date-picker>
              </nz-form-control>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon
              >Mode Of Conveyance Used</nz-form-label
            >
            <nz-form-control nzHasFeedback nzErrorTip="Select Mode">
              <nz-select
                nzAllowClear
                name="room"
                [(ngModel)]="data.TRAVEL_MODE_ID"
                style="width: 100%"
                nzPlaceHolder="Select Mode Of Conveyance Used"
                (ngModelChange)="conveyanceMode($event)"
              >
                <nz-option
                  *ngFor="let role of modedata"
                  [nzValue]="role.ID"
                  [nzLabel]="role.NAME"
                >
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>From Place</nz-form-label>
            <nz-form-control nzErrorTip="Please Enter From Place">
              <input
                nz-input
                name="fromname"
                required
                placeHolder="Enter From Place"
                maxlength="256"
                autocomplete="off"
                [(ngModel)]="data.FROM_PLACE"
                required
              />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>To Place</nz-form-label>
            <nz-form-control nzErrorTip="Please Enter To Place">
              <input
                nz-input
                name="toname"
                required
                placeHolder="Enter To Place"
                maxlength="256"
                autocomplete="off"
                [(ngModel)]="data.TO_PLACE"
                required
              />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon
              >Class To Which Entitled</nz-form-label
            >
            <nz-form-control
              nzHasFeedback
              nzErrorTip="Select Class To Which Entitled"
            >
              <nz-select
                nzAllowClear
                name="rofffom"
                [(ngModel)]="data.ENTITLED_TRAVEL_CLASS_ID"
                style="width: 100%"
                nzPlaceHolder="Select Class To Which Entitled"
              >
                <nz-option
                  *ngFor="let role of classdata"
                  [nzValue]="role.ID"
                  [nzLabel]="role.NAME"
                >
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon
              >Class By Which Travelled</nz-form-label
            >
            <nz-form-control
              nzHasFeedback
              nzErrorTip="Select Class By Which Travelled"
            >
              <nz-select
                nzAllowClear
                name="rooddddgfbm"
                [(ngModel)]="data.TRAVELLED_TRAVEL_CLASS_ID"
                style="width: 100%"
                nzPlaceHolder="Select Class By Which Travelled"
              >
                <nz-option
                  *ngFor="let role of classdata"
                  [nzValue]="role.ID"
                  [nzLabel]="role.NAME"
                >
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>Claimed Amount</nz-form-label>
            <nz-form-control nzErrorTip="Enter Claimed Amount">
              <nz-input-group style="width: 100%" nzAddOnBefore="₹">
                <input
                  nz-input
                  autocomplete="off"
                  [(ngModel)]="data.TRANSPORTATION_CLAIMED_AMOUNT"
                  name="TRANSPORTATION_CLAIMED_AMOUNT"
                  (keypress)="omit($event)"
                  type="text"
                  maxlength="8"
                  placeHolder="Claimed Amount"
                  required
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon
              >Fare Of Entitled Class</nz-form-label
            >
            <nz-form-control nzErrorTip="Enter Fare Of Entitled Class">
              <nz-input-group style="width: 100%" nzAddOnBefore="₹">
                <input
                  nz-input
                  autocomplete="off"
                  [(ngModel)]="data.FARE_OF_ENTITLED_CLASS"
                  name="fairPaid"
                  (keypress)="omit($event)"
                  type="text"
                  maxlength="8"
                  placeHolder="Fare Of Entitled Class"
                  required
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </nz-spin>
  </form>

  <div class="footer">
    <button
      type="button"
      nz-button
      (click)="close(AddNewRecord)"
      class="ant-btn"
      style="margin-right: 8px"
    >
      <span>Cancel</span>
    </button>
    <button
      type="button"
      class="ant-btn ant-btn-primary"
      nzType="primary"
      *ngIf="!ID"
      nz-button
      nz-dropdown
      [nzDropdownMenu]="menu"
      [nzLoading]="isSpinning"
      (click)="save(false, AddNewRecord)"
    >
      <span>Save & Close</span>
    </button>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li
          nz-menu-item
          class="menuc"
          style="border-radius: 4px; background-color: #40a9ff"
        >
          <a style="color: white" (click)="save(true, AddNewRecord)"
            >Save & New</a
          >
        </li>
      </ul>
    </nz-dropdown-menu>
    <button
      type="button"
      class="ant-btn ant-btn-primary"
      nz-button
      [nzLoading]="isSpinning"
      *ngIf="ID"
      (click)="save(false, AddNewRecord)"
    >
      <span>Update & Close</span>
    </button>
  </div>
  <div class="header">
    <button
      type="button"
      nz-button
      (click)="close(AddNewRecord)"
      class="ant-btn"
      style="margin-right: 8px"
    >
      <i nz-icon nzType="close" nzTheme="outline"></i>
    </button>
  </div>
</div>
