<nz-steps [nzCurrent]="index" (nzIndexChange)="onIndexChange($event)">
  <nz-step value="0" nzTitle="Choose File"></nz-step>
  <nz-step [nzDisabled]="stepsdisabled" value="1" nzTitle="Map Fields"></nz-step>
  <nz-step [nzDisabled]="stepsdisabled1" value="2" nzTitle="Import Data"></nz-step>
</nz-steps>


<div class="steps-content">
  <div *ngIf="index == 0">
    <div>
      <form nz-form #form10>
        <nz-spin [nzSpinning]="isSpinning">
          <div nz-row>
            <div nz-col nzSpan="24" class="my-box">
              <div nz-row style="margin-top: 50px;">

                <div nz-col nzSpan="24" style="text-align:center ;">
                  <nz-form-item>
                    <nz-form-label nzRequired nzNoColon>Download Sample File</nz-form-label>
                    <nz-form-control nzHasFeedback>
                      <!-- <button [href]="excelurl" target="_self" rel="noopener noreferrer">
                      Download Sample
                      </button> -->


                      <button nz-button [nzLoading]="loadingRecords" (click)="downloadMyFile()">Download

                      </button>
                    </nz-form-control>
                  </nz-form-item>
                </div>

                <!-- <div nz-col nzSpan="12">
                  <nz-form-item>
                    <nz-form-label nzRequired nzNoColon>Upload Sample File</nz-form-label>
                    <nz-form-control nzHasFeedback nzErrorTip="Please Select Icon">
                      <button nz-button style="height: 28px; padding: 0px 7px; font-size: 12px;"
                        (click)="fileInput.click()">Select Upload
                        <input #fileInput [(ngModel)]="Excel" name="icon1" type="file" (change)="onFileChange($event)"
                          style="display: none;" required />
                      </button>
                    </nz-form-control>
                  </nz-form-item>
                </div> -->

                <div nz-col nzSpan="24" style="text-align:center ; margin-top: 50px;">
                  <nz-form-item>
                    <nz-form-label nzRequired nzNoColon>Upload Excel File </nz-form-label>
                    <nz-form-control nzHasFeedback nzErrorTip="Upload File">
                      <button nz-button (click)="fileInput.click()">
                        Choose File
                        <input #fileInput name="otherimage" type="file"
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          (change)="onFileSelected($event)" style="display: none;" required />
                      </button>
                    </nz-form-control>
                  </nz-form-item>
                  <div nz-row>
                    <div nz-col nzSpan="24" *ngIf="isProgressVisible"
                      style="text-align: center; font-size: 50px; font-weight: bold;">
                      <!-- {{uploadProgress}} % -->
                      <nz-progress [nzPercent]="uploadProgress" [nzStrokeWidth]="10"></nz-progress>
                    </div>
                </div>
                  <div nz-col nzSpan="23" *ngIf="fileURL !=null"
                    style="overflow: hidden; padding-left: 25px; font-size: 13px;">
                    {{fileURL.name}}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </nz-spin>
      </form>
    </div>
    <div class="footer">

      <button *ngIf="fileURL!=null" type="button" class="ant-btn ant-btn-primary" nz-button nz-dropdown
        (click)="save(false,form10)"><span>Upload</span></button>

      <!-- <button type="button" class="ant-btn ant-btn-primary" nz-button
          (click)="save(false,form10)"><span>Update</span></button> -->

    </div>
  </div>

  <div *ngIf="index == 1">
    <div>
      <form nz-form #form11>
        <nz-spin [nzSpinning]="isSpinning">
          <div nz-row>
            <div nz-col nzSpan="24" class="my-box">
              <div nz-row style="margin-top: 50px;">

                <div nz-col nzSpan="12">
                  <nz-form-label nzNoColon style="margin-left: 10px;"><b>Fields From Excel</b></nz-form-label>
                  <div *ngFor="let row of COLUMN_JSON; let i = index">
                    <div nz-row>
                      <div nz-col nzSpan="24">
                        <div nz-row>
                          <div nz-col nzSpan="16">
                            <nz-form-item>
                              <!-- <nz-form-label nzRequired nzNoColon>{{data1}}</nz-form-label> -->
                              <nz-form-control nzErrorTip="Please Select data">
                                <nz-select nzAllowClear style="width: 90%;" name="c{{i}}"
                                  [(ngModel)]="COLUMN_JSON[i]['EXCEL_FIELD']">
                                  <nz-option *ngFor="let data of data1; let n=index" [nzValue]="data"
                                    [nzLabel]="data"></nz-option>
                                  <!-- <nz-option nzValue="0" nzLabel="Hyundai Temp"></nz-option> -->
                                </nz-select>

                              </nz-form-control>
                            </nz-form-item>
                          </div>
                          <div nz-col nzSpan="8" style="margin-top: 10px;">
                            --------------->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div nz-col nzSpan="8"> -->
                <!-- <div *ngFor="let row of COLUMN_JSON; let i = index" >---------------------------------------->
                <!-- </div>
                </div> -->

                <div nz-col nzSpan="8">
                  <nz-form-label nzNoColon style="margin-left: 10px;"><b>Fields From Application</b></nz-form-label>
                  <div *ngFor="let row of COLUMN_JSON; let c = index">
                    <nz-form-item>
                      <nz-form-control nzErrorTip="Please Select data">
                        <nz-select nzAllowClear style="width: 90%;" name="v{{c}}"
                          [(ngModel)]="COLUMN_JSON[c]['TABLE_FIELD']" (ngModelChange)="fieldchange($event,c)">
                          <nz-option *ngFor="let data of databaseexcel1; let b=index" [nzValue]="data['TABLE_FIELD']"
                            [nzLabel]="data['TABLE_FIELD']"></nz-option>
                        </nz-select>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
                <div nz-col nzSpan="3"></div>
                <!-- <div nz-col nzSpan="12">
                  <nz-form-item>
                    <nz-form-label nzRequired nzNoColon>Download Sample File</nz-form-label>
                    <nz-form-control nzHasFeedback nzErrorTip="Please Select Icon">
                      <button nz-button style="height: 28px; padding: 0px 7px; font-size: 12px;"
                        (click)="fileInput.click()">Select Upload
                        <input #fileInput [(ngModel)]="Excel" name="icon" type="file" (change)="onFileChange($event)"
                          style="display: none;" required />
                      </button>
                    </nz-form-control>
                  </nz-form-item>
                </div> -->

              </div>
            </div>
          </div>
        </nz-spin>
      </form>
      <div  nz-col nzSpan="6">
      <div style="text-align:left;">
        <label style="margin-top:30px" nz-checkbox [(ngModel)]="allowDuplicateRecords ">Allow Duplicate</label>
      </div>
    </div>
      <!-- <div *ngIf="allowDuplicateRecords" nz-col nzSpan="8" style="margin-top: 10px;">
        <nz-form-item>
          <nz-form-label  nzNoColon>Select Fields</nz-form-label>
          <nz-form-control nzErrorTip="Please Select data">
            <nz-select nzMode="multiple" nzAllowClear style="width: 90%;" name="datata"
              [(ngModel)]="ALL_COLUMNS" >
              <nz-option *ngFor="let data of data1; let n=index" [nzValue]="data"
                [nzLabel]="data"></nz-option>
            </nz-select>

          </nz-form-control>
        </nz-form-item>
      </div> -->
     
      <div style="text-align:right;margin-top: 20px;">
        <button type="button" nz-button nzType="primary" (click)="save2(false,form11)">Import</button>
      </div>
    </div>
  </div>

  <div *ngIf="index == 2">
    <div>
      <form nz-form #form12>
        <nz-spin [nzSpinning]="isSpinning">
          <div nz-row>
            <div nz-col nzSpan="24" class="my-box">
              <form nz-form #form10>
                <nz-spin [nzSpinning]="isSpinning">

                  <div nz-row style="margin-top:30px;text-align: center;;">
                    <div nz-col nzSpan="5" style="border: 1px solid blue; margin-right: 25px;">
                      <div nz-col nzSpan="12" style="background-color: rgb(219, 212, 231);">
                        <h4 style="margin-top: 7px;"><b>{{TotalCount}}</b></h4>
                      </div>
                      <div nz-col nzSpan="12">
                        <h4 style="color:blue ;margin-top: 7px"><b>Total</b></h4>
                      </div>
                    </div>

                    <div nz-col nzSpan="5" style="border: 1px solid green;margin-right: 25px;">
                      <div nz-col nzSpan="12" style="background-color: rgb(206, 253, 206);">
                        <h4 style="margin-top: 7px"><b>{{SuccessCount}}</b></h4>
                      </div>
                      <div nz-col nzSpan="12">
                        <h4 style="color:green;margin-top: 7px"><b>Success</b></h4>
                      </div>
                    </div>

                    <div nz-col nzSpan="5" style="border: 1px solid black;margin-right: 25px;">
                      <div nz-col nzSpan="12" style="background-color: rgb(241, 234, 234);">
                        <h4 style="margin-top: 7px"><b>{{DuplicateCount}} </b></h4>
                      </div>
                      <div nz-col nzSpan="12">
                        <h4 style="color:black;margin-top: 7px"><b>Duplicate</b></h4>
                      </div>
                    </div>

                    <div nz-col nzSpan="5" style="border: 1px solid rgb(235, 160, 160);margin-right: 25px;">
                      <div nz-col nzSpan="12" style="background-color: rgb(236, 198, 198);">
                        <h4 style="margin-top: 7px"><b>{{FailedCount}}</b></h4>
                      </div>
                      <div nz-col nzSpan="12">
                        <h4 style="color:red;margin-top: 7px"><b>Failed</b></h4>
                      </div>
                    </div>
                  </div>

                  <div nz-col nzSpan="24">
                    <nz-table class="my-scroll no-wrap1" nzBordered #leadcategorymaster
                      nzTemplateMode [nzLoading]="loadingRecords"
                      nzWidth="100%" style="overflow-x: auto;"
                     (nzPageSizeChange)="search(true)"
                      (nzQueryParams)="sort($event)">

                      <thead (nzSortChange)="sort($event)" nzSingleSort>
                        <tr>
                          <th style="width: 100px;"><b>Status</b></th>
                          <th *ngFor="let item of data1;"><b>{{item}}</b></th>
                        </tr>

                        <tr *ngFor="let item1 of keys;let i = index">
                          <th style="width: 100px;"><b>Success</b></th>
                          <th *ngFor="let item2 of item1 ">{{item2==null?'':item2}}</th>
                        </tr>

                        <tr *ngFor="let item1 of keys2;let i = index">
                          <th style="width: 70px;"><b>Failed</b></th>

                          <th *ngFor="let item2 of item1 ">{{item2}}</th>

                        </tr>
                        <tr *ngFor="let item1 of keys1;let i = index">
                          <th style="width: 70px;"><b>Duplicate</b></th>

                          <th *ngFor="let item2 of item1 ">{{item2==null?'':item2}}</th>

                        </tr>
                      </thead>
                      <!-- <tbody></tbody> -->
                    </nz-table>
                  </div>
                </nz-spin>
              </form>
            </div>
          </div>
        </nz-spin>
      </form>
      <div style="text-align:right;">
        <button type="button" nz-button nzType="primary" (click)="finish(false,form12)">Finish</button>
      </div>
      <!-- <table class="sjs-table">
        <tbody>
          <tr *ngFor="let row of data ">
            <td *ngFor="let val of row">
              {{val}}
            </td>
          </tr>
        </tbody>
      </table> -->
      <!-- </div> -->
    </div>
  </div>
</div>