<div>
  <form nz-form #AddNewRecord="ngForm">
    <nz-spin [nzSpinning]="isSpinning">
      <div nz-row class="my-box">
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>From Place</nz-form-label>
            <nz-form-control nzErrorTip="Please Enter From Place ">
              <input
                nz-input
                name="fromPlace"
                [(ngModel)]="data.PLACE_FROM"
                placeHolder="Enter From Place "
                autocomplete="off"
                maxlength="256"
              />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>To Place</nz-form-label>
            <nz-form-control nzErrorTip="Please Enter To Place ">
              <input
                nz-input
                name="toPlace"
                [(ngModel)]="data.PLACE_TO"
                placeHolder="Enter To Place"
                autocomplete="off"
                maxlength="256"
              />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div nz-row class="my-box">
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon
              >Mode Of Conveyance</nz-form-label
            >
            <nz-input-group>
              <nz-select
                name="modeCon"
                nzPlaceHolder="Select Mode Of Conveyance"
                [(ngModel)]="data.MODE_OF_CONVEYANCE_ID"
                style="width: 100%"
                (ngModelChange)="getClasses($event)"
              >
                <nz-option
                  *ngFor="let role of mode"
                  [nzValue]="role.ID"
                  [nzLabel]="role.NAME"
                ></nz-option>
              </nz-select>
            </nz-input-group>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="12"></div>
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon
              >Class To which Entitled</nz-form-label
            >
            <nz-form-control nzErrorTip="Please Select Class">
              <nz-select
                name="classen"
                nzPlaceHolder="Select Class To which Entitled"
                [(ngModel)]="data.ENTITLED_CLASS_ID"
                style="width: 100%"
              >
                <nz-option
                  *ngFor="let role of class"
                  [nzValue]="role.ID"
                  [nzLabel]="role.NAME"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon
              >Class Actually Travelled</nz-form-label
            >
            <nz-form-control nzErrorTip="Please Select Class">
              <nz-select
                name="travell"
                nzPlaceHolder="Select Class Actually Travelled"
                [(ngModel)]="data.TRAVELLED_CLASS_ID"
                style="width: 100%"
              >
                <nz-option
                  *ngFor="let role of class"
                  [nzValue]="role.ID"
                  [nzLabel]="role.NAME"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row class="my-box">
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>No of Fairs</nz-form-label>
            <nz-form-control nzErrorTip="Please Enter No of Fairs">
              <nz-input-group nzAddOnAfter="No's">
                <input
                  nz-input
                  autocomplete="off"
                  name="noFair"
                  type="text"
                  maxlength="8"
                  placeHolder="Enter No of Fairs"
                  [(ngModel)]="data.NO_OF_FAIRS"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon
              >Fair Paid claimed</nz-form-label
            >
            <nz-form-control nzErrorTip="Please Enter Fair Paid claimed">
              <nz-input-group nzAddOnBefore="₹">
                <input
                  nz-input
                  autocomplete="off"
                  (keypress)="omit($event)"
                  name="JOURNEY_PARTICULAR_CLAIMED_AMOUNT"
                  type="text"
                  maxlength="8"
                  placeHolder="Enter Fair Paid claimed"
                  [(ngModel)]="data.JOURNEY_PARTICULAR_CLAIMED_AMOUNT"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon
              >Fair Paid Admisible</nz-form-label
            >
            <nz-form-control nzErrorTip="Please Enter Fair Paid">
              <nz-input-group nzAddOnBefore="₹">
                <input
                  nz-input
                  autocomplete="off"
                  (keypress)="omit($event)"
                  name="fairPaid"
                  type="text"
                  maxlength="8"
                  placeHolder="Enter Fair Paid Admisible"
                  [(ngModel)]="data.FAIR_PAID"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <!-- <div nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label nzRequired nzNoColon>Remark</nz-form-label>
                        <nz-form-control>
                            <textarea nz-input [nzAutosize]="{ minRows: 3, maxRows: 6 }" name="remark"
                                placeHolder="Enter Remark" [(ngModel)]="data.REMARKS" maxlength="500"></textarea>
                        </nz-form-control>
                    </nz-form-item>
                </div> -->
      </div>
    </nz-spin>
  </form>

  <div class="footer">
    <button
      type="button"
      nz-button
      (click)="close(AddNewRecord)"
      class="ant-btn"
      style="margin-right: 8px"
    >
      <span>Cancel</span>
    </button>
    <button
      type="button"
      class="ant-btn ant-btn-primary"
      nzType="primary"
      *ngIf="!data.ID"
      nz-button
      nz-dropdown
      [nzDropdownMenu]="menu"
      [nzLoading]="isSpinning"
      (click)="save(false, websitebannerPage)"
    >
      <span>Save & Close</span>
    </button>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li
          nz-menu-item
          class="menuc"
          style="border-radius: 4px; background-color: #40a9ff"
        >
          <a style="color: white" (click)="save(true, AddNewRecord)"
            >Save & New</a
          >
        </li>
      </ul>
    </nz-dropdown-menu>
    <button
      type="button"
      class="ant-btn ant-btn-primary"
      nz-button
      [nzLoading]="isSpinning"
      *ngIf="data.ID"
      (click)="save(false, AddNewRecord)"
    >
      <span>Update & Close</span>
    </button>
  </div>
  <div class="header">
    <button
      type="button"
      nz-button
      (click)="close(AddNewRecord)"
      class="ant-btn"
      style="margin-right: 8px"
    >
      <i nz-icon nzType="close" nzTheme="outline"></i>
    </button>
  </div>
</div>
