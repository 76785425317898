import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import * as html2pdf from 'html2pdf.js';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { TranferapplicationMaster } from 'src/app/Models/transferapplication';
import { ApiService } from 'src/app/Service/api.service';
import { ToWords } from 'to-words';
import { AngularEditorConfig } from '@kolkov/angular-editor';

const toWords = new ToWords({
  localeCode: 'en-IN',
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    // currencyOptions: {
    //   // can be used to override defaults for the selected locale
    //   name: 'Rupee',
    //   plural: 'Rupees',
    //   symbol: '₹',
    //   fractionalUnit: {
    //     name: 'Paisa',
    //     plural: 'Paise',
    //     symbol: '',
    //   },
    // },
  },
});
@Component({
  selector: 'app-order-form',
  templateUrl: './order-form.component.html',
  styleUrls: ['./order-form.component.css'],
})
export class OrderFormComponent implements OnInit {
  @Input() drawerClose;
  @Input() transferallounceorder;
  @Input() tranferchnagesorder1;
  @Input() journeydetails;
  @Input() journeyfairfaird;
  @Input() raiamount;
  @Input() amount;
  @Input() railorder;
  @Input() accommodation;
  @Input() totacountofbill;
  @Input() istransport;
  @Input() drawerClaimData: TranferapplicationMaster;
  @Input() netAmountInWord;
  @Input() TravelModeNames;
  @Input() showPrivate;
  @Input() multiplication;
  @Input() fairpaidofpartb;
  @Input() weightrate;
  @Input() distance;
  @Input() amountt;
  @Input() totalofweight;
  @Input() grosspaymentvalue;
  @Input() nettotacountofbill;
  @Input() transferJourneyClaimedAmount;
  @Input() inspectorDestination;
  loadingRecords: boolean = false;

  editorConfigPara: AngularEditorConfig = {
    editable: true,
    height: '50',
    minHeight: '50',
    maxHeight: '50',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: false,
    showToolbar: false,
    placeholder: 'Enter Remark...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
  };
  constructor(
    private message: NzNotificationService,
    private api: ApiService,
    private datepipe: DatePipe,
    public cookie: CookieService
  ) {}
  isVisible = false;
  drawerData: TranferapplicationMaster = new TranferapplicationMaster();
  ngOnInit(): void {
    this.fileNumberList();
  }

  orderBtn() {
    this.isVisible = true;
  }

  close(): void {
    this.drawerClose();
  }

  download() {}
  omit(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  total = 0;
  gettotal() {
    if (
      this.drawerClaimData.CTG_TOTAL != undefined &&
      this.drawerClaimData.PERSONAL_EFFECT_TOTAL != undefined &&
      this.drawerClaimData.ROAD_MILEAGE_TOTAL != undefined &&
      this.drawerClaimData.JOURNEY_TOTAL != undefined &&
      this.drawerClaimData.PRIVATE_CONVEYANCE_TOTAL != undefined
    ) {
      this.total =
        Number(this.drawerClaimData.CTG_TOTAL) +
        Number(this.drawerClaimData.PERSONAL_EFFECT_TOTAL) +
        Number(this.drawerClaimData.ROAD_MILEAGE_TOTAL) +
        Number(this.transferJourneyClaimedAmount) +
        // Number(this.drawerClaimData.JOURNEY_TOTAL) +
        Number(this.drawerClaimData.PRIVATE_CONVEYANCE_TOTAL);
    }

    return this.total;
  }
  nettotal = 0;
  gettotall() {
    this.drawerClaimData.ORDER_FORM_NET_TOTAL = 0;
    if (this.drawerClaimData.ADVANCED_AMOUNT != undefined) {
      this.drawerClaimData.ORDER_FORM_NET_TOTAL =
        this.total - this.drawerClaimData.ADVANCED_AMOUNT;
    }
    return this.drawerClaimData.ORDER_FORM_NET_TOTAL;
  }
  getwords() {
    this.netAmountInWord = toWords.convert(
      Number(this.drawerClaimData.ORDER_FORM_NET_TOTAL),
      {
        currency: true,
      }
    );

    return this.netAmountInWord;
  }

  ordertotal: any;
  advanceamount: any;
  calculation(event: any) {
    if (
      this.drawerClaimData.ORDER_AIR_FARE != null &&
      this.drawerClaimData.ORDER_PERSONAL_EFFECT != undefined
    ) {
      this.drawerClaimData.ORDER_FORM_TOTAL =
        Number(event) +
        Number(this.drawerClaimData.ORDER_AIR_FARE) +
        Number(this.drawerClaimData.ORDER_PERSONAL_EFFECT);
    }

    if (this.drawerClaimData.IS_ADVANCE_TAKEN == true) {
      this.drawerClaimData.ORDER_FORM_NET_TOTAL =
        this.drawerClaimData.ORDER_FORM_TOTAL -
        Number(this.drawerClaimData.ADVANCED_AMOUNT);
    } else {
      this.drawerClaimData.ORDER_FORM_NET_TOTAL =
        Number(event) +
        Number(this.drawerClaimData.ORDER_AIR_FARE) +
        Number(this.drawerClaimData.ORDER_PERSONAL_EFFECT);
    }
  }

  calculation1(event: any) {
    if (
      this.drawerClaimData.ORDER_CTG != null &&
      this.drawerClaimData.ORDER_PERSONAL_EFFECT != undefined
    ) {
      this.drawerClaimData.ORDER_FORM_TOTAL =
        Number(this.drawerClaimData.ORDER_CTG) +
        Number(event) +
        Number(this.drawerClaimData.ORDER_PERSONAL_EFFECT);
    }

    if (this.drawerClaimData.IS_ADVANCE_TAKEN == true) {
      this.drawerClaimData.ORDER_FORM_NET_TOTAL =
        this.drawerClaimData.ORDER_FORM_TOTAL -
        Number(this.drawerClaimData.ADVANCED_AMOUNT);
    } else {
      this.drawerClaimData.ORDER_FORM_NET_TOTAL =
        Number(this.drawerClaimData.ORDER_CTG) +
        Number(event) +
        Number(this.drawerClaimData.ORDER_PERSONAL_EFFECT);
    }
  }

  calculation2(event: any) {
    if (
      this.drawerClaimData.ORDER_CTG != null &&
      this.drawerClaimData.ORDER_AIR_FARE != undefined
    ) {
      this.drawerClaimData.ORDER_FORM_TOTAL =
        Number(this.drawerClaimData.ORDER_CTG) +
        Number(this.drawerClaimData.ORDER_AIR_FARE) +
        Number(event);
    }

    if (this.drawerClaimData.IS_ADVANCE_TAKEN == true) {
      this.drawerClaimData.ORDER_FORM_NET_TOTAL =
        this.drawerClaimData.ORDER_FORM_TOTAL -
        Number(this.drawerClaimData.ADVANCED_AMOUNT);
    } else {
      this.drawerClaimData.ORDER_FORM_NET_TOTAL =
        Number(this.drawerClaimData.ORDER_CTG) +
        Number(this.drawerClaimData.ORDER_AIR_FARE) +
        Number(event);
    }
  }

  print() {
    this.api
      .gettransferdata(0, 0, '', '', ' AND ID = ' + this.drawerClaimData.ID)
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            this.loadingRecords = false;
            this.drawerClaimData = data['data'][0];
            this.printOrderModalVisible = true;
          } else {
            // this.message.error("Can't Load Data", '');
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }
  printOrderModalVisible = false;
  showmodal() {
    // this.advanceamount = this.drawerClaimData.ORDER_FORM_TOTAL;
    if (this.drawerClaimData.ORDER_FORM_DATE == undefined) {
      this.drawerClaimData.ORDER_FORM_DATE = null;
    } else {
      this.drawerClaimData.ORDER_FORM_DATE = this.datepipe.transform(
        this.drawerClaimData.ORDER_FORM_DATE,
        'yyyy-MM-dd'
      );
    }
    if (this.drawerClaimData.IS_EDITABLE_DESTINATION == false) {
      this.drawerClaimData.DESTINATION_REMARK = null;
    } else {
      this.drawerClaimData.DESTINATION_REMARK =
        this.drawerClaimData.DESTINATION_REMARK;
    }
    this.api.updatetransfer(this.drawerClaimData).subscribe((successCode) => {
      if (successCode.code == '200') {
        this.loadingRecords = false;

        this.message.success('Information Saved Successfully...', '');
        this.printOrderModalVisible = true;
      } else {
        this.message.error('Information Has Not Saved...', '');
        this.loadingRecords = false;
      }
    });
  }
  getwidth() {
    if (window.innerWidth <= 400) {
      return 400;
    } else {
      return 850;
    }
  }
  printOrderModalCancel() {
    this.printOrderModalVisible = false;
  }
  openpdf() {
    const element = document.getElementById('excel-table');
    const opt = {
      margin: 0.2,
      filename: 'Dated.pdf',
      image: { type: 'jpeg', quality: 7 },
      html2canvas: { scale: 7 },
      jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
    };
    html2pdf().from(element).set(opt).save();
  }
  fileList: any = [];
  fileNumberList() {
    this.api
      .getFileMaster(
        0,
        0,
        'ID',
        'ASC',
        ' AND STATUS = 1 AND HIRARCHY_ID in (9,10)',
        sessionStorage.getItem('userId')
      )
      .subscribe(
        (data: any) => {
          if (data['code'] == 200 && data['count'] > 0) {
            this.fileList = data['data'];
          } else {
            this.fileList = [];
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }
  // index = 0;
  // getCount() {
  //   this.index += 1;
  //   return this.index;
  // }

  destinationRemarkNull(event) {
    if (event == false) {
      this.drawerClaimData.DESTINATION_REMARK = null;
    }
  }
}
