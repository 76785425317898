export class checkListltc1 {
  LTC_ID: any;
  JOURNEY_DETAILS_ONE: any = 0;
  JOURNEY_DETAILS_TWO: any = 0;
  JOURNEY_DETAILS_THREE: any = 0;
  JOURNEY_DETAILS_FOUR: any = 0;
  JOURNEY_DETAILS_FIVE: any = 0;
  JOURNEY_DETAILS_SIX: any = 0;
  JOURNEY_DETAILS_SEVEN: any = 0;
  JOURNEY_DETAILS_EIGHT: any = 0;
  JOURNEY_DETAILS_FIVE_REMARK1: any = '';
  JOURNEY_DETAILS_FIVE_REMARK2: any = '';
  JOURNEY_DETAILS_FIVE_REMARK3: any = '';
}
