export class ClaimMaster {
  ID: number;
  // EMP_ID:any;
  // PATIENT_NAME:any;
  // RELATION_WITH_PATIENT:any;
  // NATURE_OF_TREATMENT:any;
  // TREATEMENT_START_DATE    :any;
  // TREATEMENT_END_DATE    :any;
  // IS_ADVANCE_TAKEN:any;
  // ADVANCE_AMOUNT:any;
  ADVANCE_TAKEN_DATE: any;
  // INSPECTOR_ID:any;
  // CURRENT_STAGE_ID:any;
  // NAME:any;

  HOSPITAL_TYPE: string = '';
  // HOSPITAL_ID:any
  HOSPITAL_NAME: any;
  HOSPITAL_ADDRESS: any;
  // BILL_FILLING_DATE:any
  BANK_NAME: any;
  SB_ACC_NO: any;
  IFSC_CODE: any;
  MICR_CODE: any;
  WARD_ENTITLEMENT: string = '';
  TREATEMENT_TYPE: string = '';

  EMP_ID: any;
  GRADE_PAY: any;
  OFFICE_NAME: any;
  DESIGNATION: any;
  LOCATION: any;
  // DDO_OF_THE_OFFICIAL:any
  PATIENT_NAME: any;
  RELATION_WITH_PATIENT: any;
  BENEFICIARY_TYPE: any;
  CGHS_CARD_NO: any;
  IS_EMERGENCY_TREATMENT_APPLIED: any;
  EMERGENCY_REF_DATE: any;
  HOSPITAL_ID: any;
  IS_PRIVATE_HOSPITAL: any;
  IS_PERMISSION_TAKEN: any;
  NATURE_OF_TREATMENT: any;
  TREATMENT_START_DATE: any;
  TREATMENT_END_DATE: any;
  IS_FORWARDING_LETTER: any;
  IS_MEDICAL_REIMBURSEMENT_CLAIMED: any;
  IS_COPY_OF_CGHS_CARD_FOR_BOTH_BENEFICIARY_AND_PATIENT: any;
  IS_DISCHARGE_CARD: any;
  FORM_NO_3_IN_CASE_THE_MEDICAL_CLAIM: any;
  IS_FINAL_BILL_IN_ORIGINAL_ALONG_WITH_ONE_XEROX_ONE_COPY: any;
  IS_PAY_SLIP_OF_THE_PERIOD_OF_TRATMENT: any;
  IS_BANK_MANDATE_FROM_ALONG_WITH_CANCELLED_CHEQUE: any;
  IS_PRESCRIPTION_OF_MEDICINES_PURCHASED_FROM_OUTSIDE: any;
  // STAGE_ID: any;
  BILL_FILIING_DATE: any = new Date();
  IS_BILL_FILLED_IN_TIME: any;
  AMOUNT_OF_REIMBUSMENT_CLAIMED: any;
  AMOUNT_OF_REIMBUSMENT_ADMISSIBLE: any;
  IS_ADVANCE_TAKEN: boolean = false;
  ADVANCE_AMOUNT: any;
  NET_AMOUNT_PAYABLE: any;
  CURRENT_STAGE_ID: any;
  INSPECTOR_ID: any;
  REMARK: any;
  SUB_STAGE: any;
  WARD_TYPE: any;
  BANK_ACCOUNT_NO: any;
  CLAIM_ACCREDITATION: string;
  PATIENT_CGHS_BENEFICIERY_NO: any;

  ADDRESS: any;
  EMAIL_ID: any;
  STATUS: boolean = true;
  MOBILE_NO: any;
  CGHS_CARD_VALIDITY: any;
  DDO_OF_THE_OFFICIAL: any;
  EMPLOYEE_CODE: any;
  EMPLOYEE_NAME: any;
  PAY_BILL: string = 'NonGazPay';
  hospitalData = [];
  DELAY_CONDELENCE_DIFFERENCE: number = 0;
  SIGNATURE_ID: number = 0;
  ADVANCE_ADMISSIBLE_AMOUNT: number = 0;
  BILL_NO: any;
  ZAO_HN:any;
  ZAO:any;
  CHEQUE_DRAWN:any=0;
}
