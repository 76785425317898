import { Component, Input, OnInit } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as html2pdf from 'html2pdf.js';
import { DatePipe } from '@angular/common';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from 'src/app/Service/api.service';
@Component({
  selector: 'app-placealongside',
  templateUrl: './placealongside.component.html',
  styleUrls: ['./placealongside.component.css'],
})
export class PlacealongsideComponent implements OnInit {
  @Input() drawerClose;
  @Input() Railtotal: any;
  @Input() ShipBoattotal: any;
  @Input() Roadtotal: any;
  @Input() airtotal: any;
  @Input() alltotal: any;
  @Input() advanceamountta: any;
  @Input() placeorderdata: any;
  @Input() nettotalamountplaceorder: any;
  @Input() accototal: any;
  @Input() foodtotal: any;
  @Input() detailsandpurpose: any;
  @Input() acctotal1: any;
  @Input() ShipBoattotal1: any;
  @Input() airtotal1: any;
  @Input() Roadtotal1: any;
  @Input() Railtotal1: any;
  @Input() foodtotal1: any;
  @Input() hotelAdmissibleAmount: any;
  @Input() accommodationAdmissibleAmount: any;
  @Input() foodAdmissibleAmount: any;
  @Input() intercityAdmissibleAmount: any;
  @Input() intercityClaimedAmount: any;
  @Input() accommodationClaimedAmount: any;
  @Input() totalOfClaimedAmount: any;
  @Input() totalOfAdmissibleAmount: any;
  constructor(
    private datePipe: DatePipe,
    private message: NzNotificationService,
    private api: ApiService
  ) {}
  fileList: any = [];
  ngOnInit(): void {
    this.api
      .getFileMaster(
        0,
        0,
        'ID',
        'ASC',
        ' AND STATUS = 1 AND HIRARCHY_ID in (6,7)',
        sessionStorage.getItem('userId')
      )
      .subscribe(
        (data: any) => {
          if (data['code'] == 200 && data['count'] > 0) {
            this.fileList = data['data'];
          } else {
            this.fileList = [];
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }
  visible = false;

  open(): void {
    this.visible = true;
  }

  omit(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  close(): void {
    this.drawerClose();
  }
  openpdf() {
    const element = document.getElementById('excel-table');
    const opt = {
      margin: 0.2,
      filename: 'Part-B.pdf',
      image: { type: 'jpeg', quality: 7 },
      html2canvas: { scale: 7 },
      jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
    };
    html2pdf().from(element).set(opt).save();
  }

  loadingRecords = false;
  printOrderModalVisible: boolean = false;
  showmodal() {
    this.loadingRecords = true;
    // this.placeorderdata.PLACE_ORDER_NET_AMOUNT = this.nettotalamountplaceorder;
    this.api.updatetour(this.placeorderdata).subscribe((successCode) => {
      if (successCode.code == '200') {
        // this.loadingRecords = false;
        // this.printModel();

        this.api
          .gettouralldata(0, 0, '', '', ' AND ID=' + this.placeorderdata.ID)
          .subscribe(
            (data) => {
              if (data.code == '200') {
                this.placeorderdata = data['data'][0];
                this.loadingRecords = false;
                this.printOrderModalVisible = true;
              } else {
                this.loadingRecords = false;
              }
            },
            (err) => {
              console.log(err);
            }
          );
        this.message.success('Information Saved Successfully...', '');
      } else {
        this.loadingRecords = false;
        this.message.error('Information Has Not Saved...', '');
        // this.loadingRecords = false;
      }
    });
  }
  getwidth() {
    if (window.innerWidth <= 400) {
      return 400;
    } else {
      return 850;
    }
  }

  changeamount1(event: any) {
    this.placeorderdata.PLACE_ORDER_GROSS_AMOUNT = 0;
    this.placeorderdata.PLACE_ORDER_NET_AMOUNT = 0;

    this.placeorderdata.PLACE_ORDER_GROSS_AMOUNT =
      Number(event) +
      Number(this.placeorderdata.PLACE_ORDER_ROAD_ADMISSIBLE_AMOUNT) +
      Number(this.placeorderdata.PLACE_ORDER_SHIP_ADMISSIBLE_AMOUNT) +
      Number(this.placeorderdata.PLACE_ORDER_RAIL_ADMISSIBLE_AMOUNT) +
      this.acctotal1 +
      Number(this.placeorderdata.FOOD_ADMISIBLE_AMOUNT);

    if (
      this.advanceamountta == undefined ||
      this.advanceamountta == null ||
      this.advanceamountta == '' ||
      this.advanceamountta == "'NaN'"
    ) {
      this.advanceamountta = 0;
    }

    this.placeorderdata.PLACE_ORDER_NET_AMOUNT =
      Number(this.placeorderdata.PLACE_ORDER_GROSS_AMOUNT) -
      Number(this.advanceamountta);
  }
  changeamount2(event: any) {
    this.placeorderdata.PLACE_ORDER_GROSS_AMOUNT = 0;
    this.placeorderdata.PLACE_ORDER_NET_AMOUNT = 0;

    this.placeorderdata.PLACE_ORDER_GROSS_AMOUNT =
      Number(this.placeorderdata.PLACE_ORDER_AIR_ADMISSIBLE_AMOUNT) +
      Number(this.placeorderdata.PLACE_ORDER_ROAD_ADMISSIBLE_AMOUNT) +
      Number(event) +
      Number(this.placeorderdata.PLACE_ORDER_RAIL_ADMISSIBLE_AMOUNT) +
      this.acctotal1 +
      Number(this.placeorderdata.FOOD_ADMISIBLE_AMOUNT);

    if (
      this.advanceamountta == undefined ||
      this.advanceamountta == null ||
      this.advanceamountta == '' ||
      this.advanceamountta == "'NaN'"
    ) {
      this.advanceamountta = 0;
    }

    this.placeorderdata.PLACE_ORDER_NET_AMOUNT =
      Number(this.placeorderdata.PLACE_ORDER_GROSS_AMOUNT) -
      Number(this.advanceamountta);
  }
  changeamount3(event: any) {
    this.placeorderdata.PLACE_ORDER_GROSS_AMOUNT = 0;
    this.placeorderdata.PLACE_ORDER_NET_AMOUNT = 0;

    this.placeorderdata.PLACE_ORDER_GROSS_AMOUNT =
      Number(this.placeorderdata.PLACE_ORDER_AIR_ADMISSIBLE_AMOUNT) +
      Number(event) +
      Number(this.placeorderdata.PLACE_ORDER_SHIP_ADMISSIBLE_AMOUNT) +
      Number(this.placeorderdata.PLACE_ORDER_RAIL_ADMISSIBLE_AMOUNT) +
      this.acctotal1 +
      Number(this.placeorderdata.FOOD_ADMISIBLE_AMOUNT);

    if (
      this.advanceamountta == undefined ||
      this.advanceamountta == null ||
      this.advanceamountta == '' ||
      this.advanceamountta == "'NaN'"
    ) {
      this.advanceamountta = 0;
    }

    this.placeorderdata.PLACE_ORDER_NET_AMOUNT =
      Number(this.placeorderdata.PLACE_ORDER_GROSS_AMOUNT) -
      Number(this.advanceamountta);
  }
  changeamount5(event: any) {
    this.placeorderdata.PLACE_ORDER_GROSS_AMOUNT = 0;
    this.placeorderdata.PLACE_ORDER_NET_AMOUNT = 0;

    this.placeorderdata.PLACE_ORDER_GROSS_AMOUNT =
      Number(this.placeorderdata.PLACE_ORDER_AIR_ADMISSIBLE_AMOUNT) +
      Number(this.placeorderdata.PLACE_ORDER_ROAD_ADMISSIBLE_AMOUNT) +
      Number(this.placeorderdata.PLACE_ORDER_SHIP_ADMISSIBLE_AMOUNT) +
      Number(this.placeorderdata.PLACE_ORDER_RAIL_ADMISSIBLE_AMOUNT) +
      Number(event) +
      Number(this.placeorderdata.FOOD_ADMISIBLE_AMOUNT);

    if (
      this.advanceamountta == undefined ||
      this.advanceamountta == null ||
      this.advanceamountta == '' ||
      this.advanceamountta == "'NaN'"
    ) {
      this.advanceamountta = 0;
    }

    this.placeorderdata.PLACE_ORDER_NET_AMOUNT =
      Number(this.placeorderdata.PLACE_ORDER_GROSS_AMOUNT) -
      Number(this.advanceamountta);
  }
  changeamount4(event: any) {
    this.placeorderdata.PLACE_ORDER_GROSS_AMOUNT = 0;
    this.placeorderdata.PLACE_ORDER_NET_AMOUNT = 0;

    this.placeorderdata.PLACE_ORDER_GROSS_AMOUNT =
      Number(this.placeorderdata.PLACE_ORDER_AIR_ADMISSIBLE_AMOUNT) +
      Number(this.placeorderdata.PLACE_ORDER_ROAD_ADMISSIBLE_AMOUNT) +
      Number(this.placeorderdata.PLACE_ORDER_SHIP_ADMISSIBLE_AMOUNT) +
      Number(event) +
      this.acctotal1;

    if (
      this.advanceamountta == undefined ||
      this.advanceamountta == null ||
      this.advanceamountta == '' ||
      this.advanceamountta == "'NaN'"
    ) {
      this.advanceamountta = 0;
    }

    // this.placeorderdata.PLACE_ORDER_NET_AMOUNT =
    //   Number(this.placeorderdata.PLACE_ORDER_GROSS_AMOUNT) -
    //   Number(this.advanceamountta);
    this.placeorderdata.PLACE_ORDER_NET_AMOUNT =
      Number(this.placeorderdata.PLACE_ORDER_GROSS_AMOUNT) -
      Number(this.advanceamountta);
  }

  changeamount6(event: any) {
    this.placeorderdata.PLACE_ORDER_GROSS_AMOUNT = 0;
    this.placeorderdata.PLACE_ORDER_NET_AMOUNT = 0;

    this.placeorderdata.PLACE_ORDER_GROSS_AMOUNT =
      Number(this.placeorderdata.PLACE_ORDER_AIR_ADMISSIBLE_AMOUNT) +
      Number(this.placeorderdata.PLACE_ORDER_ROAD_ADMISSIBLE_AMOUNT) +
      Number(this.placeorderdata.PLACE_ORDER_SHIP_ADMISSIBLE_AMOUNT) +
      Number(event) +
      this.acctotal1 +
      Number(this.placeorderdata.PLACE_ORDER_RAIL_ADMISSIBLE_AMOUNT);

    if (
      this.advanceamountta == undefined ||
      this.advanceamountta == null ||
      this.advanceamountta == '' ||
      this.advanceamountta == "'NaN'"
    ) {
      this.advanceamountta = 0;
    }

    // this.placeorderdata.PLACE_ORDER_NET_AMOUNT =
    //   Number(this.placeorderdata.PLACE_ORDER_GROSS_AMOUNT) -
    //   Number(this.advanceamountta);
    this.placeorderdata.PLACE_ORDER_NET_AMOUNT =
      Number(this.placeorderdata.PLACE_ORDER_GROSS_AMOUNT) -
      Number(this.advanceamountta);
  }

  printOrderModalCancel() {
    this.printOrderModalVisible = false;
  }

  // DRAWER2

  visible2 = false;

  open2(): void {
    this.visible2 = true;
  }

  close2(): void {
    this.visible2 = false;
  }

  openpdf2() {
    const element = document.getElementById('excel-table2');
    const opt = {
      margin: 0.2,
      filename: 'Travel-Allowance.pdf',
      image: { type: 'jpeg', quality: 7 },
      html2canvas: { scale: 7 },
      jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
    };
    html2pdf().from(element).set(opt).save();
  }
}
