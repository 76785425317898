<div *ngIf="visiblee == false && visiblee1 == false && fogotdata == true">
  <div nz-row *ngIf="!isLogedIn">
    <div class="limiter">
      <div class="container-login100" style="background-color: white">
        <div class="wrap-login100">
          <img src=".\assets\aykarlogo.png" class="image" />
          <h4 style="text-align: center">Mumbai</h4>
          <h4
            style="
              color: #208aeb;
              font-weight: bold;
              text-transform: uppercase;
              text-align: center;
            "
          >
            Claim Management System
          </h4>
          <h4 style="text-align: center; color: rgb(134, 132, 132)">
            <b> (Medical/LTC/TA on Tour/Transfer) </b>
          </h4>
          <br />

          <form nz-form class="login-form">
            <nz-form-item>
              <nz-form-control
                nzRequired
                nzErrorTip="Please Enter Valid Email/Mobile Number"
              >
                <nz-input-group nzPrefixIcon="user" style="margin-left: 35px">
                  <input
                    type="email"
                    name="email_id"
                    autocomplete="off"
                    [(ngModel)]="EMAIL_ID"
                    nz-input
                    placeholder="Email/Mobile Number"
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-control nzRequired nzErrorTip="Input is required">
                <nz-input-group
                  nzPrefixIcon="lock"
                  [nzSuffix]="suffixTemplate"
                  style="margin-left: 35px"
                >
                  <input
                    [type]="passwordVisible ? 'text' : 'password'"
                    name="password"
                    autocomplete="off"
                    [(ngModel)]="PASSWORD"
                    nz-input
                    placeholder="Password"
                    required
                  />
                </nz-input-group>
                <ng-template #suffixTemplate>
                  <i
                    nz-icon
                    [nzType]="passwordVisible ? 'eye' : 'eye-invisible'"
                    (click)="passwordVisible = !passwordVisible"
                  ></i>
                </ng-template>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-control>
                <a class="login-form-forgot" (click)="showModal()"
                  >Forgot Password?</a
                ><br />
                <!-- <div class="container-login100-form-btn">
                  <div class="wrap-login100-form-btn">
                    <div class="login100-form-bgbtn"></div>
                    <button nz-button class="login100-form-btn" (click)="login()" [nzLoading]="isloginSpinning"
                      [nzType]="'primary'">Log in</button>
                  </div>
                </div> -->
                <button
                  nz-button
                  (click)="login()"
                  [nzLoading]="isloginSpinning"
                  [nzType]="'primary'"
                  style="width: 75%; border-radius: 20px; margin: 15px 0 0 75px"
                >
                  Log in
                </button>
              </nz-form-control>
            </nz-form-item>
          </form>
          <div style="text-align: center">
            New User? <a (click)="hideandshow()">Register Here</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  nz-row
  *ngIf="visiblee == true && visiblee1 == false && fogotdata == false"
>
  <div class="limiter">
    <div class="container-login100" style="background-color: white">
      <div class="wrap-login1000">
        <img src=".\assets\aykarlogo.png" class="image" />
        <h4 style="text-align: center">Mumbai</h4>
        <h4
          style="
            color: #208aeb;
            font-weight: bold;
            text-transform: uppercase;
            text-align: center;
          "
        >
          Claim Management System
        </h4>
        <h4 style="text-align: center; color: rgb(134, 132, 132)">
          <b> (Medical/LTC/TA on Tour/Transfer) </b>
        </h4>
        <br />
        <form nz-form class="login-form">
          <div *ngIf="!sendotpemplyee && !employeemobileverified">
            <h4 style="text-align: center; margin-left: 15%">
              Enter Your Registered Mobile Number
            </h4>
            <nz-form-item class="ant-form-item">
              <nz-form-control nzRequired>
                <nz-input-group>
                  <input
                    nz-input
                    autocomplete="off"
                    (keypress)="omit($event)"
                    style="text-align: center; margin-left: 17%; width: 81%"
                    placeholder="Mobile Number"
                    maxlength="10"
                    required
                    name="mobile"
                    [(ngModel)]="MOBILE_NO"
                    class="form-control"
                    x-webkit-speech="x-webkit-speech"
                    required
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div *ngIf="!sendotpemplyee && !employeemobileverified">
            <nz-form-item>
              <nz-form-control>
                <button
                  nz-button
                  [nzType]="'primary'"
                  (click)="check1()"
                  [nzLoading]="isloginSpinning"
                  style="width: 75%; border-radius: 20px; margin-left: 60px"
                >
                  SEND OTP
                </button>
              </nz-form-control>
            </nz-form-item>
          </div>
          <!-- <nz-form-item>
            <nz-form-control>
              <div class="container-login100-form-btn" *ngIf="!sendotpemplyee && !employeemobileverified">
                <div nz-row class="wrap-login100-form-btn">
                  <div nz-col nzSpan="24" class="login100-form-bgbtn"></div>
                  <button nz-button class="login100-form-btn" style=" font-family: Poppins-Medium;" (click)="check1()"
                    [nzLoading]="isloginSpinning" [nzType]="'primary'"><span style=" font-family: Poppins-Medium;">Send
                      Otp</span></button>
                </div>
              </div>
            </nz-form-control>
          </nz-form-item> -->
        </form>

        <a
          style="text-align: center; margin-left: 160px"
          *ngIf="!sendotpemplyee && !employeemobileverified"
          (click)="loginpage()"
          >Cancel</a
        >

        <div
          *ngIf="sendotpemplyee && !employeemobileverified && !employeepassword"
          style="margin-top: 25px"
        >
          <nz-form-item>
            <nz-form-control>
              <input
                nz-input
                name="demo1name"
                style="width: 75%; margin-left: 13%; text-align: center"
                autocomplete="off"
                required
                (keypress)="omit($event)"
                placeholder="Enter OTP"
                (onInputChange)="onOtpChange($event)"
                [(ngModel)]="OTP"
                maxlength="6"
                required
              />
              <h4 style="font-size: 12px; margin-top: 20px; text-align: center">
                Didn't recive OTP?
                <a
                  *ngIf="resendTrue && !showTimer"
                  (click)="resendOTP()"
                  style="color: #131952; font-weight: bold; cursor: pointer"
                >
                  Resend OTP
                </a>
                <a
                  *ngIf="resendTrue && showTimer"
                  style="color: #2b2c33; font-weight: bold"
                >
                  Resend OTP({{ 180 - timerMobile }})s</a
                >
              </h4>
            </nz-form-control>
          </nz-form-item>
        </div>
        <!-- <div class="container-login100-form-btn" *ngIf="sendotpemplyee && !employeemobileverified && !employeepassword ">
            <div class="wrap-login100-form-btn">
              <div class="login100-form-bgbtn"></div>
              <button nz-button class="login100-form-btn" style=" font-family: Poppins-Medium;" (click)="varify1()"
                [nzLoading]="isloginSpinning" [nzType]="'primary'">Verify Otp</button>
            </div>
          </div> -->
        <div
          *ngIf="sendotpemplyee && !employeemobileverified && !employeepassword"
        >
          <nz-form-item>
            <nz-form-control>
              <button
                nz-button
                [nzType]="'primary'"
                (click)="varify1()"
                [nzLoading]="isloginSpinning"
                style="width: 50%; border-radius: 20px; margin-left: 95px"
              >
                VERIFY OTP
              </button>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div *ngIf="employeepassword">
          <h4 style="text-align: center">Enter Password</h4>
          <nz-form-item>
            <nz-form-control nzRequired>
              <nz-input-group [nzSuffix]="suffixTemplate1">
                <input
                  [type]="passwordVisible1 ? 'text' : 'password'"
                  style="text-align: center"
                  name="password"
                  [(ngModel)]="NEW_PASSWORD"
                  nz-input
                  placeholder="Enter your New password"
                  required
                />
              </nz-input-group>
              <ng-template #suffixTemplate1>
                <i
                  nz-icon
                  [nzType]="passwordVisible1 ? 'eye' : 'eye-invisible'"
                  (click)="passwordVisible1 = !passwordVisible1"
                ></i>
              </ng-template>
            </nz-form-control>
          </nz-form-item>

          <h4 style="text-align: center">Confirm Password</h4>
          <nz-form-item>
            <nz-form-control nzRequired>
              <nz-input-group [nzSuffix]="suffixTemplate">
                <input
                  [type]="passwordVisible ? 'text' : 'password'"
                  name="confirmpassword"
                  style="text-align: center"
                  [(ngModel)]="CONFPASSWORD"
                  nz-input
                  placeholder="Enter Confirm Password"
                  required
                />
              </nz-input-group>
              <ng-template #suffixTemplate>
                <i
                  nz-icon
                  [nzType]="passwordVisible ? 'eye' : 'eye-invisible'"
                  (click)="passwordVisible = !passwordVisible"
                ></i>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-row>
          <div nz-col nzSpan="6"></div>
          <div nz-col nzSpan="12">
            <div class="container-login100-form-btn2" *ngIf="employeepassword">
              <div class="wrap-login100-form-btn2">
                <div class="login100-form-bgbtn"></div>
                <button
                  nz-button
                  class="login100-form-btn"
                  style="font-family: Poppins-Medium"
                  (click)="confpass()"
                  [nzLoading]="isloginSpinning"
                  [nzType]="'primary'"
                >
                  Confirm Password
                </button>
              </div>
            </div>
          </div>
          <div nz-col nzSpan="6"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="visiblee == false && visiblee1 == true && fogotdata == false">
  <div nz-row>
    <div class="limiter">
      <div class="container-login100" style="background-color: white">
        <div class="wrap-login100">
          <div nz-row>
            <div nz-col nzSpan="24">
              <img src=".\assets\aykarlogo.png" class="image" />
              <h4 style="text-align: center">Mumbai</h4>
              <h4
                style="
                  color: #208aeb;
                  font-weight: bold;
                  text-transform: uppercase;
                  text-align: center;
                "
              >
                Claim Management System
              </h4>
              <h4 style="text-align: center; color: rgb(134, 132, 132)">
                <b> (Medical/LTC/TA on Tour/Transfer) </b>
              </h4>

              <h3
                style="text-align: center; color: #208aeb; font-weight: bold"
                *ngIf="!sendOtp && !mobileverified"
              >
                Registration
              </h3>
              <h4
                style="text-align: center; color: #208aeb; font-weight: bold"
                *ngIf="sendOtp && !mobileverified && !password"
              >
                Please Enter OTP received on Whatsapp
              </h4>
            </div>
          </div>
          <div nz-row>
            <form nz-form>
              <!-- <form nz-form class="login-form"> -->
              <div nz-row *ngIf="!sendOtp && !mobileverified">
                <div nz-col nzSpan="24">
                  <h4 style="text-align: center">Enter Your Mobile Number</h4>
                </div>

                <!-- <div nz-row> -->
                <div nz-col nzSpan="3"></div>
                <div nz-col nzSpan="18">
                  <nz-form-item>
                    <nz-form-control nzRequired>
                      <nz-input-group>
                        <input
                          nz-input
                          autocomplete="off"
                          (keypress)="omit($event)"
                          style="text-align: center"
                          placeholder="Mobile Number"
                          maxlength="10"
                          required
                          name="mobile"
                          [(ngModel)]="MOBILE_NO"
                          x-webkit-speech="x-webkit-speech"
                          required
                        />
                      </nz-input-group>
                      <h5 style="text-align: center">
                        Preferly Share Your Whatsapp Number
                      </h5>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col nzSpan="3"></div>
                <!-- </div> -->
                <!-- <div class="container-login100-form-btn">
                <div class="wrap-login100-form-btn">
                  <div class="login100-form-bgbtn"></div>
                  <button nz-button class="login100-form-btn" style=" font-family: Poppins-Medium;" (click)="check()"
                    [nzLoading]="isloginSpinning" [nzType]="'primary'"><span style=" font-family: Poppins-Medium;">Send
                      Otp</span></button>
                </div>
              </div> <br> -->
                <div nz-col nzSpan="24" style="text-align: center">
                  <button
                    nz-button
                    [nzType]="'primary'"
                    (click)="check()"
                    [nzLoading]="isloginSpinning"
                    style="width: 60%; border-radius: 20px"
                  >
                    SEND OTP
                  </button>
                </div>
                <br />
                <br />
                <div nz-col nzSpan="24">
                  <a
                    style="text-align: center; margin-left: 155px"
                    href="/employeelogin"
                    >Cancel</a
                  >
                </div>
              </div>

              <div nz-row>
                <div nz-col nzSpan="3"></div>
                <div
                  nz-col
                  nzSpan="18"
                  *ngIf="sendOtp && !mobileverified && !password"
                >
                  <nz-form-item>
                    <nz-form-control>
                      <input
                        nz-input
                        name="demo1name"
                        style="text-align: center"
                        autocomplete="off"
                        required
                        (keypress)="omit($event)"
                        (onInputChange)="onOtpChange($event)"
                        [(ngModel)]="OTP"
                        maxlength="6"
                        minlength="6"
                        required
                        placeholder="Enter OTP"
                      />
                      <h4
                        style="
                          font-size: 12px;
                          margin-top: 20px;
                          margin-bottom: 10px;
                          text-align: center;
                        "
                      >
                        Did not Recieved OTP ?
                        <a
                          *ngIf="resendTrue && !showTimer"
                          style="
                            color: #131952;
                            font-weight: bold;
                            cursor: pointer;
                          "
                        >
                          Resend OTP
                        </a>
                        <a
                          *ngIf="resendTrue && showTimer"
                          style="color: #2b2c33; font-weight: bold"
                        >
                          Resend OTP({{ 180 - timerMobile }})s</a
                        >
                      </h4>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col nzSpan="3"></div>
                <!-- </div> -->
                <div nz-col nzSpan="3"></div>
                <div
                  nz-col
                  nzSpan="18"
                  style="text-align: center"
                  *ngIf="sendOtp && !mobileverified && !password"
                >
                  <div class="wrap-login100-form-btn3">
                    <!-- <div class="login100-form-bgbtn"></div> -->
                    <button
                      nz-button
                      class="login100-form-btn"
                      style="font-family: Poppins-Medium"
                      (click)="varify()"
                      [nzLoading]="isloginSpinning"
                      [nzType]="'primary'"
                    >
                      Verify Otp
                    </button>
                  </div>
                </div>
                <div nz-col nzSpan="3"></div>
              </div>

              <div *ngIf="password">
                <h3
                  style="text-align: center; color: #208aeb; font-weight: bold"
                >
                  Registration
                </h3>
                <h4><span style="color: red">*</span>Enter Name</h4>
                <nz-form-item>
                  <nz-form-control nzErrorTip="Please Enter Name">
                    <input
                      nz-input
                      name="demo1name"
                      autocomplete="off"
                      placeholder="Please Enter Name"
                      required
                      [(ngModel)]="data.NAME"
                      [pattern]="namepatt"
                      maxlength="128"
                      required
                    />
                  </nz-form-control>
                </nz-form-item>

                <h4><span style="color: red">*</span>Mobile No</h4>
                <nz-form-item>
                  <nz-form-control nzErrorTip="Please Enter Mobile No">
                    <input
                      nz-input
                      name="clientmobileno"
                      autocomplete="off"
                      [pattern]="mobpattern"
                      placeholder="Please Enter Mobile No"
                      disabled="true"
                      [(ngModel)]="MOBILE_NO"
                      maxlength="10"
                      minlength="10"
                      (keypress)="omit($event)"
                      required
                    />
                  </nz-form-control>
                </nz-form-item>

                <h4><span style="color: red"></span>Email Id</h4>
                <nz-form-item>
                  <nz-form-control nzErrorTip="Please Enter Email Id">
                    <input
                      nz-input
                      name="clientemail"
                      autocomplete="off"
                      [pattern]="emailpattern"
                      placeholder="Please Enter Email Id"
                      [(ngModel)]="data.EMAIL_ID"
                      required
                    />
                  </nz-form-control>
                </nz-form-item>

                <h4><span style="color: red">*</span>Enter Password</h4>
                <nz-form-item>
                  <nz-form-control nzRequired>
                    <nz-input-group [nzSuffix]="suffixTemplate1">
                      <input
                        [type]="passwordVisible1 ? 'text' : 'password'"
                        autocomplete="off"
                        name="pasuuuuuusword"
                        [(ngModel)]="data.PASSWORD"
                        nz-input
                        maxlength="20"
                        minlength="8"
                        placeholder="Enter your New password"
                        required
                      />
                    </nz-input-group>
                    <ng-template #suffixTemplate1>
                      <i
                        nz-icon
                        [nzType]="passwordVisible1 ? 'eye' : 'eye-invisible'"
                        (click)="passwordVisible1 = !passwordVisible1"
                      ></i>
                    </ng-template>
                  </nz-form-control>
                </nz-form-item>

                <h4><span style="color: red">*</span>Confirm Password</h4>
                <nz-form-item>
                  <nz-form-control nzRequired>
                    <nz-input-group [nzSuffix]="suffixTemplate">
                      <input
                        [type]="passwordVisible ? 'text' : 'password'"
                        autocomplete="off"
                        name="pas66666sword"
                        [(ngModel)]="CONF_PASSWORD"
                        nz-input
                        maxlength="20"
                        minlength="8"
                        placeholder="Enter Confirm Password"
                        required
                      />
                    </nz-input-group>
                    <ng-template #suffixTemplate>
                      <i
                        nz-icon
                        [nzType]="passwordVisible ? 'eye' : 'eye-invisible'"
                        (click)="passwordVisible = !passwordVisible"
                      ></i>
                    </ng-template>
                  </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                  <nz-form-control>
                    <div nz-row>
                      <div nz-col style="margin-left: 20px">
                        <label
                          nz-checkbox
                          [(ngModel)]="isCheckedButton"
                          name="check"
                          (ngModelChange)="registerTrue($event)"
                        >
                        </label>
                      </div>
                      <div nz-col>
                        <a class="login-form-forgot" for="flexCheckDefault"
                          >&nbsp;&nbsp; Agree terms and Conditions</a
                        >
                      </div>
                    </div>
                  </nz-form-control>
                </nz-form-item>

                <div class="container-login100-form-btn">
                  <div class="wrap-login100-form-btn">
                    <div class="login100-form-bgbtn"></div>
                    <button
                      nz-button
                      class="login100-form-btn"
                      style="font-family: Poppins-Medium"
                      (click)="Register()"
                      [nzLoading]="isloginSpinning"
                      [nzType]="'primary'"
                      [disabled]="registerButton"
                    >
                      Register Now
                    </button>
                  </div>
                </div>
                <br />

                <div class="login-form-forgot">
                  Already have an account ?
                  <a (click)="loginpage()">Login Here</a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
