import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from 'src/app/Service/api.service';
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-claimcertificate',
  templateUrl: './claimcertificate.component.html',
  styleUrls: ['./claimcertificate.component.css'],
})
export class ClaimcertificateComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() certificateData;
  @Input() HospitalMapping;
  @Input() loadingRecords: boolean = false;
  constructor(
    private datePipe: DatePipe,
    private message: NzNotificationService,
    private api: ApiService,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {}

  claimHospitalMapping = [];

  Save() {
    this.loadingRecords = true;
    this.claimHospitalMapping = [];
    for (var i = 0; this.HospitalMapping.length > i; i++) {
      var obj = Object.assign({}, this.HospitalMapping[i]);
      obj.ID = undefined;
      this.claimHospitalMapping.push(obj);
      // this.claimHospitalMapping[i]['CLAIM_ID'] = undefined;
      if (this.HospitalMapping.length == i + 1) {
        this.certificateData.hospitalData = this.claimHospitalMapping;
      }
    }
    this.certificateData['CLAIM_ID'] = this.certificateData.ID;
    this.api.updateclaimed(this.certificateData).subscribe((successCode) => {
      if (successCode.code == '200') {
        this.message.success('Information Changed Successfully...', '');
        // this.loadingRecords = false;
        this.api
          .getclaimMaster(
            1,
            1,
            '',
            '',
            ' AND ID = ' + this.certificateData.ID,
            '',
            '',
            '',
            ''
          )
          .subscribe(
            (data) => {
              if (data['code'] == 200) {
                console.log(data);
                this.loadingRecords = false;
                this.openPrintOrderModal();
              } else {
                // this.message.error("Can't Load Data", '');
                this.loadingRecords = false;
              }
            },
            (err) => {
              console.log(err);
            }
          );
      } else {
        this.message.error('Information Has Not Changed...', '');
        this.loadingRecords = false;
      }
    });
  }
  close(): void {
    this.loadingRecords = false;
    this.drawerClose();
  }

  printOrderModalVisible: boolean = false;

  openPrintOrderModal() {
    this.printOrderModalVisible = true;
  }

  printOrderModalCancel() {
    this.printOrderModalVisible = false;
  }

  getwidth() {
    if (window.innerWidth <= 400) {
      return 400;
    } else {
      return 850;
    }
  }

  // auto(event: number) {

  // }

  isfound = 0;

  fileName: string = 'HOD Order Letter';
  pdfDownload: boolean = false;

  generatePDF() {
    this.pdfDownload = true;
    const element = document.getElementById('printOrderModal');
    const opt = {
      margin: 0.2,
      filename: 'Download.pdf',
      image: { type: 'jpeg', quality: 5 },
      html2canvas: { scale: 5 },
      jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
    };
    // html2pdf().from(element).set(opt).save();
    html2pdf()
      .from(element)
      .set(opt)
      .save()
      .then(() => {
        this.pdfDownload = false;
      });
  }

  //   openpdf() {
  //     this.pdfDownload = true;

  //     const element = document.getElementById('excel-table');
  //     const opt = {
  //         margin: 0.2,
  //         filename: 'Part-A.pdf',
  //         image: { type: 'jpeg', quality: 7 },
  //         html2canvas: { scale: 7 },
  //         jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
  //     };

  //     html2pdf().from(element).set(opt).save().then(() => {
  //         this.pdfDownload = false;
  //     });
  // }
}
