export class Filemaster {
    ID: number
    HIRARCHY_ID: number;
    FILE_NAME: string;
    DISCRIPTION: string;
    FILE_NUMBER: any;
    CREATOR_ID: any
    FILE_CREATED_DATE_TIME: any
    CURRENT_POSITION_ID: any
    CURRENT_POSITION_DATETIME: any
    SENDER_ID : number
    STATUS: any
    FILE_STATUS="A"
    YEAR:any="2023-24"
    ACTION_STATUS:any
    IS_ADVANCE
    FINAL_HIRARCHY_ID
}