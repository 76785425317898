export class Joureyofparticularcity {
  ID: any;
  DATE: any;
  FROM_PLACE: any;
  TO_PLACE: any;
  FARE_PAID: any;
  EMP_ID: any;
  TOUR_ID: any;
  FARE_PAID_BY_OFFICER: any;
}
