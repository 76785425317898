<div>
  <form nz-form #SignaturePage="ngForm">
    <nz-spin [nzSpinning]="isSpinning">
      <div nz-row>
        <!-- <div nz-col nzSpan="24">
                    <div nz-row> -->
        <div nz-col nzSpan="24">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>Users</nz-form-label>
            <nz-form-control>
              <nz-select name="userid" [(ngModel)]="data.USER_ID" nzAllowClear nzShowSearch nzPlaceHolder="Choose User">
                <nz-option *ngFor="let user of UserList" [nzValue]="user.ID" [nzLabel]="user.NAME">
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="24">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>Name</nz-form-label>
            <nz-form-control nzHasFeedback nzErrorTip=" Please Enter Name ">
              <input nz-input name="postnjjame" maxlength="60" [pattern]="namepatt" [(ngModel)]="data.NAME" required />
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="24">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>Name in Hindi</nz-form-label>
            <nz-form-control nzHasFeedback nzErrorTip=" Please Enter Name in Hindi">
              <input nz-input name="postsssnjjame" maxlength="60"  [(ngModel)]="data.NAME_HN"
                required />
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="24">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>Post</nz-form-label>
            <nz-form-control nzHasFeedback nzErrorTip=" Please Enter Valid Post Name ">
              <input nz-input name="postname" maxlength="60" [pattern]="namepatt" [(ngModel)]="data.POST" required />
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="24">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>Post in Hindi</nz-form-label>
            <nz-form-control nzHasFeedback nzErrorTip=" Please Enter Post Name in Hindi">
              <input nz-input name="postsdsname" maxlength="60"  [(ngModel)]="data.POST_HN"
                required />
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="24">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon> Office Name</nz-form-label>
            <nz-form-control nzHasFeedback nzErrorTip=" Please Enter Valid Office Name ">
              <input nz-input name="officename" maxlength="60" [pattern]="namepatt" [(ngModel)]="data.OFFICE_NAME"
                required />
            </nz-form-control>
          </nz-form-item>
        </div>


        <div nz-col nzSpan="24">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon> Office Name in Hindi</nz-form-label>
            <nz-form-control nzHasFeedback nzErrorTip=" Please Enter Office Name in Hindi">
              <input nz-input name="officehhname" maxlength="60"  [(ngModel)]="data.OFFICE_NAME_HN"
                required />
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="24">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon> Section Type</nz-form-label>
            <nz-form-control>
              <nz-radio-group [(ngModel)]="data.SECTION_TYPE" name="SectionType">
                <label nz-radio nzValue="welfare">Welfare and SG</label>
                <label nz-radio nzValue="gazpay">Gaz. Pay Bill II</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="11">
          <nz-form-item style="margin-left: 5px">
            <nz-form-label nzNoColon> Status </nz-form-label>
            <nz-form-control>
              <nz-switch name="is_active" [(ngModel)]="data.STATUS"></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
        <!-- </div>
                </div> -->
      </div>
    </nz-spin>
  </form>

  <div class="footer">
    <button type="button" nz-button  (click)="close(SignaturePage)" class="ant-btn" style="margin-right: 8px">
      <span> Cancel </span>
    </button>
    <button type="button" class="ant-btn ant-btn-primary" *ngIf="!data.ID" nz-button nz-dropdown [nzDropdownMenu]="menu"
      (click)="save(false, SignaturePage)">
      <span> Save & Close </span>
    </button>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li nz-menu-item class="menuc" style="border-radius: 4px; background-color: #40a9ff">
          <a style="color: white" (click)="save(true, SignaturePage)">
            Save & New
          </a>
        </li>
      </ul>
    </nz-dropdown-menu>
    <button type="button" class="ant-btn ant-btn-primary" nz-button [nzLoading]="isSpinning" *ngIf="data.ID"
      (click)="save(false, SignaturePage)">
      <span> Update & Close </span>
    </button>
  
  </div>
  <div class="header">
    <button type="button" nz-button (click)="close(SignaturePage)" class="ant-btn"
        style="margin-right: 8px">
        <i nz-icon nzType="close" nzTheme="outline"></i>
    </button>
</div>
</div>