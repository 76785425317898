<div>
  <form nz-form #referanceExpoOrderPage="ngForm">
    <nz-spin [nzSpinning]="isSpinning">
      <div nz-row [nzGutter]="10">
        <div nz-col nzXs="8" nzSm="8" nzMd="8" nzLg="8" nzXl="8" nzSpan="8">
          <div class="my-box" style="height: 78vh; overflow-y: auto">
            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <div>
                  <!-- <nz-form-item>
                
                    <nz-radio-group
                      [(ngModel)]="data.PAY_BILL"
                      name="radiogaz"
                    >
                      <label nz-radio nzValue="NonGazPay">Welfare & S.G </label>
                      <label nz-radio nzValue="GazPay">Gaz Pay Bill</label>
                    </nz-radio-group>
                  </nz-form-item> -->

                  <div nz-col nzSpan="24">
                    <nz-form-item>
                      <nz-form-label nzRequired nzNoColon
                        >Signature</nz-form-label
                      >
                      <nz-form-control>
                        <nz-select
                          name="userid"
                          [(ngModel)]="data.SIGNATURE_ID"
                          nzAllowClear
                          nzPlaceHolder="Choose Signature"
                          (ngModelChange)="signature($event)"
                        >
                          <nz-option
                            *ngFor="let user of Signaturearray"
                            [nzValue]="user.ID"
                            [nzLabel]="user.NAME"
                          >
                          </nz-option>
                        </nz-select>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
              </div>
            </div>

            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="12"
              >
                <nz-form-item
                  style="
                    display: inline-block;
                    margin-bottom: 5px;
                    width: -webkit-fill-available;
                    padding: 0px 10px;
                  "
                >
                  <nz-form-label nzRequired nzNoColon
                    >Bill Submission Date
                  </nz-form-label>

                  <nz-form-control>
                    <nz-date-picker
                      style="width: 100%"
                      nzFormat="dd/MM/yyyy"
                      name="BILL_SUBMISSION_DATE"
                      [(ngModel)]="data.BILL_SUBMISSION_DATE"
                    >
                    </nz-date-picker>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="12"
              >
                <nz-form-item
                  style="
                    display: inline-block;
                    margin-bottom: 5px;
                    width: -webkit-fill-available;
                    padding: 0px 10px;
                  "
                >
                  <nz-form-label nzRequired nzNoColon
                    >Ex-post Facto Bill Received Date
                  </nz-form-label>

                  <nz-form-control>
                    <nz-date-picker
                      style="width: 100%"
                      nzFormat="dd/MM/yyyy"
                      name="RECEIVED_DATE"
                      [(ngModel)]="data.RECEIVED_DATE"
                    >
                    </nz-date-picker>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="12"
              >
                <nz-form-item
                  style="
                    display: inline-block;
                    margin-bottom: 5px;
                    width: -webkit-fill-available;
                    padding: 0px 10px;
                  "
                >
                  <nz-form-label nzRequired nzNoColon
                    >Ex-post Facto Letter Date
                  </nz-form-label>

                  <nz-form-control>
                    <nz-date-picker
                      style="width: 100%"
                      nzFormat="dd/MM/yyyy"
                      name="LETTER_DATE"
                      [(ngModel)]="data.LETTER_DATE"
                    >
                    </nz-date-picker>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <!-- <div nz-row>
                <div
                  nz-col
                  nzXs="24"
                  nzSm="24"
                  nzMd="24"
                  nzLg="24"
                  nzXl="24"
                  nzSpan="12"
                >
                  <nz-form-item
                    style="
                      display: inline-block;
                      margin-bottom: 5px;
                      width: -webkit-fill-available;
                      padding: 0px 10px;
                    "
                  >
                    <nz-form-label nzRequired nzNoColon
                      >Treatement Permission Start Date
                    </nz-form-label>
  
                    <nz-form-control>
                      <nz-date-picker
                        style="width: 100%"
                        nzFormat="dd/MM/yyyy"
                        name="startdate"
                        [(ngModel)]="data.TREATEMENT_PERMISSION_START_DATE"
                      >
                      </nz-date-picker>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
              <div nz-row>
                <div
                  nz-col
                  nzXs="24"
                  nzSm="24"
                  nzMd="24"
                  nzLg="24"
                  nzXl="24"
                  nzSpan="12"
                >
                  <nz-form-item
                    style="
                      display: inline-block;
                      margin-bottom: 5px;
                      width: -webkit-fill-available;
                      padding: 0px 10px;
                    "
                  >
                    <nz-form-label nzRequired nzNoColon
                      >Treatement Permission End Date
                    </nz-form-label>
  
                    <nz-form-control>
                      <nz-date-picker
                        style="width: 100%"
                        nzFormat="dd/MM/yyyy"
                        name="enddate"
                        [(ngModel)]="data.TREATEMENT_PERMISSION_END_DATE"
                      >
                      </nz-date-picker>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div> -->
            <div nz-row>
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="24"
                nzXl="24"
                nzSpan="24"
              >
                <nz-form-item
                  style="
                    display: inline-block;
                    margin-bottom: 5px;
                    width: -webkit-fill-available;
                    padding: 0px 10px;
                  "
                >
                  <nz-form-label nzRequired nzNoColon>
                    Ex-post Facto Expence amount</nz-form-label
                  >

                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Expence amount is required"
                  >
                    <input
                      style="width: 100%"
                      name="EXPENCE_AMOUNT"
                      nz-input
                      placeholder="Enter Expence amount"
                      (keypress)="omit($event)"
                      type="text"
                      [(ngModel)]="data.EXPENCE_AMOUNT"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div>
              <nz-form-item>
                <nz-form-label nzNoColon>Remark 3</nz-form-label>

                <nz-form-control>
                  <textarea
                    nz-input
                    [nzAutosize]="{ minRows: 3, maxRows: 6 }"
                    name="Remark3"
                    placeHolder="Enter Remark 3"
                    [(ngModel)]="data.EXPOST_REASON"
                    maxlength="2500"
                  ></textarea>
                </nz-form-control>
              </nz-form-item>
              <!-- <span
                *ngIf="data.CLAIM_REASON != undefined"
                style="font-size: 10px; color: red"
                >{{ CLAIM_REASON.length }}/ 2500</span
              > -->
            </div>
            <div>
              <nz-form-item>
                <nz-form-label nzNoColon>Reason</nz-form-label>

                <nz-form-control>
                  <textarea
                    nz-input
                    [nzAutosize]="{ minRows: 3, maxRows: 6 }"
                    name="claimreason"
                    placeHolder="Enter Reason"
                    [(ngModel)]="data.CLAIM_REASON"
                    maxlength="2500"
                  ></textarea>
                </nz-form-control>
              </nz-form-item>
              <!-- <span
                *ngIf="data.CLAIM_REASON != undefined"
                style="font-size: 10px; color: red"
                >{{ CLAIM_REASON.length }}/ 2500</span
              > -->
            </div>
            <div *ngFor="let data of HospitalMapping; let i = index">
              <div class="my-box">
                <div nz-row>
                  <div nz-col nzSpan="24">
                    <nz-form-item>
                      <nz-form-label nzNoColon>
                        Name of Hospital
                      </nz-form-label>

                      <nz-form-control
                        nzHasFeedback
                        nzErrorTip="Name of Hospital is required"
                      >
                        <input
                          style="width: 100%"
                          name="NAMENAME{{ i }}"
                          nz-input
                          placeholder="Enter name in english"
                          autocomplete="off"
                          type="text"
                          disabled="true"
                          [(ngModel)]="data.NAME"
                        />
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
                <!-- <div nz-row>
                    <div
                      nz-col
                      nzXs="24"
                      nzSm="24"
                      nzMd="24"
                      nzLg="24"
                      nzXl="24"
                      nzSpan="24"
                    >
                      <nz-form-item>
                        <nz-form-label nzRequired nzNoColon
                          >Name of Hospital(in hindi)</nz-form-label
                        >
    
                        <nz-form-control
                          nzHasFeedback
                          nzErrorTip="Name of Hospital(in hindi) is required"
                        >
                          <input
                            style="width: 100%"
                            nz-input
                            placeholder="Enter name of Hospital in hindi"
                            autocomplete="off"
                            name="NAME_HN{{ i }}"
                            type="text"
                            [(ngModel)]="data.NAME_HN"
                          />
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                  </div> -->

                <div nz-row>
                  <div
                    nz-col
                    nzXs="24"
                    nzSm="24"
                    nzMd="24"
                    nzLg="24"
                    nzXl="24"
                    nzSpan="24"
                  >
                    <nz-form-item>
                      <nz-form-label nzNoColon
                        >Address of Hospital
                      </nz-form-label>

                      <nz-form-control
                        nzHasFeedback
                        nzErrorTip="Name of Address is required"
                      >
                        <input
                          style="width: 100%"
                          name="ADDRESS{{ i }}"
                          nz-input
                          placeholder="Enter Address in english"
                          autocomplete="off"
                          type="text"
                          [(ngModel)]="data.ADDRESS"
                        />
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
                <!-- <div nz-row>
                    <div
                      nz-col
                      nzXs="24"
                      nzSm="24"
                      nzMd="24"
                      nzLg="24"
                      nzXl="24"
                      nzSpan="24"
                    >
                      <nz-form-item>
                        <nz-form-label nzRequired nzNoColon
                          >Address of Hospital(in hindi)</nz-form-label
                        >
    
                        <nz-form-control
                          nzHasFeedback
                          nzErrorTip="Address of Hospital(in hindi) is required"
                        >
                          <input
                            style="width: 100%"
                            nz-input
                            placeholder="Enter Address of Hospital in hindi"
                            autocomplete="off"
                            name="ADDRESS_HN{{ i }}"
                            type="text"
                            [(ngModel)]="data.ADDRESS_HN"
                          />
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                  </div> -->

                <div>
                  <nz-form-item>
                    <nz-form-label nzRequired nzNoColon
                      >Show In Order</nz-form-label
                    >
                    <nz-form-control>
                      <nz-switch
                        name="IS_VISIBLE_IN_ORDER{{ i }}"
                        nzCheckedChildren="Yes"
                        nzUnCheckedChildren="No"
                        [(ngModel)]="HospitalMapping[i].IS_VISIBLE_IN_ORDER"
                      ></nz-switch>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
            </div>

            <!-- <div nz-row *ngIf="data.HOSPITAL_TYPE == 'E'">
        <div
          nz-col
          nzXs="24"
          nzSm="24"
          nzMd="24"
          nzLg="24"
          nzXl="24"
          nzSpan="24"
        >
          <nz-form-item>
            <nz-form-label nzNoColon>Name of Hospital </nz-form-label>
        
            <nz-form-control
              nzHasFeedback
              nzErrorTip="Name of patient is required"
            >
              <input
                style="width: 100%"
                name="EMPANELLED_HOSPITAL_NAME"
                nz-input
                placeholder="Enter name in english"
                autocomplete="off"
                type="text"
                disabled="true"
                [(ngModel)]="data.EMPANELLED_HOSPITAL_NAME"
              />
            </nz-form-control>
          </nz-form-item>
        </div>
        </div> -->

            <!-- <div nz-row>
                      <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" nzSpan="24">
                          <nz-form-item>
                              <nz-form-label  nzNoColon>Amount (in words)</nz-form-label>
        
                              <nz-form-control nzHasFeedback nzErrorTip="City Name is required">
                                  <input style="width: 100%;" nz-input placeholder="Enter Amount"
                                      autocomplete="off"  name="amountinwords" type="text"
                                      [(ngModel)]="amountinwords" />
                              </nz-form-control>
                          </nz-form-item>
                      </div>
                     
                  </div>
                  <div nz-row>
                      <div  nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" nzSpan="24">
                          <nz-form-item>
                              <nz-form-label nzRequired nzNoColon>Amount (in hindi)</nz-form-label>
        
                              <nz-form-control nzHasFeedback nzErrorTip="City Name is required">
                                  <input style="width: 100%;" nz-input placeholder="Enter amount in hindi"
                                      autocomplete="off" name="amountinwordsh"  type="text"
                                      [(ngModel)]="amountinwordsh" />
                              </nz-form-control>
                          </nz-form-item>
                      </div>
                  </div> -->
          </div>
        </div>

        <div nz-col nzXs="16" nzSm="16" nzMd="16" nzLg="16" nzXl="16">
          <div
            style="
              font-family: Arial, Helvetica, sans-serif !important;
              height: 78vh;
              overflow-y: auto;
            "
          >
            <div
              *ngIf="data != null"
              style="margin-top: 37px; text-align: justify"
            >
              <div style="text-align: center"><b> Order Sheet</b></div>
              <p style="margin-top: 15px">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <b>{{ data.EMPLOYEE_NAME }}&nbsp;, {{ data.DESIGNATION }},</b>
                an CGHS/AMA benefiary, has submitted the Medical bill on
                <b>{{ data.BILL_SUBMISSION_DATE | date : "dd/MM/yyyy" }}</b>
                and received in this office on
                <b>{{ data.RECEIVED_DATE | date : "dd/MM/yyyy" }}</b> for
                reimbursement of medical expenses of Rs
                <b>{{ data.EXPENCE_AMOUNT }} /-</b> <b>(F/A).</b> The expenses
                are incurred for {{ data.NATURE_OF_TREATMENT }} treatment from

                <span *ngFor="let data of HospitalMapping; let k = index">
                  <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                  {{ data.NAME }} ,{{ data.ADDRESS }},{{
                    data.TYPE == "E"
                      ? "[Empanelled]"
                      : data.TYPE == "NE"
                      ? "[Non Empanelled]"
                      : data.TYPE == "G"
                      ? "Government"
                      : ""
                  }}
                  <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                  <!-- <span *ngIf="HospitalMapping.length - 2 == k">and</span> -->
                  <!-- </span> -->
                </span>

                from
                <b>{{ data.TREATMENT_START_DATE | date : "dd/MM/yyyy" }}</b>
                to
                <b>{{ data.TREATMENT_END_DATE | date : "dd/MM/yyyy" }}</b>

                for
                <span *ngIf="data.RELATION_WITH_PATIENT != 'Self'">
                  his/her {{ data.RELATION_WITH_PATIENT }}
                  {{ data.PATIENT_NAME }}.</span
                >

                <!-- &nbsp; for&nbsp;{{ data.NATURE_OF_TREATMENT }}
                  <span *ngIf="data.RELATION_WITH_PATIENT == 'Self'">
                    of Self</span
                  > -->
                Vide letter dt.<b>{{
                  data.LETTER_DATE | date : "dd/MM/yyyy"
                }}</b
                >, he has requested for ex-post facto permission for claiming
                reimbursement of medical expenses incurred for
                {{ data.NATURE_OF_TREATMENT }} from
                <span *ngFor="let data of HospitalMapping; let k = index">
                  <span *ngIf="data.IS_VISIBLE_IN_ORDER == true">
                    {{ data.NAME }} ,{{ data.ADDRESS }},{{
                      data.TYPE == "E"
                        ? "[Empanelled]"
                        : data.TYPE == "NE"
                        ? "[Non Empanelled]"
                        : data.TYPE == "G"
                        ? "Government"
                        : ""
                    }}
                    <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                    <!-- <span *ngIf="HospitalMapping.length - 2 == k">and</span> -->
                  </span>
                </span>
              </p>

              <p>
                2. As per procedure, the beneficiary should primarily take
                treatment from CGHS dispensary but if he/she is advised for
                specialized treatment, the same can be obtained from empanelled
                hospital without prior permission from HOD. For taking treatment
                in a Non-empanelled hospital through CGHS, in addition to the
                reference from CGHS Doctor prior permission from HOD is also
                required to be taken.
              </p>

              <p>3. {{ data.EXPOST_REASON }}</p>
              <!-- <p style="display: none;">
                3. {{getdataaaa(data.EXPOST_REASON)}}
              </p> -->

              <p>
                4.<b>{{ data.CLAIM_REASON }}</b>
              </p>

              <p>
                5. In view of the reason given by
                <b>{{ data.EMPLOYEE_NAME }}&nbsp;, {{ data.DESIGNATION }},</b>
                the application for ex-post facto permission may kindly be
                considered sympathetically. As per Office Memorandum
                No.1967/2013/DEL/CGHS/SZ/D52/CGHS(P) dated 30.12.2014
                <b>(F/C)</b> issued by the Ministry of Health & Family Welfare,
                CGHS (Policy) Division, New Delhi, the power is vested with the
                HOD to accord ex-post facto permission, subject to reimbursement
                of expenses being restricted to CGHS rate or actual expenditure
                whichever is less.
              </p>

              <p>
                Put up for kind consideration/approval along with ex- post facto
                permission letter.
              </p>

              <div style="margin-top: 124px; margin-left: 59%">
                <div *ngIf="SECTION_TYPE == 'welfare'">
                  <b>(Ritesh Kumar)</b>
                </div>
                <div *ngIf="SECTION_TYPE == 'gazpay'">
                  <b>(Jitendra R Chandekar)</b>
                </div>

                <span *ngIf="SECTION_TYPE == 'welfare'">
                  ITO (HQ) Welfare & SG, Mumbai.</span
                >
                <span *ngIf="SECTION_TYPE == 'gazpay'"
                  >ITO Gaz Pay Bill Section II, Mumbai</span
                >
              </div>

              <div><u>Jt.CIT(HQ) Personnel, Mumbai.</u></div>
              <div nz-row style="margin-top: 98px">
                <div nz-col nzSpan="14">
                  <div
                    style="
                      page-break-inside: avoid !important;
                      font-size: 13px;
                      page-break-before: avoid !important;
                    "
                  >
                    <div>
                      <b
                        >Commisioner of Income Tax (Administration & TPS),
                        Mumbai
                      </b>
                    </div>
                  </div>
                </div>
                <div nz-col nzSpan="10">
                  <div style="border-style: solid; box-sizing: border-box">
                    Checked By...............
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>
  </form>

  <div class="footer">
    <button
      type="button"
      nz-button
      class="ant-btn"
      style="margin-right: 8px"
      (click)="close()"
    >
      <span>Cancel</span>
    </button>

    <button
      type="button"
      class="ant-btn ant-btn-primary"
      nz-button
      (click)="Save()"
    >
      <span>Save & Preview</span>
    </button>
    <!-- <button nz-button nzType="primary"  (click)="pakagedata()">
                      Save and Next
                  </button> -->

    <!-- <button
                type="button"
                class="ant-btn ant-btn-primary"
                nz-button
                (click)="openPrintOrderModal()"
              >
                <span>Preview</span>
              </button> -->
  </div>
  <div class="header">
    <button
      type="button"
      nz-button
      (click)="close()"
      class="ant-btn"
      style="margin-right: 8px"
    >
      <i nz-icon nzType="close" nzTheme="outline"></i>
    </button>
  </div>
</div>

<nz-modal
  [nzWidth]="getwidth()"
  [(nzVisible)]="printOrderModalVisible"
  nzMaskClosable="false"
  nzClosable="false"
  [nzContent]="printOrderModalContent"
  [nzTitle]="'Referance Expo Facto Order Sheet'"
  (nzOnCancel)="printOrderModalCancel()"
  [nzFooter]="printOrderModalFooter"
>
  <ng-template #printOrderModalContent>
    <nz-spin [nzSpinning]="isSpinning">
      <div style="height: 450px; overflow: auto">
        <div id="printOrderModal">
          <div style="width: 700px; padding: 3px; margin-left: 12px">
            <div style="font-family: Arial, Helvetica, sans-serif !important">
              <div
                *ngIf="data != null"
                style="margin-top: 37px; text-align: justify"
              >
                <div style="text-align: center"><b> Order Sheet</b></div>
                <p style="margin-top: 15px">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <b>{{ data.EMPLOYEE_NAME }}&nbsp;, {{ data.DESIGNATION }},</b>
                  an CGHS/AMA benefiary, has submitted the Medical bill on
                  <b>{{ data.BILL_SUBMISSION_DATE | date : "dd/MM/yyyy" }}</b>
                  and received in this office on
                  <b>{{ data.RECEIVED_DATE | date : "dd/MM/yyyy" }}</b> for
                  reimbursement of medical expenses of Rs
                  <b>{{ data.EXPENCE_AMOUNT }} /-</b> <b>(F/A).</b> The expenses
                  are incurred for {{ data.NATURE_OF_TREATMENT }} treatment from

                  <span *ngFor="let data of HospitalMapping; let k = index">
                    <!-- <span *ngIf="data.IS_VISIBLE_IN_ORDER == true"> -->
                    {{ data.NAME }} ,{{ data.ADDRESS }},{{
                      data.TYPE == "E"
                        ? "[Empanelled]"
                        : data.TYPE == "NE"
                        ? "[Non Empanelled]"
                        : data.TYPE == "G"
                        ? "Government"
                        : ""
                    }}
                    <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                    <!-- <span *ngIf="HospitalMapping.length - 2 == k">and</span> -->
                    <!-- </span> -->
                  </span>

                  from
                  <b>{{ data.TREATMENT_START_DATE | date : "dd/MM/yyyy" }}</b>
                  to
                  <b>{{ data.TREATMENT_END_DATE | date : "dd/MM/yyyy" }}</b>

                  for
                  <span *ngIf="data.RELATION_WITH_PATIENT != 'Self'">
                    his/her {{ data.RELATION_WITH_PATIENT }}
                    {{ data.PATIENT_NAME }}.</span
                  >

                  <!-- &nbsp; for&nbsp;{{ data.NATURE_OF_TREATMENT }}
                    <span *ngIf="data.RELATION_WITH_PATIENT == 'Self'">
                      of Self</span
                    > -->
                  Vide letter dt.<b>{{
                    data.LETTER_DATE | date : "dd/MM/yyyy"
                  }}</b
                  >, he has requested for ex-post facto permission for claiming
                  reimbursement of medical expenses incurred for
                  {{ data.NATURE_OF_TREATMENT }} from
                  <span *ngFor="let data of HospitalMapping; let k = index">
                    <span *ngIf="data.IS_VISIBLE_IN_ORDER == true">
                      {{ data.NAME }} ,{{ data.ADDRESS }},{{
                        data.TYPE == "E"
                          ? "[Empanelled]"
                          : data.TYPE == "NE"
                          ? "[Non Empanelled]"
                          : data.TYPE == "G"
                          ? "Government"
                          : ""
                      }}
                      <span *ngIf="HospitalMapping.length - 2 != k">,</span>
                      <!-- <span *ngIf="HospitalMapping.length - 2 == k">and</span> -->
                    </span>
                  </span>
                </p>

                <p>
                  2. As per procedure, the beneficiary should primarily take
                  treatment from CGHS dispensary but if he/she is advised for
                  specialized treatment, the same can be obtained from
                  empanelled hospital without prior permission from HOD. For
                  taking treatment in a Non-empanelled hospital through CGHS, in
                  addition to the reference from CGHS Doctor prior permission
                  from HOD is also required to be taken.
                </p>

                <p>3. {{ data.EXPOST_REASON }}</p>

                <p>
                  4.<b>{{ data.CLAIM_REASON }}</b>
                </p>

                <p>
                  5. In view of the reason given by
                  <b>{{ data.EMPLOYEE_NAME }}&nbsp;, {{ data.DESIGNATION }},</b>
                  the application for ex-post facto permission may kindly be
                  considered sympathetically. As per Office Memorandum
                  No.1967/2013/DEL/CGHS/SZ/D52/CGHS(P) dated 30.12.2014
                  <b>(F/C)</b> issued by the Ministry of Health & Family
                  Welfare, CGHS (Policy) Division, New Delhi, the power is
                  vested with the HOD to accord ex-post facto permission,
                  subject to reimbursement of expenses being restricted to CGHS
                  rate or actual expenditure whichever is less.
                </p>

                <p>
                  Put up for kind consideration/approval along with ex- post
                  facto permission letter.
                </p>

                <div style="margin-top: 124px; margin-left: 59%">
                  <div *ngIf="SECTION_TYPE == 'welfare'">
                    <b>(Ritesh Kumar)</b>
                  </div>
                  <div *ngIf="SECTION_TYPE == 'gazpay'">
                    <b>(Jitendra R Chandekar)</b>
                  </div>

                  <span *ngIf="SECTION_TYPE == 'welfare'">
                    ITO (HQ) Welfare & SG, Mumbai.</span
                  >
                  <span *ngIf="SECTION_TYPE == 'gazpay'"
                    >ITO Gaz Pay Bill Section II, Mumbai</span
                  >
                </div>

                <div><u>Jt.CIT(HQ) Personnel, Mumbai.</u></div>
                <div nz-row style="margin-top: 98px">
                  <div nz-col nzSpan="14">
                    <div
                      style="
                        page-break-inside: avoid !important;
                        font-size: 13px;
                        page-break-before: avoid !important;
                      "
                    >
                      <div>
                        <b
                          >Commisioner of Income Tax (Administration & TPS),
                          Mumbai
                        </b>
                      </div>
                    </div>
                  </div>
                  <div nz-col nzSpan="10">
                    <div style="border-style: solid; box-sizing: border-box">
                      Checked By...............
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>
  </ng-template>

  <ng-template #printOrderModalFooter>
    <button nz-button nzType="default" (click)="printOrderModalCancel()">
      Close
    </button>

    <button
      nz-button
      nzType="default"
      [useExistingCss]="true"
      printSectionId="printOrderModal"
      ngxPrint
    >
      Print
    </button>

    <button
      nz-button
      nzType="primary"
      [nzLoading]="pdfDownload"
      (click)="generatePDF()"
    >
      Download
    </button>
  </ng-template>
</nz-modal>
