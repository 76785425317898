import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { ApplicantMaster } from 'src/app/Models/applicantmaster';
import { InvestigationMaster } from 'src/app/Models/InvestigationMaster';
import { ApiService } from 'src/app/Service/api.service';
import { ToWords } from 'to-words';
import * as html2pdf from 'html2pdf.js';

const toWords = new ToWords({
  localeCode: 'en-IN',
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    // currencyOptions: {
    //   // can be used to override defaults for the selected locale
    //   name: 'Rupee',
    //   plural: 'Rupees',
    //   symbol: '₹',
    //   fractionalUnit: {
    //     name: 'Paisa',
    //     plural: 'Paise',
    //     symbol: '',
    //   },
    // },
  },
});
const toWordsen = new ToWords({
  localeCode: 'hi-IN',
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    // currencyOptions: {
    //   // can be used to override defaults for the selected locale
    //   name: 'Rupee',
    //   plural: 'रुपये',
    //   symbol: '₹',
    //   fractionalUnit: {
    //     name: 'Paisa',
    //     plural: 'Paise',
    //     symbol: '',
    //   },
    // },
  },
});

@Component({
  selector: 'app-claimmasterlist',
  templateUrl: './claimmasterlist.component.html',
  styleUrls: ['./claimmasterlist.component.css'],
})
export class ClaimmasterlistComponent implements OnInit {
  drawerData2: any[] = [];
  drawerVisible: boolean = false;
  drawerTitle: string = '';
  filterClass: string = 'filter-invisible';

  drawerData: ApplicantMaster = new ApplicantMaster();
  // data: ClaimMaster = new ClaimMaster();
  userId = Number(sessionStorage.getItem('userId'));
  // userName = Number(sessionStorage.getItem('userId'));
  userName = sessionStorage.getItem('userName');
  roleId = Number(sessionStorage.getItem('roleId'));
  pageSize2 = 10;

  formTitle = 'Manage Claims';
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = false;
  sortValue: string = 'desc';
  sortKey: string = 'ID';
  searchText: string = '';
  filterQuery: string = '';
  size = 'small';
  isFilterApplied: string = 'default';
  newButton: string = 'default';
  forwardButton: string = 'default';
  rejectButton: string = 'default';
  queryButton: string = 'default';
  approvedButton: string = 'default';
  readyButton: string = 'default';
  totalButton: string = 'default';
  claimData: any;
  allClaimCount: any;
  allNewCount: any;
  allForwardCount: any;
  allRejectCount: any;
  allQueryCount: any;
  allApprovedCount: any;
  allReadyCount: any;
  INSPECTOR_ID: any;
  columns: string[][] = [
    ['EMPLOYEE_NAME', 'EMPLOYEE_NAME'],
    ['CLAIM_STAGE_NAME', 'CLAIM_STAGE_NAME'],
    ['CLAIM_NO', 'CLAIM_NO'],
    ['MOBILE_NO', 'MOBILE_NO'],
    ['EMPLOYEE_CODE', 'EMPLOYEE_CODE'],
    ['DESIGNATION', 'DESIGNATION'],
    ['PATIENT_NAME', 'PATIENT_NAME'],
    ['RELATION_WITH_PATIENT', 'RELATION_WITH_PATIENT'],
    ['BILL_FILIING_DATE', 'BILL_FILIING_DATE'],
    ['ADMISSIBLE_AMOUNT', 'ADMISSIBLE_AMOUNT'],
    ['TREATEMENT_TYPE', 'TREATEMENT_TYPE'],
    ['TREATMENT_START_DATE', 'TREATMENT_START_DATE'],
    ['TREATMENT_END_DATE', 'TREATMENT_END_DATE'],
    ['WARD_TYPE', 'WARD_TYPE'],
    ['NATURE_OF_TREATMENT', 'NATURE_OF_TREATMENT'],
    ['CREATED_MODIFIED_DATE', 'CREATED_MODIFIED_DATE'],
    ['INSPECTOR_NAME', 'INSPECTOR_NAME'],
  ];

  TYPE_OF_HOSPITAL: any;
  STAGE_NAME: any;
  isSpinning = false;

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'default';
    this.filterQuery = '';
    this.BILL_FILIING_DATE = [];
    // this.BILL_FILIING_DATE[0] = new Date(
    //   this.current.getFullYear() + '-' + (this.current.getMonth() + 1) + '-01'
    // );
    // this.BILL_FILIING_DATE[1] = new Date();
    this.TYPE_OF_HOSPITAL = [];
    this.dataList = [];
    this.INSPECTOR_ID = '';
    this.search();
  }

  dateFormat = 'dd/MM/yyyy';
  isButtonSpinning: boolean = false;

  // TYPE_OF_HOSPITAL: any = [];

  START_DATE: any;
  END_DATE: any;
  BILL_FILIING_DATE = [];
  current = new Date();
  rejectClaimVisible: boolean = false;
  constructor(
    private message: NzNotificationService,
    private api: ApiService,
    private datePipe: DatePipe
  ) {}
  ngOnInit(): void {
    if (this.userId == 1) {
      this.loadAllUsers();
    } else {
    }
    // this.stageName();
    // this.search();
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(reset);
  }
  stages = [];
  stageName() {
    this.api.getStage(0, 0, '', 'asc', 'AND STATUS=1').subscribe(
      (data1) => {
        console.log(data1);
        this.stages = data1['data'];
      },
      (err) => {
        console.log(err);
      }
    );
  }

  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find((item) => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'id';
    const sortOrder = (currentSort && currentSort.value) || 'desc';
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if (this.pageSize2 != pageSize) {
      this.pageIndex = 1;
      this.pageSize2 = pageSize;
    }

    if (this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search();
  }

  userIdFilter;
  search(reset: boolean = false) {
    this.isSpinning = true;
    var filter = '';
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }
    console.log('search text : ' + this.searchText);

    var likeQuery = '';

    if (this.searchText != '') {
      likeQuery = ' AND (';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    }

    if (
      this.BILL_FILIING_DATE != undefined &&
      this.BILL_FILIING_DATE.length != 0
    ) {
      this.START_DATE = this.datePipe.transform(
        this.BILL_FILIING_DATE[0],
        'yyyy-MM-dd'
      );
      this.END_DATE = this.datePipe.transform(
        this.BILL_FILIING_DATE[1],
        'yyyy-MM-dd'
      );
    } else {
      this.START_DATE = null;
      this.END_DATE = null;
    }
    if (Number(sessionStorage.getItem('roleId')) == 3) {
      this.userIdFilter =
        ' AND IS_DELETED = 0 AND INSPECTOR_ID = ' +
        Number(sessionStorage.getItem('userId'));
    } else if (Number(sessionStorage.getItem('roleId')) == 4) {
      this.userIdFilter =
        ' AND IS_DELETED = 0 AND AO_ID = ' +
        Number(sessionStorage.getItem('userId'));
    } else {
      this.userIdFilter = ' AND IS_DELETED = 0';
    }
    this.loadingRecords = true;
    console.log(this.STAGE_NAME);
    // this.api
    //   .getClaimMasterCount(
    //     this.pageIndex,
    //     this.pageSize,
    //     this.sortKey,
    //     sort,
    //     this.userIdFilter
    //   )
    //   .subscribe(
    //     (data) => {
    //       console.log(data);
    //       // this.loadingRecords = false;
    //       this.allClaimCount = data['count'][0]['ALL_CLAIMS'];
    //       this.allNewCount = data['count'][0]['NEW'];
    //       this.allForwardCount = data['count'][0]['FORWARD'];
    //       this.allRejectCount = data['count'][0]['REJECTED'];
    //       this.allQueryCount = data['count'][0]['QUERY_RAISED'];
    //       this.allApprovedCount = data['count'][0]['APPROVED'];
    //       this.allReadyCount = data['count'][0]['READY'];
    //       // this.dataList = data['data'];
    //       // console.log(data['data']);

    //       // this.isSpinning = false;
    //       // this.filterClass = 'filter-invisible';
    //     },
    //     (err) => {
    //       console.log(err);
    //     }
    //   );
    this.api
      .getclaimMaster(
        this.pageIndex,
        this.pageSize,
        this.sortKey,
        sort,
        this.userIdFilter + this.filterQuery + likeQuery,
        this.START_DATE,
        this.END_DATE,
        this.TYPE_OF_HOSPITAL,
        this.STAGE_NAME
      )
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            console.log(data);
            this.loadingRecords = false;
            this.totalRecords = data['count'];
            this.dataList = data['data'];
            this.isSpinning = false;
            this.filterClass = 'filter-invisible';
          } else {
            this.message.error("Can't Load Data", '');
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  applyFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'primary';
    this.loadingRecords = false;
    var sort: string;

    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }

    // if (this.SALES_MANAGER_ID != undefined) {
    //   // this.SALES_MANAGER_ID = this.SALES_MANAGER_ID;
    // }
    if (
      this.BILL_FILIING_DATE.length > 0 &&
      this.INSPECTOR_ID != null &&
      this.INSPECTOR_ID != undefined &&
      this.INSPECTOR_ID != ''
    ) {
      this.BILL_FILIING_DATE[0] = this.datePipe.transform(
        this.BILL_FILIING_DATE[0],
        'yyyy-MM-dd'
      );
      this.BILL_FILIING_DATE[1] = this.datePipe.transform(
        this.BILL_FILIING_DATE[1],
        'yyyy-MM-dd'
      );
      this.filterQuery = ' AND INSPECTOR_ID = ' + this.INSPECTOR_ID;
      this.search();
    } else if (
      this.BILL_FILIING_DATE.length == 0 &&
      this.INSPECTOR_ID != null &&
      this.INSPECTOR_ID != undefined &&
      this.INSPECTOR_ID != ''
    ) {
      this.filterQuery = ' AND INSPECTOR_ID = ' + this.INSPECTOR_ID;
      this.search();
    } else if (
      this.BILL_FILIING_DATE.length > 0 &&
      (this.INSPECTOR_ID == null ||
        this.INSPECTOR_ID == undefined ||
        this.INSPECTOR_ID == '')
    ) {
      this.BILL_FILIING_DATE[0] = this.datePipe.transform(
        this.BILL_FILIING_DATE[0],
        'yyyy-MM-dd'
      );
      this.BILL_FILIING_DATE[1] = this.datePipe.transform(
        this.BILL_FILIING_DATE[1],
        'yyyy-MM-dd'
      );
      this.search();
    } else {
      this.message.error('Please Select Any Filter Value', '');
    }
    // this.BILL_FILIING_DATE[0] = this.datePipe.transform(
    //   this.BILL_FILIING_DATE[0],
    //   'yyyy-MM-dd'
    // );
    // this.BILL_FILIING_DATE[1] = this.datePipe.transform(
    //   this.BILL_FILIING_DATE[1],
    //   'yyyy-MM-dd'
    // );

    // if (this.BILL_FILIING_DATE[0] != null) {
    //   this.START_DATE = this.BILL_FILIING_DATE[0];
    // }

    // if (this.BILL_FILIING_DATE[1] != null) {
    //   this.END_DATE = this.BILL_FILIING_DATE[1];
    // }

    // this.search();
  }

  showFilter() {
    if (this.filterClass === 'filter-visible')
      this.filterClass = 'filter-invisible';
    else this.filterClass = 'filter-visible';
  }

  drawerClose(): void {
    this.currentStage = 0;
    this.drawerVisible = false;
    this.search();

    // window.location.reload();
  }

  get closeCallback() {
    this.currentStage = 0;
    return this.drawerClose.bind(this);
  }
  currentStage = 0;
  isSpin: boolean = false;
  add(): void {
    this.drawerTitle = 'Create New Claim';
    this.drawerData = new ApplicantMaster();
    this.currentStage = 0;
    this.isSpin = false;
    console.log(' this.isSpin', this.isSpin);

    this.claimID = '';
    this.empID = '';
    this.currentStageID = '';
    this.drawerVisible = true;
  }
  // empID=0;
  // edit(data: any): void {
  //   this.drawerTitle = 'Edit Claim Details';

  //   this.drawerData = Object.assign({}, data);

  //   console.log('asjfj', this.drawerData);

  //   this.empID = data.ID;

  //   this.drawerVisible = true;
  // }

  empID;
  claimID;
  currentStageID;
  edit(data: any): void {
    this.currentStage = 0;
    this.drawerTitle = 'Edit Claim Details';
    this.drawerData = Object.assign({}, data);
    this.isSpin = false;
    this.empID = this.drawerData.EMP_ID;
    this.claimID = this.drawerData.ID;
    this.currentStageID = this.drawerData.CURRENT_STAGE_ID;

    this.drawerData['NAME'] = this.drawerData['EMPLOYEE_NAME'];
    this.drawerData['EMP_ID'] = this.drawerData['EMP_ID'];
    // this.drawerData['DESIGNATION'] = this.drawerData['EMPLOYEE_DESIGNATION'];
    // this.drawerData['OFFICE_NAME'] = this.drawerData['EMPLOYEE_OFFICE_NAME'];
    // this.drawerData['DDO_OF_THE_OFFICIAL'] = this.drawerData['EMPLOYEE_DDO'];
    this.drawerData['EMPLOYEE_CODE'] = this.drawerData['EMPLOYEE_CODE'];
    // this.drawerData['GRADE_PAY'] = this.drawerData['EMPLOYEE_GRADE_PAY'];
    // this.drawerData['EMAIL_ID'] = this.drawerData['EMPLOYEE_NAME'];
    // this.drawerData['MOBILE_NO'] = this.drawerData['EMPLOYEE_NAME'];
    // this.drawerData['BENEFICIARY_TYPE'] = this.drawerData['BENEFICIARY_TYPE'];
    // this.drawerData['ADDRESS'] = this.drawerData['EMPLOYEE_NAME'];
    // this.drawerData['CGHS_CARD_NO'] = this.drawerData['EMPLOYEE_NAME'];
    // this.drawerData['CGHS_CARD_VALIDITY'] = this.drawerData['EMPLOYEE_NAME'];
    this.drawerVisible = true;
  }
  orderDrawerVisible: boolean = false;
  Spin: boolean = false;
  orderDrawerTitle: string;
  orderdata: any;
  amountinwords: any;
  amountinwordsh: any;
  am = 100;
  fileList = [];
  HospitalMapping = [];
  Signaturearray = [];
  openOrderDrawer(data: any): void {
    this.Order = '';
    this.loadingRecords = true;
    if (data.ADMISSIBLE_AMOUNT == null || data.ADMISSIBLE_AMOUNT == undefined) {
      this.message.info('Please Fill Annexure Details First', '');
      this.loadingRecords = false;
    } else {
      this.isAdvanceSameAsAdmissible = false;
      this.admissibleineng = '';
      this.admissibleinhindi = '';
      this.advadmissibleineng = '';
      this.advadmissibleinhindi = '';
      this.advanceineng = '';
      this.advanceinhindi = '';
      this.finaladmissibleineng = '';
      this.finaladmissibleinhindi = '';
      this.finalclaimedineng = '';
      this.finalclaimedinhindi = '';
      this.finalremainingineng = '';
      this.finalremaininginhindi = '';
      var filterQuery =
        ' AND CURRENT_POSITION_ID = ' +
        sessionStorage.getItem('userId') +
        ' AND (CLAIM_ID=null OR CLAIM_ID=0)';
      this.api
        .getFileMaster(
          0,
          0,
          'ID',
          'ASC',
          filterQuery,
          sessionStorage.getItem('userId')
        )
        .subscribe(
          (data: any) => {
            if (data['code'] == 200 && data['count'] > 0) {
              this.fileList = data['data'];
            } else {
              this.fileList = [];
              // this.loadingRecords = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
      this.api
        .getSignature(0, 0, '', '', ' AND ID = ' + data.SIGNATURE_ID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              this.SECTION_TYPE = data['data'][0]['SECTION_TYPE'];
              this.NAME = data['data'][0]['NAME'];
              this.NAME_HN = data['data'][0]['NAME_HN'];
              this.OFFICE_NAME = data['data'][0]['OFFICE_NAME'];
              this.OFFICE_NAME_HN = data['data'][0]['OFFICE_NAME_HN'];
              this.POST = data['data'][0]['POST'];
              this.POST_HN = data['data'][0]['POST_HN'];
              // this.loadingRecords = false;
              // this.orderDrawerVisible = true;
            } else {
              this.message.error('Something Went Wrong', '');
              this.loadingRecords = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
      this.orderdata = data;
      if (
        this.orderdata.ADMISSIBLE_AMOUNT == null ||
        this.orderdata.ADMISSIBLE_AMOUNT == undefined ||
        this.orderdata.ADMISSIBLE_AMOUNT == 'NaN'
      ) {
        this.orderdata.ADMISSIBLE_AMOUNT = 0;
      } else {
        this.orderdata.ADMISSIBLE_AMOUNT = this.orderdata.ADMISSIBLE_AMOUNT;
      }

      let words = toWords.convert(this.am, { currency: true });
      this.orderdata.ADMISSIBLE_AMOUNT = this.orderdata.ADMISSIBLE_AMOUNT;
      this.amountinwords = toWords.convert(this.orderdata.ADMISSIBLE_AMOUNT, {
        currency: true,
      });

      let wordss = toWordsen.convert(this.am, { currency: true });
      this.orderdata.ADMISSIBLE_AMOUNT = this.orderdata.ADMISSIBLE_AMOUNT;
      this.amountinwordsh = toWordsen.convert(
        this.orderdata.ADMISSIBLE_AMOUNT,
        {
          currency: true,
        }
      );

      if (
        this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT == null ||
        this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT == undefined ||
        this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT == 'NaN'
      ) {
        this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT = 0;
      } else {
        this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT =
          this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT;
      }

      let wordsss = toWords.convert(this.am, { currency: true });
      this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT =
        this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT;
      this.amountinwordss = toWords.convert(
        this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT,
        {
          currency: true,
        }
      );

      let wordssss = toWordsen.convert(this.am, { currency: true });
      this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT =
        this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT;
      this.amountinwordssh = toWordsen.convert(
        this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT,
        {
          currency: true,
        }
      );

      this.orderDrawerTitle = 'Order Details';
      this.Signaturearray = [];
      this.api
        .getHospitalMapping(0, 0, 'ID', 'ASC', ' AND CLAIM_ID = ' + data.ID)
        .subscribe(
          (data: any) => {
            if (data['code'] == 200) {
              if (data['count'] > 0) {
                this.HospitalMapping = data['data'];
                console.log('ddddd', this.HospitalMapping);
              } else {
                this.HospitalMapping = [];
              }
              this.api.getSignature(0, 0, 'ID', 'desc', '').subscribe(
                (data) => {
                  if (data['code'] == 200) {
                    this.Signaturearray = data['data'];
                    this.loadingRecords = false;
                    this.Order = 'O';
                    this.isAdvanceSameAsAdmissible = false;

                    if (
                      this.orderdata.ADMISSIBLE_AMOUNT == null ||
                      this.orderdata.ADMISSIBLE_AMOUNT == undefined ||
                      this.orderdata.ADMISSIBLE_AMOUNT == 'NaN'
                    ) {
                      this.orderdata.ADMISSIBLE_AMOUNT = 0;
                    } else {
                      this.orderdata.ADMISSIBLE_AMOUNT =
                        this.orderdata.ADMISSIBLE_AMOUNT;
                    }

                    if (
                      this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT == null ||
                      this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT == undefined ||
                      this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT == 'NaN'
                    ) {
                      this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT = 0;
                    } else {
                      this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT =
                        this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT;
                    }

                    if (
                      this.orderdata.ADVANCE_AMOUNT == null ||
                      this.orderdata.ADVANCE_AMOUNT == undefined ||
                      this.orderdata.ADVANCE_AMOUNT == 'NaN'
                    ) {
                      this.orderdata.ADVANCE_AMOUNT = 0;
                    } else {
                      this.orderdata.ADVANCE_AMOUNT =
                        this.orderdata.ADVANCE_AMOUNT;
                    }

                    if (
                      this.orderdata.FINAL_ADMISSIBLE_AMOUNT == null ||
                      this.orderdata.FINAL_ADMISSIBLE_AMOUNT == undefined ||
                      this.orderdata.FINAL_ADMISSIBLE_AMOUNT == 'NaN'
                    ) {
                      this.orderdata.FINAL_ADMISSIBLE_AMOUNT = 0;
                    } else {
                      this.orderdata.FINAL_ADMISSIBLE_AMOUNT =
                        this.orderdata.FINAL_ADMISSIBLE_AMOUNT;
                    }

                    if (
                      this.orderdata.FINAL_CLAIMED_AMOUNT == null ||
                      this.orderdata.FINAL_CLAIMED_AMOUNT == undefined ||
                      this.orderdata.FINAL_CLAIMED_AMOUNT == 'NaN'
                    ) {
                      this.orderdata.FINAL_CLAIMED_AMOUNT = 0;
                    } else {
                      this.orderdata.FINAL_CLAIMED_AMOUNT =
                        this.orderdata.FINAL_CLAIMED_AMOUNT;
                    }

                    if (
                      this.orderdata.FINAL_REMAINING_AMOUNT == null ||
                      this.orderdata.FINAL_REMAINING_AMOUNT == undefined ||
                      this.orderdata.FINAL_REMAINING_AMOUNT == 'NaN'
                    ) {
                      this.orderdata.FINAL_REMAINING_AMOUNT = 0;
                    } else {
                      this.orderdata.FINAL_REMAINING_AMOUNT =
                        this.orderdata.FINAL_REMAINING_AMOUNT;
                    }

                    if (
                      this.orderdata.FINAL_CLAIMED_AMOUNT != null &&
                      this.orderdata.FINAL_CLAIMED_AMOUNT != undefined &&
                      this.orderdata.FINAL_CLAIMED_AMOUNT != ''
                    ) {
                      let finclaimeng = toWords.convert(this.am, {
                        currency: true,
                      });
                      this.orderdata.FINAL_CLAIMED_AMOUNT =
                        this.orderdata.FINAL_CLAIMED_AMOUNT;
                      this.finalclaimedineng = toWords.convert(
                        this.orderdata.FINAL_CLAIMED_AMOUNT,
                        {
                          currency: true,
                        }
                      );

                      let finclaimhn = toWordsen.convert(this.am, {
                        currency: true,
                      });
                      this.orderdata.FINAL_CLAIMED_AMOUNT =
                        this.orderdata.FINAL_CLAIMED_AMOUNT;
                      this.finalclaimedinhindi = toWordsen.convert(
                        this.orderdata.FINAL_CLAIMED_AMOUNT,
                        {
                          currency: true,
                        }
                      );
                    } else {
                      this.finalclaimedinhindi = '';
                      this.finalclaimedineng = '';
                    }

                    if (
                      this.orderdata.FINAL_ADMISSIBLE_AMOUNT != null &&
                      this.orderdata.FINAL_ADMISSIBLE_AMOUNT != undefined &&
                      this.orderdata.FINAL_ADMISSIBLE_AMOUNT != ''
                    ) {
                      let finadmeng = toWords.convert(this.am, {
                        currency: true,
                      });
                      this.orderdata.FINAL_ADMISSIBLE_AMOUNT =
                        this.orderdata.FINAL_ADMISSIBLE_AMOUNT;
                      this.finaladmissibleineng = toWords.convert(
                        this.orderdata.FINAL_ADMISSIBLE_AMOUNT,
                        {
                          currency: true,
                        }
                      );

                      let finadmhn = toWordsen.convert(this.am, {
                        currency: true,
                      });
                      this.orderdata.FINAL_ADMISSIBLE_AMOUNT =
                        this.orderdata.FINAL_ADMISSIBLE_AMOUNT;
                      this.finaladmissibleinhindi = toWordsen.convert(
                        this.orderdata.FINAL_ADMISSIBLE_AMOUNT,
                        {
                          currency: true,
                        }
                      );
                    } else {
                      this.finaladmissibleineng = '';
                      this.finaladmissibleinhindi = '';
                    }

                    if (
                      this.orderdata.ADVANCE_AMOUNT != null &&
                      this.orderdata.ADVANCE_AMOUNT != undefined &&
                      this.orderdata.ADVANCE_AMOUNT != ''
                    ) {
                      let adveng = toWords.convert(this.am, { currency: true });
                      this.orderdata.ADVANCE_AMOUNT =
                        this.orderdata.ADVANCE_AMOUNT;
                      this.advanceineng = toWords.convert(
                        this.orderdata.ADVANCE_AMOUNT,
                        {
                          currency: true,
                        }
                      );

                      let advhn = toWordsen.convert(this.am, {
                        currency: true,
                      });
                      this.orderdata.ADVANCE_AMOUNT =
                        this.orderdata.ADVANCE_AMOUNT;
                      this.advanceinhindi = toWordsen.convert(
                        this.orderdata.ADVANCE_AMOUNT,
                        {
                          currency: true,
                        }
                      );
                    } else {
                      this.advanceineng = '';
                      this.advanceinhindi = '';
                    }

                    if (
                      this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT != null &&
                      this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT != undefined &&
                      this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT != ''
                    ) {
                      let advadmeng = toWords.convert(this.am, {
                        currency: true,
                      });
                      this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT =
                        this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT;
                      this.advadmissibleineng = toWords.convert(
                        this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT,
                        {
                          currency: true,
                        }
                      );

                      let advadmhn = toWordsen.convert(this.am, {
                        currency: true,
                      });
                      this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT =
                        this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT;
                      this.advadmissibleinhindi = toWordsen.convert(
                        this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT,
                        {
                          currency: true,
                        }
                      );
                    } else {
                      this.advadmissibleineng = '';
                      this.advadmissibleinhindi = '';
                    }

                    if (
                      this.orderdata.ADMISSIBLE_AMOUNT != null &&
                      this.orderdata.ADMISSIBLE_AMOUNT != undefined &&
                      this.orderdata.ADMISSIBLE_AMOUNT != ''
                    ) {
                      let admeng = toWords.convert(this.am, { currency: true });
                      this.orderdata.ADMISSIBLE_AMOUNT =
                        this.orderdata.ADMISSIBLE_AMOUNT;
                      this.admissibleineng = toWords.convert(
                        this.orderdata.ADMISSIBLE_AMOUNT,
                        {
                          currency: true,
                        }
                      );

                      let admhn = toWordsen.convert(this.am, {
                        currency: true,
                      });
                      this.orderdata.ADMISSIBLE_AMOUNT =
                        this.orderdata.ADMISSIBLE_AMOUNT;
                      this.admissibleinhindi = toWordsen.convert(
                        this.orderdata.ADMISSIBLE_AMOUNT,
                        {
                          currency: true,
                        }
                      );
                    } else {
                      this.admissibleineng = '';
                      this.admissibleinhindi = '';
                    }

                    if (
                      this.orderdata.FINAL_REMAINING_AMOUNT != null &&
                      this.orderdata.FINAL_REMAINING_AMOUNT != undefined &&
                      this.orderdata.FINAL_REMAINING_AMOUNT != ''
                    ) {
                      let rem = toWords.convert(this.am, { currency: true });
                      this.orderdata.FINAL_REMAINING_AMOUNT =
                        this.orderdata.FINAL_REMAINING_AMOUNT;
                      this.finalremainingineng = toWords.convert(
                        this.orderdata.FINAL_REMAINING_AMOUNT,
                        {
                          currency: true,
                        }
                      );

                      let remhn = toWordsen.convert(this.am, {
                        currency: true,
                      });
                      this.orderdata.FINAL_REMAINING_AMOUNT =
                        this.orderdata.FINAL_REMAINING_AMOUNT;
                      this.finalremaininginhindi = toWordsen.convert(
                        this.orderdata.FINAL_REMAINING_AMOUNT,
                        {
                          currency: true,
                        }
                      );
                    } else {
                      this.finalremainingineng = '';
                      this.finalremaininginhindi = '';
                    }

                    this.orderDrawerVisible = true;
                    this.Spin = false;
                  } else {
                    this.loadingRecords = false;
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
            } else {
              this.loadingRecords = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }
  Order: any = '';
  orderDrawerClose(): void {
    this.orderDrawerVisible = false;
    this.Spin = false;
    this.search();
  }

  get orderDrawerCloseCallback() {
    return this.orderDrawerClose.bind(this);
  }
  isAdvanceSameAsAdmissible: boolean = false;
  openNewOrderDrawer(data: any): void {
    this.Order = '';
    this.loadingRecords = true;
    this.orderdata = data;
    // this.orderdata.ZAO  = data.ZAO;
    // this.orderdata.ZAO_HN  = data.ZAO_HN;
    if (
      data.CHEQUE_DRAWN == null ||
      data.CHEQUE_DRAWN == undefined ||
      data.CHEQUE_DRAWN == ''
    ) {
      this.orderdata.CHEQUE_DRAWN = 0;
    }
    // if(data.ZAO_HN == null || data.ZAO_HN == undefined || data.ZAO_HN == ''){
    //   this.orderdata.ZAO_HN = 'ZAO , प्री-चेक युनिट, मुंबई - चेक आँचल अनिकेत कलमस्ते के पक्ष में तैयार किया जा सकता है।';
    // }

    if (data.ADMISSIBLE_AMOUNT == null || data.ADMISSIBLE_AMOUNT == undefined) {
      this.message.info('Please Fill Annexure Details First', '');
      this.loadingRecords = false;
    } else {
      this.isAdvanceSameAsAdmissible = false;
      this.admissibleineng = '';
      this.admissibleinhindi = '';
      this.advadmissibleineng = '';
      this.advadmissibleinhindi = '';
      this.advanceineng = '';
      this.advanceinhindi = '';
      this.finaladmissibleineng = '';
      this.finaladmissibleinhindi = '';
      this.finalclaimedineng = '';
      this.finalclaimedinhindi = '';
      this.finalremainingineng = '';
      this.finalremaininginhindi = '';
      var filterQuery =
        ' AND CURRENT_POSITION_ID = ' +
        sessionStorage.getItem('userId') +
        ' AND (CLAIM_ID=null OR CLAIM_ID=0)';
      this.api
        .getFileMaster(
          0,
          0,
          'ID',
          'ASC',
          filterQuery,
          sessionStorage.getItem('userId')
        )
        .subscribe(
          (data: any) => {
            if (data['code'] == 200 && data['count'] > 0) {
              this.fileList = data['data'];
            } else {
              this.fileList = [];
              // this.loadingRecords = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
      this.api
        .getSignature(0, 0, '', '', ' AND ID = ' + data.SIGNATURE_ID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              this.SECTION_TYPE = data['data'][0]['SECTION_TYPE'];
              this.NAME = data['data'][0]['NAME'];
              this.NAME_HN = data['data'][0]['NAME_HN'];
              this.OFFICE_NAME = data['data'][0]['OFFICE_NAME'];
              this.OFFICE_NAME_HN = data['data'][0]['OFFICE_NAME_HN'];
              this.POST = data['data'][0]['POST'];
              this.POST_HN = data['data'][0]['POST_HN'];
              // this.loadingRecords = false;
              // this.orderDrawerVisible = true;
            } else {
              this.message.error('Something Went Wrong', '');
              this.loadingRecords = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
      if (
        this.orderdata.ADMISSIBLE_AMOUNT == null ||
        this.orderdata.ADMISSIBLE_AMOUNT == undefined ||
        this.orderdata.ADMISSIBLE_AMOUNT == 'NaN'
      ) {
        this.orderdata.ADMISSIBLE_AMOUNT = 0;
      } else {
        this.orderdata.ADMISSIBLE_AMOUNT = this.orderdata.ADMISSIBLE_AMOUNT;
      }

      let words = toWords.convert(this.am, { currency: true });
      this.orderdata.ADMISSIBLE_AMOUNT = this.orderdata.ADMISSIBLE_AMOUNT;
      this.amountinwords = toWords.convert(this.orderdata.ADMISSIBLE_AMOUNT, {
        currency: true,
      });

      let wordss = toWordsen.convert(this.am, { currency: true });
      this.orderdata.ADMISSIBLE_AMOUNT = this.orderdata.ADMISSIBLE_AMOUNT;
      this.amountinwordsh = toWordsen.convert(
        this.orderdata.ADMISSIBLE_AMOUNT,
        {
          currency: true,
        }
      );

      if (
        this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT == null ||
        this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT == undefined ||
        this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT == 'NaN'
      ) {
        this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT = 0;
      } else {
        this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT =
          this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT;
      }

      this.orderDrawerTitle = 'Order Details';
      this.Signaturearray = [];
      this.api
        .getHospitalMapping(0, 0, 'ID', 'ASC', ' AND CLAIM_ID = ' + data.ID)
        .subscribe(
          (data: any) => {
            if (data['code'] == 200) {
              if (data['count'] > 0) {
                this.HospitalMapping = data['data'];
                console.log('ddddd', this.HospitalMapping);
              } else {
                this.HospitalMapping = [];
              }
              this.api.getSignature(0, 0, 'ID', 'desc', '').subscribe(
                (data) => {
                  if (data['code'] == 200) {
                    this.Signaturearray = data['data'];
                    this.loadingRecords = false;
                    this.Spin = false;
                    this.total = 0;
                    this.total1 = 0;
                    this.advanceAdmissible = 0;
                    this.api
                      .getannexture(
                        0,
                        0,
                        'ID',
                        'ASC',
                        ' AND STATUS = 1 AND IS_ADVANCE = 0 AND CLAIM_ID=' +
                          this.orderdata.ID
                      )
                      .subscribe(
                        (data) => {
                          if (data['code'] == 200) {
                            if (
                              this.orderdata.ADMISSIBLE_AMOUNT == null ||
                              this.orderdata.ADMISSIBLE_AMOUNT == undefined ||
                              this.orderdata.ADMISSIBLE_AMOUNT == 'NaN'
                            ) {
                              this.orderdata.ADMISSIBLE_AMOUNT = 0;
                            } else {
                              this.orderdata.ADMISSIBLE_AMOUNT =
                                this.orderdata.ADMISSIBLE_AMOUNT;
                            }

                            if (
                              this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT ==
                                null ||
                              this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT ==
                                undefined ||
                              this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT == 'NaN'
                            ) {
                              this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT = 0;
                            } else {
                              this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT =
                                this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT;
                            }

                            if (
                              this.orderdata.ADVANCE_AMOUNT == null ||
                              this.orderdata.ADVANCE_AMOUNT == undefined ||
                              this.orderdata.ADVANCE_AMOUNT == 'NaN'
                            ) {
                              this.orderdata.ADVANCE_AMOUNT = 0;
                            } else {
                              this.orderdata.ADVANCE_AMOUNT =
                                this.orderdata.ADVANCE_AMOUNT;
                            }

                            if (
                              this.orderdata.FINAL_ADMISSIBLE_AMOUNT == null ||
                              this.orderdata.FINAL_ADMISSIBLE_AMOUNT ==
                                undefined ||
                              this.orderdata.FINAL_ADMISSIBLE_AMOUNT == 'NaN'
                            ) {
                              this.orderdata.FINAL_ADMISSIBLE_AMOUNT = 0;
                            } else {
                              this.orderdata.FINAL_ADMISSIBLE_AMOUNT =
                                this.orderdata.FINAL_ADMISSIBLE_AMOUNT;
                            }

                            if (
                              this.orderdata.FINAL_CLAIMED_AMOUNT == null ||
                              this.orderdata.FINAL_CLAIMED_AMOUNT ==
                                undefined ||
                              this.orderdata.FINAL_CLAIMED_AMOUNT == 'NaN'
                            ) {
                              this.orderdata.FINAL_CLAIMED_AMOUNT = 0;
                            } else {
                              this.orderdata.FINAL_CLAIMED_AMOUNT =
                                this.orderdata.FINAL_CLAIMED_AMOUNT;
                            }

                            if (
                              this.orderdata.FINAL_REMAINING_AMOUNT == null ||
                              this.orderdata.FINAL_REMAINING_AMOUNT ==
                                undefined ||
                              this.orderdata.FINAL_REMAINING_AMOUNT == 'NaN'
                            ) {
                              this.orderdata.FINAL_REMAINING_AMOUNT = 0;
                            } else {
                              this.orderdata.FINAL_REMAINING_AMOUNT =
                                this.orderdata.FINAL_REMAINING_AMOUNT;
                            }
                            if (
                              this.orderdata.FINAL_CLAIMED_AMOUNT != null &&
                              this.orderdata.FINAL_CLAIMED_AMOUNT !=
                                undefined &&
                              this.orderdata.FINAL_CLAIMED_AMOUNT != ''
                            ) {
                              let finclaimeng = toWords.convert(this.am, {
                                currency: true,
                              });
                              this.orderdata.FINAL_CLAIMED_AMOUNT =
                                this.orderdata.FINAL_CLAIMED_AMOUNT;
                              this.finalclaimedineng = toWords.convert(
                                this.orderdata.FINAL_CLAIMED_AMOUNT,
                                {
                                  currency: true,
                                }
                              );

                              let finclaimhn = toWordsen.convert(this.am, {
                                currency: true,
                              });
                              this.orderdata.FINAL_CLAIMED_AMOUNT =
                                this.orderdata.FINAL_CLAIMED_AMOUNT;
                              this.finalclaimedinhindi = toWordsen.convert(
                                this.orderdata.FINAL_CLAIMED_AMOUNT,
                                {
                                  currency: true,
                                }
                              );
                            } else {
                              this.finalclaimedinhindi = '';
                              this.finalclaimedineng = '';
                            }

                            if (
                              this.orderdata.FINAL_ADMISSIBLE_AMOUNT != null &&
                              this.orderdata.FINAL_ADMISSIBLE_AMOUNT !=
                                undefined &&
                              this.orderdata.FINAL_ADMISSIBLE_AMOUNT != ''
                            ) {
                              let finadmeng = toWords.convert(this.am, {
                                currency: true,
                              });
                              this.orderdata.FINAL_ADMISSIBLE_AMOUNT =
                                this.orderdata.FINAL_ADMISSIBLE_AMOUNT;
                              this.finaladmissibleineng = toWords.convert(
                                this.orderdata.FINAL_ADMISSIBLE_AMOUNT,
                                {
                                  currency: true,
                                }
                              );

                              let finadmhn = toWordsen.convert(this.am, {
                                currency: true,
                              });
                              this.orderdata.FINAL_ADMISSIBLE_AMOUNT =
                                this.orderdata.FINAL_ADMISSIBLE_AMOUNT;
                              this.finaladmissibleinhindi = toWordsen.convert(
                                this.orderdata.FINAL_ADMISSIBLE_AMOUNT,
                                {
                                  currency: true,
                                }
                              );
                            } else {
                              this.finaladmissibleineng = '';
                              this.finaladmissibleinhindi = '';
                            }

                            if (
                              this.orderdata.ADVANCE_AMOUNT != null &&
                              this.orderdata.ADVANCE_AMOUNT != undefined &&
                              this.orderdata.ADVANCE_AMOUNT != ''
                            ) {
                              let adveng = toWords.convert(this.am, {
                                currency: true,
                              });
                              this.orderdata.ADVANCE_AMOUNT =
                                this.orderdata.ADVANCE_AMOUNT;
                              this.advanceineng = toWords.convert(
                                this.orderdata.ADVANCE_AMOUNT,
                                {
                                  currency: true,
                                }
                              );

                              let advhn = toWordsen.convert(this.am, {
                                currency: true,
                              });
                              this.orderdata.ADVANCE_AMOUNT =
                                this.orderdata.ADVANCE_AMOUNT;
                              this.advanceinhindi = toWordsen.convert(
                                this.orderdata.ADVANCE_AMOUNT,
                                {
                                  currency: true,
                                }
                              );
                            } else {
                              this.advanceineng = '';
                              this.advanceinhindi = '';
                            }

                            if (
                              this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT !=
                                null &&
                              this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT !=
                                undefined &&
                              this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT != ''
                            ) {
                              let advadmeng = toWords.convert(this.am, {
                                currency: true,
                              });
                              this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT =
                                this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT;
                              this.advadmissibleineng = toWords.convert(
                                this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT,
                                {
                                  currency: true,
                                }
                              );

                              let advadmhn = toWordsen.convert(this.am, {
                                currency: true,
                              });
                              this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT =
                                this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT;
                              this.advadmissibleinhindi = toWordsen.convert(
                                this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT,
                                {
                                  currency: true,
                                }
                              );
                            } else {
                              this.advadmissibleineng = '';
                              this.advadmissibleinhindi = '';
                            }

                            if (
                              this.orderdata.ADMISSIBLE_AMOUNT != null &&
                              this.orderdata.ADMISSIBLE_AMOUNT != undefined &&
                              this.orderdata.ADMISSIBLE_AMOUNT != ''
                            ) {
                              let admeng = toWords.convert(this.am, {
                                currency: true,
                              });
                              this.orderdata.ADMISSIBLE_AMOUNT =
                                this.orderdata.ADMISSIBLE_AMOUNT;
                              this.admissibleineng = toWords.convert(
                                this.orderdata.ADMISSIBLE_AMOUNT,
                                {
                                  currency: true,
                                }
                              );

                              let admhn = toWordsen.convert(this.am, {
                                currency: true,
                              });
                              this.orderdata.ADMISSIBLE_AMOUNT =
                                this.orderdata.ADMISSIBLE_AMOUNT;
                              this.admissibleinhindi = toWordsen.convert(
                                this.orderdata.ADMISSIBLE_AMOUNT,
                                {
                                  currency: true,
                                }
                              );
                            } else {
                              this.admissibleineng = '';
                              this.admissibleinhindi = '';
                            }

                            if (
                              this.orderdata.FINAL_REMAINING_AMOUNT != null &&
                              this.orderdata.FINAL_REMAINING_AMOUNT !=
                                undefined &&
                              this.orderdata.FINAL_REMAINING_AMOUNT != ''
                            ) {
                              let rem = toWords.convert(this.am, {
                                currency: true,
                              });
                              this.orderdata.FINAL_REMAINING_AMOUNT =
                                this.orderdata.FINAL_REMAINING_AMOUNT;
                              this.finalremainingineng = toWords.convert(
                                this.orderdata.FINAL_REMAINING_AMOUNT,
                                {
                                  currency: true,
                                }
                              );

                              let remhn = toWordsen.convert(this.am, {
                                currency: true,
                              });
                              this.orderdata.FINAL_REMAINING_AMOUNT =
                                this.orderdata.FINAL_REMAINING_AMOUNT;
                              this.finalremaininginhindi = toWordsen.convert(
                                this.orderdata.FINAL_REMAINING_AMOUNT,
                                {
                                  currency: true,
                                }
                              );
                            } else {
                              this.finalremainingineng = '';
                              this.finalremaininginhindi = '';
                            }
                            if (data['data'].length > 0) {
                              this.showlayoutDataList = data['data'];
                              this.total = 0;
                              this.total1 = 0;
                              this.advanceAdmissible = 0;

                              // for (var i = 0; data['count'] > i; i++) {
                              //   this.total =
                              //     this.total +
                              //     this.showlayoutDataList[i]['CLAIMED_AMOUNT'];
                              //   this.total1 =
                              //     this.total1 +
                              //     this.showlayoutDataList[i][
                              //       'ADMISSIBLE_AMOUNT'
                              //     ];
                              // }
                              // this.claimData.ADMISSIBLE_AMOUNT = this.total1;
                              // this.claimData.CLAIMED_AMOUNT = this.total;

                              this.total1 = Number(this.total1);
                              if (this.orderdata.IS_ADVANCE_TAKEN == 1) {
                                // this.advanceAdmissible = Math.round(
                                //   (this.total1 * 90) / 100
                                // );
                                this.advanceAdmissible =
                                  this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT;

                                if (
                                  this.advanceAdmissible ==
                                  this.orderdata.ADVANCE_AMOUNT
                                ) {
                                  this.isAdvanceSameAsAdmissible = true;
                                } else {
                                  this.isAdvanceSameAsAdmissible = false;
                                }
                              } else {
                                this.advanceAdmissible = 0;
                                this.isAdvanceSameAsAdmissible = false;
                              }
                              if (this.isAdvanceSameAsAdmissible == true) {
                                let wordsss = toWords.convert(this.am, {
                                  currency: true,
                                });
                                this.advanceAdmissible = this.advanceAdmissible;
                                this.amountinwordss = toWords.convert(
                                  this.advanceAdmissible,
                                  {
                                    currency: true,
                                  }
                                );

                                let wordssss = toWordsen.convert(this.am, {
                                  currency: true,
                                });
                                this.advanceAdmissible = this.advanceAdmissible;
                                this.amountinwordssh = toWordsen.convert(
                                  this.advanceAdmissible,
                                  {
                                    currency: true,
                                  }
                                );
                              } else {
                                let wordsss = toWords.convert(this.am, {
                                  currency: true,
                                });
                                this.orderdata.ADMISSIBLE_AMOUNT =
                                  this.orderdata.ADMISSIBLE_AMOUNT;
                                this.amountinwordss = toWords.convert(
                                  this.orderdata.ADMISSIBLE_AMOUNT,
                                  {
                                    currency: true,
                                  }
                                );

                                let wordssss = toWordsen.convert(this.am, {
                                  currency: true,
                                });
                                this.orderdata.ADMISSIBLE_AMOUNT =
                                  this.orderdata.ADMISSIBLE_AMOUNT;
                                this.amountinwordssh = toWordsen.convert(
                                  this.orderdata.ADMISSIBLE_AMOUNT,
                                  {
                                    currency: true,
                                  }
                                );
                              }
                              this.isSpinning = false;
                              this.Order = 'L';
                              this.orderDrawerVisible = true;
                            } else {
                              this.isSpinning = false;
                              this.Order = 'L';
                              this.isAdvanceSameAsAdmissible = false;
                              this.orderDrawerVisible = true;
                            }
                          } else {
                            this.isSpinning = false;
                          }
                        },
                        (err) => {
                          console.log(err);
                          this.isSpinning = false;
                        }
                      );
                  } else {
                    this.loadingRecords = false;
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
            } else {
              this.loadingRecords = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }

  admissibleineng: any = '';
  admissibleinhindi: any = '';
  advadmissibleineng: any = '';
  advadmissibleinhindi: any = '';
  advanceineng: any = '';
  advanceinhindi: any = '';
  finaladmissibleineng: any = '';
  finaladmissibleinhindi: any = '';
  finalclaimedineng: any = '';
  finalclaimedinhindi: any = '';
  finalremainingineng: any = '';
  finalremaininginhindi: any = '';
  openAdvanceOrderDrawer(data: any): void {
    this.Order = '';
    this.loadingRecords = true;
    if (
      data.FINAL_REMAINING_AMOUNT == null ||
      data.FINAL_REMAINING_AMOUNT == undefined ||
      data.FINAL_REMAINING_AMOUNT == 0 ||
      data.FINAL_REMAINING_AMOUNT == ''
    ) {
      this.message.info('Please Fill Advance Annexure Details First', '');
      this.loadingRecords = false;
    } else {
      this.orderdata = data;
      this.isAdvanceSameAsAdmissible = false;
      this.admissibleineng = '';
      this.admissibleinhindi = '';
      this.advadmissibleineng = '';
      this.advadmissibleinhindi = '';
      this.advanceineng = '';
      this.advanceinhindi = '';
      this.finaladmissibleineng = '';
      this.finaladmissibleinhindi = '';
      this.finalclaimedineng = '';
      this.finalclaimedinhindi = '';
      this.finalremainingineng = '';
      this.finalremaininginhindi = '';
      var filterQuery =
        ' AND CURRENT_POSITION_ID = ' +
        sessionStorage.getItem('userId') +
        ' AND (CLAIM_ID=null OR CLAIM_ID=0)';
      this.api
        .getFileMaster(
          0,
          0,
          'ID',
          'ASC',
          filterQuery,
          sessionStorage.getItem('userId')
        )
        .subscribe(
          (data: any) => {
            if (data['code'] == 200 && data['count'] > 0) {
              this.fileList = data['data'];
            } else {
              this.fileList = [];
              // this.loadingRecords = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
      this.api
        .getSignature(0, 0, '', '', ' AND ID = ' + data.SIGNATURE_ID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              this.SECTION_TYPE = data['data'][0]['SECTION_TYPE'];
              this.NAME = data['data'][0]['NAME'];
              this.NAME_HN = data['data'][0]['NAME_HN'];
              this.OFFICE_NAME = data['data'][0]['OFFICE_NAME'];
              this.OFFICE_NAME_HN = data['data'][0]['OFFICE_NAME_HN'];
              this.POST = data['data'][0]['POST'];
              this.POST_HN = data['data'][0]['POST_HN'];
              // this.loadingRecords = false;
              // this.orderDrawerVisible = true;
            } else {
              this.message.error('Something Went Wrong', '');
              this.loadingRecords = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
      this.orderDrawerTitle = 'Order Details';
      this.Signaturearray = [];
      this.api
        .getHospitalMapping(0, 0, 'ID', 'ASC', ' AND CLAIM_ID = ' + data.ID)
        .subscribe(
          (data: any) => {
            if (data['code'] == 200) {
              if (data['count'] > 0) {
                this.HospitalMapping = data['data'];
              } else {
                this.HospitalMapping = [];
              }
              this.api.getSignature(0, 0, 'ID', 'desc', '').subscribe(
                (data) => {
                  if (data['code'] == 200) {
                    if (
                      this.orderdata.ADMISSIBLE_AMOUNT == null ||
                      this.orderdata.ADMISSIBLE_AMOUNT == undefined ||
                      this.orderdata.ADMISSIBLE_AMOUNT == 'NaN'
                    ) {
                      this.orderdata.ADMISSIBLE_AMOUNT = 0;
                    } else {
                      this.orderdata.ADMISSIBLE_AMOUNT =
                        this.orderdata.ADMISSIBLE_AMOUNT;
                    }

                    if (
                      this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT == null ||
                      this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT == undefined ||
                      this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT == 'NaN'
                    ) {
                      this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT = 0;
                    } else {
                      this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT =
                        this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT;
                    }

                    if (
                      this.orderdata.ADVANCE_AMOUNT == null ||
                      this.orderdata.ADVANCE_AMOUNT == undefined ||
                      this.orderdata.ADVANCE_AMOUNT == 'NaN'
                    ) {
                      this.orderdata.ADVANCE_AMOUNT = 0;
                    } else {
                      this.orderdata.ADVANCE_AMOUNT =
                        this.orderdata.ADVANCE_AMOUNT;
                    }

                    if (
                      this.orderdata.FINAL_ADMISSIBLE_AMOUNT == null ||
                      this.orderdata.FINAL_ADMISSIBLE_AMOUNT == undefined ||
                      this.orderdata.FINAL_ADMISSIBLE_AMOUNT == 'NaN'
                    ) {
                      this.orderdata.FINAL_ADMISSIBLE_AMOUNT = 0;
                    } else {
                      this.orderdata.FINAL_ADMISSIBLE_AMOUNT =
                        this.orderdata.FINAL_ADMISSIBLE_AMOUNT;
                    }

                    if (
                      this.orderdata.FINAL_CLAIMED_AMOUNT == null ||
                      this.orderdata.FINAL_CLAIMED_AMOUNT == undefined ||
                      this.orderdata.FINAL_CLAIMED_AMOUNT == 'NaN'
                    ) {
                      this.orderdata.FINAL_CLAIMED_AMOUNT = 0;
                    } else {
                      this.orderdata.FINAL_CLAIMED_AMOUNT =
                        this.orderdata.FINAL_CLAIMED_AMOUNT;
                    }

                    if (
                      this.orderdata.FINAL_REMAINING_AMOUNT == null ||
                      this.orderdata.FINAL_REMAINING_AMOUNT == undefined ||
                      this.orderdata.FINAL_REMAINING_AMOUNT == 'NaN'
                    ) {
                      this.orderdata.FINAL_REMAINING_AMOUNT = 0;
                    } else {
                      this.orderdata.FINAL_REMAINING_AMOUNT =
                        this.orderdata.FINAL_REMAINING_AMOUNT;
                    }

                    if (
                      this.orderdata.FINAL_CLAIMED_AMOUNT != null &&
                      this.orderdata.FINAL_CLAIMED_AMOUNT != undefined &&
                      this.orderdata.FINAL_CLAIMED_AMOUNT != ''
                    ) {
                      let finclaimeng = toWords.convert(this.am, {
                        currency: true,
                      });
                      this.orderdata.FINAL_CLAIMED_AMOUNT =
                        this.orderdata.FINAL_CLAIMED_AMOUNT;
                      this.finalclaimedineng = toWords.convert(
                        this.orderdata.FINAL_CLAIMED_AMOUNT,
                        {
                          currency: true,
                        }
                      );

                      let finclaimhn = toWordsen.convert(this.am, {
                        currency: true,
                      });
                      this.orderdata.FINAL_CLAIMED_AMOUNT =
                        this.orderdata.FINAL_CLAIMED_AMOUNT;
                      this.finalclaimedinhindi = toWordsen.convert(
                        this.orderdata.FINAL_CLAIMED_AMOUNT,
                        {
                          currency: true,
                        }
                      );
                    } else {
                      this.finalclaimedinhindi = '';
                      this.finalclaimedineng = '';
                    }

                    if (
                      this.orderdata.FINAL_ADMISSIBLE_AMOUNT != null &&
                      this.orderdata.FINAL_ADMISSIBLE_AMOUNT != undefined &&
                      this.orderdata.FINAL_ADMISSIBLE_AMOUNT != ''
                    ) {
                      let finadmeng = toWords.convert(this.am, {
                        currency: true,
                      });
                      this.orderdata.FINAL_ADMISSIBLE_AMOUNT =
                        this.orderdata.FINAL_ADMISSIBLE_AMOUNT;
                      this.finaladmissibleineng = toWords.convert(
                        this.orderdata.FINAL_ADMISSIBLE_AMOUNT,
                        {
                          currency: true,
                        }
                      );

                      let finadmhn = toWordsen.convert(this.am, {
                        currency: true,
                      });
                      this.orderdata.FINAL_ADMISSIBLE_AMOUNT =
                        this.orderdata.FINAL_ADMISSIBLE_AMOUNT;
                      this.finaladmissibleinhindi = toWordsen.convert(
                        this.orderdata.FINAL_ADMISSIBLE_AMOUNT,
                        {
                          currency: true,
                        }
                      );
                    } else {
                      this.finaladmissibleineng = '';
                      this.finaladmissibleinhindi = '';
                    }

                    if (
                      this.orderdata.ADVANCE_AMOUNT != null &&
                      this.orderdata.ADVANCE_AMOUNT != undefined &&
                      this.orderdata.ADVANCE_AMOUNT != ''
                    ) {
                      let adveng = toWords.convert(this.am, { currency: true });
                      this.orderdata.ADVANCE_AMOUNT =
                        this.orderdata.ADVANCE_AMOUNT;
                      this.advanceineng = toWords.convert(
                        this.orderdata.ADVANCE_AMOUNT,
                        {
                          currency: true,
                        }
                      );

                      let advhn = toWordsen.convert(this.am, {
                        currency: true,
                      });
                      this.orderdata.ADVANCE_AMOUNT =
                        this.orderdata.ADVANCE_AMOUNT;
                      this.advanceinhindi = toWordsen.convert(
                        this.orderdata.ADVANCE_AMOUNT,
                        {
                          currency: true,
                        }
                      );
                    } else {
                      this.advanceineng = '';
                      this.advanceinhindi = '';
                    }

                    if (
                      this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT != null &&
                      this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT != undefined &&
                      this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT != ''
                    ) {
                      let advadmeng = toWords.convert(this.am, {
                        currency: true,
                      });
                      this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT =
                        this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT;
                      this.advadmissibleineng = toWords.convert(
                        this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT,
                        {
                          currency: true,
                        }
                      );

                      let advadmhn = toWordsen.convert(this.am, {
                        currency: true,
                      });
                      this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT =
                        this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT;
                      this.advadmissibleinhindi = toWordsen.convert(
                        this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT,
                        {
                          currency: true,
                        }
                      );
                    } else {
                      this.advadmissibleineng = '';
                      this.advadmissibleinhindi = '';
                    }

                    if (
                      this.orderdata.ADMISSIBLE_AMOUNT != null &&
                      this.orderdata.ADMISSIBLE_AMOUNT != undefined &&
                      this.orderdata.ADMISSIBLE_AMOUNT != ''
                    ) {
                      let admeng = toWords.convert(this.am, { currency: true });
                      this.orderdata.ADMISSIBLE_AMOUNT =
                        this.orderdata.ADMISSIBLE_AMOUNT;
                      this.admissibleineng = toWords.convert(
                        this.orderdata.ADMISSIBLE_AMOUNT,
                        {
                          currency: true,
                        }
                      );

                      let admhn = toWordsen.convert(this.am, {
                        currency: true,
                      });
                      this.orderdata.ADMISSIBLE_AMOUNT =
                        this.orderdata.ADMISSIBLE_AMOUNT;
                      this.admissibleinhindi = toWordsen.convert(
                        this.orderdata.ADMISSIBLE_AMOUNT,
                        {
                          currency: true,
                        }
                      );
                    } else {
                      this.admissibleineng = '';
                      this.admissibleinhindi = '';
                    }

                    if (
                      this.orderdata.FINAL_REMAINING_AMOUNT != null &&
                      this.orderdata.FINAL_REMAINING_AMOUNT != undefined &&
                      this.orderdata.FINAL_REMAINING_AMOUNT != ''
                    ) {
                      let rem = toWords.convert(this.am, { currency: true });
                      this.orderdata.FINAL_REMAINING_AMOUNT =
                        this.orderdata.FINAL_REMAINING_AMOUNT;
                      this.finalremainingineng = toWords.convert(
                        this.orderdata.FINAL_REMAINING_AMOUNT,
                        {
                          currency: true,
                        }
                      );

                      let remhn = toWordsen.convert(this.am, {
                        currency: true,
                      });
                      this.orderdata.FINAL_REMAINING_AMOUNT =
                        this.orderdata.FINAL_REMAINING_AMOUNT;
                      this.finalremaininginhindi = toWordsen.convert(
                        this.orderdata.FINAL_REMAINING_AMOUNT,
                        {
                          currency: true,
                        }
                      );
                    } else {
                      this.finalremainingineng = '';
                      this.finalremaininginhindi = '';
                    }

                    if (
                      this.orderdata.ADVANCE_ADMISSIBLE_AMOUNT ==
                      this.orderdata.ADVANCE_AMOUNT
                    ) {
                      this.isAdvanceSameAsAdmissible = true;
                    } else {
                      this.isAdvanceSameAsAdmissible = false;
                    }

                    this.Signaturearray = data['data'];
                    this.loadingRecords = false;
                    this.Order = 'AO';
                    this.orderDrawerVisible = true;
                    this.Spin = false;
                  } else {
                    this.loadingRecords = false;
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
            } else {
              this.loadingRecords = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }
  isVisible: boolean = false;

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  investigationDrawerVisible: boolean = false;
  investigationDrawerTitle: string;
  investigationDrawerData: InvestigationMaster = new InvestigationMaster();
  showlayoutDataList = [];
  total = 0;
  total1 = 0;
  advanceAdmissible = 0;
  citylist = [];
  hospitallist = [];
  ACCREDITATION = '';
  openInvestigationDrawer(data: any): void {
    this.total = 0;
    this.total1 = 0;
    if (data != null || data != undefined) {
      this.claimData = data;
      this.investigationDrawerTitle = 'Create New Investigation';
      this.investigationDrawerData = new InvestigationMaster();
      this.citylist = [];
      this.loadingRecords = true;
      this.api.getCityMaster(0, 0, 'NAME', 'ASC', ' AND STATUS = 1').subscribe(
        (data) => {
          if (data['code'] == 200) {
            if (data['data'].length > 0) {
              this.citylist = data['data'];
            } else {
              this.citylist = [];
            }
            this.api
              .getHospitalMapping(
                0,
                0,
                'NAME',
                'ASC',
                ' AND CLAIM_ID=' + this.claimData.ID
              )
              .subscribe(
                (data) => {
                  if (data['code'] == 200) {
                    if (data['data'].length > 0) {
                      this.hospitallist = data['data'];
                      this.investigationDrawerData.CLAIM_HOSPITAL_MAPPING_ID =
                        this.hospitallist[0]['ID'];
                      this.ACCREDITATION = this.hospitallist[0]['ACCREDATION'];
                    } else {
                      this.hospitallist = [];
                    }
                    this.api
                      .getannexture(
                        0,
                        0,
                        'ID',
                        'ASC',
                        ' AND STATUS=1 AND IS_ADVANCE = 0 AND CLAIM_ID=' +
                          this.claimData.ID
                      )
                      .subscribe(
                        (data) => {
                          if (data['code'] == 200) {
                            this.showlayoutDataList = data['data'];
                            this.total = 0;
                            this.total1 = 0;
                            if (data['data'].length > 0) {
                              for (
                                var i = 0;
                                this.showlayoutDataList.length > i;
                                i++
                              ) {
                                this.total =
                                  this.total +
                                  this.showlayoutDataList[i]['CLAIMED_AMOUNT'];
                                this.total1 =
                                  this.total1 +
                                  this.showlayoutDataList[i][
                                    'ADMISSIBLE_AMOUNT'
                                  ];
                              }
                            } else {
                              this.total = 0;
                              this.total1 = 0;
                            }
                            this.total1 = Math.round(this.total1);
                            if (this.claimData.IS_ADVANCE_TAKEN == 1) {
                              this.advanceAdmissible = Math.round(
                                (this.total1 * 90) / 100
                              );
                            } else {
                              this.advanceAdmissible = 0;
                            }
                            this.claimData.ADVANCE_ADMISSIBLE_AMOUNT =
                              Math.round(this.advanceAdmissible);
                            this.loadingRecords = false;
                            this.investigationDrawerVisible = true;
                          } else {
                            this.loadingRecords = false;
                          }
                        },
                        (err) => {
                          console.log(err);
                          this.isSpinning = false;
                        }
                      );
                  } else {
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
          } else {
            this.loadingRecords = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
    }
  }

  investigationDrawerClose(): void {
    this.investigationDrawerVisible = false;
    this.search();
  }

  get investigationDrawerCloseCallback() {
    return this.investigationDrawerClose.bind(this);
  }

  advanceAnnexureVisible: boolean = false;
  openAdvanceAnnexure(data: any): void {
    if (data != null || data != undefined) {
      this.total = 0;
      this.total1 = 0;
      this.claimData = data;
      this.investigationDrawerTitle = 'Create New Advance Annexure A';
      this.investigationDrawerData = new InvestigationMaster();
      this.citylist = [];
      this.loadingRecords = true;
      this.api.getCityMaster(0, 0, 'NAME', 'ASC', ' AND STATUS = 1').subscribe(
        (data) => {
          if (data['code'] == 200) {
            if (data['data'].length > 0) {
              this.citylist = data['data'];
            } else {
              this.citylist = [];
            }
          } else {
            this.loadingRecords = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );
      this.api
        .getannexture(
          0,
          0,
          'ID',
          'ASC',
          ' AND STATUS=1 AND IS_ADVANCE = 1 AND CLAIM_ID=' + this.claimData.ID
        )
        .subscribe(
          (data) => {
            this.showlayoutDataList = data['data'];
            this.total = 0;
            this.total1 = 0;
            if (data['data'].length > 0) {
              for (var i = 0; this.showlayoutDataList.length > i; i++) {
                this.total =
                  this.total + this.showlayoutDataList[i]['CLAIMED_AMOUNT'];
                this.total1 =
                  this.total1 + this.showlayoutDataList[i]['ADMISSIBLE_AMOUNT'];
              }
            } else {
              this.total = 0;
              this.total1 = 0;
            }
            this.total1 = Math.round(this.total1);
            if (this.claimData.IS_ADVANCE_TAKEN == 1) {
              this.advanceAdmissible = Math.round(this.total1);
            } else {
              this.advanceAdmissible = 0;
            }
            this.claimData.ADVANCE_ADMISSIBLE_AMOUNT = Math.round(
              this.advanceAdmissible
            );
            this.loadingRecords = false;
            this.advanceAnnexureVisible = true;
          },
          (err) => {
            console.log(err);
            this.isSpinning = false;
          }
        );
      this.api
        .getHospitalMapping(0, 0, 'NAME', 'ASC', ' AND CLAIM_ID=' + data.ID)
        .subscribe(
          (data) => {
            if (data['code'] == 200 && data['data'].length > 0)
              this.hospitallist = data['data'];
            this.investigationDrawerData.CLAIM_HOSPITAL_MAPPING_ID =
              this.hospitallist[0]['ID'];

            this.ACCREDITATION = this.hospitallist[0]['ACCREDATION'];
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
    }
  }

  advanceAnnexureDrawerClose(): void {
    this.advanceAnnexureVisible = false;
    this.search();
  }

  get advanceAnnexureDrawerCloseCallback() {
    return this.advanceAnnexureDrawerClose.bind(this);
  }

  drawerLogVisible: boolean = false;
  drawerLogTitle: string = '';
  drawerLogData = [];

  openLogDrawer(): void {
    this.drawerLogTitle = 'Claim Log Details';
    this.drawerLogVisible = true;
  }

  drawerLogClose(): void {
    this.search();
    this.drawerLogVisible = false;
  }

  get closeLogCallback() {
    return this.drawerLogClose.bind(this);
  }

  applyStageFilter(event: any, stageId: string) {
    this.filterQuery = '';
    console.log('event', stageId);
    if (event == 'N') {
      this.newButton = 'primary';
      this.forwardButton = 'default';
      this.rejectButton = 'default';
      this.queryButton = 'default';
      this.approvedButton = 'default';
      this.readyButton = 'default';
      this.totalButton = 'default';
    } else if (event == 'F') {
      this.newButton = 'default';
      this.forwardButton = 'primary';
      this.rejectButton = 'default';
      this.queryButton = 'default';
      this.approvedButton = 'default';
      this.readyButton = 'default';
      this.totalButton = 'default';
    } else if (event == 'R') {
      this.newButton = 'default';
      this.forwardButton = 'default';
      this.rejectButton = 'primary';
      this.queryButton = 'default';
      this.approvedButton = 'default';
      this.readyButton = 'default';
      this.totalButton = 'default';
    } else if (event == 'Q') {
      this.newButton = 'default';
      this.forwardButton = 'default';
      this.rejectButton = 'default';
      this.queryButton = 'primary';
      this.approvedButton = 'default';
      this.readyButton = 'default';
      this.totalButton = 'default';
    } else if (event == 'A') {
      this.newButton = 'default';
      this.forwardButton = 'default';
      this.rejectButton = 'default';
      this.queryButton = 'default';
      this.approvedButton = 'primary';
      this.readyButton = 'default';
      this.totalButton = 'default';
    } else if (event == 'Z') {
      this.newButton = 'default';
      this.forwardButton = 'default';
      this.rejectButton = 'default';
      this.queryButton = 'default';
      this.approvedButton = 'default';
      this.readyButton = 'primary';
      this.totalButton = 'default';
    } else {
      this.newButton = 'default';
      this.forwardButton = 'default';
      this.rejectButton = 'default';
      this.queryButton = 'default';
      this.approvedButton = 'default';
      this.readyButton = 'default';
      this.totalButton = 'primary';
    }
    // this.loadingRecords = true;
    if (stageId != ' ') {
      var filterId = stageId.split(',');
      var filterQuery = ' AND (';
      for (var i = 0; i < filterId.length; i++) {
        filterQuery =
          filterQuery + ' CURRENT_STAGE_ID =' + filterId[i] + ' OR ';
      }
      filterQuery = filterQuery.substring(0, filterQuery.length - 3) + ')';
      this.filterQuery = this.filterQuery + filterQuery;
      console.log('this.filterQuery', this.filterQuery);
    } else {
      this.filterQuery = '';
      console.log('this.filterQuery2', this.filterQuery);
    }
    // this.loadingRecords = false;
    this.applyFilter();
  }

  confirm(data: any, i: any) {
    console.log('data', data);
    console.log('i', i);
    var data1 = {
      ID: i,
      CURRENT_STAGE_ID: 6,
    };
    console.log('data1', data1);

    // data.CURRENT_STAGE_ID = 6;
    // this.api.updateclaimed(data)
    console.log('AA', this.api.updateclaimed(data));
  }
  cancel(): void {}

  // deleteConfirm(data: any) {
  //   this.loadingRecords = true;
  //   console.log('data', data);
  //   var data1 = {
  //     ID: data.ID,
  //     EMP_ID: data.EMP_ID,
  //   };
  //   this.api.deleteClaim(data1).subscribe((successCode) => {
  //     if (successCode.code == '200') {
  //       this.message.success('Claim Deleted Successfully...', '');
  //       // if (!addNew) this.drawerClose();
  //       this.search();
  //       this.loadingRecords = false;
  //     } else {
  //       this.message.error('Information Has Not Deleted...', '');
  //       this.loadingRecords = false;
  //     }
  //   });
  // }

  deleteConfirm(data: any) {
    this.loadingRecords = true;

    console.log('data', data);

    var data1 = {
      ID: data.ID,
      EMP_ID: data.EMP_ID,
      IS_DELETED: '1',
      IS_ADVANCE_TAKEN: data.IS_ADVANCE_TAKEN,
      CURRENT_STAGE_ID: data.CURRENT_STAGE_ID,
      PAY_BILL: data.PAY_BILL,
      SIGNATURE_ID: data.SIGNATURE_ID,
    };
    this.api.updateClaim(data1).subscribe((successCode) => {
      if (successCode.code == '200') {
        this.message.success('Claim Deleted Successfully...', '');
        // if (!addNew) this.drawerClose();
        this.search();
        this.loadingRecords = false;
      } else {
        this.message.error('Information Has Not Deleted...', '');
        this.loadingRecords = false;
      }
    });
  }
  deleteCancel(): void {}

  // rejectConfirm(data: any) {
  //   // data.CURRENT_STAGE_ID = 5;
  //   // this.api.updateClaim();
  // }
  // rejectCancel(): void {}

  drawerClaimData: any;
  drawerClaimTitle = '';
  drawerClaimVisible = false;
  QUESTIONARIES: any;
  checkList: any;

  amountInwords = '';
  SECTION_TYPE: any;
  NAME: any;
  NAME_HN: any;
  OFFICE_NAME: any;
  OFFICE_NAME_HN: any;
  POST: any;
  POST_HN: any;
  viewFile(data: any): void {
    this.drawerClaimTitle = 'View Claim File';
    this.loadingRecords = true;
    this.drawerClaimData = Object.assign({}, data);
    if (this.drawerClaimData.IS_ADVANCE_TAKEN == 1) {
      if (
        data.ADVANCE_ADMISSIBLE_AMOUNT != undefined &&
        data.ADVANCE_ADMISSIBLE_AMOUNT != null
      ) {
        this.amountInwords = toWords.convert(
          this.drawerClaimData.ADVANCE_ADMISSIBLE_AMOUNT,
          { currency: true }
        );

        let wordss = toWordsen.convert(this.am, { currency: true });
        data.ADVANCE_ADMISSIBLE_AMOUNT = data.ADVANCE_ADMISSIBLE_AMOUNT;
        this.amountinwordsh = toWordsen.convert(
          data.ADVANCE_ADMISSIBLE_AMOUNT,
          {
            currency: true,
          }
        );
      } else {
      }
    } else {
      if (
        data.ADMISSIBLE_AMOUNT != undefined &&
        data.ADMISSIBLE_AMOUNT != null
      ) {
        this.amountInwords = toWords.convert(
          this.drawerClaimData.ADMISSIBLE_AMOUNT,
          { currency: true }
        );

        let wordss = toWordsen.convert(this.am, { currency: true });
        data.ADMISSIBLE_AMOUNT = data.ADMISSIBLE_AMOUNT;
        this.amountinwordsh = toWordsen.convert(data.ADMISSIBLE_AMOUNT, {
          currency: true,
        });
      } else {
      }
    }
    this.api
      .getSignature(0, 0, 'ID', 'desc', ' AND ID = ' + data.SIGNATURE_ID)
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            this.SECTION_TYPE = data['data'][0]['SECTION_TYPE'];
            this.api
              .getHospitalMapping(
                0,
                0,
                'NAME',
                'ASC',
                ' AND CLAIM_ID=' + this.drawerClaimData.ID
              )
              .subscribe(
                (data) => {
                  if (data['code'] == 200) {
                    if (data['data'].length > 0) {
                      this.hospitallist = data['data'];
                    } else {
                      this.hospitallist = [];
                    }
                    this.drawerClaimVisible = true;
                  } else {
                    this.loadingRecords = false;
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
          } else {
            this.loadingRecords = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  drawerAdvanceClaimVisible: boolean = false;
  drawerAdvanceClaimTitle: string = '';
  viewFileForAdvance(data: any): void {
    this.drawerAdvanceClaimTitle = 'View Advance Claim File';
    this.loadingRecords = true;
    this.drawerClaimData = Object.assign({}, data);
    if (this.drawerClaimData.IS_ADVANCE_TAKEN == 1) {
      if (
        data.FINAL_REMAINING_AMOUNT != undefined &&
        data.FINAL_REMAINING_AMOUNT != null
      ) {
        this.amountInwords = toWords.convert(
          this.drawerClaimData.FINAL_REMAINING_AMOUNT,
          { currency: true }
        );

        let wordss = toWordsen.convert(this.am, { currency: true });
        data.FINAL_REMAINING_AMOUNT = data.FINAL_REMAINING_AMOUNT;
        this.amountinwordsh = toWordsen.convert(data.FINAL_REMAINING_AMOUNT, {
          currency: true,
        });
      } else {
      }
    } else {
      if (data.ADVANCE_AMOUNT != undefined && data.ADVANCE_AMOUNT != null) {
        this.amountInwords = toWords.convert(
          this.drawerClaimData.ADVANCE_AMOUNT,
          { currency: true }
        );

        let wordss = toWordsen.convert(this.am, { currency: true });
        data.ADVANCE_AMOUNT = data.ADVANCE_AMOUNT;
        this.amountinwordsh = toWordsen.convert(data.ADVANCE_AMOUNT, {
          currency: true,
        });
      } else {
      }
    }
    this.api
      .getSignature(0, 0, 'ID', 'desc', ' AND ID = ' + data.SIGNATURE_ID)
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            this.SECTION_TYPE = data['data'][0]['SECTION_TYPE'];
            this.api
              .getHospitalMapping(
                0,
                0,
                'NAME',
                'ASC',
                ' AND CLAIM_ID=' + this.drawerClaimData.ID
              )
              .subscribe(
                (data) => {
                  if (data['code'] == 200) {
                    if (data['data'].length > 0) {
                      this.hospitallist = data['data'];
                    } else {
                      this.hospitallist = [];
                    }
                    this.drawerAdvanceClaimVisible = true;
                  } else {
                    this.loadingRecords = false;
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
          } else {
            this.loadingRecords = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  viewFileNew(data: any): void {
    this.drawerClaimTitle = 'View Claim File';
    this.loadingRecords = true;
    this.drawerClaimData = Object.assign({}, data);
    if (this.drawerClaimData.IS_ADVANCE_TAKEN == 1) {
      if (
        data.ADVANCE_AMOUNT != null &&
        data.ADVANCE_AMOUNT != undefined &&
        data.ADVANCE_AMOUNT != ''
      ) {
        this.amountInwords = toWords.convert(data.ADVANCE_AMOUNT, {
          currency: true,
        });

        let wordss = toWordsen.convert(this.am, { currency: true });
        data.ADVANCE_AMOUNT = data.ADVANCE_AMOUNT;
        this.amountinwordsh = toWordsen.convert(data.ADVANCE_AMOUNT, {
          currency: true,
        });
      } else {
        this.amountInwords = '';
        this.amountinwordsh = '';
      }
      // if (
      //   data.ADVANCE_AMOUNT != undefined &&
      //   data.ADVANCE_AMOUNT != null &&
      //   data.ADMISSIBLE_AMOUNT != undefined &&
      //   data.ADMISSIBLE_AMOUNT != null
      // ) {
      //   if (data.ADVANCE_AMOUNT > data.ADMISSIBLE_AMOUNT) {
      //     this.amountInwords = toWords.convert(
      //       data.ADVANCE_AMOUNT - data.ADMISSIBLE_AMOUNT,
      //       { currency: true }
      //     );

      //     let wordss = toWordsen.convert(this.am, { currency: true });
      //     data.ADVANCE_AMOUNT = data.ADVANCE_AMOUNT;
      //     this.amountinwordsh = toWordsen.convert(
      //       data.ADVANCE_AMOUNT - data.ADMISSIBLE_AMOUNT,
      //       {
      //         currency: true,
      //       }
      //     );
      //   } else {
      //     this.amountInwords = toWords.convert(
      //       data.ADMISSIBLE_AMOUNT - data.ADVANCE_AMOUNT,
      //       { currency: true }
      //     );

      //     let wordss = toWordsen.convert(this.am, { currency: true });
      //     data.ADVANCE_AMOUNT = data.ADVANCE_AMOUNT;
      //     this.amountinwordsh = toWordsen.convert(
      //       data.ADMISSIBLE_AMOUNT - data.ADVANCE_AMOUNT,
      //       {
      //         currency: true,
      //       }
      //     );
      //   }
      // } else {
      // }
    } else {
      if (
        data.ADMISSIBLE_AMOUNT != undefined &&
        data.ADMISSIBLE_AMOUNT != null &&
        data.ADMISSIBLE_AMOUNT != ''
      ) {
        this.amountInwords = toWords.convert(
          this.drawerClaimData.ADMISSIBLE_AMOUNT,
          { currency: true }
        );

        let wordss = toWordsen.convert(this.am, { currency: true });
        data.ADMISSIBLE_AMOUNT = data.ADMISSIBLE_AMOUNT;
        this.amountinwordsh = toWordsen.convert(data.ADMISSIBLE_AMOUNT, {
          currency: true,
        });
      } else {
        this.amountInwords = '';
        this.amountinwordsh = '';
      }
    }
    this.api
      .getSignature(0, 0, 'ID', 'desc', ' AND ID = ' + data.SIGNATURE_ID)
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            this.SECTION_TYPE = data['data'][0]['SECTION_TYPE'];
            this.api
              .getHospitalMapping(
                0,
                0,
                'NAME',
                'ASC',
                ' AND CLAIM_ID=' + this.drawerClaimData.ID
              )
              .subscribe(
                (data) => {
                  if (data['code'] == 200) {
                    if (data['data'].length > 0) {
                      this.hospitallist = data['data'];
                    } else {
                      this.hospitallist = [];
                    }
                    this.drawerNewClaimVisible = true;
                  } else {
                    this.loadingRecords = false;
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
          } else {
            this.loadingRecords = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }
  drawerNewClaimVisible: boolean = false;
  drawerNewClaimClose(): void {
    this.drawerNewClaimVisible = false;
    this.search();
  }

  get closeNewClaimCallback() {
    return this.drawerNewClaimClose.bind(this);
  }
  drawerAdvanceClaimClose(): void {
    this.drawerAdvanceClaimVisible = false;
    this.search();
  }

  get closeAdvanceClaimCallback() {
    return this.drawerAdvanceClaimClose.bind(this);
  }
  drawerClaimClose(): void {
    this.drawerClaimVisible = false;
    this.search();
  }

  get closeClaimCallback() {
    return this.drawerClaimClose.bind(this);
  }

  fileName: string = 'Claim';
  pdfDownload: boolean = false;

  public generatePDF() {
    var i = 0;
    var date = new Date();
    var datef = this.datePipe.transform(date, 'dd-MM-yyyy');
    var dates = this.datePipe.transform(date, 'hh-mm-ss a');
    var data = document.getElementById('claimFile');

    html2pdf()
      .from(data)
      .set({
        margin: [5, 10, 2, 5],
        pagebreak: { mode: ['css', 'legecy'] },
        jsPDF: { unit: 'mm', format: 'A4', orientation: 'portrait' },
      })
      .toPdf()
      .get('pdf')
      .then(function (pdf) {
        this.pdfDownload = true;
        var totalPages = pdf.internal.getNumberOfPages();

        for (i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(12);
          pdf.setTextColor(150);
          pdf.text(i.toString(), pdf.internal.pageSize.getWidth() / 2, 10);
        }

        this.pdfDownload = false;
      })
      .save(this.fileName + '_' + datef + '_' + dates + '.pdf');
  }
  public generateNewPDF() {
    var i = 0;
    var date = new Date();
    var datef = this.datePipe.transform(date, 'dd-MM-yyyy');
    var dates = this.datePipe.transform(date, 'hh-mm-ss a');
    var data = document.getElementById('newClaimFile');

    html2pdf()
      .from(data)
      .set({
        margin: [5, 10, 2, 5],
        pagebreak: { mode: ['css', 'legecy'] },
        jsPDF: { unit: 'mm', format: 'A4', orientation: 'portrait' },
      })
      .toPdf()
      .get('pdf')
      .then(function (pdf) {
        this.pdfDownload = true;
        var totalPages = pdf.internal.getNumberOfPages();

        for (i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(12);
          pdf.setTextColor(150);
          pdf.text(i.toString(), pdf.internal.pageSize.getWidth() / 2, 10);
        }

        this.pdfDownload = false;
      })
      .save(this.fileName + '_' + datef + '_' + dates + '.pdf');
  }
  public generateAdvancePDF() {
    var i = 0;
    var date = new Date();
    var datef = this.datePipe.transform(date, 'dd-MM-yyyy');
    var dates = this.datePipe.transform(date, 'hh-mm-ss a');
    var data = document.getElementById('advanceClaimFile');

    html2pdf()
      .from(data)
      .set({
        margin: [5, 10, 2, 5],
        pagebreak: { mode: ['css', 'legecy'] },
        jsPDF: { unit: 'mm', format: 'A4', orientation: 'portrait' },
      })
      .toPdf()
      .get('pdf')
      .then(function (pdf) {
        this.pdfDownload = true;
        var totalPages = pdf.internal.getNumberOfPages();

        for (i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(12);
          pdf.setTextColor(150);
          pdf.text(i.toString(), pdf.internal.pageSize.getWidth() / 2, 10);
        }

        this.pdfDownload = false;
      })
      .save(this.fileName + '_' + datef + '_' + dates + '.pdf');
  }

  isApproveVisible = false;
  FileId;
  selectedData;
  AO_ID;
  REMARK;

  openApproveModalHP(data) {
    this.selectedData = '';
    this.AO_ID = '';
    this.REMARK = '';
    this.isApproveVisible = true;
    this.selectedData = data;
    this.FileId = data.ID;
    this.loadAllUsers();
  }

  handleApproveCancel() {
    this.isApproveVisible = false;
  }

  handleApproveOk() {
    if (this.AO_ID == null || this.AO_ID == '' || this.AO_ID == undefined) {
      this.message.error('Please Select AO.', '');
    } else {
      var data2 = {
        ID: this.selectedData.ID,
        ADVANCE_TAKEN_DATE: this.selectedData.ADVANCE_TAKEN_DATE,
        HOSPITAL_TYPE: this.selectedData.HOSPITAL_TYPE,
        HOSPITAL_NAME: this.selectedData.HOSPITAL_NAME,
        HOSPITAL_ADDRESS: this.selectedData.HOSPITAL_ADDRESS,
        BANK_NAME: this.selectedData.BANK_NAME,
        SB_ACC_NO: this.selectedData.SB_ACC_NO,
        IFSC_CODE: this.selectedData.IFSC_CODE,
        MICR_CODE: this.selectedData.MICR_CODE,
        WARD_ENTITLEMENT: this.selectedData.WARD_ENTITLEMENT,
        TREATEMENT_TYPE: this.selectedData.TREATEMENT_TYPE,
        EMP_ID: this.selectedData.EMP_ID,
        GRADE_PAY: this.selectedData.GRADE_PAY,
        OFFICE_NAME: this.selectedData.OFFICE_NAME,
        DESIGNATION: this.selectedData.DESIGNATION,
        LOCATION: this.selectedData.LOCATION,
        PATIENT_NAME: this.selectedData.PATIENT_NAME,
        RELATION_WITH_PATIENT: this.selectedData.RELATION_WITH_PATIENT,
        BENEFICIARY_TYPE: this.selectedData.BENEFICIARY_TYPE,
        CGHS_CARD_NO: this.selectedData.CGHS_CARD_NO,
        IS_EMERGENCY_TREATMENT_APPLIED:
          this.selectedData.IS_EMERGENCY_TREATMENT_APPLIED,
        EMERGENCY_REF_DATE: this.selectedData.EMERGENCY_REF_DATE,
        HOSPITAL_ID: this.selectedData.HOSPITAL_ID,
        IS_PRIVATE_HOSPITAL: this.selectedData.IS_PRIVATE_HOSPITAL,
        IS_PERMISSION_TAKEN: this.selectedData.IS_PERMISSION_TAKEN,
        NATURE_OF_TREATMENT: this.selectedData.NATURE_OF_TREATMENT,
        TREATMENT_START_DATE: this.selectedData.TREATMENT_START_DATE,
        TREATMENT_END_DATE: this.selectedData.TREATMENT_END_DATE,
        IS_FORWARDING_LETTER: this.selectedData.IS_FORWARDING_LETTER,
        IS_MEDICAL_REIMBURSEMENT_CLAIMED:
          this.selectedData.IS_MEDICAL_REIMBURSEMENT_CLAIMED,
        IS_COPY_OF_CGHS_CARD_FOR_BOTH_BENEFICIARY_AND_PATIENT:
          this.selectedData
            .IS_COPY_OF_CGHS_CARD_FOR_BOTH_BENEFICIARY_AND_PATIENT,
        IS_DISCHARGE_CARD: this.selectedData.IS_DISCHARGE_CARD,
        FORM_NO_3_IN_CASE_THE_MEDICAL_CLAIM:
          this.selectedData.FORM_NO_3_IN_CASE_THE_MEDICAL_CLAIM,
        IS_FINAL_BILL_IN_ORIGINAL_ALONG_WITH_ONE_XEROX_ONE_COPY:
          this.selectedData
            .IS_FINAL_BILL_IN_ORIGINAL_ALONG_WITH_ONE_XEROX_ONE_COPY,
        IS_PAY_SLIP_OF_THE_PERIOD_OF_TRATMENT:
          this.selectedData.IS_PAY_SLIP_OF_THE_PERIOD_OF_TRATMENT,
        IS_BANK_MANDATE_FROM_ALONG_WITH_CANCELLED_CHEQUE:
          this.selectedData.IS_BANK_MANDATE_FROM_ALONG_WITH_CANCELLED_CHEQUE,
        IS_PRESCRIPTION_OF_MEDICINES_PURCHASED_FROM_OUTSIDE:
          this.selectedData.IS_PRESCRIPTION_OF_MEDICINES_PURCHASED_FROM_OUTSIDE,
        BILL_FILIING_DATE: this.selectedData.BILL_FILIING_DATE,
        IS_BILL_FILLED_IN_TIME: this.selectedData.IS_BILL_FILLED_IN_TIME,
        AMOUNT_OF_REIMBUSMENT_CLAIMED:
          this.selectedData.AMOUNT_OF_REIMBUSMENT_CLAIMED,
        AMOUNT_OF_REIMBUSMENT_ADMISSIBLE:
          this.selectedData.AMOUNT_OF_REIMBUSMENT_ADMISSIBLE,
        IS_ADVANCE_TAKEN: this.selectedData.IS_ADVANCE_TAKEN,
        ADVANCE_AMOUNT: this.selectedData.ADVANCE_AMOUNT,
        NET_AMOUNT_PAYABLE: this.selectedData.NET_AMOUNT_PAYABLE,
        CURRENT_STAGE_ID: 6,
        INSPECTOR_ID: Number(sessionStorage.getItem('userId')),
        REMARK: this.REMARK,
        SUB_STAGE: this.selectedData.SUB_STAGE,
        WARD_TYPE: this.selectedData.WARD_TYPE,
        BANK_ACCOUNT_NO: this.selectedData.BANK_ACCOUNT_NO,
        CLAIM_ACCREDITATION: this.selectedData.CLAIM_ACCREDITATION,
        PATIENT_CGHS_BENEFICIERY_NO:
          this.selectedData.PATIENT_CGHS_BENEFICIERY_NO,
        AO_ID: this.AO_ID,
      };
      this.api.updateclaimed(data2).subscribe((successCode) => {
        if (successCode.code == '200') {
          this.message.success('Information Changed Successfully...', '');
          this.isApproveVisible = false;
          this.search();
          // if (!addNew) this.drawerClose();
          this.isSpinning = false;
        } else {
          this.message.error('Information Has Not Changed...', '');
          this.isSpinning = false;
          this.isApproveVisible = true;
        }
      });
    }
  }
  users = [];
  loadAllUsers() {
    this.api.getAllUsers(0, 0, 'ID', 'desc', ' AND IS_ACTIVE = 1').subscribe(
      (userData) => {
        if (userData['code'] == 200) {
          this.users = userData['data'];
        } else {
          this.users = [];
          this.message.error("Can't Load User Information", '');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  AnnexureVisible = false;
  AnnexureCancel() {
    this.AnnexureVisible = false;
  }

  openAnnextureFile(data) {
    this.drawerClaimData = Object.assign({}, data);
    this.loadingRecords = true;
    this.api
      .getannexture(
        0,
        0,
        'ID',
        'ASC',
        ' AND STATUS = 1 AND IS_ADVANCE = 0 AND CLAIM_ID=' +
          this.drawerClaimData.ID
      )
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            var countt = data['count'];
            this.showlayoutDataList = data['data'];
            this.total = 0;
            this.total1 = 0;
            for (var i = 0; countt > i; i++) {
              this.total =
                this.total + this.showlayoutDataList[i]['CLAIMED_AMOUNT'];
              this.total1 =
                this.total1 + this.showlayoutDataList[i]['ADMISSIBLE_AMOUNT'];
            }
            if (this.drawerClaimData.IS_ADVANCE_TAKEN == 1) {
              this.advanceAdmissible = Math.round((this.total1 * 90) / 100);
            } else {
              this.advanceAdmissible = 0;
            }
            this.drawerClaimData.ADVANCE_ADMISSIBLE_AMOUNT = Math.round(
              this.advanceAdmissible
            );
            this.api
              .getHospitalMapping(
                0,
                0,
                'NAME',
                'ASC',
                ' AND CLAIM_ID=' + this.drawerClaimData.ID
              )
              .subscribe(
                (data) => {
                  if (data['code'] == 200) {
                    if (data['data'].length > 0) {
                      this.hospitallist = data['data'];
                    } else {
                      this.hospitallist = [];
                    }
                    this.AnnexureVisible = true;
                    this.loadingRecords = false;
                  } else {
                    this.loadingRecords = false;
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
          } else {
            this.loadingRecords = false;
          }
          // this.isSpinning = false;
          // console.log(this.procedureList2, 'jsdbhfvskgrvbjn');
        },
        (err) => {
          console.log(err);
          // this.isSpinning = false;
        }
      );
  }

  // public downloadclaimFile() {
  //   var i = 0;
  //   var date = new Date();
  //   var datef = this.datePipe.transform(date, 'dd-MM-yyyy');
  //   var dates = this.datePipe.transform(date, 'hh-mm-ss a');
  //   var data = document.getElementById('printAnnexureModal');

  //   html2pdf()
  //     .from(data)
  //     .set({
  //       margin: [2, 10, 2, 5],
  //       pagebreak: { mode: ['css', 'legecy'] },
  //       jsPDF: { unit: 'mm', format: 'A4', orientation: 'portrait' },
  //     })
  //     .toPdf()
  //     .get('pdf')
  //     .then(function (pdf) {
  //       this.pdfDownload = true;
  //       var totalPages = pdf.internal.getNumberOfPages();

  //       for (i = 1; i <= totalPages; i++) {
  //         pdf.setPage(i);
  //         pdf.setFontSize(12);
  //         pdf.setTextColor(150);
  //         pdf.text(i.toString(), pdf.internal.pageSize.getWidth() / 2, 10);
  //       }

  //       this.pdfDownload = false;
  //     })
  //     .save('Annexure "A"_' + datef + '_' + dates + '.pdf');
  // }

  printOrderModalVisible: boolean = false;
  amountinwordssh: any;
  amountinwordss: any;
  openPrintOrderModal(data) {
    this.loadingRecords = true;
    this.orderdata = data;
    let words = toWords.convert(this.am, { currency: true });
    this.orderdata.ADMISSIBLE_AMOUNT = this.orderdata.ADMISSIBLE_AMOUNT;
    this.amountinwords = toWords.convert(this.orderdata.ADMISSIBLE_AMOUNT, {
      currency: true,
    });

    let wordss = toWordsen.convert(this.am, { currency: true });
    this.orderdata.ADMISSIBLE_AMOUNT = this.orderdata.ADMISSIBLE_AMOUNT;
    this.amountinwordsh = toWordsen.convert(this.orderdata.ADMISSIBLE_AMOUNT, {
      currency: true,
    });

    this.api
      .getSignature(0, 0, '', '', ' AND ID = ' + data.SIGNATURE_ID)
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            this.SECTION_TYPE = data['data'][0]['SECTION_TYPE'];
            this.loadingRecords = false;
            this.printOrderModalVisible = true;
          } else {
            this.message.error('Something Went Wrong', '');
            this.loadingRecords = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  printOrderModalCancel() {
    this.printOrderModalVisible = false;
  }
  getwidth() {
    if (window.innerWidth <= 400) {
      return 400;
    } else {
      return 850;
    }
  }

  orderPDF() {
    var i = 0;
    var date = new Date();
    var datef = this.datePipe.transform(date, 'dd-MM-yyyy');
    var dates = this.datePipe.transform(date, 'hh-mm-ss a');
    var data = document.getElementById('printOrderModal');

    html2pdf()
      .from(data)
      .set({
        image: { type: 'jpeg', quality: 1.0 },
        html2canvas: { scale: 3, useCORS: true },
        margin: [2, 10, 2, 5],
        pagebreak: { mode: ['css', 'legecy'] },
        jsPDF: { unit: 'mm', format: 'A4', orientation: 'portrait' },
      })
      .toPdf()
      .get('pdf')
      .then(function (pdf) {
        this.pdfDownload = true;
        var totalPages = pdf.internal.getNumberOfPages();

        for (i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(12);
          pdf.setTextColor(150);
          pdf.text(i.toString(), pdf.internal.pageSize.getWidth() / 2, 10);
        }

        this.pdfDownload = false;
      })
      .save('Sanction Order_' + datef + '_' + dates + '.pdf');
  }
  downloadclaimFile() {
    const element = document.getElementById('printAnnexureModal');
    const opt = {
      margin: 0.2,
      filename: 'Download.pdf',
      image: { type: 'jpeg', quality: 5 },
      html2canvas: { scale: 5 },
      jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
    };
    html2pdf().from(element).set(opt).save();
  }

  certificateVisible = false;
  certificateloadingRecords = false;
  certificateTitle: string = '';
  certificateData = [];
  openCertificate(data) {
    this.loadingRecords = true;
    this.certificateloadingRecords = false;
    this.certificateTitle = 'Certificate';
    this.certificateData = Object.assign({}, data);
    this.api
      .getHospitalMapping(0, 0, 'ID', 'ASC', ' AND CLAIM_ID = ' + data.ID)
      .subscribe(
        (data: any) => {
          if (data['code'] == 200) {
            if (data['code'] == 200 && data['count'] > 0) {
              this.HospitalMapping = data['data'];
              console.log(this.HospitalMapping);
            } else {
              this.HospitalMapping = [];
            }
            this.loadingRecords = false;
            this.certificateVisible = true;
          } else {
            this.loadingRecords = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );
    // this.GARVisible=true;
    // this.ltcsheet1Visible = true;
    // this.ltcsheet2Visible = true;
    // this.ltcorderVisible=true;
  }

  certificaterClose(): void {
    this.certificateVisible = false;
    this.certificateloadingRecords = false;
    this.search();
  }

  get certificaterCloseCallback() {
    return this.certificaterClose.bind(this);
  }

  ltcorderVisible = false;
  ltcorderVisibleClose(): void {
    this.ltcorderVisible = false;
    // this.search();
  }
  GARVisibleTitle = '';
  ltcorderVisibleTitle = '';
  GARData;
  amountinWordsInGar: any;
  SIGNATURE_ID: any;
  openGAR23(data) {
    this.SIGNATURE_ID = data.SIGNATURE_ID;
    this.certificateloadingRecords = false;
    this.loadingRecords = true;
    this.GARVisibleTitle = 'GAR 23';
    this.GARData = Object.assign({}, data);
    let words = toWords.convert(this.am, { currency: true });
    data.ADMISSIBLE_AMOUNT = data.ADMISSIBLE_AMOUNT;
    this.amountinWordsInGar = toWords.convert(data.ADMISSIBLE_AMOUNT, {
      currency: true,
    });
    this.api
      .getHospitalMapping(0, 0, 'ID', 'ASC', ' AND CLAIM_ID = ' + data.ID)
      .subscribe(
        (data: any) => {
          if (data['code'] == 200) {
            if (data['code'] == 200 && data['count'] > 0) {
              this.HospitalMapping = data['data'];
              console.log(this.HospitalMapping);
            } else {
              this.HospitalMapping = [];
            }
            this.api
              .getSignature(0, 0, '', '', ' AND ID = ' + this.SIGNATURE_ID)
              .subscribe(
                (data) => {
                  if (data['code'] == 200) {
                    this.OFFICE_NAME = data['data'][0]['OFFICE_NAME'];

                    this.api.getSignature(0, 0, 'ID', 'desc', '').subscribe(
                      (data) => {
                        if (data['code'] == 200) {
                          this.Signaturearray = data['data'];
                          this.GARVisible = true;
                          this.loadingRecords = false;
                        } else {
                          this.loadingRecords = false;
                        }
                      },
                      (err) => {
                        console.log(err);
                      }
                    );
                  } else {
                    this.message.error('Something Went Wrong', '');
                    this.loadingRecords = false;
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
          } else {
            this.loadingRecords = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );
    // this.GARVisible=true;
    // this.ltcsheet1Visible = true;
    // this.ltcsheet2Visible = true;
    // this.ltcorderVisible=true;
  }
  get ltcorderCloseCallback() {
    return this.ltcorderVisibleClose.bind(this);
  }
  GARVisible = false;
  GARVisibleClose(): void {
    this.GARVisible = false;
    this.search();
  }
  get GARCloseCallback() {
    return this.GARVisibleClose.bind(this);
  }

  getScrollConfig(): { x: string; y: string } {
    return this.userId !== 1
      ? { x: '6200px', y: '420px' }
      : { x: '6400px', y: '420px' };
  }
}
