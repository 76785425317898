
export class roadRail {
    ID: any
    LTC_ID: any
    DATE: any
    FROM_PLACE: any
    TO_PLACE: any
    FARE_PAID:any
    CREATED_MODIFIED_DATE: any
    CLIENT_ID: any
    ENTITLED_TRAVEL_CLASS_ID: any
    ROAD_CLAIMED_AMOUNT:any=0
}