<div nz-row nzType="flex" style="margin-bottom: 5px">
  <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
    <div class="formname">{{ formTitle }}</div>
  </div>

  <div
    nz-col
    nzXs="24"
    nzSm="24"
    nzMd="12"
    nzLg="12"
    nzXl="12"
    style="display: flex; justify-content: flex-end"
  >
    <form nz-form [nzLayout]="'inline'">
      <!-- <nz-form-item>
        <button
          nz-button
          nzTooltipTitle="Filter"
          nzTooltipPlacement="top"
          nz-tooltip
          [nzType]="isFilterApplied"
          (click)="showFilter()"
        >
          <i nz-icon nzType="filter"></i>
        </button>
      </nz-form-item> -->

      <nz-form-item>
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
          <input
            type="text"
            autocomplete="off"
            name="search"
            nz-input
            (keydown.enter)="onKeypressEvent($event)"
            placeholder="Search Records"
            [(ngModel)]="searchText"
          />
        </nz-input-group>

        <ng-template #suffixIconButton>
          <button nz-button nzType="primary" (click)="search()" nzSearch>
            <i nz-icon nzType="search" id="button"></i>
          </button>
        </ng-template>
      </nz-form-item>
    </form>
  </div>
</div>

<div nz-row>
  <div nz-col nzSpan="24">
    <div class="filter {{ filterClass }}">
      <div class="filter-box" style="margin-bottom: 5px">
        <form nz-form>
          <div nz-row>
            <div nz-col nzSpan="5">
              <nz-form-item style="margin-top: 1px; width: 113%">
                <nz-form-label nzNoColon>Select Date</nz-form-label>
                <nz-range-picker
                  name="date"
                  nzFormat="dd MMM yyyy"
                  [(ngModel)]="BILL_FILIING_DATE"
                >
                </nz-range-picker>
              </nz-form-item>
            </div>
            <!-- [(ngModel)]="data" -->
            <div nz-col nzSpan="5" style="margin-left: 39px">
              <nz-form-item>
                <nz-form-label nzNoColon>Hospital Type</nz-form-label>
                <nz-select
                  nzShowSearch
                  nzAllowClear
                  name="hospitalType"
                  [(ngModel)]="TYPE_OF_HOSPITAL"
                  nzPlaceHolder="Select Hospital Type"
                >
                  <nz-option nzLabel="Empanelled" nzValue="E"></nz-option>
                  <nz-option nzLabel="Non-Empanelled" nzValue="NE"></nz-option>
                  <nz-option nzLabel="Governemnt" nzValue="G"></nz-option>
                </nz-select>
              </nz-form-item>
            </div>
            <!-- [(ngModel)]="STAGE_ID" -->

            <!-- </div>
                      <div nz-row nzGutter="8"> -->

            <div nz-col nzSpan="7">
              <nz-form-label nzNoColon></nz-form-label>
              <nz-form-item style="margin-left: 45px">
                <button
                  style="margin-right: 10px"
                  nz-button
                  [nzLoading]="isSpinning"
                  nzType="primary"
                  (click)="applyFilter()"
                  nzJustify="end"
                >
                  <i nz-icon nzType="filter"></i>Apply Filter
                </button>
                <button nz-button nzType="primary" (click)="clearFilter()">
                  <i nz-icon nzType="filter"></i>Clear filter
                </button>
              </nz-form-item>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div nz-row>
  <div nz-col nzSpan="24">
    <nz-table
      class="my-scroll no-wrap1"
      #basicTable
      nzShowSizeChanger
      [nzData]="dataList"
      [(nzPageIndex)]="pageIndex"
      [nzFrontPagination]="false"
      [nzLoading]="loadingRecords"
      [nzTotal]="totalRecords"
      [(nzPageSize)]="pageSize"
      (nzPageIndexChange)="search()"
      (nzPageSizeChange)="search(true)"
      (nzQueryParams)="sort($event)"
      [nzScroll]="{ x: '3400px', y: '420px' }"
      nzBordered
    >
      <thead>
        <tr>
          <th nzWidth="90px" style="font-weight: bold; text-align: center">
            Action
          </th>
          <th
            nzWidth="230px"
            style="font-weight: bold; text-align: center"
            nzColumnKey="EMPLOYEE_NAME"
            [nzSortFn]="true"
          >
            Applicant Name
          </th>

          <th
            nzWidth="250px"
            style="font-weight: bold; text-align: center"
            nzColumnKey="DESIGNATION"
            [nzSortFn]="true"
          >
            Employee Designation
          </th>
          <th
            nzWidth="130px"
            style="font-weight: bold; text-align: center"
            nzColumnKey="BASIC_PAY"
            [nzSortFn]="true"
          >
            Basic Pay
          </th>

          <th
            nzWidth="220px"
            style="font-weight: bold; text-align: center"
            nzColumnKey="HEADQUARTERS_NAME"
            [nzSortFn]="true"
          >
            Office Name
          </th>

          <th
            nzWidth="130px"
            style="font-weight: bold; text-align: center"
            nzColumnKey="MOBILE_NO"
            [nzSortFn]="true"
          >
            Mobile No
          </th>
          <th
            nzWidth="130px"
            style="font-weight: bold; text-align: center"
            nzColumnKey="GRADE_PAY_LEVEL"
            [nzSortFn]="true"
          >
            Gradepay Level
          </th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let data of basicTable.data; let i = index">
          <td nzWidth="100px">
            <div style="padding: 3px">
              <span style="cursor: pointer" (click)="pickClaim(data)">
                <nz-tag
                  style="
                    padding: 3px;
                    border: 1px blue solid;
                    border-radius: 0px;
                  "
                  [nzColor]="'blue'"
                >
                  Pick Claim
                </nz-tag>
              </span>
            </div>
          </td>

          <td style="text-align: center; white-space: pre-line">
            {{ data.EMPLOYEE_NAME }}
          </td>

          <td style="text-align: center; white-space: pre-line">
            {{ data.DESIGNATION }}
          </td>

          <td style="text-align: center; white-space: pre-line">
            {{ data.BASIC_PAY }}
          </td>
          <td style="text-align: center; white-space: pre-line">
            {{ data.HEADQUARTERS_NAME }}
          </td>
          <td style="text-align: center; white-space: pre-line">
            {{ data.MOBILE_NO }}
          </td>
          <td style="text-align: center; white-space: pre-line">
            {{ data.GRADE_PAY_LEVEL }}
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>
