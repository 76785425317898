<div nz-row nzType="flex">
    <div nz-col nzXs="24" nzSm="24" nzMd="20" nzLg="20" nzXl="12">
        <div class="formname">{{formTitle}}</div>
    </div>

    <div nz-col nzXs="24" nzSm="24" nzMd="4" nzLg="6" nzXl="12" style="display: flex; justify-content: flex-end">
        <form nz-form [nzLayout]="'inline'">

            <nz-form-item>
                <button nz-button nzTooltipTitle="Excel" nzTooltipPlacement="top" nz-tooltip nzType="primary"
                    (click)="importInExcel()" [nzLoading]="exportLoading">
                    <i nz-icon nzType="file-excel" nzTheme="outline"></i>
                </button>
            </nz-form-item>

            <nz-form-item>
                <button nz-button nzTooltipTitle="Filter" nzTooltipPlacement="top" nz-tooltip [nzType]="isFilterApplied"
                    (click)="showFilter()">
                    <i nz-icon nzType="filter"></i>
                </button>
            </nz-form-item>
            <nz-form-item>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                    <input type="text" name="search" [(ngModel)]="searchText" (keyup)="keyup($event)" (keydown.enter)="onKeypressEvent($event)"
                        nz-input placeholder="Search" />
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" id="button" (click)="search(true)" nzSearch><i nz-icon
                            nzType="search"></i></button>
                </ng-template>
            </nz-form-item>
        </form>
    </div>
</div>

<div nz-row>
    <div nz-col nzSpan="24">
        <div class="filter {{ filterClass }}">
            <div class="filter-box">

                <form nz-form>
                    <div nz-row nzGutter="8">
                        <div nz-col nzSpan="8">
                            <nz-form-item style="margin-top: 1px;width: 100%;">
                                <nz-form-label nzNoColon>Select Date</nz-form-label>
                                <nz-range-picker name="date" nzFormat="dd MMM yyyy" [(ngModel)]="DATE">
                                </nz-range-picker>
                            </nz-form-item>
                        </div>

                        <div nz-col nzSpan="8">

                            <nz-form-item style=" margin-left: 10px;margin-top: 33px;">
                                <button style="margin-right: 10px" nz-button [nzLoading]="isSpinning" nzType="primary"
                                    (click)="applyFilter()" nzJustify="end">
                                    <i nz-icon nzType="filter"></i>Apply filter
                                </button>
                                <button nz-button nzType="primary" (click)="clearFilter()">
                                    <i nz-icon nzType="filter"></i>Clear Filter
                                </button>
                            </nz-form-item>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>





<!-- Table -->
<div nz-row>
    <div nz-col nzSpan="24">
        <nz-table id="excel-table" class="my-scroll no-wrap1" nzBordered #table nzShowSizeChanger
            [nzFrontPagination]="false" [nzData]="dataList" [nzLoading]="loadingRecords" [nzTotal]="totalRecords"
            [(nzPageIndex)]="pageIndex" [(nzPageSize)]="pageSize" (nzPageIndexChange)="search()"
            (nzPageSizeChange)="search()" (nzQueryParams)="sort($event)">

            <thead nzSingleSort>
                <tr>
                    <th nzWidth="100px" style="font-weight: bold; text-align: center;"
                        nzColumnKey="{{'BENEFICIARY_TYPE'}}" [nzSortFn]="true">
                        Beneficiary Type
                    </th>
                    <th nzWidth="80px" style="font-weight: bold; text-align: center;" nzColumnKey="{{'CLAIMED_AMOUNT'}}"
                        [nzSortFn]="true">
                        Total Amount of reimbursement claimed
                    </th>
                    <th nzWidth="70px" nzColumnKey="{{'ADMISSIBLE_AMOUNT'}}" [nzSortFn]="true"
                        style="font-weight: bold; text-align: center;">
                        Total Amount of reimbursement Admissible
                    </th>

                    <th nzWidth="50px" style="font-weight: bold; text-align: center;"
                        nzColumnKey="{{'ADVANCE_AMOUNT'}}" [nzSortFn]="true">
                        Total Advance given Amount
                    </th>
                    <th nzWidth="50px" style="font-weight: bold; text-align: center;"
                        nzColumnKey="{{'NET_PAYABLE_AMOUNT'}}" [nzSortFn]="true">
                        Total Net amount Payable/recoverable
                    </th>

                    <!-- <th nzWidth="70px" nzColumnKey="{{'NET_PAYABLE_AMOUNT'}}" [nzSortFn]="true"
                        style="font-weight: bold; text-align: center;">
                        Total Net amount Payable/recoverable
                    </th> -->
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let data of table.data;">
                    <!-- <td style="white-space: pre-line;" align="center">{{data['BENEFICIARY_TYPE']}}</td> -->

                    <td style="text-align: center;">
                        <span nz-icon *ngIf="data['BENEFICIARY_TYPE']=='CG'">CGHS</span>
                        <span nz-icon *ngIf="data['BENEFICIARY_TYPE']=='CS'">CS(MA)</span>
                      
                    </td>
                    <td style="white-space: pre-line;" align="center">{{data['CLAIMED_AMOUNT']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['ADMISSIBLE_AMOUNT']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['ADVANCE_AMOUNT']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['NET_PAYABLE_AMOUNT']}}</td>
                    <!-- <td style="white-space: pre-line;" align="center">{{data['NET_PAYABLE_AMOUNT']}}</td> -->
                </tr>
            </tbody>
        </nz-table>
    </div>
</div>