<div nz-row nzType="flex" style="margin-bottom: 5px">
  <div
    nz-col
    nzXs="24"
    nzSm="24"
    nzMd="20"
    nzLg="20"
    nzXl="12"
    style="padding-left: 30px"
  >
    <div class="formname">{{ formTitle }}</div>
  </div>

  <div
    nz-col
    nzXs="24"
    nzSm="24"
    nzMd="4"
    nzLg="4"
    nzXl="12"
    style="display: flex; justify-content: flex-end"
  >
    <form nz-form [nzLayout]="'inline'">
      <nz-form-item>
        <button
          *ngIf="userId == 1"
          nz-button
          nzTooltipTitle="Filter"
          nzTooltipPlacement="top"
          nz-tooltip
          [nzType]="isFilterApplied"
          (click)="showFilter()"
        >
          <i nz-icon nzType="filter"></i>
        </button>
      </nz-form-item>

      <nz-form-item style="width: 40%">
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
          <input
            type="text"
            autocomplete="off"
            name="search"
            nz-input
            (keydown.enter)="onKeypressEvent($event)"
            placeholder="Search Records"
            [(ngModel)]="searchText"
          />
        </nz-input-group>

        <ng-template #suffixIconButton>
          <button nz-button nzType="primary" (click)="search()" nzSearch>
            <i nz-icon nzType="search" id="button"></i>
          </button>
        </ng-template>
      </nz-form-item>

      <nz-form-item>
        <button nz-button nzType="primary" (click)="add1()" nzJustify="end">
          <i nz-icon nzType="plus"></i>Add New Transfer
        </button>
      </nz-form-item>
    </form>
  </div>
</div>

<div nz-row>
  <div nz-col nzSpan="24">
    <div class="filter {{ filterClass }}">
      <div class="filter-box" style="margin-bottom: 5px">
        <form nz-form>
          <div nz-row>
            <!-- <div nz-col nzSpan="5">
                <nz-form-item style="margin-top: 1px; width: 113%">
                  <nz-form-label nzNoColon>Select Date</nz-form-label>
                  <nz-range-picker
                    name="date"
                    nzFormat="dd MMM yyyy"
                    [(ngModel)]="BILL_FILIING_DATE"
                  >
                  </nz-range-picker>
                </nz-form-item>
              </div> -->

            <div nz-col nzSpan="5">
              <nz-form-item>
                <nz-form-label nzNoColon>Inspector Name</nz-form-label>
                <nz-select
                  name="inspectorName"
                  nzShowSearch
                  nzAllowClear
                  style="width: 100%"
                  [(ngModel)]="INSPECTOR_ID"
                  nzPlaceHolder="Select Inspector Name"
                >
                  <nz-option
                    *ngFor="let role of users"
                    [nzValue]="role.ID"
                    [nzLabel]="role.NAME"
                  >
                  </nz-option>
                </nz-select>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="7">
              <nz-form-label nzNoColon></nz-form-label>
              <nz-form-item style="margin-left: 45px">
                <button
                  style="margin-right: 10px"
                  nz-button
                  [nzLoading]="isSpinning"
                  nzType="primary"
                  (click)="applyFilter()"
                  nzJustify="end"
                >
                  <i nz-icon nzType="filter"></i>Apply Filter
                </button>
                <button nz-button nzType="primary" (click)="clearFilter()">
                  <i nz-icon nzType="filter"></i>Clear filter
                </button>
              </nz-form-item>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div nz-row>
  <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
    <!-- <div style="text-align: center">
          <span>
            <button
              (click)="applyStageFilter('ALL', ' ')"
              nz-button
              [nzType]="totalButton"
              style="border: 1px solid blue; color: blue"
            >
              All : {{ allClaimCount }}
            </button>
          </span>
    
          <span>
            <button
              (click)="applyStageFilter('N', '1,2,3,4')"
              nz-button
              [nzType]="newButton"
              style="border: 1px solid blue; color: blue"
            >
              New : {{ allNewCount }}
            </button>
          </span>
    
          <span>
            <button
              (click)="applyStageFilter('F', '6,7,9,11,15')"
              nz-button
              [nzType]="forwardButton"
              style="border: 1px solid #000000; color: black"
            >
              Forwarded : {{ allForwardCount }}
            </button>
          </span>
          <span>
            <button
              (click)="applyStageFilter('R', '5')"
              nz-button
              [nzType]="rejectButton"
              style="border: 1px solid #f70000; color: red"
            >
              Rejected : {{ allRejectCount }}
            </button>
          </span>
          <span>
            <button
              (click)="applyStageFilter('Q', '8,10,12,14,16')"
              nz-button
              [nzType]="queryButton"
              style="border: 1px solid blue; color: blue"
            >
              Queried : {{ allQueryCount }}
            </button>
          </span>
          <span>
            <button
              (click)="applyStageFilter('A', '17')"
              nz-button
              [nzType]="approvedButton"
              style="border: 1px solid #000000; color: black"
            >
              Approved : {{ allApprovedCount }}
            </button>
          </span>
          <span>
            <button
              (click)="applyStageFilter('Z', '13')"
              nz-button
              [nzType]="readyButton"
              style="border: 1px solid red; color: red"
            >
              Ready For Zonal : {{ allReadyCount }}
            </button>
          </span>
        </div> -->
  </div>
</div>

<div nz-row>
  <div nz-col nzSpan="24">
    <nz-table
      class="my-scroll no-wrap1"
      #basicTable
      nzShowSizeChanger
      [nzData]="dataList"
      [(nzPageIndex)]="pageIndex"
      [nzFrontPagination]="false"
      [nzLoading]="loadingRecords"
      [nzTotal]="totalRecords"
      [(nzPageSize)]="pageSize"
      (nzPageIndexChange)="search()"
      (nzPageSizeChange)="search(true)"
      (nzQueryParams)="sort($event)"
      [nzScroll]="{ x: '3150px', y: '420px' }"
      nzBordered
    >
      <thead (nzSortChange)="sort($event)" nzSingleSort>
        <tr>
          <th nzWidth="100px" style="font-weight: bold; text-align: center">
            Sr.No
          </th>
          <th
            nzWidth="200px"
            style="font-weight: bold; text-align: center"
            nzColumnKey="NAME"
          >
            File No
          </th>

          <th nzWidth="120px" style="font-weight: bold; text-align: center">
            Action
          </th>
          <th nzWidth="110px" style="font-weight: bold; text-align: center">
            Document
          </th>
          <th nzWidth="70px" style="font-weight: bold; text-align: center">
            Delete
          </th>

          <th
            nzWidth="250px"
            style="font-weight: bold; text-align: center"
            nzColumnKey="NAME"
            [nzSortFn]="true"
          >
            Applicant Name
          </th>

          <th
            nzWidth="110px"
            style="font-weight: bold; text-align: center"
            nzColumnKey="MOBILE_NO"
            [nzSortFn]="true"
          >
            Mobile No.
          </th>
          <th
            nzWidth="150px"
            style="font-weight: bold; text-align: center"
            nzColumnKey="EMPLOYEE_CODE"
            [nzSortFn]="true"
          >
            Employee Code
          </th>
          <th
            nzWidth="270px"
            style="font-weight: bold; text-align: center"
            nzColumnKey="DESIGNATION"
            [nzSortFn]="true"
          >
            Employee Designation
          </th>

          <th
            nzWidth="170px"
            style="font-weight: bold; text-align: center"
            nzColumnKey="DDO_OF_THE_OFFICIAL"
            [nzSortFn]="true"
          >
            DDO official
          </th>
          <th
            nzWidth="220px"
            style="font-weight: bold; text-align: center"
            nzColumnKey="OFFICE_NAME"
            [nzSortFn]="true"
          >
            Office Name
          </th>
          <th
            nzWidth="250px"
            style="font-weight: bold; text-align: center"
            nzColumnKey="ADDRESS"
            [nzSortFn]="true"
          >
            Old Residential Address
          </th>
          <th
            nzWidth="220px"
            style="font-weight: bold; text-align: center"
            nzColumnKey="NEW_HEADQUARTERS_NAME"
            [nzSortFn]="true"
          >
            New Head Quater Name
          </th>
          <th
            nzWidth="250px"
            style="font-weight: bold; text-align: center"
            nzColumnKey="NEW_ADDRESS"
            [nzSortFn]="true"
          >
            New Residential Address
          </th>

          <th
            nzWidth="200px"
            style="font-weight: bold; text-align: center"
            nzColumnKey="NAME_OF_FAMILY_MEMBER"
            [nzSortFn]="true"
          >
            Name of Family Member
          </th>
          <th
            nzWidth="80px"
            style="font-weight: bold; text-align: center"
            nzColumnKey="AGE"
            [nzSortFn]="true"
          >
            Age
          </th>
          <th
            nzWidth="190px"
            style="font-weight: bold; text-align: center"
            nzColumnKey="RELATIONSHIP"
            [nzSortFn]="true"
          >
            Relation with Patient
          </th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let data of basicTable.data; let i = index">
          <td style="text-align: center; white-space: pre-line" nzWidth="190px">
            <!-- <b> {{i + 1 }}</b> -->
            {{ (pageIndex - 1) * pageSize + i + 1 }}
          </td>
          <td style="text-align: center; white-space: pre-line" nzWidth="190px">
            {{ data.FILE_NO }}
          </td>

          <td style="text-align: center; white-space: pre-line" nzWidth="120px">
            <span
              style="cursor: pointer"
              *ngIf="
                data.TRANSFER_STATUS !== 'P' &&
                data.TRANSFER_STATUS !== 'C' &&
                data.TRANSFER_STATUS !== 'T' &&
                (data.TRANSFER_STATUS == 'R' ||
                  data.TRANSFER_STATUS == undefined ||
                  data.TRANSFER_STATUS == null)
              "
              (click)="edit(data)"
            >
              <nz-tag
                style="padding: 3px; border: 1px blue solid; border-radius: 0px"
                [nzColor]="'blue'"
              >
                <i nz-icon nzType="edit"></i> Verify Claim
              </nz-tag>
            </span>
          </td>

          <td nzWidth="200px" style="text-align: center; white-space: pre-line">
            <div style="padding: 3px">
              <!-- <td style="text-align: center;"><a (click)="edit(data)"><i nz-icon nzType="edit" nzTheme="outline"></i></a> -->
              <!-- </td> -->
              <span>
                <span
                  style="cursor: pointer"
                  (click)="travellingallounce(data)"
                >
                  <nz-tag
                    style="
                      padding: 3px;
                      border: 1px black solid;
                      border-radius: 5px;
                    "
                    [nzColor]="'default'"
                  >
                    Part A
                  </nz-tag>
                </span>
                &nbsp;

                <!-- <span style="cursor: pointer"
                                    (click)="orderData(data)">
                                    <nz-tag
                                        style="
                        padding: 3px;
                        border: 1px rgb(2, 33, 209) solid;
                        border-radius: 5px;
                      "
                                        [nzColor]="'default'">
                                        <span nz-icon nzType="eye"
                                            nzTheme="outline"></span> Order
                                    </nz-tag>
                                </span> -->

                <!-- &nbsp;
                                <span style="cursor: pointer"
                                    (click)="partb(data)">
                                    <nz-tag
                                        style="
                        padding: 3px;
                        border: 1px rgb(255, 88, 88) solid;
                        border-radius: 5px;
                      "
                                        [nzColor]="'default'">
                                        Part B
                                    </nz-tag>
                                </span>
                                &nbsp; -->
                <!-- travellng -->

                <!-- <span style="cursor: pointer" (click)="certificate(data)">
                    <nz-tag
                      style="
                        padding: 3px;
                        border: 1px black solid;
                        border-radius: 5px;
                      "
                      [nzColor]="'default'"
                    >de
                      Certificate
                    </nz-tag>
                  </span> -->

                <!-- &nbsp; -->
                <!-- 
                                <span style="cursor: pointer"
                                    (click)="openChecklist(data)">
                                    <nz-tag
                                        style="
                        padding: 3px;
                        border: 1px red solid;
                        border-radius: 5px;
                      "
                                        [nzColor]="'red'">
                                        CheckList
                                    </nz-tag>
                                </span> -->
              </span>
            </div>
          </td>
          <td align="center" nzWidth="70px">
            <span
              *ngIf="
                data.TRANSFER_STATUS !== 'P' &&
                data.TRANSFER_STATUS !== 'C' &&
                data.TRANSFER_STATUS !== 'T' &&
                (data.TRANSFER_STATUS == 'R' ||
                  data.TRANSFER_STATUS == undefined ||
                  data.TRANSFER_STATUS == null)
              "
              style="cursor: pointer"
              nz-popconfirm
              nzPopconfirmTitle="Are you sure want delete?"
              nzPopconfirmPlacement="top"
              (nzOnConfirm)="deleteConfirm(data)"
              (nzOnCancel)="deleteCancel()"
            >
              <span
                nz-icon
                nzType="delete"
                nzTheme="twotone"
                nz-tooltip
                nzTooltipTitle="Delete"
              >
              </span>
            </span>
          </td>
          <td style="text-align: center; white-space: pre-line" nzWidth="250px">
            {{ data.NAME }}
          </td>

          <td style="text-align: center; white-space: pre-line" nzWidth="110px">
            {{ data.MOBILE_NO }}
          </td>
          <td style="text-align: center; white-space: pre-line" nzWidth="150px">
            {{ data.EMPLOYEE_CODE }}
          </td>
          <td style="text-align: center; white-space: pre-line" nzWidth="270px">
            {{ data.DESIGNATION }}
          </td>

          <td style="text-align: center; white-space: pre-line" nzWidth="170px">
            {{ data.DDO_OF_THE_OFFICIAL }}
          </td>
          <td style="text-align: center; white-space: pre-line" nzWidth="220px">
            {{ data.OFFICE_NAME }}
          </td>
          <td style="text-align: center; white-space: pre-line" nzWidth="150px">
            {{ data.ADDRESS }}
          </td>
          <td style="text-align: center; white-space: pre-line" nzWidth="150px">
            {{ data.NEW_HEADQUARTERS_NAME }}
          </td>
          <td style="text-align: center; white-space: pre-line" nzWidth="150px">
            {{ data.NEW_ADDRESS }}
          </td>
          <td style="text-align: center; white-space: pre-line" nzWidth="190px">
            {{ data.NAME_OF_FAMILY_MEMBER }}
          </td>
          <td style="text-align: center; white-space: pre-line" nzWidth="190px">
            {{ data.AGE }}
          </td>
          <td style="text-align: center; white-space: pre-line" nzWidth="190px">
            {{ data.RELATIONSHIP }}
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>

<nz-drawer
  [nzBodyStyle]="{
    height: 'calc(100% - 55px)',
    overflow: 'auto',
    'padding-bottom': '53px'
  }"
  [nzClosable]="false"
  [nzMask]="true"
  [nzMaskClosable]="false"
  [nzWidth]="1050"
  [nzVisible]="drawerVisible"
  [nzTitle]="drawerTitle"
  (nzOnClose)="drawerClose()"
  (nzKeyboard)="(true)"
>
  <app-employeetransferadd
    [drawerClose]="closeCallback"
    [data]="drawerData"
    [empID]="empID"
    [claimID]="claimID"
    [currentStageID]="currentStageID"
    [current]="currentStage"
    [editrelation]="editrelation"
    [isSpinning]="isSpin"
    [relationdata]="relationdata"
  ></app-employeetransferadd>
</nz-drawer>

<nz-drawer
  [nzBodyStyle]="{
    height: 'calc(100% - 55px)',
    overflow: 'auto',
    'padding-bottom': '53px'
  }"
  [nzClosable]="true"
  [nzMask]="true"
  [nzMaskClosable]="false"
  [nzWidth]="1200"
  [nzVisible]="orderDrawerVisible"
  [nzTitle]="orderDrawerTitle"
  (nzOnClose)="orderDrawerClose()"
  (nzKeyboard)="(true)"
>
  <app-claim-application-drawer
    [amountinwordsh]="amountinwordsh"
    [amountinwords]="amountinwords"
    [orderdata]="orderdata"
    [fileList]="fileList"
    [drawerClose]="orderDrawerCloseCallback"
    [HospitalMapping]="HospitalMapping"
  >
  </app-claim-application-drawer>
</nz-drawer>

<nz-drawer
  [nzBodyStyle]="{
    height: 'calc(100% - 55px)',
    overflow: 'auto',
    'padding-bottom': '53px'
  }"
  [nzClosable]="false"
  [nzMask]="true"
  [nzMaskClosable]="false"
  [nzWidth]="1200"
  [nzVisible]="investigationDrawerVisible"
  [nzTitle]="investigationDrawerTitle"
  (nzOnClose)="investigationDrawerClose()"
  (nzKeyboard)="(true)"
>
  <app-add-investigation-master-revised
    [drawerClose]="investigationDrawerCloseCallback"
    [data]="investigationDrawerData"
    [claimData]="claimData"
    [showlayoutDataList]="showlayoutDataList"
    [total]="total"
    [total1]="total1"
    [citylist]="citylist"
    [hospitallist]="hospitallist"
    [ACCREDITATION]="ACCREDITATION"
  >
  </app-add-investigation-master-revised>
</nz-drawer>

<nz-drawer
  [nzBodyStyle]="{
    height: 'calc(100% - 55px)',
    overflow: 'auto',
    'padding-bottom': '53px'
  }"
  [nzClosable]="true"
  [nzMask]="true"
  [nzMaskClosable]="false"
  [nzWidth]="1100"
  [nzVisible]="drawerLogVisible"
  [nzTitle]="drawerLogTitle"
  (nzOnClose)="drawerLogClose()"
  (nzKeyboard)="(true)"
>
  <app-claim-log-drawer
    [drawerClose]="closeLogCallback"
    [data]="drawerLogData"
  ></app-claim-log-drawer>
</nz-drawer>

<nz-modal [(nzVisible)]="isVisible" nzTitle (nzOnCancel)="handleCancel()">
  <div *nzModalContent>
    <nz-form-item>
      <nz-form-label nzRequired nzNoColon>Forward Claim To</nz-form-label>

      <nz-input-group>
        <nz-select
          name="ForwardTo"
          style="width: 100%"
          nzShowSearch
          nzPlaceHolder="Select Hospital Type"
        >
          <nz-option [nzValue]="'STO'" [nzLabel]="'STO'"> </nz-option>
        </nz-select>
      </nz-input-group>
    </nz-form-item>
  </div>

  <div *nzModalFooter>
    <button nz-button nzType="primary" (click)="handleOk()">Send</button>
  </div>
</nz-modal>

<nz-drawer
  [nzBodyStyle]="{
    height: 'calc(100% - 55px)',
    overflow: 'auto',
    'padding-bottom': '53px'
  }"
  [nzClosable]="true"
  [nzMask]="true"
  [nzMaskClosable]="false"
  [nzWidth]="1100"
  [nzVisible]="drawerClaimVisible1"
  [nzTitle]="drawerClaimTitle"
  (nzOnClose)="drawerClaimClose()"
  (nzKeyboard)="(true)"
>
  <div *ngIf="drawerClaimData != undefined">
    <div style="text-align: right; margin-bottom: 5px">
      <button
        nz-button
        nzType="default"
        [useExistingCss]="true"
        printSectionId="claimFile"
        ngxPrint
      >
        Print
      </button>

      <button
        nz-button
        nzType="primary"
        [nzLoading]="pdfDownload"
        (click)="generatePDF()"
        style="margin-left: 20px"
      >
        Download
      </button>
    </div>

    <div style="height: 80vh; overflow-y: scroll">
      <div id="claimFile" #claimFile>
        <div style="font-family: Arial, Helvetica, sans-serif !important">
          <div
            style="
              text-align: center;
              font-weight: bold;
              font-size: 20px;
              margin-bottom: 10px;
              margin-top: 10px;
            "
          >
            Medical Reimbursement Claim
          </div>

          <table
            class="table"
            style="
              font-size: 12px;
              page-break-inside: avoid !important;
              border: 1px solid black !important;
              border-collapse: collapse !important;
            "
          >
            <tr>
              <td style="width: 5%; text-align: center !important">1.</td>
              <td style="width: 46%">Name of the Applicant</td>
              <td style="width: 4%; text-align: center !important">:</td>
              <td style="width: 45%">
                {{ drawerClaimData.EMPLOYEE_NAME }}
              </td>
            </tr>
            <tr>
              <td style="text-align: center !important">2.</td>
              <td>Designation & Office</td>
              <td style="text-align: center !important">:</td>
              <td>
                {{ drawerClaimData.EMPLOYEE_DESIGNATION }},
                {{ drawerClaimData.EMPLOYEE_OFFICE_NAME }}
              </td>
            </tr>

            <tr>
              <td style="text-align: center !important">3.</td>
              <td>DDO of the Official</td>
              <td style="text-align: center !important">:</td>
              <td>
                {{ drawerClaimData.DDO_OF_THE_OFFICIAL }}
              </td>
            </tr>

            <tr>
              <td style="text-align: center !important">4.</td>
              <td>Employee Code No.</td>
              <td style="text-align: center !important">:</td>
              <td>
                {{ drawerClaimData.EMPLOYEE_CODE }}
              </td>
            </tr>

            <tr>
              <td style="text-align: center !important">5.</td>
              <td>Patient's Relationship</td>
              <td style="text-align: center !important">:</td>
              <td>
                <span *ngIf="drawerClaimData.RELATION_WITH_PATIENT == 'Self'">{{
                  drawerClaimData.RELATION_WITH_PATIENT
                }}</span>
                <span *ngIf="drawerClaimData.RELATION_WITH_PATIENT != 'Self'"
                  >{{ drawerClaimData.RELATION_WITH_PATIENT }} ,
                  {{ drawerClaimData.PATIENT_NAME }}</span
                >
              </td>
            </tr>

            <tr>
              <td style="text-align: center !important">6.</td>
              <td>Whether CGHS/CS (MA) Beneficiary</td>
              <td style="text-align: center !important">:</td>
              <td>CGHS Card No.{{ drawerClaimData.CGHS_CARD_NO }}</td>
            </tr>

            <tr>
              <td rowspan="2" style="text-align: center !important">7.</td>
              <td>Whether emergency treatment is availed?</td>
              <td style="text-align: center !important">:</td>
              <td>
                <span *ngIf="drawerClaimData.EMERGENCY_TREATEMENT == 1">
                  Yes
                </span>
                <span *ngIf="drawerClaimData.EMERGENCY_TREATEMENT == 0">
                  No
                </span>
              </td>
            </tr>
            <tr>
              <td>If not, date of CGHS/AMA's reference.</td>
              <td style="text-align: center !important">:</td>
              <td>
                <span *ngIf="drawerClaimData.EMERGENCY_TREATEMENT == 0">
                  <span
                    *ngIf="
                      drawerClaimData != undefined &&
                      drawerClaimData.IS_PERMISSION_TAKEN_FROM_HOD != undefined
                    "
                  >
                    CGHS Reference letter dated
                    {{ drawerClaimData.CGHS_AMA_REFERENCE_DATE }}
                  </span>
                </span>
              </td>
            </tr>

            <tr>
              <td rowspan="2" style="text-align: center !important">8.</td>
              <td>Date of filing the bill.</td>
              <td style="text-align: center !important">:</td>
              <td>
                {{ drawerClaimData.BILL_FILIING_DATE | date : "dd-MM-yyyy" }}
              </td>
            </tr>
            <tr>
              <td>Whether the bill is filed in time?</td>
              <td style="text-align: center !important">:</td>
              <td>
                <span
                  *ngIf="
                    drawerClaimData != undefined &&
                    drawerClaimData.IS_PERMISSION_TAKEN_FROM_HOD != undefined
                  "
                >
                  <span *ngIf="drawerClaimData.BILL_FILLED_INTIME == 1">
                    Yes
                  </span>
                  <span *ngIf="drawerClaimData.BILL_FILLED_INTIME == 0">
                    No
                    <span
                      *ngIf="
                        drawerClaimData.DELAY_CONDOLENCE_DATE != undefined &&
                        drawerClaimData.DELAY_CONDOLENCE_DATE != null
                      "
                    >
                      , Condonation letter dated
                      {{ drawerClaimData.DELAY_CONDOLENCE_DATE }}
                    </span>
                  </span>
                </span>
              </td>
            </tr>

            <tr>
              <td rowspan="2" style="text-align: center !important">9.</td>
              <td>
                Name of the Hospital Diagnostic Centre (Empanelled /
                Non-empanelled / Government)
              </td>
              <td style="text-align: center !important">:</td>
              <td>
                <span *ngFor="let hospital of hospitallist">
                  {{ hospital.NAME }}-{{
                    hospital.TYPE == "E"
                      ? "Empanelled"
                      : hospital.TYPE == "NE"
                      ? "Non Empanelled"
                      : hospital.TYPE == "G"
                      ? "Government"
                      : ""
                  }}
                  ({{ hospital.ADDRESS }}),
                </span>
              </td>
            </tr>
            <tr>
              <td>
                If Private Hospital / Diagnostic Centre, whether
                permission/ex-post facto approval taken from HOD or not?
              </td>
              <td style="text-align: center !important">:</td>
              <td>
                <span
                  nz-icon
                  *ngIf="
                    drawerClaimData != undefined &&
                    drawerClaimData.IS_PERMISSION_TAKEN_FROM_HOD != undefined
                  "
                >
                  <span
                    *ngIf="drawerClaimData.IS_PERMISSION_TAKEN_FROM_HOD == 1"
                  >
                    Yes, Permission Letter Vide Date
                    {{
                      drawerClaimData.HOD_PERMISSION_DATE | date : "dd-MM-yyyy"
                    }}
                  </span>
                  <span
                    *ngIf="
                      drawerClaimData.IS_PERMISSION_TAKEN_FROM_HOD == 0 &&
                      drawerClaimData.EXPO_FACTO_PERMISSION == 0
                    "
                  >
                    NA</span
                  >
                  <span
                    *ngIf="
                      drawerClaimData.IS_PERMISSION_TAKEN_FROM_HOD == 0 &&
                      drawerClaimData.EXPO_FACTO_PERMISSION == 1
                    "
                  >
                    Yes, Expost Facto Permission Letter Vide Date
                    {{
                      drawerClaimData.EXPO_FACTO_DATE | date : "dd-MM-yyyy"
                    }}</span
                  >
                </span>
              </td>
            </tr>

            <tr>
              <td style="text-align: center !important">10.</td>
              <td>Nature of treatment / tests</td>
              <td style="text-align: center !important">:</td>
              <td>
                {{ drawerClaimData.NATURE_OF_TREATMENT }}
              </td>
            </tr>
            <tr>
              <td style="text-align: center !important">11.</td>
              <td>Period of treatment / tests</td>
              <td style="text-align: center !important">:</td>
              <td>
                {{ drawerClaimData.TREATMENT_START_DATE | date : "dd-MM-yyyy" }}
                To
                {{ drawerClaimData.TREATMENT_END_DATE | date : "dd-MM-yyyy" }}
              </td>
            </tr>
            <tr>
              <td style="text-align: center !important">12.</td>
              <td>Amount of reimbursement claimed</td>
              <td style="text-align: center !important">:</td>
              <td>₹ {{ drawerClaimData.CLAIMED_AMOUNT }}</td>
            </tr>
            <tr>
              <td style="text-align: center !important">13.</td>
              <td>Amount of reimbursement admissible</td>
              <td style="text-align: center !important">:</td>
              <td>₹ {{ drawerClaimData.ADMISSIBLE_AMOUNT }}</td>
            </tr>

            <tr>
              <td style="text-align: center !important">14.</td>
              <td>Advance taken, if any</td>
              <td style="text-align: center !important">:</td>
              <td>
                <span *ngIf="drawerClaimData.IS_ADVANCE_TAKEN == 1">
                  Yes, ₹ {{ drawerClaimData.ADVANCE_AMOUNT }}
                </span>
                <span *ngIf="drawerClaimData.IS_ADVANCE_TAKEN == 0"> No </span>
              </td>
            </tr>

            <tr>
              <td style="text-align: center !important">15.</td>
              <td>Net amount payable/recoverable</td>
              <td style="text-align: center !important">:</td>
              <td>
                <span
                  *ngIf="
                    drawerClaimData.ADMISSIBLE_AMOUNT != undefined &&
                    drawerClaimData.ADMISSIBLE_AMOUNT != null &&
                    drawerClaimData.ADVANCE_AMOUNT != undefined &&
                    drawerClaimData.ADVANCE_AMOUNT != null
                  "
                >
                  ₹{{
                    drawerClaimData.ADMISSIBLE_AMOUNT -
                      drawerClaimData.ADVANCE_AMOUNT
                  }}
                </span>
                <span
                  *ngIf="
                    drawerClaimData.ADMISSIBLE_AMOUNT != undefined &&
                    drawerClaimData.ADMISSIBLE_AMOUNT != null &&
                    (drawerClaimData.ADVANCE_AMOUNT == undefined ||
                      drawerClaimData.ADVANCE_AMOUNT == null)
                  "
                >
                  {{ drawerClaimData.ADMISSIBLE_AMOUNT }}
                </span>
              </td>
            </tr>
          </table>

          <div
            style="
              font-size: 12px;
              text-align: justify;
              margin: 20px 10px;
              page-break-inside: avoid !important;
            "
          >
            a) In view of the prior permission from HOD/Emergency Certificate
            furnished from the hospital, recommended for medical reimbursement.
            <br />
            b) Neither any permission from the HOD is taken nor any Emergency
            Certificate is furnished from the hospital, hence not recommended
            for medical reimbursement.
            <br />
            c) In view of the treatment taken at
            <span *ngFor="let hospital of hospitallist">
              {{
                hospital.TYPE == "E"
                  ? "Empanelled"
                  : hospital.TYPE == "NE"
                  ? "Non Empanelled"
                  : hospital.TYPE == "G"
                  ? "Government"
                  : ""
              }},
            </span>
            Lab/Hospital, recommended for medical reimbursement.
            <br />
            d) The patient is taking lifelong treatment, for which permission
            has been granted vide letter dated
            <span style="border-bottom: 1px solid grey">
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;
            </span>
            . The present bill relates to continuous treatment taken by the
            official.
            <br />
            e) The admissible amount is worked out as per CGHS rate.
          </div>

          <div
            style="
              font-weight: bold;
              margin: 10px 0px;
              page-break-inside: avoid !important;
            "
          >
            The checklist of the above mentioned bill is as under : -
          </div>
          <table
            class="table"
            style="
              font-size: 12px;
              page-break-inside: avoid !important;
              border: 1px solid black !important;
              border-collapse: collapse !important;
            "
          >
            <tr>
              <td style="width: 5%; text-align: center !important">1.</td>
              <td style="width: 80%">
                Forwarding letter (through proper channel)
              </td>
              <td style="width: 15%; text-align: center !important">
                <span
                  *ngIf="
                    drawerClaimData != undefined &&
                    drawerClaimData.IS_FORWARDING_LETTER == 1
                  "
                >
                  Yes
                </span>
                <span
                  *ngIf="
                    drawerClaimData != undefined &&
                    drawerClaimData.IS_FORWARDING_LETTER == 0
                  "
                >
                  No</span
                >
              </td>
            </tr>
            <tr>
              <td style="text-align: center !important">2.</td>
              <td>Medical Reimbursement Claim (MRC) form for (CGHS/CSMA)</td>
              <td style="text-align: center !important">
                <span
                  *ngIf="
                    drawerClaimData != undefined &&
                    drawerClaimData.MEDICAL_REIMBURSMENT_CLAIM_FORM_FOR_CGHS ==
                      1
                  "
                >
                  Yes
                </span>
                <span
                  *ngIf="
                    drawerClaimData != undefined &&
                    drawerClaimData.MEDICAL_REIMBURSMENT_CLAIM_FORM_FOR_CGHS ==
                      0
                  "
                >
                  No</span
                >
              </td>
            </tr>

            <tr>
              <td style="text-align: center !important">3.</td>
              <td>Copy of CGHS Card for both beneficiary and patient</td>
              <td style="text-align: center !important">
                <span
                  *ngIf="
                    drawerClaimData != undefined &&
                    drawerClaimData.COPY_OF_CGHS_CARD == 'Y'
                  "
                >
                  Yes
                </span>
                <span
                  *ngIf="
                    drawerClaimData != undefined &&
                    drawerClaimData.COPY_OF_CGHS_CARD == 'N'
                  "
                >
                  No</span
                >
                <span
                  *ngIf="
                    drawerClaimData != undefined &&
                    drawerClaimData.COPY_OF_CGHS_CARD == 'NA'
                  "
                >
                  NA</span
                >
              </td>
            </tr>

            <tr>
              <td style="text-align: center !important">4.</td>
              <td>Discharge Card</td>
              <td style="text-align: center !important">
                <span
                  *ngIf="
                    drawerClaimData != undefined &&
                    drawerClaimData.DISCHARGE_CARD == 'Y'
                  "
                >
                  Yes
                </span>
                <span
                  *ngIf="
                    drawerClaimData != undefined &&
                    drawerClaimData.DISCHARGE_CARD == 'N'
                  "
                >
                  No</span
                >
                <span
                  *ngIf="
                    drawerClaimData != undefined &&
                    drawerClaimData.DISCHARGE_CARD == 'NA'
                  "
                >
                  NA</span
                >
              </td>
            </tr>

            <tr>
              <td style="text-align: center !important">5.</td>
              <td>
                Form No.3 in case the medical claim is of the official's family
                member for AMA Beneficiary
              </td>
              <td style="text-align: center !important">
                <span
                  *ngIf="
                    drawerClaimData != undefined &&
                    drawerClaimData.FORM_NO_3_MEDICAL_CLAIM == 'Y'
                  "
                >
                  Yes
                </span>
                <span
                  *ngIf="
                    drawerClaimData != undefined &&
                    drawerClaimData.FORM_NO_3_MEDICAL_CLAIM == 'N'
                  "
                >
                  No</span
                >
                <span
                  *ngIf="
                    drawerClaimData != undefined &&
                    drawerClaimData.FORM_NO_3_MEDICAL_CLAIM == 'NA'
                  "
                >
                  NA</span
                >
              </td>
            </tr>

            <tr>
              <td style="text-align: center !important">6.</td>
              <td>Final Bill in Original along with one Xerox Copy</td>
              <td style="text-align: center !important">
                <span
                  *ngIf="
                    drawerClaimData != undefined &&
                    drawerClaimData.FINAL_BILL_ORIGINAL_ALONG_WITH_XEROX == 1
                  "
                >
                  Yes
                </span>
                <span
                  *ngIf="
                    drawerClaimData != undefined &&
                    drawerClaimData.FINAL_BILL_ORIGINAL_ALONG_WITH_XEROX == 0
                  "
                >
                  No</span
                >
              </td>
            </tr>

            <tr>
              <td style="text-align: center !important">7.</td>
              <td>Pay Slip of the period of treatment</td>
              <td style="text-align: center !important">
                <span
                  *ngIf="
                    drawerClaimData != undefined &&
                    drawerClaimData.PAYSLIP_OF_PERIOD_OF_TREATMENT == 1
                  "
                >
                  Yes
                </span>
                <span
                  *ngIf="
                    drawerClaimData != undefined &&
                    drawerClaimData.PAYSLIP_OF_PERIOD_OF_TREATMENT == 0
                  "
                >
                  No</span
                >
              </td>
            </tr>

            <tr>
              <td style="text-align: center !important">8.</td>
              <td>Bank mandate form alongwith cancelled Cheque</td>
              <td style="text-align: center !important">
                <span
                  *ngIf="
                    drawerClaimData != undefined &&
                    drawerClaimData.BANK_MANDATE_FORM_ALONG_WITH_CANCELLED_CHEQUE ==
                      1
                  "
                >
                  Yes
                </span>
                <span
                  *ngIf="
                    drawerClaimData != undefined &&
                    drawerClaimData.BANK_MANDATE_FORM_ALONG_WITH_CANCELLED_CHEQUE ==
                      0
                  "
                >
                  No</span
                >
              </td>
            </tr>
            <tr>
              <td style="text-align: center !important">9.</td>
              <td>Prescription of medicines purchased from outside</td>
              <td style="text-align: center !important">
                <span
                  *ngIf="
                    drawerClaimData != undefined &&
                    drawerClaimData.PRESCIPTION_OF_MEDICINES_PURSCHASED_OUTSIDE ==
                      'Y'
                  "
                >
                  Yes
                </span>
                <span
                  *ngIf="
                    drawerClaimData != undefined &&
                    drawerClaimData.PRESCIPTION_OF_MEDICINES_PURSCHASED_OUTSIDE ==
                      'N'
                  "
                >
                  No</span
                >
                <span
                  *ngIf="
                    drawerClaimData != undefined &&
                    drawerClaimData.PRESCIPTION_OF_MEDICINES_PURSCHASED_OUTSIDE ==
                      'NA'
                  "
                >
                  NA</span
                >
              </td>
            </tr>
          </table>

          <div
            style="
              text-align: justify;
              margin-top: 22px;
              page-break-inside: avoid !important;
            "
          >
            &nbsp; &nbsp; &nbsp;The Commissioner of Income-tax (Admn. & TPS),
            Mumbai, may kindly peruse and consider to sanction an amount of Rs.
            {{ drawerClaimData.ADMISSIBLE_AMOUNT }} ({{ amountInwords }}) to be
            reimbursed to {{ drawerClaimData.EMPLOYEE_NAME }},
            {{ drawerClaimData.EMPLOYEE_DESIGNATION }},
            {{ drawerClaimData.EMPLOYEE_OFFICE_NAME }}. Sanction Order (2
            copies) is put up for signature, please.
          </div>

          <div
            style="
              page-break-inside: avoid !important;
              display: inline-flex;
              width: 100% !important;
              margin-top: 80px;
            "
          >
            <div style="width: 60% !important">
              Prepared & verified by : Inspector
            </div>
            <div style="width: 40% !important">
              Checked by : Administrative Officer
            </div>
          </div>

          <div
            style="
              page-break-inside: avoid !important;
              display: inline-flex;
              width: 100% !important;
              margin-top: 80px;
            "
          >
            <div style="width: 60% !important">Jt.CIT(HQ)Personnel,Mumbai.</div>
            <div style="width: 40% !important">
              <span *ngIf="SECTION_TYPE == 'welfare'">
                ITO Welfare & SG, Mumbai.
              </span>
              <span *ngIf="SECTION_TYPE == 'gazpay'">
                ITO Gazetted Pay Bill Section II, Personnel Mumbai.
              </span>
            </div>
          </div>

          <div
            style="
              page-break-inside: avoid !important;
              display: inline-flex;
              width: 100% !important;
              margin-top: 80px;
            "
          >
            <div style="width: 100% !important; font-weight: bold">
              CIT(Admin & TPS)Personnel,Mumbai.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nz-drawer>

<!-- <nz-drawer
      [nzBodyStyle]="{
        height: 'calc(100% - 55px)',
        overflow: 'auto',
        'padding-bottom': '53px'
      }"
      [nzClosable]="true"
      [nzMask]="true"
      [nzMaskClosable]="false"
      [nzWidth]="1100"
      [nzVisible]="drawerClaimVisible"
      [nzTitle]="drawerClaimTitle"
      (nzOnClose)="drawerClaimClose()"
      (nzKeyboard)="(true)"
    >
      <div *ngIf="drawerClaimData != undefined">
        <div style="text-align: right; margin-bottom: 5px">
          <button
            nz-button
            nzType="default"
            [useExistingCss]="true"
            printSectionId="claimFile"
            ngxPrint
          >
            Print
          </button>
    
          <button
            nz-button
            nzType="primary"
            [nzLoading]="pdfDownload"
            (click)="generatePDF()"
            style="margin-left: 20px"
          >
            Download
          </button>
        </div>
    
        <div style="height: 80vh; overflow-y: scroll">
          <div id="claimFile" #claimFile>
            <div style="font-family: Arial, Helvetica, sans-serif !important">
              <div
                style="
                  text-align: center;
                  font-weight: bold;
                  font-size: 20px;
                  margin-bottom: 10px;
                  margin-top: 10px;
                "
              >
                Medical Reimbursement Claim
              </div>
    
              <table
                class="table"
                style="
                  font-size: 12px;
                  page-break-inside: avoid !important;
                  border: 1px solid black !important;
                  border-collapse: collapse !important;
                "
              >
                <tr>
                  <td style="width: 5%; text-align: center !important">1.</td>
                  <td style="width: 46%">Name of the Applicant</td>
                  <td style="width: 4%; text-align: center !important">:</td>
                  <td style="width: 45%">
                    {{ drawerClaimData.EMPLOYEE_NAME }}
                  </td>
                </tr>
                <tr>
                  <td style="text-align: center !important">2.</td>
                  <td>Designation & Office</td>
                  <td style="text-align: center !important">:</td>
                  <td>
                    {{ drawerClaimData.EMPLOYEE_DESIGNATION }},
                    {{ drawerClaimData.EMPLOYEE_OFFICE_NAME }}
                  </td>
                </tr>
    
                <tr>
                  <td style="text-align: center !important">3.</td>
                  <td>DDO of the Official</td>
                  <td style="text-align: center !important">:</td>
                  <td>
                    {{ drawerClaimData.DDO_OF_THE_OFFICIAL }}
                  </td>
                </tr>
    
                <tr>
                  <td style="text-align: center !important">4.</td>
                  <td>Employee Code No.</td>
                  <td style="text-align: center !important">:</td>
                  <td>
                    {{ drawerClaimData.EMPLOYEE_CODE }}
                  </td>
                </tr>
    
                <tr>
                  <td style="text-align: center !important">5.</td>
                  <td>Patient's Relationship</td>
                  <td style="text-align: center !important">:</td>
                  <td>
                    <span *ngIf="drawerClaimData.RELATION_WITH_PATIENT == 'Self'">{{
                      drawerClaimData.RELATION_WITH_PATIENT
                    }}</span>
                    <span *ngIf="drawerClaimData.RELATION_WITH_PATIENT != 'Self'"
                      >{{ drawerClaimData.RELATION_WITH_PATIENT }} ,
                      {{ drawerClaimData.PATIENT_NAME }}</span
                    >
                  </td>
                </tr>
    
                <tr>
                  <td style="text-align: center !important">6.</td>
                  <td>Whether CGHS/CS (MA) Beneficiary</td>
                  <td style="text-align: center !important">:</td>
                  <td>CGHS Card No.{{ drawerClaimData.CGHS_CARD_NO }}</td>
                </tr>
    
                <tr>
                  <td rowspan="2" style="text-align: center !important">7.</td>
                  <td>Whether emergency treatment is availed?</td>
                  <td style="text-align: center !important">:</td>
                  <td>
                    <span *ngIf="drawerClaimData.EMERGENCY_TREATEMENT == 1">
                      Yes
                    </span>
                    <span *ngIf="drawerClaimData.EMERGENCY_TREATEMENT == 0">
                      No
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>If not, date of CGHS/AMA's reference.</td>
                  <td style="text-align: center !important">:</td>
                  <td>
                    <span *ngIf="drawerClaimData.EMERGENCY_TREATEMENT == 0">
                      <span
                        *ngIf="
                          drawerClaimData != undefined &&
                          drawerClaimData.IS_PERMISSION_TAKEN_FROM_HOD != undefined
                        "
                      >
                        CGHS Reference letter dated
                        {{ drawerClaimData.CGHS_AMA_REFERENCE_DATE }}
                      </span>
                    </span>
                  </td>
                </tr>
    
                <tr>
                  <td rowspan="2" style="text-align: center !important">8.</td>
                  <td>Date of filing the bill.</td>
                  <td style="text-align: center !important">:</td>
                  <td>
                    {{ drawerClaimData.BILL_FILIING_DATE | date : "dd/MM/yyyy" }}
                  </td>
                </tr>
                <tr>
                  <td>Whether the bill is filed in time?</td>
                  <td style="text-align: center !important">:</td>
                  <td>
                    <span
                      *ngIf="
                        drawerClaimData != undefined &&
                        drawerClaimData.IS_PERMISSION_TAKEN_FROM_HOD != undefined
                      "
                    >
                      <span *ngIf="drawerClaimData.BILL_FILLED_INTIME == 1">
                        Yes
                      </span>
                      <span *ngIf="drawerClaimData.BILL_FILLED_INTIME == 0">
                        No
                        <span
                          *ngIf="
                            drawerClaimData.DELAY_CONDOLENCE_DATE != undefined &&
                            drawerClaimData.DELAY_CONDOLENCE_DATE != null
                          "
                        >
                          , Condonation letter dated
                          {{ drawerClaimData.DELAY_CONDOLENCE_DATE }}
                        </span>
                      </span>
                    </span>
                  </td>
                </tr>
    
                <tr>
                  <td rowspan="2" style="text-align: center !important">9.</td>
                  <td>
                    Name of the Hospital Diagnostic Centre (Empanelled /
                    Non-empanelled / Government)
                  </td>
                  <td style="text-align: center !important">:</td>
                  <td>
                    <span *ngFor="let hospital of hospitallist">
                      {{ hospital.NAME }}-{{
                        hospital.TYPE == "E"
                          ? "Empanelled"
                          : hospital.TYPE == "NE"
                          ? "Non Empanelled"
                          : hospital.TYPE == "G"
                          ? "Government"
                          : ""
                      }}
                      ({{ hospital.ADDRESS }}),
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    If Private Hospital / Diagnostic Centre, whether
                    permission/ex-post facto approval taken from HOD or not?
                  </td>
                  <td style="text-align: center !important">:</td>
                  <td>
                    <span
                      nz-icon
                      *ngIf="
                        drawerClaimData != undefined &&
                        drawerClaimData.IS_PERMISSION_TAKEN_FROM_HOD != undefined
                      "
                    >
                      <span
                        *ngIf="drawerClaimData.IS_PERMISSION_TAKEN_FROM_HOD == 1"
                      >
                        Yes, Permission Letter Vide Date
                        {{
                          drawerClaimData.HOD_PERMISSION_DATE | date : "dd/MM/yyyy"
                        }}
                      </span>
                      <span
                        *ngIf="
                          drawerClaimData.IS_PERMISSION_TAKEN_FROM_HOD == 0 &&
                          drawerClaimData.EXPO_FACTO_PERMISSION == 0
                        "
                      >
                        NA</span
                      >
                      <span
                        *ngIf="
                          drawerClaimData.IS_PERMISSION_TAKEN_FROM_HOD == 0 &&
                          drawerClaimData.EXPO_FACTO_PERMISSION == 1
                        "
                      >
                        Yes, Expost Facto Permission Letter Vide Date
                        {{
                          drawerClaimData.EXPO_FACTO_DATE | date : "dd/MM/yyyy"
                        }}</span
                      >
                    </span>
                  </td>
                </tr>
    
                <tr>
                  <td style="text-align: center !important">10.</td>
                  <td>Nature of treatment / tests</td>
                  <td style="text-align: center !important">:</td>
                  <td>
                    {{ drawerClaimData.NATURE_OF_TREATMENT }}
                  </td>
                </tr>
                <tr>
                  <td style="text-align: center !important">11.</td>
                  <td>Period of treatment / tests</td>
                  <td style="text-align: center !important">:</td>
                  <td>
                    {{ drawerClaimData.TREATMENT_START_DATE | date : "dd/MM/yyyy" }}
                    To
                    {{ drawerClaimData.TREATMENT_END_DATE | date : "dd/MM/yyyy" }}
                  </td>
                </tr>
                <tr>
                  <td style="text-align: center !important">12.</td>
                  <td>Amount of reimbursement claimed</td>
                  <td style="text-align: center !important">:</td>
                  <td>₹ {{ drawerClaimData.CLAIMED_AMOUNT }}</td>
                </tr>
                <tr>
                  <td style="text-align: center !important">13.</td>
                  <td>Amount of reimbursement admissible</td>
                  <td style="text-align: center !important">:</td>
                  <td>₹ {{ drawerClaimData.ADMISSIBLE_AMOUNT }}</td>
                </tr>
    
                <tr>
                  <td style="text-align: center !important">14.</td>
                  <td>Advance taken, if any</td>
                  <td style="text-align: center !important">:</td>
                  <td>
                    <span *ngIf="drawerClaimData.IS_ADVANCE_TAKEN == 1">
                      Yes, ₹ {{ drawerClaimData.ADVANCE_AMOUNT }}
                    </span>
                    <span *ngIf="drawerClaimData.IS_ADVANCE_TAKEN == 0"> No </span>
                  </td>
                </tr>
    
                <tr>
                  <td style="text-align: center !important">15.</td>
                  <td>Net amount payable/recoverable</td>
                  <td style="text-align: center !important">:</td>
                  <td>
                    <span
                      *ngIf="
                        drawerClaimData.ADMISSIBLE_AMOUNT != undefined &&
                        drawerClaimData.ADMISSIBLE_AMOUNT != null &&
                        drawerClaimData.ADVANCE_AMOUNT != undefined &&
                        drawerClaimData.ADVANCE_AMOUNT != null
                      "
                    >
                      ₹{{
                        drawerClaimData.ADMISSIBLE_AMOUNT -
                          drawerClaimData.ADVANCE_AMOUNT
                      }}
                    </span>
                    <span
                      *ngIf="
                        drawerClaimData.ADMISSIBLE_AMOUNT != undefined &&
                        drawerClaimData.ADMISSIBLE_AMOUNT != null &&
                        (drawerClaimData.ADVANCE_AMOUNT == undefined ||
                          drawerClaimData.ADVANCE_AMOUNT == null)
                      "
                    >
                      {{ drawerClaimData.ADMISSIBLE_AMOUNT }}
                    </span>
                  </td>
                </tr>
              </table>
    
              <div
                style="
                  font-size: 12px;
                  text-align: justify;
                  margin: 20px 10px;
                  page-break-inside: avoid !important;
                "
              >
                a) In view of the prior permission from HOD/Emergency Certificate
                furnished from the hospital, recommended for medical reimbursement.
                <br />
                b) Neither any permission from the HOD is taken nor any Emergency
                Certificate is furnished from the hospital, hence not recommended
                for medical reimbursement.
                <br />
                c) In view of the treatment taken at
                <span *ngFor="let hospital of hospitallist">
                  {{
                    hospital.TYPE == "E"
                      ? "Empanelled"
                      : hospital.TYPE == "NE"
                      ? "Non Empanelled"
                      : hospital.TYPE == "G"
                      ? "Government"
                      : ""
                  }},
                </span>
                Lab/Hospital, recommended for medical reimbursement.
                <br />
                d) The patient is taking lifelong treatment, for which permission
                has been granted vide letter dated
                <span style="border-bottom: 1px solid grey">
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;
                </span>
                . The present bill relates to continuous treatment taken by the
                official.
                <br />
                e) The admissible amount is worked out as per CGHS rate.
              </div>
    
              <div
                style="
                  font-weight: bold;
                  margin: 10px 0px;
                  page-break-inside: avoid !important;
                "
              >
                The checklist of the above mentioned bill is as under : -
              </div>
              <table
                class="table"
                style="
                  font-size: 12px;
                  page-break-inside: avoid !important;
                  border: 1px solid black !important;
                  border-collapse: collapse !important;
                "
              >
                <tr>
                  <td style="width: 5%; text-align: center !important">1.</td>
                  <td style="width: 80%">
                    Forwarding letter (through proper channel)
                  </td>
                  <td style="width: 15%; text-align: center !important">
                    <span
                      *ngIf="
                        drawerClaimData != undefined &&
                        drawerClaimData.IS_FORWARDING_LETTER == 1
                      "
                    >
                      Yes
                    </span>
                    <span
                      *ngIf="
                        drawerClaimData != undefined &&
                        drawerClaimData.IS_FORWARDING_LETTER == 0
                      "
                    >
                      No</span
                    >
                  </td>
                </tr>
                <tr>
                  <td style="text-align: center !important">2.</td>
                  <td>Medical Reimbursement Claim (MRC) form for (CGHS/CSMA)</td>
                  <td style="text-align: center !important">
                    <span
                      *ngIf="
                        drawerClaimData != undefined &&
                        drawerClaimData.MEDICAL_REIMBURSMENT_CLAIM_FORM_FOR_CGHS ==
                          1
                      "
                    >
                      Yes
                    </span>
                    <span
                      *ngIf="
                        drawerClaimData != undefined &&
                        drawerClaimData.MEDICAL_REIMBURSMENT_CLAIM_FORM_FOR_CGHS ==
                          0
                      "
                    >
                      No</span
                    >
                  </td>
                </tr>
    
                <tr>
                  <td style="text-align: center !important">3.</td>
                  <td>Copy of CGHS Card for both beneficiary and patient</td>
                  <td style="text-align: center !important">
                    <span
                      *ngIf="
                        drawerClaimData != undefined &&
                        drawerClaimData.COPY_OF_CGHS_CARD == 'Y'
                      "
                    >
                      Yes
                    </span>
                    <span
                      *ngIf="
                        drawerClaimData != undefined &&
                        drawerClaimData.COPY_OF_CGHS_CARD == 'N'
                      "
                    >
                      No</span
                    >
                    <span
                      *ngIf="
                        drawerClaimData != undefined &&
                        drawerClaimData.COPY_OF_CGHS_CARD == 'NA'
                      "
                    >
                      NA</span
                    >
                  </td>
                </tr>
    
                <tr>
                  <td style="text-align: center !important">4.</td>
                  <td>Discharge Card</td>
                  <td style="text-align: center !important">
                    <span
                      *ngIf="
                        drawerClaimData != undefined &&
                        drawerClaimData.DISCHARGE_CARD == 'Y'
                      "
                    >
                      Yes
                    </span>
                    <span
                      *ngIf="
                        drawerClaimData != undefined &&
                        drawerClaimData.DISCHARGE_CARD == 'N'
                      "
                    >
                      No</span
                    >
                    <span
                      *ngIf="
                        drawerClaimData != undefined &&
                        drawerClaimData.DISCHARGE_CARD == 'NA'
                      "
                    >
                      NA</span
                    >
                  </td>
                </tr>
    
                <tr>
                  <td style="text-align: center !important">5.</td>
                  <td>
                    Form No.3 in case the medical claim is of the official's family
                    member for AMA Beneficiary
                  </td>
                  <td style="text-align: center !important">
                    <span
                      *ngIf="
                        drawerClaimData != undefined &&
                        drawerClaimData.FORM_NO_3_MEDICAL_CLAIM == 'Y'
                      "
                    >
                      Yes
                    </span>
                    <span
                      *ngIf="
                        drawerClaimData != undefined &&
                        drawerClaimData.FORM_NO_3_MEDICAL_CLAIM == 'N'
                      "
                    >
                      No</span
                    >
                    <span
                      *ngIf="
                        drawerClaimData != undefined &&
                        drawerClaimData.FORM_NO_3_MEDICAL_CLAIM == 'NA'
                      "
                    >
                      NA</span
                    >
                  </td>
                </tr>
    
                <tr>
                  <td style="text-align: center !important">6.</td>
                  <td>Final Bill in Original along with one Xerox Copy</td>
                  <td style="text-align: center !important">
                    <span
                      *ngIf="
                        drawerClaimData != undefined &&
                        drawerClaimData.FINAL_BILL_ORIGINAL_ALONG_WITH_XEROX == 1
                      "
                    >
                      Yes
                    </span>
                    <span
                      *ngIf="
                        drawerClaimData != undefined &&
                        drawerClaimData.FINAL_BILL_ORIGINAL_ALONG_WITH_XEROX == 0
                      "
                    >
                      No</span
                    >
                  </td>
                </tr>
    
                <tr>
                  <td style="text-align: center !important">7.</td>
                  <td>Pay Slip of the period of treatment</td>
                  <td style="text-align: center !important">
                    <span
                      *ngIf="
                        drawerClaimData != undefined &&
                        drawerClaimData.PAYSLIP_OF_PERIOD_OF_TREATMENT == 1
                      "
                    >
                      Yes
                    </span>
                    <span
                      *ngIf="
                        drawerClaimData != undefined &&
                        drawerClaimData.PAYSLIP_OF_PERIOD_OF_TREATMENT == 0
                      "
                    >
                      No</span
                    >
                  </td>
                </tr>
    
                <tr>
                  <td style="text-align: center !important">8.</td>
                  <td>Bank mandate form alongwith cancelled Cheque</td>
                  <td style="text-align: center !important">
                    <span
                      *ngIf="
                        drawerClaimData != undefined &&
                        drawerClaimData.BANK_MANDATE_FORM_ALONG_WITH_CANCELLED_CHEQUE ==
                          1
                      "
                    >
                      Yes
                    </span>
                    <span
                      *ngIf="
                        drawerClaimData != undefined &&
                        drawerClaimData.BANK_MANDATE_FORM_ALONG_WITH_CANCELLED_CHEQUE ==
                          0
                      "
                    >
                      No</span
                    >
                  </td>
                </tr>
                <tr>
                  <td style="text-align: center !important">9.</td>
                  <td>Prescription of medicines purchased from outside</td>
                  <td style="text-align: center !important">
                    <span
                      *ngIf="
                        drawerClaimData != undefined &&
                        drawerClaimData.PRESCIPTION_OF_MEDICINES_PURSCHASED_OUTSIDE ==
                          'Y'
                      "
                    >
                      Yes
                    </span>
                    <span
                      *ngIf="
                        drawerClaimData != undefined &&
                        drawerClaimData.PRESCIPTION_OF_MEDICINES_PURSCHASED_OUTSIDE ==
                          'N'
                      "
                    >
                      No</span
                    >
                    <span
                      *ngIf="
                        drawerClaimData != undefined &&
                        drawerClaimData.PRESCIPTION_OF_MEDICINES_PURSCHASED_OUTSIDE ==
                          'NA'
                      "
                    >
                      NA</span
                    >
                  </td>
                </tr>
              </table>
    
              <div
                style="
                  text-align: justify;
                  margin-top: 60px;
                  page-break-inside: avoid !important;
                "
              >
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;The Commissioner of
                Income-tax (Admn. & TPS), Mumbai, may kindly peruse and consider to
                sanction an amount of Rs.
                {{ drawerClaimData.ADMISSIBLE_AMOUNT }} ({{ amountInwords }}) to be
                reimbursed to {{ drawerClaimData.EMPLOYEE_NAME }},
                {{ drawerClaimData.EMPLOYEE_DESIGNATION }},
                {{ drawerClaimData.EMPLOYEE_OFFICE_NAME }}. Sanction Order (2
                copies) is put up for signature, please.
              </div>
    
              <div
                style="
                  page-break-inside: avoid !important;
                  display: inline-flex;
                  width: 100% !important;
                  margin-top: 60px;
                "
              >
                <div style="width: 60% !important">
                  Prepared & verified by : Inspector
                </div>
                <div style="width: 40% !important">
                  Checked by : Administrative Officer
                </div>
              </div>
    
              <div
                style="
                  page-break-inside: avoid !important;
                  display: inline-flex;
                  width: 100% !important;
                  margin-top: 60px;
                "
              >
                <div style="width: 60% !important">Jt.CIT(HQ)Personnel,Mumbai.</div>
                <div style="width: 40% !important">
                  <span *ngIf="drawerClaimData.PAY_BILL == 'NonGazPay'">
                    ITO Welfare & SG, Mumbai.
                  </span>
                  <span *ngIf="drawerClaimData.PAY_BILL == 'GazPay'">
                    ITO Gazetted Pay Bill Section II, Personnel Mumbai.
                  </span>
                </div>
              </div>
    
              <div
                style="
                  page-break-inside: avoid !important;
                  display: inline-flex;
                  width: 100% !important;
                  margin-top: 60px;
                "
              >
                <div style="width: 100% !important; font-weight: bold">
                  CIT(Admin & TPS)Personnel,Mumbai.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-drawer> -->

<nz-modal
  nzWidth="380px"
  [(nzVisible)]="isApproveVisible"
  nzTitle="Transfer File "
  [nzFooter]="salesFooter"
  [nzContent]="approveModalContentHP"
  (nzOnCancel)="handleApproveCancel()"
>
  <ng-template #approveModalContentHP>
    <nz-spin [nzSpinning]="isApproveModalConfirmLoadingHP">
      <div nz-row>
        <div nz-col nzSpan="24">
          <nz-form-item
            style="
              display: inline-block;
              margin-bottom: 5px;
              width: -webkit-fill-available;
              padding: 0px 10px;
            "
          >
            <nz-form-label nzRequired nzNoColon> Select AO </nz-form-label>
            <nz-form-control nzErrorTip="Please Select AO">
              <nz-select
                nzShowSearch
                name="APPROVER_ID"
                [(ngModel)]="AO_ID"
                nzAllowClear
                nzPlaceHolder="Select AO"
                style="width: 300px"
              >
                <nz-option
                  *ngFor="let item of users"
                  [nzLabel]="item.NAME"
                  [nzValue]="item.ID"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="24">
          <nz-form-item
            style="
              display: inline-block;
              margin-bottom: 5px;
              width: -webkit-fill-available;
              padding: 0px 10px;
            "
          >
            <nz-form-label nzNoColon>Remark</nz-form-label>

            <nz-form-control
              nzHasFeedback
              nzErrorTip="Please Enter Valid Remark"
            >
              <textarea
                nz-input
                rows="2"
                name="remark"
                placeHolder="Enter Remark"
                [(ngModel)]="REMARK"
                maxlength="500"
              >
              </textarea>
            </nz-form-control>

            <span *ngIf="REMARK" style="font-size: 10px; color: red">
              {{ REMARK.length }}/ 500
            </span>
          </nz-form-item>
        </div>
      </div>
    </nz-spin>
  </ng-template>
  <ng-template #salesFooter>
    <button nz-button nzType="default" (click)="handleApproveCancel()">
      Cancel
    </button>

    <button
      nz-button
      nzType="primary"
      [nzLoading]="isApproveModalConfirmLoading"
      (click)="handleApproveOk()"
    >
      Send
    </button>
  </ng-template>
</nz-modal>

<!-- *********************************************************************************************** -->

<nz-modal
  [(nzVisible)]="AnnexureVisible"
  (nzOnCancel)="AnnexureCancel()"
  [nzWidth]="850"
  [nzContent]="printAnnexureModalContent"
  [nzTitle]="'Annexure File'"
  [nzFooter]="printAnnexureModalFooter"
>
  <ng-template #printAnnexureModalContent>
    <div style="height: 450px; overflow: auto">
      <div id="printAnnexureModal">
        <div style="font-family: Arial, Helvetica, sans-serif !important">
          <span style="margin-left: 45%"><b>ANNEXURE 'A'</b></span>

          <table style="overflow: none; width: 100%">
            <tbody>
              <tr>
                <td
                  style="
                    border: 1px solid black;
                    padding-left: 6px;
                    font-size: 13px;
                  "
                >
                  <b
                    >NAME :

                    <span *ngIf="drawerClaimData != undefined">
                      {{ drawerClaimData.EMPLOYEE_NAME }}
                    </span></b
                  >
                </td>
                <td
                  style="
                    border: 1px solid black;
                    padding-left: 6px;
                    font-size: 13px;
                  "
                >
                  <b>BASIC PAY</b>
                </td>

                <td
                  style="
                    border: 1px solid black;
                    padding-left: 6px;
                    font-size: 13px;
                  "
                >
                  <span *ngIf="drawerClaimData != undefined">
                    {{ drawerClaimData.GRADE_PAY }}
                  </span>
                </td>
              </tr>
              <tr style="width: 30px">
                <td
                  style="
                    border: 1px solid black;
                    padding-left: 6px;
                    font-size: 13px;
                  "
                  rowspan="2"
                >
                  <b>
                    Designation :
                    <span *ngIf="drawerClaimData != undefined">
                      {{ drawerClaimData.EMPLOYEE_DESIGNATION }},
                      {{ drawerClaimData.EMPLOYEE_OFFICE_NAME }}
                    </span></b
                  >
                </td>
                <td
                  style="
                    border: 1px solid black;
                    padding-left: 6px;
                    font-size: 13px;
                  "
                >
                  <b>CGHS CARD NO :</b>
                </td>

                <td
                  style="
                    border: 1px solid black;
                    padding-left: 6px;
                    font-size: 13px;
                  "
                >
                  <span *ngIf="drawerClaimData != undefined">
                    {{ drawerClaimData.CGHS_CARD_NO }}
                  </span>
                </td>
              </tr>
              <tr>
                <td
                  style="
                    border: 1px solid black;
                    padding-left: 6px;
                    font-size: 13px;
                  "
                >
                  <b>EMPLOYEE CODE</b>
                </td>
                <td
                  style="
                    border: 1px solid black;
                    padding-left: 6px;
                    font-size: 13px;
                  "
                >
                  <span *ngIf="drawerClaimData != undefined">
                    {{ drawerClaimData.EMPLOYEE_CODE }}
                  </span>
                </td>
              </tr>

              <tr>
                <td
                  style="
                    border: 1px solid black;
                    padding-left: 6px;
                    font-size: 13px;
                  "
                >
                  <b
                    >PATIENT :
                    <span *ngIf="drawerClaimData != undefined">
                      {{ drawerClaimData.RELATION_WITH_PATIENT }}
                      <span
                        *ngIf="drawerClaimData.RELATION_WITH_PATIENT != 'Self'"
                      >
                        - {{ drawerClaimData.PATIENT_NAME }}
                      </span>
                    </span>
                    <!-- <span   *ngIf="drawerClaimData != undefined">
                        {{ drawerClaimData.RELATION_WITH_PATIENT }} -
                        {{ drawerClaimData.PATIENT_NAME }} - {{ drawerClaimData.CGHS_CARD_NO }}
                      </span> -->
                  </b>
                </td>
                <td
                  style="
                    border: 1px solid black;
                    padding-left: 6px;
                    font-size: 10px;
                  "
                >
                  <b></b>
                </td>

                <td
                  style="
                    border: 1px solid black;
                    padding-left: 6px;
                    font-size: 10px;
                  "
                ></td>
              </tr>
            </tbody>
          </table>

          <table style="overflow: none; width: 100%">
            <tbody>
              <tr>
                <td
                  style="
                    border: 1px solid black;
                    padding-left: 6px;
                    font-size: 13px;
                  "
                >
                  <b>NATURE OF TREATMENT</b>
                </td>
                <td
                  colspan="3"
                  style="
                    border: 1px solid black;
                    padding-left: 6px;
                    font-size: 12px;
                  "
                >
                  <span *ngIf="drawerClaimData != undefined">
                    : {{ drawerClaimData.NATURE_OF_TREATMENT }}
                  </span>
                </td>
              </tr>
              <tr>
                <td
                  style="
                    border: 1px solid black;
                    padding-left: 6px;
                    font-size: 13px;
                  "
                >
                  <b>PERIOD</b>
                </td>
                <td
                  colspan="3"
                  style="
                    border: 1px solid black;
                    padding-left: 6px;
                    font-size: 12px;
                  "
                >
                  <span *ngIf="drawerClaimData != undefined">
                    :
                    {{
                      drawerClaimData.TREATMENT_START_DATE | date : "dd-MM-yyyy"
                    }}
                    To
                    {{
                      drawerClaimData.TREATMENT_END_DATE | date : "dd-MM-yyyy"
                    }}
                  </span>
                </td>
              </tr>
              <tr>
                <td
                  style="
                    border: 1px solid black;
                    padding-left: 6px;
                    font-size: 13px;
                  "
                >
                  <b>NAME OF HOSPITAL</b>
                </td>
                <td
                  colspan="3"
                  style="
                    border: 1px solid black;
                    padding-left: 6px;
                    font-size: 12px;
                  "
                >
                  <span *ngFor="let hospital of hospitallist">
                    {{ hospital.NAME }}-{{
                      hospital.TYPE == "E"
                        ? "Empanelled"
                        : hospital.TYPE == "NE"
                        ? "Non Empanelled"
                        : hospital.TYPE == "G"
                        ? "Government"
                        : ""
                    }}
                    ({{ hospital.ADDRESS }}),
                  </span>
                </td>
              </tr>
              <tr>
                <td
                  style="
                    border: 1px solid black;
                    padding-left: 6px;
                    font-size: 13px;
                  "
                >
                  <b>EMERGENCY OR NOT</b>
                </td>
                <td
                  colspan="3"
                  style="
                    border: 1px solid black;
                    padding-left: 6px;
                    font-size: 12px;
                  "
                >
                  <span *ngIf="drawerClaimData != undefined">
                    <span
                      nz-col
                      nzSpan="24"
                      *ngIf="drawerClaimData.EMERGENCY_TREATEMENT == 1"
                      >EMERGENCY</span
                    >
                    <span
                      nz-col
                      nzSpan="24"
                      *ngIf="drawerClaimData.EMERGENCY_TREATEMENT == 0"
                      >NO</span
                    >
                  </span>
                </td>
              </tr>

              <tr>
                <td
                  style="
                    border: 1px solid black;
                    padding-left: 6px;
                    font-size: 13px;
                  "
                >
                  <b>REFERRED RATE LIST</b>
                </td>
                <td
                  colspan="3"
                  style="
                    border: 1px solid black;
                    padding-left: 6px;
                    font-size: 12px;
                  "
                >
                  : {{ this.REFRED_RATE_LIST }}
                </td>
              </tr>
              <!-- </tbody>
            </table>
            <table style="overflow: none; width: 100%">
              <tbody> -->
              <tr>
                <td
                  style="
                    border: 1px solid black;
                    padding-left: 6px;
                    font-size: 13px;
                  "
                >
                  <b>PARTICULARS</b>
                </td>
                <td
                  style="
                    border: 1px solid black;
                    padding-left: 6px;
                    font-size: 13px;
                  "
                >
                  <b>SCHEDULE NO.</b>
                </td>
                <td
                  style="
                    border: 1px solid black;
                    padding-left: 6px;
                    font-size: 13px;
                  "
                >
                  <b>CLAIMED AMOUNT</b>
                </td>
                <td
                  style="
                    border: 1px solid black;
                    padding-left: 6px;
                    font-size: 13px;
                  "
                >
                  <b>ADMISSIBLE AMOUNT</b>
                </td>
              </tr>

              <tr *ngFor="let data of showlayoutDataList; let i = index">
                <td
                  style="
                    border: 1px solid black;
                    padding-left: 6px;
                    font-size: 11px;
                  "
                >
                  {{ data.PARTICULARS }}
                </td>
                <td
                  style="
                    border: 1px solid black;
                    padding-left: 6px;
                    font-size: 11px;
                  "
                >
                  {{ data["CODE_NO"] }}
                  <span
                    *ngIf="
                      data['SHORT_CODE'] != undefined &&
                      data['SHORT_CODE'] != null &&
                      data['SHORT_CODE'] != ''
                    "
                    >({{ data["SHORT_CODE"] }})</span
                  >
                </td>
                <td
                  style="
                    border: 1px solid black;
                    padding-left: 6px;
                    font-size: 11px;
                  "
                >
                  {{ data.CLAIMED_AMOUNT }}
                </td>
                <td
                  style="
                    border: 1px solid black;
                    padding-left: 6px;
                    font-size: 11px;
                  "
                >
                  {{ data.ADMISSIBLE_AMOUNT }}
                </td>
              </tr>

              <tr>
                <td
                  style="
                    border: 1px solid black;
                    padding-left: 6px;
                    font-size: 11px;
                  "
                >
                  <b>Total</b>
                </td>
                <td style="border: 1px solid black; padding-left: 6px"></td>
                <td
                  style="
                    border: 1px solid black;
                    padding-left: 6px;
                    font-size: 11px;
                  "
                >
                  <b>{{ total }}</b>
                </td>
                <td
                  style="
                    border: 1px solid black;
                    padding-left: 6px;
                    font-size: 11px;
                  "
                >
                  <b>{{ total1 }}</b>
                </td>
              </tr>
            </tbody>
          </table>

          <br />
          <div nz-col nzSpan="24" *ngIf="drawerClaimData != undefined">
            {{ drawerClaimData.ANNEXURE_REMARK }}
          </div>
          <br />
          <div style="font-size: 13px">
            <span> Prepared By: Inspector</span>
          </div>
          <div style="margin-top: 30px; font-size: 13px">
            <span> Checked By: Administrative Officer</span>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #printAnnexureModalFooter>
    <button nz-button nzType="default" (click)="AnnexureCancel()">Close</button>

    <button
      nz-button
      nzType="default"
      [useExistingCss]="true"
      printSectionId="printAnnexureModal"
      ngxPrint
    >
      Print
    </button>

    <button
      nz-button
      nzType="primary"
      [nzLoading]="pdfDownload"
      (click)="downloadclaimFile()"
    >
      Download
    </button>
  </ng-template>
</nz-modal>

<nz-modal
  [nzWidth]="getwidth()"
  [(nzVisible)]="printOrderModalVisible"
  nzMaskClosable="false"
  nzClosable="false"
  [nzContent]="printOrderModalContent"
  [nzTitle]="'Sanction Order'"
  (nzOnCancel)="printOrderModalCancel()"
  [nzFooter]="printOrderModalFooter"
>
  <ng-template #printOrderModalContent>
    <nz-spin [nzSpinning]="loadingRecords">
      <div style="height: 450px; overflow: auto">
        <div id="printOrderModal">
          <div style="width: 700px; padding: 3px; margin-left: 30px">
            <div>
              <div nz-row>
                <div nz-col nzXs="2" nzSm="2" nzMd="2" nzLg="2" nzXl="2">
                  <div style="text-align: right">
                    <img
                      src="assets/govtOfIndia.png"
                      alt
                      height="130px"
                      width="100px"
                    />
                  </div>
                </div>

                <div nz-col nzXs="22" nzSm="22" nzMd="22" nzLg="22" nzXl="22">
                  <div
                    style="width: 100%; text-align: center; font-weight: bold"
                  >
                    <div>भारत सरकार</div>
                    <div style="text-transform: uppercase">
                      Government Of India
                    </div>
                    <div>
                      कार्यालय , आयकर आयुक्त (प्रशासन एवं टीपीएस), मुंबई
                    </div>
                    <div style="text-transform: uppercase">
                      Office Of The Commissioner of Income-Tax (Admin, & TPS)
                    </div>
                    <div>
                      तीसरी मंजिल, आयकर भवन, महर्षी कर्वे रोड, मुंबई -20
                    </div>
                    <div style="text-transform: uppercase">
                      3<sup style="text-transform: lowercase">rd</sup>
                      Floor, aayakar bhavan, Maharshi Karve Road, Mumbai-20
                    </div>
                  </div>
                </div>
              </div>

              <div
                style="
                  width: 100%;
                  border: 1px solid black;
                  padding: 10px;
                  margin-top: 10px;
                  font-size: 13px;
                "
              >
                <div nz-row>
                  <div nz-col nzSpan="12">{{ orderdata.FILE_NUMBER }}</div>

                  <div nz-col nzSpan="12" style="text-align: right">
                    दिनांक
                    :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                  <!-- {{ orderdata.ORDER_DATE | date : "dd MMM yyyy" }} -->
                </div>
              </div>

              <div style="font-family: Arial, Helvetica, sans-serif !important">
                <div
                  style="
                    text-align: center;
                    margin-top: 20px;
                    margin-bottom: 10px;
                    font-size: 13px;
                  "
                >
                  <b>स्वीकृति आदेश/ SANCTION ORDER</b>
                </div>
                <div style="font-size: 14px !important">
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {{ orderdata.EMPLOYEE_NAME_HN }}
                    {{ orderdata.DESIGNATION }}, को उनके द्वारा दिनांक
                    {{
                      orderdata.TREATMENT_START_DATE | date : "dd-MM-yyyy"
                    }}&nbsp; से
                    {{
                      orderdata.TREATMENT_END_DATE | date : "dd-MM-yyyy"
                    }}&nbsp; को {{ orderdata.HOSPITAL_NAME_HN }},
                    {{ orderdata.HOSPITAL_ADDRESS_HN }}

                    में
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Father'"
                        >उनके पिता</span
                      >
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Father'
                        "
                        >उनके दादाजी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Wife'"
                        >उनकी पत्नी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Son'"
                        >उनका बेटा</span
                      >
                      <span
                        *ngIf="orderdata.RELATION_WITH_PATIENT == 'Daughter'"
                        >उनकी बेटी</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Sister'"
                        >उनकी बहन</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Brother'"
                        >उनके भाई</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'"
                        >उनकी मां</span
                      >
                      <span
                        *ngIf="
                          orderdata.RELATION_WITH_PATIENT == 'Grand-Mother'
                        "
                        >उनकी दादी मां</span
                      >
                      <!-- <span
                        *ngIf="orderdata.RELATION_WITH_PATIENT == 'Father-In-Law'"
                        >उनके ससुर</span
                      >
                      <span
                        *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother-In-Law'"
                        >उनकी सासू मां</span
                      > -->
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} के</span
                      >
                      <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Mother'">
                        &nbsp;{{ orderdata.PATIENT_NAME_HN }} कि</span
                      >
                    </span>

                    &nbsp;
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      स्वयं कि</span
                    >
                    {{ orderdata.NATURE_OF_TREATMENT }}
                    के इलाज के लिए खर्च की गई राशि, जो
                    <b
                      >रुपये {{ orderdata.ADMISSIBLE_AMOUNT }}&nbsp; ({{
                        amountinwordsh
                      }})</b
                    >
                    से अधिक नहीं है, को सीजीएचएस दर के अनुसार चिकित्सा व्यय की
                    प्रतिपूर्ती के लिए स्वीकृति प्रदान की जाती है। यह मंजूरी
                    स्वास्थ्य एवं परिवार कल्याण मंत्रालय, भारत सरकार द्वारा जारी
                    दिनांक 18.03.1992 के कार्यालय ज्ञापन सं.
                    एस-11012/1/91/सीजीएचएस (पी) खंड 1 के माध्यम से मुझे प्रदत्त
                    शक्तियो का प्रयोग करते हुए जारी की जाती है।
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sanction is
                    hereby accorded for the reimbursement of medical expenses as
                    per CGHS rate for an amount not exceeding
                    <b
                      >Rs {{ orderdata.ADMISSIBLE_AMOUNT }}&nbsp; ({{
                        amountinwords
                      }})</b
                    >
                    incurred by {{ orderdata.EMPLOYEE_NAME }}&nbsp;,
                    {{ orderdata.DESIGNATION }}
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT != 'Self'">
                      for his/her {{ orderdata.RELATION_WITH_PATIENT }}
                      {{ orderdata.PATIENT_NAME }}</span
                    >
                    <span *ngIf="orderdata.RELATION_WITH_PATIENT == 'Self'">
                      Self</span
                    >
                    &nbsp; for&nbsp;{{ orderdata.NATURE_OF_TREATMENT }}, at
                    <span
                      *ngIf="
                        orderdata.HOSPITAL_ID != undefined ||
                        orderdata.HOSPITAL_ID != null
                      "
                    >
                      {{ orderdata.EMPANELLED_HOSPITAL_NAME }}</span
                    >
                    <span
                      *ngIf="
                        orderdata.HOSPITAL_ID == undefined ||
                        orderdata.HOSPITAL_ID == null
                      "
                    >
                      {{ orderdata.HOSPITAL_NAME }}
                    </span>
                    ,
                    {{ orderdata.HOSPITAL_ADDRESS }}
                    from
                    {{ orderdata.TREATMENT_START_DATE | date : "dd-MM-yyyy" }}
                    to
                    {{ orderdata.TREATMENT_END_DATE | date : "dd-MM-yyyy" }}.
                    This sanction is issued in exercise of the powers vested in
                    me vide Office Memorandum No.S-11012/1/91/CGHS(P) Vol. |
                    dated 18.03.1992 issued by the Ministry of Health & Family
                    Welfare , Government of India.
                  </p>

                  <p
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 0
                    "
                  >
                    यह आपात स्थिति का मामला था, जिसके लिए तत्काल उपचार की
                    आवश्यकता थी अतः गैर-पैनलबद्ध अस्पताल में भर्ती कराया गया था|
                  </p>

                  <p
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 0
                    "
                  >
                    It was a case of emergency , necessitating immediate
                    treatment and was asmitted in a non-empanelled hospital.
                  </p>

                  <p
                    *ngIf="
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 1 &&
                        orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 0 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 1 &&
                        orderdata.IS_HOSPITAL_EMPLANELLED == 0 &&
                        orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 0 &&
                        orderdata.EMERGENCY_TREATEMENT == 0) ||
                      orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1
                    "
                  >
                    दिनांक
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0">
                      {{ orderdata.EXPO_FACTO_DATE }}
                    </span>
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1">
                      {{ orderdata.HOD_PERMISSION_DATE }}
                    </span>
                    के पत्र द्वारा उपरोक्त उपचार के लिये कार्योत्तर अनुमति
                    प्रदान की गई है।
                  </p>

                  <p
                    *ngIf="
                      (orderdata.EXPO_FACTO_PERMISExpostSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 1 &&
                        orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.EMERGENCY_TREATEMENT == 0 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 1 &&
                        orderdata.IS_HOSPITAL_EMPLANELLED == 0 &&
                        orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0) ||
                      (orderdata.EXPO_FACTO_PERMISSION == 1 &&
                        orderdata.REFERENCE_FORM_CGHS_AMA_TAKEN == 0 &&
                        orderdata.EMERGENCY_TREATEMENT == 0) ||
                      orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1
                    "
                  >
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 0">
                      Expost Facto Permission for the above treatment has been
                      granted vide letter dated {{ orderdata.EXPO_FACTO_DATE }}
                    </span>
                    <span *ngIf="orderdata.IS_PERMISSION_TAKEN_FROM_HOD == 1">
                      Permission for the above treatement has been granted vide
                      letter dated {{ orderdata.HOD_PERMISSION_DATE }}
                    </span>
                  </p>
                  <!-- <p
                    *ngIf="
                      orderdata.IS_BILL_FILLED_IN_TIME == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 1 &&
                      orderdata.EXPO_FACTO_PERMISSION == 1
                    "
                  >
                    दिनांक 10/01/2023 के पत्र द्वारा उपरोक्त उपचार के लिए अनुमति
                    प्रदान की गई है।
                  </p>
                  <p
                    *ngIf="
                      orderdata.IS_BILL_FILLED_IN_TIME == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 1 &&
                      orderdata.EXPO_FACTO_PERMISSION == 1
                    "
                  >
                    Permission for the above treatment has been granted vide letter
                    dated 10/01/2023
                  </p> -->

                  <p
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 1
                    "
                  >
                    यह आपात स्थिति का मामला था जिसमें तत्काल प्रवेश की आवश्यकता
                    थी और उसे पैनलबद्ध अस्पताल में भर्ती कराया गया था.
                  </p>
                  <p
                    *ngIf="
                      orderdata.EMERGENCY_TREATEMENT == 1 &&
                      orderdata.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == 1 &&
                      orderdata.IS_HOSPITAL_EMPLANELLED == 1
                    "
                  >
                    It was a case of Emergency necessitating immediate admission
                    and was admitted in empanelled hospital.
                  </p>
                  <p>
                    उक्त व्यथ को वित्तीय वर्ष 2022-2023 के लिए विभाग को आबंटित
                    मुख्य शीर्ष "2021-चिकित्सा" के अंतर्गत अनुदान के नामे डाला
                    जाए।
                  </p>

                  <p>
                    The above expenditure should be debited to the grant under
                    major head "2021" Medical Allotment to the Department for
                    the
                    <b>F.Y 2022-2023</b>
                  </p>

                  <p>
                    हिंदी और अंग्रेजी संस्करण में किसी भी भिन्नता के मामले में
                    आदेश का अंग्रेजी संस्करण मान्य होगा।
                  </p>

                  <p>
                    The English version of the order will prevail in case of any
                    variation in Hindi & English version
                  </p>

                  <div style="margin-top: 100px; font-size: 13px">
                    <div nz-row>
                      <div nz-col nzSpan="15"></div>

                      <div nz-col nzSpan="9" style="text-align: center">
                        <div>(विनय सिन्हा)</div>
                        <div style="font-weight: bold">
                          आयकर आयुक्त (प्रशासन एवं टीपीएस) , मुंबई
                        </div>
                      </div>
                    </div>

                    <div nz-row>
                      <div nz-col nzSpan="15"></div>

                      <div nz-col nzSpan="9" style="text-align: center">
                        <div>(Vinay Sinha)</div>
                        <div style="font-weight: bold; font-size: 13px">
                          Commissioner Of Income Tax(Admin & TPS),Mumbai.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style="page-break-inside: avoid !important; font-size: 13px"
                  >
                    <div style="margin-top: 50px">
                      <b>प्रतिलिपी/Copy to:</b>
                    </div>
                    <div style="margin-top: 20px">
                      1) डीडीओ,कल्याण और एसजी,मुंबई (2 प्रतियां) फॉर्म एमआरसी
                      (एस), अस्पताल रसीद, डिस्चार्ज सारांश आदि के साथ|
                    </div>
                    <div>
                      1) DDO , Welfare & SG , Mumbai (2 copies) alongwith Form
                      MRC(S) , hospital receipts , discharge summary etc.
                    </div>
                    <div style="margin-top: 20px">
                      2) ZAO , प्री-चेक युनिट, मुंबई - चेक
                      <b> {{ orderdata.EMPLOYEE_NAME_HN }}</b>
                      के पक्ष में तैयार किया जा सकता है। |
                    </div>
                    <div>
                      2) ZAO , Pre-check unit , Mumbai - Cheque may be drawn in
                      favour of {{ orderdata.EMPLOYEE_NAME }}.
                    </div>

                    <div style="margin-top: 20px">
                      3)<b>
                        {{ orderdata.EMPLOYEE_NAME_HN }},
                        {{ orderdata.DESIGNATION }}
                        {{ orderdata.EMPLOYEE_OFFICE_NAME }}
                        {{ orderdata.LOCATION }},</b
                      >
                      स्वीकार्य राशि की गणना की प्रति के साथ |
                    </div>

                    <div>
                      3)
                      <b
                        >{{ orderdata.EMPLOYEE_NAME }} ,
                        {{ orderdata.DESIGNATION }}
                        {{ orderdata.EMPLOYEE_OFFICE_NAME }}
                        {{ orderdata.LOCATION }}. ,
                      </b>
                      along with copy of working of admissible amount
                    </div>

                    <div style="margin-top: 100px">
                      <div nz-row>
                        <div nz-col nzSpan="15"></div>

                        <div nz-col nzSpan="9" style="text-align: center">
                          <div style="font-weight: bold">(सुहास एस सालसकर)</div>
                          <div>आयकर अधिकारी (मुख्‍यालय)</div>
                          <div>कल्याण और कर्मचारी शिकायत, मुंबई</div>
                        </div>
                      </div>

                      <div nz-row>
                        <div nz-col nzSpan="15"></div>

                        <div nz-col nzSpan="9" style="text-align: center">
                          <div style="font-weight: bold">
                            (Suhas S. Salaskar)
                          </div>
                          <div>Income Tax Officer(HQ)</div>
                          <div>Welfare & S.G, Mumbai</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>
  </ng-template>

  <ng-template #printOrderModalFooter>
    <button nz-button nzType="default" (click)="printOrderModalCancel()">
      Close
    </button>

    <button
      nz-button
      nzType="default"
      [useExistingCss]="true"
      printSectionId="printOrderModal"
      ngxPrint
    >
      Print
    </button>

    <button
      nz-button
      nzType="primary"
      [nzLoading]="pdfDownload"
      (click)="orderPDF()"
    >
      Download
    </button>
  </ng-template>
</nz-modal>

<!-- <nz-drawer
    [nzBodyStyle]="{
      height: 'calc(100% - 55px)',
      overflow: 'auto',
      'padding-bottom': '53px'
    }"
    [nzClosable]="true"
    [nzMask]="true"
    [nzMaskClosable]="false"
    [nzWidth]="1000"
    [nzKeyboard]="true"
    [nzVisible]="drawerClaimVisible"
    [nzTitle]="orderFormTitle"
    (nzOnClose)="drawerClose1()"
  >
    <ng-container>
      <app-order-form
        [accommodation]="accommodation"
        [totacountofbill]="totacountofbill"
        [railorder]="railorder"
        [tranferchnagesorder]="tranferchnagesorder"
        [journeyfairfaird]="journeyfairfaird"
        [amount]="[amount]"
        [raiamount]="raiamount"
        [drawerClose]="closeCallbackClaim"
        [transferallounceorder]="transferallounceorder"
        [journeydetails]="journeydetails"
        [drawerClaimData]="drawerClaimData"
        [netAmountInWord]="netAmountInWord"
      ></app-order-form>
    </ng-container>
  </nz-drawer> -->

<nz-drawer
  [nzBodyStyle]="{
    height: 'calc(100% - 55px)',
    overflow: 'auto',
    'padding-bottom': '53px'
  }"
  [nzClosable]="true"
  [nzMask]="true"
  [nzMaskClosable]="false"
  [nzWidth]="1000"
  [nzKeyboard]="true"
  [nzVisible]="drawerClaimVisible"
  [nzTitle]="orderFormTitle"
  (nzOnClose)="drawerClose1()"
>
  <!-- <app-order-form
    [drawerClose1]="closeCallback1"
  ></app-order-form> -->
  <ng-container>
    <app-order-form
      [accommodation]="accommodation"
      [totacountofbill]="totacountofbill"
      [railorder]="railorder"
      [tranferchnagesorder1]="tranferchnagesorder1"
      [journeyfairfaird]="journeyfairfaird"
      [amount]="[amount]"
      [raiamount]="raiamount"
      [drawerClose]="closeCallbackClaim"
      [transferallounceorder]="transferallounceorder"
      [journeydetails]="journeydetails"
      [drawerClaimData]="drawerClaimData"
      [netAmountInWord]="netAmountInWord"
      [TravelModeNames]="TravelModeNames"
      [showPrivate]="showPrivate"
      [multiplication]="multiplication"
      [fairpaidofpartb]="fairpaidofpartb"
      [weightrate]="weightrate"
      [distance]="distance"
      [amountt]="amountt"
      [totalofweight]="totalofweight"
      [grosspaymentvalue]="grosspaymentvalue"
      [nettotacountofbill]="nettotacountofbill"
    ></app-order-form>
  </ng-container>
</nz-drawer>

<!-- <nz-drawer
    [nzBodyStyle]="{
      height: 'calc(100% - 55px)',
      overflow: 'auto',
      'padding-bottom': '53px'
    }"
    [nzClosable]="true"
    [nzMask]="true"
    [nzMaskClosable]="false"
    [nzWidth]="1100"
    [nzKeyboard]="true"
    [nzVisible]="partborder"
    [nzTitle]="partDrawerTitle"
    (nzOnClose)="partbdrawerclose()"
  >
    <ng-container>
      <app-add
        [drawerClose]="closeCallbackpartb"
        [partbdata]="partbdata"
        [journeyDetailsData]="journeyDetailsData"
        [multiplication]="multiplication"
        [incidentalsAmonut]="incidentalsAmonut"
      ></app-add>
    </ng-container>
  </nz-drawer> -->

<nz-drawer
  [nzBodyStyle]="{
    height: 'calc(100% - 55px)',
    overflow: 'auto',
    'padding-bottom': '53px'
  }"
  [nzClosable]="true"
  [nzMask]="true"
  [nzMaskClosable]="false"
  [nzWidth]="800"
  [nzKeyboard]="true"
  [nzVisible]="partborder"
  [nzTitle]="partDrawerTitle"
  (nzOnClose)="partbdrawerclose()"
>
  <ng-container>
    <app-add
      [drawerClose]="closeCallbackpartb"
      [partbdata]="partbdata"
      [journeyDetailsData]="journeyDetailsData"
      [multiplication]="multiplication"
      [tranferchnagesorder]="tranferchnagesorder"
      [totalofweight]="totalofweight"
      [incidentalsAmonut]="incidentalsAmonut"
      [transferallounceorder]="transferallounceorder"
      [netpaymentofpartb]="netpaymentofpartb"
      [amountofadvance]="amountofadvance"
      [amountt]="amountt"
      [weightrate]="weightrate"
      [distance]="distance"
      [grosspaymentofpartb]="grosspaymentofpartb"
      [fairpaidofpartb]="fairpaidofpartb"
      [grosspaymentvalue]="grosspaymentvalue"
      [privateVehicleMinFair]="privateVehicleMinFair"
      [showPrivate]="showPrivate"
      [TravelModeNames]="TravelModeNames"
    ></app-add>
  </ng-container>
</nz-drawer>

<nz-drawer
  [nzBodyStyle]="{
    height: 'calc(100% - 55px)',
    overflow: 'auto',
    'padding-bottom': '53px'
  }"
  [nzClosable]="true"
  [nzMask]="true"
  [nzMaskClosable]="false"
  [nzWidth]="1000"
  [nzKeyboard]="true"
  [nzVisible]="transferallounce"
  [nzTitle]="transferAllounceDrawerTitle"
  (nzOnClose)="transferallowuncedrawerclose()"
>
  <ng-container>
    <app-traveling-allowance
      [transferallounceorder]="transferallounceorder"
      [journeydetails]="journeydetails"
      [accommodation]="accommodation"
      [tranferchnagesorder]="tranferchnagesorder"
      [relationdataorder]="relationdataorder"
      [railorder]="railorder"
      [drawerClose]="closeCallbacktransferallowunce"
    ></app-traveling-allowance>
  </ng-container>
</nz-drawer>

<nz-drawer
  [nzBodyStyle]="{
    height: 'calc(100% - 55px)',
    overflow: 'auto',
    'padding-bottom': '53px'
  }"
  [nzClosable]="true"
  [nzMask]="true"
  [nzMaskClosable]="false"
  [nzWidth]="850"
  [nzKeyboard]="true"
  [nzVisible]="certificateorder"
  [nzTitle]="certificateDrawerTitle"
  (nzOnClose)="certificatedrawerclose()"
>
  <ng-container>
    <app-certificate
      [drawerClose]="closeCallbackcertificate"
      [transferallounceorder]="transferallounceorder"
    ></app-certificate>
  </ng-container>
</nz-drawer>

<nz-drawer
  [nzBodyStyle]="{
    height: 'calc(100% - 55px)',
    overflow: 'auto',
    'padding-bottom': '53px'
  }"
  [nzClosable]="true"
  [nzMask]="true"
  [nzMaskClosable]="false"
  [nzWidth]="1000"
  [nzKeyboard]="true"
  [nzVisible]="checklistVisible"
  [nzTitle]="checklistTitle"
  (nzOnClose)="checkListdrawerclose()"
>
  <ng-container>
    <!-- <app-transferchecklist></app-transferchecklist> -->
    <app-transferchecklist
      [drawerClose]="closeCallbackchecklist"
      [checklistData]="checklistData"
      [transferData]="transferData"
      [POST]="POST"
      [OFFICE_NAME]="OFFICE_NAME"
      [SIGNNAME]="SIGNNAME"
    ></app-transferchecklist>
  </ng-container>
</nz-drawer>
