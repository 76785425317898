import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { EmployeeMaster } from 'src/app/Models/Employee';
import { LTCMaster } from 'src/app/Models/LTCMaster';
import { LTCQuestionaryMaster } from 'src/app/Models/LTCQuestionaryMaster';
import { roadRail } from 'src/app/Models/RoadRail';
import { journeyP } from 'src/app/Models/journeyParticular';
import { Journeydetails } from 'src/app/Models/journeydetails';
import { Realtionshipdata } from 'src/app/Models/relationship';
import { Travelmode } from 'src/app/Models/travelmode';
import { ApiService } from 'src/app/Service/api.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-addltcmaster',
  templateUrl: './addltcmaster.component.html',
  styleUrls: ['./addltcmaster.component.css'],
})
export class AddltcmasterComponent implements OnInit {
  constructor(
    private message: NzNotificationService,
    private api: ApiService,
    private datepipe: DatePipe,
    public cookie: CookieService,
    private sanitizer: DomSanitizer
  ) {}
  userId: any;
  ngOnInit(): void {
    this.allEmployeeList();
    this.BlockYears();
    this.current = this.current;
    this.userId = Number(sessionStorage.getItem('userId'));
  }

  namepatt = /[a-zA-Z][a-zA-Z ]+/;
  emailpattern =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  @Input() isSpinning = false;
  @Input() relationdata: any = [];
  // @Input() Hospitalclaim: any = [];
  @Input() journey: any;
  isOk = true;
  HEADQUARTERS: any;

  @Input()
  drawerVisible: boolean = false;
  empDrawerVisible: boolean = false;
  @Input() drawerClose: Function;
  @Input() data: LTCMaster;

  @Input() empID: any;
  @Input() ltcID: any;
  // @Input() data2: ClaimMaster;
  //  data2: ClaimMaster;
  // @Input() QueData: QuestionaryMaster;
  // data2: ClaimMaster = new ClaimMaster();
  QueData: LTCQuestionaryMaster = new LTCQuestionaryMaster();
  // hospitalData: HospitalMappingMaster = new HospitalMappingMaster();
  // @Input() data4: CheckList;
  //  data4: CheckList;
  // data4: CheckList = new CheckList();

  @Input() current = 0;
  employee: EmployeeMaster[] = [];

  Cities: EmployeeMaster[];
  filteredOptions = [];
  Names = EmployeeMaster;
  hospitalList: any = [];
  diffDays: any;
  date1: any;
  date2: any;
  expoDiffDays: any;
  TREATEMENT_TYPE1 = false;
  TREATEMENT_TYPE2 = false;
  CGHS_AMA_REFERENCE_DATE: any = new Date();
  CGHS_AMA_REFERENCE_END_DATE: any = new Date();
  empLoader: boolean = false;
  orderdata1 = [];
  travelmode = new Travelmode();
  drawerTitleform1!: string;
  drawerVisible2form1: boolean = false;
  drawerData2form1: roadRail = new roadRail();
  blockYearList: any = [];
  BlockYears(): void {
    this.api
      .getBlockYearMaster(0, 0, '', 'asc', ' AND STATUS = 1')
      .subscribe((data) => {
        if (data['code'] == 200) {
          this.blockYearList = data['data'];
        }
      });
  }

  LTCID: any;
  addform1(): void {
    this.drawerTitleform1 = 'Add Places Details Which Are Connected By Rail';
    this.drawerData2form1 = new roadRail();
    // this.data.LTC_ID = this.ltcID;
    this.ltcID = this.ltcID;
    this.drawerVisible2form1 = true;
  }

  drawerClose2form1(): void {
    this.drawerVisible2form1 = false;
    this.getDataform1();
  }

  get closeCallbackform1form1() {
    return this.drawerClose2form1.bind(this);
  }

  switch = false;

  switch1 = false;
  switchChange1(e: boolean) {
    this.switch1 != e;
    this.data.IS_ADVANCE_TAKEN = null;
  }

  drawerTitleform3!: string;
  drawerVisibleform3: boolean = false;
  drawerDataform3: journeyP = new journeyP();

  addform3(): void {
    this.drawerTitleform3 = 'Add Details Of Accommodation Class';
    this.drawerDataform3 = new journeyP();
    this.ltcID = this.ltcID;
    this.drawerVisibleform3 = true;
  }

  drawerCloseform3(): void {
    this.getDataform3();
    this.drawerVisibleform3 = false;
  }

  get closeCallbackform3() {
    return this.drawerCloseform3.bind(this);
  }

  drawerTitleform7!: string;
  drawerVisibleform7: boolean = false;
  drawerDataform7: Journeydetails = new Journeydetails();
  gradePayLevel: any = '';
  addform7(): void {
    this.drawerTitleform7 = 'Add Details of Journeys(s)';
    this.drawerDataform7 = new Journeydetails();
    this.ltcID = this.ltcID;
    this.gradePayLevel = this.data.GRADE_PAY_LEVEL;
    this.drawerVisibleform7 = true;
  }

  drawerCloseform7(): void {
    this.api
      .ltcJourneyDetail(0, 0, '', 'asc', ' AND LTC_ID =' + this.ltcID)
      .subscribe((data) => {
        if (data['code'] == 200) {
          this.formdata7 = data['data'];
        }
      });
    this.drawerVisibleform7 = false;
  }

  get closeCallbackform7() {
    return this.drawerCloseform7.bind(this);
  }

  formdata3 = [];
  formdata2 = [];
  formdata1 = [];
  formdata7 = [];
  getDataform3() {
    this.api
      .getJourneyParticular(0, 0, '', 'asc', ' AND LTC_ID =' + this.ltcID)
      .subscribe((data) => {
        if (data['code'] == 200) {
          this.formdata3 = data['data'];
        }
      });
  }

  getDataform2() {
    this.api
      .ltcJourneyDetail(0, 0, '', 'asc', ' AND LTC_ID =' + this.ltcID)
      .subscribe((data) => {
        if (data['code'] == 200) {
          this.formdata7 = data['data'];
        }
      });
  }

  confirmDeleterelation(data: any, i: number) {
    this.relationdata = this.relationdata.filter((item, index) => index != i);

    this.relationdata = [...[], ...this.relationdata];
  }
  // SHOW_TABLE: boolean = false;
  // showtable(event: { SHOW_TABLE: boolean; }) {
  //   event.IS_HIGHER_CLASS_ACCOMODATION = !event.SHOW_TABLE;
  // }

  getDataform1() {
    this.api
      .ltcRoadConnectedRail(0, 0, '', 'asc', ' AND LTC_ID =' + this.ltcID)
      .subscribe((data) => {
        if (data['code'] == 200) {
          this.formdata1 = data['data'];
        }
      });
  }

  deleteConfirm(data: any) {
    // this.loadingRecords = true;

    (data.IS_DELETED = 1),
      this.api.ltcJourneyDetailUpdate(data).subscribe((successCode) => {
        if (successCode.code == '200') {
          this.message.success('Record Deleted Successfully...', '');
          this.getDataform2();
          // this.loadingRecords = false;
        } else {
          this.message.error('Information Has Not Deleted...', '');
          // this.loadingRecords = false;
        }
      });
  }

  deleteConfirmparticular(data: any) {
    // this.loadingRecords = true;

    (data.IS_DELETED = 1),
      this.api.updateJourneyParticular(data).subscribe((successCode) => {
        if (successCode.code == '200') {
          this.message.success('Record Deleted Successfully...', '');
          this.getDataform3();
          // this.loadingRecords = false;
        } else {
          this.message.error('Information Has Not Deleted...', '');
          // this.loadingRecords = false;
        }
      });
  }

  deleteConfirmlitcroad(data: any) {
    // this.loadingRecords = true;

    (data.IS_DELETED = 1),
      this.api
        .ltcRoadConnectedRailUpdate(data, this.ltcID)
        .subscribe((successCode) => {
          if (successCode.code == '200') {
            this.message.success('Record Deleted Successfully...', '');
            this.getDataform1();
            // this.loadingRecords = false;
          } else {
            this.message.error('Information Has Not Deleted...', '');
            // this.loadingRecords = false;
          }
        });
  }

  getDataform7() {
    this.api
      .getAllltcMaster(0, 0, '', 'asc', ' AND ID =' + this.ltcID)
      .subscribe((data) => {
        if (data['code'] == 200) {
          this.formdata7 = data['data'];
        }
      });
  }

  // getrelationtable() {
  //   this.api
  //     .getrelationtable(0, 0, '', 'asc', ' AND TRANSFER_ID =' + this.ltcID)
  //     .subscribe((data) => {
  //       if (data['code'] == 200) {
  //         this.relationdata = data['data'];
  //       }
  //     });
  // }
  getTimeIn12Hour(time: any) {
    return this.datepipe.transform(new Date(), 'yyyy-MM-dd' + ' ' + time);
  }
  checkboxdatais: boolean = false;
  chechboxdata(event: any) {
    this.checkboxdatais = event;
  }

  showtable1(event: any) {
    // this.data.IS_HIGHER_CLASS_ACCOMODATION = event;
  }
  class = [];
  editform3(data: journeyP): void {
    this.drawerTitleform3 = 'Edit Details Of Accommodation Class';
    this.drawerDataform3 = Object.assign({}, data);

    this.ltcID = this.ltcID;

    this.api
      .gettravelclass(
        0,
        0,
        '',
        '',
        ' AND STATUS=1 AND MODE_ID=' + data.MODE_OF_CONVEYANCE_ID
      )
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            this.class = data['data'];
            this.drawerVisibleform3 = true;
          } else {
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  classdata: any;
  editform7(data: Journeydetails): void {
    this.drawerTitleform7 = 'Edit Details of Journeys(s)';
    this.gradePayLevel = this.data.GRADE_PAY_LEVEL;

    this.drawerDataform7 = Object.assign({}, data);
    this.api
      .gettravelclass(
        0,
        0,
        '',
        '',
        ' AND STATUS=1 AND ID=' + data.TRAVEL_CLASS_ID
      )
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            this.classdata = data['data'];
          }
        },
        (err) => {
          console.log(err);
        }
      );
    // this.drawerDataform7.TRAVEL_CLASS_ID = data.TRAVEL_CLASS_ID;

    this.ltcID = this.ltcID;
    this.drawerVisibleform7 = true;
  }
  dataList: any = [];

  editform1(data: roadRail): void {
    this.drawerTitleform1 = 'Edit Places Details Which Are Connected By Rail';
    this.drawerData2form1 = Object.assign({}, data);
    this.data.LTC_ID = this.ltcID;

    //   this.api.itcRoadConnectedRail(0, 0, '', '', 'AND ID = ' + data.ID).subscribe(
    //     (data) => {
    //       if (data['code'] == 200) {
    //         this.drawerData2form1 = data['data'];
    //       }
    //     },
    //     (err) => {
    //       console.log(err);
    //     }
    //   );
    this.drawerVisible2form1 = true;
  }

  allEmployeeList() {
    this.empLoader = true;

    this.api.getEmployeeMaster(0, 0, '', '', ' AND STATUS = 1').subscribe(
      (data) => {
        if (data['code'] == 200) {
          this.employee = data['data'];
          this.filteredOptions = this.employee;
          this.empLoader = false;
        } else {
          this.message.error("Can't Load Employee Data", '');
          this.empLoader = false;
        }
      },
      (err) => {
        console.log(err);
      }
    );
    if (this.data.EMP_ID != null || this.data.EMP_ID != undefined) {
      this.api
        .getEmployeeMaster(0, 0, '', '', ' AND ID = ' + this.data.EMP_ID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              this.data.OFFICE_NAME = data['data'][0]['OFFICE_NAME'];
              this.data.DESIGNATION = data['data'][0]['DESIGNATION'];
              this.data.EMPLOYEE_CODE = data['data'][0]['EMPLOYEE_CODE'];
              this.data.LOCATION = data['data'][0]['LOCATION'];
              this.data.DDO_OF_THE_OFFICIAL =
                data['data'][0]['DDO_OF_THE_OFFICIAL'];
              this.data.GRADE_PAY = data['data'][0]['GRADE_PAY'];
              this.data.EMAIL_ID = data['data'][0]['EMAIL_ID'];
              this.data.MOBILE_NO = data['data'][0]['MOBILE_NO'];
              this.data.ADDRESS = data['data'][0]['ADDRESS'];
              this.data.EMPLOYEE_NAME = data['data'][0]['NAME'];
            } else {
              this.message.error("Can't Load Employee Data", '');
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
    }
  }

  allHospitalList() {
    this.api.getAllHospital(0, 0, '', '', ' AND STATUS = 1').subscribe(
      (data) => {
        if (data['code'] == 200) {
          this.hospitalList = data['data'];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  close(): void {
    this.current = 0;
    this.drawerClose();
  }

  applicantResetDrawer(ITCMasterPages: NgForm) {
    this.data = new LTCMaster();
    ITCMasterPages.form.reset();
  }

  // close(applicantMasterPages: NgForm,claimMasterPage: NgForm,queMasterPage: NgForm,checkListMasterPage: NgForm) {

  //  this.current = 0;
  //   this.applicantResetDrawer(applicantMasterPages);
  //   this.claimResetDrawer(claimMasterPage);
  //   this.queResetDrawer(queMasterPage);
  //   this.checkResetDrawer(checkListMasterPage);
  //   // claimMasterPage.form.reset();
  //   this.drawerClose();
  // }

  omit(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  dateOmit(event: any) {
    return false;
  }

  save(addNew: boolean, claimMasterPage: NgForm): void {
    if (this.current == 0) {
      this.current = 1;
    } else if (this.current == 1) {
      this.current = 2;
    } else if (this.current == 2) {
      this.current = 3;
    }
    this.isSpinning = false;
    this.isOk = true;

    if (
      this.data.NAME.trim() == '' &&
      this.data.LOCATION.trim() == '' &&
      // this.data.DDO_OF_THE_OFFICIAL.trim() == '' &&
      this.data.DESIGNATION.trim() == '' &&
      this.data.OFFICE_NAME.trim() == '' &&
      this.data.EMPLOYEE_CODE == 0 &&
      this.data.GRADE_PAY == 0
    ) {
      this.isOk = false;
      this.message.error('Please Fill All The Required Fields ', '');
    } else if (this.data.NAME == null || this.data.NAME.trim() == '') {
      this.isOk = false;
      this.message.error(' Please Enter Employee Name.', '');
    } else if (
      this.data.EMPLOYEE_CODE == undefined ||
      this.data.EMPLOYEE_CODE <= 0
    ) {
      this.isOk = false;
      this.message.error('Please Enter Employee Code  ', '');
    } else if (this.data.GRADE_PAY == undefined || this.data.GRADE_PAY <= 0) {
      this.isOk = false;
      this.message.error('Please Enter Grade Pay  ', '');
    } else if (
      this.data.OFFICE_NAME == null ||
      this.data.OFFICE_NAME.trim() == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Office Name.', '');
    } else if (
      this.data.DESIGNATION == null ||
      this.data.DESIGNATION.trim() == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Designation', '');
    } else if (this.data.LOCATION == null || this.data.LOCATION.trim() == '') {
      this.isOk = false;
      this.message.error(' Please Enter Location', '');
      // } else if (
      //   this.data.DDO_OF_THE_OFFICIAL == null ||
      //   this.data.DDO_OF_THE_OFFICIAL.trim() == ''
      // ) {
      //   this.isOk = false;
      //   this.message.error(' Please Enter Designation', '');
    }

    if (this.isOk) {
      this.isSpinning = true;
      {
        if (this.data.ID) {
          this.api.updateEmployeeMaster(this.data).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information Changed Successfully...', '');
              if (!addNew) this.drawerClose();
              this.isSpinning = false;
            } else {
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
        } else {
          this.api.createEmployeeMaster(this.data).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information Save Successfully...', '');
              if (!addNew) this.drawerClose();
              else {
                this.data = new LTCMaster();
                // this.resetDrawer(claimMasterPage);
                // this.data.IMG_URL= '';

                this.api.getEmployeeMaster(0, 0, '', 'desc', '').subscribe(
                  (data) => {
                    // if (data['count']==0){
                    //   this.data.SEQUENCE_NUMBER=1;
                    // }else
                    // {
                    //   this.data.SEQUENCE_NUMBER=data['data'][0]['SEQUENCE_NUMBER']+1;
                    // }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              }
              this.isSpinning = false;
            } else {
              this.message.error('Failed To Fill Information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
  }
  STATION_NAME: string = '';
  TOTAL_AMOUNT: any;
  ADVANCE_TAKEN_DATE: any;
  ADVANCED_AMOUNT: any;
  AMOUNT: any;
  AlldataSave(addNew: boolean) {
    this.isOk = true;
    this.isSpinning = false;
    // this.data.transportationData = this.formdata2;

    // this.data.transportationDataPersonal = this.formdata3;

    // this.data.journeyData = this.formdata1;

    // this.data.STATION_NAME = this.STATION_NAME;
    // this.data.TOTAL_AMOUNT = this.TOTAL_AMOUNT;
    // this.data.AMOUNT = this.AMOUNT;
    // this.data.ADVANCE_TAKEN_DATE = this.ADVANCE_TAKEN_DATE;
    // this.data.ADVANCED_AMOUNT = this.ADVANCED_AMOUNT;

    if (this.data.IS_ADVANCE_TAKEN == true || this.data.IS_ADVANCE_TAKEN == 1) {
      if (
        this.data.AMOUNT_OF_ADVANCE == undefined ||
        this.data.AMOUNT_OF_ADVANCE == 0
      ) {
        this.message.error('Please enter advance amount', '');
        this.isOk = false;
      }
    }

    this.data.ID = this.ltcID;
    if (this.isOk && this.data.ID != undefined) {
      // if (stage == 'V') {
      //   // this.data.CURRENT_STAGE_ID = 3;
      // }
      this.api.ltcMasterUpdate(this.data).subscribe((successCode) => {
        if (successCode.code == '200') {
          // this.drawerClose();
          this.ltcID = successCode.LTC_ID;
          this.next();
          this.message.success('Information Saved Successfully...', '');
        } else {
          this.message.error('Failed To Save Information....', '');
          this.isSpinning = false;
        }
      });
    }
  }
  /////////////Disable Date /////////////
  disabledEndDate = (current: Date): boolean =>
    differenceInCalendarDays(current, new Date(this.data.LEAVE_START_DATE)) < 0;

  leaveEndDate() {
    this.disabledEndDate = (current: Date): boolean =>
      differenceInCalendarDays(current, new Date(this.data.LEAVE_START_DATE)) <
      0;
  }
  disabledStartDate = (current: Date): boolean =>
    differenceInCalendarDays(current, new Date(this.data.LEAVE_END_DATE)) > 0;

  leaveStartDate() {
    this.disabledStartDate = (current: Date): boolean =>
      differenceInCalendarDays(current, new Date(this.data.LEAVE_END_DATE)) > 0;
  }
  /////////////Disable Date /////////////
  empAllDataForUpdate: any;

  empSave(): void {
    this.isSpinning = false;
    this.data.INSPECTOR_ID = Number(sessionStorage.getItem('userId'));
    this.data.familyData = this.relationdata;

    this.isOk = true;

    if (
      this.data.EMP_ID == undefined &&
      this.data.DESIGNATION == undefined &&
      // this.data.NEW_OFFICE_NAME == undefined &&
      // this.data.NEW_ADDRESS == undefined &&
      // this.data.OFFICE_NAME == undefined &&
      (this.data.NATURE_OF_LEAVE == undefined ||
        this.data.NATURE_OF_LEAVE == 0) &&
      (this.data.LEAVE_START_DATE == undefined ||
        this.data.LEAVE_START_DATE == null) &&
      (this.data.LEAVE_END_DATE == undefined ||
        this.data.LEAVE_END_DATE == null) &&
      this.data.BLOCK_YEAR_ID == undefined
    ) {
      this.isOk = false;
      this.message.error('Please Fill All The Required Fields ', '');
    } else if (
      this.data.EMP_ID == undefined ||
      this.data.EMP_ID == null ||
      this.data.EMP_ID == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Select Employee Name', '');
    } else if (
      this.data.DESIGNATION == undefined ||
      this.data.DESIGNATION == null ||
      this.data.DESIGNATION == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Designation', '');
      // } else if (
      //   this.data.OFFICE_NAME == undefined ||
      //   this.data.OFFICE_NAME == null ||
      //   this.data.OFFICE_NAME == ''
      // ) {
      //   this.isOk = false;
      //   this.message.error(' Please Enter Office Name', '');
    } else if (
      this.data.NATURE_OF_LEAVE == undefined ||
      this.data.NATURE_OF_LEAVE == null ||
      this.data.NATURE_OF_LEAVE == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Select Nature Of Leave', '');
    } else if (
      this.data.LEAVE_START_DATE == undefined ||
      this.data.LEAVE_START_DATE == null ||
      this.data.LEAVE_START_DATE == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Select Leave Start Date', '');
    } else if (
      this.data.LEAVE_END_DATE == undefined ||
      this.data.LEAVE_END_DATE == null ||
      this.data.LEAVE_END_DATE == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Select Leave End Date', '');
    } else if (
      this.data.BLOCK_YEAR_ID == undefined ||
      this.data.BLOCK_YEAR_ID == null ||
      this.data.BLOCK_YEAR_ID == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Select Block Year', '');
    } else if (
      this.data.SALUTATION == undefined ||
      this.data.SALUTATION == null ||
      this.data.SALUTATION == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Select Salutation', '');
    }

    if (this.isOk) {
      this.data.familyData = this.relationdata;
      // this.data.memberTransferData = JSON.stringify(this.relationdata);

      this.isSpinning = true;
      if (
        this.data.OFFICE_NAME == undefined ||
        this.data.OFFICE_NAME == null ||
        this.data.OFFICE_NAME == '' ||
        this.data.OFFICE_NAME.trim() == ''
      ) {
        this.data.OFFICE_NAME = null;
      } else {
        this.data.OFFICE_NAME = this.data.OFFICE_NAME;
      }
      if (
        this.data.EMAIL_ID == undefined ||
        this.data.EMAIL_ID == null ||
        this.data.EMAIL_ID == '' ||
        this.data.EMAIL_ID.trim() == ''
      ) {
        this.data.EMAIL_ID = null;
      } else {
        this.data.EMAIL_ID = this.data.EMAIL_ID;
      }
      if (
        this.data.EMPLOYEE_CODE == undefined ||
        this.data.EMPLOYEE_CODE == null ||
        this.data.EMPLOYEE_CODE == '' ||
        this.data.EMPLOYEE_CODE.trim() == ''
      ) {
        this.data.EMPLOYEE_CODE = ' ';
      } else {
        this.data.EMPLOYEE_CODE = this.data.EMPLOYEE_CODE;
      }

      if (
        this.data.MOBILE_NO == undefined ||
        this.data.MOBILE_NO == null ||
        this.data.MOBILE_NO == '' ||
        this.data.MOBILE_NO.trim() == ''
      ) {
        this.data.MOBILE_NO = ' ';
      } else {
        this.data.MOBILE_NO = this.data.MOBILE_NO;
      }

      if (
        this.data.ADDRESS == undefined ||
        this.data.ADDRESS == null ||
        this.data.ADDRESS == '' ||
        this.data.ADDRESS.trim() == ''
      ) {
        this.data.ADDRESS = ' ';
      } else {
        this.data.ADDRESS = this.data.ADDRESS;
      }

      if (
        this.data.LEAVE_START_DATE != undefined &&
        this.data.LEAVE_START_DATE != null &&
        this.data.LEAVE_START_DATE != ''
      ) {
        this.data.LEAVE_START_DATE = this.datepipe.transform(
          this.data.LEAVE_START_DATE,
          'yyyy-MM-dd'
        );
      }

      if (
        this.data.LEAVE_END_DATE != undefined &&
        this.data.LEAVE_END_DATE != null &&
        this.data.LEAVE_END_DATE != ''
      ) {
        this.data.LEAVE_END_DATE = this.datepipe.transform(
          this.data.LEAVE_END_DATE,
          'yyyy-MM-dd'
        );
      }

      if (this.data.ID) {
        if (
          this.data.familyData == undefined ||
          this.data.familyData.length == 0
        ) {
        } else {
          for (var i = 0; this.relationdata.length > i; i++) {
            this.relationdata[i]['ID'] = undefined;
          }
          this.data.familyData = this.relationdata;
        }
        this.data['LTC_ID'] = this.ltcID;
        this.api.ltcMasterLTCCreate(this.data).subscribe((successCode) => {
          if (successCode.code == '200') {
            this.message.success('Information Saved Successfully...', '');
            // this.empID = successCode.EMP_ID;
            this.ltcID = successCode.LTC_ID;
            this.next();
            this.isSpinning = false;
          } else if (successCode.code == '300') {
            this.message.error(
              'Email ID or Mobile Number Already Registered...',
              ''
            );
            this.isSpinning = false;
          } else {
            this.message.error('Information Has Not Saved...', '');
            this.isSpinning = false;
          }
        });
      } else {
        this.api.ltcMasterLTCCreate(this.data).subscribe((successCode) => {
          if (successCode.code == '200') {
            this.message.success('Information Save Successfully...', '');
            // this.empID = successCode.EMPLOYEE;
            this.ltcID = successCode.LTC_ID;
            this.next();
            // if (!addNew)
            // this.drawerClose();
            // else {
            //   this.data = new ApplicantMaster();
            //   this.resetDrawer(claimMasterPage);
            //   // this.data.IMG_URL= '';

            //   this.api.getEmployeeMaster(0,0,'','desc','').subscribe (data =>{
            //     // if (data['count']==0){
            //     //   this.data.SEQUENCE_NUMBER=1;
            //     // }else
            //     // {
            //     //   this.data.SEQUENCE_NUMBER=data['data'][0]['SEQUENCE_NUMBER']+1;
            //     // }
            //   },err=>{
            //     console.log(err);
            //   })
            // }
            this.isSpinning = false;
          } else if (successCode.code == '300') {
            this.message.error(
              'Email ID or Mobile Number Already Registered...',
              ''
            );
            this.isSpinning = false;
          } else {
            this.message.error('Failed To Fill Information...', '');
            this.isSpinning = false;
          }
        });
      }
    }
  }
  // empSave(): void {
  //   this.isSpinning = false;
  //   this.data.INSPECTOR_ID = Number(sessionStorage.getItem('userId'));
  //   this.data.familyData = this.relationdata;

  //   this.isOk = true;

  //   if (
  //     this.data.EMP_ID == undefined &&
  //     this.data.DESIGNATION == undefined &&
  //     // this.data.NEW_OFFICE_NAME == undefined &&
  //     // this.data.NEW_ADDRESS == undefined &&
  //     // this.data.OFFICE_NAME == undefined &&
  //     this.data.DDO_OF_THE_OFFICIAL == undefined &&
  //     this.data.EMPLOYEE_CODE == undefined &&
  //     (this.data.GRADE_PAY == undefined || this.data.GRADE_PAY == 0) &&
  //     (this.data.MOBILE_NO == undefined || this.data.MOBILE_NO == 0) &&
  //     (this.data.NATURE_OF_LEAVE == undefined ||
  //       this.data.NATURE_OF_LEAVE == 0) &&
  //     (this.data.LEAVE_START_DATE == undefined ||
  //       this.data.LEAVE_START_DATE == null) &&
  //     (this.data.LEAVE_END_DATE == undefined ||
  //       this.data.LEAVE_END_DATE == null) &&
  //     this.data.GRADE_PAY_LEVEL == undefined &&
  //     this.data.BLOCK_YEAR_ID == undefined
  //   ) {
  //     this.isOk = false;
  //     this.message.error('Please Fill All The Required Fields ', '');
  //   } else if (
  //     this.data.EMP_ID == undefined ||
  //     this.data.EMP_ID == null ||
  //     this.data.EMP_ID == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Select Employee Name', '');
  //   } else if (
  //     this.data.DESIGNATION == undefined ||
  //     this.data.DESIGNATION == null ||
  //     this.data.DESIGNATION == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Enter Designation', '');
  //     // } else if (
  //     //   this.data.OFFICE_NAME == undefined ||
  //     //   this.data.OFFICE_NAME == null ||
  //     //   this.data.OFFICE_NAME == ''
  //     // ) {
  //     //   this.isOk = false;
  //     //   this.message.error(' Please Enter Office Name', '');
  //   } else if (
  //     this.data.DDO_OF_THE_OFFICIAL == undefined ||
  //     this.data.DDO_OF_THE_OFFICIAL == null ||
  //     this.data.DDO_OF_THE_OFFICIAL == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Enter DDO Of The Official', '');
  //   } else if (
  //     this.data.EMPLOYEE_CODE == undefined ||
  //     this.data.EMPLOYEE_CODE == null ||
  //     this.data.EMPLOYEE_CODE == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Enter Employee Code', '');
  //   } else if (
  //     this.data.GRADE_PAY == undefined ||
  //     this.data.GRADE_PAY == null ||
  //     this.data.GRADE_PAY == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Select Basic Pay', '');
  //   } else if (
  //     this.data.MOBILE_NO == undefined ||
  //     this.data.MOBILE_NO == null ||
  //     this.data.MOBILE_NO == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Enter Mobile Number', '');
  //   } else if (
  //     this.data.GRADE_PAY_LEVEL == undefined ||
  //     this.data.GRADE_PAY_LEVEL == null ||
  //     this.data.GRADE_PAY_LEVEL == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Enter Grade Pay Level', '');
  //   } else if (
  //     this.data.NATURE_OF_LEAVE == undefined ||
  //     this.data.NATURE_OF_LEAVE == null ||
  //     this.data.NATURE_OF_LEAVE == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Select Nature Of Leave', '');
  //   } else if (
  //     this.data.LEAVE_START_DATE == undefined ||
  //     this.data.LEAVE_START_DATE == null ||
  //     this.data.LEAVE_START_DATE == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Select Leave Start Date', '');
  //   } else if (
  //     this.data.LEAVE_END_DATE == undefined ||
  //     this.data.LEAVE_END_DATE == null ||
  //     this.data.LEAVE_END_DATE == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Select Leave End Date', '');
  //   } else if (
  //     this.data.BLOCK_YEAR_ID == undefined ||
  //     this.data.BLOCK_YEAR_ID == null ||
  //     this.data.BLOCK_YEAR_ID == ''
  //   ) {
  //     this.isOk = false;
  //     this.message.error(' Please Select Block Year', '');
  //   }

  //   if (this.isOk) {
  //     this.data.familyData = this.relationdata;
  //     // this.data.memberTransferData = JSON.stringify(this.relationdata);

  //     this.isSpinning = true;
  //     if (
  //       this.data.OFFICE_NAME == undefined ||
  //       this.data.OFFICE_NAME == null ||
  //       this.data.OFFICE_NAME == '' ||
  //       this.data.OFFICE_NAME.trim() == ''
  //     ) {
  //       this.data.OFFICE_NAME = null;
  //     } else {
  //       this.data.OFFICE_NAME = this.data.OFFICE_NAME;
  //     }
  //     if (
  //       this.data.EMAIL_ID == undefined ||
  //       this.data.EMAIL_ID == null ||
  //       this.data.EMAIL_ID == '' ||
  //       this.data.EMAIL_ID.trim() == ''
  //     ) {
  //       this.data.EMAIL_ID = null;
  //     } else {
  //       this.data.EMAIL_ID = this.data.EMAIL_ID;
  //     }
  //     if (
  //       this.data.EMPLOYEE_CODE == undefined ||
  //       this.data.EMPLOYEE_CODE == null ||
  //       this.data.EMPLOYEE_CODE == '' ||
  //       this.data.EMPLOYEE_CODE.trim() == ''
  //     ) {
  //       this.data.EMPLOYEE_CODE = ' ';
  //     } else {
  //       this.data.EMPLOYEE_CODE = this.data.EMPLOYEE_CODE;
  //     }

  //     if (
  //       this.data.MOBILE_NO == undefined ||
  //       this.data.MOBILE_NO == null ||
  //       this.data.MOBILE_NO == '' ||
  //       this.data.MOBILE_NO.trim() == ''
  //     ) {
  //       this.data.MOBILE_NO = ' ';
  //     } else {
  //       this.data.MOBILE_NO = this.data.MOBILE_NO;
  //     }

  //     if (
  //       this.data.ADDRESS == undefined ||
  //       this.data.ADDRESS == null ||
  //       this.data.ADDRESS == '' ||
  //       this.data.ADDRESS.trim() == ''
  //     ) {
  //       this.data.ADDRESS = ' ';
  //     } else {
  //       this.data.ADDRESS = this.data.ADDRESS;
  //     }

  //     if (
  //       this.data.LEAVE_START_DATE != undefined &&
  //       this.data.LEAVE_START_DATE != null &&
  //       this.data.LEAVE_START_DATE != ''
  //     ) {
  //       this.data.LEAVE_START_DATE = this.datepipe.transform(
  //         this.data.LEAVE_START_DATE,
  //         'yyyy-MM-dd'
  //       );
  //     }

  //     if (
  //       this.data.LEAVE_END_DATE != undefined &&
  //       this.data.LEAVE_END_DATE != null &&
  //       this.data.LEAVE_END_DATE != ''
  //     ) {
  //       this.data.LEAVE_END_DATE = this.datepipe.transform(
  //         this.data.LEAVE_END_DATE,
  //         'yyyy-MM-dd'
  //       );
  //     }

  //     if (this.data.ID) {
  //       if (
  //         this.data.familyData == undefined ||
  //         this.data.familyData.length == 0
  //       ) {
  //       } else {
  //         for (var i = 0; this.relationdata.length > i; i++) {
  //           this.relationdata[i]['ID'] = undefined;
  //         }
  //         this.data.familyData = this.relationdata;
  //       }
  //       this.data['LTC_ID'] = this.ltcID;
  //       this.api.ltcMasterLTCCreate(this.data).subscribe((successCode) => {
  //         if (successCode.code == '200') {
  //           this.message.success('Information Saved Successfully...', '');
  //           // this.empID = successCode.EMP_ID;
  //           this.ltcID = successCode.LTC_ID;
  //           this.next();
  //           this.isSpinning = false;
  //         } else if (successCode.code == '300') {
  //           this.message.error(
  //             'Email ID or Mobile Number Already Registered...',
  //             ''
  //           );
  //           this.isSpinning = false;
  //         } else {
  //           this.message.error('Information Has Not Saved...', '');
  //           this.isSpinning = false;
  //         }
  //       });
  //     } else {
  //       this.api.ltcMasterLTCCreate(this.data).subscribe((successCode) => {
  //         if (successCode.code == '200') {
  //           this.message.success('Information Save Successfully...', '');
  //           // this.empID = successCode.EMPLOYEE;
  //           this.ltcID = successCode.LTC_ID;
  //           this.next();
  //           // if (!addNew)
  //           // this.drawerClose();
  //           // else {
  //           //   this.data = new ApplicantMaster();
  //           //   this.resetDrawer(claimMasterPage);
  //           //   // this.data.IMG_URL= '';

  //           //   this.api.getEmployeeMaster(0,0,'','desc','').subscribe (data =>{
  //           //     // if (data['count']==0){
  //           //     //   this.data.SEQUENCE_NUMBER=1;
  //           //     // }else
  //           //     // {
  //           //     //   this.data.SEQUENCE_NUMBER=data['data'][0]['SEQUENCE_NUMBER']+1;
  //           //     // }
  //           //   },err=>{
  //           //     console.log(err);
  //           //   })
  //           // }
  //           this.isSpinning = false;
  //         } else if (successCode.code == '300') {
  //           this.message.error(
  //             'Email ID or Mobile Number Already Registered...',
  //             ''
  //           );
  //           this.isSpinning = false;
  //         } else {
  //           this.message.error('Failed To Fill Information...', '');
  //           this.isSpinning = false;
  //         }
  //       });
  //     }
  //   }
  // }

  queSave(addNew: boolean): void {
    this.isSpinning = false;
    this.isOk = true;
    this.QueData.LTC_ID = this.ltcID;
    if (
      this.QueData.IS_PRINTOUT_SUBMITTED_FOR_CONCERNED_TIME_SLOT == undefined ||
      this.QueData.IS_PRINTOUT_SUBMITTED_FOR_CONCERNED_TIME_SLOT == null ||
      this.QueData.IS_PRINTOUT_SUBMITTED_FOR_CONCERNED_TIME_SLOT == ''
    ) {
      this.isOk = false;
      this.isSpinning = false;
      this.message.error(' Please Select Checklist No 4(a)', '');
    } else if (
      this.QueData.IS_COPY_OF_LEAVE_LEAVE_SANCTIONED_ORDER_GIVEN == undefined ||
      this.QueData.IS_COPY_OF_LEAVE_LEAVE_SANCTIONED_ORDER_GIVEN == null ||
      this.QueData.IS_COPY_OF_LEAVE_LEAVE_SANCTIONED_ORDER_GIVEN == ''
    ) {
      this.isOk = false;
      this.isSpinning = false;
      this.message.error(' Please Select Checklist No 7', '');
    } else if (
      this.QueData.IS_DEPENDENCY_CERTIFICATE_GIVEN == undefined ||
      this.QueData.IS_DEPENDENCY_CERTIFICATE_GIVEN == null ||
      this.QueData.IS_DEPENDENCY_CERTIFICATE_GIVEN == ''
    ) {
      this.isOk = false;
      this.isSpinning = false;
      this.message.error(' Please Select Checklist No 9', '');
    }

    if (this.isOk) {
      this.isSpinning = true;

      this.api.ltcMasterUpdate(this.data).subscribe((successCode) => {
        if (successCode.code == '200') {
          if (this.QueData.ID) {
            this.api
              .updateLTCquestions(this.QueData)
              .subscribe((successCode) => {
                if (successCode.code == '200') {
                  this.message.success(
                    'Information Changed Successfully...',
                    ''
                  );
                  this.drawerClose();
                  this.current = 0;
                  this.isSpinning = false;
                } else {
                  this.message.error('Information Has Not Changed...', '');
                  this.isSpinning = false;
                }
              });
          } else {
            this.api
              .createLTCquestions(this.QueData)
              .subscribe((successCode) => {
                if (successCode.code == '200') {
                  this.message.success('Information Save Successfully...', '');
                  this.drawerClose();
                  this.current = 0;
                  this.isSpinning = false;
                } else {
                  this.message.error('Failed To Fill Information...', '');
                  this.isSpinning = false;
                }
              });
          }
          // this.message.success('Information Saved Successfully...', '');
        } else {
          this.message.error('Failed To Save Information....', '');
          this.isSpinning = false;
        }
      });
    }
  }

  // queSave(addNew: boolean): void {
  //     this.isSpinning = false;
  //     this.isOk = true;
  //     this.QueData.LTC_ID = this.ltcID;

  //     if (
  //       this.data.ACTION_STATUS == 'R' &&
  //       (this.data.ACTION_REMARK == undefined ||
  //         this.data.ACTION_REMARK == null ||
  //         this.data.ACTION_REMARK == '')
  //     ) {
  //       this.isOk = false;
  //       this.message.error(' Please Enter Reject Remark.', '');
  //     } else if (
  //       this.data.ACTION_STATUS == 'H' &&
  //       (this.data.ACTION_DATE == undefined || this.data.ACTION_DATE == null)
  //     ) {
  //       this.isOk = false;
  //       this.message.error(' Please Select On Hold Date.', '');
  //     } else if (
  //       this.data.ACTION_STATUS == 'H' &&
  //       (this.data.ACTION_REMARK == undefined ||
  //         this.data.ACTION_REMARK == null ||
  //         this.data.ACTION_REMARK == '')
  //     ) {
  //       this.isOk = false;
  //       this.message.error(' Please Enter On Hold Remark.', '');
  //     }

  //     if(this.data.ACTION_DATE !=null && this.data.ACTION_DATE !='' && this.data.ACTION_DATE !=undefined  ){
  //       this.data.ACTION_DATE = this.datepipe.transform(
  //         this.data.ACTION_DATE,
  //         'yyyy-MM-dd'
  //       );
  //     }

  //     if (this.isOk) {

  //       this.isSpinning = true;

  //       {
  //         if (this.QueData.ID) {
  //           this.api.updateLTCquestions(this.QueData).subscribe((successCode) => {
  //             if (successCode.code == '200') {

  //               this.api.ltcMasterUpdate(this.data).subscribe((successCode) => {
  //                 if (successCode.code == '200') {
  //                   // this.drawerClose();
  //                   this.drawerClose();
  //                   this.current = 0;
  //                   this.isSpinning = false;
  //                   this.message.success('Information Changed Successfully...', '');
  //                 } else {
  //                   this.isSpinning = false;
  //                   this.message.error('Failed To Save Information....', '');
  //                 }
  //               });

  //             } else {
  //               this.message.error('Information Has Not Changed...', '');
  //               this.isSpinning = false;
  //             }
  //           });
  //         } else {
  //           this.api.createLTCquestions(this.QueData).subscribe((successCode) => {
  //             if (successCode.code == '200') {
  //               this.api.ltcMasterUpdate(this.data).subscribe((successCode) => {
  //                 if (successCode.code == '200') {
  //                   // this.drawerClose();
  //                   this.drawerClose();
  //                   this.current = 0;
  //                   this.isSpinning = false;
  //                   this.message.success('Information Changed Successfully...', '');
  //                 } else {
  //                   this.isSpinning = false;
  //                   this.message.error('Failed To Save Information....', '');
  //                 }
  //               });

  //             } else {
  //               this.message.error('Failed To Fill Information...', '');
  //               this.isSpinning = false;
  //             }
  //           });
  //         }
  //       }
  //     }
  //   }
  pre2(): void {
    if (this.current == 2 && this.data.ID > 0) {
      this.current -= 2;
    } else {
      this.current -= 1;
    }
  }

  pre(): void {
    if (this.current == 1) {
      this.isSpinning = true;
      this.allEmployeeList();
      this.BlockYears();
      this.api
        .getAllltcMaster(0, 0, '', '', ' AND ID =' + this.ltcID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              if (data['data'].length == 0) {
                // this.current -= 1;
                // this.isSpinning = false;
              } else {
                this.data = data['data'][0];

                // this.current -= 1;
                // this.isSpinning = false;
              }
              this.api
                .getltc_family_master(
                  0,
                  0,
                  '',
                  '',
                  ' AND LTC_ID = ' + this.ltcID
                )
                .subscribe(
                  (data) => {
                    if (data['code'] == 200) {
                      if (data['code'] == 200 && data['data'].length > 0) {
                        this.relationdata = data['data'];
                      } else {
                        this.relationdata = [];
                      }
                      this.current -= 1;
                      this.isSpinning = false;
                    } else {
                      this.isSpinning = false;
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
            } else {
              this.message.error('Something Went Wrong', '');
              this.isSpinning = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else if (this.current == 3) {
      this.isSpinning = true;
      this.api
        .getAllltcMaster(0, 0, '', '', ' AND ID =' + this.ltcID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              if (data['data'].length == 0) {
                this.data = new LTCMaster();
                this.isSpinning = false;
                this.current = 2;
              } else {
                this.data = data['data'][0];
                this.isSpinning = false;
                this.current = 2;
              }
            } else {
              this.message.error('Something Went Wrong', '');
              this.isSpinning = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
      this.isSpinning = true;
    } else if (this.current == 2) {
      this.isSpinning = true;
      this.api
        .getAllltcMaster(0, 0, '', '', ' AND ID =' + this.ltcID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              if (data['data'].length == 0) {
                this.data = new LTCMaster();
                this.current = 1;
                this.isSpinning = false;
              } else {
                this.data = data['data'][0];
              }
              this.getDataform1();
              this.getDataform7();
              // this.getDataform2();
              this.getDataform3();
              this.api
                .ltcJourneyDetail(0, 0, '', 'asc', ' AND LTC_ID =' + this.ltcID)
                .subscribe((data) => {
                  if (data['code'] == 200) {
                    this.isSpinning = false;

                    this.formdata7 = data['data'];
                    this.current = 1;
                  }
                });
            } else {
              this.message.error('Something Went Wrong', '');
              this.isSpinning = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );

      this.isSpinning = false;
    } else {
      this.isSpinning = true;
      this.current -= 1;
      this.isSpinning = false;
    }
  }

  filterEmpData(event: any) {
    this.empLoader = true;
    if (event != null) {
      this.api.getEmployeeMaster(0, 0, '', '', ' AND ID =' + event).subscribe(
        (data) => {
          if (data['code'] == 200) {
            this.empLoader = false;
            this.data.EMP_ID = data['data'][0]['ID'];
            this.data.OFFICE_NAME = data['data'][0]['OFFICE_NAME'];
            this.data.DESIGNATION = data['data'][0]['DESIGNATION'];
            this.data.EMPLOYEE_CODE = data['data'][0]['EMPLOYEE_CODE'];
            this.data.LOCATION = data['data'][0]['LOCATION'];
            this.data.DDO_OF_THE_OFFICIAL =
              data['data'][0]['DDO_OF_THE_OFFICIAL'];
            this.data.GRADE_PAY = data['data'][0]['GRADE_PAY'];
            this.data.EMAIL_ID = data['data'][0]['EMAIL_ID'];
            this.data.MOBILE_NO = data['data'][0]['MOBILE_NO'];
            this.data.ADDRESS = data['data'][0]['ADDRESS'];
            this.data.EMPLOYEE_NAME = data['data'][0]['NAME'];
            // this.data.CGHS_CARD_NO = '';
            // this.data.CGHS_CARD_VALIDITY = '';
            // this.data.BENEFICIARY_TYPE = '';
          }
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      this.empLoader = false;
      this.data.ID = null;
      this.data.OFFICE_NAME = '';
      this.data.DESIGNATION = '';
      this.data.EMPLOYEE_CODE = '';
      this.data.LOCATION = '';
      this.data.DDO_OF_THE_OFFICIAL = '';
      this.data.GRADE_PAY = '';
      this.data.EMAIL_ID = '';
      this.data.MOBILE_NO = '';
      this.data.ADDRESS = '';
      // this.data.CGHS_CARD_NO = '';
      // this.data.CGHS_CARD_VALIDITY = '';
      // this.data.BENEFICIARY_TYPE = '';
    }
  }

  // name
  // onChange(value: string): void {
  //   this.name == value

  //   this.filteredOptions = this.employee.filter(option => option.NAME.toLowerCase().indexOf(value.toLowerCase()) !== -1);

  //   // var a = this.employee.filter(obj => {
  //   //   return obj.NAME == value;
  //   // })

  // }

  name = '';
  onChange(value: string): void {
    this.name = value;
    this.filteredOptions = [];
    this.filteredOptions = this.employee.filter(
      (option) => option.NAME.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
    if (value != '') {
      if (this.filteredOptions.length > 0) {
        // this.name = this.filteredOptions[0]['NAME'];
        this.data.ID = this.filteredOptions[0]['ID'];

        this.data.OFFICE_NAME = this.filteredOptions[0]['OFFICE_NAME'];
        this.data.DESIGNATION = this.filteredOptions[0]['DESIGNATION'];
        this.data.EMPLOYEE_CODE = this.filteredOptions[0]['EMPLOYEE_CODE'];
        this.data.LOCATION = this.filteredOptions[0]['LOCATION'];
        this.data.DDO_OF_THE_OFFICIAL =
          this.filteredOptions[0]['DDO_OF_THE_OFFICIAL'];
        this.data.GRADE_PAY = this.filteredOptions[0]['GRADE_PAY'];
        this.data.EMAIL_ID = this.filteredOptions[0]['EMAIL_ID'];
        this.data.MOBILE_NO = this.filteredOptions[0]['MOBILE_NO'];
        this.data.ADDRESS = this.filteredOptions[0]['ADDRESS'];
      } else {
        this.data.ID = undefined;
        this.name = value;
        this.data.OFFICE_NAME = '';
        this.data.DESIGNATION = '';
        this.data.EMPLOYEE_CODE = '';
        this.data.LOCATION = '';
        this.data.DDO_OF_THE_OFFICIAL = '';
        this.data.GRADE_PAY = '';
        this.data.EMAIL_ID = '';
        this.data.MOBILE_NO = '';
        this.data.ADDRESS = '';
      }
    } else {
      this.name = value;
      this.data.OFFICE_NAME = '';
      this.data.DESIGNATION = '';
      this.data.EMPLOYEE_CODE = '';
      this.data.LOCATION = '';
      this.data.DDO_OF_THE_OFFICIAL = '';
      this.data.GRADE_PAY = '';
      this.data.EMAIL_ID = '';
      this.data.MOBILE_NO = '';
      this.data.ADDRESS = '';
    }
    this.data.NAME = this.name;
  }
  emergencyTaken;
  drCertificate;
  empanelHospital;
  expoFacto;
  referanceTaken;
  hodPermission;

  fileList: any = [];
  fileNumberList() {
    this.api
      .getFileMaster(
        0,
        0,
        'ID',
        'ASC',
        ' AND STATUS = 1 AND HIRARCHY_ID in (3,4)',
        sessionStorage.getItem('userId')
      )
      .subscribe(
        (data: any) => {
          if (data['code'] == 200 && data['count'] > 0) {
            this.fileList = data['data'];
          } else {
            this.fileList = [];
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  next() {
    if (this.current == 0) {
      this.isSpinning = true;
      this.api
        .getAllltcMaster(0, 0, '', '', ' AND ID =' + this.ltcID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              if (data['data'].length == 0) {
                this.data = new LTCMaster();
                this.isSpinning = false;
              } else {
                this.data = data['data'][0];
              }
              this.getDataform1();
              this.getDataform7();
              // this.getDataform2();
              this.getDataform3();
              this.api
                .ltcJourneyDetail(0, 0, '', 'asc', ' AND LTC_ID =' + this.ltcID)
                .subscribe((data) => {
                  if (data['code'] == 200) {
                    this.isSpinning = false;

                    this.formdata7 = data['data'];
                    this.current = 1;
                  }
                });
            } else {
              this.message.error('Something Went Wrong', '');
              this.isSpinning = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else if (this.current == 1) {
      this.api
        .getAllltcMaster(0, 0, '', '', ' AND ID =' + this.ltcID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              if (data['data'].length == 0) {
                this.data = new LTCMaster();
                this.current = 2;
                this.isSpinning = false;
              } else {
                this.data = data['data'][0];
                this.current = 2;
              }
            } else {
              this.message.error('Something Went Wrong', '');
              this.isSpinning = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else if (this.current == 2) {
      this.fileNumberList();
      this.api
        .getAllltcMaster(0, 0, '', '', ' AND ID =' + this.ltcID)
        .subscribe(
          (data1) => {
            if (data1['code'] == 200) {
              this.data = data1['data'][0];
              this.api
                .getLTCquestions(0, 0, '', '', ' AND LTC_ID =' + this.ltcID)
                .subscribe(
                  (data) => {
                    if (data['code'] == 200) {
                      if (data['data'].length == 0) {
                        this.QueData = new LTCQuestionaryMaster();
                        if (this.data.IS_ADVANCE_TAKEN == 1) {
                          this.QueData.IS_ADVANCE_FOR_LTC_GRANTED = true;
                        } else {
                          this.QueData.IS_ADVANCE_FOR_LTC_GRANTED =
                            this.QueData.IS_ADVANCE_FOR_LTC_GRANTED;
                        }
                        if (
                          this.data.BLOCK_YEAR != undefined &&
                          this.data.BLOCK_YEAR != null &&
                          this.data.BLOCK_YEAR != ''
                        ) {
                          this.QueData.WHETHER_BLOCK_YEAR_MENTIONED = true;
                        } else {
                          this.QueData.WHETHER_BLOCK_YEAR_MENTIONED =
                            this.QueData.WHETHER_BLOCK_YEAR_MENTIONED;
                        }
                        if (
                          this.data.LTC_MANDATE_FORM != undefined &&
                          this.data.LTC_MANDATE_FORM != null &&
                          this.data.LTC_MANDATE_FORM != ''
                        ) {
                          this.QueData.IS_BANK_MANDATE_FORM_SUBMITTED = true;
                        }

                        this.current = 3;
                        this.isSpinning = false;
                      } else {
                        this.QueData = data['data'][0];
                        this.current = 3;
                        this.isSpinning = false;
                      }
                    } else {
                      this.message.error('Something Went Wrong', '');
                      this.isSpinning = false;
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
            } else {
              this.message.error('Something Went Wrong', '');
              this.isSpinning = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }

  empanneledHospitalMapList = [];
  nonEmpanneled: boolean = false;

  // TREATEMENT_TYPE1 =false;
  // TREATEMENT_TYPE2 =false;

  list: any = [];
  ADD(CGHS_AMA_REFERENCE_DATE: any) {
    this.list.push(CGHS_AMA_REFERENCE_DATE);
  }

  drawerData: EmployeeMaster = new EmployeeMaster();
  drawerTitle: string = '';

  add(): void {
    this.drawerTitle = 'Create New Employee';
    this.drawerData = new EmployeeMaster();
    this.empDrawerVisible = true;
  }
  editEmp;

  edit(data: any): void {
    this.drawerTitle = 'Edit Employee Details';
    this.api
      .getEmployeeMaster(0, 0, '', '', ' AND ID = ' + data.EMP_ID)
      .subscribe((data) => {
        if (data['code'] == 200) {
          this.drawerData = data['data'][0];
        } else {
          this.message.error("Can't Load Employee Data", '');
        }
      }),
      (this.empDrawerVisible = true);
  }

  empDrawerClose(): void {
    this.empDrawerVisible = false;
    this.allEmployeeList();
    // window.location.reload();
  }

  get closeCallback() {
    return this.empDrawerClose.bind(this);
  }

  employeeSearch(event: any) {
    var f = '';
    if (event.length >= 3) {
      this.api
        .getEmployeeMaster(
          0,
          0,
          '',
          'asc',
          " AND NAME like '%" +
            event +
            "%'" +
            " OR EMPLOYEE_CODE like '%" +
            event +
            "%'" +
            " OR OFFICE_NAME like '%" +
            event +
            "%'" +
            " OR DESIGNATION like '%" +
            event +
            "%'" +
            " OR DDO_OF_THE_OFFICIAL like '%" +
            event +
            "%'" +
            f
        )
        .subscribe(
          (empData) => {
            if (empData['code'] == 200) {
              var filteredOptions = empData['data'];

              // this.empLoader = false;
            } else {
              this.message.error("Can't Load Employee Data", '');
              // this.empLoader = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
    }
    // this.api
    //   .getinvestigationprocedure(
    //     0,
    //     0,
    //     'NAME',
    //     'asc',
    //     " and NAME like '%" + event + "%'" + f
    //   )
    //   .subscribe(
    //     (data) => {
    //       if (data['code'] == 200 && data['data'].length > 0)
    //         this.procedureList2 = data['data'];
    //       else this.procedureList2 = [];
    //     },
    //     (err) => {
    //       console.log(err);
    //       this.isSpinning = false;
    //     }
    //   );
    // }
  }
  pressCS(event) {
    if (event == 'CS') {
      // this.data.CGHS_CARD_VALIDITY = '';
      // this.data.CGHS_CARD_NO = '';
    } else {
    }
  }
  orderSheetVisible: boolean = false;
  orderSheetTitle: string = '';
  orderValue: any = '';
  orderSheet() {
    this.orderValue = '';
    this.orderSheetTitle = 'Order Sheet';
    this.orderSheetVisible = true;
    // this.orderValue = 1;
  }
  permissionLetter() {
    this.orderValue = '';
    this.orderSheetTitle = 'Permission Letter';
    this.orderSheetVisible = true;
    // this.orderValue = 2;
  }

  orderSheetClose(): void {
    this.orderSheetVisible = false;
  }

  get orderSheetCloseCallback() {
    return this.orderSheetClose.bind(this);
  }
  HospitalMapping = [];
  queData: any = [];
  empID1;
  claimID1;

  index = -1;
  editrelation: Realtionshipdata = new Realtionshipdata();
  edit1(data: Realtionshipdata, i: any): void {
    this.index = i;
    this.editrelation = Object.assign({}, data);
  }

  addData(addNew: boolean, relation: NgForm) {
    this.isSpinning = false;
    this.isOk = true;

    if (
      (this.editrelation.NAME_OF_FAMILY_MEMBER == undefined ||
        this.editrelation.NAME_OF_FAMILY_MEMBER.trim() == '') &&
      (this.editrelation.AGE == undefined || this.editrelation.AGE == 0) &&
      (this.editrelation.RELATIONSHIP == undefined ||
        this.editrelation.RELATIONSHIP.trim() == '')
    ) {
      this.isOk = false;
      this.message.error('Please Fill All The Required Fields ', '');
    } else if (
      this.editrelation.NAME_OF_FAMILY_MEMBER == undefined ||
      this.editrelation.NAME_OF_FAMILY_MEMBER.trim() == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Name of Family Member.', '');
    } else if (
      this.editrelation.AGE == undefined ||
      this.editrelation.AGE <= 0
    ) {
      this.isOk = false;
      this.message.error('Please Enter Age  ', '');
    } else if (
      this.editrelation.RELATIONSHIP == null ||
      this.editrelation.RELATIONSHIP == undefined
    ) {
      this.isOk = false;
      this.message.error(
        ' Please Select Relationship with the Govt. Servant.',
        ''
      );
    }

    if (this.isOk) {
      this.editrelation.ID = undefined;
      this.editrelation.CLIENT_ID = 1;

      if (this.index > -1) {
        this.relationdata[this.index] = Object.assign({}, this.editrelation);
      } else {
        this.relationdata.push(Object.assign({}, this.editrelation));
      }
      this.relationdata = [...[], ...this.relationdata];

      relation.form.reset();
      this.editrelation = new Realtionshipdata();
      this.index = -1;
    }
  }

  // confirmDeleteHospital(data: any) {
  //   this.relationdata = this.relationdata.filter((item) => item.ID != data.ID);
  // }

  // clearRemark(event) {
  //   if (event == 'A') {
  //     this.data.ACTION_REMARK = null;

  //     this.data.ACTION_DATE = null;
  //   } else if (event == 'R') {
  //     this.data.ACTION_REMARK = null;
  //     this.data.ACTION_DATE = null;
  //   } else {
  //     this.data.ACTION_REMARK = null;
  //   }
  // }

  uploadDoc(addNew: boolean) {
    this.isOk = true;
    if (this.isOk && this.data.ID != undefined) {
      this.isSpinning = true;
      if (this.data.ID) {
        // this.data.TRANSFER_ID = undefined;
        this.api.ltcMasterUpdate(this.data).subscribe((successCode) => {
          if (successCode.code == '200') {
            this.isSpinning = false;
            // this.drawerClose();
            this.next();
            this.message.success('Information Saved Successfully...', '');
          } else {
            this.message.error('Information Has Not Saved...', '');
            this.isSpinning = false;
          }
        });
      } else {
      }
    }
  }

  updatecall() {
    this.api.ltcMasterUpdate(this.data).subscribe((successCode) => {
      if (successCode.code == '200') {
        // this.isSpinning = false;
        // this.message.success('Verified Successfully...', '');
        // this.printOrderModalVisible = false;
      } else {
        this.message.error('Information Has Not Saved...', '');

        this.isSpinning = false;
      }
    });
  }

  LeaveApplicationFileURL: any;
  onFileSelectedLeaveApp(event: any) {
    // if (this.data.FILE_TYPE == 'PDF') {

    if (event.target.files[0].type == 'application/pdf') {
      this.isSpinning = true;
      this.LeaveApplicationFileURL = <File>event.target.files[0];

      if (this.LeaveApplicationFileURL != null) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.LeaveApplicationFileURL.name.split('.').pop();
        var d = this.datepipe.transform(new Date(), 'yyyyMMdd');
        var url = '';
        url = d == null ? '' : d + number + '.' + fileExt;
        if (
          this.data.PRIOR_LEAVE_APPLICATION_URL != undefined &&
          this.data.PRIOR_LEAVE_APPLICATION_URL.trim() != ''
        ) {
          var arr = this.data.PRIOR_LEAVE_APPLICATION_URL.split('/');
          if (arr.length > 1) {
            url = arr[5];
          }
        }
      }
      this.api
        .onUpload('priorLeaveApplication', this.LeaveApplicationFileURL, url)
        .subscribe((successCode) => {
          if (successCode.code == '200') {
            this.data.PRIOR_LEAVE_APPLICATION_URL = url;
            // this.data.ASSUMPTION_CHARGE_STATUS = '';
            this.updatecall();
            this.message.success('File Uploaded Successfully...', '');
            this.isSpinning = false;

            // this.data.FILE_URL = url;
          } else {
            this.message.error('Failed To Save File...', '');
            this.isSpinning = false;
          }
        });
    } else {
      this.message.error('Please Select Only PDF File', '');
      this.LeaveApplicationFileURL = null;
      this.data.PRIOR_LEAVE_APPLICATION_URL = null;
    }
  }

  leaveSanctionFileURL: any;
  onFileSelectedLeaveOrder(event: any) {
    // if (this.data.FILE_TYPE == 'PDF') {

    if (event.target.files[0].type == 'application/pdf') {
      this.isSpinning = true;
      this.leaveSanctionFileURL = <File>event.target.files[0];

      if (this.leaveSanctionFileURL != null) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.leaveSanctionFileURL.name.split('.').pop();
        var d = this.datepipe.transform(new Date(), 'yyyyMMdd');
        var url = '';
        url = d == null ? '' : d + number + '.' + fileExt;
        if (
          this.data.LEAVE_SACTIONED_ORDER_URL != undefined &&
          this.data.LEAVE_SACTIONED_ORDER_URL.trim() != ''
        ) {
          var arr = this.data.LEAVE_SACTIONED_ORDER_URL.split('/');
          if (arr.length > 1) {
            url = arr[5];
          }
        }
      }
      this.api
        .onUpload('leaveSanctionedOrder', this.leaveSanctionFileURL, url)
        .subscribe((successCode) => {
          if (successCode.code == '200') {
            this.data.LEAVE_SACTIONED_ORDER_URL = url;
            // this.data.LPC_STATUS = '';
            this.updatecall();
            this.message.success('File Uploaded Successfully...', '');
            this.isSpinning = false;

            // this.data.FILE_URL = url;
          } else {
            this.message.error('Failed To Save File...', '');
            this.isSpinning = false;
          }
        });
    } else {
      this.message.error('Please Select Only PDF File', '');
      this.leaveSanctionFileURL = null;
      this.data.LEAVE_SACTIONED_ORDER_URL = null;
    }
  }

  LTCMandateFormFileURL: any;
  onFileSelectedMandateForm(event: any) {
    // if (this.data.FILE_TYPE == 'PDF') {

    if (event.target.files[0].type == 'application/pdf') {
      this.isSpinning = true;
      this.LTCMandateFormFileURL = <File>event.target.files[0];

      if (this.LTCMandateFormFileURL != null) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.LTCMandateFormFileURL.name.split('.').pop();
        var d = this.datepipe.transform(new Date(), 'yyyyMMdd');
        var url = '';
        url = d == null ? '' : d + number + '.' + fileExt;
        if (
          this.data.LTC_MANDATE_FORM != undefined &&
          this.data.LTC_MANDATE_FORM.trim() != ''
        ) {
          var arr = this.data.LTC_MANDATE_FORM.split('/');
          if (arr.length > 1) {
            url = arr[5];
          }
        }
      }
      this.api
        .onUpload('ltcMandateForm', this.LTCMandateFormFileURL, url)
        .subscribe((successCode) => {
          if (successCode.code == '200') {
            this.data.LTC_MANDATE_FORM = url;
            // this.data.MANDATE_FORM_STATUS = '';
            this.updatecall();
            this.message.success('File Uploaded Successfully...', '');
            this.isSpinning = false;
            // this.data.FILE_URL = url;
          } else {
            this.message.error('Failed To Save File...', '');
            this.isSpinning = false;
          }
        });
    } else {
      this.message.error('Please Select Only PDF File', '');
      this.LTCMandateFormFileURL = null;
      this.data.LTC_MANDATE_FORM = null;
    }
  }
  LTCTransferFileURL: any;
  onFileSelectedPaymentSlip(event: any) {
    // if (this.data.FILE_TYPE == 'PDF') {

    if (event.target.files[0].type == 'application/pdf') {
      this.isSpinning = true;
      this.LTCTransferFileURL = <File>event.target.files[0];

      if (this.LTCTransferFileURL != null) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.LTCTransferFileURL.name.split('.').pop();
        var d = this.datepipe.transform(new Date(), 'yyyyMMdd');
        var url = '';
        url = d == null ? '' : d + number + '.' + fileExt;
        if (
          this.data.LATEST_PAYMENT_SLIP != undefined &&
          this.data.LATEST_PAYMENT_SLIP.trim() != ''
        ) {
          var arr = this.data.LATEST_PAYMENT_SLIP.split('/');
          if (arr.length > 1) {
            url = arr[5];
          }
        }
      }
      this.api
        .onUpload('latestPaymentSlip', this.LTCTransferFileURL, url)
        .subscribe((successCode) => {
          if (successCode.code == '200') {
            this.data.LATEST_PAYMENT_SLIP = url;
            // this.data.TRANSFER_ORDER_SERVICE_CERTIFICATE_STATUS = '';
            this.updatecall();
            this.message.success('File Uploaded Successfully...', '');
            this.isSpinning = false;
            // this.data.FILE_URL = url;
          } else {
            this.message.error('Failed To Save File...', '');
            this.isSpinning = false;
          }
        });
    } else {
      this.message.error('Please Select Only PDF File', '');
      this.LTCTransferFileURL = null;
      this.data.LATEST_PAYMENT_SLIP = null;
    }
  }

  dependancyCertificateFileURL: any;
  onFileSelectedDependancyCertificate(event: any) {
    // if (this.data.FILE_TYPE == 'PDF') {

    if (event.target.files[0].type == 'application/pdf') {
      this.isSpinning = true;
      this.dependancyCertificateFileURL = <File>event.target.files[0];

      if (this.dependancyCertificateFileURL != null) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.dependancyCertificateFileURL.name.split('.').pop();
        var d = this.datepipe.transform(new Date(), 'yyyyMMdd');
        var url = '';
        url = d == null ? '' : d + number + '.' + fileExt;
        if (
          this.data.DEPENDENCY_CERTIFICATE != undefined &&
          this.data.DEPENDENCY_CERTIFICATE.trim() != ''
        ) {
          var arr = this.data.DEPENDENCY_CERTIFICATE.split('/');
          if (arr.length > 1) {
            url = arr[5];
          }
        }
      }
      this.api
        .onUpload(
          'dependencyCertificate',
          this.dependancyCertificateFileURL,
          url
        )
        .subscribe((successCode) => {
          if (successCode.code == '200') {
            this.data.DEPENDENCY_CERTIFICATE = url;
            // this.data.TICKET_BOARDING_PASS_STATUS = '';
            this.updatecall();
            this.message.success('File Uploaded Successfully...', '');
            this.isSpinning = false;
            // this.data.FILE_URL = url;
          } else {
            this.message.error('Failed To Save File...', '');
            this.isSpinning = false;
          }
        });
    } else {
      this.message.error('Please Select Only PDF File', '');
      this.dependancyCertificateFileURL = null;
      this.data.DEPENDENCY_CERTIFICATE = null;
    }
  }
  websiteSSFileURL: any;
  onFileSelectedwebsiteSS(event: any) {
    // if (this.data.FILE_TYPE == 'PDF') {

    if (event.target.files[0].type == 'application/pdf') {
      this.isSpinning = true;
      this.websiteSSFileURL = <File>event.target.files[0];

      if (this.websiteSSFileURL != null) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.websiteSSFileURL.name.split('.').pop();
        var d = this.datepipe.transform(new Date(), 'yyyyMMdd');
        var url = '';
        url = d == null ? '' : d + number + '.' + fileExt;
        if (
          this.data.WEBSITE_SCREENSHOT != undefined &&
          this.data.WEBSITE_SCREENSHOT.trim() != ''
        ) {
          var arr = this.data.WEBSITE_SCREENSHOT.split('/');
          if (arr.length > 1) {
            url = arr[5];
          }
        }
      }
      this.api
        .onUpload('websiteScreenShot', this.websiteSSFileURL, url)
        .subscribe((successCode) => {
          if (successCode.code == '200') {
            this.data.WEBSITE_SCREENSHOT = url;
            // this.data.TICKET_BOARDING_PASS_STATUS = '';
            this.updatecall();
            this.message.success('File Uploaded Successfully...', '');
            this.isSpinning = false;
            // this.data.FILE_URL = url;
          } else {
            this.message.error('Failed To Save File...', '');
            this.isSpinning = false;
          }
        });
    } else {
      this.message.error('Please Select Only PDF File', '');
      this.websiteSSFileURL = null;
      this.data.WEBSITE_SCREENSHOT = null;
    }
  }

  LTCTransportationBillFileURL: any;
  onFileSelectedTransportationBill(event: any) {
    // if (this.data.FILE_TYPE == 'PDF') {

    if (event.target.files[0].type == 'application/pdf') {
      this.isSpinning = true;
      this.LTCTransportationBillFileURL = <File>event.target.files[0];

      if (this.LTCTransportationBillFileURL != null) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.LTCTransportationBillFileURL.name.split('.').pop();
        var d = this.datepipe.transform(new Date(), 'yyyyMMdd');
        var url = '';
        url = d == null ? '' : d + number + '.' + fileExt;
        if (
          this.data.LTC_TRANSPORTATION_BILLS != undefined &&
          this.data.LTC_TRANSPORTATION_BILLS.trim() != ''
        ) {
          var arr = this.data.LTC_TRANSPORTATION_BILLS.split('/');
          if (arr.length > 1) {
            url = arr[5];
          }
        }
      }
      this.api
        .onUpload(
          'ltcTransportationBills',
          this.LTCTransportationBillFileURL,
          url
        )
        .subscribe((successCode) => {
          if (successCode.code == '200') {
            this.data.LTC_TRANSPORTATION_BILLS = url;
            // this.data.TRANSPORTATION_BILLS_STATUS = '';
            this.updatecall();
            this.message.success('File Uploaded Successfully...', '');
            this.isSpinning = false;
            // this.data.FILE_URL = url;
          } else {
            this.message.error('Failed To Save File...', '');
            this.isSpinning = false;
          }
        });
    } else {
      this.message.error('Please Select Only PDF File', '');
      this.LTCTransportationBillFileURL = null;
      this.data.LTC_TRANSPORTATION_BILLS = null;
    }
  }

  condonationOfDelayFileURL: any;
  onFileSelectedCondonationOfDelay(event: any) {
    // if (this.data.FILE_TYPE == 'PDF') {

    if (event.target.files[0].type == 'application/pdf') {
      this.isSpinning = true;
      this.condonationOfDelayFileURL = <File>event.target.files[0];

      if (this.condonationOfDelayFileURL != null) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.condonationOfDelayFileURL.name.split('.').pop();
        var d = this.datepipe.transform(new Date(), 'yyyyMMdd');
        var url = '';
        url = d == null ? '' : d + number + '.' + fileExt;
        if (
          this.data.LTC_CONDONATION_OF_DELAY != undefined &&
          this.data.LTC_CONDONATION_OF_DELAY.trim() != ''
        ) {
          var arr = this.data.LTC_CONDONATION_OF_DELAY.split('/');
          if (arr.length > 1) {
            url = arr[5];
          }
        }
      }
      this.api
        .onUpload('ltcCondonationOfDelay', this.condonationOfDelayFileURL, url)
        .subscribe((successCode) => {
          if (successCode.code == '200') {
            this.data.LTC_CONDONATION_OF_DELAY = url;
            // this.data.CONDOLATION_OF_DELAY_STATUS = '';
            this.updatecall();
            this.message.success('File Uploaded Successfully...', '');
            this.isSpinning = false;
            // this.data.FILE_URL = url;
          } else {
            this.message.error('Failed To Save File...', '');
            this.isSpinning = false;
          }
        });
    } else {
      this.message.error('Please Select Only PDF File', '');
      this.condonationOfDelayFileURL = null;
      this.data.LTC_CONDONATION_OF_DELAY = null;
    }
  }

  view = 0;
  sanitizedLink: any = '';
  getS(link: string) {
    if (this.view == 1) {
      var a = this.api.retriveimgUrl + 'priorLeaveApplication/' + link;
    }
    if (this.view == 2) {
      var a = this.api.retriveimgUrl + 'leaveSanctionedOrder/' + link;
    }
    if (this.view == 3) {
      var a = this.api.retriveimgUrl + 'ltcMandateForm/' + link;
    }
    if (this.view == 4) {
      var a = this.api.retriveimgUrl + 'latestPaymentSlip/' + link;
    }
    if (this.view == 5) {
      var a = this.api.retriveimgUrl + 'dependencyCertificate/' + link;
    }
    if (this.view == 6) {
      var a = this.api.retriveimgUrl + 'ltcTransportationBills/' + link;
    }
    if (this.view == 7) {
      var a = this.api.retriveimgUrl + 'ltcCondonationOfDelay/' + link;
    }
    if (this.view == 8) {
      var a = this.api.retriveimgUrl + 'websiteScreenShot/' + link;
    }
    this.sanitizedLink = this.sanitizer.bypassSecurityTrustResourceUrl(a);
    return this.sanitizedLink;
  }
  printOrderModalCancel() {
    this.printOrderModalVisible = false;
  }
  printOrderModalVisible = false;
  viewLeaveApplicationPDF(pdfURL: string): void {
    this.view = 1;
    this.printOrderModalVisible = true;

    // window.open(this.api.retriveimgUrl + 'assumptionCharge/' + pdfURL);
  }
  viewLeaveSanctionPDF(pdfURL: string): void {
    this.view = 2;
    this.printOrderModalVisible = true;
    // window.open(this.api.retriveimgUrl + 'lpc/' + pdfURL);
  }
  viewMandateFormPDF(pdfURL: string): void {
    this.view = 3;
    this.printOrderModalVisible = true;
    // window.open(this.api.retriveimgUrl + 'mandateForm/' + pdfURL);
  }
  viewPaymentPDF(pdfURL: string): void {
    this.view = 4;
    this.printOrderModalVisible = true;
    // window.open(
    // this.api.retriveimgUrl + 'transferServiceCertificate/' + pdfURL
    // );
  }
  viewDependancyPDF(pdfURL: string): void {
    this.view = 5;
    this.printOrderModalVisible = true;
    // window.open(this.api.retriveimgUrl + 'ticketBoardingPass/' + pdfURL);
  }
  viewTransportationBillsPDF(pdfURL: string): void {
    this.view = 6;
    this.printOrderModalVisible = true;
    // window.open(this.api.retriveimgUrl + 'transportationBills/' + pdfURL);
  }
  viewCondonationOfDelayPDF(pdfURL: string): void {
    this.view = 7;
    this.printOrderModalVisible = true;
    // window.open(this.api.retriveimgUrl + 'condolationOfDelay/' + pdfURL);
  }

  viewwebsiteSSPDF(pdfURL: string): void {
    this.view = 8;
    this.printOrderModalVisible = true;
    // window.open(this.api.retriveimgUrl + 'ticketBoardingPass/' + pdfURL);
  }
}
