<div>
    <form nz-form #websitebannerPage="ngForm">
        <div nz-row>
            <!-- <div nz-col nzSpan="24">
                <nz-form-item>
                    <nz-form-label nzRequired nzNoColon>City Name </nz-form-label>

                  
                        <nz-select nzShowSearch nzAllowClear [(ngModel)]="data.CITY_ID" nzErrorTip="Please Select City" nzPlaceHolder="Please Select City " name="cityid"
                        required>
                        <nz-option *ngFor="let role of city " [nzValue]="role.ID" [nzLabel]="role.NAME">
                        </nz-option>
                       
                      </nz-select>
                    
                </nz-form-item>
                
            </div> -->


           
            <div nz-col nzSpan="24">
                <nz-form-item>
                    <nz-form-label nzRequired nzNoColon>Category Name</nz-form-label>

                    <nz-form-control nzErrorTip=" Please Enter Category Name">
                        <input nz-input autocomplete="off" name="name" type="text" maxlength="256" placeHolder="Enter Category Name"
                            [(ngModel)]="data.NAME" required />
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div nz-col nzSpan="24">
                <nz-form-item>
                    <nz-form-label nzNoColon>Status</nz-form-label>

                    <nz-form-control>
                        <nz-switch name="status" nzCheckedChildren="Yes" nzUnCheckedChildren="No"
                            [(ngModel)]="data.STATUS"></nz-switch>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
    </form>

    <div class="footer">
        <button type="button" nz-button (click)="close(websitebannerPage)" class="ant-btn" style="margin-right: 8px">
            <span>Cancel</span>
        </button>
        <button type="button" class="ant-btn ant-btn-primary" *ngIf="!data.ID" nz-button nz-dropdown
            [nzDropdownMenu]="menu" [nzLoading]="isSpinning" (click)="save(false,websitebannerPage)">
            <span>Save & Close</span>
        </button>
        <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
                <li nz-menu-item class="menuc" style="border-radius: 4px; background-color: #40a9ff">
                    <a style="color: white" (click)="save(true,websitebannerPage)">Save & New</a>
                </li>
            </ul>
        </nz-dropdown-menu>
        <button type="button" class="ant-btn ant-btn-primary" nz-button [nzLoading]="isSpinning" *ngIf="data.ID"
            (click)="save(false,websitebannerPage)">
            <span>Update & Close</span>
        </button>
    </div>
    <div class="header">
        <button type="button" nz-button (click)="close(websitebannerPage)" class="ant-btn"
            style="margin-right: 8px">
            <i nz-icon nzType="close" nzTheme="outline"></i>
        </button>
    </div>
</div>