<div nz-row nzType="flex">
  <div nz-col nzXs="24" nzSm="24" nzMd="20" nzLg="20" nzXl="12">
    <div class="formname">Transfer Detailed Summary Report</div>
  </div>

  <div
    nz-col
    nzXs="24"
    nzSm="24"
    nzMd="4"
    nzLg="6"
    nzXl="12"
    style="display: flex; justify-content: flex-end"
  >
    <form nz-form [nzLayout]="'inline'">
      <nz-form-item>
        <button
          nz-button
          nzTooltipTitle="Excel"
          nzTooltipPlacement="top"
          nz-tooltip
          nzType="primary"
          (click)="importInExcel()"
          [nzLoading]="exportLoading"
        >
          <i nz-icon nzType="file-excel" nzTheme="outline"></i>
        </button>
      </nz-form-item>

      <nz-form-item>
        <button
          nz-button
          nzTooltipTitle="Filter"
          nzTooltipPlacement="top"
          nz-tooltip
          [nzType]="isFilterApplied"
          (click)="showFilter()"
        >
          <i nz-icon nzType="filter"></i>
        </button>
      </nz-form-item>

      <nz-form-item>
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
          <input
            type="text"
            name="search"
            [(ngModel)]="searchText"
            (keydown.enter)="onKeypressEvent($event)"
            nz-input
            placeholder="Search"
          />
        </nz-input-group>
        <ng-template #suffixIconButton>
          <button
            nz-button
            nzType="primary"
            id="button"
            (click)="search(true)"
            nzSearch
          >
            <i nz-icon nzType="search"></i>
          </button>
        </ng-template>
      </nz-form-item>
    </form>
  </div>
</div>

<div nz-row>
  <div nz-col nzSpan="24">
    <div class="filter {{ filterClass }}">
      <div class="filter-box">
        <form nz-form>
          <div nz-row>
            <!-- <div nz-col nzSpan="5">
                            <nz-form-item>
                                <nz-form-label nzNoColon>Start Date</nz-form-label>
                                <nz-date-picker name="startdate"
                                    style="width: 100%;" nzFormat="dd-MM-yyyy "
                                    [(ngModel)]="startValue"
                                    nzPlaceHolder="Select Start Date.">
                                </nz-date-picker>
                            </nz-form-item>
                        </div> -->
            <!-- 
                        <div nz-col nzSpan="5">
                            <nz-form-item>
                                <nz-form-label nzNoColon>End Date</nz-form-label>
                                <nz-date-picker #EndDatePicker name="enddate"
                                    nzFormat="dd-MM-yyyy" [(ngModel)]="endValue"
                                    style="width: 100%;"
                                    nzPlaceHolder="Select End Date."></nz-date-picker>
                            </nz-form-item>
                        </div> -->

            <div nz-col nzSpan="6">
              <nz-form-item>
                <nz-form-label nzNoColon>Inspector Name</nz-form-label>
                <nz-select
                  name="InspectorName"
                  nzShowSearch
                  nzAllowClear
                  style="width: 100%"
                  nzPlaceHolder="Select Inspector Name"
                  [(ngModel)]="type"
                >
                  <nz-option
                    *ngFor="let iname of inspectorname"
                    [nzValue]="iname.ID"
                    [nzLabel]="iname.NAME"
                  >
                  </nz-option>
                </nz-select>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="8">
              <nz-form-label nzNoColon></nz-form-label>
              <nz-form-item>
                <button
                  nz-button
                  [nzLoading]="isSpinning"
                  nzType="primary"
                  (click)="applyFilter()"
                  nzJustify="end"
                >
                  <i nz-icon nzType="filter"></i>Apply Filter
                </button>
                <button nz-button nzType="primary" (click)="clearFilter()">
                  <i nz-icon nzType="filter"></i>Clear filter
                </button>
              </nz-form-item>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div nz-row>
  <div nz-col nzSpan="24">
    <nz-table
      id="excel-table"
      class="my-scroll no-wrap1"
      nzBordered
      #table
      nzShowSizeChanger
      [nzFrontPagination]="false"
      [nzData]="dataList"
      [nzLoading]="loadingRecords"
      [nzTotal]="totalRecords"
      [(nzPageIndex)]="pageIndex"
      [(nzPageSize)]="pageSize"
      (nzPageIndexChange)="search()"
      [nzScroll]="{ x: '1800px', y: '420px' }"
      (nzPageSizeChange)="search()"
      (nzQueryParams)="sort($event)"
    >
      <thead nzSingleSort>
        <tr>
          <th
            nzWidth="170px"
            style="font-weight: bold; text-align: center"
            nzColumnKey="{{ 'INSPECTOR_NAME' }}"
            [nzSortFn]="true"
          >
            Inspector Name
          </th>
          <th
            nzWidth="160px"
            style="font-weight: bold; text-align: center"
            nzColumnKey="{{ 'NAME' }}"
            [nzSortFn]="true"
          >
            Applicant Name
          </th>

          <th
            nzWidth="140px"
            nzColumnKey="{{ 'DESIGNATION' }}"
            [nzSortFn]="true"
            style="font-weight: bold; text-align: center"
          >
            Designation
          </th>
          <th
            nzWidth="100px"
            nzColumnKey="{{ 'ADDRESS' }}"
            [nzSortFn]="true"
            style="font-weight: bold; text-align: center"
          >
            Address
          </th>
          <th
            nzWidth="100px"
            nzColumnKey="{{ 'OFFICE_NAME' }}"
            [nzSortFn]="true"
            style="font-weight: bold; text-align: center"
          >
            Office Name
          </th>
          <th
            nzWidth="100px"
            nzColumnKey="{{ 'NEW_OFFICE_NAME' }}"
            [nzSortFn]="true"
            style="font-weight: bold; text-align: center"
          >
            New Office Name
          </th>
          <th
            nzWidth="100px"
            nzColumnKey="{{ 'NEW_HEADQUARTERS_NAME' }}"
            [nzSortFn]="true"
            style="font-weight: bold; text-align: center"
          >
            New HeadQuater Name
          </th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let data of table.data">
          <td style="white-space: pre-line" align="center">
            {{ data["INSPECTOR_NAME"] }}
          </td>

          <td style="white-space: pre-line" align="center">
            {{ data["NAME"] }}
          </td>
          <td style="white-space: pre-line" align="center">
            {{ data["DESIGNATION"] }}
          </td>
          <td style="white-space: pre-line" align="center">
            {{ data["OFFICE_NAME"] }}
          </td>

          <td style="white-space: pre-line" align="center">
            {{ data["ADDRESS"] }}
          </td>
          <td style="white-space: pre-line" align="center">
            {{ data["NEW_OFFICE_NAME"] }}
          </td>
          <td style="white-space: pre-line" align="center">
            {{ data["NEW_HEADQUARTERS_NAME"] }}
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>
