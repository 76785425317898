<div>
  <nz-steps [nzCurrent]="current" style="margin: 0px 50px">
    <nz-step nzTitle="Applicant Information"></nz-step>
    <nz-step nzTitle="Claim Information"></nz-step>
    <nz-step nzTitle="Questionaries"></nz-step>
    <nz-step nzTitle="Checklist"></nz-step>
  </nz-steps>

  <div class="steps-content" style="overflow-y: scroll; height: 510px">
    <!-- Applicant Info -->
    <div *ngIf="current == 0">
      <div>
        <form nz-form #applicantMasterPages="ngForm">
          <nz-spin [nzSpinning]="isSpinning">
            <div nz-row>
              <!-- <div nz-col nzSpan="7">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Employee Name</nz-form-label
                  >

                  <nz-form-control nzErrorTip="Please Select Employee Name">
                    <nz-select
                      nzShowSearch
                      nzAllowClear
                      nzPlaceHolder="Select Employee"
                      (ngModelChange)="filterEmpData($event)"
                      name="empID"
                      [(ngModel)]="data.EMP_ID"
                      [nzLoading]="empLoader"
                      required
                    >
                      <nz-option
                        *ngFor="let role of employee"
                        [nzValue]="role.ID"
                        [nzLabel]="role.NAME"
                      >
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div> -->

              <div nz-col nzSpan="12">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Employee Name</nz-form-label
                  >

                  <nz-form-control nzErrorTip="Please Select Employee Name">
                    <nz-select
                      [nzOptionHeightPx]="60"
                      nzShowSearch
                      nzAllowClear
                      nzPlaceHolder="Select Employee"
                      (ngModelChange)="filterEmpData($event)"
                      name="empID"
                      [(ngModel)]="data.EMP_ID"
                      [nzLoading]="empLoader"
                      (keyup)="employeeSearch($event.target.value)"
                      required
                    >
                      <nz-option
                        *ngFor="let role of employee"
                        [nzValue]="role.ID"
                        nzLabel="{{ role.NAME }} - {{ role.EMPLOYEE_CODE }}"
                        nzCustomContent
                      >
                        <div style="background-color: #fffcfc; padding: 5px">
                          <div nz-row>
                            <div
                              nz-col
                              nzSpan="24"
                              style="width: 100%; color: black; font-size: 14px"
                            >
                              <b
                                >{{ role.NAME }}
                                <small style="position: absolute; right: 0px">{{
                                  role.EMPLOYEE_CODE
                                }}</small></b
                              >
                            </div>

                            <div nz-col nzSpan="24" style="font-size: 11px">
                              <div nz-row>
                                <div
                                  style="color: #b30062; font-weight: bold"
                                  nz-col
                                  nzSpan="12"
                                >
                                  {{ role.OFFICE_NAME }}
                                </div>

                                <div nz-col nzSpan="1"></div>

                                <div
                                  style="color: #2f08c0; font-weight: bold"
                                  nz-col
                                  nzSpan="11"
                                >
                                  {{ role.DESIGNATION }}
                                </div>
                              </div>
                            </div>

                            <div
                              nz-col
                              nzSpan="24"
                              style="font-size: 14px; font-weight: 500"
                            >
                              {{ role.DDO_OF_THE_OFFICIAL }}
                            </div>
                          </div>
                        </div>
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="1" *ngIf="!data.EMP_ID">
                <nz-form-item>
                  <nz-form-label nzNoColon></nz-form-label>
                  <nz-form-control>
                    <button nz-button nzType="primary" (click)="add()">
                      <i nz-icon nzType="plus"></i>
                    </button>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="1" *ngIf="data.EMP_ID">
                <nz-form-item>
                  <nz-form-label nzNoColon></nz-form-label>
                  <nz-form-control>
                    <button nz-button nzType="primary" (click)="edit(data)">
                      <i nz-icon nzType="edit"></i>
                    </button>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <!-- <div nz-col nzSpan="8">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Employee Name</nz-form-label
                  >
                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Select/Enter Employee Name "
                  >
                    <input
                      nz-input
                      maxlength="512"
                      name="empname"
                      style="width: 100%"
                      [(ngModel)]="data.NAME"
                      nz-input
                      placeholder="Please Select/Enter Employee"
                      draggable="false"
                      required
                      (ngModelChange)="onChange($event)"
                      [nzAutocomplete]="auto"
                    />
                    <nz-autocomplete #auto>
                      <nz-auto-option
                        *ngFor="let role of filteredOptions"
                        [nzValue]="role.NAME"
                        [nzLabel]="role.NAME"
                        >{{ role.NAME }}</nz-auto-option
                      >
                    </nz-autocomplete>
                  </nz-form-control>
                </nz-form-item>
              </div> -->

              <div nz-col nzSpan="11">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Designation</nz-form-label
                  >
                  <nz-form-control nzErrorTip="Enter Designation">
                    <input
                      nz-input
                      maxlength="256"
                      autocomplete="off"
                      name="Designation"
                      type="text"
                      [(ngModel)]="data.DESIGNATION"
                      placeHolder="Enter The Designation"
                      required
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="9">
                <nz-form-item>
                  <nz-form-label nzNoColon>Office Name</nz-form-label>
                  <nz-form-control nzErrorTip="Enter Correct Office Name">
                    <input
                      nz-input
                      autocomplete="off"
                      maxlength="256"
                      name="Office Name"
                      type="text"
                      [(ngModel)]="data.OFFICE_NAME"
                      placeHolder="Enter The Office Name"
                      required
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="9">
                <nz-form-item>
                  <nz-form-label nzNoColon>DDO Of The Official</nz-form-label>
                  <nz-form-control
                    nzErrorTip="Enter Correct DDO Of The Official"
                  >
                    <input
                      nz-input
                      maxlength="256"
                      autocomplete="off"
                      name="DDOOfficial"
                      type="text"
                      [(ngModel)]="data.DDO_OF_THE_OFFICIAL"
                      placeHolder="Enter The DDO Of The Official"
                      required
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="6">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Employee Code</nz-form-label
                  >

                  <nz-form-control nzErrorTip="Enter Correct Employee Code">
                    <input
                      nz-input
                      maxlength="16"
                      autocomplete="off"
                      name="EmployeeCode"
                      [(ngModel)]="data.EMPLOYEE_CODE"
                      type="text"
                      placeHolder="Enter Employee Code"
                      required
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="6">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>Basic Pay</nz-form-label>

                  <nz-form-control nzErrorTip="Enter Correct Basic Pay">
                    <nz-input-group style="width: 100%" nzAddOnBefore="₹">
                      <input
                        nz-input
                        maxlength="8"
                        autocomplete="off"
                        (keypress)="omit($event)"
                        [(ngModel)]="data.GRADE_PAY"
                        name="gradePay"
                        type="text"
                        placeHolder="Enter Employee Basic Pay"
                        required
                      />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="6">
                <nz-form-item>
                  <nz-form-label nzNoColon>Email ID </nz-form-label>
                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Enter Valid Email ID"
                  >
                    <input
                      nz-input
                      name="commiteeemail"
                      maxlength="256"
                      [pattern]="emailpattern"
                      [(ngModel)]="data.EMAIL_ID"
                      placeHolder="Enter Email ID"
                      required
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="6">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon>
                    Mobile Number
                  </nz-form-label>
                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Enter Valid Mobile Number"
                  >
                    <input
                      nz-input
                      name="commiteemobileno"
                      [pattern]="mobpattern"
                      [(ngModel)]="data.MOBILE_NO"
                      maxlength="10"
                      minlength="10"
                      (keypress)="omit($event)"
                      placeHolder="Enter Mobile Number"
                      required
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="6">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Beneficiary Type
                  </nz-form-label>
                  <nz-form-control>
                    <nz-radio-group
                      name="yesno"
                      [(ngModel)]="data.BENEFICIARY_TYPE"
                      required
                      (ngModelChange)="pressCS($event)"
                    >
                      <label nz-radio nzValue="CG">CGHS </label>
                      <label nz-radio nzValue="CS">CS(MA)</label>
                    </nz-radio-group>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="18">
                <div nz-row>
                  <div nz-col nzSpan="24">
                    <nz-form-item>
                      <nz-form-label nzNoColon>
                        Detailed Address Of Employee
                      </nz-form-label>
                      <nz-form-control
                        nzHasFeedback
                        nzErrorTip="Please Enter Valid Address"
                      >
                        <textarea
                          nz-input
                          style="width: 100%"
                          maxlength="256"
                          rows="4"
                          name="contactaddress"
                          [(ngModel)]="data.ADDRESS"
                          placeholder="Enter Detailed Address"
                          required
                        ></textarea>
                        <h6
                          style="color: red"
                          *ngIf="data.ADDRESS != undefined"
                        >
                          {{ data.ADDRESS.length }}/256
                        </h6>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
              </div>

              <div nz-col nzSpan="6">
                <div nz-row>
                  <div nz-col nzSpan="24" *ngIf="data.BENEFICIARY_TYPE == 'CG'">
                    <nz-form-item>
                      <nz-form-label nzRequired nzNoColon
                        >CGHS Card Number</nz-form-label
                      >
                      <nz-form-control
                        nzHasFeedback
                        nzErrorTip="Enter Correct CGHS Card Number"
                      >
                        <input
                          nz-input
                          name="CGHScardnumber"
                          maxlength="16"
                          [(ngModel)]="data.CGHS_CARD_NO"
                          placeHolder="Enter CGHS Card Number"
                          required
                        />
                      </nz-form-control>
                    </nz-form-item>
                  </div>

                  <div nz-col nzSpan="24" *ngIf="data.BENEFICIARY_TYPE == 'CG'">
                    <nz-form-item>
                      <nz-form-label nzNoColon
                        >CGHS Card Validity</nz-form-label
                      >
                      <nz-form-control
                        nzErrorTip="Select Correct CGHS Card Validity"
                      >
                        <nz-date-picker
                          style="width: 100%"
                          nzFormat="dd/MM/yyyy"
                          name="CGHScardvalidity"
                          [(ngModel)]="data.CGHS_CARD_VALIDITY"
                          required
                        ></nz-date-picker>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
              </div>
              <!-- <div nz-col nzSpan="8">
     <nz-form-item>
       <nz-form-label nzRequired nzNoColon>Is Advance Taken?</nz-form-label>
       <nz-form-control>
         c
       </nz-form-control>
     </nz-form-item>
   </div>                         -->

              <!-- <div nz-col nzSpan="8">
            <nz-form-item>
              <nz-form-label nzRequired nzNoColon>Location</nz-form-label>
              <nz-form-control nzErrorTip="Input is required">
                <input nz-input autocomplete="off" name="Location" type="text" [(ngModel)]="data.LOCATION"
                  placeHolder="Enter The Location" required />
              </nz-form-control>
            </nz-form-item>
          </div> -->
            </div>
          </nz-spin>
        </form>
      </div>
    </div>
    <!-- Applicant Info -->

    <!-- Claim Info -->
    <div *ngIf="current == 1">
      <form nz-form #claimMasterPage="ngForm">
        <nz-spin [nzSpinning]="isSpinning">
          <div nz-row class="my-box">
            <div nz-col nzSpan="6">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon
                  >Relationship with Applicant</nz-form-label
                >
                <nz-form-control
                  nzErrorTip="Please Select Correct Relationship with Applicant"
                >
                  <!-- <input nz-input autocomplete="off" name="Relation" type="text"
                [(ngModel)]="data2.RELATION_WITH_PATIENT" placeHolder="Enter Relation" required /> -->
                  <nz-select
                    nzShowSearch
                    nzAllowClear
                    nzPlaceHolder="Select Relationship with Applicant"
                    [(ngModel)]="data2.RELATION_WITH_PATIENT"
                    name="relation"
                    (ngModelChange)="relationshipFilter($event)"
                    required
                  >
                    <!-- <nz-option *ngFor="let relatives of relativeArray;" [nzValue]="relatives"
                [nzLabel]="relatives"></nz-option> -->
                    <nz-option nzLabel="Self" nzValue="Self"></nz-option>
                    <nz-option nzLabel="Mother" nzValue="Mother"></nz-option>
                    <nz-option nzLabel="Father" nzValue="Father"></nz-option>
                    <nz-option nzLabel="Brother" nzValue="Brother"></nz-option>
                    <nz-option nzLabel="Sister" nzValue="Sister"></nz-option>
                    <nz-option
                      nzLabel="Daughter"
                      nzValue="Daughter"
                    ></nz-option>
                    <nz-option nzLabel="Son" nzValue="Son"></nz-option>
                    <nz-option nzLabel="Wife" nzValue="Wife"></nz-option>
                    <nz-option nzLabel="Husband" nzValue="Husband"></nz-option>
                    <!-- <nz-option nzLabel="Grand-Mother" nzValue="Grand-Mother" ></nz-option>
                    <nz-option nzLabel="Grand-Father" nzValue="Grand-Father"></nz-option> -->
                    <nz-option
                      nzLabel="Father-In-Law"
                      nzValue="Father-In-Law"
                    ></nz-option>
                    <nz-option
                      nzLabel="Mother-In-Law"
                      nzValue="Mother-In-Law"
                    ></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="10">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>Patient Name</nz-form-label>
                <nz-form-control nzErrorTip="Please Enter Correct Patient Name">
                  <input
                    nz-input
                    autocomplete="off"
                    maxlength="128"
                    name="PatientName"
                    type="text"
                    [(ngModel)]="data2.PATIENT_NAME"
                    placeHolder="Enter Patient Name"
                    required
                  />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="8" *ngIf="data2.BENEFICIARY_TYPE == 'CG'">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>
                  Patient CGHS Beneficiary Number</nz-form-label
                >

                <nz-form-control
                  nzErrorTip="Please Enter Correct Patient CGHS Beneficiary Number"
                >
                  <!-- <nz-input-group style="width: 100%" nzAddOnBefore="₹"> -->
                  <input
                    nz-input
                    autocomplete="off"
                    maxlength="16"
                    [(ngModel)]="data2.PATIENT_CGHS_BENEFICIERY_NO"
                    name="cghsnumber"
                    type="text"
                    placeHolder="Enter Patient CGHS Beneficiary Number"
                    required
                  />
                  <!-- </nz-input-group> -->
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="8" *ngIf="data2.BENEFICIARY_TYPE == 'CS'"></div>
            <div nz-col nzSpan="16">
              <div nz-row>
                <div nz-col nzSpan="24">
                  <nz-form-item>
                    <nz-form-label nzRequired nzNoColon
                      >Nature Of Treatment/Test</nz-form-label
                    >

                    <nz-form-control
                      nzErrorTip="Please Enter Correct Nature Of Treatment/Test"
                    >
                      <textarea
                        nz-input
                        [nzAutosize]="{ minRows: 4, maxRows: 6 }"
                        name="NatureOfTreatment"
                        placeHolder="Enter a Nature Of Treatment/Test"
                        [(ngModel)]="data2.NATURE_OF_TREATMENT"
                        maxlength="512"
                        required
                      ></textarea>
                      <h6
                        style="color: red"
                        *ngIf="data2.NATURE_OF_TREATMENT != undefined"
                      >
                        {{ data2.NATURE_OF_TREATMENT.length }}/512
                      </h6>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
            </div>

            <div nz-col nzSpan="8">
              <div nz-row>
                <div nz-col nzSpan="24">
                  <nz-form-item>
                    <nz-form-label
                      *ngIf="data2.IS_ADVANCE_TAKEN == true"
                      nzNoColon
                      >Treatment Start Date</nz-form-label
                    >
                    <nz-form-label
                      *ngIf="data2.IS_ADVANCE_TAKEN == false"
                      nzRequired
                      nzNoColon
                      >Treatment Start Date</nz-form-label
                    >
                    <nz-form-control
                      nzErrorTip="Please Select Treatment Start Date"
                    >
                      <nz-date-picker
                        style="width: 100%"
                        nzFormat="dd/MM/yyyy"
                        (ngModelChange)="disableBillDate()"
                        [(ngModel)]="data2.TREATMENT_START_DATE"
                        name="TreatmentStartDate"
                        (ngModelChange)="treatEndDate()"
                        required
                      ></nz-date-picker>
                    </nz-form-control>
                  </nz-form-item>
                </div>

                <div nz-col nzSpan="24">
                  <nz-form-item>
                    <nz-form-label
                      *ngIf="data2.IS_ADVANCE_TAKEN == true"
                      nzNoColon
                      >Treatment End Date</nz-form-label
                    >
                    <nz-form-label
                      *ngIf="data2.IS_ADVANCE_TAKEN == false"
                      nzNoColon
                      nzRequired
                      >Treatment End Date</nz-form-label
                    >
                    <nz-form-control
                      nzErrorTip="Please Select Treatment End Date"
                    >
                      <nz-date-picker
                        style="width: 100%"
                        [nzDisabledDate]="disabledDate2"
                        [(ngModel)]="data2.TREATMENT_END_DATE"
                        nzFormat="dd/MM/yyyy"
                        name="TreatmentEndDate"
                        required
                      ></nz-date-picker>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
            </div>
          </div>

          <div nz-row class="my-box">
            <div nz-col nzSpan="6">
              <nz-form-item>
                <nz-form-label
                  *ngIf="data2.IS_ADVANCE_TAKEN == false"
                  nzRequired
                  nzNoColon
                >
                  Bill Filling Date
                </nz-form-label>
                <nz-form-label *ngIf="data2.IS_ADVANCE_TAKEN == true" nzNoColon>
                  Bill Filling Date
                </nz-form-label>
                <nz-form-control nzErrorTip="Please Select Bill Filling Date">
                  <nz-date-picker
                    style="width: 100%"
                    nzFormat="dd/MM/yyyy"
                    name="billfilldate"
                    [(ngModel)]="data2.BILL_FILIING_DATE"
                    [nzDisabledDate]="disabledDate"
                    required
                  ></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="6">
              <nz-form-item>
                <nz-form-label nzNoColon>Is Advance Taken?</nz-form-label>
                <nz-form-control>
                  <nz-switch
                    [(ngModel)]="data2.IS_ADVANCE_TAKEN"
                    name="AdvanceTaken"
                    nzCheckedChildren="Yes"
                    nzUnCheckedChildren="No"
                    (ngModelChange)="isAdvanceTaken($event)"
                  >
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="6" *ngIf="data2.IS_ADVANCE_TAKEN == true">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon
                  >Advance Amount</nz-form-label
                >
                <nz-form-control
                  nzHasFeedback
                  nzErrorTip="Please Enter Advance Amount"
                >
                  <nz-input-group nzAddOnBefore="₹">
                    <input
                      nz-input
                      name="AdvanceAmount"
                      maxlength="8"
                      type="number"
                      [(ngModel)]="data2.ADVANCE_AMOUNT"
                      placeHolder="Enter Advance Amount"
                      disabled="true"
                      required
                    />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="6" *ngIf="data2.IS_ADVANCE_TAKEN == true">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon
                  >Date Of Advance Taken</nz-form-label
                >
                <nz-form-control
                  nzErrorTip="Please Select Date Of Advance Taken"
                >
                  <nz-date-picker
                    style="width: 100%"
                    nzFormat="dd/MM/yyyy"
                    name="dateCGHSAMASreferences"
                    [(ngModel)]="data2.ADVANCE_TAKEN_DATE"
                    required
                  ></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <form nz-form #hospitalFormReset="ngForm">
            <div nz-row class="my-box">
              <!-- <nz-spin [nzSpinning]="isSpinning"> -->
              <!-- <div nz-col nzSpan="16" *ngIf="data2.IS_ADVANCE_TAKEN == false "  ></div> -->

              <div nz-col nzSpan="6">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Hospital Type</nz-form-label
                  >

                  <!-- <nz-select name="role_name" style="width: 100%;" [(ngModel)]="data2.id" nzShowSearch
                      nzPlaceHolder="Select City">
                      <nz-option *ngFor="let role of CITY_MASTER " [nzValue]="role.ID"
                          nzLabel="{{role.NAME}}">
                      </nz-option>
                  </nz-select> -->
                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Select Hospital Type"
                  >
                    <nz-select
                      nzShowSearch
                      nzAllowClear
                      nzPlaceHolder="Please Select Hospital Type "
                      name="hospitaltype"
                      [(ngModel)]="hospitalData.TYPE"
                      (ngModelChange)="hospitalType($event)"
                      required
                    >
                      <nz-option nzValue="E" nzLabel="Empanelled"></nz-option>
                      <nz-option
                        nzValue="NE"
                        nzLabel="Non Empanelled"
                      ></nz-option>
                      <nz-option nzValue="G" nzLabel="Government"></nz-option>
                      <!-- <nz-option *ngFor="let role of hospitaltype " [nzValue]="role.ID" [nzLabel]="role.NAME">
                  </nz-option> -->
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div
                nz-col
                nzSpan="16"
                *ngIf="
                  hospitalData.TYPE == undefined ||
                  hospitalData.TYPE == null ||
                  hospitalData.TYPE == ''
                "
              ></div>

              <div nz-col nzSpan="18" *ngIf="hospitalData.TYPE == 'E'">
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Hospital Name</nz-form-label
                  >
                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Select Correct Hospital Name"
                  >
                    <nz-select
                      name="role_name"
                      style="width: 100%"
                      [(ngModel)]="hospitalData.HOSPITAL_ID"
                      nzShowSearch
                      nzPlaceHolder="Select Hospital Name"
                      (ngModelChange)="hospitalAddress($event)"
                    >
                      <nz-option
                        *ngFor="let role of hospitalList"
                        [nzValue]="role.ID"
                        nzLabel="{{ role.NAME }}"
                        required
                      >
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div
                nz-col
                nzSpan="18"
                *ngIf="hospitalData.TYPE == 'NE' || hospitalData.TYPE == 'G'"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzNoColon
                    >Hospital Name</nz-form-label
                  >
                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please Enter Correct Hospital Name"
                  >
                    <input
                      nz-input
                      name="hospitalname"
                      maxlength="256"
                      [(ngModel)]="hospitalData.NAME"
                      placeHolder="Enter Hospital Name"
                      required
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="16">
                <div nz-row>
                  <div
                    nz-col
                    nzSpan="24"
                    *ngIf="
                      hospitalData.TYPE == undefined || hospitalData.TYPE == ''
                    "
                  ></div>
                  <div
                    nz-col
                    nzSpan="24"
                    *ngIf="
                      hospitalData.TYPE == 'E' ||
                      hospitalData.TYPE == 'NE' ||
                      hospitalData.TYPE == 'G'
                    "
                  >
                    <nz-form-item>
                      <nz-form-label nzRequired nzNoColon>
                        Hospital Address
                      </nz-form-label>
                      <nz-form-control
                        nzHasFeedback
                        nzErrorTip="Please Enter Valid Hospital Address"
                      >
                        <textarea
                          maxlength="256"
                          nz-input
                          style="width: 100%"
                          rows="2"
                          name="contactaddress"
                          [(ngModel)]="hospitalData.ADDRESS"
                          placeholder="Enter Hospital Address"
                          required
                        ></textarea>
                        <h6
                          style="color: red"
                          *ngIf="hospitalData.ADDRESS != undefined"
                        >
                          {{ hospitalData.ADDRESS.length }}/256
                        </h6>
                      </nz-form-control>
                    </nz-form-item>
                  </div>

                  <!-- <div
                  nz-col
                  nzSpan="24"
                  *ngIf="
                    data2.HOSPITAL_TYPE == 'NE' || data2.HOSPITAL_TYPE == 'G'
                  "
                >
                  <nz-form-item>
                    <nz-form-label nzRequired nzNoColon> Hospital Address </nz-form-label>
                    <nz-form-control
                      nzHasFeedback
                      nzErrorTip="Please Enter Valid Hospital Address"
                    >
                      <textarea
                        nz-input
                        style="width: 100%"
                        maxlength="256"
                        rows="3"
                        name="contactaddress2"
                        [(ngModel)]="data2.HOSPITAL_ADDRESS"
                        required
                      ></textarea>
                      <h6
                        style="color: red"
                        *ngIf="data2.HOSPITAL_ADDRESS != undefined"
                      >
                        {{ data2.HOSPITAL_ADDRESS.length }}/256
                      </h6>
                    </nz-form-control>
                  </nz-form-item>
                </div> -->
                </div>
              </div>

              <div nz-col nzSpan="7">
                <div nz-row>
                  <div
                    nz-col
                    nzSpan="24"
                    *ngIf="
                      hospitalData.TYPE == 'NE' || hospitalData.TYPE == 'G'
                    "
                  >
                    <nz-form-item>
                      <nz-form-label nzRequired nzNoColon
                        >Accreditation</nz-form-label
                      >
                      <nz-form-control
                        nzHasFeedback
                        nzErrorTip="Please Select Accreditation"
                      >
                        <!-- <input nz-input autocomplete="off" name="accreditation" type="text"
                            placeHolder="Enter The Accreditation" [(ngModel)]="data.ACCREDITATION" required />
                         -->
                        <nz-select
                          name="accreditation"
                          [(ngModel)]="hospitalData.ACCREDATION"
                          nzShowSearch
                          nzAllowClear
                          nzPlaceHolder="Select Accreditation"
                          required
                        >
                          <nz-option nzValue="N" nzLabel="NABH"></nz-option>
                          <nz-option
                            nzValue="NN"
                            nzLabel="Non NABH"
                          ></nz-option>
                          <nz-option
                            nzValue="SP"
                            nzLabel="Super Speciality"
                          ></nz-option>
                          <!-- <nz-option *ngFor="let option of accreditation" [nzLabel]="option" [nzValue]="option"></nz-option> -->
                        </nz-select>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>

                <div nz-col nzSpan="24" *ngIf="hospitalData.TYPE == 'E'">
                  <nz-form-item>
                    <nz-form-label nzRequired nzNoColon
                      >Accreditation</nz-form-label
                    >
                    <nz-form-control
                      nzHasFeedback
                      nzErrorTip="Please Select Accreditation"
                    >
                      <!-- <input nz-input autocomplete="off" name="accreditation" type="text"
                        placeHolder="Enter The Accreditation" [(ngModel)]="data.ACCREDITATION" required />
                     -->
                      <nz-select
                        disabled="true"
                        name="accreditation2"
                        [(ngModel)]="hospitalData.ACCREDATION"
                        nzShowSearch
                        nzAllowClear
                        nzPlaceHolder="Select Accreditation"
                        required
                      >
                        <nz-option nzValue="N" nzLabel="NABH"></nz-option>
                        <nz-option nzValue="NN" nzLabel="Non NABH"></nz-option>
                        <nz-option
                          nzValue="SP"
                          nzLabel="Super Speciality"
                        ></nz-option>
                        <!-- <nz-option *ngFor="let option of accreditation" [nzLabel]="option" [nzValue]="option"></nz-option> -->
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>

              <div
                nz-col
                nzSpan="1"
                *ngIf="
                  hospitalData.TYPE == 'NE' ||
                  hospitalData.TYPE == 'G' ||
                  hospitalData.TYPE == 'E'
                "
              >
                <nz-form-item>
                  <nz-form-label nzNoColon> </nz-form-label>
                  <nz-form-control>
                    <button
                      *ngIf="!hospitalData.ID"
                      nz-button
                      nzType="primary"
                      (click)="addHospital(false, hospitalFormReset)"
                    >
                      <i nz-icon nzType="plus"></i>
                    </button>
                    <button
                      *ngIf="hospitalData.ID"
                      nz-button
                      nzType="primary"
                      (click)="addHospital(false, hospitalFormReset)"
                    >
                      <i nz-icon nzType="edit"></i>
                    </button>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <!-- </nz-spin> -->
            </div>
          </form>

          <div nz-row class="my-box">
            <div nz-col nzSpan="24">
              <nz-table
                class="my-scroll no-wrap1"
                nzBordered
                [nzData]="hospitalMapList"
                #hospitaltabledata
                nzFrontPagination="false"
                [nzScroll]="{ x: '1400px', y: '250px' }"
              >
                <thead>
                  <tr>
                    <th
                      nzColumnKey=""
                      nzWidth="20px"
                      nzAlign="center"
                      style="font-weight: bold"
                    >
                      Action
                    </th>

                    <th
                      nzWidth="60px"
                      nzAlign="center"
                      style="font-weight: bold"
                    >
                      Hospital Type
                    </th>

                    <th nzColumnKey="" nzWidth="150px" nzAlign="center">
                      <p style="font-weight: bold">Hospital Name</p>
                    </th>

                    <th nzColumnKey="" nzWidth="150px" nzAlign="center">
                      <p style="font-weight: bold">Hospital Address</p>
                    </th>

                    <th nzColumnKey="" nzWidth="45px" nzAlign="center">
                      <p style="font-weight: bold">Accreditation</p>
                    </th>
                  </tr>
                </thead>

                <tbody style="text-align: center">
                  <tr
                    *ngFor="let data of hospitaltabledata.data; let i = index"
                    style="white-space: pre-line"
                  >
                    <td>
                      <span
                        style="cursor: pointer"
                        nz-popconfirm
                        nzPopconfirmTitle="Are you sure want delete?"
                        nzPopconfirmPlacement="top"
                        (nzOnConfirm)="confirmDeleteHospital(data)"
                        (nzOnCancel)="cancel()"
                      >
                        <span
                          nz-icon
                          nzType="delete"
                          nzTheme="twotone"
                          nz-tooltip
                          nzTooltipTitle="Delete"
                        >
                        </span>
                      </span>
                      &nbsp;
                      <span
                        nz-icon
                        nzType="edit"
                        nzTheme="twotone"
                        nz-tooltip
                        (click)="editHospital(data)"
                        nzTooltipTitle="Edit"
                      >
                      </span>
                    </td>
                    <td style="text-align: center; white-space: pre-line">
                      <span nz-icon *ngIf="data['TYPE'] == 'E'"
                        >Empanelled</span
                      >
                      <span nz-icon *ngIf="data['TYPE'] == 'NE'"
                        >Non Empanelled</span
                      >
                      <span nz-icon *ngIf="data['TYPE'] == 'G'"
                        >Government</span
                      >
                    </td>
                    <td style="text-align: center; white-space: pre-line">
                      {{ data["NAME"] }}
                    </td>
                    <td style="text-align: center; white-space: pre-line">
                      {{ data["ADDRESS"] }}
                    </td>
                    <td style="text-align: center; white-space: pre-line">
                      <span nz-icon *ngIf="data['ACCREDATION'] == 'N'"
                        >NAHB</span
                      >
                      <span nz-icon *ngIf="data['ACCREDATION'] == 'NN'"
                        >Non NAHB</span
                      >
                      <span nz-icon *ngIf="data['ACCREDATION'] == 'SP'"
                        >Super Speciality</span
                      >
                    </td>
                  </tr>
                </tbody>
              </nz-table>
            </div>
          </div>

          <div nz-row class="my-box">
            <div nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label nzNoColon> Name Of The Bank</nz-form-label>
                <nz-form-control
                  nzHasFeedback
                  nzErrorTip="Please Enter Valid Name Of The Bank"
                >
                  <input
                    nz-input
                    name="bankname"
                    maxlength="256"
                    [(ngModel)]="data2.BANK_NAME"
                    placeHolder="Enter Name Of The Bank"
                    required
                  />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label nzNoColon> SB Account Number</nz-form-label>
                <nz-form-control
                  nzHasFeedback
                  nzErrorTip="Please Enter Valid SB Account Number"
                >
                  <input
                    nz-input
                    name="accnumber"
                    maxlength="128"
                    [(ngModel)]="data2.BANK_ACCOUNT_NO"
                    placeHolder="Enter SB Account Number"
                    required
                  />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label nzNoColon> Branch IFSC Code </nz-form-label>
                <nz-form-control
                  nzHasFeedback
                  nzErrorTip="Please Enter Valid Branch IFSC Code"
                >
                  <input
                    nz-input
                    name="branchcode"
                    maxlength="32"
                    [(ngModel)]="data2.IFSC_CODE"
                    placeHolder="Enter SB Account Number"
                    required
                  />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label nzNoColon> Branch MICR Code </nz-form-label>
                <nz-form-control
                  nzHasFeedback
                  nzErrorTip="Please Enter Valid Branch MICR Code"
                >
                  <input
                    nz-input
                    name="micrcode"
                    maxlength="32"
                    [(ngModel)]="data2.MICR_CODE"
                    placeHolder="Enter Branch MICR Code"
                    required
                  />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label nzNoColon>Ward Entitlement </nz-form-label>
                <nz-form-control>
                  <nz-radio-group
                    name="wardentitlement"
                    [(ngModel)]="data2.WARD_TYPE"
                  >
                    <label nz-radio nzValue="P">Pvt. </label>
                    <label nz-radio nzValue="S">Semi Pvt.</label>
                    <label nz-radio nzValue="G">General</label>
                  </nz-radio-group>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label nzNoColon> Treatment Type </nz-form-label>
                <nz-form-control>
                  <nz-checkbox-wrapper style="width: 100%">
                    <div nz-row>
                      <div nz-col nzSpan="24">
                        <label
                          nz-checkbox
                          (nzCheckedChange)="onItemChecked($event)"
                          name="treattype2"
                          [(ngModel)]="TREATEMENT_TYPE1"
                          >OPD Treatment / Test Entitled</label
                        >
                      </div>
                      <div nz-col nzSpan="24">
                        <label
                          nz-checkbox
                          (nzCheckedChange)="onItemChecked2($event)"
                          name="treattype3"
                          [(ngModel)]="TREATEMENT_TYPE2"
                          >Indoor Treatment</label
                        >
                      </div>
                    </div>
                  </nz-checkbox-wrapper>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </nz-spin>
      </form>
    </div>
    <!-- Claim Info -->

    <!-- Questionary -->
    <div *ngIf="current == 2">
      <form nz-form #queMasterPage="ngForm">
        <nz-spin [nzSpinning]="isSpinning">
          <div nz-row>
            <div nz-col nzSpan="16">
              <div>Whether Emergency Treatment is Availed ?</div>
            </div>
            <div nz-col nzSpan="8">
              <nz-form-item>
                <!-- <nz-form-label nzRequired nzNoColon>Whether Emergency Treatment is Availed ?</nz-form-label> -->
                <nz-form-control>
                  <nz-switch
                    [(ngModel)]="data3.EMERGENCY_TREATEMENT"
                    name="emegtrat"
                    nzCheckedChildren="Yes"
                    nzUnCheckedChildren="No"
                    (ngModelChange)="emergencySwitch($event)"
                  >
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div nz-row *ngIf="data3.EMERGENCY_TREATEMENT == false">
            <div nz-col nzSpan="16">
              <div>Whether CGHS/AMA's Reference Taken?</div>
            </div>
            <div nz-col nzSpan="8">
              <nz-form-item>
                <!-- <nz-form-label nzRequired nzNoColon>Whether CGHS/AMA's Reference Taken?</nz-form-label> -->
                <nz-form-control>
                  <nz-switch
                    [(ngModel)]="data3.REFERENCE_FORM_CGHS_AMA_TAKEN"
                    name="cgmsreferance"
                    nzCheckedChildren="Yes"
                    nzUnCheckedChildren="No"
                    (ngModelChange)="onChangeReferance($event)"
                  >
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div nz-row *ngIf="data3.REFERENCE_FORM_CGHS_AMA_TAKEN == true">
            <div nz-col nzSpan="7">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>
                  CGHS/AMA's Reference Start Date
                </nz-form-label>
                <nz-form-control>
                  <nz-date-picker
                    style="width: 100%"
                    nzFormat="dd/MM/yyyy"
                    name="localreferencedate"
                    [(ngModel)]="CGHS_AMA_REFERENCE_DATE"
                    required
                  ></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <!-- <div nz-col nzSpan="7">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>
                  CGHS/AMA's Reference Start Date
                </nz-form-label>
                <nz-form-control>
                  <nz-date-picker
                    style="width: 100%"
                    nzFormat="dd/MM/yyyy"
                    name="localreferencedate"
                    [(ngModel)]="CGHS_AMA_REFERENCE_DATE"
                    [nzDisabledDate]="referanceDateDisabledDate"
                    required
                  ></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div> -->

            <div nz-col nzSpan="7">
              <nz-form-item>
                <nz-form-label nzNoColon>
                  CGHS/AMA's Reference End Date
                </nz-form-label>
                <nz-form-control>
                  <nz-date-picker
                    style="width: 100%"
                    nzFormat="dd/MM/yyyy"
                    name="localreferenceenddate"
                    [(ngModel)]="CGHS_AMA_REFERENCE_END_DATE"
                    required
                  ></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <!-- <div nz-col nzSpan="7">
              <nz-form-item>
                <nz-form-label nzNoColon>
                  CGHS/AMA's Reference End Date
                </nz-form-label>
                <nz-form-control>
                  <nz-date-picker
                    style="width: 100%"
                    nzFormat="dd/MM/yyyy"
                    name="localreferenceenddate"
                    [(ngModel)]="CGHS_AMA_REFERENCE_END_DATE"
                    [nzDisabledDate]="referanceDateDisabledDate"
                    required
                  ></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div> -->

            <div nz-col nzSpan="4">
              <nz-form-item>
                <nz-form-label nzNoColon> </nz-form-label>
                <nz-form-control>
                  <button nz-button nzType="primary" (click)="addDates()">
                    <i nz-icon nzType="plus"></i>
                  </button>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="18">
              <nz-form-item>
                <nz-form-label nzNoColon>
                  CGHS/AMA's Reference Start Date
                </nz-form-label>
                <nz-form-control>
                  <nz-select
                    name="referencedate"
                    [(ngModel)]="data3.CGHS_AMA_REFERENCE_DATE"
                    placeHolder="Enter CGHS/AMA's Reference Start Date"
                    nzMode="tags"
                    (keypress)="dateOmit($event)"
                    (ngModelChange)="checkDateDiff()"
                  >
                    <nz-option
                      *ngFor="let option of data3.CGHS_AMA_REFERENCE_DATE"
                      [nzLabel]="option"
                      [nzValue]="option"
                    ></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="18">
              <nz-form-item>
                <nz-form-label nzNoColon>
                  CGHS/AMA's Reference End Date
                </nz-form-label>
                <nz-form-control>
                  <nz-select
                    name="referenceenddate"
                    [(ngModel)]="data3.CGHS_AMA_REFERENCE_END_DATE"
                    placeHolder="Enter CGHS/AMA's Reference End Date"
                    nzMode="tags"
                    (keypress)="dateOmit($event)"
                    (ngModelChange)="checkDateDiff()"
                  >
                    <nz-option
                      *ngFor="let option of data3.CGHS_AMA_REFERENCE_END_DATE"
                      [nzLabel]="option"
                      [nzValue]="option"
                    ></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div nz-row>
            <div
              nz-col
              nzSpan="10"
              *ngIf="data3.REFERENCE_FORM_CGHS_AMA_TAKEN == true"
            >
              <nz-form-item>
                <nz-form-label nzNoColon>
                  CGHS/AMA's Reference Number
                </nz-form-label>
                <nz-form-control
                  nzHasFeedback
                  nzErrorTip="please Enter Valid CGHS/AMA's Reference Number "
                >
                  <input
                    nz-input
                    name="referencenumber"
                    maxlength="64"
                    [(ngModel)]="data3.CGHS_AMA_REFERENCE_NO"
                    placeHolder="Enter CGHS/AMA's Reference Number"
                    required
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <!-- <div>
          <button
      type="button"
      nz-button
      (click)="ADD(CGHS_AMA_REFERENCE_DATE)"
      class="ant-btn"
      style="margin-right: 8px"
    >
    </button>
  </div> -->

          <div nz-row>
            <div nz-col nzSpan="16">
              <div>Whether Hospital Is Empanelled?</div>
            </div>
            <div nz-col nzSpan="8">
              <nz-form-item>
                <!-- <nz-form-label nzRequired nzNoColon>Whether Hospital Is Emplanelled?</nz-form-label> -->
                <nz-form-control>
                  <nz-switch
                    [(ngModel)]="data3.IS_HOSPITAL_EMPLANELLED"
                    name="empanelledhospital"
                    nzCheckedChildren="Yes"
                    [disabled]="true"
                    nzUnCheckedChildren="No"
                  >
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div nz-row *ngIf="data3.EMERGENCY_TREATEMENT == true">
            <div nz-col nzSpan="16">
              <div>Whether Doctor Certificate For Emergency Taken ?</div>
            </div>
            <div nz-col nzSpan="8">
              <nz-form-item>
                <!-- <nz-form-label nzRequired nzNoColon>Whether Hospital Is Emplanelled?</nz-form-label> -->
                <nz-form-control>
                  <nz-switch
                    [(ngModel)]="data3.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN"
                    name="doctorcertificate"
                    nzCheckedChildren="Yes"
                    nzUnCheckedChildren="No"
                    (ngModelChange)="doctorSwitch($event)"
                  >
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div
            nz-row
            *ngIf="
              data3.IS_HOSPITAL_EMPLANELLED == false &&
              data3.REFERENCE_FORM_CGHS_AMA_TAKEN == true
            "
          >
            <div nz-col nzSpan="16">
              <div>Whether Permission From HOD Taken?</div>
            </div>
            <div nz-col nzSpan="8">
              <nz-form-item>
                <!-- <nz-form-label nzRequired nzNoColon>Whether Hospital Is Emplanelled?</nz-form-label> -->
                <nz-form-control>
                  <nz-switch
                    [(ngModel)]="data3.IS_PERMISSION_TAKEN_FROM_HOD"
                    name="hodpermission"
                    nzCheckedChildren="Yes"
                    nzUnCheckedChildren="No"
                    (ngModelChange)="onChangeHOD($event)"
                  >
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div nz-row>
            <div
              nz-col
              nzSpan="6"
              *ngIf="data3.IS_PERMISSION_TAKEN_FROM_HOD == true"
            >
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>
                  HOD Permission Date
                </nz-form-label>
                <nz-form-control nzErrorTip="Select Correct Date">
                  <nz-date-picker
                    style="width: 100%"
                    nzFormat="dd/MM/yyyy"
                    name="Permissiondate"
                    [(ngModel)]="data3.HOD_PERMISSION_DATE"
                    required
                  ></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div
              nz-col
              nzSpan="12"
              *ngIf="data3.IS_PERMISSION_TAKEN_FROM_HOD == true"
            >
              <nz-form-item>
                <nz-form-label nzNoColon> HOD Permission Number </nz-form-label>
                <nz-form-control
                  nzHasFeedback
                  nzErrorTip="Please Enter Valid HOD Permission Number"
                >
                  <input
                    nz-input
                    name="Permissionnumber "
                    maxlength="32"
                    [(ngModel)]="data3.HOD_PERMISSION_NO"
                    placeHolder="Enter HOD Permission Number"
                    required
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div nz-row *ngIf="data3.IS_PERMISSION_TAKEN_FROM_HOD == true">
            <div nz-col nzSpan="12">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon
                  >Select HOD Permission</nz-form-label
                >

                <nz-form-control nzErrorTip="Please Select HOD Permission">
                  <nz-select
                    [nzOptionHeightPx]="60"
                    nzShowSearch
                    nzAllowClear
                    nzPlaceHolder="Select HOD Permission"
                    name="hodID"
                    [(ngModel)]="data3.HOD_ID"
                    required
                    nzMode="multiple"
                  >
                    <nz-option
                      *ngFor="let role of HODPermissionList"
                      [nzValue]="role.ID"
                      nzLabel="{{ role.EMPLOYEE_NAME }} - {{
                        role.EMPLOYEE_CODE
                      }}"
                      nzCustomContent
                    >
                      <div style="background-color: #fffcfc; padding: 5px">
                        <div nz-row>
                          <div
                            nz-col
                            nzSpan="24"
                            style="width: 100%; color: black; font-size: 14px"
                          >
                            <b
                              >{{ role.EMPLOYEE_NAME }}
                              <small style="position: absolute; right: 0px">{{
                                role.EMPLOYEE_CODE
                              }}</small></b
                            >
                          </div>

                          <div nz-col nzSpan="24" style="font-size: 11px">
                            <div nz-row>
                              <div
                                style="color: #b30062; font-weight: bold"
                                nz-col
                                nzSpan="12"
                              >
                                {{ role.OFFICE_NAME }}
                              </div>

                              <div nz-col nzSpan="1"></div>

                              <div
                                style="color: #2f08c0; font-weight: bold"
                                nz-col
                                nzSpan="11"
                              >
                                {{ role.DESIGNATION }}
                              </div>
                            </div>
                          </div>

                          <div
                            nz-col
                            nzSpan="24"
                            style="font-size: 14px; font-weight: 500"
                          >
                            {{ role.DDO_OF_THE_OFFICIAL }}
                          </div>
                          <div
                            nz-col
                            nzSpan="24"
                            style="font-size: 14px; font-weight: 500"
                          >
                            <span style="color: #1890ff"
                              >Relationship With Patient :</span
                            >
                            &nbsp;
                            {{ role.RELATION_WITH_PATIENT }}
                          </div>
                        </div>
                      </div>
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="12">
              <nz-form-item>
                <nz-form-label nzNoColon></nz-form-label>
                <nz-form-control>
                  <button
                    nz-button
                    nzType="primary"
                    (click)="genHODPermission()"
                  >
                    Generate HOD Permission
                  </button>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <!-- <div
              nz-row
              *ngIf="data3.IS_PERMISSION_TAKEN_FROM_HOD == true"
              style="margin-top: 10px; margin-bottom: 10px"
            >
              <div nz-col nzSpan="12">
                <nz-form-item>
                  <nz-form-control>
                    <button
                      nz-button
                      nzType="primary"
                      (click)="refHODOrderSheet()"
                    >
                      HOD Order Sheet
                    </button>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="12">
                <nz-form-item>
                  <nz-form-control>
                    <button
                      nz-button
                      nzType="primary"
                      (click)="refHODPermission()"
                    >
                      HOD Permission Letter
                    </button>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div> -->

          <div
            nz-row
            *ngIf="
              (data3.EMERGENCY_TREATEMENT == true &&
                data3.DOCTOR_EMERGENCY_CERTIFICATE_TAKEN == false) ||
              (data3.EMERGENCY_TREATEMENT == false &&
                data3.REFERENCE_FORM_CGHS_AMA_TAKEN == true &&
                data3.IS_HOSPITAL_EMPLANELLED == false &&
                data3.IS_PERMISSION_TAKEN_FROM_HOD == false) ||
              (data3.EMERGENCY_TREATEMENT == false &&
                data3.REFERENCE_FORM_CGHS_AMA_TAKEN == false) ||
              (data3.EMERGENCY_TREATEMENT == false &&
                data3.REFERENCE_FORM_CGHS_AMA_TAKEN == true &&
                expoDiffDays > 30)
            "
          >
            <div nz-col nzSpan="16">
              <div>Whether Ex-Post Facto Permission Taken?</div>
            </div>
            <div nz-col nzSpan="8">
              <nz-form-item>
                <!-- <nz-form-label nzRequired nzNoColon>Whether Hospital Is Emplanelled?</nz-form-label> -->
                <nz-form-control>
                  <nz-switch
                    [(ngModel)]="data3.EXPO_FACTO_PERMISSION"
                    name="EXPOSTFACTOPERMISSION"
                    nzCheckedChildren="Yes"
                    nzUnCheckedChildren="No"
                    (ngModelChange)="onChangeExpoFacto($event)"
                  >
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div nz-row>
            <div nz-col nzSpan="6" *ngIf="data3.EXPO_FACTO_PERMISSION == true">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>
                  Ex-Post Facto Referance Date
                </nz-form-label>
                <nz-form-control nzErrorTip="Select Correct Date">
                  <nz-date-picker
                    style="width: 100%"
                    nzFormat="dd/MM/yyyy"
                    name="expostdate"
                    [(ngModel)]="data3.EXPO_FACTO_DATE"
                    required
                  ></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="12" *ngIf="data3.EXPO_FACTO_PERMISSION == true">
              <nz-form-item>
                <nz-form-label nzNoColon>
                  Ex-Post Facto Referance Number
                </nz-form-label>
                <nz-form-control
                  nzHasFeedback
                  nzErrorTip="Please Enter Valid Ex-Post Facto Referance Number"
                >
                  <input
                    nz-input
                    name="referancedateno"
                    maxlength="32"
                    [(ngModel)]="data3.EXPO_FACTO_REFERENCE_NO"
                    placeHolder="Enter Ex-Post Facto Referance Number"
                    required
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <!-- <div
              nz-row
              *ngIf="data3.EXPO_FACTO_PERMISSION == true"
              style="margin-top: 10px; margin-bottom: 10px"
            >
              <div nz-col nzSpan="12">
                <nz-form-item>
                  <nz-form-control>
                    <button
                      nz-button
                      nzType="primary"
                      (click)="refExpoOrderSheet()"
                    >
                      Referance Expo Order Sheet
                    </button>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="12">
                <nz-form-item>
                  <nz-form-control>
                    <button
                      nz-button
                      nzType="primary"
                      (click)="refExpoPermissionLetter()"
                    >
                      Referance Expo Permission Letter
                    </button>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div> -->
          <div nz-row *ngIf="data3.EXPO_FACTO_PERMISSION == true">
            <div nz-col nzSpan="12">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon
                  >Select Expost Facto Permission</nz-form-label
                >

                <nz-form-control
                  nzErrorTip="Please Select Expost Facto Permission"
                >
                  <nz-select
                    [nzOptionHeightPx]="60"
                    nzShowSearch
                    nzAllowClear
                    nzPlaceHolder="Select Expost Facto Permission"
                    name="expostID"
                    [(ngModel)]="data3.EXPOST_FACTO_ID"
                    required
                  >
                    <nz-option
                      *ngFor="let role of expostFactoList"
                      [nzValue]="role.ID"
                      nzLabel="{{ role.EMPLOYEE_NAME }} - {{
                        role.EMPLOYEE_CODE
                      }}"
                      nzCustomContent
                    >
                      <div style="background-color: #fffcfc; padding: 5px">
                        <div nz-row>
                          <div
                            nz-col
                            nzSpan="24"
                            style="width: 100%; color: black; font-size: 14px"
                          >
                            <b
                              >{{ role.EMPLOYEE_NAME }}
                              <small style="position: absolute; right: 0px">{{
                                role.EMPLOYEE_CODE
                              }}</small></b
                            >
                          </div>

                          <div nz-col nzSpan="24" style="font-size: 11px">
                            <div nz-row>
                              <div
                                style="color: #b30062; font-weight: bold"
                                nz-col
                                nzSpan="12"
                              >
                                {{ role.OFFICE_NAME }}
                              </div>

                              <div nz-col nzSpan="1"></div>

                              <div
                                style="color: #2f08c0; font-weight: bold"
                                nz-col
                                nzSpan="11"
                              >
                                {{ role.DESIGNATION }}
                              </div>
                            </div>
                          </div>

                          <div
                            nz-col
                            nzSpan="24"
                            style="font-size: 14px; font-weight: 500"
                          >
                            {{ role.DDO_OF_THE_OFFICIAL }}
                          </div>
                          <div
                            nz-col
                            nzSpan="24"
                            style="font-size: 14px; font-weight: 500"
                          >
                            <span style="color: #1890ff"
                              >Relationship With Patient :</span
                            >
                            &nbsp;
                            {{ role.RELATION_WITH_PATIENT }}
                          </div>
                        </div>
                      </div>
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="12">
              <nz-form-item>
                <nz-form-label nzNoColon></nz-form-label>
                <nz-form-control>
                  <button nz-button nzType="primary" (click)="genExpostFacto()">
                    Generate Expost Facto Permission
                  </button>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div nz-row>
            <div nz-col nzSpan="16">
              <div>Whether The Bill Filed In Time ?</div>
            </div>
            <div nz-col nzSpan="8">
              <nz-form-item>
                <!-- <nz-form-label nzRequired nzNoColon>Whether Hospital Is Emplanelled?</nz-form-label> -->
                <nz-form-control>
                  <nz-switch
                    [disabled]="true"
                    [(ngModel)]="data3.BILL_FILLED_INTIME"
                    name="billintime"
                    nzCheckedChildren="Yes"
                    nzUnCheckedChildren="No"
                  >
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div nz-row>
            <div nz-col nzSpan="6" *ngIf="data3.BILL_FILLED_INTIME == false">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>
                  Delay Condolence Date
                </nz-form-label>
                <nz-form-control nzErrorTip="Select Correct Date">
                  <nz-date-picker
                    style="width: 100%"
                    nzFormat="dd/MM/yyyy"
                    name="delaycondolencedate"
                    [(ngModel)]="data3.DELAY_CONDOLENCE_DATE"
                    required
                  ></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div nz-row *ngIf="data3.BILL_FILLED_INTIME == false">
            <div nz-col nzSpan="12">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon
                  >Select Delay Condonation Permission</nz-form-label
                >

                <nz-form-control
                  nzErrorTip="Please Select Delay Condonation Permission"
                >
                  <nz-select
                    [nzOptionHeightPx]="60"
                    nzShowSearch
                    nzAllowClear
                    nzPlaceHolder="Select Delay Condonation Permission"
                    name="delayID"
                    [(ngModel)]="data3.DELAY_CONDOLATION_ID"
                    required
                  >
                    <nz-option
                      *ngFor="let role of delayCondolationList"
                      [nzValue]="role.ID"
                      nzLabel="{{ role.EMPLOYEE_NAME }} - {{
                        role.EMPLOYEE_CODE
                      }}"
                      nzCustomContent
                    >
                      <div style="background-color: #fffcfc; padding: 5px">
                        <div nz-row>
                          <div
                            nz-col
                            nzSpan="24"
                            style="width: 100%; color: black; font-size: 14px"
                          >
                            <b
                              >{{ role.EMPLOYEE_NAME }}
                              <small style="position: absolute; right: 0px">{{
                                role.EMPLOYEE_CODE
                              }}</small></b
                            >
                          </div>

                          <div nz-col nzSpan="24" style="font-size: 11px">
                            <div nz-row>
                              <div
                                style="color: #b30062; font-weight: bold"
                                nz-col
                                nzSpan="12"
                              >
                                {{ role.OFFICE_NAME }}
                              </div>

                              <div nz-col nzSpan="1"></div>

                              <div
                                style="color: #2f08c0; font-weight: bold"
                                nz-col
                                nzSpan="11"
                              >
                                {{ role.DESIGNATION }}
                              </div>
                            </div>
                          </div>

                          <div
                            nz-col
                            nzSpan="24"
                            style="font-size: 14px; font-weight: 500"
                          >
                            {{ role.DDO_OF_THE_OFFICIAL }}
                          </div>
                          <div
                            nz-col
                            nzSpan="24"
                            style="font-size: 14px; font-weight: 500"
                          >
                            <span style="color: #1890ff"
                              >Relationship With Patient :</span
                            >
                            &nbsp;
                            {{ role.RELATION_WITH_PATIENT }}
                          </div>
                        </div>
                      </div>
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="12">
              <nz-form-item>
                <nz-form-label nzNoColon></nz-form-label>
                <nz-form-control>
                  <button
                    nz-button
                    nzType="primary"
                    (click)="genDelayCondonation()"
                  >
                    Generate Delay Condonation Permission
                  </button>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <!-- <div nz-col nzSpan="12" *ngIf="data3.BILL_FILLED_INTIME == false">
              <nz-form-item>
                <nz-form-label nzNoColon>
                  Delay Condolence Number
                </nz-form-label>
                <nz-form-control
                  nzHasFeedback
                  nzErrorTip="Please Enter Valid Delay Condolence Number"
                >
                  <input
                    nz-input
                    name="delaycondolencenumber "
                    maxlength="64"
                    [(ngModel)]="data3.DELAY_CONDOLENCE_NUMBER"
                    placeHolder="Enter Delay Condolence Number"
                    required
                  />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div
              nz-row
              *ngIf="data3.BILL_FILLED_INTIME == false"
              style="margin-top: 10px; margin-bottom: 10px"
            >
              <div nz-col nzSpan="12">
                <nz-form-item>
                  <nz-form-control>
                    <button
                      nz-button
                      nzType="primary"
                      (click)="delayOrderSheetnew()"
                    >
                      Delay Order Sheet
                    </button>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="12">
                <nz-form-item>
                  <nz-form-control>
                    <button
                      nz-button
                      nzType="primary"
                      (click)="permissionnewLetter()"
                    >
                      Delay Permission Letter
                    </button>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
         </div> -->

          <!-- <div nz-col nzSpan="24">
            <nz-form-item>
              <nz-form-label nzRequired nzNoColon>Whether CGHS/CS (MA) Benificiary?</nz-form-label>
              <nz-form-control>
                <nz-switch [(ngModel)]="AdvanceTaken"  [(ngModel)]="data3.IS_CGHS_OR_CS" name="AdvanceTaken" nzCheckedChildren="Yes"
                  nzUnCheckedChildren="No">
                </nz-switch>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="24" *ngIf="AdvanceTaken == true" >
            <nz-form-item>
              <nz-form-label nzRequired nzNoColon>CGHS Card Number</nz-form-label>
              <nz-form-control nzHasFeedback nzErrorTip="Input is Required">
                  <input nz-input name="cardnumber" type="number" [(ngModel)]="data3.CGHS_CARD_NO"
                    placeHolder="Enter Net Payable Amount" required />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="12" *ngIf="AdvanceTaken == true">
            <nz-form-item>
              <nz-form-label nzRequired nzNoColon>CGHS Card Validity Date</nz-form-label>
              <nz-form-control nzErrorTip="Input is required">
                <nz-date-picker style="width: 100%" nzFormat="dd/MM/yyyy"
                  name="cardvaliditydate" [(ngModel)]="data3.CGHS_CARD_VALIDITY_DATE"></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="12" >
            <nz-form-item>
              <nz-form-label nzRequired nzNoColon> Date of Filing The Bill </nz-form-label>
              <nz-form-control nzErrorTip="Input is required">
                <nz-date-picker style="width: 100%" nzFormat="dd/MM/yyyy"
                  name="Dateoffiling" [(ngModel)]="data3.BILL_FILIING_DATE"></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
              

          <div nz-col nzSpan="24">
            <nz-form-item>
              <nz-form-label nzRequired nzNoColon>Whether The Bill Filed In Time ?</nz-form-label>
              <nz-form-control>
                <nz-switch [(ngModel)]="AdvanceTaken"  [(ngModel)]="data3.BILL_FILLED_INTIME" name="AdvanceTaken" nzCheckedChildren="Yes"
                  nzUnCheckedChildren="No">
                </nz-switch>
              </nz-form-control>
            </nz-form-item>
          </div>
          

        <div nz-col nzSpan="24">
            <nz-form-item>
              <nz-form-label nzRequired nzNoColon>Whether Delay Condolence Permission Taken?</nz-form-label>
              <nz-form-control>
                <nz-switch [(ngModel)]="AdvanceTaken"  [(ngModel)]="data3.IS_DELAY_CONDOLENCE_PERMISSION_TAKEN" name="AdvanceTaken" nzCheckedChildren="Yes"
                  nzUnCheckedChildren="No">
                </nz-switch>
              </nz-form-control>
            </nz-form-item>
          </div> -->

          <!-- <div nz-col nzSpan="16">
            <nz-form-item>
              <nz-form-label nzRequired nzNoColon>Name of Hospital
              </nz-form-label>
              <nz-input-group>
                <nz-select name="Hospitalname" style="width: 100%" nzShowSearch nzPlaceHolder="Select Hospital" [(ngModel)]="data3.HOSPITAL_ID">
                  <nz-option *ngFor="let Hospital of Hospital" [nzValue]="Hospital.ID"
                    [nzLabel]="Hospital.NAME"></nz-option>
                </nz-select>
              </nz-input-group>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="16">
            <nz-form-item>
              <nz-form-label nzRequired nzNoColon>Name of Hospital</nz-form-label>
              <nz-form-control nzErrorTip="Input is required">
                <input nz-input autocomplete="off" name="DDOOfficial" type="text"
                  [(ngModel)]="data3.HOSPITAL_ID" placeHolder="Enter The DDO" required />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="8">
            <nz-form-item>
              <nz-form-label nzRequired nzNoColon>Hospital Type
              </nz-form-label>
              <nz-input-group>
                <nz-select name="HospitalType" style="width: 100%" nzShowSearch nzPlaceHolder="Select Hospital Type"
                [(ngModel)]="data3.TYPE_OF_HOSPITAL">
                  <nz-option [nzValue]="1" [nzLabel]="'Empanelled'"></nz-option>
                  <nz-option [nzValue]="2" [nzLabel]="'Non-Empanelled'"></nz-option>
                  <nz-option [nzValue]="3" [nzLabel]="'Goverment'"></nz-option>
                </nz-select>
              </nz-input-group>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="8">
            <nz-form-item>
              <nz-form-label nzRequired nzNoColon>Hospital Type
              </nz-form-label>
              <nz-input-group>
                <nz-select name="HospitalType" style="width: 100%" nzShowSearch nzPlaceHolder="Select Hospital Type"
                [(ngModel)]="data3.TYPE_OF_HOSPITAL">
                  <nz-option [nzValue]="1" [nzLabel]="'Empanelled'"></nz-option>
                  <nz-option [nzValue]="2" [nzLabel]="'Non-Empanelled'"></nz-option>
                  <nz-option [nzValue]="3" [nzLabel]="'Goverment'"></nz-option>
                </nz-select>
              </nz-input-group>
            </nz-form-item>
          </div> -->
        </nz-spin>
      </form>
    </div>
    <!-- Questionary -->

    <!-- CheckList -->
    <div *ngIf="current == 3">
      <form nz-form #checkListMasterPage="ngForm">
        <nz-spin [nzSpinning]="isSpinning">
          <div nz-col nzSpan="24">
            <!-- <div nz-row class="my-box">
          
            </div> -->
            <nz-divider
              nzText="The checklist of the above mentioned bill is as under:"
            ></nz-divider>
            <div class="my-box">
              <div nz-row>
                <div nz-col nzSpan="15">
                  <nz-form-item>
                    <nz-form-label nzNoColon>File Number</nz-form-label>
                    <nz-form-control nzHasFeedback>
                      <nz-select
                        nzShowSearch
                        nzAllowClear
                        name="FILE_NUMBER"
                        [(ngModel)]="data4.FILE_NO_ID"
                      >
                        <nz-option
                          *ngFor="let option of fileList"
                          [nzLabel]="option.FILE_NUMBER"
                          [nzValue]="option.ID"
                        >
                        </nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
              <div nz-row>
                <div nz-col nzSpan="16">
                  <div>1. Forwarding Letter (Through Propoer Channel)</div>
                </div>
                <div nz-col nzSpan="8">
                  <nz-form-item>
                    <!-- <nz-form-label nzRequired nzNoColon>Whether Emergency Treatment is Availed ?</nz-form-label> -->
                    <nz-form-control>
                      <nz-switch
                        [(ngModel)]="data4.IS_FORWARDING_LETTER"
                        name="forwardletter"
                        nzCheckedChildren="Yes"
                        nzUnCheckedChildren="No"
                      >
                      </nz-switch>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>

              <div nz-row>
                <div nz-col nzSpan="16">
                  <div>
                    2.Medical Reimbursment Claim(MRC) form for (CGHS/CSMA)
                  </div>
                  <!-- <div>for Treatment from the Hospital ( In case of Advance Bill)</div> -->
                </div>
                <div nz-col nzSpan="8">
                  <nz-form-item>
                    <!-- <nz-form-label nzRequired nzNoColon>Whether Emergency Treatment is Availed ?</nz-form-label> -->
                    <nz-form-control>
                      <nz-switch
                        [(ngModel)]="
                          data4.MEDICAL_REIMBURSMENT_CLAIM_FORM_FOR_CGHS
                        "
                        name="medicalreimburstment"
                        nzCheckedChildren="Yes"
                        nzUnCheckedChildren="No"
                      >
                      </nz-switch>
                    </nz-form-control>
                  </nz-form-item>

                  <!-- <div nz-col nzSpan="10" *ngIf="data4.MEDICAL_REIMBURSMENT_CLAIM_FORM_FOR_CGHS=='Y'">
                      <nz-form-item>
                        <nz-form-label nzRequired nzNoColon>Patient Name</nz-form-label>
                        <nz-form-control nzErrorTip="Input is required">
                          <input nz-input autocomplete="off" name="advancebill" type="text" [(ngModel)]="data4.PATIENT_NAME"
                            placeHolder="Enter Advance Bill" required />
                        </nz-form-control>
                      </nz-form-item>
                    </div> -->
                </div>
              </div>

              <div nz-row>
                <div nz-col nzSpan="16">
                  <div>
                    3. Copy of CGHS card for both benificiary and patient
                  </div>
                </div>
                <div nz-col nzSpan="8">
                  <nz-form-item>
                    <!-- <nz-form-label nzRequired nzNoColon>Whether Emergency Treatment is Availed ?</nz-form-label> -->
                    <nz-form-control>
                      <nz-radio-group
                        name="copyofcard"
                        [(ngModel)]="data4.COPY_OF_CGHS_CARD"
                      >
                        <label nz-radio nzValue="Y">Yes </label>
                        <label nz-radio nzValue="N">No</label>
                        <label nz-radio nzValue="NA">NA</label>
                      </nz-radio-group>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>

              <div nz-row>
                <div nz-col nzSpan="16">
                  <div>4. Discharge Card</div>
                </div>
                <div nz-col nzSpan="8">
                  <nz-form-item>
                    <!-- <nz-form-label nzRequired nzNoColon>Whether Emergency Treatment is Availed ?</nz-form-label> -->
                    <nz-form-control>
                      <nz-radio-group
                        name="dischargecard"
                        [(ngModel)]="data4.DISCHARGE_CARD"
                      >
                        <label nz-radio nzValue="Y">Yes </label>
                        <label nz-radio nzValue="N">No</label>
                        <label nz-radio nzValue="NA">NA</label>
                      </nz-radio-group>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>

              <div nz-row>
                <div nz-col nzSpan="16">
                  <div>
                    5. Form No.3 in case the medical claim is of the officials
                    family member for AMA Benificiery
                  </div>
                </div>
                <div nz-col nzSpan="8">
                  <nz-form-item>
                    <!-- <nz-form-label nzRequired nzNoColon>Whether Emergency Treatment is Availed ?</nz-form-label> -->
                    <nz-form-control>
                      <nz-radio-group
                        name="formno3"
                        [(ngModel)]="data4.FORM_NO_3_MEDICAL_CLAIM"
                      >
                        <label nz-radio nzValue="Y">Yes </label>
                        <label nz-radio nzValue="N">No</label>
                        <label nz-radio nzValue="NA">NA</label>
                      </nz-radio-group>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>

              <div nz-row>
                <div nz-col nzSpan="16">
                  <div>
                    6.Final bill in original along with one Xerox copy
                    <!-- (Exstimate in case of Advance) -->
                  </div>
                </div>
                <div nz-col nzSpan="8">
                  <nz-form-control>
                    <nz-switch
                      name="finalbill"
                      [(ngModel)]="data4.FINAL_BILL_ORIGINAL_ALONG_WITH_XEROX"
                      nzCheckedChildren="Yes"
                      nzUnCheckedChildren="No"
                    >
                    </nz-switch>
                  </nz-form-control>
                </div>
              </div>

              <div nz-row>
                <div nz-col nzSpan="16">
                  <div>7. Payslip of The Period of Treatment</div>
                </div>
                <div nz-col nzSpan="8">
                  <nz-form-control>
                    <nz-switch
                      name="payslip"
                      [(ngModel)]="data4.PAYSLIP_OF_PERIOD_OF_TREATMENT"
                      nzCheckedChildren="Yes"
                      nzUnCheckedChildren="No"
                    >
                    </nz-switch>
                  </nz-form-control>
                </div>
              </div>

              <div nz-row>
                <div nz-col nzSpan="16">
                  <div>
                    8. Bank mandate form along with cancelled cheque
                    <!-- (of the Hospital) -->
                  </div>
                </div>
                <div nz-col nzSpan="8">
                  <nz-form-control>
                    <nz-switch
                      name="cancelchecque"
                      [(ngModel)]="
                        data4.BANK_MANDATE_FORM_ALONG_WITH_CANCELLED_CHEQUE
                      "
                      nzCheckedChildren="Yes"
                      nzUnCheckedChildren="No"
                    >
                    </nz-switch>
                  </nz-form-control>
                </div>
              </div>

              <div nz-row>
                <div nz-col nzSpan="16">
                  <div>9. Prescription of Medicines Purchased From Outside</div>
                </div>
                <div nz-col nzSpan="8">
                  <nz-form-control>
                    <nz-radio-group
                      name="medicines"
                      [(ngModel)]="
                        data4.PRESCIPTION_OF_MEDICINES_PURSCHASED_OUTSIDE
                      "
                    >
                      <label nz-radio nzValue="Y">Yes </label>
                      <label nz-radio nzValue="N">No</label>
                      <label nz-radio nzValue="NA">NA</label>
                    </nz-radio-group>
                    <!-- <nz-switch name="medicines" [(ngModel)]="data4.PRESCIPTION_OF_MEDICINES_PURSCHASED_OUTSIDE"
                  nzCheckedChildren="Yes" nzUnCheckedChildren="No">
                </nz-switch> -->
                  </nz-form-control>
                </div>
              </div>
            </div>
          </div>

          <div class="my-box">
            <div nz-col nzSpan="12" style="margin-left: 10px">
              <nz-form-item>
                <nz-form-label nzNoColon>
                  <h3>Claim Confirmation</h3>
                </nz-form-label>
                <nz-form-control>
                  <!-- <nz-radio-group name="status" style="margin-left: 10px;" [(ngModel)]="radioval" required>
              <label nz-radio nzValue="A" style="color:green;">Accept</label>
              <label nz-radio nzValue="R" style="color:red;">Reject</label>
            </nz-radio-group> -->
                  <nz-radio-group
                    name="checkstatus"
                    style="margin-left: 10px"
                    [(ngModel)]="data4.CHECKLIST_STATUS"
                    (ngModelChange)="clearRemark($event)"
                    required
                  >
                    <label nz-radio nzValue="A" style="color: green"
                      >Accept Claim</label
                    >

                    <label nz-radio nzValue="R" style="color: red"
                      >Reject Claim</label
                    >
                    <label nz-radio nzValue="H" style="color: black"
                      >On Hold</label
                    >
                  </nz-radio-group>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="24" *ngIf="data4.CHECKLIST_STATUS == 'R'">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon
                  >Reject Remark</nz-form-label
                >
                <nz-form-control
                  nzHasFeedback
                  nzErrorTip="Please Enter Valid Reject Remark "
                >
                  <textarea
                    name="remark"
                    style="width: 100%"
                    [(ngModel)]="data4.REJECT_REMARK"
                    nz-input
                    [nzAutosize]="{ minRows: 2, maxRows: 4 }"
                    maxlength="256"
                    placeholder="Please Enter Reject Remark"
                    draggable="false"
                    required
                  ></textarea>
                  <h6
                    style="color: red"
                    *ngIf="data4.REJECT_REMARK != undefined"
                  >
                    {{ data4.REJECT_REMARK.length }}/256
                  </h6>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="6" *ngIf="data4.CHECKLIST_STATUS == 'H'">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon>
                  On Hold Date
                </nz-form-label>
                <nz-form-control nzErrorTip="Select Correct On Hold Date">
                  <nz-date-picker
                    style="width: 100%"
                    nzFormat="dd/MM/yyyy"
                    name="onholddate"
                    [(ngModel)]="data4.ON_HOLD_DATE"
                    required
                  ></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="24" *ngIf="data4.CHECKLIST_STATUS == 'H'">
              <nz-form-item>
                <nz-form-label nzRequired nzNoColon
                  >On Hold Remark</nz-form-label
                >
                <nz-form-control
                  nzHasFeedback
                  nzErrorTip="Please Enter Valid On Hold Remark "
                >
                  <textarea
                    name="onholdremark"
                    style="width: 100%"
                    [(ngModel)]="data4.ON_HOLD_REMARK"
                    nz-input
                    [nzAutosize]="{ minRows: 2, maxRows: 4 }"
                    maxlength="256"
                    placeholder="Please Enter On Hold Remark"
                    draggable="false"
                    required
                  ></textarea>
                  <h6
                    style="color: red"
                    *ngIf="data4.ON_HOLD_REMARK != undefined"
                  >
                    {{ data4.ON_HOLD_REMARK.length }}/256
                  </h6>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </nz-spin>
      </form>
    </div>
  </div>
  <!-- CheckList -->

  <div class="footer">
    <button
      [nzLoading]="isSpinning"
      nz-button
      nzType="default"
      (click)="pre()"
      *ngIf="current > 0"
      style="margin-right: 8px"
    >
      <span>Previous</span>
    </button>

    <button
      [nzLoading]="isSpinning"
      *ngIf="current < 3 && data.ID && (userId == 1 || userId == 48)"
      type="button"
      class="ant-btn ant-btn-primary"
      nz-button
      (click)="next()"
    >
      <span> Next </span>
    </button>

    <span>
      <button
        [nzLoading]="isSpinning"
        *ngIf="current == 0 && userId != 1 && userId != 48"
        nzType="primary"
        class="ant-btn ant-btn-primary"
        nz-button
        (click)="empSave(false, claimMasterPage)"
      >
        <span>Save Applicant Info. & Next </span>
      </button>
    </span>
    <!-- <span *ngIf="data.ID">
      <button [nzLoading]="isSpinning"  type="button" class="ant-btn ant-btn-primary" nz-button
      (click)="next()">
      <span>Next </span>
    </button>
  </span> -->
    <!-- <button nz-button nzType="default" (click)="pre()" *ngIf="current > 1"  style="margin-right: 8px">
            <span>Previous</span>
          </button> -->
    <span>
      <button
        [nzLoading]="isSpinning"
        *ngIf="current == 1 && userId != 1 && userId != 48"
        nzType="primary"
        class="ant-btn ant-btn-primary"
        nz-button
        (click)="claimSave(false)"
      >
        <span>Save Claim Info. & Next </span>
      </button>
    </span>
    <!-- <button nz-button nzType="default" (click)="pre()" *ngIf="current > 2"  style="margin-right: 8px">
            <span>Previous</span>
          </button> -->
    <span>
      <button
        [nzLoading]="isSpinning"
        *ngIf="current == 2 && userId != 1 && userId != 48"
        nzType="primary"
        class="ant-btn ant-btn-primary"
        nz-button
        (click)="queSave(false)"
      >
        <span>Save Questionary & Next </span>
      </button>
    </span>

    <span>
      <button
        [nzLoading]="isSpinning"
        *ngIf="current == 3 && userId != 1 && userId != 48"
        nzType="primary"
        class="ant-btn ant-btn-primary"
        nz-button
        (click)="checkSave(false)"
      >
        <span>Save & Finish</span>
      </button>
    </span>
    <button
      type="button"
      nz-button
      (click)="close()"
      class="ant-btn"
      style="margin-right: 8px"
    >
      <span>Cancel</span>
    </button>
  </div>
  <!-- <div class="header">
    <button type="button" nz-button (click)="close(applicantMasterPages,claimMasterPage,queMasterPage,checkListMasterPage)" class="ant-btn"
    style="margin-right: 8px;"><i nz-icon nzType="close" nzTheme="outline"></i></button>        
  </div> -->
  <div class="header">
    <button
      type="button"
      nz-button
      (click)="close()"
      class="ant-btn"
      style="margin-right: 8px"
    >
      <i nz-icon nzType="close" nzTheme="outline"></i>
    </button>
  </div>
</div>

<div *ngIf="empDrawerVisible">
  <nz-drawer
    [nzBodyStyle]="{
      height: 'calc(100% - 55px)',
      overflow: 'auto',
      'padding-bottom': '53px'
    }"
    [nzClosable]="false"
    [nzMask]="true"
    [nzMaskClosable]="false"
    [nzWidth]="700"
    [nzVisible]="empDrawerVisible"
    [nzTitle]="drawerTitle"
    (nzOnClose)="empDrawerClose()"
    (nzKeyboard)="(true)"
  >
    <app-addclaimemployee
      [drawerClose]="closeCallback"
      [data]="drawerData"
    ></app-addclaimemployee>
  </nz-drawer>
</div>

<div *ngIf="orderSheetVisible">
  <nz-drawer
    [nzBodyStyle]="{
      height: 'calc(100% - 55px)',
      overflow: 'auto',
      'padding-bottom': '53px'
    }"
    [nzClosable]="false"
    [nzMask]="true"
    [nzMaskClosable]="false"
    [nzWidth]="1100"
    [nzVisible]="orderSheetVisible"
    [nzTitle]="orderSheetTitle"
    (nzOnClose)="orderSheetClose()"
    (nzKeyboard)="(true)"
  >
    <!-- <app-refexpoorder
    [drawerClose]="orderSheetCloseCallback"
    [data]="drawerData"
    [orderValue]="orderValue"
  ></app-refexpoorder> -->
    <app-refexpoorder
      [drawerClose]="orderSheetCloseCallback"
    ></app-refexpoorder>
  </nz-drawer>
</div>

<div *ngIf="refhodorderVisible">
  <nz-drawer
    [nzBodyStyle]="{
      height: 'calc(100% - 55px)',
      overflow: 'auto',
      'padding-bottom': '53px'
    }"
    [nzClosable]="false"
    [nzMask]="true"
    [nzMaskClosable]="false"
    [nzWidth]="1200"
    [nzVisible]="refhodorderVisible"
    [nzTitle]="refhodorderSheetTitle"
    (nzOnClose)="refhodorderSheetClose()"
    (nzKeyboard)="(true)"
  >
    <!-- <app-refhodorder
    [drawerClose]="delayOrderSheetCloseCallback"
    [data]="drawerData"
  ></app-refhodorder> -->
    <!-- <ng-container *nzDrawerContent> -->
    <app-refhodorder
      [drawerClose]="refhodorderSheetCloseCallback"
      [orderdata]="orderdata1"
      [HospitalMapping]="HospitalMapping"
      [queData]="queData"
      [empID]="empID1"
      [claimID]="claimID1"
      [SECTION_TYPE]="SECTION_TYPE"
    ></app-refhodorder>
    <!-- </ng-container> -->
  </nz-drawer>
</div>

<div *ngIf="refhodPermissionVisible">
  <nz-drawer
    [nzBodyStyle]="{
      height: 'calc(100% - 55px)',
      overflow: 'auto',
      'padding-bottom': '53px'
    }"
    [nzClosable]="false"
    [nzMask]="true"
    [nzMaskClosable]="false"
    [nzWidth]="1200"
    [nzVisible]="refhodPermissionVisible"
    [nzTitle]="refhodPermissionSheetTitle"
    (nzOnClose)="refhodPermissionSheetClose()"
    (nzKeyboard)="(true)"
  >
    <app-refhodpermission
      [drawerClose]="refhodPermissionSheetCloseCallback"
      [orderdata]="orderdata1"
      [HospitalMapping]="HospitalMapping"
      [queData]="queData"
      [empID]="empID1"
      [claimID]="claimID1"
      [SECTION_TYPE]="SECTION_TYPE"
      [NAME]="NAME"
      [NAME_HN]="NAME_HN"
      [OFFICE_NAME]="OFFICE_NAME"
      [OFFICE_NAME_HN]="OFFICE_NAME_HN"
      [POST]="POST"
      [POST_HN]="POST_HN"
    ></app-refhodpermission>
    <!-- <ng-container *nzDrawerContent> -->
    <!-- <app-refhodpermission
    [drawerClose1]="delayPermissionCloseCallback"
    [orderdata1]="orderdata1"
    [HospitalMapping]="HospitalMapping"
    [delayPermissionData]="delayPermissionData"
  ></app-refhodpermission> -->
    <!-- </ng-container> -->
  </nz-drawer>
</div>

<div *ngIf="delayOrderSheetnewVisible">
  <nz-drawer
    [nzBodyStyle]="{
      height: 'calc(100% - 55px)',
      overflow: 'auto',
      'padding-bottom': '53px'
    }"
    [nzClosable]="false"
    [nzMask]="true"
    [nzMaskClosable]="false"
    [nzWidth]="1150"
    [nzVisible]="delayOrderSheetnewVisible"
    [nzTitle]="delayOrderSheetnewTitle"
    (nzOnClose)="delayOrderSheetnewClose()"
    (nzKeyboard)="(true)"
  >
    <!-- <app-delaycondolenceorder
  [drawerClose]="delayOrderSheetCloseCallback"
  [data]="drawerData"
></app-delaycondolenceorder> -->
    <!-- <ng-container *nzDrawerContent> -->
    <app-delaycondolenceorder
      [drawerClose]="delayOrderSheetCloseCallback"
      [orderdata1]="orderdata1"
      [HospitalMapping]="HospitalMapping"
      [queData]="queData"
      [SECTION_TYPE]="SECTION_TYPE"
      [sixMonthDate]="sixMonthDate"
      [dateBetweenDiff]="dateBetweenDiff"
    ></app-delaycondolenceorder>
    <!-- </ng-container> -->
  </nz-drawer>
</div>

<div *ngIf="delayPermissionVisiblenew">
  <nz-drawer
    [nzBodyStyle]="{
      height: 'calc(100% - 55px)',
      overflow: 'auto',
      'padding-bottom': '53px'
    }"
    [nzClosable]="false"
    [nzMask]="true"
    [nzMaskClosable]="false"
    [nzWidth]="1200"
    [nzVisible]="delayPermissionVisiblenew"
    [nzTitle]="delayPermissionTitlenew"
    (nzOnClose)="delayPermissionClosenew()"
    (nzKeyboard)="(true)"
  >
    <app-delaycondolencepermission
      [orderdata]="orderdata1"
      [queData]="queData"
      [HospitalMapping]="HospitalMapping"
      [SECTION_TYPE]="SECTION_TYPE"
      [SIGNNAME]="NAME"
      [NAME_HN]="NAME_HN"
      [OFFICE_NAME]="OFFICE_NAME"
      [OFFICE_NAME_HN]="OFFICE_NAME_HN"
      [POST]="POST"
      [POST_HN]="POST_HN"
      [drawerClose]="delayPermissionCloseCallback"
      [dateBetweenDiff]="dateBetweenDiff"
    ></app-delaycondolencepermission>
    <!-- <ng-container *nzDrawerContent> -->
    <!-- <app-delaycondolencepermission
  [drawerClose1]="delayPermissionCloseCallback"
  [orderdata1]="orderdata1"
  [HospitalMapping]="HospitalMapping"
  [delayPermissionData]="delayPermissionData"
></app-delaycondolencepermission> -->
    <!-- </ng-container> -->
  </nz-drawer>
</div>

<div *ngIf="refExpoOrderVisible">
  <nz-drawer
    [nzBodyStyle]="{
      height: 'calc(100% - 55px)',
      overflow: 'auto',
      'padding-bottom': '53px'
    }"
    [nzClosable]="false"
    [nzMask]="true"
    [nzMaskClosable]="false"
    [nzWidth]="1200"
    [nzVisible]="refExpoOrderVisible"
    [nzTitle]="refExpoOrderTitle"
    (nzOnClose)="refExpoOrderClose()"
    (nzKeyboard)="(true)"
  >
    <app-refexpoorder
      [drawerClose]="refExpoOrderCloseCallback"
      [orderdata]="orderdata1"
      [HospitalMapping]="HospitalMapping"
      [queData]="queData"
      [empID]="empID1"
      [claimID]="claimID1"
      [SECTION_TYPE]="SECTION_TYPE"
    ></app-refexpoorder>
    <!-- <ng-container *nzDrawerContent> -->
    <!-- <app-refexpoorder
  [drawerClose1]="refExpoOrderCloseCallback"
  [orderdata1]="orderdata1"
  [HospitalMapping]="HospitalMapping"
  [delayPermissionData]="delayPermissionData"
></app-refexpoorder> -->
    <!-- </ng-container> -->
  </nz-drawer>
</div>

<div *ngIf="refExpoPermissionLetterVisible">
  <nz-drawer
    [nzBodyStyle]="{
      height: 'calc(100% - 55px)',
      overflow: 'auto',
      'padding-bottom': '53px'
    }"
    [nzClosable]="false"
    [nzMask]="true"
    [nzMaskClosable]="false"
    [nzWidth]="1200"
    [nzVisible]="refExpoPermissionLetterVisible"
    [nzTitle]="refExpoPermissionLetterTitle"
    (nzOnClose)="refExpoPermissionLetterClose()"
    (nzKeyboard)="(true)"
  >
    <app-refexpopermission
      [drawerClose]="refExpoPermissionLetterCloseCallback"
      [orderdata]="orderdata1"
      [HospitalMapping]="HospitalMapping"
      [queData]="queData"
      [empID]="empID1"
      [claimID]="claimID1"
      [SECTION_TYPE]="SECTION_TYPE"
      [NAME]="NAME"
      [NAME_HN]="NAME_HN"
      [OFFICE_NAME]="OFFICE_NAME"
      [OFFICE_NAME_HN]="OFFICE_NAME_HN"
      [POST]="POST"
      [POST_HN]="POST_HN"
      [isSpinning]="isRefExpoPermission"
    ></app-refexpopermission>
    <!-- <ng-container *nzDrawerContent> -->
    <!-- <app-refexpopermission></app-refexpopermission> -->
    <!-- </ng-container> -->
  </nz-drawer>
</div>

<div *ngIf="delayCondonationVisible">
  <nz-drawer
    [nzBodyStyle]="{
      height: 'calc(100% - 55px)',
      overflow: 'auto',
      'padding-bottom': '53px'
    }"
    [nzClosable]="true"
    [nzMask]="true"
    [nzMaskClosable]="false"
    [nzWidth]="1200"
    [nzVisible]="delayCondonationVisible"
    (nzOnClose)="delayCondonationDrawerClose()"
    (nzKeyboard)="(true)"
  >
    <!-- <app-generatedelaycondonationlist   
  ></app-generatedelaycondonationlist> -->
    <!-- <ng-container *nzDrawerContent> -->
    <app-generatedelaycondonationlist
      [empID]="empID"
      [totalRecords]="totalRecords"
      [dataList]="dataList"
      [loadingRecords]="delayLoadingRecords"
    ></app-generatedelaycondonationlist>
    <!-- </ng-container> -->
  </nz-drawer>
</div>

<div *ngIf="HODPermissionVisible">
  <nz-drawer
    [nzBodyStyle]="{
      height: 'calc(100% - 55px)',
      overflow: 'auto',
      'padding-bottom': '53px'
    }"
    [nzClosable]="true"
    [nzMask]="true"
    [nzMaskClosable]="false"
    [nzWidth]="1200"
    [nzVisible]="HODPermissionVisible"
    (nzOnClose)="HODPermissionDrawerClose()"
    (nzKeyboard)="(true)"
  >
    <!-- <app-generatehodlist
    [drawerClose]="delayCondonationCloseCallback"
    [orderdata]="orderdata1"
    [HospitalMapping]="HospitalMapping"
    [queData]="queData"
    [empID]="empID1"
    [claimID]="claimID1"
    [SECTION_TYPE]="SECTION_TYPE"
    [NAME]="NAME"
    [NAME_HN]="NAME_HN"
    [OFFICE_NAME]="OFFICE_NAME"
    [OFFICE_NAME_HN]="OFFICE_NAME_HN"
    [POST]="POST"
    [POST_HN]="POST_HN"
    [isSpinning]="isRefExpoPermission"
  ></app-generatehodlist> -->
    <!-- <ng-container *nzDrawerContent> -->
    <app-generatehodlist
      [empID]="HODPermissionID"
      [totalRecords]="totalRecords"
      [dataList]="dataList"
    ></app-generatehodlist>
    <!-- </ng-container> -->
  </nz-drawer>
</div>

<div *ngIf="expostFactoVisible">
  <nz-drawer
    [nzBodyStyle]="{
      height: 'calc(100% - 55px)',
      overflow: 'auto',
      'padding-bottom': '53px'
    }"
    [nzClosable]="true"
    [nzMask]="true"
    [nzMaskClosable]="false"
    [nzWidth]="1200"
    [nzVisible]="expostFactoVisible"
    (nzOnClose)="expostFactoDrawerClose()"
    (nzKeyboard)="(true)"
  >
    <!-- <app-generateexpostfactolist
    [drawerClose]="delayCondonationCloseCallback"
    [orderdata]="orderdata1"
    [HospitalMapping]="HospitalMapping"
    [queData]="queData"
    [empID]="empID1"
    [claimID]="claimID1"
    [SECTION_TYPE]="SECTION_TYPE"
    [NAME]="NAME"
    [NAME_HN]="NAME_HN"
    [OFFICE_NAME]="OFFICE_NAME"
    [OFFICE_NAME_HN]="OFFICE_NAME_HN"
    [POST]="POST"
    [POST_HN]="POST_HN"
    [isSpinning]="isRefExpoPermission"
  ></app-generateexpostfactolist> -->
    <!-- <ng-container *nzDrawerContent> -->
    <app-generateexpostfactolist
      [empID]="expostFactoID"
      [totalRecords]="totalRecords"
      [dataList]="dataList"
      [loadingRecords]="ExpostLoadingRecords"
    ></app-generateexpostfactolist>
    <!-- </ng-container> -->
  </nz-drawer>
</div>
