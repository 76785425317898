<div>
  <form nz-form #AddNewRecord="ngForm">
    <nz-spin [nzSpinning]="isSpinning">
      <div nz-row>
        <!-- <div nz-row class="my-box"> -->
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>Date</nz-form-label>
            <nz-form-control nzErrorTip="Please Select Date">
              <nz-form-control nzHasFeedback nzErrorTip="Please Select Date">
                <nz-date-picker
                  name="date"
                  [(ngModel)]="data.DATE"
                  style="width: 100%"
                ></nz-date-picker>
              </nz-form-control>
            </nz-form-control>
          </nz-form-item>
        </div>
        <!-- (ngModelChange)="getclassmode($event)" -->
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon
              >Mode Of Conveyance Used</nz-form-label
            >
            <nz-form-control nzHasFeedback nzErrorTip="Select Mode">
              <nz-select
                nzAllowClear
                name="room"
                [(ngModel)]="data.TRAVEL_MODE_ID"
                style="width: 100%"
                (ngModelChange)="getclassmode($event)"
                nzPlaceHolder="Select Mode Of Conveyance Used"
              >
                <nz-option
                  *ngFor="let role2 of modedata"
                  [nzValue]="role2.ID"
                  [nzLabel]="role2.NAME"
                >
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>From Date</nz-form-label>
            <nz-form-control nzHasFeedback nzErrorTip="Please Select to Date">
              <nz-date-picker
                nzFormat="dd-MM-yyyy HH:mm"
                nzShowTime
                name="fromstation"
                [(ngModel)]="data.PERIOD_FROM"
                style="width: 100%"
                placeHolder="Select Form Date"
              ></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>To Date</nz-form-label>
            <nz-form-control nzHasFeedback nzErrorTip="Please Select to Date">
              <nz-date-picker
                nzFormat="dd-MM-yyyy HH:mm"
                nzShowTime
                name="dddddddddate"
                placeHolder="Select To Date"
                [(ngModel)]="data.PERIOD_TO"
                style="width: 100%"
              ></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>From Place</nz-form-label>
            <nz-form-control nzErrorTip="Please Enter From Place">
              <nz-input-group style="width: 100%">
                <input
                  nz-input
                  autocomplete="off"
                  [(ngModel)]="data.PLACE_FROM"
                  name="rafddfgdsgte"
                  type="text"
                  maxlength="64"
                  [pattern]="namepatt"
                  placeHolder="Enter From Place"
                  required
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon>To Place</nz-form-label>
            <nz-form-control nzErrorTip="Please Enter To Place">
              <nz-input-group style="width: 100%">
                <input
                  nz-input
                  autocomplete="off"
                  [(ngModel)]="data.PLACE_TO"
                  name="rafdsgte"
                  type="text"
                  maxlength="64"
                  [pattern]="namepatt"
                  placeHolder="Enter To Place"
                  required
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon
              >Class To Which Entitled</nz-form-label
            >
            <nz-form-control
              nzHasFeedback
              nzErrorTip="Select Class To Which Entitled"
            >
              <nz-select
                nzAllowClear
                name="rofffom"
                [(ngModel)]="data.ENTITLED_CLASS_ID"
                style="width: 100%"
                nzPlaceHolder="Select Class To Which Entitled"
              >
                <nz-option
                  *ngFor="let role of classdata"
                  [nzValue]="role.ID"
                  [nzLabel]="role.NAME"
                >
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon
              >Class By Which Travelled</nz-form-label
            >
            <nz-form-control
              nzHasFeedback
              nzErrorTip="Select Class By Which Travelled"
            >
              <nz-select
                nzAllowClear
                name="rooddddgfbm"
                [(ngModel)]="data.TRAVELLED_CLASS_ID"
                style="width: 100%"
                nzPlaceHolder="Select Class By Which Travelled"
              >
                <nz-option
                  *ngFor="let role1 of classdata1"
                  [nzValue]="role1.ID"
                  [nzLabel]="role1.NAME"
                >
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <!-- <div nz-col nzSpan="24">
          <span
            style="color: red; padding-left: 10px"
            *ngIf="
              gradpaylevel == 'Level 1' ||
              gradpaylevel == 'Level 2' ||
              gradpaylevel == 'Level 3' ||
              gradpaylevel == 'Level 4' ||
              gradpaylevel == 'Level 5'
            "
          >
          </span>
          <span
            style="color: red; padding-left: 10px"
            *ngIf="
              gradpaylevel == 'Level 6' ||
              gradpaylevel == 'Level 7' ||
              gradpaylevel == 'Level 8'
            "
            >Note: Maximum Admissible Amount Is ₹ 450.
          </span>
          <span
            style="color: red; padding-left: 10px"
            *ngIf="
              gradpaylevel == 'Level 9' ||
              gradpaylevel == 'Level 10' ||
              gradpaylevel == 'Level 11'
            "
            >Note: Maximum Admissible Amount Is ₹ 750.</span
          >
          <span
            style="color: red; padding-left: 10px"
            *ngIf="gradpaylevel == 'Level 12' || gradpaylevel == 'Level 13'"
            >Note: Maximum Admissible Amount Is ₹ 4500.</span
          >
          <span
            style="color: red; padding-left: 10px"
            *ngIf="
              gradpaylevel == 'Level 14' ||
              gradpaylevel == 'Level 15' ||
              gradpaylevel == 'Level 16' ||
              gradpaylevel == 'Level 17'
            "
            >Note: Maximum Admissible Amount Is ₹ 7500.</span
          >
        </div> -->
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon
              >Fair Paid By The Officer</nz-form-label
            >
            <nz-form-control nzErrorTip="Enter Fair Paid By The Officer">
              <nz-input-group style="width: 100%" nzAddOnBefore="₹">
                <input
                  nz-input
                  autocomplete="off"
                  [(ngModel)]="data.FARE_PAID_BY_OFFICER"
                  name="fairPaidbyofficer"
                  type="text"
                  (keypress)="omit($event)"
                  maxlength="8"
                  placeHolder="Fair Paid By The Officer"
                  required
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired nzNoColon
              >Admissible Amount</nz-form-label
            >
            <nz-form-control nzErrorTip="Enter Admissible Amount">
              <nz-input-group style="width: 100%" nzAddOnBefore="₹">
                <input
                  nz-input
                  autocomplete="off"
                  [(ngModel)]="data.FARE_OF_ENTITLED_CLASS"
                  name="fairPaid"
                  type="text"
                  (keypress)="omit($event)"
                  maxlength="8"
                  placeHolder="Admissible Amount"
                  required
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </nz-spin>
  </form>

  <div class="footer">
    <button
      type="button"
      nz-button
      (click)="close(AddNewRecord)"
      class="ant-btn"
      style="margin-right: 8px"
    >
      <span>Cancel</span>
    </button>
    <button
      type="button"
      class="ant-btn ant-btn-primary"
      nzType="primary"
      *ngIf="!data.ID"
      nz-button
      nz-dropdown
      [nzDropdownMenu]="menu"
      [nzLoading]="isSpinning"
      (click)="save(false, AddNewRecord)"
    >
      <span>Save & Close</span>
    </button>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li
          nz-menu-item
          class="menuc"
          style="border-radius: 4px; background-color: #40a9ff"
        >
          <a style="color: white" (click)="save(true, AddNewRecord)"
            >Save & New</a
          >
        </li>
      </ul>
    </nz-dropdown-menu>
    <button
      type="button"
      class="ant-btn ant-btn-primary"
      nz-button
      [nzLoading]="isSpinning"
      *ngIf="data.ID"
      (click)="save(false, AddNewRecord)"
    >
      <span>Update & Close</span>
    </button>
  </div>
  <div class="header">
    <button
      type="button"
      nz-button
      (click)="close(AddNewRecord)"
      class="ant-btn"
      style="margin-right: 8px"
    >
      <i nz-icon nzType="close" nzTheme="outline"></i>
    </button>
  </div>
</div>
