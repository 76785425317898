import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Claiemeded } from 'src/app/Models/addclaimed';
import { EmployeeMaster } from 'src/app/Models/Employee';
import { HospitalMaster } from 'src/app/Models/HospitalMaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-applyclaim',
  templateUrl: './applyclaim.component.html',
  styleUrls: ['./applyclaim.component.css'],
})
export class ApplyclaimComponent implements OnInit {
  @Input() drawerClose: Function;

  @Input() data: Claiemeded;
  employee: EmployeeMaster[] = [];
  Hospital: HospitalMaster[] = [];
  dataList: any;
  isSpinning = false;
  treatment_availed = false;
  PrivateH = false;
  Benefeciarytype: string = '';
  AdvanceTaken = false;
  selectedValue = null;
  // relativeArray = []

  status: boolean = true;
  constructor(
    private message: NzNotificationService,
    private api: ApiService
  ) {}

  ngOnInit() {
    this.api.gethospital(0, 0, '', '', ' AND STATUS=1').subscribe(
      (data) => {
        if (data['code'] == 200) {
          this.Hospital = data['data'];
        }
      },
      (err) => {
        console.log(err);
      }
    );

    this.api.getEmployeeMaster(0, 0, '', '', '').subscribe(
      (data) => {
        if (data['code'] == 200) {
          this.employee = data['data'];
          // this.data.OFFICE_NAME = data['data'][0]['OFFICE_NAME'];
          // this.data.DESIGNATION = data['data'][0]['DESIGNATION'];

          // this.data.LOCATION = data['data'][0]['LOCATION'];
          // this.data.DDO_OF_THE_OFFICIAL = data['data'][0]['DDO_OF_THE_OFFICIAL'];
          // this.data.GRADE_PAY = data['data'][0]['GRADE_PAY'];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  filterdrama(event: any) {
    this.api.getEmployeeMaster(0, 0, '', '', ' AND ID =' + event).subscribe(
      (data) => {
        if (data['code'] == 200) {
          this.employee = data['data'];
          this.data.OFFICE_NAME = data['data'][0]['OFFICE_NAME'];
          this.data.DESIGNATION = data['data'][0]['DESIGNATION'];
          this.data.EMPLOYEE_CODE = data['data'][0]['DESIGNATION'];

          this.data.LOCATION = data['data'][0]['LOCATION'];
          this.data.DDO_OF_THE_OFFICIAL =
            data['data'][0]['DDO_OF_THE_OFFICIAL'];
          this.data.GRADE_PAY = data['data'][0]['GRADE_PAY'];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  getemp() {}
  isOk = true;
  categoryList = [];

  close(): void {
    this.drawerClose();
  }

  isValidMobile(mobile) {
    const expression = /^[6-9]\d{9}$/;
    return expression.test(String('' + mobile).toLowerCase());
  }

  isValidEmail(email) {
    const expression =
      /^[_a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/;
    return expression.test(String(email).toLowerCase());
  }

  save(addNew: boolean, websitebannerPage: NgForm): void {
    this.isSpinning = false;
    this.isOk = true;
    if (
      this.data.OFFICE_NAME.trim() == '' &&
      this.data.DESIGNATION.trim() == '' &&
      this.data.LOCATION.trim() == '' &&
      this.data.DDO_OF_THE_OFFICIAL.trim() == '' &&
      this.data.PATIENT_NAME.trim() == '' &&
      this.data.RELATION_WITH_PATIENT.trim() == '' &&
      this.data.BENEFICIARY_TYPE.trim() == '' &&
      this.data.CGHS_CARD_NO.trim() == '' &&
      this.data.EMP_ID == 0 &&
      this.data.GRADE_PAY == 0
    ) {
      this.isOk = false;
      this.message.error('Please Fill All The Required Fields ', '');
    }

    if (this.isOk) {
      this.isSpinning = true;
      {
        if (this.data.ID) {
          this.api.updateclaimed(this.data).subscribe((successCode) => {
            if (successCode['code'] == 200) {
              this.message.success('Information Changed Successfully...', '');
              if (!addNew) this.drawerClose();
              this.isSpinning = false;
            } else {
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
        } else {
          this.api.createclaimed(this.data).subscribe((successCode) => {
            if (successCode['code'] == 200) {
              this.message.success('Information Save Successfully...', '');
              if (!addNew) this.drawerClose();
              // else {
              //   this.data = new Claiemeded();
              //   this.resetDrawer(websitebannerPage);
              //   // this.data.IMG_URL= '';

              //   this.api.getclaimed(1,1,'SEQUENCE_NUMBER','desc','').subscribe (data =>{
              //     // if (data['count']==0){
              //     //   this.data.SEQUENCE_NUMBER=1;
              //     // }else
              //     // {
              //     //   this.data.SEQUENCE_NUMBER=data['data'][0]['SEQUENCE_NUMBER']+1;
              //     // }
              //   },err=>{
              //     console.log(err);
              //   })
              // }
              this.isSpinning = false;
            } else {
              this.message.error('Failed To Fill Information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
  }

  resetDrawer(websitebannerPage: NgForm) {
    this.data = new Claiemeded();
    // this.data.PARENT_GROUP_ID=this.parentgroup;
    websitebannerPage.form.markAsPristine();
    websitebannerPage.form.markAsUntouched();
  }

  SelectRelative(data: Event) {}

  omit(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  relativeArray = [
    'Father',
    'Mother',
    'Sister',
    'Daughter',
    'Grandfather ',
    'Grandmother',
    'Maternal-Grandfather',
    'Maternal-Grandmothe',
    'Adopted Daughter',
    'Adopted Son',
    'Son’s Wife',
    'Daughter’s Husband (Son In Law)	',
    'Niece',
    'Son’s Son (Grandson)',
    'Son’s Daughter ',
    'Husband',
    'Wife',
    'Fiancé Or Fiancée',
    'Aunt',
    'Uncle',
    'Father’s Sister',
    'Elder Sister Husband',
    'Younger Sister Husband',
    'Husband Elder Brother',
    'Husband Younger Brother',
    'Elder Brother’s Wife',
    'Younger Brothers Wife',
    'Wife’s Sister (Sister in Law)',
    'Wife’s Elder Brother',
    'Wife’s Younger Brother',
    'Younger Sister Husband',
    'Husband’s Elder Brother (Brother In Law)',
    'Wife’s Brother Wife',
    'Husband Younger Brother',
    'Husband’s Sister’s Husband',
    'Wife’s Sister’s Husband',
    'Husband’s Elder Brother’s Wife',
    'Husband’s Younger Brother’s Wife',
    'Father’s Brother’s Son',
    '	Fathers Brother’s Daughter',
    'Father’s Sister’s Son',
    'Father’s Sister’s Daughter',
    'Mother’s Brother’s Son',
    'Mother’s Brother’s Daughter',
    'Mother’s Sister’s Son',
    'Mother’s Sister’s Daughter',
    'Spouse',
    'Spouse’s Mother',
    'Spouse’s Father',
    'Father’s Younger Brother',
    'Father’s Elder Brother',
    'Father’s Younger Brother’s wife',
    'Mother’s Brother',
    'Mother’s Younger',
    'Mother’s Younger',
    'Mother’s Elder',
    'Mother’s Elder',
    'Mother’s Brother',
    'Step Brother',
    'Step Sister',
    'Step Mother',
    'Step Father',
    'Step Son',
    'Step Daughter',
  ];
}
