<div nz-row nzType="flex">
    <div nz-col nzXs="24" nzSm="24" nzMd="20" nzLg="20" nzXl="12">
        <div class="formname">{{formTitle}}</div>
    </div>

    <div nz-col nzXs="24" nzSm="24" nzMd="4" nzLg="6" nzXl="12" style="display: flex; justify-content: flex-end">
        <form nz-form [nzLayout]="'inline'">
            <nz-form-item>
                <button nz-button nzTooltipTitle="Excel" nzTooltipPlacement="top" nz-tooltip nzType="primary"
                    (click)="importInExcel()" [nzLoading]="exportLoading">
                    <i nz-icon nzType="file-excel" nzTheme="outline"></i>
                </button>
            </nz-form-item>

            <nz-form-item>
                <button nz-button nzTooltipTitle="Filter" nzTooltipPlacement="top" nz-tooltip [nzType]="isFilterApplied"
                    (click)="showFilter()">
                    <i nz-icon nzType="filter"></i>
                </button>
            </nz-form-item>

            <nz-form-item>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                    <input type="text" name="search" [(ngModel)]="searchText" (keydown.enter)="onKeypressEvent($event)"
                        nz-input placeholder="Search" />
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" id="button" (click)="search(true)" nzSearch><i nz-icon
                            nzType="search"></i></button>
                </ng-template>
            </nz-form-item>
        </form>
    </div>
</div>

<div nz-row>
    <div nz-col nzSpan="24">
        <div class="filter {{ filterClass }}">
            <div class="filter-box">

                <form nz-form>
                    <div nz-row>
                        <div nz-col nzSpan="8">
                            <nz-form-item style="margin-top: 1px;width: 100%;">
                                <nz-form-label nzNoColon>Select Date</nz-form-label>
                                <nz-range-picker name="date" nzFormat="dd MMM yyyy" [(ngModel)]="DATE">
                                </nz-range-picker>
                            </nz-form-item>
                        </div>
                        <!-- [(ngModel)]="data" -->
                        <!-- <div nz-col nzSpan="6">
                            <nz-form-item>
                                <nz-form-label nzNoColon>Hospital Type</nz-form-label>
                                <nz-select nzShowSearch nzAllowClear name="hospitalType"
                                    nzPlaceHolder="Select Hospital Type" [(ngModel)]="HOSPITAL_TYPE">
                                    <nz-option nzLabel="Empanelled" nzValue="E"></nz-option>
                                    <nz-option nzLabel="Non-Empanelled" nzValue="NE"></nz-option>
                                    <nz-option nzLabel="Governemnt" nzValue="G"></nz-option>
                                </nz-select>
                            </nz-form-item>
                        </div>
                        <!-- [(ngModel)]="STAGE_ID" 
                        <div nz-col nzSpan="6">
                            <nz-form-item>
                                <nz-form-label nzNoColon>Stage Name</nz-form-label>
                                <nz-select name="stageName" nzShowSearch nzAllowClear style="width: 100%"
                                    nzPlaceHolder="Select Stage Name" nzMode="multiple" [(ngModel)]="STAGE_NAME">
                                    <nz-option *ngFor="let stage of stages;" [nzValue]="stage.NAME"
                                        [nzLabel]="stage.NAME">
                                    </nz-option>
                                </nz-select>
                            </nz-form-item>
                        </div>
                        <div nz-col nzSpan="6">
                            <nz-form-item>
                                <nz-form-label nzNoColon>Inspector Name</nz-form-label>
                                <nz-select name="InspectorName" nzShowSearch nzAllowClear style="width: 100%"
                                    nzPlaceHolder="Select Inspector Name" nzMode="multiple"
                                    [(ngModel)]="INSPECTOR_NAME">
                                    <nz-option *ngFor="let iname of inspectorname;" [nzValue]="iname.ID"
                                        [nzLabel]="iname.NAME">
                                    </nz-option>
                                </nz-select>
                            </nz-form-item>
                        </div>
                    </div> -->

                        <!-- <div nz-col nzSpan="6">
                            <nz-form-item>
                                <nz-form-label nzNoColon>Benificiary Type</nz-form-label>
                                <nz-select nzShowSearch nzAllowClear name="Benificiarytypesds"
                                    nzPlaceHolder="Select Benificiary Type" [(ngModel)]="BENIFICIARY_TYPE">
                                    <nz-option nzLabel="CGHS" nzValue="CG"></nz-option>
                                    <nz-option nzLabel="CS(MA)" nzValue="CS"></nz-option>

                                </nz-select>
                            </nz-form-item>
                        </div> -->

                        <div nz-col nzSpan="8">
                            <nz-form-label nzNoColon></nz-form-label>
                            <nz-form-item>
                                <button nz-button [nzLoading]="isSpinning" nzType="primary" (click)="applyFilter()"
                                    nzJustify="end">
                                    <i nz-icon nzType="filter"></i>Apply Filter
                                </button>
                                <button nz-button nzType="primary" (click)="clearFilter()">
                                    <i nz-icon nzType="filter"></i>Clear filter
                                </button>
                            </nz-form-item>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>





<!-- Table -->
<div nz-row>
    <div nz-col nzSpan="24">
        <!-- <nz-table id="excel-table" class="my-scroll no-wrap1" nzBordered #table nzShowSizeChanger [nzFrontPagination]="true"
            [nzData]="dataList" [nzLoading]="loadingRecords" [nzTotal]="totalRecords" [(nzPageIndex)]="pageIndex"
            [(nzPageSize)]="pageSize" (nzPageIndexChange)="search()" (nzPageSizeChange)="search(true)"> -->

        <nz-table id="excel-table" class="my-scroll no-wrap1" nzBordered #table nzShowSizeChanger
            [nzFrontPagination]="false" [nzData]="dataList" [nzLoading]="loadingRecords" [nzTotal]="totalRecords"
            [(nzPageIndex)]="pageIndex" [(nzPageSize)]="pageSize" (nzPageIndexChange)="search()"
            [nzScroll]="{x:'1500px',y:'420px'}" (nzPageSizeChange)="search()" (nzQueryParams)="sort($event)">

            <thead nzSingleSort>
                <tr>

                    <th nzWidth="120px" style="font-weight: bold; text-align: center;" nzColumnKey="{{'HIRARCHY_NAME'}}"
                        [nzSortFn]="true">
                        Hierarchy Name
                    </th>
                    <th nzWidth="70px" nzColumnKey="{{'CREATED'}}" [nzSortFn]="true"
                        style="font-weight: bold; text-align: center;">
                        Created
                    </th>

                    <th nzWidth="80px" style="font-weight: bold; text-align: center;" nzColumnKey="{{'TRANSFERED'}}"
                        [nzSortFn]="true">
                        Transfered to other
                    </th>
                    <th nzWidth="90px" style="font-weight: bold; text-align: center;" nzColumnKey="{{'PENDING_AT_ME'}}"
                        [nzSortFn]="true">
                        Pending at my desk
                    </th>
                    <th nzWidth="90px" style="font-weight: bold; text-align: center;"
                        nzColumnKey="{{'PENDING_AT_OTHERS'}}" [nzSortFn]="true">
                        Pending at other desk
                    </th>

                    <th nzWidth="70px" style="font-weight: bold; text-align: center;" nzColumnKey="{{'IN_ADVANCE'}}"
                        [nzSortFn]="true">
                        In Advanced
                    </th>

                    <th nzWidth="50px" style="font-weight: bold; text-align: center;" nzColumnKey="{{'IN_FINAL'}}"
                        [nzSortFn]="true">
                        In Final
                    </th>

                    <th nzWidth="50px" style="font-weight: bold; text-align: center;" nzColumnKey="{{'CLOSED'}}"
                        [nzSortFn]="true">
                        Closed
                    </th>




                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let data of table.data;">

                    <td style="white-space: pre-line;" align="center">{{data['HIRARCHY_NAME']}}</td>

                    <!-- <td style="text-align: center;">
                        <span nz-icon *ngIf="data['HOSPITAL_TYPE']=='E'">Empanelled</span>
                        <span nz-icon *ngIf="data['HOSPITAL_TYPE']=='NE'">Non Empanelled</span>
                        <span nz-icon *ngIf="data['HOSPITAL_TYPE']=='G'">Government</span>
                    </td> -->

                    <td style="white-space: pre-line;" align="center">{{data['CREATED']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['TRANSFERED']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['PENDING_AT_ME']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['PENDING_AT_OTHERS']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['IN_ADVANCE']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['IN_FINAL']}}</td>
                    <td style="white-space: pre-line;" align="center">{{data['CLOSED']}}</td>
                </tr>
            </tbody>
        </nz-table>
    </div>
</div>