export class form3 {
  ID: any;
  // DATE: any = new Date();
  // MODE: any;
  // STATION_FROM: any;
  // STATION_TO: any;
  // WEIGHT: any;
  // RATE: any;
  // AMOUNT: any;
  // REMARK: any;
  // TRAVEL_MODE_ID: any;
  // EMP_ID:any
  // TRANSFER_ID:any

  EMP_ID: any;
  TRANSFER_ID: any;
  DATE: any;
  TRANSPORT_MODE_ID: any;
  FROM_STATION: any;
  TO_STATION: any;
  WEIGHT_IN_KG: any;
  RATE: any;
  AMOUNT: any;
  REMARKS: any;
  DISTANCE: any;
  IS_DELETED: any;
  TRANSPORTATION_CLAIMED_AMOUNT:any=0

  
}
