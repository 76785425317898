import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-reportsview',
  templateUrl: './reportsview.component.html',
  styleUrls: ['./reportsview.component.css'],
})
export class ReportsviewComponent implements OnInit {
  constructor(private api: ApiService) {}

  ngOnInit(): void {
    this.getAllForms();
  }
  isCollapsed = false;
  MedicalTitle = 'Medical Reports';
  FileTitle = ' File Reports';
  TourTitle = ' Tour Reports';
  LTCTitle = ' LTC Reports';
  TransferTitle = ' Transfer Reports';
  menus = [];
  reports = [];
  array = [];
  FILEREPORTS = [];
  LTCREPORTS = [];
  TOURREPORTS = [];
  TransferREPORTS = [];
  roleId = Number(sessionStorage.getItem('roleId'));

  getAllForms() {
    this.api.getForms(this.roleId).subscribe(
      (data1) => {
        this.reports = data1['data'];

        for (let k = 0; k < this.reports.length; k++) {
          if (this.reports[k].title == 'Reports') {
            this.menus = this.reports[k].children;
            // console.log(this.reports[k].title);
          }
        }
        for (let k = 0; k < this.reports.length; k++) {
          if (this.reports[k].title == 'File Reports') {
            this.FILEREPORTS = this.reports[k].children;
          }
        }
        for (let k = 0; k < this.reports.length; k++) {
          if (this.reports[k].title == 'LTC Reports') {
            this.LTCREPORTS = this.reports[k].children;
          }
        }
        for (let k = 0; k < this.reports.length; k++) {
          if (this.reports[k].title == 'Tour Reports') {
            this.TOURREPORTS = this.reports[k].children;
          }
        }
        for (let k = 0; k < this.reports.length; k++) {
          if (this.reports[k].title == 'Transfer Reports') {
            this.TransferREPORTS = this.reports[k].children;
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
