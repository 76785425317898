import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { journeyP } from 'src/app/Models/journeyParticular';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-particularsofjourney',
  templateUrl: './particularsofjourney.component.html',
  styleUrls: ['./particularsofjourney.component.css'],
})
export class ParticularsofjourneyComponent implements OnInit {
  @Input()
  drawerVisible: boolean = false;
  @Input() drawerClose: Function;
  @Input() data: journeyP;
  @Input() empID: any;
  @Input() ltcID: any;
  @Input() class: any = [];

  modedata: any[] = [];

  constructor(
    private message: NzNotificationService,
    private api: ApiService,
    private datePipe: DatePipe
  ) {}
  isSpinning = false;
  isOk = true;
  ngOnInit(): void {
    this.getJourney();
    this.getMode();
  }

  getClasses(event: any) {
    this.data.MODE_OF_CONVEYANCE_ID = event;
    this.data.ENTITLED_CLASS_ID = 0;
    this.data.TRAVELLED_CLASS_ID = 0;
    if (
      this.data.MODE_OF_CONVEYANCE_ID != undefined &&
      this.data.MODE_OF_CONVEYANCE_ID != null
    )
      this.api
        .gettravelclass(0, 0, '', '', ' AND STATUS=1 AND MODE_ID=' + event)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              this.class = data['data'];
            }
          },
          (err) => {
            console.log(err);
          }
        );
  }
  mode: any = [];
  getMode() {
    this.api.gettravelmode(0, 0, '', '', '').subscribe(
      (data) => {
        if (data['code'] == 200) {
          this.mode = data['data'];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  getJourney() {
    this.api.getJourneyParticular(0, 0, '', '', '').subscribe(
      (data) => {
        if (data['code'] == 200) {
          this.modedata = data['data'];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  close(AddNewRecord: NgForm) {
    this.drawerClose();
    this.resetDrawer(AddNewRecord);
    AddNewRecord.form.reset();
  }

  resetDrawer(AddNewRecord: NgForm) {
    this.data = new journeyP();
    // this.data.PARENT_GROUP_ID=this.parentgroup;
    AddNewRecord.form.markAsPristine();
    AddNewRecord.form.markAsUntouched();
  }

  omit(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 46 || charCode > 57)) {
      return false;
    }
    return true;
  }

  save(addNew: boolean, AddNewRecord: NgForm): void {
    this.isSpinning = false;
    this.isOk = true;
    if (
      this.data.FAIR_PAID != null ||
      this.data.FAIR_PAID != undefined ||
      this.data.FAIR_PAID != ''
    ) {
      this.data.FAIR_PAID = Number(this.data.FAIR_PAID);
    } else {
    }
    if (
      this.data.JOURNEY_PARTICULAR_CLAIMED_AMOUNT != null ||
      this.data.JOURNEY_PARTICULAR_CLAIMED_AMOUNT != undefined
    ) {
      this.data.JOURNEY_PARTICULAR_CLAIMED_AMOUNT = Number(
        this.data.JOURNEY_PARTICULAR_CLAIMED_AMOUNT
      );
    } else {
    }
    if (
      (this.data.PLACE_FROM == undefined || this.data.PLACE_FROM == '') &&
      (this.data.PLACE_TO == undefined || this.data.PLACE_TO == '') &&
      (this.data.MODE_OF_CONVEYANCE_ID == undefined ||
        this.data.MODE_OF_CONVEYANCE_ID == '') &&
      (this.data.FAIR_PAID == undefined || this.data.FAIR_PAID == '') &&
      (this.data.ENTITLED_CLASS_ID == undefined ||
        this.data.ENTITLED_CLASS_ID == '') &&
      (this.data.TRAVELLED_CLASS_ID == undefined ||
        this.data.TRAVELLED_CLASS_ID == '') &&
      (this.data.NO_OF_FAIRS == undefined || this.data.NO_OF_FAIRS == '')
    ) {
      this.isOk = false;
      this.message.error('Please Fill All The Required Fields ', '');
    }
    // else if (this.data.DATE == null || this.data.DATE == '') {
    //   this.isOk = false;
    //   this.message.error(' Please Select Date.', '');
    // }
    // else if (this.data.TRANSPORT_MODE_ID == null || this.data.TRANSPORT_MODE_ID == '') {
    //   this.isOk = false;
    //   this.message.error(' Please Select Mode.', '');
    // }
    else if (this.data.PLACE_FROM == null || this.data.PLACE_FROM == '') {
      this.isOk = false;
      this.message.error(' Please Enter From Place.', '');
    } else if (this.data.PLACE_TO == null || this.data.PLACE_TO == '') {
      this.isOk = false;
      this.message.error('Please Enter To Place.', '');
    } else if (
      this.data.MODE_OF_CONVEYANCE_ID == null ||
      this.data.MODE_OF_CONVEYANCE_ID == 0
    ) {
      this.isOk = false;
      this.message.error(' Please Select Mode Of Conveyance', '');
    } else if (
      this.data.ENTITLED_CLASS_ID == null ||
      this.data.ENTITLED_CLASS_ID == 0
    ) {
      this.isOk = false;
      this.message.error(' Please Select Class Entitled.', '');
    } else if (
      this.data.TRAVELLED_CLASS_ID == null ||
      this.data.TRAVELLED_CLASS_ID == 0
    ) {
      this.isOk = false;
      this.message.error(' Please Select Class Used To Travel.', '');
    } else if (this.data.NO_OF_FAIRS == null || this.data.NO_OF_FAIRS == 0) {
      this.isOk = false;
      this.message.error(' Please Enter No. Of Fair.', '');
    } else if (this.data.FAIR_PAID == null || this.data.FAIR_PAID == 0) {
      this.isOk = false;
      this.message.error(' Please Enter Fair Paid.', '');
    } else if (
      this.data.FAIR_PAID > this.data.JOURNEY_PARTICULAR_CLAIMED_AMOUNT
    ) {
      this.isOk = false;
      this.message.error(
        'Please Enter Fair Paid Admisible Amount Which is Less than Fair Paid claimed',
        ''
      );
    }

    if (this.isOk) {
      // if (this.data.DATE == undefined) {
      //   this.data.DATE = null;
      // } else {
      //   this.data.DATE = this.datePipe.transform(this.data.DATE, 'yyyy-MM-dd');
      // }
      this.isSpinning = true;
      {
        if (this.data.ID) {
          this.data.LTC_ID = this.data.LTC_ID;
          this.api
            .updateJourneyParticular(this.data)
            .subscribe((successCode) => {
              if (successCode.code == '200') {
                this.message.success('Information Changed Successfully...', '');
                // this.ltcID = successCode.LTC_ID;
                this.getJourney();
                if (!addNew) {
                  this.drawerClose();

                  this.isSpinning = false;
                  AddNewRecord.form.reset();
                }
                // this.search()
              } else {
                this.message.error('Information Has Not Changed...', '');
                this.isSpinning = false;
              }
            });
        } else {
          this.data.LTC_ID = this.ltcID;
          this.api
            .createJourneyParticular(this.data)
            .subscribe((successCode) => {
              if (successCode.code == '200') {
                this.message.success('Information Save Successfully...', '');
                // this.ltcID = successCode.LTC_ID;
                this.getJourney();
                if (!addNew) {
                  this.drawerClose();
                } else {
                  // this.ltcID = successCode.LTC_ID;
                  this.data = new journeyP();
                  this.resetDrawer(AddNewRecord);
                }
                this.isSpinning = false;
              } else {
                this.message.error('Failed To Fill Information...', '');
                this.isSpinning = false;
              }
            });
        }
      }
    }
  }
}
