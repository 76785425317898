import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexGrid,
  ApexLegend,
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexResponsive,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
} from 'ng-apexcharts';
import { DatePipe } from '@angular/common';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  colors: string[];
  legend: ApexLegend;
  title: ApexTitleSubtitle;

};

@Component({
  selector: 'app-month-wise-claim-countltc',
  templateUrl: './month-wise-claim-countltc.component.html',
  styleUrls: ['./month-wise-claim-countltc.component.css']
})
export class MonthWiseClaimCountltcComponent implements OnInit {

  // chartOptions1: Partial<ChartOptions1>;
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  date = new Date();
  constructor(private datepipe: DatePipe, private api: ApiService) {
    const year = new Date().getFullYear();
    this.endDates = this.getEndDatesOfYear(year);
    console.log(this.endDates, "this.endDates ")
  }


  getLastDayOfMonth(year: number, month: number): number {
    const date = new Date(year, month + 1, 0);
    return date.getDate();
  }

  // getEndDatesOfYear(year: number): number[] {
  //   const endDates: number[] = [];
  //   for (let month = 0; month < 12; month++) {
  //     const lastDay = this.getLastDayOfMonth(year, month);
  //     endDates.push(lastDay);
  //   }
  //   return endDates;

  // }

  ngOnInit() {
    this.userId = sessionStorage.getItem('userId');


    this.daywisedashboard();
    this.daywisecount = [];
    this.datapush = [];
    this.datapush2 = [];
    this.isLoading = true;
    this.onChange(this.date);


  }
  endDates: number[];
  daywisecount = [];
  datapush = [];
  datapush2 = [];

  year: any;
  month: any;

  day: any;
  isLoading: boolean = false;
  userId: any = sessionStorage.getItem('userId');




  daywisedashboard() {
    this.datapush = [];
    this.datapush2 = [];
    this.isLoading = true;
    this.year = this.datepipe.transform(this.changedate, 'yyyy');
    this.month = this.datepipe.transform(this.changedate, 'MM');

    this.api
      .monthWiseClaimCountltc(0, 0, '', '', '', '', this.monthwieseyear)
      .subscribe(
        (data1) => {

          if (data1['count'] > 0) {
            this.daywisecount = data1['data'];
            console.log(this.daywisecount);
            if (this.daywisecount.length > 0) {
              for (let i = 0; i < this.daywisecount.length; i++) {
                this.datapush2.push(data1['data'][i]['CLAIM_COUNT']);
                console.log(data1['data'][i]['CLAIM_COUNT']);

                this.isLoading = false;
              }

            } else {
            }
          } else if (data1['count'] == 0) {
            console.log(data1['data'].length);
            for (let j = 0; j < data1['data'].length; j++) {
              this.datapush.push(data1['data'][j]['MONTH']);
              console.log(data1['data'][j]['MONTH']);

              this.isLoading = false;
            }
            console.log(this.datapush);
          } else {
            this.datapush2 = [];
            this.isLoading = false;
          }

        },
        (err) => {
          console.log(err);
          this.isLoading = false;
        }
      );

    // else {
    //   this.api
    //     .monthWiseClaimCountltc(
    //       0,
    //       0,
    //       '',
    //       '',
    //       '',
    //       this.enddatesarray, this.monthwieseyear

    //     )
    //     .subscribe(
    //       (data1) => {

    //         if (data1['count'] > 0) {
    //           this.daywisecount = data1['data'];
    //           console.log(this.daywisecount);
    //           if (this.daywisecount.length > 0) {
    //             for (let i = 0; i < this.daywisecount.length; i++) {
    //               this.datapush.push(data1['data'][i]['TOTAL_COUNT']);
    //               this.datapush2.push(data1['data'][i]['TOTAL_COUNT']);
    //               this.isLoading = false;
    //             }

    //           } else {
    //           }
    //         } else if (data1['count'] == 0) {
    //           console.log(data1['data'].length);
    //           for (let j = 0; j < data1['data'].length; j++) {
    //             this.datapush.push(data1['data'][j]['TOTAL_COUNT']);
    //             this.isLoading = false;

    //           }

    //           console.log(this.datapush);
    //         } else {
    //           this.datapush2 = [];
    //           this.isLoading = false;
    //         }

    //       },
    //       (err) => {
    //         console.log(err);
    //         this.isLoading = false;
    //       }
    //     );
    // }
  }

  today = new Date();
  disabledDate = (current: Date): boolean => {

    return differenceInCalendarDays(current, this.today) > 0;
  };
  changedate: any;




  asdas: any
  asdas1: any
  onChange(result: Date): void {
    if (result != null && result != undefined) {
      console.log(result, "result");
      const year = result.getFullYear();
      this.monthwieseyear = year.toString();
      this.daywisedashboard();
    } else if (result == null) {

      this.asdas = new Date();

      this.asdas = this.datepipe.transform(this.asdas, 'yyyy-MM');
      this.asdas1 = this.datepipe.transform(this.asdas, 'yyyy');
      this.date = this.asdas;
      console.log(this.date, "this.date")

      this.api
        .monthWiseClaimCountltc(0, 0, '', '', '', '', this.asdas1)
        .subscribe(
          (data1) => {

            if (data1['count'] > 0) {
              this.daywisecount = data1['data'];
              console.log(this.daywisecount);
              if (this.daywisecount.length > 0) {
                for (let i = 0; i < this.daywisecount.length; i++) {
                  this.datapush2.push(data1['data'][i]['CLAIM_COUNT']);
                  console.log(data1['data'][i]['CLAIM_COUNT']);

                  this.isLoading = false;
                }

              } else {
              }
            } else if (data1['count'] == 0) {
              console.log(data1['data'].length);
              for (let j = 0; j < data1['data'].length; j++) {
                this.datapush.push(data1['data'][j]['MONTH']);
                console.log(data1['data'][j]['MONTH']);

                this.isLoading = false;
              }
              console.log(this.datapush);
            } else {
              this.datapush2 = [];
              this.isLoading = false;
            }

          },
          (err) => {
            console.log(err);
            this.isLoading = false;
          }
        );

    }



    if (this.datapush2.length <= 0) {
      this.daywisecount = [];
      this.datapush2 = [];
      this.date = null;
      for (let i = 0; i < this.daywisecount.length; i++) {
        this.datapush2.push((this.datapush[i]['CLAIM_COUNT']));
      }
      this.chartOptions = {
        series: [
          {
            name: 'Total Applications',
            data: this.datapush2,
          },
        ],
        chart: {
          height: 350,
          type: 'bar',
          events: {
            click: function (chart, w, e) {
            },
          },
        },

        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        grid: {
          show: false,
        },
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        }
        // xaxis: {
        //   categories: this.datapush,


        //   // labels: {
        //   //   style: {

        //   //     fontSize: '12px',
        //   //   },
        //   // },
        // },
      };

      console.log(this.datapush);
    } else {
    }
  }


  enddatesarray: number[] = []
  monthwieseyear: any

  monthwieseyear1: string[] = [];
  getEndDatesOfYear(year: number): number[] {
    const endDates: number[] = [];
    for (let month = 0; month < 12; month++) {
      const date = new Date(year, month + 1, 0);
      endDates.push(date.getDate());
    }
    return endDates;
  }

}
