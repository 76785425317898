import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-transfer-pending-claim',
  templateUrl: './transfer-pending-claim.component.html',
  styleUrls: ['./transfer-pending-claim.component.css'],
})
export class TransferPendingClaimComponent implements OnInit {
  drawerTitle!: string;
  userId = sessionStorage.getItem('userId');
  userName = sessionStorage.getItem('userName');
  formTitle = 'Transfer Pending Claim';
  dataList = [];
  loadingRecords = false;
  totalRecords = 1;
  pageIndex = 1;
  pageSize = 10;
  sortValue: string = 'asc';
  sortKey: string = 'ID';
  searchText: string = '';
  filterQuery: string = '';
  isFilterApplied: string = 'default';
  columns: string[][] = [
    ['NAME', 'NAME'],
    ['MOBILE_NO', 'MOBILE_NO'],
    ['EMPLOYEE_CODE', 'EMPLOYEE_CODE'],
    ['DESIGNATION', 'DESIGNATION'],
    ['DDO_OF_THE_OFFICIAL', 'DDO_OF_THE_OFFICIAL'],
    ['OFFICE_NAME', 'OFFICE_NAME'],
    ['ADDRESS', 'ADDRESS'],
    ['NEW_HEADQUARTERS_NAME', 'NEW_HEADQUARTERS_NAME'],
    ['NEW_ADDRESS', 'NEW_ADDRESS'],
    ['NAME_OF_FAMILY_MEMBER', 'NAME_OF_FAMILY_MEMBER'],
    ['AGE', 'AGE'],
    ['RELATIONSHIP', 'RELATIONSHIP'],
  ];

  constructor(
    private message: NzNotificationService,
    private api: ApiService
  ) {}

  ngOnInit(): void {
    // this.search();
  }
  onKeypressEvent(event: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    // this.search();
  }
  keyup(event: any) {
    this.search();
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = 'id';
      this.sortValue = 'desc';
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }
    var likeQuery = '';
    if (this.searchText != '') {
      likeQuery = ' AND (';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    }

    this.api
      .gettransferdata(
        this.pageIndex,
        this.pageSize,
        this.sortKey,
        sort,
        likeQuery + " AND TRANSFER_STATUS = 'P' "
      )
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            this.loadingRecords = false;
            this.totalRecords = data['count'];
            this.dataList = data['data'];
            // this.isSpinning = false;
            // this.filterClass = 'filter-invisible';
          } else {
            this.message.error("Can't Load Data", '');
            this.loadingRecords = false;
          }
        },

        (err) => {
          console.log(err);
        }
      );
  }

  update(data: any) {
    this.loadingRecords = true;
    data['TRANSFER_STATUS'] = 'T';
    data['INSPECTOR_ID'] = this.userId;
    data['INSPECTOR_NAME'] = this.userName;

    if (data.ID) {
      this.api.updatetransfer(data).subscribe((successCode) => {
        if (successCode.code == '200') {
          this.message.success('Information Changed Successfully...', '');
          // if (!addNew) this.drawerClose();
          this.search();
          this.loadingRecords = false;
        } else {
          this.message.error('Information Has Not Changed...', '');
          this.loadingRecords = false;
        }
      });
    }
  }

  sort(params: NzTableQueryParams) {
    this.loadingRecords = true;
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find((item) => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'ID';
    const sortOrder = (currentSort && currentSort.value) || 'asc';

    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if (this.pageSize != pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    }

    if (this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search();
  }
}
