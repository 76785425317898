import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { roadRail } from 'src/app/Models/RoadRail';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-placesconnectedbyrail',
  templateUrl: './placesconnectedbyrail.component.html',
  styleUrls: ['./placesconnectedbyrail.component.css'],
})
export class PlacesconnectedbyrailComponent implements OnInit {
  @Input()
  drawerVisible: boolean = false;
  @Input() drawerClose: Function;
  @Input() data: roadRail;
  @Input() empID: any;
  @Input() ltcID: any;
  // @Input() drawerData2form1: any;
  modedata: any[] = [];

  constructor(
    private message: NzNotificationService,
    private api: ApiService,
    private datePipe: DatePipe
  ) {}
  isSpinning = false;
  isOk: any = true;
  ngOnInit(): void {
    this.getClass();
  }
  classdata: any = [];
  getClass() {
    this.api
      .gettravelclass(0, 0, '', '', ' AND STATUS=1 AND MODE_ID= 3')
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            this.classdata = data['data'];
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  close(AddNewRecord: NgForm) {
    this.drawerClose();
    this.resetDrawer(AddNewRecord);
    AddNewRecord.form.reset();
  }

  resetDrawer(AddNewRecord: NgForm) {
    this.data = new roadRail();
    // this.data.PARENT_GROUP_ID=this.parentgroup;
    AddNewRecord.form.markAsPristine();
    AddNewRecord.form.markAsUntouched();
    this.data.LTC_ID = this.ltcID;
  }

  omit(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  save(addNew: boolean, AddNewRecord: NgForm): void {
    this.isSpinning = false;
    this.isOk = true;
    if (
      this.data.ROAD_CLAIMED_AMOUNT != null ||
      this.data.ROAD_CLAIMED_AMOUNT != undefined ||
      this.data.ROAD_CLAIMED_AMOUNT != ''
    ) {
      this.data.ROAD_CLAIMED_AMOUNT = Number(this.data.ROAD_CLAIMED_AMOUNT);
    } else {
    }
    if (
      this.data.FARE_PAID != null ||
      this.data.FARE_PAID != undefined ||
      this.data.FARE_PAID != ''
    ) {
      this.data.FARE_PAID = Number(this.data.FARE_PAID);
    } else {
    }
    this.data.LTC_ID = this.ltcID;

    if (
      (this.data.FROM_PLACE == undefined || this.data.FROM_PLACE == '') &&
      (this.data.TO_PLACE == undefined || this.data.TO_PLACE == '') &&
      (this.data.ENTITLED_TRAVEL_CLASS_ID == undefined ||
        this.data.ENTITLED_TRAVEL_CLASS_ID == '') &&
      (this.data.FARE_PAID == undefined || this.data.FARE_PAID == '')
    ) {
      this.isOk = false;
      this.message.error('Please Fill All The Required Fields ', '');
    } else if (
      this.data.FROM_PLACE == undefined ||
      this.data.FROM_PLACE == null ||
      this.data.FROM_PLACE == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter From Place Name', '');
    } else if (
      this.data.TO_PLACE == undefined ||
      this.data.TO_PLACE == null ||
      this.data.TO_PLACE == ''
    ) {
      this.isOk = false;
      this.message.error('Please Enter To Place Name.', '');
    } else if (
      this.data.ENTITLED_TRAVEL_CLASS_ID == undefined ||
      this.data.ENTITLED_TRAVEL_CLASS_ID == null ||
      this.data.ENTITLED_TRAVEL_CLASS_ID == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Select Class.', '');
    } else if (
      this.data.FARE_PAID == undefined ||
      this.data.FARE_PAID == null ||
      this.data.FARE_PAID == 0
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Fare Paid.', '');
    } else if (this.data.ROAD_CLAIMED_AMOUNT < this.data.FARE_PAID) {
      this.isOk = false;
      this.message.error(
        'Please Enter Fair Paid Admisible Amount Which is Less than Fair Paid claimed',
        ''
      );
    }

    if (this.isOk) {
      this.ltcID = this.data.LTC_ID;

      if (this.data.ID) {
        this.api
          .ltcRoadConnectedRailUpdate(this.data, this.ltcID)
          .subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information Changed Successfully...', '');
              // this.ltcID = successCode.LTC_ID;

              if (!addNew) {
                this.drawerClose();
                this.isSpinning = false;
                AddNewRecord.form.reset();
              }

              // this.search()
            } else {
              // this.ltcID = successCode.LTC_ID;
              this.data = new roadRail();
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
      } else {
        this.api
          .ltcRoadConnectedRailCreate(this.data)
          .subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information Save Successfully...', '');

              // this.ltcID = successCode.LTC_ID;

              if (!addNew) {
                this.close(AddNewRecord);
              } else {
                // this.ltcID = successCode.LTC_ID;
                this.data = new roadRail();
                this.resetDrawer(AddNewRecord);
              }
              this.isSpinning = false;
            } else {
              this.message.error('Failed To Fill Information...', '');
              this.isSpinning = false;
            }
          });
      }
    }
  }
}
