<div>
    <form nz-form #websitebannerPage="ngForm">
        <nz-spin [nzSpinning]="isSpinning">
            <nz-spin [nzSpinning]="isSpinning">
                <div nz-row>
                  <div nz-row class="my-box">
                    <div nz-col nzSpan="15">
                      <nz-form-item>
                        <nz-form-label nzRequired nzNoColon>Employee Name</nz-form-label>
          
                        <nz-form-control nzErrorTip="Please Enter Employee Name">
                          <input nz-input name="demo1name" required [pattern]="namepatt" maxlength="128"
                            placeHolder="Enter Employee Name" [(ngModel)]="data.NAME" maxlength="128" required />
                        </nz-form-control>
                      </nz-form-item>
                    </div>
          
                    <div nz-col nzSpan="9">
                      <nz-form-item>
                        <nz-form-label nzRequired nzNoColon>Mobile No</nz-form-label>
                        <nz-form-control nzErrorTip="Please Enter Mobile No">
                          <input nz-input autocomplete="off" name="MobileNo" [pattern]="mobilepattern" (keypress)="omit($event)"
                            type="text" maxlength="10" [(ngModel)]="data.MOBILE_NO" placeHolder="Enter Valid Mobile Number" required />
                        </nz-form-control>
                      </nz-form-item>
                    </div>
          
          
                    <div nz-col nzSpan="15">
                      <nz-form-item>
                        <nz-form-label nzNoColon>Email ID</nz-form-label>
                        <nz-form-control nzErrorTip="Please Enter Valid Email">
                          <input nz-input autocomplete="off" name="Emailid" type="text" [pattern]="emailpattern"
                            [(ngModel)]="data.EMAIL_ID" placeHolder="Enter Email ID" required />
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                
                    <div nz-col nzSpan="9">
                      <nz-form-item>
                        <nz-form-label nzRequired nzNoColon> Password </nz-form-label>
                        <nz-form-control nzErrorTip="Please Enter Valid Password">
                          <!-- <input nz-input autocomplete="off" name="password" minlength="8" 
                            type="text" maxlength="20" [(ngModel)]="data.PASSWORD" placeHolder="Enter Password" required /> -->
                            <nz-input-group  [nzSuffix]="suffixTemplate">
                              <input [type]="passwordVisible ? 'text' : 'password'" name="password" autocomplete="off"
                                [(ngModel)]="data.PASSWORD" nz-input placeholder="Enter Password" minlength="8"  maxlength="20" required />
                            </nz-input-group>
                            <ng-template #suffixTemplate>
                              <i nz-icon [nzType]="passwordVisible ? 'eye' : 'eye-invisible'"
                                (click)="passwordVisible = !passwordVisible"></i>
                            </ng-template>
                          </nz-form-control>
                      </nz-form-item>
                    </div>
          
                    <div nz-col nzSpan="24">
                      <nz-form-item>
                        <nz-form-label nzNoColon>Address</nz-form-label>
          
                        <nz-form-control nzErrorTip="Please Enter Address">
                          <textarea nz-input name="empaddress" required maxlength="256" placeHolder="Enter Address"
                            [(ngModel)]="data.ADDRESS" required></textarea>
                            <h6 style="color: red" *ngIf="data.ADDRESS!=undefined" >{{data.ADDRESS.length}}/256</h6> 
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                  </div>
                  <div nz-row class="my-box">
                  <div nz-col nzSpan="15">
                    <nz-form-item>
                      <nz-form-label  nzNoColon>Office Name</nz-form-label>
                      <nz-form-control nzErrorTip="Please Enter Office Name">
                        <input nz-input autocomplete="off" name="Office Name" maxlength="256" [pattern]="namepatt" type="text"
                          [(ngModel)]="data.OFFICE_NAME" placeHolder="Enter The Office Name" required />
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                  <div nz-col nzSpan="9">
                    <nz-form-item>
                      <nz-form-label nzRequired nzNoColon>Location</nz-form-label>
                      <nz-form-control nzErrorTip="Please Enter Location">
                        <input nz-input autocomplete="off" name="Location" type="text" maxlength="256" [(ngModel)]="data.LOCATION"
                          placeHolder="Enter The Location" required />
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                  <div nz-col nzSpan="15">
                    <nz-form-item>
                      <nz-form-label nzRequired nzNoColon>Designation</nz-form-label>
                      <nz-form-control nzErrorTip="Please Enter Designation">
                        <input nz-input autocomplete="off" name="Designation" maxlength="256" [pattern]="namepatt" type="text"
                          [(ngModel)]="data.DESIGNATION" placeHolder="Enter The Designation" required />
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                  <div nz-col nzSpan="9">
                    <nz-form-item>
                      <nz-form-label nzRequired nzNoColon>Employee Code</nz-form-label>
          
                      <nz-form-control nzErrorTip="Please Enter Employee Code ">
                        <input nz-input autocomplete="off" maxlength="16" name="EmployeeCode" [(ngModel)]="data.EMPLOYEE_CODE"
                          type="text" placeHolder="Enter Employee Code" required />
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                  <div nz-col nzSpan="15">
                    <nz-form-item>
                      <nz-form-label nzRequired nzNoColon>DDO Of The Official</nz-form-label>
                      <nz-form-control nzErrorTip="Please Enter DDO Of The Official">
                        <input nz-input autocomplete="off" name="DDOOfficial" type="text" maxlength="256"
                          [(ngModel)]="data.DDO_OF_THE_OFFICIAL" placeHolder="Enter The DDO" required />
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                  <div nz-col nzSpan="9">
                    <nz-form-item>
                      <nz-form-label nzRequired nzNoColon>Basic Pay</nz-form-label>
          
                      <nz-form-control nzErrorTip="Please Enter Basic Pay">
                        <nz-input-group style="width: 100%" nzAddOnBefore="₹">
                          <input nz-input autocomplete="off" (keypress)="omit($event)" [(ngModel)]="data.GRADE_PAY"
                            name="gradePay" type="text" maxlength="8" placeHolder="Enter Basic Pay" required />
                        </nz-input-group>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
                </div>
              </nz-spin>
        </nz-spin>
    </form>




    <div class="footer">
        <button type="button" nz-button (click)="close(websitebannerPage)" class="ant-btn" style="margin-right: 8px">
            <span>Cancel</span>
        </button>


       

        <button type="button" class="ant-btn ant-btn-primary"  nz-button nz-dropdown
        [nzLoading]="isSpinning" (click)="save1(false,websitebannerPage)">
        <span>Update & Close</span>
    </button>
       
    </div>
    <div class="header">
        <button type="button" nz-button (click)="close(websitebannerPage)" class="ant-btn" style="margin-right: 8px">
            <i nz-icon nzType="close" nzTheme="outline"></i>
        </button>
    </div>
</div>