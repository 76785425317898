<form nz-form #investigationMaster="ngForm">
  <div nz-row class="my-box" style="width: 100%">
    <div nz-col nzSpan="16">
      <nz-form-item>
        <nz-form-label nzRequired nzNoColon>
          Bill Item Name / Particulars
        </nz-form-label>

        <nz-form-control nzHasFeedback nzErrorTip="Please Enter Correct Name">
          <input
            nz-input
            name="investname"
            placeHolder="Bill Item Name / Particulars"
            [(ngModel)]="data.PARTICULARS"
            required
          />
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col nzSpan="6">
      <nz-form-item>
        <nz-form-label nzRequired nzNoColon> Claimed Amount </nz-form-label>

        <nz-form-control nzHasFeedback nzErrorTip="Please Enter Correct Amount">
          <nz-input-group style="width: 100%" nzAddOnBefore="₹">
            <input
              nz-input
              name="claimedamount"
              maxlength="10"
              (keypress)="omit($event)"
              [(ngModel)]="data.CLAIMED_AMOUNT"
              required
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col nzSpan="16">
      <nz-form-item>
        <nz-form-label nzRequired nzNoColon>
          Investigation/ Procedure
        </nz-form-label>

        <nz-form-control nzHasFeedback nzErrorTip=" Select Procedure ">
          <nz-select
            [nzOptionHeightPx]="60"
            nzShowSearch
            name="procedure"
            nzErrorTip="Please Select Procedure"
            nzAllowClear
            required
            [(ngModel)]="data.INVESTIGATION_PROCEDURE_ID"
            nzPlaceHolder="Select Procedure"
            (ngModelChange)="peocedureRate($event)"
          >
            <nz-option
              nzCustomContent
              [nzLabel]="'Not In Service'"
              [nzValue]="0"
            >
              <div nz-row>
                <div nz-col nzSpan="24">
                  <div
                    style="padding: 10px; border: 1px solid rgb(173, 172, 172)"
                  >
                    <div nz-row>
                      <div
                        nz-col
                        nzSpan="16"
                        style="color: black; font-size: 14px"
                      >
                        <b>Not In Service</b>
                      </div>

                      <div
                        nz-col
                        nzSpan="8"
                        style="font-size: 11px; text-align: left; color: blue"
                      >
                        Not In Service
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nz-option>

            <nz-option
              *ngFor="let option of procedureList2"
              [nzLabel]="option.NAME"
              [nzValue]="option.ID"
              nzCustomContent
            >
              <div nz-row>
                <div style="padding: 5px; border: 1px solid rgb(173, 172, 172)">
                  <div nz-col nzSpan="24">
                    <div nz-row>
                      <div
                        nz-col
                        nzSpan="16"
                        style="color: black; font-size: 14px"
                      >
                        <b>{{ option.NAME }}</b>
                      </div>

                      <div
                        nz-col
                        nzSpan="8"
                        style="font-size: 11px; text-align: left; color: blue"
                      >
                        {{ option.CATEGORY_NAME }}
                      </div>

                      <div nz-col nzSpan="24" style="font-size: 11px">
                        <div nz-row>
                          <div
                            style="
                              border: 1px solid;
                              background: lightgoldenrodyellow;
                              text-align: center;
                            "
                            nz-col
                            nzSpan="3"
                          >
                            NABH
                          </div>

                          <div
                            style="border: 1px solid; text-align: center"
                            nz-col
                            nzSpan="3"
                          >
                            ₹ {{ option.NABH_RATE }}
                          </div>

                          <div nz-col nzSpan="1"></div>

                          <div
                            style="
                              border: 1px solid;
                              background: lightgoldenrodyellow;
                              text-align: center;
                            "
                            nz-col
                            nzSpan="4"
                          >
                            Non NABH
                          </div>

                          <div
                            style="border: 1px solid; text-align: center"
                            nz-col
                            nzSpan="3"
                          >
                            ₹ {{ option.NON_NABH_RATE }}
                          </div>

                          <div nz-col nzSpan="1"></div>

                          <div
                            style="
                              border: 1px solid;
                              background: lightgoldenrodyellow;
                              text-align: center;
                            "
                            nz-col
                            nzSpan="4"
                          >
                            Super Speciality
                          </div>

                          <div
                            style="border: 1px solid; text-align: center"
                            nz-col
                            nzSpan="3"
                          >
                            ₹ {{ option.SUPEER_SPECIALITY_RATE }}
                          </div>
                        </div>
                      </div>

                      <div nz-col nzSpan="24" style="font-size: 11px">
                        {{ option.MEMO_NOTIFICATION_ID }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col nzSpan="6">
      <nz-form-item>
        <nz-form-label nzRequired nzNoColon> Rate </nz-form-label>

        <nz-form-control nzHasFeedback nzErrorTip="Please Enter Correct Rate">
          <nz-input-group style="width: 100%" nzAddOnBefore="₹">
            <input
              nz-input
              name="rate"
              maxlength="10"
              (keypress)="omit($event)"
              [disabled]="true"
              [(ngModel)]="data.ADMISSIBLE_AMOUNT"
              required
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col nzMd="2" nzXs="5">
      <nz-form-item>
        <nz-form-label nzNoColon></nz-form-label>

        <button
          nz-button
          nzType="primary"
          (click)="addData(investigationMaster)"
        >
          <i nz-icon nzType="plus"></i>
        </button>
      </nz-form-item>
    </div>
  </div>

  <div nz-row>
    <div nz-col nzSpan="24">
      <nz-table
        nzBordered
        [nzData]="showlayoutDataList"
        #newtabledata
        nzFrontPagination="false"
      >
        <thead>
          <tr>
            <th nzColumnKey="" nzWidth="25px" nzAlign="center">
              <p style="font-weight: bold">Action</p>
            </th>

            <th nzWidth="70px" nzAlign="center">
              <p style="font-weight: bold">Particulars</p>
            </th>

            <th nzColumnKey="" nzWidth="25px" nzAlign="center">
              <p style="font-weight: bold">Code No.</p>
            </th>

            <th nzColumnKey="" nzWidth="25px" nzAlign="center">
              <p style="font-weight: bold">Claimed</p>
            </th>

            <th nzColumnKey="" nzWidth="25px" nzAlign="center">
              <p style="font-weight: bold">Admissible</p>
            </th>
          </tr>
        </thead>

        <tbody style="text-align: center">
          <tr
            *ngFor="let data of newtabledata.data; let i = index"
            style="white-space: pre-line"
          >
            <td>
              <a (click)="edit(data)" nz-tooltip nzTooltipTitle="Edit">
                <span
                  nz-icon
                  nzType="edit"
                  nzTheme="twotone"
                  style="margin-top: 10px"
                ></span>
              </a>

              <span
                nz-icon
                nzType="delete"
                nzTheme="twotone"
                nz-tooltip
                nzTooltipTitle="Delete"
                style="margin-left: 20px"
              >
                <a nz-popconfirm nzPopconfirmPlacement="bottom"> </a>
              </span>
            </td>

            <td>
              {{ data["PARTICULARS"] }}
            </td>

            <td style="text-align: center">{{ data["CODE"] }}</td>
            <td style="text-align: center">{{ data["CLAIMED"] }}</td>
            <td style="text-align: center">{{ data["ADMISSIBLE"] }}</td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
</form>

<div class="footer">
  <button nz-button (click)="showModal()" nzType="primary">
    <span>Preview</span>
  </button>

  <button
    type="button"
    nz-button
    (click)="close(investigationMaster)"
    class="ant-btn"
    style="margin-right: 8px"
  >
    <span>Cancel</span>
  </button>
</div>

<div class="header">
  <button
    type="button"
    nz-button
    (click)="close(investigationMaster)"
    class="ant-btn"
    style="margin-right: 8px"
  >
    <i nz-icon nzType="close" nzTheme="outline"></i>
  </button>
</div>

<nz-modal
  [(nzVisible)]="isVisible"
  (nzOnCancel)="handleCancel()"
  [nzWidth]="850"
  [nzContent]="printAnnexureModalContent"
  [nzTitle]="'Annexure'"
  [nzFooter]="printAnnexureModalFooter"
>
  <ng-template #printAnnexureModalContent>
    <div style="height: 450px; overflow: auto">
      <div id="printAnnexureModal">
        <span style="margin-left: 45%"><b>ANNEXURE 'A'</b></span>

        <table style="overflow: none; width: 100%">
          <tbody>
            <tr>
              <td style="border: 1px solid black; padding-left: 6px">
                <b>NAME:</b> Mrs. Reeya P. Murkar
              </td>
              <td style="border: 1px solid black; padding-left: 6px">
                <b>BASIC PAY</b>
              </td>

              <td style="border: 1px solid black; padding-left: 6px">
                5536080
              </td>
            </tr>
            <tr style="width: 30px">
              <td
                style="border: 1px solid black; padding-left: 6px"
                rowspan="2"
              >
                <b> Designation:</b>
                Private Secretary., O/o. CIT(AU)-35., Mumbai
              </td>
              <td style="border: 1px solid black; padding-left: 6px">
                <b>CGHS CARD NO:</b>
              </td>

              <td style="border: 1px solid black; padding-left: 6px">
                5536080
              </td>
            </tr>
            <tr>
              <td style="border: 1px solid black; padding-left: 6px">
                <b>EMPLOYEE CODE</b>
              </td>
              <td style="border: 1px solid black; padding-left: 6px">121988</td>
            </tr>

            <tr>
              <td style="border: 1px solid black; padding-left: 6px">
                <b>PATIENT:</b> Mother-in-law - Smt. Vanita M. Murkar - 4424362
              </td>
              <td style="border: 1px solid black; padding-left: 6px">
                <b></b>
              </td>

              <td style="border: 1px solid black; padding-left: 6px"></td>
            </tr>
          </tbody>
        </table>

        <table style="overflow: none; width: 100%">
          <tbody sty>
            <tr>
              <td style="border: 1px solid black; padding-left: 6px">
                <b>NATURE OF ALIMENT:</b> Acute Excarebation of COPD
              </td>
              <td style="border: 1px solid black; padding-left: 6px"></td>
              <td style="border: 1px solid black; padding-left: 6px"></td>
              <td style="border: 1px solid black; padding-left: 6px"></td>
            </tr>
            <tr>
              <td style="border: 1px solid black; padding-left: 6px">
                <b>PERIOD:</b> 24-06-2022 to 28-06-2022
              </td>
              <td style="border: 1px solid black; padding-left: 6px"></td>
              <td style="border: 1px solid black; padding-left: 6px"></td>
              <td style="border: 1px solid black; padding-left: 6px"></td>
            </tr>
            <tr>
              <td style="border: 1px solid black; padding-left: 6px">
                <b>NAME OF HOSPITAL:</b> Apex Hospitals., Mumbai
                <br />
              </td>
              <td style="border: 1px solid black; padding-left: 6px"></td>
              <td style="border: 1px solid black; padding-left: 6px"></td>
              <td style="border: 1px solid black; padding-left: 6px"></td>
            </tr>
            <tr>
              <td style="border: 1px solid black; padding-left: 6px">
                <b>EMERGENCY OR NOT:</b> Emergency
              </td>
              <td style="border: 1px solid black; padding-left: 6px"></td>
              <td style="border: 1px solid black; padding-left: 6px"></td>
              <td style="border: 1px solid black; padding-left: 6px"></td>
            </tr>

            <tr>
              <td style="border: 1px solid black; padding-left: 6px">
                <b>REFERRED RATE LIST:</b> 2010, O.M. 26.10.2020
              </td>
              <td style="border: 1px solid black; padding-left: 6px"></td>
              <td style="border: 1px solid black; padding-left: 6px"></td>
              <td style="border: 1px solid black; padding-left: 6px"></td>
            </tr>

            <tr>
              <td style="border: 1px solid black; padding-left: 6px">
                <b>PARTICULARS</b>
              </td>
              <td style="border: 1px solid black; padding-left: 6px">
                <b>CODE NO.</b>
              </td>
              <td style="border: 1px solid black; padding-left: 6px">
                <b>CLAIMED</b>
              </td>
              <td style="border: 1px solid black; padding-left: 6px">
                <b>ADMISSIBLE</b>
              </td>
            </tr>

            <tr>
              <td style="border: 1px solid black; padding-left: 6px">
                Bed Charges(5 days)
              </td>
              <td style="border: 1px solid black; padding-left: 6px">484</td>
              <td style="border: 1px solid black; padding-left: 6px">11690</td>
              <td style="border: 1px solid black; padding-left: 6px">11690</td>
            </tr>

            <tr>
              <td style="border: 1px solid black; padding-left: 6px">ECG</td>
              <td style="border: 1px solid black; padding-left: 6px">37</td>
              <td style="border: 1px solid black; padding-left: 6px">43</td>
              <td style="border: 1px solid black; padding-left: 6px">43</td>
            </tr>
            <tr>
              <td style="border: 1px solid black; padding-left: 6px">
                HRCT Plain
              </td>
              <td style="border: 1px solid black; padding-left: 6px">1834</td>
              <td style="border: 1px solid black; padding-left: 6px">1700</td>
              <td style="border: 1px solid black; padding-left: 6px">1700</td>
            </tr>
            <tr>
              <td style="border: 1px solid black; padding-left: 6px">
                IPD Consultant (12 times)
              </td>
              <td style="border: 1px solid black; padding-left: 6px">2</td>
              <td style="border: 1px solid black; padding-left: 6px">1080</td>
              <td style="border: 1px solid black; padding-left: 6px">1080</td>
            </tr>
            <tr>
              <td style="border: 1px solid black; padding-left: 6px">
                OXYGEN (4 days)
              </td>
              <td style="border: 1px solid black; padding-left: 6px">485</td>
              <td style="border: 1px solid black; padding-left: 6px">4800</td>
              <td style="border: 1px solid black; padding-left: 6px">4800</td>
            </tr>
            <tr>
              <td style="border: 1px solid black; padding-left: 6px">
                Urine Routine
              </td>
              <td style="border: 1px solid black; padding-left: 6px">49</td>
              <td style="border: 1px solid black; padding-left: 6px">33</td>
              <td style="border: 1px solid black; padding-left: 6px">33</td>
            </tr>
            <tr>
              <td style="border: 1px solid black; padding-left: 6px">CBC</td>
              <td style="border: 1px solid black; padding-left: 6px">71</td>
              <td style="border: 1px solid black; padding-left: 6px">102</td>
              <td style="border: 1px solid black; padding-left: 6px">102</td>
            </tr>
            <tr>
              <td style="border: 1px solid black; padding-left: 6px">
                Liver Funtion tests
              </td>
              <td style="border: 1px solid black; padding-left: 6px">71</td>
              <td style="border: 1px solid black; padding-left: 6px">234</td>
              <td style="border: 1px solid black; padding-left: 6px">234</td>
            </tr>
            <tr>
              <td style="border: 1px solid black; padding-left: 6px">
                RFT mini
              </td>
              <td style="border: 1px solid black; padding-left: 6px">174</td>
              <td style="border: 1px solid black; padding-left: 6px">222</td>
              <td style="border: 1px solid black; padding-left: 6px">222</td>
            </tr>
            <tr>
              <td style="border: 1px solid black; padding-left: 6px">
                Serum electrolytes (2 times)
              </td>
              <td style="border: 1px solid black; padding-left: 6px">174</td>
              <td style="border: 1px solid black; padding-left: 6px">222</td>
              <td style="border: 1px solid black; padding-left: 6px">222</td>
            </tr>
            <tr>
              <td style="border: 1px solid black; padding-left: 6px">
                Blood Sugar Random (2 times)
              </td>
              <td style="border: 1px solid black; padding-left: 6px">141</td>
              <td style="border: 1px solid black; padding-left: 6px">42</td>
              <td style="border: 1px solid black; padding-left: 6px">42</td>
            </tr>
            <tr>
              <td style="border: 1px solid black; padding-left: 6px">CBS</td>
              <td style="border: 1px solid black; padding-left: 6px">71</td>
              <td style="border: 1px solid black; padding-left: 6px">102</td>
              <td style="border: 1px solid black; padding-left: 6px">102</td>
            </tr>
            <tr>
              <td style="border: 1px solid black; padding-left: 6px">
                Serum Creatinine
              </td>
              <td style="border: 1px solid black; padding-left: 6px">143</td>
              <td style="border: 1px solid black; padding-left: 6px">48</td>
              <td style="border: 1px solid black; padding-left: 6px">48</td>
            </tr>
            <tr>
              <td style="border: 1px solid black; padding-left: 6px">
                Blood Sugar Random (3 times)
              </td>
              <td style="border: 1px solid black; padding-left: 6px">141</td>
              <td style="border: 1px solid black; padding-left: 6px">63</td>
              <td style="border: 1px solid black; padding-left: 6px">63</td>
            </tr>
            <tr>
              <td style="border: 1px solid black; padding-left: 6px">
                Blood Gas Analysis
              </td>
              <td style="border: 1px solid black; padding-left: 6px">170</td>
              <td style="border: 1px solid black; padding-left: 6px">391</td>
              <td style="border: 1px solid black; padding-left: 6px">391</td>
            </tr>
            <tr>
              <td style="border: 1px solid black; padding-left: 6px">
                Triple Marker
              </td>
              <td style="border: 1px solid black; padding-left: 6px">256</td>
              <td style="border: 1px solid black; padding-left: 6px">765</td>
              <td style="border: 1px solid black; padding-left: 6px">765</td>
            </tr>
            <tr>
              <td style="border: 1px solid black; padding-left: 6px">
                Muscle testing and diagnostic (2 times)
              </td>
              <td style="border: 1px solid black; padding-left: 6px">1151</td>
              <td style="border: 1px solid black; padding-left: 6px">270</td>
              <td style="border: 1px solid black; padding-left: 6px">270</td>
            </tr>
            <tr>
              <td style="border: 1px solid black; padding-left: 6px">
                Nebuliser (13 times)
              </td>
              <td style="border: 1px solid black; padding-left: 6px">NIS</td>
              <td style="border: 1px solid black; padding-left: 6px">650</td>
              <td style="border: 1px solid black; padding-left: 6px">650</td>
            </tr>
            <tr>
              <td style="border: 1px solid black; padding-left: 6px">
                Catheterisation
              </td>
              <td style="border: 1px solid black; padding-left: 6px">18</td>
              <td style="border: 1px solid black; padding-left: 6px">425</td>
              <td style="border: 1px solid black; padding-left: 6px">425</td>
            </tr>
            <tr>
              <td style="border: 1px solid black; padding-left: 6px">
                Chest PA
              </td>
              <td style="border: 1px solid black; padding-left: 6px">306</td>
              <td style="border: 1px solid black; padding-left: 6px">60</td>
              <td style="border: 1px solid black; padding-left: 6px">60</td>
            </tr>
            <tr>
              <td style="border: 1px solid black; padding-left: 6px">
                Covid Test
              </td>
              <td style="border: 1px solid black; padding-left: 6px">
                O.M. 26.10.20
              </td>
              <td style="border: 1px solid black; padding-left: 6px">800</td>
              <td style="border: 1px solid black; padding-left: 6px">800</td>
            </tr>
            <tr>
              <td style="border: 1px solid black; padding-left: 6px">
                Medicines
              </td>
              <td style="border: 1px solid black; padding-left: 6px"></td>
              <td style="border: 1px solid black; padding-left: 6px">11602</td>
              <td style="border: 1px solid black; padding-left: 6px">11602</td>
            </tr>

            <tr>
              <td style="border: 1px solid black; padding-left: 6px">
                <b>Total</b>
              </td>
              <td style="border: 1px solid black; padding-left: 6px"></td>
              <td style="border: 1px solid black; padding-left: 6px">
                <b>35938</b>
              </td>
              <td style="border: 1px solid black; padding-left: 6px">
                <b>35938</b>
              </td>
            </tr>
          </tbody>
        </table>

        <br />

        <div>
          <span> Prepared By:</span><br />
          <span> Checked By:</span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #printAnnexureModalFooter>
    <button nz-button nzType="default" (click)="handleCancel()">Close</button>

    <button
      nz-button
      nzType="default"
      [useExistingCss]="true"
      printSectionId="printAnnexureModal"
      ngxPrint
    >
      Print
    </button>

    <button
      nz-button
      nzType="primary"
      [nzLoading]="pdfDownload"
      (click)="generatePDF()"
    >
      Download
    </button>
  </ng-template>
</nz-modal>
