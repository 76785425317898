import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import * as html2pdf from 'html2pdf.js';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { ApplicantMaster } from 'src/app/Models/applicantmaster';
import { ClaimMaster } from 'src/app/Models/claimmaster';
import { EmployeeMaster } from 'src/app/Models/Employee';
import { InvestigationMaster } from 'src/app/Models/InvestigationMaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-employeeclaimlist',
  templateUrl: './employeeclaimlist.component.html',
  styleUrls: ['./employeeclaimlist.component.css'],
})
export class EmployeeclaimlistComponent implements OnInit {
  drawerData2: any[] = [];
  drawerVisible: boolean = false;
  drawerTitle: string = '';
  filterClass: string = 'filter-invisible';
  totalButton: string = 'default';
  isFilterApplied: string = 'default';
  newButton: string = 'default';
  forwardButton: string = 'default';
  rejectButton: string = 'default';
  approvedButton: string = 'default';
  readyButton: string = 'default';
  queryButton: string = 'default';
  drawerData: ApplicantMaster = new ApplicantMaster();
  drawerData3: ClaimMaster = new ClaimMaster();
  userId = sessionStorage.getItem('userId');
  userName = sessionStorage.getItem('userName');
  roleId = sessionStorage.getItem('roleId');
  pageSize2 = 10;
  filename: string = 'Claim';

  formTitle = 'Manage Claims';
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = false;
  sortValue: string = 'desc';
  sortKey: string = 'ID';
  searchText: string = '';
  filterQuery: string = '';
  size = 'small';
  allClaimCount: any;
  allNewCount: any;
  allForwardCount: any;
  allRejectCount: any;
  allQueryCount: any;
  allApprovedCount: any;
  allReadyCount: any;

  claimData: any;
  columns: string[][] = [
    ['CLAIM_NO', 'Claim No'],
    ['PATIENT_NAME', ' Applicant Name'],
    ['EMPLOYEE_NAME', '  Applicant Name'],
    ['EMPLOYEE_CODE', ' Employee Code'],
    ['EMPLOYEE_DESIGNATION', 'Employee Designation'],
    ['RELATION_WITH_PATIENT', 'Patient Relation'],
    ['BILL_FILIING_DATE', 'Bill Filling Date'],
    ['ADMISSIBLE_AMOUNT', 'Admissible Amount(₹)'],
    ['TREATEMENT_TYPE', 'Treatment Type'],
    ['TREATMENT_START_DATE', 'Treatment Start Date'],
    ['TREATMENT_END_DATE', 'Treatment End Date'],
    ['ACCREDITATION', 'Accrediation'],
    ['HOSPITAL_TYPE', 'Hospital Type'],
    ['HOSPITAL_NAME', 'Hospital Name'],
    ['WARD_TYPE', 'Ward Entitlement'],
    ['NATURE_OF_TREATMENT', ' Nature Of Treatment'],
    ['CHECKLIST_STATUS', 'Accept / Reject'],
    ['REMARK', 'Remark'],
  ];

  TYPE_OF_HOSPITAL: any;
  STAGE_NAME: any;
  isSpinning = false;

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'default';
    this.filterQuery = '';
    this.BILL_FILIING_DATE = [];
    this.BILL_FILIING_DATE[0] = new Date(
      this.current.getFullYear() + '-' + (this.current.getMonth() + 1) + '-01'
    );
    this.BILL_FILIING_DATE[1] = new Date();
    this.TYPE_OF_HOSPITAL = [];
    this.dataList = [];
    this.search();
  }

  dateFormat = 'dd/MM/yyyy';
  isButtonSpinning: boolean = false;

  // TYPE_OF_HOSPITAL: any = [];

  START_DATE: any;
  END_DATE: any;
  BILL_FILIING_DATE = [];
  current = new Date();

  employeeedit: any;
  constructor(
    private message: NzNotificationService,
    private api: ApiService,
    private datePipe: DatePipe
  ) {}
  ngOnInit(): void {
    this.employeeedit = sessionStorage.getItem('userId');
    // this.stageName();
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(reset);
  }

  stages = [];
  stageName() {
    this.api.getStage(0, 0, '', 'asc', 'AND STATUS=1').subscribe(
      (data1) => {
        console.log(data1);
        this.stages = data1['data'];
      },
      (err) => {
        console.log(err);
      }
    );
  }

  applyStageFilter(event: any, stageId: string) {
    this.filterQuery = '';
    console.log('event', stageId);
    if (event == 'N') {
      this.newButton = 'primary';
      this.forwardButton = 'default';
      this.rejectButton = 'default';
      this.queryButton = 'default';
      this.approvedButton = 'default';
      this.readyButton = 'default';
      this.totalButton = 'default';
    } else if (event == 'F') {
      this.newButton = 'default';
      this.forwardButton = 'primary';
      this.rejectButton = 'default';
      this.queryButton = 'default';
      this.approvedButton = 'default';
      this.readyButton = 'default';
      this.totalButton = 'default';
    } else if (event == 'R') {
      this.newButton = 'default';
      this.forwardButton = 'default';
      this.rejectButton = 'primary';
      this.queryButton = 'default';
      this.approvedButton = 'default';
      this.readyButton = 'default';
      this.totalButton = 'default';
    } else if (event == 'Q') {
      this.newButton = 'default';
      this.forwardButton = 'default';
      this.rejectButton = 'default';
      this.queryButton = 'primary';
      this.approvedButton = 'default';
      this.readyButton = 'default';
      this.totalButton = 'default';
    } else if (event == 'A') {
      this.newButton = 'default';
      this.forwardButton = 'default';
      this.rejectButton = 'default';
      this.queryButton = 'default';
      this.approvedButton = 'primary';
      this.readyButton = 'default';
      this.totalButton = 'default';
    } else if (event == 'Z') {
      this.newButton = 'default';
      this.forwardButton = 'default';
      this.rejectButton = 'default';
      this.queryButton = 'default';
      this.approvedButton = 'default';
      this.readyButton = 'primary';
      this.totalButton = 'default';
    } else {
      this.newButton = 'default';
      this.forwardButton = 'default';
      this.rejectButton = 'default';
      this.queryButton = 'default';
      this.approvedButton = 'default';
      this.readyButton = 'default';
      this.totalButton = 'primary';
    }
    // this.loadingRecords = true;
    if (stageId != ' ') {
      var filterId = stageId.split(',');
      var filterQuery = ' AND (';
      for (var i = 0; i < filterId.length; i++) {
        filterQuery =
          filterQuery + ' CURRENT_STAGE_ID =' + filterId[i] + ' OR ';
      }
      filterQuery = filterQuery.substring(0, filterQuery.length - 3) + ')';
      this.filterQuery = this.filterQuery + filterQuery;
      console.log('this.filterQuery', this.filterQuery);
    } else {
      this.filterQuery = '';
      console.log('this.filterQuery2', this.filterQuery);
    }
    // this.loadingRecords = false;
    this.applyFilter();
  }

  keyup(event: any) {
    this.search();
  }
  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find((item) => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'id';
    const sortOrder = (currentSort && currentSort.value) || 'desc';
    console.log(currentSort);
    console.log('sortOrder :' + sortOrder);
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if (this.pageSize2 != pageSize) {
      this.pageIndex = 1;
      this.pageSize2 = pageSize;
    }

    if (this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search();
  }

  // search(reset: boolean = false) {
  //   var filter = '';
  //   if (reset) {
  //     this.pageIndex = 1;
  //   }
  //   // this.loadingRecords = true;
  //   var sort: string;
  //   try {
  //     sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
  //   } catch (error) {
  //     sort = '';
  //   }
  //   console.log('search text : ' + this.searchText);

  //   var likeQuery = '';

  //   if (this.searchText != '') {
  //     likeQuery = ' AND (';
  //     this.columns.forEach((column) => {
  //       likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
  //     });
  //     likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
  //   }

  //   if (
  //     this.BILL_FILIING_DATE != undefined &&
  //     this.BILL_FILIING_DATE.length != 0
  //   ) {
  //     this.START_DATE = this.datePipe.transform(
  //       this.BILL_FILIING_DATE[0],
  //       'yyyy-MM-dd'
  //     );
  //     this.END_DATE = this.datePipe.transform(
  //       this.BILL_FILIING_DATE[1],
  //       'yyyy-MM-dd'
  //     );
  //   }

  //   this.loadingRecords = true;
  //   console.log(this.STAGE_NAME);
  //   this.api
  //     .getclaimMaster(
  //       this.pageIndex,
  //       this.pageSize,
  //       this.sortKey,
  //       sort,
  //       ' AND EMP_ID =' + this.employeeedit,
  //       this.START_DATE,
  //       this.END_DATE,
  //       this.TYPE_OF_HOSPITAL,
  //       this.STAGE_NAME
  //     )
  //     .subscribe(
  //       (data) => {
  //         console.log(data);
  //         this.loadingRecords = false;
  //         this.totalRecords = data['count'];
  //         this.dataList = data['data'];
  //         this.isSpinning = false;
  //         this.filterClass = 'filter-invisible';
  //       },
  //       (err) => {
  //         console.log(err);
  //       }
  //     );
  // }
  userIdFilter;
  search(reset: boolean = false) {
    var filter = '';
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }
    console.log('search text : ' + this.searchText);

    var likeQuery = '';

    if (this.searchText != '') {
      likeQuery = ' AND (';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    }

    if (
      this.BILL_FILIING_DATE != undefined &&
      this.BILL_FILIING_DATE.length != 0
    ) {
      this.START_DATE = this.datePipe.transform(
        this.BILL_FILIING_DATE[0],
        'yyyy-MM-dd'
      );
      this.END_DATE = this.datePipe.transform(
        this.BILL_FILIING_DATE[1],
        'yyyy-MM-dd'
      );
    } else {
      this.START_DATE = null;
      this.END_DATE = null;
    }

    if (Number(sessionStorage.getItem('roleId')) == 3) {
      this.userIdFilter =
        ' AND INSPECTOR_ID = ' + Number(sessionStorage.getItem('userId'));
    } else if (Number(sessionStorage.getItem('roleId')) == 4) {
      this.userIdFilter =
        ' AND AO_ID = ' + Number(sessionStorage.getItem('userId'));
    } else if (Number(sessionStorage.getItem('roleId')) == 2) {
      this.userIdFilter =
        ' AND EMP_ID = ' + Number(sessionStorage.getItem('userId'));
    } else {
      this.userIdFilter = '';
    }
    this.loadingRecords = true;
    console.log(this.STAGE_NAME);
    // this.api
    //   .getClaimMasterCount(
    //     this.pageIndex,
    //     this.pageSize,
    //     this.sortKey,
    //     sort,
    //     this.userIdFilter
    //   )
    //   .subscribe(
    //     (data) => {
    //       console.log(data);
    //       this.loadingRecords = false;
    //       this.allClaimCount = data['count'][0]['ALL_CLAIMS'];
    //       this.allNewCount = data['count'][0]['NEW'];
    //       this.allForwardCount = data['count'][0]['FORWARD'];
    //       this.allRejectCount = data['count'][0]['REJECTED'];
    //       this.allQueryCount = data['count'][0]['QUERY_RAISED'];
    //       this.allApprovedCount = data['count'][0]['APPROVED'];
    //       this.allReadyCount = data['count'][0]['READY'];
    //       // this.dataList = data['data'];
    //       // console.log(data['data']);

    //       // this.isSpinning = false;
    //       // this.filterClass = 'filter-invisible';
    //     },
    //     (err) => {
    //       console.log(err);
    //     }
    //   );
    this.api
      .getclaimMaster(
        this.pageIndex,
        this.pageSize,
        this.sortKey,
        sort,
        this.userIdFilter + this.filterQuery + likeQuery,
        this.START_DATE,
        this.END_DATE,
        this.TYPE_OF_HOSPITAL,
        this.STAGE_NAME
      )
      .subscribe(
        (data) => {
          console.log(data);
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';
        },
        (err) => {
          console.log(err);
        }
      );
  }

  applyFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'primary';
    this.loadingRecords = false;
    var sort: string;

    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }

    // if (this.SALES_MANAGER_ID != undefined) {
    //   // this.SALES_MANAGER_ID = this.SALES_MANAGER_ID;
    // }
    this.BILL_FILIING_DATE[0] = this.datePipe.transform(
      this.BILL_FILIING_DATE[0],
      'yyyy-MM-dd'
    );
    this.BILL_FILIING_DATE[1] = this.datePipe.transform(
      this.BILL_FILIING_DATE[1],
      'yyyy-MM-dd'
    );

    if (this.BILL_FILIING_DATE[0] != null) {
      this.START_DATE = this.BILL_FILIING_DATE[0];
    }

    if (this.BILL_FILIING_DATE[1] != null) {
      this.END_DATE = this.BILL_FILIING_DATE[1];
    }

    this.search();
  }

  showFilter() {
    if (this.filterClass === 'filter-visible')
      this.filterClass = 'filter-invisible';
    else this.filterClass = 'filter-visible';
  }

  drawerClose(): void {
    this.currentTab = 0;
    this.isSpinning = false;
    this.drawerVisible = false;
    this.search();
    // window.location.reload();
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  // add(): void {
  //   this.drawerTitle = 'Create New Employee';
  //   this.drawerData = new ApplicantMaster();
  //   this.drawerVisible = true;
  // }

  currentTab;
  getemployee(): void {
    this.isSpinning = true;
    this.currentTab = 0;
    this.drawerTitle = 'Add New Claim ';

    this.api
      .getEmployeeMaster(0, 0, '', '', ' AND ID =' + this.employeeedit)
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            // this.dataList = data['data'];
            this.drawerData = Object.assign({}, data['data'][0]);
            this.isSpinning = false;
            this.drawerVisible = true;
          } else {
            this.message.error('Can,t Load Employee Information', '');
            this.isSpinning = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }
  empID;
  claimid: any;
  claimID;
  Hospitalclaim = [];
  edit(data: any): void {
    this.claimid = data.ID;
    this.isSpinning = false;
    this.currentTab = 0;
    this.drawerTitle = 'Edit Claim Details';
    // this.drawerData = Object.assign({}, data);
    this.api
      .getHospitalMapping(0, 0, 'NAME', 'desc', ' AND CLAIM_ID=' + data.ID)
      .subscribe(
        (data) => {
          if (data['code'] == 200 && data['data'].length > 0)
            this.Hospitalclaim = data['data'];

          // this.investigationDrawerData.CLAIM_HOSPITAL_MAPPING_ID = this.hospitallist[0]['ID']
        },

        (err) => {
          console.log(err);
        }
      );
    this.api
      .getEmployeeMaster(0, 0, '', '', ' AND ID =' + this.employeeedit)
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            // this.dataList = data['data'];
            this.drawerData = Object.assign({}, data['data'][0]);
            // this.drawerVisible = true;
          } else {
            this.message.error('Can,t Load Employee Information', '');
          }
        },
        (err) => {
          console.log(err);
        }
      );

    // this.drawerData3 = Object.assign({}, data);
    // console.log('asjfj', this.drawerData);
    this.empID = data.EMP_ID;
    this.claimID = data.ID;
    this.drawerData['NAME'] = this.drawerData['EMPLOYEE_NAME'];
    this.drawerVisible = true;
  }
  orderDrawerVisible: boolean = false;
  orderDrawerTitle: string;
  orderdata: any;
  amountinwords: any;
  amountinwordsh: any;
  am = 100;
  // openOrderDrawer(data: any): void {
  //   console.log('data', data);
  //   if (data.ADMISSIBLE_AMOUNT == null || data.ADMISSIBLE_AMOUNT == undefined) {
  //     this.message.info('Please Fill Annexure Details First', '');
  //   } else {
  //     this.orderdata = data;
  //     let words = toWords.convert(this.am, { currency: true });
  //     this.orderdata.ADMISSIBLE_AMOUNT = this.orderdata.ADMISSIBLE_AMOUNT;
  //     this.amountinwords = toWords.convert(this.orderdata.ADMISSIBLE_AMOUNT, {
  //       currency: true,
  //     });

  //     let wordss = toWordsen.convert(this.am, { currency: true });
  //     this.orderdata.ADMISSIBLE_AMOUNT = this.orderdata.ADMISSIBLE_AMOUNT;
  //     this.amountinwordsh = toWordsen.convert(
  //       this.orderdata.ADMISSIBLE_AMOUNT,
  //       {
  //         currency: true,
  //       }
  //     );
  //     // console.log(this.amountinwordsh);
  //     // console.log(this.amountinwords);

  //     // console.log(this.orderdata);

  //     this.orderDrawerTitle = 'Order Details';
  //     this.orderDrawerVisible = true;
  //   }
  // }

  orderDrawerClose(): void {
    this.orderDrawerVisible = false;
  }

  get orderDrawerCloseCallback() {
    return this.orderDrawerClose.bind(this);
  }

  isVisible: boolean = false;

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  investigationDrawerVisible: boolean = false;
  investigationDrawerTitle: string;
  investigationDrawerData: InvestigationMaster = new InvestigationMaster();
  showlayoutDataList = [];
  total = 0;
  total1 = 0;
  openInvestigationDrawer(data: any): void {
    this.claimData = data;
    this.investigationDrawerTitle = 'Create New Investigation';
    this.investigationDrawerData = new InvestigationMaster();

    this.api
      .getannexture(
        0,
        0,
        'ID',
        'ASC',
        ' AND STATUS=1 AND CLAIM_ID=' + this.claimData.ID
      )
      .subscribe(
        (data) => {
          this.showlayoutDataList = data['data'];

          for (var i = 0; this.showlayoutDataList.length > i; i++) {
            this.total =
              this.total + this.showlayoutDataList[i]['CLAIMED_AMOUNT'];
            this.total1 =
              this.total1 + this.showlayoutDataList[i]['ADMISSIBLE_AMOUNT'];
          }
          this.investigationDrawerVisible = true;
        },
        (err) => {
          console.log(err);
          this.isSpinning = false;
        }
      );
  }

  investigationDrawerClose(): void {
    this.investigationDrawerVisible = false;
  }

  get investigationDrawerCloseCallback() {
    return this.investigationDrawerClose.bind(this);
  }

  drawerLogVisible: boolean = false;
  drawerLogTitle: string = '';
  drawerLogData = [];

  LOGS1 = [];
  type;
  logData: any = [];
  FILE_OR_CLAIM: boolean = false;
  openLogDrawer(data): void {
    console.log('data', data);

    this.LOGS1 = data;
    this.drawerLogTitle = 'Claim Log Details';
    this.FILE_OR_CLAIM = false;
    this.filename = this.LOGS1['FILE_NUMBER'];
    this.api
      .getclaimLogs(0, 0, 'ID', 'asc', ' AND CLAIM_ID = ' + data.ID)
      .subscribe(
        (userData1) => {
          if (userData1['count'] == 0) {
            this.logData = [];
          } else {
            this.logData = userData1['data'];
          }
        },
        (err) => {
          console.log(err);
        }
      );
    this.drawerLogVisible = true;
    this.type = 'e';
  }

  drawerLogClose(): void {
    this.search();
    this.drawerLogVisible = false;
  }

  get closeLogCallback() {
    return this.drawerLogClose.bind(this);
  }

  // applyStageFilter(stageId: string) {
  //   var filterId = stageId.split(',');
  //   var filterQuery = ' AND (';
  //   for (var i = 0; i < filterId.length; i++) {
  //     filterQuery = filterQuery + ' CURRENT_STAGE_ID =' + filterId[i] + ' OR ';
  //   }
  //   filterQuery = filterQuery.substring(0, filterQuery.length - 3) + ')';
  //   this.filterQuery = this.filterQuery + filterQuery;

  //   // this.applyFilter();
  // }

  confirm(data: any, i: any) {
    data.CURRENT_STAGE_ID = 6;
    // this.api.updateClaim();
  }
  cancel(): void {}

  drawerClaimData: any;
  drawerClaimTitle = '';
  drawerClaimVisible = false;
  QUESTIONARIES: any;
  checkList: any;
  hospitallist = [];
  viewFile(data: any): void {
    this.drawerClaimTitle = 'View Claim File';
    this.drawerClaimData = Object.assign({}, data);
    this.api
      .getHospitalMapping(0, 0, 'NAME', 'ASC', ' AND CLAIM_ID=' + data.ID)
      .subscribe(
        (data) => {
          if (data['code'] == 200 && data['data'].length > 0)
            this.hospitallist = data['data'];
        },
        (err) => {
          console.log(err);
        }
      );
    this.drawerClaimVisible = true;
  }
  drawerClaimClose(): void {
    this.drawerClaimVisible = false;
  }

  get closeClaimCallback() {
    return this.drawerClaimClose.bind(this);
  }

  pdfDownload: boolean = false;

  public generatePDF() {
    var i = 0;
    var date = new Date();
    var datef = this.datePipe.transform(date, 'dd-MM-yyyy');
    var dates = this.datePipe.transform(date, 'hh-mm-ss a');
    var data = document.getElementById('claimFile');

    html2pdf()
      .from(data)
      .set({
        image: { type: 'jpeg', quality: 1.0 },
        html2canvas: { scale: 3, useCORS: true },
        margin: [5, 10, 2, 5],
        pagebreak: { mode: ['css', 'legecy'] },
        jsPDF: { unit: 'mm', format: 'A4', orientation: 'portrait' },
      })
      .toPdf()
      .get('pdf')
      .then(function (pdf) {
        this.pdfDownload = true;
        var totalPages = pdf.internal.getNumberOfPages();

        for (i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(12);
          pdf.setTextColor(150);
          pdf.text(i.toString(), pdf.internal.pageSize.getWidth() / 2, 10);
        }

        this.pdfDownload = false;
      })
      .save(this.filename + '_' + datef + '_' + dates + '.pdf');
  }

  drawerClose1(): void {
    this.drawerLogVisible = false;
    this.search(); // window.location.reload();
  }

  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }
}
