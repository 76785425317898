export class foodtable {
  ID: any;
  TOUR_ID: any = '';
  IS_VOUCHER: any = '';
  VOUCHER_URL: any = '';
  EXPENSE_AMOUNT: any = '';
  EXPENSE_DATE: any = '';
  FOOD_ADMISIBLE_AMOUNT: any = '';
  ALLOWED_PERCENTAGE: any = '';
  START_TIME: any = '';
  END_TIME: any = '';
  IS_LONG_JOURNEY: any = false;
  JOURNEY_END_DATE: any = '';
}
