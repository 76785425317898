<div nz-row nzType="flex" style="margin-bottom: 5px">
  <div
    nz-col
    nzXs="24"
    nzSm="24"
    nzMd="20"
    nzLg="20"
    nzXl="12"
    style="padding-left: 30px"
  >
    <div class="formname">{{ formTitle }}</div>
  </div>

  <div
    nz-col
    nzXs="24"
    nzSm="24"
    nzMd="4"
    nzLg="4"
    nzXl="12"
    style="display: flex; justify-content: flex-end"
  >
    <form nz-form [nzLayout]="'inline'">
      <nz-form-item>
        <button
          nz-button
          nzTooltipTitle="Filter"
          nzTooltipPlacement="top"
          nz-tooltip
          [nzType]="isFilterApplied"
          (click)="showFilter()"
        >
          <i nz-icon nzType="filter"></i>
        </button>
      </nz-form-item>

      <nz-form-item style="width: 40%">
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
          <input
            type="text"
            name="aboutsearch"
            (keyup)="keyup($event)"
            (keydown.enter)="onKeypressEvent($event)"
            [(ngModel)]="searchText"
            nz-input
            placeholder=" Search Record"
          />
        </nz-input-group>
        <ng-template #suffixIconButton>
          <button
            nz-button
            nzType="primary"
            (click)="search(true)"
            id="button"
            nzSearch
          >
            <i nz-icon nzType="search"></i>
          </button>
        </ng-template>
      </nz-form-item>
      <nz-form-item>
        <button
          nz-button
          nzType="primary"
          (click)="getemployee()"
          nzJustify="end"
        >
          <i nz-icon nzType="plus"></i>Add New Claim
        </button>
      </nz-form-item>
    </form>
  </div>
</div>

<div nz-row>
  <div nz-col nzSpan="24">
    <div class="filter {{ filterClass }}">
      <div class="filter-box" style="margin-bottom: 5px">
        <form nz-form>
          <div nz-row nzGutter="8">
            <div nz-col nzSpan="7">
              <nz-form-item style="margin-top: 1px; width: 100%">
                <nz-form-label nzNoColon>Select Date</nz-form-label>
                <nz-range-picker
                  name="date"
                  nzFormat="dd MMM yyyy"
                  [(ngModel)]="BILL_FILIING_DATE"
                >
                </nz-range-picker>
              </nz-form-item>
            </div>

            <!-- <div nz-col nzSpan="5">
              <nz-form-item>
                <nz-form-label nzNoColon>Hospital Type</nz-form-label>
                <nz-select nzShowSearch nzAllowClear name="hospitalType" [(ngModel)]="TYPE_OF_HOSPITAL"
                  nzPlaceHolder="Select Hospital Type">
                  <nz-option nzLabel="Empanelled" nzValue="1"></nz-option>
                  <nz-option nzLabel="Non-Empanelled" nzValue="2"></nz-option>
                  <nz-option nzLabel="Governemnt" nzValue="3"></nz-option>
                </nz-select>
              </nz-form-item>
            </div> -->
            <!-- [(ngModel)]="STAGE_ID" -->
            <!-- <div nz-col nzSpan="5">
              <nz-form-item>
                <nz-form-label nzNoColon>Stage Name</nz-form-label>
                <nz-select name="stageName" nzShowSearch nzAllowClear [(ngModel)]="STAGE_NAME"
                  nzPlaceHolder="Select Stage Name" nzMode="multiple">
                  <nz-option *ngFor="let stage of stages" [nzValue]="stage.ID" [nzLabel]="stage.NAME">
                  </nz-option>
                </nz-select>
              </nz-form-item>
            </div> -->

            <div nz-col nzSpan="8" style="margin-top: 32px">
              <nz-form-item>
                <button
                  style="margin-right: 15px"
                  nz-button
                  [nzLoading]="isSpinning"
                  nzType="primary"
                  (click)="applyFilter()"
                  nzJustify="end"
                >
                  <i nz-icon nzType="filter"></i>Apply filter
                </button>
                <button nz-button nzType="primary" (click)="clearFilter()">
                  <i nz-icon nzType="filter"></i>Clear Filter
                </button>
              </nz-form-item>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- <div nz-row>
  <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
    <div style="text-align: center">
      <span>
        <button (click)="applyStageFilter('ALL', ' ')" nz-button [nzType]="totalButton"
          style="border: 1px solid blue; color: blue">
          All : {{ allClaimCount }}
        </button>
      </span>

      <span>
        <button (click)="applyStageFilter('N', '3,4')" nz-button [nzType]="newButton"
          style="border: 1px solid blue; color: blue">
          New : {{ allNewCount }}
        </button>
      </span>

      <span>
        <button (click)="applyStageFilter('F', '6,7,9,11,15')" nz-button [nzType]="forwardButton"
          style="border: 1px solid #000000; color: black">
          Forwarded : {{ allForwardCount }}
        </button>
      </span>
      <span>
        <button (click)="applyStageFilter('R', '5')" nz-button [nzType]="rejectButton"
          style="border: 1px solid #f70000; color: red">
          Rejected : {{ allRejectCount }}
        </button>
      </span>
      <span>
        <button (click)="applyStageFilter('Q', '8,10,12,14,16')" nz-button [nzType]="queryButton"
          style="border: 1px solid blue; color: blue">
          Queried : {{ allQueryCount }}
        </button>
      </span>
      <span>
        <button (click)="applyStageFilter('A', '17')" nz-button [nzType]="approvedButton"
          style="border: 1px solid #000000; color: black">
          Approved : {{ allApprovedCount }}
        </button>
      </span>
      <span>
        <button (click)="applyStageFilter('Z', '13')" nz-button [nzType]="readyButton"
          style="border: 1px solid red; color: red">
          Ready For Zonal : {{ allReadyCount }}
        </button>
      </span>
    </div>
  </div>
</div> -->

<div nz-row>
  <div nz-col nzSpan="24">
    <nz-table
      class="my-scroll no-wrap1"
      nzBordered
      #basicTable
      nzShowSizeChanger
      [nzFrontPagination]="false"
      [nzData]="dataList"
      [nzLoading]="loadingRecords"
      [nzSize]="'small'"
      [nzScroll]="{ x: '2400px', y: '410px' }"
      [nzTotal]="totalRecords"
      [(nzPageIndex)]="pageIndex"
      [(nzPageSize)]="pageSize"
      (nzPageIndexChange)="search()"
      (nzPageSizeChange)="search(true)"
      (nzQueryParams)="sort($event)"
    >
      <thead>
        <tr style="text-align: center">
          <th nzWidth="120px">Action</th>
          <th nzWidth="80px">Logs</th>
          <th nzWidth="250px" nzColumnKey="CLAIM_STAGE_NAME" [nzSortFn]="true">
            Status
          </th>
          <th nzWidth="110px" nzColumnKey="CLAIM_NO" [nzSortFn]="true">
            Claim No
          </th>
          <th nzWidth="170px" nzColumnKey="EMPLOYEE_NAME" [nzSortFn]="true">
            Applicant Name
          </th>
          <th nzWidth="170px" nzColumnKey="EMPLOYEE_CODE" [nzSortFn]="true">
            Employee Code
          </th>
          <th
            nzWidth="190px"
            nzColumnKey="EMPLOYEE_DESIGNATION"
            [nzSortFn]="true"
          >
            Employee Designation
          </th>
          <th nzWidth="190px" nzColumnKey="PATIENT_NAME" [nzSortFn]="true">
            Patient Name
          </th>
          <th
            nzWidth="180px"
            nzColumnKey="RELATION_WITH_PATIENT"
            [nzSortFn]="true"
          >
            Patient Relation
          </th>
          <th nzWidth="160px" nzColumnKey="BILL_FILIING_DATE" [nzSortFn]="true">
            Bill Filling Date
          </th>
          <th nzWidth="200px" nzColumnKey="ADMISSIBLE_AMOUNT" [nzSortFn]="true">
            Admissible Amount(₹)
          </th>

          <th nzWidth="460px" nzColumnKey="TREATEMENT_TYPE" [nzSortFn]="true">
            Treatment Type
          </th>
          <th
            nzWidth="180px"
            nzColumnKey="TREATMENT_START_DATE"
            [nzSortFn]="true"
          >
            Treatment Start Date
          </th>
          <th
            nzWidth="180px"
            nzColumnKey="TREATMENT_END_DATE"
            [nzSortFn]="true"
          >
            Treatment End Date
          </th>
          <!-- <th nzWidth="150px" nzColumnKey="ACCREDITATION" [nzSortFn]="true">
            Accrediation
          </th>

          <th nzWidth="160px" nzColumnKey="HOSPITAL_TYPE" [nzSortFn]="true">
            Hospital Type
          </th>
          <th nzWidth="300px" nzColumnKey="EMPANELLED_HOSPITAL_NAME" [nzSortFn]="true">
            Empanelled Hospital Name
          </th>
          <th nzWidth="300px" nzColumnKey="HOSPITAL_NAME" [nzSortFn]="true">
            Hospital Name
          </th> -->
          <!-- <th nzWidth="25px" nzColumnKey="EMPLOYEE_NAME" [nzSortFn]="true" >Advance Amount</th>
                      <th nzWidth="35px" nzColumnKey="EMPLOYEE_NAME" [nzSortFn]="true" >Date Of Advance Taken</th> -->
          <th nzWidth="160px" nzColumnKey="WARD_TYPE" [nzSortFn]="true">
            Ward Entitlement
          </th>

          <th
            nzWidth="180px"
            nzColumnKey="NATURE_OF_TREATMENT"
            [nzSortFn]="true"
          >
            Nature Of Treatment
          </th>
          <th nzWidth="220px" nzColumnKey="CHECKLIST_STATUS" [nzSortFn]="true">
            Accept / Reject / On Hold
          </th>
          <th nzWidth="300px" nzColumnKey="REJECT_REMARK" [nzSortFn]="true">
            Reject Remark
          </th>
          <th nzWidth="130px" nzColumnKey="ON_HOLD_DATE" [nzSortFn]="true">
            On Hold Date
          </th>
          <th nzWidth="300px" nzColumnKey="ON_HOLD_REMARK" [nzSortFn]="true">
            On Hold Remark
          </th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let data of basicTable.data; let i = index">
          <td>
            <div style="padding: 3px">
              <span
                style="cursor: pointer"
                *ngIf="
                  data.CURRENT_STAGE_ID == 1 ||
                  data.CURRENT_STAGE_ID == 2 ||
                  data.CURRENT_STAGE_ID == 5
                "
                (click)="edit(data)"
              >
                <a style="text-align: center"
                  ><i nz-icon nzType="edit" nzTheme="outline"></i> Edit</a
                >
              </span>
              <a *ngIf="data.CURRENT_STAGE_ID == 3" (click)="viewFile(data)">
                <span nz-icon nzType="eye" nzTheme="outline"></span> Claim Form
              </a>
            </div>
          </td>
          <td align="center">
            <a style="margin-left: 10px" (click)="openLogDrawer(data)">
              <span nz-icon nzType="eye" nzTheme="outline"></span> View
            </a>
          </td>
          <!-- <td align="center">

            <a *ngIf="data.CHECKLIST_STATUS == 'A'" (click)="viewFile(data)">
              <span nz-icon nzType="eye" nzTheme="outline"></span> Claim File
            </a>
          </td> -->
          <!-- <td align="center">
            <a (click)="openLogDrawer()">
              <span nz-icon nzType="eye" nzTheme="outline"></span> View
            </a>
          </td> -->
          <td style="text-align: center; white-space: pre-line">
            {{ data.CLAIM_STAGE_NAME }}
          </td>
          <td style="text-align: center; white-space: pre-line">
            {{ data.CLAIM_NO }}
          </td>
          <td style="text-align: center; white-space: pre-line">
            {{ data.EMPLOYEE_NAME }}
          </td>
          <td style="text-align: center; white-space: pre-line">
            {{ data.EMPLOYEE_CODE }}
          </td>
          <td style="text-align: center; white-space: pre-line">
            {{ data.EMPLOYEE_DESIGNATION }}
          </td>
          <td style="text-align: center; white-space: pre-line">
            {{ data.PATIENT_NAME }}
          </td>
          <td style="text-align: center; white-space: pre-line">
            {{ data.RELATION_WITH_PATIENT }}
          </td>
          <td style="text-align: center; white-space: pre-line">
            {{ data.BILL_FILIING_DATE }}
          </td>
          <td style="text-align: center; white-space: pre-line">
            {{ data.ADMISSIBLE_AMOUNT }}
          </td>

          <!-- <td style="text-align: center;white-space: pre-line;" >{{ data.TREATEMENT_TYPE }}</td> -->
          <td style="text-align: center; white-space: pre-line">
            <span nz-icon *ngIf="data['TREATEMENT_TYPE'] == 'OT'"
              >OPD Treatment / Test Entitled</span
            >
            <span nz-icon *ngIf="data['TREATEMENT_TYPE'] == 'IT'"
              >Indoor Treatment</span
            >
            <span nz-icon *ngIf="data['TREATEMENT_TYPE'] == 'OT,IT'"
              >OPD Treatment / Test Entitled & Indoor Treatment</span
            >
          </td>
          <td style="text-align: center; white-space: pre-line">
            {{ data.TREATMENT_START_DATE }}
          </td>
          <td style="text-align: center; white-space: pre-line">
            {{ data.TREATMENT_END_DATE }}
          </td>

          <!-- <td style="text-align: center">
            <span nz-icon *ngIf="data['ACCREDITATION'] == 'N'">NAHB</span>
            <span nz-icon *ngIf="data['ACCREDITATION'] == 'NN'">Non NAHB</span>
            <span nz-icon *ngIf="data['ACCREDITATION'] == 'SP'">Super Speciality</span>
          </td> -->
          <!-- <td style="text-align: center;white-space: pre-line;" >{{ data.HOSPITAL_TYPE }}</td> -->
          <!-- <td style="text-align: center">
            <span nz-icon *ngIf="data['HOSPITAL_TYPE'] == 'E'">Empanelled</span>
            <span nz-icon *ngIf="data['HOSPITAL_TYPE'] == 'NE'">Non Empanelled</span>
            <span nz-icon *ngIf="data['HOSPITAL_TYPE'] == 'G'">Government</span>
          </td>
          <td style="text-align: center; white-space: pre-line">
            {{ data.EMPANELLED_HOSPITAL_NAME }}
          </td>
          <td style="text-align: center; white-space: pre-line">
            {{ data.HOSPITAL_NAME }}
          </td> -->
          <!-- <td style="text-align: center;white-space: pre-line;" >{{ data.ADVANCE_AMOUNT }}</td>
                      <td style="text-align: center;white-space: pre-line;" >{{ data.DATE_OF_ADVANCE_TAKEN }}</td> -->

          <td style="text-align: center">
            <span nz-icon *ngIf="data['WARD_TYPE'] == 'P'">Pvt </span>
            <span nz-icon *ngIf="data['WARD_TYPE'] == 'S'">Semi Pvt</span>
            <span nz-icon *ngIf="data['WARD_TYPE'] == 'G'">General</span>
          </td>
          <td style="text-align: center; white-space: pre-line">
            {{ data.NATURE_OF_TREATMENT }}
          </td>
          <td style="text-align: center">
            <span nz-icon *ngIf="data['CHECKLIST_STATUS'] == 'A'">
              Claim Accept
            </span>
            <span nz-icon *ngIf="data['CHECKLIST_STATUS'] == 'R'"
              >Claim Reject</span
            >
            <span nz-icon *ngIf="data['CHECKLIST_STATUS'] == 'H'"
              >Claim On Hold</span
            >
          </td>
          <td style="text-align: center; white-space: pre-line">
            {{ data.REJECT_REMARK }}
          </td>
          <td style="text-align: center; white-space: pre-line">
            {{ data.ON_HOLD_DATE }}
          </td>
          <td style="text-align: center; white-space: pre-line">
            {{ data.ON_HOLD_REMARK }}
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>

<div *ngIf="drawerVisible">
  <nz-drawer
    [nzBodyStyle]="{
      height: 'calc(100% - 55px)',
      overflow: 'auto',
      'padding-bottom': '53px'
    }"
    [nzClosable]="false"
    [nzMask]="true"
    [nzMaskClosable]="false"
    [nzWidth]="1000"
    [nzVisible]="drawerVisible"
    [nzTitle]="drawerTitle"
    (nzOnClose)="drawerClose()"
    (nzKeyboard)="(true)"
  >
    <app-employeeclaimadd
      [drawerClose]="closeCallback"
      [data]="drawerData"
      [data2]="drawerData3"
      [empID]="empID"
      [isSpinning]="isSpinning"
      [claimID]="claimID"
      [current]="currentTab"
      [Hospitalclaim]="Hospitalclaim"
      [claimid]="claimid"
    ></app-employeeclaimadd>
  </nz-drawer>
</div>

<nz-drawer
  [nzBodyStyle]="{
    height: 'calc(100% - 55px)',
    overflow: 'auto',
    'padding-bottom': '53px'
  }"
  [nzClosable]="true"
  [nzMask]="true"
  [nzMaskClosable]="false"
  [nzWidth]="1100"
  [nzVisible]="drawerClaimVisible"
  [nzTitle]="drawerClaimTitle"
  (nzOnClose)="drawerClaimClose()"
  (nzKeyboard)="(true)"
>
  <div *ngIf="drawerClaimData != undefined">
    <div style="text-align: right; margin-bottom: 5px">
      <button
        nz-button
        nzType="default"
        [useExistingCss]="true"
        printSectionId="claimFile"
        ngxPrint
      >
        Print
      </button>

      <button
        nz-button
        nzType="primary"
        [nzLoading]="pdfDownload"
        (click)="generatePDF()"
        style="margin-left: 20px"
      >
        Download
      </button>
    </div>

    <div style="height: 80vh; overflow-y: scroll">
      <div id="claimFile" #claimFile>
        <div style="font-family: Arial, Helvetica, sans-serif !important">
          <div
            style="
              text-align: right;
              font-size: 12px;
              margin-bottom: 10px;
              margin-top: 10px;
              padding-right: 10px;
            "
          >
            FORM - MRC (S) <br />
            <i>(For serving employees)</i>
          </div>
          <div
            style="
              text-align: center;
              font-weight: bold;
              font-size: 20px;
              margin-top: 10px;
            "
          >
            <u>CENTRAL GOVERNMENT HEALTH SCHEME</u>
          </div>

          <div
            style="
              text-align: center;

              margin-bottom: 10px;
            "
          >
            <u style="font-weight: bold; font-size: 16px !important"
              >MEDICAL REIMBURSEMENT CLAIM FORM</u
            >
            <p>
              (To be filled up by the Principal Card holder in BLOCK LETTERS)
            </p>
          </div>

          <table
            class="table"
            style="
              font-size: 12px;
              page-break-inside: avoid !important;
              line-height: 23px;
            "
          >
            <tr>
              <td style="width: 5%; text-align: center !important">1.</td>
              <td style="width: 46%">
                (a) Name of the Principal CGHS Card Holder
              </td>
              <td style="width: 4%; text-align: center !important">:</td>
              <td style="width: 45%; text-transform: uppercase">
                {{ drawerClaimData.EMPLOYEE_NAME }}
              </td>
            </tr>
            <tr>
              <td style="width: 5%; text-align: center !important"></td>
              <td style="width: 46%">(b) CGHS Ben ID No.</td>
              <td style="width: 4%; text-align: center !important">:</td>
              <td style="width: 45%; text-transform: uppercase">
                {{ drawerClaimData.CGHS_CARD_NO }}
              </td>
            </tr>

            <tr>
              <td style="width: 5%; text-align: center !important"></td>
              <td style="width: 46%">(c) Employee Code No.</td>
              <td style="width: 4%; text-align: center !important">:</td>
              <td style="width: 45%; text-transform: uppercase">
                {{ drawerClaimData.EMPLOYEE_CODE }}
              </td>
            </tr>

            <tr>
              <td style="width: 5%; text-align: center !important"></td>
              <td style="width: 46%">
                (d) Ward Entitlement — Pvt./Semi-Pvt./General
              </td>
              <td style="width: 4%; text-align: center !important">:</td>
              <td style="width: 45%; text-transform: uppercase">
                <span nz-icon *ngIf="drawerClaimData['WARD_TYPE'] == 'P'"
                  >Pvt
                </span>
                <span nz-icon *ngIf="drawerClaimData['WARD_TYPE'] == 'S'"
                  >Semi Pvt</span
                >
                <span nz-icon *ngIf="drawerClaimData['WARD_TYPE'] == 'G'"
                  >General</span
                >
              </td>
            </tr>
            <tr style="min-height: 40px">
              <td style="width: 5%; text-align: center !important"></td>
              <td style="width: 46%">(e) Full Address</td>
              <td style="width: 4%; text-align: center !important">:</td>
              <td style="width: 45%; text-transform: uppercase">
                {{ drawerClaimData.ADDRESS }}
              </td>
            </tr>
            <tr>
              <td style="width: 5%; text-align: center !important"></td>
              <td style="width: 46%">
                (e) Mobile telephone No. and e-mail address, if any
              </td>
              <td style="width: 4%; text-align: center !important">:</td>
              <td style="width: 45%; text-transform: uppercase">
                {{ drawerClaimData.MOBILE_NO }},
                {{ drawerClaimData.EMAIL_ID }}
              </td>
            </tr>

            <tr>
              <td style="width: 5%; text-align: center !important">2.</td>
              <td style="width: 46%">(a) Patient's Name</td>
              <td style="width: 4%; text-align: center !important">:</td>
              <td style="width: 45%; text-transform: uppercase">
                {{ drawerClaimData.PATIENT_NAME }}
              </td>
            </tr>

            <tr>
              <td style="width: 5%; text-align: center !important"></td>
              <td style="width: 46%">(b) Patient's CGHS Ben ID No.</td>
              <td style="width: 4%; text-align: center !important">:</td>
              <td style="width: 45%; text-transform: uppercase">
                {{ drawerClaimData.CGHS_CARD_NO }}
              </td>
            </tr>

            <tr>
              <td style="width: 5%; text-align: center !important"></td>
              <td style="width: 46%">
                (c) Relationship with the Principal CGHS card holder
              </td>
              <td style="width: 4%; text-align: center !important">:</td>
              <td style="width: 45%; text-transform: uppercase">
                {{ drawerClaimData.RELATION_WITH_PATIENT }}
              </td>
            </tr>
            <tr>
              <td style="width: 5%; text-align: center !important">3</td>
              <td style="width: 46%">
                Name & address of the hospital / diagnostic center / imaging
                center where treatment is taken or tests done:
              </td>
              <td style="width: 4%; text-align: center !important">:</td>
              <td style="width: 45%; text-transform: uppercase">
                <span *ngFor="let hospital of hospitallist">
                  {{ hospital.NAME }}
                  ({{ hospital.ADDRESS }}),
                </span>
              </td>
            </tr>

            <tr>
              <td style="width: 5%; text-align: center !important">4</td>
              <td style="width: 46%">
                Whether the hospital/diagnostic/imaging center is empanelled
                under CGHS : Yes/No
              </td>
              <td style="width: 4%; text-align: center !important">:</td>
              <td style="width: 45%; text-transform: uppercase">
                <span *ngFor="let hospital of hospitallist">
                  {{
                    hospital.TYPE == "E"
                      ? "Yes"
                      : hospital.TYPE == "NE"
                      ? "No"
                      : hospital.TYPE == "G"
                      ? "No"
                      : ""
                  }},
                </span>
              </td>
            </tr>
            <tr>
              <td style="width: 5%; text-align: center !important">5</td>
              <td style="width: 46%">
                Treatment for which reimbursement claimed
                <br />
                (a) OPD Treatment /Test & investigations
                <br />
                (b) Indoor Treatment
              </td>
              <td style="width: 4%; text-align: center !important">:</td>
              <td style="width: 45%; text-transform: uppercase">
                <span nz-icon *ngIf="drawerClaimData['TREATEMENT_TYPE'] == 'OT'"
                  >OPD Treatment / Test Entitled</span
                >
                <span nz-icon *ngIf="drawerClaimData['TREATEMENT_TYPE'] == 'IT'"
                  >Indoor Treatment</span
                >
                <span
                  nz-icon
                  *ngIf="drawerClaimData['TREATEMENT_TYPE'] == 'OT,IT'"
                  >OPD Treatment / Test Entitled & Indoor Treatment</span
                >
              </td>
            </tr>

            <tr>
              <td style="width: 5%; text-align: center !important">6.</td>
              <td style="width: 46%">
                Whether treatment was taken in emergency
              </td>
              <td style="width: 4%; text-align: center !important">:</td>
              <td style="width: 45%; text-transform: uppercase">
                <span *ngIf="drawerClaimData.EMERGENCY_TREATEMENT == 1">
                  Yes
                </span>
                <span *ngIf="drawerClaimData.EMERGENCY_TREATEMENT == 0">
                  No
                </span>
              </td>
            </tr>

            <tr>
              <td style="width: 5%; text-align: center !important">7.</td>
              <td style="width: 46%">
                Whether prior permission was taken for the treatment
              </td>
              <td style="width: 4%; text-align: center !important">:</td>
              <td style="width: 45%; text-transform: uppercase">
                <span *ngIf="drawerClaimData.IS_PERMISSION_TAKEN_FROM_HOD == 1">
                  Yes
                </span>
                <span *ngIf="drawerClaimData.IS_PERMISSION_TAKEN_FROM_HOD == 0">
                  No
                </span>
              </td>
            </tr>

            <tr>
              <td style="width: 5%; text-align: center !important">8.</td>
              <td style="width: 46%">
                Whether subscribing to any health/medical insurance scheme, If
                yes, amount claimed/received
              </td>
              <td style="width: 4%; text-align: center !important">:</td>
              <td style="width: 45%; text-transform: uppercase"></td>
            </tr>

            <tr>
              <td style="width: 5%; text-align: center !important">9.</td>
              <td style="width: 46%">
                Details of Medical Advance taken, if any
              </td>
              <td style="width: 4%; text-align: center !important">:</td>
              <td style="width: 45%; text-transform: uppercase">
                <span *ngIf="drawerClaimData.IS_ADVANCE_TAKEN == 1">
                  Yes, ₹ {{ drawerClaimData.ADVANCE_AMOUNT }}
                </span>
                <span *ngIf="drawerClaimData.IS_ADVANCE_TAKEN == 0"> No </span>
              </td>
            </tr>
            <tr>
              <td style="width: 5%; text-align: center !important">10.</td>
              <td style="width: 46%"><b>Total amount claimed</b></td>
              <td style="width: 4%; text-align: center !important">:</td>
              <td style="width: 45%; text-transform: uppercase"></td>
            </tr>
            <tr>
              <td style="width: 5%; text-align: center !important"></td>
              <td style="width: 46%">(a) OPD Treatment</td>
              <td style="width: 4%; text-align: center !important">:</td>
              <td style="width: 45%; text-transform: uppercase"></td>
            </tr>

            <tr>
              <td style="width: 5%; text-align: center !important"></td>
              <td style="width: 46%">(b) Indoor Treatment</td>
              <td style="width: 4%; text-align: center !important">:</td>
              <td style="width: 45%; text-transform: uppercase"></td>
            </tr>

            <tr>
              <td style="width: 5%; text-align: center !important"></td>
              <td style="width: 46%">(c) Tests/Investigation</td>
              <td style="width: 4%; text-align: center !important">:</td>
              <td style="width: 45%; text-transform: uppercase"></td>
            </tr>

            <tr>
              <td style="width: 5%; text-align: center !important">11.</td>
              <td style="width: 46%">
                Name of the Bank :
                <span style="text-transform: uppercase"
                  >{{ drawerClaimData.BANK_NAME }}
                </span>
              </td>
              <td style="width: 4%; text-align: center !important"></td>
              <td style="width: 45%">
                SB A/c No.:
                <span style="text-transform: uppercase">{{
                  drawerClaimData.BANK_ACCOUNT_NO
                }}</span>
              </td>
            </tr>
            <tr>
              <td style="width: 5%; text-align: center !important"></td>
              <td style="width: 46%">
                Branch MICR Code:
                <span style="text-transform: uppercase">{{
                  drawerClaimData.MICR_CODE
                }}</span>
              </td>
              <td style="width: 4%; text-align: center !important"></td>
              <td>
                IFSC Code.:
                <span style="text-transform: uppercase">{{
                  drawerClaimData.IFSC_CODE
                }}</span>
              </td>
            </tr>
          </table>
          <div style="text-align: center; margin-top: 10px">
            <b><u>DECLARATION</u></b>
          </div>
          <div
            style="
              text-align: justify !important;
              margin-top: 10px;
              font-size: 12px;
            "
          >
            I hereby declare that the statements made in the application are
            true to the best of my knowledge and belief and the person for whom
            medical expenses were incurred is wholly dependent on me. I am a
            CGHS beneficiary and the CGHS card was valid at the time of
            treatment. I agree for the reimbursement as is admissible under the
            rules.
          </div>
          <div
            style="
              page-break-inside: avoid !important;
              display: inline-flex;
              width: 100% !important;
              margin-top: 10px;
              font-size: 12px;
              font-weight: bold;
            "
          >
            <div style="width: 40% !important">
              <p>Date : ............</p>

              <p>Place : ............</p>
            </div>
            <div
              style="
                width: 60% !important;
                text-align: center;
                padding-top: 30px;
              "
            >
              <b> Signature of the Principal CGHS card holder </b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nz-drawer>

<div *ngIf="drawerLogVisible">
  <nz-drawer
    [nzBodyStyle]="{
      height: 'calc(100% - 55px)',
      overflow: 'auto',
      'padding-bottom': '53px'
    }"
    [nzClosable]="false"
    [nzMaskClosable]="false"
    [nzWidth]="850"
    [nzKeyboard]="true"
    [nzVisible]="drawerLogVisible"
    [nzTitle]="drawerLogTitle"
    (nzOnClose)="drawerClose1()"
  >
    <app-viewlogs
      [drawerClose1]="closeCallback1"
      [data]="LOGS1"
      [LOGS1]="logData"
      [type]="type"
      [filename]="filename"
      [FILE_OR_CLAIM]="FILE_OR_CLAIM"
    ></app-viewlogs>
  </nz-drawer>
</div>
