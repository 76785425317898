export class Checklisttour {
  TOUR_ID: any;
  ID: any;
  EMP_ID: any;
  ORIGINAL_BILL_SUBMITTED_IN_PROPER_FORMAT: boolean = false;
  BILL_SUBMITTED_IN_PROPER_TIME: boolean = false;
  // DELAYED_SUBMISSION_BILL: boolean = false;
  T_A_ADVANCE_TAKEN: boolean = false;
  S_R_61_SUBMITTED: boolean = false;
  // ELECTION_DUTY: boolean = false;
  // TICKET_BOOKED_THROUGH_TRAVEL_DESK: boolean = false;
  TICKET_SUBMITTED: boolean = false;
  ORIGINAL_BOARDING_PASS_SUBMITTED: boolean = false;
  TICKET_BOOKED_THROUGH_TRAVEL_AGENT: boolean = false;
  FOOD_EXPENSES_CLAIMED: boolean = false;
  ACCOMODATION_BILL_CLAIMED_SUBMITTED: boolean = false;
  TRAVELLING_VOUCHER_SUBMITTED: boolean = false;
  BANK_MANDATE_FORM_SUBMITTED: boolean = false;
  TICKET_BOOKED_THROUGH_TRAVEL_DESK: any;
  ELECTION_DUTY: any;
  DELAYED_SUBMISSION_BILL: any;
}
