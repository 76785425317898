import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { EmployeeMaster } from 'src/app/Models/Employee';
import { QuestionaryMaster } from 'src/app/Models/questionarymaster';
import { ApiService } from 'src/app/Service/api.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { ApplicantMaster } from 'src/app/Models/applicantmaster';
import { CheckList } from 'src/app/Models/checkList';
import { ClaimMaster } from 'src/app/Models/claimmaster';
import { CookieService } from 'ngx-cookie-service';
import { HospitalMappingMaster } from 'src/app/Models/hospitalmappingmaster';

export class FromTOAccount {
  // ID: number;
  CLIENT_ID: number = 1;
  CLAIM_ID: number = 0;
  HOSPITAL_ID: number = 0;
  NAME: String = '';
  ADDRESS: String = '';
  ACCREDATION: String = '';
  TYPE: String = '';
}

@Component({
  selector: 'app-employeeclaimadd',
  templateUrl: './employeeclaimadd.component.html',
  styleUrls: ['./employeeclaimadd.component.css'],
})
export class EmployeeclaimaddComponent implements OnInit {
  constructor(
    private message: NzNotificationService,
    private api: ApiService,
    private datepipe: DatePipe,
    public cookie: CookieService
  ) {}
  FROMACCOUNT: FromTOAccount = new FromTOAccount();
  namepatt = /[a-zA-Z][a-zA-Z ]+/;
  // isSpinning = false;
  isOk = true;
  screenwidth = window.innerWidth;
  userId = sessionStorage.getItem('userId');
  ngOnInit(): void {
    // this.allEmployeeList();
    this.allHospitalList();
    this.current = 0;
    this.isSpinning = false;
  }
  drawerData: ApplicantMaster = new ApplicantMaster();

  @Input()
  drawerVisible: boolean = false;
  @Input() drawerClose: Function;
  @Input() data: ApplicantMaster;
  @Input() data2: ClaimMaster;
  // @Input() data5: ClaimMaster;
  @Input() empID: any;
  @Input() claimID: any;
  @Input() claimid: any;
  @Input() isSpinning = false;
  // @Input() data2: ClaimMaster;
  //  data2: ClaimMaster;
  // @Input() data3: QuestionaryMaster;
  // data2: ClaimMaster = new ClaimMaster();
  data3: QuestionaryMaster = new QuestionaryMaster();
  data5: HospitalMappingMaster = new HospitalMappingMaster();
  // @Input() data4: CheckList;
  //  data4: CheckList;
  data4: CheckList = new CheckList();

  @Input() current = 0;
  employee: EmployeeMaster[] = [];

  Cities: EmployeeMaster[];
  filteredOptions = [];
  Names = EmployeeMaster;
  hospitalList: any = [];
  diffDays: any;
  @Input() Hospitalclaim: any = [];

  TREATEMENT_TYPE1 = false;
  TREATEMENT_TYPE2 = false;

  allEmployeeList1() {
    this.api.getEmployeeMaster(0, 0, '', '', '').subscribe(
      (data) => {
        if (data['code'] == 200) {
          this.employee = data['data'];
          this.filteredOptions = this.employee;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getdata() {
    this.api.getclaimed(0, 0, '', '', '').subscribe(
      (data) => {
        if (data['code'] == 200) {
          if (data['data'].length == 0) {
            this.data2 = new ClaimMaster();
            this.current = 1;
          } else {
            this.data2 = data['data'][0];

            if (this.data2.TREATEMENT_TYPE == '') {
              this.TREATEMENT_TYPE1 = false;
              this.TREATEMENT_TYPE2 = false;
            }
            if (this.data2.TREATEMENT_TYPE == 'IT') {
              this.TREATEMENT_TYPE1 = false;
              this.TREATEMENT_TYPE2 = true;
            }
            if (this.data2.TREATEMENT_TYPE == 'OT') {
              this.TREATEMENT_TYPE1 = true;
              this.TREATEMENT_TYPE2 = false;
            }
            if (this.data2.TREATEMENT_TYPE == 'OT,IT') {
              this.TREATEMENT_TYPE1 = true;
              this.TREATEMENT_TYPE2 = true;
            }
            // this.billInTime()
            // this.empanelledHospital()
            this.current = 1;
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  allHospitalList() {
    this.api.getAllHospital(0, 0, '', '', '').subscribe(
      (data) => {
        if (data['code'] == 200) {
          this.hospitalList = data['data'];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  close(): void {
    this.current = 0;
    this.drawerClose();
  }

  applicantResetDrawer(applicantMasterPages: NgForm) {
    console.log('current', this.current);

    this.data = new ApplicantMaster();
    // this.data.PARENT_GROUP_ID=this.parentgroup;
    // applicantMasterPages.form.markAsPristine();
    // applicantMasterPages.form.markAsUntouched();
    applicantMasterPages.form.reset();
  }
  claimResetDrawer(claimMasterPage: NgForm) {
    this.data2 = new ClaimMaster();
    // this.data.PARENT_GROUP_ID=this.parentgroup;
    // claimMasterPage.form.markAsPristine();
    // claimMasterPage.form.markAsUntouched();
    claimMasterPage.form.reset();
  }

  checkResetDrawer(checkListMasterPage: NgForm) {
    this.data4 = new CheckList();
    // this.data.PARENT_GROUP_ID=this.parentgroup;
    // checkListMasterPage.form.markAsPristine();
    // checkListMasterPage.form.markAsUntouched();
    checkListMasterPage.form.reset();
  }
  // close(applicantMasterPages: NgForm,claimMasterPage: NgForm,queMasterPage: NgForm,checkListMasterPage: NgForm) {
  //   console.log('curren2t',this.current);

  //   this.applicantResetDrawer(applicantMasterPages);
  //   this.claimResetDrawer(claimMasterPage);
  //   this.queResetDrawer(queMasterPage);
  //   this.checkResetDrawer(checkListMasterPage);
  //   // claimMasterPage.form.reset();
  //   this.drawerClose();
  // }

  omit(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  save(addNew: boolean, claimMasterPage: NgForm): void {
    if (this.current == 0) {
      this.current = 1;
    } else if (this.current == 1) {
      this.current = 2;
    } else if (this.current == 2) {
      this.current = 3;
    }
    this.isSpinning = false;
    this.isOk = true;

    if (
      this.data.NAME.trim() == '' &&
      this.data.LOCATION.trim() == '' &&
      // this.data.DDO_OF_THE_OFFICIAL.trim() == '' &&
      this.data.DESIGNATION.trim() == '' &&
      this.data.OFFICE_NAME.trim() == '' &&
      this.data.EMPLOYEE_CODE == 0 &&
      this.data.GRADE_PAY == 0
    ) {
      this.isOk = false;
      this.message.error('Please Fill All The Required Fields ', '');
    } else if (this.data.NAME == null || this.data.NAME.trim() == '') {
      this.isOk = false;
      this.message.error(' Please Enter Employee Name.', '');
    } else if (
      this.data.EMPLOYEE_CODE == undefined ||
      this.data.EMPLOYEE_CODE <= 0
    ) {
      this.isOk = false;
      this.message.error('Please Enter Employee Code  ', '');
    } else if (this.data.GRADE_PAY == undefined || this.data.GRADE_PAY <= 0) {
      this.isOk = false;
      this.message.error('Please Enter Grade Pay  ', '');
    } else if (
      this.data.OFFICE_NAME == null ||
      this.data.OFFICE_NAME.trim() == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Office Name.', '');
    } else if (
      this.data.DESIGNATION == null ||
      this.data.DESIGNATION.trim() == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Designation', '');
    } else if (this.data.LOCATION == null || this.data.LOCATION.trim() == '') {
      this.isOk = false;
      this.message.error(' Please Enter Location', '');
      // } else if (
      //   this.data.DDO_OF_THE_OFFICIAL == null ||
      //   this.data.DDO_OF_THE_OFFICIAL.trim() == ''
      // ) {
      //   this.isOk = false;
      //   this.message.error(' Please Enter Designation', '');
    }

    if (this.isOk) {
      this.isSpinning = true;
      {
        if (this.data.ID) {
          this.api.updateEmployeeMaster(this.data).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information Changed Successfully...', '');
              if (!addNew) this.drawerClose();
              this.isSpinning = false;
            } else {
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
        } else {
          this.api.createEmployeeMaster(this.data).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information Save Successfully...', '');
              if (!addNew) this.drawerClose();
              else {
                this.data = new ApplicantMaster();
                // this.resetDrawer(claimMasterPage);
                // this.data.IMG_URL= '';

                this.api.getEmployeeMaster(0, 0, '', 'desc', '').subscribe(
                  (data) => {
                    // if (data['count']==0){
                    //   this.data.SEQUENCE_NUMBER=1;
                    // }else
                    // {
                    //   this.data.SEQUENCE_NUMBER=data['data'][0]['SEQUENCE_NUMBER']+1;
                    // }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              }
              this.isSpinning = false;
            } else if (successCode['code'] == '300') {
              this.message.error('Email or Mobile No already Registered,', '');
            } else {
              this.message.error('Failed To Fill Information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
  }
  empAllDataForUpdate: any;
  empSave(addNew: boolean, applicantMasterPages: NgForm): void {
    this.isSpinning = false;
    console.log('ID', this.data.ID);
    this.isOk = true;

    if (
      this.data.NAME == undefined &&
      this.data.DESIGNATION == undefined &&
      // this.data.OFFICE_NAME == undefined &&
      this.data.DDO_OF_THE_OFFICIAL == undefined &&
      this.data.EMPLOYEE_CODE == undefined &&
      this.data.GRADE_PAY == 0 &&
      this.data.BENEFICIARY_TYPE == undefined
    ) {
      this.isOk = false;
      this.message.error('Please Fill All The Required Fields ', '');
    } else if (
      this.data.NAME == undefined ||
      this.data.NAME == null ||
      this.data.NAME == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter/Select Employee Name', '');
    } else if (
      this.data.DESIGNATION == undefined ||
      this.data.DESIGNATION == null ||
      this.data.DESIGNATION == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Designation', '');
      // } else if (
      //   this.data.OFFICE_NAME == undefined ||
      //   this.data.OFFICE_NAME == null ||
      //   this.data.OFFICE_NAME == ''
      // ) {
      //   this.isOk = false;
      //   this.message.error(' Please Enter Office Name', '');
    } else if (
      this.data.DDO_OF_THE_OFFICIAL == undefined ||
      this.data.DDO_OF_THE_OFFICIAL == null ||
      this.data.DDO_OF_THE_OFFICIAL == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter DDO Of The Official', '');
    } else if (
      this.data.EMPLOYEE_CODE == undefined ||
      this.data.EMPLOYEE_CODE == null ||
      this.data.EMPLOYEE_CODE == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Employee Code', '');
    } else if (
      this.data.GRADE_PAY == undefined ||
      this.data.GRADE_PAY == null ||
      this.data.GRADE_PAY == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Basic Pay ', '');
    } else if (
      this.data.MOBILE_NO == undefined ||
      this.data.MOBILE_NO == null ||
      this.data.MOBILE_NO == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Mobile Number', '');
    } else if (
      this.data.BENEFICIARY_TYPE == undefined ||
      this.data.BENEFICIARY_TYPE == null ||
      this.data.BENEFICIARY_TYPE == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Select Beneficiary Type', '');
    } else if (
      this.data.BENEFICIARY_TYPE == 'CG' &&
      (this.data.CGHS_CARD_NO == undefined ||
        this.data.CGHS_CARD_NO == null ||
        this.data.CGHS_CARD_NO == '')
    ) {
      this.isOk = false;
      this.message.error(' Please Enter CGHS Card Number.', '');
      // } else if (
      //   this.data.BENEFICIARY_TYPE == 'CG' &&
      //   (this.data.CGHS_CARD_VALIDITY == undefined ||
      //     this.data.CGHS_CARD_VALIDITY == null)
      // ) {
      //   this.isOk = false;
      //   this.message.error('Please Enter CGHS Card Validity  ', '');
    }

    if (this.isOk) {
      this.isSpinning = true;
      if (
        this.data.EMAIL_ID == undefined ||
        this.data.EMAIL_ID == null ||
        this.data.EMAIL_ID == '' ||
        this.data.EMAIL_ID.trim() == ''
      ) {
        this.data.EMAIL_ID = null;
      } else {
        this.data.EMAIL_ID = this.data.EMAIL_ID;
      }
      if (
        this.data.EMPLOYEE_CODE == undefined ||
        this.data.EMPLOYEE_CODE == null ||
        this.data.EMPLOYEE_CODE == '' ||
        this.data.EMPLOYEE_CODE.trim() == ''
      ) {
        this.data.EMPLOYEE_CODE = ' ';
      } else {
        this.data.EMPLOYEE_CODE = this.data.EMPLOYEE_CODE;
      }

      if (
        this.data.MOBILE_NO == undefined ||
        this.data.MOBILE_NO == null ||
        this.data.MOBILE_NO == '' ||
        this.data.MOBILE_NO.trim() == ''
      ) {
        this.data.MOBILE_NO = ' ';
      } else {
        this.data.MOBILE_NO = this.data.MOBILE_NO;
      }

      if (
        this.data.ADDRESS == undefined ||
        this.data.ADDRESS == null ||
        this.data.ADDRESS == '' ||
        this.data.ADDRESS.trim() == ''
      ) {
        this.data.ADDRESS = ' ';
      } else {
        this.data.ADDRESS = this.data.ADDRESS;
      }

      if (
        this.data.BENEFICIARY_TYPE == 'CS' &&
        (this.data.CGHS_CARD_NO != undefined ||
          this.data.CGHS_CARD_NO != null ||
          this.data.CGHS_CARD_NO != '')
      ) {
        this.data.CGHS_CARD_NO = null;
      } else {
        this.data.CGHS_CARD_NO = this.data.CGHS_CARD_NO;
      }
      if (
        this.data.BENEFICIARY_TYPE == 'CS' &&
        (this.data.CGHS_CARD_VALIDITY != undefined ||
          this.data.CGHS_CARD_VALIDITY != null ||
          this.data.CGHS_CARD_VALIDITY != '')
      ) {
        this.data.CGHS_CARD_VALIDITY = null;
      } else {
        this.data.CGHS_CARD_VALIDITY = this.data.CGHS_CARD_VALIDITY;
      }
      this.data.CGHS_CARD_VALIDITY = this.datepipe.transform(
        this.data.CGHS_CARD_VALIDITY,
        'yyyy-MM-dd'
      );
      {
        if (this.data.ID) {
          console.log('ID2', this.data.ID);
          console.log('ID2', this.data.ID);

          // if(this.empID != undefined){
          //   this.empAllDataForUpdate = {
          //     ID: this.empID,
          //     CLIENT_ID: 1,
          //     NAME: this.data.NAME,
          //     EMPLOYEE_CODE: this.data.EMPLOYEE_CODE,
          //     GRADE_PAY: this.data.GRADE_PAY,
          //     OFFICE_NAME: this.data.OFFICE_NAME,
          //     DESIGNATION: this.data.DESIGNATION,
          //     LOCATION: this.data.LOCATION,
          //     DDO_OF_THE_OFFICIAL: this.data.DDO_OF_THE_OFFICIAL,
          //     BENEFICIARY_TYPE: this.data.BENEFICIARY_TYPE,
          //     CGHS_CARD_NO: this.data.CGHS_CARD_NO,
          //     CGHS_CARD_VALIDITY: this.data.CGHS_CARD_VALIDITY,
          //     EMP_ID: this.empID,
          //     CLAIM_ID: this.claimID,
          //     MOBILE_NO: this.data.MOBILE_NO,
          //     EMAIL_ID: this.data.EMAIL_ID,
          //     STATUS: true,
          //     ADDRESS: this.data.ADDRESS,
          //     INSPECTOR_ID:0
          //   };
          // }
          // else{
          //   this.empAllDataForUpdate = {
          //     ID: this.empID,
          //     CLIENT_ID: 1,
          //     NAME: this.data.NAME,
          //     EMPLOYEE_CODE: this.data.EMPLOYEE_CODE,
          //     GRADE_PAY: this.data.GRADE_PAY,
          //     OFFICE_NAME: this.data.OFFICE_NAME,
          //     DESIGNATION: this.data.DESIGNATION,
          //     LOCATION: this.data.LOCATION,
          //     DDO_OF_THE_OFFICIAL: this.data.DDO_OF_THE_OFFICIAL,
          //     BENEFICIARY_TYPE: this.data.BENEFICIARY_TYPE,
          //     CGHS_CARD_NO: this.data.CGHS_CARD_NO,
          //     CGHS_CARD_VALIDITY: this.data.CGHS_CARD_VALIDITY,
          //     EMP_ID: this.data.ID,
          //     CLAIM_ID: this.claimID,
          //     MOBILE_NO: this.data.MOBILE_NO,
          //     EMAIL_ID: this.data.EMAIL_ID,
          //     STATUS: true,
          //     ADDRESS: this.data.ADDRESS,
          //     INSPECTOR_ID:0
          //   };
          // }
          this.data['CLAIM_ID'] = this.claimID;
          this.api.updateEmployeeMaster(this.data).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information Saved Successfully...', '');

              this.empID = successCode.EMPLOYEE;
              this.claimID = successCode.CLAIM;
              console.log(this.claimID, 'this.claimID');
              // this.getDatahospital();
              this.next();

              this.isSpinning = false;
            } else if (successCode.code == '300') {
              this.message.error(
                'Email ID or Mobile Number Already Registered...',
                ''
              );
              this.isSpinning = false;
            } else {
              this.message.error('Information Has Not Saved...', '');
              this.isSpinning = false;
            }
          });
        } else {
          this.api.createEmployeeMaster(this.data).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information Save Successfully...', '');
              this.empID = successCode.EMPLOYEE;
              this.claimID = successCode.CLAIM;
              // this.getDatahospital();
              console.log('this.claimID', this.empID);
              console.log('this.claimID', this.claimID);

              this.isSpinning = false;
            } else if (successCode.code == '300') {
              this.message.error(
                'Email ID or Mobile Number Already Registered...',
                ''
              );
              this.isSpinning = false;
            } else {
              this.message.error('Failed To Fill Information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
  }

  claimSave(addNew: boolean, claimMasterPage: NgForm, stage: string): void {
    // this.data.EMP_ID = this.userId;
    // this.data2.EMP_ID = this.empID;
    console.log(stage, 'this.data.CURRENT_STAGE_ID');
    this.data2.ID = this.claimID;
    // console.log('this.Hospitalclaim', this.Hospitalclaim);
    // console.log('this.data2.hospitalData', this.data2.hospitalData);

    for (var i = 0; this.Hospitalclaim.length > i; i++) {
      this.Hospitalclaim[i]['ID'] = undefined;
      this.Hospitalclaim[i]['CLAIM_ID'] = undefined;
    }
    this.data2.hospitalData = this.Hospitalclaim;
    this.data2.INSPECTOR_ID = 0;
    this.data2.EMP_ID = sessionStorage.getItem('userId');
    this.isSpinning = false;
    this.isOk = true;

    if (
      this.data2.RELATION_WITH_PATIENT == undefined &&
      this.data2.PATIENT_NAME == undefined &&
      this.data2.PATIENT_CGHS_BENEFICIERY_NO == undefined &&
      this.data2.NATURE_OF_TREATMENT == undefined &&
      this.data2.TREATMENT_START_DATE == undefined &&
      this.data2.TREATMENT_END_DATE == undefined &&
      // this.data2.HOSPITAL_TYPE == '' &&
      this.data2.BILL_FILIING_DATE == undefined
    ) {
      this.isOk = false;
      this.message.error('Please Fill All The Required Fields ', '');
    } else if (
      this.data2.RELATION_WITH_PATIENT == undefined ||
      this.data2.RELATION_WITH_PATIENT == null ||
      this.data2.RELATION_WITH_PATIENT == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Select Relation with Patient', '');
    } else if (
      this.data2.PATIENT_NAME == undefined ||
      this.data2.PATIENT_NAME == null ||
      this.data2.PATIENT_NAME == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Patient Name', '');
    } else if (
      this.data2.PATIENT_CGHS_BENEFICIERY_NO == undefined ||
      this.data2.PATIENT_CGHS_BENEFICIERY_NO == null ||
      this.data2.PATIENT_CGHS_BENEFICIERY_NO == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Patient CGHS Beneficiary Number', '');
    } else if (
      this.data2.NATURE_OF_TREATMENT == undefined ||
      this.data2.NATURE_OF_TREATMENT == null ||
      this.data2.NATURE_OF_TREATMENT == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Nature Of Treatment/Test', '');
    } else if (
      this.data2.TREATMENT_START_DATE == undefined ||
      this.data2.TREATMENT_START_DATE == null
    ) {
      this.isOk = false;
      this.message.error(' Please Select Treatment Start Date', '');
    } else if (
      this.data2.TREATMENT_END_DATE == undefined ||
      this.data2.TREATMENT_END_DATE == null
    ) {
      this.isOk = false;
      this.message.error(' Please Select Treatment End Date', '');
    } else if (
      this.data2.IS_ADVANCE_TAKEN == true &&
      (this.data2.ADVANCE_AMOUNT == undefined ||
        this.data2.ADVANCE_AMOUNT == null ||
        this.data2.ADVANCE_AMOUNT == '')
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Advance Amount.', '');
    } else if (
      this.data2.IS_ADVANCE_TAKEN == true &&
      (this.data2.ADVANCE_TAKEN_DATE == undefined ||
        this.data2.ADVANCE_TAKEN_DATE == null)
    ) {
      this.isOk = false;
      this.message.error('Please Select Date Of Advance Taken.  ', '');
    } else if (
      this.data2.hospitalData == undefined ||
      this.data2.hospitalData.length == 0
    ) {
      this.isOk = false;
      this.message.error('Please Add Hospital Information ', '');
    }
    //  else if (
    //   this.data2.HOSPITAL_TYPE == undefined ||
    //   this.data2.HOSPITAL_TYPE == null ||
    //   this.data2.HOSPITAL_TYPE == ''
    // ) {
    //   this.isOk = false;
    //   this.message.error(' Please Select Hospital Type', '');
    // } else if (
    //   this.data2.HOSPITAL_TYPE == 'E' &&
    //   (this.data2.HOSPITAL_ID == undefined ||
    //     this.data2.HOSPITAL_ID == null ||
    //     this.data2.HOSPITAL_ID == '')
    // ) {
    //   this.isOk = false;
    //   this.message.error(' Please Select Hospital Name.', '');
    // } else if (
    //   (this.data2.HOSPITAL_TYPE == 'NE' || this.data2.HOSPITAL_TYPE == 'G') &&
    //   (this.data2.HOSPITAL_NAME == undefined ||
    //     this.data2.HOSPITAL_NAME == null ||
    //     this.data2.HOSPITAL_NAME == '')
    // ) {
    //   this.isOk = false;
    //   this.message.error('Please Enter Hospital Name.  ', '');
    // } else if (
    //   (this.data2.HOSPITAL_TYPE == 'NE' || this.data2.HOSPITAL_TYPE == 'G') &&
    //   (this.data2.CLAIM_ACCREDITATION == undefined ||
    //     this.data2.CLAIM_ACCREDITATION == null ||
    //     this.data2.CLAIM_ACCREDITATION == '')
    // ) {
    //   this.isOk = false;
    //   this.message.error('Please Select Accreditation. ', '');
    // } else if (
    //   this.data2.BILL_FILIING_DATE == undefined ||
    //   this.data2.BILL_FILIING_DATE == null
    // ) {
    //   this.isOk = false;
    //   this.message.error(' Please Select Bill Filling Date', '');
    // }
    if (this.isOk) {
      this.data2['CLAIM_ID'] = this.claimID;
      if (stage == 'V') {
        this.data2.CURRENT_STAGE_ID = 3;
      } else {
      }
      this.isSpinning = true;
      this.data2.TREATMENT_START_DATE = this.datepipe.transform(
        this.data2.TREATMENT_START_DATE,
        'yyyy-MM-dd'
      );
      this.data2.TREATMENT_END_DATE = this.datepipe.transform(
        this.data2.TREATMENT_END_DATE,
        'yyyy-MM-dd'
      );
      this.data2.BILL_FILIING_DATE = this.datepipe.transform(
        this.data2.BILL_FILIING_DATE,
        'yyyy-MM-dd'
      );
      this.data2.ADVANCE_TAKEN_DATE = this.datepipe.transform(
        this.data2.ADVANCE_TAKEN_DATE,
        'yyyy-MM-dd'
      );

      var date1: any = new Date(this.data2.TREATMENT_END_DATE);
      var date2: any = new Date(this.data2.BILL_FILIING_DATE);
      this.diffDays = Math.floor((date2 - date1) / (1000 * 60 * 60 * 24)) + 1;

      console.log(this.data.EMP_ID, 'this.data.EMP_ID');

      {
        if (this.data2.ID) {
          for (var i = 0; this.data2.hospitalData.length > i; i++) {
            this.data2.hospitalData[i]['CLAIM_ID'] = this.claimid;
            console.log('this.data2.hospitalData', this.data2.hospitalData);
          }

          this.api.updateclaimed(this.data2).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information Changed Successfully...', '');
              if (!addNew) {
                this.empID = '';
                this.claimID = '';
                this.TREATEMENT_TYPE1 = false;
                this.TREATEMENT_TYPE2 = false;
                this.drawerClose();
                this.resetDrawer(claimMasterPage);
                this.current = 0;
                this.isSpinning = false;
              }
            } else {
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
        } else {
          this.api.createclaimed(this.data2).subscribe((successCode) => {
            // console.log(this.data.EMP_ID, 'this.data.EMP_ID');
            if (successCode.code == '200') {
              this.message.success('Information Save Successfully...', '');
              if (!addNew) {
                this.empID = '';
                this.claimID = '';
                this.TREATEMENT_TYPE1 = false;
                this.TREATEMENT_TYPE2 = false;
                this.drawerClose();
                this.resetDrawer(claimMasterPage);
                // this.data2 = new ClaimMaster();
                this.current = 0;
                this.isSpinning = false;
              }
            } else {
              this.message.error('Failed To Fill Information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
  }

  checkSave(addNew: boolean, claimMasterPage: NgForm): void {
    this.data4.EMP_ID = this.empID;
    this.data4.CLAIM_ID = this.claimID;
    // console.log('COPY_OF_CGHS_CARD', this.data4.COPY_OF_CGHS_CARD);
    // console.log('DISCHARGE_CARD', this.data4.DISCHARGE_CARD);
    // console.log('FORM_NO_3_MEDICAL_CLAIM', this.data4.FORM_NO_3_MEDICAL_CLAIM);
    // console.log('PRESCIPTION_OF_MEDICINES_PURSCHASED_OUTSIDE', this.data4.PRESCIPTION_OF_MEDICINES_PURSCHASED_OUTSIDE);

    // this.data.NAME = this.name
    // if(this.current == 0){
    //   this.current = 1;
    // }else if(this.current == 1){
    // this.current = 2;
    // } else if(this.current == 2){
    // this.current = 3;
    // }
    this.isSpinning = false;
    this.isOk = true;

    //   if(
    //   this.data.NAME.trim() == '' &&
    //   this.data.LOCATION.trim() == '' &&
    //   this.data.DDO_OF_THE_OFFICIAL.trim() == '' &&
    //   this.data.DESIGNATION.trim() == '' &&
    //   this.data.OFFICE_NAME.trim() == '' &&
    //   this.data.EMPLOYEE_CODE == 0 &&
    //   this.data.GRADE_PAY == 0

    // )
    //   {
    //     this.isOk=false;
    //     this.message.error("Please Fill All The Required Fields " ,"")
    //   }
    //   else
    if (
      this.data4.COPY_OF_CGHS_CARD == undefined ||
      this.data4.COPY_OF_CGHS_CARD == null ||
      this.data4.COPY_OF_CGHS_CARD == ''
    ) {
      this.isOk = false;
      this.message.error(
        'Please Check Copy of CGHS card for both benificiary and patient ',
        ''
      );
    } else if (
      this.data4.DISCHARGE_CARD == undefined ||
      this.data4.DISCHARGE_CARD == null ||
      this.data4.DISCHARGE_CARD == ''
    ) {
      this.isOk = false;
      this.message.error('Please Check Discharge Card ', '');
    } else if (
      this.data4.FORM_NO_3_MEDICAL_CLAIM == undefined ||
      this.data4.FORM_NO_3_MEDICAL_CLAIM == null ||
      this.data4.FORM_NO_3_MEDICAL_CLAIM == ''
    ) {
      this.isOk = false;
      this.message.error(
        'Please Check Form No.3 in case the medical claim is of the officials family member for AMA Benificiery ',
        ''
      );
    } else if (
      this.data4.PRESCIPTION_OF_MEDICINES_PURSCHASED_OUTSIDE == undefined ||
      this.data4.PRESCIPTION_OF_MEDICINES_PURSCHASED_OUTSIDE == null ||
      this.data4.PRESCIPTION_OF_MEDICINES_PURSCHASED_OUTSIDE == ''
    ) {
      this.isOk = false;
      this.message.error(
        'Please Check Prescription of Medicines Purchased From Outside ',
        ''
      );
    } else if (
      this.data4.CHECKLIST_STATUS == 'R' &&
      (this.data4.REJECT_REMARK == undefined ||
        this.data4.REJECT_REMARK == null ||
        this.data4.REJECT_REMARK == '')
    ) {
      this.isOk = false;
      this.message.error(' Please Enter Reject Remark.', '');
    }
    //   else if (this.data.EMPLOYEE_CODE == undefined || this.data.EMPLOYEE_CODE <= 0) {
    //     this.isOk = false;
    //     this.message.error('Please Enter Employee Code  ', '');
    //   }
    //   else if (this.data.GRADE_PAY == undefined || this.data.GRADE_PAY <= 0) {
    //     this.isOk = false;
    //     this.message.error('Please Enter Grade Pay  ', '');
    //   }
    //   else if (this.data.OFFICE_NAME == null || this.data.OFFICE_NAME.trim() == '') {
    //     this.isOk = false;
    //     this.message.error(' Please Enter Office Name.', '');
    //   }
    //   else if (this.data.DESIGNATION == null || this.data.DESIGNATION.trim() == '') {
    //     this.isOk = false;
    //     this.message.error(' Please Enter Designation', '');
    //   }

    //   else if (this.data.LOCATION == null || this.data.LOCATION.trim() == '') {
    //     this.isOk = false;
    //     this.message.error(' Please Enter Location', '');
    //   }
    //   else if (this.data.DDO_OF_THE_OFFICIAL == null || this.data.DDO_OF_THE_OFFICIAL.trim() == '') {
    //     this.isOk = false;
    //     this.message.error(' Please Enter Designation', '');
    //   }

    if (this.isOk) {
      this.isSpinning = true;
      // this.data3.CGHS_AMA_REFERENCE_DATE = this.datepipe.transform(this.data3.CGHS_AMA_REFERENCE_DATE, 'yyyy-MM-dd');
      // this.data3.HOD_PERMISSION_DATE = this.datepipe.transform(this.data3.HOD_PERMISSION_DATE, 'yyyy-MM-dd');
      // this.data3.EXPOST_FACTO_PERMISSION_DATE = this.datepipe.transform(this.data3.EXPOST_FACTO_PERMISSION_DATE, 'yyyy-MM-dd');
      // this.data3.DELAY_CONDOLENCE_DATE = this.datepipe.transform(this.data3.DELAY_CONDOLENCE_DATE, 'yyyy-MM-dd');
      // this.data2.TREATMENT_END_DATE = this.datepipe.transform(this.data2.TREATMENT_END_DATE, 'yyyy-MM-dd');
      // this.data2.BILL_FILIING_DATE = this.datepipe.transform(this.data2.BILL_FILIING_DATE, 'yyyy-MM-dd');
      {
        if (this.data4.ID) {
          this.api.updateChecklist(this.data4).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information Changed Successfully...', '');
              if (!addNew) this.drawerClose();
              this.isSpinning = false;
            } else {
              this.message.error('Information Has Not Changed...', '');
              this.isSpinning = false;
            }
          });
        } else {
          this.api.createChecklist(this.data4).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information Save Successfully...', '');
              if (!addNew) this.drawerClose();
              else {
                // this.data = new ApplicantMaster();
                // this.resetDrawer(claimMasterPage);
                // this.data.IMG_URL= '';
                // this.api.getEmployeeMaster(0,0,'','desc','').subscribe (data =>{
                //   // if (data['count']==0){
                //   //   this.data.SEQUENCE_NUMBER=1;
                //   // }else
                //   // {
                //   //   this.data.SEQUENCE_NUMBER=data['data'][0]['SEQUENCE_NUMBER']+1;
                //   // }
                // },err=>{
                //   console.log(err);
                // })
              }
              this.isSpinning = false;
            } else {
              this.message.error('Failed To Fill Information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
  }
  pre2(): void {
    if (this.current == 2 && this.data.ID > 0) {
      this.current -= 2;
    } else {
      this.current -= 1;
    }
  }

  referanceDateDisabledDate = (current: Date): boolean =>
    differenceInCalendarDays(
      current,
      new Date(this.data2.TREATMENT_START_DATE)
    ) < 0;

  disableReferanceDate() {
    this.referanceDateDisabledDate = (current: Date): boolean =>
      differenceInCalendarDays(
        current,
        new Date(this.data2.BILL_FILIING_DATE)
      ) > 0;
  }
  allEmployeeList() {
    this.api.getEmployeeMaster(0, 0, '', '', ' AND STATUS = 1').subscribe(
      (data) => {
        if (data['code'] == 200) {
          this.employee = data['data'];
          this.filteredOptions = this.employee;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  dataList = [];
  pre(): void {
    if (this.current == 1) {
      this.isSpinning = true;
      this.allEmployeeList();
      this.api
        .getEmployeeMaster(0, 0, '', '', ' AND ID =' + this.empID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              if (data['data'].length == 0) {
                this.current -= 1;
                this.isSpinning = false;
              } else {
                this.data = data['data'][0];
                this.current -= 1;
                this.isSpinning = false;
              }
            } else {
              this.message.error('Something Went Wrong', '');
              this.isSpinning = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else if (this.current == 3) {
      this.isSpinning = true;
      this.disableReferanceDate();
      this.api
        .getAllQuestions(0, 0, '', '', ' AND CLAIM_ID =' + this.claimID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              if (data['data'].length == 0) {
                this.current -= 1;
                this.isSpinning = false;
              } else {
                this.data3 = data['data'][0];
                if (
                  this.data3.CGHS_AMA_REFERENCE_DATE == undefined ||
                  this.data3.CGHS_AMA_REFERENCE_DATE == null ||
                  this.data3.CGHS_AMA_REFERENCE_DATE == '' ||
                  this.data3.CGHS_AMA_REFERENCE_DATE.length == 0
                ) {
                  this.data3.CGHS_AMA_REFERENCE_DATE = [];
                } else {
                  this.data3.CGHS_AMA_REFERENCE_DATE =
                    this.data3.CGHS_AMA_REFERENCE_DATE.split(',');
                }
                this.current -= 1;
                this.isSpinning = false;
              }
            } else {
              this.message.error('Something Went Wrong', '');
              this.isSpinning = false;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else if (this.current == 2) {
      this.isSpinning = true;
      this.allHospitalList();
      this.current -= 1;
      this.isSpinning = false;
    } else {
      this.isSpinning = true;
      this.current -= 1;
      this.isSpinning = false;
    }
  }

  filterdrama(event: any) {
    this.api.getEmployeeMaster(0, 0, '', '', ' AND ID =' + event).subscribe(
      (data) => {
        if (data['code'] == 200) {
          this.data.OFFICE_NAME = data['data'][0]['OFFICE_NAME'];
          this.data.DESIGNATION = data['data'][0]['DESIGNATION'];
          this.data.EMPLOYEE_CODE = data['data'][0]['EMPLOYEE_CODE'];

          this.data.LOCATION = data['data'][0]['LOCATION'];
          this.data.DDO_OF_THE_OFFICIAL =
            data['data'][0]['DDO_OF_THE_OFFICIAL'];
          this.data.GRADE_PAY = data['data'][0]['GRADE_PAY'];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // name
  // onChange(value: string): void {
  //   console.log('value', value)
  //   this.name == value

  //   this.filteredOptions = this.employee.filter(option => option.NAME.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  //   console.log('filteredOptions', this.filteredOptions)

  //   // var a = this.employee.filter(obj => {
  //   //   return obj.NAME == value;
  //   // })
  //   console.log('name', this.name)

  // }

  name = '';
  onChange(value: string): void {
    console.log('value', value);
    this.name = value;
    this.filteredOptions = [];
    this.filteredOptions = this.employee.filter(
      (option) => option.NAME.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
    console.log('filteredOptions', this.filteredOptions);
    if (value != '') {
      if (this.filteredOptions.length > 0) {
        // this.name = this.filteredOptions[0]['NAME'];
        this.data.ID = this.filteredOptions[0]['ID'];
        this.data.OFFICE_NAME = this.filteredOptions[0]['OFFICE_NAME'];
        this.data.DESIGNATION = this.filteredOptions[0]['DESIGNATION'];
        this.data.EMPLOYEE_CODE = this.filteredOptions[0]['EMPLOYEE_CODE'];

        this.data.LOCATION = this.filteredOptions[0]['LOCATION'];
        this.data.DDO_OF_THE_OFFICIAL =
          this.filteredOptions[0]['DDO_OF_THE_OFFICIAL'];
        this.data.GRADE_PAY = this.filteredOptions[0]['GRADE_PAY'];
      } else {
        this.data.ID = undefined;
        this.name = value;
        this.data.OFFICE_NAME = '';
        this.data.DESIGNATION = '';
        this.data.EMPLOYEE_CODE = '';
        this.data.LOCATION = '';
        this.data.DDO_OF_THE_OFFICIAL = '';
        this.data.GRADE_PAY = '';
      }
    } else {
      this.name = value;
      this.data.OFFICE_NAME = '';
      this.data.DESIGNATION = '';
      this.data.EMPLOYEE_CODE = '';
      this.data.LOCATION = '';
      this.data.DDO_OF_THE_OFFICIAL = '';
      this.data.GRADE_PAY = '';
    }
    this.data.NAME = this.name;
  }
  next() {
    if (this.current == 0) {
      this.data2.EMP_ID = this.empID;

      this.data2.EMPLOYEE_NAME = this.data.NAME;
      this.data2.EMPLOYEE_CODE = this.data.EMPLOYEE_CODE;
      this.data2.GRADE_PAY = this.data.GRADE_PAY;
      this.data2.OFFICE_NAME = this.data.OFFICE_NAME;
      this.data2.DESIGNATION = this.data.DESIGNATION;
      this.data2.LOCATION = this.data.LOCATION;
      this.data2.DDO_OF_THE_OFFICIAL = this.data.DDO_OF_THE_OFFICIAL;
      this.data2.BENEFICIARY_TYPE = this.data.BENEFICIARY_TYPE;
      this.data2.CGHS_CARD_NO = this.data.CGHS_CARD_NO;
      this.data2.CGHS_CARD_VALIDITY = this.data.CGHS_CARD_VALIDITY;

      this.data2.MOBILE_NO = this.data.MOBILE_NO;
      this.data2.EMAIL_ID = this.data.EMAIL_ID;
      this.data2.STATUS = true;
      this.data2.ADDRESS = this.data.ADDRESS;

      if (
        this.claimID == undefined ||
        this.claimID == null ||
        this.claimID == 0
      ) {
        this.data2 = new ClaimMaster();
        this.data2.EMP_ID = this.empID;

        this.data2.EMPLOYEE_NAME = this.data.NAME;
        this.data2.EMPLOYEE_CODE = this.data.EMPLOYEE_CODE;
        this.data2.GRADE_PAY = this.data.GRADE_PAY;
        this.data2.OFFICE_NAME = this.data.OFFICE_NAME;
        this.data2.DESIGNATION = this.data.DESIGNATION;
        this.data2.LOCATION = this.data.LOCATION;
        this.data2.DDO_OF_THE_OFFICIAL = this.data.DDO_OF_THE_OFFICIAL;
        this.data2.BENEFICIARY_TYPE = this.data.BENEFICIARY_TYPE;
        this.data2.CGHS_CARD_NO = this.data.CGHS_CARD_NO;
        this.data2.CGHS_CARD_VALIDITY = this.data.CGHS_CARD_VALIDITY;

        this.data2.MOBILE_NO = this.data.MOBILE_NO;
        this.data2.EMAIL_ID = this.data.EMAIL_ID;
        this.data2.STATUS = true;
        this.data2.ADDRESS = this.data.ADDRESS;
        this.Hospitalclaim = [];
        this.current = 1;
        this.isSpinning = false;
      } else {
        this.api.getclaimed(0, 0, '', '', ' AND ID =' + this.claimID).subscribe(
          (data) => {
            if (data['code'] == 200) {
              if (data['data'].length == 0) {
                // this.billInTime()
                // this.empanelledHospital()
                this.data2 = new ClaimMaster();
                this.current = 1;
                this.isSpinning = false;
              } else {
                this.data2 = data['data'][0];

                if (this.data2.TREATEMENT_TYPE == '') {
                  this.TREATEMENT_TYPE1 = false;
                  this.TREATEMENT_TYPE2 = false;
                }
                if (this.data2.TREATEMENT_TYPE == 'IT') {
                  this.TREATEMENT_TYPE1 = false;
                  this.TREATEMENT_TYPE2 = true;
                }
                if (this.data2.TREATEMENT_TYPE == 'OT') {
                  this.TREATEMENT_TYPE1 = true;
                  this.TREATEMENT_TYPE2 = false;
                }
                if (this.data2.TREATEMENT_TYPE == 'OT,IT') {
                  this.TREATEMENT_TYPE1 = true;
                  this.TREATEMENT_TYPE2 = true;
                }
                // this.billInTime()
                // this.empanelledHospital()
                this.current = 1;
              }
              this.data2.EMP_ID = this.empID;

              this.data2.EMPLOYEE_NAME = this.data.NAME;
              this.data2.EMPLOYEE_CODE = this.data.EMPLOYEE_CODE;
              this.data2.GRADE_PAY = this.data.GRADE_PAY;
              this.data2.OFFICE_NAME = this.data.OFFICE_NAME;
              this.data2.DESIGNATION = this.data.DESIGNATION;
              this.data2.LOCATION = this.data.LOCATION;
              this.data2.DDO_OF_THE_OFFICIAL = this.data.DDO_OF_THE_OFFICIAL;
              this.data2.BENEFICIARY_TYPE = this.data.BENEFICIARY_TYPE;
              this.data2.CGHS_CARD_NO = this.data.CGHS_CARD_NO;
              this.data2.CGHS_CARD_VALIDITY = this.data.CGHS_CARD_VALIDITY;

              this.data2.MOBILE_NO = this.data.MOBILE_NO;
              this.data2.EMAIL_ID = this.data.EMAIL_ID;
              this.data2.STATUS = true;
              this.data2.ADDRESS = this.data.ADDRESS;
            }
          },
          (err) => {
            console.log(err);
          }
        );
      }
    } else if (this.current == 1) {
      this.api
        .getAllQuestions(0, 0, '', '', ' AND CLAIM_ID =' + this.claimID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              if (data['data'].length == 0) {
                this.data3 = new QuestionaryMaster();
                this.current = 2;
              } else {
                this.data3 = data['data'][0];
                this.calculateDiff();
                this.billInTime();
                this.current = 2;
              }
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else if ((this.current = 2)) {
      this.api
        .getAllChecklist(0, 0, '', '', ' AND CLAIM_ID =' + this.claimID)
        .subscribe(
          (data) => {
            if (data['code'] == 200) {
              if (data['data'].length == 0) {
                this.data4 = new CheckList();
                this.current = 3;
              } else {
                this.data4 = data['data'][0];
                this.current = 3;
              }
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
    }
  }

  calculateDiff() {
    var date1: any = new Date(this.data2.TREATMENT_END_DATE);
    var date2: any = new Date(this.data2.BILL_FILIING_DATE);
    this.diffDays = Math.floor((date2 - date1) / (1000 * 60 * 60 * 24)) + 1;
  }

  hospitalAddress(event: any) {
    this.api.getAllHospital(0, 0, '', '', ' AND ID = ' + event).subscribe(
      (data) => {
        if (data['code'] == 200) {
          this.data5.ADDRESS = data['data'][0]['ADDRESS'];
          this.data5.NAME = data['data'][0]['NAME'];
          this.data5.ACCREDATION = data['data'][0]['ACCREDITATION'];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  hospitalType(event: any) {
    // console.log('event', event);
    // console.log('this.data2.HOSPITAL_ID', this.data2.HOSPITAL_ID);
    // console.log('this.data2.name', this.data2.HOSPITAL_NAME);

    if (event == 'E') {
      this.data5.ADDRESS = '';
      this.data5.ACCREDATION = '';
      this.data5.NAME = '';
      if (
        this.data5.NAME != undefined ||
        this.data5.NAME != null ||
        this.data5.NAME != ''
      ) {
        this.data5.NAME = '';
      } else {
        this.data5.NAME = '';
      }
    } else if (event == 'NE') {
      this.data5.ADDRESS = '';
      this.data5.ACCREDATION = '';
      this.data5.NAME = '';

      if (
        this.data5.HOSPITAL_ID != undefined ||
        this.data5.HOSPITAL_ID != null
      ) {
        this.data5.HOSPITAL_ID = null;
      } else {
        this.data5.HOSPITAL_ID = null;
      }
    } else {
      this.data5.ADDRESS = '';
      this.data5.ACCREDATION = '';
      this.data5.NAME = '';
      if (
        this.data5.HOSPITAL_ID != undefined ||
        this.data5.HOSPITAL_ID != null
      ) {
        this.data5.HOSPITAL_ID = null;
      } else {
        this.data5.HOSPITAL_ID = null;
      }
    }
  }

  billInTime() {
    var date1: any = new Date(this.data2.TREATMENT_END_DATE);
    var date2: any = new Date(this.data2.BILL_FILIING_DATE);
    this.diffDays = Math.floor((date2 - date1) / (1000 * 60 * 60 * 24)) + 1;

    if (this.data2.IS_ADVANCE_TAKEN == true && this.diffDays <= 30) {
      this.data3.BILL_FILLED_INTIME = true;
    } else if (this.data2.IS_ADVANCE_TAKEN == false && this.diffDays > 182) {
      this.data3.BILL_FILLED_INTIME = false;
    } else {
      this.data3.BILL_FILLED_INTIME = true;
    }
  }

  empanelledHospital() {
    console.log('hhhh', this.data2.HOSPITAL_TYPE);

    if (this.data2.HOSPITAL_TYPE == 'E') {
      this.data3.IS_HOSPITAL_EMPLANELLED = true;
    } else {
      this.data3.IS_HOSPITAL_EMPLANELLED = false;
    }
  }

  isAdvanceTaken(event: any) {
    if (event == false) {
      if (
        this.data2.ADVANCE_AMOUNT != null ||
        this.data2.ADVANCE_AMOUNT != undefined
      ) {
        this.data2.ADVANCE_AMOUNT = null;
      } else {
        this.data2.ADVANCE_AMOUNT = null;
      }
      if (
        this.data2.ADVANCE_TAKEN_DATE != null ||
        this.data2.ADVANCE_TAKEN_DATE != undefined
      ) {
        this.data2.ADVANCE_TAKEN_DATE = null;
      } else {
        this.data2.ADVANCE_TAKEN_DATE = null;
      }
    } else {
    }
  }
  disabledDate = (current: Date): boolean =>
    differenceInCalendarDays(current, new Date(this.data2.TREATMENT_END_DATE)) <
    0;

  disabledDate2 = (current: Date): boolean =>
    differenceInCalendarDays(
      current,
      new Date(this.data2.TREATMENT_START_DATE)
    ) < 0;

  disableBillDate() {
    this.disabledDate = (current: Date): boolean =>
      differenceInCalendarDays(
        current,
        new Date(this.data2.TREATMENT_END_DATE)
      ) < 0;
  }

  treatEndDate() {
    this.disabledDate2 = (current: Date): boolean =>
      differenceInCalendarDays(
        current,
        new Date(this.data2.TREATMENT_START_DATE)
      ) < 0;
  }
  // TREATEMENT_TYPE1 =false;
  // TREATEMENT_TYPE2 =false;

  onItemChecked(checked: boolean) {
    this.TREATEMENT_TYPE1 = checked;
    this.data2.TREATEMENT_TYPE = '';
    if (this.TREATEMENT_TYPE1 == true && this.TREATEMENT_TYPE2 == true) {
      this.data2.TREATEMENT_TYPE = 'OT,IT';
    }
    if (this.TREATEMENT_TYPE1 == false && this.TREATEMENT_TYPE2 == true) {
      this.data2.TREATEMENT_TYPE = 'IT';
    }
    if (this.TREATEMENT_TYPE1 == true && this.TREATEMENT_TYPE2 == false) {
      this.data2.TREATEMENT_TYPE = 'OT';
    }
  }

  onItemChecked2(checked: boolean) {
    this.TREATEMENT_TYPE2 = checked;
    this.data2.TREATEMENT_TYPE = '';
    if (this.TREATEMENT_TYPE1 == true && this.TREATEMENT_TYPE2 == true) {
      this.data2.TREATEMENT_TYPE = 'OT,IT';
    }
    if (this.TREATEMENT_TYPE1 == false && this.TREATEMENT_TYPE2 == true) {
      this.data2.TREATEMENT_TYPE = 'IT';
    }
    if (this.TREATEMENT_TYPE1 == true && this.TREATEMENT_TYPE2 == false) {
      this.data2.TREATEMENT_TYPE = 'OT';
    }
  }

  resetDrawer(claimMasterPage: NgForm) {
    this.data = new ApplicantMaster();
    this.data2 = new ClaimMaster();
    // this.data.PARENT_GROUP_ID=this.parentgroup;
    // claimMasterPage.form.markAsPristine();
    // claimMasterPage.form.markAsUntouched();
    this.current = 0;
  }

  addData(addNew: boolean, form11: NgForm) {
    this.isSpinning = false;
    this.data5['CLAIM_ID'] = this.claimID;
    // console.log(this.data5.TYPE, 'this.data5.TYPE');
    // console.log(this.data5.NAME, 'this.data5.NAME');
    // console.log(this.data5.ACCREDATION, 'this.data5.ACCREDATION');
    // console.log(this.data5.ADDRESS, 'this.data5.ADDRESS');
    this.isOk = true;

    if (this.data5.TYPE == '') {
      this.isOk = false;
      this.message.error('Please Fill All The Required Fields ', '');
    } else if (
      this.data5.TYPE == undefined ||
      this.data5.TYPE == null ||
      this.data5.TYPE == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Select Hospital Type', '');
    } else if (
      this.data5.TYPE == 'E' &&
      (this.data5.HOSPITAL_ID == undefined ||
        this.data5.HOSPITAL_ID == null ||
        this.data5.HOSPITAL_ID == '')
    ) {
      this.isOk = false;
      this.message.error(' Please Select Hospital Name.', '');
    } else if (
      (this.data5.TYPE == 'NE' || this.data5.TYPE == 'G') &&
      (this.data5.NAME == undefined ||
        this.data5.NAME == null ||
        this.data5.NAME == '')
    ) {
      this.isOk = false;
      this.message.error('Please Enter Hospital Name.  ', '');
    } else if (
      (this.data5.TYPE == 'NE' || this.data5.TYPE == 'G') &&
      (this.data5.ACCREDATION == undefined ||
        this.data5.ACCREDATION == null ||
        this.data5.ACCREDATION == '')
    ) {
      this.isOk = false;
      this.message.error('Please Select Accreditation. ', '');
    }
    // else if (
    //   this.data5.BILL_FILIING_DATE == undefined ||
    //   this.data5.BILL_FILIING_DATE == null
    // ) {
    //   this.isOk = false;
    //   this.message.error(' Please Select Bill Filling Date', '');
    // }
    if (this.isOk) {
      // this.isSpinning = true;

      this.FROMACCOUNT = {
        // ID: this.data5.ID,
        CLIENT_ID: 1,
        CLAIM_ID: 0,
        HOSPITAL_ID: this.data5.HOSPITAL_ID,
        NAME: this.data5.NAME,
        TYPE: this.data5.TYPE,
        ACCREDATION: this.data5.ACCREDATION,
        ADDRESS: this.data5.ADDRESS,
      };
      if (this.index > -1) {
        this.Hospitalclaim[this.index] = this.FROMACCOUNT;
      } else {
        this.Hospitalclaim.push(this.FROMACCOUNT);
      }
      this.Hospitalclaim = [...[], ...this.Hospitalclaim];
      // this.data5['hospitalData'] = [this.FROMACCOUNT];
      // console.log(this.data5['hospitalData']);
      form11.form.reset();
      this.index = -1;

      {
        // if (this.data5.ID) {
        //   this.api
        //     .updateemployeehospital(this.data5)
        //     .subscribe((successCode) => {
        //       if (successCode.code == '200') {
        //         this.message.success('Information Changed Successfully...', '');
        //         this.getDatahospital();
        //         this.resetDrawer1(form11);
        //         if ('') {
        //         }
        //         this.isSpinning = false;
        //         // this.search()
        //       } else {
        //         this.message.error('Information Has Not Changed...', '');
        //         this.isSpinning = false;
        //       }
        //     });
        // } else {
        //   this.api
        //     .createemployeehospital(this.data5)
        //     .subscribe((successCode) => {
        //       if (successCode.code == '200') {
        //         this.message.success('Information Save Successfully...', '');
        //         if ('') {
        //         } else {
        //           this.data5 = new HospitalMappingMaster();
        //           this.getDatahospital();
        //           this.resetDrawer1(form11);
        //         }
        //         this.isSpinning = false;
        //       } else {
        //         this.message.error('Failed To Fill Information...', '');
        //         this.isSpinning = false;
        //       }
        //     });
        // }
      }
    }
  }

  getDatahospital() {
    this.api.getHospitalMapping(0, 0, '', 'asc', '').subscribe((data) => {
      if (data['code'] == 200) {
        // this.loadingRecords = false;
        this.Hospitalclaim = data['data'];
      }
    });
  }
  index = -1;
  edit(data5: HospitalMappingMaster, i): void {
    console.log(data5, 'data5');
    this.index = i;
    this.data5 = Object.assign({}, data5);
  }
  resetDrawer1(form11: NgForm) {
    this.data5 = new HospitalMappingMaster();
    // this.data.PARENT_GROUP_ID=this.parentgroup;
    form11.form.markAsPristine();
    form11.form.markAsUntouched();
  }

  relationshipFilter(event) {
    // console.log(event);
    this.isSpinning = true;
    if (event == 'Self') {
      this.data2.PATIENT_NAME = this.data.NAME;
      this.data2.PATIENT_CGHS_BENEFICIERY_NO = this.data.CGHS_CARD_NO;
      this.isSpinning = false;
    } else {
      this.data2.PATIENT_CGHS_BENEFICIERY_NO = '';
      this.data2.PATIENT_NAME = '';
      this.isSpinning = false;
    }
  }

  // confirmDeleteHospital(data: any) {
  //   console.log('data', data);
  //   console.log('i', data.ID);
  //   var hospitalData = {
  //     ID: data.ID,
  //   };
  //   // this.api.deleteHospitalMapping(hospitalData).subscribe((successCode) => {
  //   //   if (successCode.code == '200') {
  //   //     this.message.success('Hospital Deleted Successfully...', '');
  //   //     this.api
  //   //       .getHospitalMapping(0, 0, '', '', ' AND CLAIM_ID = ' + this.claimID)
  //   //       .subscribe(
  //   //         (data) => {
  //   //           if (data['code'] == 200) {
  //   //             this.hospitalMapList = data['data'];
  //   //             this.isSpinning = false;
  //   //           } else {
  //   //             this.message.error("Can't Load Hospital Mapped Data.", '');
  //   //             this.isSpinning = false;
  //   //           }
  //   //         },
  //   //         (err) => {
  //   //           console.log(err);
  //   //         }
  //   //       );
  //   //   } else {
  //   //     this.message.error('Hospital Has Not Deleted...', '');
  //   //     this.isSpinning = false;
  //   //   }
  //   // });
  //   // this.api.deleteHospitalMapping(data1)
  // }
  confirmDeleteHospital(data: any) {
    this.Hospitalclaim = this.Hospitalclaim.filter(
      (item) => item.ID != data.ID
    );

    this.Hospitalclaim = [...[], ...this.Hospitalclaim];

    // const index: number = this.Hospitalclaim.indexOf(data.ID);
    // if (index !== -1) {
    //   this.Hospitalclaim.splice(index, 1);
    console.log('Hospitalclaim', this.Hospitalclaim);
    // }
  }
  cancel(): void {}
}
