import { Component } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { SignatureMaster } from 'src/app/Models/SignatureMaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-signaturemasterlist',
  templateUrl: './signaturemasterlist.component.html',
  styleUrls: ['./signaturemasterlist.component.css'],
})
export class SignaturemasterlistComponent {
  dataList = [];
  loadingRecords = false;
  totalRecords = 1;
  pageIndex = 1;
  pageSize = 10;
  sortValue: string = 'asc';
  sortKey: string = 'NAME';
  searchText: string = '';
  drawerTitle!: string;
  filterQuery: string = '';
  isFilterApplied: string = 'default';
  drawerVisible: boolean = false;
  drawerData: SignatureMaster = new SignatureMaster();
  // drawerVisible!: boolean;
  // drawerTitle!: string;
  // drawerData: Signature = new Signature();
  formTitle = ' Signature Master ';
  // dataList: any[] = [];
  // loadingRecords = false;
  // totalRecords = 1;
  // pageIndex = 1;
  // pageSize = 10;
  // sortValue: string = 'desc';
  // sortKey: string = 'id';
  // searchText: string = '';
  // filterQuery: string = '';
  // isFilterApplied: string = 'default';
  columns: string[][] = [
    ['USER_NAME', 'User Name'],
    ['OFFICE_NAME', 'Office Name'],
    ['POST', 'Post'],
    ['SECTION_TYPE', 'Section Type'],
  ];

  constructor(
    private api: ApiService,
    private message: NzNotificationService
  ) {}

  ngOnInit(): void {
    // this.search();
  }

  keyup(event: any) {
    // this.search();
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = 'id';
      this.sortValue = 'desc';
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }
    var likeQuery = '';
    if (this.searchText != '') {
      likeQuery = ' AND';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }
    this.api
      .getSignature(
        this.pageIndex,
        this.pageSize,
        this.sortKey,
        sort,
        likeQuery
      )
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            this.totalRecords = data['count'];
            this.dataList = data['data'];
            this.loadingRecords = false;
          } else {
            this.message.error('Something Went Wrong', '');
            this.loadingRecords = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }
  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  add(): void {
    this.drawerTitle = ' Add New Signature Master ';
    this.drawerData = new SignatureMaster();
    this.drawerVisible = true;
  }
  edit(data: SignatureMaster): void {
    this.drawerTitle = ' Update Signature Master Information';
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }
  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find((item) => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'id';
    const sortOrder = (currentSort && currentSort.value) || 'desc';
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if (this.pageSize != pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    }

    if (this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search();
  }
}
