<div>
  <form nz-form>
    <div style="font-size: 14px">
      <!-- Self Declaration Certificate -->
      <p style="text-align: center; font-size: large">
        <b> <u>Self Declaration Certificate</u></b>
      </p>
      <br />
      <p>
        In terms of OM No. 19030/2/2020-E.IV dated 22 December 2020 issued by
        (Department of Expenditure, Ministry of Finance, Government of India)
      </p>
      <br /><br />
      <p>
        <b
          >{{ selftoursanctionorder.EMPLOYEE_NAME }},
          {{ selftoursanctionorder.DESIGNATION }}</b
        >. hearby declare and certify that:
      </p>
      <br />

      <div nz-row>
        <div nz-col nzSpan="1">1</div>
        <div nz-col nzSpan="23">
          <p style="word-break: break-all">
            I am claiming reimbursement of travelling charges for travel within
            the city admissible under daily allowance on tour. The tour
            undertaken to Surat was for assisting the Addl.DIT(Vig.) Unit-5,
            Mumbai, in the verifying and collecting the record in the matter of
            complain in CON-879 from Surat Office.
          </p>
        </div>
      </div>

      <div nz-row>
        <div nz-col nzSpan="1">2</div>
        <div nz-col nzSpan="23">
          <p style="word-break: break-all">
            I hearby submit my self declaration in lieu of production of
            receipts / vouchers for reimbursement of travelling charges.
          </p>
        </div>
      </div>

      <div nz-row>
        <div nz-col nzSpan="1">3</div>
        <div nz-col nzSpan="23">
          <p style="word-break: break-all">
            I declare that these charges have been paid by me for travelling
            within the city i.e., from my residence at
            <span *ngFor="let data of selfdeclarationdata; let i = index">
              <ng-container *ngIf="data.IS_RETURNED === 0"
                ><b>{{ data.DEPARTURE_FROM }}</b> to
                <b>{{ data.ARRIVAL_TO }},&nbsp;</b>
              </ng-container></span
            >
            on
            <span *ngFor="let data of selfdeclarationdata; let i = index">
              <ng-container *ngIf="data.IS_RETURNED === 0">
                <b>
                  {{ data.DEPARTURE_DATETIME | date : "dd-MM-yyyy" }},&nbsp;</b
                >
              </ng-container>
            </span>
            <!-- <span
                            *ngFor="let data of selfdeclarationdata;let i =index">
                            <ng-container *ngIf="data.IS_RETURNED === 1">
                                <b> {{data.DEPARTURE_DATETIME
                                    | date : "dd-MM-yyyy"}},&nbsp;</b>
                            </ng-container>
                        </span> -->
            and also for my return travel from
            <span *ngFor="let data of selfdeclarationdata; let i = index">
              <ng-container *ngIf="data.IS_RETURNED === 1">
                <b>{{ data.DEPARTURE_FROM }}</b> to
                <b>{{ data.ARRIVAL_TO }},&nbsp;</b>
              </ng-container>
            </span>
            (residence) on
            <span *ngFor="let data of selfdeclarationdata">
              <ng-container *ngIf="data.IS_RETURNED === 1">
                <b> {{ data.ARRIVAL_DATETIME | date : "dd-MM-yyyy" }},&nbsp;</b>
              </ng-container></span
            >
          </p>
        </div>
      </div>

      <div nz-row>
        <div nz-col nzSpan="1">4</div>
        <div nz-col nzSpan="23">
          <p style="word-break: break-all">
            Thsese two travels wore done using the available local taxis (OLA
            and local taxi). The invoice of the same are annexed herewith.
          </p>
        </div>
      </div>
      <br />
      <br />

      <div style="margin-top: 100px; font-size: 13px">
        <div nz-row>
          <div nz-col nzSpan="15"></div>

          <div nz-col nzSpan="9" style="text-align: center">
            <div style="font-weight: bold">
              Name :- ({{ selftoursanctionorder.EMPLOYEE_NAME }})
            </div>
            <div>Designation :- {{ selftoursanctionorder.DESIGNATION }}</div>
            <div>Mobile No :-{{ selftoursanctionorder.MOBILE_NO }}</div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="footer">
    <button
      type="button"
      nz-button
      (click)="close()"
      class="ant-btn"
      style="margin-right: 8px"
    >
      <span>Cancel</span>
    </button>
    <button
      type="button"
      class="ant-btn ant-btn-primary"
      nz-button
      (click)="showmodal()"
    >
      <span>Download or Print</span>
    </button>
  </div>
</div>

<nz-modal
  [nzWidth]="getwidth()"
  [(nzVisible)]="printOrderModalVisible"
  nzMaskClosable="false"
  nzClosable="false"
  [nzContent]="printOrderModalContent"
  [nzTitle]="'Certificate Duplicate'"
  (nzOnCancel)="printOrderModalCancel()"
  [nzFooter]="printOrderModalFooter"
>
  <ng-template #printOrderModalContent>
    <nz-spin [nzSpinning]="loadingRecords">
      <div style="height: 450px; overflow: auto">
        <div id="excel-table">
          <div
            style="
              width: 700px;
              padding-right: 3px;
              margin-left: 50px;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 14px;
            "
          >
            <div>
              <p style="text-align: center; font-size: large">
                <b> <u>Self Declaration Certificate</u></b>
              </p>
              <br />
              <p>
                In terms of OM No. 19030/2/2020-E.IV dated 22 December 2020
                issued by (Department of Expenditure, Ministry of Finance,
                Government of India)
              </p>
              <br /><br />
              <p>
                <b
                  >{{ selftoursanctionorder.EMPLOYEE_NAME }},
                  {{ selftoursanctionorder.DESIGNATION }}</b
                >. hearby declare and certify that:
              </p>
              <br />

              <div nz-row>
                <div nz-col nzSpan="1">1</div>
                <div nz-col nzSpan="23">
                  <p style="word-break: break-all">
                    I am claiming reimbursement of travelling charges for travel
                    within the city admissible under daily allowance on tour.
                    The tour undertaken to Surat was for assisting the
                    Addl.DIT(Vig.) Unit-5, Mumbai, in the verifying and
                    collecting the record in the matter of complain in CON-879
                    from Surat Office.
                  </p>
                </div>
              </div>

              <div nz-row>
                <div nz-col nzSpan="1">2</div>
                <div nz-col nzSpan="23">
                  <p style="word-break: break-all">
                    I hearby submit my self declaration in lieu of production of
                    receipts / vouchers for reimbursement of travelling charges.
                  </p>
                </div>
              </div>

              <div nz-row>
                <div nz-col nzSpan="1">3</div>
                <div nz-col nzSpan="23">
                  <p style="word-break: break-all">
                    I declare that these charges have been paid by me for
                    travelling within the city i.e., from my residence at
                    <span
                      *ngFor="let data of selfdeclarationdata; let i = index"
                    >
                      <ng-container *ngIf="data.IS_RETURNED === 0"
                        ><b>{{ data.DEPARTURE_FROM }}</b> to
                        <b>{{ data.ARRIVAL_TO }},&nbsp;</b>
                      </ng-container></span
                    >
                    on
                    <span
                      *ngFor="let data of selfdeclarationdata; let i = index"
                    >
                      <ng-container *ngIf="data.IS_RETURNED === 0">
                        <b>
                          {{
                            data.DEPARTURE_DATETIME | date : "dd-MM-yyyy"
                          }},&nbsp;</b
                        >
                      </ng-container>
                    </span>
                    <!-- <span
                                            *ngFor="let data of selfdeclarationdata;let i =index">
                                            <ng-container *ngIf="data.IS_RETURNED === 1">
                                                <b> {{data.DEPARTURE_DATETIME
                                                    | date : "dd-MM-yyyy"}},&nbsp;</b>
                                            </ng-container>
                                        </span> -->
                    and also for my return travel from
                    <span
                      *ngFor="let data of selfdeclarationdata; let i = index"
                    >
                      <ng-container *ngIf="data.IS_RETURNED === 1">
                        <b>{{ data.DEPARTURE_FROM }}</b>
                        to <b>{{ data.ARRIVAL_TO }},&nbsp;</b>
                      </ng-container>
                    </span>
                    (residence) on
                    <span *ngFor="let data of selfdeclarationdata">
                      <ng-container *ngIf="data.IS_RETURNED === 1">
                        <b>
                          {{
                            data.ARRIVAL_DATETIME | date : "dd-MM-yyyy"
                          }},&nbsp;</b
                        >
                      </ng-container></span
                    >
                  </p>
                </div>
              </div>

              <div nz-row>
                <div nz-col nzSpan="1">4</div>
                <div nz-col nzSpan="23">
                  <p style="word-break: break-all">
                    Thsese two travels wore done using the available local taxis
                    (OLA and local taxi). The invoice of the same are annexed
                    herewith.
                  </p>
                </div>
              </div>
              <br />
              <br />
              <!-- <p style="margin-left: 575px;"><b>{{selftoursanctionorder.EMPLOYEE_NAME}}</b></p> -->

              <div style="margin-top: 100px; font-size: 13px">
                <div nz-row>
                  <div nz-col nzSpan="15"></div>

                  <div nz-col nzSpan="9" style="text-align: center">
                    <div style="font-weight: bold">
                      Name :- ({{ selftoursanctionorder.EMPLOYEE_NAME }})
                    </div>
                    <div>
                      Designation :- {{ selftoursanctionorder.DESIGNATION }}
                    </div>
                    <div>Mobile No :-{{ selftoursanctionorder.MOBILE_NO }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>
  </ng-template>
  <ng-template #printOrderModalFooter>
    <button nz-button nzType="default" (click)="printOrderModalCancel()">
      Close
    </button>

    <button
      nz-button
      nzType="default"
      [useExistingCss]="true"
      printSectionId="excel-table"
      ngxPrint
    >
      Print
    </button>

    <button
      nz-button
      nzType="primary"
      [nzLoading]="pdfDownload"
      (click)="openpdf()"
    >
      Download
    </button>
  </ng-template>
</nz-modal>
